import { AudioElementTemplate } from './AudioElementTemplate';
import { C9Object } from './C9Object';
import { LogicalGroupParent } from './LogicalGroupParent';
import { TimeControlDef } from './TimeControlDef';

export class AudioElement extends C9Object {
  timeControls = [new TimeControlDef()];
  playSpeed = 1;
  volume = 1;
  durationInMS: number;
  audioElementTemplate: AudioElementTemplate;
  parentGroups = [new LogicalGroupParent()];
  enabled: boolean;
}
