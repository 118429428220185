import { cloneDeep } from 'lodash';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ForecastDataDef } from '../../../../../model/definitions/ForecastDataDef';
import { ForecastValueDef } from '../../../../../model/definitions/ForecastPropertiesDef';
import { ForecastWDElementDef } from '../../../../../model/definitions/ForecastWDElementDef';
import { ForecastWDLocationDef } from '../../../../../model/definitions/ForecastWDLocationDef';
import { ValueTypeEnum } from '../../../../../model/enums/ValueTypeEnum';
import { updateForecastTemp } from '../../../../../store/slices/active-slice';
import { ForecastParameterCard } from './ForecastParameterCard';

interface ForecastItemProps {
  data: Array<ForecastWDElementDef>;
  isDaily: string;
  offset: number;
  freshData?: ForecastDataDef;
  step: 'observed' | 'forecast' | 'add';
  selectedDate?: number;
  zeroDate: number;
  location: string;
  returnedValues: (e: Array<ForecastWDElementDef>) => void;
  id: string;
}
export const ForecastItem = ({
  data,
  isDaily,
  freshData,
  selectedDate,
  zeroDate,
  location,
  returnedValues,
  id,
}: ForecastItemProps) => {
  const dispatch = useDispatch();
  const [updated, setUpdated] = useState(data);
  const getIcon = (isDaily: string, time: string) => {
    if (isDaily === 'true') {
      return freshData?.daily.find((item) => item.utcDate === time)?.weatherType ?? 'N/A';
    }
    if (isDaily === 'false') {
      return freshData?.intraDay.find((item) => item.utcDate === time)?.weatherType ?? 'N/A';
    }
    return 'N/A';
  };
  const updateItem = useCallback(
    (data: Array<ForecastWDElementDef>) => {
      dispatch(updateForecastTemp({ id: id, data: data }));
    },
    [data, id, selectedDate],
  );
  useEffect(() => {
    updateItem(updated);
  }, [updated]);
  const getData = (isDaily: string, time: string) => {
    if (isDaily === 'true') {
      return freshData?.daily.find((item) => {
        return item.utcDate === time;
      });
    }
    if (isDaily === 'false') {
      return freshData?.intraDay.find((item) => item.utcDate === time);
    }
  };
  const getVal = (value: ForecastValueDef, parameterType: string) => {
    return value?.properties.find((prop) => prop.name === parameterType)?.values;
  };

  const changeUnit = useCallback(
    (id: string, e: string) => {
      const arr = [...updated];
      let itm = arr.find((i) => i.id === id);
      const index = arr.findIndex((i) => i.id === id);
      if (itm && freshData && itm.forecastWDSource.value) {
        itm = {
          ...itm,
          forecastWDSource: {
            ...itm.forecastWDSource,
            location: {
              ...(freshData?.location as unknown as ForecastWDLocationDef),
              locationId: location,
            },
            unitOfMeasurement: e,
          },
        };
        arr[index] = itm;
      }
      setUpdated(arr);
    },
    [updated],
  );
  const changeIcon = (id: string, e: string) => {
    const arr = [...updated];
    let itm = arr.find((i) => i.id === id);
    const index = arr.findIndex((i) => i.id === id);
    if (itm && freshData) {
      itm = {
        ...itm,
        forecastWDSource: {
          ...itm.forecastWDSource,
          location: {
            ...(freshData?.location as unknown as ForecastWDLocationDef),
            locationId: location,
          },
          value: e,
        },
      };
      arr[index] = itm;
    }
    setUpdated(arr);
  };
  useEffect(() => {
    const dataToChange = cloneDeep(updated);
    const arr = dataToChange.map((item) => {
      let elem = cloneDeep(item);
      const { forecastWDSource } = item;
      const { parameterType, valueType } = forecastWDSource;
      const time = moment(
        new Date(
          (selectedDate ?? 0) +
            new Date(
              data.find((itemOrig) => itemOrig.forecastWDSource.id === item.forecastWDSource.id)
                ?.forecastWDSource.utcDate ?? 0,
            ).getTime() -
            zeroDate,
        ),
      )
        .toISOString(true)
        .slice(0, -10);
      const value = getData(isDaily, time);
      if (value)
        elem = {
          ...elem,
          name:
            valueType === ValueTypeEnum.NUMERICAL
              ? `${location} ${parameterType}`
              : `${location} ${getIcon(isDaily, time)}`,
          forecastWDSource: {
            ...elem.forecastWDSource,
            parameterType:
              valueType === ValueTypeEnum.IMAGE
                ? getIcon(isDaily, time)
                : elem.forecastWDSource.parameterType,
            location: {
              ...(freshData?.location as unknown as ForecastWDLocationDef),
              locationId: location,
            },
            name:
              valueType === ValueTypeEnum.NUMERICAL
                ? `${location} ${parameterType}`
                : `${location} ${getIcon(isDaily, time)}`,
            utcDate: time,
            value:
              value && valueType === ValueTypeEnum.NUMERICAL
                ? getVal(value, parameterType)
                : elem.forecastWDSource.value,
          },
        };
      return elem;
    });
    setUpdated(arr);
    returnedValues(arr);
  }, [selectedDate, freshData, isDaily, /* updated, returnedValues, data, zeroDate, */ location]);

  const renderCards = () => {
    return (
      <div className={'grid grid-cols-5 gap-2'}>
        {updated.map((item) => (
          <ForecastParameterCard
            selectedDate={selectedDate}
            onUnitChange={(e) => changeUnit(item.id, e)}
            onIconChange={(e) => {
              changeIcon(item.id, e);
            }}
            key={`${item.id}`}
            item={item}
            freshData={freshData}
            zeroDate={zeroDate}
            data={data}
            isDaily={isDaily}
          />
        ))}
      </div>
    );
  };
  return <>{selectedDate && renderCards()}</>;
};
