import { MapPanelDef } from '../../../../../model/definitions/MapPanelDef';
import { SceneDef } from '../../../../../model/definitions/SceneDef';
import { useGetGribValue } from '../useGetGribValue';

export const useUpdateGrib = () => {
  const { mutate: updateGribValues, isLoading: isLoadingGrib } = useGetGribValue();

  const updateGrib = (scene: SceneDef | null, dateRange: Array<number | null | undefined>) =>
    scene?.mapPanels.map((map: MapPanelDef) =>
      map.wdSpace.map((space) =>
        space.gribMapLayers.map(
          (grib) =>
            dateRange[0] &&
            dateRange[1] &&
            updateGribValues({
              dataProductId: grib.gribSource.dataProductId,
              location: grib.gribSource.location,
              paramTypeId: grib.gribSource.parameterType.id,
              range: [dateRange[0], dateRange[1]],
              density: grib.dataFramesDensity,
              sceneId: scene.id,
              mapId: map.id,
              spaceId: space.id,
              layerId: grib.id,
            }),
        ),
      ),
    );

  return { updateGrib, isLoadingGrib };
};
