import { IconType } from 'react-icons';

const CustomWallet: IconType = (props) => {
  const { size, color } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 27 27"
      stroke="currentColor"
      strokeWidth="0"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.6507 4.16687C23.5397 4.05486 23.4076 3.96604 23.262 3.90557C23.1163 3.8451 22.9601 3.81419 22.8025 3.81463H21.9556V1.81867C21.9552 1.50116 21.8289 1.19676 21.6044 0.97225C21.3799 0.747738 21.0755 0.62145 20.758 0.621094H3.52422C2.58954 0.621107 1.69315 0.992412 1.03223 1.65333C0.371318 2.31424 1.32337e-05 3.21063 0 4.14531V17.8549C1.32337e-05 18.7895 0.371318 19.6859 1.03223 20.3468C1.69315 21.0078 2.58954 21.3791 3.52422 21.3791H22.7553C23.0721 21.3785 23.3757 21.2526 23.6001 21.029C23.8244 20.8054 23.9512 20.5021 23.9529 20.1853L24 5.0163C24.001 4.85863 23.9706 4.70234 23.9106 4.55651C23.8507 4.41069 23.7623 4.27824 23.6507 4.16687ZM22.3574 19.7823H3.52422C3.01303 19.7823 2.52277 19.5792 2.16131 19.2178C1.79984 18.8563 1.59677 18.3661 1.59677 17.8549V4.14531C1.59677 3.63412 1.79984 3.14387 2.16131 2.7824C2.52277 2.42093 3.01303 2.21786 3.52422 2.21786H20.3588V3.81463H3.59273V5.4114H22.402L22.3574 19.7823Z"
        fill={color}
      />
    </svg>
  );
};

export default CustomWallet;
