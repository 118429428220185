import { createSlice } from '@reduxjs/toolkit';

import { RolesEnum } from '../../model/enums/RolesEnum';

const initialState: { userRoles: RolesEnum[] } = {
  userRoles: [],
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setRoles: (state, action) => {
      state.userRoles = action.payload;
    },
  },
});

export const { setRoles } = userSlice.actions;
export default userSlice.reducer;
