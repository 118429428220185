import { ColorPalette } from '../definitions/ColorPalette';
import { PaletteSetup } from '../definitions/ColorPaletteDef';
import { ColorPaletteParamTypeEnum } from '../enums/ColorPaletteParamTypeEnum';
import { PaletteLegendScalingEnum } from '../enums/PaletteLegendScalingEnum';
import { VisualisationTypeEnum } from '../enums/VisualisationTypeEnum';

export class CustomPaletteDTO {
  name: string;
  description: string;
  type = 'CUSTOM'; // system | user_defined
  paletteParamType: ColorPaletteParamTypeEnum;
  visualisationType: VisualisationTypeEnum;
  colorStops: ColorPalette = new ColorPalette();
  source: string;
  paletteLegendScaling: PaletteLegendScalingEnum;
  setup?: PaletteSetup | null;
  dataProductId?: string;
  enterpriseAccountId?: string;
}
