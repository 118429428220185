import { Select } from 'antd';
import { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useGetForecastData } from '../../core/api/forecastData/useGetForecastData';
import { useGetForecastDivep } from '../../core/api/forecastData/useGetForecastDivep';
import { ForecastLocationDef } from '../../model/definitions/ForecastLocationDef';
import { ForecastElementRequestDTO } from '../../model/DTO/ForecastElementRequestDTO';
import { ValueTypeEnum } from '../../model/enums/ValueTypeEnum';
import { ForecastElementForm } from '../../model/UI/ForecastElementForm';
import { ForecastIconElementRequestDTO } from '../../model/UI/ForecastIconElementRequestDTO';
import { ActiveDef, setForecastSource } from '../../store/slices/active-slice';
import { RootState } from '../../store/store';
import { ForecastFirstStep } from './PointDataComponents/ForecastFirstStep';
import { ForecastSecondStep } from './PointDataComponents/ForecastSecondStep';

interface AddForecastDataProps {
  onLocationSelect: (location: ForecastLocationDef) => void;
  formData: ForecastElementForm;
  onElementAdd: (form: ForecastElementForm) => void;
  step?: number;
  basic?: {
    name: string;
    description?: string;
    groupDate: boolean;
  };
  setBasic?: (e: { name: string; description?: string; groupDate: boolean }) => void;
  setFormData: (e: ForecastElementForm) => void;
}
export const AddForecastData = ({
  onLocationSelect,
  formData,
  onElementAdd,
  step,
  basic,
  setBasic,
  setFormData,
}: AddForecastDataProps) => {
  const dispatch = useDispatch();
  const { forecastSource } = useSelector<RootState>((state) => state.active) as ActiveDef;
  const [bias, setBias] = useState<boolean>(false);
  const [source, setSource] = useState<string | undefined>(forecastSource);
  const [location, setLocation] = useState<string>('');
  const { data: divep, isLoading } = useGetForecastDivep();
  const { data } = useGetForecastData(location, bias, source);
  const { elements } = formData;
  useEffect(() => {
    data?.location &&
      onLocationSelect({
        ...data.location,
        locationId: location,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  const handleAddElement = (
    element: ForecastElementRequestDTO | ForecastIconElementRequestDTO,
    remove?: boolean,
  ) => {
    const isInQueue = elements.find(
      (selected) =>
        selected.parameterType === element.parameterType &&
        selected.utcDate === element.utcDate &&
        selected.daily === element.daily,
    );
    const newQueue = cloneDeep(elements);
    const index = elements.findIndex(
      (element) =>
        element.parameterType === isInQueue?.parameterType &&
        element.utcDate === isInQueue.utcDate &&
        isInQueue.daily === element.daily,
    );
    if ((isInQueue && element.valueType !== ValueTypeEnum.IMAGE) || (isInQueue && remove)) {
      newQueue.splice(index, 1);
      onElementAdd({ ...formData, elements: newQueue });
    } else if (isInQueue && element.valueType === ValueTypeEnum.IMAGE) {
      newQueue[index] = element;
      onElementAdd({ ...formData, elements: newQueue });
    } else onElementAdd({ ...formData, elements: [...elements, element] });
  };
  const options = divep?.dataProducts?.map((source) => {
    return { label: source.sourceName, value: source.dataProductId };
  });
  const extendedOptions = options?.length ? options : [{ label: 'default', value: '' }];
  return (
    <>
      {step === 0 && basic && setBasic && (
        <>
          <label>
            Select source
            <Select
              loading={isLoading}
              options={extendedOptions}
              onChange={(e) => {
                dispatch(setForecastSource({ source: e }));
                setSource(e);
              }}
              value={source}
            />
          </label>
          {source !== undefined && (
            <ForecastFirstStep
              basic={basic}
              setBasic={setBasic}
              location={location}
              setLocation={setLocation}
            />
          )}
        </>
      )}
      {step === 1 && data && basic && (
        <ForecastSecondStep
          bias={bias}
          setBias={setBias}
          data={data}
          onElementClick={handleAddElement}
          formData={formData}
          setFormData={setFormData}
          basic={basic}
        />
      )}
    </>
  );
};
