import { IoMdVolumeHigh, IoMdVolumeLow, IoMdVolumeMute, IoMdVolumeOff } from 'react-icons/io';

export const getVolumeIcon = (volume: number) => {
  if (volume * 100 >= 66) {
    return <IoMdVolumeHigh size={12 + 0.12 * (volume * 100)} />;
  }
  if (volume * 100 < 66 && volume * 100 >= 33) {
    return <IoMdVolumeLow size={12 + 0.12 * (volume * 100)} />;
  }
  if (volume * 100 < 33 && volume * 100 > 0) {
    return <IoMdVolumeMute size={12 + 0.12 * (volume * 100)} />;
  }
  if (volume === 0) {
    return <IoMdVolumeOff size={24} />;
  }
};
