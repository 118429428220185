import { useSelector } from 'react-redux';

import { ElementsEnum } from '../../../core/ui/enums/ElementsEnum';
import { ForecastWDElementDef } from '../../../model/definitions/ForecastWDElementDef';
import { ValueTypeEnum } from '../../../model/enums/ValueTypeEnum';
import { ActiveDef } from '../../../store/slices/active-slice';
import { selectActiveForecastLayer } from '../../../store/slices/selectors';
import { RootState } from '../../../store/store';
import { Panel } from './components/Panel';
import { ForecastSpecifics } from './ForecastProperties/ForecastSpecifics';
import { BoxStyle } from './panels/BoxStyle';
import { FontProperties } from './panels/FontProperties';
import { PositionControls } from './panels/PositionControls';
import { TimeControlsPanel } from './panels/TimeControlsPanel';

export const ForecastProperties = () => {
  const observedLayer = useSelector<RootState, ForecastWDElementDef | undefined>((state) =>
    selectActiveForecastLayer(state),
  );
  const { activePoster, activeMap } = useSelector<RootState, ActiveDef>((state) => state.active);
  const {
    timeControls,
    positionControl,
    boxDef,
    forecastWDSource,
    name,
    description,
    displayUnitOfMeasurement,
  } = observedLayer ? observedLayer : new ForecastWDElementDef();

  return (
    <Panel>
      {observedLayer && (
        <>
          <ForecastSpecifics
            source={forecastWDSource}
            name={name}
            description={description}
            displayUnitOfMeasurement={displayUnitOfMeasurement}
            mapId={activeMap}
            layer={observedLayer}
          />
          {!activePoster && (
            <>
              <PositionControls
                position={positionControl}
                layer={'forecastWDElements'}
                mapId={activeMap}
              />
              <TimeControlsPanel
                timeControls={timeControls}
                layer={ElementsEnum.FORECAST_WD}
                layerType={'forecastDataLayers'}
                mapId={activeMap}
              />
            </>
          )}
          {forecastWDSource.valueType !== ValueTypeEnum.IMAGE && (
            <FontProperties
              layer={observedLayer}
              layerType={'forecastWDElements'}
              poster={activePoster}
              mapId={activeMap}
            />
          )}
          <BoxStyle box={boxDef} elementType={ElementsEnum.FORECAST_WD} mapId={activeMap} />
        </>
      )}
    </Panel>
  );
};
