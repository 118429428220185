import './ThumbnailCard.scss';

import React from 'react';

interface Props {
  item: any;
  onClick?: () => void;
  className: string;
}
const ThumbnailCard: React.FC<Props> = ({ item, className, onClick }) => {
  return (
    <div className="min-h-[110px] min-w-[110px]">
      <img src={item} alt="" onClick={onClick} className={className} />
    </div>
  );
};

export default ThumbnailCard;
