import './style.scss';

import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { Select } from 'flowbite-react';
import { nanoid } from 'nanoid';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Button from '../../atoms/button/Button';
import Input from '../../atoms/input/Input';
import TextArea from '../../atoms/input/Textarea';
import { SceneDef } from '../../model/definitions/SceneDef';
import { TimeStepEnum } from '../../model/enums/TimeStepEnum';
import { FormattingHelpPage } from '../../pages/playground/properties/panels/timeFrameIndicator/FormattingHelpPage';
import { formats } from '../../pages/playground/properties/panels/timeFrameIndicator/util';
import { selectActiveScene } from '../../store/slices/selectors';
import { RootState } from '../../store/store';

export interface ITimeStampData {
  format: string;
  step: TimeStepEnum;
  name: string;
  description: string;
  dateStart: number | null;
  dateEnd: number | null;
}
interface ITimeStampProps {
  onChange: (e: ITimeStampData) => void;
}
export const convertTimeStepEnumToString = (ts: TimeStepEnum) => {
  switch (ts) {
    case TimeStepEnum.MIN_1:
      return 'Every minute';
    case TimeStepEnum.MIN_5:
      return '5 Minutes';
    case TimeStepEnum.MIN_15:
      return '15 Minutes';
    case TimeStepEnum.MIN_10:
      return '10 Minutes';
    case TimeStepEnum.MIN_30:
      return '30 Minutes';
    case TimeStepEnum.HOURLY:
      return 'Hourly';
    case TimeStepEnum.DAILY:
      return 'Daily';
    case TimeStepEnum.WEEKLY:
      return 'Weekly';
  }
};
const { RangePicker } = DatePicker;
export const AddTimestampModal: FC<ITimeStampProps> = ({ onChange }) => {
  const activeSceneDef = useSelector<RootState, SceneDef | null>((state) =>
    selectActiveScene(state),
  );
  const [isHelp, setIsHelp] = useState<boolean>(false);
  const [isCustom, setIsCustom] = useState<boolean>(false);
  const [timeStampData, setTimeStampData] = useState<ITimeStampData>({
    format: 'DD-MM-YYYY',
    step: TimeStepEnum.HOURLY,
    name: `Timestamp ${nanoid(10)}`,
    description: '',
    dateStart: activeSceneDef?.startDate ?? null,
    dateEnd: activeSceneDef?.endDate ?? null,
  });
  const formatOptions = () => {
    const optionsArray = [];
    for (const key in formats) {
      optionsArray.push(
        // @ts-ignore
        <option key={key} value={formats[key as keyof formats]}>
          {key}
        </option>,
      );
    }
    return optionsArray;
  };
  const stepOptions = Object.keys(TimeStepEnum).map((step) => (
    <option key={step} value={step}>
      {convertTimeStepEnumToString(step as TimeStepEnum)}
    </option>
  ));
  useEffect(() => {
    onChange(timeStampData);
  }, [timeStampData]);
  return (
    <div>
      <Input
        label={'Name'}
        required
        type={'text'}
        value={timeStampData.name}
        onChange={(e) =>
          setTimeStampData((prev) => {
            return {
              ...prev,
              name: e.target.value as TimeStepEnum,
            };
          })
        }
      />
      <TextArea
        name={'description'}
        label={'Description'}
        onChange={(e) =>
          setTimeStampData((prev) => {
            return {
              ...prev,
              description: e.target.value,
            };
          })
        }
        cols={2}
        value={timeStampData.description}
      />
      <label className={'mt-6 text-sm'}>Select range</label>
      <RangePicker
        showTime
        autoFocus
        name={'range'}
        className={'w-full bg-transparent range-picker'}
        value={[
          timeStampData.dateStart ? dayjs(timeStampData.dateStart) : null,
          timeStampData.dateEnd ? dayjs(timeStampData.dateEnd) : null,
        ]}
        onChange={(e) => {
          e &&
            e[0] !== null &&
            e[1] !== null &&
            Array.isArray(e) &&
            setTimeStampData((prev) => {
              return {
                ...prev,
                dateStart: e[0] ? e[0].unix() * 1000 : null,
                dateEnd: e[1] ? e[1].unix() * 1000 : null,
              };
            });
        }}
      />
      <div className={'grid grid-cols-3 gap-2 mt-2'}>
        <div>
          <label className={'mt-6 text-sm'}>Step</label>
          <Select
            onChange={(e) =>
              setTimeStampData((prev) => {
                return {
                  ...prev,
                  step: e.target.value as TimeStepEnum,
                };
              })
            }
            value={timeStampData.step}
          >
            {stepOptions}
          </Select>
        </div>
        <div>
          <label className={'mt-6 text-sm'}>Format</label>
          <Select
            onChange={(e) => {
              if (e.target.value === 'CUSTOM') {
                setIsCustom(true);
              } else {
                setIsCustom(false);
              }
              setTimeStampData((prev) => {
                return {
                  ...prev,
                  format: e.target.value,
                };
              });
            }}
            value={timeStampData.format}
          >
            {formatOptions()}
          </Select>
        </div>
        {isCustom ? (
          <div className={'flex gap-2 items-center'}>
            <div>
              <label className={'mt-6 text-sm'}>Format</label>
              <Input
                onChange={(e) =>
                  setTimeStampData((prev) => {
                    return {
                      ...prev,
                      format: e.target.value,
                    };
                  })
                }
                style={{ width: '100%' }}
                value={timeStampData.format}
              />
            </div>
            <div>
              <label className={'mt-6 text-sm text-transparent'}>{'help'}</label>
              <Button
                label={'?'}
                shape={'round'}
                onClick={() => setIsHelp(true)}
                style={{ margin: 0 }}
                className={'self-start mt-2'}
              />
            </div>
          </div>
        ) : null}
      </div>
      {isHelp && <FormattingHelpPage isOpen={isHelp} onClose={() => setIsHelp(false)} />}
    </div>
  );
};
