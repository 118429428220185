import { fromGeoBoxToBoundsWDLayers } from '../../../../helpers/boundsManage';
import { DataFrameDef } from '../../../../model/definitions/DataFrameDef';
import { GribMapLayer } from '../../../../model/definitions/GribMapLayer';
import { MapPanelDef } from '../../../../model/definitions/MapPanelDef';
import { RadarMapLayer } from '../../../../model/definitions/RadarMapLayer';
import { SatelliteMapLayer } from '../../../../model/definitions/SatelliteMapLayer';
import { GribRange } from '../../../playground/repository-item/templateModals/MapTemplate/GribRange';
import { RadarRange } from '../../../playground/repository-item/templateModals/MapTemplate/RadarRange';
import { SatelliteRange } from '../../../playground/repository-item/templateModals/MapTemplate/SatelliteRange';

interface MapTemplatingProps {
  map: MapPanelDef;
  scene: string;
  onLayerFramesChange: (
    mapId: string,
    scene: string,
    layerType: 'grib' | 'radar' | 'satellite',
    layerId: string,
    frames: DataFrameDef[],
  ) => void;
}
export const MapTemplating = ({ map, onLayerFramesChange, scene }: MapTemplatingProps) => {
  const transformedBounds = fromGeoBoxToBoundsWDLayers(map.baseMapSetup.boundingBox);
  const {
    wdSpace: [{ gribMapLayers, radarMapLayers, satelliteMapLayers }],
    id,
  } = map;
  const handleLayer = (
    layerId: string,
    frames: DataFrameDef[],
    layer: 'grib' | 'radar' | 'satellite',
  ) => {
    onLayerFramesChange(id, scene, layer, layerId, frames);
  };
  const renderModelRanges = (gribLayers: GribMapLayer[]) => {
    return gribLayers.map((layer) => (
      <GribRange
        key={layer.id}
        source={layer}
        bounds={transformedBounds}
        setFrames={(layerId, frames) => handleLayer(layerId, frames, 'grib')}
      />
    ));
  };
  const renderRadarRanges = (radarLayers: RadarMapLayer[]) => {
    return radarLayers.map((layer) => (
      <RadarRange
        key={layer.id}
        source={layer}
        bounds={transformedBounds}
        setFrames={(layerId, frames) => handleLayer(layerId, frames, 'radar')}
      />
    ));
  };
  const renderSatelliteRanges = (satelliteMapLayers: SatelliteMapLayer[]) => {
    return satelliteMapLayers.map((layer) => (
      <SatelliteRange
        key={layer.id}
        source={layer}
        bounds={transformedBounds}
        setFrames={(layerId, frames) => handleLayer(layerId, frames, 'satellite')}
      />
    ));
  };
  return (
    <>
      <h2>
        <small>Map name:</small>
        {map.name}
      </h2>
      <div>
        {renderModelRanges(gribMapLayers)}
        {renderRadarRanges(radarMapLayers)}
        {renderSatelliteRanges(satelliteMapLayers)}
      </div>
    </>
  );
};
