import { ResizeDirection } from 're-resizable';
import { useEffect, useState } from 'react';
import { MdOutlineDragHandle } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useResizeDetector } from 'react-resize-detector';
import { Position, ResizableDelta, Rnd } from 'react-rnd';

import { ModeEnum } from '../../../core/ui/enums/ModeEnum';
import { absoluteToPercent, getNumber, getPercentFromData } from '../../../helpers/timelineUtil';
import { TimeControlDef } from '../../../model/definitions/TimeControlDef';
import { GroupingEnum } from '../../../model/UI/enums/GroupingEnum';
import { ActiveDef } from '../../../store/slices/active-slice';
import { updatePosterLayer } from '../../../store/slices/project-slice';
import { RootState } from '../../../store/store';

interface PosterLaneProps {
  time: TimeControlDef;
  layerType: MapLayers;
  duration: number;
  mapId: string;
  index: number;
  parentTime: TimeControlDef;
  layerId: string;
}
export const PosterLane = ({
  time,
  layerType,
  duration,
  index,
  mapId,
  parentTime,
  layerId,
  ...props
}: PosterLaneProps) => {
  const { mode, activeZoom, activeScene, grouping } = useSelector<RootState>(
    (state) => state.active,
  ) as ActiveDef;
  const [timeCtrl, setTimeCtrl] = useState<TimeControlDef>(time);
  const { width, ref } = useResizeDetector();
  const dispatch = useDispatch();
  const percentToTime = (percent: number) => {
    return Math.round((duration * percent) / 100);
  };
  const getPercentFromRef = (position: Position, ref: HTMLElement): number => {
    return (position.x * 100) / ref.offsetParent!.clientWidth;
  };
  const getPercentFromDelta = (data: ResizableDelta, ref: HTMLElement) => {
    return (data.width * 100) / ref.offsetParent!.clientWidth;
  };

  useEffect(() => {
    mode === ModeEnum.SEQUENCE && setTimeCtrl(absoluteToPercent([time], duration)[0]);
  }, [time, activeZoom, width, mode, duration]);

  const resizeStopped = (
    position: Position,
    delta: ResizableDelta,
    ref: HTMLElement,
    direction: ResizeDirection,
  ): void => {
    let payload: TimeControlDef = { ...timeCtrl };
    if (direction === 'left') {
      setTimeCtrl({ ...timeCtrl, startMS: getPercentFromRef(position, ref) });
      payload = { ...timeCtrl, startMS: getPercentFromRef(position, ref) };
      payload = {
        ...timeCtrl,
        startMS:
          parentTime.startMS > percentToTime(getPercentFromRef(position, ref))
            ? parentTime.startMS
            : percentToTime(getPercentFromRef(position, ref)),
        endMS: percentToTime(timeCtrl.endMS),
      };
    }
    if (direction === 'right') {
      setTimeCtrl({ ...timeCtrl, endMS: timeCtrl.endMS + getPercentFromDelta(delta, ref) });
      payload = { ...timeCtrl, endMS: timeCtrl.endMS + getPercentFromDelta(delta, ref) };
      payload = {
        ...timeCtrl,
        startMS: percentToTime(timeCtrl.startMS),
        endMS:
          parentTime.endMS < percentToTime(timeCtrl.endMS + getPercentFromDelta(delta, ref))
            ? parentTime.endMS
            : percentToTime(timeCtrl.endMS + getPercentFromDelta(delta, ref)),
      };
    }
    dispatch(
      updatePosterLayer({
        time: payload,
        activeScene,
        mapId,
        index,
      }),
    );
  };
  const dragEnded = (percent: number) => {
    let payload: TimeControlDef;
    if (absoluteToPercent([parentTime], duration)[0].startMS > percent) {
      setTimeCtrl({
        ...timeCtrl,
        startMS: absoluteToPercent([parentTime], duration)[0].startMS,
        endMS:
          absoluteToPercent([parentTime], duration)[0].startMS +
          (timeCtrl.endMS - timeCtrl.startMS),
      });
      payload = {
        ...timeCtrl,
        startMS: percentToTime(absoluteToPercent([parentTime], duration)[0].startMS),
        endMS: percentToTime(
          absoluteToPercent([parentTime], duration)[0].startMS +
            (timeCtrl.endMS - timeCtrl.startMS),
        ),
      };
    } else if (
      absoluteToPercent([parentTime], duration)[0].endMS <
      percent + (timeCtrl.endMS - timeCtrl.startMS)
    ) {
      setTimeCtrl({
        ...timeCtrl,
        startMS:
          absoluteToPercent([parentTime], duration)[0].endMS - (timeCtrl.endMS - timeCtrl.startMS),
        endMS: absoluteToPercent([parentTime], duration)[0].endMS,
      });
      payload = {
        ...timeCtrl,
        startMS: percentToTime(
          absoluteToPercent([parentTime], duration)[0].endMS - (timeCtrl.endMS - timeCtrl.startMS),
        ),
        endMS: percentToTime(absoluteToPercent([parentTime], duration)[0].endMS),
      };
    } else {
      setTimeCtrl({
        ...timeCtrl,
        startMS: percent,
        endMS: percent + (timeCtrl.endMS - timeCtrl.startMS),
      });
      payload = {
        ...timeCtrl,
        startMS: percentToTime(percent),
        endMS: percentToTime(percent + (timeCtrl.endMS - timeCtrl.startMS)),
      };
    }
    dispatch(
      updatePosterLayer({
        time: payload,
        activeScene,
        mapId,
        index,
      }),
    );
  };

  const lane = (
    <Rnd
      enableResizing={{ left: true, right: true }}
      minHeight={12}
      dragAxis={'x'}
      resizeHandleComponent={{
        right: (
          <MdOutlineDragHandle
            size={24}
            color={'rgb(96, 136, 113)'}
            style={{
              transform: 'rotate(90deg) translateY(12px)',
              height: '100%',
            }}
          />
        ),
        left: (
          <MdOutlineDragHandle
            size={24}
            color={'rgb(96, 136, 113)'}
            style={{
              transform: 'rotate(90deg) translateY(2px)',
              height: '100%',
            }}
          />
        ),
      }}
      className={`segment ${layerType}_${index}`}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0 10px',
        height: 10,
        maxHeight: 10,
        minHeight: 10,
        background: '#0054ff',
      }}
      bounds={'parent'}
      position={{
        x: getNumber(timeCtrl.startMS, width),
        y: grouping === GroupingEnum.LOGICAL ? 4 : 6,
      }}
      size={{
        width: timeCtrl.endMS - timeCtrl.startMS + '%',
        height: 12,
      }}
      onDragStop={(e, data) => {
        e && dragEnded(getPercentFromData(data, width));
      }}
      onResizeStop={(e, direction, ref, delta, position) =>
        resizeStopped(position, delta, ref, direction)
      }
    />
  );
  return (
    <>
      <div {...props} id={'Lane'} ref={ref}>
        <div className={'rail'} style={{ height: 1 }} />
        {lane}
      </div>
    </>
  );
};
