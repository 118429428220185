export const PortalDiv = () => (
  <div
    id="portal"
    style={{
      display: 'flex',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      position: 'fixed',
      pointerEvents: 'none',
      zIndex: 999,
    }}
  ></div>
);
