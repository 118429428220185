import { DataFrameDef } from './DataFrameDef';
import { PickerDef } from './PickerDef';
import { RadarSourceDef } from './RadarSourceDef';
import { TimeControlDef } from './TimeControlDef';
import { WDLocation } from './WDLocation';
import { WeatherDataMapLayer } from './WeatherDataMapLayer';

export class RadarMapLayer extends WeatherDataMapLayer {
  radarSource: RadarSourceDef;
  constructor(
    dataProductId: string | null,
    name: string,
    source: PickerDef,
    location: WDLocation,
    timeControls: TimeControlDef,
    dataFrames: Array<DataFrameDef>,
    zIndex: number,
  ) {
    super();
    this.name = name;
    this.radarSource = new RadarSourceDef();
    this.radarSource.dataProductId = dataProductId;
    this.radarSource.radarSource = source;
    this.radarSource.location = location;
    this.timeControls = [timeControls];
    this.dataFrames = dataFrames;
    this.zindex = zIndex;
  }
}
