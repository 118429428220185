import { Select } from 'antd';
import { Label, Modal, TextInput } from 'flowbite-react';
import { FC, useEffect, useState } from 'react';
import { FiEdit } from 'react-icons/fi';

import Button from '../../../atoms/button/Button';

interface Props {
  opened: boolean;
  onClose: () => void;
  onEdit: (data: { newName: string; newDescription: string; newSymbolType?: string }) => void;
  name: string;
  description: string;
  symbolType?: string;
  type?: string;
  isDefault?: boolean;
}
const EditInfoModal: FC<Props> = ({
  opened,
  onClose,
  onEdit,
  name,
  description,
  symbolType,
  isDefault,
  type,
}) => {
  const [data, setData] = useState(() => {
    if (type === 'icon-set') {
      return {
        newName: name,
        newDescription: description,
        newIsDefault: isDefault,
      };
    }
    if (type === 'symbol') {
      return {
        newName: name,
        newDescription: description,
        newSymbolType: symbolType,
      };
    }
    return {
      newName: name,
      newDescription: description,
    };
  });
  useEffect(() => {
    if (type === 'icon-set') {
      setData({ newName: name, newDescription: description, newIsDefault: isDefault });
    } else if (type === 'symbol') {
      setData({ newName: name, newDescription: description, newSymbolType: symbolType });
    } else {
      setData({
        newName: name,
        newDescription: description,
      });
    }
  }, [name, description, isDefault, symbolType, type]);

  return (
    <Modal show={opened} onClose={onClose} style={{ zIndex: 999 }}>
      <div className="details-modal">
        <Modal.Header>Edit basic info</Modal.Header>
        <Modal.Body>
          <div>
            <div className="mb-2 block mt-2">
              <Label value="Name" />
            </div>
            <TextInput
              value={data.newName ?? ''}
              onChange={(e) => setData({ ...data, newName: e.target.value })}
              type="text"
              sizing="md"
            />
          </div>
          <div>
            <div className="mb-2 block mt-2">
              <Label value="Description" />
            </div>
            <TextInput
              value={data.newDescription ?? ''}
              onChange={(e) => setData({ ...data, newDescription: e.target.value })}
              type="text"
              sizing="md"
            />
          </div>
          {type === 'symbol' && data.newSymbolType && (
            <div>
              <div className="mb-2 block mt-2">
                <Label value="Symbol type" />
              </div>
              <Select
                value={data.newSymbolType}
                onChange={(e) => setData({ ...data, newSymbolType: e })}
                options={[
                  { value: 'ARROW', label: 'ARROW' },
                  { value: 'WIND', label: 'WIND' },
                ]}
              />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            buttonType="primary"
            className={`ws-button`}
            onClick={() => onEdit(data)}
            label="Apply"
            icon={<FiEdit />}
            style={{ margin: 0 }}
            disabled={!data.newName}
          />
          <Button buttonType="secondary" className={`ws-button`} onClick={onClose} label="Cancel" />
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default EditInfoModal;
