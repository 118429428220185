export enum BorderStyleEnum {
  /*** currently unsupported by VA ***/
  /* dotted = 'dotted',
  dashed = 'dashed',
  double = 'double',
  groove = 'groove',
  ridge = 'ridge',
  inset = 'inset',
  outset = 'outset',
  hidden = 'hidden', */

  solid = 'solid',
  none = 'none',
}

export class BorderDef {
  color = 'rgba(255, 255, 255, 0)';
  width = 0;
  style: BorderStyleEnum = BorderStyleEnum.none;
}
