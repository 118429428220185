import { Button, Checkbox } from 'flowbite-react';
import Slider from 'rc-slider';
import { FC, useState } from 'react';
import { AiOutlineCaretDown, AiOutlineCaretUp } from 'react-icons/ai';

import { AudioElement } from '../../../../../model/definitions/AudioElement';
import { VideoPanelDef } from '../../../../../model/definitions/VideoPanelDef';
import GridItem from '../../shared/GridItem';
import GridWrapper from '../../shared/GridWrapper';
import { getVolumeIcon } from '../../volumeIcon';

interface VoiceOverPropertiesProps {
  voiceOver: AudioElement | VideoPanelDef;
  changeVolume: (volume: number) => void;
  remove: () => void;
  isVideoOver?: boolean;
}
export const VoiceOverProperties: FC<VoiceOverPropertiesProps> = ({
  voiceOver,
  changeVolume,
  remove,
  isVideoOver,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <>
      <div
        className={`mb-2 subheader layer-header ${open ? 'layer-header-active' : ''}`}
        onClick={() => setOpen(!open)}
      >
        {open ? <AiOutlineCaretUp /> : <AiOutlineCaretDown />}
        {isVideoOver ? 'Video' : 'Voice'} over
      </div>
      {open && (
        <div>
          <GridWrapper className="volume-prop-grid">
            {!isVideoOver && (
              <>
                {' '}
                <GridItem
                  noBorderBg
                  label={'Sound:'}
                  //@ts-ignore
                  item={getVolumeIcon(Number(voiceOver.volume))}
                />
                <GridItem
                  noBorderBg
                  label={'Volume:'}
                  item={
                    <>
                      <Slider
                        style={{ width: '100%' }}
                        min={0}
                        max={100}
                        step={1}
                        //@ts-ignore
                        value={voiceOver.volume * 100}
                        onChange={(e) => {
                          e >= 0 &&
                            e <= 100 &&
                            changeVolume(
                              //@ts-ignore
                              (Number(e) / 100).toFixed(2),
                            );
                        }}
                      />
                      <div style={{ marginLeft: '0.5rem' }}>
                        {
                          //@ts-ignore
                          Math.round(voiceOver.volume * 100)
                        }
                      </div>
                    </>
                  }
                />
              </>
            )}
            <GridItem noBorderBg label={''} item={<Button onClick={remove}>Remove</Button>} />
            <GridItem
              noBorderBg
              label={'Enabled'}
              item={<Checkbox checked={voiceOver.enabled} />}
            />
          </GridWrapper>
        </div>
      )}
    </>
  );
};
