import { AxiosError } from 'axios';
import { VscError } from 'react-icons/vsc';

import styles from './Error.module.scss';

interface ErrorProps {
  error: AxiosError | null;
}
const Error = ({ error }: ErrorProps) => {
  const errorMSG = error?.response?.data as { error: string };
  return (
    <div className={'ws-grid-loader'}>
      <div className="flex flex-col items-center justify-center w-full pb-8">
        <div className={styles.iconWrapper}>
          <VscError />
        </div>
        <span className={styles.title}>{errorMSG?.error}</span>
        <span className={styles.description}>{error?.message}</span>
      </div>
    </div>
  );
};

export default Error;
