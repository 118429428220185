import { AxiosError } from 'axios';
import { QueryKey, useQuery, UseQueryResult } from 'react-query';

import { axiosInstance } from '../../../core/api/axiosInstance';
import { C9ProjectPreviewDTO } from '../../../model/DTO/C9ProjectPreviewDTO';
import { QueryInfoMetadata } from '../../../model/other/QueryInfoMetadata';

export const key: QueryKey = ['GET_ENTERPRISE_FONTS'];

const useGetEnterpriseFonts = ({
  page,
  type,
  size = 20,
  searchKey,
  sort,
}: {
  page: number;
  type: string;
  size?: number;
  searchKey?: string;
  sort: string;
}): UseQueryResult<QueryInfoMetadata<C9ProjectPreviewDTO>, AxiosError> => {
  const shouldFetchFonts = type === 'font';

  return useQuery(
    [...key, page, searchKey, sort],
    async () => {
      const { data } = await axiosInstance.get(`${type}/enterprise/all-with-variants`, {
        params: { page, size, searchKey, sort },
      });
      return data;
    },
    {
      enabled: shouldFetchFonts,
      staleTime: 1000 * 60, // 1min
    },
  );
};

export default useGetEnterpriseFonts;
