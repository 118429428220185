import React from 'react';

import Input from '../../atoms/input/Input';
import TextArea from '../../atoms/input/Textarea';

interface Props {
  editInput: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  formValues: any;
}
const AddTextElement: React.FC<Props> = ({ editInput, formValues }) => {
  return (
    <div className={'form-body'}>
      <Input
        name={'name'}
        label={'Name'}
        value={formValues.name}
        onChange={(e) => editInput(e)}
        required
        type={'text'}
      />
      <TextArea
        name={'description'}
        label={'Description'}
        value={formValues.description}
        onChange={(e) => editInput(e)}
        cols={2}
      />
      <Input
        autoFocus
        name={'value'}
        label={'Value'}
        value={formValues.value || ''}
        onChange={(e) => editInput(e)}
        required
        style={{ width: 'calc(100% - 1px)' }}
      />
    </div>
  );
};

export default AddTextElement;
