import { v4 } from 'uuid';

import { DataFrameDef } from './DataFrameDef';
import { TimeControlDef } from './TimeControlDef';
import { WeatherDataMapLayerSetup } from './WeatherDataMapLayerSetup';

export class WeatherDataMapLayer {
  id: string = v4();
  creationTime: number;
  zindex = 1;
  timeControls: Array<TimeControlDef>;
  dataFrames: Array<DataFrameDef>;
  unLoadedDataFrames: Array<DataFrameDef>;
  enabled = true;
  name: string;
  layerType: string;
  layerSetup: WeatherDataMapLayerSetup = new WeatherDataMapLayerSetup();
  dataFrameDensity: null | 'hourly';
  dataFramesDensity = 0;
  minZoom: number;
  maxZoom: number;
  baseMapUrl: string;
  enableInterpolation? = false;
  isContouring? = false;
  opacity = 1;
  /**Realtime indicator properties to add to model**/
  realtime = false;
  indicatorPriority = 1;
}
