import './style.scss';

import { useEffect, useRef, useState } from 'react';
import { AiFillCamera } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import Input from '../../../atoms/input/Input';
import { RolesEnum } from '../../../model/enums/RolesEnum';
import { RootState } from '../../../store/store';
import { useGetEnterprise } from '../../dataprovider/hooks/useGetEnterprise';
import Button from '../../marketplace-new/atoms/Button/Button';
import Loader from '../components/Loader';
import { useGetProfileInfo } from '../queries-NEW/useGetProfileInfo';
import { useUpdateProfileInfo } from '../queries-NEW/useUpdateProfileInfo';

export interface UpdateProfile {
  firstName: string;
  lastName: string;
  username: string;
  email: string;
}
const ProfileSettings = () => {
  const { userRoles: roles } = useSelector<RootState>((state) => state.user) as {
    userRoles: RolesEnum[];
  };
  const formRef = useRef<HTMLFormElement>(null);
  const navigate = useNavigate();
  const { data, isLoading } = useGetProfileInfo();
  const { data: enterprise } = useGetEnterprise();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    username: '',
    email: '',
  });
  const { mutate } = useUpdateProfileInfo();

  const onUpdate = (formData: UpdateProfile) =>
    mutate(formData, {
      onSuccess: async () => toast.success('Profile information updated successfully!'),
      onError: async () => toast.error('Error updating profile information'),
    });
  function redirectTo() {
    navigate('/workspace');
  }

  useEffect(() => {
    data && setFormData(data);
  }, [data]);

  return (
    <>
      <div className="ws-temp-title">
        <h1>User info</h1>
      </div>
      {isLoading && <Loader />}
      {data && (
        <div className="flex flex-col w-full self-center">
          <div className={'ws-user'}>
            <div className="profile-img">
              <input type={'file'} />
              <AiFillCamera />
            </div>
          </div>
          <div className="form-wrapper">
            <form ref={formRef} className="profile-form" onSubmit={() => onUpdate(formData)}>
              <Input
                value={formData.firstName}
                label="First name"
                onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
              />
              <Input
                value={formData.lastName}
                label="Last name"
                onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
              />
              <Input
                value={formData.username}
                label="Username"
                onChange={(e) => setFormData({ ...formData, username: e.target.value })}
              />
              <Input
                value={formData.email}
                label="Email"
                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              />
            </form>
            <label className="text-[#000000] text-[16px] opacity-50 my-2">Organization:</label>
            <span>{enterprise?.name}</span>
            <label className="text-[#000000] text-[16px] opacity-50 my-2">Roles:</label>
            <ul className="list-disc ml-5">
              {roles?.map((role: RolesEnum) => (
                <li className="mb-1" key={role}>
                  {role.substring(5).replaceAll('_', ' ')}
                </li>
              ))}
            </ul>

            <div className="buttons">
              <Button buttonType="secondary" onClick={redirectTo}>
                Cancel
              </Button>
              <Button buttonType="primary" onClick={() => onUpdate(formData)}>
                Edit profile
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProfileSettings;
