import { Select, ToggleSwitch } from 'flowbite-react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Input from '../../../atoms/input/Input';
import { ElementsEnum } from '../../../core/ui/enums/ElementsEnum';
import { usePropertyGridActive } from '../../../hooks/usePropertyGridActive';
import { PointDateDef } from '../../../model/definitions/PointDateDef';
import { SceneDef } from '../../../model/definitions/SceneDef';
import { ActiveDef, setPropertyGridActiveHash } from '../../../store/slices/active-slice';
import { updateDateLayer } from '../../../store/slices/project-slice';
import { selectActiveScene } from '../../../store/slices/selectors';
import { RootState } from '../../../store/store';
import { Panel } from './components/Panel';
import { PropertySection } from './components/PropertySection';
import { BoxStyle } from './panels/BoxStyle';
import { FontProperties } from './panels/FontProperties';
import { PositionControls } from './panels/PositionControls';
import { TimeControlsPanel } from './panels/TimeControlsPanel';
import { formats } from './panels/timeFrameIndicator/util';
import styles from './Properties.module.scss';
import GridItem from './shared/GridItem';
import GridWrapper from './shared/GridWrapper';

export const PointDateProperties = () => {
  const dispatch = useDispatch();
  const { activePoster, activeElement, activeScene, activeMap } = useSelector<RootState, ActiveDef>(
    (state) => state.active,
  );
  const activeSceneDef = useSelector<RootState, SceneDef | null>((state) =>
    selectActiveScene(state),
  );
  const dateLayer = activeSceneDef?.pointDates.find((point) => point.id === activeElement);
  const hasStandardFormat = Object.values(formats).includes(dateLayer?.dateFormat ?? 'no value ');
  const [isCustom, setIsCustom] = useState<boolean>(!hasStandardFormat);
  const [format, setFormat] = useState(dateLayer?.dateFormat);
  const { isOpened, lastFocused } = usePropertyGridActive([
    'textAnimation.active',
    'textAnimation.speed',
    'value',
  ]);
  useEffect(() => {
    if (format && !Object.values(formats).includes(format)) {
      setIsCustom(true);
    }
  }, [format]);
  const posterDateLayer = activeSceneDef?.weatherPosters
    .find((poster) => poster.id === activePoster)
    ?.pointDates.find((pointDt) => pointDt.id === activeElement);
  const geoPosterDateLayer = activeSceneDef?.mapPanels
    .find((map) => map.id === activeMap)
    ?.geoPosters.find((poster) => poster.id === activePoster)
    ?.pointDates.find((pointDt) => pointDt.id === activeElement);
  function onFocus(path: Leaves<PointDateDef>) {
    dispatch(setPropertyGridActiveHash({ activeElement, focusedEl: path }));
  }
  function onDateLayerChange(propertyPath: Leaves<PointDateDef>, e: boolean | string | number) {
    onFocus(propertyPath);
    dispatch(
      updateDateLayer({
        newValue: e,
        activeScene,
        elementId: activeElement,
        propertyPath: propertyPath,
        parentId: activePoster,
        mapId: activeMap,
      }),
    );
  }
  const handleFormatChange = (format: string, custom: boolean) => {
    onFocus('dateFormat');
    if (format === 'CUSTOM') {
      !isCustom && setIsCustom(true);
      dispatch(
        updateDateLayer({
          newValue: layer?.dateFormat,
          activeScene,
          elementId: activeElement,
          propertyPath: 'dateFormat',
          parentId: activePoster,
          mapId: activeMap,
        }),
      );
      setFormat(layer?.dateFormat);
    } else {
      format && Object.values(formats).includes(format) && !custom && setIsCustom(false);
      const searchString = 'dd-';
      const replaceString = 'ddd-';
      if (format.includes(searchString)) {
        format = format.replace(searchString, replaceString);
      }
      dispatch(
        updateDateLayer({
          newValue: format,
          activeScene,
          elementId: activeElement,
          propertyPath: 'dateFormat',
          parentId: activePoster,
          mapId: activeMap,
        }),
      );
      setFormat(format);
    }
  };
  const formatOptions = () => {
    const optionsArray = [];
    for (const key in formats) {
      optionsArray.push(
        // @ts-ignore
        <option key={key} value={formats[key as keyof formats]}>
          {key}
        </option>,
      );
    }
    return optionsArray;
  };
  const layer = dateLayer || posterDateLayer || geoPosterDateLayer;
  return (
    <Panel>
      <PropertySection label={'text properties'} isOpened={isOpened}>
        <div className="prop-wrapper">
          <GridWrapper>
            <GridItem
              label={'Name:'}
              item={
                <Input
                  style={{ padding: '0' }}
                  type={'text'}
                  value={layer?.name}
                  autoFocus={lastFocused === 'name'}
                  onChange={(e) => {
                    onDateLayerChange('name', e.target.value);
                  }}
                  className={styles.inputWrap}
                  onFocus={() => onFocus('name')}
                />
              }
            />
            <GridItem
              label={'Description:'}
              item={
                <Input
                  style={{ padding: '0' }}
                  type={'text'}
                  value={layer?.description}
                  autoFocus={lastFocused === 'description'}
                  onChange={(e) => {
                    onDateLayerChange('description', e.target.value);
                  }}
                  className={styles.inputWrap}
                  onFocus={() => onFocus('description')}
                />
              }
            />
            <GridItem
              label="Format:"
              item={
                <Select
                  value={
                    Object.values(formats).includes(layer?.dateFormat ?? ' ') && !isCustom
                      ? layer?.dateFormat
                      : 'CUSTOM'
                  }
                  onChange={(e) => handleFormatChange(e.target.value, !isCustom)}
                >
                  {formatOptions()}
                </Select>
              }
            />
            {isCustom && (
              <GridItem
                label="Custom format:"
                item={
                  <input
                    value={layer?.dateFormat}
                    onChange={(e) => handleFormatChange(e.target.value, isCustom)}
                  />
                }
              />
            )}
            <GridItem
              label="Relative value:"
              noBorderBg
              item={
                <ToggleSwitch
                  label={''}
                  checked={layer?.relativeFormat ?? false}
                  onChange={(e) => onDateLayerChange('relativeFormat', e)}
                />
              }
            />
          </GridWrapper>
        </div>
      </PropertySection>
      <>
        {layer && <FontProperties layer={layer} layerType={'pointDates'} poster={activePoster} />}
        {dateLayer?.positionControl && !activePoster && (
          <PositionControls
            position={dateLayer?.positionControl}
            layer={'pointDates'}
            poster={activePoster}
          />
        )}
        {dateLayer?.timeControls && !activePoster && (
          <TimeControlsPanel
            timeControls={dateLayer?.timeControls}
            layer={ElementsEnum.POINT_DATE}
          />
        )}
        {layer && <BoxStyle box={layer?.boxDef} elementType={ElementsEnum.POINT_DATE} />}
      </>
    </Panel>
  );
};
