import { Button } from 'flowbite-react';
import { useContext, useEffect, useRef, useState } from 'react';
import { BsRecord, BsStop } from 'react-icons/bs';

import { TimeControlDef } from '../../model/definitions/TimeControlDef';
import PlayerContext from '../../pages/playground/playerContext/PlayerContext';

interface VideoRecorderProps {
  onRecorded: (e: Blob, t: TimeControlDef) => void;
  onStartRecording: () => void;
}
const videoTime = new TimeControlDef(0, 0);
const VideoRecorder = ({ onRecorded, onStartRecording }: VideoRecorderProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const streamRef = useRef<MediaStream | null>(null);
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const { time } = useContext(PlayerContext);

  useEffect(() => {
    const constraints: MediaStreamConstraints = { video: true, audio: true };
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream: MediaStream) => {
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          streamRef.current = stream;
        }
      })
      .catch((error: Error) => {
        console.error('Error accessing camera:', error);
      });

    return () => {
      // Cleanup function to release camera when component is unmounted
      if (streamRef.current) {
        const tracks = streamRef.current.getTracks();
        tracks.forEach((track) => {
          track.stop();
        });
      }
    };
  }, []);

  const startRecording = () => {
    const stream = streamRef.current;
    if (stream) {
      mediaRecorderRef.current = new MediaRecorder(stream);
      mediaRecorderRef.current.ondataavailable = handleDataAvailable;
      mediaRecorderRef.current.start();
      setIsRecording(true);
      onStartRecording();
      console.log({ start: time });
      videoTime.startMS = time;
    }
  };

  const stopRecording = () => {
    mediaRecorderRef.current?.stop();
    setIsRecording(false);
    videoTime.endMS = time;
  };

  const handleDataAvailable = (event: BlobEvent) => {
    if (event.data.size > 0) {
      const blob = new Blob([event.data], { type: 'video/webm' });
      onRecorded(blob, videoTime);
      console.log(videoTime);
      /* const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.style.display = 'none';
      a.href = url;
      a.download = 'recording.webm';
      a.click();
      URL.revokeObjectURL(url); */
    }
  };

  const handleStartStopButtonClick = () => {
    if (isRecording) {
      stopRecording();
    } else {
      startRecording();
    }
  };

  return (
    <div className={'relative flex h-full w-full'}>
      <video ref={videoRef} width="auto" height="auto" autoPlay className={'flex-grow-1'} />
      <div className={'absolute flex bottom-[0rem]'}>
        <Button onClick={handleStartStopButtonClick}>
          {isRecording ? (
            <>
              <BsStop /> Stop Recording
            </>
          ) : (
            <>
              <BsRecord /> Start Recording
            </>
          )}
        </Button>
      </div>
    </div>
  );
};

export default VideoRecorder;
