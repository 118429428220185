import './style.scss';

import { Button, Divider, Empty, Typography } from 'antd';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ForecastWDElementDef } from '../../../model/definitions/ForecastWDElementDef';
import { ObservedWDElementDef } from '../../../model/definitions/ObservedWDElementDef';
import { ValueTypeEnum } from '../../../model/enums/ValueTypeEnum';
import { ActiveDef, addToConfirmedBias } from '../../../store/slices/active-slice';
import { RootState } from '../../../store/store';
import { ForecastRow } from './ForecastRow';
import { ObservedRow } from './ObservedRow';
import { SaveModal } from './SaveModal';

export const splitStringOnCapital = (string: string) => {
  const parts = string.split(/(?=[A-Z])/);
  return parts.join(' ');
};
const { Title, Text } = Typography;
export const BiasFilter = () => {
  const dispatch = useDispatch();
  const { previewSize, biasedValues, biasParam, activeMap } = useSelector<RootState>(
    (state) => state.active,
  ) as ActiveDef;
  const [confirmed, setConfirmed] = useState<Array<string>>([]);
  const [saveModal, setSaveModal] = useState(false);
  const addToFilter = () => {
    dispatch(addToConfirmedBias());
  };
  const confirmation = (id: string) => {
    const array = [...confirmed];
    const index = array.indexOf(id);
    if (index !== -1) {
      array.splice(index, 1);
    } else {
      array.push(id);
    }

    setConfirmed(array);
  };
  function isObserved(obj: any): obj is ObservedWDElementDef {
    return 'observedWDSource' in obj;
  }
  function isForecast(obj: any): obj is ForecastWDElementDef {
    return 'forecastWDSource' in obj;
  }

  const renderObservedData = biasedValues?.map((item) => {
    if (
      (isObserved(item) && biasParam === item.observedWDSource.parameterType) ||
      (isObserved(item) &&
        biasParam === 'icon' &&
        item.observedWDSource.valueType === ValueTypeEnum.IMAGE)
    ) {
      const value =
        item.observedWDSource.valueType === ValueTypeEnum.NUMERICAL
          ? item.observedWDSource.value.find(
              (val: { value: number; unit: string; originalValue: number }) =>
                val.unit === item.observedWDSource.unitOfMeasurement,
            )
          : item.observedWDSource.value;
      return (
        <ObservedRow
          key={item.id}
          item={item}
          confirmed={confirmed.includes(item.id)}
          setConfirmed={confirmation}
          originalValue={item.observedWDSource.originalValue}
          biasValue={value.value}
        />
      );
    }
  });
  const renderForecastData = biasedValues?.map((item) => {
    if (
      (isForecast(item) && biasParam === item.forecastWDSource.parameterType) ||
      (isForecast(item) &&
        biasParam === 'icon' &&
        item.forecastWDSource.valueType === ValueTypeEnum.IMAGE)
    ) {
      const value =
        item.forecastWDSource.valueType === ValueTypeEnum.NUMERICAL
          ? item.forecastWDSource.value.find(
              (val: { value: number; unit: string; originalValue: number }) =>
                val.unit === item.forecastWDSource.unitOfMeasurement,
            )
          : item.forecastWDSource.value;
      return (
        <ForecastRow
          key={item.id}
          item={item}
          confirmed={confirmed.includes(item.id)}
          setConfirmed={confirmation}
          originalValue={
            item.valueType === ValueTypeEnum.NUMERICAL ? value.originalValue : item.originalValue
          }
          biasValue={value.value}
        />
      );
    }
  });
  return (
    <div
      key={activeMap}
      className={'bias-filter mx-8 p-8 overflow-y-auto max-h-full'}
      style={{ height: previewSize }}
    >
      <Title level={3}>Bias filter creation</Title>
      <div className={'flex gap-2 items-end'}>
        <Text strong>Parameter: {biasParam && splitStringOnCapital(biasParam)}</Text>
      </div>
      <div className={'grid grid-cols-[25%_15%_13%_13%_5%_5%_5%] gap-5 font-bold text-white mt-5'}>
        <span>Location</span>
        <span className={'text-center'}>Original value</span>
        <span>Biased value</span>
        <span>New bias</span>
        <span className={'text-center'}>Unit</span>
      </div>
      <div className={'min-h-[100px]'}>
        {biasedValues.length ? (
          [...renderObservedData, ...renderForecastData]
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'Nothing selected'} />
        )}
      </div>
      <Divider className={'my-10'} />
      <div className={'flex gap-5'}>
        <Button onClick={addToFilter}>Next param</Button>
        <Button danger onClick={() => setSaveModal(true)}>
          Create filter
        </Button>
      </div>
      <SaveModal
        open={saveModal}
        onCancel={() => setSaveModal(false)}
        onClose={() => setSaveModal(false)}
      />
    </div>
  );
};
