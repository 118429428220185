import { ScreenshotData } from '../../helpers/screenshotElement';
import { C9ProjectDef } from '../../model/definitions/C9ProjectDef';
import {
  C9ProjectCreateRequestDTO,
  C9ProjectTemplateCreateRequestDTO,
} from '../../model/DTO/C9ProjectCreateRequestDTO';
import { QueryInfo } from '../../model/other/QueryInfo';
import { axiosInstance } from './axiosInstance';
import { buildQueryInfo } from './buildQueryInfo';

const getMyProjectsQueryInfo = async (query: QueryInfo) => {
  return buildQueryInfo(query, 'dashboard/my-projects');
};

const cloneProject = async (id: string) => {
  const res = await axiosInstance.post(`project/dashboard-clone/${id}`);
  return res.data;
};

const editProject = async (
  id: string,
  versionId: string,
  name: any,
  description: any,
  type: string,
) => {
  const res = await axiosInstance.put(`basic-update/${type}`, {
    id: id,
    versionId: versionId,
    newName: name,
    newDescription: description,
  });
  return res.data;
};

const editTemplate = async (
  id: string,
  versionId: string,
  name: any,
  description: any,
  type: string,
) => {
  const res = await axiosInstance.put(`basic-update/${type}_TEMPLATE`, {
    id: id,
    versionId: versionId,
    newName: name,
    newDescription: description,
  });
  return res.data;
};
const createProjectFromTemplate = async (
  projectData: C9ProjectTemplateCreateRequestDTO,
): Promise<C9ProjectDef> => {
  const res = await axiosInstance.post<C9ProjectDef>(`studio/new-project-from-project-template`, {
    name: projectData.name,
    numberOfScenes: projectData.numberOfScenes,
    sceneTransition: projectData.sceneTransition,
    projectTemplateId: projectData.templateId,
  });
  return res.data;
};

const getNewProject = async (projectData: C9ProjectCreateRequestDTO): Promise<C9ProjectDef> => {
  const res = await axiosInstance.post<C9ProjectDef>('project', projectData);
  return res.data;
};

const getProjectById = async (id: string): Promise<C9ProjectDef> => {
  const res = await axiosInstance.get(`studio/project?projectId=${id}`);
  return res.data;
};

const saveProject = async (projectDef: C9ProjectDef): Promise<C9ProjectDef> => {
  if (projectDef.id === 'mock') {
    console.log('Mock project JSON:');
    console.log(JSON.stringify(projectDef));
    return projectDef;
  }
  const res = await axiosInstance.put<C9ProjectDef>(`project`, projectDef);
  return res.data;
};

const getProjectDetails = async (id: string): Promise<C9ProjectDef> => {
  const res = await axiosInstance.get(`project/time-referenced/${id}`);
  return res.data;
};

const getProjectVersion = async (projectVersionID: string): Promise<C9ProjectDef> => {
  const res = await axiosInstance.get(`project/${projectVersionID}`);
  return res.data;
};

const deleteProject = async (id: string) => {
  const res = await axiosInstance.delete(`project/${id}`);
  return res.data;
};

const getPaginatedProjects = async (pageData: {
  pageIndex: number;
  pageSize: number;
  searchKey: string;
}) => {
  const { pageIndex, pageSize, searchKey } = pageData;
  const res = await axiosInstance.get(
    `dashboard/my-projects?page=${pageIndex ? pageIndex : 0}&size=${
      pageSize ? pageSize : 20
    }&searchKey=${searchKey ? searchKey : ''}`,
  );
  return res.data;
};

const setProjectThumbnail = async (data: {
  thumbnail: {
    id: string;
    url: string;
    blob: Blob | null;
  }[];
  versionId: string;
}): Promise<C9ProjectDef> => {
  const { thumbnail, versionId } = data;

  const fData = new FormData();
  fData.append('versionId', versionId);
  thumbnail.forEach((t, idx) => {
    fData.append('files', t.blob!, `file${idx}`);
  });

  const res = await axiosInstance.post<C9ProjectDef>(`project/setThumbnail`, fData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return res.data;
};

const setSceneThumbnail = async (data: {
  thumbnail: ScreenshotData[];
  projectVersionId: string;
  sceneId: string;
}): Promise<C9ProjectDef> => {
  const { thumbnail, projectVersionId, sceneId } = data;

  const fData = new FormData();
  fData.append('projectVersionId', projectVersionId);
  fData.append('sceneId', sceneId);
  thumbnail.forEach((t, idx) => {
    fData.append('files', t.blob!, `file${idx}`);
  });

  const res = await axiosInstance.post<C9ProjectDef>(`scene/setThumbnail`, fData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return res.data;
};

export {
  cloneProject,
  createProjectFromTemplate,
  deleteProject,
  editProject,
  editTemplate,
  getMyProjectsQueryInfo,
  getNewProject,
  getPaginatedProjects,
  getProjectById,
  getProjectDetails,
  getProjectVersion,
  saveProject,
  setProjectThumbnail,
  setSceneThumbnail,
};
