import { useEffect, useState } from 'react';

import Input from '../../../atoms/input/Input';
import TextArea from '../../../atoms/input/Textarea';
import LoadingIndicator from '../../../atoms/loadingIndicator/LoadingIndicator';
import { useGetDateTime } from '../../../core/api/observedData/useGetDateTime';
import { useGetStations } from '../../../core/api/observedData/useGetStations';
import { capitalize } from '../../../helpers/stringUtils';
import { useDebounce } from '../../../hooks/useDebounce';
import { ObservedTimePicker } from './ObservedTimePicker';

interface FirstStepProps {
  basic: {
    name: string;
    description?: string;
    groupDate: boolean;
  };
  station: string | undefined;
  setStation: (e: string | undefined) => void;
  setBasic: (e: { name: string; description?: string; groupDate: boolean }) => void;
  timestamp: number;
  setTimestamp: (e: number) => void;
}
export const ObservedFirstStep = ({
  basic,
  setBasic,
  timestamp,
  setTimestamp,
  station,
  setStation,
}: FirstStepProps) => {
  const [searchKey, setSearchKey] = useState<string | undefined>(station);
  const debounceSearch = useDebounce(searchKey);
  const { data: stations, isLoading } = useGetStations(debounceSearch?.toUpperCase());
  // const [bias, isBias] = useState<boolean>(false);
  const { data: dateTime, isLoading: loadingDates } = useGetDateTime(station);
  useEffect(() => {
    if (station && searchKey?.toUpperCase() !== station) {
      setStation(undefined);
      setSearchKey('');
      setTimestamp(0);
    }
  }, [searchKey, setStation, setTimestamp, station]);
  const handleStation = (val: string, loc: string) => {
    setSearchKey(val);
    if (loc) setBasic({ ...basic, name: capitalize(loc?.split('/')[0]) });
    else setBasic({ ...basic, name: capitalize(loc) });
    setStation(val);
  };
  const getBorders = () => {
    if (searchKey && station !== searchKey.toUpperCase())
      return { borderBottomLeftRadius: 0, borderBottomRightRadius: 0 };
  };
  return (
    <div>
      <div className="relative location-wrapper">
        <Input
          label={'Select Station'}
          required
          value={searchKey}
          onChange={(e) => setSearchKey(e.target.value)}
          style={getBorders()}
        />
        {searchKey && station !== searchKey.toUpperCase() && (
          <div className={'search-result items-center'}>
            {isLoading ? (
              <div className={'flex w-1/2 loading'}>
                <div className={'flex items-center mr-3'}>
                  <LoadingIndicator />
                </div>
                Searching
              </div>
            ) : stations && stations.length && station !== searchKey.toUpperCase() ? (
              stations?.map((stat) => (
                <div
                  key={stat.value}
                  onClick={() => handleStation(stat.value, stat.fullName)}
                  className={'result'}
                >
                  {stat.fullName} {stat.value}
                </div>
              ))
            ) : (
              <div className={'flex justify-center items-center'}>no results</div>
            )}
          </div>
        )}
      </div>
      <Input
        onChange={(e) => setBasic({ ...basic, name: e.target.value })}
        value={basic.name}
        label={'Name'}
        required
      />
      <TextArea
        label={'Description'}
        value={basic.description}
        onChange={(e) => setBasic({ ...basic, description: e.target.value })}
      />

      {loadingDates ? (
        <div className={'w-full mt-3 flex justify-center items-center'}>
          <LoadingIndicator />
          Getting available dates<span className={'loading'}></span>
        </div>
      ) : (
        dateTime && (
          <ObservedTimePicker
            availableTimes={dateTime}
            onDateSelect={setTimestamp}
            currentSelection={timestamp}
          />
        )
      )}
    </div>
  );
};
