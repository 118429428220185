import { useFontLoader } from '../../core/api/useLoadFont';

function TextWithFontFamily({ node, element }: any) {
  useFontLoader(node.fontFamily);

  return (
    <span
      style={{
        fontFamily: `${node.fontFamily} ${node.fontType}`,
        textTransform: node.textTransform,
      }}
    >
      {element}
    </span>
  );
}

export default TextWithFontFamily;
