import './style.scss';

import { useContext, useState } from 'react';
import { Zoom } from 'react-awesome-reveal';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import { Triangle } from 'react-loader-spinner';
import ReactPaginate from 'react-paginate';

import { useGetProfileInfo } from '../../../dashboard/queries-NEW/useGetProfileInfo';
import { useGetPublishedItems } from '../../hooks/useGetPublishedItems';
import { AntContent } from '../../layout/Layout';
import MarketplaceContext from '../../marketplaceContext';
import { MarketplaceItem } from '../../model/interfaces/MarketplaceItem';
import ItemCard from '../../molecules/item-card/ItemCard';

const MyProfile = () => {
  const { data } = useGetProfileInfo();
  const { admin } = useContext(MarketplaceContext);
  const [pages, setPages] = useState<{ current: number; last: number }>({
    current: 0,
    last: 0,
  });
  const { data: published, isLoading } = useGetPublishedItems(
    admin,
    pages.current,
    20,
    '',
    data?.email,
  );

  const renderItems = () => {
    return published?.content.map((item: MarketplaceItem, index: number) => (
      <ItemCard item={item} key={index} isAdmin={admin} />
    ));
  };
  return (
    <div className="my-profile">
      <div className="profile-heading">
        <div className="content">
          <div className={'user'}></div>
          <div className="flex flex-col ml-4">
            <span className="title"> {data?.firstName + ' ' + data?.lastName}</span>
            <span className="description">Summary</span>
          </div>
        </div>
      </div>

      <AntContent>
        <div className={'flex justify-center items-center mb-7 flex-col'}>
          {isLoading ? (
            <Triangle
              height="80"
              width="80"
              color="#3A36DB"
              ariaLabel="triangle-loading"
              wrapperStyle={{}}
              visible={true}
            />
          ) : (
            <div className={'grid grid-cols-4 gap-4 min-h-screen w-full item-card-wrapper'}>
              <Zoom cascade damping={0.2} triggerOnce duration={300}>
                {renderItems()}
              </Zoom>
            </div>
          )}
        </div>
        <ReactPaginate
          className={'pagination'}
          pageLinkClassName={'pagination-item'}
          activeLinkClassName={'pagination-active'}
          previousLinkClassName={'pagination-prev-next'}
          nextLinkClassName={'pagination-prev-next'}
          breakLabel="..."
          nextLabel={<BsChevronRight />}
          onPageChange={(e) => {
            setPages({ ...pages, current: e.selected });
          }}
          pageRangeDisplayed={5}
          pageCount={pages.last}
          previousLabel={<BsChevronLeft />}
        />
      </AntContent>
    </div>
  );
};

export default MyProfile;
