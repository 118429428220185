import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';

import { axiosInstance } from '../../../../core/api/axiosInstance';
import { fitToCanvasHelper } from '../../../../helpers/fitToCanvas';
import { getMaxZindex } from '../../../../helpers/timelineUtil';
import { C9ProjectDef } from '../../../../model/definitions/C9ProjectDef';
import { ImagePanelDef } from '../../../../model/definitions/ImagePanelDef';
import { PositionControlDef } from '../../../../model/definitions/PositionControlDef';
import { TimeControlDef } from '../../../../model/definitions/TimeControlDef';
import { VideoPanelDefTemplate } from '../../../../model/definitions/VideoPanelDefTemplate';
import { setElement } from '../../../../store/slices/active-slice';
import { addImageLayer } from '../../../../store/slices/project-slice';

export const useGetImageFromRepo = () => {
  const dispatch = useDispatch();
  return useMutation(
    async ({
      id,
      ratio,
      activeScene,
      project,
      duration,
      time,
      previewSize,
    }: {
      id: string;
      ratio: [number, number];
      activeScene: string;
      project: C9ProjectDef;
      duration: number;
      time: number;
      previewSize: number;
    }): Promise<VideoPanelDefTemplate> => {
      const { data } = await axiosInstance.get(`multimedia/IMAGE/current-template/${id}`);
      const imageLayer = new ImagePanelDef();
      imageLayer.imagePanelDefTemplate = data;
      imageLayer.name = data.name;
      const { width, height, x, y } = fitToCanvasHelper(data, ratio, previewSize);
      imageLayer.positionControl = new PositionControlDef(width, height);
      imageLayer.positionControl.x = x;
      imageLayer.positionControl.y = y;
      imageLayer.positionControl.zindex = getMaxZindex(activeScene as string, project);
      imageLayer.templateId = data.id;
      imageLayer.templateVersionId = data.versionId;
      imageLayer.timeControls = [new TimeControlDef(time, duration)];
      imageLayer.enabled = true;
      dispatch(addImageLayer({ imageLayer, activeScene }));
      dispatch(
        setElement({
          activeElement: imageLayer.id,
          activeProp: 'imagePanels',
        }),
      );
      return data;
    },
  );
};
