import { Map } from 'ol';

const CACHE: { [key: string]: Map } = {};

export class MapsRenderCache {
  static get(key: string): Map | null {
    if (Object.hasOwn(CACHE, key)) {
      return CACHE[key];
    }
    return null;
  }
  static set(key: string, value: Map) {
    CACHE[key] = value;
  }
  static remove(key: string) {
    delete CACHE[key];
  }
  static clear() {
    for (const key in CACHE) {
      if (Object.hasOwn(CACHE, key)) {
        const cachedMap = CACHE[key];
        cachedMap.getAllLayers().forEach((l) => {
          /**dispose all layers to free up resources of webgl contexts */
          l.dispose();
        });
        delete CACHE[key];
      }
    }
  }
}
