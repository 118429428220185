import { useQuery } from 'react-query';

import { axiosInstance } from '../../../core/api/axiosInstance';

export const key = ['MARKETPLACE_PUBLISHED'];
export const useGetPublishedItems = (
  admin: boolean,
  page: number,
  size = 20,
  templateType?: string,
  publishedBy?: string,
  searchKey?: string,
  sortByField?: string,
  sortAscDesc?: string,
) => {
  return useQuery(
    key,
    async () => {
      const res = await axiosInstance.get('marketplace/published-marketplace-items', {
        params: {
          page,
          size,
          templateType,
          publishedBy,
          searchKey,
          sortByField,
          sortAscDesc,
        },
      });
      return res.data;
    },
    {
      enabled: admin,
    },
  );
};
