import { useQuery } from 'react-query';

import { axiosInstance } from '../../../../core/api/axiosInstance';

export const key = ['SHOPPING_CART_ITEMS'];
export const useGetShoppingCartItems = (authenticated: boolean) => {
  return useQuery(
    [...key],
    async () => {
      const res = await axiosInstance.get('/order-by-user');
      return res.data;
    },
    {
      enabled: authenticated,
    },
  );
};
