import { DataFrameDef } from './DataFrameDef';
import { GribSourceDef } from './GribSourceDef';
import { PickerDef } from './PickerDef';
import { TimeControlDef } from './TimeControlDef';
import { WDLocation } from './WDLocation';
import { WeatherDataMapLayer } from './WeatherDataMapLayer';

export class GribMapLayer extends WeatherDataMapLayer {
  gribSource = new GribSourceDef();
  constructor(
    dataProductId: string | null,
    name: string,
    source: PickerDef,
    parameterType: PickerDef,
    model: PickerDef,
    location: WDLocation,
    timeControls: TimeControlDef,
    dataFrames: Array<DataFrameDef>,
    zIndex: number,
  ) {
    super();
    this.name = name;
    this.gribSource.gribSource = source;
    this.gribSource.dataProductId = dataProductId;
    this.gribSource.parameterType = parameterType;
    this.gribSource.location = location;
    this.gribSource.gribModel = model;
    this.timeControls = [timeControls];
    this.dataFrames = dataFrames;
    this.zindex = zIndex;
  }
}
