import { cloneDeep } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { AiOutlineCopy, AiOutlinePlus } from 'react-icons/ai';
import { BsTrash } from 'react-icons/bs';
import { ImPaste } from 'react-icons/im';
import { useDispatch, useSelector } from 'react-redux';
import { v4 } from 'uuid';

import { MAX_FULLSCREEN_HEIGHT } from '../model/constants/constants';
import { AnimationPanelDef } from '../model/definitions/AnimationPanelDef';
import { ForecastWDElementDef } from '../model/definitions/ForecastWDElementDef';
import { ImagePanelDef } from '../model/definitions/ImagePanelDef';
import { MapPanelDef } from '../model/definitions/MapPanelDef';
import { ObservedWDElementDef } from '../model/definitions/ObservedWDElementDef';
import { SceneDef } from '../model/definitions/SceneDef';
import { TextPanelDef } from '../model/definitions/TextPanelDef';
import { VideoPanelDef } from '../model/definitions/VideoPanelDef';
import { WeatherPosterDef } from '../model/definitions/WeatherPosterDef';
import { transformAbsoluteToPercent } from '../molecules/canvasElements/utils';
import { ActiveDef, setClipboard, setElement } from '../store/slices/active-slice';
import {
  addAnimationLayer,
  addForecastElements,
  addImageLayer,
  addMapLayer,
  addOWDLayer,
  addPosterLayer,
  addTextLayer,
  addVideoLayer,
  updatePanel,
  updatePosterPanel,
} from '../store/slices/project-slice';
import { RootState } from '../store/store';
import { useScaleFactor } from './useScaleFactor';

export const useContextMenu = (isFullScreen: boolean, addElement?: () => void) => {
  const dispatch = useDispatch();
  const { activeScene, clipboard, activeAspectRatio } = useSelector<RootState>(
    (state) => state.active,
  ) as ActiveDef;
  const scaleFactor = useScaleFactor(isFullScreen);
  const scenes = useSelector<RootState, SceneDef[]>(
    (state) => state.project.present.project.sceneDefs,
  );
  const [xPos, setXPos] = useState(0);
  const [yPos, setYPos] = useState(0);
  const [newXPos, setNewXPos] = useState(0);
  const [newYPos, setNewYPos] = useState(0);
  const [showMenu, setShowMenu] = useState(false);
  const [elementProps, setElementProps] = useState<
    | {
        parentId: string | undefined;
        id: string;
        type:
          | 'imagePanels'
          | 'videoPanels'
          | 'textPanels'
          | 'mapPanels'
          | 'observedWDElements'
          | 'forecastWDElements'
          | 'weatherPosters';
        parenttype: 'weatherPosters';
      }
    | undefined
  >(undefined);
  const isDescendant = function (parent: any, child: any) {
    let node = child?.parentNode;
    while (node) {
      if (node === parent || child === parent) {
        return true;
      }
      node = node.parentNode;
    }
    return false;
  };
  const handleContextMenu = useCallback(
    (e: MouseEvent) => {
      const canvasCont = document.getElementById('canvas-container');
      if (!canvasCont) return;
      if (isDescendant(canvasCont, e.target) && e.type === 'contextmenu') {
        e.preventDefault();
        e.stopPropagation();
        const element = e.target as HTMLDivElement;
        const target = element?.closest('div[data-type]') as HTMLDivElement;
        const CONTEXT_MENU_WIDTH = 130;
        const CONTEXT_MENU_HEIGHT = 126;
        const tempX = e.pageX - canvasCont!.getBoundingClientRect()?.left;
        const tempY = e.pageY - canvasCont!.getBoundingClientRect()?.top;
        setXPos(
          tempX + CONTEXT_MENU_WIDTH > canvasCont.offsetWidth * scaleFactor
            ? tempX / scaleFactor - CONTEXT_MENU_WIDTH / scaleFactor
            : tempX / scaleFactor,
        );
        setYPos(
          tempY + CONTEXT_MENU_HEIGHT > canvasCont.offsetHeight * scaleFactor
            ? tempY / scaleFactor - CONTEXT_MENU_HEIGHT / scaleFactor
            : tempY / scaleFactor,
        );
        setNewXPos(tempX / scaleFactor);
        setNewYPos(tempY / scaleFactor);
        if (target) {
          // we need data-id instead of id on ElementContainer because video screenshot won't work since it uses element id
          //@ts-ignore
          const id = target.id || target.attributes['data-id']?.nodeValue;
          //@ts-ignore
          const type = target.attributes['data-type']?.nodeValue;
          //@ts-ignore
          const parentId = target.attributes['data-parent']?.nodeValue;
          //@ts-ignore
          const parenttype = target.attributes['data-parenttype']?.nodeValue;
          setElementProps({ parentId, id, type, parenttype });
        } else {
          setElementProps(undefined);
        }
        setShowMenu(true);
      }
    },
    [setXPos, setYPos, scaleFactor],
  );

  const handleClick = useCallback(() => {
    showMenu && setShowMenu(false);
  }, [showMenu]);

  useEffect(() => {
    document.addEventListener('click', handleClick);
    document.addEventListener('contextmenu', handleContextMenu);
    return () => {
      document.addEventListener('click', handleClick);
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  });
  const onCopy = () => {
    let elements = [] as
      | TextPanelDef[]
      | VideoPanelDef[]
      | ImagePanelDef[]
      | MapPanelDef[]
      | ObservedWDElementDef[]
      | ForecastWDElementDef[]
      | AnimationPanelDef[]
      | undefined;
    if (elementProps?.type || elementProps?.parenttype) {
      // @ts-ignore
      elements = scenes.find((scene) => scene.id === activeScene)?.[
        elementProps.parenttype ? elementProps.parenttype : elementProps.type
      ];
      const id = elementProps.parentId !== undefined ? elementProps.parentId : elementProps.id;
      //@ts-ignore
      const element = elements?.find(
        (
          elem:
            | TextPanelDef
            | VideoPanelDef
            | ImagePanelDef
            | MapPanelDef
            | WeatherPosterDef
            | ObservedWDElementDef
            | ForecastWDElementDef
            | AnimationPanelDef,
        ) => elem.id === id,
      );
      const copied = cloneDeep(element);
      if (!elementProps.parentId)
        dispatch(
          setClipboard({
            type: elementProps.type,
            element: { ...copied, id: v4(), name: `Copy of ${copied.name}` },
          }),
        );
      else
        dispatch(
          setClipboard({
            type: elementProps.parenttype,
            element: { ...copied, id: v4(), name: `Copy of ${copied.name}` },
          }),
        );
    }
  };
  const onPaste = (e: React.MouseEvent) => {
    const id = v4();
    e.stopPropagation();
    if (clipboard?.element)
      switch (clipboard?.type) {
        case 'textPanels':
          dispatch(
            addTextLayer({
              textLayer: {
                ...clipboard.element,
                id: id,
                positionControl: {
                  ...clipboard.element.positionControl,
                  x: transformAbsoluteToPercent(
                    newXPos,
                    activeAspectRatio,
                    'width',
                    MAX_FULLSCREEN_HEIGHT,
                  ),
                  y: transformAbsoluteToPercent(
                    newYPos,
                    activeAspectRatio,
                    'height',
                    MAX_FULLSCREEN_HEIGHT,
                  ),
                },
              } as TextPanelDef,
              activeScene,
            }),
          );
          break;
        case 'observedWDElements':
          dispatch(
            addOWDLayer({
              owdLayer: {
                ...clipboard.element,
                id: id,
                positionControl: {
                  ...clipboard.element.positionControl,
                  x: transformAbsoluteToPercent(
                    newXPos,
                    activeAspectRatio,
                    'width',
                    MAX_FULLSCREEN_HEIGHT,
                  ),
                  y: transformAbsoluteToPercent(
                    newYPos,
                    activeAspectRatio,
                    'height',
                    MAX_FULLSCREEN_HEIGHT,
                  ),
                },
              } as ObservedWDElementDef,
              activeScene,
            }),
          );
          break;
        case 'forecastWDElements':
          dispatch(
            addForecastElements({
              forecastElements: {
                ...clipboard.element,
                id: id,
                positionControl: {
                  ...clipboard.element.positionControl,
                  x: transformAbsoluteToPercent(
                    newXPos,
                    activeAspectRatio,
                    'width',
                    MAX_FULLSCREEN_HEIGHT,
                  ),
                  y: transformAbsoluteToPercent(
                    newYPos,
                    activeAspectRatio,
                    'height',
                    MAX_FULLSCREEN_HEIGHT,
                  ),
                },
              } as unknown as ForecastWDElementDef,
              activeScene,
            }),
          );
          break;
        case 'videoPanels':
          dispatch(
            addVideoLayer({
              videoLayer: {
                ...clipboard.element,
                id: id,
                positionControl: {
                  ...clipboard.element.positionControl,
                  x: transformAbsoluteToPercent(
                    newXPos,
                    activeAspectRatio,
                    'width',
                    MAX_FULLSCREEN_HEIGHT,
                  ),
                  y: transformAbsoluteToPercent(
                    newYPos,
                    activeAspectRatio,
                    'height',
                    MAX_FULLSCREEN_HEIGHT,
                  ),
                },
              } as VideoPanelDef,
              activeScene,
            }),
          );
          break;
        case 'imagePanels':
          dispatch(
            addImageLayer({
              imageLayer: {
                ...clipboard.element,
                id: id,
                positionControl: {
                  ...clipboard.element.positionControl,
                  x: transformAbsoluteToPercent(
                    newXPos,
                    activeAspectRatio,
                    'width',
                    MAX_FULLSCREEN_HEIGHT,
                  ),
                  y: transformAbsoluteToPercent(
                    newYPos,
                    activeAspectRatio,
                    'height',
                    MAX_FULLSCREEN_HEIGHT,
                  ),
                },
              } as ImagePanelDef,
              activeScene,
            }),
          );
          break;
        case 'mapPanels':
          dispatch(
            addMapLayer({
              mapLayer: {
                ...clipboard.element,
                id: id,
                positionControl: {
                  ...clipboard.element.positionControl,
                  x: transformAbsoluteToPercent(
                    newXPos,
                    activeAspectRatio,
                    'width',
                    MAX_FULLSCREEN_HEIGHT,
                  ),
                  y: transformAbsoluteToPercent(
                    newYPos,
                    activeAspectRatio,
                    'height',
                    MAX_FULLSCREEN_HEIGHT,
                  ),
                },
              } as MapPanelDef,
              activeScene,
            }),
          );
          break;
        case 'weatherPosters':
          dispatch(
            addPosterLayer({
              weatherPoster: {
                ...clipboard.element,
                id: id,
                positionControl: {
                  ...clipboard.element.positionControl,
                  x: transformAbsoluteToPercent(
                    newXPos,
                    activeAspectRatio,
                    'width',
                    MAX_FULLSCREEN_HEIGHT,
                  ),
                  y: transformAbsoluteToPercent(
                    newYPos,
                    activeAspectRatio,
                    'height',
                    MAX_FULLSCREEN_HEIGHT,
                  ),
                },
              } as WeatherPosterDef,
              activeScene,
            }),
          );
          break;
        case 'animationPanels':
          dispatch(
            addAnimationLayer({
              animationLayer: {
                ...clipboard.element,
                id: id,
                positionControl: {
                  ...clipboard.element.positionControl,
                  x: transformAbsoluteToPercent(
                    newXPos,
                    activeAspectRatio,
                    'width',
                    MAX_FULLSCREEN_HEIGHT,
                  ),
                  y: transformAbsoluteToPercent(
                    newYPos,
                    activeAspectRatio,
                    'height',
                    MAX_FULLSCREEN_HEIGHT,
                  ),
                },
              } as AnimationPanelDef,
              activeScene,
            }),
          );
          break;
      }
    clipboard && dispatch(setElement({ activeElement: id, activeProp: clipboard.type }));
    setShowMenu(false);
  };
  const onDelete = () => {
    if (elementProps?.parentId) {
      dispatch(
        updatePosterPanel({
          activeScene,
          activeProp: elementProps.type,
          activeElement: elementProps.id,
          activeParent: elementProps.parenttype,
        }),
      );
    } else if (elementProps?.id) {
      dispatch(
        updatePanel({
          activeScene,
          activeProp: elementProps.type,
          activeElement: elementProps.id,
        }),
      );
    }
  };
  const menu = (
    <ul
      className={'rounded-md py-2 text-black min-w-[100px] canvas-context-menu'}
      style={{
        backgroundColor: 'white',
        position: 'absolute',
        left: xPos,
        top: yPos,
        zIndex: 999,
        minWidth: '130px',
        paddingLeft: '5px',
        paddingRight: '5px',
        transform: `scale(${1 / scaleFactor})`,
        transformOrigin: '0 0',
      }}
    >
      {addElement ? (
        <li
          onClick={addElement}
          className={'px-3 py-1 cursor-pointer hover:bg-gray-200 flex items-center'}
        >
          <AiOutlinePlus style={{ marginRight: '10px', marginTop: ' 2px' }} />
          New
        </li>
      ) : null}
      {/* {addElement ? <li className={'border'}></li> : null} */}
      <li
        onClick={onCopy}
        className={`px-3 py-1 cursor-pointer flex items-center ${
          !!elementProps?.type && 'hover:bg-gray-200'
        } ${!elementProps?.type && 'text-slate-400'}`}
        style={{ cursor: !elementProps?.type ? 'default' : 'pointer' }}
      >
        <AiOutlineCopy style={{ marginRight: '10px', marginTop: ' 2px' }} />
        Copy
      </li>
      <li
        onClick={onPaste}
        className={`px-3 py-1 cursor-pointer flex items-center ${
          clipboard && 'hover:bg-gray-200'
        } ${!clipboard && 'text-slate-400'}`}
        style={{ cursor: !clipboard ? 'default' : 'pointer' }}
      >
        <ImPaste style={{ marginRight: '10px', marginTop: ' 2px' }} />
        Paste
      </li>
      {/* <li className={'border'}></li> */}
      <li
        onClick={onDelete}
        className={`px-3 py-1 cursor-pointer flex items-center ${
          !!elementProps?.type && 'hover:bg-gray-200'
        } ${elementProps?.type ? 'text-[#ff0000]' : 'text-slate-400'}`}
        style={{ cursor: !elementProps?.type ? 'default' : 'pointer' }}
      >
        <BsTrash color="red" style={{ marginRight: '10px', marginTop: ' 2px' }} />
        Delete
      </li>
    </ul>
  );
  return { xPos, yPos, showMenu, menu };
};
