import '../../timeline/timelane/style.scss';

import moment from 'moment/moment';
import { ResizeDirection } from 're-resizable';
import { useCallback, useEffect, useState } from 'react';
import { MdOutlineDragHandle } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useResizeDetector } from 'react-resize-detector';
import { Position, ResizableDelta, Rnd } from 'react-rnd';

import { absoluteToPercent, getNumber, getPercentFromData } from '../../../helpers/timelineUtil';
import { DataFrameDef } from '../../../model/definitions/DataFrameDef';
import { ForecastWDElementDef } from '../../../model/definitions/ForecastWDElementDef';
import { TimeControlDef } from '../../../model/definitions/TimeControlDef';
import { GroupingEnum } from '../../../model/UI/enums/GroupingEnum';
import { ActiveDef } from '../../../store/slices/active-slice';
import { updateMapForecastLayer } from '../../../store/slices/project-slice';
import { RootState } from '../../../store/store';
import { getLayerCentralTime, layerSpan } from '../helpers';

interface ForecastGroupLaneProps {
  duration: number;
  mapId: string;
  parentId: string;
  parentTime: TimeControlDef;
  span: { min: number; max: number };
  segments: ForecastWDElementDef[];
}

export const ForecastGroupLane = ({
  duration,
  mapId,
  parentId,
  parentTime,
  segments,
  span,
  ...props
}: ForecastGroupLaneProps) => {
  const dispatch = useDispatch();
  const { grouping, syncSpace, activeScene } = useSelector<RootState>(
    (state) => state.active,
  ) as ActiveDef;
  const { width, ref } = useResizeDetector();
  const isSynced = syncSpace.includes(parentId);
  const [time, setTime] = useState<TimeControlDef>(new TimeControlDef(0, 0));
  const getPercentFromRef = (position: Position, ref: HTMLElement): number => {
    return (position.x * 100) / ref.offsetParent!.clientWidth;
  };
  const getPercentFromDelta = (data: ResizableDelta, ref: HTMLElement) => {
    return (data.width * 100) / ref.offsetParent!.clientWidth;
  };
  const resizeStopped = (
    position: Position,
    delta: ResizableDelta,
    ref: HTMLElement,
    direction: ResizeDirection,
  ) => {
    if (direction === 'left') {
      console.log('left');
      updatePointTime({
        ...time,
        startMS: getPercentFromRef(position, ref),
      });
    }
    if (direction === 'right') {
      console.log('right');
      updatePointTime({
        ...time,
        endMS: time.endMS + getPercentFromDelta(delta, ref),
      });
    }
  };
  const dragEnded = (percent: number) => {
    if (absoluteToPercent([parentTime], duration)[0].startMS > percent) {
      updatePointTime({
        ...time,
        startMS: absoluteToPercent([parentTime], duration)[0].startMS,
        endMS: absoluteToPercent([parentTime], duration)[0].startMS + (time.endMS - time.startMS),
      });
    } else if (
      absoluteToPercent([parentTime], duration)[0].endMS <
      percent + (time.endMS - time.startMS)
    ) {
      updatePointTime({
        ...time,
        startMS: absoluteToPercent([parentTime], duration)[0].endMS - (time.endMS - time.startMS),
        endMS: absoluteToPercent([parentTime], duration)[0].endMS,
      });
    } else {
      updatePointTime({
        ...time,
        startMS: percent,
        endMS: percent + (time.endMS - time.startMS),
      });
    }
  };
  const _splitNumber = (inputNumber: number, numSplits: number): number[] => {
    const result: number[] = [];

    for (let i = 1; i <= numSplits; i++) {
      const splitValue = Math.ceil((inputNumber * i) / numSplits);
      result.push(splitValue);
    }

    return result;
  };
  const sortObjectsByUtcTimeAscending = (
    objects: ForecastWDElementDef[],
  ): ForecastWDElementDef[] => {
    return objects
      .slice()
      .sort(
        (a, b) =>
          new Date(a.forecastWDSource.utcDate).getTime() -
          new Date(b.forecastWDSource.utcDate).getTime(),
      );
  };
  const _percentToTime = useCallback(
    (percent: number) => {
      return Math.round((duration * percent) / 100);
    },
    [duration],
  );
  const _frames: DataFrameDef[] = segments.map((point) => {
    return {
      timestamp: Number(moment(point.forecastWDSource.utcDate).format('x')) / 1000,
      frameId: point.id,
    };
  });
  const updatePointTime = useCallback(
    (time: TimeControlDef) => {
      const startTime = _percentToTime(time.startMS);
      const points = sortObjectsByUtcTimeAscending(segments);
      const layerDuration = _percentToTime(time.endMS - time.startMS) / segments.length;
      const pointsWitNewTimes = points.map((point, index) => {
        return {
          ...point,
          timeControls: [
            {
              ...point.timeControls[0],
              startMS: startTime + layerDuration * index,
              endMS:
                startTime + _frames.length === 1
                  ? parentTime.endMS
                  : layerDuration * index + layerDuration,
            },
          ],
        };
      });
      dispatch(updateMapForecastLayer({ mapId, elements: pointsWitNewTimes, activeScene }));
    },
    [_percentToTime, activeScene, mapId, segments],
  );
  useEffect(() => {
    if (isSynced)
      setTime(
        absoluteToPercent(
          [
            {
              ...time,
              startMS: getLayerCentralTime(parentTime, span, layerSpan(_frames), _frames).start,
              endMS: getLayerCentralTime(parentTime, span, layerSpan(_frames), _frames).end,
            },
          ],
          duration,
        )[0],
      );
    else {
      const start =
        segments[0].timeControls[0].startMS < parentTime.startMS
          ? parentTime.startMS
          : segments[0].timeControls[0].startMS;
      const end =
        segments[segments.length - 1].timeControls[0].endMS > parentTime.endMS
          ? parentTime.endMS
          : segments[segments.length - 1].timeControls[0].endMS;
      setTime(
        absoluteToPercent(
          [
            {
              ...time,
              startMS: start,
              endMS: end === 0 ? parentTime.endMS : end,
            },
          ],
          duration,
        )[0],
      );
    }
  }, [duration, parentTime, span, width, segments, isSynced]);
  useEffect(() => {
    updatePointTime(time);
  }, [segments.length, activeScene, isSynced, span /*width, segments */]);
  const laneSize = {
    width: parentTime
      ? time.endMS > absoluteToPercent([parentTime], duration)[0].endMS
        ? absoluteToPercent([parentTime], duration)[0].endMS - time.startMS + '%'
        : time.endMS - time.startMS + '%'
      : time.endMS - time.startMS + '%',
    height: parentTime ? 12 : 24,
  };
  const lane = (
    <Rnd
      enableResizing={{
        left: !isSynced,
        right: !isSynced,
      }}
      className={`segment`}
      disableDragging={isSynced}
      minHeight={12}
      dragAxis={'x'}
      key={segments.map((point) => point.id).toString()}
      position={{
        x: getNumber(time.startMS, width),
        y: grouping === GroupingEnum.LOGICAL ? 4 : 6,
      }}
      onResizeStop={(e, direction, ref, delta, position) =>
        resizeStopped(position, delta, ref, direction)
      }
      onDragStop={(e, data) => {
        e && dragEnded(getPercentFromData(data, width));
      }}
      size={laneSize}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0 10px',
        height: 10,
        maxHeight: 10,
        minHeight: 10,
        background: segments.every((point) => point.enabled)
          ? 'rgba(241,210,5,0.8)'
          : 'rgba(241,210,5,0.2)',
      }}
      resizeHandleComponent={{
        right: (
          <MdOutlineDragHandle
            size={24}
            color={'rgb(96, 136, 113)'}
            style={{
              transform: 'rotate(90deg) translateY(12px)',
              height: '100%',
            }}
          />
        ),
        left: (
          <MdOutlineDragHandle
            size={24}
            color={'rgb(96, 136, 113)'}
            style={{
              transform: 'rotate(90deg) translateY(2px)',
              height: '100%',
            }}
          />
        ),
      }}
    >
      <div className={'layer-hover'}>
        {moment(segments[0].forecastWDSource.utcDate).format('DD.MMM, HH:mm')}-
        {moment(segments[segments.length - 1].forecastWDSource.utcDate).format('DD.MMM, HH:mm')}
      </div>
    </Rnd>
  );
  return (
    <div {...props} id={'Lane'} ref={ref}>
      <div className={'rail'} style={{ height: 1 }} />
      {lane}
    </div>
  );
};
