import { useQuery } from 'react-query';

import { PickerDef } from '../../../model/definitions/PickerDef';
import { axiosInstance } from '../axiosInstance';

export const useGetRadarSource = (bounds: [number, number, number, number], oldLayer: boolean) => {
  const apiUrl = `radar-data/${oldLayer ? '' : 'v2/'}radar-source`;
  const key = [`RADAR_SOURCE${oldLayer ? '' : '_V2'}`];

  return useQuery(
    [key, bounds],
    async () => {
      const { data } = await axiosInstance.post(apiUrl, {
        leftLongitude: bounds[0],
        rightLongitude: bounds[1],
        upperLatitude: bounds[2],
        lowerLatitude: bounds[3],
      });
      return data as Array<PickerDef>;
    },
    { enabled: Boolean(bounds.length) },
  );
};
