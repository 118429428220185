import { Carousel as AntCarousel, CarouselProps } from 'antd';
import { CarouselRef } from 'antd/es/carousel';
import React from 'react';

const Carousel = React.forwardRef<CarouselRef, React.PropsWithChildren<CarouselProps>>(
  (props, ref) => {
    return <AntCarousel {...props} ref={ref}></AntCarousel>;
  },
);

Carousel.displayName = 'Carousel';

export default Carousel;
