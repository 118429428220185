import { FaSubscript, FaSuperscript } from 'react-icons/fa';
import { MdFormatUnderlined } from 'react-icons/md';

const iconList = {
  underline: <MdFormatUnderlined size={15} className="mx-[5px]" />,
  superscript: <FaSuperscript size={15} className="mx-[5px]" />,
  subscript: <FaSubscript size={15} className="mx-[5px]" />,
};

const Icon = (props) => {
  const { icon } = props;
  return iconList[icon];
};

export default Icon;
