import './styles.scss';

interface PriceProps {
  currency: Currency;
  price: number;
}

interface Currency {
  name: string;
  symbol: string;
  code: string;
  label: string;
  exchangeRateUsd: number;
}
const Price = ({ currency, price }: PriceProps) => {
  return <>{currency.symbol + ' ' + (currency.exchangeRateUsd * price).toFixed(2)}</>;
};

export default Price;
