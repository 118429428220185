import { Triangle } from 'react-loader-spinner';

const Loader = ({ color }: { color?: string }) => {
  return (
    <div className={'ws-grid-loader'}>
      <Triangle
        height="80"
        width="80"
        color={color ?? '#3A36DB'}
        ariaLabel="triangle-loading"
        wrapperStyle={{}}
        visible={true}
      />
    </div>
  );
};

export default Loader;
