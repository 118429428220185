import { useQuery } from 'react-query';

import { Station } from '../../../model/other/ObservedData';
import { axiosInstance } from '../axiosInstance';

const key = ['USE_STATIONS'];
export const useGetStations = (searchKey?: string) =>
  useQuery(
    [...key, searchKey],
    async (): Promise<Array<Station>> => {
      const { data } = await axiosInstance.get('observed-data/observed-station', {
        params: {
          searchKey,
        },
      });
      return data;
    },
    {
      enabled: !!searchKey,
    },
  );
