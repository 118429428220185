import { useMutation } from 'react-query';

import { MapPanelDef } from '../../../../model/definitions/MapPanelDef';
import { axiosInstance } from '../../axiosInstance';

export const useApplyBiasGroupOnMap = () =>
  useMutation(
    async ({
      type,
      biasGroupId,
      body,
    }: {
      type: 'OBSERVED' | 'FORECAST';
      biasGroupId: string;
      body: MapPanelDef;
    }): Promise<MapPanelDef> => {
      const { data } = await axiosInstance.put(`biasGroup/apply/${type}/${biasGroupId}/map`, body);
      return data;
    },
  );
