import { ResizeDirection } from 're-resizable';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useResizeDetector } from 'react-resize-detector';
import { Position, ResizableDelta, Rnd } from 'react-rnd';

import {
  absoluteToPercent,
  getNumber,
  getPercentFromData,
} from '../../../../../helpers/timelineUtil';
import { C9ProjectDef } from '../../../../../model/definitions/C9ProjectDef';
import { TimeControlDef } from '../../../../../model/definitions/TimeControlDef';
import {
  updateProject,
  updateVideoOverTime,
  updateVoiceOverTime,
} from '../../../../../store/slices/project-slice';

interface VoiceOverLaneProps {
  value: Array<TimeControlDef>;
  duration: number;
  videoOver?: boolean;
}
const style = {
  backgroundColor: 'rgb(0,84,255)',
};
export const VoiceOverLane: FC<VoiceOverLaneProps> = ({ value, duration, videoOver, ...props }) => {
  const dispatch = useDispatch();
  const { width, ref } = useResizeDetector();
  const [pieces, setPieces] = useState<Array<TimeControlDef>>(value);
  useEffect(() => {
    setPieces(absoluteToPercent(value, duration, 0, duration));
  }, [width, duration, value]);
  const getPercentFromRef = (position: Position, ref: HTMLElement): number => {
    return (position.x * 100) / ref.offsetParent!.clientWidth;
  };
  const getPercentFromDelta = (data: ResizableDelta, ref: HTMLElement) => {
    return (data.width * 100) / ref.offsetParent!.clientWidth;
  };
  const percentToTime = (percent: number) => {
    return Math.round((duration * percent) / 100);
  };
  const dragEnded = (percent: number, index: number) => {
    const newPieces = [...pieces];
    const width = newPieces[index].endMS - newPieces[index].startMS;
    if (newPieces[index - 1] && newPieces[index - 1].endMS > percent) {
      newPieces[index].startMS = newPieces[index - 1].endMS;
      newPieces[index].endMS = newPieces[index - 1].endMS + width;
    } else if (newPieces[index + 1] && newPieces[index + 1].startMS < percent + width) {
      newPieces[index].startMS = newPieces[index + 1].startMS - width;
      newPieces[index].endMS = newPieces[index + 1].startMS;
    } else {
      newPieces[index].startMS = percent;
      newPieces[index].endMS = percent + width;
    }
    const percentToTime = (percent: number) => {
      return Math.round((duration * percent) / 100);
    };
    setPieces(newPieces);
    const newTimes: TimeControlDef[] = [];
    newPieces.forEach((item) => {
      newTimes.push({
        ...item,
        endMS: percentToTime(item.endMS),
        startMS: percentToTime(item.startMS),
      });
    });
    !videoOver && dispatch(updateVoiceOverTime({ time: newTimes }));
    videoOver && dispatch(updateVideoOverTime({ time: newTimes }));
  };
  const resizeStopped = (
    position: Position,
    delta: ResizableDelta,
    ref: HTMLElement,
    direction: ResizeDirection,
    index: number,
  ): void => {
    const newPieces = [...pieces];
    if (direction === 'left') {
      if (newPieces[index - 1] && newPieces[index - 1].endMS > getPercentFromRef(position, ref)) {
        newPieces[index].startMS = newPieces[index - 1].endMS;
      } else {
        newPieces[index].startMS = getPercentFromRef(position, ref);
      }
    }
    if (direction === 'right') {
      if (
        newPieces[index + 1] &&
        newPieces[index + 1].startMS < newPieces[index].endMS + getPercentFromDelta(delta, ref)
      ) {
        newPieces[index].endMS = newPieces[index + 1].startMS;
      } else {
        newPieces[index].endMS += getPercentFromDelta(delta, ref);
      }
    }
    setPieces(newPieces);
    const newTimes: TimeControlDef[] = [];
    newPieces.forEach((item) => {
      newTimes.push({
        ...item,
        endMS: percentToTime(item.endMS),
        startMS: percentToTime(item.startMS),
      });
    });
    !videoOver && dispatch(updateVoiceOverTime({ time: newTimes }));
    videoOver && dispatch(updateVideoOverTime({ time: newTimes }));
  };
  function onUpdateProject(newValue: any, propertyPath: keyof C9ProjectDef) {
    //@ts-ignore
    dispatch(updateProject({ newValue, propertyPath }));
  }
  const onDelete = () => {
    videoOver ? onUpdateProject(undefined, 'videoOver') : onUpdateProject(undefined, 'voiceOver');
  };
  const all = () =>
    pieces?.map((item, index) => (
      <Rnd
        key={`Skip_${index}`}
        onDoubleClick={(e: MouseEvent) => onDelete()}
        enableResizing={{ left: true, right: true }}
        minHeight={14}
        dragAxis={'x'}
        maxHeight={14}
        bounds={'parent'}
        style={style}
        position={{
          x: getNumber(item.startMS, width),
          y: 7,
        }}
        size={{
          width: item.endMS - item.startMS + '%',
          height: 14,
        }}
        onDragStop={(e, data) => {
          e && dragEnded(getPercentFromData(data, width), index);
        }}
        onResizeStop={(e, direction, ref, delta, position) =>
          resizeStopped(position, delta, ref, direction, index)
        }
      />
    ));
  return (
    <div {...props} id={'Lane'} ref={ref}>
      <div className={'rail'} style={{ height: 1 }} />
      {all()}
    </div>
  );
};
