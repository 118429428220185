import { useSelector } from 'react-redux';

import { ElementsEnum } from '../../../../core/ui/enums/ElementsEnum';
import { TimestampElementDef } from '../../../../model/definitions/TimestampElementDef';
import { selectActiveTimestampLayer } from '../../../../store/slices/selectors';
import { RootState } from '../../../../store/store';
import { Panel } from '../components/Panel';
import { BoxStyle } from '../panels/BoxStyle';
import { PositionControls } from '../panels/PositionControls';
import { TimeControlsPanel } from '../panels/TimeControlsPanel';
import { BasicProperties } from './BasicProperties';
import { FormatProperties } from './FormatProperties';
import { TextStyle } from './TextStyle';

export const TimestampProperties = () => {
  const layer = useSelector<RootState, TimestampElementDef | null | undefined>((state) =>
    selectActiveTimestampLayer(state),
  );
  return (
    <Panel>
      {layer ? (
        <>
          <BasicProperties {...layer} />
          <FormatProperties {...layer} />
          <TextStyle {...layer} />
          <>
            {layer?.positionControl && (
              <PositionControls position={layer?.positionControl} layer={'timestampPanels'} />
            )}
            {layer?.timeControls && (
              <TimeControlsPanel
                timeControls={layer?.timeControls}
                layer={ElementsEnum.TIMESTAMP}
              />
            )}
          </>
          <BoxStyle box={layer?.boxDef} elementType={ElementsEnum.TIMESTAMP} />
        </>
      ) : null}
    </Panel>
  );
};
