import { useQuery } from 'react-query';

import { axiosInstance } from '../axiosInstance';

const key = ['DATE_TIME'];
export const useGetDateTime = (station?: string) =>
  useQuery(
    [...key, station],
    async () => {
      const { data } = await axiosInstance.get('observed-data/observed-times', {
        params: { station },
      });
      return data;
    },
    {
      enabled: !!station,
    },
  );
