import { useMutation } from 'react-query';

import { ScreenshotData } from '../../../helpers/screenshotElement';
import { WeatherPosterDef } from '../../../model/definitions/WeatherPosterDef';
import { axiosInstance } from '../axiosInstance';
import { getRepoGraph } from '../RepositoryAPI';

export const useUpdatePosterTemplate = () =>
  useMutation(
    async ({
      posterDef,
      templateId,
      thumbnailURLs,
      name,
      description,
    }: {
      posterDef: WeatherPosterDef;
      templateId: string;
      thumbnailURLs: ScreenshotData[];
      name: string;
      description: string;
    }) => {
      const form = new FormData();
      const tempDef = await getRepoGraph(templateId);
      tempDef.name = name;
      tempDef.description = description;
      tempDef.graphDef = posterDef;
      form.append('weatherPosterDefTemplate', JSON.stringify({ ...tempDef, thumbnailURLs: [] }));
      if (thumbnailURLs && thumbnailURLs.length) {
        thumbnailURLs.forEach((th) => {
          form.append('thumbnailFiles', th.blob!);
        });
      }

      const { data } = await axiosInstance.put('weather-poster-template', form, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      return data;
    },
  );
