export enum MapLayersEnum {
  BORDERS = 'borders',
  ROADS = 'roads',
  COASTLINE = 'coastline',
  USA0STATES = 'usastates',
  // CITIES = 'cities',
  RIVERS = 'rivers',
  COUNTRIES = 'admin_countries',
  BOUNDARY_LINES_LAND = 'boundary_lines_land',
  BOUNDARY_LINES_MARITIME_INDICATOR = 'boundary_lines_maritime_indicator',
  BOUNDARY_BREAKAWAY_DISPUTED_AREAS = 'boundary_breakaway_disputed_areas',
  STATES_PROVINCES = 'states_provinces',
}
