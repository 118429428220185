import { useMutation } from 'react-query';

import { axiosInstance } from '../../../core/api/axiosInstance';

export const useUpdateIconSet = () =>
  useMutation(async (template: FormData) => {
    return await axiosInstance.put('icon-set-template', template, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  });
