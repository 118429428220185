import { AiOutlineAudio, AiOutlineFundProjectionScreen } from 'react-icons/ai';
import { MdOutlineVideoSettings } from 'react-icons/md';
import { RiImageAddLine } from 'react-icons/ri';
import { TbMap2 } from 'react-icons/tb';

import { MarketplaceTemplateEnum } from '../../../../model/enums/MarketplaceTemplateEnum';
import Audio1 from '../../assets/images/LandingPage/background/Audio1.jpg';
import Audio2 from '../../assets/images/LandingPage/background/Audio2.jpg';
import Image1 from '../../assets/images/LandingPage/background/Image1.jpg';
import Image2 from '../../assets/images/LandingPage/background/Image2.jpg';
import Map1 from '../../assets/images/LandingPage/background/Map1.jpg';
import Map2 from '../../assets/images/LandingPage/background/Map2.jpg';
import Project1 from '../../assets/images/LandingPage/background/Project1.jpg';
import Project2 from '../../assets/images/LandingPage/background/Project2.jpg';
import Video1 from '../../assets/images/LandingPage/background/Video1.jpg';
import Video2 from '../../assets/images/LandingPage/background/Video2.jpg';

export const categoriesConfig = [
  {
    name: 'Projects',
    description:
      '<p>This marketplace could offer a wide range of videos, such as stock <b>footage, animations, video clips,</b> and other types of visual content. Users could search for videos based on specific criteria, such as <b>keywords, themes, formats,</b> and lengths.</p>',
    type: MarketplaceTemplateEnum.PROJECT_TEMPLATE,
    backgroundImages: [Project1, Project2, Project1],
    backgroundIcon: (
      <AiOutlineFundProjectionScreen
        size={170}
        color="white"
        className="odd hidden xl:block absolute bottom-[8%] left-[40%] z-0 fliping"
      />
    ),
  },
  {
    name: 'Images',
    description:
      '<p><b>The platform may also offer additional features,</b> such as user reviews, recommendations, and licensing options. <b>This marketplace</b> could be a valuable resource for individuals and businesses who need <b>high-quality images.</b></p>',
    type: MarketplaceTemplateEnum.IMAGE_TEMPLATE,
    backgroundImages: [Image1, Image2, Image1],
    backgroundIcon: (
      <RiImageAddLine
        size={300}
        color="white"
        className="even hidden xl:block absolute bottom-4 right-4 z-0 floating"
      />
    ),
  },
  {
    name: 'Maps',
    description:
      '<p><b>This marketplace</b> may offer a wide <b>range of maps</b>, including city maps, <b>topographical maps,</b> navigational maps, and more. Users could search for maps based on specific <b>locations, themes, or types of maps.</b></p>',
    type: MarketplaceTemplateEnum.MAP_TEMPLATE,
    backgroundImages: [Map1, Map2, Map1],
    backgroundIcon: (
      <TbMap2
        size={250}
        color="white"
        className="odd hidden xl:block absolute bottom-[0%] left-[45%] z-10 opacity-70"
      />
    ),
  },
  {
    name: 'Audios',
    description:
      '<p><b>The platform may also offer additional features,</b> such as user reviews, recommendations, and licensing options. <b>This marketplace</b> could be a valuable resource for individuals and businesses who need <b>high-quality images.</b></p>',
    type: MarketplaceTemplateEnum.AUDIO_TEMPLATE,
    backgroundImages: [Audio1, Audio2, Audio1],
    backgroundIcon: (
      <AiOutlineAudio
        size={250}
        color="white"
        className="even hidden xl:block absolute bottom-4 right-4 z-0 floating"
      />
    ),
  },
  {
    name: 'Videos',
    description:
      '<p>This marketplace could offer a wide range of videos, such as stock <b>footage, animations, video clips,</b> and other types of visual content. Users could search for videos based on specific criteria, such as <b>keywords, themes, formats,</b> and lengths.</p>',
    type: MarketplaceTemplateEnum.VIDEO_TEMPLATE,
    backgroundImages: [Video1, Video2, Video1],
    backgroundIcon: (
      <MdOutlineVideoSettings
        size={200}
        color="white"
        className="odd hidden xl:block absolute bottom-[8%] left-[30%] z-0"
      />
    ),
  },

  {
    name: 'Scenes',
    type: MarketplaceTemplateEnum.SCENE_TEMPLATE,
  },
  {
    name: 'Posters',
    type: MarketplaceTemplateEnum.WEATHER_POSTER_TEMPLATE,
  },
  {
    name: 'Composites',
    type: MarketplaceTemplateEnum.WEATHER_GEO_POSTER_TEMPLATE,
  },
  {
    name: 'Icons sets',
    type: MarketplaceTemplateEnum.ICON_SET_TEMPLATE,
  },
  {
    name: 'Custom Maps',
    type: MarketplaceTemplateEnum.CUSTOM_MAP_TEMPLATE,
  },
  {
    name: 'Custom Vectors',
    type: MarketplaceTemplateEnum.CUSTOM_VECTOR_TEMPLATE,
  },
  {
    name: 'Animation',
    type: MarketplaceTemplateEnum.ANIMATION_TEMPLATE,
  },
];
