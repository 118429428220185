import './style.scss';

import { ColorPicker, InputNumber } from 'antd';
import format from 'date-fns/format';
import { Button, ToggleSwitch } from 'flowbite-react';
import { cloneDeep, debounce } from 'lodash';
import Slider from 'rc-slider';
import { useEffect, useState } from 'react';
import { AiOutlineCaretDown, AiOutlineCaretUp, AiOutlinePlus } from 'react-icons/ai';
import { IoSaveOutline } from 'react-icons/io5';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { v4 } from 'uuid';

import TemplateButton from '../../../../atoms/button/Button';
import { saveLayerAsTemplate, updateLayerAsTemplate } from '../../../../core/api/TemplatesAPI';
import { WeatherDataLoader } from '../../../../core/weather-data/WeatherDataLoader';
import { ScreenshotData } from '../../../../helpers/screenshotElement';
import { useAddLayersDisabled } from '../../../../hooks/useAddLayersDisabled';
import { useImplicitSave } from '../../../../hooks/useImplicitSave';
import { usePropertyGridActive } from '../../../../hooks/usePropertyGridActive';
import { MAX_ZINDEX_VALUE } from '../../../../model/constants/constants';
import { C9ProjectDef } from '../../../../model/definitions/C9ProjectDef';
import { EventMapLayer } from '../../../../model/definitions/EventMapLayer';
import { GribMapLayer } from '../../../../model/definitions/GribMapLayer';
import { MapPanelDef } from '../../../../model/definitions/MapPanelDef';
import { RadarMapLayer } from '../../../../model/definitions/RadarMapLayer';
import { SatelliteMapLayer } from '../../../../model/definitions/SatelliteMapLayer';
import { SymbolLayerDef, SymbolSourceType } from '../../../../model/definitions/SymbolLayerDef';
import { TimeControlDef } from '../../../../model/definitions/TimeControlDef';
import {
  ParticleDensityEnum,
  ParticleDensityText,
} from '../../../../model/enums/ParticleDensityEnum';
import { VisualisationTypeEnum } from '../../../../model/enums/VisualisationTypeEnum';
import { isWindLayer } from '../../../../molecules/mapElement/useSymbolLayers';
import {
  ActiveDef,
  addSymbolNewOverrides,
  setApplyLayer,
  setLayerType,
  setNewPoint,
  setPropertyGridActiveHash,
  setSymbolEditingLayerId,
} from '../../../../store/slices/active-slice';
import {
  setSymbolLayerPoints,
  updateIndicatorPriority,
} from '../../../../store/slices/project-slice';
import { RootState } from '../../../../store/store';
import { useConfirmation } from '../../../dashboard/modals/useConfirmation';
import MapEventLayersModal from '../../modals/MapEventLayersModal';
import MapGribLayersModal from '../../modals/MapGribLayersModal';
import MapRadarLayersModal from '../../modals/MapRadarLayersModal';
import MapSatelliteLayerModal from '../../modals/MapSatelliteLayerModal';
import MapSymbolLayersModal from '../../modals/MapSymbolLayersModal';
import {
  gribPropertiesDeps,
  radarPropertiesDeps,
  satellitePropertiesDeps,
  symbolPropertiesDeps,
  timeIndicatorPropertiesDeps,
} from '../constants/propertiesConstants';
import { AnimationPicker } from '../panels/panelElements/AnimationPicker';
import { TimeFrameIndicator } from '../panels/timeFrameIndicator/TimeFrameIndicator';
import SaveAsTemplateModal from '../SaveAsTemplateModal';
import GridActions from '../shared/GridActions';
import GridItem from '../shared/GridItem';
import GridWrapper from '../shared/GridWrapper';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import { SymbolPointsProperties } from './SymbolPointsProperties';
import { SymbolStyleProperties } from './SymbolStyleProperties';

const inputStyle = {
  backgroundColor: '#060b12',
  border: '1px solid #2b3441',
  borderRadius: 2,
  padding: '0 5px',
  width: '100%',
};
interface LayersProps {
  mapLayer: MapPanelDef;
  onMapLayerChange: (
    propertyPath: Paths<MapPanelDef>,
    newValue:
      | GribMapLayer[]
      | RadarMapLayer[]
      | SatelliteMapLayer[]
      | SymbolLayerDef[]
      | EventMapLayer[],
  ) => void;
  layerType?: 'grib' | 'radar' | 'satellite' | 'symbol';
  layerId?: string;
}

export const Layers = ({ mapLayer, onMapLayerChange, layerType, layerId }: LayersProps) => {
  const { activeScene, activeElement, applyLayer, symbolEditingLayerId } = useSelector<RootState>(
    (state) => state.active,
  ) as ActiveDef;
  const { confirm } = useConfirmation();
  useEffect(() => {
    if (applyLayer) {
      switch (applyLayer.layerType) {
        case 'GRIB':
          setMapGribLayersModalVisible(true);
          break;
        case 'RADAR':
          setMapRadarLayersModalVisible(true);
          break;
        case 'SATELLITE':
          setMapSatelliteLayerModalVisible(true);
          break;
        case 'SYMBOL':
          setMapSymbolLayerModalVisible(true);
          break;
      }
    }
  }, [applyLayer]);
  const dispatch = useDispatch();
  const [mapGribLayersModalVisible, setMapGribLayersModalVisible] = useState(false);
  const [mapRadarLayersModalVisible, setMapRadarLayersModalVisible] = useState(false);
  const [mapSatelliteLayerModalVisible, setMapSatelliteLayerModalVisible] = useState(false);
  const [mapSymbolLayerModalVisible, setMapSymbolLayerModalVisible] = useState(false);
  const [mapEventLayerModalVisible, setMapEventLayerModalVisible] = useState(false);
  const [expandedLayers, setExpandedLayers] = useState<string[]>([]);
  const [editId, setEditId] = useState<string | null>(null);
  const isAddLayersDisabled = useAddLayersDisabled();
  const [opened, setOpened] = useState(false);
  const [templateLayer, setTemplateLayer] = useState<
    GribMapLayer | RadarMapLayer | SatelliteMapLayer | SymbolLayerDef
  >();
  const implicitSave = useImplicitSave();
  const project = useSelector<RootState, C9ProjectDef>((state) => state.project.present.project);
  const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);

  const { lastFocused } = usePropertyGridActive([]);
  const [timeFrame, setTimeFrame] = useState(timeIndicatorPropertiesDeps.includes(lastFocused));
  const [accordionGribLayrs, setAccordionGribLayers] = useState<boolean>(
    gribPropertiesDeps.includes(lastFocused),
  );
  const [accordionRadarLayers, setAccordionRadarLayers] = useState<boolean>(
    radarPropertiesDeps.includes(lastFocused),
  );
  const [accordionSatelliteLayers, setAccordionSatelliteLayers] = useState<boolean>(
    satellitePropertiesDeps.includes(lastFocused),
  );
  const [accordionSymbolLayers, setAccordionSymbolLayers] = useState<boolean>(
    symbolPropertiesDeps.includes(lastFocused),
  );
  const [accordionEventLayers, setAccordionEventLayers] = useState<boolean>(false);

  useEffect(() => {
    if (layerType === 'grib') {
      setAccordionGribLayers(true);
      setAccordionRadarLayers(false);
      setAccordionSatelliteLayers(false);
      setAccordionSymbolLayers(false);
    }
    if (layerType === 'radar') {
      setAccordionGribLayers(false);
      setAccordionRadarLayers(true);
      setAccordionSatelliteLayers(false);
      setAccordionSymbolLayers(false);
    }
    if (layerType === 'satellite') {
      setAccordionGribLayers(false);
      setAccordionRadarLayers(false);
      setAccordionSatelliteLayers(true);
      setAccordionSymbolLayers(false);
    }
    if (layerType === 'symbol') {
      setAccordionGribLayers(false);
      setAccordionRadarLayers(false);
      setAccordionSatelliteLayers(false);
      setAccordionSymbolLayers(true);
    }
    layerId && setExpandedLayers([layerId]);
    layerId &&
      document.getElementById(layerId)?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, [layerType, layerId]);

  function onCloseConfirmationModal() {
    setConfirmationModalVisible(false);
  }

  function onFocus(path: string) {
    dispatch(setPropertyGridActiveHash({ activeElement, focusedEl: path }));
  }

  function onEditClick(id: string | null) {
    setEditId(id);
  }

  const setOpacityGrib = (e: number, id: string) => {
    onFocus('gribMapLayers');
    const layersToEdit = cloneDeep(mapLayer.wdSpace[0].gribMapLayers);
    const layerToSetOpacity = layersToEdit.find((layer) => layer.id === id);
    if (layerToSetOpacity) layerToSetOpacity.opacity = e;
    // @ts-ignore
    onMapLayerChange('wdSpace[0].gribMapLayers', layersToEdit);
  };
  const setOpacityRadar = (e: number, id: string) => {
    onFocus('radarMapLayers');
    const layersToEdit = cloneDeep(mapLayer.wdSpace[0].radarMapLayers);
    const layerToSetOpacity = layersToEdit.find((layer) => layer.id === id);
    if (layerToSetOpacity) layerToSetOpacity.opacity = e;
    // @ts-ignore
    onMapLayerChange('wdSpace[0].radarMapLayers', layersToEdit);
  };
  const setOpacitySatellite = (e: number, id: string) => {
    onFocus('satelliteMapLayers');
    const layersToEdit = cloneDeep(mapLayer.wdSpace[0].satelliteMapLayers);
    const layerToSetOpacity = layersToEdit.find((layer) => layer.id === id);
    if (layerToSetOpacity) layerToSetOpacity.opacity = e;
    // @ts-ignore
    onMapLayerChange('wdSpace[0].satelliteMapLayers', layersToEdit);
  };
  const enableGribLayer = (e: boolean, id: string) => {
    onFocus('gribMapLayers');
    const layersToEdit = cloneDeep(mapLayer.wdSpace[0].gribMapLayers);
    const layerToEnable = layersToEdit.find((layer) => layer.gribSource.id === id);
    if (layerToEnable) layerToEnable.enabled = e;
    // @ts-ignore
    onMapLayerChange('wdSpace[0].gribMapLayers', layersToEdit);
  };
  const onEventLayerPropertyChange = debounce(
    (id: string, propertyName: keyof EventMapLayer, newValue: any) => {
      const layersToEdit = cloneDeep(mapLayer.wdSpace[0].eventLayers);
      const layerToChange = layersToEdit.find((l) => l.id === id);
      let value = newValue;
      if (propertyName === 'radius') {
        value = isNaN(Number(newValue)) ? 10 : Number(newValue);
      }
      if (layerToChange) {
        // @ts-ignore
        layerToChange[propertyName] = value;
        // @ts-ignore
        onMapLayerChange('wdSpace[0].eventLayers', layersToEdit);
      }
    },
    300,
  );
  const enableRealtimeIndicator = (
    e: boolean,
    id: string,
    layerType: 'gribMapLayers' | 'radarMapLayers' | 'satelliteMapLayers',
  ) => {
    onFocus(layerType);
    const layersToEdit = cloneDeep(mapLayer.wdSpace[0][layerType]);
    const source =
      layerType === 'gribMapLayers'
        ? 'gribSource'
        : layerType === 'radarMapLayers'
        ? 'radarSource'
        : 'satelliteSource';
    //@ts-ignore
    const layerToEnable = layersToEdit?.find((layer) => layer[source].id === id);
    if (layerToEnable) layerToEnable.realtime = e;
    // @ts-ignore
    onMapLayerChange(`wdSpace[0].${layerType}`, layersToEdit);
  };
  const changeIsolineWidth = async (e: number, layerSetupId: string) => {
    onFocus('gribMapLayers');
    const layersToEdit = cloneDeep(mapLayer.wdSpace[0].gribMapLayers);
    const layer = layersToEdit.find((layer) => layer.layerSetup.id === layerSetupId);
    if (layer) {
      layer.layerSetup.isolineWidth = e;
      // @ts-ignore
      onMapLayerChange('wdSpace[0].gribMapLayers', layersToEdit);
    }
  };

  const setGribAnimation = (id: string, prop: keyof TimeControlDef, value: any) => {
    onFocus('gribMapLayers');
    const layersToEdit = cloneDeep(mapLayer.wdSpace[0].gribMapLayers);
    const layerToSetOpacity = layersToEdit.find((layer) => layer.id === id);
    if (layerToSetOpacity)
      layerToSetOpacity.timeControls = [{ ...layerToSetOpacity.timeControls[0], [prop]: value }];
    // @ts-ignore
    onMapLayerChange('wdSpace[0].gribMapLayers', layersToEdit);
  };
  const setRadarAnimation = (id: string, prop: keyof TimeControlDef, value: any) => {
    onFocus('radarMapLayer');
    const layersToEdit = cloneDeep(mapLayer.wdSpace[0].radarMapLayers);
    const layerToSetOpacity = layersToEdit.find((layer) => layer.id === id);
    if (layerToSetOpacity)
      layerToSetOpacity.timeControls = [{ ...layerToSetOpacity.timeControls[0], [prop]: value }];
    // @ts-ignore
    onMapLayerChange('wdSpace[0].radarMapLayers', layersToEdit);
  };
  const setSatelliteAnimation = (id: string, prop: keyof TimeControlDef, value: any) => {
    onFocus('satelliteMapLayer');
    const layersToEdit = cloneDeep(mapLayer.wdSpace[0].satelliteMapLayers);
    const layerToSetOpacity = layersToEdit.find((layer) => layer.id === id);
    if (layerToSetOpacity)
      layerToSetOpacity.timeControls = [{ ...layerToSetOpacity.timeControls[0], [prop]: value }];
    // @ts-ignore
    onMapLayerChange('wdSpace[0].satelliteMapLayers', layersToEdit);
  };
  const setSymbolAnimation = (id: string, prop: keyof TimeControlDef, value: any) => {
    onFocus('symbolLayers');
    const layersToEdit = cloneDeep(mapLayer.wdSpace[0].symbolLayers);
    const layerToSetOpacity = layersToEdit.find((layer) => layer.id === id);
    if (layerToSetOpacity)
      layerToSetOpacity.timeControls = [{ ...layerToSetOpacity.timeControls[0], [prop]: value }];
    // @ts-ignore
    onMapLayerChange('wdSpace[0].symbolLayers', layersToEdit);
  };
  const setIndicatorPriority = (
    e: number,
    id: string,
    layerType: 'gribMapLayers' | 'radarMapLayers' | 'satelliteMapLayers',
  ) => {
    dispatch(
      updateIndicatorPriority({
        priority: e,
        activeScene,
        layerType,
        layerId: id,
        elementId: activeElement,
      }),
    );
  };
  const enableRadarLayer = (e: boolean, id: string) => {
    onFocus('radarMapLayers');
    const layersToEdit = cloneDeep(mapLayer.wdSpace[0].radarMapLayers);
    const layerToEnable = layersToEdit.find((layer) => layer.radarSource.id === id);
    if (layerToEnable) layerToEnable.enabled = e;
    // @ts-ignore
    onMapLayerChange('wdSpace[0].radarMapLayers', layersToEdit);
  };
  const enableSatelliteLayer = (e: boolean, id: string) => {
    onFocus('satelliteMapLayers');
    const layersToEdit = cloneDeep(mapLayer.wdSpace[0].satelliteMapLayers);
    const layerToEnable = layersToEdit.find((layer) => layer.satelliteSource.id === id);
    if (layerToEnable) layerToEnable.enabled = e;
    // @ts-ignore
    onMapLayerChange('wdSpace[0].satelliteMapLayers', layersToEdit);
  };
  const enableSymbolLayer = (e: boolean, id: string) => {
    onFocus('symbolLayers');
    const layersToEdit = cloneDeep(mapLayer.wdSpace[0].symbolLayers);
    // add for point data?
    const layerToEnable = layersToEdit.find((layer) => layer?.id === id);
    if (layerToEnable) {
      layerToEnable.enabled = e;
      !layerToEnable.enabled && dispatch(setSymbolEditingLayerId(''));
    }
    // @ts-ignore
    onMapLayerChange('wdSpace[0].symbolLayers', layersToEdit);
  };
  const enableEventLayer = (e: boolean, id: string) => {
    onFocus('eventLayers');
    const layersToEdit = cloneDeep(mapLayer.wdSpace[0].eventLayers);
    // add for point data?
    const layerToEnable = layersToEdit.find((layer) => layer?.id === id);
    if (layerToEnable) layerToEnable.enabled = e;
    // @ts-ignore
    onMapLayerChange('wdSpace[0].eventLayers', layersToEdit);
  };
  const enableGribLegend = (e: boolean, layerSetupId: string) => {
    onFocus('gribMapLayers');
    const layersToEdit = cloneDeep(mapLayer.wdSpace[0].gribMapLayers);
    const legendToEnable = layersToEdit.find((layer) => layer.layerSetup.id === layerSetupId);
    if (legendToEnable) legendToEnable.layerSetup.displayPaletteLegend = e;
    // @ts-ignore
    onMapLayerChange('wdSpace[0].gribMapLayers', layersToEdit);
  };
  const enableRadarLegend = (e: boolean, layerSetupId: string) => {
    const layersToEdit = cloneDeep(mapLayer.wdSpace[0].radarMapLayers);
    const legendToEnable = layersToEdit.find((layer) => layer.layerSetup.id === layerSetupId);
    if (legendToEnable) legendToEnable.layerSetup.displayPaletteLegend = e;
    // @ts-ignore
    onMapLayerChange('wdSpace[0].radarMapLayers', layersToEdit);
  };

  const enableSatelliteLegend = (e: boolean, layerSetupId: string) => {
    onFocus('satelliteMapLayers');
    const layersToEdit = cloneDeep(mapLayer.wdSpace[0].satelliteMapLayers);
    const legendToEnable = layersToEdit.find((layer) => layer.layerSetup.id === layerSetupId);
    if (legendToEnable) legendToEnable.layerSetup.displayPaletteLegend = e;
    // @ts-ignore
    onMapLayerChange('wdSpace[0].satelliteMapLayers', layersToEdit);
  };
  const removeGribLayer = (id: string) => {
    const remainingLayers = mapLayer.wdSpace[0].gribMapLayers.filter(
      (layer) => layer.gribSource.id !== id,
    );
    // @ts-ignore
    onMapLayerChange('wdSpace[0].gribMapLayers', remainingLayers);
    setConfirmationModalVisible(false);
  };
  const removeRadarLayers = (id: string) => {
    const remainingLayers = mapLayer.wdSpace[0].radarMapLayers.filter(
      (layer) => layer.radarSource.id !== id,
    );
    // @ts-ignore
    onMapLayerChange('wdSpace[0].radarMapLayers', remainingLayers);

    setConfirmationModalVisible(false);
  };
  const removeSatelliteLayers = (id: string) => {
    const remainingLayers = mapLayer.wdSpace[0].satelliteMapLayers.filter(
      (layer) => layer.satelliteSource.id !== id,
    );
    // @ts-ignore
    onMapLayerChange('wdSpace[0].satelliteMapLayers', remainingLayers);

    setConfirmationModalVisible(false);
  };
  const removeSymbolLayer = (id: string) => {
    const remainingLayers = mapLayer.wdSpace[0].symbolLayers.filter(
      (layer) => layer && layer.id !== id,
    );
    // @ts-ignore
    onMapLayerChange('wdSpace[0].symbolLayers', remainingLayers);
    setConfirmationModalVisible(false);
  };
  const removeEventLayer = (id: string) => {
    const remainingLayers = mapLayer.wdSpace[0].eventLayers.filter(
      (layer) => layer && layer.id !== id,
    );
    // @ts-ignore
    onMapLayerChange('wdSpace[0].eventLayers', remainingLayers);
    setConfirmationModalVisible(false);
  };
  const changeZindex = (
    e: any,
    type:
      | 'gribMapLayers'
      | 'radarMapLayers'
      | 'satelliteMapLayers'
      | 'symbolLayers'
      | 'eventLayers',
    index: number,
  ) => {
    onFocus(type);
    const cloned = cloneDeep(mapLayer);
    const found = cloned.wdSpace[0][type][index];
    if (found) {
      found.zindex = Number(e);
    }
    // @ts-ignore
    onMapLayerChange(`wdSpace[0].${type}`, cloned.wdSpace[0][type]);
  };

  function onTemplateSave(
    layer: GribMapLayer | RadarMapLayer | SatelliteMapLayer | SymbolLayerDef,
  ) {
    setOpened(true);
    setTemplateLayer(layer);
  }

  const renderGribLayers = () => {
    return (
      <div className="ml-8 bg-black">
        {mapLayer.wdSpace[0].gribMapLayers.map((layer, i) => (
          <div key={layer.id} id={layer.id}>
            <div
              className={`mb-2 subheader layer-header no-padding-top ${
                expandedLayers.includes(layer.id) ? 'layer-header-active' : ''
              }`}
              onClick={() => openLayer(layer.id)}
            >
              {expandedLayers.includes(layer.id) ? <AiOutlineCaretUp /> : <AiOutlineCaretDown />}
              <div>
                Layer #{i + 1}: {layer.name}
              </div>
            </div>
            {expandedLayers.includes(layer.id) && (
              <div className="prop-wrapper">
                <ConfirmDeleteModal
                  show={confirmationModalVisible}
                  onConfirm={() => removeGribLayer(layer.gribSource.id)}
                  onClose={() => onCloseConfirmationModal()}
                />
                <div className="float-right mb-1 property-grey-actions-wrapper">
                  <TemplateButton
                    buttonType="border"
                    label={
                      layer.gribSource.templateId ? 'Save new template version' : 'Save as template'
                    }
                    icon={<IoSaveOutline style={{ width: 12, height: 12 }} />}
                    onClick={() => onTemplateSave(layer)}
                    className="flex items-center gap-1 property-grey-buttons"
                  />
                </div>
                <div key={layer.gribSource.id} className={`accordion-body`}>
                  <GridWrapper className="layer-info-wrapper">
                    <GridItem label="Name:" item={layer.name} />
                    <GridItem label="Parameter:" item={layer.gribSource.parameterType.name} />
                    <GridItem label="Source:" item={layer.gribSource.gribSource.name} />
                    <GridItem
                      label="Layer level:"
                      noBorderBg
                      item={
                        <InputNumber
                          style={inputStyle}
                          value={layer.zindex}
                          onChange={(e) => changeZindex(e, 'gribMapLayers', i)}
                          min={0}
                          max={MAX_ZINDEX_VALUE}
                          step={1}
                        />
                      }
                    />
                    {layer.dataFrames.length > 0 && (
                      <>
                        <GridItem
                          label="Range Start:"
                          title={`${format(
                            new Date(layer.dataFrames[0].timestamp * 1000),
                            'EEEE dd.MM.yyyy HH:mm:ss',
                          )} - ${format(
                            new Date(
                              layer.dataFrames[layer.dataFrames.length - 1].timestamp * 1000,
                            ),
                            'EEEE dd.MM HH:mm:ss',
                          )}`}
                          item={
                            <div>
                              {format(
                                new Date(layer.dataFrames[0].timestamp * 1000),
                                'EEEE dd.MM.yyyy HH:mm:ss',
                              )}
                            </div>
                          }
                        />
                        <GridItem
                          label="Range End:"
                          title={`${format(
                            new Date(layer.dataFrames[0].timestamp * 1000),
                            'EEEE dd.MM.yyyy HH:mm:ss',
                          )} - ${format(
                            new Date(
                              layer.dataFrames[layer.dataFrames.length - 1].timestamp * 1000,
                            ),
                            'EEEE dd.MM HH:mm:ss',
                          )}`}
                          item={
                            <div>
                              {format(
                                new Date(
                                  layer.dataFrames[layer.dataFrames.length - 1].timestamp * 1000,
                                ),
                                'EEEE dd.MM HH:mm:ss',
                              )}
                            </div>
                          }
                        />
                      </>
                    )}
                    {layer.layerSetup?.interpolation && (
                      <GridItem label="Interpolation:" item={layer.layerSetup?.interpolation} />
                    )}
                    {layer.layerSetup?.visualisationType === VisualisationTypeEnum.ISOLINE && (
                      <GridItem
                        label="Isoline width:"
                        noBorderBg
                        item={
                          <InputNumber
                            style={inputStyle}
                            value={layer.layerSetup?.isolineWidth}
                            onChange={(e) => changeIsolineWidth(e || 0, layer.layerSetup.id)}
                            min={0.1}
                            max={100}
                          />
                        }
                      />
                    )}
                    {layer.layerSetup?.preprocessing && (
                      <GridItem label="Filtering type:" item={layer.layerSetup?.preprocessing} />
                    )}
                    {layer.layerSetup?.postprocessing && (
                      <GridItem label="Filtering type:" item={layer.layerSetup?.postprocessing} />
                    )}
                    {layer.layerSetup?.visualisationType === VisualisationTypeEnum.PARTICLE ? (
                      <GridItem
                        label="Particle density:"
                        item={
                          ParticleDensityText[
                            layer.layerSetup?.windParticles?.particleDensity as ParticleDensityEnum
                          ]
                        }
                      />
                    ) : null}
                    <GridItem
                      noBorderBg
                      label="Enabled:"
                      item={
                        <ToggleSwitch
                          checked={layer.enabled}
                          label={'enabled'}
                          onChange={(e) => enableGribLayer(e, layer.gribSource.id)}
                        />
                      }
                    />
                    <GridItem
                      label="Alpha"
                      itemStyle={{ display: 'flex', alignItems: 'center' }}
                      item={
                        <Slider
                          style={{ width: '100%' }}
                          min={0}
                          max={1}
                          step={0.1}
                          value={layer.opacity || 0}
                          onChange={(e) => {
                            if (typeof e === 'number') {
                              setOpacityGrib(e, layer.id);
                            }
                          }}
                        />
                      }
                    />
                    <GridItem
                      noBorderBg
                      label="Realtime indicator:"
                      item={
                        <ToggleSwitch
                          checked={layer.realtime}
                          label={'enabled'}
                          onChange={(e) =>
                            enableRealtimeIndicator(e, layer.gribSource.id, 'gribMapLayers')
                          }
                        />
                      }
                    />
                    <GridItem
                      label="Indicator priority:"
                      noBorderBg
                      item={
                        <InputNumber
                          precision={0}
                          style={inputStyle}
                          value={layer.indicatorPriority}
                          onChange={(e) => setIndicatorPriority(e || 0, layer.id, 'gribMapLayers')}
                        />
                      }
                    />
                  </GridWrapper>
                  <h4 style={{ marginTop: '1rem' }}>Color palette</h4>
                  {layer.layerSetup?.colorPaletteDef && (
                    <GridWrapper className="color-palette-wrapper">
                      {layer.layerSetup?.colorPaletteDef?.name && (
                        <GridItem label="Name:" item={layer.layerSetup?.colorPaletteDef?.name} />
                      )}
                      {layer.layerSetup?.colorPaletteDef?.colorStops?.interval !== null ? (
                        <GridItem
                          label="Interval:"
                          item={layer.layerSetup?.colorPaletteDef?.colorStops?.interval}
                        />
                      ) : null}
                      <GridItem
                        noBorderBg
                        label="Legend:"
                        item={
                          <ToggleSwitch
                            checked={layer.layerSetup?.displayPaletteLegend}
                            label={'legend'}
                            onChange={(e) => enableGribLegend(e, layer.layerSetup.id)}
                          />
                        }
                      />
                    </GridWrapper>
                  )}
                  <GridActions>
                    <Button color="failure" onClick={() => setConfirmationModalVisible(true)}>
                      <span>Delete layer</span>
                    </Button>
                    <Button color="info" onClick={() => onEditClick(layer.id)}>
                      <span>Edit layer</span>
                    </Button>
                  </GridActions>
                  {editId === layer.id && (
                    <MapGribLayersModal
                      opened={editId === layer.id}
                      mapLayer={mapLayer}
                      onClose={() => setEditId(null)}
                      gribLayerEdit={layer}
                    />
                  )}
                </div>
                <GridWrapper>
                  <AnimationPicker
                    timeControls={layer.timeControls[0]}
                    onChange={(value, prop) => setGribAnimation(layer.id, prop, value)}
                  />
                </GridWrapper>
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };
  const renderRadarLayers = () => {
    return (
      <div className="ml-8 bg-black">
        {mapLayer.wdSpace[0].radarMapLayers.map((layer, i) => (
          <div key={layer.id} id={layer.id}>
            <div
              className={`mb-2 subheader layer-header no-padding-top ${
                expandedLayers.includes(layer.id) ? 'layer-header-active' : ''
              }`}
              onClick={() => openLayer(layer.id)}
            >
              {expandedLayers.includes(layer.id) ? <AiOutlineCaretUp /> : <AiOutlineCaretDown />}
              <div>
                Layer #{i + 1}: {layer.name}
              </div>
            </div>
            {expandedLayers.includes(layer.id) && (
              <div className="prop-wrapper">
                <ConfirmDeleteModal
                  show={confirmationModalVisible}
                  onConfirm={() => removeRadarLayers(layer.radarSource.id)}
                  onClose={() => onCloseConfirmationModal()}
                />
                <div className="float-right mb-1 property-grey-actions-wrapper">
                  <TemplateButton
                    buttonType="border"
                    label={
                      layer.radarSource.templateId
                        ? 'Save new template version'
                        : 'Save as template'
                    }
                    icon={<IoSaveOutline style={{ width: 12, height: 12 }} />}
                    onClick={() => onTemplateSave(layer)}
                    className="flex items-center gap-1 property-grey-buttons"
                  />
                </div>
                <div key={layer.radarSource.id} className={'accordion-body'}>
                  <GridWrapper>
                    {layer.radarSource.name && (
                      <GridItem label="Name:" item={layer.radarSource.name} />
                    )}
                    {layer.radarSource.radarSource.name && (
                      <GridItem label="Source:" item={layer.radarSource.radarSource.name} />
                    )}

                    <GridItem
                      label="Layer level:"
                      noBorderBg
                      item={
                        <InputNumber
                          style={inputStyle}
                          value={layer.zindex}
                          onChange={(e) => changeZindex(e, 'radarMapLayers', i)}
                          min={0}
                          max={MAX_ZINDEX_VALUE}
                          step={1}
                        />
                      }
                    />
                    {layer.dataFrames.length > 0 ? (
                      <>
                        <GridItem
                          label="Range Start:"
                          title={`${format(
                            new Date(layer.dataFrames[0].timestamp * 1000),
                            'EEEE dd.MM.yyyy HH:mm:ss',
                          )} - ${format(
                            new Date(
                              layer.dataFrames[layer.dataFrames.length - 1].timestamp * 1000,
                            ),
                            'EEEE dd.MM HH:mm:ss',
                          )}`}
                          item={
                            <div>
                              {format(
                                new Date(layer.dataFrames[0].timestamp * 1000),
                                'EEEE dd.MM.yyyy HH:mm:ss',
                              )}
                            </div>
                          }
                        />
                        <GridItem
                          label="Range End:"
                          title={`${format(
                            new Date(layer.dataFrames[0].timestamp * 1000),
                            'EEEE dd.MM.yyyy HH:mm:ss',
                          )} - ${format(
                            new Date(
                              layer.dataFrames[layer.dataFrames.length - 1].timestamp * 1000,
                            ),
                            'EEEE dd.MM HH:mm:ss',
                          )}`}
                          item={
                            <div>
                              {format(
                                new Date(
                                  layer.dataFrames[layer.dataFrames.length - 1].timestamp * 1000,
                                ),
                                'EEEE dd.MM HH:mm:ss',
                              )}
                            </div>
                          }
                        />
                      </>
                    ) : null}

                    <GridItem
                      noBorderBg
                      label="Enabled:"
                      item={
                        <ToggleSwitch
                          checked={layer.enabled}
                          label={'enabled'}
                          onChange={(e) => enableRadarLayer(e, layer.radarSource.id)}
                        />
                      }
                    />
                    <GridItem
                      label="Alpha"
                      itemStyle={{ display: 'flex', alignItems: 'center' }}
                      item={
                        <Slider
                          style={{ width: '100%' }}
                          min={0}
                          max={1}
                          step={0.1}
                          value={layer.opacity || 0}
                          onChange={(e) => {
                            if (typeof e === 'number') {
                              setOpacityRadar(e, layer.id);
                            }
                          }}
                        />
                      }
                    />
                    <GridItem
                      noBorderBg
                      label="Realtime indicator:"
                      item={
                        <ToggleSwitch
                          checked={layer.realtime}
                          label={'enabled'}
                          onChange={(e) =>
                            enableRealtimeIndicator(e, layer.radarSource.id, 'radarMapLayers')
                          }
                        />
                      }
                    />
                    <GridItem
                      label="Indicator priority:"
                      noBorderBg
                      item={
                        <InputNumber
                          precision={0}
                          style={inputStyle}
                          value={layer.indicatorPriority}
                          onChange={(e) => setIndicatorPriority(e || 0, layer.id, 'radarMapLayers')}
                        />
                      }
                    />
                  </GridWrapper>
                  <h4 style={{ marginTop: '1rem' }}>Color palette</h4>
                  {layer.layerSetup?.colorPaletteDef && (
                    <GridWrapper className="color-palette-wrapper">
                      {layer.layerSetup?.colorPaletteDef?.name && (
                        <GridItem label="Name:" item={layer.layerSetup?.colorPaletteDef?.name} />
                      )}
                      {layer.layerSetup?.colorPaletteDef?.colorStops?.interval !== null ? (
                        <GridItem
                          label="Interval:"
                          item={layer.layerSetup?.colorPaletteDef?.colorStops?.interval}
                        />
                      ) : null}
                      <GridItem
                        noBorderBg
                        label="Legend:"
                        item={
                          <ToggleSwitch
                            checked={layer.layerSetup?.displayPaletteLegend}
                            label={'legend'}
                            onChange={(e) => enableRadarLegend(e, layer.layerSetup.id)}
                          />
                        }
                      />
                    </GridWrapper>
                  )}
                  <GridActions className="delete-layer">
                    <Button color="failure" onClick={() => setConfirmationModalVisible(true)}>
                      <span>Delete layer</span>
                    </Button>
                    <Button color="info" onClick={() => onEditClick(layer.id)}>
                      <span>Edit layer</span>
                    </Button>
                  </GridActions>
                  {editId === layer.id && (
                    <MapRadarLayersModal
                      opened={editId === layer.id}
                      mapLayer={mapLayer}
                      onClose={() => setEditId(null)}
                      radarLayerEdit={layer}
                    />
                  )}
                </div>
                <GridWrapper>
                  <AnimationPicker
                    timeControls={layer.timeControls[0]}
                    onChange={(value, prop) => setRadarAnimation(layer.id, prop, value)}
                  />
                </GridWrapper>
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };
  const renderSatelliteLayers = () => {
    return (
      <>
        <div className="ml-8 bg-black">
          {mapLayer.wdSpace[0].satelliteMapLayers.map((layer, i) => (
            <div key={layer.id} id={layer.id}>
              <div
                className={`mb-2 subheader layer-header no-padding-top ${
                  expandedLayers.includes(layer.id) ? 'layer-header-active' : ''
                }`}
                onClick={() => openLayer(layer.id)}
              >
                {expandedLayers.includes(layer.id) ? <AiOutlineCaretUp /> : <AiOutlineCaretDown />}
                <div>
                  Layer #{i + 1}: {layer.name}
                </div>
              </div>
              {expandedLayers.includes(layer.id) && (
                <div className="prop-wrapper">
                  <ConfirmDeleteModal
                    show={confirmationModalVisible}
                    onConfirm={() => removeSatelliteLayers(layer.satelliteSource.id)}
                    onClose={() => onCloseConfirmationModal()}
                  />
                  <div className="float-right mb-1 property-grey-actions-wrapper">
                    <TemplateButton
                      buttonType="border"
                      label={
                        layer.satelliteSource.templateId
                          ? 'Save new template version'
                          : 'Save as template'
                      }
                      icon={<IoSaveOutline style={{ width: 12, height: 12 }} />}
                      onClick={() => onTemplateSave(layer)}
                      className="flex items-center gap-1 property-grey-buttons"
                    />
                  </div>
                  <div key={layer.satelliteSource.id} className={'accordion-body'}>
                    <div className={'grid prop-grid-4'}>
                      {layer.satelliteSource.name && (
                        <GridItem label="Name:" item={layer.satelliteSource.name} />
                      )}
                      {layer.satelliteSource.satelliteType.name && (
                        <GridItem label="Type:" item={layer.satelliteSource.satelliteType.name} />
                      )}
                      {layer.satelliteSource.satelliteSource.name && (
                        <GridItem
                          label="Source:"
                          item={layer.satelliteSource.satelliteSource.name}
                        />
                      )}
                      <GridItem
                        label="Range Start:"
                        title={`${format(
                          new Date(layer.dataFrames[0].timestamp * 1000),
                          'EEEE dd.MM.yyyy HH:mm:ss',
                        )} - ${format(
                          new Date(layer.dataFrames[layer.dataFrames.length - 1].timestamp * 1000),
                          'EEEE dd.MM HH:mm:ss',
                        )}`}
                        item={
                          <div>
                            {format(
                              new Date(layer.dataFrames[0].timestamp * 1000),
                              'EEEE dd.MM.yyyy HH:mm:ss',
                            )}
                          </div>
                        }
                      />
                      <GridItem
                        label="Range End:"
                        title={`${format(
                          new Date(layer.dataFrames[0].timestamp * 1000),
                          'EEEE dd.MM.yyyy HH:mm:ss',
                        )} - ${format(
                          new Date(layer.dataFrames[layer.dataFrames.length - 1].timestamp * 1000),
                          'EEEE dd.MM HH:mm:ss',
                        )}`}
                        item={
                          <div>
                            {format(
                              new Date(
                                layer.dataFrames[layer.dataFrames.length - 1].timestamp * 1000,
                              ),
                              'EEEE dd.MM HH:mm:ss',
                            )}
                          </div>
                        }
                      />
                      <GridItem
                        label="Layer level:"
                        noBorderBg
                        item={
                          <InputNumber
                            style={inputStyle}
                            value={layer.zindex}
                            onChange={(e) => changeZindex(e, 'satelliteMapLayers', i)}
                            min={0}
                            max={MAX_ZINDEX_VALUE}
                            step={1}
                          />
                        }
                      />
                      <GridItem
                        noBorderBg
                        label="Enable interpolation:"
                        item={layer.enableInterpolation ? 'Yes' : 'No'}
                      />

                      <GridItem
                        noBorderBg
                        label="Enabled:"
                        item={
                          <ToggleSwitch
                            checked={layer.enabled}
                            label={'enabled'}
                            onChange={(e) => enableSatelliteLayer(e, layer.satelliteSource.id)}
                          />
                        }
                      />
                    </div>
                    <h4 style={{ marginTop: '1rem' }}>Color palette</h4>
                    {Boolean(layer.layerSetup?.colorPaletteDef) && (
                      <GridWrapper className="color-palette-wrapper">
                        {layer.layerSetup.colorPaletteDef?.name && (
                          <GridItem label="Name:" item={layer.layerSetup.colorPaletteDef?.name} />
                        )}
                        {layer.layerSetup.colorPaletteDef?.colorStops?.interval !== null ? (
                          <GridItem
                            label="Interval:"
                            item={layer.layerSetup.colorPaletteDef?.colorStops?.interval}
                          />
                        ) : null}
                        <GridItem
                          noBorderBg
                          label="Legend:"
                          item={
                            <ToggleSwitch
                              checked={layer.layerSetup?.displayPaletteLegend}
                              label={'legend'}
                              onChange={(e) => enableSatelliteLegend(e, layer.layerSetup.id)}
                            />
                          }
                        />
                        <GridItem
                          label="Alpha"
                          itemStyle={{ display: 'flex', alignItems: 'center' }}
                          item={
                            <Slider
                              style={{ width: '100%' }}
                              min={0}
                              max={1}
                              step={0.1}
                              value={layer.opacity || 0}
                              onChange={(e) => {
                                if (typeof e === 'number') {
                                  setOpacitySatellite(e, layer.id);
                                }
                              }}
                            />
                          }
                        />
                        <GridItem
                          noBorderBg
                          label="Realtime indicator:"
                          item={
                            <ToggleSwitch
                              checked={layer.realtime}
                              label={'enabled'}
                              onChange={(e) =>
                                enableRealtimeIndicator(
                                  e,
                                  layer.satelliteSource.id,
                                  'satelliteMapLayers',
                                )
                              }
                            />
                          }
                        />
                        <GridItem
                          noBorderBg
                          label="Indicator priority:"
                          item={
                            <InputNumber
                              precision={0}
                              style={inputStyle}
                              value={layer.indicatorPriority}
                              onChange={(e) =>
                                setIndicatorPriority(e || 0, layer.id, 'satelliteMapLayers')
                              }
                            />
                          }
                        />
                      </GridWrapper>
                    )}
                    <GridActions className="delete-layer">
                      <Button color="failure" onClick={() => setConfirmationModalVisible(true)}>
                        <span>Delete layer</span>
                      </Button>
                      <Button color="info" onClick={() => onEditClick(layer.id)}>
                        <span>Edit layer</span>
                      </Button>
                    </GridActions>
                    {editId === layer.id && (
                      <MapSatelliteLayerModal
                        opened={editId === layer.id}
                        mapLayer={mapLayer}
                        onClose={() => setEditId(null)}
                        satelliteLayerEdit={layer}
                      />
                    )}
                  </div>
                  <GridWrapper>
                    <AnimationPicker
                      timeControls={layer.timeControls[0]}
                      onChange={(value, prop) => setSatelliteAnimation(layer.id, prop, value)}
                    />
                  </GridWrapper>
                </div>
              )}
            </div>
          ))}
        </div>
      </>
    );
  };

  const deletePoints = (id: string) => {
    confirm({
      message: `Are you sure you want to delete all points?`,
      onConfirm: () => {
        WeatherDataLoader.setPoints(id, []);
        dispatch(setSymbolLayerPoints({ id, points: [] }));
        dispatch(setNewPoint(false));
        dispatch(addSymbolNewOverrides()); // triger rerender on canvas
      },
    });
  };

  const renderSymbolLayers = () => {
    return (
      <div className="ml-8 bg-black">
        {mapLayer.wdSpace[0].symbolLayers?.map((layer, i) => (
          <div key={layer.id} id={layer.id}>
            <div
              className={`mb-2 subheader layer-header no-padding-top ${
                expandedLayers.includes(layer.id) ? 'layer-header-active' : ''
              }`}
              onClick={() => openLayer(layer.id)}
            >
              {expandedLayers.includes(layer.id) ? <AiOutlineCaretUp /> : <AiOutlineCaretDown />}
              <div>
                Layer #{i + 1} (
                {layer.symbolSource.sourceType === SymbolSourceType.ModelData ? 'Model' : 'Point'}
                {layer.symbolSource.sourceType === SymbolSourceType.PointData &&
                  ` - ${layer.symbolSource.pointType}`}
                ) : {layer.name}
              </div>
            </div>
            {expandedLayers.includes(layer.id) && (
              <div className="prop-wrapper">
                <ConfirmDeleteModal
                  show={confirmationModalVisible}
                  //@ts-ignore
                  onConfirm={() => removeSymbolLayer(layer.id)}
                  onClose={() => onCloseConfirmationModal()}
                />
                <div className="float-right mb-1 property-grey-actions-wrapper">
                  <TemplateButton
                    buttonType="border"
                    label={
                      layer.symbolSource.templateId
                        ? 'Save new template version'
                        : 'Save as template'
                    }
                    icon={<IoSaveOutline style={{ width: 12, height: 12 }} />}
                    onClick={() => onTemplateSave(layer)}
                    className="flex items-center gap-1 property-grey-buttons"
                  />
                </div>
                <div key={layer.symbolSource.id} className={`accordion-body`}>
                  <GridWrapper className="layer-info-wrapper">
                    <GridItem label="Name:" item={layer.name} />
                    <GridItem
                      label="Parameter:"
                      item={
                        layer.symbolSource.sourceType == SymbolSourceType.ModelData
                          ? layer.symbolSource.gribSource.parameterType.name
                          : layer.symbolSource.pointParameter
                      }
                    />
                    {layer.symbolSource.sourceType == SymbolSourceType.ModelData ? (
                      <GridItem
                        label="Source:"
                        item={layer.symbolSource.gribSource.gribSource.name}
                      />
                    ) : null}
                    <GridItem
                      label="Layer level:"
                      noBorderBg
                      item={
                        <InputNumber
                          style={inputStyle}
                          value={layer.zindex}
                          onChange={(e) => changeZindex(e, 'symbolLayers', i)}
                          min={0}
                          max={MAX_ZINDEX_VALUE}
                          step={1}
                        />
                      }
                    />
                    {layer.dataFrames.length > 0 && (
                      <>
                        <GridItem
                          label="Range Start:"
                          title={`${format(
                            new Date(layer.dataFrames[0].timestamp * 1000),
                            'EEEE dd.MM.yyyy HH:mm:ss',
                          )} - ${format(
                            new Date(
                              layer.dataFrames[layer.dataFrames.length - 1].timestamp * 1000,
                            ),
                            'EEEE dd.MM.yyyy HH:mm:ss',
                          )}`}
                          item={
                            <div>
                              {format(
                                new Date(layer.dataFrames[0].timestamp * 1000),
                                'EEEE dd.MM.yyyy HH:mm:ss',
                              )}
                            </div>
                          }
                        />
                        <GridItem
                          label="Range End:"
                          title={`${format(
                            new Date(layer.dataFrames[0].timestamp * 1000),
                            'EEEE dd.MM.yyyy HH:mm:ss',
                          )} - ${format(
                            new Date(
                              layer.dataFrames[layer.dataFrames.length - 1].timestamp * 1000,
                            ),
                            'EEEE dd.MM.yyyy HH:mm:ss',
                          )}`}
                          item={
                            <div>
                              {format(
                                new Date(
                                  layer.dataFrames[layer.dataFrames.length - 1].timestamp * 1000,
                                ),
                                'EEEE dd.MM.yyyy HH:mm:ss',
                              )}
                            </div>
                          }
                        />
                      </>
                    )}
                    {layer.symbolSource.pointDataFrames &&
                      layer.symbolSource.pointDataFrames.length > 0 && (
                        <>
                          <GridItem
                            label="Range Start:"
                            title={`${format(
                              new Date(layer.symbolSource.pointDataFrames[0].startDate * 1000),
                              'EEEE dd.MM.yyyy HH:mm:ss',
                            )} - ${format(
                              new Date(
                                layer.symbolSource.pointDataFrames[
                                  layer.symbolSource.pointDataFrames.length - 1
                                ].startDate * 1000,
                              ),
                              'EEEE dd.MM.yyyy HH:mm:ss',
                            )}`}
                            item={
                              <div>
                                {format(
                                  new Date(layer.symbolSource.pointDataFrames[0].startDate * 1000),
                                  'EEEE dd.MM.yyyy HH:mm:ss',
                                )}
                              </div>
                            }
                          />
                          <GridItem
                            label="Range End:"
                            title={`${format(
                              new Date(layer.symbolSource.pointDataFrames[0].startDate * 1000),
                              'EEEE dd.MM.yyyy HH:mm:ss',
                            )} - ${format(
                              new Date(
                                layer.symbolSource.pointDataFrames[
                                  layer.symbolSource.pointDataFrames.length - 1
                                ].startDate * 1000,
                              ),
                              'EEEE dd.MM.yyyy HH:mm:ss',
                            )}`}
                            item={
                              <div>
                                {format(
                                  new Date(
                                    layer.symbolSource.pointDataFrames[
                                      layer.symbolSource.pointDataFrames.length - 1
                                    ].startDate * 1000,
                                  ),
                                  'EEEE dd.MM.yyyy HH:mm:ss',
                                )}
                              </div>
                            }
                          />
                        </>
                      )}
                    <GridItem
                      noBorderBg
                      label="Enabled:"
                      item={
                        <ToggleSwitch
                          checked={layer.enabled}
                          label={'enabled'}
                          onChange={(e) =>
                            // add for point data?
                            layer.id && enableSymbolLayer(e, layer.id)
                          }
                        />
                      }
                    />
                  </GridWrapper>
                  <GridActions className="pb-4">
                    <Button color="failure" onClick={() => setConfirmationModalVisible(true)}>
                      <span>Delete layer</span>
                    </Button>
                    <Button color="info" onClick={() => onEditClick(layer.id)}>
                      <span>Edit layer</span>
                    </Button>
                  </GridActions>
                  {editId === layer.id && (
                    <MapSymbolLayersModal
                      opened={editId === layer.id}
                      mapLayer={mapLayer}
                      onClose={() => setEditId(null)}
                      gribLayerEdit={layer}
                    />
                  )}
                </div>
                <div className="accordion-body pb-4">
                  <GridWrapper>
                    <GridItem
                      noBorderBg
                      label="Edit locations:"
                      item={
                        <ToggleSwitch
                          checked={symbolEditingLayerId == layer.id}
                          label={'edit locations'}
                          onChange={(e) => {
                            if (e) {
                              dispatch(setSymbolEditingLayerId(layer.id));
                            } else {
                              dispatch(setSymbolEditingLayerId(''));
                            }
                          }}
                        />
                      }
                    />
                  </GridWrapper>
                  {/* <div className="px-4">
                    <BoxStyle
                      box={layer.defaultStyle.boxDef}
                      elementType={ElementsEnum.SYMBOL_LAYER}
                      mapId={mapLayer.id}
                      layerId={layer.id}
                    />
                  </div> */}
                  <SymbolStyleProperties
                    layerId={layer.id}
                    mapId={mapLayer.id}
                    symbolStyle={layer.symbolSource.defaultStyle}
                    isWindLayer={isWindLayer(layer)}
                  />
                </div>
                <div className="accordion-body pb-4">
                  <GridWrapper>
                    <AnimationPicker
                      timeControls={layer.timeControls[0]}
                      onChange={(value, prop) => setSymbolAnimation(layer.id, prop, value)}
                    />
                  </GridWrapper>
                </div>
                <div className="px-4">
                  {layer.symbolSource.points.length > 1 && (
                    <Button
                      color="failure"
                      style={{ marginBottom: 8, padding: 10, marginLeft: 8 }}
                      onClick={() => deletePoints(layer.id)}
                    >
                      <span>Delete all points</span>
                    </Button>
                  )}
                  <SymbolPointsProperties layer={layer} mapPanel={mapLayer} />
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  const renderEventLayers = () => {
    return (
      <div className="ml-8 bg-black">
        {mapLayer.wdSpace[0].eventLayers?.map((layer, i) => (
          <div key={layer.id} id={layer.id}>
            <div
              className={`mb-2 subheader layer-header no-padding-top ${
                expandedLayers.includes(layer.id) ? 'layer-header-active' : ''
              }`}
              onClick={() => openLayer(layer.id)}
            >
              {expandedLayers.includes(layer.id) ? <AiOutlineCaretUp /> : <AiOutlineCaretDown />}
              <div>
                Layer #{i + 1} {layer.name || 'Event Layer'}
              </div>
            </div>
            {expandedLayers.includes(layer.id) && (
              <div className="prop-wrapper">
                <ConfirmDeleteModal
                  show={confirmationModalVisible}
                  //@ts-ignore
                  onConfirm={() => removeEventLayer(layer.id)}
                  onClose={() => onCloseConfirmationModal()}
                />

                <div key={layer.id} className={`accordion-body`}>
                  <GridWrapper className="layer-info-wrapper">
                    <GridItem label="Name:" item={layer.name} />

                    <GridItem
                      label="Layer level:"
                      noBorderBg
                      item={
                        <InputNumber
                          style={inputStyle}
                          value={layer.zindex}
                          onChange={(e) => changeZindex(e, 'eventLayers', i)}
                          min={0}
                          max={MAX_ZINDEX_VALUE}
                          step={1}
                        />
                      }
                    />
                    {layer.dataFrames.length > 0 && (
                      <>
                        <GridItem
                          label="Range Start:"
                          title={`${format(
                            new Date(layer.dataFrames[0].timestamp * 1000),
                            'EEEE dd.MM.yyyy HH:mm:ss',
                          )} - ${format(
                            new Date(
                              layer.dataFrames[layer.dataFrames.length - 1].timestamp * 1000,
                            ),
                            'EEEE dd.MM HH:mm:ss',
                          )}`}
                          item={
                            <div>
                              {format(
                                new Date(layer.dataFrames[0].timestamp * 1000),
                                'EEEE dd.MM.yyyy HH:mm:ss',
                              )}
                            </div>
                          }
                        />
                        <GridItem
                          label="Range End:"
                          title={`${format(
                            new Date(layer.dataFrames[0].timestamp * 1000),
                            'EEEE dd.MM.yyyy HH:mm:ss',
                          )} - ${format(
                            new Date(
                              layer.dataFrames[layer.dataFrames.length - 1].timestamp * 1000,
                            ),
                            'EEEE dd.MM HH:mm:ss',
                          )}`}
                          item={
                            <div>
                              {format(
                                new Date(
                                  layer.dataFrames[layer.dataFrames.length - 1].timestamp * 1000,
                                ),
                                'EEEE dd.MM HH:mm:ss',
                              )}
                            </div>
                          }
                        />
                      </>
                    )}

                    <GridItem
                      noBorderBg
                      label="Enabled:"
                      item={
                        <ToggleSwitch
                          checked={layer.enabled}
                          label={'enabled'}
                          onChange={(e) => layer.id && enableEventLayer(e, layer.id)}
                        />
                      }
                    />
                    <GridItem
                      noBorderBg
                      label="Radius:"
                      item={
                        <InputNumber
                          style={inputStyle}
                          defaultValue={layer.radius || 0}
                          onChange={(e) => onEventLayerPropertyChange(layer.id, 'radius', e)}
                          min={0}
                          max={MAX_ZINDEX_VALUE}
                          step={1}
                        />
                      }
                    />
                    <GridItem
                      noBorderBg
                      label="Color:"
                      item={
                        <ColorPicker
                          defaultValue={layer.color || 'rgba(255, 0, 0, 1)'}
                          onChange={(_, hex) => onEventLayerPropertyChange(layer.id, 'color', hex)}
                        />
                      }
                    />
                  </GridWrapper>
                  <GridActions className="pb-4">
                    <Button color="failure" onClick={() => setConfirmationModalVisible(true)}>
                      <span>Delete layer</span>
                    </Button>
                    <Button color="info" onClick={() => onEditClick(layer.id)}>
                      <span>Edit layer</span>
                    </Button>
                  </GridActions>
                  {editId === layer.id && (
                    <MapEventLayersModal
                      opened={editId === layer.id}
                      mapLayer={mapLayer}
                      onClose={() => setEditId(null)}
                      gribLayerEdit={layer}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  const openLayer = (id: string) => {
    let expLayers = expandedLayers;
    if (expLayers?.includes(id)) {
      expLayers = expLayers.filter((item) => item !== id);
    } else {
      expLayers?.push(id);
      if (layerType === 'symbol') {
        const layersToEdit = cloneDeep(mapLayer.wdSpace[0].symbolLayers);
        const layerToEnable = layersToEdit.find((layer) => layer.id === id);
        layerToEnable?.enabled && dispatch(setSymbolEditingLayerId(id));
      }
    }
    setExpandedLayers([...expLayers]);
  };

  function onConfirm(formData: {
    name: string;
    description: string;
    thumbnailURLs: ScreenshotData[];
  }) {
    const { name, description, thumbnailURLs } = formData;
    const cloneTemplate = cloneDeep(templateLayer);
    if (cloneTemplate) {
      const templateId =
        //@ts-ignore
        cloneTemplate?.gribSource
          ? //@ts-ignore
            cloneTemplate.gribSource.templateId
          : //@ts-ignore
          cloneTemplate?.radarSource
          ? //@ts-ignore
            cloneTemplate.radarSource.templateId
          : //@ts-ignore
          cloneTemplate?.satelliteSource
          ? // @ts-ignore
            cloneTemplate.satelliteSource.templateId
          : // @ts-ignore
            cloneTemplate.symbolSource.templateId;
      if (!cloneTemplate.layerType) {
        cloneTemplate.layerType =
          //@ts-ignore
          cloneTemplate?.gribSource
            ? 'GRIB'
            : //@ts-ignore
            cloneTemplate?.radarSource
            ? 'RADAR'
            : //@ts-ignore
            cloneTemplate?.satelliteSource
            ? 'SATELLITE'
            : 'SYMBOL';
      }
      if (templateId) {
        updateLayerTemplate.mutate({
          layerPanelDef: cloneTemplate!,
          templateId: templateId,
          thumbnailURLs,
          name,
          description,
        });
      } else {
        //@ts-ignore
        if (cloneTemplate.symbolSource) {
          //@ts-ignore
          cloneTemplate.symbolSource.templateId = v4();
        }
        saveLayerTemplate.mutate({
          name,
          description,
          thumbnailURLs,
          layerPanelDef: cloneTemplate,
        });
      }
    }
  }

  const updateLayerTemplate = useMutation(updateLayerAsTemplate, {
    onSuccess: (data) => {
      const proj = cloneDeep(project);
      const scene = proj.sceneDefs.find((sc) => sc.id === activeScene);
      const map = scene?.mapPanels.find((mp) => mp.id === mapLayer?.id);
      let layer;
      if (templateLayer) {
        if (data.layerPanelDef.layerType === 'GRIB') {
          layer = map?.wdSpace[0].gribMapLayers.find(
            (gribLayer: GribMapLayer) => gribLayer.id === templateLayer.id,
          );
          if (!layer) return;
          layer.gribSource.templateVersionId = data.versionId;
        }
        if (data.layerPanelDef.layerType === 'RADAR') {
          layer = map?.wdSpace[0].radarMapLayers.find(
            (radarLayer: RadarMapLayer) => radarLayer.id === templateLayer.id,
          );
          if (!layer) return;
          layer.radarSource.templateVersionId = data.versionId;
        }
        if (data.layerPanelDef.layerType === 'SATELLITE') {
          layer = map?.wdSpace[0].satelliteMapLayers.find(
            (satelliteLayer: SatelliteMapLayer) => satelliteLayer.id === templateLayer.id,
          );
          if (!layer) return;
          layer.satelliteSource.templateVersionId = data.versionId;
        }
        if (data.layerPanelDef.layerType === 'SYMBOL') {
          layer = map?.wdSpace[0].symbolLayers.find(
            (l: SymbolLayerDef) => l.id === templateLayer.id,
          );
          if (!layer) return;
          layer.symbolSource.templateVersionId = data.versionId;
        }
      }
      implicitSave(proj);
      toast.success('Successfully updated layer template!');
      setOpened(false);
    },
  });

  const saveLayerTemplate = useMutation(saveLayerAsTemplate, {
    onSuccess: (data) => {
      const proj = cloneDeep(project);
      const scene = proj.sceneDefs.find((sc) => sc.id === activeScene);
      const map = scene?.mapPanels.find((mp) => mp.id === mapLayer?.id);
      let layer;
      if (templateLayer) {
        if (data.layerPanelDef.layerType === 'GRIB') {
          layer = map?.wdSpace[0].gribMapLayers.find(
            (gribLayer: GribMapLayer) => gribLayer.id === templateLayer.id,
          );
          if (!layer) return;
          layer.gribSource.templateId = data.id;
          layer.gribSource.templateVersionId = data.versionId;
        }
        if (data.layerPanelDef.layerType === 'RADAR') {
          layer = map?.wdSpace[0].radarMapLayers.find(
            (radarLayer: RadarMapLayer) => radarLayer.id === templateLayer.id,
          );
          if (!layer) return;
          layer.radarSource.templateId = data.id;
          layer.radarSource.templateVersionId = data.versionId;
        }
        if (data.layerPanelDef.layerType === 'SATELLITE') {
          layer = map?.wdSpace[0].satelliteMapLayers.find(
            (satelliteLayer: SatelliteMapLayer) => satelliteLayer.id === templateLayer.id,
          );
          if (!layer) return;
          layer.satelliteSource.templateId = data.id;
          layer.satelliteSource.templateVersionId = data.versionId;
        }
        if (data.layerPanelDef.layerType === 'SYMBOL') {
          layer = map?.wdSpace[0].symbolLayers.find(
            (l: SymbolLayerDef) => l.id === templateLayer.id,
          );
          if (!layer) return;
          layer.symbolSource.templateId = data.id;
          layer.symbolSource.templateVersionId = data.versionId;
        }
      }
      implicitSave(proj);
      toast.success('Successfully added layer template!');
      setOpened(false);
    },
  });

  return (
    <>
      <div
        className={`mb-2 subheader layer-header ${
          mapLayer?.wdSpace[0].satelliteMapLayers.length === 0 &&
          mapLayer?.wdSpace[0].radarMapLayers.length === 0 &&
          mapLayer?.wdSpace[0].gribMapLayers.length === 0 &&
          mapLayer?.wdSpace[0].observedDataLayers.length === 0 &&
          mapLayer?.wdSpace[0].forecastDataLayers.length === 0 &&
          mapLayer?.wdSpace[0].symbolLayers.length === 0 &&
          'layer-disabled'
        } ${accordionGribLayrs ? 'layer-header-active' : ''}`}
        onClick={() => setTimeFrame(!timeFrame)}
      >
        <div className="layer-title">
          {timeFrame ? <AiOutlineCaretUp /> : <AiOutlineCaretDown />}
          <span>Timestamp</span>
        </div>
      </div>
      {timeFrame && (
        <div className="prop-wrapper layers-wrapper">
          <TimeFrameIndicator
            space={mapLayer?.wdSpace[0]}
            mapId={mapLayer.id}
            activeScene={activeScene}
          />
        </div>
      )}
      <div
        className={`mb-2 subheader layer-header ${
          mapLayer?.wdSpace[0].gribMapLayers?.length === 0 && 'layer-disabled'
        } ${accordionGribLayrs ? 'layer-header-active' : ''}`}
        onClick={() => {
          dispatch(setLayerType({ mapId: mapLayer.id, layerType: undefined, layerId: undefined }));
          setAccordionGribLayers(!accordionGribLayrs);
        }}
      >
        <div className="layer-title">
          {accordionGribLayrs ? <AiOutlineCaretUp /> : <AiOutlineCaretDown />}
          <span>Model Layers</span>
        </div>
        <Button
          color="cyan"
          disabled={isAddLayersDisabled}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setMapGribLayersModalVisible(true);
          }}
        >
          <AiOutlinePlus /> Add Model layers
        </Button>
      </div>
      {accordionGribLayrs && <div className="prop-wrapper">{renderGribLayers()}</div>}
      <div
        className={`mb-2 subheader layer-header ${
          mapLayer?.wdSpace[0].radarMapLayers?.length === 0 && 'layer-disabled'
        } ${accordionRadarLayers ? 'layer-header-active' : ''}`}
        onClick={() => {
          dispatch(setLayerType({ mapId: mapLayer.id, layerType: undefined, layerId: undefined }));
          setAccordionRadarLayers(!accordionRadarLayers);
        }}
      >
        <div className="layer-title">
          {accordionRadarLayers ? <AiOutlineCaretUp /> : <AiOutlineCaretDown />}
          <span>Radar Layers</span>
        </div>
        <Button
          color="cyan"
          disabled={isAddLayersDisabled}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setMapRadarLayersModalVisible(true);
          }}
        >
          <AiOutlinePlus /> Add Radar layers
        </Button>
      </div>
      {accordionRadarLayers && <div className="prop-wrapper">{renderRadarLayers()}</div>}
      <div
        className={`mb-2 subheader layer-header ${
          mapLayer?.wdSpace[0].satelliteMapLayers?.length === 0 && 'layer-disabled'
        } ${accordionSatelliteLayers ? 'layer-header-active' : ''}`}
        onClick={() => {
          dispatch(setLayerType({ mapId: mapLayer.id, layerType: undefined, layerId: undefined }));
          setAccordionSatelliteLayers(!accordionSatelliteLayers);
        }}
      >
        <div className="layer-title">
          {accordionSatelliteLayers ? <AiOutlineCaretUp /> : <AiOutlineCaretDown />}
          <span>Satellite Layers</span>
        </div>
        <Button
          color="cyan"
          disabled={isAddLayersDisabled}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setMapSatelliteLayerModalVisible(true);
          }}
        >
          <AiOutlinePlus /> Add Satellite layers
        </Button>
      </div>
      {accordionSatelliteLayers && <div className="prop-wrapper">{renderSatelliteLayers()}</div>}
      <div
        className={`mb-2 subheader layer-header ${
          mapLayer?.wdSpace[0].symbolLayers?.length === 0 && 'layer-disabled'
        } ${accordionSymbolLayers ? 'layer-header-active' : ''}`}
        onClick={() => {
          dispatch(setLayerType({ mapId: mapLayer.id, layerType: undefined, layerId: undefined }));
          setAccordionSymbolLayers(!accordionSymbolLayers);
        }}
      >
        <div className="layer-title">
          {accordionSymbolLayers ? <AiOutlineCaretUp /> : <AiOutlineCaretDown />}
          <span>Symbol Layers</span>
        </div>
        <Button
          color="cyan"
          disabled={isAddLayersDisabled}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setMapSymbolLayerModalVisible(true);
          }}
        >
          <AiOutlinePlus /> Add Symbol layers
        </Button>
      </div>
      {accordionSymbolLayers && <div className="prop-wrapper">{renderSymbolLayers()}</div>}
      <div
        className={`mb-2 subheader layer-header ${
          mapLayer?.wdSpace[0].eventLayers?.length === 0 && 'layer-disabled'
        } ${accordionEventLayers ? 'layer-header-active' : ''}`}
        onClick={() => {
          dispatch(setLayerType({ mapId: mapLayer.id, layerType: undefined, layerId: undefined }));
          setAccordionEventLayers((ael) => !ael);
        }}
      >
        <div className="layer-title">
          {accordionEventLayers ? <AiOutlineCaretUp /> : <AiOutlineCaretDown />}
          <span>Event Layers</span>
        </div>
        <Button
          color="cyan"
          disabled={isAddLayersDisabled}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setMapEventLayerModalVisible(true);
          }}
        >
          <AiOutlinePlus /> Add Event layers
        </Button>
      </div>
      {accordionEventLayers && <div className="prop-wrapper">{renderEventLayers()}</div>}
      {mapLayer && mapGribLayersModalVisible && (
        <MapGribLayersModal
          mapLayer={mapLayer}
          onClose={() => setMapGribLayersModalVisible(false)}
          opened={mapGribLayersModalVisible}
        />
      )}
      {mapLayer && mapRadarLayersModalVisible && (
        <MapRadarLayersModal
          mapLayer={mapLayer}
          onClose={() => setMapRadarLayersModalVisible(false)}
          opened={mapRadarLayersModalVisible}
        />
      )}
      {mapLayer && mapSatelliteLayerModalVisible && (
        <MapSatelliteLayerModal
          mapLayer={mapLayer}
          onClose={() => setMapSatelliteLayerModalVisible(false)}
          opened={mapSatelliteLayerModalVisible}
        />
      )}
      {mapLayer && mapSymbolLayerModalVisible && (
        <MapSymbolLayersModal
          mapLayer={mapLayer}
          onClose={() => setMapSymbolLayerModalVisible(false)}
          opened={mapSymbolLayerModalVisible}
        />
      )}
      {mapLayer && mapEventLayerModalVisible && (
        <MapEventLayersModal
          mapLayer={mapLayer}
          onClose={() => setMapEventLayerModalVisible(false)}
          opened={mapEventLayerModalVisible}
        />
      )}
      {mapLayer && applyLayer && mapGribLayersModalVisible && (
        <MapGribLayersModal
          opened={true}
          mapLayer={mapLayer}
          onClose={() => {
            setMapGribLayersModalVisible(false);
            dispatch(
              setApplyLayer({
                layer: undefined,
              }),
            );
          }}
          gribLayerEdit={applyLayer as GribMapLayer}
          applyLayer
        />
      )}
      {mapLayer && applyLayer && mapRadarLayersModalVisible && (
        <MapRadarLayersModal
          mapLayer={mapLayer}
          onClose={() => {
            setMapRadarLayersModalVisible(false);
            dispatch(
              setApplyLayer({
                layer: undefined,
              }),
            );
          }}
          opened={mapRadarLayersModalVisible}
          radarLayerEdit={applyLayer as RadarMapLayer}
          applyLayer
        />
      )}
      {mapLayer && applyLayer && mapSatelliteLayerModalVisible && (
        <MapSatelliteLayerModal
          mapLayer={mapLayer}
          onClose={() => {
            setMapSatelliteLayerModalVisible(false);
            dispatch(
              setApplyLayer({
                layer: undefined,
              }),
            );
          }}
          opened={mapSatelliteLayerModalVisible}
          satelliteLayerEdit={applyLayer as SatelliteMapLayer}
          applyLayer
        />
      )}
      {mapLayer && applyLayer && mapSymbolLayerModalVisible && (
        <MapSymbolLayersModal
          opened={true}
          mapLayer={mapLayer}
          onClose={() => {
            setMapSymbolLayerModalVisible(false);
            dispatch(
              setApplyLayer({
                layer: undefined,
              }),
            );
          }}
          gribLayerEdit={applyLayer as SymbolLayerDef}
          applyLayer
        />
      )}
      {templateLayer && opened && (
        <SaveAsTemplateModal
          entityName={templateLayer.name}
          onClose={() => setOpened(false)}
          onConfirm={onConfirm}
          opened={opened}
          loading={saveLayerTemplate.isLoading}
          templateId={templateLayer.id}
          type={'layer'}
        />
      )}
    </>
  );
};
