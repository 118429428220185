import './LandingPage.scss';

import { useContext, useState } from 'react';
import Carousel from 'react-spring-3d-carousel';

import LPBackground from '../../atoms/LPBackground/LPBackground';
import { useGetPublicApprovedItemsFilter } from '../../hooks/filterUseGetPublicApprovedItemsFilter';
import { useGetCurrency } from '../../hooks/useGetCurrency';
import MarketplaceContext from '../../marketplaceContext';
import { MarketplaceItem } from '../../model/interfaces/MarketplaceItem';
import AntItemCard from '../../molecules/AntItemCard/AntItemCard';
import Card from './Card';
import { categoriesConfig } from './config';

const LandingPage = () => {
  const { tokenEmail, admin } = useContext(MarketplaceContext);
  const path = location.pathname.indexOf('/my-store/');
  const { data: currency } = useGetCurrency();

  const { data: publicApproved } = useGetPublicApprovedItemsFilter(
    true,
    0,
    2,
    path !== -1 ? tokenEmail : '',
    '',
  );

  const renderItems = () => {
    return publicApproved?.content.map((item: MarketplaceItem) => (
      <div className="relative w-full -pb-12" key={item.id}>
        <AntItemCard item={item} isAdmin={admin} email={tokenEmail} imageCard currency={currency} />
      </div>
    ));
  };

  const cardss = [
    {
      key: 1,
      content: (
        <Card imagen="https://updates.theme-fusion.com/wp-content/uploads/2017/12/convertplus_thumbnail.jpg" />
      ),
    },
    {
      key: 2,
      content: (
        <Card imagen="https://updates.theme-fusion.com/wp-content/uploads/2017/12/acf_pro.png" />
      ),
    },
    {
      key: 3,
      content: (
        <Card imagen="https://updates.theme-fusion.com/wp-content/uploads/2017/12/layer_slider_plugin_thumb.png" />
      ),
    },
    {
      key: 4,
      content: (
        <Card imagen="https://updates.theme-fusion.com/wp-content/uploads/2016/08/slider_revolution-1.png" />
      ),
    },
    {
      key: 5,
      content: (
        <Card imagen="https://updates.theme-fusion.com/wp-content/uploads/2019/01/pwa_880_660.jpg" />
      ),
    },
  ];

  const table = cardss.map((element, index) => {
    return { ...element, onClick: () => setGoToSlide(index) };
  });

  const [goToSlide, setGoToSlide] = useState<any>(null);
  const [cards] = useState<any>(table);

  // const [offsetRadius, setOffsetRadius] = useState(2);
  // const [showArrows, setShowArrows] = useState(false);

  // useEffect(() => {
  //   setOffsetRadius(props.offset);
  //   setShowArrows(props.showArrows);
  // }, [props.offset, props.showArrows]);

  return (
    <div className="overflow-hidden m-0 mx-8 section-wrapper">
      {categoriesConfig.slice(0, 5).map((category) => {
        return <LPBackground key={category.name} {...category} />;
      })}
      <div
        className="relative flex flex-col md:flex-row justify-center gap-10 pt-4 max-width-[80%]"
        style={{ margin: '0 auto', maxWidth: '1200px' }}
      >
        <div className="w-full lg:w-1/2 xl:w-1/3" style={{ width: '40%' }}>
          <h1 className="uppercase text-[#2176FF] mt-4">Special offer</h1>
          <h3 className="text-4xl font-extralight w-[90%] tracking-wide	mt-2">
            Experience the power of dynamic data offerings
          </h3>
          <div className={'flex gap-10 mt-20 flex-wrap md:flex-nowrap'}>{renderItems()}</div>
        </div>
        <div className="special-offer-carousel" style={{ width: '60%' }}>
          <Carousel
            slides={cards}
            goToSlide={goToSlide}
            // offsetRadius={offsetRadius}
            showNavigation={false}
            // animationConfig={config.gentle}
          />
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
