import { useMutation } from 'react-query';

import { axiosInstance } from '../../../core/api/axiosInstance';

export const useAddViaUpload = () =>
  useMutation(async (form: FormData) => {
    return await axiosInstance.post('multimedia/add-icon-set-items', form, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  });
