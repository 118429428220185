import { Select } from 'flowbite-react';
import Slider from 'rc-slider';
import { useDispatch, useSelector } from 'react-redux';

import { TimeControlDef } from '../../../model/definitions/TimeControlDef';
import { AnimationsEnum } from '../../../model/enums/AnimationsEnum';
import { ActiveDef, updateMultiselectTime } from '../../../store/slices/active-slice';
import { multiUpdateAnimationType } from '../../../store/slices/project-slice';
import { RootState } from '../../../store/store';
import InputNumber from '../../marketplace-new/atoms/FormatNumber/FormatNumber';
import { Panel } from './components/Panel';
import { PropertySection } from './components/PropertySection';
import styles from './Properties.module.scss';
import GridItem from './shared/GridItem';
import GridWrapper from './shared/GridWrapper';

export const Multiselect = () => {
  const dispatch = useDispatch();
  const { multiselect, activeFramerate, activeScene } = useSelector<RootState>(
    (state) => state.active,
  ) as ActiveDef;
  const allElements = multiselect.map((section) => section.elements).flat();
  const times = allElements.map((element) => element.element.timeControls[0]);
  const allHaveSameInAnimation = times.every(
    (obj) => obj.inAnimationDef === times[0]?.inAnimationDef,
  );
  const allHaveSameOutAnimation = times.every(
    (obj) => obj.outAnimationDef === times[0]?.outAnimationDef,
  );
  const allHaveSameInAnimationDuration = times.every(
    (obj) => obj.inAnimationDuration === times[0]?.inAnimationDuration,
  );
  const allHaveSameOutAnimationDuration = times.every(
    (obj) => obj.outAnimationDuration === times[0]?.outAnimationDuration,
  );
  const inAnimationOptions = Object.keys(AnimationsEnum)
    .slice(0, -1)
    .map((k) => (
      <option key={k} value={k}>
        {k.charAt(0).toUpperCase() + k.slice(1).toLowerCase().replace(/_/, ' ')}
      </option>
    ));
  const outAnimationOptions = Object.keys(AnimationsEnum)
    .filter((e) => e !== 'FADE_IN')
    .map((k) => (
      <option key={k} value={k}>
        {k.charAt(0).toUpperCase() + k.slice(1).toLowerCase().replace(/_/, ' ')}
      </option>
    ));
  const updateAnimationType = (prop: keyof TimeControlDef, value: any) => {
    dispatch(updateMultiselectTime({ prop, value }));
    dispatch(
      multiUpdateAnimationType({
        activeScene,
        selection: multiselect,
        prop,
        value,
      }),
    );
  };
  return (
    <Panel>
      {multiselect.length === 0 ? (
        <div className={`${styles.wrapper} justify-center items-center`}>
          <div className={'flex justify-center items-center h-full min-h-[200px]'}>
            <p>No element selected</p>
          </div>
        </div>
      ) : (
        <PropertySection label={'Animation properties'} isOpened={true}>
          <GridWrapper>
            <GridItem
              label={`In animation:`}
              item={
                <Select
                  key={`In_animation_${
                    multiselect.map((item) => item.elements).length
                  }_${allHaveSameInAnimation}`}
                  className={styles.select}
                  value={allHaveSameInAnimation ? times[0]?.inAnimationDef : undefined}
                  onChange={(e) =>
                    updateAnimationType('inAnimationDef', e.target.value as AnimationsEnum)
                  }
                >
                  <option value={undefined}></option>
                  {inAnimationOptions}
                </Select>
              }
            />

            <GridItem
              noBorderBg
              label="Duration:"
              item={
                <>
                  <Slider
                    min={0}
                    max={activeFramerate * 2}
                    value={
                      allHaveSameInAnimationDuration
                        ? ((times[0]?.inAnimationDuration ?? 0) / 1000) * activeFramerate
                        : undefined
                    }
                    disabled={(times[0]?.inAnimationDef ?? 0) === AnimationsEnum.CUT}
                    onAfterChange={(e) => {
                      e &&
                        typeof e === 'number' &&
                        updateAnimationType('inAnimationDuration', (e * 1000) / activeFramerate);
                    }}
                  />
                  <InputNumber
                    type={'number'}
                    min={0}
                    max={activeFramerate * 2}
                    precision={2}
                    disabled={
                      allHaveSameInAnimationDuration &&
                      times[0]?.inAnimationDef === AnimationsEnum.CUT
                    }
                    className={styles.inputWrap}
                    value={
                      allHaveSameInAnimationDuration
                        ? ((times[0]?.inAnimationDuration ?? 0) / 1000) * activeFramerate
                        : undefined
                    }
                    onInputChange={(e) => {
                      e >= 0 &&
                        e <= 10000 &&
                        updateAnimationType('inAnimationDuration', (e * 1000) / activeFramerate);
                    }}
                  />
                  fr
                </>
              }
            />
            <GridItem
              label={`Out animation:`}
              item={
                <Select
                  key={`Out_animation_${
                    multiselect.map((item) => item.elements).length
                  }_${allHaveSameOutAnimation}`}
                  className={styles.select}
                  value={allHaveSameOutAnimation ? times[0]?.outAnimationDef : undefined}
                  onChange={(e) =>
                    updateAnimationType('outAnimationDef', e.target.value as AnimationsEnum)
                  }
                >
                  <option></option>
                  {outAnimationOptions}
                </Select>
              }
            />
            <GridItem
              noBorderBg
              label="Duration:"
              item={
                <>
                  <Slider
                    min={0}
                    max={activeFramerate * 2}
                    value={
                      allHaveSameOutAnimationDuration
                        ? ((times[0]?.outAnimationDuration ?? 0) / 1000) * activeFramerate
                        : undefined
                    }
                    disabled={(times[0]?.outAnimationDef ?? 0) === AnimationsEnum.CUT}
                    onAfterChange={(e) => {
                      e &&
                        typeof e === 'number' &&
                        updateAnimationType('outAnimationDuration', (e * 1000) / activeFramerate);
                    }}
                  />
                  <InputNumber
                    type={'number'}
                    min={0}
                    max={activeFramerate * 2}
                    precision={2}
                    disabled={
                      allHaveSameOutAnimationDuration &&
                      times[0]?.outAnimationDef === AnimationsEnum.CUT
                    }
                    className={styles.inputWrap}
                    value={
                      allHaveSameOutAnimationDuration
                        ? ((times[0]?.outAnimationDuration ?? 0) / 1000) * activeFramerate
                        : undefined
                    }
                    onInputChange={(e) => {
                      e >= 0 &&
                        e <= 10000 &&
                        updateAnimationType('outAnimationDuration', (e * 1000) / activeFramerate);
                    }}
                  />
                  fr
                </>
              }
            />
          </GridWrapper>
        </PropertySection>
      )}
    </Panel>
  );
};
