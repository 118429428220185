export enum FilteringTypeEnum {
  MEDIAN = 'median',
  GAUSSIAN = 'gaussian',
  LOW_BANDPASS = 'low-bandpass',
}

export enum PostProcessingTypeEnum {
  MEDIAN_POST = 'median-post',
  EMBOSS = 'emboss',
}
