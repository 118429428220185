import { Button } from 'flowbite-react';
import { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { queryCity } from '../../../../core/api/WeatherAPI';
import { WeatherDataLoader } from '../../../../core/weather-data/WeatherDataLoader';
import { FrameLoadingResult } from '../../../../core/weather-data/WeatherDataLoaderTypes';
// import { WeatherDataLoader } from '../../../../core/weather-data/WeatherDataLoader';
import { MapPanelDef } from '../../../../model/definitions/MapPanelDef';
import {
  SymbolLayerDef,
  SymbolLayerPointDef,
  SymbolSourceType,
} from '../../../../model/definitions/SymbolLayerDef';
import {
  getCurrentSymbolLayerFrame,
  isWeatherTypeLayer,
  isWindLayer,
} from '../../../../molecules/mapElement/useSymbolLayers';
import {
  ActiveDef,
  addSymbolNewOverrides,
  setNewPoint,
  setSymbolPoint,
} from '../../../../store/slices/active-slice';
import { setSymbolLayerPoints } from '../../../../store/slices/project-slice';
import { RootState } from '../../../../store/store';
import PlayerContext from '../../playerContext/PlayerContext';
import { PropertySection } from '../components/PropertySection';
import styles from '../Properties.module.scss';
import GridItem from '../shared/GridItem';
import GridWrapper from '../shared/GridWrapper';
import { SymbolPointsOverrideProperties } from './SymbolPointsOverrideProperties';

interface Props {
  layer: SymbolLayerDef;
  mapPanel: MapPanelDef;
}

function formatCoordinates(latitude?: number, longitude?: number): string {
  if (!latitude || !longitude) return '';
  latitude = parseFloat(latitude.toFixed(1));
  longitude = parseFloat(longitude.toFixed(1));
  const latitudeDirection = latitude < 0 ? 'S' : 'N';
  latitude = Math.abs(latitude);
  const longitudeDirection = longitude < 0 ? 'W' : 'E';
  longitude = Math.abs(longitude);
  return latitude + '°' + latitudeDirection + ' ' + longitude + '°' + longitudeDirection;
}

export const SymbolPointsProperties = ({ layer, mapPanel }: Props) => {
  // find values for the current frame for this layer - if there is a current frame?
  const { activeFramerate, activeSymbolPoint, mode, projectToPlay } = useSelector<RootState>(
    (state) => state.active,
  ) as ActiveDef;
  const [currentFrame, setCurrentFrame] = useState<{
    result?: FrameLoadingResult | undefined;
    lastingTime: number;
    elapsedTime: number;
  }>();
  const [openedPoints, setOpenedPoints] = useState<string[]>([]);
  const isPointData = layer.symbolSource.sourceType === SymbolSourceType.PointData;
  const precision = layer.symbolSource.defaultStyle.precision;
  const { data: cityData } = useQuery(
    ['cityInfo', layer.symbolSource.points, isPointData],
    async () => {
      const promises = layer.symbolSource.points?.map((p) => {
        return queryCity(p.lon.toFixed(2), p.lat.toFixed(2)).then((cityInfo) => {
          return cityInfo[0];
        });
      });

      return Promise.all(promises);
    },
    { cacheTime: Infinity, staleTime: Infinity, enabled: !isPointData },
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const activeCoordString = activeSymbolPoint
      ? `${activeSymbolPoint[0]},${activeSymbolPoint[1]}`
      : null;

    if (activeCoordString && !openedPoints.includes(activeCoordString)) {
      setOpenedPoints((prevOpenedPoints) => [...prevOpenedPoints, activeCoordString]);
    }
  }, [activeSymbolPoint, openedPoints]);

  const playContext = useContext(PlayerContext);

  const scene = projectToPlay.sceneDefs.find((sc) =>
    sc.mapPanels.some((p) => p.id === mapPanel.id),
  );
  const sceneStartMs = scene?.timeControl.startMS || 0;

  useEffect(() => {
    let timerId: NodeJS.Timeout;
    const fetchData = () => {
      const frame = getCurrentSymbolLayerFrame(
        layer,
        mode,
        activeFramerate,
        sceneStartMs,
        playContext,
      );

      if (frame?.result?.status === 1) {
        setCurrentFrame(frame);
      } else {
        timerId = setTimeout(fetchData, 1000);
      }
    };
    fetchData();
    return () => {
      clearTimeout(timerId);
    };
  }, [layer.symbolSource.points, mode, activeFramerate, sceneStartMs, playContext]);

  const points = currentFrame?.result?.symbolData?.points ?? [];

  const isWind = isWindLayer(layer);
  const isWeatherLayer = isWeatherTypeLayer(layer);
  let labelValue1 = `Value (${isWeatherLayer ? 'Icon' : layer.symbolSource.unit}):`;
  if (isWind) {
    labelValue1 = `Direction (degrees):`;
  }

  const handleOpenedPoints = (
    p: SymbolLayerPointDef,
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    let points;
    const coordinateString = `${p.lon.toFixed(2)},${p.lat.toFixed(2)}`;
    const activeCoordinateString = activeSymbolPoint
      ? `${activeSymbolPoint[0]},${activeSymbolPoint[1]}`
      : '';
    if (openedPoints.includes(coordinateString) || coordinateString === activeCoordinateString) {
      dispatch(setSymbolPoint({ point: undefined }));
      points = openedPoints.filter((coord) => coord !== coordinateString);
    } else {
      points = [...openedPoints, coordinateString];
    }
    setOpenedPoints(points);
  };

  const deletePoint = (index: number) => {
    const newPoints = layer.symbolSource.points.filter((_, i) => i !== index);
    if (currentFrame?.result?.frameId) {
      WeatherDataLoader.setPoints(layer.id, newPoints);
    }
    dispatch(setSymbolLayerPoints({ id: layer.id, points: newPoints }));
    dispatch(setNewPoint(false));
    dispatch(addSymbolNewOverrides()); // triger rerender on canvas
  };

  const renderDeleteButton = (index: number) => {
    return (
      <GridItem
        label=""
        noBorderBg
        item={
          <Button color="failure" onClick={() => deletePoint(index)}>
            <span>Delete</span>
          </Button>
        }
      />
    );
  };

  return (
    <>
      {layer.symbolSource.points.map((p, index) => {
        const isOpened = openedPoints.includes(`${p.lon.toFixed(2)},${p.lat.toFixed(2)}`);
        const point = points?.find(
          (x) => x.lat.toFixed(2) === p.lat.toFixed(2) && x.lon.toFixed(2) === p.lon.toFixed(2),
        );
        const pointValues = point?.val ?? [];
        const originalValues = point?.old_val ?? [];
        const city = point?.city;
        const cityInfo = cityData && cityData[index];
        const formattedCoords = isPointData
          ? formatCoordinates(city?.lat, city?.lon)
          : formatCoordinates(cityInfo?.lat, cityInfo?.lon);
        const cityName = isPointData
          ? city?.name
          : cityInfo?.city
          ? cityInfo?.city
          : cityInfo?.state;
        const value = isWeatherLayer
          ? point?.weatherType
          : pointValues.length > 0
          ? pointValues[0]
          : '';
        let updatedAt;
        let userId;
        let overrideId;

        if (isPointData) {
          updatedAt = point?.timestamp ?? undefined;
          userId = point?.userId ?? undefined;
          overrideId = point?.overrideId ?? undefined;
        } else {
          updatedAt = point?.updated_at ?? undefined;
          userId = point?.user_id ?? undefined;
        }
        return (
          <PropertySection
            label={`Point #${index + 1} ${
              formattedCoords && '- ' + cityName + ' ' + formattedCoords
            }`}
            isOpened={isOpened}
            key={`${p.lon},${p.lat}`}
            onClick={(e) => handleOpenedPoints(p, e)}
          >
            <GridWrapper className="mt-2 mb-2">
              <GridItem
                label={'Latitude:'}
                noBorderBg
                item={
                  <input
                    style={{ padding: '0' }}
                    type={'number'}
                    readOnly
                    value={p.lat.toFixed(2)}
                    className={styles.inputWrap}
                  />
                }
              />
              <GridItem
                label={'Longitude:'}
                noBorderBg
                item={
                  <input
                    style={{ padding: '0' }}
                    type={'number'}
                    readOnly
                    value={p.lon.toFixed(2)}
                    className={styles.inputWrap}
                  />
                }
              />
              <GridItem
                label={labelValue1}
                noBorderBg
                item={
                  <input
                    readOnly
                    style={{ padding: '0' }}
                    type={point?.weatherType ? 'string' : 'number'}
                    value={
                      value !== null
                        ? point?.weatherType
                          ? value
                          : Number(value)?.toFixed(precision)
                        : ''
                    }
                    className={styles.inputWrap}
                  />
                }
              />
              {isWind ? (
                <GridItem
                  label={`Speed (${
                    layer.symbolSource.unit === 'WDirWSpeed' ? 'm/s' : layer.symbolSource.unit
                  })`}
                  noBorderBg
                  item={
                    <input
                      readOnly
                      style={{ padding: '0' }}
                      type={'number'}
                      value={pointValues.length > 1 ? pointValues[1]?.toFixed(1) || '' : ''}
                      className={styles.inputWrap}
                    />
                  }
                />
              ) : (
                renderDeleteButton(index)
              )}
            </GridWrapper>
            {!isWeatherLayer && (
              <GridWrapper className="mb-2">
                <SymbolPointsOverrideProperties
                  pointValues={pointValues}
                  point={p}
                  currentFrame={currentFrame?.result}
                  layer={layer}
                  updatedAt={updatedAt}
                  userId={userId}
                  originalValues={originalValues}
                  overrideId={overrideId}
                />
                {isWind && renderDeleteButton(index)}
              </GridWrapper>
            )}
          </PropertySection>
        );
      })}
    </>
  );
};
