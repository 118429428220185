import { useContext, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ElementsEnum } from '../../core/ui/enums/ElementsEnum';
import { PlaybackEnum } from '../../core/ui/enums/PlaybackEnum';
import { AudioElement } from '../../model/definitions/AudioElement';
import PlayerContext from '../../pages/playground/playerContext/PlayerContext';
import { ActiveDef } from '../../store/slices/active-slice';
import { updateAudioLayer, updateElementTime } from '../../store/slices/project-slice';
import { RootState } from '../../store/store';
import { key as getAudioKey, useGetAudio } from '../elementUtility/queryHooks/useGetAudio';

interface AudioElementProps {
  panelProps: AudioElement;
  sceneId?: string | number;
  isVoiceOver?: boolean;
}

export const CanvasAudioElement = ({ panelProps, isVoiceOver = false }: AudioElementProps) => {
  //TODO
  /*** cut after first full duration finished ***/
  /*** skip after first full duration finished ***/
  //END TODO
  const elementRef = useRef<HTMLAudioElement>(null);
  const contextValue = useContext(PlayerContext);
  const { time, isPlaying, skip } = contextValue;
  const { activeScene } = useSelector<RootState, ActiveDef>((state) => state.active);
  const { timeControls, versionId, audioElementTemplate, id, enabled } = panelProps;
  const { data } = useGetAudio(
    [...getAudioKey, audioElementTemplate.versionId],
    audioElementTemplate.versionId,
  );
  const dispatch = useDispatch();
  function getDuration() {
    const audio = elementRef.current;
    if (audio) {
      audio.currentTime = 0;
      audio.removeEventListener('timeupdate', getDuration);
      !isVoiceOver &&
        dispatch(
          updateAudioLayer({
            newValue: audio.duration * 1000,
            elementId: id,
            activeScene: activeScene,
            propertyPath: 'audioElementTemplate.durationInMS',
          }),
        );
      !isVoiceOver &&
        dispatch(
          updateElementTime({
            time: [
              {
                ...panelProps.timeControls[0],
                endMS: panelProps.timeControls[0].startMS + audio.duration * 1000,
              },
            ],
            elementId: id,
            activeScene: activeScene,
            elementType: ElementsEnum.AUDIO,
          }),
        );
    }
  }
  const run = () => {
    return timeControls.find((segment) => segment.startMS <= time && segment.endMS >= time);
  };
  const calcVolumeOnFadeIn = (start = 0, end = 0, durationIn = 0, durationOut = 0) => {
    if (!panelProps.enabled) {
      return 0;
    }
    if (time - start < durationIn && enabled)
      return (time - start) / durationIn < panelProps.volume
        ? (time - start) / durationIn
        : panelProps.volume;
    if (time > end - durationOut && time <= end && enabled)
      return (end - time) / durationOut < panelProps.volume
        ? (end - time) / durationOut
        : panelProps.volume;
    return enabled ? panelProps.volume : 0;
  };
  useEffect(() => {
    if (elementRef.current) {
      if (panelProps.enabled) elementRef.current.volume = panelProps.volume;
      else elementRef.current.volume = 0;
    }
  }, [panelProps.enabled, panelProps.volume]);
  /*** skip while playing ***/
  useEffect(() => {
    if (isPlaying === PlaybackEnum.PLAYING && elementRef.current) {
      const start = run()?.startMS ?? 0;
      const seek = run()?.seekToTimeMS ?? 0;
      const element = elementRef.current;
      if (element && isPlaying === PlaybackEnum.PLAYING) {
        element.currentTime = (time + skip.val - start + seek) / 1000;
      }
    }
  }, [isPlaying, skip.ctrl]);
  /*** determines should element be played or paused ***/
  useEffect(() => {
    const element = elementRef.current;
    if (element) {
      element.volume = calcVolumeOnFadeIn(
        run()?.startMS,
        run()?.endMS,
        run()?.inAnimationDuration,
        run()?.outAnimationDuration,
      );
      run() && isPlaying === PlaybackEnum.PLAYING ? element.play() : element.pause();
    }
  }, [isPlaying, time]);
  /*** changes playing state ***/
  useEffect(() => {
    const element = elementRef.current;
    if (element) {
      run() && isPlaying === PlaybackEnum.PLAYING ? element.play() : element.pause();
    }
  }, [isPlaying]);
  /*** skips part of reproduction based on timeline interaction while player is inactive ***/
  useEffect(() => {
    const start = run()?.startMS ?? 0;
    const seek = run()?.seekToTimeMS ?? 0;
    const element = elementRef.current;
    if (element && isPlaying !== PlaybackEnum.PLAYING) {
      element.currentTime = (time + skip.val - start + seek) / 1000;
    }
  }, [time]);
  /*** sets starting point in reproduction time of segment ***/
  useEffect(() => {
    const seek = run()?.seekToTimeMS ?? 0;
    const start = run()?.startMS ?? 0;
    const element = elementRef.current;
    if (element && isPlaying === PlaybackEnum.PLAYING) {
      element.currentTime = (time - start + seek) / 1000;
    }
  }, [isPlaying, run()?.seekToTimeMS]);
  return (
    <audio
      key={versionId}
      src={data}
      id={versionId}
      ref={elementRef}
      autoPlay={false}
      onLoadedData={() => {
        const audio = elementRef.current;
        audio?.addEventListener('loadedmetadata', () => {
          if (audio.duration === Infinity) {
            audio.currentTime = 1e101;
            audio.addEventListener('timeupdate', getDuration);
          }
        });
      }}
    />
  );
};
