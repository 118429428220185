import './style.scss';

import { useKeycloak } from '@react-keycloak/web';
import { Button, Form, Steps, theme } from 'antd';
import { RcFile } from 'antd/es/upload';
import cronstrue from 'cronstrue';
import { register } from 'ol/proj/proj4';
import proj4 from 'proj4';
import React, { useEffect, useState } from 'react';
import { AiOutlineFileSync, AiOutlineInfoCircle } from 'react-icons/ai';
import { IoColorPaletteOutline } from 'react-icons/io5';
import { TbDatabase } from 'react-icons/tb';
import { VscDebugDisconnect } from 'react-icons/vsc';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { DataProductTypeEnum } from '../../../model/enums/DataProductTypeEnum';
import { ProtocolTypeEnum } from '../../../model/enums/ProtocolTypeEnum';
import { ScrapingEngineTypeEnum } from '../../../model/enums/ScrapingEngineTypeEnum';
import nsper from '../../../molecules/mapElement/nsper';
import { useAddDataProduct } from '../hooks/useAddDataProduct';
import { useEditDataProduct } from '../hooks/useEditDataProduct';
import { useGetProvider } from '../hooks/useGetProvider';
import AccessFileInfo from '../molecules/AccessFileInfo';
import ColorPalette from '../molecules/ColorPalette';
import DataFilterSetup from '../molecules/DataFilterSetup';
import DynamicParameteres from '../molecules/DynamicParameteres';
import GeneralInfo from '../molecules/GeneralInfo';
import IngestionInfo from '../molecules/IngestionInfo';
import ValidationFiles from '../molecules/ValidationFiles';
import { DataProductInterface } from '../pages/Products';

interface StepsInterface {
  selectedProduct?: DataProductInterface;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface FormErrors {
  baseName?: string;
  productType?: string;
  name?: string;
  ortho?: string;
  proj?: string;
  url?: string;
  regexName?: string;
  base64ShFile?: string;
  retentionTime?: string;
  protocol?: string;
  updateFrequency?: string;
  forecastLocationFile?: string;
}

const defaultForm = {
  providerId: '',
  baseName: '',
  name: '',
  description: '',
  productType: '',
  accessInformation: {
    username: '',
    password: '',
    url: '',
    protocol: ProtocolTypeEnum.FTP,
    ports: ['21'],
  },
  fileInformation: {
    regexName: '',
    folderName: '',
    fileFormat: '',
  },
  dataFilterSetup: {
    retentionTime: null,
  },
  params: { ortho: '', proj: '' },
  timeToBeReadFrom: 'FROM_FILE_HEADER',
  regExpressions: [
    [
      {
        fixedText: 'string',
      },
      {
        textLen: {
          lowerLimit: 0,
          upperLimit: 1,
        },
      },
      {
        numberOfDigits: {
          lowerLimit: 0,
          upperLimit: 1,
        },
      },
      {
        digitRange: {
          lowerLimit: 0,
          upperLimit: 1,
          step: 1,
        },
      },
    ],
  ],
  parameterMappings: [],
  scrapingEngineType: ScrapingEngineTypeEnum.PROVIDER_SCRIPTING,
  base64ShFile: null,
  defaultColorPaletteIds: [],
  forecastLocationFile: undefined,
};

const StepsAntd = ({ selectedProduct, setOpen }: StepsInterface) => {
  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);
  const { mutate: addProductMutation, isLoading: addLoading } = useAddDataProduct();
  const { mutate: editProductMutation, isLoading: editLoading } = useEditDataProduct();
  const { keycloak } = useKeycloak();
  const { data: provider } = useGetProvider('provider', keycloak?.tokenParsed?.email);
  const navigate = useNavigate();
  const [form, setForm] = useState<DataProductInterface>(
    selectedProduct ? selectedProduct : defaultForm,
  );
  const [thumbnailFiles, setThumbnailFiles] = useState<RcFile[]>([]);
  const [csvForecastFile, setCsvForecastFile] = useState<RcFile>();
  const [errors, setErrors] = useState<FormErrors>({});
  const [orthoType, setOrthoType] = useState<string>(
    form.params.ortho != '' ? 'predefined' : 'file',
  );
  const [projType, setProjType] = useState<string>(form.params.proj != '' ? 'predefined' : 'file');
  const [checkParameters, setCheckParameters] = useState<boolean>(false);

  useEffect(() => {
    if (provider && !selectedProduct) {
      setForm({ ...form, providerId: provider.id });
    }
  }, [provider, selectedProduct]);

  const next = async () => {
    const validationErrors: FormErrors = {};

    if (current === 0) {
      if (!form.baseName) {
        validationErrors.baseName = 'Base name is required';
      }
      if (!form.productType) {
        validationErrors.productType = 'Please select product type';
      }
      if (form.productType === DataProductTypeEnum.MODEL && !form.name) {
        validationErrors.name = 'Please insert product name';
      }
      if (
        form.productType === DataProductTypeEnum.FORECAST &&
        !csvForecastFile &&
        !form.forecastLocationFile
      ) {
        validationErrors.forecastLocationFile = 'Please insert location file';
      }
      if (
        (!form.params.ortho ||
          (form.params.ortho &&
            !/^[-]?(?:\d+(?:\.\d+)?|[0-8]?\d+(?:\.\d+)?) [-]?(?:\d+(?:\.\d+)?|[0-8]?\d+(?:\.\d+)?) [-]?(?:\d+(?:\.\d+)?|[0-8]?\d+(?:\.\d+)?) [-]?(?:\d+(?:\.\d+)?|[0-8]?\d+(?:\.\d+)?)$/.test(
              form.params.ortho,
            ))) &&
        (orthoType === 'predefined' ||
          form.productType === DataProductTypeEnum.RADAR ||
          form.productType === DataProductTypeEnum.SATELLITE)
      ) {
        validationErrors.ortho = 'Please insert valid ortho format';
      }
      if (
        projType === 'predefined' ||
        form.productType === DataProductTypeEnum.RADAR ||
        form.productType === DataProductTypeEnum.SATELLITE
      ) {
        if (!form.params.proj) {
          validationErrors.proj = 'Please insert proj';
        } else {
          if (form.params.proj.includes('+proj=nsper')) {
            // @ts-ignore
            proj4.Proj.projections.add(nsper);
            register(proj4);
          }
          try {
            proj4(form.params.proj);
            setForm({ ...form, params: { ...form.params, proj: form.params.proj } });
          } catch (e) {
            validationErrors.proj = 'Proj4 that you entered is not valid!';
          }
        }
      }
    }
    if (current === 1) {
      if (form.scrapingEngineType === ScrapingEngineTypeEnum.GEO_STREAM_DSL) {
        if (!form.accessInformation?.url) {
          validationErrors.url = 'Please insert url';
        }
        if (!form.fileInformation?.regexName) {
          validationErrors.regexName = ' Please insert regex';
        }
        if (!form.accessInformation?.protocol) {
          validationErrors.protocol = ' Please insert protocol';
        }
      }
      if (
        form.scrapingEngineType === ScrapingEngineTypeEnum.PROVIDER_SCRIPTING &&
        !form.base64ShFile
      ) {
        validationErrors.base64ShFile = 'Please insert file';
      }
    }

    if (Object.keys(validationErrors).length === 0) {
      setErrors({});
      if (current === 3 && form.productType === DataProductTypeEnum.RADAR) {
        setCurrent(current + 2);
      } else {
        setCurrent(current + 1);
      }
    } else {
      setErrors(validationErrors);
      toast.error('Please fill required fields');
    }
  };

  const prev = () => {
    if (current === steps.length - 1 && form.productType === DataProductTypeEnum.RADAR) {
      setCurrent(current - 2);
    } else {
      setCurrent(current - 1);
    }
  };

  const hideSteps =
    form.productType === DataProductTypeEnum.FORECAST ||
    form.productType === DataProductTypeEnum.OBSERVED ||
    form.productType === DataProductTypeEnum.EVENT;

  const steps = [
    {
      title: 'General info',
      content: (
        <GeneralInfo
          form={form}
          setForm={setForm}
          thumbnailFiles={thumbnailFiles}
          setThumbnailFiles={setThumbnailFiles}
          csvForecastFile={csvForecastFile}
          setCsvForecastFile={setCsvForecastFile}
          errors={errors}
          setErrors={setErrors}
          orthoType={orthoType}
          setOrthoType={setOrthoType}
          projType={projType}
          setProjType={setProjType}
        />
      ),
      icon: <AiOutlineInfoCircle />,
    },
    {
      title: 'Access and file info',
      content: <AccessFileInfo form={form} setForm={setForm} errors={errors} />,
      icon: <VscDebugDisconnect />,
    },
    {
      title: 'Data filter setup',
      content: <DataFilterSetup form={form} setForm={setForm} errors={errors} />,
      icon: <TbDatabase />,
    },
    hideSteps
      ? {
          title: 'Ingestion info',
          content: <IngestionInfo form={form} />,
          icon: <AiOutlineFileSync />,
          hidden: !hideSteps || form.scrapingEngineType !== ScrapingEngineTypeEnum.WITHOUT_SCRAPING,
        }
      : {
          title: 'Validation Files',
          content: <ValidationFiles form={form} />,
          icon: <AiOutlineFileSync />,
          hidden: hideSteps,
        },
    {
      title: 'Param mapping',
      content: (
        <DynamicParameteres form={form} setForm={setForm} setCheckParameters={setCheckParameters} />
      ),
      icon: <AiOutlineFileSync />,
      hidden: form.productType === DataProductTypeEnum.RADAR || hideSteps,
    },
    {
      title: 'Recommended colors',
      content: <ColorPalette form={form} setForm={setForm} thumbnailFiles={thumbnailFiles} />,
      icon: <IoColorPaletteOutline />,
      hidden: hideSteps,
    },
  ];

  const items = steps
    .filter((item) => !item.hidden)
    .map((item) => ({ key: item.title, title: item.title, icon: item.icon }));

  const contentStyle: React.CSSProperties = {
    textAlign: 'center',
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 0,
    padding: 20,
  };

  const handleSubmit = () => {
    if (current === 2) {
      if (!form.dataFilterSetup.retentionTime) {
        setErrors({ retentionTime: 'Please insert retention time' });
        return;
      }
      if (form.dataFilterSetup.updateFrequency) {
        try {
          cronstrue.toString(form.dataFilterSetup.updateFrequency, {
            use24HourTimeFormat: true,
          });
        } catch (error) {
          setErrors({ updateFrequency: 'Please insert valid cron' });
          return;
        }
      }
    }
    const formData = new FormData();
    const selectedFields: any = {
      providerId: form.providerId,
      baseName: form.baseName,
      description: form.description,
      name: form.productType === DataProductTypeEnum.MODEL ? form.name : '',
      productType: form.productType,
      dataFilterSetup: form.dataFilterSetup,
      params: form.params,
      regExpressions: form.regExpressions,
      scrapingEngineType: form.scrapingEngineType,
      defaultColorPaletteIds: form.defaultColorPaletteIds,
    };
    if (selectedProduct || form.id) {
      selectedFields.id = form.id;
      selectedFields.versionId = form.versionId;
      selectedFields.creator = form.creator;
      selectedFields.createdAt = form.createdAt;
      selectedFields.createdOnWDConsole = form.createdOnWDConsole;
      selectedFields.supportInfo = form.supportInfo;
    }
    if (form.scrapingEngineType === ScrapingEngineTypeEnum.PROVIDER_SCRIPTING) {
      selectedFields.base64ShFile = form.base64ShFile;
    }
    if (form.scrapingEngineType === ScrapingEngineTypeEnum.GEO_STREAM_DSL) {
      selectedFields.accessInformation = form.accessInformation;
      selectedFields.fileInformation = form.fileInformation;
    }
    if (form.productType === DataProductTypeEnum.SATELLITE) {
      selectedFields.types = form.types;
    }
    if (form.productType === DataProductTypeEnum.MODEL) {
      selectedFields.timeToBeReadFrom = form.timeToBeReadFrom;
    }
    if (form.productType === DataProductTypeEnum.MODEL && projType === 'file') {
      selectedFields.params.proj = '';
    }
    if (form.productType === DataProductTypeEnum.MODEL && orthoType === 'file') {
      selectedFields.params.ortho = '';
    }
    if (form.parameterMappings) {
      form.parameterMappings.map((parameter) => {
        delete parameter.id;
        delete parameter.default;
      });
      selectedFields.parameterMappings = form.parameterMappings;
    }

    formData.append('dataProduct', JSON.stringify(selectedFields));
    thumbnailFiles.forEach((file) => {
      file && formData.append('thumbnailFiles', file);
    });
    if (csvForecastFile && form.productType === DataProductTypeEnum.FORECAST)
      formData.append('csvForecastFile', csvForecastFile);
    if (form.id) {
      editProductMutation(formData, {
        onSuccess: (data) => {
          setForm(data);
          if (data.createdOnWDConsole) {
            toast.success('Successfully edited product!');
            if (current === steps.length - 1) {
              setOpen ? setOpen(false) : navigate('/workspace/dataprovider/products');
            } else {
              setCurrent(current + 1);
            }
          } else {
            toast.error('Not a valid data product. Please try again!');
          }
        },
      });
    } else {
      addProductMutation(formData, {
        onSuccess: (data) => {
          setForm(data);
          if (data.createdOnWDConsole) {
            toast.success('Successfully added new product!');
            if (hideSteps && form.scrapingEngineType !== ScrapingEngineTypeEnum.WITHOUT_SCRAPING) {
              navigate('/workspace/dataprovider/products');
            } else {
              setCurrent(current + 1);
            }
          } else {
            toast.error('Not a valid data product. Please try again!');
          }
        },
      });
    }
  };

  const handleSkip = () => {
    setForm(selectedProduct ? selectedProduct : defaultForm);
    setCurrent(3);
  };

  const handleClose = () => {
    setOpen ? setOpen(false) : navigate('/workspace/dataprovider/products');
  };

  return (
    <div className="select-text">
      <Steps current={current} items={items} />
      <Form
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        style={{ maxWidth: '90%', margin: '0 auto', padding: 30 }}
        initialValues={form}
      >
        <div style={contentStyle}>{steps[current].content}</div>
        <div style={{ marginTop: 24 }}>
          {((current > 0 && selectedProduct) ||
            (current > 0 && !selectedProduct && current !== 3)) && (
            <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
              Previous
            </Button>
          )}
          {((current < steps.length - 1 && current !== 2 && !hideSteps) ||
            (hideSteps && current !== 2 && current !== 3)) && (
            <Button
              type="primary"
              onClick={() => next()}
              disabled={checkParameters && current === steps.length - 2}
            >
              Next
            </Button>
          )}
          {current === steps.length - 1 && !hideSteps && (
            <Button type="primary" onClick={() => handleSubmit()}>
              Save and Publish
            </Button>
          )}
          {current === 2 && (
            <Button
              type="primary"
              onClick={() => handleSubmit()}
              loading={addLoading || editLoading}
            >
              Submit
            </Button>
          )}
          {hideSteps && current === 3 && (
            <Button type="primary" onClick={() => handleClose()}>
              Close
            </Button>
          )}
          {form.createdOnWDConsole && current < 3 && (
            <Button type="text" onClick={() => handleSkip()}>
              {hideSteps && form.scrapingEngineType === ScrapingEngineTypeEnum.WITHOUT_SCRAPING
                ? 'Skip to ingest info'
                : 'Skip to validation files'}
            </Button>
          )}
        </div>
      </Form>
    </div>
  );
};

export default StepsAntd;
