import { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { useDispatch } from 'react-redux';

import Button from '../../../../../atoms/button/Button';
import { fromGeoBoxToBoundsWDLayers } from '../../../../../helpers/boundsManage';
import { DataFrameDef } from '../../../../../model/definitions/DataFrameDef';
import { MapPanelDef } from '../../../../../model/definitions/MapPanelDef';
import Modal from '../../../../../molecules/modal/Modal';
import { addMapLayer, replaceMapLayer } from '../../../../../store/slices/project-slice';
import { AddGeoModal } from '../../../modals/AddGeoModal';
import { AutoImport } from '../../../sidebar-new/auto-import/AutoImport';
import { GribRange } from './GribRange';
import { RadarRange } from './RadarRange';
import { SatelliteRange } from './SatelliteRange';

interface TemplateModalProps {
  flow?: 'single' | 'scene';
  scene: string;
  isOpen: boolean;
  onClose: () => void;
  definition: MapPanelDef;
}

export const MapTemplateModal = ({
  flow = 'single',
  isOpen,
  onClose,
  definition,
  scene,
}: TemplateModalProps) => {
  const dispatch = useDispatch();
  const {
    wdSpace: [{ gribMapLayers, radarMapLayers, satelliteMapLayers }],
    name,
  } = definition;
  const transformedBounds = fromGeoBoxToBoundsWDLayers(definition.baseMapSetup.boundingBox);
  const [mapToAdd, setMapToAdd] = useState(cloneDeep(definition));
  const [step, setStep] = useState<number>(0);
  const [isOpenGeo, setIsOpenGeo] = useState<boolean>(true);
  const [layerMode, setLayerMode] = useState<'auto' | 'manual' | undefined>('auto');
  const handleGribLayer = (id: string, frames: DataFrameDef[]) => {
    const map = cloneDeep(mapToAdd);
    const layer = map.wdSpace[0].gribMapLayers.find((layer) => layer.id === id);
    if (layer) {
      layer.dataFrames = [];
      layer.unLoadedDataFrames = frames;
    }
    setMapToAdd(map);
  };
  const isDisabled = () => {
    const space = mapToAdd.wdSpace[0];
    const gribLayers = space.gribMapLayers;
    const radarLayers = space.radarMapLayers;
    const satelliteLayers = space.satelliteMapLayers;
    const hasGribStamps =
      gribLayers.length === 0 ||
      gribLayers.some((layer) => {
        return layer.unLoadedDataFrames.length > 0 && layer.unLoadedDataFrames[0] !== undefined;
      });
    const hasRadarStamps =
      !gribLayers.length || radarLayers.some((layer) => layer.unLoadedDataFrames.length > 0);
    const hasSatelliteStamps =
      !gribLayers.length || satelliteLayers.some((layer) => layer.unLoadedDataFrames.length > 0);
    if (gribLayers.some((layer) => layer.unLoadedDataFrames.length > 150)) {
      return true;
    }
    if (radarLayers.some((layer) => layer.unLoadedDataFrames.length > 150)) {
      return true;
    }
    if (satelliteLayers.some((layer) => layer.unLoadedDataFrames.length > 150)) {
      return true;
    }
    if (step === 0 && !hasGribStamps) {
      return true;
    }
    if (step === 1 && !hasRadarStamps) {
      return true;
    }
    return step === 2 && !hasSatelliteStamps;
  };
  const handleRadarLayer = (id: string, frames: DataFrameDef[]) => {
    const map = cloneDeep(mapToAdd);
    const layer = map.wdSpace[0].radarMapLayers.find((layer) => layer.id === id);
    if (layer) {
      layer.dataFrames = [];
      layer.unLoadedDataFrames = frames;
    }
    setMapToAdd(map);
  };
  const handleSatelliteLayer = (id: string, frames: DataFrameDef[]) => {
    const map = cloneDeep(mapToAdd);
    const layer = map.wdSpace[0].satelliteMapLayers.find((layer) => layer.id === id);
    if (layer) {
      layer.dataFrames = [];
      layer.unLoadedDataFrames = frames;
    }
    setMapToAdd(map);
  };
  useEffect(() => {
    setStep(0);
  }, [definition.id]);
  const renderModelRanges = () => {
    if (gribMapLayers.length)
      return gribMapLayers.map((layer) => (
        <GribRange
          key={layer.id}
          source={layer}
          bounds={transformedBounds}
          setFrames={handleGribLayer}
          layerNew={mapToAdd.wdSpace[0]?.gribMapLayers.find((grib) => grib.id === layer.id)}
        />
      ));
    else setStep((step) => ++step);
  };

  /* useEffect(() => {
    if (
      gribMapLayers.length === 0 &&
      radarMapLayers.length === 0 &&
      satelliteMapLayers.length === 0
    ) {
      setLayerMode('auto');
      setStep(3);
    }
  }, [gribMapLayers.length, radarMapLayers.length, satelliteMapLayers.length, step]); */
  useEffect(() => {
    if (definition.geoPosters.length === 0 && step === 3) {
      setStep(4);
    }
  }, [definition.geoPosters.length, step]);
  const renderRadarRanges = () => {
    if (radarMapLayers.length && mapToAdd.wdSpace)
      return radarMapLayers.map((layer) => (
        <RadarRange
          key={layer.id}
          source={layer}
          bounds={transformedBounds}
          setFrames={handleRadarLayer}
          layerNew={mapToAdd.wdSpace[0]?.radarMapLayers.find((radar) => radar.id === layer.id)}
        />
      ));
    else setStep((step) => ++step);
  };
  const renderSatelliteRanges = () => {
    if (satelliteMapLayers.length)
      return satelliteMapLayers.map((layer) => (
        <SatelliteRange
          key={layer.id}
          source={layer}
          bounds={transformedBounds}
          setFrames={handleSatelliteLayer}
          layerNew={mapToAdd.wdSpace[0]?.satelliteMapLayers.find(
            (satellite) => satellite.id === layer.id,
          )}
        />
      ));
    else setStep((step) => ++step);
  };
  useEffect(() => {
    const handleAddLayer = () => {
      flow === 'single'
        ? dispatch(addMapLayer({ mapLayer: mapToAdd, activeScene: scene }))
        : dispatch(replaceMapLayer({ mapLayer: mapToAdd, activeScene: scene }));
      onClose();
    };
    step > 3 && handleAddLayer();
    step > 3 && onClose();
  }, [step]);
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      className="template-scene-modal"
      header={`Map ${name} template setup`}
      footer={
        <div className="modal-footer">
          <div className={'footnote'}></div>
          <div className={'button-holder'}>
            <div className="w-full flex items-center mt-5 next-back-btn">
              <Button onClick={onClose} buttonType="secondary" label="Cancel" />
              {step > 0 && <Button onClick={() => setStep((step) => --step)} label="Previous" />}
              <Button
                disabled={isDisabled()}
                buttonType="primary"
                onClick={() => (layerMode === 'auto' ? setStep(4) : setStep((step) => ++step))}
                label="Next"
                icon={<AiOutlineArrowRight />}
              />
            </div>
          </div>
        </div>
      }
    >
      <>
        {step}
        {layerMode === undefined && (
          <>
            <h3>Layer setup</h3>
            <div className={'flex justify-center'}>
              <Button onClick={() => setLayerMode('auto')} label={'Auto layer mode'} />
              <Button onClick={() => setLayerMode('manual')} label={'Manual layer mode'} />
            </div>
          </>
        )}
        {layerMode === 'manual' && (
          <div>
            {step === 0 && (
              <>
                <div className={'subheader'}>Model layers</div>
                {renderModelRanges()}
              </>
            )}
            {step === 1 && (
              <>
                <div className={'subheader'}>Radar layers</div>
                {renderRadarRanges()}
              </>
            )}
            {step === 2 && (
              <>
                <div className={'subheader'}>Satellite layers</div>
                {renderSatelliteRanges()}
              </>
            )}
          </div>
        )}
        {layerMode === 'auto' && (
          <AutoImport
            map={mapToAdd}
            gribLayers={definition.wdSpace[0].gribMapLayers}
            radarLayers={definition.wdSpace[0].radarMapLayers}
            satelliteLayers={definition.wdSpace[0].satelliteMapLayers}
            bounds={definition.baseMapSetup.baseMapConfigurationBounds}
            setMapToAdd={(e) => {
              setStep(3);
              setMapToAdd(e);
            }}
          />
        )}
        {step === 3 && definition.geoPosters.length ? (
          <AddGeoModal
            onClose={() => setIsOpenGeo(false)}
            onOk={() => {}}
            initialData={definition.geoPosters}
            opened={isOpenGeo}
            timeControls={definition.timeControls}
            initialPosterTemp={definition.geoPosters[0]?.templateId}
            addPostersToMap={(e) => {
              setMapToAdd((prevState) => ({
                ...prevState,
                geoPosters: e ?? [],
              }));
              setIsOpenGeo(false);
            }}
          />
        ) : null}
      </>
    </Modal>
  );
};
