import './Header.scss';

import { useNavigate } from 'react-router-dom';

import HeaderLogo from '../../assets/images/LandingPage/HeaderLogo.png';
import { AntHeader } from '../../layout/Layout';
import HeaderButtons from '../HeaderButtons';

const Header = () => {
  const navigate = useNavigate();

  return (
    <AntHeader>
      <img alt="Geostream" src={HeaderLogo} onClick={() => navigate('/marketplace')} />

      <HeaderButtons />
    </AntHeader>
  );
};

export default Header;
