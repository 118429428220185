import { useQuery } from 'react-query';

import { axiosInstance } from '../../../core/api/axiosInstance';

export const key = ['ENTERPRISE_PROPERTIES'];
export const useGetEnterpriseProperties = (id: string) => {
  return useQuery(
    [...key, id],
    async () => {
      const res = await axiosInstance.get(`/enterprise-properties/enterprise/${id}`);
      return res.data;
    },
    {
      enabled: !!id,
    },
  );
};
