import { NodeModel, RenderParams } from '@minoru/react-dnd-treeview';
import React, { FC } from 'react';

import { ElementsEnum } from '../../../core/ui/enums/ElementsEnum';
import { LogicalGroupElement } from '../../../model/definitions/LogicalGroupElement';
import { PanelDefs } from '../../../model/UI/PanelDef';
import styles from './CustomNode.module.scss';

type Props = RenderParams & {
  node: NodeModel<PanelDefs & LogicalGroupElement & { type: ElementsEnum }>;
  isSelected: boolean;
  isDragging: boolean;
  testIdPrefix?: string;
  onClick: (
    e: MouseEvent,
    node: NodeModel<PanelDefs & LogicalGroupElement & { type: ElementsEnum }>,
  ) => void;
  children: JSX.Element;
};

export const CustomNode: FC<Props> = ({ testIdPrefix = '', ...props }) => {
  const { id } = props.node;

  const handleClick = (e: React.MouseEvent) => {
    // @ts-ignore
    props.onClick(e, props.node);
  };

  if (props.isSelected) {
    props.containerRef.current?.classList.add(styles.selected);
  } else {
    props.containerRef.current?.classList.remove(styles.selected);
  }

  if (props.isDragging) {
    props.containerRef.current?.classList.add(styles.dragging);
  } else {
    props.containerRef.current?.classList.remove(styles.dragging);
  }

  return (
    <div
      className={styles.root}
      //   style={{ paddingInlineStart: indent }}
      data-testid={`${testIdPrefix}custom-node-${id}`}
      onClick={handleClick}
    >
      {props.children}
    </div>
  );
};
