import Slider from 'rc-slider';
import React, { useState } from 'react';
import { IoCameraOutline } from 'react-icons/io5';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import noThumbnail from '../../../assets/images/thumbnail.jpg';
import Button from '../../../atoms/button/Button';
import { setMultimediaThumbnail } from '../../../core/api/MultimediaAPI';
import { ElementsEnum } from '../../../core/ui/enums/ElementsEnum';
import { ScreenshotData } from '../../../helpers/screenshotElement';
import { usePropertyGridActive } from '../../../hooks/usePropertyGridActive';
import { AudioElement } from '../../../model/definitions/AudioElement';
import { ActiveDef, setPropertyGridActiveHash } from '../../../store/slices/active-slice';
import { updateAudioLayer } from '../../../store/slices/project-slice';
import { selectActiveAudioElement } from '../../../store/slices/selectors';
import { RootState } from '../../../store/store';
import { Panel } from './components/Panel';
import { PropertySection } from './components/PropertySection';
import ImageAudioThumbnailModal from './ImageAudioThumbnail';
import { TimeControlsPanel } from './panels/TimeControlsPanel';
import GridItem from './shared/GridItem';
import GridWrapper from './shared/GridWrapper';
import { getVolumeIcon } from './volumeIcon';

const AudioProperties: React.FC = () => {
  const [thumbnail, setThumbnail] = useState(false);
  const active = useSelector<RootState, ActiveDef>((state) => state.active);
  const audioLayer = useSelector<RootState, AudioElement | null | undefined>((state) =>
    selectActiveAudioElement(state),
  );
  const { isOpened } = usePropertyGridActive(['volume']);
  const thumbnailMut = useMutation(setMultimediaThumbnail, {
    onSuccess: () => {
      setThumbnail(false);
    },
  });
  const dispatch = useDispatch();
  function onAudioChange(propertyPath: Leaves<AudioElement>, e: string | number | boolean) {
    onFocus(propertyPath);
    dispatch(
      updateAudioLayer({
        newValue: e,
        activeScene: active.activeScene,
        elementId: active.activeElement,
        propertyPath: propertyPath,
      }),
    );
  }
  function onFocus(path: Leaves<AudioElement>) {
    dispatch(setPropertyGridActiveHash({ activeElement: active.activeElement, focusedEl: path }));
  }
  function onThumbnail() {
    setThumbnail(true);
  }
  function onConfirm(data: ScreenshotData) {
    thumbnailMut.mutate({
      multimediaType: 'AUDIO',
      versionId: audioLayer!.audioElementTemplate.versionId,
      thumbnail: data,
    });
  }

  return (
    <Panel
      actions={[
        <Button
          key={audioLayer?.id}
          buttonType="border"
          label="Set thumbnail"
          icon={<IoCameraOutline />}
          onClick={onThumbnail}
          className="property-grey-buttons"
        />,
      ]}
    >
      <PropertySection isOpened={isOpened} label={`${audioLayer?.name} properties`}>
        <div className="prop-wrapper">
          <GridWrapper className="volume-prop-grid">
            <GridItem
              noBorderBg
              label={'Sound:'}
              item={getVolumeIcon(Number(audioLayer?.volume ?? 0))}
            />
            <GridItem
              noBorderBg
              label={'Volume:'}
              item={
                <>
                  <Slider
                    style={{ width: '100%' }}
                    min={0}
                    max={100}
                    step={1}
                    value={(audioLayer?.volume ?? 0) * 100}
                    onChange={(e) => {
                      e >= 0 &&
                        e <= 100 &&
                        onAudioChange(
                          //@ts-ignore
                          `volume`,
                          (Number(e) / 100).toFixed(2),
                        );
                    }}
                  />
                  <div style={{ marginLeft: '0.5rem' }}>
                    {Math.round((audioLayer?.volume ?? 0) * 100)}
                  </div>
                </>
              }
            />
          </GridWrapper>
        </div>
      </PropertySection>
      <>
        {audioLayer?.timeControls?.length ? (
          <TimeControlsPanel timeControls={audioLayer?.timeControls} layer={ElementsEnum.AUDIO} />
        ) : null}
        {audioLayer?.audioElementTemplate && (
          <ImageAudioThumbnailModal
            opened={thumbnail}
            onClose={() => setThumbnail(false)}
            currentThumbnail={audioLayer.audioElementTemplate?.thumbnailUrls[0] || noThumbnail}
            loading={thumbnailMut.isLoading}
            onConfirm={onConfirm}
          />
        )}
      </>
    </Panel>
  );
};

export default AudioProperties;
