import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import update from 'immutability-helper';
import { cloneDeep, merge, set, uniqBy } from 'lodash';
import moment from 'moment/moment';
import { v4 } from 'uuid';

import { createUnSynchronizedIndicator, elementEnumToKey } from '../../components/timeline/helpers';
import { ElementsEnum } from '../../core/ui/enums/ElementsEnum';
import { getFpsFromExportFormat } from '../../helpers/getFpsFromExportFormat';
import { AnimationPanelDef } from '../../model/definitions/AnimationPanelDef';
import { AudioElement } from '../../model/definitions/AudioElement';
import { BackgroundDef } from '../../model/definitions/BackgroundDef';
import { BorderDef } from '../../model/definitions/BorderDef';
import { BoxDef } from '../../model/definitions/BoxDef';
import { C9ProjectDef } from '../../model/definitions/C9ProjectDef';
import { CustomVectorLayer } from '../../model/definitions/CustomVectorLayer';
import { DataFrameDef } from '../../model/definitions/DataFrameDef';
import { DrawingDef } from '../../model/definitions/DrawingDef';
import { ForecastWDElementDef } from '../../model/definitions/ForecastWDElementDef';
import { GraticulesDef } from '../../model/definitions/GraticulesDef';
import { ImagePanelDef } from '../../model/definitions/ImagePanelDef';
import { KeyFramesDef } from '../../model/definitions/KeyFramesDef';
import { LogicalGroupElement } from '../../model/definitions/LogicalGroupElement';
import { MapPanelDef } from '../../model/definitions/MapPanelDef';
import { MapPositionControlDef } from '../../model/definitions/MapPositionControlDef';
import { ObservedWDElementDef } from '../../model/definitions/ObservedWDElementDef';
import { PointDateDef } from '../../model/definitions/PointDateDef';
import { PointLocationDef } from '../../model/definitions/PointLocationDef';
import { PositionControlDef } from '../../model/definitions/PositionControlDef';
import { SceneDef } from '../../model/definitions/SceneDef';
import { SymbolLayerPointDef, SymbolStyleDef } from '../../model/definitions/SymbolLayerDef';
import { TextPanelDef } from '../../model/definitions/TextPanelDef';
import { TimeControlDef } from '../../model/definitions/TimeControlDef';
import { TimestampElementDef } from '../../model/definitions/TimestampElementDef';
import { VideoPanelDef } from '../../model/definitions/VideoPanelDef';
import { WeatherDataMapLayer } from '../../model/definitions/WeatherDataMapLayer';
import { WeatherDataMapLayerSetup } from '../../model/definitions/WeatherDataMapLayerSetup';
import { WeatherDataSpaceDef } from '../../model/definitions/WeatherDataSpaceDef';
import { WeatherGeoPosterDef } from '../../model/definitions/WeatherGeoPosterDef';
import { WeatherGraphDef } from '../../model/definitions/WeatherGraphDef';
import { WeatherPosterDef } from '../../model/definitions/WeatherPosterDef';
import { AnimationsEnum } from '../../model/enums/AnimationsEnum';
import { ImageTypeEnum } from '../../model/enums/ImageTypeEnum';
import { RenderCompressionEnum } from '../../model/enums/RenderCompressionEnum';
import { TimeStepEnum } from '../../model/enums/TimeStepEnum';
import { ValueTypeEnum } from '../../model/enums/ValueTypeEnum';
import { getAllLayerSetupsInSceneReducer } from '../../molecules/mapElement/helpers';
import { MapStylesHash } from '../../molecules/mapElement/MapStylesHash';
import { AllElementsDefs, Multiselection } from '../../types/elements';

const emptyProject = new C9ProjectDef();
type AnyObject = { [key: string]: any };

const setNestedProperty = (obj: AnyObject, path: string, value: any): void => {
  path.split('.').reduce((acc, prop, idx, arr) => {
    if (idx === arr.length - 1) {
      acc[prop] = value; // Set the value at the final property
    } else {
      acc[prop] = acc[prop] || {}; // Create intermediate objects if they don't exist
    }
    return acc[prop];
  }, obj);
};

export interface ProjectState {
  project: C9ProjectDef;
  currentSavedProject: C9ProjectDef;
  storyboardSceneDefs: SceneDef[];
  isStoryboardHoverActive: boolean;
}

const initialState: ProjectState = {
  project: emptyProject,
  currentSavedProject: emptyProject,
  storyboardSceneDefs: [],
  isStoryboardHoverActive: false,
};
//const cloned = cloneDeep(state) was slowing down app changed to cloned = state
const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    resetProject() {
      return initialState;
    },
    addScene(state, action: PayloadAction<{ scene: SceneDef }>) {
      const {
        payload: { scene },
      } = action;
      state.project.sceneDefs.push(scene);
    },
    editScene(
      state,
      action: PayloadAction<{
        id: string;
        name: string;
        duration: number;
        timeControl: TimeControlDef;
      }>,
    ) {
      const {
        payload: { id, name, duration, timeControl },
      } = action;
      const scene = state.project.sceneDefs.find((scene) => scene.id === id);
      if (scene) {
        scene.name = name;
        scene.durationInMS = duration;
        scene.timeControl = timeControl;
      }
      return state;
    },
    multiUpdateAnimationType(
      state,
      action: PayloadAction<{
        activeScene: string;
        selection: Multiselection;
        prop: keyof TimeControlDef;
        value: any;
      }>,
    ) {
      const {
        payload: { activeScene, selection, prop, value },
      } = action;
      const scene = state.project.sceneDefs.find((scene) => scene.id === activeScene);
      if (scene) {
        selection.forEach((item) =>
          item.elements.forEach((elem) => {
            if (!elem.parentId && !elem.mapId) {
              const elementsInScene = scene[item.type as keyof SceneDef] as Array<AllElementsDefs>;
              const elemTochange = elementsInScene.find(
                (e: AllElementsDefs) => e.id === elem.element.id,
              );
              if (elemTochange) {
                elemTochange.timeControls[0] = {
                  ...elemTochange.timeControls[0],
                  [prop]: value,
                };
              }
            }
            if (elem.parentId && !elem.mapId) {
              const poster = scene.weatherPosters.find((pos) => pos.id === elem.parentId);
              if (poster) {
                const elementsInPoster = poster[
                  item.type as keyof WeatherPosterDef
                ] as Array<AllElementsDefs>;
                const elemTochange = elementsInPoster?.find(
                  (e: AllElementsDefs) => e.id === elem.element.id,
                );
                if (elemTochange) {
                  elemTochange.timeControls[0] = {
                    ...elemTochange.timeControls[0],
                    [prop]: value,
                  };
                }
              }
            }
            if (elem.parentId && elem.mapId) {
              const map = scene.mapPanels.find((mp) => mp.id === elem.mapId);
              if (map) {
                const poster = map.geoPosters.find((pos) => pos.id === elem.parentId);
                if (poster) {
                  const elementsInPoster = poster[
                    item.type as keyof WeatherGeoPosterDef
                  ] as Array<AllElementsDefs>;
                  const elemTochange = elementsInPoster.find(
                    (e: AllElementsDefs) => e.id === elem.element.id,
                  );
                  if (elemTochange) {
                    elemTochange.timeControls[0] = {
                      ...elemTochange.timeControls[0],
                      [prop]: value,
                    };
                  }
                }
              }
            }
            if (!elem.parentId && elem.mapId) {
              const map = scene.mapPanels.find((mp) => mp.id === elem.mapId);
              if (map) {
                const elementsInPoster = map[
                  item.type as keyof MapPanelDef
                ] as Array<AllElementsDefs>;
                const elemTochange = elementsInPoster.find(
                  (e: AllElementsDefs) => e.id === elem.element.id,
                );
                if (elemTochange) {
                  elemTochange.timeControls[0] = {
                    ...elemTochange.timeControls[0],
                    [prop]: value,
                  };
                }
              }
            }
          }),
        );
      }
      return state;
    },
    multiUpdateTime(
      state,
      action: PayloadAction<{
        activeScene: string;
        selection: Multiselection;
        start: number;
        end: number;
        sceneLength: number;
        movement: 'drag' | 'resize';
      }>,
    ) {
      const {
        payload: { activeScene, selection, start, end, movement, sceneLength },
      } = action;
      const scene = state.project.sceneDefs.find((scene) => scene.id === activeScene);
      const selectedPosters = selection
        .filter((elem) => elem.type === 'weatherPosters')
        .map((item) => item.elements.map((el) => el.element.id))
        .flat(3);
      if (scene) {
        selection.forEach((item) =>
          item.elements.forEach((elem) => {
            if (!elem.parentId && !elem.mapId) {
              const elementsInScene = scene[item.type as keyof SceneDef] as Array<AllElementsDefs>;
              const elemTochange = elementsInScene.find(
                (e: AllElementsDefs) => e.id === elem.element.id,
              );
              if (elemTochange && movement === 'resize') {
                const newStart =
                  elemTochange.timeControls[0].startMS + start >= 0
                    ? elemTochange.timeControls[0].startMS + start
                    : 0;
                const newEnd =
                  elemTochange.timeControls[0].endMS + end > sceneLength
                    ? sceneLength
                    : elemTochange.timeControls[0].endMS + end;
                elemTochange.timeControls[0] = {
                  ...elemTochange.timeControls[0],
                  startMS: newStart,
                  endMS: newEnd,
                };
              }
              if (elemTochange && movement === 'drag') {
                const newStart =
                  elemTochange.timeControls[0].startMS + start >= 0
                    ? elemTochange.timeControls[0].startMS + start
                    : 0;
                const newEnd =
                  elemTochange.timeControls[0].endMS + end > sceneLength
                    ? sceneLength
                    : elemTochange.timeControls[0].endMS + end;
                if (start > 0 && newEnd === sceneLength)
                  elemTochange.timeControls[0] = {
                    ...elemTochange.timeControls[0],
                    startMS:
                      newEnd -
                      (elemTochange.timeControls[0].endMS - elemTochange.timeControls[0].startMS),
                    endMS: newEnd,
                  };
                else if (start < 0 && newStart === 0) {
                  elemTochange.timeControls[0] = {
                    ...elemTochange.timeControls[0],
                    startMS: newStart,
                    endMS:
                      elemTochange.timeControls[0].endMS - elemTochange.timeControls[0].startMS,
                  };
                } else {
                  elemTochange.timeControls[0] = {
                    ...elemTochange.timeControls[0],
                    startMS:
                      elemTochange.timeControls[0].startMS + start >= 0
                        ? elemTochange.timeControls[0].startMS + start
                        : 0,
                    endMS:
                      elemTochange.timeControls[0].endMS + end > sceneLength
                        ? sceneLength
                        : elemTochange.timeControls[0].endMS + end,
                  };
                }
              }
            }
            if (elem.parentId && !elem.mapId && selectedPosters.indexOf(elem.parentId) < 0) {
              const poster = scene.weatherPosters.find((pos) => pos.id === elem.parentId);
              const posterTime = poster?.timeControls[0];
              if (poster) {
                const elementsInPoster = poster[
                  item.type as keyof WeatherPosterDef
                ] as Array<AllElementsDefs>;
                const elemTochange = elementsInPoster.find(
                  (e: AllElementsDefs) => e.id === elem.element.id,
                );
                if (elemTochange && posterTime && movement === 'resize') {
                  elemTochange.timeControls[0] = {
                    ...elemTochange.timeControls[0],
                    startMS:
                      elemTochange.timeControls[0].startMS + start < 0
                        ? 0
                        : elemTochange.timeControls[0].startMS + start,
                    endMS:
                      elemTochange.timeControls[0].endMS + end > posterTime.endMS
                        ? posterTime.endMS
                        : elemTochange.timeControls[0].endMS + end,
                  };
                }
                if (elemTochange && posterTime && movement === 'drag') {
                  const newStart =
                    elemTochange.timeControls[0].startMS + start >= 0
                      ? elemTochange.timeControls[0].startMS + start
                      : 0;
                  const newEnd =
                    elemTochange.timeControls[0].endMS + end > posterTime.endMS
                      ? posterTime.endMS
                      : elemTochange.timeControls[0].endMS + end;
                  if (start > 0 && newEnd === posterTime.endMS)
                    elemTochange.timeControls[0] = {
                      ...elemTochange.timeControls[0],
                      startMS:
                        posterTime.endMS -
                        (elemTochange.timeControls[0].endMS - elemTochange.timeControls[0].startMS),
                      endMS: newEnd,
                    };
                  else if (start < 0 && newStart === 0) {
                    elemTochange.timeControls[0] = {
                      ...elemTochange.timeControls[0],
                      startMS: newStart,
                      endMS:
                        elemTochange.timeControls[0].endMS - elemTochange.timeControls[0].startMS,
                    };
                  } else {
                    elemTochange.timeControls[0] = {
                      ...elemTochange.timeControls[0],
                      startMS:
                        elemTochange.timeControls[0].startMS + start >= 0
                          ? elemTochange.timeControls[0].startMS + start
                          : 0,
                      endMS:
                        elemTochange.timeControls[0].endMS + end > sceneLength
                          ? sceneLength
                          : elemTochange.timeControls[0].endMS + end,
                    };
                  }
                }
              }
            }
            if (elem.parentId && elem.mapId) {
              const map = scene.mapPanels.find((mp) => mp.id === elem.mapId);
              if (map) {
                const poster = map.geoPosters.find((pos) => pos.id === elem.parentId);
                const posterTime = poster?.timeControls[0];
                if (poster) {
                  const elementsInPoster = poster[
                    item.type as keyof WeatherGeoPosterDef
                  ] as Array<AllElementsDefs>;
                  const elemTochange = elementsInPoster.find(
                    (e: AllElementsDefs) => e.id === elem.element.id,
                  );
                  if (elemTochange && posterTime && movement === 'resize') {
                    elemTochange.timeControls[0] = {
                      ...elemTochange.timeControls[0],
                      startMS:
                        elemTochange.timeControls[0].startMS + start < 0
                          ? 0
                          : elemTochange.timeControls[0].startMS + start,
                      endMS:
                        elemTochange.timeControls[0].endMS + end > posterTime.endMS
                          ? posterTime.endMS
                          : elemTochange.timeControls[0].endMS + end,
                    };
                  }
                  if (elemTochange && movement === 'drag') {
                    const newStart =
                      elemTochange.timeControls[0].startMS + start >= 0
                        ? elemTochange.timeControls[0].startMS + start
                        : 0;
                    const newEnd =
                      elemTochange.timeControls[0].endMS + end > sceneLength
                        ? sceneLength
                        : elemTochange.timeControls[0].endMS + end;
                    if (start > 0 && newEnd === sceneLength)
                      elemTochange.timeControls[0] = {
                        ...elemTochange.timeControls[0],
                        startMS:
                          newEnd -
                          (elemTochange.timeControls[0].endMS -
                            elemTochange.timeControls[0].startMS),
                        endMS: newEnd,
                      };
                    else if (start < 0 && newStart === 0) {
                      elemTochange.timeControls[0] = {
                        ...elemTochange.timeControls[0],
                        startMS: newStart,
                        endMS:
                          elemTochange.timeControls[0].endMS - elemTochange.timeControls[0].startMS,
                      };
                    } else {
                      elemTochange.timeControls[0] = {
                        ...elemTochange.timeControls[0],
                        startMS:
                          elemTochange.timeControls[0].startMS + start >= 0
                            ? elemTochange.timeControls[0].startMS + start
                            : 0,
                        endMS:
                          elemTochange.timeControls[0].endMS + end > sceneLength
                            ? sceneLength
                            : elemTochange.timeControls[0].endMS + end,
                      };
                    }
                  }
                }
              }
            }
            if (!elem.parentId && elem.mapId) {
              const map = scene.mapPanels.find((mp) => mp.id === elem.mapId);
              if (map) {
                const posterTime = map?.timeControls[0];
                const elementsInPoster = map[
                  item.type as keyof MapPanelDef
                ] as Array<AllElementsDefs>;
                const elemTochange = elementsInPoster?.find(
                  (e: AllElementsDefs) => e.id === elem.element.id,
                );
                if (elemTochange && posterTime && movement === 'resize') {
                  elemTochange.timeControls[0] = {
                    ...elemTochange.timeControls[0],
                    startMS:
                      elemTochange.timeControls[0].startMS + start < 0
                        ? 0
                        : elemTochange.timeControls[0].startMS + start,
                    endMS:
                      elemTochange.timeControls[0].endMS + end > posterTime.endMS
                        ? posterTime.endMS
                        : elemTochange.timeControls[0].endMS + end,
                  };
                }
                if (elemTochange && movement === 'drag') {
                  const newStart =
                    elemTochange.timeControls[0].startMS + start >= 0
                      ? elemTochange.timeControls[0].startMS + start
                      : 0;
                  const newEnd =
                    elemTochange.timeControls[0].endMS + end > sceneLength
                      ? sceneLength
                      : elemTochange.timeControls[0].endMS + end;
                  if (start > 0 && newEnd === sceneLength)
                    elemTochange.timeControls[0] = {
                      ...elemTochange.timeControls[0],
                      startMS:
                        newEnd -
                        (elemTochange.timeControls[0].endMS - elemTochange.timeControls[0].startMS),
                      endMS: newEnd,
                    };
                  else if (start < 0 && newStart === 0) {
                    elemTochange.timeControls[0] = {
                      ...elemTochange.timeControls[0],
                      startMS: newStart,
                      endMS:
                        elemTochange.timeControls[0].endMS - elemTochange.timeControls[0].startMS,
                    };
                  } else {
                    elemTochange.timeControls[0] = {
                      ...elemTochange.timeControls[0],
                      startMS:
                        elemTochange.timeControls[0].startMS + start >= 0
                          ? elemTochange.timeControls[0].startMS + start
                          : 0,
                      endMS:
                        elemTochange.timeControls[0].endMS + end > sceneLength
                          ? sceneLength
                          : elemTochange.timeControls[0].endMS + end,
                    };
                  }
                }
              }
            }
          }),
        );
      }
      return state;
    },
    updateDrawingElement(
      state,
      action: PayloadAction<{
        activeScene: string;
        activeMap: string;
        layerId: string;
        layerProperties: string;
      }>,
    ) {
      const {
        payload: { activeScene, activeMap, layerId, layerProperties },
      } = action;
      const scene = state.project.sceneDefs.find((scene) => scene.id === activeScene);
      if (scene) {
        const map = scene.mapPanels.find((map) => map.id === activeMap);
        if (map) {
          const drawing = map.drawingElements.find((drawing) => drawing.id === layerId);
          if (drawing) {
            console.log(drawing.id);
            drawing.drawingGeoJson = layerProperties;
            return state;
          }
        }
      }
      return state;
    },
    updateDrawingElementGeneral(
      state,
      action: PayloadAction<{
        activeScene: string;
        activeMap: string;
        layerId: string;
        layer: DrawingDef;
      }>,
    ) {
      const {
        payload: { activeScene, activeMap, layerId, layer },
      } = action;
      const scene = state.project.sceneDefs.find((scene) => scene.id === activeScene);
      if (scene) {
        const map = scene.mapPanels.find((map) => map.id === activeMap);
        if (map) {
          const index = map.drawingElements.findIndex((item) => item.id === layerId);
          if (index !== -1) {
            map.drawingElements[index] = layer;
          }
        }
      }
      return state;
    },
    insertScene(state, action: PayloadAction<{ scene: SceneDef; index: number; id: string }>) {
      const {
        payload: { scene, index, id },
      } = action;
      const clonedScene = { ...scene };
      clonedScene.name = 'Clone of ' + scene.name;
      clonedScene.id = id;
      clonedScene.mapPanels = clonedScene.mapPanels.map((e) => {
        return {
          ...e,
          id: v4(),
          wdSpace: e.wdSpace.map((space) => {
            return { ...space, id: v4() };
          }),
        };
      });
      clonedScene.mapPanels.forEach((map) => {
        map.geoPosters = map.geoPosters.map((gp) => ({ ...gp, id: v4() }));
      });
      state.project.sceneDefs.splice(index + 1, 0, clonedScene);
    },
    addNewProject(state, action: PayloadAction<{ project: C9ProjectDef }>) {
      const {
        payload: { project },
      } = action;
      project.properties.baseAppUrl = process.env.REACT_APP_API_BASE_URL!;
      project.properties.timezone = !project.properties.timezone
        ? Intl.DateTimeFormat().resolvedOptions().timeZone
        : project.properties.timezone;
      project.properties.imageType = project.properties.imageType
        ? project.properties.imageType
        : ImageTypeEnum.JPG;
      project.properties.renderCompression = project.properties.renderCompression
        ? project.properties.renderCompression
        : RenderCompressionEnum['10Mbps'];
      // TO BE DONE DM
      project.sceneDefs.forEach((sc) => {
        if (sc.mapPanels) {
          sc.mapPanels.forEach((mp) => {
            // console.log(
            //   'LOADED DRAWINGS ',
            //   mp.drawingElements.map((d) => d.keyframes.map((k) => JSON.parse(k.geoJson))),
            // );
            if (!mp.mapType || !MapStylesHash[mp.mapType]) mp.mapType = 'BASIC';
            /**TO BE DONE - Flyovers disabled */
            // if (mp.flyOverEnabled) mp.flyOverEnabled = false;
            if (!mp.graticule) {
              mp.graticule = new GraticulesDef();
            }
            /**Create indicator at project load  */
            if (mp.wdSpace?.length) {
              const space = mp.wdSpace[0];
              const fps = getFpsFromExportFormat(project.projectExportFormat);
              space.indicator = createUnSynchronizedIndicator(
                space,
                space.timeframeIndicatorFormat,
                space.mapTimeframeTextIndicator,
                fps,
              );
            }
          });
        }
      });

      state.project = project;
    },
    addImageLayer(
      state,
      action: PayloadAction<{
        imageLayer: ImagePanelDef;
        activeScene: string | number;
      }>,
    ) {
      const {
        payload: { imageLayer, activeScene },
      } = action;
      if (state.project.sceneDefs?.length)
        state?.project.sceneDefs
          .find((item) => item.id === activeScene)
          ?.imagePanels.push(imageLayer);
      else {
        const sceneDef = new SceneDef();
        sceneDef.imagePanels = [imageLayer];
        state?.project?.sceneDefs?.push(sceneDef);
      }
    },
    addAnimationLayer(
      state,
      action: PayloadAction<{
        animationLayer: AnimationPanelDef;
        activeScene: string | number;
      }>,
    ) {
      const {
        payload: { animationLayer, activeScene },
      } = action;
      if (state.project.sceneDefs?.length)
        state?.project.sceneDefs
          .find((item) => item.id === activeScene)
          ?.animationPanels.push(animationLayer);
      else {
        const sceneDef = new SceneDef();
        sceneDef.animationPanels = [animationLayer];
        state?.project?.sceneDefs?.push(sceneDef);
      }
    },
    addPosterLayer(
      state,
      action: PayloadAction<{
        weatherPoster: WeatherPosterDef;
        activeScene: string | number;
      }>,
    ) {
      const {
        payload: { weatherPoster, activeScene },
      } = action;
      if (state.project.sceneDefs?.length)
        state?.project.sceneDefs
          .find((item) => item.id === activeScene)
          ?.weatherPosters.push(weatherPoster);
      else {
        const sceneDef = new SceneDef();
        sceneDef.weatherPosters = [weatherPoster];
        state?.project?.sceneDefs?.push(sceneDef);
      }
    },
    addTextLayer(
      state,
      action: PayloadAction<{
        textLayer: TextPanelDef;
        activeScene: string | number;
      }>,
    ) {
      const {
        payload: { textLayer, activeScene },
      } = action;
      if (state.project.sceneDefs.length) {
        const sc = state.project.sceneDefs.find((item) => item.id === activeScene);
        if (sc && !sc.textPanels.map((tp) => tp.id).includes(textLayer.id))
          sc.textPanels.push(textLayer);
      } else {
        const sceneDef = new SceneDef();
        sceneDef.textPanels = [textLayer];
        state.project.sceneDefs.push(sceneDef);
      }
    },
    addTimestampLayer(
      state,
      action: PayloadAction<{
        timestampLayer: TimestampElementDef;
        activeScene: string | number;
      }>,
    ) {
      const {
        payload: { timestampLayer, activeScene },
      } = action;
      if (state.project.sceneDefs.length) {
        const sc = state.project.sceneDefs.find((item) => item.id === activeScene);
        if (sc) {
          if (!sc.timestampPanels) {
            sc.timestampPanels = [];
          }
          if (!sc.timestampPanels.map((tp) => tp.id).includes(timestampLayer.id)) {
            sc.timestampPanels.push(timestampLayer);
          }
        }
      }
      return state;
    },
    addPointDateLayer(
      state,
      action: PayloadAction<{
        dateLayer: PointDateDef;
        activeScene: string | number;
      }>,
    ) {
      const {
        payload: { dateLayer, activeScene },
      } = action;
      if (state.project.sceneDefs.length) {
        const sc = state.project.sceneDefs.find((item) => item.id === activeScene);
        if (sc && !sc.pointDates.map((tp) => tp.id).includes(dateLayer.id))
          sc.pointDates.push(dateLayer);
      } else {
        const sceneDef = new SceneDef();
        sceneDef.pointDates = [dateLayer];
        state.project.sceneDefs.push(sceneDef);
      }
    },
    addPointLocationLayer(
      state,
      action: PayloadAction<{
        locationLayer: PointLocationDef;
        activeScene: string | number;
      }>,
    ) {
      const {
        payload: { locationLayer, activeScene },
      } = action;
      if (state.project.sceneDefs.length) {
        const sc = state.project.sceneDefs.find((item) => item.id === activeScene);
        if (sc && !sc.pointLocation?.map((tp) => tp.id).includes(locationLayer.id))
          if (sc.pointLocation) sc.pointLocation.push(locationLayer);
          /*** remove after BE is solved and upper condition if (sc.pointLocation) is redundant**/ else
            sc.pointLocation = [locationLayer];
      } else {
        const sceneDef = new SceneDef();
        sceneDef.pointLocation = [locationLayer];
        state.project.sceneDefs.push(sceneDef);
      }
    },
    addOWDLayer(
      state,
      action: PayloadAction<{
        owdLayer: ObservedWDElementDef;
        activeScene: string;
      }>,
    ) {
      const {
        payload: { owdLayer, activeScene },
      } = action;
      if (state.project.sceneDefs.length)
        state.project.sceneDefs
          .find((item) => item.id === activeScene)
          ?.observedWDElements.push(owdLayer);
      else {
        const sceneDef = new SceneDef();
        sceneDef.observedWDElements = [owdLayer];
        state.project.sceneDefs.push(sceneDef);
      }
    },
    addMapOWDLayer(
      state,
      action: PayloadAction<{
        owdLayer: ObservedWDElementDef;
        mapId: string;
        activeScene: string;
      }>,
    ) {
      const {
        payload: { owdLayer, activeScene, mapId },
      } = action;
      const scene = state.project.sceneDefs.find((item) => item.id === activeScene);
      if (scene) {
        const map = scene.mapPanels.find((mapLayer) => mapLayer.id === mapId);
        if (map) {
          map.wdSpace[0].observedDataLayers.push(owdLayer);
        }
      }
      return state;
    },
    addForecastElements(
      state,
      action: PayloadAction<{
        forecastElements: ForecastWDElementDef;
        activeScene: string;
      }>,
    ) {
      const {
        payload: { forecastElements, activeScene },
      } = action;
      if (state.project.sceneDefs.length)
        state.project.sceneDefs
          .find((item) => item.id === activeScene)
          ?.forecastWDElements.push(forecastElements);
      else {
        const sceneDef = new SceneDef();
        sceneDef.forecastWDElements = [forecastElements];
        state.project.sceneDefs.push(sceneDef);
      }
    },
    addMapForecastLayer(
      state,
      action: PayloadAction<{
        forecastLayer: ForecastWDElementDef;
        mapId: string;
        activeScene: string;
      }>,
    ) {
      const {
        payload: { forecastLayer, activeScene, mapId },
      } = action;
      const scene = state.project.sceneDefs.find((item) => item.id === activeScene);
      if (scene) {
        const map = scene.mapPanels.find((mapLayer) => mapLayer.id === mapId);
        if (map) {
          map.wdSpace[0].forecastDataLayers.push(forecastLayer);
        }
      }
      return state;
    },
    addCustomVectorLayer(
      state,
      action: PayloadAction<{
        vector: CustomVectorLayer;
        mapId: string;
        activeScene: string;
      }>,
    ) {
      const {
        payload: { vector, activeScene, mapId },
      } = action;
      const scene = state.project.sceneDefs.find((item) => item.id === activeScene);
      if (scene) {
        const map = scene.mapPanels.find((mapLayer) => mapLayer.id === mapId);
        if (map) {
          map.wdSpace[0].vectorMapLayers.push(vector);
        }
      }
      return state;
    },
    editCustomVectorLayerStyle(
      state,
      action: PayloadAction<{
        vector: string;
        mapId: string;
        activeScene: string;
        style: string;
      }>,
    ) {
      const {
        payload: { vector, activeScene, mapId, style },
      } = action;
      const scene = state.project.sceneDefs.find((item) => item.id === activeScene);
      if (scene) {
        const map = scene.mapPanels.find((mapLayer) => mapLayer.id === mapId);
        if (map) {
          const layer = map.wdSpace[0].vectorMapLayers.find((item) => item.id === vector);
          if (layer) {
            layer.style = style;
            return state;
          }
          return state;
        }
        return state;
      }
      return state;
    },
    removeCustomVectorLayer(
      state,
      action: PayloadAction<{
        vector: string;
        mapId: string;
        activeScene: string;
      }>,
    ) {
      const {
        payload: { vector, activeScene, mapId },
      } = action;
      const scene = state.project.sceneDefs.find((item) => item.id === activeScene);
      if (scene) {
        const map = scene.mapPanels.find((mapLayer) => mapLayer.id === mapId);
        if (map) {
          const layers = map.wdSpace[0].vectorMapLayers;
          const indexToRemove = layers.findIndex((item) => item.id === vector);
          if (indexToRemove !== -1) {
            layers.splice(indexToRemove, 1);
          }
          //map.wdSpace[0].vectorMapLayers.push(vector);
        }
      }
      return state;
    },
    copyMapDrawingLayer(
      state,
      action: PayloadAction<{
        drawing: DrawingDef;
        mapId: string;
        activeScene: string;
      }>,
    ) {
      const {
        payload: { drawing, activeScene, mapId },
      } = action;
      const scene = state.project.sceneDefs.find((item) => item.id === activeScene);
      if (scene) {
        const map = scene.mapPanels.find((mapLayer) => mapLayer.id === mapId);
        if (map) {
          drawing.id = v4();
          map.drawingElements.push(drawing);
        }
      }
      return state;
    },
    fillSpaceWithForecast(
      state,
      action: PayloadAction<{
        forecastLayers: ForecastWDElementDef[];
        mapId: string;
        activeScene: string;
      }>,
    ) {
      const {
        payload: { forecastLayers, activeScene, mapId },
      } = action;
      const scene = state.project.sceneDefs.find((item) => item.id === activeScene);
      if (scene) {
        const map = scene.mapPanels.find((mapLayer) => mapLayer.id === mapId);
        if (map) {
          const forecast = map.wdSpace[0].forecastDataLayers;
          forecastLayers.forEach((layer) => {
            const exists = forecast.find(
              (fl) =>
                fl.forecastWDSource.utcDate === layer.forecastWDSource.utcDate &&
                fl.forecastWDSource.parameterType === layer.forecastWDSource.parameterType,
            );
            if (!exists) {
              forecast.push(layer);
            }
          });
          forecast.sort((a, b) =>
            moment(a.forecastWDSource.utcDate).isBefore(moment(b.forecastWDSource.utcDate))
              ? -1
              : moment(a.forecastWDSource.utcDate).isAfter(moment(b.forecastWDSource.utcDate))
              ? 1
              : 0,
          );
        }
      }
      return state;
    },
    addVideoLayer(
      state,
      action: PayloadAction<{
        videoLayer: VideoPanelDef;
        activeScene: string | number;
      }>,
    ) {
      const {
        payload: { videoLayer, activeScene },
      } = action;
      if (state.project.sceneDefs.length)
        state.project.sceneDefs
          .find((item) => item.id === activeScene)
          ?.videoPanels.push(videoLayer);
      else {
        const sceneDef = new SceneDef();
        sceneDef.videoPanels = [videoLayer];
        state?.project?.sceneDefs?.push(sceneDef);
      }
    },
    addMapLayer(
      state,
      action: PayloadAction<{
        mapLayer: MapPanelDef;
        activeScene: string | number;
      }>,
    ) {
      const {
        payload: { mapLayer, activeScene },
      } = action;
      // TO BE DONE
      if (!mapLayer.mapType || !MapStylesHash[mapLayer.mapType]) mapLayer.mapType = 'BASIC';

      if (state.project.sceneDefs.length) {
        state.project.sceneDefs.find((item) => item.id === activeScene)?.mapPanels.push(mapLayer);
      } else {
        const sceneDef = new SceneDef();
        sceneDef.mapPanels = [mapLayer];
        state.project.sceneDefs.push(sceneDef);
      }
    },
    replaceMapLayer(
      state,
      action: PayloadAction<{
        mapLayer: MapPanelDef;
        activeScene: string | number;
      }>,
    ) {
      const {
        payload: { mapLayer, activeScene },
      } = action;
      const sceneDef = state.project.sceneDefs.find((item) => item.id === activeScene);
      if (sceneDef) {
        const mapPanels = sceneDef.mapPanels;
        const index = mapPanels.findIndex((panel) => panel.id === mapLayer.id);
        mapPanels[index] = mapLayer;
      }
      return state;
    },
    addKeyFrame(
      state,
      action: PayloadAction<{
        activeScene: string;
        elementId: string | number;
        keyFrame: KeyFramesDef;
      }>,
    ) {
      const {
        payload: { activeScene, elementId, keyFrame },
      } = action;
      state.project.sceneDefs
        .find((scene) => scene.id === activeScene)
        ?.mapPanels.find((map) => map.id === elementId)
        ?.flyOver?.keyFrames?.push(keyFrame);
      state.project.sceneDefs
        .find((scene) => scene.id === activeScene)
        ?.mapPanels.find((map) => map.id === elementId)
        ?.flyOver?.keyFrames?.sort((a, b) => (a.timeInMS > b.timeInMS ? 1 : -1));
    },
    removeKeyFrame(
      state,
      action: PayloadAction<{
        activeScene: string;
        elementId: string | number;
        keyFrame: number;
      }>,
    ) {
      const {
        payload: { activeScene, elementId, keyFrame },
      } = action;
      state.project.sceneDefs
        .find((scene) => scene.id === activeScene)
        ?.mapPanels.find((map) => map.id === elementId)
        ?.flyOver?.keyFrames?.splice(keyFrame, 1);
    },
    updateIndicatorPosition(
      state,
      action: PayloadAction<{
        position: PositionControlDef;
        activeScene: string;
        mapId?: string;
      }>,
    ) {
      const {
        payload: { position, activeScene, mapId },
      } = action;
      const mapSpace = state.project.sceneDefs
        .find((scene) => scene.id === activeScene)!
        .mapPanels.find((mapElement) => mapElement.id === mapId)?.wdSpace[0];
      if (mapSpace) {
        mapSpace.mapTimeframeTextIndicator.positionControl = position;
      }
      return state;
    },
    updateAnimationTemplateSize(
      state,
      action: PayloadAction<{
        activeScene: string;
        elementId: string;
        w: number;
        h: number;
      }>,
    ) {
      const {
        payload: { activeScene, elementId, w, h },
      } = action;
      const scene = state.project.sceneDefs.find((scene) => scene.id === activeScene);
      if (scene) {
        const element = scene.animationPanels.find((animation) => animation.id === elementId);
        if (element) {
          element.animationPanelDefTemplate = { ...element.animationPanelDefTemplate, w: w, h: h };
        }
      }
      return state;
    },
    updatePosition(
      state,
      action: PayloadAction<{
        position: PositionControlDef;
        activeScene: string;
        elementType: SceneKeys<SceneDef> | 'mapTimeframeTextIndicator';
        elementId: string;
        isPoster?: boolean;
        posterId?: string;
      }>,
    ) {
      const {
        payload: { position, activeScene, elementType, elementId, isPoster, posterId },
      } = action;
      const cloned = cloneDeep(state) as ProjectState;
      if (!isPoster) {
        if (elementType === 'imagePanels') {
          cloned.project.sceneDefs
            .find((scene) => scene.id === activeScene)!
            .imagePanels.find((image) => image.id === elementId)!.positionControl = position;
        }
        if (elementType === 'animationPanels') {
          cloned.project.sceneDefs
            .find((scene) => scene.id === activeScene)!
            .animationPanels.find((image) => image.id === elementId)!.positionControl = position;
        }
        if (elementType === 'textPanels') {
          cloned.project.sceneDefs
            .find((scene) => scene.id === activeScene)!
            .textPanels.find((image) => image.id === elementId)!.positionControl = position;
        }
        if (elementType === 'timestampPanels') {
          cloned.project.sceneDefs
            .find((scene) => scene.id === activeScene)!
            .timestampPanels.find((image) => image.id === elementId)!.positionControl = position;
        }
        if (elementType === 'pointDates') {
          cloned.project.sceneDefs
            .find((scene) => scene.id === activeScene)!
            .pointDates.find((image) => image.id === elementId)!.positionControl = position;
        }
        if (elementType === 'pointLocation') {
          cloned.project.sceneDefs
            .find((scene) => scene.id === activeScene)!
            .pointLocation.find((image) => image.id === elementId)!.positionControl = position;
        }
        if (elementType === 'videoPanels') {
          cloned.project.sceneDefs
            .find((scene) => scene.id === activeScene)!
            .videoPanels.find((image) => image.id === elementId)!.positionControl = position;
        }
        if (elementType === 'mapPanels') {
          cloned.project.sceneDefs
            .find((scene) => scene.id === activeScene)!
            .mapPanels.find((image) => image.id === elementId)!.positionControl = position;
        }
        if (elementType === 'observedWDElements') {
          cloned.project.sceneDefs
            .find((scene) => scene.id === activeScene)!
            .observedWDElements.find((observed) => observed.id === elementId)!.positionControl =
            position;
        }
        if (elementType === 'forecastWDElements') {
          cloned.project.sceneDefs
            .find((scene) => scene.id === activeScene)!
            .forecastWDElements.find((observed) => observed.id === elementId)!.positionControl =
            position;
        }
        if (elementType === 'weatherPosters') {
          const scene = cloned.project.sceneDefs.find((scene) => scene.id === activeScene);
          if (scene) {
            const poster = scene.weatherPosters.find((image) => image.id === elementId);
            if (poster) {
              poster.positionControl = position;
            }
          }
        }
        // @ts-ignore
        if (elementType === 'layerLegend') {
          const currActiveScene = cloned.project.sceneDefs.find(
            (scene) => scene.id === activeScene,
          )!;
          const flattenAllLayers = getAllLayerSetupsInSceneReducer(currActiveScene);
          const layerFound = flattenAllLayers.find((l) => l.id === elementId);
          layerFound!.paletteLegendPositionControl = position;
        }
      } else if (isPoster) {
        if (elementType === 'imagePanels') {
          cloned.project.sceneDefs
            .find((scene) => scene.id === activeScene)!
            .weatherPosters.find((poster) => poster.id === posterId)!
            .imagePanels.find((image) => image.id === elementId)!.positionControl = position;
        }
        if (elementType === 'animationPanels') {
          cloned.project.sceneDefs
            .find((scene) => scene.id === activeScene)!
            .weatherPosters.find((poster) => poster.id === posterId)!
            .animationPanels.find((image) => image.id === elementId)!.positionControl = position;
        }
        if (elementType === 'textPanels') {
          cloned.project.sceneDefs
            .find((scene) => scene.id === activeScene)!
            .weatherPosters.find((poster) => poster.id === posterId)!
            .textPanels.find((image) => image.id === elementId)!.positionControl = position;
        }
        if (elementType === 'videoPanels') {
          cloned.project.sceneDefs
            .find((scene) => scene.id === activeScene)!
            .weatherPosters.find((poster) => poster.id === posterId)!
            .videoPanels.find((image) => image.id === elementId)!.positionControl = position;
        }
        if (elementType === 'observedWDElements') {
          cloned.project.sceneDefs
            .find((scene) => scene.id === activeScene)!
            .weatherPosters.find((poster) => poster.id === posterId)!
            .observedWDElements.find((observed) => observed.id === elementId)!.positionControl =
            position;
        }
        if (elementType === 'forecastWDElements') {
          cloned.project.sceneDefs
            .find((scene) => scene.id === activeScene)!
            .weatherPosters.find((poster) => poster.id === posterId)!
            .forecastWDElements.find((observed) => observed.id === elementId)!.positionControl =
            position;
        }
      }
      return cloned;
    },
    updateCompositePosition(
      state,
      action: PayloadAction<{
        position: PositionControlDef;
        activeMap: string;
        activeScene: string;
        posterId?: string;
      }>,
    ) {
      const {
        payload: { position, activeScene, activeMap, posterId },
      } = action;
      const cloned = cloneDeep(state);
      const scene = cloned.project.sceneDefs.find((scene) => scene.id === activeScene);
      if (scene) {
        const map = scene.mapPanels.find((mapPanel) => mapPanel.id === activeMap);
        if (map) {
          const composite = map.geoPosters.find((poster) => poster.id === posterId);
          if (composite) {
            composite.positionControl = position;
          }
        }
      }
      return cloned;
    },
    updateMapOverlay(
      state,
      action: PayloadAction<{
        position: PositionControlDef;
        activeScene: string;
        elementType: SceneKeys<SceneDef> | 'mapTimeframeTextIndicator';
        geoPosterId?: string;
        elementId: string;
        mapId: string;
      }>,
    ) {
      const {
        payload: { position, activeScene, elementType, elementId, geoPosterId, mapId },
      } = action;
      const scene = state.project.sceneDefs.find((scene) => scene.id === activeScene)!;
      const activeMap = scene.mapPanels.find((m) => m.id === mapId)!;
      const activeGeoPoster = activeMap.geoPosters.find((gp) => gp.id === geoPosterId)!;
      console.log({ mapId, geoPosterId });
      if (mapId && !geoPosterId) {
        const space = activeMap.wdSpace[0];
        if (elementType === 'observedWDElements') {
          const elem = space.observedDataLayers.find((layer) => layer.id === elementId);
          console.log(elem);
          if (elem) elem.positionControl = position;
        }
        if (elementType === 'forecastWDElements') {
          const elem = space.forecastDataLayers.find((layer) => layer.id === elementId);
          if (elem) elem.positionControl = position;
        }
      }
      // @ts-ignore
      if (elementType === 'mapOverlay') {
        activeGeoPoster.positionControl = position;
      }
      if (elementType === 'imagePanels') {
        activeGeoPoster.imagePanels.find((p) => p.id === elementId)!.positionControl = position;
      }
      if (elementType === 'textPanels') {
        activeGeoPoster.textPanels.find((p) => p.id === elementId)!.positionControl = position;
      }
      if (elementType === 'videoPanels') {
        activeGeoPoster.videoPanels.find((p) => p.id === elementId)!.positionControl = position;
      }
      if (elementType === 'observedWDElements') {
        if (geoPosterId)
          activeGeoPoster.observedWDElements.find((p) => p.id === elementId)!.positionControl =
            position;
        else {
          activeMap.wdSpace[0].observedDataLayers.find((p) => p.id === elementId)!.positionControl =
            position;
        }
      }
      if (elementType === 'forecastWDElements') {
        if (geoPosterId)
          activeGeoPoster.forecastWDElements.find((p) => p.id === elementId)!.positionControl =
            position;
        else {
          activeMap.wdSpace[0].forecastDataLayers.find((p) => p.id === elementId)!.positionControl =
            position;
        }
      }
      return state;
    },
    updateMapOverlayLonLat(
      state,
      action: PayloadAction<{
        lonLat: [number, number];
        activeScene: string;
        geoPosterId: string;
        mapId: string;
      }>,
    ) {
      const {
        payload: { lonLat, activeScene, geoPosterId, mapId },
      } = action;
      const scene = state.project.sceneDefs.find((scene) => scene.id === activeScene)!;
      const activeMap = scene.mapPanels.find((m) => m.id === mapId)!;
      const activeGeoPoster = activeMap.geoPosters.find((gp) => gp.id === geoPosterId);
      if (mapId && activeGeoPoster) {
        activeGeoPoster.longitude = lonLat[0];
        activeGeoPoster.latitude = lonLat[1];
      }
    },
    updateForecastLonLat(
      state,
      action: PayloadAction<{
        lonLat: [number, number];
        activeScene: string;
        elemId: string;
        mapId: string;
      }>,
    ) {
      const {
        payload: { lonLat, activeScene, elemId, mapId },
      } = action;
      const scene = state.project.sceneDefs.find((scene) => scene.id === activeScene)!;
      const activeMap = scene.mapPanels.find((m) => m.id === mapId)!;
      const activeElement = activeMap.wdSpace[0].forecastDataLayers.find((gp) => gp.id === elemId);
      if (mapId && activeElement) {
        activeElement.forecastWDSource.location.longitude = lonLat[0];
        activeElement.forecastWDSource.location.latitude = lonLat[1];
      }
    },
    updateMapOverlayProperties(
      state,
      action: PayloadAction<{
        newValue: any;
        activeScene: string;
        geoPosterId: string;
        activeProp: Paths<WeatherPosterDef>;
      }>,
    ) {
      const {
        payload: { activeScene, newValue, geoPosterId, activeProp },
      } = action;
      const scene = state.project.sceneDefs.find((s) => s.id === activeScene);
      const allPosters = scene?.mapPanels.map((m) => m.geoPosters).flat();
      const poster = allPosters?.find((p) => p.id === geoPosterId);
      set(poster!, activeProp, newValue);
    },
    addGraphLayer(
      state,
      action: PayloadAction<{
        graphLayer: WeatherPosterDef | never[];
        activeScene: string | number;
      }>,
    ) {
      const {
        payload: { graphLayer, activeScene },
      } = action;
      if (state.project.sceneDefs.length) {
        state.project.sceneDefs
          .find((item) => item.id === activeScene)
          ?.weatherPosters.push(graphLayer as WeatherPosterDef);
      } else {
        const sceneDef = new SceneDef();
        sceneDef.weatherPosters = [graphLayer as WeatherPosterDef];
        state.project.sceneDefs.push(sceneDef);
      }
    },
    deleteFromGraphLayer(
      state,
      action: PayloadAction<{
        graphLayer: string;
        activeScene: string | number;
        propVal: SceneKeys<SceneDef>;
        id: string;
      }>,
    ) {
      const {
        payload: { graphLayer, activeScene, propVal, id },
      } = action;
      const graph = state.project.sceneDefs
        .find((scene) => scene.id === activeScene)
        ?.weatherPosters.find((poster) => poster.id === graphLayer);
      let layer;
      if (graph) {
        layer =
          graph[
            propVal as
              | 'imagePanels'
              | 'videoPanels'
              | 'forecastWDElements'
              | 'observedWDElements'
              | 'textPanels'
          ];
        const index = layer.findIndex((item) => item.id === id);
        layer.splice(index, 1);
      }
    },
    addAudioLayer(
      state,
      action: PayloadAction<{
        audioLayer: AudioElement;
        activeScene: string | number;
      }>,
    ) {
      const {
        payload: { audioLayer, activeScene },
      } = action;
      if (state.project.sceneDefs.length)
        state.project.sceneDefs
          .find((item) => item.id === activeScene)
          ?.audioElements.push(audioLayer);
      else {
        const sceneDef = new SceneDef();
        sceneDef.audioElements = [audioLayer];
        state?.project?.sceneDefs?.push(sceneDef);
      }
    },
    updatePosterElementTime(
      state,
      action: PayloadAction<{
        time: TimeControlDef[];
        activeScene: string | number;
        elementType: ElementsEnum;
        elementId: string;
        posterId: string;
      }>,
    ) {
      const {
        payload: { activeScene, elementId, time, elementType, posterId },
      } = action;
      const scene = state.project.sceneDefs.find((scene) => scene.id === activeScene);
      if (scene) {
        const poster = scene.weatherPosters.find((poster) => poster.id === posterId);
        if (poster) {
          if (elementType === ElementsEnum.IMAGE) {
            const image = poster.imagePanels.find((image) => image.id === elementId);
            if (image) image.timeControls = time;
          }
          if (elementType === ElementsEnum.ANIMATION) {
            const image = poster.animationPanels.find((image) => image.id === elementId);
            if (image) image.timeControls = time;
          }
          if (elementType === ElementsEnum.TEXT) {
            const text = poster.textPanels.find((text) => text.id === elementId);
            if (text) {
              text.timeControls = time;
            }
          }
          if (elementType === ElementsEnum.VIDEO) {
            const video = poster.videoPanels.find((video) => video.id === elementId);
            if (video) {
              video.timeControls = time;
            }
          }
          if (elementType === ElementsEnum.OBSERVED_WD) {
            const observed = poster.observedWDElements.find(
              (observed) => observed.id === elementId,
            );
            if (observed) {
              observed.timeControls = time;
            }
          }
          if (elementType === ElementsEnum.FORECAST_WD) {
            const forecast = poster.forecastWDElements.find(
              (forecast) => forecast.id === elementId,
            );
            if (forecast) {
              forecast.timeControls = time;
            }
          }
          if (elementType === ElementsEnum.POINT_DATE) {
            const date = poster.pointDates.find((date) => date.id === elementId);
            if (date) {
              date.timeControls = time;
            }
          }
          if (elementType === ElementsEnum.POINT_LOCATION) {
            const date = poster.pointLocation.find((date) => date.id === elementId);
            if (date) {
              date.timeControls = time;
            }
          }
        }
      }
    },
    updateGeoPosterElementTime(
      state,
      action: PayloadAction<{
        time: TimeControlDef[];
        activeScene: string | number;
        elementType: ElementsEnum;
        elementId: string;
        mapId: string;
        posterId: string;
      }>,
    ) {
      const {
        payload: { activeScene, elementId, time, elementType, posterId, mapId },
      } = action;
      const scene = state.project.sceneDefs.find((scene) => scene.id === activeScene);
      if (scene) {
        const map = scene.mapPanels.find((map) => map.id === mapId);
        if (map) {
          const poster = map.geoPosters.find((poster) => poster.id === posterId);
          if (poster) {
            if (elementType === ElementsEnum.IMAGE) {
              const image = poster.imagePanels.find((image) => image.id === elementId);
              if (image) image.timeControls = time;
            }
            if (elementType === ElementsEnum.TEXT) {
              const text = poster.textPanels.find((text) => text.id === elementId);
              if (text) {
                text.timeControls = time;
              }
            }
            if (elementType === ElementsEnum.VIDEO) {
              const video = poster.videoPanels.find((video) => video.id === elementId);
              if (video) {
                video.timeControls = time;
              }
            }
            if (elementType === ElementsEnum.OBSERVED_WD) {
              const observed = poster.observedWDElements.find(
                (observed) => observed.id === elementId,
              );
              if (observed) {
                observed.timeControls = time;
              }
            }
            if (elementType === ElementsEnum.FORECAST_WD) {
              const forecast = poster.forecastWDElements.find(
                (forecast) => forecast.id === elementId,
              );
              if (forecast) {
                forecast.timeControls = time;
              }
            }
            if (elementType === ElementsEnum.POINT_DATE) {
              const date = poster.pointDates.find((date) => date.id === elementId);
              if (date) {
                date.timeControls = time;
              }
            }
            if (elementType === ElementsEnum.POINT_LOCATION) {
              const date = poster.pointLocation.find((date) => date.id === elementId);
              if (date) {
                date.timeControls = time;
              }
            }
          }
        }
      }
    },
    updateElementTime(
      state,
      action: PayloadAction<{
        time: TimeControlDef[];
        activeScene: string | number;
        elementType: ElementsEnum;
        elementId: string;
        drawMapId?: string;
      }>,
    ) {
      const {
        payload: { time, activeScene, elementType, elementId, drawMapId = undefined },
      } = action;
      time.forEach((piece) => {
        if (piece.startMS < 0) {
          const diff = piece.endMS - piece.startMS;
          piece.startMS = 0;
          piece.endMS = diff;
        }
      });
      if (elementType === ElementsEnum.IMAGE)
        state.project.sceneDefs
          .find((scene) => scene.id === activeScene)!
          .imagePanels.find((image) => image.id === elementId)!.timeControls = time;
      if (elementType === ElementsEnum.ANIMATION)
        state.project.sceneDefs
          .find((scene) => scene.id === activeScene)!
          .animationPanels.find((animation) => animation.id === elementId)!.timeControls = time;
      if (elementType === ElementsEnum.TEXT)
        state.project.sceneDefs
          .find((scene) => scene.id === activeScene)!
          .textPanels.find((image) => image.id === elementId)!.timeControls = time;
      if (elementType === ElementsEnum.TIMESTAMP)
        state.project.sceneDefs
          .find((scene) => scene.id === activeScene)!
          .timestampPanels.find((timestamp) => timestamp.id === elementId)!.timeControls = time;
      if (elementType === ElementsEnum.POINT_DATE)
        state.project.sceneDefs
          .find((scene) => scene.id === activeScene)!
          .pointDates.find((image) => image.id === elementId)!.timeControls = time;
      if (elementType === ElementsEnum.POINT_LOCATION)
        state.project.sceneDefs
          .find((scene) => scene.id === activeScene)!
          .pointLocation.find((image) => image.id === elementId)!.timeControls = time;
      if (elementType === ElementsEnum.VIDEO)
        state.project.sceneDefs
          .find((scene) => scene.id === activeScene)!
          .videoPanels.find((image) => image.id === elementId)!.timeControls = time;
      if (elementType === ElementsEnum.AUDIO)
        state.project.sceneDefs
          .find((scene) => scene.id === activeScene)!
          .audioElements.find((image) => image.id === elementId)!.timeControls = time;
      if (elementType === ElementsEnum.MAP)
        state.project.sceneDefs
          .find((scene) => scene.id === activeScene)!
          .mapPanels.find((image) => image.id === elementId)!.timeControls = time;
      if (elementType === ElementsEnum.OBSERVED_WD)
        state.project.sceneDefs
          .find((scene) => scene.id === activeScene)!
          .observedWDElements.find((image) => image.id === elementId)!.timeControls = time;
      if (elementType === ElementsEnum.FORECAST_WD)
        state.project.sceneDefs
          .find((scene) => scene.id === activeScene)!
          .forecastWDElements.find((image) => image.id === elementId)!.timeControls = time;
      if (elementType === ElementsEnum.WEATHER_GRAPH) {
        const scene = state.project.sceneDefs.find((scene) => scene.id === activeScene);
        if (scene) {
          const poster = scene.weatherPosters.find((image) => image.id === elementId);
          if (poster) {
            poster.timeControls = time;
          }
        }
      }
      if (elementType === ElementsEnum.DRAWING)
        state.project.sceneDefs
          .find((scene) => scene.id === activeScene)!
          .mapPanels.find((image) => image.id === drawMapId!)!
          .drawingElements.find((d) => d.id === elementId)!.timeControls = time;
      return state;
    },
    updateSpaceTime(
      state,
      action: PayloadAction<{
        time: TimeControlDef;
        activeScene: string;
        mapId: string;
        spaceId: string;
      }>,
    ) {
      const {
        payload: { activeScene, mapId, spaceId, time },
      } = action;
      const scene = state.project.sceneDefs.find((scene) => scene.id === activeScene);
      const activeMap = scene?.mapPanels.find((mapItem) => mapItem.id === mapId);
      if (activeMap) {
        const layers = activeMap.wdSpace.find((space) => space.id === spaceId);
        if (layers) layers.timeControls[0] = time;
      }
      return state;
    },
    setSpaceSync(
      state,
      action: PayloadAction<{
        sync: boolean;
        mapId: string;
        spaceId: string;
        activeScene: string;
      }>,
    ) {
      const {
        payload: { activeScene, mapId, spaceId, sync },
      } = action;
      const scene = state.project.sceneDefs.find((scene) => scene.id === activeScene);
      const activeMap = scene?.mapPanels.find((mapItem) => mapItem.id === mapId);
      if (activeMap) {
        const layers = activeMap.wdSpace.find((space) => space.id === spaceId);
        if (layers) layers.layerSync = sync;
      }
      return state;
    },
    updateSpaceClipLayers(
      state,
      action: PayloadAction<{
        payload: WeatherDataSpaceDef;
        activeScene: string;
        mapId: string;
        spaceId: string;
      }>,
    ) {
      const {
        payload: { activeScene, mapId, spaceId, payload },
      } = action;
      const scene = state.project.sceneDefs.find((scene) => scene.id === activeScene);
      const activeMap = scene?.mapPanels.find((mapItem) => mapItem.id === mapId);
      if (activeMap) {
        const space = activeMap.wdSpace.find((space) => space.id === spaceId);
        if (space) {
          space.gribMapLayers = payload.gribMapLayers;
          space.radarMapLayers = payload.radarMapLayers;
          space.satelliteMapLayers = payload.satelliteMapLayers;
          space.symbolLayers = payload.symbolLayers;
        }
      }
      return state;
    },
    updateDrawTime(
      state,
      action: PayloadAction<{
        time: TimeControlDef[];
        activeScene: string;
        mapId: string;
        spaceId: string;
      }>,
    ) {
      const {
        payload: { activeScene, mapId, spaceId, time },
      } = action;
      const scene = state.project.sceneDefs.find((scene) => scene.id === activeScene);
      const activeMap = scene?.mapPanels.find((mapItem) => mapItem.id === mapId);
      if (activeMap) {
        const layers = activeMap.drawingElements.find((space) => space.id === spaceId);
        if (layers) {
          layers.timeControls = time;
        }
      }
      return state;
    },
    updateMapObservedLayer(
      state,
      action: PayloadAction<{
        time: TimeControlDef;
        activeScene: string;
        mapId: string;
        id: string;
      }>,
    ) {
      const {
        payload: { activeScene, mapId, id, time },
      } = action;
      const scene = state.project.sceneDefs.find((scene) => scene.id === activeScene);
      const activeMap = scene?.mapPanels.find((mapItem) => mapItem.id === mapId);
      if (activeMap) {
        const layer = activeMap.wdSpace[0].observedDataLayers.find((item) => item.id === id);
        if (layer) layer.timeControls[0] = time;
      }
      return state;
    },
    updateMapForecastLayer(
      state,
      action: PayloadAction<{
        activeScene: string;
        mapId: string;
        elements: ForecastWDElementDef[];
      }>,
    ) {
      const {
        payload: { activeScene, mapId, elements },
      } = action;
      function replaceObjects(
        originalArray: ForecastWDElementDef[],
        objectsToReplace: ForecastWDElementDef[],
      ): ForecastWDElementDef[] {
        return originalArray.map((originalObj) => {
          const replacementObj = objectsToReplace.find(
            (replaceObj) => replaceObj.id === originalObj.id,
          );
          return replacementObj ? replacementObj : originalObj;
        });
      }
      const scene = state.project.sceneDefs.find((scene) => scene.id === activeScene);
      const activeMap = scene?.mapPanels.find((mapItem) => mapItem.id === mapId);
      if (activeMap) {
        activeMap.wdSpace[0].forecastDataLayers = replaceObjects(
          activeMap.wdSpace[0].forecastDataLayers,
          elements,
        );
        return state;
      }
    },
    updateMapGRSLayer(
      state,
      action: PayloadAction<{
        time: TimeControlDef;
        activeScene: string;
        mapId: string;
        layerType: MapLayers;
        index: number;
      }>,
    ) {
      const {
        payload: { activeScene, mapId, layerType, index, time },
      } = action;
      const scene = state.project.sceneDefs.find((scene) => scene.id === activeScene);
      const activeMap = scene?.mapPanels.find((mapItem) => mapItem.id === mapId);
      if (activeMap) {
        const layer = activeMap.wdSpace[0][layerType][index];
        if (layer) layer.timeControls[0] = time;
      }
    },
    updatePosterLayer(
      state,
      action: PayloadAction<{
        time: TimeControlDef;
        activeScene: string;
        mapId: string;
        index: number;
      }>,
    ) {
      const {
        payload: { activeScene, mapId, index, time },
      } = action;
      const scene = state.project.sceneDefs.find((scene) => scene.id === activeScene);
      const activeMap = scene?.mapPanels.find((mapItem) => mapItem.id === mapId);
      if (activeMap) {
        const layer = activeMap.geoPosters[index];
        if (layer) layer.timeControls[0] = time;
      }
    },
    updateMapLayerTime(
      state,
      action: PayloadAction<{
        time: TimeControlDef;
        activeElement: string;
        activeScene: string;
        mapId: string;
        layerType: 'observedDataLayers' | 'forecastDataLayers' | 'drawingElements';
        index: number;
      }>,
    ) {
      const {
        payload: { activeScene, mapId, layerType, index, time, activeElement },
      } = action;
      const scene = state.project.sceneDefs.find((scene) => scene.id === activeScene);
      const activeMap = scene?.mapPanels.find((mapItem) => mapItem.id === mapId);
      if (activeMap) {
        if (layerType === 'drawingElements') {
          const layer = activeMap.drawingElements.find(
            (layer) =>
              JSON.parse(layer.drawingGeoJson).features[0].properties.featureId === activeElement,
          );
          if (layer) {
            layer.timeControls[index] = time;
          }
        } else {
          const space = activeMap.wdSpace[0];
          const layerTypeArray: ObservedWDElementDef[] | ForecastWDElementDef[] = space[layerType];
          if (layerTypeArray) {
            // @ts-ignore
            const layer = layerTypeArray.find(
              (lay: ObservedWDElementDef | ForecastWDElementDef) => lay.id === activeElement,
            );
            if (layer) {
              layer.timeControls[index] = time;
            }
          }
        }
      }
    },
    updateTextLayer(
      state,
      action: PayloadAction<{
        newValue: any;
        propertyPath: Leaves<TextPanelDef>;
        activeScene: string | number;
        elementId: string | number;
        parentId?: string;
      }>,
    ) {
      const {
        payload: { activeScene, propertyPath, elementId, newValue, parentId },
      } = action;
      const toBeChanged = parentId
        ? state.project.sceneDefs
            .find((sc) => sc.id === activeScene)!
            .weatherPosters.find((poster) => poster.id === parentId)!
            .textPanels.find((tp) => tp.id === elementId)
        : state.project.sceneDefs
            .find((sc) => sc.id === activeScene)!
            .textPanels.find((tp) => tp.id === elementId);
      set(toBeChanged!, propertyPath, newValue);
      return state;
    },
    deleteTextLayer(
      state,
      action: PayloadAction<{
        activeScene: string | number;
        elementId: string | number;
        parentId?: string;
      }>,
    ) {
      const {
        payload: { activeScene, elementId, parentId },
      } = action;

      if (parentId) {
        state.project.sceneDefs
          .find((sc) => sc.id === activeScene)!
          .weatherPosters.find((poster) => poster.id === parentId)!.textPanels =
          state.project.sceneDefs
            .find((sc) => sc.id === activeScene)!
            .weatherPosters.find((poster) => poster.id === parentId)!
            .textPanels.filter((tp) => tp.id !== elementId);
      } else {
        state.project.sceneDefs.find((sc) => sc.id === activeScene)!.textPanels =
          state.project.sceneDefs
            .find((sc) => sc.id === activeScene)!
            .textPanels.filter((tp) => tp.id !== elementId);
      }
      return state;
    },
    updateTimestampLayer(
      state,
      action: PayloadAction<{
        propertyPath: Leaves<TimestampElementDef>;
        activeScene: string;
        elementId: string;
        value: any;
      }>,
    ) {
      const {
        payload: { propertyPath, activeScene, elementId, value },
      } = action;
      const scene = state.project.sceneDefs.find((sc) => sc.id === activeScene);
      if (scene) {
        const layer = scene.timestampPanels.find((panel) => panel.id === elementId);
        if (layer) {
          const typedLayer = layer as TimestampElementDef;
          // @ts-ignore
          typedLayer[propertyPath] = value;
        }
      }
      return state;
    },
    updateDateLayer(
      state,
      action: PayloadAction<{
        newValue: any;
        propertyPath: Leaves<PointDateDef>;
        activeScene: string | number;
        elementId: string | number;
        parentId?: string;
        mapId?: string;
      }>,
    ) {
      const {
        payload: { activeScene, propertyPath, elementId, newValue, parentId, mapId },
      } = action;
      const scene = state.project.sceneDefs.find((sc) => sc.id === activeScene);
      if (parentId && mapId && scene) {
        const map = scene.mapPanels.find((map) => map.id === mapId);
        if (map) {
          const poster = map.geoPosters.find((poster) => poster.id === parentId);
          if (poster) {
            const element = poster.pointDates.find((date) => date.id === elementId);
            if (element) {
              set(element, propertyPath, newValue);
            }
          }
        }
      }
      if (parentId && !mapId && scene) {
        const poster = scene.weatherPosters.find((poster) => poster.id === parentId);
        if (poster) {
          const element = poster.pointDates.find((date) => date.id === elementId);
          if (element) {
            set(element, propertyPath, newValue);
          }
        }
      }
      if (!parentId && !mapId && scene) {
        const element = scene.pointDates.find((date) => date.id === elementId);
        if (element) {
          set(element, propertyPath, newValue);
        }
      }
      return state;
    },
    updateLocationLayer(
      state,
      action: PayloadAction<{
        newValue: any;
        propertyPath: Leaves<PointLocationDef>;
        activeScene: string | number;
        elementId: string | number;
        parentId?: string;
        mapId?: string;
      }>,
    ) {
      const {
        payload: { activeScene, propertyPath, elementId, newValue, parentId, mapId },
      } = action;
      const scene = state.project.sceneDefs.find((sc) => sc.id === activeScene);
      if (parentId && mapId && scene) {
        const map = scene.mapPanels.find((map) => map.id === mapId);
        if (map) {
          const poster = map.geoPosters.find((poster) => poster.id === parentId);
          if (poster) {
            const element = poster.pointLocation.find((date) => date.id === elementId);
            if (element) {
              set(element, propertyPath, newValue);
            }
          }
        }
      }
      if (parentId && !mapId && scene) {
        const poster = scene.weatherPosters.find((poster) => poster.id === parentId);
        if (poster) {
          const element = poster.pointLocation.find((date) => date.id === elementId);
          if (element) {
            set(element, propertyPath, newValue);
          }
        }
      }
      if (!parentId && !mapId && scene) {
        const element = scene.pointLocation.find((date) => date.id === elementId);
        if (element) {
          set(element, propertyPath, newValue);
        }
      }
      return state;
    },
    updateIndicatorLayer(
      state,
      action: PayloadAction<{
        newValue: any;
        propertyPath: Leaves<TextPanelDef>;
        activeScene: string | number;
        parentId?: string;
      }>,
    ) {
      const {
        payload: { activeScene, propertyPath, newValue, parentId },
      } = action;
      const indicator = state.project.sceneDefs
        .find((sc) => sc.id === activeScene)!
        .mapPanels.find((poster) => poster.id === parentId)?.wdSpace[0].mapTimeframeTextIndicator;
      if (indicator) {
        // @ts-ignore
        indicator[propertyPath] = newValue;
      }
      return state;
    },
    updateSymbolLayerStyle(
      state,
      action: PayloadAction<{
        newValue: any;
        propertyPath: Leaves<SymbolStyleDef>;
        activeScene: string | number;
        mapId: string;
        layerId: string;
      }>,
    ) {
      const {
        payload: { activeScene, propertyPath, newValue, layerId, mapId },
      } = action;
      const symbolLayers = state.project.sceneDefs
        .find((sc) => sc.id === activeScene)!
        .mapPanels.find((poster) => poster.id === mapId)
        ?.wdSpace[0].symbolLayers.map(cloneDeep);

      symbolLayers?.forEach((l) => {
        if (l.id === layerId) {
          // @ts-ignore
          l.symbolSource.defaultStyle[propertyPath] = newValue;
        }
      });

      if (symbolLayers) {
        state.project.sceneDefs
          .find((sc) => sc.id === activeScene)!
          .mapPanels.find((poster) => poster.id === mapId)!.wdSpace[0].symbolLayers = symbolLayers;
      }

      return state;
    },
    updateObservedMapLayerBox(
      state,
      action: PayloadAction<{
        id: string;
        newValue: any;
        propertyPath: Leaves<BoxDef | BackgroundDef>;
        activeScene: string | number;
        parentId: string;
      }>,
    ) {
      const {
        payload: { activeScene, propertyPath, newValue, parentId, id },
      } = action;
      const spaces = state.project.sceneDefs
        .find((scene) => scene.id === activeScene)
        ?.mapPanels.find((element) => element.id === parentId)?.wdSpace;
      if (spaces && spaces[0]) {
        const box = spaces[0].observedDataLayers.find((item) => item.id === id)?.boxDef as BoxDef;
        //@ts-ignore
        set(box, propertyPath, newValue);
      }
      return state;
    },
    updateForecastMapLayerBox(
      state,
      action: PayloadAction<{
        id: string;
        newValue: any;
        propertyPath: Leaves<BoxDef | BackgroundDef>;
        activeScene: string | number;
        parentId: string;
      }>,
    ) {
      const {
        payload: { activeScene, propertyPath, newValue, parentId, id },
      } = action;
      const spaces = state.project.sceneDefs
        .find((scene) => scene.id === activeScene)
        ?.mapPanels.find((element) => element.id === parentId)?.wdSpace;
      if (spaces && spaces[0]) {
        const box = spaces[0].forecastDataLayers.find((item) => item.id === id)?.boxDef as BoxDef;
        //@ts-ignore
        set(box, propertyPath, newValue);
      }
      return state;
    },
    updateObservedMapLayerBackground(
      state,
      action: PayloadAction<{
        id: string;
        newValue: any;
        propertyPath: Leaves<BackgroundDef>;
        activeScene: string | number;
        parentId: string;
      }>,
    ) {
      const {
        payload: { activeScene, propertyPath, newValue, parentId, id },
      } = action;
      const scene = state.project.sceneDefs.find((sc) => sc.id === activeScene);
      if (scene) {
        const map = scene.mapPanels.find((poster) => poster.id === parentId);
        if (map) {
          const layer = map.wdSpace[0].observedDataLayers.find((data) => data.id === id);
          if (layer) {
            layer.boxDef.background[propertyPath] = newValue;
          }
        }
      }
      return state;
    },
    updateObservedMapLayer(
      state,
      action: PayloadAction<{
        id: string;
        newValue: any;
        propertyPath: Leaves<ObservedWDElementDef>;
        activeScene: string | number;
        parentId: string;
      }>,
    ) {
      const {
        payload: { activeScene, propertyPath, newValue, parentId, id },
      } = action;
      const scene = state.project.sceneDefs.find((sc) => sc.id === activeScene);
      if (scene) {
        const map = scene.mapPanels.find((poster) => poster.id === parentId);
        if (map) {
          const layer = map.wdSpace[0].observedDataLayers.find((data) => data.id === id);
          if (layer) {
            // @ts-ignore
            layer[propertyPath] = newValue;
          }
        }
      }
      return state;
    },
    updateForecastMapLayer(
      state,
      action: PayloadAction<{
        id: string;
        newValue: any;
        propertyPath: Leaves<ForecastWDElementDef>;
        activeScene: string | number;
        parentId?: string;
      }>,
    ) {
      const {
        payload: { activeScene, parentId, propertyPath, newValue, id },
      } = action;
      const scene = state.project.sceneDefs.find((sc) => sc.id === activeScene);
      if (scene) {
        const map = scene.mapPanels.find((mapPanel) => mapPanel.id === parentId);
        if (map) {
          const layer = map.wdSpace[0].forecastDataLayers.find((forecast) => forecast.id === id);
          if (layer) {
            //@ts-ignore
            layer[propertyPath] = newValue;
          }
        }
      }
      return state;
    },
    updateOWDLayer(
      state,
      action: PayloadAction<{
        newValue: any;
        propertyPath: Leaves<ObservedWDElementDef>;
        activeScene: string | number;
        elementId: string | number;
        parentId?: string;
        mapId?: string;
      }>,
    ) {
      const {
        payload: { activeScene, propertyPath, elementId, newValue, parentId, mapId },
      } = action;
      const toBeChanged =
        parentId && mapId
          ? state.project.sceneDefs
              .find((sc) => sc.id === activeScene)!
              .mapPanels.find((poster) => poster.id === mapId)!
              .geoPosters.find((tp) => tp.id === parentId)
              ?.observedWDElements.find((item) => item.id === elementId)
          : parentId && !mapId
          ? state.project.sceneDefs
              .find((sc) => sc.id === activeScene)!
              .weatherPosters.find((tp) => tp.id === parentId)
              ?.observedWDElements.find((item) => item.id === elementId)
          : !parentId && mapId
          ? state.project.sceneDefs
              .find((sc) => sc.id === activeScene)!
              .mapPanels.find((poster) => poster.id === mapId)!
              .wdSpace[0]?.observedDataLayers.find((item) => item.id === elementId)
          : state.project.sceneDefs
              .find((sc) => sc.id === activeScene)!
              .observedWDElements.find((item) => item.id === elementId);
      if (
        toBeChanged?.observedWDSource.valueType === ValueTypeEnum.NUMERICAL &&
        propertyPath === 'observedWDSource.value'
      ) {
        set(toBeChanged, propertyPath, newValue);
      } else {
        set(toBeChanged!, propertyPath, newValue);
      }
      return state;
    },
    deleteGeoPoster(
      state,
      action: PayloadAction<{
        activeScene: string | number;
        elementId: string | number;
        mapId?: string;
      }>,
    ) {
      const {
        payload: { activeScene, elementId, mapId },
      } = action;
      const posters = state.project.sceneDefs
        .find((sc) => sc.id === activeScene)!
        .mapPanels.find((poster) => poster.id === mapId)!.geoPosters;
      const index = posters.findIndex((pos) => pos.id === elementId);
      posters.splice(index, 1);
      return state;
    },
    toggleGeoPoster(
      state,
      action: PayloadAction<{
        activeScene: string | number;
        elementId: string | number;
        mapId?: string;
        enabled: boolean;
      }>,
    ) {
      const {
        payload: { activeScene, elementId, mapId, enabled },
      } = action;
      const posters = state.project.sceneDefs
        .find((sc) => sc.id === activeScene)!
        .mapPanels.find((poster) => poster.id === mapId)!.geoPosters;
      const index = posters.findIndex((pos) => pos.id === elementId);
      posters[index].enabled = enabled;
      return state;
    },
    toggleGeoPosterElement(
      state,
      action: PayloadAction<{
        activeScene: string | number;
        elementId: string | number;
        mapId: string;
        elementType: ElementsEnum;
        posterId: string;
        enabled: boolean;
      }>,
    ) {
      const {
        payload: { activeScene, elementId, mapId, elementType, posterId, enabled },
      } = action;
      const scene = state.project.sceneDefs.find((scene) => scene.id === activeScene);
      if (scene) {
        const map = scene.mapPanels.find((mapPanel) => mapPanel.id === mapId);
        if (map) {
          const poster = map.geoPosters.find((geo) => geo.id === posterId);
          const property = elementEnumToKey(elementType) as keyof WeatherGeoPosterDef;
          if (poster) {
            const requiredArray = poster[property] as unknown as Array<
              | ImagePanelDef
              | VideoPanelDef
              | TextPanelDef
              | AudioElement
              | ObservedWDElementDef
              | ForecastWDElementDef
              | PointDateDef
              | PointLocationDef
            >;
            const element = requiredArray.find((item) => item.id === elementId);
            if (element) element.enabled = enabled;
          }
        }
      }
    },
    togglePosterElement(
      state,
      action: PayloadAction<{
        activeScene: string | number;
        elementId: string | number;
        elementType: ElementsEnum;
        posterId: string;
        enabled: boolean;
      }>,
    ) {
      const {
        payload: { activeScene, elementId, elementType, posterId, enabled },
      } = action;
      const scene = state.project.sceneDefs.find((scene) => scene.id === activeScene);
      if (scene) {
        const poster = scene.weatherPosters.find((poster) => poster.id === posterId);
        const property = elementEnumToKey(elementType) as keyof WeatherPosterDef;
        if (poster) {
          const requiredArray = poster[property] as unknown as Array<
            | ImagePanelDef
            | VideoPanelDef
            | TextPanelDef
            | AudioElement
            | ObservedWDElementDef
            | ForecastWDElementDef
            | PointDateDef
            | PointLocationDef
          >;
          const element = requiredArray.find((item) => item.id === elementId);
          if (element) element.enabled = enabled;
        }
      }
    },
    updateForecastLayer(
      state,
      action: PayloadAction<{
        newValue: any;
        propertyPath: Leaves<ForecastWDElementDef>;
        activeScene: string | number;
        elementId: string | number;
        parentId?: string;
        mapId?: string;
      }>,
    ) {
      const {
        payload: { activeScene, propertyPath, elementId, newValue, parentId, mapId },
      } = action;
      //;
      const toBeChanged =
        parentId && mapId
          ? state.project.sceneDefs
              .find((sc) => sc.id === activeScene)!
              .mapPanels.find((poster) => poster.id === mapId)!
              .geoPosters.find((tp) => tp.id === parentId)
              ?.forecastWDElements.find((item) => item.id === elementId)
          : parentId && !mapId
          ? state.project.sceneDefs
              .find((sc) => sc.id === activeScene)!
              .weatherPosters.find((tp) => tp.id === parentId)
              ?.forecastWDElements.find((item) => item.id === elementId)
          : !parentId && mapId
          ? state.project.sceneDefs
              .find((sc) => sc.id === activeScene)!
              .mapPanels.find((poster) => poster.id === mapId)!
              .wdSpace[0]?.forecastDataLayers.find((item) => item.id === elementId)
          : state.project.sceneDefs
              .find((sc) => sc.id === activeScene)!
              .forecastWDElements.find((item) => item.id === elementId);
      if (
        toBeChanged?.forecastWDSource.valueType === ValueTypeEnum.NUMERICAL &&
        propertyPath === 'forecastWDSource.value'
      ) {
        set(toBeChanged, propertyPath, newValue);
      } else {
        set(toBeChanged!, propertyPath, newValue);
      }
      return state;
    },
    updateProject(
      state,
      action: PayloadAction<{
        newValue: any;
        propertyPath: Leaves<C9ProjectDef>;
      }>,
    ) {
      const {
        payload: { propertyPath, newValue },
      } = action;
      state.project = set(state.project, propertyPath, newValue);
    },

    updateScene(
      state,
      action: PayloadAction<{
        newValue: string | number;
        propertyPath: Leaves<SceneDef>;
        activeScene: string | number;
      }>,
    ) {
      const {
        payload: { activeScene, propertyPath, newValue },
      } = action;
      const cloned = cloneDeep(state);
      const toBeChanged = cloned.project.sceneDefs.find((sc) => sc.id === activeScene)!;
      set(toBeChanged!, propertyPath, newValue);
      return cloned;
    },
    updateLogicalGroups(
      state,
      action: PayloadAction<{
        groups: Array<LogicalGroupElement>;
        activeScene: string;
      }>,
    ) {
      const {
        payload: { groups, activeScene },
      } = action;
      const cloned = cloneDeep(state);
      const toBeChanged = (cloned.project.sceneDefs.find(
        (sc) => sc.id === activeScene,
      )!.logicalGroups = groups);
      set(toBeChanged!, 'logicalGroups', groups);
      return cloned;
    },
    updateImageLayer(
      state,
      action: PayloadAction<{
        newValue: any;
        propertyPath: Leaves<ImagePanelDef>;
        activeScene: string | number;
        elementId: string | number;
        parentId?: string;
      }>,
    ) {
      const {
        payload: { activeScene, propertyPath, elementId, newValue, parentId },
      } = action;
      const cloned = cloneDeep(state);
      const toBeChanged = parentId
        ? cloned.project.sceneDefs
            .find((sc) => sc.id === activeScene)!
            .weatherPosters.find((poster) => poster.id === parentId)!
            .imagePanels.find((tp) => tp.id === elementId)
        : cloned.project.sceneDefs
            .find((sc) => sc.id === activeScene)!
            .imagePanels.find((tp) => tp.id === elementId);
      set(toBeChanged!, propertyPath, newValue);
      return cloned;
    },
    deleteImageLayer(
      state,
      action: PayloadAction<{
        activeScene: string | number;
        elementId: string | number;
        parentId?: string;
      }>,
    ) {
      const {
        payload: { activeScene, elementId, parentId },
      } = action;
      if (parentId) {
        state.project.sceneDefs
          .find((sc) => sc.id === activeScene)!
          .weatherPosters.find((poster) => poster.id === parentId)!.imagePanels =
          state.project.sceneDefs
            .find((sc) => sc.id === activeScene)!
            .weatherPosters.find((poster) => poster.id === parentId)!
            .imagePanels.filter((tp) => tp.id !== elementId);
      } else {
        state.project.sceneDefs.find((sc) => sc.id === activeScene)!.imagePanels =
          state.project.sceneDefs
            .find((sc) => sc.id === activeScene)!
            .imagePanels.filter((tp) => tp.id !== elementId);
      }
      return state;
    },
    updateAnimationLayer(
      state,
      action: PayloadAction<{
        newValue: any;
        propertyPath: Leaves<AnimationPanelDef>;
        activeScene: string | number;
        elementId: string | number;
        parentId?: string;
      }>,
    ) {
      const cloned = cloneDeep(state);
      const {
        payload: { activeScene, propertyPath, elementId, newValue, parentId },
      } = action;
      const toBeChanged = parentId
        ? cloned.project.sceneDefs
            .find((sc) => sc.id === activeScene)!
            .weatherPosters.find((poster) => poster.id === parentId)!
            .animationPanels.find((tp) => tp.id === elementId)
        : cloned.project.sceneDefs
            .find((sc) => sc.id === activeScene)!
            .animationPanels.find((tp) => tp.id === elementId);
      set(toBeChanged!, propertyPath, newValue);
      return cloned;
    },
    deleteAnimationLayer(
      state,
      action: PayloadAction<{
        activeScene: string | number;
        elementId: string | number;
        parentId?: string;
      }>,
    ) {
      const {
        payload: { activeScene, elementId, parentId },
      } = action;
      if (parentId) {
        state.project.sceneDefs
          .find((sc) => sc.id === activeScene)!
          .weatherPosters.find((poster) => poster.id === parentId)!.animationPanels =
          state.project.sceneDefs
            .find((sc) => sc.id === activeScene)!
            .weatherPosters.find((poster) => poster.id === parentId)!
            .animationPanels.filter((tp) => tp.id !== elementId);
      } else {
        state.project.sceneDefs.find((sc) => sc.id === activeScene)!.animationPanels =
          state.project.sceneDefs
            .find((sc) => sc.id === activeScene)!
            .animationPanels.filter((tp) => tp.id !== elementId);
      }
      return state;
    },
    updateVideoLayer(
      state,
      action: PayloadAction<{
        newValue: any;
        propertyPath: Leaves<VideoPanelDef>;
        activeScene: string | number;
        elementId: string | number;
        parentId?: string;
      }>,
    ) {
      const {
        payload: { activeScene, propertyPath, elementId, newValue, parentId },
      } = action;
      const cloned = cloneDeep(state);
      const toBeChanged = parentId
        ? cloned.project.sceneDefs
            .find((sc) => sc.id === activeScene)!
            .weatherPosters.find((poster) => poster.id === parentId)!
            .videoPanels.find((tp) => tp.id === elementId)
        : cloned.project.sceneDefs
            .find((sc) => sc.id === activeScene)!
            .videoPanels.find((tp) => tp.id === elementId);
      set(toBeChanged!, propertyPath, newValue);
      return cloned;
    },
    updateMapLayer(
      state,
      action: PayloadAction<{
        newValue: any;
        propertyPath: Paths<MapPanelDef>;
        activeScene: string | number;
        elementId: string | number;
      }>,
    ) {
      const {
        payload: { activeScene, propertyPath, elementId, newValue },
      } = action;
      const cloned: ProjectState = cloneDeep(state);
      const toBeChanged = cloned.project.sceneDefs
        .find((sc: SceneDef) => sc.id === activeScene)
        ?.mapPanels?.find((tp: MapPanelDef) => tp.id === elementId);
      if (toBeChanged && propertyPath !== 'isNew') set(toBeChanged!, propertyPath, newValue);
      return cloned;
    },
    updateMapExtent(
      state,
      action: PayloadAction<{
        activeScene: string | number;
        elementId: string | number;
      }>,
    ) {
      const {
        payload: { activeScene, elementId },
      } = action;
      const cloned: ProjectState = cloneDeep(state);
      const toBeChanged = cloned.project.sceneDefs
        .find((sc: SceneDef) => sc.id === activeScene)
        ?.mapPanels?.find((tp: MapPanelDef) => tp.id === elementId);
      if (toBeChanged) set(toBeChanged!, 'extentVersion', 1);
      return cloned;
    },
    updateIndicatorPriority(
      state,
      action: PayloadAction<{
        priority: number;
        activeScene: string | number;
        elementId: string | number;
        layerType: 'gribMapLayers' | 'radarMapLayers' | 'satelliteMapLayers';
        layerId: string;
      }>,
    ) {
      const {
        payload: { activeScene, elementId, priority, layerType, layerId },
      } = action;
      const scene = state.project.sceneDefs.find((sc) => sc.id === activeScene);
      if (scene) {
        const map = scene.mapPanels.find((mp) => mp.id === elementId);
        if (map) {
          const layers = map.wdSpace[0][layerType] as unknown as WeatherDataMapLayer[];
          const layer = layers.find((layer) => layer.id === layerId);
          if (layer) layer.indicatorPriority = priority;
        }
      }
      return state;
    },
    addGeoPoster(
      state,
      action: PayloadAction<{
        newValue: WeatherGeoPosterDef[];
        activeScene: string | number;
        elementId: string | number;
      }>,
    ) {
      const {
        payload: { activeScene, elementId, newValue },
      } = action;
      const toBeChanged = state.project.sceneDefs
        .find((sc) => sc.id === activeScene)!
        .mapPanels.find((tp) => tp.id === elementId);
      newValue.forEach((poster) => {
        poster.forecastWDElements.forEach((item) => (item.id = v4()));
        poster.observedWDElements.forEach((item) => (item.id = v4()));
        poster.videoPanels.forEach((item) => (item.id = v4()));
        poster.textPanels.forEach((item) => (item.id = v4()));
        poster.imagePanels.forEach((item) => (item.id = v4()));
        poster.pointDates.forEach((item) => (item.id = v4()));
      });
      if (toBeChanged) {
        if (toBeChanged.geoPosters) {
          toBeChanged.geoPosters.push(...newValue);
        } else {
          toBeChanged.geoPosters = newValue;
        }
      }
      return state;
    },
    updateAudioLayer(
      state,
      action: PayloadAction<{
        newValue: any;
        propertyPath: Leaves<AudioElement>;
        activeScene: string | number;
        elementId: string | number;
      }>,
    ) {
      const {
        payload: { activeScene, propertyPath, elementId, newValue },
      } = action;
      const cloned = cloneDeep(state);
      const toBeChanged = cloned.project.sceneDefs
        .find((sc) => sc.id === activeScene)!
        .audioElements.find((tp) => tp.id === elementId);
      set(toBeChanged!, propertyPath, newValue);
      return cloned;
    },
    updateGraphLayer(
      state,
      action: PayloadAction<{
        newValue: any;
        propertyPath: Leaves<WeatherGraphDef>;
        activeScene: string | number;
        elementId: string | number;
      }>,
    ) {
      const {
        payload: { activeScene, propertyPath, elementId, newValue },
      } = action;
      const cloned = cloneDeep(state);
      const toBeChanged = cloned.project.sceneDefs
        .find((sc) => sc.id === activeScene)!
        .weatherPosters.find((tp) => tp.id === elementId);
      set(toBeChanged!, propertyPath, newValue);
      return cloned;
    },
    updatePanel(
      state,
      action: PayloadAction<{
        activeScene: string | number;
        activeElement: string | number;
        activeProp: keyof SceneDef;
      }>,
    ) {
      const {
        payload: { activeScene, activeElement, activeProp },
      } = action;
      const cloned = cloneDeep(state);
      const scene = cloned.project.sceneDefs.find((sc) => sc.id === activeScene) as SceneDef;
      const panelToChange = scene[activeProp] as
        | VideoPanelDef[]
        | ImagePanelDef[]
        | AnimationPanelDef[]
        | AudioElement[]
        | TextPanelDef[]
        | MapPanelDef[]
        | ObservedWDElementDef[]
        | WeatherGraphDef[]
        | PointDateDef[]
        | PointLocationDef[];
      const index = panelToChange.findIndex((obj) => obj.id === activeElement);
      panelToChange.splice(index, 1);
      return cloned;
    },
    updatePosterPanel(
      state,
      action: PayloadAction<{
        activeScene: string | number;
        activeElement: string | number;
        activeProp: keyof SceneDef;
        activeParent: keyof SceneDef;
      }>,
    ) {
      const {
        payload: { activeScene, activeElement, activeProp, activeParent },
      } = action;
      const cloned = cloneDeep(state);
      const scene = cloned.project.sceneDefs.find((sc) => sc.id === activeScene);
      if (scene) {
        const panelToChange = scene[activeParent];
        if (Array.isArray(panelToChange)) {
          panelToChange.forEach((panel: any, i) => {
            const propArray = panel[activeProp];
            if (Array.isArray(propArray)) {
              const index = propArray.findIndex((obj) => obj.id === activeElement);
              if (index !== -1) {
                panelToChange.splice(i, 1); // delete whole composite
                // propArray.splice(index, 1); // to delete single item in composite
              }
            }
          });
        }
      }
      return cloned;
    },
    cutElement(
      state,
      action: PayloadAction<{
        activeScene: string | number;
        activeElement: string | number;
        activeProp: keyof SceneDef;
        time: number;
      }>,
    ) {
      const {
        payload: { activeScene, activeElement, activeProp, time },
      } = action;
      const cloned = cloneDeep(state);
      const scene = cloned.project.sceneDefs.find((sc) => sc.id === activeScene) as SceneDef;
      const panelToChange = scene[activeProp] as any[];
      const timeControls = panelToChange.find(
        (element) => element.id === activeElement,
      )?.timeControls;
      const index = timeControls.findIndex(
        (obj: TimeControlDef) => obj.startMS < time && obj.endMS > time,
      );
      const objEdited = timeControls.find(
        (obj: TimeControlDef) => obj.startMS < time && obj.endMS > time,
      );
      objEdited && timeControls.splice(index, 1);
      objEdited &&
        timeControls.splice(index, 0, {
          ...objEdited,
          endMS: time,
          outAnimationDef: AnimationsEnum.CUT,
          outAnimationDuration: 0,
        });
      objEdited &&
        timeControls.splice(index + 1, 0, {
          ...objEdited,
          startMS: time,
          seekToTimeMS: time + objEdited.seekToTimeMS - objEdited.startMS,
          inAnimationDef: AnimationsEnum.CUT,
          inAnimationDuration: 0,
        });
      return cloned;
    },
    enableLayer(
      state,
      action: PayloadAction<{
        activeScene: string | number;
        activeElement: string | number;
        activeProp: keyof SceneDef;
        value: boolean;
      }>,
    ) {
      const {
        payload: { activeScene, activeProp, activeElement },
      } = action;
      const cloned = cloneDeep(state);
      const scene = cloned.project.sceneDefs.find((scene) => scene.id === activeScene);
      if (scene) {
        const panels = scene[activeProp] as any[];
        const panel = panels?.find((elem) => elem.id === activeElement);
        panel.enabled = !panel?.enabled;
      }
      return cloned;
    },
    cutDrawingElement(
      state,
      action: PayloadAction<{
        activeScene: string | number;
        activeElement: string | number;
        parentId: string;
        time: number;
      }>,
    ) {
      const {
        payload: { activeScene, activeElement, time, parentId },
      } = action;
      const scene = state.project.sceneDefs.find((sc) => sc.id === activeScene) as SceneDef;
      const mapToChange = scene.mapPanels.find((map) => map.id === parentId);
      const drawPanel = mapToChange?.drawingElements.find(
        (drawing) => drawing.id === activeElement,
      );
      const timeControls = drawPanel?.timeControls;
      const index = timeControls?.findIndex(
        (obj: TimeControlDef) => obj.startMS < time && obj.endMS > time,
      );
      const objedited = timeControls?.find(
        (obj: TimeControlDef) => obj.startMS < time && obj.endMS > time,
      );
      objedited && index && timeControls?.splice(index, 1);
      objedited &&
        index &&
        timeControls?.splice(index, 0, {
          ...objedited,
          endMS: time,
          outAnimationDef: AnimationsEnum.CUT,
          outAnimationDuration: 0,
        });
      objedited &&
        index &&
        timeControls?.splice(index + 1, 0, {
          ...objedited,
          startMS: time,
          seekToTimeMS: time + objedited.seekToTimeMS - objedited.startMS,
          inAnimationDef: AnimationsEnum.CUT,
          inAnimationDuration: 0,
        });
      return state;
    },
    /**check if i can access curent state from store for active scene */
    deleteScene(
      state,
      action: PayloadAction<{
        sceneId: string | number;
      }>,
    ) {
      const {
        payload: { sceneId },
      } = action;
      state.project.sceneDefs = state.project.sceneDefs.filter((sc) => sc.id !== sceneId);
    },
    updateMapDefPartial(
      state,
      action: PayloadAction<{
        mapDefPartial: DeepPartial<MapPanelDef>;
        activeScene: string | number;
        elementId: string | number;
      }>,
    ) {
      const {
        payload: { activeScene, elementId, mapDefPartial },
      } = action;
      const toBeChanged = state.project.sceneDefs
        .find((sc) => sc.id === activeScene)!
        .mapPanels.find((tp) => tp.id === elementId);
      merge(toBeChanged, mapDefPartial);
      return state;
    },
    updateKeyframePositionControlByIndex(
      state,
      action: PayloadAction<{
        positionControl: MapPositionControlDef;
        activeScene: string | number;
        elementId: string | number;
        defIndex: number;
      }>,
    ) {
      const {
        payload: { activeScene, elementId, defIndex, positionControl },
      } = action;
      const toBeChanged = state.project.sceneDefs
        .find((sc) => sc.id === activeScene)!
        .mapPanels.find((tp) => tp.id === elementId);
      if (toBeChanged?.flyOver?.keyFrames![defIndex])
        toBeChanged.flyOver.keyFrames[defIndex].mapPositionControl = positionControl;
      return state;
    },
    setSavedProject(state, action: PayloadAction<{ savedProject: C9ProjectDef }>) {
      const {
        payload: { savedProject },
      } = action;
      state.currentSavedProject = savedProject;
    },
    updateIndicatorBox(
      state,
      action: PayloadAction<{
        activeScene: string;
        propertyPath: Leaves<BoxDef | BackgroundDef>;
        value: string | number | BorderDef;
        parentId?: string;
      }>,
    ) {
      const {
        payload: { activeScene, propertyPath, value, parentId },
      } = action;
      const spaces = state.project.sceneDefs
        .find((scene) => scene.id === activeScene)
        ?.mapPanels.find((element) => element.id === parentId)?.wdSpace;
      if (spaces && spaces[0]) {
        const indicator = spaces[0].mapTimeframeTextIndicator.boxDef as BoxDef;
        set(indicator, propertyPath, value);
      }
      return state;
    },
    updateBoxDef(
      state,
      action: PayloadAction<{
        activeScene: string;
        elementType: ElementsEnum;
        activeElement: string;
        propertyPath: Leaves<BoxDef | BackgroundDef>;
        value: string | number | BorderDef;
        parentId?: string;
      }>,
    ) {
      const {
        payload: { activeScene, elementType, activeElement, propertyPath, value, parentId },
      } = action;
      let toBeChanged;
      switch (elementType) {
        case ElementsEnum.OBSERVED_WD: {
          const scene = state.project.sceneDefs.find((scene) => scene.id === activeScene);
          if (parentId) {
            if (scene) {
              const weatherPoster = scene.weatherPosters.find((poster) => poster.id === parentId);
              if (weatherPoster) {
                const obsElem = weatherPoster.observedWDElements.find(
                  (panel) => panel.id === activeElement,
                );
                if (obsElem) {
                  console.log(obsElem.boxDef, propertyPath, value);
                  // @ts-ignore
                  obsElem.boxDef[propertyPath] = value;
                }
              }
            }
          } else {
            if (scene) {
              const obsElem = scene.observedWDElements.find((panel) => panel.id === activeElement);
              if (obsElem) {
                setNestedProperty(obsElem.boxDef, propertyPath, value);
              }
            }
          }
          return state;
        }
        case ElementsEnum.FORECAST_WD: {
          const scene = state.project.sceneDefs.find((scene) => scene.id === activeScene);
          if (parentId) {
            console.log('poster forecast');
            if (scene) {
              const weatherPoster = scene.weatherPosters.find((poster) => poster.id === parentId);
              if (weatherPoster) {
                console.log(weatherPoster.id);
                const foreElem = weatherPoster.forecastWDElements.find(
                  (panel) => panel.id === activeElement,
                );
                if (foreElem) {
                  setNestedProperty(foreElem.boxDef, propertyPath, value);
                }
              }
            }
          } else {
            if (scene) {
              const foreElem = scene.forecastWDElements.find((panel) => panel.id === activeElement);
              if (foreElem) {
                setNestedProperty(foreElem.boxDef, propertyPath, value);
                //@ts-ignore
                foreElem.boxDef[propertyPath] = value;
              }
            }
          }
          return state;
        }
        case ElementsEnum.ANIMATION:
          toBeChanged = parentId
            ? (state.project.sceneDefs
                .find((scene) => scene.id === activeScene)
                ?.weatherPosters.find((poster) => poster.id === parentId)
                ?.animationPanels.find((panel) => panel.id === activeElement)
                ?.background as BackgroundDef)
            : (state.project.sceneDefs
                .find((scene) => scene.id === activeScene)!
                .animationPanels.find((panel) => panel.id === activeElement)
                ?.background as BackgroundDef);
          set(toBeChanged, propertyPath, value);
          return state;
        case ElementsEnum.POINT_DATE:
          toBeChanged = parentId
            ? (state.project.sceneDefs
                .find((scene) => scene.id === activeScene)
                ?.weatherPosters.find((poster) => poster.id === parentId)
                ?.pointDates.find((panel) => panel.id === activeElement)?.boxDef as BoxDef)
            : (state.project.sceneDefs
                .find((scene) => scene.id === activeScene)!
                .pointDates.find((panel) => panel.id === activeElement)?.boxDef as BoxDef);
          set(toBeChanged, propertyPath, value);
          return state;
        case ElementsEnum.POINT_LOCATION:
          toBeChanged = parentId
            ? (state.project.sceneDefs
                .find((scene) => scene.id === activeScene)
                ?.weatherPosters.find((poster) => poster.id === parentId)
                ?.pointLocation.find((panel) => panel.id === activeElement)?.boxDef as BoxDef)
            : (state.project.sceneDefs
                .find((scene) => scene.id === activeScene)!
                .pointLocation.find((panel) => panel.id === activeElement)?.boxDef as BoxDef);
          set(toBeChanged, propertyPath, value);
          return state;
        case ElementsEnum.TEXT:
          toBeChanged = state.project.sceneDefs
            .find((scene) => scene.id === activeScene)!
            .textPanels.find((panel) => panel.id === activeElement)?.boxDef as BoxDef;
          set(toBeChanged, propertyPath, value);
          return state;
        case ElementsEnum.TIMESTAMP:
          toBeChanged = state.project.sceneDefs
            .find((scene) => scene.id === activeScene)!
            .timestampPanels.find((panel) => panel.id === activeElement)?.boxDef as BoxDef;
          set(toBeChanged, propertyPath, value);
          return state;
      }
    },
    updateObservedPanel(
      state,
      action: PayloadAction<{
        activeScene: string;
        activeElement: string;
        value: Record<string, unknown> | number;
      }>,
    ) {
      const {
        payload: { activeScene, activeElement, value },
      } = action;
      const val = state.project.sceneDefs
        .find((scene) => scene.id === activeScene)
        ?.observedWDElements.find((owd) => owd.id === activeElement)?.observedWDSource;
      if (val) val.value = value;
      return state;
    },
    updateForecastPanel(
      state,
      action: PayloadAction<{
        activeScene: string;
        activeElement: string;
        value: Record<string, unknown> | number;
      }>,
    ) {
      const {
        payload: { activeScene, activeElement, value },
      } = action;
      const val = state.project.sceneDefs
        .find((scene) => scene.id === activeScene)
        ?.forecastWDElements.find((owd) => owd.id === activeElement)?.forecastWDSource;
      if (val) val.value = value;
      return state;
    },
    addLogicalGroup(
      state,
      action: PayloadAction<{ activeScene: string; element: LogicalGroupElement }>,
    ) {
      const {
        payload: { activeScene, element },
      } = action;
      state.project.sceneDefs
        .find((scene: SceneDef) => scene.id === activeScene)
        ?.logicalGroups.push(element);
    },
    deleteLogicalGroup(state, action: PayloadAction<{ activeScene: string; id: string }>) {
      const {
        payload: { activeScene, id },
      } = action;
      const scene = state.project.sceneDefs.find((scene: SceneDef) => scene.id === activeScene);
      if (scene) {
        const index = scene.logicalGroups.findIndex((group) => group.id === id);
        scene.logicalGroups.splice(index, 1);
      }
    },
    updateLayerGroupPos(
      state,
      action: PayloadAction<{
        activeScene: string;
        type: ElementsEnum;
        id: string;
        order: number;
        parent: string;
      }>,
    ) {
      const {
        payload: { activeScene, type, id, order, parent },
      } = action;
      const scene = state.project.sceneDefs.find((scene: SceneDef) => scene.id === activeScene);
      if (scene) {
        let def;
        switch (type) {
          case ElementsEnum.AUDIO:
            def = def = scene.audioElements.find((panel) => panel.id === id);
            break;
          case ElementsEnum.TEXT:
            def = def = scene.textPanels.find((panel) => panel.id === id);
            break;
          case ElementsEnum.IMAGE:
            def = def = scene.imagePanels.find((panel) => panel.id === id);
            break;
          case ElementsEnum.VIDEO:
            def = def = scene.videoPanels.find((panel) => panel.id === id);
            break;
          case ElementsEnum.OBSERVED_WD:
            def = def = scene.observedWDElements.find((panel) => panel.id === id);
            break;
          case ElementsEnum.FORECAST_WD:
            def = def = scene.forecastWDElements.find((panel) => panel.id === id);
            break;
          case ElementsEnum.MAP:
            def = def = scene.mapPanels.find((panel) => panel.id === id);
            break;
        }

        if (def) {
          def.parentGroups[0].groupId = parent;
          def.parentGroups[0].orderNumInGroup = order;
        }
      }
      return state;
    },
    updateGroupPos(
      state,
      action: PayloadAction<{
        activeScene: string;
        id: string;
        order: number;
        parent: string;
      }>,
    ) {
      const {
        payload: { activeScene, id, order, parent },
      } = action;
      const scene = state.project.sceneDefs.find((scene: SceneDef) => scene.id === activeScene);
      if (scene) {
        const group = scene.logicalGroups.find((item) => item.id === id);
        if (group) {
          group.parentGroupId = parent;
          group.orderNumInParentGroup = order;
        }
      }
      return state;
    },
    updateGroupName(
      state,
      action: PayloadAction<{
        activeScene: string;
        id: string;
        name: string;
      }>,
    ) {
      const {
        payload: { activeScene, id, name },
      } = action;
      const scene = state.project.sceneDefs.find((scene: SceneDef) => scene.id === activeScene);
      if (scene) {
        const group = scene.logicalGroups.find((item) => item.id === id);
        if (group) {
          group.name = name;
        }
      }
      return state;
    },
    updateIndicatorTime(
      state,
      action: PayloadAction<{
        activeScene: string;
        mapId: string;
        timeControls: TimeControlDef[];
      }>,
    ) {
      const {
        payload: { activeScene, mapId, timeControls },
      } = action;
      const scene = state.project.sceneDefs.find((scene: SceneDef) => scene.id === activeScene);
      if (scene) {
        const mapElement = scene.mapPanels.find((item) => item.id === mapId);
        if (mapElement) {
          const space = mapElement.wdSpace[0];
          space.mapTimeframeTextIndicator.timeControls = timeControls;
        }
      }
      return state;
    },
    updateIndicatorStep(
      state,
      action: PayloadAction<{
        activeScene: string;
        mapId: string;
        timeframeIndicatorStep: TimeStepEnum;
      }>,
    ) {
      const {
        payload: { activeScene, mapId, timeframeIndicatorStep },
      } = action;
      const scene = state.project.sceneDefs.find((scene: SceneDef) => scene.id === activeScene);
      if (scene) {
        const mapElement = scene.mapPanels.find((item) => item.id === mapId);
        if (mapElement) {
          const space = mapElement.wdSpace[0];
          space.timeframeIndicatorStep = timeframeIndicatorStep;
        }
      }
      return state;
    },
    updateIndicatorRound(
      state,
      action: PayloadAction<{
        activeScene: string;
        mapId: string;
        rounded: boolean;
      }>,
    ) {
      const {
        payload: { activeScene, mapId, rounded },
      } = action;
      const scene = state.project.sceneDefs.find((scene: SceneDef) => scene.id === activeScene);
      if (scene) {
        const mapElement = scene.mapPanels.find((item) => item.id === mapId);
        if (mapElement) {
          const space = mapElement.wdSpace[0];
          space.roundTimeframeIndicator = rounded;
        }
      }
      return state;
    },
    updateRelativeTime(
      state,
      action: PayloadAction<{
        activeScene: string;
        mapId: string;
        relativeTime: boolean;
      }>,
    ) {
      const {
        payload: { activeScene, mapId, relativeTime },
      } = action;
      const scene = state.project.sceneDefs.find((scene: SceneDef) => scene.id === activeScene);
      if (scene) {
        const mapElement = scene.mapPanels.find((item) => item.id === mapId);
        if (mapElement) {
          const space = mapElement.wdSpace[0];
          space.relativeTime = relativeTime;
        }
      }
      return state;
    },
    enableIndicator(
      state,
      action: PayloadAction<{
        activeScene: string;
        mapId: string;
        enabled: boolean;
      }>,
    ) {
      const {
        payload: { activeScene, mapId, enabled },
      } = action;
      const scene = state.project.sceneDefs.find((scene: SceneDef) => scene.id === activeScene);
      if (scene) {
        const mapElement = scene.mapPanels.find((item) => item.id === mapId);
        if (mapElement) {
          const space = mapElement.wdSpace[0];
          space.enableTimeframeIndicator = enabled;
        }
      }
      return state;
    },
    updateIndicatorFormat(
      state,
      action: PayloadAction<{
        activeScene: string;
        mapId: string;
        format: string;
      }>,
    ) {
      const {
        payload: { activeScene, mapId, format },
      } = action;
      const scene = state.project.sceneDefs.find((scene: SceneDef) => scene.id === activeScene);
      if (scene) {
        const mapElement = scene.mapPanels.find((item) => item.id === mapId);
        if (mapElement) {
          const space = mapElement.wdSpace[0];
          space.timeframeIndicatorFormat = format;
        }
      }
      return state;
    },
    createIndicator(
      state,
      action: PayloadAction<{
        activeScene: string;
        mapId: string;
        indicator: Array<{ timeControls: TimeControlDef; value: string; dateValue: string }>;
      }>,
    ) {
      const {
        payload: { activeScene, mapId, indicator },
      } = action;

      const scene = state.project.sceneDefs.find((scene: SceneDef) => scene.id === activeScene);
      if (scene) {
        const mapElement = scene.mapPanels.find((item) => item.id === mapId);
        if (mapElement) {
          const space = mapElement.wdSpace[0];
          space.indicator = indicator;
          /**TO BE DONE reconsider this rerendering drawings on each change */
          mapElement.drawingElements = [...mapElement.drawingElements];
        }
      }
      return state;
    },
    setBaseMapHidden(
      state,
      action: PayloadAction<{
        activeScene: string;
        mapId: string;
        value: boolean;
      }>,
    ) {
      const {
        payload: { activeScene, mapId, value },
      } = action;
      const scene = state.project.sceneDefs.find((s) => s.id === activeScene)!;
      const map = scene.mapPanels.find((m) => m.id === mapId)!;
      map.baseMapSetup.hidden = value;
      return state;
    },
    setOceanMaskHidden(
      state,
      action: PayloadAction<{
        activeScene: string;
        mapId: string;
        value: boolean;
      }>,
    ) {
      const {
        payload: { activeScene, mapId, value },
      } = action;
      const scene = state.project.sceneDefs.find((s) => s.id === activeScene)!;
      const map = scene.mapPanels.find((m) => m.id === mapId)!;
      map.oceanMask.hidden = value;
      return state;
    },
    reorderScenes(
      state,
      action: PayloadAction<{
        oldIndex: number;
        newIndex: number;
      }>,
    ) {
      const {
        payload: { oldIndex, newIndex },
      } = action;
      const scenes = state.project.sceneDefs;
      state.isStoryboardHoverActive = false;
      state.project.sceneDefs = update(scenes, {
        $splice: [
          [oldIndex, 1],
          [newIndex, 0, scenes[oldIndex]],
        ],
      });
      return state;
    },
    reorderScenesForStoryboard(
      state,
      action: PayloadAction<{
        oldIndex: number;
        newIndex: number;
      }>,
    ) {
      const {
        payload: { oldIndex, newIndex },
      } = action;
      // This is only used for drag and drop scenes on storyboard
      // use a separate list of sceneDefs while hovering, to prevent
      // all the scenes to rerender
      // Only apply the ordering to the real scenes on drop, not on hover
      const scenes = state.isStoryboardHoverActive
        ? state.storyboardSceneDefs
        : state.project.sceneDefs;
      state.storyboardSceneDefs = update(scenes, {
        $splice: [
          [oldIndex, 1],
          [newIndex, 0, scenes[oldIndex]],
        ],
      });
      state.isStoryboardHoverActive = true;
      return state;
    },
    addTimeToSkip(
      state,
      action: PayloadAction<{
        timeToSkip: SkipTimeDef;
      }>,
    ) {
      const {
        payload: { timeToSkip },
      } = action;
      const skipTime = state.project.skippedTime;
      if (skipTime) skipTime.push(timeToSkip);
      else state.project.skippedTime = [timeToSkip];
      state.project.skippedTime.sort((a, b) => a.startMS - b.startMS);
      return state;
    },
    updateProjectSkipTimes(
      state,
      action: PayloadAction<{
        timeToSkip: SkipTimeDef[];
      }>,
    ) {
      const {
        payload: { timeToSkip },
      } = action;
      state.project.skippedTime = timeToSkip;
      return state;
    },
    deleteSkip(
      state,
      action: PayloadAction<{
        index: number;
      }>,
    ) {
      const {
        payload: { index },
      } = action;
      state.project.skippedTime.splice(index, 1);
      return state;
    },
    editOneSkip(
      state,
      action: PayloadAction<{
        index: number;
        value: SkipTimeDef;
      }>,
    ) {
      const {
        payload: { index, value },
      } = action;
      state.project.skippedTime[index] = value;
      return state;
    },
    closeTimeSkip(
      state,
      action: PayloadAction<{
        time: number;
      }>,
    ) {
      const {
        payload: { time },
      } = action;
      const times = [...state.project.skippedTime];
      const valToEdit = times.findIndex((item) => item.startMS === item.endMS);
      if (valToEdit > -1) {
        if (times[valToEdit].startMS >= time) times.splice(valToEdit, 1);
        else times[valToEdit].endMS = time;
      }
      state.project.skippedTime = times;
      return state;
    },
    addProjectVoiceOver(
      state,
      action: PayloadAction<{
        audio: AudioElement;
      }>,
    ) {
      const {
        payload: { audio },
      } = action;
      state.project.voiceOver = audio;
      return state;
    },
    addProjectVideOver(
      state,
      action: PayloadAction<{
        video: VideoPanelDef;
      }>,
    ) {
      const {
        payload: { video },
      } = action;
      state.project.videoOver = video;
      return state;
    },
    updateVoiceOverTime(
      state,
      action: PayloadAction<{
        time: TimeControlDef[];
      }>,
    ) {
      const {
        payload: { time },
      } = action;
      state.project.voiceOver.timeControls = time;
      return state;
    },
    updateVideoOverTime(
      state,
      action: PayloadAction<{
        time: TimeControlDef[];
      }>,
    ) {
      const {
        payload: { time },
      } = action;
      state.project.videoOver.timeControls = time;
      return state;
    },
    editSceneTransition(
      state,
      action: PayloadAction<{
        time: TimeControlDef;
        activeScene: string | number;
      }>,
    ) {
      const {
        payload: { time, activeScene },
      } = action;
      const scene = state.project.sceneDefs.find((scene) => scene.id === activeScene);
      if (scene && scene?.timeControl) scene.timeControl = time;
      return state;
    },
    setProject(
      state,
      action: PayloadAction<{
        project: C9ProjectDef;
      }>,
    ) {
      const {
        payload: { project },
      } = action;
      state.project = project;
      return state;
    },
    addVideoRecorder(state, action: PayloadAction<{ recorder: VideoPanelDef | null }>) {
      const {
        payload: { recorder },
      } = action;
      state.project.videoOverRecorder = state.project.videoOverRecorder ? null : recorder;
      return state;
    },
    updateRecorderPosition(state, action: PayloadAction<{ position: PositionControlDef }>) {
      const {
        payload: { position },
      } = action;
      const rec = state.project.videoOverRecorder;
      if (rec) rec.positionControl = position;
      return state;
    },
    removeThumbnail(state, action: PayloadAction<{ sceneId: string; thumbnailUrl: string }>) {
      const {
        payload: { sceneId, thumbnailUrl },
      } = action;
      const scene = state.project.sceneDefs.find((scene) => scene.id === sceneId);
      if (scene) {
        const index = scene.thumbnailUrls.indexOf(thumbnailUrl);
        if (index > -1) {
          scene.thumbnailUrls.splice(index, 1);
        }
      }
      return state;
    },
    extendSceneDuration(state, action: PayloadAction<{ activeScene: string; duration: number }>) {
      const {
        payload: { activeScene, duration },
      } = action;
      const scene = state.project.sceneDefs.find((scene) => scene.id === activeScene);
      if (scene) {
        scene.durationInMS = scene.durationInMS + duration * 1000;
      }
      return state;
    },
    replacePosterInScene(
      state,
      action: PayloadAction<{ activeScene: string; weatherPoster: WeatherPosterDef }>,
    ) {
      const {
        payload: { activeScene, weatherPoster },
      } = action;
      const scene = state.project.sceneDefs.find((scene) => scene.id === activeScene);
      if (scene) {
        const poster = scene.weatherPosters.find((poster) => poster.id === weatherPoster.id);
        if (poster) {
          poster.observedWDElements = weatherPoster.observedWDElements;
          poster.forecastWDElements = weatherPoster.forecastWDElements;
        }
      }
      return state;
    },
    replaceMapInScene(
      state,
      action: PayloadAction<{ activeScene: string; mapPanel: MapPanelDef }>,
    ) {
      const {
        payload: { activeScene, mapPanel },
      } = action;
      const scene = state.project.sceneDefs.find((scene) => scene.id === activeScene);
      if (scene) {
        const indexToUpdate = scene.mapPanels.findIndex((item) => item.id === mapPanel.id);
        if (indexToUpdate !== -1) {
          const updatedArray = [...scene.mapPanels];
          updatedArray[indexToUpdate] = mapPanel; // Replace the element
          scene.mapPanels = updatedArray;
        }
      }
      return state;
    },
    replacePosterInMap(
      state,
      action: PayloadAction<{
        activeScene: string;
        activeMap: string;
        weatherPoster: WeatherPosterDef;
      }>,
    ) {
      const {
        payload: { activeScene, weatherPoster, activeMap },
      } = action;
      const scene = state.project.sceneDefs.find((scene) => scene.id === activeScene);
      if (scene) {
        const map = scene.mapPanels.find((mapLayer) => mapLayer.id === activeMap);
        if (map) {
          const poster = map.geoPosters.find((poster) => poster.id === weatherPoster.id);
          if (poster) {
            poster.observedWDElements = weatherPoster.observedWDElements;
            poster.forecastWDElements = weatherPoster.forecastWDElements;
          }
        }
      }
      return state;
    },
    replaceSceneInProject(state, action: PayloadAction<{ sceneDef: SceneDef }>) {
      const {
        payload: { sceneDef },
      } = action;
      const scenes = state.project.sceneDefs;
      const indexToUpdate = scenes.findIndex((item) => item.id === sceneDef.id);
      if (indexToUpdate !== -1) {
        const updatedArray = [...scenes];
        updatedArray[indexToUpdate] = sceneDef; // Replace the
        state.project.sceneDefs = updatedArray;
      }
      return state;
    },
    deleteCompositeIndicator(
      state,
      action: PayloadAction<{
        activeScene: string | number;
        mapId: string | number;
        type: 'OBSERVED' | 'FORECAST';
      }>,
    ) {
      const {
        payload: { activeScene, mapId, type },
      } = action;
      const scene = state.project.sceneDefs.find((scene) => scene.id === activeScene);
      if (scene) {
        const indicator = scene.pointDates.find(
          (date) => date.pointWDGroupId === `${mapId}_${type}`,
        );
        if (indicator) {
          const index = scene.pointDates.findIndex((item) => item.id === indicator.id);
          scene.pointDates.splice(index, 1);
        }
      }
    },
    editLayerLegend(
      state,
      action: PayloadAction<{
        activeScene: string | number;
        mapId: string | number;
        legendId: string;
        propertyPath: Leaves<WeatherDataMapLayerSetup>;
        value: any;
      }>,
    ) {
      const {
        payload: { activeScene, mapId, legendId, propertyPath, value },
      } = action;
      const scene = state.project.sceneDefs.find((scene) => scene.id === activeScene);
      if (scene) {
        const map = scene.mapPanels.find((panel) => panel.id === mapId);
        if (map) {
          const space = map.wdSpace[0];
          const isGrib = space.gribMapLayers.find((layer) => layer.layerSetup.id === legendId);
          const isRadar = space.radarMapLayers.find((layer) => layer.layerSetup.id === legendId);
          const isSatellite = space.satelliteMapLayers.find(
            (layer) => layer.layerSetup.id === legendId,
          );
          if (isGrib) {
            //@ts-ignore
            isGrib.layerSetup[propertyPath] = value;
            return state;
          }
          if (isRadar) {
            //@ts-ignore
            isRadar.layerSetup[propertyPath] = value;
            return state;
          }
          if (isSatellite) {
            //@ts-ignore
            isSatellite.layerSetup[propertyPath] = value;
            return state;
          }
        }
      }
      return state;
    },
    editLayerColors(
      state,
      action: PayloadAction<{
        activeScene: string;
        mapId: string;
        layerType: 'grib' | 'radar' | 'satellite';
        layerId: string;
        pallet: Record<number, string>;
      }>,
    ) {
      const {
        payload: { activeScene, mapId, layerType, layerId, pallet },
      } = action;
      const scene = state.project.sceneDefs.find((scene) => scene.id === activeScene);
      if (scene) {
        const map = scene.mapPanels.find((map) => map.id === mapId);
        if (map) {
          const space = map.wdSpace[0];
          const gribs = space.gribMapLayers;
          const radars = space.radarMapLayers;
          const satellites = space.satelliteMapLayers;
          if (layerType === 'grib') {
            const layer = gribs.find((layer) => layer.id === layerId);
            if (layer && layer.layerSetup.colorPaletteDef)
              layer.layerSetup.colorPaletteDef.colorStops.pallet = pallet;
          }
          if (layerType === 'radar') {
            const layer = radars.find((layer) => layer.id === layerId);
            if (layer && layer.layerSetup.colorPaletteDef)
              layer.layerSetup.colorPaletteDef.colorStops.pallet = pallet;
          }
          if (layerType === 'satellite') {
            const layer = satellites.find((layer) => layer.id === layerId);
            if (layer && layer.layerSetup.colorPaletteDef)
              layer.layerSetup.colorPaletteDef.colorStops.pallet = pallet;
          }
        }
      }
      return state;
    },
    setSymbolLayerPoints(
      state,
      action: PayloadAction<{ id: string; points: SymbolLayerPointDef[] }>,
    ) {
      const {
        payload: { id, points },
      } = action;

      state.project.sceneDefs.forEach((scene) => {
        scene.mapPanels.forEach((mapPanel) => {
          mapPanel.wdSpace[0].symbolLayers.forEach((symbolLayer) => {
            if (symbolLayer.id === id) {
              symbolLayer.symbolSource.points = uniqBy(
                points,
                (p) => `${p.lon.toFixed(3)}-${p.lat.toFixed(3)}`,
              );
            }
          });
        });
      });
    },
    updateGribFrames(
      state,
      action: PayloadAction<{
        sceneId: string;
        mapId: string;
        spaceId: string;
        layerId: string;
        frames: Array<DataFrameDef>;
      }>,
    ) {
      const {
        payload: { sceneId, mapId, spaceId, layerId, frames },
      } = action;
      const scene = state.project.sceneDefs.find((sc) => sc.id === sceneId);
      if (scene) {
        const map = scene.mapPanels.find((m) => m.id === mapId);
        if (map) {
          const space = map.wdSpace.find((m) => m.id === spaceId);
          if (space) {
            const layer = space.gribMapLayers.find((m) => m.id === layerId);
            if (layer) layer.dataFrames = frames;
          }
        }
      }
      return state;
    },
    updateRadarFrames(
      state,
      action: PayloadAction<{
        sceneId: string;
        mapId: string;
        spaceId: string;
        layerId: string;
        frames: Array<DataFrameDef>;
      }>,
    ) {
      const {
        payload: { sceneId, mapId, spaceId, layerId, frames },
      } = action;
      const scene = state.project.sceneDefs.find((sc) => sc.id === sceneId);
      if (scene) {
        const map = scene.mapPanels.find((m) => m.id === mapId);
        if (map) {
          const space = map.wdSpace.find((m) => m.id === spaceId);
          if (space) {
            const layer = space.radarMapLayers.find((m) => m.id === layerId);
            if (layer) layer.dataFrames = frames;
          }
        }
      }
      return state;
    },
    updateSatelliteFrames(
      state,
      action: PayloadAction<{
        sceneId: string;
        mapId: string;
        spaceId: string;
        layerId: string;
        frames: Array<DataFrameDef>;
      }>,
    ) {
      const {
        payload: { sceneId, mapId, spaceId, layerId, frames },
      } = action;
      const scene = state.project.sceneDefs.find((sc) => sc.id === sceneId);
      if (scene) {
        const map = scene.mapPanels.find((m) => m.id === mapId);
        if (map) {
          const space = map.wdSpace.find((m) => m.id === spaceId);
          if (space) {
            const layer = space.satelliteMapLayers.find((m) => m.id === layerId);
            if (layer) layer.dataFrames = frames;
          }
        }
      }
      return state;
    },
    enforceDrawingsRerender(state, action: PayloadAction<{ sceneId: string; mapId: string }>) {
      const {
        payload: { mapId, sceneId },
      } = action;
      const sceneFound = state.project.sceneDefs.find((sc) => sc.id === sceneId);
      if (!sceneFound) return;
      const mapFound = sceneFound.mapPanels.find((m) => m.id === mapId);
      if (!mapFound) return;
      mapFound.drawingElements = [...mapFound.drawingElements];
    },
  },
});

export const {
  addAudioLayer,
  addForecastElements,
  fillSpaceWithForecast,
  addMapForecastLayer,
  addGeoPoster,
  addGraphLayer,
  addImageLayer,
  addAnimationLayer,
  addKeyFrame,
  addLogicalGroup,
  addMapLayer,
  addNewProject,
  addOWDLayer,
  addMapOWDLayer,
  addPointDateLayer,
  addPointLocationLayer,
  addPosterLayer,
  addProjectVideOver,
  addProjectVoiceOver,
  addScene,
  editScene,
  addTextLayer,
  addTimeToSkip,
  addVideoLayer,
  closeTimeSkip,
  createIndicator,
  cutDrawingElement,
  cutElement,
  deleteCompositeIndicator,
  deleteFromGraphLayer,
  deleteGeoPoster,
  deleteImageLayer,
  deleteLogicalGroup,
  deleteScene,
  deleteSkip,
  deleteTextLayer,
  editOneSkip,
  editSceneTransition,
  enableIndicator,
  enableLayer,
  extendSceneDuration,
  insertScene,
  removeKeyFrame,
  removeThumbnail,
  reorderScenes,
  reorderScenesForStoryboard,
  replaceMapLayer,
  resetProject,
  setBaseMapHidden,
  setOceanMaskHidden,
  setProject,
  setSavedProject,
  toggleGeoPoster,
  toggleGeoPosterElement,
  togglePosterElement,
  updateAudioLayer,
  updateBoxDef,
  updateDateLayer,
  updateDrawTime,
  updateElementTime,
  updateForecastLayer,
  updateForecastPanel,
  updateGeoPosterElementTime,
  updateGraphLayer,
  updateGroupName,
  updateGroupPos,
  updateImageLayer,
  updateAnimationLayer,
  deleteAnimationLayer,
  updateIndicatorBox,
  updateIndicatorFormat,
  updateIndicatorLayer,
  updateSymbolLayerStyle,
  updateIndicatorPosition,
  updateIndicatorRound,
  updateRelativeTime,
  updateIndicatorStep,
  updateIndicatorTime,
  updateKeyframePositionControlByIndex,
  updateLayerGroupPos,
  updateLocationLayer,
  updateLogicalGroups,
  updateMapDefPartial,
  updateMapGRSLayer,
  updateMapObservedLayer,
  updateMapForecastLayer,
  updateMapLayer,
  updateMapLayerTime,
  updateIndicatorPriority,
  updateMapOverlay,
  updateMapOverlayLonLat,
  updateMapOverlayProperties,
  updateOWDLayer,
  updateObservedPanel,
  updatePanel,
  updatePosterPanel,
  updatePosition,
  updatePosterElementTime,
  updatePosterLayer,
  updateProject,
  updateProjectSkipTimes,
  updateRecorderPosition,
  updateScene,
  updateSpaceTime,
  updateTextLayer,
  updateVideoLayer,
  updateVideoOverTime,
  updateVoiceOverTime,
  addVideoRecorder,
  multiUpdateAnimationType,
  multiUpdateTime,
  updateAnimationTemplateSize,
  updateObservedMapLayer,
  updateForecastMapLayer,
  updateObservedMapLayerBox,
  updateForecastMapLayerBox,
  updateObservedMapLayerBackground,
  editLayerLegend,
  updateDrawingElement,
  updateDrawingElementGeneral,
  editLayerColors,
  replacePosterInScene,
  replacePosterInMap,
  replaceMapInScene,
  replaceSceneInProject,
  setSymbolLayerPoints,
  updateSpaceClipLayers,
  updateForecastLonLat,
  copyMapDrawingLayer,
  addCustomVectorLayer,
  editCustomVectorLayerStyle,
  removeCustomVectorLayer,
  enforceDrawingsRerender,
  updateCompositePosition,
  setSpaceSync,
  updateMapExtent,
  updateGribFrames,
  updateRadarFrames,
  updateSatelliteFrames,
  addTimestampLayer,
  updateTimestampLayer,
} = projectSlice.actions;
export default projectSlice.reducer;
