import './style.scss';

import { ResizeDirection } from 're-resizable';
import { useEffect, useState } from 'react';
import { MdOutlineDragHandle } from 'react-icons/md';
import { TbChevronsDownRight, TbChevronsUpRight } from 'react-icons/tb';
import { useDispatch, useSelector } from 'react-redux';
import { useResizeDetector } from 'react-resize-detector';
import { Position, ResizableDelta, Rnd } from 'react-rnd';

import { ElementsEnum } from '../../../core/ui/enums/ElementsEnum';
import { ModeEnum } from '../../../core/ui/enums/ModeEnum';
import { absoluteToPercent, getNumber, getPercentFromData } from '../../../helpers/timelineUtil';
import { C9ProjectDef } from '../../../model/definitions/C9ProjectDef';
import { TimeControlDef } from '../../../model/definitions/TimeControlDef';
import { AnimationsEnum } from '../../../model/enums/AnimationsEnum';
import { LaneProps } from '../../../model/UI/LaneProps';
import { ActiveDef } from '../../../store/slices/active-slice';
import {
  multiUpdateTime,
  updateDrawTime,
  updateElementTime,
} from '../../../store/slices/project-slice';
import { RootState } from '../../../store/store';

const DrawLaneRefactor = ({
  segments = [new TimeControlDef()],
  element,
  elementId,
  type,
  scenes,
  duration,
  frames,
  disabled,
  parentTime,
  parentId,
  open,
  ...props
}: LaneProps) => {
  const { mode, activeZoom, posterOpen, activeScene, multiselect } = useSelector<RootState>(
    (state) => state.active,
  ) as ActiveDef;
  const project = useSelector<RootState, C9ProjectDef>((state) => state.project.present.project);
  const isOpen = posterOpen.findIndex((value) => value === parentId) > -1 || !parentId;
  const [pieces, setPieces] = useState<Array<TimeControlDef>>(segments);
  const currentScene = useSelector<RootState>((state) => state.active.activeScene) as string;
  const elemSection = multiselect.find((section) => section.type === 'drawingElements');
  const selected = elemSection?.elements.find((listItem) => listItem.element.id === elementId);
  const actualScene = project.sceneDefs.find((scenes) => scenes.id === activeScene);
  const { width, ref } = useResizeDetector();
  const dispatch = useDispatch();
  useEffect(() => {
    setPieces(absoluteToPercent(segments, duration, parentTime?.startMS, parentTime?.endMS));
  }, [segments, activeZoom, width, mode, duration, parentTime, isOpen]);

  const getPercentFromRef = (position: Position, ref: HTMLElement): number => {
    return (position.x * 100) / ref.offsetParent!.clientWidth;
  };
  const getPercentFromDelta = (data: ResizableDelta, ref: HTMLElement) => {
    return (data.width * 100) / ref.offsetParent!.clientWidth;
  };
  const percentToTime = (percent: number) => {
    return Math.round((duration * percent) / 100);
  };
  const multiUpdate = (diffStart = 0, diffEnd = 0, movement: 'drag' | 'resize') => {
    selected &&
      actualScene &&
      dispatch(
        multiUpdateTime({
          activeScene,
          selection: multiselect,
          start: diffStart,
          end: diffEnd,
          movement,
          sceneLength: actualScene?.durationInMS,
        }),
      );
  };
  const dragEnded = (percent: number, type: ElementsEnum, index: number) => {
    const newPieces = [...pieces];
    const width = newPieces[index].endMS - newPieces[index].startMS;
    if (newPieces[index - 1] && newPieces[index - 1].endMS > percent) {
      newPieces[index].startMS = newPieces[index - 1].endMS;
      newPieces[index].endMS = newPieces[index - 1].endMS + width;
    } else if (newPieces[index + 1] && newPieces[index + 1].startMS < percent + width) {
      newPieces[index].startMS = newPieces[index + 1].startMS - width;
      newPieces[index].endMS = newPieces[index + 1].startMS;
    } else {
      newPieces[index].startMS = percent;
      newPieces[index].endMS = percent + width;
    }
    setPieces(newPieces);
    const newTimes: TimeControlDef[] = [];
    newPieces.forEach((item) => {
      newTimes.push({
        ...item,
        endMS: percentToTime(item.endMS),
        startMS: percentToTime(item.startMS),
      });
    });
    const startDiff =
      percentToTime(newPieces[index].startMS) -
      segments[index].startMS -
      (parentTime?.startMS || 0);
    const endDiff = percentToTime(newPieces[index].endMS) - segments[index].endMS;
    multiUpdate(startDiff, endDiff, 'drag');
    !selected &&
      parentId &&
      dispatch(
        updateDrawTime({
          time: newTimes,
          activeScene: currentScene,
          mapId: parentId,
          spaceId: elementId,
        }),
      );
  };
  const resizeStopped = (
    position: Position,
    delta: ResizableDelta,
    ref: HTMLElement,
    direction: ResizeDirection,
    type: ElementsEnum,
    index: number,
  ): void => {
    const newPieces = [...pieces];
    if (direction === 'left') {
      if (newPieces[index - 1] && newPieces[index - 1].endMS > getPercentFromRef(position, ref)) {
        newPieces[index].startMS = newPieces[index - 1].endMS;
      } else {
        newPieces[index].startMS = getPercentFromRef(position, ref);
      }
    }
    if (direction === 'right') {
      if (
        newPieces[index + 1] &&
        newPieces[index + 1].startMS < newPieces[index].endMS + getPercentFromDelta(delta, ref)
      ) {
        newPieces[index].endMS = newPieces[index + 1].startMS;
      } else {
        newPieces[index].endMS += getPercentFromDelta(delta, ref);
      }
    }
    const startDiff =
      percentToTime(newPieces[index].startMS) -
      segments[index].startMS -
      (parentTime?.startMS || 0);
    const endDiff = percentToTime(newPieces[index].endMS) - segments[index].endMS;
    multiUpdate(startDiff, endDiff, 'resize');
    setPieces(newPieces);
    const newTimes: TimeControlDef[] = [];
    newPieces.forEach((item) => {
      newTimes.push({
        ...item,
        endMS: percentToTime(item.endMS),
        startMS: percentToTime(item.startMS),
      });
    });
    !selected &&
      parentId &&
      dispatch(
        updateDrawTime({
          time: newTimes,
          activeScene: currentScene,
          mapId: parentId,
          spaceId: elementId,
        }),
      );
  };
  const onDelete = (e: MouseEvent, index: number) => {
    e.preventDefault();
    if (e.type === 'dblclick' && type) {
      const time = [...segments];
      time.splice(index, 1);
      dispatch(
        updateElementTime({
          time: time,
          elementId: elementId,
          elementType: type,
          activeScene: currentScene,
        }),
      );
    }
  };

  const all = () => {
    return pieces.map((item, index) => (
      <Rnd
        key={`${elementId}${index}`}
        onDoubleClick={(e: MouseEvent) => onDelete(e, index)}
        enableResizing={
          disabled
            ? !disabled
            : !(element?.flyOver?.keyFrames && element.flyOver?.keyFrames.length > 0)
            ? {
                left: mode === ModeEnum.SEQUENCE,
                right: mode === ModeEnum.SEQUENCE,
              }
            : false
        }
        minHeight={12}
        dragAxis={'x'}
        resizeHandleComponent={{
          right: (
            <MdOutlineDragHandle
              size={24}
              color={'rgb(96, 136, 113)'}
              style={{
                transform: 'rotate(90deg) translateY(12px)',
                height: '100%',
              }}
            />
          ),
          left: (
            <MdOutlineDragHandle
              size={24}
              color={'rgb(96, 136, 113)'}
              style={{
                transform: 'rotate(90deg) translateY(2px)',
                height: '100%',
              }}
            />
          ),
        }}
        disableDragging={
          (element?.flyOver?.keyFrames && element.flyOver?.keyFrames.length > 0) || disabled
        }
        title={`${percentToTime(item.startMS) / 1000}s - ${percentToTime(item.endMS) / 1000}s`}
        className={`segment`}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '0 10px',
          height: 10,
          maxHeight: 10,
          minHeight: 10,
          background: 'deeppink',
        }}
        maxHeight={12}
        bounds={'parent'}
        position={{
          x: getNumber(item.startMS, width),
          y: 6,
        }}
        size={{
          width: parentTime
            ? item.endMS > absoluteToPercent([parentTime], duration)[0].endMS
              ? absoluteToPercent([parentTime], duration)[0].endMS - item.startMS + '%'
              : item.endMS - item.startMS + '%'
            : item.endMS - item.startMS + '%',
          height: 12,
        }}
        onDragStop={(e, data) => {
          e && dragEnded(getPercentFromData(data, width), type!, index);
        }}
        onResizeStop={(e, direction, ref, delta, position) =>
          resizeStopped(position, delta, ref, direction, type!, index)
        }
      >
        {item.inAnimationDef === AnimationsEnum.FADE_IN && <TbChevronsUpRight />}
        {item.outAnimationDef === AnimationsEnum.FADE_OUT && (
          <TbChevronsDownRight style={{ right: 10, position: 'absolute' }} />
        )}
      </Rnd>
    ));
  };
  return (
    <>
      {open ? (
        <div {...props} id={'Lane'} ref={ref}>
          <div className={'rail'} style={{ height: mode === ModeEnum.PROJECT ? 35 : 1 }} />
          {all()}
        </div>
      ) : null}
    </>
  );
};

export default DrawLaneRefactor;
