import { useQuery } from 'react-query';

import { axiosInstance } from '../axiosInstance';

export const key = ['ENTERPRISE_PALETTES'];
export const useGetPalettes = (id?: string) =>
  useQuery(
    [...key, id],
    async () => {
      const { data } = await axiosInstance.get(`color-palette/enterprise/${id}`);
      return data;
    },
    {
      enabled: !!id,
    },
  );
