import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { queryClient } from '../../..';
import { axiosInstance } from '../../../core/api/axiosInstance';
import { allFontsKey, enterpriseKey, key } from './fontKeys';

export const useEditFont = () => {
  return useMutation(
    async (data: { id: string; name: string; description: string }) => {
      return await axiosInstance.put(`font`, data);
    },
    {
      onSuccess: () => {
        toast.success('Font edited successfully!');
        const keys = [allFontsKey, key, enterpriseKey];
        keys.forEach((k) => queryClient.invalidateQueries(k));
      },
      onError: () => {
        toast.error('Error editing font!');
      },
    },
  );
};
