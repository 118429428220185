import Facebook from '../../assets/images/SocialMedia/Facebook.png';
import Instagram from '../../assets/images/SocialMedia/Instagram.png';
import Linkedin from '../../assets/images/SocialMedia/Linkedin.png';
import Twitter from '../../assets/images/SocialMedia/Twitter.png';
import Youtube from '../../assets/images/SocialMedia/YouTube.png';

export const SocialMediaIcons = [
  {
    image: Instagram,
    link: '',
  },
  { image: Twitter, link: '' },
  { image: Linkedin, link: '' },
  { image: Facebook, link: '' },
  { image: Youtube, link: '' },
];
