import '../../storyBoard.scss';

import type { FC } from 'react';
import { useCallback } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDispatch, useSelector } from 'react-redux';

import useRoleAccess from '../../../../../hooks/useRoleAccess';
import { C9ProjectDef } from '../../../../../model/definitions/C9ProjectDef';
import { RolesEnum } from '../../../../../model/enums/RolesEnum';
import {
  reorderScenes,
  reorderScenesForStoryboard,
} from '../../../../../store/slices/project-slice';
import { RootState } from '../../../../../store/store';
import { SceneSimple } from '../../../Timeline';
import { SceneCard } from './SceneCard';

interface BoardProps {
  duration: number;
  scenes: Array<SceneSimple>;
  originalScenesOrder: Array<string>;
}
export const Board: FC<BoardProps> = ({ scenes, duration, originalScenesOrder }) => {
  const project = useSelector<RootState, C9ProjectDef>((state) => state.project?.present?.project);
  const roleProducer = useRoleAccess(
    [RolesEnum.ROLE_PRODUCER],
    project.isSharedEntity,
    project.inEditMode,
  );
  const dispatch = useDispatch();
  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      dispatch(reorderScenes({ oldIndex: dragIndex, newIndex: hoverIndex }));
    },
    [dispatch],
  );
  const hoverCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      dispatch(reorderScenesForStoryboard({ oldIndex: dragIndex, newIndex: hoverIndex }));
    },
    [dispatch],
  );

  const renderCard = (card: SceneSimple, index: number) => {
    return (
      duration && (
        <SceneCard
          key={card.id}
          index={index}
          originalIndex={originalScenesOrder.findIndex((x) => x === card.id)!}
          id={card.id}
          text={card.name}
          moveCard={moveCard}
          hoverCard={hoverCard}
          data={card}
          duration={duration}
        />
      )
    );
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className={`board ${roleProducer ? '' : 'pointer-events-none opacity-30'}`}>
        {scenes.map((card, i) => renderCard(card, i))}
      </div>
    </DndProvider>
  );
};
