import { PartialLottieComponentProps, useLottie } from 'lottie-react';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { PlaybackEnum } from '../core/ui/enums/PlaybackEnum';
import { useMultimediaObjectUrl } from '../hooks/useMultimediaObjectUrl';
import { TimeControlDef } from '../model/definitions/TimeControlDef';
import playerContext from '../pages/playground/playerContext/PlayerContext';
import { ActiveDef } from '../store/slices/active-slice';
import { RootState } from '../store/store';

interface LottieIconProps extends PartialLottieComponentProps {
  versionId: string;
  inCanvas?: boolean;
  time?: TimeControlDef[];
  parentStart?: number;
  frameRate?: number;
  repeat?: boolean;
}

export const LottieIconAnimation = ({
  versionId,
  time = [new TimeControlDef(0, 0)],
  parentStart = 0,
  repeat = false,
  ...rest
}: LottieIconProps) => {
  const { activeFramerate } = useSelector<RootState>((state) => state.active) as ActiveDef; // Get active frame rate from Redux store
  const url = useMultimediaObjectUrl(versionId, true); // Get the multimedia URL based on versionId
  const { isPlaying, time: contextTime } = useContext(playerContext); // Access playback state from context

  // Memoize Lottie options to dynamically update based on activeFramerate, URL, etc.
  const options = useMemo(
    () => ({
      ...rest,
      animationData: url,
      loop: !!repeat,
      autoplay: true,
      frameRate: activeFramerate, // Apply dynamic frame rate from Redux state
      className: 'max-h-full max-w-full contents', // Ensure layout styles
    }),
    [url, repeat, activeFramerate, rest],
  );

  const { View, goToAndStop, goToAndPlay, pause, stop, play, animationItem } = useLottie(options);

  const startMS = useMemo(() => parentStart + (time[0]?.startMS || 0), [parentStart, time]);

  // Callback to handle frame rate updates dynamically
  const handleFrameRateUpdate = useCallback(() => {
    if (animationItem) {
      animationItem.frameRate = activeFramerate; // Update animation framerate if activeFramerate changes
    }
  }, [animationItem, activeFramerate]);

  // Handle the playback logic based on the context time and playback state
  const handlePlayback = useCallback(() => {
    const frameRate = animationItem?.frameRate ?? activeFramerate; // Use either the animation framerate or the active one
    const totalFrames = animationItem?.totalFrames ?? 0;
    const elapsedFrames = (contextTime - startMS) / frameRate; // Calculate elapsed frames based on context time

    if (isPlaying === PlaybackEnum.PLAYING) {
      if (contextTime >= startMS) {
        if (repeat) {
          goToAndPlay(Math.ceil(elapsedFrames % totalFrames), true); // Loop when repeat is true
        } else if (elapsedFrames >= totalFrames) {
          goToAndStop(totalFrames - 1, true); // Stop if the animation reaches the end
        } else {
          play(); // Play normally
        }
      } else {
        stop(); // Stop if context time is before start time
      }
    } else {
      pause(); // Pause if playback is paused
      const targetFrame = Math.min(elapsedFrames, totalFrames - 1);
      goToAndStop(repeat ? elapsedFrames % totalFrames : targetFrame, true); // Go to the correct frame
    }
  }, [animationItem, contextTime, activeFramerate, isPlaying, startMS, repeat]);

  useEffect(() => {
    handlePlayback(); // Trigger playback logic whenever context time or playback state changes
  }, [contextTime, activeFramerate, isPlaying, handlePlayback]);

  useEffect(() => {
    handleFrameRateUpdate(); // Update the frame rate dynamically when needed
  }, [activeFramerate, handleFrameRateUpdate]);

  useEffect(() => {
    if (contextTime <= 0 && isPlaying !== PlaybackEnum.PLAYING) {
      goToAndStop(0, true); // Reset to frame 0 if not playing and context time is 0
    }
  }, [contextTime, isPlaying]);

  return <>{View}</>;
};
