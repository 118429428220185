export class AnchorDef {
  enable = true;
  longitude: number;
  latitude: number;
  /**used as city default position returned by BE endpoint */
  anchorOffsetX: number;
  anchorOffsetY: number;
  type: 'shape' | 'image' = 'shape';
  imageURL: string | null = null;
  shape: 'circle' | 'square' | 'cross' = 'circle'; //circle, square
  size = 3; //radius/height
  fillColor = '#2596be';
  borderColor = '#2596be';
  borderWidth = 1;
  width = 20;
  height = 20;
  constructor(lon: number, lat: number) {
    this.latitude = lat;
    this.longitude = lon;
    this.anchorOffsetX = lon;
    this.anchorOffsetY = lat;
  }
}
