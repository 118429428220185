import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { axiosInstance } from '../../../../core/api/axiosInstance';
import { queryClient } from '../../../../index';
import IChargeStatus from '../../../payment/types/IChargeStatus.type';
import { key as cartKey } from './useGetShoppingCartItems';
import { key } from './useGetTransactions';

export const useConfirmTransaction = () => {
  return useMutation(
    async ({ paymentIntentId, paymentIntentSecret, redirectStatus }: IChargeStatus) => {
      return await axiosInstance.post(
        `payment/transaction/confirm?payment_intent=${paymentIntentId}&payment_intent_client_secret=${paymentIntentSecret}&redirect_status=${redirectStatus}`,
      );
    },
    {
      onSuccess: () => {
        toast.success('Transaction successfully finished!');
        queryClient.invalidateQueries([...key]);
        queryClient.invalidateQueries([...cartKey]);
      },
      onError: () => {
        toast.error('There is problem with transaction!');
      },
    },
  );
};
