import { nanoid } from 'nanoid';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { v4 } from 'uuid';

import { axiosInstance } from '../../../../core/api/axiosInstance';
import { SceneDef } from '../../../../model/definitions/SceneDef';
import { SceneDefTemplate } from '../../../../model/definitions/SceneDefTemplate';
import { setScene } from '../../../../store/slices/active-slice';
import { addScene } from '../../../../store/slices/project-slice';

export const useGetSceneFromRepo = () => {
  const dispatch = useDispatch();
  return useMutation(async ({ id }: { id: string }): Promise<SceneDef> => {
    const { data } = await axiosInstance.get<SceneDefTemplate>(`scene-template/current/${id}`);
    data.sceneDef.templateId = data.id;
    data.sceneDef.name = `Scene ${nanoid(3)}`;
    data.sceneDef.id = v4();
    data.sceneDef.templateVersionId = data.versionId;
    dispatch(addScene({ scene: data.sceneDef }));
    dispatch(setScene({ activeScene: data.sceneDef.id }));
    return data.sceneDef;
  });
};
