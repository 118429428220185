import { Progress } from 'flowbite-react';
import { useEffect, useRef, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { toast } from 'react-toastify';

import formatFileSize from '../../../../helpers/formatFileSize';
import useUploadMultimedia from '../../queries-NEW/useUploadMultimedia';
import { UpdateInterface } from '../../templates-NEW/Projects';
import { handleZipData } from '../SequenceModal';
import { UploadableFile } from './UploadFiles';

export interface SingleFileUploadWithProgressProps {
  file: UploadableFile;
  fileType: 'image' | 'video' | 'audio' | 'custom_map' | 'animation' | 'symbol' | 'custom_vector';
  onDelete: (file: File) => void;
  setFiles: React.Dispatch<React.SetStateAction<UpdateInterface[]>>;
}

export default function UploadSingleFile({
  file,
  fileType,
  onDelete,
  setFiles,
}: SingleFileUploadWithProgressProps) {
  const controller = useRef(new AbortController());
  const { progress, mutation } = useUploadMultimedia();
  const [percent, setPercent] = useState<number>();

  useEffect(() => {
    uploadFile(file, controller.current, fileType);
  }, []);

  function sendData(abort: AbortController) {
    const formData = new FormData();
    formData.append('file', file.file);
    formData.append('type', fileType.toUpperCase());
    formData.append('description', file.description);
    formData.append('name', file.name);

    mutation.mutate({
      fileData: formData,
      abort,
      fileType: fileType.toUpperCase(),
    });
  }

  function uploadFile(file: UploadableFile, abort: AbortController, fileType: string) {
    if (fileType === 'animation' && file.file.type === 'application/zip') {
      handleZipData([file.file]).then((res) => {
        res ? sendData(abort) : alert("Folder '2_main' not found!");
      });
    } else {
      sendData(abort);
    }
  }

  useEffect(() => {
    if (mutation.isSuccess) {
      const array: UpdateInterface[] = [];
      array.push({
        id: mutation.data.id,
        versionId: mutation.data.versionId,
        newName: mutation.data.name,
        newDescription: mutation.data.description,
        type: `${fileType.toUpperCase()}_TEMPLATE`,
        key: fileType,
      });
      setFiles((prev) => [...prev, ...array]);
      toast.success(`Successfully uploaded file ${file.name}`);
    }
    if (mutation.isError) {
      toast.error(`Error while uploading file ${file.name}`);
    }
  }, [mutation.isSuccess, mutation.isError]),
    useEffect(() => {
      if (progress) {
        setPercent(Math.round((progress.loaded / progress.total) * 100));
      }
    }, [progress]);

  function render() {
    if (progress && percent) {
      return (
        <>
          <div className="flex justify-between my-2">
            <span className="flex items-center">
              <AiOutlineClose
                className="inline text-red-500 cursor-pointer"
                size={16}
                onClick={() => {
                  controller?.current?.abort('User aborted upload');
                  onDelete(file.file);
                }}
              />
              {file.name +
                `   ${formatFileSize(progress.loaded) + '/' + formatFileSize(progress.total)}`}
            </span>
            <span>{percent === 100 && mutation.isLoading && 'processing data...'}</span>
            <span>{percent}%</span>
          </div>
          <Progress
            progress={percent}
            color={
              mutation.isError ? 'red' : percent === 100 && mutation.isLoading ? 'blue' : 'green'
            }
            size="sm"
          />
        </>
      );
    }
  }
  return <div className="mt-4 mb-8">{render()}</div>;
}
