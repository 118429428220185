import { Select } from 'flowbite-react';
import moment from 'moment/moment';

import { stringToUnit } from '../../../../../helpers/stringToUnit';
import { ForecastDataDef } from '../../../../../model/definitions/ForecastDataDef';
import { ForecastWDElementDef } from '../../../../../model/definitions/ForecastWDElementDef';
import { ValueTypeEnum } from '../../../../../model/enums/ValueTypeEnum';
import { IconPicker } from '../../../../../organisms/addElementModal/PointDataComponents/IconPicker';

interface ForecastParameterCardProps {
  item: ForecastWDElementDef;
  freshData?: ForecastDataDef;
  zeroDate: number;
  selectedDate?: number;
  data: Array<ForecastWDElementDef>;
  isDaily: string;
  onUnitChange: (e: string) => void;
  onIconChange: (e: any, source?: string) => void;
}
export const ForecastParameterCard = ({
  item,
  freshData,
  zeroDate,
  selectedDate,
  data,
  isDaily,
  onUnitChange,
  onIconChange,
}: ForecastParameterCardProps) => {
  const { forecastWDSource } = item;
  const time = moment(
    new Date(
      (selectedDate ?? 0) +
        new Date(
          data.find((itemOrig) => itemOrig.forecastWDSource.id === item.forecastWDSource.id)
            ?.forecastWDSource.utcDate ?? 0,
        ).getTime() -
        zeroDate,
    ),
  )
    .toISOString(true)
    .slice(0, -10);
  const selectUnt = (values: { unit: string; value: number }[]) =>
    Array.isArray(values) &&
    values?.map((value) => (
      <option key={`${value?.value}_${value?.unit}`} value={value?.unit}>
        {stringToUnit(value.unit)}
      </option>
    ));
  const getIcon = (isDaily: string, time: string) => {
    if (isDaily === 'true') {
      return freshData?.daily.find((item) => item.utcDate === time)?.weatherType ?? 'N/A';
    }
    if (isDaily === 'false') {
      return freshData?.intraDay.find((item) => item.utcDate === time)?.weatherType ?? 'N/A';
    }
    return 'N/A';
  };
  const { value, parameterType, unitOfMeasurement, biasFilterAppliedOnValue } = forecastWDSource;
  return forecastWDSource.valueType === ValueTypeEnum.NUMERICAL ? (
    <div
      key={item.forecastWDSource.location?.name + item.forecastWDSource.parameterType}
      className={`mb-2 property-card ${value && biasFilterAppliedOnValue && 'ring-1 ring-red-500'}`}
      style={{ animation: 'none' }}
    >
      <div>{parameterType?.split(/(?=[A-Z])/).join(' ')}</div>
      <div>
        {Array.isArray(value) &&
          value?.find((val: { unit: string; value: number }) => val.unit === unitOfMeasurement)
            ?.value}
      </div>
      <Select
        className={'observed-select'}
        sizing={'sm'}
        value={item.forecastWDSource.unitOfMeasurement}
        onChange={(e) => {
          onUnitChange(e.target.value);
        }}
      >
        {selectUnt(item.forecastWDSource.value)}
      </Select>
    </div>
  ) : (
    <div key={item.id}>
      <IconPicker
        key={forecastWDSource.utcDate}
        isTemp
        weatherType={getIcon(isDaily, time)}
        onSelect={(e) => {
          onIconChange(e, getIcon(isDaily, time));
        }}
        onClick={(e) => onIconChange(e, getIcon(isDaily, time))}
        active={false}
      />
    </div>
  );
};
