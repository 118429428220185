import './cron-builder.css';

import cronstrue from 'cronstrue/i18n';
import React, { useCallback, useEffect, useState } from 'react';

import { HeaderKeyType, HeaderValType, loadHeaders, metadata } from './meta';

interface CronProp {
  value?: string;
  onChange(val: string): void;
  showResultText: boolean;
  showResultCron: boolean;
  translateFn?(key: string): string;
  locale?: string;
  options?: { headers: HeaderKeyType[] };
}
interface State {
  value: string[];
  selectedTab?: HeaderValType;
  headers: HeaderValType[];
  locale: string;
}
const defaultCron = '0 0 00 1/1 * ? *';
const Cron: React.FunctionComponent<CronProp> = (props) => {
  const [counter, setCounter] = useState(0);
  const [state, setState] = useState<State>({
    value: ['0', '0', '00', '1/1', '*', '?', '*'],
    headers: loadHeaders(props.options),
    locale: props.locale ? props.locale : 'en',
  });
  const setValue = (value: string) => {
    const prevState = state;
    prevState.value = value.replace(/,/g, '!').split(' ');
    const allHeaders = loadHeaders();
    if (value && value.split(' ').length === 6) {
      prevState.value.push('*');
    }
    if (!value || value.split(' ').length !== 7) {
      value = '0 0 00 1/1 * ? *';
      prevState.selectedTab = allHeaders[0];
      prevState.value = value.split(' ');
      parentChange(value.split(' '));
    } else {
      prevState.value = value.replace(/,/g, '!').split(' ');
    }
    const val = prevState.value;
    if (val[1].search('/') !== -1 && val[2] === '*' && val[3] === '1/1') {
      prevState.selectedTab = allHeaders[0];
    } else if (val[3] === '1/1') {
      prevState.selectedTab = allHeaders[1];
    } else if (val[3].search('/') !== -1 || val[5] === 'MON-FRI') {
      prevState.selectedTab = allHeaders[2];
    } else if (val[3] === '?') {
      prevState.selectedTab = allHeaders[3];
    } else if (val[3].startsWith('L') || val[4] === '1/1') {
      prevState.selectedTab = allHeaders[4];
    } else {
      prevState.selectedTab = allHeaders[0];
    }
    if (!prevState.headers.includes(prevState.selectedTab)) {
      prevState.selectedTab = prevState.headers[0];
    }
    setState(prevState);
    // Quick fix to force re-render
    // it wasn't being re-rendered because useState doesn't track deep object changes
    setCounter(counter + 1);
  };

  useEffect(() => {
    if (props.value === state.value.join(' ')) return;
    setValue(props.value ? props.value : defaultCron);
  }, [props.value]);

  const tabChanged = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, tab: HeaderValType) => {
    e.preventDefault();
    if (state.selectedTab !== tab) {
      setState({ ...state, selectedTab: tab, value: defaultValue(tab) });
    }
  };

  const getHeaders = () => {
    return state.headers.map((d, index) => {
      return (
        <li className="nav-item" key={index}>
          <button
            className={`nav-link ${state.selectedTab === d ? 'active' : ''}`}
            onClick={(e) => tabChanged(e, d)}
          >
            {translate(d)}
          </button>
        </li>
      );
    });
  };
  const onValueChange = (val: string[]) => {
    if (val && val.length) {
      setState({ ...state, value: [...val] });
    } else {
      val = ['0', '0', '00', '1/1', '*', '?', '*'];
      setState({ ...state, value: val });
    }
  };
  const parentChange = useCallback(
    (val: string[]) => {
      let newVal = '';
      newVal = val.toString().replace(/,/g, ' ');
      newVal = newVal.replace(/!/g, ',');
      props.onChange(newVal);
    },
    [props],
  );
  useEffect(() => {
    parentChange(state.value);
  }, [state.value, parentChange]);

  const getVal = () => {
    if (!state.value || !state.value.length) {
      return '-';
    }
    const val = cronstrue.toString(state.value.toString().replace(/,/g, ' ').replace(/!/g, ','), {
      throwExceptionOnParseError: false,
      locale: state.locale,
    });
    if (val.search('undefined') === -1) {
      return val;
    }
    return '-';
  };

  const defaultValue = (tab: HeaderValType): string[] => {
    const defaultValCron = metadata.find((m) => m.name == tab);
    if (!defaultValCron || !defaultValCron.initialCron) {
      return defaultCron.split(' ');
    }
    return defaultValCron.initialCron;
  };
  const getComponent = (tab: HeaderValType) => {
    const index = state.headers.indexOf(tab);
    let selectedMetaData = metadata.find((data) => data.name === tab);
    if (!selectedMetaData) {
      selectedMetaData = metadata[index];
    }
    if (!selectedMetaData) {
      throw new Error('Value does not match any available headers.');
    }
    if (!state.value || !state.value.length) {
      return <div />;
    }
    const CronComponent = selectedMetaData.component;
    return <CronComponent translate={translate} value={state.value} onChange={onValueChange} />;
  };
  const translate = (key: string): string => {
    let translatedText = key;
    if (props.translateFn) {
      translatedText = props.translateFn(key);
      if (typeof translatedText !== 'string') {
        throw new Error('translateFn expects a string translation');
      }
    }
    return translatedText;
  };

  return (
    <div className="cron_builder">
      <ul className="nav nav-tabs">{getHeaders()}</ul>
      <div className="cron_builder_bordering">
        {state.selectedTab ? getComponent(state.selectedTab) : 'Select a header'}
      </div>
      {props.showResultText && <div className="cron-builder-bg">{getVal()}</div>}
      {props.showResultCron && (
        <div className="cron-builder-bg">
          {state.value.toString().replace(/,/g, ' ').replace(/!/g, ',')}
        </div>
      )}
    </div>
  );
};
export default Cron;
