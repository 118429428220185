import '../../storyBoard.scss';

import type { Identifier } from 'dnd-core';
import type { FC } from 'react';
import { useRef } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { useDrag, useDrop } from 'react-dnd';
import { DragSourceMonitor } from 'react-dnd/src/types';
import { useDispatch } from 'react-redux';

import { ModeEnum } from '../../../../../core/ui/enums/ModeEnum';
import { setMode, setScene } from '../../../../../store/slices/active-slice';
import { SceneSimple } from '../../../Timeline';
import { ItemTypes } from './ItemTypes';

export interface CardProps {
  id: string;
  text: string;
  index: number;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  hoverCard: (dragIndex: number, hoverIndex: number) => void;
  data: SceneSimple;
  duration: number;
  originalIndex: number;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

export const SceneCard: FC<CardProps> = ({
  id,
  text,
  index,
  moveCard,
  hoverCard,
  duration,
  data,
  originalIndex,
}) => {
  const relativeValues = {
    ...data,
    startMS: (data.startMS * 100) / duration,
    endMS: (data.endMS * 100) / duration,
  };
  const dispatch = useDispatch();
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop(item: DragItem) {
      if (!ref.current) {
        return;
      }
      const hoverIndex = item.index;
      if (originalIndex === hoverIndex) {
        return;
      }
      moveCard(originalIndex, hoverIndex);
      item.index = hoverIndex;
    },
    hover(item: DragItem) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      hoverCard(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: ItemTypes.CARD,
      item: () => {
        return { id, index, text };
      },
      collect: (monitor: DragSourceMonitor<DragItem, DropResult>) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [text, id, index],
  );
  const renderThumbs = () => {
    // const imgWidth = 100 / data.thumbnailUrls.length;
    return data.thumbnailUrls?.map((thumb) => (
      <img
        style={{
          /* position: 'absolute',
          left: `${imgWidth * index}%`, */
          maxWidth: `${100 / data.thumbnailUrls.length}%`,
        }}
        className={'scene-thumb'}
        src={thumb}
        alt={thumb}
        key={`${thumb}_${data.name}`}
      />
    ));
  };
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  const doubleClickScene = () => {
    dispatch(setScene({ activeScene: id }));
    dispatch(setMode({ mode: ModeEnum.SEQUENCE }));
  };
  return (
    <>
      <div
        onDoubleClick={doubleClickScene}
        ref={ref}
        className={'story-card'}
        style={{
          opacity,
          width: relativeValues!.endMS - relativeValues!.startMS + '%',
          left: relativeValues.startMS + '%',
        }}
        data-handler-id={handlerId}
      >
        <div className={'flex justify-center h-[100%]'}>{renderThumbs()}</div>
        <div className={'absolute top-0 left-0 right-0 bottom-0 mx-auto my-auto h-0'}>{text}</div>
      </div>
    </>
  );
};
