import './style.scss';

import { ChangeEvent, InputHTMLAttributes } from 'react';

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  defaultChecked?: boolean;
}

const Checkbox = ({
  label,
  checked,
  disabled,
  defaultChecked,
  onChange,
  ...props
}: CheckboxProps) => {
  const isDisabled = disabled ? 'disabled' : '';
  return (
    <label className={['container', isDisabled].join(' ')}>
      <input
        type={'checkbox'}
        defaultChecked={defaultChecked}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        {...props}
      />
      <span className="checkmark" />
      {label}
    </label>
  );
};

export default Checkbox;
