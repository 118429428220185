import Keycloak from 'keycloak-js';

const config: Keycloak.KeycloakConfig = {
  realm: 'C9POC',
  clientId: 'frontend',
  url: process.env.REACT_APP_KEYCLOAK_URL,
};

const _kc: Keycloak = new Keycloak(config);
export { _kc, config };
