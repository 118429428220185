import { groupBy } from 'lodash';

import { ForecastDataDef } from '../../../../../model/definitions/ForecastDataDef';
import { ForecastWDElementDef } from '../../../../../model/definitions/ForecastWDElementDef';
import { ForecastItem } from './ForecastItem';

interface ForecastDateProps {
  date: string;
  templateData: Array<ForecastWDElementDef>;
  freshData?: ForecastDataDef;
  offset: number;
  refDate: number;
  setCurrent: (e: ForecastWDElementDef) => void;
  step: 'observed' | 'forecast' | 'add';
  selectedDate?: number;
  zeroDate: number;
  location: string;
  returnedValues: (e: Array<ForecastWDElementDef>) => void;
  id: string;
}
export const ForecastDate = ({
  date,
  templateData,
  offset,
  freshData,
  step,
  zeroDate,
  selectedDate,
  location,
  returnedValues,
  id,
}: ForecastDateProps) => {
  const groupByTimeframe = groupBy(templateData, 'forecastWDSource.daily');
  const renderForecast = () => {
    const arr = [];
    for (const isDaily in groupByTimeframe) {
      arr.push(isDaily === 'true' ? 'DAILY' : 'HOURLY');
      arr.push(
        <ForecastItem
          id={id}
          returnedValues={returnedValues}
          location={location}
          key={JSON.stringify(groupByTimeframe[isDaily])}
          selectedDate={selectedDate}
          zeroDate={zeroDate}
          step={step}
          data={groupByTimeframe[isDaily]}
          isDaily={isDaily}
          offset={offset}
          freshData={freshData}
        />,
      );
    }
    return arr;
  };
  const newDate = new Date(date).getTime() + ((selectedDate ?? 0) - zeroDate);
  return (
    <div>
      <div>{new Date(newDate).toLocaleDateString()}</div>
      <div>{renderForecast()}</div>
    </div>
  );
};
