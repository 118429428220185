import './style.scss';

import { cloneDeep, isEqual } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import Button from '../../../atoms/button/Button';
import { postSatelliteLayer } from '../../../core/api/mapLayers/LayersAPI';
import { fromGeoBoxToBoundsWDLayers } from '../../../helpers/boundsManage';
import {
  DEFAULT_FONT_FAMILY,
  DEFAULT_FONT_TYPE,
  DEFAULT_FONT_VARIANT_ID,
  MAX_FULLSCREEN_HEIGHT,
} from '../../../model/constants/constants';
import { C9ProjectDef } from '../../../model/definitions/C9ProjectDef';
import { ColorPaletteDef } from '../../../model/definitions/ColorPaletteDef';
import { DataFrameDef } from '../../../model/definitions/DataFrameDef';
import { MapPanelDef } from '../../../model/definitions/MapPanelDef';
import { PickerDef } from '../../../model/definitions/PickerDef';
import { PositionControlDef } from '../../../model/definitions/PositionControlDef';
import { SatelliteMapLayer } from '../../../model/definitions/SatelliteMapLayer';
import { FilteringTypeEnum, PostProcessingTypeEnum } from '../../../model/enums/FilteringTypeEnum';
import { InterpolationEnum } from '../../../model/enums/InterpolationEnum';
import { OrientationTypeEnum } from '../../../model/enums/OrientationTypeEnum';
import { transformAbsoluteToPercent } from '../../../molecules/canvasElements/utils';
import { getZindexOfMapLayer } from '../../../molecules/mapElement/helpers';
import Modal from '../../../molecules/modal/Modal';
import { generateSetup } from '../../../molecules/palette/PaletteRefactor';
import { ActiveDef, setDisplayedFlyOvers } from '../../../store/slices/active-slice';
import { updateMapLayer } from '../../../store/slices/project-slice';
import { RootState } from '../../../store/store';
import MapSatelliteLayer from '../properties/mapLayersProperties/MapSatelliteLayer';
import { getFirstLayerRange } from './frameRangeHelpers';

interface Props {
  opened: boolean;
  onClose: () => void;
  mapLayer: MapPanelDef;
  satelliteLayerEdit?: SatelliteMapLayer;
  applyLayer?: boolean;
}

const MapSatelliteLayerModal: FC<Props> = ({
  mapLayer,
  opened,
  onClose,
  satelliteLayerEdit,
  applyLayer,
}) => {
  const { activeScene, activeElement, activeAspectRatio } = useSelector<RootState, ActiveDef>(
    (state) => state.active,
  );
  const project = useSelector<RootState, C9ProjectDef>((state) => state.project.present.project);
  const enterpriseAccountId = project.enterpriseAccountId;
  // const {
  //   keycloak: { idTokenParsed },
  // } = useKeycloak();
  const dispatch = useDispatch();
  const [source, setSource] = useState<PickerDef | undefined>(
    satelliteLayerEdit ? satelliteLayerEdit.satelliteSource.satelliteSource : undefined,
  );
  const [dataProductId, setDataProductId] = useState<string | null>(
    satelliteLayerEdit ? satelliteLayerEdit.satelliteSource.dataProductId : null,
  );
  const [name, setName] = useState<string | undefined>(
    satelliteLayerEdit ? satelliteLayerEdit.name : undefined,
  );
  const [frameLoading, setFrameLoading] = useState(false);

  const firstLayerRange = getFirstLayerRange(mapLayer);
  const selectedFrames: DataFrameDef[] = [];
  if (satelliteLayerEdit && satelliteLayerEdit.dataFrames) {
    selectedFrames.push(...satelliteLayerEdit.dataFrames);
  }
  const [selectedDataFrames, setSelectedDataFrames] = useState<Array<DataFrameDef>>(selectedFrames);
  const [z, setZ] = useState<number>(
    satelliteLayerEdit ? satelliteLayerEdit.zindex : getZindexOfMapLayer(mapLayer),
  );
  const [type, setType] = useState<PickerDef | undefined>(
    satelliteLayerEdit ? satelliteLayerEdit.satelliteSource.satelliteType : undefined,
  );
  const [palette, setPalette] = useState<ColorPaletteDef | null>(
    satelliteLayerEdit ? satelliteLayerEdit.layerSetup.colorPaletteDef : null,
  );
  const transformedBounds = fromGeoBoxToBoundsWDLayers(mapLayer.baseMapSetup.boundingBox);
  const [interpolation, setInterpolation] = useState<InterpolationEnum>(
    satelliteLayerEdit ? satelliteLayerEdit.layerSetup.interpolation : InterpolationEnum.LINEAR,
  );
  const [isContouring, setIsContouring] = useState<boolean>(
    satelliteLayerEdit?.isContouring ?? true,
  );

  const [enableInterpolation, setEnableInterpolation] = useState(
    satelliteLayerEdit ? satelliteLayerEdit.enableInterpolation : true,
  );
  const [framesDensity, setFramesDensity] = useState<number>(
    satelliteLayerEdit ? satelliteLayerEdit.dataFramesDensity : 1,
  );
  const [filteringType, setFilteringType] = useState<FilteringTypeEnum | null>(
    satelliteLayerEdit ? satelliteLayerEdit.layerSetup.preprocessing : null,
  );
  const [postProcessing, setPostProcessing] = useState<PostProcessingTypeEnum | null>(
    satelliteLayerEdit?.layerSetup?.postprocessing
      ? satelliteLayerEdit.layerSetup.postprocessing
      : null,
  );
  const [embossEffect, setEmbossEffect] = useState(
    satelliteLayerEdit ? satelliteLayerEdit.layerSetup.embossEffect : 7,
  );
  const [numberOfIterations, setNumberOfIterations] = useState(
    satelliteLayerEdit ? satelliteLayerEdit.layerSetup.numberOfIterations : 4,
  );
  useEffect(() => {
    if (selectedDataFrames.length === 1) {
      setSelectedDataFrames([...selectedFrames]);
    }
  }, [type, source]);
  function onMapLayerChange(propertyPath: Paths<MapPanelDef>, newValue: SatelliteMapLayer[]) {
    dispatch(
      updateMapLayer({
        activeScene,
        newValue,
        elementId: activeElement,
        propertyPath,
      }),
    );
  }

  function isRelevantDataChanged() {
    if (!satelliteLayerEdit || applyLayer) return true;
    return (
      !isEqual(satelliteLayerEdit.satelliteSource.satelliteSource, source) ||
      !isEqual(satelliteLayerEdit.satelliteSource.satelliteType, type) ||
      !isEqual(satelliteLayerEdit.layerSetup.preprocessing, filteringType) ||
      !isEqual(satelliteLayerEdit.dataFramesDensity, framesDensity) ||
      !isEqual(satelliteLayerEdit.layerSetup.numberOfIterations, numberOfIterations)
    );
  }

  function isIrelevantDataChanged() {
    if (!satelliteLayerEdit) return true;
    return (
      !isEqual(satelliteLayerEdit.dataFrames, selectedDataFrames) ||
      !isEqual(satelliteLayerEdit.name, name) ||
      !isEqual(satelliteLayerEdit.zindex, z) ||
      !isEqual(satelliteLayerEdit.isContouring, isContouring) ||
      !isEqual(satelliteLayerEdit.enableInterpolation, enableInterpolation) ||
      !isEqual(satelliteLayerEdit.layerSetup.colorPaletteDef, palette) ||
      !isEqual(satelliteLayerEdit.layerSetup.postprocessing, postProcessing) ||
      !isEqual(satelliteLayerEdit.layerSetup.embossEffect, embossEffect) ||
      !isEqual(satelliteLayerEdit.layerSetup.interpolation, interpolation)
    );
  }

  function onIrelevantDataChanged() {
    const cloned = cloneDeep(mapLayer.wdSpace[0].satelliteMapLayers);
    const found = cloned.find((l) => l.id === satelliteLayerEdit?.id);
    if (found) {
      found.name = name!;
      found.zindex = Number(z);
      found.isContouring = isContouring;
      found.layerSetup.interpolation = interpolation;
      found.enableInterpolation = enableInterpolation;
      if (palette) {
        found.layerSetup.colorPaletteDef = palette;
        found.layerSetup.paletteLegendScaling = palette.paletteLegendScaling;
      }
      found.layerSetup.postprocessing = postProcessing;
      found.layerSetup.embossEffect = embossEffect;
      found.dataFrames = selectedDataFrames;
      // @ts-ignore
      onMapLayerChange('wdSpace[0].satelliteMapLayers', cloned);
    }
  }

  const addLayer = async () => {
    if (source && type && name) {
      if (!isRelevantDataChanged()) {
        if (isIrelevantDataChanged()) {
          onIrelevantDataChanged();
        }
        return;
      }
      const layerToAdd = new SatelliteMapLayer(
        name,
        dataProductId,
        source,
        type,
        {
          leftLongitude: transformedBounds[0],
          rightLongitude: transformedBounds[1],
          upperLatitude: transformedBounds[2],
          lowerLatitude: transformedBounds[3],
        },
        satelliteLayerEdit ? satelliteLayerEdit.timeControls[0] : mapLayer.timeControls[0],
        selectedDataFrames,
        z,
      );
      layerToAdd.layerType = satelliteLayerEdit ? satelliteLayerEdit.layerType : 'SATELLITE';
      layerToAdd.dataFramesDensity = framesDensity;
      layerToAdd.isContouring = isContouring;
      layerToAdd.layerSetup.interpolation = interpolation;
      layerToAdd.enableInterpolation = enableInterpolation;
      layerToAdd.layerSetup.preprocessing = filteringType;
      layerToAdd.layerSetup.postprocessing = postProcessing;
      layerToAdd.layerSetup.baseAppUrl = process.env.REACT_APP_API_BASE_URL as string;
      layerToAdd.layerSetup.embossEffect = embossEffect;
      layerToAdd.layerSetup.numberOfIterations = numberOfIterations;
      if (palette) {
        layerToAdd.layerSetup.colorPaletteDef = palette;
        layerToAdd.layerSetup.paletteLegendScaling = palette.paletteLegendScaling;
      }
      if (layerToAdd.layerSetup.colorPaletteDef && !layerToAdd.layerSetup.colorPaletteDef.setup)
        layerToAdd.layerSetup.colorPaletteDef = {
          ...layerToAdd.layerSetup.colorPaletteDef,
          setup: generateSetup(palette?.colorStops),
        };
      layerToAdd.opacity = satelliteLayerEdit?.opacity ?? 1;
      layerToAdd.layerSetup.paletteLegendOrientation =
        satelliteLayerEdit?.layerSetup.paletteLegendOrientation ?? OrientationTypeEnum.HORIZONTAL;
      layerToAdd.layerSetup.displayPaletteLegend =
        !!satelliteLayerEdit?.layerSetup.displayPaletteLegend;
      layerToAdd.layerSetup.displayLegendValues =
        satelliteLayerEdit?.layerSetup.displayLegendValues ?? true;
      layerToAdd.layerSetup.legendFontSize = satelliteLayerEdit?.layerSetup.legendFontSize ?? 3;
      layerToAdd.layerSetup.fontFamily =
        satelliteLayerEdit?.layerSetup.fontFamily ?? DEFAULT_FONT_FAMILY;
      layerToAdd.layerSetup.fontType = satelliteLayerEdit?.layerSetup.fontType ?? DEFAULT_FONT_TYPE;
      layerToAdd.layerSetup.fontVariantId =
        satelliteLayerEdit?.layerSetup.fontVariantId ?? DEFAULT_FONT_VARIANT_ID;
      layerToAdd.layerSetup.paletteLegendPositionControl =
        satelliteLayerEdit?.layerSetup.paletteLegendPositionControl ??
        new PositionControlDef(
          transformAbsoluteToPercent(500, activeAspectRatio, 'width', MAX_FULLSCREEN_HEIGHT),
          transformAbsoluteToPercent(60, activeAspectRatio, 'height', MAX_FULLSCREEN_HEIGHT),
          transformAbsoluteToPercent(30, activeAspectRatio, 'width', MAX_FULLSCREEN_HEIGHT),
          transformAbsoluteToPercent(
            MAX_FULLSCREEN_HEIGHT - 80,
            activeAspectRatio,
            'height',
            MAX_FULLSCREEN_HEIGHT,
          ),
        );
      dispatch(setDisplayedFlyOvers({ displayedFlyOver: activeElement! }));
      setFrameLoading(true);
      try {
        const addedLayer = await postSatelliteLayer(layerToAdd, mapLayer.baseMapSetup.id);
        const existingLayers = satelliteLayerEdit
          ? mapLayer.wdSpace[0].satelliteMapLayers.filter((l) => l.id !== satelliteLayerEdit.id)
          : mapLayer.wdSpace[0].satelliteMapLayers;
        // @ts-ignore
        onMapLayerChange('wdSpace[0].satelliteMapLayers', [...existingLayers, addedLayer]);
      } catch (e) {
        setFrameLoading(false);
        toast.error('Error adding layer!');
        console.error(e);
      }
    }
  };

  async function handleAddLayer() {
    await addLayer();
    onClose();
  }

  return (
    <Modal
      isOpen={opened}
      onClose={onClose}
      className="add-layers-wrapper"
      header={'Set layers'}
      footer={
        <div className={'modal-footer'}>
          <Button
            disabled={
              !(
                name &&
                source &&
                (palette || !type?.name.includes('INFRARED')) &&
                selectedDataFrames.length
              )
            }
            label={satelliteLayerEdit && !applyLayer ? 'Apply changes' : 'Add layer'}
            buttonType="primary"
            loading={frameLoading}
            onClick={handleAddLayer}
          />
        </div>
      }
    >
      <div className="add-layers-body">
        <MapSatelliteLayer
          oldLayer={satelliteLayerEdit && !dataProductId ? true : false}
          dataProductId={dataProductId}
          setDataProductId={setDataProductId}
          enterpriseAccountId={enterpriseAccountId}
          embossEffect={embossEffect}
          setEmbossEffect={setEmbossEffect}
          numberOfIterations={numberOfIterations}
          setNumberOfIterations={setNumberOfIterations}
          setPostProcessingType={setPostProcessing}
          postProcessing={postProcessing}
          name={name}
          setName={setName}
          type={type}
          setType={setType}
          source={source}
          setSource={setSource}
          selectedDataFrames={selectedDataFrames}
          setSelectedDataFrames={setSelectedDataFrames}
          z={z}
          setZ={setZ}
          bounds={transformedBounds}
          mapLayer={mapLayer}
          onMapLayerChange={onMapLayerChange}
          framesDensity={framesDensity}
          setFramesDensity={setFramesDensity}
          isContouring={isContouring}
          setIsContouring={setIsContouring}
          interpolation={interpolation}
          setInterpolation={setInterpolation}
          enableInterpolation={enableInterpolation}
          setEnableInterpolation={setEnableInterpolation}
          filteringType={filteringType}
          setFilteringType={setFilteringType}
          palette={palette}
          setPalette={(pal) => {
            if (pal)
              if (pal?.colorStops.interval != null) {
                setPalette(pal);
              } else {
                const val = {
                  ...pal,
                  colorStops: {
                    ...pal.colorStops,
                    interval:
                      palette?.colorStops?.interval != null ? palette.colorStops.interval : 2,
                  },
                };
                setPalette(val);
              }
          }}
          satelliteLayerEdit={satelliteLayerEdit}
          rangeFromFirstLayer={firstLayerRange}
        />
      </div>
    </Modal>
  );
};

export default MapSatelliteLayerModal;
