import { ForecastValueDef } from '../definitions/ForecastPropertiesDef';
import { PointDataSourceHistoryDef } from '../definitions/PointDataSourceHistoryDef';
import { ValueTypeEnum } from '../enums/ValueTypeEnum';

export class ForecastElementRequestDTO {
  name: string;
  description: string;
  datetime: number | null = null;
  utcDate: string;
  localDate: string;
  parameterType: string;
  unitOfMeasurement: string;
  value: Array<{ unit: string; value: number }> | undefined;
  originalValue: number;
  valueType: ValueTypeEnum;
  dayTime: boolean;
  intraTime: boolean;
  daily: boolean;
  valueHistory: Array<PointDataSourceHistoryDef> = [];
  constructor(
    name: string,
    description: string,
    parameterType: string,
    properties: ForecastValueDef,
    unit: string,
    valueType: ValueTypeEnum,
    dayTime: boolean,
  ) {
    //const value = properties.properties.find((prop) => prop.name === parameterType)?.values; //.find((val) => val.unit === unit)?.value ?? 0;
    /* const originalValue =
      properties.properties
        .find((prop) => prop.name === parameterType)
        ?.values.find((val) => val.unit === unit)?.originalValue ?? 0; */
    if (name && parameterType)
      this.name =
        name +
        ' ' +
        parameterType +
        ' ' +
        (dayTime
          ? 'daily'
          : new Date(properties.utcDate).toLocaleTimeString(undefined, {
              hour: '2-digit',
              minute: '2-digit',
              timeZone: 'UTC',
            }));
    if (description) this.description = description;
    this.utcDate = properties.utcDate;
    this.localDate = properties.localDate;
    this.unitOfMeasurement = unit;
    this.parameterType = parameterType;
    this.originalValue =
      properties.properties
        .find((prop) => prop.name === parameterType)
        ?.values.find((val) => val.unit === unit)?.originalValue ?? 0;
    this.value = properties.properties.find((prop) => prop.name === parameterType)?.values; //.find((val) => val.unit === unit)?.value ?? 0;
    this.valueType = valueType;
    if (dayTime) {
      this.daily = true;
      this.intraTime = false;
    } else {
      this.daily = false;
      this.intraTime = true;
    }
  }
}
