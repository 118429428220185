import { ValueTypeEnum } from '../enums/ValueTypeEnum';
import { Station } from '../other/ObservedData';
import { ObservedWDStationDef } from './ObservedWDStationDef';
import { PointWDElementDef } from './PointWDElementDef';

export class ObservedWDSourceDef extends PointWDElementDef {
  station: ObservedWDStationDef;
  changedValue?: number;
  constructor(
    time?: number,
    param?: string,
    unit?: string,
    valueType?: ValueTypeEnum,
    stat?: Station,
    value?: any | number,
  ) {
    super();
    if (time) this.datetime = time;
    if (param) this.parameterType = param;
    if (unit) this.unitOfMeasurement = unit;
    if (valueType) this.valueType = valueType;
    if (value) this.value = value;
    if (stat?.fullName && stat.value && stat.longitude && stat.latitude)
      this.station = new ObservedWDStationDef(
        stat?.fullName,
        stat?.value,
        stat?.longitude,
        stat?.latitude,
      );
  }
}
