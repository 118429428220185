import React, { FC } from 'react';
import { useDispatch } from 'react-redux';

import { SceneDef } from '../../../../model/definitions/SceneDef';
import { TimeControlDef } from '../../../../model/definitions/TimeControlDef';
import { AnimationsEnum } from '../../../../model/enums/AnimationsEnum';
import { editSceneTransition } from '../../../../store/slices/project-slice';
import { PropertySection } from '../components/PropertySection';
import GridWrapper from '../shared/GridWrapper';
import { AnimationPicker } from './panelElements/AnimationPicker';

interface SceneTransitionPropertiesProps {
  scenes: Array<SceneDef>;
  frameRate: number;
}
export const SceneTransitionProperties: FC<SceneTransitionPropertiesProps> = ({
  scenes,
  frameRate,
}) => {
  const dispatch = useDispatch();
  const changeSceneTransition = (
    e: number | AnimationsEnum,
    name: keyof TimeControlDef,
    sceneId: string,
    transitions: TimeControlDef,
  ) => {
    if (transitions && sceneId) {
      if (e === AnimationsEnum.CUT && name.toString().includes('inAnimation')) {
        dispatch(
          editSceneTransition({
            time: {
              ...transitions,
              [name]: e,
              inAnimationDuration: 0,
            },
            activeScene: sceneId,
          }),
        );
        return;
      } else if (e === AnimationsEnum.CUT && name.toString().includes('outAnimation')) {
        dispatch(
          editSceneTransition({
            time: {
              ...transitions,
              [name]: e,
              outAnimationDuration: 0,
            },
            activeScene: sceneId,
          }),
        );
        return;
      } else if (e === AnimationsEnum.FADE_OUT || e === AnimationsEnum.FADE_IN) {
        dispatch(
          editSceneTransition({
            time: {
              ...transitions,
              [name]: e,
              outAnimationDuration:
                e === AnimationsEnum.FADE_OUT ? 500 : transitions.outAnimationDuration,
              inAnimationDuration:
                e === AnimationsEnum.FADE_IN ? 500 : transitions.inAnimationDuration,
            },
            activeScene: sceneId,
          }),
        );
        return;
      } else {
        dispatch(
          editSceneTransition({
            time: {
              ...transitions,
              [name]: e,
            },
            activeScene: sceneId,
          }),
        );
        return;
      }
    }
  };
  const renderSceneTransitions = () => {
    return scenes.map((scene) => (
      <React.Fragment key={scene.id}>
        <div>{scene.name}</div>
        <GridWrapper>
          <AnimationPicker
            timeControls={scene.timeControl}
            onChange={(value, name) =>
              changeSceneTransition(value, name, scene.id, scene.timeControl)
            }
            maxVal={frameRate * 2}
          />
        </GridWrapper>
      </React.Fragment>
    ));
  };
  return (
    <PropertySection label={'Transitions of scenes'} isOpened={false}>
      <>{renderSceneTransitions()}</>
    </PropertySection>
  );
};
