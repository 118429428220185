import { Select } from 'flowbite-react';
import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';

import Checkbox from '../../../../atoms/checkbox/Checkbox';
import Input from '../../../../atoms/input/Input';
import { useGetRadarSource } from '../../../../core/api/mapLayers/useGetRadarSource';
import { useGetRadarValue } from '../../../../core/api/mapLayers/useGetRadarValue';
import { MAX_ZINDEX_VALUE } from '../../../../model/constants/constants';
import { ColorPaletteDef } from '../../../../model/definitions/ColorPaletteDef';
import { DataFrameDef } from '../../../../model/definitions/DataFrameDef';
import { MapPanelDef } from '../../../../model/definitions/MapPanelDef';
import { PickerDef } from '../../../../model/definitions/PickerDef';
import { RadarMapLayer } from '../../../../model/definitions/RadarMapLayer';
import { ColorPaletteParamTypeEnum } from '../../../../model/enums/ColorPaletteParamTypeEnum';
import {
  FilteringTypeEnum,
  PostProcessingTypeEnum,
} from '../../../../model/enums/FilteringTypeEnum';
import { InterpolationEnum } from '../../../../model/enums/InterpolationEnum';
import { filterFramesByTimeWindow } from '../../../../molecules/mapElement/helpers';
import { PaletteRefactor } from '../../../../molecules/palette/PaletteRefactor';
import InputNumber from '../../../marketplace-new/atoms/FormatNumber/FormatNumber';
import { FirstLayerRange } from '../../modals/frameRangeHelpers';
import { FramesRange } from '../../modals/FramesRange';
import styles from '../Properties.module.scss';
import { CustomPalette } from './CustomPalette';

interface MapLayersProps {
  oldLayer: boolean;
  dataProductId: string | null;
  setDataProductId: (e: string) => void;
  bounds: [number, number, number, number];
  name?: string;
  setName: (e: string) => void;
  mapLayer: MapPanelDef;
  onMapLayerChange: (propertyPath: keyof MapPanelDef, newValue: RadarMapLayer[]) => void;
  source: PickerDef | undefined;
  setSource: (e?: PickerDef) => void;
  selectedDataFrames: Array<DataFrameDef>;
  setSelectedDataFrames: (e: Array<DataFrameDef>) => void;
  z: number;
  setZ: (e: number) => void;
  interpolation: InterpolationEnum;
  filteringType: FilteringTypeEnum | null;
  setInterpolation: Dispatch<SetStateAction<InterpolationEnum>>;
  setFilteringType: Dispatch<SetStateAction<FilteringTypeEnum | null>>;
  palette: ColorPaletteDef | null;
  setPalette: (palette: ColorPaletteDef) => void;
  radarLayerEdit?: RadarMapLayer;
  rangeFromFirstLayer?: FirstLayerRange | null;
  embossEffect: number;
  setEmbossEffect: (e: number) => void;
  numberOfIterations: number;
  setNumberOfIterations: (e: number) => void;
  postProcessing: PostProcessingTypeEnum | null;
  setPostProcessingType: React.Dispatch<React.SetStateAction<PostProcessingTypeEnum | null>>;
  setFramesDensity: React.Dispatch<React.SetStateAction<number>>;
  framesDensity: number;
  enterpriseAccountId: string;
  isContouring: boolean;
  setIsContouring: React.Dispatch<React.SetStateAction<boolean>>;
}
const MapRadarLayers = ({
  oldLayer,
  dataProductId,
  setDataProductId,
  bounds,
  source,
  name,
  setName,
  setSource,
  selectedDataFrames,
  setSelectedDataFrames,
  z,
  setZ,
  filteringType,
  interpolation,
  setFilteringType,
  setInterpolation,
  setPalette,
  palette,
  radarLayerEdit,
  rangeFromFirstLayer,
  embossEffect,
  setEmbossEffect,
  numberOfIterations,
  setNumberOfIterations,
  postProcessing,
  setPostProcessingType,
  framesDensity,
  setFramesDensity,
  enterpriseAccountId,
  isContouring,
  setIsContouring,
}: MapLayersProps) => {
  const { data: sourceData, isLoading: loadingSource } = useGetRadarSource(bounds, oldLayer);
  const {
    mutate: getValue,
    data: radarValues,
    isLoading: loadingValue,
  } = useGetRadarValue(oldLayer);
  const [editPalette, setEditPalette] = useState<string | undefined>(undefined);
  const selectedRangeValue = useMemo(() => {
    if (!radarLayerEdit || !radarValues?.length || !radarLayerEdit?.dataFrames) return [0, 0];
    const [firstFrame] = radarLayerEdit.dataFrames;
    if (!firstFrame) return [0, 0];
    const indexOfFirstFrame = radarValues!
      .sort((a, b) => a.timestamp - b.timestamp)
      .findIndex((fr) => fr.frameId === firstFrame.frameId);
    return [indexOfFirstFrame, indexOfFirstFrame + radarLayerEdit.dataFrames.length];
  }, [radarLayerEdit, radarValues]);

  const framesWithDensity = useMemo(() => {
    if (!framesDensity) return radarValues ?? [];
    else return filterFramesByTimeWindow(radarValues || [], framesDensity, selectedDataFrames[0]);
  }, [framesDensity, radarValues]);

  useEffect(() => {
    if (bounds.length) {
      if (oldLayer) getValue({ sourceId: source!.id, location: bounds });
      if (radarLayerEdit && dataProductId) getValue({ dataProductId, location: bounds });
    }
  }, [bounds.length]);
  const onParamChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = Number(e.target.value);
    const selectedParam = sourceData?.find((param) => param.id === value);

    if (selectedParam?.dataProductId) {
      getValue({
        dataProductId: selectedParam.dataProductId,
        location: bounds,
      });
      setDataProductId(selectedParam.dataProductId);
    } else {
      if (value >= 0) {
        value &&
          getValue({
            sourceId: value,
            location: bounds,
          });
      }
    }

    setSource(selectedParam);
    if (selectedParam) {
      setName(`Radar ${selectedParam.name}`);
    }
  };
  const onInterpolationChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setInterpolation(e.target.value as InterpolationEnum);
  };
  const onFilteringTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setFilteringType(e.target.value !== 'null' ? (e.target.value as FilteringTypeEnum) : null);
  };
  function onHourlyChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.checked) {
      setFramesDensity(1);
    } else {
      setFramesDensity(0);
    }
  }
  const radarSourceSelect = () => (
    <Select
      className={'w-full'}
      style={{ width: '100%' }}
      onChange={onParamChange}
      value={source?.id}
      required
    >
      <option value={-1}>
        {sourceData?.length === 0 ? 'No sources available' : 'Select source'}
      </option>
      {sourceData
        ?.sort((a, b) => (a.id > b.id ? 1 : -1))
        .map((k: PickerDef) => (
          <option key={k.id} value={k.id}>
            {k.name}
          </option>
        ))}
    </Select>
  );

  const useHourlyValues = (
    <div className="relative flex flex-col">
      <Checkbox
        checked={framesDensity > 0}
        label="Time step granularity"
        onChange={onHourlyChange}
      />
      {!framesDensity && (
        <small className=" absolute top-5 left-[0px] text-red-500">
          *A high number of data time steps may lower rendering performance
        </small>
      )}
    </div>
  );

  const interpolationSelect = (
    <Select
      className={'w-full'}
      style={{ width: '100%' }}
      onChange={onInterpolationChange}
      value={interpolation}
      required
    >
      {Object.entries(InterpolationEnum).map(([k, v]) => (
        <option key={k} value={v}>
          {k}
        </option>
      ))}
    </Select>
  );
  const onPostProcessingTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setPostProcessingType(
      e.target.value !== 'null' ? (e.target.value as PostProcessingTypeEnum) : null,
    );
  };
  const postProcessingSelect = (
    <Select
      className={'w-full'}
      style={{ width: '100%' }}
      onChange={onPostProcessingTypeChange}
      value={postProcessing ?? 'null'}
      required
    >
      <option value={''}>No post processing</option>
      {Object.entries(PostProcessingTypeEnum).map(([k, v]) => {
        return (
          <option key={k} value={v}>
            {k}
          </option>
        );
      })}
    </Select>
  );
  const filteringTypeSelect = (
    <Select
      className={'w-full'}
      style={{ width: '100%' }}
      onChange={onFilteringTypeChange}
      value={filteringType ?? 'null'}
      required
    >
      <option key={'no-val'} value={'null'}>
        No filtering
      </option>
      {Object.entries(FilteringTypeEnum).map(([k, v]) => (
        <option key={k} value={v}>
          {k}
        </option>
      ))}
    </Select>
  );
  const onEnableContouringChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsContouring(e.target.checked);
  };
  return (
    <>
      <h3>Radar Layer</h3>
      <div className="layers-wrap">
        <div className="layer-item-flex-wrapper">
          <div className="layer-item">
            <label>
              <span>*</span> Radar source
            </label>
            {loadingSource ? <div className={'loading'}>Loading sources</div> : radarSourceSelect()}
          </div>
          <div className="layer-item">
            <label>
              <span>*</span> Layer name
            </label>
            <Input
              placeholder="Type radar layer name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="layer-item-flex-wrapper">
          <div className="layer-item">
            <label>
              <span>*</span> Layer level
            </label>
            <div className="grid-item">
              <InputNumber
                value={z}
                onInputChange={(e) => setZ(e)}
                className={styles.inputWrap}
                required
                min={0}
                max={MAX_ZINDEX_VALUE}
                step={1}
              />
            </div>
          </div>
          <div className="layer-item">
            <label>
              Frames granularity
              <Select
                value={framesDensity ?? 0}
                onChange={(e) => setFramesDensity(Number(e.target.value))}
              >
                <option value={0}>original</option>
                <option value={1}>1h</option>
                <option value={2}>2h</option>
                <option value={6}>6h</option>
                <option value={12}>12h</option>
                <option value={24}>24h</option>
                <option value={168}>7 days</option>
              </Select>
            </label>
          </div>
        </div>
        <div className="layer-item flex justify-end my-4 gap-4">
          {useHourlyValues}{' '}
          <Checkbox
            checked={isContouring}
            onChange={onEnableContouringChange}
            label="Enable contouring"
          />
        </div>
        {source && (
          <>
            {loadingValue ? (
              <div className={'loading my-[70px] text-center'}>Loading available frames</div>
            ) : (
              radarValues && (
                <FramesRange
                  maxDays={2}
                  layerType={'radar'}
                  frames={framesWithDensity}
                  onRangeChange={setSelectedDataFrames}
                  selectedFrames={selectedDataFrames}
                  layerEdit={radarLayerEdit}
                  defaultValue={radarLayerEdit ? selectedRangeValue : undefined}
                  show={!!rangeFromFirstLayer}
                  rangeFromFirstLayer={rangeFromFirstLayer}
                  framesDensity={framesDensity}
                />
              )
            )}
            <div className="layer-item-flex-wrapper modal-palette">
              <PaletteRefactor
                parameter={ColorPaletteParamTypeEnum.RADAR}
                value={palette}
                setPalette={setPalette}
                layerEdit={radarLayerEdit}
                editPalette={setEditPalette}
                source={source}
                dataProductId={dataProductId}
                enterpriseAccountId={enterpriseAccountId}
                width={1000}
              />
            </div>
            <CustomPalette
              onOk={(pal) => {
                const val = {
                  ...pal,
                  colorStops: {
                    ...pal.colorStops,
                    interval:
                      palette?.colorStops?.interval != null ? palette?.colorStops?.interval : null,
                  },
                };
                setPalette(val);
              }}
              paletteParamType={ColorPaletteParamTypeEnum.RADAR}
              paletteToEdit={editPalette}
              setPaletteToEdit={(e) => {
                setEditPalette(e);
              }}
              source={source}
            />
            <div className="layer-item-flex-wrapper">
              <div className="layer-item">
                <label>Color scale interpolation</label>
                {interpolationSelect}
              </div>
              <div className="layer-item"></div>
            </div>
            <div className="layer-item-flex-wrapper">
              <div className="layer-item">
                <label>Filtering type</label>
                {filteringTypeSelect}
              </div>
              {filteringType === FilteringTypeEnum.LOW_BANDPASS ? (
                <div className="layer-item">
                  <label>Number of Iterations</label>
                  <InputNumber
                    value={numberOfIterations}
                    max={100}
                    min={0}
                    onInputChange={(e) => {
                      const val = e >= 0 && e <= 100 ? e : numberOfIterations;
                      setNumberOfIterations(val);
                    }}
                  />
                </div>
              ) : (
                <div className="layer-item"></div>
              )}
            </div>
            <div className="layer-item-flex-wrapper">
              <div className="layer-item">
                <label>Post-processing</label>
                {postProcessingSelect}
              </div>
              {postProcessing === PostProcessingTypeEnum.EMBOSS ? (
                <div className="layer-item">
                  <label>Emboss effect</label>
                  <InputNumber
                    value={embossEffect}
                    max={100}
                    min={0}
                    onInputChange={(e) => {
                      const val = e >= 0 && e <= 100 ? e : embossEffect;
                      setEmbossEffect(val);
                    }}
                  />
                </div>
              ) : (
                <div className="layer-item"></div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default MapRadarLayers;
