import { Button, Tour, TourProps } from 'antd';
import { useRef, useState } from 'react';

import Card from '../atoms/Card';

function DataProvider() {
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);

  const [open, setOpen] = useState<boolean>(false);

  const steps: TourProps['steps'] = [
    {
      title: 'Data Provider Products Preview',
      description: 'Manage your products',
      target: () => ref1.current,
    },
    {
      title: 'Register New Data Product',
      description: 'Add new data product',
      target: () => ref2.current,
    },
    {
      title: 'Data Provider Profile',
      description: 'See profile details and edit them',
      target: () => ref3.current,
    },
  ];

  const pages = [
    {
      ref: ref1,
      title: 'Data Provider Products Preview',
      description: 'Here you can manage all your products',
      image: '',
      link: './products',
    },
    {
      ref: ref2,
      title: 'Register New Data Product',
      description: 'Here you can add new data product',
      image: '',
      link: './product-registration',
    },
    {
      ref: ref3,
      title: 'Data Provider Profile',
      description: 'Here you can manage your profile',
      image: '',
      link: './profile',
    },
  ];
  return (
    <>
      <div className="flex justify-between">
        <h1>Data Provider</h1>
        <Button type="primary" onClick={() => setOpen(true)}>
          Begin Tour
        </Button>
      </div>
      <div className="flex h-[calc(100vh-208px)] justify-center items-center">
        <div className="flex gap-20">
          {pages.map((page) => {
            return <Card key={page.link} {...page} />;
          })}
        </div>
        <Tour open={open} onClose={() => setOpen(false)} steps={steps} />
      </div>
    </>
  );
}

export default DataProvider;
