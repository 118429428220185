import { useQuery } from 'react-query';

import { axiosInstance } from '../../../core/api/axiosInstance';

const key = ['SEQUENCE_ZIP'];
export const useGetSequenceZip = (id?: string, isSequence?: boolean) =>
  useQuery(
    [...key, id],
    async () => {
      const { data } = await axiosInstance.get(`multimedia/download/${id}`, {
        responseType: 'arraybuffer',
      });
      return data;
    },
    {
      enabled: !!id,
      cacheTime: 10 * 60 * 1000,
      staleTime: Infinity,
      keepPreviousData: true,
    },
  );
