import { InputNumber } from 'antd';
import { Select, ToggleSwitch } from 'flowbite-react';
import { useDispatch, useSelector } from 'react-redux';

import { useFontLoader, useFontSetter } from '../../../core/api/useLoadFont';
import { usePropertyGridActive } from '../../../hooks/usePropertyGridActive';
import { DEFAULT_FONT_FAMILY, MAX_FULLSCREEN_HEIGHT } from '../../../model/constants/constants';
import { WeatherDataMapLayerSetup } from '../../../model/definitions/WeatherDataMapLayerSetup';
import { OrientationTypeEnum } from '../../../model/enums/OrientationTypeEnum';
import { PaletteLegendScalingEnum } from '../../../model/enums/PaletteLegendScalingEnum';
import {
  transformAbsoluteToPercent,
  transformPercentToAbsolute,
} from '../../../molecules/canvasElements/utils';
import { ActiveDef } from '../../../store/slices/active-slice';
import { editLayerLegend } from '../../../store/slices/project-slice';
import { selectActiveLegend } from '../../../store/slices/selectors';
import { RootState } from '../../../store/store';
import { Panel } from './components/Panel';
import { PropertySection } from './components/PropertySection';
import { FontInterface, FontVariantInterface } from './panels/FontProperties';
import styles from './Properties.module.scss';
import GridItem from './shared/GridItem';
import GridWrapper from './shared/GridWrapper';

export const LayerLegendProperties = () => {
  const { activeScene, activeMap, activeAspectRatio } = useSelector<RootState, ActiveDef>(
    (state) => state.active,
  );
  const dispatch = useDispatch();
  const legend = useSelector<RootState, WeatherDataMapLayerSetup | null | undefined>((state) =>
    selectActiveLegend(state),
  );
  const { fonts, fontVariants, fetchFontVariants } = useFontLoader(
    legend?.fontFamily || DEFAULT_FONT_FAMILY,
  );

  useFontSetter(
    fontVariants,
    legend?.fontType,
    fontTypeSetter,
    fontVariantIdSetter,
    fetchFontVariants.isLoading,
  );

  function fontTypeSetter(value: string) {
    changeSetup('fontType', value);
  }

  function fontVariantIdSetter(value: string) {
    changeSetup('fontVariantId', value);
  }
  const position = legend?.paletteLegendPositionControl;
  const changeSetup = (propertyPath: Leaves<WeatherDataMapLayerSetup>, value: any) => {
    legend &&
      dispatch(
        editLayerLegend({
          activeScene,
          mapId: activeMap,
          legendId: legend.id,
          propertyPath: propertyPath,
          value,
        }),
      );
  };
  const changePosition = (prop: string, value: number | null) => {
    legend &&
      //@ts-ignore
      changeSetup('paletteLegendPositionControl', {
        ...legend.paletteLegendPositionControl,
        [prop]: value,
      });
  };
  const changeOrientation = () => {
    if (position && legend) {
      const w = transformAbsoluteToPercent(
        transformPercentToAbsolute(position.h, activeAspectRatio, 'height', MAX_FULLSCREEN_HEIGHT),
        activeAspectRatio,
        'width',
        MAX_FULLSCREEN_HEIGHT,
      );
      const h = transformAbsoluteToPercent(
        transformPercentToAbsolute(position.w, activeAspectRatio, 'width', MAX_FULLSCREEN_HEIGHT),
        activeAspectRatio,
        'height',
        MAX_FULLSCREEN_HEIGHT,
      );
      //@ts-ignore
      changeSetup('paletteLegendPositionControl', { ...legend.paletteLegendPositionControl, w, h });
    }
  };
  const { isOpened } = usePropertyGridActive(['position']);
  return (
    <Panel>
      <PropertySection label={'Setup'} isOpened={isOpened}>
        <GridWrapper className="color-palette-wrapper my-5">
          <GridItem
            noBorderBg
            label="Orientation:"
            item={
              <>
                <label
                  style={{
                    opacity: `${
                      legend?.paletteLegendOrientation === OrientationTypeEnum.HORIZONTAL
                        ? '1'
                        : '0.3'
                    }`,
                    marginRight: '10px',
                    marginLeft: 0,
                  }}
                  className="hor-ver-switcher"
                >
                  Horizontal
                </label>
                <ToggleSwitch
                  checked={legend?.paletteLegendOrientation === OrientationTypeEnum.VERTICAL}
                  label={''}
                  onChange={(e) => {
                    changeOrientation();
                    changeSetup(
                      'paletteLegendOrientation',
                      e ? OrientationTypeEnum.VERTICAL : OrientationTypeEnum.HORIZONTAL,
                    );
                  }}
                />
                <label
                  style={{
                    textAlign: 'left',
                    opacity: `${
                      legend?.paletteLegendOrientation === OrientationTypeEnum.VERTICAL
                        ? '1'
                        : '0.3'
                    }`,
                  }}
                >
                  Vertical
                </label>
              </>
            }
          />
          <GridItem
            noBorderBg
            label="Scaling:"
            item={
              <>
                <label
                  style={{
                    opacity: `${
                      legend?.paletteLegendScaling === PaletteLegendScalingEnum.HOMOGENOUS
                        ? '1'
                        : '0.3'
                    }`,
                  }}
                  className="hor-ver-switcher ml-0 mr-5"
                >
                  Homogenous
                </label>
                <ToggleSwitch
                  checked={legend?.paletteLegendScaling === PaletteLegendScalingEnum.PROPORTIONAL}
                  label={''}
                  onChange={(e) => {
                    changeSetup(
                      'paletteLegendScaling',
                      e
                        ? PaletteLegendScalingEnum.PROPORTIONAL
                        : PaletteLegendScalingEnum.HOMOGENOUS,
                    );
                  }}
                />
                <label
                  style={{
                    textAlign: 'left',
                    opacity: `${
                      legend?.paletteLegendScaling === PaletteLegendScalingEnum.PROPORTIONAL
                        ? '1'
                        : '0.3'
                    }`,
                  }}
                >
                  Proportional
                </label>
              </>
            }
          />
          <GridItem
            noBorderBg
            label="Legend values:"
            item={
              <ToggleSwitch
                checked={!!legend?.displayLegendValues}
                label={'legend values'}
                onChange={(e) => changeSetup('displayLegendValues', e)}
              />
            }
          />
          <GridItem
            noBorderBg
            label="Font size:"
            item={
              <InputNumber
                value={legend?.legendFontSize ?? 100}
                min={0}
                max={100}
                onChange={(e) => typeof e === 'number' && e > 0 && changeSetup('legendFontSize', e)}
              />
            }
          />
          <GridItem
            label="Font family:"
            item={
              <Select
                className={styles.select}
                onChange={(e) => {
                  changeSetup('fontFamily', e.target.value);
                  changeSetup('fontType', '');
                  changeSetup('fontVariantId', '');
                }}
                value={legend?.fontFamily}
              >
                {fonts?.map((font: FontInterface) => (
                  <option
                    key={`${font.name}_${legend?.id}`}
                    value={font.name.split('(')[0]}
                    style={{ fontFamily: font.name.split('(')[0] }}
                  >
                    {font.name}
                  </option>
                ))}
              </Select>
            }
          />
          <GridItem
            label="Font type:"
            item={
              <select
                className={styles.select}
                value={legend?.fontType}
                onChange={(e) => {
                  const findType = fontVariants.find(
                    (fontVariant: FontVariantInterface) => fontVariant.type === e.target.value,
                  );
                  changeSetup('fontType', e.target.value);
                  changeSetup('fontVariantId', findType.id);
                }}
              >
                {Array.isArray(fontVariants) &&
                  fontVariants?.map((fontVariant: FontVariantInterface) => (
                    <option key={`${fontVariant.id}_${legend?.id}`} value={fontVariant.type}>
                      {fontVariant.type}
                    </option>
                  ))}
              </select>
            }
          />
        </GridWrapper>
      </PropertySection>
      <PropertySection label={'position'} isOpened={isOpened}>
        <GridWrapper className="color-palette-wrapper my-5 px-5">
          <GridItem
            label={'Width:'}
            item={
              <InputNumber
                className={'w-full bg-transparent border-0'}
                value={legend?.paletteLegendPositionControl?.w}
                onChange={(e) => changePosition('w', e)}
              />
            }
          />
          <GridItem
            label={'Height:'}
            item={
              <InputNumber
                className={'w-full bg-transparent border-0'}
                value={legend?.paletteLegendPositionControl?.h}
                onChange={(e) => changePosition('h', e)}
              />
            }
          />
          <GridItem
            label={'Left:'}
            item={
              <InputNumber
                className={'w-full bg-transparent border-0'}
                value={legend?.paletteLegendPositionControl?.x}
                onChange={(e) => changePosition('x', e)}
              />
            }
          />
          <GridItem
            label={'Top:'}
            item={
              <InputNumber
                className={'w-full bg-transparent border-0'}
                value={legend?.paletteLegendPositionControl?.y}
                onChange={(e) => changePosition('y', e)}
              />
            }
          />
          <GridItem
            label={'Layer level:'}
            item={
              <InputNumber
                className={'w-full bg-transparent border-0'}
                value={legend?.paletteLegendPositionControl?.zindex}
                onChange={(e) => changePosition('zindex', e)}
              />
            }
          />
          <GridItem
            label={'Rotation:'}
            item={
              <InputNumber
                className={'w-full bg-transparent border-0'}
                value={legend?.paletteLegendPositionControl?.rotation}
                onChange={(e) => changePosition('rotation', e)}
              />
            }
          />
        </GridWrapper>
      </PropertySection>
      <PropertySection label={'description'} isOpened={isOpened}>
        <GridWrapper className="color-palette-wrapper my-5">
          <GridItem noBorderBg label={'Name:'} item={legend?.colorPaletteDef?.name} />
          <GridItem noBorderBg label={'Description:'} item={legend?.colorPaletteDef?.description} />
          <GridItem
            noBorderBg
            label={'Visualisation:'}
            item={legend?.colorPaletteDef?.visualisationType}
          />
          <GridItem
            noBorderBg
            label={'Parameter Type:'}
            item={legend?.colorPaletteDef?.paletteParamType}
          />
        </GridWrapper>
      </PropertySection>
    </Panel>
  );
};
