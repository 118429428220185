import './Footer.scss';

import Copyright from '../../assets/images/Footer/Copyright.png';
import FooterImage from '../../assets/images/Footer/FooterLogo.png';
import Link from '../../assets/images/Footer/Link.png';
import SocialMedia from '../../atoms/SocialMedia/SocialMedia';
import { AntFooter } from '../../layout/Layout';
import { createListConfig, supportListConfig } from './config';
import FooterList from './FooterList/FooterList';

const Footer = () => {
  return (
    <AntFooter>
      <div className="footer-wrapper">
        <img src={FooterImage} alt="" />
      </div>
      <div className="lists">
        <FooterList title={'Create'} list={createListConfig} />
        <FooterList title={'Tools'} list={createListConfig} />
        <FooterList title={'About'} list={createListConfig} />
        <FooterList title={'Support'} list={supportListConfig} />
      </div>
      <div className="bottom-wrapper">
        <div className="flex">
          <img src={Copyright} alt="" />
          <img src={Link} alt="" />
        </div>
        <SocialMedia />
      </div>
    </AntFooter>
  );
};

export default Footer;
