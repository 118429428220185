import { C9Object } from './C9Object';
import { TimeControlDef } from './TimeControlDef';

export class DrawingDef extends C9Object {
  versionId: string;
  timeControls: TimeControlDef[];
  drawingGeoJson: string;
  enabled = true;

  keyframes: DrawingKeyframeDef[] = [];
}

export interface DrawingKeyframeDef {
  geoJson: string;
  startTime: number;
  timestamp: number;
}
