import { useQuery } from 'react-query';

import { axiosInstance } from '../../../core/api/axiosInstance';

export const key = ['ACCOUNT_ROLES'];
export const useGetRoles = () => {
  return useQuery(key, async () => {
    const res = await axiosInstance.get('/keycloak/assigned-roles');
    return res.data;
  });
};
