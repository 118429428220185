import { useQuery } from 'react-query';

import { axiosInstance } from '../../axiosInstance';

const key = ['ORDERED_ICON_SET'];
export const useGetOrderedIconSet = (version: string) =>
  useQuery(
    [...key, version],
    async () => {
      const { data } = await axiosInstance.get(
        `icon-set-template/ordered-icons-by-set?iconSetVersionId=${version}`,
      );
      return data;
    },
    {
      enabled: !!version,
      cacheTime: Infinity,
      staleTime: Infinity,
    },
  );
