import { useQuery } from 'react-query';

import { axiosInstancePublic } from '../../../core/api/axiosInstancePublic';

export const key = ['PUBLIC_MARKETPLACE_ITEM'];
export const useGetPublicPublishedItem = (id: string, auth: boolean) => {
  return useQuery(
    key,
    async () => {
      const res = await axiosInstancePublic.get(`marketplace-item/${id}`);
      return res.data;
    },
    {
      enabled: !auth,
    },
  );
};
