import './Button.scss';

import { ButtonHTMLAttributes } from 'react';
import { IconContext } from 'react-icons';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  buttonType?: 'primary' | 'secondary' | 'link' | 'buy';
  shape?: 'regular' | 'rounded' | 'square';
  fullWidth?: boolean;
  selectedClassName?: string;
  labelClassname?: string;
}

const Button = ({
  buttonType = 'primary',
  shape = 'regular',
  fullWidth = false,
  selectedClassName,
  labelClassname,
  ...props
}: ButtonProps) => {
  const mode = `button-${buttonType}`;
  const btnShape = `button-${shape}`;

  return (
    <IconContext.Provider value={{}}>
      <button {...props} className={['button', mode, btnShape, selectedClassName].join(' ')}>
        <span className={['label', labelClassname].join(' ')}>{props?.children}</span>
      </button>
    </IconContext.Provider>
  );
};

export default Button;
