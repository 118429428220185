import dayjs from 'dayjs';
import * as durationFns from 'duration-fns';
import { cloneDeep } from 'lodash';
import moment from 'moment/moment';
import { MarkObj } from 'rc-slider/lib/Marks';
import { ReactNode } from 'react';

import { ElementsEnum } from '../../core/ui/enums/ElementsEnum';
import { ModeEnum } from '../../core/ui/enums/ModeEnum';
import {
  absoluteToPercent,
  getProjectDuration,
  getSceneStartEnd,
  parseScenes,
} from '../../helpers/timelineUtil';
import { CURRENT_TIME_DATE_FORMAT } from '../../model/constants/constants';
import { AnimationPanelDef } from '../../model/definitions/AnimationPanelDef';
import { AudioElement } from '../../model/definitions/AudioElement';
import { C9ProjectDef } from '../../model/definitions/C9ProjectDef';
import { DataFrameDef } from '../../model/definitions/DataFrameDef';
import { ForecastWDElementDef } from '../../model/definitions/ForecastWDElementDef';
import { ImagePanelDef } from '../../model/definitions/ImagePanelDef';
import { MapPanelDef } from '../../model/definitions/MapPanelDef';
import { ObservedWDElementDef } from '../../model/definitions/ObservedWDElementDef';
import { PointDateDef } from '../../model/definitions/PointDateDef';
import { PointLocationDef } from '../../model/definitions/PointLocationDef';
import { SceneDef } from '../../model/definitions/SceneDef';
import { SymbolSourceType } from '../../model/definitions/SymbolLayerDef';
import { TextPanelDef } from '../../model/definitions/TextPanelDef';
import { TimeControlDef } from '../../model/definitions/TimeControlDef';
import { VideoPanelDef } from '../../model/definitions/VideoPanelDef';
import { WeatherDataMapLayer } from '../../model/definitions/WeatherDataMapLayer';
import { WeatherDataSpaceDef } from '../../model/definitions/WeatherDataSpaceDef';
import { WeatherPosterDef } from '../../model/definitions/WeatherPosterDef';
import { TimeStepEnum } from '../../model/enums/TimeStepEnum';
import { GroupingEnum } from '../../model/UI/enums/GroupingEnum';
import {
  enumToS,
  splitRange,
  splitRangeRealtime,
} from '../../pages/playground/properties/panels/timeFrameIndicator/util';
import { GroupLane } from './timelane/GroupLane';

const getRollerMarks = (
  mode: ModeEnum,
  duration: number,
  granulation: number,
  timelineWidth: number,
  project?: C9ProjectDef,
) => {
  const array = [
    ...Array(
      Math.round(mode === ModeEnum.SEQUENCE ? duration : project ? getProjectDuration(project) : 0),
    ).keys(),
  ];
  array.push(array.length);
  const interval = 1000;
  let closestNums = [array[0]];
  for (let i = 1; i < array.length; i++) {
    if (Math.abs(array[i] - interval) < Math.abs(closestNums[0] - interval)) {
      closestNums = [array[i]];
    } else if (Math.abs(array[i] - interval) === Math.abs(closestNums[0] - interval)) {
      closestNums.push(array[i]);
    }
  }
  closestNums = array.filter((num) => num % 100 === 0);
  const obj = {} as Record<string, unknown>;
  closestNums.forEach((element, index) => {
    if (
      element % (1000 * Math.ceil(duration / ((timelineWidth / 50) * 1000))) === 0 ||
      index === 0
    ) {
      obj[element] = {
        style: {
          color: 'rgba(255,255,255,0.4)',
          alignItems: 'end',
          display: 'flex',
          textAlign: 'center',
          userSelect: 'none',
          zIndex: 1001,
        },
        label: (
          <div>
            <div>{readableTime(element) ?? 0}</div>
            <div
              style={{
                marginTop: -8,
                fontWeight: 600,
                left: '50%',
              }}
            >
              |
            </div>
          </div>
        ),
      };
    } else {
      obj[element] = {
        style: {
          color: 'rgba(255,255,255,0.4)',
          fontSize: 6,
          marginTop: 3,
          height: '20px',
          alignItems: 'end',
          display: 'flex',
        },
        label: <div>|</div>,
      };
    }
  });
  return obj as Record<string | number, ReactNode | MarkObj> | undefined;
};
const insetGroupingPanel = (
  elements: SceneDef,
  id: string,
  type: SceneKeys<SceneDef>,
  groupMode: GroupingEnum,
  duration: number,
  isHidden?: boolean,
) => {
  // @ts-ignore
  const index = elements && elements[type]?.findIndex((object) => object.id === id);
  if (index === 0 && groupMode === GroupingEnum.OFF) {
    switch (type) {
      case 'imagePanels':
        return (
          <GroupLane
            times={getGroupTimes(
              elements[type].map((item) => item.timeControls),
              duration,
            )}
            isOpen={isHidden}
            groupName={'IMAGE'}
          />
        );
      case 'animationPanels':
        return (
          <GroupLane
            times={getGroupTimes(
              elements[type].map((item) => item.timeControls),
              duration,
            )}
            isOpen={isHidden}
            groupName={'ANIMATION'}
          />
        );
      case 'videoPanels':
        return (
          <GroupLane
            times={getGroupTimes(
              elements[type].map((item) => item.timeControls),
              duration,
            )}
            isOpen={isHidden}
            groupName={'VIDEO'}
          />
        );
      case 'observedWDElements':
        return (
          <div id={'Lane'} className={'flex justify-center italic items-start drop-shadow-md'}>
            <GroupLane
              times={getGroupTimes(
                elements[type].map((item) => item.timeControls),
                duration,
              )}
              isOpen={isHidden}
              groupName={'OBSERVED DATA'}
            />
          </div>
        );
      case 'pointDates':
        return (
          <div id={'Lane'} className={'flex justify-center italic items-start drop-shadow-md'}>
            <GroupLane
              times={getGroupTimes(
                elements[type].map((item) => item.timeControls),
                duration,
              )}
              isOpen={isHidden}
              groupName={'POINT DATE'}
            />
          </div>
        );
      case 'pointLocation':
        return (
          <div id={'Lane'} className={'flex justify-center italic items-start drop-shadow-md'}>
            <GroupLane
              times={getGroupTimes(
                elements[type].map((item) => item.timeControls),
                duration,
              )}
              isOpen={isHidden}
              groupName={'POINT LOCATION'}
            />
          </div>
        );
      case 'forecastWDElements':
        return (
          <div id={'Lane'} className={'flex justify-center italic items-start drop-shadow-md'}>
            <GroupLane
              times={getGroupTimes(
                elements[type].map((item) => item.timeControls),
                duration,
              )}
              isOpen={isHidden}
              groupName={'FORECAST DATA'}
            />
          </div>
        );
      case 'textPanels':
        return (
          <div id={'Lane'} className={'flex justify-center italic items-start drop-shadow-md'}>
            <GroupLane
              times={getGroupTimes(
                elements[type].map((item) => item.timeControls),
                duration,
              )}
              isOpen={isHidden}
              groupName={'TEXT'}
            />
          </div>
        );
      case 'timestampPanels':
        return (
          <div id={'Lane'} className={'flex justify-center italic items-start drop-shadow-md'}>
            <GroupLane
              times={getGroupTimes(
                elements[type].map((item) => item.timeControls),
                duration,
              )}
              isOpen={isHidden}
              groupName={'TIMESTAMP'}
            />
          </div>
        );
      case 'mapPanels':
        return (
          <div id={'Lane'} className={'flex justify-center italic items-start drop-shadow-md'}>
            <GroupLane
              times={getGroupTimes(
                elements[type].map((item) => item.timeControls),
                duration,
              )}
              isOpen={isHidden}
              groupName={'MAP'}
            />
          </div>
        );
      case 'audioElements':
        return (
          <div id={'Lane'} className={'flex justify-center italic items-start drop-shadow-md'}>
            <GroupLane
              times={getGroupTimes(
                elements[type].map((item) => item.timeControls),
                duration,
              )}
              isOpen={isHidden}
              groupName={'AUDIO'}
            />
          </div>
        );
      case 'weatherPosters':
        return (
          <div id={'Lane'} className={'flex justify-center italic items-start drop-shadow-md'}>
            <GroupLane
              times={getGroupTimes(
                elements[type].map((item) => item.timeControls),
                duration,
              )}
              isOpen={isHidden}
              groupName={'COMPOSITE'}
            />
          </div>
        );
    }
  } else {
    return null;
  }
};
/***** Old create player, keep commented just in case ***/
/* const createPlayer = (
  project: C9ProjectDef,
  syncedSpaces: Array<string>,
  framerate: number,
): C9ProjectDef => {
  const simpleScenes = parseScenes(project);
  const newPlayer = cloneDeep(project);
  simpleScenes.forEach((scene, index) => {
    const start =
      index === 0
        ? 0
        : simpleScenes[index - 1].endMS - (index !== 0 ? scene.inAnimationDuration : 0);
    const editableScene = newPlayer.sceneDefs.find(
      (sceneDef: SceneDef) => sceneDef.id === scene.id,
    )!;
    editableScene.timeControl = {
      ...editableScene.timeControl,
      startMS:
        index === 0
          ? 0
          : simpleScenes[index - 1].endMS - (index !== 0 ? scene.inAnimationDuration : 0),
      endMS:
        index === 0
          ? editableScene.durationInMS
          : simpleScenes[index - 1].endMS -
            (index !== 0 ? scene.inAnimationDuration : 0) +
            editableScene.durationInMS,
    };
    editableScene.textPanels.map((panel: TextPanelDef) =>
      panel.timeControls.forEach((control) => {
        control.startMS = control.startMS + start;
        control.endMS = control.endMS + start;
      }),
    );
    editableScene.imagePanels.map((panel: ImagePanelDef) =>
      panel.timeControls.forEach((control) => {
        control.startMS = control.startMS + start;
        control.endMS = control.endMS + start;
      }),
    );
    editableScene.videoPanels.map((panel: VideoPanelDef) =>
      panel.timeControls.forEach((control) => {
        control.startMS = control.startMS + start;
        control.endMS = control.endMS + start;
      }),
    );
    editableScene.audioElements.map((panel: AudioElement) =>
      panel.timeControls.forEach((control) => {
        control.startMS = control.startMS + start;
        control.endMS = control.endMS + start;
      }),
    );
    editableScene.animationPanels.map((panel: AnimationPanelDef) =>
      panel.timeControls.forEach((control) => {
        control.startMS = control.startMS + start;
        control.endMS = control.endMS + start;
      }),
    );
    editableScene.mapPanels.map((panel: MapPanelDef) => {
      panel.timeControls.forEach((control) => {
        control.startMS = control.startMS + start;
        control.endMS = control.endMS + start;
      });
      panel.wdSpace?.forEach((space) => {
        const isSynced = syncedSpaces.includes(space.id);
        if (isSynced) {
          const noOfSteps =
            ((spaceSpan(space)?.max ?? 0) - (spaceSpan(space)?.min ?? 0)) /
            enumToS(space.timeframeIndicatorStep as TimeStepEnum);
          space.indicator = splitRange(
            space.timeControls[0].startMS,
            space.timeControls[0].endMS,
            spaceSpan(space)?.min,
            spaceSpan(space)?.max,
            noOfSteps,
            space.timeframeIndicatorFormat,
            CURRENT_TIME_DATE_FORMAT,
            space.mapTimeframeTextIndicator,
            space.roundTimeframeIndicator,
            space.timeframeIndicatorStep,
            framerate,
            space,
          );
        } else {
          //@ts-ignore
          space.indicator = createUnSynchronizedIndicator(
            space,
            space.timeframeIndicatorFormat,
            space.mapTimeframeTextIndicator,
            framerate,
          );
        }
        space?.mapTimeframeTextIndicator?.timeControls.forEach((control) => {
          control.startMS = control.startMS + start;
          control.endMS = control.endMS + start;
        });
        /!* if (space.mapTimeframeTextIndicator)
          space.mapTimeframeTextIndicator.positionControl = {
            ...space.mapTimeframeTextIndicator.positionControl,
            zindex: panel.positionControl.zindex + 1,
          }; *!/
        space.timeControls?.forEach((control) => {
          control.startMS = control.startMS + start;
          control.endMS = control.endMS + start;
        });
        space.indicator?.forEach((indicator) => {
          indicator.timeControls.startMS = indicator.timeControls.startMS + start;
          indicator.timeControls.endMS = indicator.timeControls.endMS + start;
        });
        space.gribMapLayers?.forEach((sl) => {
          sl.layerSetup.paletteTC = cloneDeep(sl.timeControls);
          sl.layerSetup.paletteTC?.forEach((tc) => {
            tc.startMS = tc.startMS + start;
            tc.endMS = tc.endMS + start;
          });
        });
        space.radarMapLayers?.forEach((sl) => {
          sl.layerSetup.paletteTC = cloneDeep(sl.timeControls);
          sl.layerSetup.paletteTC?.forEach((tc) => {
            tc.startMS = tc.startMS + start;
            tc.endMS = tc.endMS + start;
          });
        });
        space.satelliteMapLayers?.forEach((sl) => {
          sl.layerSetup.paletteTC = cloneDeep(sl.timeControls);
          sl.layerSetup.paletteTC?.forEach((tc) => {
            tc.startMS = tc.startMS + start;
            tc.endMS = tc.endMS + start;
          });
        });
      });
      panel.drawingElements.forEach((p) => {
        p.timeControls.forEach((control) => {
          control.startMS = control.startMS + start;
          control.endMS = control.endMS + start;
        });
      });
      return panel;
    });
    editableScene.weatherPosters.map((panel: WeatherPosterDef) =>
      panel.timeControls.forEach((control) => {
        control.startMS = control.startMS + start;
        control.endMS = control.endMS + start;
      }),
    );
    editableScene.observedWDElements.map((panel: ObservedWDElementDef) =>
      panel.timeControls.forEach((control) => {
        control.startMS = control.startMS + start;
        control.endMS = control.endMS + start;
      }),
    );
    editableScene.forecastWDElements.map((panel: ForecastWDElementDef) =>
      panel.timeControls.forEach((control) => {
        control.startMS = control.startMS + start;
        control.endMS = control.endMS + start;
      }),
    );
    editableScene.pointDates.map((panel: PointDateDef) =>
      panel.timeControls.forEach((control) => {
        control.startMS = control.startMS + start;
        control.endMS = control.endMS + start;
      }),
    );
    editableScene.pointLocation.map((panel: PointLocationDef) =>
      panel.timeControls.forEach((control) => {
        control.startMS = control.startMS + start;
        control.endMS = control.endMS + start;
      }),
    );
  });
  return newPlayer;
}; */
const playerCache = new WeakMap();

const createPlayer = (
  project: C9ProjectDef,
  syncedSpaces: Array<string>,
  framerate: number,
): C9ProjectDef => {
  const cacheKey = JSON.stringify(project);

  if (playerCache.has(project)) {
    const cachedEntry = playerCache.get(project);
    if (cachedEntry.key === cacheKey) {
      return cachedEntry.value; // Return the cached player if it exists
    }
  }
  const newPlayer = JSON.parse(JSON.stringify(project));
  const simpleScenes = parseScenes(newPlayer);
  simpleScenes.forEach((scene, index) => {
    const start = index === 0 ? 0 : simpleScenes[index - 1].endMS - scene.inAnimationDuration;
    const editableScene = newPlayer.sceneDefs[index];

    if (!editableScene) return;
    editableScene.timeControl = {
      ...scene,
      seekToTimeMS: 0,
      startMS: start,
      endMS: start + editableScene.durationInMS,
    };

    // Helper function to update time controls for panels
    const updateTimeControls = (panels: Array<{ timeControls: TimeControlDef[] }>) => {
      panels?.forEach((panel) =>
        panel.timeControls.forEach((control) => {
          control.startMS += start;
          control.endMS += start;
        }),
      );
    };

    updateTimeControls(editableScene.textPanels);
    updateTimeControls(editableScene.timestampPanels);
    updateTimeControls(editableScene.imagePanels);
    updateTimeControls(editableScene.videoPanels);
    updateTimeControls(editableScene.audioElements);
    updateTimeControls(editableScene.animationPanels);
    updateTimeControls(editableScene.weatherPosters);
    updateTimeControls(editableScene.observedWDElements);
    updateTimeControls(editableScene.forecastWDElements);
    updateTimeControls(editableScene.pointDates);
    updateTimeControls(editableScene.pointLocation);

    // Update map panels
    editableScene.mapPanels.forEach((panel: MapPanelDef) => {
      updateTimeControls([panel]);
      updateTimeControls(panel.geoPosters);
      panel.wdSpace?.forEach((space) => {
        const isSynced = space.layerSync;
        if (isSynced) {
          const noOfSteps =
            ((editableScene.dateEnd ? editableScene.dateEnd : spaceSpan(space)?.max ?? 0) -
              (editableScene.dateStart ? editableScene.dateStart : spaceSpan(space)?.min ?? 0)) /
            enumToS(space.timeframeIndicatorStep as TimeStepEnum);
          space.indicator = splitRange(
            space.timeControls[0].startMS,
            space.timeControls[0].endMS,
            editableScene.startDate ?? spaceSpan(space)?.min,
            editableScene.endDate ?? spaceSpan(space)?.max,
            noOfSteps,
            space.timeframeIndicatorFormat,
            CURRENT_TIME_DATE_FORMAT,
            space.mapTimeframeTextIndicator,
            space.roundTimeframeIndicator,
            space.timeframeIndicatorStep,
            framerate,
            space,
          );
        } else {
          space.indicator = createUnSynchronizedIndicator(
            space,
            space.timeframeIndicatorFormat,
            space.mapTimeframeTextIndicator,
            framerate,
          );
        }

        if (space.mapTimeframeTextIndicator) {
          updateTimeControls([space.mapTimeframeTextIndicator]);
        }

        updateTimeControls([space]);

        space.indicator?.forEach((indicator) => {
          indicator.timeControls.startMS += start;
          indicator.timeControls.endMS += start;
        });

        const updateLayerTimeControls = (
          layers: Array<{ layerSetup: { paletteTC: TimeControlDef[] } }>,
        ) => {
          layers?.forEach((layer) => {
            if (layer.layerSetup?.paletteTC) {
              layer.layerSetup.paletteTC = layer.layerSetup.paletteTC.map((tc) => ({
                ...tc,
                startMS: tc.startMS + start,
                endMS: tc.endMS + start,
              }));
            }
          });
        };

        updateLayerTimeControls(space.gribMapLayers || []);
        updateLayerTimeControls(space.radarMapLayers || []);
        updateLayerTimeControls(space.satelliteMapLayers || []);
      });

      updateTimeControls(panel.drawingElements);
    });
  });

  // Cache the result with the unique key
  playerCache.set(project, { key: cacheKey, value: newPlayer });
  // console.timeEnd('player');
  return newPlayer;
};
const createPlayerRef = (
  project: C9ProjectDef,
  syncedSpaces: Array<string>,
  framerate: number,
): C9ProjectDef => {
  const simpleScenes = parseScenes(project);
  const newPlayer = project;
  let updatedScenes: SceneDef[] = [];
  simpleScenes.forEach((scene, index) => {
    const start =
      index === 0
        ? 0
        : simpleScenes[index - 1].endMS - (index !== 0 ? scene.inAnimationDuration : 0);
    updatedScenes = newPlayer.sceneDefs.map((sceneDef) => {
      if (sceneDef.id !== scene.id) return sceneDef;
      const editableScene = cloneDeep(sceneDef);
      editableScene.timeControl = {
        ...editableScene.timeControl,
        startMS:
          index === 0
            ? 0
            : simpleScenes[index - 1].endMS - (index !== 0 ? scene.inAnimationDuration : 0),
        endMS:
          index === 0
            ? editableScene.durationInMS
            : simpleScenes[index - 1].endMS -
              (index !== 0 ? scene.inAnimationDuration : 0) +
              editableScene.durationInMS,
      };
      editableScene.textPanels.map((panel: TextPanelDef) =>
        panel.timeControls.forEach((control) => {
          control.startMS = control.startMS + start;
          control.endMS = control.endMS + start;
        }),
      );
      editableScene.imagePanels.map((panel: ImagePanelDef) =>
        panel.timeControls.forEach((control) => {
          control.startMS = control.startMS + start;
          control.endMS = control.endMS + start;
        }),
      );
      editableScene.videoPanels.map((panel: VideoPanelDef) =>
        panel.timeControls.forEach((control) => {
          control.startMS = control.startMS + start;
          control.endMS = control.endMS + start;
        }),
      );
      editableScene.audioElements.map((panel: AudioElement) =>
        panel.timeControls.forEach((control) => {
          control.startMS = control.startMS + start;
          control.endMS = control.endMS + start;
        }),
      );
      editableScene.animationPanels.map((panel: AnimationPanelDef) =>
        panel.timeControls.forEach((control) => {
          control.startMS = control.startMS + start;
          control.endMS = control.endMS + start;
        }),
      );
      editableScene.mapPanels.map((panel: MapPanelDef) => {
        panel.timeControls.forEach((control) => {
          control.startMS = control.startMS + start;
          control.endMS = control.endMS + start;
        });
        panel.wdSpace?.forEach((space) => {
          const isSynced = syncedSpaces.includes(space.id);
          if (isSynced) {
            const noOfSteps =
              ((spaceSpan(space)?.max ?? 0) - (spaceSpan(space)?.min ?? 0)) /
              enumToS(space.timeframeIndicatorStep as TimeStepEnum);
            space.indicator = splitRange(
              space.timeControls[0].startMS,
              space.timeControls[0].endMS,
              spaceSpan(space)?.min,
              spaceSpan(space)?.max,
              noOfSteps,
              space.timeframeIndicatorFormat,
              CURRENT_TIME_DATE_FORMAT,
              space.mapTimeframeTextIndicator,
              space.roundTimeframeIndicator,
              space.timeframeIndicatorStep,
              framerate,
              space,
            );
          } else {
            //@ts-ignore
            space.indicator = createUnSynchronizedIndicator(
              space,
              space.timeframeIndicatorFormat,
              space.mapTimeframeTextIndicator,
              framerate,
            );
          }
          space?.mapTimeframeTextIndicator?.timeControls.forEach((control) => {
            control.startMS = control.startMS + start;
            control.endMS = control.endMS + start;
          });
          /* if (space.mapTimeframeTextIndicator)
          space.mapTimeframeTextIndicator.positionControl = {
            ...space.mapTimeframeTextIndicator.positionControl,
            zindex: panel.positionControl.zindex + 1,
          }; */
          space.timeControls?.forEach((control) => {
            control.startMS = control.startMS + start;
            control.endMS = control.endMS + start;
          });
          space.indicator?.forEach((indicator) => {
            indicator.timeControls.startMS = indicator.timeControls.startMS + start;
            indicator.timeControls.endMS = indicator.timeControls.endMS + start;
          });
          space.gribMapLayers?.forEach((sl) => {
            sl.layerSetup.paletteTC = cloneDeep(sl.timeControls);
            sl.layerSetup.paletteTC?.forEach((tc) => {
              tc.startMS = tc.startMS + start;
              tc.endMS = tc.endMS + start;
            });
          });
          space.radarMapLayers?.forEach((sl) => {
            sl.layerSetup.paletteTC = cloneDeep(sl.timeControls);
            sl.layerSetup.paletteTC?.forEach((tc) => {
              tc.startMS = tc.startMS + start;
              tc.endMS = tc.endMS + start;
            });
          });
          space.satelliteMapLayers?.forEach((sl) => {
            sl.layerSetup.paletteTC = cloneDeep(sl.timeControls);
            sl.layerSetup.paletteTC?.forEach((tc) => {
              tc.startMS = tc.startMS + start;
              tc.endMS = tc.endMS + start;
            });
          });
        });
        panel.drawingElements.forEach((p) => {
          p.timeControls.forEach((control) => {
            control.startMS = control.startMS + start;
            control.endMS = control.endMS + start;
          });
        });
        return panel;
      });
      editableScene.weatherPosters.map((panel: WeatherPosterDef) =>
        panel.timeControls.forEach((control) => {
          control.startMS = control.startMS + start;
          control.endMS = control.endMS + start;
        }),
      );
      editableScene.observedWDElements.map((panel: ObservedWDElementDef) =>
        panel.timeControls.forEach((control) => {
          control.startMS = control.startMS + start;
          control.endMS = control.endMS + start;
        }),
      );
      editableScene.forecastWDElements.map((panel: ForecastWDElementDef) =>
        panel.timeControls.forEach((control) => {
          control.startMS = control.startMS + start;
          control.endMS = control.endMS + start;
        }),
      );
      editableScene.pointDates.map((panel: PointDateDef) =>
        panel.timeControls.forEach((control) => {
          control.startMS = control.startMS + start;
          control.endMS = control.endMS + start;
        }),
      );
      editableScene.pointLocation.map((panel: PointLocationDef) =>
        panel.timeControls.forEach((control) => {
          control.startMS = control.startMS + start;
          control.endMS = control.endMS + start;
        }),
      );
      return editableScene;
    });
  });
  return { ...newPlayer, sceneDefs: updatedScenes };
};
const elementEnumToKey = (elementType: ElementsEnum): SceneKeys<SceneDef> => {
  switch (elementType) {
    case ElementsEnum.AUDIO:
      return 'audioElements';
    case ElementsEnum.FORECAST_WD:
      return 'forecastWDElements';
    case ElementsEnum.IMAGE:
      return 'imagePanels';
    case ElementsEnum.ANIMATION:
      return 'animationPanels';
    case ElementsEnum.OBSERVED_WD:
      return 'observedWDElements';
    case ElementsEnum.MAP:
      return 'mapPanels';
    case ElementsEnum.TEXT:
      return 'textPanels';
    case ElementsEnum.TIMESTAMP:
      return 'timestampPanels';
    case ElementsEnum.VIDEO:
      return 'videoPanels';
    case ElementsEnum.POINT_DATE:
      return 'pointDates';
    case ElementsEnum.POINT_LOCATION:
      return 'pointLocation';
    case ElementsEnum.WEATHER_GRAPH:
      return 'weatherPosters';
    default:
      return 'textPanels';
  }
};
const keyToElementsEnum = (elementType: keyof SceneDef): ElementsEnum => {
  switch (elementType) {
    case 'audioElements':
      return ElementsEnum.AUDIO;
    case 'forecastWDElements':
      return ElementsEnum.FORECAST_WD;
    case 'imagePanels':
      return ElementsEnum.IMAGE;
    case 'animationPanels':
      return ElementsEnum.ANIMATION;
    case 'observedWDElements':
      return ElementsEnum.OBSERVED_WD;
    case 'mapPanels':
      return ElementsEnum.MAP;
    case 'textPanels':
      return ElementsEnum.TEXT;
    case 'timestampPanels':
      return ElementsEnum.TIMESTAMP;
    case 'videoPanels':
      return ElementsEnum.VIDEO;
    case 'pointDates':
      return ElementsEnum.POINT_DATE;
    case 'pointLocation':
      return ElementsEnum.POINT_LOCATION;
    default:
      return ElementsEnum.WEATHER_GRAPH;
  }
};

const readableTime = (time: number) => {
  const normal = durationFns.normalize(Math.round(time));
  const hours = normal.hours ? String('0' + normal.hours).slice(-2) + ':' : '';
  const minutes = normal.minutes ? String('0' + normal.minutes).slice(-2) + ':' : '00:';
  const seconds = normal.seconds ? String('0' + normal.seconds).slice(-2) + '' : '00';
  return hours + minutes + seconds;
};
const getRealtimeIndicator = (
  space: WeatherDataSpaceDef,
  framerate: number,
  sceneStart?: number | null,
  sceneEnd?: number | null,
) => {
  const grib: Array<WeatherDataMapLayer> = [];
  const radar: Array<WeatherDataMapLayer> = [];
  const satellite: Array<WeatherDataMapLayer> = [];
  //const observed = space.observedDataLayers;
  space.gribMapLayers.forEach(
    (layer) =>
      layer.enabled && (layer.realtime || layer.dataFrames.length === 1) && grib.push(layer),
  );
  space.radarMapLayers.forEach(
    (layer) =>
      layer.enabled && (layer.realtime || layer.dataFrames.length === 1) && radar.push(layer),
  );
  space.satelliteMapLayers.forEach(
    (layer) =>
      layer.enabled && (layer.realtime || layer.dataFrames.length === 1) && satellite.push(layer),
  );
  const data = [...grib, ...radar, ...satellite].sort((a, b) => {
    if ((a.indicatorPriority || 0) !== (b.indicatorPriority || 0)) {
      return (a.indicatorPriority || 0) - (b.indicatorPriority || 0);
    }
    return Number(b.realtime) - Number(a.realtime);
  });
  return data
    .map((layer) => {
      return splitRangeRealtime(
        layer.timeControls[0].startMS,
        layer.timeControls[0].endMS,
        layer,
        space.timeframeIndicatorFormat,
        CURRENT_TIME_DATE_FORMAT,
        space.mapTimeframeTextIndicator,
        space.roundTimeframeIndicator,
        framerate,
        sceneStart,
        sceneEnd,
      );
    })
    .flat();
};
const createUnSynchronizedIndicator = (
  space: WeatherDataSpaceDef,
  format: string,
  model: TextPanelDef,
  framerate: number,
  sceneStart?: number | null,
  sceneEnd?: number | null,
) => {
  const grib = space.gribMapLayers.map((layer) => {
    const frs = [];
    layer.enabled && !layer.realtime && frs.push(...layer.dataFrames.map((fr) => fr?.timestamp));
    const noOfSteps =
      ((sceneEnd ? sceneEnd / 1000 : Math.max(...frs)) -
        (sceneStart ? sceneStart / 1000 : Math.min(...frs))) /
      enumToS(space.timeframeIndicatorStep as TimeStepEnum);
    return splitRange(
      space.timeControls[0].startMS,
      space.timeControls[0].endMS,
      sceneStart ? sceneStart / 1000 : Math.min(...frs),
      sceneEnd ? sceneEnd / 1000 : Math.max(...frs),
      noOfSteps,
      format,
      CURRENT_TIME_DATE_FORMAT,
      model,
      space.roundTimeframeIndicator,
      space.timeframeIndicatorStep,
      framerate,
      space,
    );
  });
  const symbol = space.symbolLayers.map((layer) => {
    const frs = [];
    if (layer.enabled) {
      if (layer.symbolSource.sourceType == SymbolSourceType.ModelData) {
        frs.push(...layer.dataFrames.map((fr) => fr?.timestamp));
      } else {
        frs.push(...layer.symbolSource.pointDataFrames!.map((fr) => fr?.startDate));
      }
    }
    const noOfSteps =
      (Math.max(...frs) - Math.min(...frs)) / enumToS(space.timeframeIndicatorStep as TimeStepEnum);
    return splitRange(
      layer.timeControls[0].startMS,
      layer.timeControls[0].endMS,
      Math.min(...frs),
      Math.max(...frs),
      noOfSteps,
      format,
      CURRENT_TIME_DATE_FORMAT,
      model,
      space.roundTimeframeIndicator,
      space.timeframeIndicatorStep,
      framerate,
      space,
    );
  });
  const radar = space.radarMapLayers.map((layer) => {
    const frs = [];
    layer.enabled && !layer.realtime && frs.push(...layer.dataFrames.map((fr) => fr?.timestamp));
    const noOfSteps =
      ((Math.max(...frs) ?? 0) - (Math.min(...frs) ?? 0)) /
      enumToS(space.timeframeIndicatorStep as TimeStepEnum);
    return splitRange(
      layer.timeControls[0].startMS,
      layer.timeControls[0].endMS,
      Math.min(...frs),
      Math.max(...frs),
      noOfSteps,
      format,
      CURRENT_TIME_DATE_FORMAT,
      model,
      space.roundTimeframeIndicator,
      space.timeframeIndicatorStep,
      framerate,
      space,
    );
  });
  const satellite = space.satelliteMapLayers.map((layer) => {
    const frs = [];
    layer.enabled && !layer.realtime && frs.push(...layer.dataFrames.map((fr) => fr?.timestamp));
    const noOfSteps =
      ((Math.max(...frs) ?? 0) - (Math.min(...frs) ?? 0)) /
      enumToS(space.timeframeIndicatorStep as TimeStepEnum);
    return splitRange(
      layer.timeControls[0].startMS,
      layer.timeControls[0].endMS,
      Math.min(...frs),
      Math.max(...frs),
      noOfSteps,
      format,
      CURRENT_TIME_DATE_FORMAT,
      model,
      space.roundTimeframeIndicator,
      space.timeframeIndicatorStep,
      framerate,
      space,
    );
  });
  const observed = space.observedDataLayers.map((layer) => {
    const frs = [];
    layer.enabled &&
      frs.push(
        ...[
          moment(layer.observedWDSource.utcDate).startOf('hour').valueOf() / 1000 + 1,
          moment(layer.observedWDSource.utcDate).add(1, 'hour').startOf('hour').valueOf() / 1000 +
            1,
        ],
      );
    const noOfSteps =
      ((Math.max(...frs) ?? 0) - (Math.min(...frs) ?? 0)) /
      enumToS(space.timeframeIndicatorStep as TimeStepEnum);
    return splitRange(
      layer.timeControls[0].startMS,
      layer.timeControls[0].endMS,
      Math.min(...frs),
      Math.max(...frs),
      noOfSteps,
      format,
      CURRENT_TIME_DATE_FORMAT,
      model,
      space.roundTimeframeIndicator,
      space.timeframeIndicatorStep,
      framerate,
      space,
    );
  });
  const forecast = space.forecastDataLayers.map((layer) => {
    const validity = layer.forecastWDSource.daily ? 'day' : 'hour';
    const frs = [];
    layer.enabled &&
      frs.push(
        ...[
          moment(layer.forecastWDSource.utcDate).startOf(validity).valueOf() / 1000,
          moment(layer.forecastWDSource.utcDate).add(1, validity).startOf(validity).valueOf() /
            1000,
        ],
      );
    const noOfSteps =
      ((Math.max(...frs) ?? 0) - (Math.min(...frs) ?? 0)) /
      enumToS(space.timeframeIndicatorStep as TimeStepEnum);
    return splitRange(
      layer.timeControls[0].startMS,
      layer.timeControls[0].endMS,
      Math.min(...frs),
      Math.max(...frs),
      noOfSteps,
      format,
      CURRENT_TIME_DATE_FORMAT,
      model,
      space.roundTimeframeIndicator,
      space.timeframeIndicatorStep,
      framerate,
      space,
    );
  });
  return getRealtimeIndicator(space, framerate).concat(
    [...grib, ...radar, ...satellite, ...symbol, ...observed, ...forecast].flat(),
  );
};
const spaceSpan = (space: WeatherDataSpaceDef) => {
  const grib: Array<number> = [];
  const radar: Array<number> = [];
  const satellite: Array<number> = [];
  const observed: Array<number> = [];
  const forecast: Array<number> = [];
  const symbol: Array<number> = [];
  space.gribMapLayers.forEach(
    (layer) => layer.enabled && grib.push(...layer.dataFrames.map((fr) => fr?.timestamp)),
  );
  space.radarMapLayers.forEach(
    (layer) => layer.enabled && radar.push(...layer.dataFrames.map((fr) => fr?.timestamp)),
  );
  space.satelliteMapLayers.forEach(
    (layer) => layer.enabled && satellite.push(...layer.dataFrames.map((fr) => fr?.timestamp)),
  );
  if (space.symbolLayers) {
    space.symbolLayers.forEach((layer) => {
      if (layer.enabled) {
        if (layer.symbolSource.sourceType === SymbolSourceType.ModelData) {
          symbol.push(...layer.dataFrames.map((fr) => fr?.timestamp));
        } else {
          symbol.push(...layer.symbolSource.pointDataFrames!.map((fr) => fr?.startDate));
        }
      }
    });
  }
  space.observedDataLayers.forEach((layer) => {
    if (layer.enabled) {
      observed.push(
        Math.round(moment(layer.observedWDSource.utcDate).startOf('hour').valueOf() / 1000),
      );
      observed.push(
        Math.round(moment(layer.observedWDSource.utcDate).endOf('hour').valueOf() / 1000),
      );
    }
  });
  space.forecastDataLayers.forEach((layer) => {
    if (layer.enabled) {
      const validity = layer.forecastWDSource.daily ? 'day' : 'hour';
      forecast.push(
        Math.round(moment(layer.forecastWDSource.utcDate).startOf(validity).valueOf() / 1000),
      );
      /*  forecast.push(
        Math.round(moment(layer.forecastWDSource.utcDate).endOf(validity).valueOf() / 1000),
      ); */
    }
  });
  const span = [...radar, ...grib, ...satellite, ...observed, ...forecast, ...symbol].sort(
    (a, b) => a - b,
  );
  return { min: span.length ? Math.min(...span) : 0, max: span.length ? Math.max(...span) : 0 };
};
const layerSpan = (layerFrames: Array<DataFrameDef>) => {
  const frames = layerFrames.map((fr) => fr.timestamp);
  frames.sort((a, b) => a - b);
  frames.splice(frames.length - 1, 1);
  return {
    min: Math.min(...frames),
    max: Math.max(...frames),
    stepNo: layerFrames.length - 1,
    step: layerFrames[1]?.timestamp - layerFrames[0]?.timestamp,
  };
};
/* const getLayerCentralTime = (
  spaceTime: TimeControlDef,
  spaceSpan: { min: number; max: number },
  layerSpan: { min: number; max: number; stepNo: number; step: number },
  frames: DataFrameDef[],
) => {
  const fr = frames.map((frame) => frame.timestamp);
  fr.sort((a, b) => a - b);
  const spanWidth = spaceSpan.max - spaceSpan.min;
  const layerAbs = Math.max(...fr) - Math.min(...fr);
  const layerWidthPercentage = (layerAbs * 100) / spanWidth;
  const spanDuration = spaceTime.endMS - spaceTime.startMS;
  const startTimeOffsetWidth = layerSpan.min - spaceSpan.min;
  const startTimeOffsetWidthPercentage = (startTimeOffsetWidth * 100) / spanWidth;
  const layerDuration = (spanDuration * layerWidthPercentage) / 100;
  console.log(layerDuration);
  const layerStartTime = spaceTime.startMS + (spanDuration * startTimeOffsetWidthPercentage) / 100;
  return {
    start: Math.floor(layerStartTime),
    end: Math.ceil(layerStartTime + layerDuration),
  };
}; */
const getLayerCentralTime = (
  spaceTime: TimeControlDef,
  spaceSpan: { min: number; max: number },
  layerSpan: { min: number; max: number; stepNo: number; step: number },
  frames: DataFrameDef[],
) => {
  const timestamps = frames.map((frame) => frame.timestamp);
  timestamps.sort((a, b) => a - b);

  const spaceSpanWidth = spaceSpan.max - spaceSpan.min;
  const layerAbs = Math.max(...timestamps) - Math.min(...timestamps); // Absolute time span of the layer
  const spanDuration = spaceTime.endMS - spaceTime.startMS; // Total duration of the space time

  // Calculate the layer width as a percentage of the space span
  const layerWidthPercentage = (layerAbs * 100) / spaceSpanWidth;

  // Handle start time offset, ensuring layerSpan.min can be outside of spaceSpan.min
  const startTimeOffset = layerSpan.min - spaceSpan.min;
  const startTimeOffsetPercentage = (startTimeOffset * 100) / spaceSpanWidth;

  // Calculate the start time for the layer within the space time frame
  const layerStartTime = spaceTime.startMS + (spanDuration * startTimeOffsetPercentage) / 100;

  // Calculate the duration of the layer in the space's time frame
  const layerDuration = (spanDuration * layerWidthPercentage) / 100;

  return {
    start: Math.floor(layerStartTime),
    end: Math.ceil(layerStartTime + layerDuration),
  };
};

const mergeOverlappingRanges = (ranges: TimeControlDef[], sceneDuration: number) => {
  ranges.sort((a, b) => a.startMS - b.startMS);

  const mergedRanges = [];
  let currentRange = ranges[0];
  for (let i = 1; i < ranges.length; i++) {
    const nextRange = ranges[i];
    if (currentRange.endMS >= nextRange.startMS) {
      currentRange = {
        ...currentRange,
        startMS: Math.min(currentRange.startMS, nextRange.startMS),
        endMS: Math.max(currentRange.endMS, nextRange.endMS),
      };
    } else {
      mergedRanges.push(currentRange);
      currentRange = nextRange;
    }
  }
  mergedRanges.push(currentRange);

  return absoluteToPercent(mergedRanges, sceneDuration);
};
const getGroupTimes = (array: TimeControlDef[][], sceneDuration: number): TimeControlDef[] => {
  const times = array.flat();
  return mergeOverlappingRanges(times, sceneDuration);
};
const findGroupMembers = (elements: SceneDef, group: string) => {
  const text = elements.textPanels.filter((elem) => elem.parentGroups[0].groupId === group);
  const video = elements.videoPanels.filter((elem) => elem.parentGroups[0].groupId === group);
  const image = elements.imagePanels.filter((elem) => elem.parentGroups[0].groupId === group);
  const audio = elements.audioElements.filter((elem) => elem.parentGroups[0].groupId === group);
  const map = elements.mapPanels.filter((elem) => elem.parentGroups[0].groupId === group);
  const observed = elements.observedWDElements.filter(
    (elem) => elem.parentGroups[0].groupId === group,
  );
  const forecast = elements.forecastWDElements.filter(
    (elem) => elem.parentGroups[0].groupId === group,
  );
  const poster = elements.weatherPosters.filter((elem) => elem.parentGroups[0].groupId === group);
  const groupElements = [
    ...text,
    ...forecast,
    ...video,
    ...image,
    ...audio,
    ...map,
    ...observed,
    ...poster,
  ];
  return groupElements.map((item) => item.timeControls);
};

function mapValueInRange(
  value: number,
  minRange: number,
  maxRange: number,
  minTarget: number,
  maxTarget: number,
): number {
  // Ensure the value is within the range
  if (value <= minRange) return minTarget;
  if (value >= maxRange) return maxTarget;

  // Calculate the proportional value within the target range
  const proportion = (value - minRange) / (maxRange - minRange);
  return minTarget + proportion * (maxTarget - minTarget);
}
function findDateInRange(
  startSecond: number,
  endSecond: number,
  startDateTimestamp: number,
  endDateTimestamp: number,
  targetTimestamp: number,
): number {
  // Calculate the fraction of the target position within the range
  const fraction = (targetTimestamp - startDateTimestamp) / (endDateTimestamp - startDateTimestamp);

  // Interpolate (or extrapolate) the position within the seconds range based on the fraction
  // Return the target position in seconds, which may be outside the range
  return startSecond + fraction * (endSecond - startSecond);
}
const findClosestToMidnight = (
  timestamps: Array<{ timeControls: TimeControlDef; value: string; dateValue: string }>,
  hourOffset: number,
  dateStart?: number | null,
  dateEnd?: number | null,
  wdSpaceTime?: TimeControlDef,
): { value: number; min: number; max: number } | null => {
  const todayMidnight = new Date();
  const dateFormat = 'DD-MM-YYYY HH:mm'; // Format specifier for the input string
  todayMidnight.setUTCHours(0, 0, 0, 0);
  // Calculate the target timestamp based on the hour offset
  const targetTime = new Date(todayMidnight);
  targetTime.setUTCHours(targetTime.getUTCHours() + Math.floor(hourOffset));
  const fractionalHour = hourOffset % 1;
  targetTime.setUTCMinutes(targetTime.getUTCMinutes() + Math.floor(fractionalHour * 60));
  const targetTimestamp = targetTime.getTime();
  // Check if the target timestamp is in the array
  const allTimestamps = timestamps.map((ind) => moment(ind.dateValue, dateFormat).valueOf());
  const allTimes = timestamps.map((ind) => ind.timeControls.startMS);
  const minRange = Math.min(...allTimestamps);
  const maxRange = Math.max(...allTimestamps);
  const minTime = Math.min(...allTimes);
  const maxTime = Math.max(...allTimes);
  let value = mapValueInRange(targetTimestamp, minRange, maxRange, minTime, maxTime);
  if (dateEnd && dateStart && wdSpaceTime) {
    value = findDateInRange(
      wdSpaceTime.startMS,
      wdSpaceTime.endMS,
      dateStart,
      dateEnd,
      targetTimestamp,
    );
  }
  if (value) {
    const date1 = dayjs(minRange);
    const date2 = dayjs(maxRange);
    const min = date1.diff(todayMidnight, 'hour');
    const max = date2.diff(todayMidnight, 'hour');
    return { value: Math.round(value), min, max };
    //return target.timeControls.startMS;
  } else {
    return { value: 0, min: 0, max: 0 };
  }
};

function isTimeRangeFullyCovered(
  mainObj: SceneDef,
  otherObjs: Array<ImagePanelDef | VideoPanelDef | MapPanelDef>,
) {
  const { startMS } = mainObj.timeControl;
  const endMS = getSceneStartEnd(mainObj).endMS;
  const allIntervals = otherObjs.flatMap((obj) => obj.timeControls);
  const sortedIntervals = allIntervals.sort((a, b) => a.startMS - b.startMS);
  let currentEnd = startMS;
  for (const interval of sortedIntervals) {
    if (interval.startMS > currentEnd) {
      return true;
    }
    currentEnd = Math.max(currentEnd, interval.endMS);
    if (currentEnd >= endMS) {
      return false;
    }
  }
  return true;
}
export {
  createPlayer,
  createPlayerRef,
  createUnSynchronizedIndicator,
  elementEnumToKey,
  findClosestToMidnight,
  findGroupMembers,
  getGroupTimes,
  getLayerCentralTime,
  getRealtimeIndicator,
  getRollerMarks,
  insetGroupingPanel,
  isTimeRangeFullyCovered,
  keyToElementsEnum,
  layerSpan,
  mergeOverlappingRanges,
  readableTime,
  spaceSpan,
};
