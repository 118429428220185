import { findLastIndex } from 'lodash';
import moment from 'moment/moment';
import Range from 'rc-slider';
import { HandleProps } from 'rc-slider/lib/Handles/Handle';
import { MarkObj } from 'rc-slider/lib/Marks';
import { ReactElement, ReactNode, useMemo, useRef } from 'react';

import { DataFrameDef } from '../../../model/definitions/DataFrameDef';

interface Props {
  timestamps: DataFrameDef[];
  defaultValue: DataFrameDef[] | undefined;
  onValueChange: (val: number[]) => void;
}

const dotStyle = {
  width: 0.1,
  borderRadius: 0,
  border: 0,
};

export const EventTimestampsRange = ({ timestamps, defaultValue, onValueChange }: Props) => {
  const initialRangeSet = useRef(false);
  const { /*minTimestamp, maxTimestamp,*/ timestampsList } = useMemo(() => {
    if (!timestamps?.length) return { minTimestamp: null, maxTimestamp: null, timestampsList: [] };
    /**Sorted in useQuery hook */
    // timestamps.sort((a, b) => {
    //   const dateA = a.timestamp;
    //   const dateB = b.timestamp;
    //   return dateA - dateB; // Changed to dateA - dateB
    // });

    const minTimestamp = timestamps[0].timestamp;
    const maxTimestamp = timestamps[timestamps.length - 1].timestamp;
    const timestampsList: number[] = [];
    const step = 60 * 60; // seconds in hour
    let i = minTimestamp;
    do {
      timestampsList.push(i);
      i += step;
    } while (i <= maxTimestamp);
    timestampsList.push(i + step);
    /**Propagate initial value to parent if no default value */
    if (timestamps?.length && !initialRangeSet.current && !defaultValue?.length) {
      initialRangeSet.current = true;
      onValueChange(timestampsList.slice(0, 1));
    }
    return { minTimestamp, maxTimestamp, timestampsList };
  }, [timestamps]);

  const defaultValueIndexes = useMemo(() => {
    if (defaultValue && defaultValue.length > 1) {
      const defStart = defaultValue[0].timestamp;
      const defEnd = defaultValue[defaultValue.length - 1].timestamp;

      const startIndex = findLastIndex(timestampsList, (ts) => ts <= defStart);
      const endIndex = timestampsList.findIndex((ts) => ts >= defEnd);

      return [startIndex, endIndex];
    }
    return [0, 1];
  }, [defaultValue, timestampsList]);

  const sliderMarks = useMemo(() => {
    const marks: Record<number, MarkObj> = {};
    timestampsList.forEach((t, i) => {
      marks[i] = {
        label: moment(t * 1000).format('ddd DD-MMM-YYYY HH:mm'),
        style: {
          top: -40,
          display: i === 0 || i === timestampsList.length - 1 ? 'block' : 'none',
          padding: '2px 0',
          borderRadius: 0,
        },
      };
    });

    return marks;
  }, [timestampsList]);

  const sliderHandle = (e: ReactElement<HandleProps>, key: number, value: ReactNode) => {
    return {
      ...e,
      props: {
        ...e.props,
        style: {
          ...e.props.style,
          top: 20,
          boxShadow: 'none',
          width: 20,
          height: 20,
          borderRadius: 0,
          backgroundColor: '#0054ff',
          transform: 'translateX(-50%) rotate(-45deg)',
        },
        children: [
          <div
            key={key}
            style={{
              top: -5,
              transform: 'translateX(-50%) rotate(45deg)',
              position: 'relative',
              width: 'fit-content',
              backgroundColor: '#0054ff',
              color: '#ffffff',
              padding: 2,
              borderRadius: 3,
            }}
          >
            {value}
          </div>,
        ],
      },
    };
  };

  return (
    <div id="FramesRange">
      <Range
        defaultValue={defaultValueIndexes}
        draggableTrack
        range={true}
        className="frames-range"
        min={0}
        max={timestampsList.length - 1}
        marks={sliderMarks}
        dotStyle={dotStyle}
        handleRender={(props, origin) =>
          sliderHandle(props, origin.index, sliderMarks[origin.value]?.label)
        }
        onChange={(e: number | number[]) => {
          if (Array.isArray(e)) {
            onValueChange(timestampsList.slice(e[0], e[1] + 1));
          }
        }}
      />
    </div>
  );
};
