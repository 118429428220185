import { findLastIndex } from 'lodash';

import { KeyFramesDef } from '../model/definitions/KeyFramesDef';
import { MapPositionControlDef } from '../model/definitions/MapPositionControlDef';

export function getPlayKeyframes(
  currentTime: number,
  keyframesSorted: KeyFramesDef[],
  initialPosition: MapPositionControlDef,
) {
  if (currentTime < +keyframesSorted[0].timeInMS!) {
    return [
      { ...initialPosition, time: 0, transitionType: 'LINEAR' },
      {
        ...keyframesSorted[0].mapPositionControl,
        time: +keyframesSorted[0].timeInMS!,
        transitionType: keyframesSorted[0].transitionType,
      },
    ];
  }

  const index = findLastIndex(keyframesSorted, (k: KeyFramesDef) => +k.timeInMS! < currentTime);
  return [
    keyframesSorted[index]
      ? {
          ...keyframesSorted[index]?.mapPositionControl,
          time: +keyframesSorted[index].timeInMS!,
          transitionType: keyframesSorted[index]?.transitionType,
        }
      : null,
    keyframesSorted[index + 1]
      ? {
          ...keyframesSorted[index + 1]?.mapPositionControl,
          time: +keyframesSorted[index + 1].timeInMS!,
          transitionType: keyframesSorted[index + 1]?.transitionType,
        }
      : null,
  ];
}

export function shouldFly(
  current: MapPositionControlDef | null,
  next: MapPositionControlDef | null,
) {
  if (!next || !current) return false;
  return Boolean(
    current.latitude?.toFixed(2) !== next.latitude?.toFixed(2) ||
      current.longitude?.toFixed(2) !== next.longitude?.toFixed(2) ||
      current.zoom?.toFixed(2) !== next.zoom?.toFixed(2),
  );
}

export function getCurrentMapPosition(
  activeTime: number,
  keyframesSorted: KeyFramesDef[],
  initialPosition: MapPositionControlDef,
) {
  if (!keyframesSorted.length) return initialPosition;
  // if (activeTime < keyframesSorted[0].timeInMS!) return initialPosition;
  const index = findLastIndex(keyframesSorted, (k: KeyFramesDef) => +k.timeInMS! <= activeTime);
  return keyframesSorted[index]?.mapPositionControl;
}
