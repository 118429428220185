import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { queryClient } from '../../..';
import { axiosInstance } from '../../../core/api/axiosInstance';
import { key as getSchedulersKey } from './useGetSchedulers';

export const useEditScheduler = () => {
  return useMutation(
    async (data: {
      id: string;
      name: string;
      description: string;
      cronExpression: string;
      projectId: string;
    }) => {
      return await axiosInstance.put(`scheduler`, data);
    },
    {
      onSuccess: () => {
        toast.success('Scheduler edited successfully!');
        queryClient.invalidateQueries(getSchedulersKey);
      },
      onError: () => {
        toast.error('Error editing scheduler!');
      },
    },
  );
};
