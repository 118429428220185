import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Loader from '../../../dashboard/components/loader-NEW/Loader';
import { useGetPublishedItem } from '../../../dashboard/queries-NEW/useGetPublishedItem';
import { useGetPublicPublishedItem } from '../../hooks/useGetPublicPublishedItem';
import { AntContent } from '../../layout/Layout';
import MarketplaceContext from '../../marketplaceContext';
import RelatedContent from '../../molecules/RelatedContent/RelatedContent';
import Thumbnails from '../../molecules/Thumbnails/Thumbnails';
import DetailsPageInformation from './DetailsPageInformation/DetailsPageInformation';

const DetailsPage = () => {
  const id = useParams() as unknown as { id: string };
  const { tokenEmail, admin, authenticated } = useContext(MarketplaceContext);
  const path = location.pathname.includes('/my-store/');

  const {
    data: published,
    isLoading,
    refetch,
    isFetching,
  } = useGetPublishedItem(id.id, authenticated);
  const {
    data: publicPublished,
    isLoading: publicLoading,
    refetch: publicRefetch,
    isFetching: publicIsFetching,
  } = useGetPublicPublishedItem(id.id, authenticated);

  useEffect(() => {
    authenticated ? refetch() : publicRefetch();
  }, [id]);

  const data = authenticated ? published : publicPublished;

  return (
    <>
      {isLoading || publicLoading || isFetching || publicIsFetching ? (
        <div className="flex justify-center">
          <Loader />
        </div>
      ) : (
        <AntContent>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-10 lg:gap-28">
            <Thumbnails item={data} authenticated={authenticated} />
            <DetailsPageInformation
              item={data}
              isAdmin={admin}
              path={path}
              email={tokenEmail}
              authenticated={authenticated}
            />
          </div>

          {!path && (
            <RelatedContent
              id={data?.id}
              title="RELATED"
              email={tokenEmail}
              authenticated={authenticated}
            />
          )}
        </AntContent>
      )}
    </>
  );
};

export default DetailsPage;
