import { IconType } from 'react-icons';

const Settings: IconType = (props) => {
  const { size, color } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 27 27"
      stroke="currentColor"
      strokeWidth="0"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 4H20.4857C20.0571 1.7 18.3429 0 16.2857 0C14.2286 0 12.5143 1.7 12.0857 4H0V6H12.0857C12.5143 8.3 14.2286 10 16.2857 10C18.3429 10 20.0571 8.3 20.4857 6H24V4ZM16.2857 8C14.8286 8 13.7143 6.7 13.7143 5C13.7143 3.3 14.8286 2 16.2857 2C17.7429 2 18.8571 3.3 18.8571 5C18.8571 6.7 17.7429 8 16.2857 8ZM0 20H3.51429C3.94286 22.3 5.65714 24 7.71429 24C9.77143 24 11.4857 22.3 11.9143 20H24V18H11.9143C11.4857 15.7 9.77143 14 7.71429 14C5.65714 14 3.94286 15.7 3.51429 18H0V20ZM7.71429 16C9.17143 16 10.2857 17.3 10.2857 19C10.2857 20.7 9.17143 22 7.71429 22C6.25714 22 5.14286 20.7 5.14286 19C5.14286 17.3 6.25714 16 7.71429 16Z"
        fill={color}
      />
    </svg>
  );
};

export default Settings;
