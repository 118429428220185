const Next = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 1.32173e-09C0.734785 1.32173e-09 0.480432 0.105357 0.292895 0.292893C0.105359 0.48043 1.9073e-06 0.734784 1.9073e-06 1C-6.35767e-07 1.0013 -6.35767e-07 1.0026 1.9073e-06 1.00391V9V16.9961C-6.35507e-07 16.9974 -6.35507e-07 16.9987 1.9073e-06 17C1.9073e-06 17.2652 0.105359 17.5196 0.292895 17.7071C0.480432 17.8946 0.734785 18 1 18C1.21304 17.9996 1.42037 17.9312 1.5918 17.8047H1.59375C1.60299 17.7977 1.61211 17.7905 1.6211 17.7832L12.541 9.8418C12.6819 9.75116 12.7978 9.62658 12.878 9.47949C12.9582 9.33241 13.0001 9.16753 13 9C12.9999 8.83588 12.9594 8.67431 12.8821 8.52955C12.8047 8.38478 12.693 8.26128 12.5566 8.16992L1.59375 0.195312C1.42179 0.068438 1.2137 -1.09826e-05 1 1.32173e-09ZM16.5 1.32173e-09C15.672 1.32173e-09 15 0.672 15 1.5V16.5C15 17.328 15.672 18 16.5 18C17.328 18 18 17.328 18 16.5V1.5C18 0.672 17.328 1.32173e-09 16.5 1.32173e-09Z"
        fill="#678299"
      />
    </svg>
  );
};

export default Next;
