import { Alert } from 'antd';
import { Select } from 'flowbite-react';
import React, { useEffect } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useSelector } from 'react-redux';

import Input from '../../../../atoms/input/Input';
import { useGetPointUnits } from '../../../../core/api/mapLayers/useGetUnits';
import {
  useGetSymbolParameters,
  useGetSymbolSources,
  useGetSymbolTimestamps,
} from '../../../../core/api/point/useGetSymbolParameters';
import { MAX_ZINDEX_VALUE } from '../../../../model/constants/constants';
import { C9ProjectDef } from '../../../../model/definitions/C9ProjectDef';
import { MapPanelDef } from '../../../../model/definitions/MapPanelDef';
import { PointDataFrameDef } from '../../../../model/definitions/PointDataFrameDef';
import {
  PointSourceDef,
  SymbolLayerDef,
  SymbolPointType,
} from '../../../../model/definitions/SymbolLayerDef';
import { RootState } from '../../../../store/store';
import useGetAllIconSets from '../../../dashboard/queries-NEW/useGetAllIconSets';
import InputNumber from '../../../marketplace-new/atoms/FormatNumber/FormatNumber';
import { FirstLayerRange } from '../../modals/frameRangeHelpers';
import { PointTimestampsRange } from '../../modals/PointTimestampsRange';

interface MapLayersProps {
  bounds: [number, number, number, number];
  name?: string;
  setName: (e: string) => void;
  mapLayer: MapPanelDef;
  onMapLayerChange: (propertyPath: keyof MapPanelDef, newValue: SymbolLayerDef[]) => void;
  z: number;
  setZ: React.Dispatch<React.SetStateAction<number>>;
  unit: string | undefined;
  setUnit: React.Dispatch<React.SetStateAction<string | undefined>>;
  source?: PointSourceDef;
  setSource: (e?: PointSourceDef) => void;
  dataProductId: string | null;
  setDataProductId: (e: string) => void;
  gribLayerEdit?: SymbolLayerDef;
  rangeFromFirstLayer: FirstLayerRange | null;
  pointDataFrames: PointDataFrameDef[] | undefined;
  setPointDataFrames: React.Dispatch<React.SetStateAction<PointDataFrameDef[] | undefined>>;
  pointParameter: string | undefined;
  setPointParameter: React.Dispatch<React.SetStateAction<string | undefined>>;
  pointType?: SymbolPointType;
  iconPackId?: string;
  setIconPackId: React.Dispatch<React.SetStateAction<string | undefined>>;
}
const MapSymbolModelLayers = ({
  bounds,
  name,
  setName,
  z,
  setZ,
  unit,
  setUnit,
  source,
  setSource,
  dataProductId,
  setDataProductId,
  gribLayerEdit,
  rangeFromFirstLayer,
  pointDataFrames,
  setPointDataFrames,
  pointParameter,
  setPointParameter,
  iconPackId,
  setIconPackId,
  pointType = SymbolPointType.Forecast,
}: MapLayersProps) => {
  const {
    data: sourceQuery,
    refetch: refetchSymbolSources,
    isLoading: isLoadingSources,
  } = useGetSymbolSources(bounds, pointType);
  const project = useSelector<RootState, C9ProjectDef>((state) => state.project.present.project);
  const { data: parametersQuery, isLoading } = useGetSymbolParameters(
    dataProductId as string,
    bounds,
    pointType,
  );

  const { data: timestampsQuery, refetch: refetchSymbolTimestamp } = useGetSymbolTimestamps(
    dataProductId as string,
    pointParameter,
    bounds,
    pointType,
    project?.properties.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone,
  );
  useHotkeys(
    'ctrl+shift+o, command+shift+o',
    (ev) => {
      ev.preventDefault();
      if (!timestampsQuery?.boundingBoxCheckUrl) return;
      window.open(
        `${process.env.REACT_APP_POINT_API_BASE_URL}${timestampsQuery.boundingBoxCheckUrl}`,
        '_blank',
      );
    },
    [timestampsQuery],
  );
  const { data: unitsList, isLoading: unitsLoading } = useGetPointUnits(
    pointParameter === 'WindSpeedAndDirection' ? 'WindSpeed' : pointParameter,
  );
  const { data: templates, isLoading: iconsSetLoading } = useGetAllIconSets({
    parameter: pointParameter,
    page: 0,
    size: 1000,
  });

  useEffect(() => {
    if (!source && sourceQuery && sourceQuery.dataProducts.length > 0) {
      const defaultSource = sourceQuery.dataProducts.find(
        (source) => source.sourceName === 'Default',
      );
      if (defaultSource) {
        setDataProductId(defaultSource.dataProductId);
        setSource(defaultSource);
      }
    }
  }, [source, sourceQuery]);

  useEffect(() => {
    if (!iconPackId && templates && templates.content.length > 0) {
      const iconPack =
        templates.content.find((iconPack) => iconPack.isDefault) || templates.content[0];
      setIconPackId(iconPack.id);
    }
  }, [iconPackId, templates]);
  useEffect(() => {
    if (
      pointDataFrames?.length === 0 &&
      timestampsQuery &&
      timestampsQuery?.timestamps?.length > 1
    ) {
      setPointDataFrames([timestampsQuery?.timestamps[0], timestampsQuery?.timestamps[1]]);
    }
  }, [pointDataFrames, timestampsQuery]);
  const sourceSelect = () =>
    sourceQuery ? (
      <Select
        className={'w-full'}
        style={{ width: '100%' }}
        onChange={(e) => {
          setDataProductId(e.target.value);
          const selectedSource = sourceQuery.dataProducts?.find(
            (source) => source.dataProductId === e.target.value,
          );
          setSource(selectedSource);
        }}
        value={source?.dataProductId}
        required
      >
        <option value={''}>
          {sourceQuery.dataProducts?.length === 0 ? 'No sources available' : 'Select source'}
        </option>
        {sourceQuery.dataProducts?.map((k: { sourceName: string; dataProductId: string }) => (
          <option key={k.dataProductId} value={k.dataProductId}>
            {k.sourceName}
          </option>
        ))}
      </Select>
    ) : null;

  const paramSelect = () =>
    parametersQuery ? (
      <Select
        className={'w-full'}
        style={{ width: '100%' }}
        onChange={(e) => {
          // setUnit(e.target.value);
          setPointParameter(e.target.value);
          setName(e.target.value);
        }}
        value={pointParameter}
        required
      >
        <option value={''}>
          {parametersQuery.parameters.length === 0 ? 'No parameters available' : 'Select parameter'}
        </option>
        {parametersQuery.parameters.map((k) => (
          <option key={k.name} value={k.name}>
            {k.name}
          </option>
        ))}
      </Select>
    ) : null;

  const unitSelect = () =>
    unitsList ? (
      <Select
        className={'w-full'}
        style={{ width: '100%' }}
        onChange={(e) => {
          setUnit(e.target.value);
        }}
        value={unit}
        required
      >
        <option value={''}>{unitsList?.length === 0 ? 'No units available' : 'Select unit'}</option>
        {unitsList?.map((k) => (
          <option key={k.value} value={k.value}>
            {k.key}
          </option>
        ))}
      </Select>
    ) : null;

  const iconSetSelect = () =>
    templates?.content ? (
      <Select
        className={'w-full'}
        style={{ width: '100%' }}
        onChange={(e) => {
          setIconPackId(e.target.value);
        }}
        value={iconPackId}
        required
      >
        <option value={''}>
          {templates.content.length === 0 ? 'No icon set available' : 'Select icon set'}
        </option>
        {templates.content?.map((k) => (
          <option key={k.id} value={k.id}>
            {k.name}
          </option>
        ))}
      </Select>
    ) : null;

  useEffect(() => {
    refetchSymbolSources();
  }, [pointType]);

  useEffect(() => {
    if (pointParameter) {
      refetchSymbolTimestamp();
    }
  }, [pointParameter, pointType]);

  useEffect(() => {
    // Set default value for pointDataFrames when it loads.. in a bad way
    if (
      pointDataFrames == undefined &&
      timestampsQuery &&
      timestampsQuery.timestamps &&
      timestampsQuery.timestamps.length > 1
    ) {
      setPointDataFrames(timestampsQuery.timestamps.slice(0, 2));
    }
  }, [timestampsQuery, pointDataFrames, setPointDataFrames]);
  return (
    <>
      <h3>Point Layer</h3>
      <div className="layers-wrap model-layer">
        <div className="layer-item-flex-wrapper">
          <div className="layer-item">
            <>
              <label>
                <span>*</span> Source
              </label>
              {isLoadingSources ? <div className={'loading'}>Loading sources</div> : sourceSelect()}
            </>
          </div>
          {pointParameter && (
            <div className="layer-item">
              <label>
                <span>*</span> Layer name
              </label>
              <Input
                placeholder="Type point layer name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
          )}
        </div>
        <div className="layer-item-flex-wrapper">
          {source && (
            <div className="layer-item">
              <>
                <label>
                  <span>*</span> Parameter
                </label>
                {isLoading ? <div className={'loading'}>Loading parameters</div> : paramSelect()}
              </>
            </div>
          )}
          {pointParameter && (
            <div className="layer-item">
              <label>
                <span>*</span> Layer level
              </label>
              <InputNumber
                placeholder="0"
                value={z}
                onInputChange={(e) => setZ(e)}
                required
                min={0}
                max={MAX_ZINDEX_VALUE}
                step={1}
              />
            </div>
          )}
        </div>
        <div className="layer-item-flex-wrapper mr-[3em]">
          {source && pointParameter && (
            <div className="layer-item">
              <label>
                <span>*</span> {pointParameter !== 'WeatherType' ? 'Unit' : 'Icon Set'}
              </label>
              {pointParameter !== 'WeatherType' ? (
                unitsLoading ? (
                  <div className="loading">Loading units</div>
                ) : (
                  unitSelect()
                )
              ) : iconsSetLoading ? (
                <div className="loading">Loading icon set</div>
              ) : (
                iconSetSelect()
              )}
            </div>
          )}
        </div>
        {timestampsQuery && timestampsQuery.timestamps.length > 0 ? (
          <>
            {timestampsQuery.hasTimezoneInconsistencies && (
              <Alert message="The choice of days is limited due to time zones" banner />
            )}
            <PointTimestampsRange
              timestamps={timestampsQuery.timestamps}
              defaultValue={pointDataFrames}
              isObserved={pointType === SymbolPointType.Observed}
              onValueChange={setPointDataFrames}
            />
          </>
        ) : (
          'No data...'
        )}
        <div className="layer-item-flex-wrapper">
          <div className="layer-item"></div>
        </div>
      </div>
    </>
  );
};
export default MapSymbolModelLayers;
