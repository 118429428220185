import { useQuery } from 'react-query';

import { FontInterface } from '../../pages/playground/properties/panels/FontProperties';
import { axiosInstance } from './axiosInstance';

const key = ['FONT_VARIANT'];

export const useGetVariants = (fonts: FontInterface[]) => {
  const fontsIds = fonts.map((font) => font.id);
  return useQuery(
    [...key, fontsIds],
    async () => {
      const fontPromises = fonts.map((font) => axiosInstance.get(`font-variant/font/${font.id}`));
      const fontResponses = await Promise.all(fontPromises);

      return fontResponses.map((response, index) => {
        return { fontVariants: [...response.data], name: fonts[index].name };
      });
    },
    { enabled: !!fonts.length, staleTime: Infinity },
  );
};
