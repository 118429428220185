import Select, { SingleValue } from 'react-select';

function FilterItems({
  activeTab,
  type,
  setActiveTab,
}: {
  activeTab: { value: string; label: string };
  type: string;
  setActiveTab: (activeTab: { value: string; label: string }) => void;
}) {
  const options = [
    { value: 'USER', label: `My ${type}s` },
    { value: 'ORGANIZATION', label: `Organization's ${type}s` },
  ];

  const handleSelectChange = (selected: SingleValue<{ value: string; label: string }>) => {
    if (selected) setActiveTab(selected);
  };

  return (
    <div className="w-[200px] ml-auto lg:ml-[10px]">
      <Select
        onChange={handleSelectChange}
        value={activeTab}
        options={options}
        isSearchable={false}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: state.isFocused ? '#2176ff' : 'rgba(0, 0, 0, 0.2)',
            borderRadius: '0.5rem',
          }),
        }}
      />
    </div>
  );
}

export default FilterItems;
