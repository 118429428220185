import { ForecastWDElementDef } from '../model/definitions/ForecastWDElementDef';
import { ImagePanelDef } from '../model/definitions/ImagePanelDef';
import { ObservedWDElementDef } from '../model/definitions/ObservedWDElementDef';
import { TextPanelDef } from '../model/definitions/TextPanelDef';
import { VideoPanelDef } from '../model/definitions/VideoPanelDef';
import { WeatherPosterDef } from '../model/definitions/WeatherPosterDef';

const findXPosition = (
  elements: Array<
    | VideoPanelDef
    | ObservedWDElementDef
    | ImagePanelDef
    | TextPanelDef
    | ForecastWDElementDef
    | WeatherPosterDef
  >,
) => {
  const positions = elements.map((element) => element.positionControl.x);
  return Math.min(...positions);
};
const findYPosition = (
  elements: Array<
    | VideoPanelDef
    | ObservedWDElementDef
    | ImagePanelDef
    | TextPanelDef
    | ForecastWDElementDef
    | WeatherPosterDef
  >,
) => {
  const positions = elements.map((element) => element.positionControl.y);
  return Math.min(...positions);
};

const findWidth = (
  elements: Array<
    | VideoPanelDef
    | ObservedWDElementDef
    | ImagePanelDef
    | TextPanelDef
    | ForecastWDElementDef
    | WeatherPosterDef
  >,
) => {
  const secondXs = elements.map((element) => element.positionControl.x + element.positionControl.w);
  const x2 = Math.max(...secondXs);
  return x2 - findXPosition(elements);
};
const findHeight = (
  elements: Array<
    | VideoPanelDef
    | ObservedWDElementDef
    | ImagePanelDef
    | TextPanelDef
    | ForecastWDElementDef
    | WeatherPosterDef
  >,
) => {
  const secondYs = elements.map((element) => element.positionControl.y + element.positionControl.h);
  const y2 = Math.max(...secondYs);
  return y2 - findYPosition(elements);
};

export { findHeight, findWidth, findXPosition, findYPosition };
