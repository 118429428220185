import { CaretDownOutlined, CaretUpOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Checkbox, InputNumber } from 'antd';
import { useCallback } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useDispatch, useSelector } from 'react-redux';

import { useGetOrderedIconSet } from '../../../core/api/bias/bias-group/useGetOrderedIconSet';
import { useGetImageMultimedia } from '../../../core/api/useGetMultimedia';
import { stringToUnit } from '../../../helpers/stringToUnit';
import { ForecastWDElementDef } from '../../../model/definitions/ForecastWDElementDef';
import { ValueTypeEnum } from '../../../model/enums/ValueTypeEnum';
import { displayPointValue } from '../../../molecules/canvasElements/showPointDataValue';
import { removeDuplicateIds } from '../../../molecules/canvasElements/utils';
import {
  ActiveDef,
  removeFromBiasFilter,
  updateForecastBiasValue,
} from '../../../store/slices/active-slice';
import { RootState } from '../../../store/store';
import { IconsDropDown } from './IconsDropDown';

interface ForecastRowProps {
  item: ForecastWDElementDef;
  confirmed: boolean;
  setConfirmed: (id: string) => void;
  originalValue: number;
  biasValue: number;
}
export const ForecastRow = ({ item, confirmed, setConfirmed, biasValue }: ForecastRowProps) => {
  const dispatch = useDispatch();
  const updateForecastBias = useCallback(
    (id: string, value: number) => {
      dispatch(updateForecastBiasValue({ value, id }));
    },
    [dispatch],
  );
  const { biasedValues, addedBiasedValues } = useSelector<RootState>(
    (state) => state.active,
  ) as ActiveDef;
  const { data: icons } = useGetOrderedIconSet(item.forecastWDSource?.value.iconSetVersionId);
  const { data: url } = useGetImageMultimedia(
    item.forecastWDSource?.originalValue?.value?.versionId,
    false,
  );
  const {
    forecastWDSource: { value, valueType, parameterType, originalValue, unitOfMeasurement },
  } = item;
  const indexOfBias = (id: string) => biasedValues.findIndex((item) => item.id === id);
  const indexOfConfirmed = (id: string) =>
    removeDuplicateIds(addedBiasedValues).findIndex((item) => item.id === id);
  function isForecast(obj: any): obj is ForecastWDElementDef {
    if (obj) return 'forecastWDSource' in obj;
    return false;
  }
  const getBiasValue = (id: string, unit: string) => {
    const element =
      removeDuplicateIds(addedBiasedValues)[indexOfConfirmed(id)] || biasedValues[indexOfBias(id)];
    if (isForecast(element)) {
      return element.forecastWDSource.value?.find((val: any) => val.unit === unit)?.value;
    }
  };
  const getOriginalValue = (id: string, unit: string) => {
    const element =
      removeDuplicateIds(addedBiasedValues)[indexOfConfirmed(id)] || biasedValues[indexOfBias(id)];
    if (isForecast(element)) {
      return element.forecastWDSource.value?.find((val: any) => val.unit === unit)?.originalValue;
    }
  };
  const handleUp = useCallback(() => {
    !confirmed && updateForecastBias(item.id, biasValue + 1);
  }, [biasValue, confirmed, item.id, updateForecastBias]);
  const handleDown = useCallback(() => {
    !confirmed && updateForecastBias(item.id, biasValue - 1);
  }, [biasValue, confirmed, item.id, updateForecastBias]);
  useHotkeys(
    'up',
    (ev) => {
      ev.preventDefault();
      !confirmed && handleUp();
    },
    [biasValue, confirmed],
  );
  useHotkeys(
    'down',
    (ev) => {
      ev.preventDefault();
      !confirmed && handleDown();
    },
    [biasValue, confirmed],
  );
  const remove = (id: string) => {
    dispatch(removeFromBiasFilter({ id }));
  };
  /* function subtractNumbers(num1: number, num2: number) {
    const scale = 10 ** 10;
    const intNum1 = Math.round(num1 * scale);
    const intNum2 = Math.round(num2 * scale);

    // Perform the addition with integers
    const resultInt = intNum1 - intNum2;

    // Convert the result back to the desired precision
    return resultInt / scale;
  } */
  console.log(
    displayPointValue(valueType, value, parameterType, false, undefined, unitOfMeasurement, true),
  );

  return (
    <div key={item.id} className={'grid grid-cols-[25%_15%_13%_13%_5%_5%_5%] gap-5 mb-4'}>
      <span className={'italic font-light'}>{item.forecastWDSource.location.name}</span>
      <span className={'text-center'}>
        {valueType === ValueTypeEnum.IMAGE
          ? displayPointValue(
              valueType,
              originalValue?.versionId,
              originalValue?.parameterType,
              originalValue?.jsonAnimation,
              url,
              originalValue?.unitOfMeasurement,
            )
          : displayPointValue(
              valueType,
              value,
              parameterType,
              false,
              undefined,
              unitOfMeasurement,
              true,
            )}
      </span>
      {valueType === ValueTypeEnum.NUMERICAL ? (
        <InputNumber
          disabled={confirmed}
          size={'small'}
          className={'bias-input'}
          value={Math.round(biasValue * 100) / 100}
          onChange={(e) => typeof e === 'number' && !confirmed && updateForecastBias(item.id, e)}
        />
      ) : (
        <IconsDropDown icons={icons} item={item} confirmed={confirmed} />
      )}
      <div className={'text-white font-bold'}>
        {item.forecastWDSource.valueType === ValueTypeEnum.NUMERICAL &&
          (getOriginalValue(item.id, item.forecastWDSource.unitOfMeasurement) <
          getBiasValue(item.id, item.forecastWDSource.unitOfMeasurement) ? (
            <CaretUpOutlined style={{ color: 'green' }} />
          ) : getOriginalValue(item.id, item.forecastWDSource.unitOfMeasurement) >
            getBiasValue(item.id, item.forecastWDSource.unitOfMeasurement) ? (
            <CaretDownOutlined style={{ color: 'red' }} />
          ) : (
            <span className={'text-yellow-200'}>=</span>
          ))}
        {item.forecastWDSource.valueType === ValueTypeEnum.NUMERICAL &&
          getBiasValue(item.id, item.forecastWDSource.unitOfMeasurement) -
            getOriginalValue(item.id, item.forecastWDSource.unitOfMeasurement)}
      </div>
      <span className={'text-center'}>{stringToUnit(item.forecastWDSource.unitOfMeasurement)}</span>
      <Checkbox checked={confirmed} onChange={() => setConfirmed(item.id)} />
      <Button
        onClick={() => remove(item.id)}
        title={'Remove from filter'}
        icon={<CloseOutlined />}
        danger
        size={'small'}
        className={'flex items-center justify-center'}
      />
    </div>
  );
};
