import { useMutation } from 'react-query';

import { PickerDef } from '../../../model/definitions/PickerDef';
import { axiosInstance } from '../axiosInstance';

const apiUrl = 'grib-data/grib-model';
const key = ['GRIB_SOURCE_MODEL'];

export const useGetGribModelType = () => {
  return useMutation(
    key,
    async ({
      parameterTypeId,
      sourceId,
      bounds,
    }: {
      parameterTypeId: number;
      sourceId: number;
      bounds: [number, number, number, number];
    }) => {
      const { data } = await axiosInstance.post(apiUrl, {
        parameterTypeId,
        sourceId,
        location: {
          leftLongitude: bounds[0],
          rightLongitude: bounds[1],
          upperLatitude: bounds[2],
          lowerLatitude: bounds[3],
        },
      });
      return data as Array<PickerDef>;
    },
  );
};
