import { useState } from 'react';
import { useMutation } from 'react-query';

import { axiosInstance } from '../../../core/api/axiosInstance';
import { queryClient } from '../../../index';
import { MutateWithProgress } from '../components/upload/Upload';

const useUploadMultimedia = (): MutateWithProgress<any> => {
  const [progress, setProgress] = useState<ProgressEvent>();
  const mutation = useMutation(
    async ({
      fileData,
      abort,
      type,
    }: {
      fileData: FormData;
      abort: AbortController;
      type: string;
    }) => {
      const { data } = await axiosInstance.post(`multimedia/uploadFile`, fileData, {
        signal: abort.signal,
        headers: { 'content-type': 'multipart/form-data' },
        onUploadProgress: function (progressEvent) {
          setProgress(progressEvent);
        },
      });
      return data;
    },
    {
      onSuccess: (data, args) => queryClient.invalidateQueries(args.type),
    },
  );
  return { progress, mutation };
};

export default useUploadMultimedia;
