import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { axiosInstance } from '../../../core/api/axiosInstance';
import { queryClient } from '../../../index';
import { key } from './useGetSqsConfigs';

export const useDeleteSqsConfig = () => {
  return useMutation(
    async (id: number) => {
      return await axiosInstance.delete(`sqs-configs/${id}`);
    },
    {
      onSuccess: () => {
        toast.success('SQS config deleted!');
        queryClient.invalidateQueries(key);
      },
      onError: () => {
        toast.error('Error deleting SQS config!');
      },
    },
  );
};
