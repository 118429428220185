import { Select, ToggleSwitch } from 'flowbite-react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../../../../atoms/button/Button';
import { ElementsEnum } from '../../../../../core/ui/enums/ElementsEnum';
import { WeatherDataSpaceDef } from '../../../../../model/definitions/WeatherDataSpaceDef';
import { TimeStepEnum } from '../../../../../model/enums/TimeStepEnum';
import { ActiveDef, setPropertyGridActiveHash } from '../../../../../store/slices/active-slice';
import {
  enableIndicator,
  updateIndicatorFormat,
  updateIndicatorRound,
  updateIndicatorStep,
  updateRelativeTime,
} from '../../../../../store/slices/project-slice';
import { RootState } from '../../../../../store/store';
import GridItem from '../../shared/GridItem';
import GridWrapper from '../../shared/GridWrapper';
import { BoxStyle } from '../BoxStyle';
import { FontProperties } from '../FontProperties';
import { PositionControls } from '../PositionControls';
import { FormattingHelpPage } from './FormattingHelpPage';
import { formats } from './util';

interface TimeFrameIndicatorProps {
  space: WeatherDataSpaceDef;
  activeScene: string;
  mapId: string;
}
export const TimeFrameIndicator = ({ space, activeScene, mapId }: TimeFrameIndicatorProps) => {
  const dispatch = useDispatch();
  const {
    enableTimeframeIndicator,
    timeframeIndicatorStep,
    mapTimeframeTextIndicator,
    timeframeIndicatorFormat,
    roundTimeframeIndicator,
    relativeTime,
  } = space;
  const { activeElement } = useSelector<RootState, ActiveDef>((state) => state.active);
  const [isCustom, setIsCustom] = useState<boolean>(false);
  const [isHelp, setIsHelp] = useState<boolean>(false);
  useEffect(() => {
    if (!Object.values(formats).includes(timeframeIndicatorFormat)) {
      setIsCustom(true);
    }
  }, [timeframeIndicatorFormat]);
  const stepOptions = Object.keys(TimeStepEnum).map((step) => (
    <option key={step} value={step}>
      {step}
    </option>
  ));
  const formatOptions = () => {
    const optionsArray = [];
    for (const key in formats) {
      optionsArray.push(
        // @ts-ignore
        <option key={key} value={formats[key as keyof formats]}>
          {key}
        </option>,
      );
    }
    return optionsArray;
  };
  const handleStepChange = (step: string) => {
    onFocus('timeframeIndicatorStep');
    dispatch(
      updateIndicatorStep({ activeScene, mapId, timeframeIndicatorStep: step as TimeStepEnum }),
    );
  };
  const handleRound = (val: boolean) => {
    onFocus('timeframeIndicatorStep');
    dispatch(updateIndicatorRound({ activeScene, mapId, rounded: val }));
  };
  const handleRelativeTime = (val: boolean) => {
    onFocus('relativeTime');
    dispatch(updateRelativeTime({ activeScene, mapId, relativeTime: val }));
  };
  const handleFormatChange = (format: string, custom: boolean) => {
    onFocus('timeframeIndicatorFormat');
    if (format === 'CUSTOM') {
      !isCustom && setIsCustom(true);
      dispatch(
        updateIndicatorFormat({
          activeScene,
          mapId,
          format: timeframeIndicatorFormat,
        }),
      );
      handleRelativeTime(false);
    } else {
      Object.values(formats).includes(format) && !custom && setIsCustom(false);
      const searchString = /(?<!d)d{2}(?!d)/g;
      const replaceString = 'ddd';

      format = format.replace(searchString, replaceString);
      dispatch(
        updateIndicatorFormat({
          activeScene,
          mapId,
          format,
        }),
      );
    }
  };
  const handleEnable = (enabled: boolean) => {
    onFocus('enableTimeframeIndicator');
    dispatch(enableIndicator({ activeScene, mapId, enabled }));
  };
  function onFocus(path: string) {
    dispatch(setPropertyGridActiveHash({ activeElement, focusedEl: path }));
  }
  return (
    <div className="prop-wrapper">
      <GridWrapper>
        <GridItem
          noBorderBg
          label="Enable indicator:"
          item={
            <ToggleSwitch checked={enableTimeframeIndicator} label={''} onChange={handleEnable} />
          }
        />
        <GridItem
          label="Step:"
          item={
            <Select
              value={timeframeIndicatorStep}
              onChange={(e) => handleStepChange(e.target.value)}
            >
              {stepOptions}
            </Select>
          }
        />
        <GridItem
          label="Format:"
          item={
            <Select
              value={
                Object.values(formats).includes(timeframeIndicatorFormat) && !isCustom
                  ? timeframeIndicatorFormat
                  : 'CUSTOM'
              }
              onChange={(e) => handleFormatChange(e.target.value, !isCustom)}
            >
              {formatOptions()}
            </Select>
          }
        />
        <GridItem
          label={isCustom ? 'Custom format:' : ''}
          item={
            isCustom ? (
              <div className={'flex items-top'}>
                <input
                  value={timeframeIndicatorFormat}
                  onChange={(e) => handleFormatChange(e.target.value, isCustom)}
                  style={{ width: '100%' }}
                />
                <Button
                  label={'?'}
                  shape={'round'}
                  onClick={() => setIsHelp(true)}
                  style={{ margin: 0 }}
                />
              </div>
            ) : null
          }
        />
        <GridItem
          noBorderBg
          label="Rounded value:"
          item={
            <ToggleSwitch label={''} checked={roundTimeframeIndicator} onChange={handleRound} />
          }
        />
        <GridItem
          noBorderBg
          label="Relative time:"
          item={
            <ToggleSwitch
              label={''}
              disabled={isCustom}
              checked={relativeTime && !isCustom}
              onChange={handleRelativeTime}
            />
          }
        />
      </GridWrapper>
      <div className={'ml-8 bg-black'}>
        {mapTimeframeTextIndicator && (
          <FontProperties
            layer={mapTimeframeTextIndicator}
            layerType={'mapTimeframeTextIndicator'}
            poster={mapId}
          />
        )}
        {isHelp && <FormattingHelpPage isOpen={isHelp} onClose={() => setIsHelp(false)} />}
        {mapTimeframeTextIndicator?.positionControl && (
          <PositionControls
            layer={'mapTimeframeTextIndicator'}
            position={mapTimeframeTextIndicator?.positionControl}
            poster={mapId}
          />
        )}
        {mapTimeframeTextIndicator && (
          <BoxStyle
            box={mapTimeframeTextIndicator?.boxDef}
            elementType={ElementsEnum.INDICATOR}
            parent={mapId}
          />
        )}
      </div>
    </div>
  );
};
