import { useDispatch, useSelector } from 'react-redux';

import Button from '../../atoms/button/Button';
import { C9ProjectDef } from '../../model/definitions/C9ProjectDef';
import { ActiveDef, setScene } from '../../store/slices/active-slice';
import { deleteScene } from '../../store/slices/project-slice';
import { RootState } from '../../store/store';

interface DeleteSceneModalProps {
  onClose?: any;
}

function DeleteSceneModal({ onClose }: DeleteSceneModalProps) {
  const dispatch = useDispatch();

  const project = useSelector<RootState, C9ProjectDef>((state) => state.project?.present?.project);
  const { activeScene } = useSelector<RootState, ActiveDef>((state) => state.active);

  const nextScene = async () => {
    const index = project.sceneDefs.findIndex((scene) => scene.id === activeScene);
    const sceneToDelete = activeScene;
    const nextScene = index === 0 ? project.sceneDefs[1].id : project.sceneDefs[index - 1].id;
    await dispatch(setScene({ activeScene: nextScene }));
    return sceneToDelete;
  };
  function onDelete(e: { stopPropagation: () => void }) {
    e.stopPropagation();
    nextScene().then((res) => {
      dispatch(deleteScene({ sceneId: res }));
    });
    onClose();
  }

  return (
    <div
      aria-hidden="false"
      className="fixed top-0 right-0 left-0 z-[1] h-modal overflow-y-auto overflow-x-hidden md:inset-0 md:h-full items-center justify-center flex bg-gray-900 bg-opacity-50 dark:bg-opacity-80"
      role="dialog"
    >
      <div className="relative h-full w-full p-4 md:h-auto max-w-md">
        <div className="confirmation-modal dark  relative rounded-lg bg-white shadow dark:bg-gray-700">
          <div className="flex items-start justify-between rounded-t dark:border-gray-600 p-2 border-b-0">
            <Button
              style={{ marginLeft: 'auto', height: ' auto' }}
              buttonType="link"
              onClick={onClose}
              icon={
                <svg
                  stroke="currentColor"
                  fill="none"
                  strokeWidth="0"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  className="h-5 w-5"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              }
            />
          </div>
          <div className="p-6 pt-0">
            <div className="text-center">
              <svg
                stroke="currentColor"
                fill="none"
                strokeWidth="0"
                viewBox="0 0 24 24"
                className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                ></path>
              </svg>
              <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                Are you sure you want to delete?
              </h3>
              <div className="flex justify-center modal-footer">
                <Button buttonType="danger" onClick={onDelete} label="Yes, I'm sure" />
                <Button buttonType="secondary" onClick={onClose} label="No, cancel" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteSceneModal;
