import { UploadOutlined } from '@ant-design/icons';
import { Button, Form, Input, Table, Tabs, TabsProps, Upload } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { ChangeEvent, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { useAddTranslation } from '../queries-NEW/useAddTranslation';
import { useEditTranslation } from '../queries-NEW/useEditTranslation';
import { useGetTranslation } from '../queries-NEW/useGetTranslation';

interface FormValues {
  name: string;
  key: string;
  dialect: string;
  description?: string;
  translationFile?: any;
  id?: string;
}

interface Translation {
  key: string;
  index: string;
  value: string;
}

function Translation() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [form] = useForm();
  const { data: translation } = useGetTranslation(id);
  const { mutate: addTranslation } = useAddTranslation();
  const { mutate: editTranslation } = useEditTranslation();
  const [transformedTranslationFile, setTransformedTranslationFile] = useState<any>();
  const [dateArray, setDateArray] = useState<string[]>([]);

  useEffect(() => {
    if (id && translation) {
      const initialValues: FormValues = {
        name: translation.name,
        key: translation.key,
        dialect: translation.dialect,
        description: translation.description,
        translationFile: {},
      };
      form.setFieldsValue(initialValues);
    }
  }, [id, translation, form]);

  useEffect(() => {
    if (translation?.translationFile) {
      const array: string[] = [];
      const transformedTranslationFile = Object.fromEntries(
        Object.entries(translation?.translationFile).map(([key, value]) => {
          if (Array.isArray(value)) {
            array.push(key);
            const obj = {};
            value.forEach((item, index) => {
              //@ts-ignore
              obj[index] = item;
            });
            return [key, obj];
          }
          return [key, value];
        }),
      );
      setDateArray(array);
      setTransformedTranslationFile(transformedTranslationFile);
    }
  }, [translation]);

  const transformBackTranslationFile = () => {
    return Object.fromEntries(
      Object.entries(transformedTranslationFile).map(([key, value]) => {
        if (dateArray.includes(key)) {
          //@ts-ignore
          const arr = Object.values(value);
          return [key, arr];
        }
        return [key, value];
      }),
    );
  };

  const formatLabel = (label: string) => {
    return (
      label.charAt(0).toUpperCase() +
      label
        .slice(1)
        .replace(/([A-Z])/g, ' $1')
        .toLowerCase()
    );
  };

  const handleValueChange = (e: ChangeEvent<HTMLInputElement>, key: string, item: string) => {
    setTransformedTranslationFile((prev: any) => ({
      ...prev,
      [key]: {
        ...prev[key],
        [item]: e.target.value,
      },
    }));
  };

  const handleKeyChange = (e: ChangeEvent<HTMLInputElement>, key: string, item: string) => {
    setTransformedTranslationFile((prev: any) => {
      const updatedTranslationFile = { ...prev };
      const customWords = updatedTranslationFile[key];
      const itemValue = customWords[item];
      const updatedCustomWords = {
        ...Object.fromEntries(
          Object.entries(customWords).map(([key, value]) => {
            if (key === item) {
              return [e.target.value, itemValue];
            }
            return [key, value];
          }),
        ),
      };
      updatedTranslationFile['customWords'] = updatedCustomWords;
      return updatedTranslationFile;
    });
  };

  const onFinish = (values: FormValues) => {
    const formData = new FormData();
    const translationData: FormValues = {
      name: values.name,
      key: values.key,
      dialect: values.dialect,
      description: values.description,
    };

    if (
      values.translationFile &&
      values.translationFile.fileList &&
      values.translationFile.fileList.length > 0
    ) {
      formData.append('translationFile', values.translationFile.fileList[0].originFileObj);
    } else if (id && translation && translation.translationFile) {
      translationData.translationFile = transformBackTranslationFile();
      translationData.id = translation.id;
    }

    formData.append('translation', JSON.stringify(translationData));

    if (id) {
      editTranslation(formData, {
        onSuccess() {
          navigate('/workspace/enterprise-properties/');
        },
      });
    } else {
      addTranslation(formData, {
        onSuccess() {
          navigate('/workspace/enterprise-properties/');
        },
      });
    }
  };

  const addNewRow = () => {
    const newRow = {
      key: '',
      value: '',
    };
    setTransformedTranslationFile((prev: any) => ({
      ...prev,
      ['customWords']: {
        ...prev['customWords'],
        [newRow.key]: newRow.value,
      },
    }));
  };

  const handleDelete = (index: string) => {
    setTransformedTranslationFile((prev: any) => {
      const updatedTranslationFile = { ...prev };
      delete updatedTranslationFile['customWords'][index];
      return updatedTranslationFile;
    });
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Default translation',
      children: transformedTranslationFile && (
        <div className="grid grid-cols-2 gap-10 p-5">
          {Object.entries(transformedTranslationFile).map(([key, value]) => {
            if (key === 'customWords' || !value) return;
            const columns = [
              {
                title: 'Key',
                dataIndex: 'index',
                key: 'index',
              },
              {
                title: 'Value',
                dataIndex: 'value',
                key: 'value',
                render: (value: string, item: Translation) => {
                  return (
                    <Input value={value} onChange={(e) => handleValueChange(e, key, item.index)} />
                  );
                },
              },
            ];
            const dataSource = Object.entries(value).map(([index, item]) => ({
              key: `${key}-${index}`,
              index,
              value: item,
            }));

            return (
              <div key={key}>
                <h2 className="mb-2">{formatLabel(key)}</h2>
                <Table bordered columns={columns} dataSource={dataSource} pagination={false} />
              </div>
            );
          })}
        </div>
      ),
    },
    {
      key: '2',
      label: 'Custom translation',
      children: transformedTranslationFile && (
        <div className="grid grid-cols-2 gap-10 p-5">
          {Object.entries(transformedTranslationFile).map(([key, value]) => {
            if (key !== 'customWords') return;
            const columns = [
              {
                title: 'Key',
                dataIndex: 'index',
                key: 'index',
                render: (value: string, item: Translation) => {
                  return (
                    <Input
                      autoFocus
                      value={value}
                      onChange={(e) => handleKeyChange(e, key, item.index)}
                    />
                  );
                },
              },
              {
                title: 'Value',
                dataIndex: 'value',
                key: 'value',
                render: (value: string, item: Translation) => {
                  return (
                    <Input value={value} onChange={(e) => handleValueChange(e, key, item.index)} />
                  );
                },
              },
              {
                title: '',
                render: (value: string, item: Translation) => {
                  return (
                    <Button danger onClick={() => handleDelete(item.index)}>
                      Delete
                    </Button>
                  );
                },
              },
            ];
            let dataSource: Translation[];
            if (!value) {
              dataSource = [];
            } else {
              dataSource = Object.entries(value).map(([index, item]) => ({
                key: `${key}-${index}`,
                index,
                value: item,
              }));
            }
            return (
              <div key={key}>
                <div className="flex items-center justify-between mb-2">
                  <h2>{formatLabel(key)}</h2> <Button onClick={addNewRow}>Add new word</Button>
                </div>
                <Table bordered columns={columns} dataSource={dataSource} pagination={false} />
              </div>
            );
          })}
        </div>
      ),
    },
  ];

  return (
    <>
      <Link to="/workspace/enterprise-properties/">
        <Button>Back</Button>
      </Link>
      <h2 className="mt-2 mb-4">{id ? 'Edit' : 'Add'} Translation</h2>
      <Form
        name="upload-form"
        onFinish={onFinish}
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: 'Please enter the name!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Key"
          name="key"
          rules={[{ required: true, message: 'Please enter the key!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Dialect" name="dialect">
          <Input />
        </Form.Item>

        <Form.Item label="Description" name="description">
          <Input.TextArea className="border border-[#6B7280]" />
        </Form.Item>

        <Form.Item
          label={`${id ? 'Upload new file' : 'Upload file'}`}
          name="translationFile"
          valuePropName="file"
          rules={[{ required: id ? false : true, message: 'Please add file!' }]}
        >
          <Upload beforeUpload={() => false} accept=".json" maxCount={1}>
            <Button icon={<UploadOutlined />}>Upload</Button>
          </Upload>
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 4, span: 14 }}>
          <Button type="primary" htmlType="submit">
            {id ? 'Save' : 'Submit'}
          </Button>
        </Form.Item>
      </Form>
      {transformedTranslationFile && <Tabs defaultActiveKey="1" items={items} />}
    </>
  );
}

export default Translation;
