import { useMutation } from 'react-query';

import { axiosInstance } from '../../../core/api/axiosInstance';
import { C9ProjectDef } from '../../../model/definitions/C9ProjectDef';

const key = ['GET_PROJECT_FROM_TEMPLATE'];
export const useGetProjectFromTemplate = () =>
  useMutation(key, async ({ id, name }: { id: string; name: string }): Promise<C9ProjectDef> => {
    const { data } = await axiosInstance.post('project-from-template', {
      projectTemplateId: id,
      name,
    });
    return data;
  });
