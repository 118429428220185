import { FileDoneOutlined } from '@ant-design/icons';

interface PrivacyInterface {
  text: string;
}
const Privacy = ({ text }: PrivacyInterface) => {
  return (
    <div className="flex items-center gap-6 px-8 border-b py-10">
      <FileDoneOutlined className="text-3xl" />
      <p className="font-light text-[15px]">{text}</p>
    </div>
  );
};

export default Privacy;
