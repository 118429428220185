import './style.scss';

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { IconSetTemplateItemDef } from '../../../../model/definitions/IconSetTemplateItemDef';
import { RolesEnum } from '../../../../model/enums/RolesEnum';
import { RootState } from '../../../../store/store';
import EditInfoModal from '../../modals/EditInfoModal';
import useGetWeatherTypeCodes from '../../queries-NEW/useGetWeatherTypeCodes';
import ImagePreviewCard from '../image-preview-card/Image-preview-card';

interface Props {
  icons: IconSetTemplateItemDef[];
  selectedIcon: IconSetTemplateItemDef | null;
  showAllImages: boolean;
  preview?: boolean;
  setIcons: (icons: IconSetTemplateItemDef[]) => void;
  setSelectedIcon: (icon: IconSetTemplateItemDef | null) => void;
  setDisableSaveBtn: (disable: boolean) => void;
}

function ImagePreviewCardContainer({
  icons,
  selectedIcon,
  showAllImages,
  preview,
  setIcons,
  setSelectedIcon,
  setDisableSaveBtn,
}: Props) {
  const { data: codes } = useGetWeatherTypeCodes();
  const [editIconInfoModal, setEditIconInfoModal] = useState<boolean>(false);
  const { userRoles } = useSelector<RootState>((state) => state.user) as {
    userRoles: RolesEnum[];
  };
  const admin = userRoles.includes(RolesEnum.ROLE_SUPER_ADMIN);

  const getImagePreviewCards = () => {
    if (!icons) return;
    const imagePreviewCards: IconSetTemplateItemDef[] = icons.filter((icon) => {
      if (showAllImages) {
        return icon;
      } else {
        if (icon.id === selectedIcon?.id || icon.weatherTypeCodes.length < 1) {
          return icon;
        }
      }
    });
    return imagePreviewCards.map((item) => (
      <div
        key={item.id}
        onClick={() =>
          item.id !== selectedIcon?.id ? setSelectedIcon(item) : setSelectedIcon(null)
        }
      >
        <ImagePreviewCard
          item={item.iconTemplate}
          id={item.id}
          name={item.name}
          isSequence={item.iconTemplate.originalZipSequence}
          imgSrc={item.iconTemplate.thumbnailUrls?.[0]}
          checked={selectedIcon?.id === item.id}
          weatherTypeCodes={item.weatherTypeCodes}
          versionId={item.iconTemplate.versionId}
          jsonAnimation={item.iconTemplate.jsonAnimation}
          preview={preview}
          admin={admin}
          onEdit={() => {
            setEditIconInfoModal(true);
            setSelectedIcon(item);
          }}
          onDelete={(id) => deleteIcon(id)}
        />
      </div>
    ));
  };

  const renderWeatherTypes = (weatherTypeCodes: string[], codes?: string[]) => {
    return codes?.sort().map((code) => (
      <div
        key={code}
        className={`ws-icon-code ${weatherTypeCodes.includes(code) ? 'ws-active-type' : ''} ${
          !preview && 'cursor-pointer'
        }`}
        onClick={() => !preview && onTypeClick(code, weatherTypeCodes)}
      >
        {code}
      </div>
    ));
  };

  const onTypeClick = (code: string, weatherTypeCodes: string[]) => {
    let t = [...weatherTypeCodes];
    if (t.includes(code)) {
      const index = t.indexOf(code);
      if (index > -1) {
        t.splice(index, 1);
      }
    } else {
      t = [...t, code];
    }
    const tempIcon = { ...selectedIcon, weatherTypeCodes: t };
    setSelectedIcon(tempIcon as IconSetTemplateItemDef);
    updateIconsArr(tempIcon as IconSetTemplateItemDef);
    return t;
  };

  const updateIconsArr = (selectedIcon: IconSetTemplateItemDef) => {
    if (!selectedIcon) return;
    const updatedIcons = icons.map((item) => {
      if (item.id === selectedIcon.id) {
        return selectedIcon;
      } else {
        return item;
      }
    });
    setIcons(updatedIcons);
  };

  const enableSaveBtn = () => {
    let disable = false;
    icons.forEach((item) => {
      if (item.weatherTypeCodes.length === 0) {
        disable = true;
      }
    });
    setDisableSaveBtn(disable);
  };

  const editIconInfo = (data: { newName: string; newDescription: string }) => {
    if (!data.newName || !selectedIcon) return;
    updateIconsArr({
      ...selectedIcon,
      iconTemplate: {
        ...selectedIcon.iconTemplate,
        name: data.newName,
        description: data.newDescription,
      },
      name: data.newName,
      description: data.newDescription,
    });
    setSelectedIcon({
      ...selectedIcon,
      iconTemplate: {
        ...selectedIcon.iconTemplate,
        name: data.newName,
        description: data.newDescription,
      },
      name: data.newName,
      description: data.newDescription,
    });
    setEditIconInfoModal(false);
    toast.success('Icon edited!');
  };

  const deleteIcon = (id: string) => {
    const imagePreviewCards: IconSetTemplateItemDef[] = icons.filter((icon) => {
      if (icon.id !== id) {
        return icon;
      }
    });
    setSelectedIcon(null);
    setIcons(imagePreviewCards);
    toast.success('Icon deleted!');
  };

  useEffect(() => {
    enableSaveBtn();
  }, [icons]);

  return (
    <>
      <div className={`grid gap-y-5 grid-cols-6 2xl:grid-cols-9`}>{getImagePreviewCards()}</div>
      {selectedIcon && selectedIcon.id && (
        <div className="available-weather pt-5">
          <div className="available-weather-types">
            <div>TAGS</div>
            <div className={'ws-icon-codes-container'}>
              {renderWeatherTypes(selectedIcon && selectedIcon.weatherTypeCodes, codes)}
            </div>
          </div>
        </div>
      )}
      {editIconInfoModal && selectedIcon && (
        <EditInfoModal
          opened={editIconInfoModal}
          onClose={() => setEditIconInfoModal(false)}
          onEdit={editIconInfo}
          name={selectedIcon.name}
          description={selectedIcon.description}
        />
      )}
    </>
  );
}
export default ImagePreviewCardContainer;
