const Play = () => {
  return (
    <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 0C0.734785 0 0.480432 0.105357 0.292895 0.292893C0.105359 0.48043 1.9073e-06 0.734784 1.9073e-06 1V1.00391V12V22.9961V23C1.9073e-06 23.2652 0.105359 23.5196 0.292895 23.7071C0.480432 23.8946 0.734785 24 1 24C1.20816 23.9994 1.41095 23.9339 1.58008 23.8125L1.58203 23.8145L21.416 12.9082C21.5901 12.8286 21.7376 12.7007 21.8411 12.5397C21.9446 12.3787 21.9998 12.1914 22 12C22.0001 11.8032 21.9422 11.6108 21.8334 11.4468C21.7247 11.2829 21.57 11.1546 21.3887 11.0781L1.58203 0.185547H1.58008C1.41072 0.0648892 1.20795 3.14643e-05 1 0Z"
        fill="#678299"
      />
    </svg>
  );
};

export default Play;
