import { Button, Label, Modal, Select, TextInput } from 'flowbite-react';
import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import { createProjectFromTemplate } from '../../../core/api/ProjectsAPI';
import { getMyProjectTemplatesQueryInfo } from '../../../core/api/TemplatesAPI';
import { C9ProjectDef } from '../../../model/definitions/C9ProjectDef';
import { C9ProjectCreateFromTemplateRequestDTO } from '../../../model/DTO/C9ProjectCreateFromTemplateRequestDTO';
import { C9ProjectTemplatePreviewDTO } from '../../../model/DTO/C9ProjectTemplatePreviewDTO';
import { HistoricalTypeEnum } from '../../../model/enums/HistoricalTypeEnum';
import { TransitionsEnum } from '../../../model/enums/TransitionsEnum';

interface Props {
  opened: boolean;
  onClose: () => void;
  onNewProjectFromTemplate: (newProject: C9ProjectDef) => void;
}

const NewProjectFromTemplateModal: React.FC<Props> = ({
  opened,
  onClose,
  onNewProjectFromTemplate,
}) => {
  const [params] = useSearchParams();
  const templateId = params.get('templateId');
  /**Search for future when select has search */
  const [search] = useState('');
  // const debouncedSearch = useDebounce(search);
  const [currPage] = useState(1);
  const [template, setTemplate] = useState<C9ProjectTemplatePreviewDTO>();
  const { data: myTemplatesData } = useQuery(
    ['my-templates-query', currPage, search],
    () =>
      getMyProjectTemplatesQueryInfo({
        page: currPage - 1,
        size: 1000,
        searchKey: search,
      }),
    {
      keepPreviousData: true,
      cacheTime: Infinity,
      staleTime: Infinity,
      onSuccess: (data) => {
        const preselectedTemplate = data.content.find((t) => t.id === templateId);
        setTemplate(preselectedTemplate as C9ProjectTemplatePreviewDTO);
      },
      enabled: !!templateId,
    },
  );

  const [newProjectFromTemplate, setNewProjectFromTemplate] =
    useState<C9ProjectCreateFromTemplateRequestDTO>({
      name: '',
      sceneTransition: TransitionsEnum.NONE,
      historicalType: template?.historicalType || HistoricalTypeEnum.RELATIVE,
      templateId: templateId!,
      numberOfScenes: 1,
    });

  const templateMutation = useMutation(createProjectFromTemplate, {
    onSuccess: (data: C9ProjectDef) => {
      onNewProjectFromTemplate(data);
    },
  });

  function onSelect(ev: React.ChangeEvent<HTMLSelectElement>) {
    const newTemplate = myTemplatesData?.content.find((t) => t.id === ev.target.value);
    setTemplate(newTemplate as C9ProjectTemplatePreviewDTO);
    setNewProjectFromTemplate((p) => ({
      ...p,
      templateId: (newTemplate as C9ProjectTemplatePreviewDTO).id,
    }));
  }

  function onChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    prop: keyof C9ProjectCreateFromTemplateRequestDTO,
  ) {
    setNewProjectFromTemplate((p) => ({ ...p, [prop]: e.target.value }));
  }

  function hasError() {
    return !(
      Boolean(newProjectFromTemplate.name) &&
      Boolean(newProjectFromTemplate.sceneTransition) &&
      Boolean(newProjectFromTemplate.numberOfScenes) &&
      Boolean(newProjectFromTemplate.templateId)
    );
  }

  function createFromTemplate() {
    templateMutation.mutate(newProjectFromTemplate);
  }

  return (
    <Modal show={opened} onClose={onClose}>
      <Modal.Header>Create new project?</Modal.Header>
      <Modal.Body>
        <div>
          <div className="mb-2 block mt-2">
            <Label htmlFor="name" value="Project name" />
          </div>
          <TextInput
            value={newProjectFromTemplate.name}
            onChange={(e) => onChange(e, 'name')}
            id="name"
            type="text"
            sizing="md"
          />
        </div>
        <div className="mt-2">
          <div className="mb-2 block mt-2">
            <Label htmlFor="name" value="Template name" />
          </div>
          <Select id="templateId" value={template?.id} onChange={(event) => onSelect(event)}>
            {myTemplatesData?.content.map((k) => (
              <option key={k.id} value={k.id}>
                {k.name}
              </option>
            ))}
          </Select>
        </div>
        <div className="mt-2">
          <div className="mb-2 block mt-2">
            <Label htmlFor="scenesNum" value="Number of scenes" />
          </div>
          <TextInput
            value={newProjectFromTemplate.numberOfScenes}
            onChange={(e) => onChange(e, 'numberOfScenes')}
            id="numberOfScenes"
            type="number"
            sizing="md"
          />
        </div>
        <div className="mt-2">
          <div className="mb-2 block mt-2">
            <Label htmlFor="transition" value="Select transition" />
          </div>
          <Select
            id="sceneTransition"
            value={newProjectFromTemplate.sceneTransition}
            onChange={(e) => onChange(e, 'sceneTransition')}
          >
            {Object.keys(TransitionsEnum).map((k) => (
              <option key={k} value={k}>
                {k}
              </option>
            ))}
          </Select>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={hasError()} onClick={createFromTemplate}>
          Create
        </Button>
        <Button color="gray" onClick={onClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NewProjectFromTemplateModal;
