import { PaymentMethod } from '@stripe/stripe-js';
import { QueryKey, useQuery } from 'react-query';

import { axiosInstance } from '../../../../core/api/axiosInstance';

export const key: QueryKey = ['LIST_OF_CARDS'];

export const useGetListOfCards = () => {
  return useQuery([...key], async () => {
    const response = await axiosInstance.get<PaymentMethod[]>(`payment/user/saved-cards`);
    return response.data;
  });
};
