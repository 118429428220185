import { DataFrameDef } from '../../../../model/definitions/DataFrameDef';

export const nearestValue = (arr: Array<DataFrameDef>, val: DataFrameDef, offset: number) =>
  arr.reduce(
    (p, n) =>
      Math.abs(p) > Math.abs(n.timestamp - (val.timestamp - offset))
        ? n.timestamp - (val.timestamp - offset)
        : p,
    Infinity,
  ) +
  (val.timestamp - offset);

export const nextBiggerDate = (arr: Array<DataFrameDef>, val: DataFrameDef, offset: number) => {
  return arr.filter((frame) => frame.timestamp >= val.timestamp + offset)[0];
};
export const nextLesserDate = (arr: Array<DataFrameDef>, val: DataFrameDef, offset: number) => {
  const newArr = arr.filter((frame) => frame.timestamp <= val.timestamp + offset);
  return newArr[newArr.length - 1];
};

export const framesInBetween = (
  arr: Array<DataFrameDef>,
  val1: DataFrameDef,
  val2: DataFrameDef,
  offset: number,
) => {
  return arr.filter(
    (frame) =>
      frame?.timestamp >= val1?.timestamp - offset && frame?.timestamp <= val2?.timestamp - offset,
  );
};

export const closest = (counts: number[], goal: number) => {
  return counts?.reduce(function (prev, curr) {
    return Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev;
  });
};

export const findClosest = (array: number[], num: number) => {
  let closest = new Date().getTime();
  let diff = Infinity;
  for (let i = 0; i < array?.length; i++) {
    const currDiff = Math.abs(num - array[i]);
    if (currDiff < diff) {
      diff = currDiff;
      closest = array[i];
    }
  }
  return closest;
};
