import { ImagePanelDefTemplate } from '../model/definitions/ImagePanelDefTemplate';
import { VideoPanelDefTemplate } from '../model/definitions/VideoPanelDefTemplate';
import { transformAbsoluteToPercent } from '../molecules/canvasElements/utils';

export function fitToCanvasHelper(
  media: ImagePanelDefTemplate | VideoPanelDefTemplate,
  canvasAspect: [number, number],
  previewSize: number,
) {
  /**Reconsider this */
  const x = 0;
  const y = 0;
  const { w, h } = media;
  const [aspectW, aspectH] = canvasAspect;
  const canvasWidth = (previewSize * aspectW) / aspectH;
  const availableCanvasWidth = canvasWidth - x;
  const availableCanvasHeight = previewSize - y;
  const availableRatio = availableCanvasWidth / availableCanvasHeight;
  const ratio = w / h >= availableRatio ? 'wider' : 'higher';
  if (ratio === 'higher') {
    if (h <= availableCanvasHeight)
      return {
        height: transformAbsoluteToPercent(h, canvasAspect, 'height', previewSize),
        width: transformAbsoluteToPercent(w, canvasAspect, 'width', previewSize),
        x: transformAbsoluteToPercent(x, canvasAspect, 'width', previewSize),
        y: transformAbsoluteToPercent(y, canvasAspect, 'height', previewSize),
      };
    if (h >= availableCanvasHeight) {
      const newH = availableCanvasHeight;
      const newW = (availableCanvasHeight * w) / h;
      return {
        height: transformAbsoluteToPercent(newH, canvasAspect, 'height', previewSize),
        width: transformAbsoluteToPercent(newW, canvasAspect, 'width', previewSize),
        x: transformAbsoluteToPercent(x, canvasAspect, 'width', previewSize),
        y: transformAbsoluteToPercent(y, canvasAspect, 'height', previewSize),
      };
    }
  }
  if (ratio === 'wider') {
    if (w <= (availableCanvasHeight * availableCanvasWidth) / availableCanvasHeight) {
      return {
        height: transformAbsoluteToPercent(h, canvasAspect, 'height', previewSize),
        width: transformAbsoluteToPercent(w, canvasAspect, 'width', previewSize),
        x: transformAbsoluteToPercent(x, canvasAspect, 'width', previewSize),
        y: transformAbsoluteToPercent(y, canvasAspect, 'height', previewSize),
      };
    }
    if (w > (availableCanvasHeight * availableCanvasWidth) / availableCanvasHeight) {
      const newW = (availableCanvasHeight * availableCanvasWidth) / availableCanvasHeight;
      const newH = (h * newW) / w;
      return {
        height: transformAbsoluteToPercent(newH, canvasAspect, 'height', previewSize),
        width: transformAbsoluteToPercent(newW, canvasAspect, 'width', previewSize),
        x: transformAbsoluteToPercent(x, canvasAspect, 'width', previewSize),
        y: transformAbsoluteToPercent(y, canvasAspect, 'height', previewSize),
      };
    }
  }
  /**Should never get here */
  return {
    height: transformAbsoluteToPercent(h, canvasAspect, 'height', previewSize),
    width: transformAbsoluteToPercent(w, canvasAspect, 'width', previewSize),
    x: transformAbsoluteToPercent(x, canvasAspect, 'width', previewSize),
    y: transformAbsoluteToPercent(y, canvasAspect, 'height', previewSize),
  };
}
