import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { calculateOverlap, getListOfHigherElements } from '../helpers/zIndex';
import { C9ProjectDef } from '../model/definitions/C9ProjectDef';
import { PositionControlDef } from '../model/definitions/PositionControlDef';
import { ActiveDef } from '../store/slices/active-slice';
import { RootState } from '../store/store';

export const useOverlap = (position: PositionControlDef, id: string) => {
  const [isOverlap, setIsOverlap] = useState<number>(0);
  const project = useSelector<RootState, C9ProjectDef>((state) => state.project.present.project);
  const { activeScene } = useSelector<RootState>((state) => state.active) as ActiveDef;
  const scene = project.sceneDefs.find((scene) => scene.id === activeScene);

  useEffect(() => {
    const elements = scene ? getListOfHigherElements(scene, position?.zindex, id) : [];
    const hasOverlaps = calculateOverlap(position, elements);
    setIsOverlap(hasOverlaps);
  }, [id, position, scene]);
  return { bringToFront: isOverlap < 20 };
};
