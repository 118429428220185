import { useMutation } from 'react-query';

import { BiasFilterGroupDef } from '../../../../model/definitions/BiasFilterGroupDef';
import { ObservedWDSourceDef } from '../../../../model/definitions/ObservedWDSourceDef';
import { axiosInstance } from '../../axiosInstance';

export interface ObservedWDSourceBiasRequest extends ObservedWDSourceDef {
  changedValue: number;
}

export interface ObservedBiasGroup {
  groupName: string;
  groupDescription?: string;
  observedWDBiasSources: Array<ObservedWDSourceBiasRequest>;
}
export const useCreateObservedBiasGroup = () =>
  useMutation(async ({ payload }: { payload: ObservedBiasGroup }): Promise<BiasFilterGroupDef> => {
    const { data } = await axiosInstance.post('biasGroup/observedBiasGroup', { ...payload });
    return data;
  });
