import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { ForecastWDSourceDef } from '../../../model/definitions/ForecastWDSourceDef';
import { axiosInstance } from '../axiosInstance';

export const useCreateForecastBias = () => {
  return useMutation(
    async ({
      filterName,
      filterDescription,
      forecastWDSource,
    }: {
      filterName: string;
      filterDescription: string;
      forecastWDSource: ForecastWDSourceDef;
    }) => {
      await axiosInstance.post('biasFilter/forecastFilter', {
        filterName,
        filterDescription,
        forecastWDSource,
      });
    },
    {
      onSuccess: () => {
        toast.success('Filter created');
      },
      onError: () => {
        toast.success('Failed to create filter');
      },
    },
  );
};
