import { Badge } from 'flowbite-react';
import React, { useState } from 'react';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { HiInformationCircle } from 'react-icons/hi';

import { DefaultThumb } from '../../assets/images';
import styles from './Marketplace.module.scss';

interface Props {
  thumbnails: any;
  isDefault?: boolean;
  onClick?: () => void;
}
const ThumbnailSlider: React.FC<Props> = ({ thumbnails, isDefault, onClick }) => {
  const [currentThumbnail, setCurrentThumbnail] = useState(0);
  const [loaded, setLoaded] = useState(false);

  const onNext = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();

    if (currentThumbnail < thumbnails?.length - 1) {
      setCurrentThumbnail(currentThumbnail + 1);
    }
    if (currentThumbnail === thumbnails?.length - 1) setCurrentThumbnail(0);
  };
  const onPrevious = (event: any) => {
    event.stopPropagation();
    if (currentThumbnail > 0) setCurrentThumbnail(currentThumbnail - 1);
    if (currentThumbnail === 0) setCurrentThumbnail(thumbnails?.length - 1);
  };

  const setThumbnail = (i: any, event: any) => {
    event.stopPropagation();
    setCurrentThumbnail(i);
  };
  function generateDotHolders() {
    const dotHolders = [];
    for (let i = 0; i < thumbnails?.length; i++) {
      dotHolders.push(
        <button
          key={i}
          className={`${
            currentThumbnail !== i
              ? styles['pagination-item']
              : `${styles['pagination-item']} ${styles['pagination-item-active']}`
          }`}
          onClick={(e) => setThumbnail(i, e)}
        />,
      );
    }
    return dotHolders;
  }
  return (
    <div className={`${styles.slider}`} onClick={onClick}>
      {isDefault && (
        <Badge
          style={{
            position: 'absolute',
            zIndex: 1,
            top: 6,
            left: 6,
            padding: '5px 10px',
            width: 'fit-content',
          }}
          color="info"
          size="sm"
          icon={HiInformationCircle}
        >
          Default icon pack
        </Badge>
      )}
      {thumbnails?.length > 1 && (
        <div className={`${styles.previous}`}>
          <FaAngleLeft fill="#f6f6f6ee" onClick={onPrevious} />
        </div>
      )}
      {thumbnails?.length >= 1 ? (
        thumbnails?.map((thumb: string, index: string) => (
          <div
            key={index}
            className={`${styles.img}`}
            style={{ transform: `translateX(-${currentThumbnail * 100}%)` }}
          >
            <img
              src={loaded ? thumb : DefaultThumb}
              alt="Item thumbnail"
              width={330}
              onLoad={() => setLoaded(true)}
            />
          </div>
        ))
      ) : (
        <div
          className={`${styles.img}`}
          style={{ transform: `translateX(-${currentThumbnail * 100}%)` }}
        >
          <img src={DefaultThumb} alt="Item thumbnail" width={330} />
        </div>
      )}
      {thumbnails?.length > 1 && (
        <div className={`${styles['next']}`}>
          <FaAngleRight fill="#f6f6f6ee" onClick={onNext} />
        </div>
      )}
      {thumbnails?.length > 1 ? (
        <div className={`${styles['dots-wrapper']} `} onClick={(e) => e.stopPropagation()}>
          {' '}
          {generateDotHolders()}
        </div>
      ) : null}
    </div>
  );
};

export default ThumbnailSlider;
