import { useQuery } from 'react-query';

import { ForecastDataDef } from '../../../model/definitions/ForecastDataDef';
import { axiosInstance } from '../axiosInstance';

const key = ['GET_FORECAST_DATA'];
export const useGetForecastData = (locationName?: string, applyBias = false, source?: string) =>
  useQuery(
    [...key, locationName, applyBias, source],
    async (): Promise<ForecastDataDef> => {
      const { data } = await axiosInstance.get('forecast/forecast-data', {
        params: {
          locationId: locationName,
          applyBias,
          dataProductId: source,
        },
      });
      return data;
    },
    {
      enabled: !!locationName,
      staleTime: 0,
    },
  );
