import { chunk, findLastIndex } from 'lodash';
import moment from 'moment';
import { Feature, Map as OlMap } from 'ol';
import GeoJSON from 'ol/format/GeoJSON';
import { LineString, Point, Polygon } from 'ol/geom';
import VectorImageLayer from 'ol/layer/VectorImage';
import VectorSource from 'ol/source/Vector';
import { useEffect } from 'react';

import { imageToBlob } from '../../core/api/MultimediaAPI';
import { ModeEnum } from '../../core/ui/enums/ModeEnum';
import { PlaybackEnum } from '../../core/ui/enums/PlaybackEnum';
import { parseScenes } from '../../helpers/timelineUtil';
import { CURRENT_TIME_DATE_FORMAT } from '../../model/constants/constants';
import { C9ProjectDef } from '../../model/definitions/C9ProjectDef';
import { DrawingDef } from '../../model/definitions/DrawingDef';
import { MapPanelDef } from '../../model/definitions/MapPanelDef';
import { TimeControlDef } from '../../model/definitions/TimeControlDef';
import { AnimationsEnum } from '../../model/enums/AnimationsEnum';
import { DrawingTypeExternalEnum } from '../../model/enums/DrawingTypeEnum';
import { TimeStepEnum } from '../../model/enums/TimeStepEnum';
import { GlobalPlayerControl } from '../../pages/playground/GlobalPlayerControl';
import { PlayContext } from '../../pages/playground/playerContext/PlayerContext';
import { enumToS } from '../../pages/playground/properties/panels/timeFrameIndicator/util';
import {
  // getSmoothLineString,
  getStyleForCircle,
  getStyleForImage,
  styleForFronts,
  styleFunctionForArrow,
  styleFunctionForBasicShapes,
} from './drawHeplers';
import { getLayerByClassName } from './helpers';

const imageCache: { [key: string]: string } = {};

function useDrawingTools(
  mapDef: MapPanelDef,
  olMap: OlMap,
  contextValue: PlayContext,
  activeAspectRatio: [number, number],
  mode: ModeEnum,
  projectToPlay: C9ProjectDef,
) {
  useEffect(() => {
    if (!mapDef.drawingElements || !olMap) return;
    const enabledDrawingLayers = mapDef.drawingElements.filter((d) => d.enabled);
    /**Handle drawing layers  */
    enabledDrawingLayers.forEach((drawDef) => {
      const layerClassName = `drawing-layer-${drawDef.id}`;
      const isActive =
        drawDef.timeControls[0].startMS <= contextValue.time &&
        drawDef.timeControls[0].endMS >= contextValue.time;
      const isPlaying = contextValue.isPlaying === PlaybackEnum.PLAYING;
      let layer = getLayerByClassName(layerClassName, olMap);
      /**Get offset for animation in play time */
      const currentTime = GlobalPlayerControl.getTime();
      const isSequence = mode === ModeEnum.SEQUENCE;
      const parentSceneId = projectToPlay.sceneDefs.find((sc) =>
        sc.mapPanels.some((p) => p.id === mapDef.id),
      )?.id;
      const simpleScenes = parseScenes(projectToPlay);
      const simpleScene = simpleScenes.find((s) => s.id === parentSceneId)!;
      let fadeOpacity = 1;
      const timeControls = drawDef.timeControls[0];
      const sceneTimeOffset = isSequence ? 0 : simpleScene.startMS;

      const { currentGeojson, nextGeojson, firstFrameTime, nextFrameTime } =
        calculateDrawingKeyframeRefactor(
          drawDef,
          mapDef.wdSpace[0].indicator,
          mapDef.wdSpace[0].timeframeIndicatorStep,
          sceneTimeOffset,
        );
      // calculateDrawingKeyframeRefactor(drawDef, mapDef.wdSpace[0].indicator);
      if (!currentGeojson) return;

      const currentJson = JSON.parse(currentGeojson);
      const nextJson = JSON.parse(nextGeojson);

      if (!isPlaying) {
        // if not playing rerender with new geoojson
        if (layer) {
          olMap.removeLayer(layer);
          layer.dispose();
        }

        if (isActive)
          layer = addDrawingLayer(
            drawDef,
            olMap,
            layerClassName,
            isPlaying,
            currentGeojson,
            activeAspectRatio,
          );
      } else {
        // playing geojson stays the same
        if (isActive && !layer) {
          layer = addDrawingLayer(
            drawDef,
            olMap,
            layerClassName,
            isPlaying,
            currentGeojson,
            activeAspectRatio,
          );
        }
        if (!isActive && layer) {
          olMap.removeLayer(layer);
          layer.dispose();
        }
      }
      if (isActive && layer) {
        // animate
        const typedLayer = layer as VectorImageLayer<VectorSource>;
        typedLayer.getSource()?.forEachFeature(async (f) => {
          const props = f.getProperties();
          /**Will be only one color */
          // const currentJsonProps = currentJson?.features?.[0]?.properties;
          // if (currentJsonProps) {
          //   setColorsToProperties(currentJsonProps, props);
          // }
          const drawingType = props.drawingType;
          let imageUrl = currentJson.features[0].properties.image;
          if (imageCache[imageUrl]) {
            imageUrl = imageCache[imageUrl];
          } else {
            const blob = await imageToBlob(imageUrl);
            if (blob) {
              imageCache[imageUrl] = blob;
              imageUrl = blob;
            }
          }
          /**Handle opacity */

          const timeNow = contextValue.time - sceneTimeOffset;
          if (
            timeControls.inAnimationDef === AnimationsEnum.FADE_IN &&
            timeNow < timeControls.startMS + timeControls.inAnimationDuration
          ) {
            fadeOpacity = (timeNow - timeControls.startMS) / timeControls.inAnimationDuration;
            const finalTime = (timeControls.startMS ?? 0) + timeControls.inAnimationDuration;
            const remaining = finalTime - timeNow;
            fadeOpacity = Math.min(
              Math.max((100 - (remaining * 100) / timeControls.inAnimationDuration) / 100, 0),
              1,
            );
          }
          if (
            timeControls.outAnimationDef === AnimationsEnum.FADE_OUT &&
            timeNow > timeControls.endMS - timeControls.outAnimationDuration
          ) {
            let remaining = (timeControls.endMS ?? 0) - currentTime;
            remaining = (remaining * 100) / timeControls.outAnimationDuration / 100;
            fadeOpacity = Math.min(Math.max(remaining, 0), 1);
          }
          if (typedLayer.getOpacity() !== fadeOpacity) typedLayer.setOpacity(fadeOpacity);
          /********************************************************************** */
          if (
            drawingType === DrawingTypeExternalEnum.LINE_STRING ||
            drawingType === DrawingTypeExternalEnum.FRONTS ||
            drawingType === DrawingTypeExternalEnum.ARROW
          ) {
            const geom = f.getGeometry() as LineString;
            if (drawingType === DrawingTypeExternalEnum.LINE_STRING) {
              f.setStyle(
                styleFunctionForBasicShapes(
                  props['lineColor'],
                  props['fill'],
                  props['fillColor'],
                  props['lineThickness'],
                  false,
                ),
              );
            }
            // geom?.translate(1000, 1000);
            if (geom) {
              const currentTime = GlobalPlayerControl.getTime();

              if (!currentJson.features[0].properties.arrayOfTurningPoints) return;
              /**Update must always animate coordinates not arrayOfTurningPoints
               * in case if shape is not morphed animate array of turning points also
               * if it's morphed can't be animated
               */
              const coords = currentJson.features[0].properties.arrayOfTurningPoints.flat();
              const nextCoordsArr = nextJson.features[0].properties.arrayOfTurningPoints.flat();
              const isShapeMorfed = coords.length !== nextCoordsArr.length;
              /**If shape is modified interpolating of arrayOfTurningPoints will not work because number of points is not same */
              if (!isShapeMorfed) {
                const nextCoordinates = nextCoordsArr.map((c: number, i: number) => {
                  return animateCoordinates(
                    coords[i],
                    c,
                    firstFrameTime,
                    nextFrameTime,
                    currentTime,
                  );
                });
                // const curved = getSmoothLineString(chunk(nextCoordinates, 2));
                f.setProperties({ ...props, arrayOfTurningPoints: chunk(nextCoordinates, 2) });
                // geom.setCoordinates(curved);
              }
              const prevCoords = currentJson.features[0].geometry.coordinates.flat();
              const nextCoords = nextJson.features[0].geometry.coordinates
                .flat()
                .map((c: number, i: number) => {
                  return animateCoordinates(
                    prevCoords[i],
                    c,
                    firstFrameTime,
                    nextFrameTime,
                    currentTime,
                  );
                });

              geom.setCoordinates(chunk(nextCoords, 2));

              //
              geom.changed();
            }
          }

          if (drawingType === DrawingTypeExternalEnum.POLYGON) {
            const geom = f.getGeometry() as Polygon;
            // geom?.translate(1000, 1000);
            if (geom) {
              const currentTime = GlobalPlayerControl.getTime();
              const coords = currentJson.features[0].geometry.coordinates.flat().flat();
              const nextCoords = nextJson.features[0].geometry.coordinates.flat().flat();
              const nextCoordinates = nextCoords.map((c: number, i: number) => {
                return animateCoordinates(coords[i], c, firstFrameTime, nextFrameTime, currentTime);
              });

              geom.setFlatCoordinates(geom.getLayout(), nextCoordinates);
              geom.changed();
            }
          }

          if (drawingType === DrawingTypeExternalEnum.IMAGE) {
            const animatedProps = {
              image: imageUrl,
              imageHeight: animateCoordinates(
                currentJson.features[0].properties.imageHeight,
                nextJson.features[0].properties.imageHeight,
                firstFrameTime,
                nextFrameTime,
                currentTime,
              ),
              imageWidth: animateCoordinates(
                currentJson.features[0].properties.imageWidth,
                nextJson.features[0].properties.imageWidth,
                firstFrameTime,
                nextFrameTime,
                currentTime,
              ),
            };
            f.setStyle(getStyleForImage(animatedProps, activeAspectRatio, false));
            const geom = f.getGeometry() as Point;

            if (geom) {
              const coords = currentJson.features[0].geometry.coordinates.flat().flat();
              const nextCoords = nextJson.features[0].geometry.coordinates.flat().flat();
              const nextCoordinates = nextCoords.map((c: number, i: number) => {
                return animateCoordinates(coords[i], c, firstFrameTime, nextFrameTime, currentTime);
              });

              geom.setFlatCoordinates(geom.getLayout(), nextCoordinates);
              geom.changed();
            }
            // f.setStyle(
            //   new Style({
            //     text: newTextStyle,
            //   }),
            // );
          }

          if (drawingType === DrawingTypeExternalEnum.CIRCLE) {
            const geom = f.getGeometry() as Point;

            if (geom) {
              const currentTime = GlobalPlayerControl.getTime();
              const coords = currentJson.features[0].geometry.coordinates;
              const nextCoords = nextJson.features[0].geometry.coordinates;
              const nextCoordinates = nextCoords.map((c: number, i: number) => {
                return animateCoordinates(coords[i], c, firstFrameTime, nextFrameTime, currentTime);
              });

              geom.setFlatCoordinates(geom.getLayout(), nextCoordinates);
              geom.changed();
            }
          }

          // if (drawingType === DrawingTypeExternalEnum.FRONTS) {
          //   const geom = f.getGeometry();
          //   console.log(geom);
          // }
        });
      }
    });

    removeDrawingLayers(olMap, mapDef);
  }, [mapDef.drawingElements, contextValue]);
}

export function removeDrawingLayers(olMap: OlMap, mapDef: MapPanelDef) {
  const incoming = mapDef.drawingElements
    .filter((d) => d.enabled)
    .map((d) => `drawing-layer-${d.id}`);
  const allDrawsOnMap = olMap
    .getAllLayers()
    .filter((l) => l.getClassName().includes('drawing-layer-'));
  allDrawsOnMap.forEach((l) => {
    if (!incoming.includes(l.getClassName())) {
      olMap.removeLayer(l);
      l.dispose();
    }
  });
}

function addDrawingLayer(
  drawDef: DrawingDef,
  olMap: OlMap,
  layerClassName: string,
  isPlaying: boolean,
  currentGeojson: string,
  activeAspectRatio: [number, number],
) {
  const vectorSource = new VectorSource({
    features: new GeoJSON().readFeatures(currentGeojson),
  });

  const feature: Feature = vectorSource.getFeatures()[0];
  let zIndex = 100;
  if (feature?.getProperties()?.zIndex) {
    zIndex = feature.getProperties().zIndex;
  }
  const vectorLayer = new VectorImageLayer({
    source: vectorSource,
    zIndex: zIndex,
    style: (feature, resolution) => {
      const properties = feature.getProperties();
      const drawingType = properties['drawingType'];
      if (drawingType === 'Front') {
        return styleForFronts(feature, resolution, false, drawDef.id, isPlaying);
      } else if (drawingType === 'Arrow') {
        return styleFunctionForArrow(feature, resolution, olMap);
      } else if (drawingType == 'Circle') {
        return getStyleForCircle(properties, false);
      } else if (drawingType === 'Image') {
        return getStyleForImage(properties, activeAspectRatio, false);
      } else {
        return styleFunctionForBasicShapes(
          properties['lineColor'],
          properties['fill'],
          properties['fillColor'],
          properties['lineThickness'],
          false,
        );
      }
    },
    className: layerClassName,
  });
  olMap.addLayer(vectorLayer);
  return vectorLayer;
}

function lerp(start: number, end: number, progress: number) {
  return start + (end - start) * progress;
}

export function animateCoordinates(
  startX: number,
  endX: number,
  startTime: number,
  endTime: number,
  currentTime: number,
) {
  const progress = Math.min(1, (currentTime - startTime) / (endTime - startTime));

  // if (endTime === startTime) {
  //   /**Prevent NaN */
  //   progress = 1;
  // }
  if (isNaN(progress) || progress === -Infinity || progress === Infinity) return startX;
  // Interpolate X and Y coordinates

  return lerp(startX, endX, progress);
}

export const calculateTimestampFromIndicator = (
  indicator: {
    timeControls: TimeControlDef;
    value: string;
    dateValue: string;
  }[],
) => {
  const current = indicator.findIndex(
    (val) =>
      val.timeControls.startMS <= GlobalPlayerControl.getTime() &&
      val.timeControls.endMS >= GlobalPlayerControl.getTime(),
  );
  if (current > -1) {
    const firstOne = moment(indicator[current]?.dateValue, CURRENT_TIME_DATE_FORMAT)
      .toDate()
      .getTime();
    const nextIndicatorDateValue = indicator[current + 1]
      ? indicator[current + 1].dateValue
      : indicator[current].dateValue;
    const nxtOne = moment(nextIndicatorDateValue, CURRENT_TIME_DATE_FORMAT).toDate().getTime();

    const progress = Math.min(
      1,
      (GlobalPlayerControl.getTime() - indicator[current].timeControls.startMS) /
        (indicator[current].timeControls.endMS - indicator[current].timeControls.startMS),
    );

    return Math.round(lerp(firstOne, nxtOne, progress));
  }
  return 0;
};

export const calculateTimeFromTimestamp = (
  indicator:
    | {
        timeControls: TimeControlDef;
        value: string;
        dateValue: string;
      }[]
    | undefined,
  timestamp: number,
) => {
  if (!indicator) return;

  const indicatorsWithTimestamp = indicator.map((i) => ({
    ...i,
    timestamp: moment(i.dateValue, CURRENT_TIME_DATE_FORMAT).toDate().getTime(),
  }));

  const lastIdx = indicatorsWithTimestamp.findIndex((i, idx) => {
    const isLessThanTs = i.timestamp <= timestamp;
    let isNextMoreThenTs = false;
    if (indicatorsWithTimestamp[idx + 1]) {
      isNextMoreThenTs = indicatorsWithTimestamp[idx + 1].timestamp >= timestamp;
    }
    return isLessThanTs && isNextMoreThenTs;
  });
  if (lastIdx === -1) {
    return;
  }
  const firstOne = indicatorsWithTimestamp[lastIdx].timeControls.startMS;
  const nxtOne = indicatorsWithTimestamp[lastIdx].timeControls.endMS;
  const firstTs = indicatorsWithTimestamp[lastIdx].timestamp;
  const nxtTs = indicatorsWithTimestamp[lastIdx + 1].timestamp;
  const progress = nxtTs === firstTs ? 1 : Math.min(1, (timestamp - firstTs) / (nxtTs - firstTs));

  return Math.round(lerp(firstOne, nxtOne, progress));
};
/**
 * @deprecated use calculateDrawingKeyframeRefactor intead, use just for reference
 */
export const calculateDrawingKeyframe = (
  drawDef: DrawingDef,
  indicator: {
    timeControls: TimeControlDef;
    value: string;
    dateValue: string;
  }[],
) => {
  let currentGeojson = drawDef.drawingGeoJson;
  let nextGeojson = drawDef.drawingGeoJson;
  let firstFrameTime = 1;
  let nextFrameTime = 1;
  let firstFrameIndex = 0;

  if (drawDef.keyframes && drawDef.keyframes.length > 0) {
    const withoutTimestamps = drawDef.keyframes.filter((x) => x.timestamp == 0).length;

    if (indicator && !withoutTimestamps) {
      const current = indicator.findIndex(
        (val) =>
          val.timeControls.startMS <= GlobalPlayerControl.getTime() &&
          val.timeControls.endMS >= GlobalPlayerControl.getTime(),
      );

      if (current > -1) {
        const timestamp = calculateTimestampFromIndicator(indicator);

        firstFrameIndex = 0;
        let foundFirstFrame = false;
        for (let i = 0; i < drawDef.keyframes.length; i++) {
          if (drawDef.keyframes[i].timestamp <= timestamp) {
            foundFirstFrame = true;
            firstFrameIndex = i;
            currentGeojson = drawDef.keyframes[i].geoJson;
            firstFrameTime = indicator[current].timeControls.startMS;

            if (current > 0) {
              // to go backwards to find first startMS that works for this keyframe
              for (let j = current - 1; j >= 0; j--) {
                if (
                  drawDef.keyframes[i].timestamp <=
                  moment(indicator[j].dateValue, CURRENT_TIME_DATE_FORMAT).toDate().getTime()
                ) {
                  firstFrameTime = indicator[j].timeControls.startMS;
                }
              }
            }

            if (firstFrameTime === 0) {
              // if the timestamp is in the past, firstFrameTime should be negative
              // number of miliseconds per timestamp
              const firstTimestamp = moment(indicator[0].dateValue, CURRENT_TIME_DATE_FORMAT)
                .toDate()
                .getTime();
              const stepLengthMS =
                indicator[current].timeControls.endMS - indicator[current].timeControls.startMS;
              const timestampStep =
                moment(indicator[1].dateValue, CURRENT_TIME_DATE_FORMAT).toDate().getTime() -
                firstTimestamp;

              firstFrameTime =
                ((firstTimestamp - drawDef.keyframes[i].timestamp) / timestampStep) *
                stepLengthMS *
                -1;
            }
          }
        }

        if (foundFirstFrame) {
          if (firstFrameIndex + 1 == drawDef.keyframes.length) {
            if (firstFrameIndex === 0) {
              currentGeojson = drawDef.keyframes[0].geoJson;
              firstFrameTime = 0;
            }
            nextGeojson = drawDef.keyframes[firstFrameIndex].geoJson;
            nextFrameTime = indicator[indicator.length - 1].timeControls.endMS;
          } else {
            const nextKeyframe = drawDef.keyframes[firstFrameIndex + 1];

            for (let i = current + 1; i < indicator.length; i++) {
              const nt = moment(indicator[i].dateValue, CURRENT_TIME_DATE_FORMAT)
                .toDate()
                .getTime();

              if (nextKeyframe.timestamp <= nt) {
                nextGeojson = nextKeyframe.geoJson;
                nextFrameTime = indicator[i].timeControls.startMS;
                break;
              }
            }
          }
        } else {
          currentGeojson = drawDef.keyframes[0].geoJson;
          firstFrameTime = 1;
          nextGeojson = drawDef.keyframes[0].geoJson;
          nextFrameTime = 1;
        }
      }

      return {
        currentGeojson,
        nextGeojson,
        firstFrameTime,
        nextFrameTime,
        firstFrameIndex,
      };
    }

    currentGeojson = drawDef.keyframes[0].geoJson;
    firstFrameTime = drawDef.keyframes[0].startTime;
    let foundFirstFrame = false;

    for (let i = 0; i < drawDef.keyframes.length; i++) {
      if (drawDef.keyframes[i].startTime <= GlobalPlayerControl.getTime()) {
        foundFirstFrame = true;
        firstFrameIndex = i;
        currentGeojson = drawDef.keyframes[i].geoJson;
        firstFrameTime = drawDef.keyframes[i].startTime;
        if (i + 1 === drawDef.keyframes.length) {
          nextGeojson = currentGeojson;
          nextFrameTime = firstFrameTime;
        }
      } else {
        if (!foundFirstFrame && i + 1 == drawDef.keyframes.length) {
          firstFrameIndex = i;
          currentGeojson = drawDef.keyframes[i].geoJson;
          firstFrameTime = drawDef.keyframes[i].startTime;
        }
        nextGeojson = drawDef.keyframes[i].geoJson;
        nextFrameTime = drawDef.keyframes[i].startTime;
        break;
      }
    }
  }
  return {
    currentGeojson,
    nextGeojson,
    firstFrameTime,
    nextFrameTime,
    firstFrameIndex,
  };
};

export const calculateDrawingKeyframeRefactor = (
  drawDef: DrawingDef,
  indicator: {
    timeControls: TimeControlDef;
    value: string;
    dateValue: string;
  }[],
  timestep: TimeStepEnum,
  sceneTimeOffset = 0,
) => {
  let currentGeojson = drawDef.drawingGeoJson;
  let nextGeojson = drawDef.drawingGeoJson;
  let firstFrameTime = 1;
  let nextFrameTime = 1;
  let firstFrameIndex = 0;
  const hasIndicator = Boolean(indicator?.length);
  const currTime = GlobalPlayerControl.getTime();

  const hasEveryTimestampKeyframe = drawDef.keyframes.every((k) => Boolean(k.timestamp));
  if (hasEveryTimestampKeyframe && hasIndicator && drawDef.keyframes.length) {
    const keyframesWithTime = drawDef.keyframes.map((k) => ({
      ...k,
      startMS: calculateTimeFromTimestamp(indicator, k.timestamp),
    }));

    /**Handle out of range, go back in time to find time in past */
    const lastUndefinedIdx = findLastIndex(keyframesWithTime, (kf) => kf.startMS === undefined);
    if (lastUndefinedIdx !== -1 && timestep) {
      const lastUndefined = keyframesWithTime[lastUndefinedIdx];
      const next = keyframesWithTime[lastUndefinedIdx + 1];
      if (next) {
        const timeLapse = next.timestamp - lastUndefined.timestamp;

        const oneIndicatorTimeLapse = enumToS(timestep) * 1000;
        const oneIndicatorPlayTime =
          indicator[0].timeControls.endMS - indicator[0].timeControls.startMS;
        const perMilisecond = oneIndicatorPlayTime / oneIndicatorTimeLapse;
        const deltaTime = timeLapse * perMilisecond;

        const timeInPast = Math.round(next.startMS! - deltaTime);

        lastUndefined.startMS = timeInPast;
      } else {
        /**They are all in past return last */

        return {
          currentGeojson: lastUndefined.geoJson,
          nextGeojson: lastUndefined.geoJson,
          firstFrameTime: 1,
          nextFrameTime: 1,
          firstFrameIndex: lastUndefinedIdx,
        };
      }
    }

    /**Remove all without startMS => not in indicator */
    const keyframesWithTimeFiltered = keyframesWithTime.filter((kt) => kt.startMS !== undefined);

    if (keyframesWithTimeFiltered.length) {
      const first = keyframesWithTimeFiltered[0];
      const last = keyframesWithTimeFiltered[keyframesWithTimeFiltered.length - 1];

      if (currTime <= first!.startMS!) {
        currentGeojson = first.geoJson;
        nextGeojson = first.geoJson;
      } else if (currTime >= last!.startMS!) {
        currentGeojson = last.geoJson;
        nextGeojson = last.geoJson;
        const idx = keyframesWithTime.findIndex((k) => k.timestamp === last.timestamp);
        firstFrameTime = last.startMS!;
        nextFrameTime = last.startMS!;
        firstFrameIndex = idx;
      } else {
        /**It's somewhere between two keyframes */
        const idx = keyframesWithTimeFiltered.findIndex((k, i) => {
          return k.startMS! <= currTime && keyframesWithTimeFiltered[i + 1].startMS! >= currTime;
        });
        const foundFirst = keyframesWithTimeFiltered[idx];
        const foundNext = keyframesWithTimeFiltered[idx + 1];
        currentGeojson = foundFirst.geoJson;
        nextGeojson = foundNext.geoJson;
        firstFrameTime = foundFirst.startMS!;
        nextFrameTime = foundNext.startMS!;
        firstFrameIndex = idx;
      }
    }
  } else if (drawDef.keyframes.length) {
    /**No wd layers or some of keyframes do not have timestamp set;
     * handle play by playTime
     */
    let startFrameIdx = findLastIndex(
      drawDef.keyframes,
      (k) => k.startTime <= currTime - sceneTimeOffset,
    );
    let isFirstInFuture = false;
    if (startFrameIdx === -1) {
      isFirstInFuture = true;
      startFrameIdx = 0;
    }
    const startFrame = drawDef.keyframes[startFrameIdx];
    const nextFrame = drawDef.keyframes[startFrameIdx + 1];
    currentGeojson = drawDef.keyframes[startFrameIdx].geoJson;
    nextGeojson = nextFrame && !isFirstInFuture ? nextFrame.geoJson : currentGeojson;
    firstFrameTime = startFrame.startTime + sceneTimeOffset;
    nextFrameTime = nextFrame ? nextFrame.startTime + sceneTimeOffset : firstFrameTime;
    firstFrameIndex = startFrameIdx;
    return {
      currentGeojson,
      nextGeojson,
      firstFrameTime,
      nextFrameTime,
      firstFrameIndex,
    };
  }

  return {
    currentGeojson,
    nextGeojson,
    firstFrameTime,
    nextFrameTime,
    firstFrameIndex,
  };
};

export default useDrawingTools;
