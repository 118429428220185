export enum SocialMedia3060 {
  INSTAGRAM_9_16_30p = 'Instagram 9:16 30p',
  INSTAGRAM_9_16_60p = 'Instagram 9:16 60p',
  INSTAGRAM_1_1_30p = 'Instagram 1:1 30p',
  INSTAGRAM_1_1_60p = 'Instagram 1:1 60p',
  INSTAGRAM_4_5_30p = 'Instagram 4:5 30p',
  INSTAGRAM_4_5_60p = 'Instagram 4:5 60p',
  TWITTER_16_9_30p = 'Twitter 16:9 30p',
  TWITTER_16_9_60p = 'Twitter 16:9 60p',
  TWITTER_9_16_30p = 'Twitter 9:16 30p',
  TWITTER_9_16_60p = 'Twitter 9:16 60p',
  TWITTER_1_1_30p = 'Twitter 1:1 30p',
  TWITTER_1_1_60p = 'Twitter 1:1 60p',
  YOUTUBE_16_9_30p = 'YouTube 16:9 30p',
  YOUTUBE_16_9_60p = 'YouTube 16:9 60p',
  YOUTUBE_9_16_30p = 'YouTube 9:16 30p',
  YOUTUBE_9_16_60p = 'YouTube 9:16 60p',
}
