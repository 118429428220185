import { useMutation } from 'react-query';

import { ForecastWDElementDef } from '../../../model/definitions/ForecastWDElementDef';
import { ForecastElementRequestDTO } from '../../../model/DTO/ForecastElementRequestDTO';
import { axiosInstance } from '../axiosInstance';

export const useGetForecastElement = () =>
  useMutation(async (elements: ForecastElementRequestDTO) => {
    const { data } = await axiosInstance.post('forecast/add-forecast-wd-element', elements);
    return data.map((item: ForecastWDElementDef) => {
      const labelMatch = item.forecastWDSource.location.name?.match(/\((\d+)\)\s(.+)/);
      item.forecastWDSource.location.name = labelMatch
        ? labelMatch[2]
        : item.forecastWDSource.location.name;
      return item;
    });
  });
