import { SceneDef } from '../../../../../model/definitions/SceneDef';
import { useGetSatelliteValue } from '../useGetSatelliteValue';

export const useUpdateSatellite = () => {
  const { mutate: updateSatelliteValues, isLoading: isLoadingSatellite } = useGetSatelliteValue();

  const updateSatellite = (scene: SceneDef | null, dateRange: Array<number | null | undefined>) =>
    scene?.mapPanels.map((map) =>
      map.wdSpace.map((space) =>
        space.satelliteMapLayers.map(
          (satellite) =>
            dateRange[0] &&
            dateRange[1] &&
            updateSatelliteValues({
              dataProductId: satellite.satelliteSource.dataProductId,
              location: satellite.satelliteSource.location,
              typeId: satellite.satelliteSource.satelliteType.id,
              range: [dateRange[0], dateRange[1]],
              density: satellite.dataFramesDensity,
              sceneId: scene.id,
              mapId: map.id,
              spaceId: space.id,
              layerId: satellite.id,
            }),
        ),
      ),
    );

  return { updateSatellite, isLoadingSatellite };
};
