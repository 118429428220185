import { Button } from 'antd';
import { Select, ToggleSwitch } from 'flowbite-react';
import React, { useState } from 'react';
import { AiOutlineCaretDown, AiOutlineCaretUp } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';

import { MapPanelDef } from '../../../model/definitions/MapPanelDef';
import { DrawingTypeEnum } from '../../../model/enums/DrawingTypeEnum';
import { FrontTypeEnum } from '../../../model/enums/FrontTypeEnum';
import ManualElement from '../../../molecules/manualElement/ManualElement';
import { ActiveDef, DrawType, setActiveDraw } from '../../../store/slices/active-slice';
import { RootState } from '../../../store/store';
import InputNumber from '../../marketplace-new/atoms/FormatNumber/FormatNumber';
import SymbolImageModal from '../modals/SymbolImageModal';
import { PaletteColorPicker } from './mapLayersProperties/PalettecolorPicker';
import GridItem from './shared/GridItem';
import GridWrapper from './shared/GridWrapper';

interface Props {
  mapLayer: MapPanelDef;
}

const DrawMapProperties: React.FC<Props> = ({ mapLayer }) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { activeDraw } = useSelector<RootState, ActiveDef>((state) => state.active);
  const [imageModalIsOpen, setImageModalIsOpen] = useState(false);

  const imageCondition = activeDraw.drawingType === DrawingTypeEnum.IMAGE;

  const lineCondition =
    activeDraw.drawingType === DrawingTypeEnum.LINE_STRING ||
    activeDraw.drawingType === DrawingTypeEnum.CIRCLE ||
    activeDraw.drawingType === DrawingTypeEnum.ARROW ||
    activeDraw.drawingType === DrawingTypeEnum.CLOSED_CURVED_LINE ||
    activeDraw.drawingType === DrawingTypeEnum.POLYGON ||
    activeDraw.drawingType === DrawingTypeEnum.RECTANGLE;

  const fillCondition =
    activeDraw.drawingType === DrawingTypeEnum.CIRCLE ||
    activeDraw.drawingType === DrawingTypeEnum.CLOSED_CURVED_LINE ||
    activeDraw.drawingType === DrawingTypeEnum.POLYGON ||
    activeDraw.drawingType === DrawingTypeEnum.RECTANGLE;

  const showFrontCondition = activeDraw.drawingType === DrawingTypeEnum.FRONTS;

  const strokeCondition = lineCondition || showFrontCondition;

  const warmFrontCondition =
    showFrontCondition && activeDraw.frontType === FrontTypeEnum.WARM_FRONT;

  const coldFrontCondition =
    showFrontCondition && activeDraw.frontType === FrontTypeEnum.COLD_FRONT;

  const stationaryFrontCondition =
    showFrontCondition && activeDraw.frontType === FrontTypeEnum.STATIONARY_FRONT;

  const occludedFrontV2Condition =
    showFrontCondition && activeDraw.frontType === FrontTypeEnum.OCCLUDED_FRONT_V2;

  const occludedFrontCondition =
    showFrontCondition && activeDraw.frontType === FrontTypeEnum.OCCLUDED_FRONT;

  const outflowFrontCondition =
    showFrontCondition && activeDraw.frontType === FrontTypeEnum.THROUGH_OR_OUTFLOW_BOUNDARY;

  const regularFrontCondition =
    showFrontCondition && activeDraw.frontType !== FrontTypeEnum.THROUGH_OR_OUTFLOW_BOUNDARY;

  const specFrontCondition = showFrontCondition && !regularFrontCondition;

  const modifyManual = (
    <div>
      <div>1) Enable modify</div>
      <div>2) Select drawing that you wish to modify (ALT + Click)</div>
      <div>3) Change shape of selected drawing</div>
      <div>4) On map free space (ALT + Click) to unselect drawing and apply changes</div>
    </div>
  );

  const drawManual = (
    <div>
      <div>1) Enable drawing</div>
      <div>2) Select type of drawing</div>
      <div>3) Select styles of drawing</div>
      <div>4) Draw element on map</div>
    </div>
  );

  function onEnable(e: boolean) {
    dispatch(setActiveDraw({ newValue: e, path: 'enabled' }));
    dispatch(setActiveDraw({ newValue: false, path: 'modify' }));
  }

  function onModify(e: boolean) {
    dispatch(setActiveDraw({ newValue: e, path: 'modify' }));
    dispatch(setActiveDraw({ newValue: false, path: 'enabled' }));
  }

  function onChange(value: string | boolean, path: Paths<DrawType>) {
    dispatch(setActiveDraw({ newValue: value, path }));
  }

  return (
    <>
      <div
        className={`mb-2 subheader layer-header ${open ? 'layer-header-active' : ''}`}
        onClick={() => setOpen(!open)}
      >
        {open ? <AiOutlineCaretUp /> : <AiOutlineCaretDown />}
        Draw
      </div>
      {open && (
        <>
          <div className="prop-wrapper">
            <div className="prop-wrapper">
              <GridWrapper>
                <GridItem
                  noBorderBg
                  label={`Enable drawing:`}
                  item={
                    <ManualElement content={drawManual}>
                      <ToggleSwitch checked={activeDraw.enabled} label={''} onChange={onEnable} />
                    </ManualElement>
                  }
                />
                <GridItem
                  noBorderBg
                  label={'Shape edit: '}
                  item={
                    <ManualElement content={modifyManual}>
                      <ToggleSwitch checked={activeDraw.modify} label={''} onChange={onModify} />
                    </ManualElement>
                  }
                />
              </GridWrapper>
            </div>
            {activeDraw.enabled && (
              <div className="prop-wrapper">
                <GridWrapper>
                  <GridItem
                    label={`Drawing type:`}
                    item={
                      <Select
                        // className={styles.select}
                        value={activeDraw.drawingType}
                        onChange={(e) => onChange(e.target.value, 'drawingType')}
                      >
                        <option value={''}>None</option>
                        {Object.values(DrawingTypeEnum).map((v) => (
                          <option key={v} value={v}>
                            {v}
                          </option>
                        ))}
                      </Select>
                    }
                  />
                  {strokeCondition && (
                    <GridItem
                      label={`Stroke width:`}
                      item={
                        <InputNumber
                          onInputChange={(e) => onChange(e, 'strokeWidth')}
                          value={activeDraw.strokeWidth}
                        />
                      }
                    />
                  )}

                  {lineCondition && (
                    <GridItem
                      noBorderBg
                      label={`Line color:`}
                      item={
                        <PaletteColorPicker
                          browserRGBA
                          value={activeDraw.lineFill}
                          onChange={(e) => onChange(e, 'lineFill')}
                        />
                      }
                    />
                  )}
                  {fillCondition && (
                    <GridItem
                      noBorderBg
                      label={`Object fill:`}
                      item={
                        <PaletteColorPicker
                          browserRGBA
                          value={activeDraw.objectFill}
                          onChange={(e) => onChange(e, 'objectFill')}
                        />
                      }
                    />
                  )}

                  {showFrontCondition && (
                    <GridItem
                      label={`Front type:`}
                      item={
                        <Select
                          // className={styles.select}
                          value={activeDraw.frontType}
                          onChange={(e) => onChange(e.target.value, 'frontType')}
                        >
                          <option value={''}>None</option>
                          {Object.values(FrontTypeEnum).map((v) => (
                            <option key={v} value={v}>
                              {v}
                            </option>
                          ))}
                        </Select>
                      }
                    />
                  )}
                  {/* {regularFrontCondition && (
                    <GridItem
                      label={`Front density:`}
                      item={
                        <InputNumber
                          onInputChange={(e) => onChange(e, 'frontDensity')}
                          value={activeDraw.frontDensity}
                        />
                      }
                    />
                  )} */}

                  {/* {regularFrontCondition && (
                    <GridItem
                      label={`Size of one part:`}
                      item={
                        <InputNumber
                          onInputChange={(e) => onChange(e, 'sizeOnePart')}
                          value={activeDraw.sizeOnePart}
                        />
                      }
                    />
                  )} */}
                  {specFrontCondition && (
                    <GridItem
                      label={`Line gap:`}
                      item={
                        <InputNumber
                          onInputChange={(e) => onChange(e, 'lineGap')}
                          value={activeDraw.lineGap}
                        />
                      }
                    />
                  )}

                  {specFrontCondition && (
                    <GridItem
                      label={`One dash length:`}
                      item={
                        <InputNumber
                          onInputChange={(e) => onChange(e, 'oneDashLength')}
                          value={activeDraw.oneDashLength}
                        />
                      }
                    />
                  )}
                  {regularFrontCondition && (
                    <GridItem
                      noBorderBg
                      label={`Barb direction:`}
                      item={
                        <ToggleSwitch
                          checked={activeDraw.hornOrientation}
                          label={''}
                          onChange={(e) => onChange(e, 'hornOrientation')}
                        />
                      }
                    />
                  )}
                  {warmFrontCondition && (
                    <GridItem
                      noBorderBg
                      label={`Color:`}
                      item={
                        <PaletteColorPicker
                          browserRGBA
                          value={activeDraw.warmFront}
                          onChange={(e) => onChange(e, 'warmFront')}
                        />
                      }
                    />
                  )}
                  {coldFrontCondition && (
                    <GridItem
                      noBorderBg
                      label={`Color:`}
                      item={
                        <PaletteColorPicker
                          browserRGBA
                          value={activeDraw.coldFront}
                          onChange={(e) => onChange(e, 'coldFront')}
                        />
                      }
                    />
                  )}
                  {stationaryFrontCondition && (
                    <>
                      <GridItem
                        noBorderBg
                        label={`Warm color:`}
                        item={
                          <PaletteColorPicker
                            browserRGBA
                            value={activeDraw.stationaryWarm}
                            onChange={(e) => onChange(e, 'stationaryWarm')}
                          />
                        }
                      />
                      <GridItem
                        noBorderBg
                        label={`Cold color:`}
                        item={
                          <PaletteColorPicker
                            browserRGBA
                            value={activeDraw.stationaryCold}
                            onChange={(e) => onChange(e, 'stationaryCold')}
                          />
                        }
                      />
                    </>
                  )}
                  {occludedFrontCondition && (
                    <GridItem
                      noBorderBg
                      label={`Color:`}
                      item={
                        <PaletteColorPicker
                          browserRGBA
                          value={activeDraw.occludedFront}
                          onChange={(e) => onChange(e, 'occludedFront')}
                        />
                      }
                    />
                  )}
                  {occludedFrontV2Condition && (
                    <GridItem
                      noBorderBg
                      label={`Color:`}
                      item={
                        <PaletteColorPicker
                          browserRGBA
                          value={activeDraw.occludedFrontV2}
                          onChange={(e) => onChange(e, 'occludedFrontV2')}
                        />
                      }
                    />
                  )}
                  {outflowFrontCondition && (
                    <GridItem
                      noBorderBg
                      label={`Color:`}
                      item={
                        <PaletteColorPicker
                          browserRGBA
                          value={activeDraw.outflowFront}
                          onChange={(e) => onChange(e, 'outflowFront')}
                        />
                      }
                    />
                  )}
                  {imageCondition && activeDraw.image ? (
                    <GridItem
                      itemStyle={{ height: 'auto' }}
                      label={'Image:'}
                      noBorderBg
                      item={
                        <>
                          <img
                            src={activeDraw.image}
                            className="aspect-square max-h-14 object-cover mr-3"
                          />
                          <Button className="!h-auto" onClick={() => setImageModalIsOpen(true)}>
                            Change image
                          </Button>
                        </>
                      }
                    />
                  ) : (
                    imageCondition && (
                      <GridItem
                        itemStyle={{ height: 'auto' }}
                        label={'Image:'}
                        noBorderBg
                        item={
                          <Button className="!h-auto" onClick={() => setImageModalIsOpen(true)}>
                            Choose file
                          </Button>
                        }
                      />
                    )
                  )}
                  <GridItem
                    label={`Layer level:`}
                    item={
                      <InputNumber
                        onInputChange={(e) => onChange(e, 'zIndex')}
                        value={activeDraw.zIndex}
                      />
                    }
                  />
                </GridWrapper>
                {imageCondition && (
                  <SymbolImageModal
                    isOpen={imageModalIsOpen}
                    currentImage={activeDraw.image}
                    currentName={activeDraw.name}
                    onClose={() => setImageModalIsOpen(false)}
                    onSave={(selectedImage: string, name: string) => {
                      setImageModalIsOpen(false);
                      onChange(selectedImage, 'image');
                      onChange(name, 'name');
                    }}
                  />
                )}
              </div>
            )}
          </div>
          {/*  {mapLayer.drawingElements.map((d, i, arr) => (
            <DrawLayerProperties
              drawLayer={d}
              isLast={i === arr.length - 1}
              panelProps={mapLayer}
              key={d.id}
            />
          ))} */}
        </>
      )}
    </>
  );
};

export default DrawMapProperties;
