import { notification, Select } from 'antd';
import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import { AiOutlineCaretDown, AiOutlineCaretUp } from 'react-icons/ai';
import { IoSaveOutline } from 'react-icons/io5';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import Button from '../../../atoms/button/Button';
import { useApplyBiasGroupOnPoster } from '../../../core/api/bias/bias-group/useApplyBiasGroupOnPoster';
import { useGetBiasGroups } from '../../../core/api/bias/bias-group/useGetBiasGroups';
import { useCreateGeoPosterTemplate } from '../../../core/api/poster/useCreateGeoPosterTemplate';
import { useCreatePosterTemplate } from '../../../core/api/poster/useCreatePosterTemplate';
import { useUpdateGeoPosterTemplate } from '../../../core/api/poster/useUpdateGeoPosterTemplate';
import { useUpdatePosterTemplate } from '../../../core/api/poster/useUpdatePosterTemplate';
import { getTemplateDetails } from '../../../core/api/TemplatesAPI';
import { ElementsEnum } from '../../../core/ui/enums/ElementsEnum';
import { ScreenshotData } from '../../../helpers/screenshotElement';
import { useImplicitSave } from '../../../hooks/useImplicitSave';
import { C9ProjectDef } from '../../../model/definitions/C9ProjectDef';
import { ForecastWDElementDef } from '../../../model/definitions/ForecastWDElementDef';
import { ObservedWDElementDef } from '../../../model/definitions/ObservedWDElementDef';
import { WeatherPosterDef } from '../../../model/definitions/WeatherPosterDef';
import { ValueTypeEnum } from '../../../model/enums/ValueTypeEnum';
import { ActiveDef } from '../../../store/slices/active-slice';
import { replacePosterInMap, replacePosterInScene } from '../../../store/slices/project-slice';
import {
  selectActiveCompositeLayer,
  selectActiveGraphLayer,
} from '../../../store/slices/selectors';
import { RootState } from '../../../store/store';
import { Panel } from './components/Panel';
import { PositionControls } from './panels/PositionControls';
import { TimeControlsPanel } from './panels/TimeControlsPanel';
import SaveAsTemplateModal from './SaveAsTemplateModal';
import GridItem from './shared/GridItem';
import GridWrapper from './shared/GridWrapper';

const { Option } = Select;
const GraphProperties: React.FC = () => {
  const dispatch = useDispatch();
  const project = useSelector<RootState, C9ProjectDef>((state) => state.project.present.project);
  const graphLayer = useSelector<RootState, WeatherPosterDef | null | undefined>((state) =>
    selectActiveGraphLayer(state),
  );
  const compositeLayer = useSelector<RootState, WeatherPosterDef | null | undefined>((state) =>
    selectActiveCompositeLayer(state),
  );
  const posterLayer = graphLayer || compositeLayer;
  const implicitSave = useImplicitSave();
  const { activeScene, activeElement, activeMap } = useSelector<RootState, ActiveDef>(
    (state) => state.active,
  );
  const [accordionTxtProp, setAccordionTxtProp] = useState<boolean>(false);
  const [opened, setOpened] = useState(false);
  const [posterType, setPosterType] = useState<'OBSERVED' | 'FORECAST' | undefined>();
  const isGeoposter = Boolean(posterLayer?.useAsGeoPoster);
  const existsAsTemplate = Boolean(posterLayer?.templateId && posterLayer.templateVersionId);
  const { mutate: createPoster, isLoading: createLoading } = useCreatePosterTemplate();
  const { mutate: createGeoPoster, isLoading: createGeoLoading } = useCreateGeoPosterTemplate();
  const { mutate: updatePoster, isLoading: updateLoading } = useUpdatePosterTemplate();
  const { mutate: updateGeoPoster, isLoading: updateGeoLoading } = useUpdateGeoPosterTemplate();
  //const { mutate: applyBias } = useApplyBiasOnPoster();
  const { mutate: applyBiasGroup } = useApplyBiasGroupOnPoster();
  const { data } = useQuery(
    ['graph-template-by-id', posterLayer?.templateId, posterLayer?.templateVersionId],
    () =>
      getTemplateDetails(
        posterLayer?.templateId!,
        isGeoposter ? 'weather-geo-poster' : 'weather-poster',
      ),
    { enabled: Boolean(posterLayer?.templateId) },
  );
  const hasBiasApplied =
    !!posterLayer &&
    (posterLayer.observedWDElements.some(
      (element) => element.observedWDSource.biasFilterAppliedOnValue,
    ) ||
      posterLayer.forecastWDElements.some(
        (element) => element.forecastWDSource.biasFilterAppliedOnValue,
      ));
  const removeBias = () => {
    if (posterLayer) {
      const newObserved = posterLayer.observedWDElements.map((item) => {
        if (item.observedWDSource.valueType === ValueTypeEnum.NUMERICAL)
          return {
            ...item,
            observedWDSource: {
              ...item.observedWDSource,
              biasFilterAppliedOnValue: false,
              value: item.observedWDSource.value.map((value: any) => {
                return { ...value, value: value.originalValue };
              }),
            },
          } as unknown as ObservedWDElementDef;
        if (item.observedWDSource.valueType === ValueTypeEnum.IMAGE)
          return {
            ...item,
            observedWDSource: {
              ...item.observedWDSource,
              biasFilterAppliedOnValue: false,
              value: item.observedWDSource.originalValue,
            },
          } as unknown as ObservedWDElementDef;
      });
      const newForecast = posterLayer.forecastWDElements.map((item) => {
        if (item.forecastWDSource.valueType === ValueTypeEnum.NUMERICAL)
          return {
            ...item,
            forecastWDSource: {
              ...item.forecastWDSource,
              biasFilterAppliedOnValue: false,
              value: item.forecastWDSource.value.map((value: any) => {
                return { ...value, value: value.originalValue };
              }),
            },
          } as unknown as ForecastWDElementDef;
        if (item.forecastWDSource.valueType === ValueTypeEnum.IMAGE)
          return {
            ...item,
            forecastWDSource: {
              ...item.forecastWDSource,
              biasFilterAppliedOnValue: false,
              value: item.forecastWDSource.originalValue,
            },
          } as unknown as ForecastWDElementDef;
      });

      !isGeoposter &&
        dispatch(
          replacePosterInScene({
            activeScene,
            weatherPoster: {
              ...posterLayer,
              observedWDElements: newObserved.filter(Boolean) as ObservedWDElementDef[],
              forecastWDElements: newForecast.filter(Boolean) as ForecastWDElementDef[],
            },
          }),
        );
      isGeoposter &&
        dispatch(
          replacePosterInMap({
            activeScene,
            activeMap,
            weatherPoster: {
              ...posterLayer,
              observedWDElements: newObserved.filter(Boolean) as ObservedWDElementDef[],
              forecastWDElements: newForecast.filter(Boolean) as ForecastWDElementDef[],
            },
          }),
        );
      notification.success({ message: 'Group Filter Removed!' });
    }
  };
  useEffect(() => {
    if (posterLayer) {
      if (posterLayer.observedWDElements.some((element) => !!element.observedWDSource)) {
        setPosterType('OBSERVED');
      }
      if (posterLayer.forecastWDElements.some((element) => element.forecastWDSource)) {
        setPosterType('FORECAST');
      }
    }
  }, [posterLayer]);

  const { data: biasFilters } = useGetBiasGroups(posterType);
  const applyBiasGroupOnPoster = (id: string) => {
    if (posterType && posterLayer) {
      applyBiasGroup(
        {
          type: posterType,
          biasGroupId: id,
          body: posterLayer,
          posterType: isGeoposter ? 'weatherGeoPoster' : 'weatherPoster',
        },
        {
          onSuccess: (data, vars) => {
            vars.posterType === 'weatherPoster' &&
              dispatch(replacePosterInScene({ activeScene, weatherPoster: data }));
            vars.posterType === 'weatherGeoPoster' &&
              dispatch(replacePosterInMap({ activeScene, activeMap, weatherPoster: data }));
            notification.success({ message: 'Group Filter Applied!' });
          },
        },
      );
    }
  };
  const filterSelector = () => {
    const options = biasFilters?.map((filter) => (
      <Option value={filter.id} key={filter.id}>
        {filter.name}
      </Option>
    ));
    return (
      <Select key={hasBiasApplied.toString()} onChange={applyBiasGroupOnPoster}>
        {options}
      </Select>
    );
  };

  const saveTemplate = ({
    name,
    description,
    thumbnailURLs,
  }: {
    name: string;
    description: string;
    thumbnailURLs: ScreenshotData[];
  }) => {
    const template = {
      name,
      description,
      thumbnailURLs,
      weatherPosterDef: posterLayer,
      weatherGeoPosterDef: posterLayer,
    };
    if (!existsAsTemplate) {
      const temp = () => {
        if (posterLayer?.useAsGeoPoster) {
          return {
            name,
            description,
            thumbnailURLs,
            weatherGeoPosterDef: posterLayer,
          };
        } else {
          return {
            name,
            description,
            thumbnailURLs,
            weatherPosterDef: posterLayer,
          };
        }
      };
      posterLayer && template.weatherPosterDef?.useAsGeoPoster
        ? createGeoPoster(temp(), {
            onSuccess: (data) => {
              const proj = cloneDeep(project);
              const scene = proj.sceneDefs
                .find((sc) => sc.id === activeScene)
                ?.weatherPosters.find((poster) => poster.id === activeElement);
              if (!scene) return;
              scene.templateId = data.id;
              scene.templateVersionId = data.versionId;
              implicitSave(proj);
              toast.success('Successfully added composite template!');
              setOpened(false);
            },
          })
        : createPoster(temp(), {
            onSuccess: (data) => {
              const proj = cloneDeep(project);
              const scene = proj.sceneDefs
                .find((sc) => sc.id === activeScene)
                ?.weatherPosters.find((poster) => poster.id === activeElement);
              if (!scene) return;
              scene.templateId = data.id;
              scene.templateVersionId = data.versionId;
              implicitSave(proj);
              toast.success('Successfully added poster template!');
              setOpened(false);
            },
          });
    }
    if (existsAsTemplate && posterLayer) {
      const updateTemplate = {
        posterDef: { ...posterLayer },
        templateId: posterLayer!.templateId,
        thumbnailURLs,
        name,
        description,
      };
      isGeoposter
        ? updateGeoPoster(updateTemplate, {
            onSuccess: (data) => {
              const proj = cloneDeep(project);
              const scene = proj.sceneDefs
                .find((sc) => sc.id === activeScene)
                ?.weatherPosters.find((poster) => poster.id === posterLayer?.id);
              if (!scene) return;
              scene.templateVersionId = data.versionId;
              implicitSave(proj);
              toast.success('Successfully updated composite template!');
              setOpened(false);
            },
          })
        : updatePoster(updateTemplate, {
            onSuccess: (data) => {
              const proj = cloneDeep(project);
              const scene = proj.sceneDefs
                .find((sc) => sc.id === activeScene)
                ?.weatherPosters.find((poster) => poster.id === posterLayer?.id);
              if (!scene) return;
              scene.templateVersionId = data.versionId;
              implicitSave(proj);
              toast.success('Successfully updated poster template!');
              setOpened(false);
            },
          });
    }
  };
  /** drag to scroll **/
  return (
    <>
      <Panel
        actions={[
          <Button
            key={posterLayer?.id + 'save_template'}
            buttonType="border"
            onClick={() => setOpened(true)}
            icon={<IoSaveOutline />}
            label={existsAsTemplate ? 'Save new template version' : 'Save as template'}
            className="flex items-center gap-1 property-grey-buttons"
          />,
        ]}
      >
        <div
          className={`mb-2 subheader layer-header ${accordionTxtProp ? 'layer-header-active' : ''}`}
          onClick={() => setAccordionTxtProp(!accordionTxtProp)}
        >
          {accordionTxtProp ? <AiOutlineCaretUp /> : <AiOutlineCaretDown />}
          Composite properties
        </div>
        <>
          {accordionTxtProp && (
            <div className="prop-wrapper">
              <GridWrapper>
                <GridItem noBorderBg label={'Apply Group Bias'} item={filterSelector()} />
                {hasBiasApplied && (
                  <GridItem
                    noBorderBg
                    label={'Remove bias filter'}
                    item={<Button label={'Remove'} onClick={removeBias} />}
                  />
                )}
              </GridWrapper>
            </div>
          )}
          {posterLayer?.positionControl && (
            <PositionControls
              position={posterLayer?.positionControl}
              layer={'weatherPosters'}
              mapId={activeMap}
              isComposite={!!compositeLayer}
              poster={posterLayer.id}
            />
          )}
          {posterLayer?.timeControls && (
            <TimeControlsPanel
              mapId={activeMap}
              timeControls={posterLayer?.timeControls}
              layer={ElementsEnum.WEATHER_GRAPH}
              isComposite={!!compositeLayer}
            />
          )}
        </>
      </Panel>
      {posterLayer && opened && (
        <SaveAsTemplateModal
          entityName={posterLayer!.name}
          onClose={() => setOpened(false)}
          // @ts-ignore
          existing={data}
          onConfirm={saveTemplate}
          opened={opened}
          loading={createLoading || createGeoLoading || updateLoading || updateGeoLoading}
          templateId={posterLayer.templateId}
          type={isGeoposter ? 'weather-geo-poster' : 'weather-poster'}
        />
      )}
    </>
  );
};

export default GraphProperties;
