import { message } from 'antd';
import { useContext, useEffect, useRef, useState } from 'react';
import { AiOutlinePlus, AiOutlineSearch } from 'react-icons/ai';
import { toast } from 'react-toastify';

import Button from '../../../atoms/button/Button';
import { useDebounce } from '../../../hooks/useDebounce';
import { SortingEnum } from '../../../model/enums/SortingEnum';
import Sorting from '../../marketplace-new/molecules/Sorting/Sorting';
import GridLayout from '../layout-NEW/GridLayout';
import { AddFontModal } from '../modals/AddFontModal';
import useCreateEnterpriseFont from '../queries-NEW/useCreateEnterpriseFont';
import useDeleteFont from '../queries-NEW/useDeleteFont';
import { useEditFont } from '../queries-NEW/useEditFont';
import useGetEnterpriseFonts from '../queries-NEW/useGetEnterpriseFonts';
import { UpdateInterface } from '../templates-NEW/Projects';
import SortContext from '../templates-NEW/sortContext';
import { hasDuplicateFieldValue } from '../templates-NEW/Templates';

interface CustomFormData {
  [k: string]: string;
}

function Fonts() {
  const searchRef = useRef<HTMLInputElement>(null);
  const [page, setPage] = useState<number>(0);
  const [search, setSearch] = useState<string>('');
  const [addFont, setAddFont] = useState<boolean>(false);
  const [fontWarningId, setFontWarningId] = useState<string>();
  const [addForm, setAddForm] = useState<CustomFormData>();
  const { mutate: createFont, isLoading: loadCreateFont } = useCreateEnterpriseFont();
  const deleteFont = useDeleteFont();
  const updateFont = useEditFont();
  const [fontName, setFontName] = useState('');
  const [fontDescription, setFontDescription] = useState('');
  const [uploading, setUploading] = useState<Array<File>>([]);
  const { sortAscDesc, sortByField, handleSort } = useContext(SortContext);
  const searchKey = useDebounce(search, 300);
  const type = 'font';
  const {
    data: fonts,
    refetch,
    isLoading,
    isError,
    error,
  } = useGetEnterpriseFonts({
    page,
    type,
    searchKey,
    sort: sortByField + ',' + sortAscDesc,
  });
  const resetAddFontModal = () => {
    setFontName('');
    setFontDescription('');
    setUploading([]);
  };

  const handleUpload = () => {
    if (uploading.length === 0) {
      message.error('Please select at least one file.');
      return;
    }

    if (!fontName) {
      message.error('Please enter a name.');
      return;
    }

    const formData = new FormData();
    formData.append(type, JSON.stringify({ name: fontName, description: fontDescription }));
    uploading.forEach((file) => {
      file && formData.append('fontFiles', file);
    });

    createFont(formData, {
      onSuccess: (response) => {
        const hasDuplicates = hasDuplicateFieldValue(response.data.fontVariants);
        if (hasDuplicates) {
          setFontWarningId(response.data.id);
          toast.error('Metadata cannot be read from files. Please manually add font types.');
        }
        setAddFont(false);
        resetAddFontModal();
      },
    });
  };

  const onDelete = (id: string) =>
    deleteFont.mutate(
      { id: id, type },
      {
        onSuccess: async () => {
          if (fonts?.content.length === 1 && page > 0) {
            setPage(page - 1);
          }
        },
      },
    );

  const onUpdate = (data: UpdateInterface) => {
    const fontData = {
      id: data.id,
      name: data.newName,
      description: data.newDescription,
    };
    updateFont.mutate(fontData);
  };

  const onChange = (value: string, name: string) => {
    setAddForm({ ...addForm, [name]: value });
  };

  useEffect(() => {
    if (page !== 0) {
      const timer = setTimeout(() => {
        setPage(0);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [search]);

  useEffect(() => {
    refetch();
  }, [page, searchKey, sortAscDesc, sortByField, refetch]);
  return (
    <>
      <div className={'flex ws-fixed-header'}>
        <div className={'ws-temp-title'}>
          <h1>Fonts</h1>
        </div>

        <Button
          className={`ws-button`}
          onClick={() => setAddFont(true)}
          label="Add Font"
          icon={<AiOutlinePlus />}
        />
        <div className={'ws-input'} style={{ height: '32px' }}>
          <input
            ref={searchRef}
            value={search}
            autoFocus
            onChange={(e) => setSearch && setSearch(e.target.value)}
            placeholder={'Type here...'}
            className="w-full 2xl:!w-[300px]"
          />
          <AiOutlineSearch color={'rgba(0,0,0,0.3)'} size={24} />
        </div>
        {fonts?.content && fonts?.content.length > 1 && (
          <div className="ml-auto">
            <Sorting
              sortAscDesc={sortAscDesc}
              sorting={SortingEnum}
              handleSort={handleSort}
              sortByField={sortByField}
            />
          </div>
        )}
      </div>

      <AddFontModal
        open={addFont}
        fontName={fontName}
        setFontName={setFontName}
        fontDescription={fontDescription}
        setFontDescription={setFontDescription}
        setUploading={setUploading}
        onClose={() => {
          setAddFont(false);
          resetAddFontModal();
        }}
        onCreateFont={handleUpload}
        onChange={(value, name) => onChange(value, name)}
        loading={loadCreateFont}
      />
      <GridLayout
        searchString={searchKey}
        error={error}
        onDelete={(id) => onDelete(id)}
        onEdit={(data: Pick<UpdateInterface, 'newName' | 'newDescription' | 'id' | 'versionId'>) =>
          onUpdate({
            ...data,
            type: 'FONT_TEMPLATE',
            key: type,
          })
        }
        loading={isLoading}
        isError={isError}
        pages={fonts?.totalPages}
        currentPage={page}
        changePage={(e) => setPage(e)}
        onSearch={setSearch}
        contents={fonts?.content}
        type={type}
        fontWarningId={fontWarningId}
        setFontWarningId={setFontWarningId}
      />
    </>
  );
}

export default Fonts;
