import { FC, ReactNode } from 'react';

import CUT from './CUT';
import { FADE_INProps } from './FADE_IN';
import { FADE_OUTProps } from './FADE_OUT';

interface AnimationOptions {
  duration?: number;
  enterTime?: number;
  exitTime?: number;
  currentTime: number;
  opacity?: number;
}
export function animate(
  children: ReactNode,
  inAnimation: FC<FADE_INProps> | FC<FADE_OUTProps> = CUT,
  inOptions: AnimationOptions,
  outAnimation: FC<FADE_INProps> | FC<FADE_OUTProps> = CUT,
  outOptions: AnimationOptions,
) {
  const OutroAnimation = outAnimation;
  const IntroAnimation = inAnimation;
  return (
    <OutroAnimation {...outOptions}>
      <IntroAnimation {...inOptions}>{children}</IntroAnimation>
    </OutroAnimation>
  );
}
