import './style.scss';

import { Label, Modal, TextInput } from 'flowbite-react';
import React, { useState } from 'react';

import Button from '../../../atoms/button/Button';
import { ScreenshotData } from '../../../helpers/screenshotElement';
import ThumbnailUpload from '../../../molecules/thumbnailUplod/ThumbnailUpload';

interface TemplateProps {
  name: string;
  description: string;
  thumbnailURLs: (ScreenshotData & { id: string })[];
}
interface Props {
  opened: boolean;
  onClose: () => void;
  onConfirm: (data: TemplateProps) => void;
  entityName: string;
  loading: boolean;
  templateId: string;
  type: 'project' | 'scene' | 'map' | 'weather-poster' | 'weather-geo-poster' | 'layer';
}

const SaveAsTemplateModal: React.FC<Props> = ({
  opened,
  onClose,
  onConfirm,
  entityName,
  loading,
  templateId,
  type,
}) => {
  const [{ name, description, thumbnailURLs }, setFormData] = useState<TemplateProps>({
    name: `${entityName}-template`,
    description: '',
    thumbnailURLs: [],
  });
  const [activeThumb, setActiveThumb] = useState<string | null>(null);

  function onChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    prop: keyof TemplateProps,
  ) {
    setFormData((d) => ({ ...d, [prop]: e.target.value }));
  }
  function hasError() {
    return !name;
  }

  return (
    <Modal show={opened} onClose={onClose}>
      <div className="save-as-template-wrapper">
        <div className="modal-header">
          <Modal.Header>
            {templateId ? 'Save new version of template' : 'Save as template?'}
          </Modal.Header>
        </div>
        <Modal.Body>
          <div>
            <div className="mb-2 block mt-2">
              <Label value="Thumbnails" />
            </div>
            <ThumbnailUpload
              thumbnailURLs={thumbnailURLs}
              setFormData={setFormData}
              opened={opened}
              templateId={templateId}
              activeThumb={activeThumb}
              setActiveThumb={setActiveThumb}
              type={type}
            />
          </div>
          <div>
            <div className="mb-2 block mt-2">
              <Label htmlFor="name" value="Name" />
            </div>
            <TextInput
              value={name}
              onChange={(e) => onChange(e, 'name')}
              id="name"
              type="text"
              sizing="md"
            />
          </div>
          <div>
            <div className="mb-2 block mt-2">
              <Label htmlFor="description" value="Description" />
            </div>
            <TextInput
              value={description}
              onChange={(e) => onChange(e, 'description')}
              id="description"
              type="text"
              sizing="md"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={hasError() || loading}
            onClick={() => onConfirm({ name, description, thumbnailURLs })}
            loading={loading}
            label="Save"
          />

          <Button label="Cancel" style={{ backgroundColor: '#808080' }} onClick={onClose} />
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default SaveAsTemplateModal;
