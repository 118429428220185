import { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';

import { fromBoundsToPointBox } from '../../../helpers/boundsManage';
import { DataFrameDef } from '../../../model/definitions/DataFrameDef';
import { SourceDef } from '../../../model/definitions/EventSourceDef';
import { axiosInstancePoint } from '../axiosInstancePoint';

export const useGetEventSources = () => {
  return useQuery(['getEventSources'], async () => {
    const response = await axiosInstancePoint.get<
      any,
      AxiosResponse<{ dataProducts: SourceDef[] }>
    >(`event/divep/sources`, {});
    return response.data;
  });
};
export const useGetEventTimestamps = (
  dataProductId: string | null,
  bounds: [number, number, number, number],
  minimumPower: number,
) => {
  return useQuery(
    ['getEventTimestamps', bounds, minimumPower],
    async () => {
      const boundingBox = fromBoundsToPointBox(bounds);
      const response = await axiosInstancePoint.post<
        any,
        AxiosResponse<{ events: { utcDate: number; eventId: number }[] }>
      >(`event/timestamps`, {
        dataProductId,
        minimumPower,
        boundingBox,
      });

      const frames: DataFrameDef[] = response.data.events?.map((e) => ({
        frameId: `${e.eventId}`,
        timestamp: e.utcDate,
      }));

      frames.sort((a, b) => {
        return a.timestamp - b.timestamp;
      });

      return frames;
    },
    {
      enabled: Boolean(dataProductId),
    },
  );
};
