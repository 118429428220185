import { Select } from 'flowbite-react';
import { useEffect } from 'react';

import { useFontLoader, useFontSetter } from '../../../../core/api/useLoadFont';
import { CityGeoPosterDef } from '../../../../model/definitions/CityGeoPosterDef';
import { TextPanelDef } from '../../../../model/definitions/TextPanelDef';
import transformText from '../../properties/components/slatejs/transformText';
import { FontInterface, FontVariantInterface } from '../../properties/panels/FontProperties';
import GridItem from '../../properties/shared/GridItem';
import styles from '../AddCitiesModal.module.scss';

interface FontSelecterInterface {
  onCityChange: (cityId: number | string, prop: Paths<CityGeoPosterDef>, newValue: any) => any;
  fonts: FontInterface[];
  textPanel: TextPanelDef;
  id: string;
  defaultFontId: string;
}

function FontSelector({
  onCityChange,
  fonts,
  textPanel,
  id,
  defaultFontId,
}: FontSelecterInterface) {
  const { fontVariants, fetchFontVariants, setFontId } = useFontLoader(textPanel?.fontFamily);

  const defaultFont = defaultFontId;
  useFontSetter(
    fontVariants,
    textPanel?.fontType,
    fontTypeSetter,
    fontVariantIdSetter,
    fetchFontVariants.isLoading,
  );

  function fontTypeSetter(value: string) {
    // @ts-ignore
    onCityChange(id, 'textPanels[0].fontType', value);
  }

  function fontVariantIdSetter(value: string) {
    // @ts-ignore
    onCityChange(id, 'textPanels[0].fontVariantId', value);
  }

  useEffect(() => {
    if (defaultFont !== defaultFontId) setFontId(defaultFontId);
  }, [defaultFontId]);

  return (
    <>
      <GridItem
        noBorderBg
        label="Font family:"
        item={
          <Select
            className={styles.select}
            value={textPanel?.fontFamily}
            onChange={(e) => {
              // @ts-ignore
              onCityChange(id, 'textPanels[0].fontFamily', e.target.value);
              // @ts-ignore
              onCityChange(id, 'textPanels[0].fontType', '');
              // @ts-ignore
              onCityChange(id, 'textPanels[0].fontVariantId', '');
              const findFont = fonts?.find((font: FontInterface) => font.name === e.target.value);
              if (findFont) {
                setFontId(findFont.id);
              }
            }}
          >
            {fonts?.map((font: FontInterface) => (
              <option
                key={font.id}
                value={font.name.split('(')[0]}
                style={{ fontFamily: font.name.split('(')[0] }}
              >
                {font.name}
              </option>
            ))}
          </Select>
        }
      />

      <GridItem
        noBorderBg
        label="Font type:"
        item={
          <Select
            className={styles.select}
            value={textPanel?.fontType}
            onChange={(e) => {
              const findType = fontVariants.find(
                (fontVariant: FontVariantInterface) => fontVariant.type === e.target.value,
              );
              // @ts-ignore
              onCityChange(id, 'textPanels[0].fontType', e.target.value);
              // @ts-ignore
              onCityChange(id, 'textPanels[0].fontVariantId', findType.id);
            }}
          >
            {fontVariants?.map((fontVariant: FontVariantInterface) => (
              <option key={fontVariant.id} value={fontVariant.type}>
                {fontVariant.type}
              </option>
            ))}
          </Select>
        }
      />

      <GridItem
        label="Text transform:"
        noBorderBg
        item={
          <Select
            className={styles.select}
            value={textPanel?.textTransform}
            onChange={(e) => {
              // @ts-ignore
              onCityChange(id, 'textPanels[0].textTransform', e.target.value);
            }}
          >
            {transformText.map((item) => (
              <option key={item.format} value={item.format}>
                {item.name}
              </option>
            ))}
          </Select>
        }
      />
    </>
  );
}

export default FontSelector;
