import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  FileSearchOutlined,
  PlusOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import { Button, Checkbox, Modal, Space, Table, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { Link } from 'react-router-dom';

import { AccountDTO } from '../../../../model/DTO/AccountDTO';
import { AccountTypeEnum } from '../../../../model/enums/AccountTypeEnum';
import { UserActionsModal } from '../../../../organisms/userActionsModal/UserActionsModal';
import { useDeleteAccount } from '../../queries-NEW/useDeleteAccount';
import { useGetAccounts } from '../../queries-NEW/useGetAccounts';

const { confirm } = Modal;

export const Enterprise = () => {
  const [search, setSearch] = useState<string>('');
  const [openActionsModal, setOpenActionsModal] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<AccountDTO | null>();
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [filteredUsers, setFilteredUsers] = useState<AccountDTO[]>([]);
  const {
    data: users,
    isLoading: usersIsLoading,
    refetch: refetchUsers,
  } = useGetAccounts('enterprise-accounts');
  const { mutate: deleteAccount } = useDeleteAccount();
  const columnsUsers: any[] = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: {
        compare: (a: AccountDTO, b: AccountDTO) => a.name && b.name && a.name.localeCompare(b.name),
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
      sorter: {
        compare: (a: AccountDTO, b: AccountDTO) => a.description.localeCompare(b.description),
      },
    },
    {
      title: 'Owner Email',
      dataIndex: 'ownerEmail',
      sorter: {
        compare: (a: AccountDTO, b: AccountDTO) =>
          a.ownerEmail && b.ownerEmail && a.ownerEmail.localeCompare(b.ownerEmail),
      },
    },
    {
      title: 'Enabled',
      dataIndex: 'isEnabled',
      render: (text: string, record: AccountDTO) => (
        <span>
          <Checkbox checked={record.isEnabled} />
        </span>
      ),
      sorter: {
        compare: (a: AccountDTO, b: AccountDTO) =>
          a.isEnabled === b.isEnabled ? 0 : a.isEnabled ? -1 : 1,
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      align: 'center',
      render: (text: string, record: AccountDTO) => (
        <Space size="small">
          <Tooltip title="Data products" trigger={['click', 'hover']}>
            <Link to={`./${record.id}`}>
              <Button size="small" type="text" icon={<FileSearchOutlined />} />
            </Link>
          </Tooltip>
          <Tooltip title="Edit" trigger={['click', 'hover']}>
            <Button
              size="small"
              type="text"
              loading={record.id === selectedUser?.id}
              onClick={() => {
                handleEdit(record);
              }}
              icon={<EditOutlined />}
            />
          </Tooltip>
          <Tooltip title="Delete" trigger={['click', 'hover']}>
            <Button
              size="small"
              type="text"
              danger
              loading={record.id === selectedUserId}
              onClick={() => {
                setSelectedUserId(record.id ?? null);
                handleDelete(record);
              }}
              icon={<DeleteOutlined />}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const handleSearch = (value: string) => {
    if (!value) {
      return setFilteredUsers(users);
    }
    const newUsers = users.filter((user: AccountDTO) =>
      Object.keys(user).some((key) => {
        return String(user[key as keyof AccountDTO])
          .toLowerCase()
          .includes(value.toLowerCase());
      }),
    );
    setFilteredUsers(newUsers);
  };

  const handleEdit = (record: AccountDTO) => {
    setSelectedUser(record);
    setOpenActionsModal(true);
  };

  const handleDelete = (record: AccountDTO) => {
    confirm({
      title: 'Delete user',
      icon: <ExclamationCircleFilled />,
      content: 'Are you sure you want to delete this enterprise?',
      centered: true,
      okText: 'Delete',
      okType: 'danger',
      onOk() {
        deleteAccount(
          { api: 'enterprise-accounts', id: record.id ?? '' },
          {
            onSuccess: () => {
              setSelectedUserId(null);
            },
            onError: () => {
              setSelectedUserId(null);
            },
          },
        );
      },
      onCancel() {
        setSelectedUserId(null);
      },
    });
  };

  const handleCloseModal = () => {
    setSelectedUser(null);
    setOpenActionsModal(false);
  };

  useEffect(() => {
    handleSearch(search);
  }, [users]);

  return (
    <>
      <div className={'flex ws-fixed-header'}>
        <div className="ws-fixed-header-add-project">
          <div className={'ws-temp-title'}>
            <h1>Enterprise</h1>
          </div>
          <Space size="small">
            <Button
              type="primary"
              icon={
                <span style={{ marginRight: '0.5rem' }}>
                  <ReloadOutlined />
                </span>
              }
              loading={usersIsLoading}
              onClick={() => refetchUsers()}
              className="px-4"
            >
              Refresh
            </Button>
            <Button
              type="primary"
              icon={
                <span style={{ marginRight: '0.5rem' }}>
                  <PlusOutlined />
                </span>
              }
              onClick={() => setOpenActionsModal(true)}
              className="px-4"
            >
              Add User
            </Button>
          </Space>
        </div>
        <div className={'ws-input ws-input-project'} style={{ height: '32px' }}>
          <input
            autoFocus
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
              handleSearch(e.target.value);
            }}
            placeholder={'Type here...'}
            className="w-full 2xl:!w-[300px]"
          />
          <AiOutlineSearch color={'rgba(0,0,0,0.3)'} size={24} />
        </div>
      </div>
      <Table
        rowKey="id"
        loading={usersIsLoading}
        columns={columnsUsers}
        dataSource={filteredUsers}
        pagination={false}
      />
      <UserActionsModal
        opened={openActionsModal}
        action={selectedUser ? 'EDIT' : 'ADD'}
        existingUser={selectedUser}
        type={AccountTypeEnum.ENTERPRISE}
        onClose={handleCloseModal}
      />
    </>
  );
};
