import './style.scss';

import { useContext } from 'react';
import { BsBookmarkPlus } from 'react-icons/bs';
import { useLocation, useNavigate } from 'react-router-dom';

import { MarketplaceTemplateEnum } from '../../../../model/enums/MarketplaceTemplateEnum';
import Button from '../../atoms/Button/Button';
import MarketplaceContext from '../../marketplaceContext';
import { MarketplaceItem } from '../../model/interfaces/MarketplaceItem';

interface ItemCardInterface {
  item: MarketplaceItem;
  isAdmin?: boolean;
}
const regex = /^([^\s@.]+)\.([^\s@.]+)@[^\s@]+$/;
const ItemCard = ({ item, isAdmin }: ItemCardInterface) => {
  const { setType } = useContext(MarketplaceContext);
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname.indexOf('/my-store/');

  const name = () => {
    const name = item.creator.match(regex);
    if (name) {
      return `${name[1]} ${name[2]}`;
    }
  };

  function redirectToDetailsPage(id: string) {
    path === -1
      ? navigate(`/marketplace/items/details-page/${id}`)
      : navigate(`/marketplace/my-store/items/details-page/${id}`);
  }

  const convertEnumToString = (type: MarketplaceTemplateEnum) => {
    switch (type) {
      case MarketplaceTemplateEnum.MAP_TEMPLATE:
        return 'Maps';
      case MarketplaceTemplateEnum.IMAGE_TEMPLATE:
        return 'Images';
      case MarketplaceTemplateEnum.VIDEO_TEMPLATE:
        return 'Videos';
      case MarketplaceTemplateEnum.AUDIO_TEMPLATE:
        return 'Audios';
      case MarketplaceTemplateEnum.WEATHER_POSTER_TEMPLATE:
        return 'Posters';
      case MarketplaceTemplateEnum.WEATHER_GEO_POSTER_TEMPLATE:
        return 'Geo Posters';
      case MarketplaceTemplateEnum.PROJECT_TEMPLATE:
        return 'Projects';
      case MarketplaceTemplateEnum.SCENE_TEMPLATE:
        return 'Scenes';
    }
  };
  function convertCurrency(currency: string) {
    switch (currency) {
      case 'eur':
        return '€';
      case 'dollar':
        return '$';
      default:
        return currency;
    }
  }
  return (
    <div className={'item-card'} onClick={() => redirectToDetailsPage(item.id)}>
      <div className={'image-container'}>
        <img src={item.thumbnailUrls[0]} alt={item.name} height={'220px'} width={'100%'} />
        {isAdmin && (
          <div
            className={`absolute top-1 text-center w-full font-bold ${
              item.approved ? 'text-blue-700' : 'text-orange-700'
            }`}
          >
            {item.approved ? 'Item approved' : 'Pending approval'}
          </div>
        )}
        <div className={'overlay'} />
      </div>
      <div className={'item-data'}>
        <p>{item.name}</p>
        <div className={'item-links'}>
          <span className={'italic'}>by</span>{' '}
          <span className={'underline font-extrabold'}>{name()}</span>
          <span> in </span>
          <span className={'underline font-extrabold'} onClick={() => setType([item.templateType])}>
            {convertEnumToString(item.templateType)}
          </span>
        </div>
        <div className={'item-buttons flex mt-[5px]'}>
          <span>{item.price + convertCurrency(item.priceCurrency)}</span>
          <Button buttonType="link">
            <BsBookmarkPlus size={24} />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ItemCard;
