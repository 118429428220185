import { QueryKey, useQuery } from 'react-query';

import { axiosInstance } from '../../../../core/api/axiosInstance';

export const key: QueryKey = ['PAYMENT_TRANSACTION_BY_ID'];

export const useGetTransactionById = (id: string) => {
  return useQuery(
    [...key, id],
    async () => {
      const response = await axiosInstance.get(`payment/transaction/${id}`);
      return response.data;
    },
    { enabled: !!id },
  );
};
