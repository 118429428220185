import { IoConstructOutline } from 'react-icons/io5';

import styles from './InDevelopment.module.scss';

const InDevelopment = () => {
  return (
    <div className="flex justify-between flex-col h-full in-development">
      <div className={styles.wrapper} style={{ backgroundColor: '#f6f6f666' }}>
        <IoConstructOutline />
        <span>This page is under development!</span>
      </div>
    </div>
  );
};
export default InDevelopment;
