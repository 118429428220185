/* eslint-disable */
import { useSearchParams } from 'react-router-dom';

import { useGetAssemblyTaskById } from '../../core/api/VideoAssemblyAPI';
import Loader from '../dashboard/components/Loader';
import { RenderStatsTimeline } from './RenderStatsTimeline';

export const VRProfiler = () => {
  const [params] = useSearchParams();
  const taskId = params.get('taskId') || '';

  const { data, isLoading } = useGetAssemblyTaskById(taskId);

  const statistics = data
    ? Object.keys(data.statistics).map((key) => [key, data.statistics[key]])
    : [];

  statistics.sort(function (a, b) {
    const numA = parseInt(a[0].slice(0, 2));
    const numB = parseInt(b[0].slice(0, 2));
    const charA = a[0].charAt(2);
    const charB = b[0].charAt(2);
    if (numA !== numB) {
      return numA - numB;
    }
    if (charA !== ' ' && charB !== ' ') {
      if (charA < charB) {
        return -1;
      }

      if (charA > charB) {
        return 1;
      }
    }
    return 0;
  });

  let renderData;
  const jsonData = Object.values(statistics).pop();
  statistics.pop();
  if (jsonData) {
    const dataFromJson = JSON.parse(jsonData[1]);
    renderData = dataFromJson;
  }

  const statisticsContent = (
    <div>
      <table className="bg-[#06152b]">
        <tbody>
          {statistics.length > 0 ? (
            statistics.map((statistic) => {
              return (
                <tr key={statistic[0]}>
                  <td className="border-b border-slate-600 p-2 text-slate-300">{statistic[0]}</td>
                  <td className="border-b border-slate-600 p-2 text-slate-300">{statistic[1]}</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td className="border-b border-slate-600 p-2 text-slate-300">No data</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
  return (
    <div className="bg-[#06152b] flex flex-row w-full">
      {isLoading ? (
        <div>
          <Loader />
        </div>
      ) : (
        <>
          <div className="border border-slate-600">{statisticsContent}</div>
          <div className="w-full">
            {renderData ? (
              <RenderStatsTimeline data={renderData} />
            ) : (
              <div className="flex h-screen">
                <div className="m-auto">
                  <span className="text-white text-4xl">No data</span>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};
