import { Modal } from 'flowbite-react';
import React, { useRef, useState } from 'react';

import Button from '../../../atoms/button/Button';
import { ScreenshotData } from '../../../helpers/screenshotElement';

interface Props {
  opened: boolean;
  onClose: () => void;
  loading: boolean;
  onConfirm: (data: ScreenshotData) => void;
  currentThumbnail: string;
}

const ImageAudioThumbnailModal: React.FC<Props> = ({
  opened,
  onClose,
  loading,
  onConfirm,
  currentThumbnail,
}) => {
  const [thumbnail, setThumbnail] = useState<ScreenshotData | null>(null);
  const hiddenFileInput = useRef<HTMLInputElement | null>(null);

  const handleClick = () => {
    hiddenFileInput?.current?.click();
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileUploaded = event.target?.files?.[0];
    if (!fileUploaded) return;
    const reader = new FileReader();
    reader.readAsDataURL(fileUploaded!);
    reader.onload = function () {
      setThumbnail({ base64: reader.result as string, blob: fileUploaded! });
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  };
  return (
    <Modal show={opened} onClose={onClose}>
      <Modal.Header>Set thumbnail?</Modal.Header>
      <Modal.Body>
        <div className="w-full h-[300px] overflow-hidden">
          {thumbnail && thumbnail.base64 ? (
            <img
              className="h-[300px] object-contain m-auto"
              alt="thumbnail"
              src={thumbnail.base64}
            ></img>
          ) : (
            <img
              className="h-[300px] object-contain m-auto"
              alt="thumbnail"
              src={currentThumbnail}
            ></img>
          )}
        </div>
        <div className="flex items-center justify-center">
          <Button label="Upload a file" onClick={handleClick} />
          <input
            type="file"
            accept="image/png,image/jpeg"
            multiple={false}
            ref={hiddenFileInput}
            onChange={handleChange}
            style={{ display: 'none' }}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={!thumbnail || loading}
          onClick={() => onConfirm(thumbnail!)}
          loading={loading}
          label="Save"
        />

        <Button label="Cancel" style={{ backgroundColor: '#808080' }} onClick={onClose} />
      </Modal.Footer>
    </Modal>
  );
};

export default ImageAudioThumbnailModal;
