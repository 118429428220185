import './style.scss';
import 'react-dropzone-uploader/dist/styles.css';

import { AxiosError, AxiosResponse } from 'axios';
import { Progress } from 'flowbite-react';
import { useEffect, useRef, useState } from 'react';
import Dropzone, { IFileWithMeta, IUploadParams } from 'react-dropzone-uploader';
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';
import { RiDragDropLine } from 'react-icons/ri';

import { axiosInstance } from '../../core/api/axiosInstance';
import formatFileSize from '../../helpers/formatFileSize';

interface FileUploadProps {
  theme?: 'dark' | 'light';
  fileType: 'IMAGE' | 'VIDEO' | 'AUDIO';
  formValues: {
    name?: string;
    description?: string;
  };
  displayStatus: string;
  setDisplayStatus: React.Dispatch<React.SetStateAction<string>>;
  setSuccessfullyAdded: React.Dispatch<React.SetStateAction<boolean>>;
  setFile: React.Dispatch<React.SetStateAction<IFileWithMeta | undefined>>;
  file: IFileWithMeta | undefined;
  onChange?: (e: any) => void;
}
const baseUrl = process.env.REACT_APP_API_BASE_URL;
function FileInput({
  fileType,
  formValues,
  setSuccessfullyAdded,
  setFile,
  file,
  onChange,
  displayStatus,
  setDisplayStatus,
  theme = 'dark',
}: FileUploadProps) {
  const [progress, setProgress] = useState<ProgressEvent>();
  const [uploadedFile, setUploadedFile] = useState<AxiosResponse>();
  const [error, setError] = useState<AxiosError>();
  useEffect(() => {
    onChange && onChange(uploadedFile?.data);
  }, [uploadedFile?.data]);
  const controller = useRef(new AbortController());
  useEffect(() => {
    if (controller.current.signal.aborted) controller.current = new AbortController();
  }, [error, uploadedFile, progress]);
  //@ts-ignore
  const getUploadParams: (
    file: IFileWithMeta,
    abort: AbortController,
  ) => IUploadParams | Promise<IUploadParams> = async (file, abort) => {
    const data = new FormData();
    data.append('file', file.file, `${file.meta.name}`);
    data.append('type', fileType);
    data.append('description', formValues.description ? formValues.description : '');
    data.append('name', formValues.name ? formValues.name : '');
    const params: { isProxyCreatingAsync?: boolean } = {};
    if (fileType === 'VIDEO') {
      params.isProxyCreatingAsync = false;
    }
    try {
      setError(undefined);
      const addedFile = await axiosInstance.post(
        `${baseUrl}/api/store/multimedia/uploadFile`,
        data,
        {
          signal: abort.signal,
          headers: { 'content-type': 'multipart/form-data' },
          params,
          onUploadProgress: function (progressEvent) {
            setProgress(progressEvent);
          },
        },
      );
      setUploadedFile(addedFile);
      const file = addedFile as unknown as IFileWithMeta;
      file.meta.status = 'done';
      return file;
    } catch (e) {
      const error = e as AxiosError;
      setError(error);
      return 'error';
    }
  };

  const getAccept = () => {
    switch (fileType) {
      case 'IMAGE':
        return 'image/png, image/jpeg';
      case 'VIDEO':
        return '.mxf,video/*';
      case 'AUDIO':
        return '.mp3,.flac';
    }
  };

  useEffect(() => {
    if (progress && progress?.loaded === progress?.total && !error?.response?.status) {
      setSuccessfullyAdded(true);
    }
    function handleKeyDown(e: KeyboardEvent) {
      if ((e.key === 'o' && e.metaKey) || e.ctrlKey) {
        e.preventDefault();
        if (fileRef.current) {
          fileRef.current.click();
        }
      }
    }

    document.addEventListener('keydown', handleKeyDown);
    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [progress, error?.response?.status, setSuccessfullyAdded, fileType]);
  const parseError = (stat?: number) => {
    switch (stat) {
      case 400:
        return 'Invalid fileType!';
      case 401:
        return 'Authorization expired. Please log in again!';
      case 408:
        return 'Upload took too long! Try again...';
      case 413:
        return 'File is to large!';
      case 415:
        return 'Unsupported file type!';
      case 500:
        return 'Error uploading file!';
      case 503 || 502 || 504:
        return 'Service unavailable';
      case 507:
        return 'Insufficient storage!';
      default:
        return;
    }
  };
  const fileRef = useRef<HTMLInputElement>(null);
  return (
    <>
      <Dropzone
        getUploadParams={(f) => getUploadParams(f, controller.current)}
        styles={{
          dropzone: {
            overflow: 'auto',
            border: `1px dashed ${theme === 'dark' ? '#ffffff61' : '#00000061'}`,
          },
        }}
        accept={getAccept()}
        canRemove={true}
        disabled={!formValues.name}
        maxFiles={1}
        maxSizeBytes={52428800}
        multiple={false}
        canCancel={true}
        autoUpload={false}
        PreviewComponent={(preview) =>
          displayStatus !== 'removed' && (
            <div className={'preview-wrapper'}>
              <div className={'preview'}>
                <div
                  onClick={() => {
                    preview.fileWithMeta.remove();
                    controller.current.abort('User aborted upload');
                    setUploadedFile(undefined);
                    setProgress(undefined);
                    setError(undefined);
                  }}
                  className={'remove-file'}
                >
                  <AiOutlineClose />
                </div>
                {progress ? (
                  <>
                    <div className={'progress'}>
                      <Progress
                        label={
                          preview.fileWithMeta.file.name +
                          ` ${
                            formatFileSize(progress.loaded) + '/' + formatFileSize(progress.total)
                          }`
                        }
                        progress={Math.round((progress.loaded / progress.total) * 100)}
                        size="sm"
                        color={!parseError(error?.response?.status) ? 'green' : 'red'}
                        labelProgress={!error?.response?.status}
                        labelPosition="outside"
                      />
                    </div>
                    <div className={'status-icon'}>
                      {progress?.loaded === progress?.total && !error?.response?.status && (
                        <AiOutlineCheck />
                      )}
                    </div>
                  </>
                ) : (
                  <div className="progress">
                    {preview.fileWithMeta.file.name +
                      ` ${formatFileSize(preview.fileWithMeta.file.size)}`}
                  </div>
                )}
              </div>
              <div className={'error'} onClick={() => preview.fileWithMeta.remove()}>
                {parseError(error?.response?.status)}
              </div>
            </div>
          )
        }
        onChangeStatus={(file, status) => {
          if (status === 'ready') {
            setDisplayStatus(status);
            setFile(file);
          } else if (status !== 'rejected_file_type' && status !== 'rejected_max_files') {
            setDisplayStatus('');
          }
        }}
        inputContent={
          <div className={theme === 'dark' ? 'dropzone' : 'dropzone-light'} ref={fileRef}>
            <RiDragDropLine size={42} />
            <span>Click here to select or Drop Files to upload</span>
          </div>
        }
      />
      {fileType === 'VIDEO' && (
        <div className="text-red-500 font-semibold mt-1">
          {displayStatus === 'error_file_size' ? (
            <span>File is over 50MB. Larger video files are uploaded through the workspace.</span>
          ) : (
            !file && <span>Video files over 50MB are uploaded through the workspace.</span>
          )}
        </div>
      )}
    </>
  );
}

export default FileInput;
