import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';

import { axiosInstance } from '../../../../core/api/axiosInstance';
import { fitToCanvasHelper } from '../../../../helpers/fitToCanvas';
import { getMaxZindex } from '../../../../helpers/timelineUtil';
import { C9ProjectDef } from '../../../../model/definitions/C9ProjectDef';
import { PositionControlDef } from '../../../../model/definitions/PositionControlDef';
import { TimeControlDef } from '../../../../model/definitions/TimeControlDef';
import { VideoPanelDef } from '../../../../model/definitions/VideoPanelDef';
import { VideoPanelDefTemplate } from '../../../../model/definitions/VideoPanelDefTemplate';
import { setElement } from '../../../../store/slices/active-slice';
import { addVideoLayer } from '../../../../store/slices/project-slice';

export const useGetVideoFromRepo = () => {
  const dispatch = useDispatch();
  return useMutation(
    async ({
      id,
      ratio,
      activeScene,
      project,
      time,
      previewSize,
    }: {
      id: string;
      ratio: [number, number];
      activeScene: string;
      project: C9ProjectDef;
      time: number;
      previewSize: number;
    }): Promise<VideoPanelDefTemplate> => {
      const { data } = await axiosInstance.get(`multimedia/VIDEO/current-template/${id}`);
      const videoLayer = new VideoPanelDef();
      videoLayer.name = data.name;
      videoLayer.videoPanelDefTemplate = data;
      const { width, height, x, y } = fitToCanvasHelper(data, ratio, previewSize);
      videoLayer.positionControl = new PositionControlDef(width, height);
      videoLayer.positionControl.x = x;
      videoLayer.positionControl.y = y;
      videoLayer.positionControl.zindex = getMaxZindex(activeScene as string, project);
      videoLayer.timeControls = [new TimeControlDef(time, time + data.durationInMS)];
      videoLayer.templateId = data.id;
      videoLayer.templateVersionId = data.versionId;
      videoLayer.enabled = true;
      dispatch(
        addVideoLayer({
          videoLayer,
          activeScene,
        }),
      );
      dispatch(
        setElement({
          activeElement: videoLayer.id,
          activeProp: 'videoPanels',
        }),
      );
      return data;
    },
  );
};
