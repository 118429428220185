import { useQuery } from 'react-query';

import { axiosInstance } from './axiosInstance';

export const key = ['FONTS'];
export const useGetFonts = () => {
  return useQuery(
    key,
    async () => {
      const res = await axiosInstance.get('font/all');
      return res.data;
    },
    {
      staleTime: Infinity,
    },
  );
};
