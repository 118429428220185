export const WIND_QUAD_VERT = `
#ifdef GL_ES
precision mediump float;
#endif

attribute vec2 a_pos;

varying vec2 v_tex_pos;
varying vec4 vertTexCoord;

void main() {
    v_tex_pos = a_pos;
    vertTexCoord = vec4(1.0 - a_pos, 0, 1);
    gl_Position = vec4(1.0 - 2.0 * a_pos, 0, 1);
}
`;
