import 'rc-slider/assets/index.css';
import './style.scss';

import { findIndex, findLastIndex, uniq } from 'lodash';
import Slider from 'rc-slider';
import { MarkObj } from 'rc-slider/lib/Marks';
import { ReactNode, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useDispatch, useSelector } from 'react-redux';
import { v4 } from 'uuid';

import { ModeEnum } from '../../core/ui/enums/ModeEnum';
import { PlaybackEnum } from '../../core/ui/enums/PlaybackEnum';
import { getProjectDuration } from '../../helpers/timelineUtil';
import { useDebounce } from '../../hooks/useDebounce';
import { C9ProjectDef } from '../../model/definitions/C9ProjectDef';
import { isCopyDrawingInput } from '../../model/definitions/CopyDrawingInput';
import { SceneDef } from '../../model/definitions/SceneDef';
import { TimeControlDef } from '../../model/definitions/TimeControlDef';
import { AnimationsEnum } from '../../model/enums/AnimationsEnum';
import { GroupingEnum } from '../../model/UI/enums/GroupingEnum';
import PlayerContext from '../../pages/playground/playerContext/PlayerContext';
import styles from '../../pages/playground/Playground.module.scss';
import {
  ActiveDef,
  populatePoster,
  resetPoster,
  setProjectToPlay,
  setTime,
} from '../../store/slices/active-slice';
import {
  addAudioLayer,
  addGraphLayer,
  addImageLayer,
  addMapLayer,
  addPointDateLayer,
  addPointLocationLayer,
  addTextLayer,
  addVideoLayer,
  updatePanel,
} from '../../store/slices/project-slice';
import { selectActiveElementAny } from '../../store/slices/selectors';
import { RootState } from '../../store/store';
import { createPlayer, getRollerMarks } from './helpers';
import { renderLogicalGrouping, renderUngrouped } from './laneRendering';
import { StoryBoard } from './StoryBoard/StoryBoard';

interface TimelineProps {
  duration: number;
  elements?: SceneDef;
  collapsed?: Array<number>;
  skip?: (e: number) => void;
  project?: C9ProjectDef;
  open?: boolean;
  addElement?: () => void;
  hide?: Array<SceneKeys<SceneDef>>;
}
export interface SceneSimple {
  id: string;
  name: string;
  startMS: number;
  endMS: number;
  durationInMS: number;
  thumbnailUrls: Array<string>;
  inAnimationDuration: number;
  outAnimationDuration: number;
  inAnimationDef: AnimationsEnum;
  outAnimationDef: AnimationsEnum;
}

const Ungrouped = ({
  elements,
  hide,
  duration,
  activeZoom,
  open,
  displayedFlyOvers,
  groupMode,
  sceneStart,
  sceneEnd,
}: {
  elements: SceneDef;
  hide: Array<SceneKeys<SceneDef>>;
  duration: number;
  activeZoom: number;
  open: boolean;
  displayedFlyOvers: Array<string>;
  groupMode: GroupingEnum;
  sceneStart?: number | null;
  sceneEnd?: number | null;
}) => {
  return useMemo(() => {
    return (
      <>
        {renderUngrouped(
          elements,
          hide,
          duration,
          activeZoom,
          open,
          displayedFlyOvers,
          groupMode,
          sceneStart,
          sceneEnd,
        )}
      </>
    );
  }, [
    elements,
    hide,
    duration,
    activeZoom,
    open,
    displayedFlyOvers,
    groupMode,
    sceneStart,
    sceneEnd,
  ]);
};

const TimeLine = ({ duration, elements, skip, project, open, hide }: TimelineProps) => {
  const { time, isPlaying, setTime: setContextTime } = useContext(PlayerContext);
  const projectDef = useSelector<RootState, C9ProjectDef>((state) => state.project.present.project);
  const {
    activeZoom,
    mode,
    displayedFlyOvers,
    activeScene,
    activeProp,
    activeElement,
    activeTime,
    grouping,
    openGroups,
    syncSpace,
    activeFramerate,
  } = useSelector<RootState>((state) => state.active) as ActiveDef;
  const [rollerMarks, setRollerMarks] = useState<
    Record<string | number, ReactNode | MarkObj> | undefined
  >();
  const [granulation, setGranulation] = useState<number>();
  const [sliderTime, setSliderTime] = useState<number>(0);
  const debouncedSliderTime = useDebounce(sliderTime, 300);
  useEffect(() => {
    setSliderTime(time);
  }, [time]);

  const activeElementAny = useSelector<RootState, any>((state) => selectActiveElementAny(state));
  const sliderRef = useRef<HTMLDivElement>(null);
  const scene = projectDef?.sceneDefs.find((scene) => scene.id === activeScene);
  const selectAll = () => {
    dispatch(resetPoster());
    if (scene) {
      const {
        imagePanels,
        textPanels,
        videoPanels,
        forecastWDElements,
        observedWDElements,
        pointDates,
        pointLocation,
        animationPanels,
      } = scene;
      imagePanels.forEach((element) =>
        dispatch(populatePoster({ element: { ...element, type: 'imagePanels' } })),
      );
      textPanels.forEach((element) =>
        dispatch(populatePoster({ element: { ...element, type: 'textPanels' } })),
      );
      animationPanels.forEach((element) =>
        dispatch(populatePoster({ element: { ...element, type: 'animationPanels' } })),
      );
      videoPanels.forEach((element) =>
        dispatch(populatePoster({ element: { ...element, type: 'videoPanels' } })),
      );
      forecastWDElements.forEach((element) =>
        dispatch(populatePoster({ element: { ...element, type: 'forecastWDElements' } })),
      );
      observedWDElements.forEach((element) =>
        dispatch(populatePoster({ element: { ...element, type: 'observedWDElements' } })),
      );
      pointDates.forEach((element) =>
        dispatch(populatePoster({ element: { ...element, type: 'pointDates' } })),
      );
      pointLocation.forEach((element) =>
        dispatch(populatePoster({ element: { ...element, type: 'pointLocation' } })),
      );
    }
  };
  useEffect(() => {
    const val =
      Math.round(duration / Math.ceil((sliderRef.current?.scrollWidth ?? 0) / 50) / 1000) * 1000;
    setGranulation(val >= 1000 ? val : 1000);
  }, [activeZoom, activeScene, duration, mode]);
  useEffect(() => {
    if (duration && granulation && sliderRef.current) {
      const marks = getRollerMarks(
        mode,
        duration,
        granulation,
        sliderRef.current.offsetWidth,
        project,
      );
      setRollerMarks(marks);
    }
  }, [project, activeZoom, mode, duration, granulation]);

  useEffect(() => {
    projectDef &&
      isPlaying !== PlaybackEnum.PLAYING &&
      dispatch(
        setProjectToPlay({ projectToPlay: createPlayer(projectDef, syncSpace, activeFramerate) }),
      );
  }, [mode, activeZoom, granulation, isPlaying, projectDef.sceneDefs]);
  const dispatch = useDispatch();
  useHotkeys(
    'ctrl+right, command+right',
    (ev) => {
      ev.preventDefault();
      jumpToElementStartCutEnd('end');
    },
    [jumpToElementStartCutEnd],
  );
  useHotkeys(
    'ctrl+left, command+left',
    (ev) => {
      ev.preventDefault();
      jumpToElementStartCutEnd('start');
    },
    [jumpToElementStartCutEnd],
  );
  useHotkeys(
    'ctrl+o, command+o',
    (ev) => {
      ev.preventDefault();
      // ev.stopPropagation();
      selectAll();
    },
    [selectAll],
  );
  useHotkeys(
    'ctrl+c, command+c',
    (ev) => {
      /**Handle draw layer copy in respective prop grid */
      if (activeProp === 'drawLayer') return;
      ev.preventDefault();
      if (activeElementAny) {
        navigator.clipboard.writeText(
          JSON.stringify({ type: activeProp, element: { ...activeElementAny, id: v4() } }),
        );
      }
    },
    [activeElementAny, activeProp],
  );
  useHotkeys(
    'ctrl+x, command+x',
    (ev) => {
      ev.preventDefault();
      if (activeElementAny) {
        navigator.clipboard
          .writeText(JSON.stringify({ type: activeProp, element: activeElementAny }))
          .then(() => {
            dispatch(
              updatePanel({
                activeScene: activeScene,
                activeProp: activeProp as keyof SceneDef,
                activeElement: activeElement,
              }),
            );
          });
      }
    },
    [activeElementAny],
  );
  useHotkeys('ctrl+v, command+v', (ev) => {
    ev.preventDefault();
    navigator.clipboard
      .readText()
      .then((text) => {
        const el = JSON.parse(text);
        const isDrawing = isCopyDrawingInput(el);
        if (isDrawing) return;
        if (typeof el === 'object' && 'type' in el && 'element' in el) {
          const type = el.type;
          const element = el.element;
          el.id = v4();
          switch (type) {
            case 'mapPanels': {
              dispatch(
                addMapLayer({
                  mapLayer: { ...element, id: v4(), name: 'Copy of ' + element.name },
                  activeScene: activeScene,
                }),
              );
              return;
            }
            case 'videoPanels': {
              dispatch(
                addVideoLayer({
                  videoLayer: { ...element, id: v4(), name: 'Copy of ' + element.name },
                  activeScene: activeScene,
                }),
              );
              return;
            }
            case 'imagePanels': {
              dispatch(
                addImageLayer({
                  imageLayer: { ...element, id: v4(), name: 'Copy of ' + element.name },
                  activeScene: activeScene,
                }),
              );
              return;
            }
            case 'textPanels': {
              dispatch(
                addTextLayer({
                  textLayer: { ...element, id: v4(), name: 'Copy of ' + element.name },
                  activeScene: activeScene,
                }),
              );
              return;
            }
            case 'audioElements': {
              dispatch(
                addAudioLayer({
                  audioLayer: { ...element, id: v4(), name: 'Copy of ' + element.name },
                  activeScene: activeScene,
                }),
              );
              return;
            }
            case 'weatherPosters': {
              dispatch(
                addGraphLayer({
                  graphLayer: { ...element, id: v4(), name: 'Copy of ' + element.name },
                  activeScene,
                }),
              );
              return;
            }
            case 'pointDates': {
              dispatch(
                addPointDateLayer({
                  dateLayer: { ...element, id: v4(), name: 'Copy of ' + element.name },
                  activeScene,
                }),
              );
              return;
            }
            case 'pointLocation': {
              dispatch(
                addPointLocationLayer({
                  locationLayer: { ...element, id: v4(), name: 'Copy of ' + element.name },
                  activeScene,
                }),
              );
              return;
            }
            default: {
              console.error(`Copy of element of type ${type} failed. Handle element copy`);
            }
          }
        }
      })
      .catch((err) => console.error(err));
  });

  function jumpToElementStartCutEnd(jumpTo: 'start' | 'end') {
    if (!activeElementAny) return;
    let timeControls: number[] = activeElementAny?.timeControls
      .map((t: TimeControlDef) => [t.startMS, t.endMS])
      .flat(100);
    timeControls = uniq(timeControls);
    const newStartIndex = findLastIndex(timeControls, (t) => t < activeTime);
    const newEndIndex = findIndex(timeControls, (t) => t > activeTime);
    const newStartTime = newStartIndex === -1 ? timeControls[0] : timeControls[newStartIndex];
    const newEndTime =
      newEndIndex === -1 ? timeControls[timeControls.length - 1] : timeControls[newEndIndex];
    dispatch(setTime({ activeTime: jumpTo === 'start' ? newStartTime : newEndTime }));
    setContextTime(jumpTo === 'start' ? newStartTime : newEndTime);
  }

  useEffect(() => {
    setContextTime(sliderTime);
    isPlaying === PlaybackEnum.PLAYING && skip && skip(sliderTime);
    isPlaying !== PlaybackEnum.PLAYING && skip && skip(0);
    isPlaying === PlaybackEnum.PLAYING && dispatch(setTime({ activeTime: sliderTime }));
  }, [debouncedSliderTime]);

  return (
    <div
      id={'TimeLine'}
      ref={sliderRef}
      style={{
        width: `${99 + activeZoom}%`,
      }}
    >
      <Slider
        className={'TimeIndicator'}
        marks={rollerMarks}
        min={0}
        max={mode === ModeEnum.SEQUENCE ? duration : project ? getProjectDuration(project) : 0}
        value={sliderTime}
        style={{ marginBottom: mode === ModeEnum.SEQUENCE ? '45px' : 0 }}
        draggableTrack
        onChange={(e) => {
          typeof e === 'number' && setSliderTime(e);
        }}
      />
      <div className={styles.borderLeft} />
      <div
        className={styles.overlay}
        style={{
          width: `${((time * 100) / duration).toPrecision(8)}%`,
        }}
      />
      {mode === ModeEnum.SEQUENCE &&
        elements &&
        (grouping === GroupingEnum.LOGICAL
          ? renderLogicalGrouping(elements, duration, openGroups, displayedFlyOvers, hide)
          : rollerMarks &&
            elements &&
            hide && (
              <Ungrouped
                elements={elements}
                hide={hide}
                duration={duration}
                activeZoom={activeZoom}
                open={!!open}
                displayedFlyOvers={displayedFlyOvers}
                groupMode={grouping}
                sceneStart={scene?.startDate}
                sceneEnd={scene?.endDate}
              />
            ))}
      {mode === ModeEnum.PROJECT && project && (
        <div
          id={'TimeLine'}
          ref={sliderRef}
          style={{
            width: `${100 + activeZoom}%`,
          }}
        >
          <div className={styles.borderLeft} style={{ height: '100%' }} />
          <StoryBoard />
        </div>
      )}
      <div className={styles.borderRight} />
    </div>
  );
};
export default TimeLine;
