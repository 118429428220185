import { useState } from 'react';

import { useGetImageMultimedia } from '../../core/api/useGetMultimedia';
import { ImagePanelDef } from '../../model/definitions/ImagePanelDef';
import { PositionControlDef } from '../../model/definitions/PositionControlDef';
import { TimeControlDef } from '../../model/definitions/TimeControlDef';
import Loader from '../../pages/dashboard/components/Loader';
import ElementContainer from './ElementContainer';
import style from './style.module.scss';

interface ImageElementProps {
  panelProps: ImagePanelDef;
  canvas: { cnvWidth?: number; cnvHeight?: number };
  disabled: boolean;
  parentTime?: TimeControlDef[];
  inPoster?: boolean;
  posterId?: string;
  parentSize?: PositionControlDef;
  isMapOverlay?: boolean;
  mapId?: string;
  geoPosterId?: string;
}
export const ImageElement = ({
  panelProps,
  canvas,
  disabled,
  posterId,
  inPoster,
  parentTime,
  parentSize,
  isMapOverlay = false,
  mapId,
  geoPosterId,
}: ImageElementProps) => {
  const { imagePanelDefTemplate } = panelProps;
  const { data: url } = useGetImageMultimedia(imagePanelDefTemplate.versionId, false);
  const [isLoaded, setIsLoaded] = useState(false);
  return (
    <ElementContainer
      canvas={canvas}
      panelProps={panelProps}
      disabled={disabled}
      lock={panelProps.lockAspectRatio}
      type={'imagePanels'}
      visibility={panelProps.enabled}
      parentTime={parentTime}
      inPoster={inPoster}
      posterId={posterId}
      parentSize={parentSize}
      isMapOverlay={isMapOverlay}
      parentMapId={mapId}
      geoPosterId={geoPosterId}
      opacity={panelProps.opacity}
    >
      <img
        draggable={false}
        className={style.image_element}
        src={url}
        alt={''}
        onLoad={() => setIsLoaded(true)}
      />
      {!isLoaded && (
        <div
          className="absolute w-full h-full flex items-center justify-center inset-0 bg-gray-400/50"
          style={{ flexDirection: 'column' }}
        >
          <Loader />
          <div>
            Loading image<span className={'dots-animation'}>.</span>
          </div>
        </div>
      )}
    </ElementContainer>
  );
};
