import { useQuery } from 'react-query';

import { axiosInstance } from '../../../core/api/axiosInstance';

export const key = ['FILE_RESULTS'];

export const useFileResults = (ids: number[]) =>
  useQuery(
    [...key, ...ids],
    async () => {
      const fileResultPromises = ids.map((id) =>
        axiosInstance.get(`data-product-files/result/${id}`),
      );
      const fileResultResponses = await Promise.all(fileResultPromises);

      const flattenedArray: any[] = [];

      fileResultResponses.forEach((response) => {
        response.data.forEach((obj: any) => {
          flattenedArray.push({ ...obj });
        });
      });

      return flattenedArray;
    },
    {
      enabled: !!ids.length,
      staleTime: Infinity,
    },
  );
