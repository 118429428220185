import './style.scss';

import { CloseCircleOutlined } from '@ant-design/icons';
import { notification, Select } from 'antd';
import { cloneDeep, uniq } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { IoSaveOutline } from 'react-icons/io5';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import Button from '../../../atoms/button/Button';
import { useApplyBiasGroupOnScene } from '../../../core/api/bias/bias-group/useApplyBiasGroupOnScene';
import { useGetBiasGroups } from '../../../core/api/bias/bias-group/useGetBiasGroups';
import { setSceneThumbnail } from '../../../core/api/ProjectsAPI';
import { saveSceneAsTemplate, updateSceneAsTemplate } from '../../../core/api/TemplatesAPI';
import { useGetAvailableTranslations } from '../../../core/api/translation/useGetAvailableTranslations';
import { useGetTranslation } from '../../../core/api/translation/useGetTranslation';
import { findProperties } from '../../../helpers/findProperty';
import { screenshotCanvasElement, ScreenshotData } from '../../../helpers/screenshotElement';
import { useImplicitSave } from '../../../hooks/useImplicitSave';
import { C9ProjectDef } from '../../../model/definitions/C9ProjectDef';
import { ForecastWDElementDef } from '../../../model/definitions/ForecastWDElementDef';
import { ObservedWDElementDef } from '../../../model/definitions/ObservedWDElementDef';
import { SceneDef } from '../../../model/definitions/SceneDef';
import { ValueTypeEnum } from '../../../model/enums/ValueTypeEnum';
import { ActiveDef, setSceneTranslation } from '../../../store/slices/active-slice';
import {
  removeThumbnail,
  replaceSceneInProject,
  updateScene,
} from '../../../store/slices/project-slice';
import { selectActiveScene } from '../../../store/slices/selectors';
import { RootState } from '../../../store/store';
import { Panel } from './components/Panel';
import { PropertySection } from './components/PropertySection';
import styles from './Properties.module.scss';
import SaveAsTemplateModal from './SaveAsTemplateModal';
import { SceneTimeFrame } from './ScenePropertiesComponents/SceneTimeFrame';
import GridActions from './shared/GridActions';
import GridItem from './shared/GridItem';
import GridWrapper from './shared/GridWrapper';

const { Option } = Select;
const SceneProperties: React.FC = () => {
  const dispatch = useDispatch();
  const [opened, setOpened] = useState(false);
  const thumbnailsTempRef = useRef<ScreenshotData[]>([]);
  const activeSceneDef = useSelector<RootState, SceneDef | null>((state) =>
    selectActiveScene(state),
  );
  const [loadingSc, setLoadingSc] = useState(false);
  const hiddenFileInput = useRef<HTMLInputElement | null>(null);
  const { mutate: addThumbs, isLoading } = useMutation(setSceneThumbnail, {
    onSuccess: (data) => {
      const cloned = cloneDeep(project);
      const scene = cloned.sceneDefs.find((sc) => sc.id === activeSceneDef?.id);
      const newScene = data.sceneDefs.find((sc) => sc.id === activeSceneDef?.id);
      if (!scene || !newScene) return;
      scene.thumbnailUrls = uniq([...scene.thumbnailUrls, ...newScene.thumbnailUrls]);
      implicitSave(cloned);
    },
  });
  const { data: biasFiltersObserved } = useGetBiasGroups('OBSERVED');
  const { data: biasFiltersForecast } = useGetBiasGroups('FORECAST');
  const { mutate: applyBias, isLoading: applyingBias } = useApplyBiasGroupOnScene();
  const { data } = useGetAvailableTranslations();
  const { data: language } = useGetTranslation(activeSceneDef?.translationId ?? '');

  useEffect(() => {
    activeSceneDef &&
      dispatch(setSceneTranslation({ translation: language, scene: activeSceneDef?.id }));
  }, [language, activeSceneDef?.translationId, activeSceneDef]);
  const [screenshots, setScreenshots] = useState<Array<ScreenshotData>>([]);
  useEffect(() => {
    if (activeSceneDef) {
      setScreenshots(activeSceneDef.thumbnailUrls.map((t) => ({ blob: null, base64: t })));
    }
  }, [activeSceneDef]);
  const project = useSelector<RootState, C9ProjectDef>((state) => state.project.present.project);
  const implicitSave = useImplicitSave();

  const { activeScene } = useSelector<RootState, ActiveDef>((state) => state.active);

  function onChange(newValue: string | number, propertyPath: Leaves<SceneDef>) {
    dispatch(updateScene({ newValue, propertyPath, activeScene }));
  }

  const existsAsTemplate = Boolean(activeSceneDef?.templateId && activeSceneDef.templateVersionId);
  const hasBias =
    activeSceneDef &&
    findProperties(activeSceneDef, 'biasFilterAppliedOnValue').some((val) => val === true);
  const saveSceneTemplate = useMutation(saveSceneAsTemplate, {
    onSuccess: (data) => {
      const proj = cloneDeep(project);
      const scene = proj.sceneDefs.find((sc) => sc.id === activeSceneDef?.id);
      if (!scene) return;
      scene.templateId = data.id;
      scene.templateVersionId = data.versionId;
      implicitSave(proj);
      toast.success('Successfully added scene template!');
      setOpened(false);
    },
  });
  const updateSceneTemplate = useMutation(updateSceneAsTemplate, {
    onSuccess: (data) => {
      const proj = cloneDeep(project);
      const scene = proj.sceneDefs.find((sc) => sc.id === activeSceneDef?.id);
      if (!scene) return;
      scene.templateVersionId = data.versionId;
      implicitSave(proj);
      toast.success('Successfully updated scene template!');
      setOpened(false);
    },
  });

  const onSc = async () => {
    setLoadingSc(true);
    try {
      const newSc = await screenshotCanvasElement('scene');
      setLoadingSc(false);
      setScreenshots((sc) => [...sc, newSc]);
    } catch (e) {
      setLoadingSc(false);
    } finally {
      setLoadingSc(false);
    }
  };

  function onTemplateSave() {
    setOpened(true);
  }

  function onConfirm(formData: {
    name: string;
    description: string;
    thumbnailURLs: ScreenshotData[];
  }) {
    const { name, description, thumbnailURLs } = formData;
    if (!existsAsTemplate) {
      saveSceneTemplate.mutate({
        name,
        description,
        thumbnailURLs,
        sceneDef: activeSceneDef!,
      });
    }
    if (existsAsTemplate) {
      updateSceneTemplate.mutate({
        sceneDef: activeSceneDef!,
        templateId: activeSceneDef!.templateId,
        thumbnailURLs,
        name,
        description,
      });
    }
  }
  const removeScreenshot = (imgSrc: string) => {
    setScreenshots((sc) => sc.filter((s) => s.base64 !== imgSrc));
    activeSceneDef?.id &&
      dispatch(removeThumbnail({ sceneId: activeSceneDef.id, thumbnailUrl: imgSrc }));
  };
  const setSceneScreenshots = () => {
    const newFiles = screenshots.filter((d) => !!d.blob);
    const existingUrls = screenshots.filter((d) => !d.blob);
    const cloned = cloneDeep(project);
    const scene = cloned.sceneDefs.find((sc) => sc.id === activeSceneDef?.id);
    if (!scene) return;

    if (newFiles.length) {
      thumbnailsTempRef.current = existingUrls;
      addThumbs({ thumbnail: newFiles, projectVersionId: cloned.versionId, sceneId: scene.id });
    } else {
      scene.thumbnailUrls = [...existingUrls.map((e) => e.base64)];
      implicitSave(cloned);
    }
  };

  const handleClick = () => {
    hiddenFileInput?.current?.click();
  };
  const removeBiasFromForecast = (data: Array<ForecastWDElementDef>) =>
    data?.map((layer) => {
      return {
        ...layer,
        forecastWDSource: {
          ...layer.forecastWDSource,
          value:
            layer?.forecastWDSource.valueType === ValueTypeEnum.NUMERICAL
              ? layer.forecastWDSource.value.map((val: any) => {
                  return {
                    ...val,
                    value: val.originalValue,
                  };
                })
              : layer?.forecastWDSource.originalValue,
          biasFilterAppliedOnValue: false,
        },
      };
    });
  const removeBiasFromObserved = (data: Array<ObservedWDElementDef>) =>
    data?.map((layer) => {
      return {
        ...layer,
        observedWDSource: {
          ...layer.observedWDSource,
          value:
            layer?.observedWDSource.valueType === ValueTypeEnum.NUMERICAL
              ? layer.observedWDSource.value.map((val: any) => {
                  return {
                    ...val,
                    value: val.originalValue,
                  };
                })
              : layer?.observedWDSource.originalValue,
          biasFilterAppliedOnValue: false,
        },
      };
    });
  const removeBiasFilter = () => {
    const forecast = activeSceneDef?.forecastWDElements;
    const observed = activeSceneDef?.observedWDElements;
    const posters = activeSceneDef?.weatherPosters;
    const maps = activeSceneDef?.mapPanels;
    const noBiasMaps = maps?.map((singleMap) => {
      return {
        ...singleMap,
        geoPosters: singleMap.geoPosters.map((poster) => {
          return {
            ...poster,
            forecastWDElements: removeBiasFromForecast(poster.forecastWDElements),
            observedWDElements: removeBiasFromObserved(poster.observedWDElements),
          };
        }),
      };
    });
    const noBiasPosters = posters?.map((poster) => {
      return {
        ...poster,
        forecastWDElements: removeBiasFromForecast(poster.forecastWDElements),
        observedWDElements: removeBiasFromObserved(poster.observedWDElements),
      };
    });
    //const maps = activeSceneDef?.mapPanels;
    const noBiasForecast = removeBiasFromForecast(forecast || []);
    const noBiasObserved = removeBiasFromObserved(observed || []);

    const sceneToReplace = {
      ...activeSceneDef,
      forecastWDElements: noBiasForecast,
      observedWDElements: noBiasObserved,
      weatherPosters: noBiasPosters,
      mapPanels: noBiasMaps,
    };
    activeSceneDef && dispatch(replaceSceneInProject({ sceneDef: sceneToReplace as SceneDef }));
  };

  const options = data?.map((lang) => {
    return { label: lang.name, value: lang.id };
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileUploaded = event.target?.files?.[0];
    if (!fileUploaded) return;
    const reader = new FileReader();
    reader.readAsDataURL(fileUploaded);
    reader.onload = function () {
      setScreenshots((sc) => [
        ...sc,
        { base64: URL.createObjectURL(fileUploaded!), blob: fileUploaded! },
      ]);
    };
    reader.onerror = function (error) {
      console.error('Error: ', error);
    };
  };

  const applyBiasGroupOnScene = (type: 'FORECAST' | 'OBSERVED', id: string) => {
    activeSceneDef &&
      applyBias(
        { type, biasGroupId: id, body: activeSceneDef },
        {
          onSuccess: (data) => {
            dispatch(replaceSceneInProject({ sceneDef: data }));
            notification.success({ message: 'Group Filter Applied!' });
          },
        },
      );
  };
  const filterSelectorObserved = () => {
    const options = biasFiltersObserved?.map((filter) => (
      <Option value={filter.id} key={filter.id}>
        {filter.name}
      </Option>
    ));
    return (
      <Select
        disabled={applyingBias}
        style={{ maxWidth: 200 }}
        onChange={(e) => applyBiasGroupOnScene('OBSERVED', e)}
      >
        {options}
      </Select>
    );
  };
  const filterSelectorForecast = () => {
    const options = biasFiltersForecast?.map((filter) => (
      <Option value={filter.id} key={filter.id}>
        {filter.name}
      </Option>
    ));
    return (
      <Select
        disabled={applyingBias}
        style={{ maxWidth: 200 }}
        onChange={(e) => applyBiasGroupOnScene('FORECAST', e)}
      >
        {options}
      </Select>
    );
  };
  return (
    <Panel
      actions={[
        <Button
          key={'Save_template'}
          buttonType="border"
          onClick={onTemplateSave}
          icon={<IoSaveOutline />}
          label={existsAsTemplate ? 'Save new template version' : 'Save as template'}
          className="flex items-center gap-1 property-grey-buttons"
        />,
      ]}
    >
      <PropertySection
        label={activeSceneDef?.name ? activeSceneDef.name : 'Scene properties'}
        isOpened={true}
      >
        <div className="prop-wrapper">
          <GridWrapper>
            <GridItem
              label={'Name:'}
              item={
                <input
                  className={styles.inputWrap}
                  value={activeSceneDef?.name ? activeSceneDef.name : ''}
                  onChange={(e) => onChange(e.target.value, 'name')}
                  type="text"
                />
              }
            />

            <GridItem
              label={'Description:'}
              item={
                <input
                  type={'text'}
                  value={activeSceneDef?.description ? activeSceneDef.description : ''}
                  onChange={(e) => onChange(e.target.value, 'description')}
                  className={styles.inputWrap}
                  placeholder="Type description"
                />
              }
            />
            <GridItem
              noBorderBg
              label={`Apply filter on obsv. data:`}
              item={filterSelectorObserved()}
            />
            <GridItem
              noBorderBg
              label={`Apply filter on for. data:`}
              item={filterSelectorForecast()}
            />
            <GridItem
              noBorderBg
              label={'Language:'}
              item={
                <Select
                  value={activeSceneDef?.translationId ?? ''}
                  options={[{ label: 'Default', value: '' }, ...(options ?? [])]}
                  onChange={(val) => onChange(val, 'translationId')}
                />
              }
            />
          </GridWrapper>
          <GridActions>
            {hasBias && <Button onClick={() => removeBiasFilter()} label={'Remove bias filter'} />}
          </GridActions>
        </div>
      </PropertySection>
      <SceneTimeFrame />
      <PropertySection label={'Screenshots'} isOpened={true}>
        <div>
          <div className={'flex gap-2'}>
            <Button label={'Create thumbnail'} onClick={onSc} loading={loadingSc} />
            <Button label="Upload thumbnail" onClick={handleClick} />
            <input
              type="file"
              accept="image/png,image/jpeg"
              multiple={false}
              ref={hiddenFileInput}
              onChange={handleChange}
              className="hidden"
            />

            <Button
              loading={isLoading}
              buttonType={'danger'}
              label={'Apply'}
              onClick={() => setSceneScreenshots()}
            />
          </div>
          <div className={'flex gap-1 m-2 flex-wrap'}>
            {screenshots.map(
              (image, index) =>
                image && (
                  <div key={image.base64}>
                    <CloseCircleOutlined
                      style={{ color: 'red' }}
                      className={'absolute cursor-pointer'}
                      onClick={() => removeScreenshot(image.base64)}
                    />
                    <img
                      className={'rounded-lg'}
                      width={150}
                      src={image.base64}
                      alt={`screenshot_${index + 1}`}
                    />
                  </div>
                ),
            )}
          </div>
        </div>
      </PropertySection>
      <>
        {activeSceneDef && opened && (
          <SaveAsTemplateModal
            entityName={activeSceneDef!.name}
            onClose={() => setOpened(false)}
            onConfirm={onConfirm}
            opened={opened}
            loading={saveSceneTemplate.isLoading}
            templateId={activeSceneDef.templateId}
            type="scene"
          />
        )}
      </>
    </Panel>
  );
};

export default SceneProperties;
