import { utmZoneFromLonLat } from './helpers';

export function getProjections(parameters: { center: [number, number]; north?: boolean }) {
  const { center, north = true } = parameters;
  const [projLon, projLat] = center;
  return [
    {
      code: 'EPSG:3857',
      plainCode: 'EPSG:3857',
      projectionString:
        '+proj=merc +a=6378137 +b=6378137 +lat_ts=0 +lon_0=0 +x_0=0 +y_0=0 +k=1 +units=m +nadgrids=@null +wktext +no_defs +type=crs',
      name: 'Mercator',
      extent: [-20037508.342789244, -20037508.342789244, 20037508.342789244, 20037508.342789244],
      worldExtent: [-180.0, -85.06, 180.0, 85.06],
    },

    {
      code: `ESRI:53026:${projLon}:${projLat}`,
      plainCode: 'ESRI:53026',
      projectionString: `+proj=stere +lat_0=${
        north ? 89.9999999 : -89.9999999 // openlayers randomly breaks if lat_0 is 90 or -90 in some cases
      } +lon_0=${projLon} +lat_ts=${projLat} +datum=WGS84 +x_0=0 +y_0=0 +units=m +no_defs +type=crs`,
      name: 'Polar Stereographic',
      // eslint-disable-next-line @typescript-eslint/no-loss-of-precision
      extent: [-20037508, -20037508.342789244, 20037508.342789244, 20037508.342789244],
      worldExtent: [-180, -90, 180, 90],
    },
    {
      code: `EPSG:9820:${projLon}:${projLat}`,
      plainCode: 'EPSG:9820',
      name: 'Lambert Azimuthal Equal Area',
      projectionString: `+proj=laea +lat_0=${projLat} +lon_0=${projLon} +x_0=4321000 +y_0=3210000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs`,
      extent: [-20037508.342789244, -20037508.342789244, 20037508.342789244, 20037508.342789244],
      worldExtent: [-180, -90, 180, 90],
    },
    {
      code: `ESRI:54049:${projLon}:${projLat}`,
      plainCode: 'ESRI:54049',
      name: 'Near-Sided Perspective',
      projectionString: `+proj=nsper +lat_0=${
        projLat > 57 ? 57.0 : projLat
      } +lon_0=${projLon} +h=5900000 +nadgrids=@null +no_defs +ellps=GRS80`,
      // eslint-disable-next-line @typescript-eslint/no-loss-of-precision
      extent: [-20015077.371242613, -20015077.371242613, 20015077.372242613, 20015077.372242613],
      worldExtent: [-180, -90, 180, 90],
    },
    {
      code: `ESRI:54032:${projLon}:${projLat}`,
      plainCode: 'ESRI:54032',
      name: 'Azimuthal Equidistant',
      projectionString: `+proj=aeqd +lat_0=${
        north ? 90 : -90
      } +lon_0=${projLon} +x_0=4321000 +y_0=3210000 +datum=WGS84 +units=m +no_defs +type=crs`,
      extent: [-20037508.342789244, -20037508.342789244, 20037508.342789244, 20037508.342789244],
      worldExtent: [-180, -90, 180, 90],
    },
    {
      code: `LLC:${projLon}:${projLat}`,
      plainCode: 'LLC',
      name: 'Lambert Conformal Conic',
      projectionString: `+proj=lcc +lat_0=${projLat} +lon_0=${projLon} +lat_1=${
        projLat + 10 < 90 ? projLat + 10 : 90
      } +lat_2=${
        projLat - 10 > -90 ? projLat - 10 : -90
      } +x_0=0 +y_0=0 +ellps=WGS84 +units=m +no_defs`,
      extent: [-20037508.342789244, -20037508.342789244, 20037508.342789244, 20037508.342789244],
      worldExtent: [-180, -90, 180, 90],
    },
    {
      code: `EPSG:${32600 + utmZoneFromLonLat([projLon, projLat])}`,
      plainCode: 'UTM',
      name: 'Universal Transverse Mercator',
      projectionString: undefined,
      extent: [-20037508.342789244, -20037508.342789244, 20037508.342789244, 20037508.342789244],
      worldExtent: [18.0, 0.0, 24.0, 84.0],
    },
  ];
}

export function calculateProjection(projectionCode: string, lonLat: [number, number]) {
  const [lon, lat] = lonLat;
  if (projectionCode === 'EPSG:3857') return projectionCode;
  if (projectionCode === 'EPSG:4326') return projectionCode;
  if (projectionCode.includes('EPSG:326')) return projectionCode;
  if (projectionCode === 'UTM') {
    return `EPSG:${32600 + utmZoneFromLonLat(lonLat)}`;
  }
  return `${projectionCode}:${lon}:${lat}`;
}
