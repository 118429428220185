// @ts-ignore
import { createSelector } from '@reduxjs/toolkit';

import { PointDateDef } from '../../model/definitions/PointDateDef';
import { SceneDef } from '../../model/definitions/SceneDef';
import { RootState } from '../store';

const selectProject = (state: RootState) => state.project;
const selectActive = (state: RootState) => state.active;

const selectActiveElementAny = createSelector(
  selectProject,
  selectActive,
  (projectState, { activeElement, activeScene, activeProp }) => {
    if (!activeElement || !activeElement || !activeScene || !activeProp) return null;

    const foundScene = projectState.present.project.sceneDefs?.find((sc) => sc.id === activeScene);
    const activeEl: any = (foundScene as SceneDef)?.[activeProp as keyof SceneDef];
    return activeEl?.find((a: any) => a.id === activeElement);
  },
);

const selectActiveTextLayer = createSelector(
  selectProject,
  selectActive,
  (projectState, { activeElement, activeScene, activeProp, activePoster }) => {
    if (!activeElement || !(activeProp === 'textPanels') || !activeScene || !activeProp)
      return null;
    if (activePoster) {
      return projectState.present.project.sceneDefs
        ?.find((sc) => sc.id === activeScene)
        ?.weatherPosters.find((poster) => poster.id === activePoster)
        ?.textPanels?.find((tp) => tp.id === activeElement);
    } else
      return projectState.present.project.sceneDefs
        ?.find((sc) => sc.id === activeScene)
        ?.textPanels?.find((tp) => tp.id === activeElement);
  },
);
const selectActiveTimestampLayer = createSelector(
  selectProject,
  selectActive,
  (projectState, { activeElement, activeScene, activeProp }) => {
    if (!activeElement || !(activeProp === 'timestampPanels') || !activeScene || !activeProp)
      return null;
    const scene = projectState.present.project.sceneDefs.find((sc) => sc.id === activeScene);
    if (scene) {
      const panel = scene.timestampPanels.find((tp) => tp.id === activeElement);
      if (panel) {
        return panel;
      } else {
        return null;
      }
    }
  },
);
const selectActiveLegend = createSelector(
  selectProject,
  selectActive,
  (projectState, { activeElement, activeScene, activeProp, activeMap }) => {
    if (!activeElement || !(activeProp === 'layerLegend') || !activeScene || !activeProp)
      return null;
    const scene = projectState.present.project.sceneDefs?.find((sc) => sc.id === activeScene);
    if (scene) {
      const map = scene.mapPanels.find((panel) => panel.id === activeMap);
      if (map) {
        const space = map.wdSpace[0];
        return [...space.gribMapLayers, ...space.radarMapLayers, ...space.satelliteMapLayers].find(
          (layer) => layer.layerSetup.id === activeElement,
        )?.layerSetup;
      }
    }
  },
);
const selectActivePointDateLayer = createSelector(
  selectProject,
  selectActive,
  (projectState, { activeElement, activeScene, activeProp, activePoster }) => {
    if (!activeElement || !(activeProp === 'pointDateDef') || !activeScene || !activeProp)
      return new PointDateDef();
    if (activePoster) {
      return projectState.present.project.sceneDefs
        ?.find((sc) => sc.id === activeScene)
        ?.weatherPosters.find((poster) => poster.id === activePoster)
        ?.pointDates?.find((tp) => tp.id === activeElement);
    } else
      return projectState.present.project.sceneDefs
        ?.find((sc) => sc.id === activeScene)
        ?.pointDates?.find((tp) => tp.id === activeElement);
  },
);
const selectActiveObservedLayer = createSelector(
  selectProject,
  selectActive,
  (projectState, { activeElement, activeScene, activePoster, activeMap }) => {
    if (activePoster && activeMap) {
      const layer = projectState.present.project.sceneDefs
        ?.find((sc) => sc.id === activeScene)
        ?.mapPanels.find((map) => map.id === activeMap)
        ?.geoPosters?.find((poster) => poster.id === activePoster)
        ?.observedWDElements?.find((obs) => obs.id === activeElement);
      return layer!;
    }
    if (activePoster && !activeMap) {
      const layer = projectState.present.project.sceneDefs
        .find((sc) => sc.id === activeScene)
        ?.weatherPosters.find((poster) => poster.id === activePoster)
        ?.observedWDElements?.find((tp) => tp.id === activeElement);
      return layer!;
    }
    if (!activePoster && activeMap) {
      const layer = projectState.present.project.sceneDefs
        .find((sc) => sc.id === activeScene)
        ?.mapPanels.find((map) => map.id === activeMap)
        ?.wdSpace[0]?.observedDataLayers?.find((layer) => layer.id === activeElement);
      return layer!;
    }
    const layer = projectState.present.project.sceneDefs
      .find((sc) => sc.id === activeScene)
      ?.observedWDElements?.find((tp) => tp.id === activeElement);
    return layer!;
  },
);
const selectActiveForecastLayer = createSelector(
  selectProject,
  selectActive,
  (projectState, { activeElement, activeScene, activeMap, activePoster }) => {
    if (activePoster && activeMap) {
      const layer = projectState.present.project.sceneDefs
        ?.find((sc) => sc.id === activeScene)
        ?.mapPanels.find((map) => map.id === activeMap)
        ?.geoPosters?.find((poster) => poster.id === activePoster)
        ?.forecastWDElements?.find((obs) => obs.id === activeElement);
      return layer!;
    }
    if (activePoster && !activeMap) {
      const layer = projectState.present.project.sceneDefs
        .find((sc) => sc.id === activeScene)
        ?.weatherPosters.find((poster) => poster.id === activePoster)
        ?.forecastWDElements?.find((tp) => tp.id === activeElement);
      return layer!;
    }
    if (!activePoster && activeMap) {
      const layer = projectState.present.project.sceneDefs
        .find((sc) => sc.id === activeScene)
        ?.mapPanels.find((map) => map.id === activeMap)
        ?.wdSpace[0]?.forecastDataLayers?.find((layer) => layer.id === activeElement);
      return layer!;
    }
    const layer = projectState.present.project.sceneDefs
      .find((sc) => sc.id === activeScene)
      ?.forecastWDElements?.find((tp) => tp.id === activeElement);
    return layer!;
  },
);

const selectActiveImageLayer = createSelector(
  selectProject,
  selectActive,
  (projectState, { activeElement, activeScene, activeProp, activePoster }) => {
    if (!activeElement || !(activeProp === 'imagePanels') || !activeScene || !activeProp)
      return null;
    if (activePoster)
      return projectState.present.project.sceneDefs
        ?.find((sc) => sc.id === activeScene)
        ?.weatherPosters.find((poster) => poster.id === activePoster)
        ?.imagePanels?.find((tp) => tp.id === activeElement);
    return projectState.present.project.sceneDefs
      ?.find((sc) => sc.id === activeScene)
      ?.imagePanels?.find((tp) => tp.id === activeElement);
  },
);
const selectActiveAnimationLayer = createSelector(
  selectProject,
  selectActive,
  (projectState, { activeElement, activeScene, activeProp, activePoster }) => {
    if (!activeElement || !(activeProp === 'animationPanels') || !activeScene || !activeProp)
      return null;
    if (activePoster)
      return projectState.present.project.sceneDefs
        ?.find((sc) => sc.id === activeScene)
        ?.weatherPosters.find((poster) => poster.id === activePoster)
        ?.animationPanels?.find((tp) => tp.id === activeElement);
    return projectState.present.project.sceneDefs
      ?.find((sc) => sc.id === activeScene)
      ?.animationPanels?.find((tp) => tp.id === activeElement);
  },
);
const selectActiveVideoLayer = createSelector(
  selectProject,
  selectActive,
  (projectState, { activeElement, activeScene, activePoster }) => {
    if (activePoster)
      return projectState.present.project.sceneDefs
        ?.find((sc) => sc.id === activeScene)
        ?.weatherPosters.find((poster) => poster.id === activePoster)
        ?.videoPanels?.find((tp) => tp.id === activeElement);
    return projectState.present.project.sceneDefs
      ?.find((sc) => sc.id === activeScene)
      ?.videoPanels?.find((tp) => tp.id === activeElement);
  },
);

const selectActiveAudioElement = createSelector(
  selectProject,
  selectActive,
  (projectState, { activeElement, activeScene, activeProp }) => {
    if (!activeElement || !(activeProp === 'audioElements') || !activeScene || !activeProp)
      return null;
    return projectState.present.project.sceneDefs
      ?.find((sc) => sc.id === activeScene)
      ?.audioElements?.find((tp) => tp.id === activeElement);
  },
);

const selectActiveMapLayer = createSelector(
  selectProject,
  selectActive,
  (projectState, { activeElement, activeScene, activeProp }) => {
    if (!activeElement || !(activeProp === 'mapPanels') || !activeScene || !activeProp) return null;
    return projectState.present.project.sceneDefs
      ?.find((sc) => sc.id === activeScene)
      ?.mapPanels?.find((tp) => tp.id === activeElement);
  },
);

const selectActiveGraphLayer = createSelector(
  selectProject,
  selectActive,
  (projectState, { activeElement, activeScene, activeProp }) => {
    if (!activeElement || !(activeProp === 'weatherPosters') || !activeScene || !activeProp)
      return null;
    return projectState.present.project.sceneDefs
      ?.find((sc) => sc.id === activeScene)
      ?.weatherPosters?.find((tp) => tp.id === activeElement);
  },
);
const selectActiveCompositeLayer = createSelector(
  selectProject,
  selectActive,
  (projectState, { activeElement, activeScene, activeProp, activeMap }) => {
    if (!activeElement || !(activeProp === 'weatherPosters') || !activeScene || !activeProp)
      return null;
    const scene = projectState.present.project.sceneDefs?.find((sc) => sc.id === activeScene);
    if (scene) {
      const map = scene.mapPanels.find((mapPanel) => mapPanel.id === activeMap);
      if (map) {
        return map.geoPosters.find((wp) => wp.id === activeElement);
      }
    }
  },
);

const selectActiveScene = createSelector(
  selectProject,
  selectActive,
  (projectState, { activeScene }) => {
    if (!activeScene) return null;
    return projectState.present.project.sceneDefs?.find((sc) => sc.id === activeScene)!;
  },
);

const selectIsOnlyElement = createSelector(
  selectProject,
  selectActive,
  (projectState, { activeScene }) => {
    if (!activeScene) return { isOnly: false, id: '', prop: '' };
    const scene = projectState.present.project.sceneDefs?.find((sc) => sc.id === activeScene)!;
    const elArr: any[] = [];
    let prop = '';
    scene.audioElements.forEach((el) => elArr.push(el));
    if (scene.audioElements.length) prop = 'audioElements';
    scene.videoPanels.forEach((el) => elArr.push(el));
    if (scene.videoPanels.length) prop = 'videoPanels';
    scene.mapPanels.forEach((el) => elArr.push(el));
    if (scene.mapPanels.length) prop = 'mapPanels';
    scene.imagePanels.forEach((el) => elArr.push(el));
    if (scene.imagePanels.length) prop = 'imagePanels';
    scene.animationPanels.forEach((el) => elArr.push(el));
    if (scene.animationPanels.length) prop = 'animationPanels';
    scene.textPanels.forEach((el) => elArr.push(el));
    if (scene.textPanels.length) prop = 'textPanels';
    scene.weatherPosters.forEach((el) => elArr.push(el));
    if (scene.weatherPosters.length) prop = 'weatherPosters';
    if (elArr.length === 1) {
      return {
        isOnly: true,
        id: elArr[0]?.id,
        prop,
      };
    } else {
      return {
        isOnly: false,
        id: '',
        prop: '',
      };
    }
  },
);

const selectActiveCityGeoposter = createSelector(
  selectActiveScene,
  selectActive,
  (scene, { activeElement, activeMap }) => {
    const activeMapDef = scene!.mapPanels.find((m) => m.id === activeMap)!;
    return activeMapDef?.cityPosters?.find((p) => p.id === activeElement)!;
  },
);

const selectActiveMapLayerByActiveMap = createSelector(
  selectActiveScene,
  selectActive,
  (scene, { activeMap }) => {
    return { ...scene!.mapPanels.find((m) => m.id === activeMap)! };
  },
);
const selectSkips = createSelector(selectProject, (project) => {
  return project.present.project.skippedTime;
});
const selectLastSkip = createSelector(selectSkips, (skips) => {
  return skips.map((sk) => sk.endMS).sort((a, b) => a - b)?.[0];
});
const selectVoiceOver = createSelector(selectProject, (project) => {
  return project.present.project.voiceOver;
});
const selectProjectDuration = createSelector(selectProject, (project) => {
  if (!project.present?.project?.sceneDefs.length) return 0;
  return project.present?.project?.sceneDefs.reduce((acc, sc) => (sc.durationInMS || 0) + acc, 0);
});

export {
  selectActiveAnimationLayer,
  selectActiveAudioElement,
  selectActiveCityGeoposter,
  selectActiveCompositeLayer,
  selectActiveElementAny,
  selectActiveForecastLayer,
  selectActiveGraphLayer,
  selectActiveImageLayer,
  selectActiveLegend,
  selectActiveMapLayer,
  selectActiveMapLayerByActiveMap,
  selectActiveObservedLayer,
  selectActivePointDateLayer,
  selectActiveScene,
  selectActiveTextLayer,
  selectActiveTimestampLayer,
  selectActiveVideoLayer,
  selectIsOnlyElement,
  selectLastSkip,
  selectProjectDuration,
  selectSkips,
  selectVoiceOver,
};
