const singleColorOpacity = (color: string) => {
  const colorArray = color.match(/\d+/g);
  const isGradient = color.includes('linear-gradient');
  if (colorArray && !isGradient) {
    const opacity = Number(colorArray[3]) / 255;
    return `rgba(${colorArray[0]}, ${colorArray[1]}, ${colorArray[2]}, ${opacity})`;
  } else {
    return color;
  }
};
const gradientOpacity = (gradient: string) => {
  const colorArray = gradient.match(/\d+/g);
  if (colorArray) {
    const opacity1 = Number(colorArray[4]) / 255;
    const opacity2 = Number(colorArray[8]) / 255;
    return `linear-gradient(${colorArray[0]}deg, rgba(${colorArray[1]}, ${colorArray[2]}, ${colorArray[3]}, ${opacity1}), rgba(${colorArray[5]}, ${colorArray[6]}, ${colorArray[7]}, ${opacity2}))`;
  } else {
    return gradient;
  }
};

export { gradientOpacity, singleColorOpacity };
