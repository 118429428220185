import '../../../../molecules/FileUpload/style.scss';
import '../../modals/style.scss';

import { nanoid } from 'nanoid';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { v4 } from 'uuid';

import { UpdateInterface } from '../../templates-NEW/Projects';
import UploadInput from './UploadInput';
import UploadSingleFile from './UploadSingleFile';
import { getAccept } from './uploadUtils';

interface Props {
  theme?: 'light' | 'dark';
  fileType: 'image' | 'video' | 'audio' | 'custom_map' | 'animation' | 'symbol' | 'custom_vector';
  setFiles: React.Dispatch<React.SetStateAction<UpdateInterface[]>>;
}

export interface UploadableFile {
  id: string;
  file: File;
  name: string;
  description: string;
}

function UploadFiles({ theme = 'light', fileType, setFiles }: Props) {
  const fileRef = useRef<HTMLInputElement>(null);
  const [uploadFiles, setUploadFiles] = useState<UploadableFile[]>([]);
  const [fType, setFType] = useState({});

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const mappedAcc = acceptedFiles.map((file) => ({
      id: v4(),
      file,
      name:
        file.name.substring(0, file.name.lastIndexOf('.')) +
        '-' +
        nanoid(4) +
        file.name.substring(file.name.lastIndexOf('.')),
      description: '',
    }));

    setUploadFiles((prev) => [...prev, ...mappedAcc]);
  }, []);

  useEffect(() => {
    setFType(getAccept(fileType));
  }, [fileType]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: fType,
  });

  function onDelete(file: File) {
    setUploadFiles((curr) => curr.filter((fw) => fw.file !== file));
  }

  return (
    <>
      {uploadFiles.map((fileWrapper) => {
        return (
          <UploadSingleFile
            key={fileWrapper.id}
            file={fileWrapper}
            fileType={fileType}
            onDelete={onDelete}
            setFiles={setFiles}
          />
        );
      })}
      <section>
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <div>
            <label className="flex justify-center h-48 px-4 transition border-2 border-gray-300 border-dashed rounded-md cursor-pointer hover:border-gray-400 focus:outline-none">
              <UploadInput theme={theme} fileRef={fileRef} />
            </label>
          </div>
        </div>
      </section>
    </>
  );
}

export default UploadFiles;
