import './Thumbnails.scss';

import { Modal } from 'antd';
import { CarouselRef } from 'antd/es/carousel';
import React, { useEffect, useRef, useState } from 'react';
import { AiOutlinePlayCircle } from 'react-icons/ai';
import { BsBookmark, BsFillBookmarkFill } from 'react-icons/bs';
import ReactPlayer from 'react-player';

import { LottieIcon } from '../../../../atoms/LottieIcon';
import { IconTemplateItemDef } from '../../../../model/definitions/IconTemplateItemDef';
import { MarketplaceTemplateEnum } from '../../../../model/enums/MarketplaceTemplateEnum';
import PNGSequence from '../../../../molecules/canvasElements/sequence/PNGSequence';
import Loader from '../../../dashboard/components/loader-NEW/Loader';
import Carousel from '../../atoms/Carousel/AntCarousel';
import ThumbnailCard from '../../atoms/ThumbnailCard/ThumbnailCard';
import { useBookmark } from '../../hooks/useBookmark';
import { useGetBookmarkedItems } from '../../hooks/useGetBookmarks';
import { useGetProductMultimedia } from '../../hooks/useGetProductMultimedia';
import { useRemoveBookmark } from '../../hooks/useRemoveBookmark';

interface Props {
  item?: any;
  myStore?: boolean;
  authenticated: boolean;
}
const Thumbnails: React.FC<Props> = ({ item, authenticated }) => {
  const thumbnails = item?.templateDef?.thumbnailUrls || item?.thumbnailUrls;
  const [selectedItem, setSelectedItem] = useState(0);
  const [isBookmarked, setIsBookmarked] = useState();
  const carouselRef = useRef<CarouselRef>(null);
  const { mutate: addBookmark } = useBookmark();
  const { mutate: removeBookmark } = useRemoveBookmark();
  const { data: bookmarks } = useGetBookmarkedItems(authenticated);
  const { data, isLoading, isError } = useGetProductMultimedia(
    item?.templateDef?.file?.id,
    item?.templateType,
    authenticated,
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    if (bookmarks) {
      const bookmarked = bookmarks.find((bookmark: any) => bookmark.marketplaceItemId === item?.id);
      setIsBookmarked(bookmarked);
    }
  }, [bookmarks, item]);

  function selectTemplate(index: number) {
    if (carouselRef.current) {
      setSelectedItem(index);
      carouselRef.current.goTo(index, false);
    }
  }

  const renderItems = () => {
    return (
      <div
        className={`flex w-full self-center ${
          thumbnails?.length > 3 ? 'justify-between' : 'justify-center'
        } gap-8 mt-5 overflow-x-scroll`}
      >
        {thumbnails?.length > 1 &&
          thumbnails?.map((item: any, index: number) => (
            <ThumbnailCard
              item={item}
              key={index}
              onClick={() => selectTemplate(index)}
              className={
                index === selectedItem
                  ? 'object-contain border-2 border-[#2176ff] h-[110px] w-[110px]'
                  : 'object-contain h-[110px] w-[110px]'
              }
            />
          ))}
      </div>
    );
  };

  const onChange = (currentSlide: number) => {
    setSelectedItem(currentSlide);
  };

  const renderIcons = () => {
    return (
      <div className="text-center">
        <p className="uppercase text-gray-500 md:text-2xl lg:text-[42px] lg:!leading-[46px] font-bold mb-4">
          Icon pack
        </p>
        <div className="flex flex-col md:flex-row max-w-fit m-auto">
          {item?.templateDef?.iconItems.length > 0 ? (
            item?.templateDef?.iconItems.map((iconItem: IconTemplateItemDef) => {
              if (iconItem.iconTemplate.jsonAnimation) {
                return (
                  <div key={iconItem.id} className="flex-grow basis-0 w-40">
                    <LottieIcon versionId={iconItem.iconTemplate.versionId} autoPlay={false} />
                  </div>
                );
              } else {
                return (
                  <div key={iconItem.id} className="flex-grow basis-0">
                    <img
                      className="h-full w-full aspect-square "
                      src={iconItem.iconTemplate.thumbnailUrls[0]}
                      alt="img"
                    />
                  </div>
                );
              }
            })
          ) : (
            <p className="mx-auto text-3xl">No icons</p>
          )}
        </div>
      </div>
    );
  };

  const renderBookmark = () => {
    return (
      <div
        className={`absolute bottom-6 right-6 p-1 rounded ${
          isBookmarked ? 'bg-gray-100' : 'bg-gray-600'
        }`}
      >
        {isBookmarked ? (
          <BsFillBookmarkFill
            size={24}
            color="#2176ff"
            className="cursor-pointer"
            onClick={() => removeBookmark(isBookmarked['id'])}
          />
        ) : (
          <BsBookmark
            size={24}
            color="white"
            className="cursor-pointer"
            onClick={() => addBookmark({ marketplaceItemId: item.id, name: item.name })}
          />
        )}
      </div>
    );
  };

  return (
    <div className="wrapper">
      <div className="content">
        <div className="relative">
          <Carousel swipeToSlide draggable dots={false} afterChange={onChange} ref={carouselRef}>
            {thumbnails?.map((thumb: any, index: number) => {
              return (
                <img key={index} src={thumbnails && thumbnails[index]} className="object-contain" />
              );
            })}
          </Carousel>
          {authenticated && renderBookmark()}
          <div
            className={`${
              isError ? 'hidden' : 'block'
            } absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer`}
          >
            {isLoading ? (
              <Loader />
            ) : (
              (data ||
                ((item?.templateType === MarketplaceTemplateEnum.ICON_SET_TEMPLATE ||
                  item?.templateType === MarketplaceTemplateEnum.ANIMATION_TEMPLATE) &&
                  authenticated)) && (
                <AiOutlinePlayCircle
                  onClick={toggleModal}
                  size={65}
                  color="white"
                  className="bg-gray-600 rounded-full"
                />
              )
            )}
          </div>
        </div>
        {thumbnails?.length > 1 && renderItems()}
      </div>
      <Modal
        className="w-[100%] lg:!w-[50%] !p-0"
        centered
        closable={false}
        open={isModalOpen}
        onCancel={toggleModal}
        footer={null}
        destroyOnClose={true}
      >
        {item?.templateType === MarketplaceTemplateEnum.ICON_SET_TEMPLATE ? (
          renderIcons()
        ) : item?.templateType === MarketplaceTemplateEnum.ANIMATION_TEMPLATE ? (
          item?.templateDef?.jsonAnimation ? (
            <LottieIcon
              versionId={item?.templateVersionId}
              style={{ width: '10rem', margin: '0 auto' }}
            />
          ) : (
            <div className="w-40 m-auto">
              <PNGSequence item={item?.templateDef} showcase />
            </div>
          )
        ) : item?.templateType === MarketplaceTemplateEnum.VIDEO_TEMPLATE ? (
          <ReactPlayer
            controls
            width="100%"
            height="100%"
            playing={isModalOpen}
            url={data}
            config={{ file: { attributes: { controlsList: 'nodownload' } } }}
          />
        ) : (
          <ReactPlayer
            controls
            width="100%"
            height="50px"
            playing={isModalOpen}
            url={data}
            config={{ file: { attributes: { controlsList: 'nodownload' } } }}
          />
        )}
      </Modal>
    </div>
  );
};

export default Thumbnails;
