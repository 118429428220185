import { useMutation } from 'react-query';

import { queryClient } from '../../../index';
import { ColorPaletteDef } from '../../../model/definitions/ColorPaletteDef';
import { CustomPaletteDTO } from '../../../model/DTO/CustomPaletteDTO';
import { key as paletteQueryKey } from '../../api/mapLayers/useGetPalette';
import { axiosInstance } from '../axiosInstance';
import { key as isolineQueryKey } from '../mapLayers/useGetVisualisationPalette';

export const useCreatePalette = () =>
  useMutation(
    async (palette: CustomPaletteDTO): Promise<ColorPaletteDef> => {
      const { data } = await axiosInstance.post('color-palette', palette);
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(paletteQueryKey);
        queryClient.invalidateQueries(isolineQueryKey);
      },
    },
  );
