import { Map } from 'ol';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { ModeEnum } from '../../core/ui/enums/ModeEnum';
import {
  WeatherDataGLLayer,
  WeatherDataGLSource,
} from '../../core/weather-data/ol-extensions/WeatherDataGLLayer';
import { WeatherDataLoader } from '../../core/weather-data/WeatherDataLoader';
// import { C9ProjectDef } from '../../model/definitions/C9ProjectDef';
import { GribMapLayer } from '../../model/definitions/GribMapLayer';
import { MapPanelDef } from '../../model/definitions/MapPanelDef';
import { RadarMapLayer } from '../../model/definitions/RadarMapLayer';
import { SatelliteMapLayer } from '../../model/definitions/SatelliteMapLayer';
import { VisualisationTypeEnum } from '../../model/enums/VisualisationTypeEnum';
import { WDLayerTypeEnum } from '../../model/enums/WDLayerTypeEnum';
import { PlayContext } from '../../pages/playground/playerContext/PlayerContext';
import { ActiveDef } from '../../store/slices/active-slice';
import { RootState } from '../../store/store';

/**
 * This hook makes sure all defined weather data layers are displayed on the OpenLayers map, and re-renders them when needed
 * @param mapPanel Map element panel
 * @param map OpenLayers map
 * @param playContext The player context used for timing
 */
export const useWeatherDataLayers = (mapPanel: MapPanelDef, map: Map, playContext: PlayContext) => {
  // #1 When map layers change, make sure they are already in the OpenLayers map
  const gribLayers = mapPanel.wdSpace[0].gribMapLayers;
  const radarLayers = mapPanel.wdSpace[0].radarMapLayers;
  const satelliteLayers = mapPanel.wdSpace[0].satelliteMapLayers;

  const { activeFramerate, mode, projectToPlay } = useSelector<RootState>(
    (state) => state.active,
  ) as ActiveDef;
  // const project = useSelector<RootState, C9ProjectDef>((state) => state.project.present.project);

  const scene = projectToPlay.sceneDefs.find((sc) =>
    sc.mapPanels.some((p) => p.id === mapPanel.id),
  );
  const sceneStartMs = scene?.timeControl.startMS || 0;
  const sceneEndMs = scene?.timeControl.endMS || 0;

  useEffect(() => {
    if (!map) return;

    const allWDLayers = map.getAllLayers().filter((l) => l.get('weatherDataLayerId') != undefined);

    const layersToRemove = allWDLayers.map((l) => l.get('weatherDataLayerId'));

    gribLayers.forEach((l) => {
      // TODO: Enable isolines when the shaders are fixed
      if (!l.enabled || l.layerSetup.visualisationType === VisualisationTypeEnum.ISOLINE) return;
      const idx = layersToRemove.findIndex((x) => x === l.id);
      if (idx > -1) layersToRemove.splice(idx, 1);
      ensureLayerExistsOnMap(
        projectToPlay.id,
        map,
        mapPanel,
        l,
        WDLayerTypeEnum.model,
        activeFramerate,
        mode,
        sceneStartMs,
        sceneEndMs,
      );
    });

    radarLayers.forEach((l) => {
      if (!l.enabled) return;
      const idx = layersToRemove.findIndex((x) => x === l.id);
      if (idx > -1) layersToRemove.splice(idx, 1);
      ensureLayerExistsOnMap(
        projectToPlay.id,
        map,
        mapPanel,
        l,
        WDLayerTypeEnum.radar,
        activeFramerate,
        mode,
        sceneStartMs,
        sceneEndMs,
      );
    });

    satelliteLayers.forEach((l) => {
      if (!l.enabled) return;
      const idx = layersToRemove.findIndex((x) => x === l.id);
      if (idx > -1) layersToRemove.splice(idx, 1);
      ensureLayerExistsOnMap(
        projectToPlay.id,
        map,
        mapPanel,
        l,
        WDLayerTypeEnum.satellite,
        activeFramerate,
        mode,
        sceneStartMs,
        sceneEndMs,
      );
    });

    removeLayersFromMap(map, layersToRemove);
  }, [
    gribLayers,
    radarLayers,
    satelliteLayers,
    map,
    mapPanel,
    mode,
    activeFramerate,
    sceneStartMs,
    projectToPlay,
    sceneEndMs,
  ]);

  // #2 When time changes, trigger a rerender for all custom layers
  useEffect(() => {
    if (!map) return;
    rerenderWeatherDataLayers(map);
  }, [playContext, map]);
};

const removeLayersFromMap = (map: Map, layers: string[]) => {
  if (!layers || !layers.length) return;
  map.getAllLayers().forEach((mapLayer) => {
    if (
      mapLayer.get('weatherDataLayerId') != undefined &&
      layers.find((x) => x == mapLayer.get('weatherDataLayerId'))
    ) {
      mapLayer.dispose();
      map.removeLayer(mapLayer);
    }
  });
};

const ensureLayerExistsOnMap = (
  projectId: string,
  map: Map,
  mapPanel: MapPanelDef,
  wdLayer: GribMapLayer | SatelliteMapLayer | RadarMapLayer,
  wdLayerType: WDLayerTypeEnum,
  activeFramerate: number,
  mode: ModeEnum,
  sceneStartMs: number,
  sceneEndMs: number,
) => {
  const mapLayer = map.getAllLayers().find((l) => l.get('weatherDataLayerId') === wdLayer.id);

  const layersToPreload = [];

  if (!mapLayer) {
    // create the webgl layer
    const source = new WeatherDataGLSource({});
    source.setWDLayer(wdLayer, wdLayerType);
    const newMapLayer = new WeatherDataGLLayer({
      source,
      className: 'wd-layer-' + wdLayer.id,
    });
    newMapLayer.set('weatherDataLayerId', wdLayer.id);
    newMapLayer.setZIndex(wdLayer.zindex);
    newMapLayer.setOpacity(wdLayer.opacity);
    newMapLayer.setWDLayer(wdLayer);
    newMapLayer.setWDLayerType(wdLayerType);
    newMapLayer.setMapDefId(mapPanel.id);
    newMapLayer.setMapDef(mapPanel);
    // const rendererTyped = newMapLayer.getRenderer() as WeatherDataGLLayerRenderer;
    newMapLayer.setFrameRate(activeFramerate);
    newMapLayer.setMode(mode);
    newMapLayer.setSceneStartMs(sceneStartMs);
    newMapLayer.setSceneEndMs(sceneEndMs);
    newMapLayer.recalculateFrames();
    map.addLayer(newMapLayer);
    layersToPreload.push(wdLayer);
    newMapLayer.recalculateFrames();
  } else {
    const layerTyped = mapLayer as WeatherDataGLLayer;

    layerTyped.setZIndex(wdLayer.zindex);
    layerTyped.setOpacity(wdLayer.opacity);
    layerTyped.getSource()?.refresh();
    // const rendererTyped = layerTyped.getRenderer() as WeatherDataGLLayerRenderer;
    layerTyped.setWDLayer(wdLayer);
    layerTyped.setWDLayerType(wdLayerType);
    layerTyped.setMapDefId(mapPanel.id);
    layerTyped.setMapDef(mapPanel);
    layerTyped.setFrameRate(activeFramerate);
    layerTyped.setMode(mode);
    layerTyped.setSceneStartMs(sceneStartMs);
    layerTyped.setSceneEndMs(sceneEndMs);
    layerTyped.recalculateFrames();

    // When data frames are changed, if the first one is not preloaded, it should be
    if (
      wdLayer.dataFrames &&
      !WeatherDataLoader.getByFrameId(wdLayer.dataFrames[0]?.frameId, wdLayer.id)
    ) {
      layersToPreload.push(wdLayer);
    }

    rerenderWeatherDataLayers(map);
  }

  if (layersToPreload.length > 0) {
    const worker = WeatherDataLoader.getInstance();
    worker.loadWeatherData(projectId, layersToPreload, mapPanel, true, () => {
      rerenderWeatherDataLayers(map);
      setTimeout(() => {
        // "fixes" some strange condition where it doesn't render the data even though it's loaded and available
        rerenderWeatherDataLayers(map);
      }, 10);
    });
  }
};

const rerenderWeatherDataLayers = (map: Map) => {
  const wdLayers = map
    .getAllLayers()
    .filter((l) => l.get('weatherDataLayerId') != undefined)
    .map((x) => x as WeatherDataGLLayer);
  wdLayers.forEach((l) => {
    l.renderFrame();
  });
};
