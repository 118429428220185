import './style.scss';

import { CloseCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { Empty, Radio, RadioChangeEvent } from 'antd';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { useContext, useEffect, useState } from 'react';
import { Zoom } from 'react-awesome-reveal';
import { Triangle } from 'react-loader-spinner';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { MarketplaceTemplateEnum } from '../../../../model/enums/MarketplaceTemplateEnum';
import { SortingMarketplaceEnum } from '../../../../model/enums/SortingEnum';
import MarketBG from '../../atoms/MarketBG/MarketBG';
import Pagination from '../../atoms/Pagination/AntPagination';
import Slider from '../../atoms/Slider/Slider';
import AntTag from '../../atoms/Tags/AntTag';
import { useGetApprovedItemsFilter } from '../../hooks/filterUseGetApprovedItems';
import { useGetPublicApprovedItemsFilter } from '../../hooks/filterUseGetPublicApprovedItemsFilter';
import { useGetPublishedItemsFilter } from '../../hooks/filterUseGetPublishedItems';
import { useGetCurrency } from '../../hooks/useGetCurrency';
import { useGetMarketplaceTags } from '../../hooks/useGetMarketplaceTags';
import { AntContent } from '../../layout/Layout';
import MarketplaceContext from '../../marketplaceContext';
import { MarketplaceItem } from '../../model/interfaces/MarketplaceItem';
import AntCheckboxGroup from '../../molecules/AntCheckboxGroup/AntCheckboxGroup';
import AntItemCard from '../../molecules/AntItemCard/AntItemCard';
import AntSelect from '../../molecules/AntSelect/AntSelect';
import Sorting from '../../molecules/Sorting/Sorting';
import { categoriesConfig } from '../LandingPage/config';

const Market = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { email } = params;
  const { searchString, setSearchString, type, setType, tokenEmail, authenticated, admin } =
    useContext(MarketplaceContext);
  const [sortAscDesc, setSortAscDesc] = useState<string>('');
  const [sort, setSort] = useState<boolean>(true);
  const [sortByField, setSortByField] = useState<string>('');
  const [sizeChanger, setSizeChanger] = useState<number>(12);
  const [sliderValue, setSliderValue] = useState<[number, number]>([0, 999]);
  const [priceValue, setPriceValue] = useState<[number, number]>([0, 999]);
  const [status, setStatus] = useState<boolean | undefined>();
  const location = useLocation();
  const path = location.pathname.includes('/my-store/items');
  const store = location.pathname.includes('/marketplace/store');
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const { data: tags, isLoading: isLoadingTags } = useGetMarketplaceTags(authenticated);
  const { data: currency } = useGetCurrency();

  const statusOptions = [
    { label: 'Approved', value: 'true' },
    { label: 'Disapproved', value: 'false' },
  ];
  const [pages, setPages] = useState<{ current: number; last: number }>({
    current: 0,
    last: 0,
  });
  const { data: published, isLoading: isLoadingPublish } = useGetPublishedItemsFilter(
    admin && !store && (authenticated ? true : false),
    pages.current,
    sizeChanger,
    path ? tokenEmail : '',
    searchString,
    type,
    sortByField,
    sortAscDesc,
    priceValue[0],
    priceValue[1],
    selectedTags,
    status,
  );
  const { data: approved, isLoading: isLoadingApproved } = useGetApprovedItemsFilter(
    !admin && !store && (authenticated ? true : false),
    pages.current,
    sizeChanger,
    path ? tokenEmail : '',
    searchString,
    type,
    sortByField,
    sortAscDesc,
    priceValue[0],
    priceValue[1],
    selectedTags,
  );
  const { data: publicApproved, isLoading: isLoadingPublicApproved } =
    useGetPublicApprovedItemsFilter(
      !path && (email || !authenticated) ? true : false,
      pages.current,
      sizeChanger,
      !path && email ? email : '',
      searchString,
      type,
      sortByField,
      sortAscDesc,
      priceValue[0],
      priceValue[1],
      selectedTags,
    );

  const data =
    !path && (email || !authenticated) ? publicApproved : admin && !store ? published : approved;

  useEffect(() => {
    if (path && !authenticated) {
      navigate('/marketplace');
    }
  }, [path]);

  const renderItems = () => {
    return data?.content.map((item: MarketplaceItem) => (
      <AntItemCard
        item={item}
        key={item.id}
        isAdmin={admin}
        author={item.creator}
        currency={currency}
        email={tokenEmail}
      />
    ));
  };

  const onShowSizeChange = (current: number, pageSize: number) => {
    setSizeChanger(pageSize);
    setPages({ current: 0, last: 0 });
  };

  function handleSort(sortBy: string) {
    if (sortByField === '') {
      setSortAscDesc('ASC');
      setSort(true);
    }
    if (sortBy !== sortByField) {
      setSortByField(sortBy);
      return;
    }
    if (sortAscDesc === 'ASC' && sort) {
      setSortAscDesc('DESC');
    } else {
      setSortAscDesc('');
      setSortByField('');
      setSort(false);
    }
  }

  const convertEnumToString = (type: MarketplaceTemplateEnum) => {
    switch (type) {
      case MarketplaceTemplateEnum.MAP_TEMPLATE:
        return 'Map';
      case MarketplaceTemplateEnum.CUSTOM_MAP_TEMPLATE:
        return 'Custom Map';
      case MarketplaceTemplateEnum.CUSTOM_VECTOR_TEMPLATE:
        return 'Custom Vector';
      case MarketplaceTemplateEnum.IMAGE_TEMPLATE:
        return 'Image';
      case MarketplaceTemplateEnum.VIDEO_TEMPLATE:
        return 'Video';
      case MarketplaceTemplateEnum.AUDIO_TEMPLATE:
        return 'Audio';
      case MarketplaceTemplateEnum.ANIMATION_TEMPLATE:
        return 'Animation';
      case MarketplaceTemplateEnum.WEATHER_POSTER_TEMPLATE:
        return 'Poster';
      case MarketplaceTemplateEnum.WEATHER_GEO_POSTER_TEMPLATE:
        return 'Composite';
      case MarketplaceTemplateEnum.PROJECT_TEMPLATE:
        return 'Project';
      case MarketplaceTemplateEnum.ICON_SET_TEMPLATE:
        return 'Icon Set';
      case MarketplaceTemplateEnum.SCENE_TEMPLATE:
        return 'Scene';
    }
  };

  const onSliderChange = (value: [number, number]) => {
    setSliderValue(value);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (
        (sliderValue[1] > 0 && sliderValue[0] !== priceValue[0]) ||
        sliderValue[1] !== priceValue[1]
      ) {
        setPriceValue([
          Number((sliderValue[0] / (currency.exchangeRateUsd ?? 1)).toFixed()),
          Number((sliderValue[1] / (currency.exchangeRateUsd ?? 1)).toFixed()),
        ]);
      }
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [sliderValue]);

  useEffect(() => {
    if (currency?.code === 'EUR') {
      setPriceValue([
        Number((sliderValue[0] / (currency?.exchangeRateUsd ?? 1)).toFixed()),
        Number((sliderValue[1] / (currency?.exchangeRateUsd ?? 1)).toFixed()),
      ]);
    } else {
      setPriceValue([
        Number((sliderValue[0] * (currency?.exchangeRateUsd ?? 1)).toFixed()),
        Number((sliderValue[1] * (currency?.exchangeRateUsd ?? 1)).toFixed()),
      ]);
    }
  }, [currency]);

  useEffect(() => {
    if (data?.content.length === 0 && pages.current > 0) {
      setPages({ ...pages, current: pages.current - 1 });
    }
  }, [data]);

  const categories = [...categoriesConfig];

  const onSelectCategory = (items: CheckboxValueType[]) => {
    setPages({ current: 0, last: 0 });
    setType(items);
  };

  const updateSelectedCategories = (category: string) => {
    if (type) {
      const newSelectedCategories = type.filter((item) => category !== item);
      setType(newSelectedCategories);
    }
  };

  const resetSliderValue = () => {
    setPages({ current: 0, last: 0 });
    setSliderValue([0, 999]);
    setPriceValue([0, 999]);
  };

  const onStatusChange = ({ target: { value } }: RadioChangeEvent) => {
    const booleanValue = JSON.parse(value.toLowerCase());
    setStatus(booleanValue);
  };
  return (
    <div className="g-assets-wrapper">
      <MarketBG setSearchString={setSearchString} />
      <AntContent>
        <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-5 mt-10 mb-10 md:mt-0 md:mb-0">
          <div className="col-span-1"></div>
          <div className="tabs-sorting-wrapper col-span-1 md:col-span-3 lg:col-span-4 overflow-y-visable">
            <span className="top-filters-flex-container">
              <div className="tag-flex-container">
                {type &&
                  type.map((item, index) => {
                    return (
                      <AntTag
                        key={item as string}
                        className="marketplace-tag-big"
                        closeIcon={<CloseCircleOutlined style={{ fontSize: 14, paddingLeft: 4 }} />}
                        onClose={() => updateSelectedCategories(item as string)}
                        closable
                      >
                        {convertEnumToString(item as MarketplaceTemplateEnum)}
                      </AntTag>
                    );
                  })}
                {sliderValue[1] != 999 && (
                  <AntTag
                    className="marketplace-tag-big"
                    closeIcon={<CloseCircleOutlined style={{ fontSize: 14, paddingLeft: 4 }} />}
                    onClose={() => resetSliderValue()}
                    closable
                  >
                    {currency &&
                      `${currency.symbol} ${sliderValue[0]} - ${currency.symbol} ${sliderValue[1]}`}
                  </AntTag>
                )}
                {status !== undefined && (
                  <AntTag
                    className="marketplace-tag-big"
                    closeIcon={<CloseCircleOutlined style={{ fontSize: 14, paddingLeft: 4 }} />}
                    onClose={() => setStatus(undefined)}
                    closable
                  >
                    {status ? 'Approved' : 'Disapproved'}
                  </AntTag>
                )}
                {selectedTags.length > 0 && tokenEmail !== '' && (
                  <AntSelect
                    placeholder="Search tags..."
                    mode="multiple"
                    value={selectedTags}
                    options={tags.map((item: string) => {
                      return { value: item };
                    })}
                    className="ant-select-big"
                    onChange={(value) => setSelectedTags(value)}
                    maxTagCount={0}
                    allowClear
                    showArrow
                    suffixIcon={<SearchOutlined />}
                  />
                )}
              </div>
              <div className="sorting-wrapper-container">
                <Sorting
                  sortAscDesc={sortAscDesc}
                  sorting={SortingMarketplaceEnum}
                  handleSort={handleSort}
                  sortByField={sortByField}
                />
              </div>
            </span>
          </div>
          {!isLoadingTags && (
            <div className="col-span-1 mt-4 max-w-[70%]">
              <AntCheckboxGroup
                title="Categories"
                options={categories.map((item) => {
                  return { label: item.name, value: item.type };
                })}
                value={type}
                onChange={onSelectCategory}
              />
              {admin && (
                <Radio.Group
                  options={statusOptions}
                  onChange={onStatusChange}
                  value={status}
                  optionType="button"
                  size="small"
                />
              )}
              <Slider
                title="Price"
                sliderValue={sliderValue}
                setSliderValue={setSliderValue}
                range
                min={0}
                max={999}
                onChange={onSliderChange}
                value={sliderValue}
                trackStyle={[
                  {
                    background:
                      'radial-gradient(circle at 10% 20%, rgb(23, 138, 240) 0%, rgb(0, 181, 149) 90%)',
                    height: '8px',
                  },
                ]}
                railStyle={{
                  height: '8px',
                  borderRadius: '10px',
                }}
              />
              <AntSelect
                title="TAGS"
                placeholder="Search tags..."
                mode="multiple"
                value={selectedTags}
                options={tags?.map((item: string) => {
                  return { value: item };
                })}
                onChange={(value) => setSelectedTags(value)}
                maxTagCount={0}
                allowClear
                showArrow
                suffixIcon={<SearchOutlined />}
                tagRender={() => {
                  return <></>;
                }}
              />
              <span className="tag-flex-container">
                {selectedTags.map((item, index) => {
                  return (
                    <AntTag key={index} className="marketplace-tag">
                      {item}
                    </AntTag>
                  );
                })}
              </span>
            </div>
          )}
          <div className="col-span-1 md:col-span-3 lg:col-span-4 mt-4">
            <div className={'flex justify-center items-center  flex-col mb-12'}>
              {isLoadingPublicApproved || isLoadingApproved || isLoadingPublish ? (
                <Triangle
                  height="80"
                  width="80"
                  color="#3A36DB"
                  ariaLabel="triangle-loading"
                  wrapperStyle={{}}
                  visible={true}
                />
              ) : data?.content.length === 0 ? (
                <Empty className="ant-empty-marketplace" image={Empty.PRESENTED_IMAGE_SIMPLE} />
              ) : (
                <div className={'grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-10'}>
                  <Zoom cascade damping={0.2} triggerOnce duration={300}>
                    {renderItems()}
                  </Zoom>
                </div>
              )}
            </div>
            {data && (
              <Pagination
                showSizeChanger
                pageSizeOptions={[12, 24, 50, 100]}
                onShowSizeChange={onShowSizeChange}
                current={pages.current + 1}
                defaultPageSize={data?.size}
                onChange={(page) => setPages({ ...pages, current: page - 1 })}
                total={data?.totalPages * data?.size}
              />
            )}
          </div>
        </div>
      </AntContent>
    </div>
  );
};

export default Market;
