const stringToUnit = (unit: string) => {
  switch (unit?.toUpperCase()) {
    case 'DEGREECELSIUS':
      return '°C';
    case 'HECTOPASCAL':
      return 'hPa';
    case 'MILLIBAR':
      return 'mb';
    case 'DEGREE':
      return '°';
    case 'KNOT':
      return 'kt';
    case 'STATUTEMILE':
      return 'mi.';
    case 'METERPERSECOND':
      return 'm/s';
    case 'KILOMETERPERHOUR':
      return 'km/h';
    case 'MILESPERHOUR':
      return 'mph';
    case 'DEGREEFAHRENHEIT':
      return '°F';
    case 'MILLIMETER':
      return 'mm';
    case 'METER':
      return 'm';
    case 'FEET':
      return 'ft';
    case 'PERCENTAGE':
      return '%';
    case 'MERCURYINCH':
      return '"';
    case 'UNKNOWNUNIT':
      return 'N/A';
    case 'NONE':
      return;
    default:
      return unit;
  }
};

export { stringToUnit };
