import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { GribMapLayer } from '../../../../model/definitions/GribMapLayer';
import { MapPanelDef } from '../../../../model/definitions/MapPanelDef';
import { RadarMapLayer } from '../../../../model/definitions/RadarMapLayer';
import { SatelliteMapLayer } from '../../../../model/definitions/SatelliteMapLayer';
import { ActiveDef, setSyncSpace } from '../../../../store/slices/active-slice';
import { RootState } from '../../../../store/store';
import Loader from '../../../dashboard/components/loader-NEW/Loader';
/** commented out until auto import multiple layers enabled **/
/* const { RangePicker } = DatePicker;

type RangeValue<T> = [T, T];*/
interface AutoImportProps {
  gribLayers: GribMapLayer[];
  radarLayers: RadarMapLayer[];
  satelliteLayers: SatelliteMapLayer[];
  bounds: [number, number, number, number];
  map: MapPanelDef;
  setMapToAdd: (map: MapPanelDef) => void;
}
export const AutoImport = ({
  gribLayers,
  radarLayers,
  satelliteLayers,
  bounds,
  map,
  setMapToAdd,
}: AutoImportProps) => {
  const { syncSpace } = useSelector<RootState>((state) => state.active) as ActiveDef;
  const dispatch = useDispatch();
  //const [dates, setDates] = useState<RangeValue<Dayjs>>([dayjs(), dayjs()]);
  // const [framesTime, setFramesTime] = useState<Array<number>>([]);
  const [mapTemplate /*  setMapTemplate */] = useState<MapPanelDef>(map);
  /*  const isDateDisabled = (currentDate: dayjs.Dayjs) => {
    const formattedDate = currentDate.valueOf();
    return (
      formattedDate > Math.max(...framesTime) * 1000 ||
      formattedDate < Math.min(...framesTime) * 1000
    );
  }; */
  useEffect(() => {
    !syncSpace.includes(mapTemplate.wdSpace[0].id) &&
      dispatch(
        setSyncSpace({
          spaceId: mapTemplate.wdSpace[0].id,
        }),
      );
    setMapToAdd(mapTemplate);
  }, [mapTemplate, setMapToAdd]);
  /* const handleGrib = (id: string, e: Array<DataFrameDef>) => {
    const gribs = mapTemplate.wdSpace[0].gribMapLayers;
    const layer = gribs.find((layer) => layer.id === id);
    if (layer) {
      layer.dataFrames = e;
    }
    setMapTemplate((map) => ({ ...map, wdSpace: [{ ...map.wdSpace[0], gribMapLayers: gribs }] }));
  };
  const handleRadar = (id: string, e: Array<DataFrameDef>) => {
    const radars = mapTemplate.wdSpace[0].radarMapLayers;
    const layer = radars.find((layer) => layer.id === id);
    if (layer) {
      layer.dataFrames = e;
    }
    setMapTemplate((map) => ({ ...map, wdSpace: [{ ...map.wdSpace[0], radarMapLayers: radars }] }));
  };
  const handleSatellite = (id: string, e: Array<DataFrameDef>) => {
    const satellites = mapTemplate.wdSpace[0].satelliteMapLayers;
    const layer = satellites.find((layer) => layer.id === id);
    if (layer) {
      layer.dataFrames = e;
    }
    setMapTemplate((map) => ({
      ...map,
      wdSpace: [{ ...map.wdSpace[0], satelliteMapLayers: satellites }],
    }));
  }; */
  return (
    <div>
      <Loader />
      {/* <div className={'flex flex-col justify-center items-center'}>
        <h4 className={'text-2xl text-white mb-3'}>Select interval</h4>
        <RangePicker
          className={'bg-gray-600'}
          showTime
          disabledDate={(e) => isDateDisabled(e)}
          onChange={(e) => {
            //@ts-ignore
            e && setDates(e);
          }}
        />
        <div className={'flex my-6'}>
          <div className={'font-bold'}>
            {dates && dayjs(dates[0].valueOf()).format('DD.MM.YYYY HH:mm')}
          </div>
          -
          <div className={'font-bold'}>
            {dates && dayjs(dates[1].valueOf()).format('DD.MM.YYYY HH:mm')}
          </div>
        </div>
      </div>
      <div>
        {gribLayers?.map((layer) => (
          <GribLayerRange
            max={Math.max(...framesTime)}
            min={Math.min(...framesTime)}
            minDate={Math.round(dates[0].valueOf() / 10000) * 10000}
            maxDate={Math.round(dates[1].valueOf() / 10000) * 10000}
            key={layer.id}
            layer={layer}
            bounds={bounds}
            onChange={handleGrib}
            onData={(data: number[]) => setFramesTime((prevState) => [...prevState, ...data])}
          />
        ))}
      </div>
      <div>
        {radarLayers?.map((layer) => (
          <RadarLayerRange
            max={Math.max(...framesTime)}
            min={Math.min(...framesTime)}
            minDate={Math.round(dates[0].valueOf() / 10000) * 10000}
            maxDate={Math.round(dates[1].valueOf() / 10000) * 10000}
            key={layer.id}
            layer={layer}
            bounds={bounds}
            onChange={handleRadar}
            onData={(data: number[]) => setFramesTime((prevState) => [...prevState, ...data])}
          />
        ))}
      </div>
      <div>
        {satelliteLayers?.map((layer) => (
          <SatelliteLayerRange
            max={Math.max(...framesTime)}
            min={Math.min(...framesTime)}
            minDate={Math.round(dates[0].valueOf() / 10000) * 10000}
            maxDate={Math.round(dates[1].valueOf() / 10000) * 10000}
            key={layer.id}
            layer={layer}
            bounds={bounds}
            onChange={handleSatellite}
            onData={(data: number[]) => setFramesTime((prevState) => [...prevState, ...data])}
          />
        ))}
      </div> */}
    </div>
  );
};
