import { List } from 'antd';
import { CgTrashEmpty } from 'react-icons/cg';

import { useGetCurrency } from '../../hooks/useGetCurrency';
import Price from '../Price/Price';
import { DataType } from './Sidebar';

interface Cart {
  data: DataType[];
  handleDelete: (item: DataType) => void;
}

function Cart({ data, handleDelete }: Cart) {
  const { data: currency } = useGetCurrency();
  return (
    <List
      dataSource={data}
      renderItem={(item) => (
        <List.Item key={item.id}>
          <List.Item.Meta
            title={item.name}
            description={item.description ?? ''}
            avatar={<img className="w-[90px] h-[130px] object-cover" src={item.image ?? ''} />}
          />
          <div className="flex pl-6 justify-end">
            <div className="pl-4 pr-12">
              <p className="pb-4 text-gray-500">Price</p>
              <p className="text-[#2176ff] text-xl">
                {currency && <Price currency={currency} price={item.price} />}
              </p>
            </div>
            <div className="self-end pb-1 cursor-pointer" onClick={() => handleDelete(item)}>
              <CgTrashEmpty color="red" size={20} />
            </div>
          </div>
        </List.Item>
      )}
    />
  );
}

export default Cart;
