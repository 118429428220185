import { sortedUniqBy } from 'lodash';
import { useQuery } from 'react-query';

import { axiosInstance } from '../../../../../../core/api/axiosInstance';
import { DataFrameDef } from '../../../../../../model/definitions/DataFrameDef';

const key = ['SATELLITE_LAYER_DATA'];
export const useGetSatelliteLayerData = (
  typeId: number,
  sourceId: number,
  bounds: [number, number, number, number],
) =>
  useQuery(
    [...key, ...bounds, sourceId],
    async (): Promise<Array<DataFrameDef>> => {
      const { data } = await axiosInstance.post('satellite-data/satellite-value', {
        typeId,
        sourceId,
        location: {
          leftLongitude: bounds[0],
          rightLongitude: bounds[1],
          upperLatitude: bounds[2],
          lowerLatitude: bounds[3],
        },
      });
      const result = data as Array<DataFrameDef>;
      return sortedUniqBy(
        result.sort((a, b) => a.timestamp - b.timestamp),
        'timestamp',
      );
    },
    {
      enabled: !!bounds[1],
    },
  );
