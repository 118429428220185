import { Button, ToggleSwitch } from 'flowbite-react';
import moment from 'moment';
import { useState } from 'react';
import { AiOutlineCaretDown, AiOutlineCaretUp, AiOutlinePlus } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';

import { useAddPointDateElement } from '../../../../core/api/elements/useAddPointDateElement';
import { getMaxZindex } from '../../../../helpers/timelineUtil';
import { C9ProjectDef } from '../../../../model/definitions/C9ProjectDef';
import { MapPanelDef } from '../../../../model/definitions/MapPanelDef';
import { SceneDef } from '../../../../model/definitions/SceneDef';
import { PointLabel } from '../../../../model/enums/PointLabel';
import { ActiveDef } from '../../../../store/slices/active-slice';
import { deleteCompositeIndicator } from '../../../../store/slices/project-slice';
import { selectActiveScene } from '../../../../store/slices/selectors';
import { RootState } from '../../../../store/store';
import { AddGeoModal } from '../../modals/AddGeoModal';
import { GeoPosterProperties } from '../mapLayersProperties/GeoPosterProperties';
import GridItem from '../shared/GridItem';
import GridWrapper from '../shared/GridWrapper';
//import GridActions from '../shared/GridActions';

type Props = {
  mapLayer: MapPanelDef;
};
export const GeoPoster = ({ mapLayer }: Props) => {
  const dispatch = useDispatch();
  const project = useSelector<RootState, C9ProjectDef>((state) => state.project.present.project);
  const { activeAspectRatio, activeScene, previewSize, activeDuration } = useSelector<
    RootState,
    ActiveDef
  >((state) => state.active);
  const activeSceneDef = useSelector<RootState, SceneDef | null>((state) =>
    selectActiveScene(state),
  );
  const { mutate: addDateLayer } = useAddPointDateElement();
  const [open, setOpen] = useState<boolean>(false);
  const [posterModal, setPosterModal] = useState<boolean>(false);
  const { geoPosters } = mapLayer;
  const obsDates = geoPosters.map((poster) =>
    poster.observedWDElements.map((obs) => obs.observedWDSource.utcDate),
  );
  const forecastDates = geoPosters.map((poster) =>
    poster.forecastWDElements.map((obs) => obs.forecastWDSource.utcDate),
  );
  const removeIndicator = (type: 'OBSERVED' | 'FORECAST') => {
    dispatch(deleteCompositeIndicator({ activeScene, mapId: mapLayer.id, type }));
  };
  const findCommonDate = (dates: Array<string>) => {
    if (dates.length === 0) {
      return false;
    }
    const firstDate = moment(dates[0]);

    // Check if all dates have the same day
    const hasCommonDay = dates.every((date) => moment(date).isSame(firstDate, 'day'));
    if (!hasCommonDay) {
      return false;
    }

    return firstDate.format('YYYY-MM-DD');
  };
  const commonDateObserved = findCommonDate(obsDates.flat());
  const commonDateForecast = findCommonDate(forecastDates.flat());
  const addObservedIndicator = () => {
    commonDateObserved &&
      addDateLayer({
        data: {
          name: commonDateObserved,
          description: `${mapLayer.name} observed composites date`,
          value: commonDateObserved,
        },
        aspectRatio: activeAspectRatio,
        previewSize,
        duration: activeDuration,
        zIndex: getMaxZindex(activeScene as string, project),
        groupId: `${mapLayer.id}_OBSERVED`,
        activeScene,
        pointLabel: PointLabel.DATE,
      });
  };
  const addForecastIndicator = () => {
    commonDateForecast &&
      addDateLayer({
        data: {
          name: commonDateForecast,
          description: `${mapLayer.name} forecast composites date`,
          value: commonDateForecast,
        },
        aspectRatio: activeAspectRatio,
        previewSize,
        duration: activeDuration,
        zIndex: getMaxZindex(activeScene as string, project),
        groupId: `${mapLayer.id}_FORECAST`,
        activeScene,
        pointLabel: PointLabel.DATE,
      });
  };

  const observedIndicatorDisableAdd = () =>
    !!activeSceneDef?.pointDates.find((date) => date.pointWDGroupId === `${mapLayer.id}_OBSERVED`);
  const forecastIndicatorDisableAdd = () =>
    !!activeSceneDef?.pointDates.find((date) => date.pointWDGroupId === `${mapLayer.id}_FORECAST`);
  return (
    <>
      <div
        className={`mb-2 subheader layer-header ${geoPosters?.length === 0 && 'layer-disabled'} ${
          open ? 'layer-header-active' : ''
        }`}
        onClick={() => setOpen(!open)}
      >
        <div className="layer-title">
          {open ? <AiOutlineCaretUp /> : <AiOutlineCaretDown />}
          <span>Composites</span>
        </div>
        <Button
          color="cyan"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setPosterModal(true);
          }}
        >
          <AiOutlinePlus /> Add Composite
        </Button>
      </div>
      {open && (
        <div className="prop-wrapper">
          <GridWrapper>
            <GridItem
              noBorderBg
              label={'Observed indicator'}
              item={
                <ToggleSwitch
                  checked={observedIndicatorDisableAdd()}
                  disabled={!commonDateObserved}
                  label={''}
                  onChange={(e) => (e ? addObservedIndicator() : removeIndicator('OBSERVED'))}
                />
              }
            />
            <GridItem
              noBorderBg
              label={''}
              item={
                <div className={'text-red-500'}>
                  {commonDateObserved ? null : 'Multiple composites have different dates'}
                </div>
              }
            />
            <GridItem
              noBorderBg
              label={'Forecast indicator'}
              item={
                <ToggleSwitch
                  checked={forecastIndicatorDisableAdd()}
                  disabled={!commonDateForecast}
                  label={''}
                  onChange={(e) => (e ? addForecastIndicator() : removeIndicator('FORECAST'))}
                />
              }
            />
            <GridItem
              noBorderBg
              label={''}
              item={
                <div className={'text-red-500'}>
                  {commonDateObserved ? null : 'Multiple composites have different dates'}
                </div>
              }
            />
          </GridWrapper>
          {geoPosters.map((item) => (
            <GeoPosterProperties item={item} key={item.id} />
          ))}
        </div>
      )}
      {posterModal && (
        <AddGeoModal
          timeControls={mapLayer.timeControls}
          opened={posterModal}
          onClose={() => setPosterModal(false)}
          onOk={() => setPosterModal(false)}
        />
      )}
    </>
  );
};
