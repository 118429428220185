import 'react-toastify/dist/ReactToastify.css';
import '../style/userpage.scss';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { List } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { BiPlus } from 'react-icons/bi';
import { RiArrowDownSLine, RiArrowRightSLine } from 'react-icons/ri';
import { useParams } from 'react-router-dom';

import Button from '../../marketplace-new/atoms/Button/AntButton';
import { useAddCard } from '../../marketplace-new/hooks/payment/useAddCard';
import { useCancelTransaction } from '../../marketplace-new/hooks/payment/useCancelTransaction';
import { useChargeCard } from '../../marketplace-new/hooks/payment/useChargeCard';
import { useGetListOfCards } from '../../marketplace-new/hooks/payment/useGetListOfCards';
import { useGetTransactions } from '../../marketplace-new/hooks/payment/useGetTransactions';
import { useRemoveCard } from '../../marketplace-new/hooks/payment/useRemoveCard';
import { useGetCurrency } from '../../marketplace-new/hooks/useGetCurrency';
import Price from '../../marketplace-new/molecules/Price/Price';
import IChargeCardRequest from '../types/IChargeCardRequest.type';
import ITransaction from '../types/ITransaction.type';
import AddPaymentModal from './AddPaymentMethodModal';
import OneTimePayment from './OneTimePayment';

let stripePromise: Stripe | PromiseLike<Stripe | null> | null;
if (process.env.REACT_APP_STRIPE_PUBLIC_API_KEY) {
  stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_API_KEY);
}

export default function UserPage() {
  useParams();
  const { data: transactions, refetch: refetchTransactions } = useGetTransactions();
  const { data: savedCards, refetch: refetchCards } = useGetListOfCards();
  const { data: newCard, mutate: addPaymentMethod } = useAddCard();
  const { data: removedCard, mutate: removePaymentMethod } = useRemoveCard();
  const { mutate: cancelTransaction } = useCancelTransaction();
  const { mutate: chargeCard } = useChargeCard();
  const { data: currency } = useGetCurrency();
  const [isShown, setIsShown] = useState<boolean>(false);
  const [activities, setActivities] = useState<boolean>(false);
  const [card, setCard] = useState<{ id: string | null; fingerprint: string | null | undefined }>({
    id: null,
    fingerprint: null,
  });
  const [options, setOptions] = useState<{
    clientSecret: string | null;
    appearance?: { theme: string };
  }>({
    clientSecret: null,
    appearance: { theme: '' },
  });

  useEffect(() => {
    if (newCard) {
      setOptions({
        // @ts-ignore
        clientSecret: newCard.data.clientSecret,
      });
      setIsShown(!isShown);
    }
  }, [newCard]);

  useEffect(() => {
    if (removedCard) {
      setCard({ id: null, fingerprint: null });
    }
  }, [removedCard]);

  function statusCheck(status: string) {
    return status === 'requires_payment_method';
  }

  function getStyle(transaction: ITransaction) {
    switch (transaction.status) {
      case 'succeeded':
        return { background: '#cae6ad' };
      case 'canceled':
        return { background: '#dbb0bc' };
    }
  }

  const makePayment = (id: string | undefined, card: any) => {
    const chargeCardReq: IChargeCardRequest = {
      transactionId: id,
      fingerprint: card.fingerprint,
    };

    chargeCard(chargeCardReq!);
  };

  const appearance = {
    theme: 'stripe',
  };

  const oneTimePayment = (clientSecret: string) => {
    setIsShown(true);
    setOptions({
      clientSecret: clientSecret,
      appearance,
    });
  };
  return (
    <>
      <div className="flex justify-between items-center mb-16">
        <div>
          <h1>Your cards</h1>
          <p className="text-gray-500 font-semibold text-lg">
            You have {savedCards?.length} active card{savedCards && savedCards?.length > 1 && 's'}
          </p>
        </div>
        <div className="flex">
          <Button
            type={activities ? 'default' : 'primary'}
            className="mr-4"
            onClick={() => {
              setActivities(false);
              setIsShown(false);
              setOptions({ clientSecret: null, appearance: { theme: '' } });
            }}
          >
            Card
          </Button>
          <Button
            type={activities ? 'primary' : 'default'}
            onClick={() => {
              setActivities(true);
              setIsShown(false);
            }}
          >
            Activities
          </Button>
        </div>
      </div>
      <div className="container-user text-gray-500">
        <div
          className={`w-2/3 ${options.clientSecret && activities && isShown ? 'block' : 'hidden'}`}
        >
          {options.clientSecret && activities && isShown && (
            // @ts-ignore
            <Elements options={options} stripe={stripePromise}>
              <OneTimePayment
                clientSecret={options.clientSecret}
                setIsShown={setIsShown}
                getTransactions={refetchTransactions}
              />
            </Elements>
          )}
        </div>

        <div className={`w-2/3 ${!card.id && activities && 'hidden'}`}>
          <List
            className="mb-10"
            dataSource={savedCards}
            renderItem={(item) => {
              if (item.id === card.id && activities) {
                return (
                  <List.Item key={item.id}>
                    <List.Item.Meta
                      title={item.card?.brand.toUpperCase()}
                      description={`************${item.card?.last4}`}
                      avatar={<img src={`/cards/${item.card?.brand}.png`} height="50" width="50" />}
                    />
                  </List.Item>
                );
              } else if (!activities) {
                return (
                  <List.Item key={item.id}>
                    <List.Item.Meta
                      title={item.card?.brand.toUpperCase()}
                      description={`************${item.card?.last4}`}
                      avatar={<img src={`/cards/${item.card?.brand}.png`} height="50" width="50" />}
                    />
                    {card.id === item.id ? (
                      <RiArrowDownSLine
                        size={24}
                        color="#2176ff"
                        onClick={() => setCard({ id: null, fingerprint: null })}
                      />
                    ) : (
                      <RiArrowRightSLine
                        size={24}
                        color="rgb(107 114 128 / 1)"
                        onClick={() =>
                          setCard({ id: item.id, fingerprint: item.card?.fingerprint })
                        }
                      />
                    )}
                  </List.Item>
                );
              }
            }}
          />
          {isShown && (
            <AddPaymentModal
              setIsShown={setIsShown}
              options={options}
              getPaymentMethods={refetchCards}
            />
          )}
          {!activities && (
            <Button
              onClick={() => addPaymentMethod()}
              type="primary"
              className="flex mt-10 content-center w-[200px]"
              icon={<BiPlus className="inline-block" size={24} />}
            >
              Add new card
            </Button>
          )}
        </div>
        {activities ? (
          <div className="col-md-9 table-component w-full">
            {transactions && transactions.length > 0 && (
              <table className="table-auto">
                <thead>
                  <tr>
                    <th className="px-4 py-2">Order id</th>
                    <th className="px-4 py-2">Amount</th>
                    <th className="px-4 py-2">Time</th>
                    <th className="px-4 py-2">Status</th>
                    <th className="px-4 py-2">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {transactions?.map((transaction: ITransaction, k: any) => {
                    return (
                      <tr key={k} style={getStyle(transaction)}>
                        <td className="border px-4 py-2">{transaction.orderId}</td>
                        <td className="border px-4 py-2">
                          {currency && transaction.amount && (
                            <Price currency={currency} price={transaction.amount} />
                          )}
                        </td>
                        <td className="border px-4 py-2">
                          {moment(transaction.createdDate).format('DD.MM.YYYY').toString()}
                        </td>
                        <td className="border px-4 py-2">{transaction.status}</td>
                        <td className="border px-4 py-2">
                          {statusCheck(transaction.status!) ? (
                            <p style={{ display: 'flex', gap: '5px' }}>
                              <Button
                                className="bg-[green] !text-white border-[green]"
                                onClick={() =>
                                  card.id
                                    ? makePayment(transaction.id, card)
                                    : oneTimePayment(transaction.clientSecret)
                                }
                              >
                                Continue
                              </Button>
                              <Button
                                danger
                                className="bg-[#ff4d4f] !text-white"
                                onClick={() => cancelTransaction(transaction.id)}
                              >
                                Cancel
                              </Button>
                            </p>
                          ) : (
                            <span></span>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        ) : (
          <div className="saved-cards">
            {savedCards
              ?.filter((savedCard) => savedCard.id === card.id)
              ?.map((d: any, k: any) => {
                return (
                  <div key={k}>
                    <div className="col-md-12 mb-8">
                      <div className={'credit-card  selectable ' + d.card?.brand}>
                        <div className="credit-card-last4">{d.card?.last4}</div>
                        <div className="credit-card-expiry">
                          {d.card?.expMonth}/{d.card?.expYear}
                        </div>
                      </div>
                      <div>
                        <p className="text-black text-lg">{d.card?.brand.toUpperCase()}</p>
                        <p>************{d.card?.last4}</p>
                      </div>
                      <Button
                        danger
                        className="bg-[#ff4d4f] !text-white mt-2"
                        onClick={() => removePaymentMethod(d.id)}
                        style={{ width: 250 }}
                      >
                        Remove a card
                      </Button>
                    </div>
                    <hr />
                    <div className="py-8">
                      <p className="font-bold text-lg">Expiration date</p>
                      <p>
                        {d.card?.expMonth}/{d.card?.expYear}
                      </p>
                    </div>
                    <hr />
                    {d.billingDetails?.addres?.city && (
                      <div>
                        <p className="font-bold text-lg mt-8">Billing address</p>
                        <p>{d.billingDetails.address.line1}</p>
                        <p>{d.billingDetails.address.line2}</p>
                        <p>
                          {d.billingDetails.address.postalCode} {d.billingDetails.address.city}
                        </p>
                        <p>{d.billingDetails.address.state}</p>
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </>
  );
}
