import { Map } from 'ol';
import GeoJSON from 'ol/format/GeoJSON';
import { Select, Translate } from 'ol/interaction';
import { SelectEvent } from 'ol/interaction/Select';
import { TranslateEvent } from 'ol/interaction/Translate';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { StyleLike } from 'ol/style/Style';
import { useEffect } from 'react';

import { imageToBlob } from '../../core/api/MultimediaAPI';
import { MapPanelDef } from '../../model/definitions/MapPanelDef';

const imageCache: { [key: string]: string } = {};

export const useCityPosterLayers = (
  mapRef: React.MutableRefObject<Map | null>,
  panelProps: MapPanelDef,
  cityStyle: (previewSize: number, activeAspectRatio: [number, number]) => StyleLike,
  cityStyleSelect: (previewSize: number, activeAspectRatio: [number, number]) => StyleLike,
  MAX_FULLSCREEN_HEIGHT: number,
  activeAspectRatio: [number, number],
  translateRef: React.MutableRefObject<Translate | null>,
  citySelectRef: React.MutableRefObject<Select | null>,
  onEnd: (props: TranslateEvent) => void,
  onSelect: (props: SelectEvent) => void,
) => {
  useEffect(() => {
    const removeExistingCityLayers = () => {
      const allLayers = mapRef.current?.getAllLayers();
      const cityLayers = allLayers?.filter((l) => l.getClassName() === 'cities-layer');
      cityLayers?.forEach((layer) => {
        mapRef.current?.removeLayer(layer);
        layer.dispose();
      });
    };

    const fetchCityPosterLayer = async () => {
      const geojsonObject = {
        type: 'FeatureCollection',
        features: panelProps.showCities
          ? panelProps.cityPosters.map((p) => {
              return {
                type: 'Feature',
                properties: {
                  label: p.textPanels[0].value,
                  ...p.anchor,
                  cityPosterId: p.id,
                  ...p.textPanels[0],
                },
                geometry: {
                  type: 'Point',
                  coordinates: [p.anchor.longitude, p.anchor.latitude],
                },
              };
            })
          : [],
      };

      removeExistingCityLayers();

      if (geojsonObject.features.length === 0) {
        return;
      }

      try {
        const processedFeatures = await Promise.all(
          geojsonObject.features.map(async (feature) => {
            const isImage = feature.properties.type === 'image';

            const imageURL = feature.properties.imageURL;
            if (isImage && imageURL) {
              if (imageCache[imageURL]) {
                feature.properties.imageURL = imageCache[imageURL];
                return feature;
              } else {
                try {
                  const blob = await imageToBlob(imageURL);
                  if (blob) {
                    imageCache[imageURL] = blob;
                    feature.properties.imageURL = blob;
                    return feature;
                  }
                } catch (error) {
                  console.error('Error fetching image blob:', error);
                  return feature;
                }
              }
            } else {
              return feature;
            }
          }),
        );

        const vectorSource = new VectorSource({
          features: new GeoJSON().readFeatures(
            { type: 'FeatureCollection', features: processedFeatures },
            {
              dataProjection: 'EPSG:4326',
              featureProjection: mapRef.current?.getView().getProjection(),
            },
          ),
        });

        const vectorLayer = new VectorLayer({
          source: vectorSource,
          zIndex: panelProps.cityPosters[0]?.positionControl?.zindex,
          style: cityStyle(MAX_FULLSCREEN_HEIGHT, activeAspectRatio),
          className: 'cities-layer',
          declutter: true,
          updateWhileAnimating: true,
          updateWhileInteracting: true,
        });

        mapRef.current?.addLayer(vectorLayer);

        if (translateRef.current) {
          mapRef.current?.removeInteraction(translateRef.current);
          translateRef.current?.dispose();
        }
        if (citySelectRef.current) {
          mapRef.current?.removeInteraction(citySelectRef.current);
          citySelectRef.current.dispose();
        }

        if (!panelProps.lockCitiesPosition) {
          citySelectRef.current = new Select({
            hitTolerance: 2,
            style: cityStyleSelect(MAX_FULLSCREEN_HEIGHT, activeAspectRatio),
            layers: (props) => {
              return props.getClassName() === 'cities-layer';
            },
          });
          translateRef.current = new Translate({
            features: citySelectRef.current.getFeatures(),
          });
          mapRef.current?.addInteraction(citySelectRef.current);
          mapRef.current?.addInteraction(translateRef.current);
          translateRef.current!.on('translateend', onEnd);
          citySelectRef.current!.on('select', onSelect);
        }
      } catch (error) {
        console.error('Error processing features:', error);
      }
    };

    if (panelProps.showCities) {
      fetchCityPosterLayer();
    } else {
      removeExistingCityLayers();
    }
  }, [panelProps.cityPosters, panelProps.lockCitiesPosition, panelProps.showCities]);
};
