import { PaymentIntent } from '@stripe/stripe-js';
import { useMutation } from 'react-query';

import { axiosInstance } from '../../../../core/api/axiosInstance';

export const useAddCard = () => {
  return useMutation(async () => {
    return await axiosInstance.post<PaymentIntent>('payment/user/add-payment-method');
  });
};
