import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { queryClient } from '../../..';
import { axiosInstance } from '../../../core/api/axiosInstance';
import { key } from './useGetDataProductFiles';

export const useAddDataProductFiles = () => {
  return useMutation(
    async (files: FormData) => {
      const { data } = await axiosInstance.post('data-product-files', files, {
        headers: { 'content-type': 'multipart/form-data' },
      });
      return data;
    },
    {
      onSuccess: () => {
        toast.success('Successfully added data product file!');
        queryClient.invalidateQueries(key);
      },
      onError: () => {
        toast.error('There was an error while adding data product file. Please try again.');
      },
    },
  );
};
