import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripePaymentElementOptions } from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useConfirmTransaction } from '../../marketplace-new/hooks/payment/useConfirmTransaction';
import { useGetCurrency } from '../../marketplace-new/hooks/useGetCurrency';
import Price from '../../marketplace-new/molecules/Price/Price';

interface OneTimePaymentInterface {
  clientSecret: string;
  amount?: number;
  setIsShown?: React.Dispatch<React.SetStateAction<boolean>>;
  getTransactions?: () => void;
}

export default function OneTimePayment({
  clientSecret,
  amount,
  setIsShown,
  getTransactions,
}: OneTimePaymentInterface) {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { data: confirmedTransaction, mutate: confirmTransaction } = useConfirmTransaction();
  const { data: currency } = useGetCurrency();
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (!stripe) {
      return;
    }

    if (!clientSecret) {
      return;
    }
  }, [stripe]);

  useEffect(() => {
    if (confirmedTransaction) {
      setIsShown && setIsShown(false);
      getTransactions && getTransactions();
    }
    if (confirmedTransaction && location.pathname.includes('checkout')) {
      navigate('/marketplace/confirm');
    }
  }, [confirmedTransaction]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    stripe
      .confirmPayment({
        elements,
        confirmParams: {
          // Return URL where the customer should be redirected after the PaymentIntent is confirmed.
          return_url: process.env.REACT_APP_BASE_URL + '/marketplace/confirm',
        },
        redirect: 'if_required',
      })
      .then(function (result) {
        if (result.paymentIntent) {
          confirmTransaction({
            paymentIntentId: result.paymentIntent.id,
            paymentIntentSecret: result.paymentIntent.client_secret,
            redirectStatus: result.paymentIntent.status,
          });
        }
        if (result?.error?.message) {
          setMessage(result.error.message);
          toast.error(result.error.message);
        }
        setIsLoading(false);
      });
  };

  const paymentElementOptions: StripePaymentElementOptions = {
    layout: 'tabs',
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <button
        disabled={isLoading || !stripe || !elements}
        id="submit"
        className="block bg-[#192552] text-white text-2xl w-full py-3 rounded-lg"
      >
        <div id="button-text">
          {amount ? (
            <p className="">Pay {currency && <Price currency={currency} price={amount} />}</p>
          ) : (
            'Pay'
          )}
        </div>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}
