import { useQuery } from 'react-query';

import { axiosInstance } from '../../../core/api/axiosInstance';

export const key = ['SCHEDULERS'];
export const useGetSchedulers = (
  pagination: { page: number; size: number },
  projectId?: string,
) => {
  return useQuery([...key, pagination, projectId], async () => {
    const url = projectId
      ? `scheduler/scheduler_task/all-by-project/${projectId}?page=${pagination.page - 1}&size=${
          pagination.size
        }`
      : `scheduler/scheduler_task/by-user?page=${pagination.page - 1}&size=${pagination.size}`;
    const res = await axiosInstance.get(url);
    return res.data;
  });
};
