import { DatePicker } from 'antd';
import { ApexOptions } from 'apexcharts';
import dayjs from 'dayjs';
import ReactApexChart from 'react-apexcharts';

import LoadingIndicator from '../../../atoms/loadingIndicator/LoadingIndicator';

const { RangePicker } = DatePicker;

interface Props {
  title: string;
  dateRange: [string | null, string | null];
  options: ApexOptions;
  dataSeries: ApexAxisChartSeries;
  isLoading: boolean;
  handleCalendarChange: (values: any) => void;
  setDateRange: (value: [string | null, string | null]) => void;
}

function Chart(props: Props) {
  const { title, dateRange, options, dataSeries, isLoading, handleCalendarChange, setDateRange } =
    props;
  return (
    <div className="w-full">
      <p className="text-[#06152b] opacity-75 text-lg font-semibold mb-1">{title}</p>
      <RangePicker
        showTime
        value={[
          dateRange[0] !== null ? dayjs(dateRange[0]) : null,
          dateRange[1] !== null ? dayjs(dateRange[1]) : null,
        ]}
        onChange={(values, formatString) => {
          if (values) {
            setDateRange(formatString);
          }
        }}
        className="mb-4"
        onCalendarChange={handleCalendarChange}
      />
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <ReactApexChart options={options} series={dataSeries} type="bar" />
      )}
    </div>
  );
}

export default Chart;
