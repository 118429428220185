import '../style/payment.scss';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';

import SetupForm from './SetupForm';

let stripePromise: Stripe | PromiseLike<Stripe | null> | null;
if (process.env.REACT_APP_STRIPE_PUBLIC_API_KEY) {
  stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_API_KEY);
}

interface AddPaymentMethodInterface {
  setIsShown: React.Dispatch<React.SetStateAction<boolean>>;
  options: object;
  getPaymentMethods: () => void;
}
export default function AddPaymentMethodModalComponent({
  setIsShown,
  options,
  getPaymentMethods,
}: AddPaymentMethodInterface) {
  return (
    <>
      <div>
        <Elements stripe={stripePromise} options={options}>
          <SetupForm setIsShown={setIsShown} getPaymentMethods={getPaymentMethods} />
        </Elements>
      </div>
    </>
  );
}
