import React from 'react';
import { AiOutlineAudio, AiOutlineFileImage, AiOutlineVideoCameraAdd } from 'react-icons/ai';
import { Link, useLocation } from 'react-router-dom';

import styles from './MenuItem.module.scss';

interface Props {
  to: string;
  label: string;
  iconSrc: string;
  type?: string;
}

const MenuItem: React.FC<Props> = ({ to, label, iconSrc, type }) => {
  const { pathname } = useLocation();
  const active = pathname?.includes(to);
  return (
    <div
      className={`flex items-center ${styles['menu-wrraper']} ${
        active ||
        (pathname === '/' && label === 'Dashboard') ||
        (pathname === '/marketplace' && label === 'Projects')
          ? styles['active-link']
          : ''
      }`}
    >
      {/* refactor this */}
      <Link className={`${styles['menu-item']} ml-2`} to={to}>
        {!type && <img src={iconSrc} alt="icon" className={`${styles.img}`} />}{' '}
        {type === 'image' && <AiOutlineFileImage fill="#B3B3B3" />}
        {type === 'video' && <AiOutlineVideoCameraAdd fill="#B3B3B3" />}
        {type === 'audio' && <AiOutlineAudio fill="#B3B3B3" />}
        {label}
      </Link>
    </div>
  );
};

export default MenuItem;
