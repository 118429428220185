import type { UploadProps } from 'antd';
import { Button, message, Upload } from 'antd';

import { _kc } from '../../../../core/auth/KeycloakService';

interface CitiesImageInputProps {
  value: string | null;
  onChange: (v: string | null) => void;
}

export const CitiesImageInput = (props: CitiesImageInputProps) => {
  const uploadProps: UploadProps = {
    accept: 'image/png',
    action: `${process.env.REACT_APP_API_BASE_URL}/api/store/multimedia/uploadFileOnCDN`,
    headers: {
      authorization: 'Bearer ' + _kc.token,
    },
    beforeUpload: (file) => {
      const isPNG = file.type === 'image/png';
      if (!isPNG) {
        message.error(`${file.name} is not a png file`);
      }
      return isPNG || Upload.LIST_IGNORE;
    },
    onChange: async (info) => {
      if (info.fileList.length > 0) {
        props.onChange(info.fileList[0].response);
      } else {
        props.onChange(null);
      }
    },
  };

  return (
    <>
      <Upload
        {...uploadProps}
        itemRender={() => {
          return <></>;
        }}
      >
        <Button className="!h-auto">Choose file</Button>
      </Upload>
      <div className="mx-5 border border-gray-700 bg-gray-500 p-2">
        {props.value ? <img className="max-w-[24px] max-h-[24px]" src={props.value} /> : null}
      </div>
    </>
  );
};
