import { FileAddOutlined, InboxOutlined } from '@ant-design/icons';
import { Form, Input, Select, Tooltip, Upload } from 'antd';
import { RcFile, UploadFile } from 'antd/es/upload';
import { AiOutlineDelete } from 'react-icons/ai';

import { DataProductFromFileEnum } from '../../../model/enums/DataProductFromFileEnum';
import { DataProductTargetTypeEnum } from '../../../model/enums/DataProductTargetTypeEnum';
import { DataProductTypeEnum } from '../../../model/enums/DataProductTypeEnum';
import { FormErrors } from '../organisms/Steps';
import { DataProductInterface } from '../pages/Products';

const { TextArea } = Input;

export const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

interface BasicInfoInterface {
  form: DataProductInterface;
  setForm: React.Dispatch<React.SetStateAction<DataProductInterface>>;
  thumbnailFiles: RcFile[];
  setThumbnailFiles: React.Dispatch<React.SetStateAction<RcFile[]>>;
  csvForecastFile?: RcFile;
  setCsvForecastFile: React.Dispatch<React.SetStateAction<RcFile | undefined>>;
  errors: FormErrors;
  setErrors: React.Dispatch<React.SetStateAction<FormErrors>>;
  orthoType: string;
  setOrthoType: React.Dispatch<React.SetStateAction<string>>;
  projType: string;
  setProjType: React.Dispatch<React.SetStateAction<string>>;
}

const GeneralInfo = ({
  form,
  setForm,
  thumbnailFiles,
  setThumbnailFiles,
  csvForecastFile,
  setCsvForecastFile,
  errors,
  setErrors,
  orthoType,
  setOrthoType,
  projType,
  setProjType,
}: BasicInfoInterface) => {
  const handleParamsSelect = (e: string) => {
    setForm({ ...form, timeToBeReadFrom: e });
  };
  const handleParamsChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (errors.proj || errors.ortho) {
      setErrors({ ...errors, [e.target.name]: '' });
    }
    setForm({ ...form, params: { ...form.params, [e.target.name]: e.target.value } });
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleProductTypeChange = (e: string) => {
    setForm({
      ...form,
      productType: e,
    });
  };

  const handleType = (e: string[]) => {
    setForm({ ...form, types: e });
  };

  const beforeUpload = (_: RcFile, files: RcFile[]) => {
    setThumbnailFiles([...thumbnailFiles, ...files]);
    return false;
  };

  const handleDeleteFile = (e: UploadFile<any>) => {
    const filterFiles = thumbnailFiles.filter((file) => file.uid !== e.uid);
    setThumbnailFiles([...filterFiles]);
  };

  const beforeLocationUpload = (file: RcFile) => {
    setCsvForecastFile(file);
    return false;
  };

  const handleLocationDeleteFile = () => {
    setCsvForecastFile(undefined);
  };

  const handleParamsType = (type: string, e: string) => {
    type === 'ortho' ? setOrthoType(e) : setProjType(e);
  };

  return (
    <>
      <Form.Item
        label="Base Name"
        required
        validateStatus={!form.baseName && errors.baseName ? 'error' : ''}
        help={!form.baseName && errors.baseName}
      >
        <Input name="baseName" value={form.baseName} onChange={(e) => handleChange(e)} />
        <p className="text-gray-500 mt-1 text-sm">
          Will be applied retroactively, and automatically
        </p>
      </Form.Item>
      <Form.Item
        label="Product type"
        required
        validateStatus={!form.productType && errors.productType ? 'error' : ''}
        help={!form.productType && errors.productType}
      >
        <Select
          value={form.productType}
          options={Object.entries(DataProductTypeEnum).map((o) => {
            return {
              value: o[0],
              label: o[1],
            };
          })}
          disabled={!!form.id}
          onChange={(e) => handleProductTypeChange(e)}
        />
      </Form.Item>
      {form.productType && (
        <>
          {form.productType === 'MODEL' && (
            <Form.Item
              label="Product Name"
              required
              validateStatus={!form.name && errors.name ? 'error' : ''}
              help={!form.name && errors.name}
            >
              <Input name="name" value={form.name} onChange={(e) => handleChange(e)} />
              <p className="text-gray-500 mt-1 text-sm">
                Will be applied retroactively, and automatically
              </p>
            </Form.Item>
          )}

          <Form.Item label="Product Description">
            <TextArea
              name="description"
              value={form.description}
              rows={4}
              onChange={(e) => handleChange(e)}
            />
          </Form.Item>
          <Form.Item label="Product thumbnails:">
            <Form.Item valuePropName="thumbnailFiles" getValueFromEvent={normFile} noStyle>
              <Upload.Dragger
                name="files"
                multiple
                accept="image/*"
                beforeUpload={(_, files: RcFile[]) => beforeUpload(_, files)}
                onRemove={(e) => handleDeleteFile(e)}
                fileList={thumbnailFiles}
                iconRender={() => {
                  return <></>;
                }}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag files to this area to upload</p>
                <p className="ant-upload-hint">Support for a single or bulk upload.</p>
              </Upload.Dragger>
            </Form.Item>
          </Form.Item>
          {form.productType === DataProductTypeEnum.FORECAST && (
            <Form.Item
              label="Location file:"
              validateStatus={!csvForecastFile && errors.forecastLocationFile ? 'error' : ''}
              help={!csvForecastFile && errors.forecastLocationFile}
            >
              <Form.Item valuePropName="forecastLocationFile" getValueFromEvent={normFile} noStyle>
                <Upload.Dragger
                  name="forecastLocationFile"
                  accept=".csv"
                  beforeUpload={(file: RcFile) => beforeLocationUpload(file)}
                  onRemove={(e) => handleLocationDeleteFile()}
                  iconRender={() => {
                    return <></>;
                  }}
                >
                  <p className="ant-upload-drag-icon">
                    <FileAddOutlined />
                  </p>
                  <p className="ant-upload-text">Click or drag file to this area to upload</p>
                  <p className="ant-upload-hint">Support CSV file only.</p>
                </Upload.Dragger>
                {!csvForecastFile && form.forecastLocationFile && (
                  <div className="flex items-center justify-center mt-2">
                    <span className="mr-1">{form.forecastLocationFile.name}</span>
                    <Tooltip title="Delete file">
                      <AiOutlineDelete
                        onClick={() => {
                          setForm((prev) => ({ ...prev, forecastLocationFile: undefined }));
                        }}
                        size={18}
                        className="text-gray-500 cursor-pointer"
                      />
                    </Tooltip>
                  </div>
                )}
                <pre className="bg-gray-200 px-4 py-2 rounded-lg w-[100%] my-4">
                  <code className="text-gray-800 text-sm">
                    <p className="text-lg">Header is required for Location file</p>
                    <p className="text-base">
                      Columns order: Name, State, Country, Longitude, Latitude, Timezone, LocationId
                    </p>
                  </code>
                </pre>
              </Form.Item>
            </Form.Item>
          )}
          {form.productType === DataProductTypeEnum.MODEL && (
            <Form.Item label="Orthographic type" className="mb-0">
              <Select
                value={orthoType}
                onChange={(e) => handleParamsType('ortho', e)}
                options={[
                  { label: 'File', value: 'file' },
                  { label: 'Predefined', value: 'predefined' },
                ]}
              />
              <p className="text-gray-500 mt-1 text-sm mb-7">
                Will be applied to the new ingested files
              </p>
            </Form.Item>
          )}
          {(form.productType === DataProductTypeEnum.SATELLITE ||
            form.productType === DataProductTypeEnum.RADAR ||
            orthoType === 'predefined') && (
            <Form.Item
              label="Orthographic"
              required
              validateStatus={errors.ortho ? 'error' : ''}
              help={errors.ortho}
            >
              <Input
                name="ortho"
                value={form.params.ortho}
                placeholder="x_min x_max y_min y_max"
                onChange={(e) => handleParamsChange(e)}
              />
              {form.productType !== 'MODEL' && (
                <p className="text-gray-500 mt-1 text-sm">
                  Will be applied to the new ingested files
                </p>
              )}
            </Form.Item>
          )}
          {form.productType === 'MODEL' && (
            <Form.Item label="Projection type" className="mb-0">
              <Select
                value={projType}
                onChange={(e) => handleParamsType('proj', e)}
                options={[
                  { label: 'File', value: 'file' },
                  { label: 'Predefined', value: 'predefined' },
                ]}
              />
              <p className="text-gray-500 mt-1 text-sm mb-7">
                Will be applied to the new ingested files
              </p>
            </Form.Item>
          )}
          {(form.productType === DataProductTypeEnum.SATELLITE ||
            form.productType === DataProductTypeEnum.RADAR ||
            projType === 'predefined') && (
            <Form.Item
              label="Projection"
              required
              validateStatus={errors.proj ? 'error' : ''}
              help={errors.proj}
            >
              <Input
                name="proj"
                value={form.params.proj}
                placeholder="PROJ.4 string"
                onChange={(e) => handleParamsChange(e)}
              />
              {form.productType !== 'MODEL' && (
                <p className="text-gray-500 mt-1 text-sm">
                  Will be applied to the new ingested files
                </p>
              )}
            </Form.Item>
          )}
          {form.productType === 'MODEL' && (
            <Form.Item label="Time to be read from">
              <Select
                value={form.timeToBeReadFrom}
                options={Object.entries(DataProductFromFileEnum).map((o) => {
                  return {
                    value: o[0],
                    label: o[1],
                  };
                })}
                onChange={(e) => handleParamsSelect(e)}
              />
              <p className="text-gray-500 mt-1 text-sm">
                Will be applied to the new ingested files
              </p>
            </Form.Item>
          )}
          {form.productType === 'SATELLITE' && (
            <Form.Item label="Types">
              <Select
                value={form.types}
                mode="multiple"
                allowClear
                options={Object.entries(DataProductTargetTypeEnum).map((o) => {
                  return {
                    value: o[0],
                    label: o[1],
                  };
                })}
                onChange={(e) => handleType(e)}
              />
              <p className="text-gray-500 mt-1 text-sm">
                Will be applied to the new ingested files
              </p>
            </Form.Item>
          )}
        </>
      )}
    </>
  );
};

export default GeneralInfo;
