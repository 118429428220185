import { InboxOutlined } from '@ant-design/icons';
import { Alert, Form, Table, Tag, Tooltip, Upload } from 'antd';
import { RcFile } from 'antd/es/upload';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { AiOutlineDelete, AiOutlineEye, AiOutlineFileSync } from 'react-icons/ai';
import { Triangle } from 'react-loader-spinner';

import FileValidationModal from '../atoms/FileValidationModal';
import { useAddDataProductFiles } from '../hooks/useAddDataProductFiles';
import { useDeleteDataProductFile } from '../hooks/useDeleteDataProductFile';
import { useGetDataProductFiles } from '../hooks/useGetDataProductFiles';
import { useGetStorageLocation } from '../hooks/useGetStorageLocation';
import { useValidateFile } from '../hooks/useValidateFile';
import { DataProductInterface } from '../pages/Products';
import { normFile } from './GeneralInfo';

interface ValidationFilesInterface {
  form: DataProductInterface;
}

export interface DataProductFilesInterface {
  id: number;
  filename: string;
  status: string;
  phase: string;
  description: string;
  created_at: number;
  updated_at: number;
}

function ValidationFiles({ form }: ValidationFilesInterface) {
  const [isRefetching, setIsRefetching] = useState(false);
  const { data, refetch } = useGetDataProductFiles(isRefetching, form?.id);
  const { mutate: addProductFilesMutation, isLoading } = useAddDataProductFiles();
  const { mutate: deleteFileMutation } = useDeleteDataProductFile();
  const { data: storageLocation } = useGetStorageLocation(form?.id);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [fileResultId, setFileResultId] = useState<number>();
  const [revalidateId, setRevalidateId] = useState<number | undefined>();
  const { isSuccess: onValidateSuccess } = useValidateFile(revalidateId);
  useEffect(() => {
    setIsRefetching(true);
  }, []);
  const beforeUpload = (file: RcFile) => {
    const validationFilesFormData = new FormData();
    file && validationFilesFormData.append('file', file);
    if (form.id) {
      validationFilesFormData.append('id', form.id);
    }
    addProductFilesMutation(validationFilesFormData, {
      onSuccess() {
        setIsRefetching(true);
      },
    });

    return false;
  };

  const handleFileResult = (id: number) => {
    setFileResultId(id);
    setIsOpen(true);
  };

  function removeWordAndBefore(inputString: string, targetWord: string) {
    const index = inputString.indexOf(targetWord);
    if (index !== -1) {
      return inputString.slice(index + targetWord.length);
    }
    return inputString;
  }

  const columns = [
    {
      title: 'File name',
      dataIndex: 'filename',
      key: 'filename',
      width: 500,
      render: (_: string, record: DataProductFilesInterface) => (
        <span>{removeWordAndBefore(record.filename, 'validation/')}</span>
      ),
    },
    {
      title: 'Test date',
      dataIndex: 'updated_at',
      key: 'updated_at',
      render: (_: number, record: DataProductFilesInterface) => (
        <span>{moment(record.updated_at).format('DD-MM-YYYY, h:mm:ss a')}</span>
      ),
    },
    {
      title: 'Phase',
      dataIndex: 'phase',
      key: 'phase',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_: string, record: DataProductFilesInterface) => (
        <Tooltip title={record.status === 'ERROR' ? record.description : ''}>
          <Tag
            color={
              record.status && record.status === 'DONE'
                ? 'success'
                : record.status === 'ERROR'
                ? 'error'
                : ''
            }
          >
            {record.status}
          </Tag>
        </Tooltip>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text: string, record: DataProductFilesInterface) => (
        <div key={record.id} className="flex">
          {record.status === 'DONE' && (
            <Tooltip title="Results">
              <AiOutlineEye
                onClick={() => handleFileResult(record.id)}
                size={18}
                className="text-gray-500 cursor-pointer"
              />
            </Tooltip>
          )}
          <Tooltip title="Retry">
            <AiOutlineFileSync
              size={18}
              className="cursor-pointer"
              onClick={() => setRevalidateId(record.id)}
            />
          </Tooltip>
          <Tooltip title="Delete file">
            <AiOutlineDelete
              onClick={() =>
                deleteFileMutation(record.id, {
                  onSuccess() {
                    setIsRefetching(true);
                  },
                })
              }
              size={18}
              className="text-gray-500 cursor-pointer"
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (data) {
      const find = data.find(
        (d: DataProductFilesInterface) =>
          d.status === 'IN_PROGRESS' || (d.status === 'DONE' && d.phase !== 'LOAD'),
      );
      if (find) {
        setIsRefetching(true);
      } else {
        setIsRefetching(false);
      }
    }
  }, [refetch, data, onValidateSuccess]);

  useEffect(() => {
    if (onValidateSuccess) {
      setIsRefetching(true);
      setRevalidateId(undefined);
    }
  }, [onValidateSuccess]);

  return (
    <>
      {storageLocation && (
        <Alert
          className="mb-4 select-text"
          description={
            <div className="text-left">
              <p>
                To directly upload files to the studio, for the data product, please use the
                following location on S3:
              </p>
              <ul className="list-disc list-inside">
                <li>
                  studio location:{' '}
                  <span className="font-semibold">{storageLocation.full_storage_path}</span>
                </li>
              </ul>
              <p>Please contact the Administrator to provide you with key and secret.</p>
            </div>
          }
          type="info"
          closable
        />
      )}
      <Form.Item valuePropName="validationFiles" getValueFromEvent={normFile} noStyle>
        <Upload.Dragger
          name="files"
          multiple
          accept=".grb, .grb2, .pbm, .pgm, .ppm, .pxm, .pnm, .png, .tiff, .tif"
          beforeUpload={(file: RcFile) => beforeUpload(file)}
          showUploadList={false}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click or drag files to this area to upload</p>
          <p className="ant-upload-hint">Support for a single or bulk upload.</p>
        </Upload.Dragger>
      </Form.Item>
      {isLoading && (
        <div className="justify-center flex">
          <Triangle
            height="80"
            width="80"
            color="#3A36DB"
            ariaLabel="triangle-loading"
            wrapperStyle={{ justifyContent: 'center', width: '100%', marginTop: '20px' }}
            visible={true}
          />
        </div>
      )}
      <Table
        className="overflow-x-scroll bg-white"
        dataSource={data}
        columns={columns}
        rowKey="filename"
        pagination={false}
      />
      {fileResultId && (
        <FileValidationModal
          isOpen={isOpen}
          fileId={fileResultId}
          type={form.productType}
          setIsOpen={setIsOpen}
        />
      )}
    </>
  );
}

export default ValidationFiles;
