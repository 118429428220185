import { QueryKey, useQuery } from 'react-query';

import { axiosInstance } from '../../../core/api/axiosInstance';

export const key: QueryKey = ['BOOKMARKED_ITEMS'];

export const useGetBookmarkedItems = (auth: boolean) => {
  return useQuery(
    [...key],
    async () => {
      const res = await axiosInstance.get('bookmark/');
      return res.data;
    },
    {
      enabled: auth,
    },
  );
};
