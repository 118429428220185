import { useKeycloak } from '@react-keycloak/web';
import { Tooltip } from 'flowbite-react';
import { useMemo } from 'react';
import { IoIosCodeDownload } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../../atoms/button/Button';
import { ModeEnum } from '../../../core/ui/enums/ModeEnum';
import { WeatherDataLoader } from '../../../core/weather-data/WeatherDataLoader';
import { C9ProjectDef } from '../../../model/definitions/C9ProjectDef';
import { GribMapLayer } from '../../../model/definitions/GribMapLayer';
import { RadarMapLayer } from '../../../model/definitions/RadarMapLayer';
import { SatelliteMapLayer } from '../../../model/definitions/SatelliteMapLayer';
import { SymbolLayerDef } from '../../../model/definitions/SymbolLayerDef';
import { loadFrames } from '../../../pages/playground/loadFrames';
import { ActiveDef } from '../../../store/slices/active-slice';
import { RootState } from '../../../store/store';

export const LoadLayerFramesButton = ({
  layer,
}: {
  layer: RadarMapLayer | GribMapLayer | SatelliteMapLayer | SymbolLayerDef;
}) => {
  const {
    keycloak: { idTokenParsed },
  } = useKeycloak();
  const dispatch = useDispatch();
  const { activeScene, mode } = useSelector<RootState>((state) => state.active) as ActiveDef;
  const project = useSelector<RootState, C9ProjectDef>((state) => state.project.present.project);
  const mapIsLoading = useSelector<RootState, boolean>((state) => state.mapLoading.mapIsLoading);

  const allLoaded = useMemo(() => {
    const frameIds = layer.dataFrames
      .map((x) => x.frameId)
      .filter((frameId) => WeatherDataLoader.getByFrameId(frameId, layer.id) == undefined);

    return frameIds.length === 0;
  }, [layer.dataFrames, mapIsLoading]);

  if (allLoaded || !layer.enabled) {
    return null;
  }

  const load = () => {
    const scene = project.sceneDefs.find((scene) => scene.id === (activeScene as string));
    const scenes = mode === ModeEnum.SEQUENCE && scene ? [scene] : project.sceneDefs;
    loadFrames(scenes, dispatch, idTokenParsed!, layer);
  };

  return (
    <Tooltip content="Load all frames" style={`dark`}>
      <Button
        onClick={() => load()}
        disabled={mapIsLoading}
        className="surface-temp-icon"
        buttonType="link"
        icon={<IoIosCodeDownload color="white" />}
      />
    </Tooltip>
  );
};
