import { AiFillFolderOpen } from 'react-icons/ai';

import styles from './NoDataFound.module.scss';

const NoDataFound = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full pb-8">
      <div className={styles.iconWrapper}>
        <AiFillFolderOpen />
      </div>
      <span className={styles.title}>No results found</span>
      <span className={styles.description}>
        Try adjusting your search to find what you're looking for.
      </span>
    </div>
  );
};

export default NoDataFound;
