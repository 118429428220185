import { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ModeEnum } from '../core/ui/enums/ModeEnum';
import { MIN_FRAME_DURATION } from '../model/constants/constants';
import PlayerContext from '../pages/playground/playerContext/PlayerContext';
import { ActiveDef } from '../store/slices/active-slice';
import { selectLastSkip, selectProjectDuration } from '../store/slices/selectors';
import { RootState } from '../store/store';
/**NIMA_765 if lastSkip time and project end time is in range less than minimum Frame time (for 60fps 1000/60)
 * hide map and it's loader on last "frame" to prevent black flickering
 */
export function useHideMapOnLastSkip() {
  const lastSkipTime = useSelector<RootState, number | undefined>((state) => selectLastSkip(state));
  const projectDuration = useSelector<RootState, number>((state) => selectProjectDuration(state));
  const { time } = useContext(PlayerContext);
  const { mode } = useSelector<RootState, ActiveDef>((state) => state.active);
  const isProjectMode = mode === ModeEnum.PROJECT;
  return useMemo(() => {
    if (!isProjectMode || !lastSkipTime) return false;
    return projectDuration - lastSkipTime < MIN_FRAME_DURATION && time >= lastSkipTime;
  }, [time, isProjectMode, lastSkipTime, projectDuration]);
}
