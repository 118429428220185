import { ToggleSwitch, Tooltip } from 'flowbite-react';
import { useState } from 'react';
import { VscTrash } from 'react-icons/vsc';
import { useDispatch, useSelector } from 'react-redux';

import { WeatherPosterDef } from '../../../../model/definitions/WeatherPosterDef';
import { ActiveDef } from '../../../../store/slices/active-slice';
import { deleteGeoPoster, toggleGeoPoster } from '../../../../store/slices/project-slice';
import { RootState } from '../../../../store/store';

interface Props {
  item: WeatherPosterDef;
}
export const GeoPosterProperties = ({ item }: Props) => {
  const dispatch = useDispatch();
  const { activeElement, activeScene } = useSelector<RootState>(
    (state) => state.active,
  ) as ActiveDef;

  const [open, setOpen] = useState<boolean>(false);
  const deletePoster = () =>
    dispatch(deleteGeoPoster({ activeScene, mapId: activeElement, elementId: item.id }));
  const toggleGeoPosterChange = (checked: boolean) => {
    dispatch(
      toggleGeoPoster({ activeScene, mapId: activeElement, elementId: item.id, enabled: checked }),
    );
  };
  return (
    <div style={{ marginLeft: '30px' }}>
      <div
        key={item.id}
        className={`flex p-1 geo-poster-prop layer-header`}
        onClick={() => setOpen(!open)}
      >
        <div>{item.name}</div>
        <div style={{ marginLeft: '1rem' }}>
          <ToggleSwitch checked={item.enabled} onChange={toggleGeoPosterChange} label={'Enabled'} />
        </div>
        <Tooltip content="Delete" style={`dark`}>
          <div
            className={'hoverState text-red-500 flex items-center h-full'}
            onClick={() => deletePoster()}
            //disabled={!activeElement}
          >
            <VscTrash style={{ color: 'red', fontSize: 14 }} />
          </div>
        </Tooltip>
      </div>
    </div>
  );
};
