import { Checkbox, Label } from 'flowbite-react';
import { useState } from 'react';

import Button from '../../../atoms/button/Button';
import Input from '../../../atoms/input/Input';
import TextArea from '../../../atoms/input/Textarea';
import { ForecastWDElementDef } from '../../../model/definitions/ForecastWDElementDef';
import { ImagePanelDef } from '../../../model/definitions/ImagePanelDef';
import { ObservedWDElementDef } from '../../../model/definitions/ObservedWDElementDef';
import { SceneDef } from '../../../model/definitions/SceneDef';
import { TextPanelDef } from '../../../model/definitions/TextPanelDef';
import { VideoPanelDef } from '../../../model/definitions/VideoPanelDef';
import { WeatherPosterDef } from '../../../model/definitions/WeatherPosterDef';
import Modal from '../../../molecules/modal/Modal';

interface CreatePosterModalProps {
  opened: boolean;
  onClose: () => void;
  onOk: (name: string, description: string, geoPoster?: boolean) => void;
  noOfObserved: number;
  noOfForecast: number;
  selectedContent: Array<
    (
      | VideoPanelDef
      | ObservedWDElementDef
      | ImagePanelDef
      | TextPanelDef
      | ForecastWDElementDef
      | WeatherPosterDef
    ) & {
      type: SceneKeys<SceneDef>;
    }
  >;
}
export const CreatePosterModal = ({
  opened,
  onClose,
  onOk,
  noOfForecast,
  noOfObserved,
  selectedContent,
}: CreatePosterModalProps) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [geoPoster, setGeoPoster] = useState<boolean>(false);
  const observed: Array<ObservedWDElementDef> = selectedContent.filter(
    (panel) => panel.type === 'observedWDElements',
  ) as Array<ObservedWDElementDef>;
  const forecast: Array<ForecastWDElementDef> = selectedContent.filter(
    (panel) => panel.type === 'forecastWDElements',
  ) as Array<ForecastWDElementDef>;
  const observedCities = observed.map((item) => item.observedWDSource.station.fullName);
  const forecastCities = forecast.map((item) => item.forecastWDSource.location.name);
  let cities = [...observedCities, ...forecastCities];
  cities = [...new Set(cities)];
  const renderWarning = () => {
    if (noOfForecast > 0 && noOfObserved > 0) {
      return (
        <div className={'text-red-800 text-2xl'}>
          Selection contains both forecast and observed data. Please limit your selection to only
          one type of weather data to proceed
        </div>
      );
    }
  };
  const times = () => {
    if (cities.length !== 1) return false;
    else if (noOfForecast > 0 && noOfObserved > 0) return false;
    else if (noOfForecast > 0) {
      const forecast = selectedContent.filter(
        (panel) => panel.type === 'forecastWDElements',
      ) as Array<ForecastWDElementDef>;
      let dates = forecast.map((data) => data.forecastWDSource.utcDate);
      let hasDaily = forecast.map((data) => data.forecastWDSource.daily);
      dates = [...new Set(dates)];
      hasDaily = [...new Set(hasDaily)];
      if (dates.length > 1 || hasDaily.length > 1) return true;
    } else if (noOfObserved > 0) {
      const forecast = selectedContent.filter(
        (panel) => panel.type === 'observedWDElements',
      ) as Array<ObservedWDElementDef>;
      let dates = forecast.map((data) => data.observedWDSource.datetime);
      dates = [...new Set(dates)];
      if (dates.length > 1) return true;
    }
  };
  times();
  return (
    <Modal
      isOpen={opened}
      onClose={() => {
        onClose();
      }}
      header={'Create Poster'}
      className="template-scene-modal"
      footer={
        <div className="modal-footer">
          <div className={'footnote'}></div>
          <div className={'button-holder'}>
            <Button onClick={onClose} buttonType="secondary" label="Cancel" />
            <Button
              label="Create"
              onClick={() => onOk(name, description, geoPoster)}
              disabled={!name || (noOfObserved > 0 && noOfForecast > 0)}
            />
          </div>
        </div>
      }
    >
      <>
        {renderWarning()}
        <Input
          name={'name'}
          label={'Name'}
          placeholder="Type name..."
          required
          value={name}
          onChange={(e) => setName(e.target.value)}
          type={'text'}
        />
        <TextArea
          placeholder="Type description..."
          name={'description'}
          label={'Description'}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          cols={2}
        />
        <div className={'mt-3'}>
          <Label>
            <Checkbox
              disabled={cities.length > 1}
              checked={geoPoster}
              onChange={(e) => setGeoPoster(e.target.checked)}
              style={{ marginRight: '0.3rem' }}
            />
            Create as Composite
            {cities.length > 1 && (
              <div>
                Composite can`t be created from this selection because multiple locations are
                selected.{' '}
                {cities.map((city) => (
                  <span key={city} className={'mr-3'}>
                    {city};
                  </span>
                ))}
              </div>
            )}
          </Label>
        </div>
      </>
    </Modal>
  );
};
