import { BackgroundDef } from './BackgroundDef';
import { C9Object } from './C9Object';
import { ImagePanelDefTemplate } from './ImagePanelDefTemplate';
import { LogicalGroupParent } from './LogicalGroupParent';
import { PositionControlDef } from './PositionControlDef';
import { TimeControlDef } from './TimeControlDef';

export class ImagePanelDef extends C9Object {
  timeControls = [new TimeControlDef()];
  positionControl = new PositionControlDef(0, 0);
  background: BackgroundDef;
  imagePanelDefTemplate: ImagePanelDefTemplate;
  parentGroups = [new LogicalGroupParent()];
  lockAspectRatio = true;
  enabled: boolean;
  opacity = 1;
}
