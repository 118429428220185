import { AxiosError } from 'axios';
import { QueryKey, useQuery, UseQueryResult } from 'react-query';

import { axiosInstance } from '../../../core/api/axiosInstance';
import { C9ProjectPreviewDTO } from '../../../model/DTO/C9ProjectPreviewDTO';
import { QueryInfoMetadata } from '../../../model/other/QueryInfoMetadata';

export const key: QueryKey = ['GET_REPO_TEMPLATES'];

const useGetRepoTemplates = ({
  page,
  type,
  size = 19,
  searchKey,
  sortByField,
  sortAscDesc,
}: {
  page: number;
  type:
    | 'project'
    | 'scene'
    | 'weather-poster'
    | 'weather-geo-poster'
    | 'font'
    | 'map'
    | 'icon-set'
    | 'geoposter'
    | 'poster'
    | 'layer';
  size?: number;
  searchKey?: string;
  sortByField?: string;
  sortAscDesc?: string;
}): UseQueryResult<QueryInfoMetadata<C9ProjectPreviewDTO>, AxiosError> => {
  return useQuery(
    [...key, type, page, searchKey, sortByField, sortAscDesc],
    async () => {
      const { data } = await axiosInstance.get(
        `repository/my-repo-${
          type === 'poster' ? 'weather-poster' : type === 'geoposter' ? 'weather-geo-poster' : type
        }-templates`,
        {
          params: { page, size, searchKey, sortByField, sortAscDesc },
        },
      );
      return data;
    },
    {
      enabled: !!type,
    },
  );
};

export default useGetRepoTemplates;
