import '../../../../molecules/FileUpload/style.scss';

import { RefObject } from 'react';
import { RiDragDropLine } from 'react-icons/ri';

interface UploadInputProps {
  theme: 'dark' | 'light';
  fileRef: RefObject<HTMLDivElement>;
}
const UploadInput = ({ theme = 'light', fileRef }: UploadInputProps) => {
  return (
    <div className={theme === 'dark' ? 'dropzone' : 'dropzone-light'} ref={fileRef}>
      <RiDragDropLine size={42} />
      <span className="font-medium text-gray-600">
        Click here to select or Drop Files to upload
      </span>
    </div>
  );
};
export default UploadInput;
