import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { queryClient } from '../../..';
import { axiosInstance } from '../../../core/api/axiosInstance';
import { key as useGetPublishedItemsKey } from '../hooks/filterUseGetPublishedItems';

export const useUnpublishMarketplaceItem = () => {
  return useMutation(
    async (data: { templateType: string; templateId: string; templateVersionId: string }) => {
      return await axiosInstance.post(`marketplace/unPublish`, data);
    },
    {
      onSuccess: () => {
        toast.success('Item unpublished!');
        queryClient.invalidateQueries(useGetPublishedItemsKey);
      },
      onError: () => {
        toast.error('Error unpublishing item!');
      },
    },
  );
};
