import { IconTemplateDef } from '../../../model/definitions/IconTemplateDef';
import { ValueTypeEnum } from '../../../model/enums/ValueTypeEnum';
import { displayPointValue } from '../../../molecules/canvasElements/showPointDataValue';

interface DDIconProps {
  versionId: string;
  isJson: boolean;
  icon?: IconTemplateDef;
  className?: string;
  full?: boolean;
}
export const DDIcon = ({ versionId, isJson, icon, className, full }: DDIconProps) => {
  return (
    <div
      className={` ${className} ${
        full ? 'h-full' : 'h-2/3'
      } grid-icon content justify-center items-center dd-icon`}
    >
      {displayPointValue(
        ValueTypeEnum.IMAGE,
        versionId,
        '',
        isJson,
        undefined,
        undefined,
        false,
        icon?.originalZipSequence,
        icon,
        true,
      )}
    </div>
  );
};
