import { FC, ReactNode, useEffect, useState } from 'react';
import { AiOutlineCaretDown, AiOutlineCaretUp } from 'react-icons/ai';

interface PropertySectionProps {
  isOpened: boolean;
  label: string;
  children: ReactNode;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}
export const PropertySection: FC<PropertySectionProps> = ({
  isOpened,
  label,
  children,
  className,
  onClick,
}) => {
  const [accordionProp, setAccordionProp] = useState<boolean>(isOpened);

  useEffect(() => {
    if (onClick && isOpened !== accordionProp) setAccordionProp(isOpened);
  }, [isOpened]);

  return (
    <>
      <div
        className={`mb-2 subheader layer-header ${
          accordionProp ? 'layer-header-active' : ''
        } ${className}`}
        onClick={(e) => {
          setAccordionProp(!accordionProp);
          onClick?.(e);
        }}
      >
        {accordionProp ? <AiOutlineCaretUp /> : <AiOutlineCaretDown />}
        {label}
      </div>
      {accordionProp && children}
    </>
  );
};
