import Color from 'colorjs.io';
import { useCallback, useEffect, useRef, useState } from 'react';

import { MAX_FULLSCREEN_HEIGHT } from '../../../../../model/constants/constants';
import { PaletteSetup, PaletteSetupDegree } from '../../../../../model/definitions/ColorPaletteDef';
import { OrientationTypeEnum } from '../../../../../model/enums/OrientationTypeEnum';
import { PaletteLegendScalingEnum } from '../../../../../model/enums/PaletteLegendScalingEnum';
import { getRgba } from '../../../../../molecules/palette/utils';
import { findNumberRange, interpolateValues, legendRgba, toRgbaString } from './utils';

interface CustomLegendProps {
  setup: PaletteSetup;
  proportional: PaletteLegendScalingEnum;
  w?: number;
  h?: number;
  values?: boolean;
  orientation?: OrientationTypeEnum;
  labelSize?: number;
  fontFamily?: string;
  fontType?: string;
  cnvHeight?: number;
  onChange?: (e: Record<number, string>) => void;
}

export const CustomLegend = ({
  setup,
  proportional = PaletteLegendScalingEnum.PROPORTIONAL,
  w = 20000,
  h,
  values = true,
  orientation = OrientationTypeEnum.HORIZONTAL,
  fontFamily = 'Arial',
  labelSize = 2,
  fontType = 'Regular',
  cnvHeight,
  onChange,
}: CustomLegendProps) => {
  const { scale } = setup;
  const scaleRounded: Array<PaletteSetupDegree> = scale.map((item) => {
    return {
      ...item,
      degree: Math.round(item.degree * 100) / 100,
    };
  });
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [paletteColors, setPaletteColors] = useState<Record<number, string>>({});
  const canvas = canvasRef.current;
  const bucketWidth =
    ((orientation === OrientationTypeEnum.HORIZONTAL ? canvas?.width : canvas?.height) ?? 0) /
    scaleRounded.filter((item) => item.active).length;
  const scaleBucketWidth =
    ((orientation === OrientationTypeEnum.HORIZONTAL ? canvas?.width : canvas?.height) ?? 0) /
    scaleRounded.filter((item) => item.active).length;
  const convertToKeyValue = (array: PaletteSetupDegree[]): Record<number, string> => {
    return Object.fromEntries(
      array.map((item) => [Math.round(item.degree * 100) / 100, item.color]),
    );
  };
  useEffect(() => {
    const data = scaleRounded;
    const redefinedPalette: Record<number, string> = {};
    const gradient: Record<number, Color[]> = {};
    if (data?.length)
      redefinedPalette[data[data.length - 1]?.degree] = `${data[data.length - 1]?.color}`;
    if (data?.length)
      for (let i = 1; i < (data.length ?? 0); i++) {
        getRgba(data[i].color);
        const color = new Color(getRgba(data[i - 1].color));
        gradient[data[i - 1].degree] = [];
        // @ts-ignore
        const interColors = color.steps(getRgba(data[i].color), {
          space: 'srgb',
          outputSpace: 'srgb',
          steps: data[i - 1].interpolationSteps + 2,
        });
        const newSteps = interpolateValues(
          data[i - 1].degree,
          data[i].degree,
          data[i - 1].interpolationSteps,
        );
        for (let i = 0; i < newSteps.length; i++) {
          if (interColors[i] && newSteps[i]) {
            redefinedPalette[Math.round(newSteps[i] * 100) / 100] = toRgbaString(
              interColors[i + 1] as Color,
            );
          }
        }
      }
    const mainPalette = convertToKeyValue(scaleRounded);
    setPaletteColors({ ...redefinedPalette, ...mainPalette });
    onChange && onChange({ ...redefinedPalette, ...mainPalette });
  }, [scale, proportional, bucketWidth]);
  const max = Math.min(...scaleRounded.filter((item) => item.active).map((item) => item.degree));
  const min = Math.max(...scaleRounded.filter((item) => item.active).map((item) => item.degree));
  const width = max - min;
  const fontSize = ((cnvHeight ?? MAX_FULLSCREEN_HEIGHT) / 100) * labelSize;
  const proportionalPosition = useCallback(
    (key: number, cnvWidth: number, cnvHeight: number) => {
      const spread = orientation === OrientationTypeEnum.HORIZONTAL ? cnvWidth : cnvHeight;
      const keys = Object.keys(paletteColors).sort((a, b) => Number(a) - Number(b));
      const index = keys.findIndex((element) => element === key.toString());
      const relativeWidth = keys[index + 1] ? Number(keys[index + 1]) - Number(keys[index]) : 0;
      const keyWidth = (relativeWidth * 100) / width;
      const startKey = ((key - min) * 100) / width;
      const start = (spread * (100 - startKey)) / 100;
      const bucketWidth = (spread * keyWidth) / 100;
      const startPosition =
        orientation === OrientationTypeEnum.HORIZONTAL ? start : spread - start + bucketWidth;
      return { start: startPosition, bucketWidth: Math.abs(bucketWidth) };
    },
    [min, orientation, paletteColors, width],
  );
  const homogenousPosition = useCallback(
    (key: number) => {
      const activeScale = scaleRounded.filter((item) => item.active).map((item) => item.degree);
      const lastKey = activeScale[activeScale.length - 1];
      const keys = Object.keys(paletteColors).sort((a, b) => Number(a) - Number(b));
      const prop = findNumberRange(activeScale, key);
      const positionIndex = keys
        .filter((key) => Number(key) >= Number(prop?.range[0]))
        .findIndex((element) => element === key.toString());
      const bucketIndex = activeScale.findIndex((obj) => obj === (prop?.range[0] ?? 0));
      const interpolateSteps =
        scaleRounded
          .filter((item) => item.active)
          .find((obj) => obj.degree === (prop?.range[0] ?? 0))?.interpolationSteps ?? 0;
      const width =
        key === lastKey || interpolateSteps === 0
          ? bucketWidth
          : bucketWidth / (interpolateSteps + 1);
      const start = bucketWidth * bucketIndex + width * Math.abs(positionIndex);
      return { start, width };
    },
    [bucketWidth, paletteColors, scale],
  );
  const scalePosition = useCallback(
    (key: number, cnvWidth: number, cnvHeight: number) => {
      const spread = orientation === OrientationTypeEnum.HORIZONTAL ? cnvWidth : cnvHeight;
      const keys = scaleRounded
        .filter((item) => item.active)
        .map((item) => item.degree)
        .sort((a, b) => Number(a) - Number(b));
      const index = keys.findIndex((element) => element === key);
      const relativeWidth = keys[index + 1] ? Number(keys[index + 1]) - Number(keys[index]) : 0;
      const keyWidth = (relativeWidth * 100) / width;
      const startKey = ((key - min) * 100) / width;
      const bucketWidth = (spread * keyWidth) / 100;
      const start =
        index === 0
          ? fontSize
          : index === keys.length - 1
          ? (spread * (100 - startKey)) / 100 - fontSize
          : (spread * (100 - startKey)) / 100;
      return { start, bucketWidth: Math.abs(bucketWidth) };
    },
    [fontSize, min, orientation, scale, width],
  );

  const mainPosition = useCallback(
    (color: string) =>
      canvas && proportional === PaletteLegendScalingEnum.PROPORTIONAL
        ? proportionalPosition(Number(color), canvas.width, canvas.height).start
        : homogenousPosition(Number(color)).start,
    [canvas, homogenousPosition, proportional, proportionalPosition],
  );
  const mainWidth = useCallback(
    (color: string) =>
      canvas && proportional === PaletteLegendScalingEnum.PROPORTIONAL
        ? proportionalPosition(Number(color), canvas.width, canvas.height).bucketWidth
        : homogenousPosition(Number(color)).width,
    [canvas, homogenousPosition, proportional, proportionalPosition],
  );
  useEffect(() => {
    const ctx = canvas?.getContext('2d');
    const last = scaleRounded.filter((item) => item.active).length - 1;
    const fontFamilyWithType = fontFamily + ' ' + fontType;
    if (ctx && canvas) {
      ctx.imageSmoothingEnabled = true;
      ctx.clearRect(0, 0, canvas?.width, canvas.height);
      orientation === OrientationTypeEnum.HORIZONTAL
        ? Object.entries(paletteColors)
            .sort((a, b) => Number(a[0]) - Number(b[0]))
            .forEach((color) => {
              const x = mainPosition(color[0]);
              const y = 0;
              const width = mainWidth(color[0]);
              ctx.fillStyle = legendRgba(color[1]);
              ctx.fillRect(x, y, width, canvas.height);
            })
        : Object.entries(paletteColors)
            .sort((a, b) => Number(a[0]) - Number(b[0]))
            .forEach((color) => {
              const height = mainWidth(color[0]);
              const y =
                proportional === PaletteLegendScalingEnum.PROPORTIONAL
                  ? mainPosition(color[0])
                  : canvas.height - mainPosition(color[0]) - height;
              const x = 0;
              ctx.fillStyle = legendRgba(color[1]);
              ctx.fillRect(x, y, canvas.width, height);
            });
      if (values) {
        orientation === OrientationTypeEnum.HORIZONTAL
          ? scaleRounded
              .filter((item) => item.active)
              .sort((a, b) => Number(a.degree) - Number(b.degree))
              .forEach((color, index) => {
                const x =
                  proportional === PaletteLegendScalingEnum.PROPORTIONAL
                    ? scalePosition(Number(color.degree), canvas.width, canvas.height).start
                    : Math.floor(index * scaleBucketWidth);
                const text = (Math.round(color.degree * 100) / 100).toString();
                const textWidth = ctx.measureText(text).width;
                const metrics = ctx.measureText(text);
                const actualHeight =
                  metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;
                ctx.fillStyle = 'white';
                ctx.textAlign = index !== last ? 'center' : 'left';
                ctx.font = `${fontSize}px ${fontFamilyWithType}`;
                ctx.fillText(
                  text,
                  proportional === PaletteLegendScalingEnum.PROPORTIONAL
                    ? index === 0
                      ? textWidth / 2
                      : index === last
                      ? canvas.width - textWidth
                      : x
                    : x + bucketWidth / 2 - (index === last ? textWidth / 2 : 0),
                  actualHeight + (canvas.height - actualHeight) / 2, //canvas.height / 2 + fontSize / 2,
                );
              })
          : scaleRounded
              .filter((item) => item.active)
              .sort((a, b) => Number(b.degree) - Number(a.degree))
              .forEach((color, index) => {
                const y =
                  proportional === PaletteLegendScalingEnum.PROPORTIONAL
                    ? scalePosition(Number(color.degree), canvas.width, canvas.height).start /*  +
                      (index === 0 ? fontSize + 1 : 0) */
                    : Math.floor(index * scaleBucketWidth) + scaleBucketWidth / 2;
                ctx.fillStyle = 'white';
                ctx.font = `${fontSize}px ${fontFamilyWithType}`;
                ctx.textAlign = 'center';
                const text = (Math.round(color.degree * 100) / 100).toString();
                ctx.fillText(
                  text,
                  canvas.width / 2,
                  proportional === PaletteLegendScalingEnum.PROPORTIONAL
                    ? index === 0
                      ? fontSize
                      : index !== last
                      ? canvas.height - y + fontSize / 2
                      : canvas.height - fontSize / 2
                    : y + fontSize / 2,
                );
              });
      }
    }
  }, [
    orientation,
    values,
    scaleBucketWidth,
    bucketWidth,
    paletteColors,
    proportional,
    setup,
    w,
    h,
    scalePosition,
    proportionalPosition,
    canvas,
    scale,
    homogenousPosition,
    mainPosition,
    mainWidth,
    fontSize,
    fontFamily,
    fontType,
  ]);
  return (
    <div className="relative flex h-full w-full overflow-clip">
      <canvas ref={canvasRef} className={'w-full h-full'} width={w} height={h ? h : w / 15} />
    </div>
  );
};
