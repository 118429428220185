import { CSSProperties, useEffect, useRef, useState } from 'react';
import { ColorResult, RGBColor } from 'react-color';
import useEyeDropper from 'use-eye-dropper';

import Chrome from '../../../../atoms/color-picker-refactor/chrome/Chrome';
import styles from '../Properties.module.scss';
import { CloseOnClickOutside } from './CloseOnClickOutside';

interface ColorPickerProps {
  value: string;
  disabled?: boolean;
  onChange?: (e: string) => void;
  isPalette?: boolean;
  outline?: boolean;
  browserRGBA?: boolean;
  pickerStyle?: CSSProperties;
}
export const PaletteColorPicker = ({
  value,
  onChange,
  isPalette = false,
  disabled,
  outline,
  browserRGBA,
  pickerStyle,
}: ColorPickerProps) => {
  const pickerRef = useRef<HTMLDivElement>(null);
  const [pickerVisible, setPickerVisible] = useState(false);
  const { open, isSupported } = useEyeDropper();
  const initialState = () => {
    const colors = value?.match(/\d+(\.\d+)?/g);

    if (colors && Number(colors[3]) > 1) {
      return {
        r: Number(colors[0]),
        g: Number(colors[1]),
        b: Number(colors[2]),
        a: Number(colors[3]) / 255,
      };
      // eslint-disable-next-line sonarjs/no-duplicated-branches
    } else if (colors) {
      return {
        r: Number(colors[0]),
        g: Number(colors[1]),
        b: Number(colors[2]),
        a: browserRGBA ? Number(colors[3]) : Number(colors[3]) / 255,
      };
    } else {
      return {
        r: 255,
        g: 255,
        b: 255,
        a: 0,
      };
    }
  };
  const [color, setColor] = useState<RGBColor>(initialState());
  const pickColor = () => {
    open().then((color) => {
      hexToRGB(color.sRGBHex);
    });
  };
  const hexToRGB = (color: string) => {
    const r = parseInt(color.slice(1, 3), 16);
    const g = parseInt(color.slice(3, 5), 16);
    const b = parseInt(color.slice(5, 7), 16);
    setColor({ r, g, b, a: 1 });
  };
  useEffect(() => {
    if (isPalette) {
      onChange &&
        onChange(`${color?.r}, ${color?.g}, ${color?.b}, ${Math.round(255 * (color?.a ?? 1))}`);
    } else if (browserRGBA) {
      onChange && onChange(`rgba(${color?.r}, ${color?.g}, ${color?.b}, ${color?.a ?? 1})`);
    } else {
      onChange &&
        onChange(
          `rgba(${color?.r}, ${color?.g}, ${color?.b}, ${Math.round(255 * (color?.a ?? 1))})`,
        );
    }
  }, [color]);
  useEffect(() => {
    setColor(initialState());
  }, []);
  useEffect(() => {
    pickerVisible && pickerRef.current?.focus();
  }, [pickerVisible]);
  useEffect(() => {
    setColor(initialState());
  }, [value]);
  const editColor = (col: keyof RGBColor, val: number) => {
    if (col === 'a') {
      val > 1 ? (val = 1) : val;
    } else {
      val > 255 ? (val = 255) : val;
    }

    col &&
      color &&
      setColor({
        ...color,
        [col]: val,
      });
  };
  return (
    <div className={styles.colorPickerPalette}>
      <div
        onClick={() => {
          !disabled && setPickerVisible(true);
        }}
        className={styles.indicator}
        style={{
          backgroundColor: `rgba(${color?.r}, ${color?.g}, ${color?.b}, ${color?.a})`,
          width: '25px',
          height: '25px',
          minWidth: '25px',
          outline: outline ? '1px solid #d9d9d9' : '',
        }}
      />
      <div className={'flex items-center'}>
        <div className={'flex items-center'}>
          R:{' '}
          <input
            className={`${styles.colInput} ${styles.r}`}
            value={color?.r}
            type={'number'}
            max={255}
            min={0}
            step={1}
            onChange={(e) => {
              const red = Number(e.target.value);
              if (!isNaN(red) && red >= 0 && red <= 255) editColor('r', red);
            }}
            disabled={disabled}
          />
        </div>
        <div className={'flex items-center'}>
          G:{' '}
          <input
            className={`${styles.colInput} ${styles.g}`}
            value={color?.g}
            type={'number'}
            max={255}
            min={0}
            step={1}
            onChange={(e) => {
              const green = Number(e.target.value);
              if (!isNaN(green) && green >= 0 && green <= 255) editColor('g', green);
            }}
            disabled={disabled}
          />
        </div>
        <div className={'flex items-center'}>
          B:{' '}
          <input
            className={`${styles.colInput} ${styles.b}`}
            value={color?.b}
            type={'number'}
            max={255}
            min={0}
            step={1}
            onChange={(e) => {
              const blue = Number(e.target.value);
              if (!isNaN(blue) && blue >= 0 && blue <= 255) editColor('b', blue);
            }}
            disabled={disabled}
          />
        </div>
        <div className={'flex items-center'}>
          a:{' '}
          <input
            className={styles.colInput}
            value={Math.round((color?.a ?? 1) * 255)}
            type={'number'}
            max={255}
            min={0}
            step={0.1}
            disabled={disabled}
            onChange={(e) => {
              const alpha = Number(e.target.value);
              if (!isNaN(alpha) && alpha >= 0 && alpha <= 255) editColor('a', alpha / 255);
            }}
          />
        </div>
      </div>
      {pickerVisible && (
        <CloseOnClickOutside
          onClose={() => !disabled && setPickerVisible(false)}
          className={styles.palettePickerWrapper}
          style={pickerStyle}
        >
          <Chrome
            onChange={(e: ColorResult, event: React.ChangeEvent<HTMLInputElement>) => {
              event.stopPropagation();
              setColor(e.rgb);
            }}
            pickColor={pickColor}
            colorByName={hexToRGB}
            color={color}
            isSupported={isSupported}
          />
        </CloseOnClickOutside>
      )}
    </div>
  );
};
