import { Moment } from 'moment';
import moment from 'moment-timezone';

import { getRealtimeIndicator } from '../../../../../components/timeline/helpers';
// import { getRealtimeIndicator } from '../../../../../components/timeline/helpers';
import { ForecastWDElementDef } from '../../../../../model/definitions/ForecastWDElementDef';
import { ObservedWDElementDef } from '../../../../../model/definitions/ObservedWDElementDef';
import { TextPanelDef } from '../../../../../model/definitions/TextPanelDef';
import { WeatherDataMapLayer } from '../../../../../model/definitions/WeatherDataMapLayer';
import { WeatherDataMapLayerSetup } from '../../../../../model/definitions/WeatherDataMapLayerSetup';
import { WeatherDataSpaceDef } from '../../../../../model/definitions/WeatherDataSpaceDef';
import { TimeStepEnum } from '../../../../../model/enums/TimeStepEnum';

const enumToS = (step: TimeStepEnum) => {
  switch (step) {
    case TimeStepEnum.MIN_1: {
      return 60;
    }
    case TimeStepEnum.MIN_5: {
      return 5 * 60;
    }
    case TimeStepEnum.MIN_10:
      return 10 * 60;
    case TimeStepEnum.MIN_15:
      return 15 * 60;
    case TimeStepEnum.MIN_30:
      return 30 * 60;
    case TimeStepEnum.HOURLY:
      return 60 * 60;
    case TimeStepEnum.DAILY:
      return 60 * 60 * 24;
    case TimeStepEnum.WEEKLY:
      return 60 * 60 * 24 * 7;
  }
};
const formats = {
  'DD-MM-YYYY': 'DD-MM-YYYY',
  'DD/MM/YYYY': 'DD/MM/YYYY',
  'DD.MM.YYYY': 'DD.MM.YYYY',
  'DD-MM-YYYY HH:mm': 'DD-MM-YYYY HH:mm',
  'DD-MM, HH:mm': 'DD-MM, HH:mm',
  'D-M, HH:mm': 'D-M, HH:mm',
  'D.M, HH:mm': 'D.M, HH:mm',
  'D/M, HH:mm': 'D/M, HH:mm',
  'dddd, HH:mm': 'dddd, HH:mm',
  'DD/MM/YYYY HH:mm': 'DD/MM/YYYY HH:mm',
  'DD.MM.YYYY HH:mm': 'DD.MM.YYYY HH:mm',
  'MM-DD-YYYY': 'MM-DD-YYYY',
  'MM/DD/YYYY': 'MM/DD/YYYY',
  'MM.DD.YYYY': 'MM.DD.YYYY',
  'MM-DD-YYYY HH:ss': 'MM-DD-YYYY HH:mm',
  'MM/DD/YYYY HH:ss': 'MM/DD/YYYY HH:mm',
  'MM.DD.YYYY HH:ss': 'MM.DD.YYYY HH:mm',
  'Custom format': 'CUSTOM',
};
const zone = moment.tz.guess();
function splitRange(
  start: number,
  end: number,
  timeStart: number,
  timeEnd: number,
  n: number,
  dateFormat: string,
  timeDateFormat: string,
  model: TextPanelDef,
  rounded: boolean,
  step: TimeStepEnum,
  frameRate: number,
  space: WeatherDataSpaceDef,
  sceneStart?: number | null,
  sceneEnd?: number | null,
) {
  const rangeSize = (end - start) / n;
  const timeLeap = (timeEnd - timeStart) / n;
  const subRanges = [];
  const getCustomPartOfDay = function (date: Moment) {
    const hours = date.hours();
    if (hours >= 6 && hours < 12) {
      return '$Morning';
    } else if (hours >= 12 && hours < 18) {
      return '$Day';
    } else if (hours >= 18 && hours < 24) {
      return '$Evening';
    } else {
      return '$Night';
    }
  };

  for (let i = 0; i <= n + 1; i++) {
    const time = timeStart + i * (isNaN(timeLeap) ? 0 : timeLeap);
    const subRangeStart = start + i * rangeSize;
    let subRangeEnd = subRangeStart + rangeSize;
    if (subRangeEnd > end) {
      subRangeEnd = end;
    }
    try {
      let timeVal = moment(time * 1000);
      const minute = timeVal.minute();

      if (rounded && step === TimeStepEnum.HOURLY) {
        const minutes = new Date(time * 1000).getUTCMinutes();
        timeVal.tz(zone).format(dateFormat); //.startOf('hour')
        if (minutes < 30) {
          timeVal.tz(zone).startOf('hour').format(dateFormat); //.startOf('hour')
        } else {
          timeVal.tz(zone).add(1, 'hour').startOf('hour').format(dateFormat);
        }
      }
      if (step === TimeStepEnum.MIN_5) {
        const roundedMinute = Math.round(minute / 5) * 5;
        timeVal = timeVal.clone().minute(roundedMinute).second(0).millisecond(0); // round the time to the nearest 5 minutes
      }
      if (step === TimeStepEnum.MIN_10) {
        const roundedMinute = Math.round(minute / 10) * 10;
        timeVal = timeVal.clone().minute(roundedMinute).second(0).millisecond(0);
      }
      if (step === TimeStepEnum.MIN_15) {
        const roundedMinute = Math.round(minute / 15) * 15;
        timeVal = timeVal.clone().minute(roundedMinute).second(0).millisecond(0);
      }
      if (step === TimeStepEnum.MIN_30) {
        const roundedMinute = Math.round(minute / 30) * 30;
        timeVal = timeVal.clone().minute(roundedMinute).second(0).millisecond(0);
      }
      //@ts-ignore
      const def = {
        value: moment(i <= n ? timeVal : timeEnd * 1000)
          .tz(zone)
          .format(dateFormat)
          .replace(/pp/g, () => getCustomPartOfDay(moment(i < n ? timeVal : timeEnd * 1000)))
          .replace(/PP/g, () =>
            getCustomPartOfDay(moment(i < n ? timeVal : timeEnd * 1000)).toUpperCase(),
          ),
        dateValue: moment(i < n ? timeVal : timeEnd * 1000)
          .tz(zone)
          .format(timeDateFormat),
        timeControls: {
          ...model.timeControls[0],
          startMS: i < n ? Math.floor(subRangeStart) : end - 1000 / frameRate,
          endMS:
            i === n - 1
              ? subRangeEnd - 1000 / frameRate
              : i < n
              ? Math.floor(subRangeEnd)
              : end + 1,
        },
      };
      subRanges.push(def);
    } catch (error) {
      console.log(`Error parsing date: ${error}`);
    }
  }
  /*  if (timeStart === timeEnd) {
    const def = {
      //value: format(new Date(timeStart * 1000), dateFormat ?? 'dd.MM.yyyy HH:mm'),
      value: moment(timeStart * 1000)
        .tz(zone)
        .format(dateFormat),
      dateValue: moment(timeStart * 1000)
        .tz(zone)
        .format(timeDateFormat),
      timeControls: {
        ...model.timeControls[0],
        startMS: start,
        endMS: start,
      },
    };
    subRanges.push(def);
  } */
  return getRealtimeIndicator(space, frameRate, sceneStart, sceneEnd).concat(subRanges);
}

function isWeatherDataMapLayer(obj: any): obj is WeatherDataMapLayer {
  return (
    typeof obj === 'object' &&
    obj !== null &&
    typeof obj.id === 'string' &&
    typeof obj.creationTime === 'number' &&
    typeof obj.zindex === 'number' &&
    Array.isArray(obj.timeControls) &&
    obj.timeControls.every((tc: any) => typeof tc === 'object') && // Adjust this check based on TimeControlDef shape
    Array.isArray(obj.dataFrames) &&
    obj.dataFrames.every((df: any) => typeof df === 'object') && // Adjust this check based on DataFrameDef shape
    Array.isArray(obj.unLoadedDataFrames) &&
    obj.unLoadedDataFrames.every((udf: any) => typeof udf === 'object') && // Adjust this check based on DataFrameDef shape
    typeof obj.enabled === 'boolean' &&
    typeof obj.name === 'string' &&
    typeof obj.layerType === 'string' &&
    obj.layerSetup instanceof WeatherDataMapLayerSetup &&
    (obj.dataFrameDensity === null || obj.dataFrameDensity === 'hourly') &&
    typeof obj.dataFramesDensity === 'number' &&
    typeof obj.minZoom === 'number' &&
    typeof obj.maxZoom === 'number' &&
    typeof obj.baseMapUrl === 'string' &&
    (typeof obj.enableInterpolation === 'undefined' ||
      typeof obj.enableInterpolation === 'boolean') &&
    (typeof obj.isContouring === 'undefined' || typeof obj.isContouring === 'boolean') &&
    typeof obj.opacity === 'number' &&
    typeof obj.realtime === 'boolean' &&
    typeof obj.indicatorPriority === 'number'
  );
}

function splitRangeRealtime(
  start: number,
  end: number,
  layer: WeatherDataMapLayer | ObservedWDElementDef,
  dateFormat: string,
  timeDateFormat: string,
  model: TextPanelDef,
  rounded: boolean,
  frameRate: number,
  startDate?: number | null,
  endDate?: number | null,
) {
  function isObserved(obj: any): obj is ObservedWDElementDef {
    return 'observedWDSource' in obj;
  }
  function isForecast(obj: any): obj is ForecastWDElementDef {
    return 'observedWDSource' in obj;
  }
  const frs = [];
  if (isObserved(layer)) {
    frs.push(
      ...[
        moment(layer.observedWDSource.utcDate).startOf('day').valueOf() / 1000,
        moment(layer.observedWDSource.utcDate).endOf('day').valueOf() / 1000,
      ],
    );
  } else if (isForecast(layer)) {
    const validity = layer.forecastWDSource.daily ? 'day' : 'hour';
    frs.push(
      ...[
        moment(layer.forecastWDSource.utcDate).startOf(validity).valueOf() / 1000,
        moment(layer.forecastWDSource.utcDate).endOf('day').valueOf() / 1000,
      ],
    );
  } else layer.enabled && frs.push(...layer.dataFrames.map((fr) => fr?.timestamp));
  const timeStart = startDate ? startDate / 1000 : Math.min(...frs);
  const timeEnd = endDate ? endDate / 1000 : Math.max(...frs);
  const n = isObserved(layer) ? 1 : layer.dataFrames.length - 1;
  const rangeSize = (end - start) / n;
  const subRanges = [];
  const dataFrames = () => {
    if (isObserved(layer)) {
      return [
        { timestamp: moment(layer.observedWDSource.utcDate).startOf('day').valueOf() / 1000 },
        { timestamp: moment(layer.observedWDSource.utcDate).endOf('day').valueOf() / 1000 },
      ];
    } else if (isForecast(layer)) {
      const validity = layer.forecastWDSource.daily ? 'day' : 'hour';
      return [
        { timestamp: moment(layer.forecastWDSource.utcDate).startOf(validity).valueOf() / 1000 },
        { timestamp: moment(layer.forecastWDSource.utcDate).endOf(validity).valueOf() / 1000 },
      ];
    } else return layer.dataFrames;
  };
  for (let i = 0; i < n + 1; i++) {
    const subRangeStart = start + i * rangeSize;
    let subRangeEnd = subRangeStart + rangeSize;
    if (subRangeEnd > end) {
      subRangeEnd = end;
    }
    try {
      const timeVal = moment(dataFrames()[i].timestamp * 1000);
      const def = {
        value: moment(i < n ? timeVal : timeEnd * 1000)
          .tz(zone)
          .format(dateFormat),
        dateValue: moment(i < n ? timeVal : timeEnd * 1000)
          .tz(zone)
          .format(timeDateFormat),
        timeControls: {
          ...model.timeControls[0],
          startMS: i < n ? subRangeStart : end - 1000 / frameRate,
          endMS: i === n - 1 ? subRangeEnd - 1000 / frameRate : i < n ? subRangeEnd : end + 1,
        },
      };
      subRanges.push(def);
    } catch (error) {
      console.log(`Error parsing date: ${error}`);
    }
  }
  if (timeStart === timeEnd) {
    const def = {
      //value: format(new Date(timeStart * 1000), dateFormat ?? 'dd.MM.yyyy HH:mm'),
      value: moment(timeStart * 1000)
        .tz(zone)
        .format(dateFormat),
      dateValue: moment(timeStart * 1000)
        .tz(zone)
        .format(timeDateFormat),
      timeControls: {
        ...model.timeControls[0],
        startMS: isWeatherDataMapLayer(layer) ? (layer.realtime ? start : start - 1) : start - 1,
        endMS: isWeatherDataMapLayer(layer) ? (layer.realtime ? end : start - 1) : start - 1,
      },
    };
    subRanges.push(def);
  }
  return subRanges;
}
export { enumToS, formats, splitRange, splitRangeRealtime };
