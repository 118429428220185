import { useMutation } from 'react-query';

import { DataFrameDef } from '../../../model/definitions/DataFrameDef';
import { axiosInstance } from '../axiosInstance';

export const useGetRadarValue = (oldLayer: boolean) => {
  const apiUrl = `radar-data/${oldLayer ? '' : 'v2/'}radar-value`;
  const key = [`RADAR_VALUE${oldLayer ? '' : '_V2'}`];
  return useMutation(
    key,
    async ({
      sourceId,
      dataProductId,
      location,
    }: {
      sourceId?: number;
      dataProductId?: string;
      location: [number, number, number, number];
    }) => {
      const { data } = await axiosInstance.post(apiUrl, {
        sourceId,
        dataProductId,
        location: {
          leftLongitude: location[0],
          rightLongitude: location[1],
          upperLatitude: location[2],
          lowerLatitude: location[3],
        },
      });
      return data as Array<DataFrameDef>;
    },
  );
};
