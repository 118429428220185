import { VisualisationTypeEnum } from '../../../model/enums/VisualisationTypeEnum';

export const shouldHaveInterpolateOption = (
  paramName: string,
  visualisationType?: VisualisationTypeEnum,
) => {
  const names = [
    // Disabled until isolines are moved to WebGL
    'Mean sea level pressure',
    'Medium cloud cover',
    'Total cloud cover',
    'Low cloud cover',
    'High cloud cover',
    'Total Precipitation',
    'Surface temperature',
  ];

  if (visualisationType && visualisationType == VisualisationTypeEnum.HEATMAP) {
    names.push('10m wind');
    names.push('500hPa wind');
  }

  return names.some((n) => n === paramName);
};
