import { Modal } from 'flowbite-react';
import { FC } from 'react';

import InDevelopment from '../components/in-development/InDevelopment';
import styles from './Modals.module.scss';

interface Props {
  description?: string;
  opened?: boolean;
  onClose?: (e: any) => void;
  onShowMore?: () => void;
}

const ShowMore: FC<Props> = ({ opened, onClose, description, onShowMore }) => {
  return (
    <Modal show={opened} style={{ zIndex: 999 }}>
      <div className="flex flex-col p-8">
        {description ? <span className="break-all"> {`${description}`}</span> : <InDevelopment />}

        <div className="flex justify-center">
          <button className={styles.cancel} onClick={onClose ? onClose : onShowMore}>
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ShowMore;
