import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { queryClient } from '../../..';
import { axiosInstance } from '../../../core/api/axiosInstance';
import { FontVariantInterface } from '../../playground/properties/panels/FontProperties';
import { enterpriseKey, fontKey, key } from './fontKeys';

export const useEditFontVariant = () => {
  return useMutation(
    async ({ fontVariant, fontId }: { fontVariant: FontVariantInterface; fontId: string }) => {
      return await axiosInstance.put(`font-variant`, fontVariant, {
        params: {
          fontId,
        },
      });
    },
    {
      onSuccess: () => {
        toast.success('Font variant updated successfully!');
        const keys = [key, enterpriseKey, fontKey];
        keys.forEach((k) => queryClient.invalidateQueries(k));
      },
      onError: () => {
        toast.error('Error while editing font variant!');
      },
    },
  );
};
