import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';

export interface FADE_INProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  duration?: number;
  enterTime?: number;
  currentTime: number;
  opacity?: number;
}
const FADE_IN: FC<FADE_INProps> = ({
  duration = 0,
  enterTime,
  currentTime = 0,
  opacity = 1,
  ...props
}: FADE_INProps) => {
  const finalTime = (enterTime ?? 0) + duration;
  const remaining = finalTime - currentTime;
  const opacityLevel =
    Math.min(Math.max((100 - (remaining * 100) / duration) / 100, 0), 1) * opacity;
  return <div style={{ opacity: opacityLevel, height: '100%' }}>{props.children}</div>;
};
export default FADE_IN;
