export const WIND_DRAW_VERT = `
attribute float a_index;

//these variable are defined in the pde
uniform sampler2D u_particles;
uniform float u_particles_res;
uniform float u_particle_size;

varying vec2 v_particle_pos;
varying vec4 v_position;


void main() {
    gl_PointSize = u_particle_size;

    vec2 po = vec2(
        fract(a_index / u_particles_res),
        floor(a_index / u_particles_res) / u_particles_res);
    vec4 color = texture2D(u_particles, po);

    // decode current particle position from the pixel's RGBA value
    v_particle_pos = vec2(
        color.r / 255.0 + color.b,
        color.g / 255.0 + color.a); // 0-1

    gl_Position = vec4(2.0 * v_particle_pos.x - 1.0, 1.0 - 2.0 * v_particle_pos.y , 0.0, 1.0);
    v_position = gl_Position;

}
`;
