import { HandleProps } from 'rc-slider/es/Handles/Handle';
import { ReactElement, ReactNode } from 'react';

const sliderHandle = (e: ReactElement<HandleProps>, key: number, value: ReactNode) => {
  return {
    ...e,
    props: {
      ...e.props,
      style: {
        ...e.props.style,
        top: 20,
        boxShadow: 'none',
        width: 20,
        height: 20,
        borderRadius: 0,
        backgroundColor: '#0054ff',
        transform: 'translateX(-50%) rotate(-45deg)',
      },
      children: [
        <div
          key={key}
          style={{
            top: -5,
            transform: 'translateX(-50%) rotate(45deg)',
            position: 'relative',
            width: 'fit-content',
            backgroundColor: '#0054ff',
            color: '#ffffff',
            padding: 2,
            borderRadius: 3,
          }}
        >
          {value}
        </div>,
      ],
    },
  };
};

export { sliderHandle };
