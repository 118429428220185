import { useMutation } from 'react-query';

import { axiosInstance } from '../../../core/api/axiosInstance';
import { IconSetTemplateItemDef } from '../../../model/definitions/IconSetTemplateItemDef';

export const useCreateIconPack = () => {
  return useMutation(
    async ({
      name,
      description,
    }: {
      name: string;
      description?: string;
    }): Promise<IconSetTemplateItemDef> => {
      const { data } = await axiosInstance.post('icon-set-template', null, {
        params: {
          name,
          description,
        },
      });
      return data;
    },
  );
};
