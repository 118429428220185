import { useMutation, useQuery } from 'react-query';

import { IconTemplateDef } from '../../../model/definitions/IconTemplateDef';
import { axiosInstance } from '../axiosInstance';

const key = ['GET_ICONS'];
export const useGetIcons = (timestamp?: number, city?: string, iconCode?: string) =>
  useQuery(
    [...key, iconCode, timestamp, city],
    async (): Promise<Array<IconTemplateDef>> => {
      const { data } = await axiosInstance.get(`icon-set-template/my-icons-by-code/`, {
        params: {
          weatherTypeCode: iconCode,
          setSearchKey: '',
          page: 0,
          size: 1000,
        },
      });
      return data.content;
    },
    {
      enabled: !!iconCode && iconCode !== 'NA',
    },
  );

export const useGetMultipleIcons = (iconCodes: string[] = [], timestamp?: number, city?: string) =>
  useQuery(
    [...key, JSON.stringify(iconCodes), timestamp, city], // Include iconCodes in the cache key
    async (): Promise<Array<{ param: string; value: any }>> => {
      const iconPromises = iconCodes.map(async (iconCode) => {
        const { data } = await axiosInstance.get(`icon-set-template/my-icons-by-code/`, {
          params: {
            weatherTypeCode: iconCode,
            setSearchKey: '',
            page: 0,
            size: 1000,
          },
        });
        return { param: iconCode, value: data.content[0] };
      });

      return Promise.all(iconPromises);
    },
    {
      enabled: iconCodes.length > 0,
    },
  );

export const useFetchIcons = () => {
  return useMutation([...key], async (iconCode?: string): Promise<Array<IconTemplateDef>> => {
    const { data } = await axiosInstance.get(`icon-set-template/my-icons-by-code/`, {
      params: {
        weatherTypeCode: iconCode,
        setSearchKey: '',
        page: 0,
        size: 1000,
      },
    });
    return data.content;
  });
};
