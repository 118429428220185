import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';

import {
  FontInterface,
  FontVariantInterface,
} from '../../pages/playground/properties/panels/FontProperties';
import { axiosInstancePublic } from './axiosInstancePublic';
import { useGetFont } from './useGetFont';
import { useGetFonts } from './useGetFonts';

const useFontLoader = (fontFamily: string) => {
  const { data: fonts } = useGetFonts();
  const [fontId, setFontId] = useState('');
  useFontIdSetter(fonts, fontFamily, setFontId);
  const { data: fontVariants } = useGetFont(fontId);
  const fetchFontVariants = useQuery(
    ['fontVariants', fontId],
    () => loadFont(fontVariants, fontFamily),
    {
      enabled: !!fontVariants,
      staleTime: Infinity,
    },
  );
  return { fonts, fontVariants, fetchFontVariants, fontId, setFontId };
};

const useFontIdSetter = (
  fonts: FontInterface[],
  fontFamily: string,
  setFontId: React.Dispatch<React.SetStateAction<string>>,
) => {
  useEffect(() => {
    if (fonts && fontFamily) {
      const findFont = fonts.find((font: FontInterface) => font.name === fontFamily);
      if (findFont) {
        setFontId(findFont.id);
      }
    }
  }, [fontFamily, fonts]);
};

const useFontSetter = (
  fontVariants: FontVariantInterface[],
  fontType: string | undefined,
  fontTypeSetter: (value: string) => void,
  fontVariantIdSetter: (value: string) => void,
  isLoading: boolean,
) => {
  useEffect(() => {
    if (!isLoading && fontVariants && !fontType && Array.isArray(fontVariants)) {
      const defaultFontVariant = fontVariants.find(
        (fontVariant: FontVariantInterface) => fontVariant.defaultType,
      );
      if (defaultFontVariant) {
        fontTypeSetter(defaultFontVariant.type);
        fontVariantIdSetter(defaultFontVariant.id);
      }
    }
  }, [fontVariants, isLoading]);
};

async function fetchFontVariant(variant: FontVariantInterface) {
  const response = await axiosInstancePublic.get(`font-variant/ttf/${variant.id}`, {
    responseType: 'blob',
  });
  return response.data;
}

async function loadFont(fontVariants: FontVariantInterface[], fontFamily: string) {
  const existingFonts = Array.from(document.fonts);
  const fontNames = fontVariants.map((fontVariant: FontVariantInterface) => {
    return `${fontFamily} ${fontVariant.type}`;
  });
  const fontExists = existingFonts.some((font) => {
    return fontNames.includes(font.family);
  }); // TODO remove if font variants
  if (!fontExists && Array.isArray(fontVariants)) {
    try {
      const fontPromises = fontVariants.map(async (fontVariant: FontVariantInterface) => {
        const fontBlob = await fetchFontVariant(fontVariant);
        const fontUrl = URL.createObjectURL(fontBlob);
        const fontFace = new FontFace(fontFamily + ' ' + fontVariant.type, `url(${fontUrl})`);
        document.fonts.add(fontFace);
        await fontFace.load();
        URL.revokeObjectURL(fontUrl);
      });
      await Promise.all(fontPromises);
    } catch (error) {
      toast.error(`Error loading font: ${fontFamily}`);
    }
  }
}
export { fetchFontVariant, useFontIdSetter, useFontLoader, useFontSetter };
export default loadFont;
