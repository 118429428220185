import { IconType } from 'react-icons';

const CustomMultimedia: IconType = (props) => {
  const { size, color } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 27 27"
      stroke="currentColor"
      strokeWidth="0"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.71429 9.42861V21.4286H22.2857V9.42861H1.71429ZM0.857143 7.71432H23.1429C23.3702 7.71432 23.5882 7.80463 23.7489 7.96537C23.9097 8.12612 24 8.34414 24 8.57146V22.2857C24 22.5131 23.9097 22.7311 23.7489 22.8918C23.5882 23.0526 23.3702 23.1429 23.1429 23.1429H0.857143C0.629814 23.1429 0.411797 23.0526 0.251051 22.8918C0.0903058 22.7311 0 22.5131 0 22.2857V8.57146C0 8.34414 0.0903058 8.12612 0.251051 7.96537C0.411797 7.80463 0.629814 7.71432 0.857143 7.71432ZM2.57143 4.28575H21.4286V6.00003H2.57143V4.28575ZM5.14286 0.857178H18.8571V2.57146H5.14286V0.857178Z"
        fill={color}
      />
    </svg>
  );
};

export default CustomMultimedia;
