import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { queryClient } from '../../../..';
import { axiosInstance } from '../../../../core/api/axiosInstance';
import { key } from './useGetShoppingCartItems';

export const useAddToCart = () => {
  return useMutation(
    async (itemId: string) => {
      return await axiosInstance.post(`/order-item/${itemId}`);
    },
    {
      onSuccess: () => {
        toast.success(`Item successfully added to shopping cart`);
        queryClient.invalidateQueries(key);
      },
      onError: (error: any) => {
        toast.error(error.response.data.message);
      },
    },
  );
};
