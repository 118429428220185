import { useQuery } from 'react-query';

import { axiosInstance } from '../../../core/api/axiosInstance';

export const key = ['ACCOUNTS'];
export const useGetAccounts = (api: string) => {
  return useQuery([...key, api], async () => {
    const res = await axiosInstance.get(api);
    return res.data;
  });
};
