import './styles.scss';

import { InputHTMLAttributes } from 'react';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  onInputChange: (e: any) => void;
  precision?: number;
}

// TODO: Switch to antd number input component as soon as possible
const InputNumber = ({ onInputChange, ...rest }: Props) => {
  const onChange = (value: string) => {
    let number = Number(value);
    if (rest.min != undefined && rest.min > number) {
      number = Number(rest.min);
    }
    if (rest.max != undefined && rest.max < number) {
      number = Number(rest.max);
    }
    onInputChange(number);
  };

  return (
    <input
      {...rest}
      type="number"
      className="input-wrap"
      onChange={(e) => onChange(e.target.value)}
      value={rest.value}
    />
  );
};

export default InputNumber;
