import './style.scss';

import { InputNumber, Slider as AntSlider } from 'antd';
import { SliderRangeProps } from 'antd/es/slider';

interface SliderRangeGroupProps extends SliderRangeProps {
  title: string;
  sliderValue: [number, number];
  setSliderValue: React.Dispatch<React.SetStateAction<[number, number]>>;
}

const Slider = ({ title, sliderValue, setSliderValue, ...props }: SliderRangeGroupProps) => {
  return (
    <div className="pb-8">
      <div className="ant-checkbox-group-title">{title}</div>
      <div className="flex items-center justify-between">
        <InputNumber
          value={sliderValue[0]}
          className="w-full rounded-none"
          onChange={(e: number | null) =>
            e && e < sliderValue[1] && e > -1 && setSliderValue([e, sliderValue[1]])
          }
        />
        <div className="flex items-center justify-center h-full mx-2">-</div>
        <InputNumber
          value={sliderValue[1]}
          className="w-full rounded-none"
          onChange={(e: number | null) =>
            e && e > sliderValue[0] && setSliderValue([sliderValue[0], e])
          }
        />
      </div>
      <AntSlider {...props}></AntSlider>
    </div>
  );
};

export default Slider;
