import { useEffect, useState } from 'react';

import { getNormalisedFrames } from '../../../../helpers/math';
import { DataFrameDef } from '../../../../model/definitions/DataFrameDef';
import { framesInBetween } from './nearestDataFrame';

export const useDefaultFrames = (
  data: Array<DataFrameDef> | undefined,
  dataFrames: Array<DataFrameDef>,
  creationTime: number,
) => {
  const [selectedFrames, setSelectedFrames] = useState<[number, number] | undefined>();
  const offset1 = creationTime - dataFrames[0]?.timestamp * 1000;
  const offset2 = creationTime - dataFrames[dataFrames.length - 1]?.timestamp * 1000;
  useEffect(() => {
    const frs =
      getNormalisedFrames(data ?? []) &&
      framesInBetween(
        getNormalisedFrames(data ?? []),
        dataFrames[0],
        dataFrames[dataFrames.length - 1],
        offset1 / 1000,
      );
    const firstFrame = frs && frs[0]?.timestamp;
    const lastFrame = frs && frs[frs.length - 1]?.timestamp;
    const firstIndex = getNormalisedFrames(data ?? [])?.findIndex(
      (frame) => frame?.timestamp === firstFrame,
    );
    const lastIndex = getNormalisedFrames(data ?? [])?.findIndex(
      (frame) => frame?.timestamp === lastFrame,
    );
    firstIndex && lastIndex && setSelectedFrames([firstIndex, lastIndex]);
  }, [data, dataFrames, offset1, offset2]);

  return { selectedFrames };
};
