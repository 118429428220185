import { Modal } from 'flowbite-react';

import Button from '../../../../atoms/button/Button';

interface ChangeIconModalProps {
  open: boolean;
  onClose: () => void;
}
export const ChangeIconModal = ({ onClose, open }: ChangeIconModalProps) => {
  return (
    <Modal show={open} onClose={onClose}>
      <Modal.Header style={{ background: '#1f2a40', color: 'white' }}>Available icons</Modal.Header>
      <Modal.Body style={{ background: '#1f2a40', color: 'white' }}></Modal.Body>
      <Modal.Footer style={{ background: '#1f2a40', color: 'white' }}>
        <Button onClick={() => {}} label="Save" />
        <Button label="Cancel" style={{ backgroundColor: '#808080' }} onClick={onClose} />
      </Modal.Footer>
    </Modal>
  );
};
