import { PositionControlDef } from '../../../../model/definitions/PositionControlDef';

export const fitOther = (
  pc: PositionControlDef,
  activeAspectRatio: [number, number],
  preview: number,
) => {
  const { h, w, x, y } = pc;
  const totalW = w + x;
  const totalH = h + y;
  const ratio = totalW / totalH >= activeAspectRatio[0] / activeAspectRatio[1] ? 'wider' : 'higher';
  if (ratio === 'higher') {
    if (totalH <= preview) return { height: h, width: w };
    if (totalH >= preview) {
      const newH = preview;
      const newW = (preview * w) / h;
      return { height: newH - y, width: newW - x };
    }
  }
  if (ratio === 'wider') {
    if (w <= (preview * activeAspectRatio[0]) / activeAspectRatio[1]) {
      return { height: h, width: w };
    }
    if (w > (preview * activeAspectRatio[0]) / activeAspectRatio[1]) {
      const newW = (preview * activeAspectRatio[0]) / activeAspectRatio[1];
      const newH = (h * newW) / w;
      return { height: newH - y, width: newW - x };
    }
  }
  return { width: w, height: h };
};
