import { Select, ToggleSwitch } from 'flowbite-react';
import Slider from 'rc-slider';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useFontLoader, useFontSetter } from '../../../../core/api/useLoadFont';
import { usePropertyGridActive } from '../../../../hooks/usePropertyGridActive';
import { ForecastWDElementDef } from '../../../../model/definitions/ForecastWDElementDef';
import { ObservedWDElementDef } from '../../../../model/definitions/ObservedWDElementDef';
import { SceneDef } from '../../../../model/definitions/SceneDef';
import { TextPanelDef } from '../../../../model/definitions/TextPanelDef';
import { FontAlignmentPicker } from '../../../../molecules/FontAlignmentPicker';
import { ActiveDef, setPropertyGridActiveHash } from '../../../../store/slices/active-slice';
import {
  updateDateLayer,
  updateForecastLayer,
  updateForecastMapLayer,
  updateIndicatorLayer,
  updateLocationLayer,
  updateObservedMapLayer,
  updateOWDLayer,
  updateTextLayer,
} from '../../../../store/slices/project-slice';
import { RootState } from '../../../../store/store';
import InputNumber from '../../../marketplace-new/atoms/FormatNumber/FormatNumber';
import transformText from '../components/slatejs/transformText';
import { fontPropertiesDeps } from '../constants/propertiesConstants';
import { PaletteColorPicker } from '../mapLayersProperties/PalettecolorPicker';
import styles from '../Properties.module.scss';
import GridItem from '../shared/GridItem';
import GridWrapper from '../shared/GridWrapper';

interface FontPropertiesProps {
  layer: ObservedWDElementDef | TextPanelDef | ForecastWDElementDef;
  layerType:
    | SceneKeys<SceneDef>
    | 'mapTimeframeTextIndicator'
    | 'observedDataLayers'
    | 'forecastDataLayers';
  poster?: string;
  mapId?: string;
}

export interface FontInterface {
  createdAt: number;
  creator: string;
  description: string;
  format: string;
  id: string;
  name: string;
  updatedAt?: number;
}

export interface FontVariantInterface {
  createdAt: number;
  creator?: string;
  defaultType: boolean;
  id: string;
  type: string;
  updatedAt?: number;
  url: string;
}

type FontKeys = Leaves<TextPanelDef> & Leaves<ObservedWDElementDef>;
export const CoreFontProperties = ({ layer, layerType, poster, mapId }: FontPropertiesProps) => {
  const {
    fontSize,
    fontAlignment,
    fontColor,
    fontFamily,
    fontType,
    strokeWidth,
    strokeColor,
    textTransform,
  } = layer;
  const { activeScene, activeElement, activeMap } = useSelector<RootState>(
    (state) => state.active,
  ) as ActiveDef;

  const { fonts, fontVariants, fetchFontVariants } = useFontLoader(fontFamily);

  useFontSetter(
    fontVariants,
    fontType,
    fontTypeSetter,
    fontVariantIdSetter,
    fetchFontVariants.isLoading,
  );

  function fontTypeSetter(value: string) {
    getActionToDispatch(value, 'fontType');
  }

  function fontVariantIdSetter(value: string) {
    getActionToDispatch(value, 'fontVariantId');
  }

  const isGradient = fontColor?.toString().includes('linear-gradient');
  const { lastFocused } = usePropertyGridActive(fontPropertiesDeps);
  const [savedGradient, setSavedGradient] = useState<string>(
    'linear-gradient(45deg,rgba(255, 255, 255, 255), rgba(255, 255, 255, 0))',
  );
  const [savedColor, setSavedColor] = useState<string>('rgba(255, 255, 255, 255)');
  useEffect(() => {
    if (isGradient) setSavedGradient(fontColor);
    else setSavedColor(fontColor);
  }, [fontColor, isGradient]);
  const dispatch = useDispatch();
  function getActionToDispatch(e: number | string | boolean, name: FontKeys) {
    onFocus(name);
    switch (layerType) {
      case 'textPanels':
        dispatch(
          updateTextLayer({
            newValue: e,
            activeScene: activeScene,
            elementId: activeElement,
            propertyPath: name,
            parentId: poster,
          }),
        );
        break;
      case 'pointDates':
        dispatch(
          updateDateLayer({
            newValue: e,
            activeScene: activeScene,
            elementId: activeElement,
            propertyPath: name,
            parentId: poster,
            mapId: activeMap,
          }),
        );
        break;
      case 'pointLocation':
        dispatch(
          updateLocationLayer({
            newValue: e,
            activeScene: activeScene,
            elementId: activeElement,
            propertyPath: name,
            parentId: poster,
            mapId: activeMap,
          }),
        );
        break;
      case 'observedWDElements':
        if (!poster && mapId) {
          dispatch(
            updateObservedMapLayer({
              newValue: e,
              activeScene: activeScene,
              propertyPath: name,
              parentId: mapId,
              id: layer.id,
            }),
          );
        } else
          dispatch(
            updateOWDLayer({
              newValue: e,
              activeScene: activeScene,
              elementId: activeElement,
              propertyPath: name,
              parentId: poster,
            }),
          );
        break;
      case 'forecastWDElements':
        if (!poster && mapId) {
          dispatch(
            updateForecastMapLayer({
              newValue: e,
              activeScene: activeScene,
              propertyPath: name,
              parentId: mapId,
              id: layer.id,
            }),
          );
        } else
          dispatch(
            updateForecastLayer({
              newValue: e,
              activeScene: activeScene,
              elementId: activeElement,
              propertyPath: name,
              parentId: poster,
            }),
          );
        break;
      case 'mapTimeframeTextIndicator':
        dispatch(
          updateIndicatorLayer({
            newValue: e,
            activeScene: activeScene,
            propertyPath: name,
            parentId: poster,
          }),
        );
        break;
      default: {
        throw new Error('Invalid type');
      }
    }
  }
  const parseFontLinearGradient = (index: number) => {
    const colors = fontColor?.match(/\d+/g);
    let value: string[] = [];
    if (colors) {
      value = [
        colors[0],
        `rgba(${colors[1]}, ${colors[2]},${colors[3]},${colors[4]})`,
        `rgba(${colors[5]}, ${colors[6]},${colors[7]},${colors[8]})`,
      ];
    }
    return value[index];
  };
  const getFontGradientArray = () => {
    if (fontColor?.includes('linear')) {
      const colors = fontColor.match(/\d+/g);
      if (colors)
        return [
          colors[0],
          `rgba(${colors[1]}, ${colors[2]},${colors[3]},${colors[4]})`,
          `rgba(${colors[5]}, ${colors[6]},${colors[7]},${colors[8]})`,
        ];
    }
  };
  const onChangeFontGradient = (color: string, index: number) => {
    const newGradient = getFontGradientArray();
    if (newGradient) {
      newGradient[index] = color;
    }
    getActionToDispatch(
      newGradient
        ? `linear-gradient(${newGradient[0]}deg, ${newGradient[1]},${newGradient[2]}) text`
        : '',
      'fontColor',
    );
  };

  function onFocus(path: FontKeys) {
    dispatch(setPropertyGridActiveHash({ activeElement, focusedEl: path }));
  }

  return (
    <div className="prop-wrapper" key={layer.id}>
      <GridWrapper>
        <GridItem
          label="Size:"
          item={
            <input
              className={styles.inputWrap}
              min={0}
              type="number"
              value={fontSize}
              onChange={(e) => {
                getActionToDispatch(
                  Number(e.target.value) >= 0 ? Number(e.target.value) : 0,
                  'fontSize',
                );
              }}
              onFocus={() => onFocus('fontSize')}
              autoFocus={lastFocused === 'fontSize'}
            />
          }
        />

        <GridItem
          noBorderBg
          label="Alignment:"
          item={
            <FontAlignmentPicker
              onClick={(e) => getActionToDispatch(e, 'fontAlignment')}
              value={fontAlignment}
            />
          }
        />
        {layerType !== 'textPanels' && (
          <>
            <GridItem
              label="Font family:"
              item={
                <Select
                  className={styles.select}
                  onChange={(e) => {
                    getActionToDispatch(e.target.value, 'fontFamily');
                    getActionToDispatch('', 'fontType');
                    getActionToDispatch('', 'fontVariantId');
                  }}
                  value={fontFamily}
                >
                  {fonts?.map((font: FontInterface) => (
                    <option
                      key={`${font.name}_${layer.id}`}
                      value={font.name.split('(')[0]}
                      style={{ fontFamily: font.name.split('(')[0] }}
                    >
                      {font.name}
                    </option>
                  ))}
                </Select>
              }
            />
            <GridItem
              label="Font type:"
              item={
                <select
                  className={styles.select}
                  value={fontType}
                  onChange={(e) => {
                    const findType = fontVariants.find(
                      (fontVariant: FontVariantInterface) => fontVariant.type === e.target.value,
                    );
                    getActionToDispatch(e.target.value, 'fontType');
                    getActionToDispatch(findType.id, 'fontVariantId');
                  }}
                >
                  {Array.isArray(fontVariants) &&
                    fontVariants?.map((fontVariant: FontVariantInterface) => (
                      <option key={`${fontVariant.id}_${layer.id}`} value={fontVariant.type}>
                        {fontVariant.type}
                      </option>
                    ))}
                </select>
              }
            />
          </>
        )}
        <GridItem
          label="Stroke width:"
          item={
            <input
              className={styles.inputWrap}
              min={0}
              value={strokeWidth ?? 0}
              type="number"
              onChange={(e) =>
                getActionToDispatch(
                  Number(e.target.value) >= 0 ? Number(e.target.value) : 0,
                  'strokeWidth',
                )
              }
              step={1}
              onFocus={() => onFocus('strokeWidth')}
              autoFocus={lastFocused === 'strokeWidth'}
            />
          }
        />
        <GridItem
          noBorderBg
          label="Stroke Color:"
          item={
            <PaletteColorPicker
              value={strokeColor}
              onChange={(e) => getActionToDispatch(e, 'strokeColor')}
            />
          }
        />
        <GridItem
          noBorderBg
          label="Font gradient:"
          item={
            <ToggleSwitch
              style={{ width: 'auto' }}
              label={''}
              checked={isGradient}
              onChange={(e) => {
                e ? setSavedColor(fontColor) : setSavedGradient(fontColor);
                getActionToDispatch(e ? savedGradient : savedColor, 'fontColor');
              }}
            />
          }
        />
        {!isGradient && (
          <GridItem
            noBorderBg
            label="Font color:"
            item={
              <PaletteColorPicker
                value={fontColor}
                onChange={(e) => getActionToDispatch(e, 'fontColor')}
              />
            }
          />
        )}
      </GridWrapper>

      {isGradient && (
        <>
          <h4>Gradient</h4>
          <GridWrapper>
            <GridItem
              noBorderBg
              label="From:"
              item={
                <PaletteColorPicker
                  value={parseFontLinearGradient(1)}
                  onChange={(e) => onChangeFontGradient(e, 1)}
                  disabled={!fontColor.includes('linear-gradient')}
                />
              }
            />

            <GridItem
              noBorderBg
              label="To:"
              item={
                <PaletteColorPicker
                  value={parseFontLinearGradient(2)}
                  onChange={(e) => onChangeFontGradient(e, 2)}
                  disabled={!fontColor.includes('linear-gradient')}
                />
              }
            />

            <GridItem
              noBorderBg
              label="Degree:"
              item={
                <>
                  <Slider
                    min={0}
                    max={360}
                    value={Number(parseFontLinearGradient(0))}
                    disabled={!fontColor.includes('linear-gradient')}
                    onChange={(e) => {
                      e && typeof e === 'number' && onChangeFontGradient(e.toString(), 0);
                    }}
                  />
                  <InputNumber
                    className={styles.inputWrap}
                    max={360}
                    min={0}
                    precision={2}
                    disabled={!fontColor.includes('linear-gradient')}
                    value={parseFontLinearGradient(0)}
                    onInputChange={(e) => {
                      e >= 0 && e <= 360 && onChangeFontGradient(e.toString(), 0);
                    }}
                    onFocus={() => onFocus('fontColor')}
                    autoFocus={lastFocused === 'fontColor'}
                    type="number"
                  />
                </>
              }
            />
          </GridWrapper>
        </>
      )}
      {layerType !== 'textPanels' && (
        <GridWrapper className="mt-2">
          <GridItem
            label="Text transform:"
            item={
              <Select
                value={textTransform}
                onChange={(e) => getActionToDispatch(e.target.value, 'textTransform')}
              >
                {transformText.map((item) => (
                  <option key={item.format} value={item.format}>
                    {item.name}
                  </option>
                ))}
              </Select>
            }
          />
        </GridWrapper>
      )}
    </div>
  );
};
