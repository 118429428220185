import { useMutation } from 'react-query';
import { v4 } from 'uuid';

import { axiosInstance } from '../../../../core/api/axiosInstance';
import { getMaxZindex } from '../../../../helpers/timelineUtil';
import { C9ProjectDef } from '../../../../model/definitions/C9ProjectDef';
import { MapPanelDef } from '../../../../model/definitions/MapPanelDef';
import { TimeControlDef } from '../../../../model/definitions/TimeControlDef';
import { WeatherDataSpaceDef } from '../../../../model/definitions/WeatherDataSpaceDef';
import { fitOther } from '../repoUtil/fitContent';

const key = ['GET_MAP_FROM_REPO'];
export const useGetMapFromRepo = () => {
  return useMutation(
    key,
    async ({
      id,
      ratio,
      activeScene,
      project,
      sceneDuration,
      time,
      previewSize,
    }: {
      id: string;
      ratio: [number, number];
      activeScene: string;
      project: C9ProjectDef;
      sceneDuration: number;
      time: number;
      previewSize: number;
    }): Promise<MapPanelDef> => {
      const { data } = await axiosInstance.get(`map-template/current/${id}`);
      data.mapDef.id = v4();
      data.mapDef.templateId = data.id;
      data.mapDef.templateVersionId = data.versionId;
      const { width, height } = fitOther(data.mapDef.positionControl, ratio, previewSize);
      data.mapDef.positionControl.w = width;
      data.mapDef.positionControl.h = height;
      data.mapDef.positionControl.zindex = getMaxZindex(activeScene as string, project);
      data.mapDef.timeControls = [new TimeControlDef(time, sceneDuration)];
      data.mapDef.wdSpace?.map((space: WeatherDataSpaceDef) => {
        space.timeControls = [new TimeControlDef(time, sceneDuration)];
      });
      return data.mapDef;
    },
  );
};
