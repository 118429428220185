import { QueryKey, useMutation } from 'react-query';

import { axiosInstance } from '../../../core/api/axiosInstance';
import { queryClient } from '../../../index';
import { key as multimediaKey } from './useGetMultimedia';
import { key as projectsKey } from './useGetProjects';
import { key as templatesKey } from './useGetTemplates';

const apiURL = 'basic-update';
const useUpdateInfo = () => {
  return useMutation(
    async ({
      id,
      versionId,
      newName,
      newDescription,
      newSymbolType,
      newIsDefault,
      type,
    }: {
      id: string;
      versionId: string;
      newName: string;
      newDescription: string;
      newSymbolType?: string;
      newIsDefault?: boolean;
      type: string;
      key: string | QueryKey | readonly unknown[];
    }) => {
      const { data } = await axiosInstance.put(
        `${apiURL}/${
          type === 'POSTER_TEMPLATE'
            ? 'WEATHER_POSTER_TEMPLATE'
            : type === 'GEOPOSTER_TEMPLATE'
            ? 'WEATHER_GEO_POSTER_TEMPLATE'
            : type
        }`,
        {
          id,
          versionId,
          newName,
          newDescription,
          isDefault: newIsDefault,
          symbolType: newSymbolType,
        },
      );
      return data;
    },
    {
      onSuccess: (data, args) => {
        let key;
        const array: QueryKey = [
          'project',
          'scene',
          'weather-poster',
          'weather-geo-poster',
          'font',
          'map',
          'icon-set',
          'geoposter',
          'poster',
          'layer',
        ];
        const template = array.includes(args.key);
        if (template) {
          key = templatesKey;
        } else if (
          args.key === 'image' ||
          args.key === 'video' ||
          args.key === 'audio' ||
          args.key === 'custom_map' ||
          args.key === 'custom_vector' ||
          args.key === 'animation' ||
          args.key === 'symbol'
        ) {
          key = multimediaKey;
        } else {
          key = projectsKey;
        }
        queryClient.invalidateQueries(key);
      },
    },
  );
};

export default useUpdateInfo;
