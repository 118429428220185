import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import { DataFrameDef } from '../../../model/definitions/DataFrameDef';
import { PickerDef } from '../../../model/definitions/PickerDef';
import { axiosInstance } from '../axiosInstance';

const valuesKey = ['GRIB_VALUE'];

export const useGetGrib = (oldLayer: boolean) => {
  const [paramData, setParamData] = useState<PickerDef[]>([]);
  const [sourceData, setSourceData] = useState<PickerDef[]>([]);
  const [gribValue, setGribValue] = useState<DataFrameDef[]>([]);
  const [paramLoading, setParamLoading] = useState(false);
  const [sourceLoading, setSourceLoading] = useState(false);
  const [framesLoading, setFramesLoading] = useState(false);

  const apiUrl = `grib-data${oldLayer ? '' : '/v2'}`;
  const gribKey = [`GRIB_SOURCE_TYPE${oldLayer ? '' : '_V2'}`];
  const typeKey = [`GRIB_PARAM_TYPE${oldLayer ? '' : '_V2'}`];

  const useGetGribParamType = (bounds: [number, number, number, number]) => {
    return useQuery(
      [typeKey, bounds],
      async () => {
        setParamLoading(true);
        try {
          const { data } = await axiosInstance.post(
            `${apiUrl}/grib-parameter-type-with-visualisation`,
            {
              leftLongitude: bounds[0],
              rightLongitude: bounds[1],
              upperLatitude: bounds[2],
              lowerLatitude: bounds[3],
            },
          );
          setParamData(data);
        } catch (error) {
          // Handle error
        } finally {
          setParamLoading(false);
        }
      },
      { enabled: Boolean(bounds.length) && oldLayer },
    );
  };

  const getSource = useMutation(
    gribKey,
    async ({
      bounds,
      parameterTypeId,
    }: {
      bounds: [number, number, number, number];
      parameterTypeId?: number;
    }) => {
      setSourceLoading(true);
      try {
        const requestData: any = {
          leftLongitude: bounds[0],
          rightLongitude: bounds[1],
          upperLatitude: bounds[2],
          lowerLatitude: bounds[3],
        };
        if (oldLayer) {
          requestData.parameterTypeId = parameterTypeId;
        }
        const { data } = await axiosInstance.post(
          `${apiUrl}${oldLayer ? '/grib-source-type' : '/grib-sources'}`,
          requestData,
        );
        setSourceData(data);
      } catch (error) {
        // Handle error
      } finally {
        setSourceLoading(false);
      }
    },
  );

  const getTypes = useMutation(
    typeKey,
    async ({
      bounds,
      dataProductId,
    }: {
      bounds: [number, number, number, number];
      dataProductId: string;
    }) => {
      setParamLoading(true);
      try {
        const { data } = await axiosInstance.post(
          `${apiUrl}/grib-parameter-type-with-visualisation`,
          {
            location: {
              leftLongitude: bounds[0],
              rightLongitude: bounds[1],
              upperLatitude: bounds[2],
              lowerLatitude: bounds[3],
            },
            dataProductId,
          },
        );
        setParamData(data);
      } catch (error) {
        // Handle error
      } finally {
        setParamLoading(false);
      }
    },
  );

  const getValues = useMutation(
    valuesKey,
    async ({
      paramTypeId,
      bounds,
      dataProductId,
      sourceId,
      modelId,
    }: {
      paramTypeId: number;
      bounds: [number, number, number, number];
      dataProductId?: string;
      sourceId?: number;
      modelId?: number;
    }) => {
      setFramesLoading(true);
      try {
        const requestData: any = {
          paramTypeId,
          location: {
            leftLongitude: bounds[0],
            rightLongitude: bounds[1],
            upperLatitude: bounds[2],
            lowerLatitude: bounds[3],
          },
        };
        if (oldLayer) {
          requestData.sourceId = sourceId;
          requestData.modelId = modelId;
        } else {
          requestData.dataProductId = dataProductId;
        }
        const { data } = await axiosInstance.post(`${apiUrl}/grib-value`, requestData);
        setGribValue(data);
      } catch (error) {
        // Handle error
      } finally {
        setFramesLoading(false);
      }
    },
  );

  return {
    useGetGribParamType,
    getSource: getSource.mutate,
    getTypes: getTypes.mutate,
    getGribValue: getValues.mutate,
    sourceData,
    paramData,
    gribValue,
    sourceLoading,
    paramLoading,
    framesLoading,
  };
};

export default useGetGrib;
export { valuesKey };
