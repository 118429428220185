import { RefObject } from 'react';

import { TimeStepEnum } from '../../model/enums/TimeStepEnum';

const translateText = (
  w: number,
  aspectRatio: [number, number],
  time: number,
  ref: RefObject<HTMLDivElement>,
  speed: number,
  startMS: number,
  previewHeight: number,
  direction: string,
): string => {
  const containerWidth = transformPercentToAbsolute(w, aspectRatio, 'width', previewHeight);
  const movement = ((time - startMS) / 100) * speed + containerWidth;
  const crawlWidth = ref.current?.scrollWidth ? ref.current.scrollWidth : 0;
  const repeat = Math.floor(Math.abs(containerWidth - movement) / (containerWidth + crawlWidth));
  return direction === 'right'
    ? `translate(${movement - (containerWidth + crawlWidth) * repeat - containerWidth * 2}px)`
    : `translate(${
        containerWidth - movement + (containerWidth + crawlWidth) * repeat + containerWidth
      }px)`;
};

const transformAbsoluteToPercent = (
  value: number,
  aspectRatio: [number, number],
  direction: 'width' | 'height',
  previewHeight: number,
  canvas?: { w?: number; h?: number },
) => {
  const [aspectW, aspectH] = aspectRatio;
  const noParentWidth = (previewHeight * aspectW) / aspectH;
  const canvasWidth = canvas?.w ?? noParentWidth;
  const canvasHeight = canvas?.h ?? previewHeight;
  switch (direction) {
    case 'width': {
      return parseFloat(((value / canvasWidth) * 100).toFixed(3));
    }
    case 'height': {
      return parseFloat(((value / canvasHeight) * 100).toFixed(3));
    }
  }
};

const transformPercentToAbsolute = (
  percent: number,
  aspectRatio: [number, number],
  direction: 'width' | 'height',
  previewHeight: number,
  canvas?: { w?: number; h?: number },
) => {
  const [aspectW, aspectH] = aspectRatio;
  const noParentWidth = (previewHeight * aspectW) / aspectH;
  const canvasWidth = canvas?.w ?? noParentWidth;
  const canvasHeight = canvas?.h ?? previewHeight;
  switch (direction) {
    case 'width': {
      return parseFloat(((percent / 100) * canvasWidth).toFixed(3));
    }
    case 'height': {
      return parseFloat(((percent / 100) * canvasHeight).toFixed(3));
    }
  }
};

const removeDuplicateIds = (objects: { id?: string }[]): { id?: string }[] => {
  const encounteredIds = new Map<string, number>();

  for (let i = objects.length - 1; i >= 0; i--) {
    const object = objects[i];

    if (Object.prototype.hasOwnProperty.call(object, 'id')) {
      const id = object.id!;

      if (encounteredIds.has(id)) {
        const storedIndex = encounteredIds.get(id)!;
        if (i < storedIndex) {
          objects.splice(i, 1);
        } else {
          encounteredIds.set(id, i);
        }
      } else {
        encounteredIds.set(id, i);
      }
    }
  }

  return objects;
};

function roundToClosestInterval(date: number, interval: TimeStepEnum): number {
  const msInInterval = (() => {
    switch (interval) {
      case TimeStepEnum.MIN_1:
        return 60 * 1000;
      case TimeStepEnum.MIN_5:
        return 5 * 60 * 1000;
      case TimeStepEnum.MIN_10:
        return 10 * 60 * 1000;
      case TimeStepEnum.MIN_15:
        return 15 * 60 * 1000;
      case TimeStepEnum.MIN_30:
        return 30 * 60 * 1000;
      case TimeStepEnum.HOURLY:
        return 60 * 60 * 1000;
      case TimeStepEnum.DAILY:
        return 24 * 60 * 60 * 1000;
      case TimeStepEnum.WEEKLY:
        return 7 * 24 * 60 * 60 * 1000;
      default:
        throw new Error('Invalid interval');
    }
  })();

  const remainder = date % msInInterval;
  return remainder < msInInterval / 2 ? date - remainder : date + (msInInterval - remainder);
}
function generateTimestamps(
  startDate: number | null,
  endDate: number | null,
  interval: TimeStepEnum,
): number[] {
  if (!startDate || !endDate) {
    return [];
  }

  const start = startDate;
  const end = endDate;
  const timestamps: number[] = [];

  let step: number;
  switch (interval) {
    case TimeStepEnum.MIN_1:
      step = 60 * 1000;
      break;
    case TimeStepEnum.MIN_5:
      step = 5 * 60 * 1000;
      break;
    case TimeStepEnum.MIN_10:
      step = 10 * 60 * 1000;
      break;
    case TimeStepEnum.MIN_15:
      step = 15 * 60 * 1000;
      break;
    case TimeStepEnum.MIN_30:
      step = 30 * 60 * 1000;
      break;
    case TimeStepEnum.HOURLY:
      step = 60 * 60 * 1000;
      break;
    case TimeStepEnum.DAILY:
      step = 24 * 60 * 60 * 1000;
      break;
    case TimeStepEnum.WEEKLY:
      step = 7 * 24 * 60 * 60 * 1000;
      break;
    default:
      throw new Error('Invalid interval');
  }

  for (let current = start; current <= end; current += step) {
    timestamps.push(current);
  }

  return timestamps;
}

function displayTimestampsEvenly(
  timestamps: number[],
  range: { min: number; max: number },
  time: number,
): number {
  const rangeDuration = range.max - range.min;
  const numIntervals = timestamps.length - 1;
  const intervalDuration = rangeDuration / numIntervals;
  const timeOffset = time - range.min;
  const intervalIndex = Math.floor(timeOffset / intervalDuration);
  const clampedIndex = Math.min(Math.max(intervalIndex, 0), timestamps.length - 1);
  return timestamps[clampedIndex];
}

export {
  displayTimestampsEvenly,
  generateTimestamps,
  removeDuplicateIds,
  roundToClosestInterval,
  transformAbsoluteToPercent,
  transformPercentToAbsolute,
  translateText,
};
