import { useSelector } from 'react-redux';

import { C9ProjectDef } from '../../../model/definitions/C9ProjectDef';
import { ForecastWDSourceDef } from '../../../model/definitions/ForecastWDSourceDef';
import { ActiveDef } from '../../../store/slices/active-slice';
import { RootState } from '../../../store/store';
import { PropertySection } from './components/PropertySection';
import { ForecastSpecifics } from './ForecastProperties/ForecastSpecifics';

interface GeoForecastPropertiesProps {
  element: string;
}
export const GeoForecastProperties = ({ element }: GeoForecastPropertiesProps) => {
  const project = useSelector<RootState, C9ProjectDef>((state) => state.project.present.project);
  const { activePoster, activeMap, activeScene } = useSelector<RootState, ActiveDef>(
    (state) => state.active,
  );
  const point = project.sceneDefs
    .find((scene) => scene.id === activeScene)
    ?.mapPanels.find((map) => map.id === activeMap)
    ?.geoPosters.find((poster) => poster.id === activePoster)
    ?.forecastWDElements.find((point) => point.id === element);
  // const { name, description, forecastWDSource, displayUnitOfMeasurement } = element;
  return (
    <PropertySection label={point?.name ?? ''} isOpened={true}>
      <ForecastSpecifics
        name={point?.name ?? ''}
        description={point?.description ?? ''}
        source={point?.forecastWDSource ?? new ForecastWDSourceDef()}
        displayUnitOfMeasurement={!!point?.displayUnitOfMeasurement}
      />
    </PropertySection>
  );
};
