import './PaletteLegend.scss';

import { useCallback, useEffect, useRef } from 'react';

import { ColorPaletteDef } from '../../model/definitions/ColorPaletteDef';
import { OrientationTypeEnum } from '../../model/enums/OrientationTypeEnum';
import { VisualisationTypeEnum } from '../../model/enums/VisualisationTypeEnum';
import { generateGradient, getRgba, TempRange } from './utils';

interface Props {
  palette: ColorPaletteDef;
  intervalSize: number | null;
  min?: number;
  max?: number;
  orientation: OrientationTypeEnum;
  values?: boolean;
  width?: number;
  height?: number;
  visualisationType?: string;
}

function PaletteLegend({
  palette,
  intervalSize,
  max = TempRange.max,
  min = TempRange.min,
  orientation,
  values,
  width = 20000,
  height,
  visualisationType,
}: Props) {
  const isHorizontal = orientation === OrientationTypeEnum.HORIZONTAL;
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const helperCanvasRef = useRef<HTMLCanvasElement>(null);
  const steps = Object.keys(palette.colorStops.pallet).length - 1;
  const gradientMarkSize = (isHorizontal ? width ?? 1000 : height ?? 250) / steps;

  const gradientWidth = steps * gradientMarkSize;
  const markSize = (isHorizontal ? width : height ?? 250) / steps; //(marks.length - 1);

  const drawScale = useCallback(() => {
    const canvas = canvasRef.current;
    const helperCtx = helperCanvasRef.current?.getContext('2d');
    const ctx = canvas?.getContext('2d');
    if (canvas && ctx && Object.keys(palette.colorStops.pallet).length !== 0) {
      //@ts-ignore
      ctx.reset();
      //@ts-ignore
      helperCtx.reset();
      const colors = palette && generateGradient(palette, intervalSize, min, max);
      if (intervalSize && intervalSize > 0) {
        const sortedColors = Object.entries(colors).sort(([a], [b]) => Number(a) - Number(b));
        //draw legend boxes
        sortedColors.forEach(([unit, cls], index) => {
          const particleSize = markSize / cls.length;
          let x = 0;
          cls.forEach((c) => {
            ctx.fillStyle = c.toString() || 'black';
            if (isHorizontal) {
              ctx.fillRect(x + index * markSize, 0, particleSize, canvas.height);
            } else {
              ctx.fillRect(0, x + index * markSize, canvas.height, particleSize);
            }
            x += particleSize;
          });
        });
      } else {
        //draw gradient background
        const col = isHorizontal
          ? ctx.createLinearGradient(0, 0, gradientWidth, 0)
          : ctx.createLinearGradient(0, 0, 0, height ?? canvasRef.current?.height);
        const points = Object.keys(palette.colorStops.pallet)
          .map((item) => parseFloat(item).toFixed(item.split('.')[1]?.length) as unknown as number)
          .filter((key) => Number(key) >= min && Number(key) <= max)
          .sort((a, b) => (isHorizontal ? a - b : b - a));
        if (min !== max) {
          points.forEach((p) => {
            col.addColorStop((p - min) / (max - min), getRgba(palette.colorStops.pallet[`${p}`]));
          });
        } else {
          col.addColorStop(0, getRgba(palette.colorStops.pallet[`${points[0]}`]));
        }
        if (visualisationType === VisualisationTypeEnum.ISOLINE) {
          ctx.fillStyle = col;
        }
        ctx.fillStyle = col;
        isHorizontal
          ? ctx.fillRect(0, 0, 19500, canvas.height)
          : ctx.fillRect(0, 0, canvas.height, 19500);
      }
      //draw scale and labels
      if (values !== false) {
        if (isHorizontal) {
          Object.entries(colors)
            .sort(([a], [b]) => Number(a) - Number(b))
            .forEach(([unit, cls], index) => {
              ctx.fillStyle = '#fff';
              ctx.shadowBlur = 0;
              index !== 0
                ? ctx.fillRect(index * markSize, canvas.height - 10, 1, 10)
                : index === 0
                ? ctx.fillRect(0, canvas.height - 10, 1, 10)
                : null;
              ctx.font = `${canvas.height / 4 < 24 ? canvas.height / 4 : 24}px Arial`;
              ctx.textAlign = index !== 0 ? 'center' : 'left';
              ctx.textBaseline = 'middle';
              ctx.fillStyle = '#FFFFFF';
              ctx.shadowColor = 'black';
              ctx.shadowBlur = 5;
              ctx.fillText(
                `${Number(unit)}`,
                index !== Object.entries(colors).length - 1
                  ? index * markSize
                  : index * markSize - (canvas.height / 4 < 24 ? canvas.height / 4 : 24),
                canvas.height / 2,
              );
            });
        } else {
          //const lastIndex = Object.entries(colors).length - 1;
          Object.entries(colors)
            .sort(([a], [b]) => Number(b) - Number(a))
            .forEach(([unit, cls], index) => {
              helperCtx!.fillStyle = '#fff';
              helperCtx!.shadowBlur = 0;
              /* index !== lastIndex &&  */ helperCtx!.fillRect(
                canvas.width - 5,
                index * markSize,
                8,
                1,
              );
              helperCtx!.font = `${canvas.width / 4 < 24 ? canvas.width / 4 : 24}px Arial`;
              helperCtx!.textAlign = 'center';
              helperCtx!.textBaseline = index === 0 ? 'top' : 'middle';
              helperCtx!.fillStyle = '#ffffff';
              helperCtx!.shadowColor = 'black';
              helperCtx!.shadowBlur = 5;
              helperCtx!.fillText(
                `${Number(unit)}`,
                canvas.width / 2,
                index * markSize /* -
                  (index === lastIndex ? (canvas.height / 4 < 24 ? canvas.height / 4 : 16) : 0), */,
              );
            });
        }
      }
    }
  }, [
    palette,
    intervalSize,
    min,
    max,
    values,
    markSize,
    isHorizontal,
    gradientWidth,
    height,
    visualisationType,
  ]);

  useEffect(() => {
    drawScale();
  }, [drawScale, values]);

  return (
    <div className="palette-legend relative">
      <canvas
        style={{ transform: isHorizontal ? '' : 'rotate(180deg)' }}
        width={width}
        height={height ? height : width / 15}
        ref={canvasRef}
      />
      <canvas
        style={{ position: 'absolute', top: '0px', left: '0px', backgroundColor: 'transparent' }}
        width={width}
        height={height ? height : width / 15}
        ref={helperCanvasRef}
      />
    </div>
  );
}

export default PaletteLegend;
