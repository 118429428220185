import './style.scss';

import { useContext, useEffect, useRef, useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useDebounce } from '../../../hooks/useDebounce';
import { SortingOldWorkspaceEnum } from '../../../model/enums/SortingEnum';
import Sorting from '../../marketplace-new/molecules/Sorting/Sorting';
import GridLayout from '../layout-NEW/GridLayout';
import { useDeletePurchasedItem } from '../queries-NEW/useDeletePurchaseItem';
import useGetPurchasedTemplates from '../queries-NEW/useGetPurchasedTemplates';
import { getTemplateType } from './DetailsPageWS';
import SortContext from './sortContext';

const PurchasedTemplates = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(0);
  const [search, setSearch] = useState<string>('');
  const searchKey = useDebounce(search, 300);
  const searchRef = useRef<HTMLInputElement>(null);
  const { type } = useParams() as unknown as {
    type: 'scene' | 'map' | 'project' | 'poster' | 'geoposter' | 'icon-set';
  };
  const { sortAscDesc, sortByField, handleSort } = useContext(SortContext);
  const deleteTemplate = useDeletePurchasedItem();
  const {
    data: templates,
    isLoading,
    isError,
    error,
    refetch,
  } = useGetPurchasedTemplates({ page, type, searchKey, sortByField, sortAscDesc });

  useEffect(() => {
    setSearch('');
    setPage(0);
  }, [type]);

  useEffect(() => {
    if (page !== 0) {
      const timer = setTimeout(() => {
        setPage(0);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [search]);

  useEffect(() => {
    refetch();
  }, [page, searchKey, sortAscDesc, sortByField, refetch]);

  const onDelete = (id: string) => {
    deleteTemplate.mutate(
      { id, templateType: getTemplateType(type), multimedia: false },
      {
        onSuccess: async () => {
          if (templates?.content.length === 1 && page > 0) {
            setPage(page - 1);
          }
          toast.success('Item deleted');
        },
        onError: async (error: any) => {
          if (error?.response?.status === 403) {
            toast.error('You are not allowed to delete item');
          } else {
            toast.error('Error deleting item');
          }
        },
      },
    );
  };

  return (
    <>
      <div className="flex ws-fixed-header">
        <div className={'ws-temp-title'}>
          <h1>
            {type !== 'geoposter'
              ? type.charAt(0).toUpperCase() + type.substring(1).replace('-', ' ') + 's'
              : 'Composites'}
          </h1>
        </div>
        <div className={'ws-input '} style={{ height: '40px' }}>
          <input
            ref={searchRef}
            value={search}
            autoFocus
            onChange={(e) => setSearch && setSearch(e.target.value)}
            placeholder={'Type here...'}
            className="w-full 2xl:!w-[300px]"
          />
          <AiOutlineSearch color={'rgba(0,0,0,0.3)'} size={24} />
        </div>
        {templates?.content && templates?.content.length > 1 && (
          <div className="ml-auto">
            <Sorting
              sortAscDesc={sortAscDesc}
              sorting={SortingOldWorkspaceEnum}
              handleSort={handleSort}
              sortByField={sortByField}
            />
          </div>
        )}
      </div>
      <GridLayout
        searchString={searchKey}
        link={(id: string) => {
          type === 'icon-set'
            ? navigate(`/workspace/templates/icon-set/preview/${id}`)
            : navigate(`preview/${id}`);
        }}
        error={error}
        loading={isLoading}
        isError={isError}
        pages={templates?.totalPages}
        currentPage={page}
        changePage={(e) => setPage(e)}
        onSearch={setSearch}
        contents={templates?.content}
        type={type}
        onDelete={(id) => onDelete(id)}
      />
    </>
  );
};

export default PurchasedTemplates;
