import { QueryKey, useQuery } from 'react-query';

import { axiosInstance } from '../../../core/api/axiosInstance';

export const key: QueryKey = ['MARKETPLACE_SIMILAR_ITEMS'];

export const useSimilarItems = (id: string, auth: boolean) => {
  return useQuery(
    [...key, id],
    async () => {
      const res = await axiosInstance.get(`marketplace/similar-items/${id}`);
      return res.data;
    },
    {
      enabled: auth,
    },
  );
};
