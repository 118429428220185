import './style.scss';

import { UserOutlined } from '@ant-design/icons';
import { useKeycloak } from '@react-keycloak/web';
import { Avatar } from 'antd';
import { useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import { AiOutlineLogout, AiOutlineSetting, AiOutlineUser } from 'react-icons/ai';
import { FaAppStore, FaRegUserCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

interface UserAction {
  align?: boolean;
}

const UserActions = ({ align }: UserAction) => {
  const { keycloak } = useKeycloak();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  function onSignOut() {
    keycloak.logout({
      redirectUri: `${process.env.REACT_APP_BASE_URL}/marketplace`,
    });
  }

  function navigateTo(route: string) {
    navigate(route);
  }

  return (
    <div className={'ws-user-wrapper'} onClick={() => setIsOpen(true)}>
      <Avatar
        size={40}
        icon={<UserOutlined />}
        style={{ cursor: 'pointer', marginRight: align ? 28 : 0 }}
      />
      {isOpen && (
        <ClickAwayListener onClickAway={() => setIsOpen(false)}>
          <div className={'ws-user-dropdown'}>
            <div className={'ws-user-item'}>
              <p>
                <AiOutlineUser />
                {keycloak.tokenParsed?.name}
              </p>
            </div>
            <div
              className={'ws-user-item'}
              onClick={() => navigateTo('/workspace/profile-settings')}
            >
              <p>
                <AiOutlineSetting />
                Profile Settings
              </p>
            </div>
            <div
              className={'ws-user-item'}
              onClick={() => navigateTo('/marketplace/my-store/items')}
            >
              <p>
                <FaAppStore />
                My store
              </p>
            </div>
            <div className={'ws-user-item'} onClick={() => navigateTo('/marketplace/my-profile')}>
              <p>
                <FaRegUserCircle />
                My profile
              </p>
            </div>
            <div className={'ws-user-item'} onClick={() => onSignOut()}>
              <p>
                <AiOutlineLogout />
                Sign Out
              </p>
            </div>
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default UserActions;
