import { AxiosError } from 'axios';
import { QueryKey, useQuery, UseQueryResult } from 'react-query';

import { axiosInstance } from '../../../core/api/axiosInstance';
import { C9ProjectPreviewDTO } from '../../../model/DTO/C9ProjectPreviewDTO';
import { QueryInfoMetadata } from '../../../model/other/QueryInfoMetadata';

export const key: QueryKey = ['GET_REPO_MULTIMEDIA'];

const useGetRepoMultimedia = ({
  page,
  type,
  size = 20,
  searchKey,
  sortByField,
  sortAscDesc,
}: {
  page: number;
  type: 'video' | 'audio' | 'image' | 'animation' | 'symbol';
  size?: number;
  searchKey?: string;
  sortByField?: string;
  sortAscDesc?: string;
}): UseQueryResult<QueryInfoMetadata<C9ProjectPreviewDTO>, AxiosError> => {
  const isEnabled =
    Boolean(type) &&
    (type.toUpperCase() === 'AUDIO' ||
      type.toUpperCase() === 'VIDEO' ||
      type.toUpperCase() === 'IMAGE' ||
      type.toUpperCase() === 'ANIMATION' ||
      type.toUpperCase() === 'SYMBOL');
  return useQuery(
    [...key, page, searchKey, type, sortByField, sortAscDesc],
    async () => {
      const { data } = await axiosInstance.get('multimedia/my-repo-preview', {
        params: { page, size, searchKey, type: type.toUpperCase(), sortByField, sortAscDesc },
      });
      return data;
    },
    { enabled: isEnabled },
  );
};

export default useGetRepoMultimedia;
