import { useQuery } from 'react-query';

import { axiosInstance } from '../axiosInstance';

const key = ['TRANSLATION_FILE'];
export const useGetTranslation = (id: string) =>
  useQuery(
    [...key, id],
    async () => {
      const { data } = await axiosInstance.get(`translations/${id}/file`);
      return data;
    },
    {
      enabled: !!id,
    },
  );
