export class MapPositionControlDef {
  longitude: number;
  latitude: number;
  zoom: number;
  pitch?: number;
  bearing?: number;
  defaultZoom: number; // zoom for fixed height of 350px
  updateView? = true; // should update map view on change
  flyToInitialPosition? = 1;
  constructor(lon: number, lat: number) {
    this.longitude = lon;
    this.latitude = lat;
  }
}
