export enum SocialMedia2550 {
  INSTAGRAM_9_16_25p = 'Instagram 9:16 25p',
  INSTAGRAM_9_16_50p = 'Instagram 9:16 50p',
  INSTAGRAM_1_1_25p = 'Instagram 1:1 25p',
  INSTAGRAM_1_1_50p = 'Instagram 1:1 50p',
  INSTAGRAM_4_5_25p = 'Instagram 4:5 25p',
  INSTAGRAM_4_5_50p = 'Instagram 4:5 50p',
  TWITTER_16_9_25p = 'Twitter 16:9 25p',
  TWITTER_16_9_50p = 'Twitter 16:9 50p',
  TWITTER_9_16_25p = 'Twitter 9:16 25p',
  TWITTER_9_16_50p = 'Twitter 9:16 50p',
  TWITTER_1_1_25p = 'Twitter 1:1 25p',
  TWITTER_1_1_50p = 'Twitter 1:1 50p',
  YOUTUBE_16_9_25p = 'YouTube 16:9 25p',
  YOUTUBE_16_9_50p = 'YouTube 16:9 50p',
  YOUTUBE_9_16_25p = 'YouTube 9:16 25p',
  YOUTUBE_9_16_50p = 'YouTube 9:16 50p',
}
