import { Form, Input, InputRef } from 'antd';
import cronstrue from 'cronstrue';
import { useRef, useState } from 'react';

import { DataFormInterface } from '../molecules/DataFilterSetup';

function Cron({ form, setForm, errors }: DataFormInterface) {
  const [index, setIndex] = useState<number>();
  const inputRef = useRef<InputRef | null>(null);

  const buttons = [
    { label: 'minute', id: 'minute', index: 1 },
    { label: 'hour', id: 'hour', index: 2 },
    { label: 'day (month)', id: 'daymonth', index: 3 },
    { label: 'month', id: 'month', index: 4 },
    { label: 'day (week)', id: 'dayweek', index: 5 },
  ];

  const handleFrequencyChange = (value: string) => {
    if (form.dataFilterSetup.updateFrequency !== value) {
      setForm({ ...form, dataFilterSetup: { ...form.dataFilterSetup, updateFrequency: value } });
    }
  };
  const getDescription = () => {
    if (form.dataFilterSetup.updateFrequency) {
      try {
        return cronstrue.toString(form.dataFilterSetup.updateFrequency, {
          use24HourTimeFormat: true,
        });
      } catch (error) {
        return 'Invalid Cron Expression';
      }
    }
  };

  const handleTextClick = (value: string) => {
    if (inputRef?.current?.input) {
      const inputText = inputRef.current.input.value;
      const valueToSegments: { [key: string]: number } = {
        minute: 0,
        hour: 1,
        daymonth: 2,
        month: 3,
        dayweek: 4,
      };

      const segments = inputText.split(' ');
      const segmentIndex = valueToSegments[value];

      if (segmentIndex !== undefined) {
        const startIndex =
          segmentIndex === 0
            ? segments.slice(0, segmentIndex).join(' ').length
            : segments.slice(0, segmentIndex).join(' ').length + 1;
        const endIndex = segments.slice(0, segmentIndex + 1).join(' ').length;

        inputRef.current.setSelectionRange(startIndex, endIndex);
        inputRef.current.focus();
        setIndex(segmentIndex + 1);
      }
    }
  };

  const getAllowedValues = (index: number) => {
    switch (index) {
      case 1:
        return '0-59';
      case 2:
        return '0-23';
      case 3:
        return '1-31';
      case 4:
        return '1-2';
      case 5:
        return '0-6';
    }
  };

  const getAlternativeValues = (index: number) => {
    switch (index) {
      case 4:
        return 'JAN-DEC';
      case 5:
        return 'SUN-SAT';
    }
  };
  return (
    <>
      <p className="font-bold text-gray-700">{getDescription()}</p>
      <Form.Item
        label="Schedule"
        required
        validateStatus={getDescription() === 'Invalid Cron Expression' ? 'error' : ''}
        help={getDescription() === 'Invalid Cron Expression' && errors.updateFrequency}
      >
        <Input
          ref={inputRef}
          value={form.dataFilterSetup.updateFrequency}
          onChange={(e) => handleFrequencyChange(e.target.value)}
        />
        <div className="flex gap-2">
          {buttons.map((button) => (
            <button
              key={button.id}
              onClick={() => handleTextClick(button.id)}
              className={`${index === button.index ? 'font-bold' : ''}`}
            >
              {button.label}
            </button>
          ))}
        </div>
      </Form.Item>
      <div className="mb-10">
        <ul>
          <li>
            <span className="mr-2">*</span> any value
          </li>
          <li>
            <span className="mr-2">,</span> value list separator
          </li>
          <li>
            <span className="mr-2">-</span> range of values
          </li>
          <li>
            <span className="mr-2">/</span> step values
          </li>
          {index && (
            <>
              <li>
                <span className="mr-2">{getAllowedValues(index)}</span> allowed values
              </li>
              {index > 3 && (
                <li>
                  <span className="mr-2">{getAlternativeValues(index)}</span> alternative single
                  values
                </li>
              )}
              {index === 5 && (
                <li>
                  <span className="mr-2">7</span> sunday (non-standard)
                </li>
              )}
            </>
          )}
        </ul>
      </div>
    </>
  );
}

export default Cron;
