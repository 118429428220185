import { useQuery } from 'react-query';

import { axiosInstance } from '../../../core/api/axiosInstance';
import { key } from './useGetDataProducts';

export const useGetDataProductsByProvider = (
  id: string,
  page: number,
  size: number,
  sort: string,
  searchKey: string,
) =>
  useQuery(
    [...key, id],
    async () => {
      const { data } = await axiosInstance.get(`data-products/by-provider/${id}`, {
        params: { page, size, sort, searchKey },
      });
      return data;
    },
    {
      staleTime: Infinity,
      enabled: !!id,
    },
  );
