import './style.scss';

import { Tooltip } from 'antd';
import {
  ButtonHTMLAttributes,
  Component,
  HTMLAttributes,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { AiFillWarning, AiOutlineCopy, AiOutlineEdit, AiOutlinePlus } from 'react-icons/ai';

export interface TabItemProp {
  title: ReactNode;
  itemKey: string | number;
  children: JSX.Element | undefined;
}
const Item = ({ children }: TabItemProp) => {
  return <>{children}</>;
};

interface DebounceProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  delay: number;
  onClick: (e: any) => void;
}
const DebouncedButton = ({ delay, onClick, ...props }: DebounceProps) => {
  const [isDisabled, setDisabled] = useState(false);

  useEffect(() => {
    if (!isDisabled) {
      // timeout elapsed, nothing to do
      return;
    }

    // isDisabled was changed to true, set back to false after `delay`
    const handle = setTimeout(() => {
      setDisabled(false);
    }, delay);
    return () => clearTimeout(handle);
  }, [isDisabled, delay]);

  const handleClick = (e: any) => {
    if (isDisabled) {
      return;
    }

    setDisabled(true);
    return onClick(e);
  };

  return (
    <button {...props} disabled={isDisabled} onClick={handleClick}>
      <>{props.children}</>
    </button>
  );
};
interface TabProps extends HTMLAttributes<HTMLDivElement> {
  addItem?: () => void;
  active: string | number;
  children: JSX.Element[];
  actions?: JSX.Element | JSX.Element[];
  onClick: (e: any) => void;
  timelineActions?: any;
  deleteSceneModal?: any;
  copy?: (e: string) => void;
  edit?: (e: string) => void;
  roleAccess?: boolean;
}
class Tabs extends Component<Readonly<TabProps>, any> {
  constructor(props: any) {
    super(props);
  }

  static Item: Function;
  renderItem() {
    return (
      <>
        {this.props.children &&
          this.props.children.map(
            (child: JSX.Element) =>
              child.props.itemKey === this.props.active && (
                <Item
                  title={child.props.title}
                  itemKey={child.props.itemKey}
                  key={child.props.itemKey}
                >
                  {child}
                </Item>
              ),
          )}
      </>
    );
  }
  isActive = (key: string | number) => {
    return this.props.active === key;
  };
  renderTabButtons = () =>
    this.props?.children &&
    this.props?.children.map((item: JSX.Element) => {
      return (
        <div
          key={item?.props?.itemKey}
          onClick={() => {
            this.props?.onClick && this.props?.onClick(item.props);
          }}
          className={`relative !flex gap-2 items-center tab-btn ${
            this.isActive(item?.props?.itemKey) ? 'tab-active' : ''
          }`}
        >
          {item.props.isSceneCovered ? (
            <Tooltip
              title={
                'There are blank frames in scene setup! To remove this warning, Map, Image or Video object has to be visible during whole scene'
              }
            >
              <div className={'pulse relative'}>
                <AiFillWarning
                  style={{ color: 'red', width: 15 }}
                  className={'absolute z-10 top-o'}
                />
              </div>
            </Tooltip>
          ) : null}
          <span>{item.props.title}</span>
          <div className="tab-btns">
            {this.props.copy && this.props.roleAccess && (
              <AiOutlineCopy
                title="Copy scene"
                onClick={() => this.props.copy && this.props.copy(item.props.itemKey)}
                color={'#818a95'}
              />
            )}
            {this.props.edit && this.props.roleAccess && (
              <AiOutlineEdit
                title="Edit scene"
                onClick={() => this.props.edit && this.props.edit(item.props.itemKey)}
                color={'#818a95'}
              />
            )}
            {this.props.deleteSceneModal && this.props.deleteSceneModal}
          </div>
        </div>
      );
    });

  render() {
    return (
      <div
        className="w-full"
        style={{
          position: 'relative',
          height: '100%',
        }}
      >
        {this.props?.children?.length === 0 ? null : (
          <div className={'buttons-container'}>
            <div className={'tab-buttons'}>
              {this.renderTabButtons()}
              {this.props.addItem && (
                // @ts-ignore
                <DebouncedButton
                  delay={500}
                  id={'addTab'}
                  key={'key'}
                  onClick={() => {
                    this.props.addItem && this.props.addItem();
                  }}
                  className={`small-tab ${
                    this.props.roleAccess ? '' : 'pointer-events-none opacity-30'
                  }`}
                >
                  <AiOutlinePlus />
                </DebouncedButton>
              )}
            </div>
            {this.props.timelineActions}
          </div>
        )}
        <div className="tab-content-wrapper" style={{ ...this.props.style }}>
          {this.renderItem()}

          {this.props?.children?.length === 0 && (
            <div className="click-to-add-scene">
              <span
                onClick={() => {
                  this.props.addItem && this.props.addItem();
                }}
              >
                {' '}
                Click to add scene
              </span>
            </div>
          )}
        </div>
      </div>
    );
  }
}

Tabs.Item = Item;
export default Tabs;
