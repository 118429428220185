import './style.scss';
import 'react-circular-progressbar/dist/styles.css';

import { useContext, useEffect, useRef, useState } from 'react';
import { AiOutlinePlus, AiOutlineSearch } from 'react-icons/ai';
import { QueryKey } from 'react-query';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import Button from '../../../atoms/button/Button';
import useShareProject from '../../../core/api/useShareProject';
import { useUnlockProject } from '../../../core/api/useUnlockProject';
import { getAspectRatioFromExportFormat } from '../../../helpers/getAspectRatioFromExportFormat';
import { getFpsFromExportFormat } from '../../../helpers/getFpsFromExportFormat';
import { useDebounce } from '../../../hooks/useDebounce';
import { C9ProjectDef } from '../../../model/definitions/C9ProjectDef';
import { TimeControlDef } from '../../../model/definitions/TimeControlDef';
import { C9ProjectPreviewDTO } from '../../../model/DTO/C9ProjectPreviewDTO';
import { SortingProjectEnum } from '../../../model/enums/SortingEnum';
import { setActiveAspectRatio, setActiveFrameRate } from '../../../store/slices/active-slice';
import { addNewProject, setSavedProject } from '../../../store/slices/project-slice';
import Sorting from '../../marketplace-new/molecules/Sorting/Sorting';
import NewProjectModal from '../../playground/modals/NewProjectModal';
import GridLayout from '../layout-NEW/GridLayout';
import useCloneProject from '../queries-NEW/useCloneProject';
import useDeleteProject from '../queries-NEW/useDeleteProjects';
import useUpdateInfo from '../queries-NEW/useEditBasicInfo';
import useGetProjects, { key as getProjectsKey } from '../queries-NEW/useGetProjects';
import FilterItems from './components/FilterItems';
import SortContext from './sortContext';

export interface UpdateInterface {
  id: string;
  versionId: string;
  newName: string;
  newDescription: string;
  newSymbolType?: string;
  newIsDefault?: boolean;
  type: string;
  key: string | QueryKey | readonly unknown[];
}
const Projects = () => {
  const searchRef = useRef<HTMLInputElement>(null);
  const [page, setPage] = useState<number>(0);
  const [search, setSearch] = useState<string>('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { sortAscDesc, sortByField, handleSort } = useContext(SortContext);
  const searchKey = useDebounce(search, 300);
  const [activeTab, setActiveTab] = useState<{ value: string; label: string }>({
    value: 'USER',
    label: 'My Projects',
  });
  const {
    data: projects,
    isLoading,
    isError,
    error,
    refetch,
  } = useGetProjects({
    page: page,
    searchKey,
    sort: sortByField + ',' + sortAscDesc,
    activeTab: activeTab.value,
  });

  const deleteProject = useDeleteProject();
  const cloneProject = useCloneProject();
  const shareProject = useShareProject();
  const unlockProject = useUnlockProject();
  const updateProject = useUpdateInfo();
  const replaceExisting = false;
  const onDelete = (e: string) =>
    deleteProject.mutate(e, {
      onSuccess: async () => {
        if (projects?.content.length === 1 && page > 0) {
          setPage(page - 1);
        }
        toast.success('Item deleted');
      },
      onError: async () => toast.error('Error deleting item'),
    });
  const onShare = (id: string, type: string, replaceExisting: boolean) =>
    shareProject.mutate({ id, type, replaceExisting });
  const onUnlock = (id: string) => unlockProject.mutate(id);
  const onClone = (e: string) =>
    cloneProject.mutate(e, {
      onSuccess: async () => toast.success('Item Cloned'),
      onError: async () => toast.error('Error cloning item'),
    });
  const onUpdate = (data: UpdateInterface) =>
    updateProject.mutate(data, {
      onSuccess: async () => toast.success("Item's basic info updated"),
      onError: async () => toast.error('Error updating basic info'),
    });
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    setSearch('');
    refetch();
  }, []);

  useEffect(() => {
    if (page !== 0) {
      const timer = setTimeout(() => {
        setPage(0);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [search]);

  useEffect(() => {
    refetch();
  }, [page, searchKey, sortAscDesc, sortByField, activeTab, refetch]);
  const onNewProject = (newProject: C9ProjectDef) => {
    setOpen(false);
    const fps = getFpsFromExportFormat(newProject.projectExportFormat);
    const aspectRatio = getAspectRatioFromExportFormat(newProject.projectExportFormat);
    newProject.sceneDefs.forEach((scene) => {
      if (!scene.timeControl) {
        scene.timeControl = new TimeControlDef(0, scene.durationInMS);
      }
    });

    dispatch(addNewProject({ project: newProject }));
    dispatch(setSavedProject({ savedProject: newProject }));
    dispatch(setActiveAspectRatio({ aspectRatio }));
    dispatch(setActiveFrameRate({ fps }));
  };

  return (
    <>
      <div className={'flex ws-fixed-header'}>
        <div className="ws-fixed-header-add-project">
          <div className={'ws-temp-title'}>
            <h1>Projects</h1>
          </div>

          <Button
            className={`ws-button`}
            onClick={() => setOpen(true)}
            disabled={activeTab.value !== 'USER'}
            label="Add Project"
            icon={<AiOutlinePlus />}
            size="small"
          />
          <FilterItems activeTab={activeTab} type={'project'} setActiveTab={setActiveTab} />
        </div>
        <div className={'ws-input ws-input-project'} style={{ height: '32px' }}>
          <input
            autoFocus
            value={search}
            ref={searchRef}
            onChange={(e) => setSearch && setSearch(e.target.value)}
            placeholder={'Type here...'}
            className="w-full 2xl:!w-[300px]"
          />
          <AiOutlineSearch color={'rgba(0,0,0,0.3)'} size={24} />
        </div>
        {projects?.content && projects?.content.length > 1 && (
          <div className="ml-auto">
            <Sorting
              sortAscDesc={sortAscDesc}
              sorting={SortingProjectEnum}
              handleSort={handleSort}
              sortByField={sortByField}
            />
          </div>
        )}
      </div>
      <GridLayout
        searchString={searchKey}
        error={error}
        link={(id: string) => navigate(`/studio?projectId=${id}`)}
        detailsLink={(id: string, data: C9ProjectPreviewDTO) =>
          navigate(`projects/${id}`, { state: data })
        }
        type={'PROJECT'}
        onDelete={(id) => onDelete(id)}
        onShare={(id, type) => onShare(id, type, replaceExisting)}
        onClone={(id, versionId) => onClone(versionId)}
        onEdit={(data: Pick<UpdateInterface, 'newName' | 'newDescription' | 'id' | 'versionId'>) =>
          onUpdate({
            ...data,
            type: 'PROJECT',
            key: getProjectsKey,
          })
        }
        loading={isLoading}
        isError={isError}
        pages={projects?.totalPages}
        currentPage={page}
        changePage={(e) => setPage(e)}
        onSearch={setSearch}
        contents={projects?.content}
        activeTab={activeTab.value}
        setOpen={setOpen}
        onUnlock={(id) => onUnlock(id)}
      />
      <NewProjectModal opened={open} onClose={() => setOpen(false)} onConfirm={onNewProject} />
    </>
  );
};

export default Projects;
