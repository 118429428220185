import { useKeycloak } from '@react-keycloak/web';
import { Tooltip } from 'antd';
import { Button } from 'flowbite-react';
import moment from 'moment';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';

import CircleInput from '../../../../atoms/circle-input/CircleInput';
import LoadingIndicator from '../../../../atoms/loadingIndicator/LoadingIndicator';
import { WeatherDataHttpClient } from '../../../../core/weather-data/WeatherDataHttpClient';
import { WeatherDataLoader } from '../../../../core/weather-data/WeatherDataLoader';
import { FrameLoadingResult } from '../../../../core/weather-data/WeatherDataLoaderTypes';
import {
  SymbolLayerDef,
  SymbolLayerPointDef,
  SymbolPointType,
  SymbolSourceType,
} from '../../../../model/definitions/SymbolLayerDef';
import { PointDataOverrideDTO } from '../../../../model/DTO/PointDataOverrideDTO';
import { isWindLayer } from '../../../../molecules/mapElement/useSymbolLayers';
import { addSymbolNewOverrides } from '../../../../store/slices/active-slice';
import styles from '../Properties.module.scss';
import GridItem from '../shared/GridItem';

interface Props {
  point: SymbolLayerPointDef;
  pointValues: number[];
  currentFrame: FrameLoadingResult | undefined;
  layer: SymbolLayerDef;
  originalValues: number[];
  updatedAt?: number;
  userId?: string;
  overrideId?: number;
}

export const SymbolPointsOverrideProperties = ({
  point,
  pointValues,
  currentFrame,
  layer,
  originalValues,
  updatedAt,
  userId,
  overrideId,
}: Props) => {
  const dispatch = useDispatch();
  const urlParams = new URLSearchParams(window.location.search);
  const projectId = urlParams.get('projectId');
  const { keycloak } = useKeycloak();
  const [value1, setValue1] = useState(pointValues.length > 0 ? pointValues[0] : '');
  const [value2, setValue2] = useState(pointValues.length > 1 ? pointValues[1] : '');

  const isWind = isWindLayer(layer);
  const isPointData = layer.symbolSource.sourceType === SymbolSourceType.PointData;

  const httpClient = WeatherDataHttpClient.getInstance();
  const modelMutation = useMutation(httpClient.overrideModelData, {
    onSuccess: (_, vars) => {
      // do optimistic update of data, to avoid reloading the entire frame
      WeatherDataLoader.setOverride(
        layer.id,
        currentFrame!.frameId,
        vars.pointOverride.lat,
        vars.pointOverride.lon,
        vars.pointOverride.new_val,
        vars.pointOverride.old_val,
        Math.floor(Date.now() / 1000),
        vars.overrideParticipant.user_id as string,
      );
      dispatch(addSymbolNewOverrides());
    },
  });

  const pointMutation = useMutation(httpClient.overridePointData, {
    onSuccess: (data, vars) => {
      const newValue = vars.overrides.map((o) => o.overrideValue.newValue);
      const oldValue = vars.overrides.map((o) => o.overrideValue.oldValue);
      // do optimistic update of data, to avoid reloading the entire frame
      WeatherDataLoader.setOverrideByLocation(
        layer.id,
        currentFrame!.frameId,
        vars.overrides[0].locationId,
        newValue,
        oldValue,
        Math.floor(Date.now() / 1000),
        vars.overrideParticipant.userId as string,
        data[0].id,
      );
      dispatch(addSymbolNewOverrides());
    },
  });

  const revertPointValueMutation = useMutation(httpClient.revertPointData, {
    onSuccess: () => {
      WeatherDataLoader.setOverrideByLocation(
        layer.id,
        currentFrame!.frameId,
        point.locationId as string,
        originalValues,
        [],
        undefined,
        undefined,
      );
      dispatch(addSymbolNewOverrides());
    },
  });

  const revertModelValueMutation = useMutation(httpClient.revertModelData, {
    onSuccess: () => {
      WeatherDataLoader.setOverride(
        layer.id,
        currentFrame!.frameId,
        point.lat,
        point.lon,
        originalValues,
        [],
        undefined,
        undefined,
      );
      dispatch(addSymbolNewOverrides());
    },
  });

  const applyOverride = () => {
    if (isPointData) {
      const overrides: PointDataOverrideDTO[] = [];
      if (isWind) {
        overrides.push({
          timestamp: currentFrame?.symbolData?.frameTimestamp ?? 0,
          parameter: 'WindDirection',
          locationId: point.locationId ?? '',
          overrideValue: {
            oldValue: pointValues[0],
            newValue: Number(value1),
            unit: 'Degree',
          },
          userId: keycloak.tokenParsed?.email,
        });
        overrides.push({
          timestamp: currentFrame?.symbolData?.frameTimestamp ?? 0,
          parameter: 'WindSpeed',
          locationId: point.locationId ?? '',
          overrideValue: {
            oldValue: pointValues[1],
            newValue: Number(value2),
            unit: layer.symbolSource.unit ?? '',
          },
          userId: keycloak.tokenParsed?.email,
        });
      } else {
        overrides.push({
          timestamp: currentFrame?.symbolData?.frameTimestamp ?? 0,
          parameter: layer.symbolSource.pointParameter ?? '',
          locationId: point.locationId ?? '',
          overrideValue: {
            oldValue: pointValues[0],
            newValue: Number(value1),
            unit: layer.symbolSource.unit ?? '',
          },
          userId: keycloak.tokenParsed?.email,
        });
      }
      pointMutation.mutate({
        overrides,
        overrideParticipant: {
          projectId: projectId || undefined,
          userId: keycloak.tokenParsed?.email,
        },
        pointType: layer.symbolSource.pointType,
        dataProductId: layer.symbolSource.pointSource?.dataProductId as string,
      });
    } else {
      const newValue = isWind ? [Number(value1), Number(value2)] : [Number(value1)];
      modelMutation.mutate({
        pointOverride: {
          lat: point.lat,
          lon: point.lon,
          old_val: pointValues,
          new_val: newValue,
        },
        frameId: currentFrame?.frameId || '',
        overrideParticipant: {
          project_id: projectId || undefined,
          user_id: keycloak.tokenParsed?.email,
        },
      });
    }
  };
  let formattedDate;
  if (updatedAt) {
    const timestamp = updatedAt;
    const date = new Date(timestamp * 1000);
    formattedDate = moment(date).format('HH:mm MMM DD YYYY Z');
  }

  const revertValue = () => {
    if (isPointData) {
      if (
        (userId && overrideId && layer.symbolSource.pointType === SymbolPointType.Forecast) ||
        layer.symbolSource.pointType === SymbolPointType.Observed
      ) {
        revertPointValueMutation.mutate({
          id: overrideId as number,
          userId: userId as string,
          pointType: layer.symbolSource.pointType,
        });
      }
    } else {
      revertModelValueMutation.mutate({
        frame_id: currentFrame?.frameId || '',
        points: [
          {
            lat: point.lat,
            lon: point.lon,
          },
        ],
        override_participant: {
          project_id: projectId || undefined,
          user_id: keycloak.tokenParsed?.email,
        },
      });
    }
  };

  return (
    <>
      <GridItem
        label={isWind ? 'Override direction:' : 'Override value:'}
        noBorderBg={isWind ? true : false}
        item={
          <Tooltip title={formattedDate && userId ? `${userId} at ${formattedDate}` : ''}>
            {isWind ? (
              <div className="flex">
                <span className="w-8">{value1}</span>{' '}
                <CircleInput value={value1} setValue={setValue1} />
              </div>
            ) : (
              <input
                style={{ padding: '0' }}
                type={'number'}
                value={value1}
                onChange={(e) => setValue1(e.target.value)}
                className={styles.inputWrap}
              />
            )}
          </Tooltip>
        }
      />
      {isWind && (
        <GridItem
          label={'Override speed:'}
          item={
            <input
              style={{ padding: '0' }}
              type={'number'}
              value={value2}
              onChange={(e) => setValue2(e.target.value)}
              className={styles.inputWrap}
            />
          }
        />
      )}
      <GridItem
        label=""
        noBorderBg
        item={
          <Button
            disabled={pointMutation.isLoading || modelMutation.isLoading}
            color="info"
            onClick={applyOverride}
          >
            {(pointMutation.isLoading || modelMutation.isLoading) && <LoadingIndicator />}
            <span>Save override</span>
          </Button>
        }
      />
      {originalValues.length > 0 && (
        <GridItem
          label=""
          noBorderBg
          item={
            <Button color="info" onClick={revertValue}>
              <span>Revert value to original</span>
            </Button>
          }
        />
      )}
    </>
  );
};
