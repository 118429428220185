import AvatarStyles from './componentStyles/AvatarStyles';
import ButtonStyles from './componentStyles/ButtonStyles';
import InputStyles from './componentStyles/InputStyles';
import SelectStyles from './componentStyles/SelectStyles';
import TabsStyles from './componentStyles/TabsStyles';

const Theme = {
  token: {},
  components: {
    Button: ButtonStyles,
    Input: InputStyles,
    Tabs: TabsStyles,
    Select: SelectStyles,
    Dropdown: {},
    Avatar: AvatarStyles,
  },
};

export default Theme;
