import { Select } from 'antd';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useGetObservedDivep } from '../../core/api/observedData/useGetObservedDivep';
import { useGetValue } from '../../core/api/observedData/useGetValue';
import { IconTemplateDef } from '../../model/definitions/IconTemplateDef';
import {
  ObservedElement,
  ObservedWDElementRequestDTO,
} from '../../model/DTO/ObservedWDElementRequestDTO';
import { ValueTypeEnum } from '../../model/enums/ValueTypeEnum';
import { ObservedProperties } from '../../model/other/ObservedData';
import Loader from '../../pages/dashboard/components/Loader';
import { ActiveDef, setObservedSource } from '../../store/slices/active-slice';
import { RootState } from '../../store/store';
import { ObservedFirstStep } from './PointDataComponents/ObservedFirstStep';
import { ObservedSecondStep } from './PointDataComponents/ObservedSecondStep';

interface AddOWDProps {
  onSelect: (data: ObservedWDElementRequestDTO) => void;
  formValues: ObservedWDElementRequestDTO;
  setFormValues: (form: ObservedWDElementRequestDTO) => void;
  step?: number;
  basic: {
    name: string;
    description?: string;
    groupDate: boolean;
  };
  setBasic: (e: { name: string; description?: string; groupDate: boolean }) => void;
  timestamp?: number;
  setTimestamp?: (e: number) => void;
}
const AddObservedData = ({
  onSelect,
  formValues,
  step,
  basic,
  setBasic,
  timestamp,
  setTimestamp,
  setFormValues,
}: AddOWDProps) => {
  const dispatch = useDispatch();
  const { observedSource } = useSelector<RootState>((state) => state.active) as ActiveDef;
  const [source, setSource] = useState<string | undefined>(observedSource);
  const { data: divep, isLoading } = useGetObservedDivep();
  const [station, setStation] = useState<string>();
  const [bias, setBias] = useState<boolean>(false);
  const { data: observedData, isLoading: loadingData } = useGetValue(
    timestamp,
    station,
    bias,
    step,
    source,
  );
  const findElementByName = (name: string) =>
    formValues?.elements?.find((element) => element.parameterType === name);
  const findElementIndexByName = (name: string) =>
    formValues.elements.findIndex((num) => num.parameterType === name);
  const selectData = (property: ObservedProperties, unit: string) => {
    let elements = formValues.elements ? [...formValues.elements] : [];
    if (!findElementByName(property.name) && observedData) {
      const val = property.values; //.find((value) => value.unit === unit)?.value;
      const original = property.values.find((value) => value.unit === unit)?.originalValue;
      const measure = property.values.find((value) => value.unit === unit)?.unit;
      const element = new ObservedElement(
        observedData,
        basic?.name,
        basic?.description,
        ValueTypeEnum.NUMERICAL,
        val,
        original,
        property.name,
        measure,
      );
      elements = [...elements, element];
    } else {
      const index = findElementIndexByName(property.name);
      elements.splice(index, 1);
    }
    if (observedData)
      onSelect({
        station: observedData.station,
        elements: elements,
        groupDate: formValues.groupDate,
        groupLocation: formValues.groupLocation,
      });
  };
  const selectIcon = (icon: IconTemplateDef, remove?: boolean) => {
    let elements = formValues.elements ? [...formValues.elements] : [];
    const element = new ObservedElement(
      observedData,
      basic?.name,
      basic?.description,
      ValueTypeEnum.IMAGE,
      icon,
      icon,
      observedData?.weatherType,
    );
    const index = elements.findIndex(
      (num) => !Array.isArray(num.value) && num.value.id === icon.id,
    );
    const existingIndex = formValues?.elements?.findIndex(
      (element) => element.valueType === ValueTypeEnum.IMAGE,
    );
    if (index < 0 && existingIndex < 0 && observedData) {
      elements = [...elements, element];
    } else if (index === existingIndex && remove) {
      elements.splice(index, 1);
    } else {
      if (observedData) {
        elements[existingIndex] = element;
      }
    }
    if (observedData)
      onSelect({
        station: observedData.station,
        elements: elements,
        groupDate: formValues.groupDate,
        groupLocation: formValues.groupLocation,
      });
  };
  const options = divep?.dataProducts?.map((source) => {
    return { label: source.sourceName, value: source.dataProductId };
  });
  const extendedOptions = options?.length ? options : [{ label: 'default', value: '' }];
  return (
    <div className="form-body">
      {step === 0 && basic && setBasic && setTimestamp && (
        <>
          <label>
            Select source
            <Select
              loading={isLoading}
              options={extendedOptions}
              onChange={(e) => {
                dispatch(setObservedSource({ source: e }));
                setSource(e);
              }}
              value={source}
            />
          </label>
          {source !== undefined && (
            <ObservedFirstStep
              station={station}
              setStation={setStation}
              basic={basic}
              setBasic={setBasic}
              timestamp={timestamp ?? 0}
              setTimestamp={setTimestamp}
            />
          )}
        </>
      )}
      {step === 1 &&
        (loadingData ? (
          <Loader />
        ) : (
          observedData && (
            <ObservedSecondStep
              setFormValues={setFormValues}
              bias={bias}
              setBias={setBias}
              observedData={observedData}
              selectData={selectData}
              selectIcon={selectIcon}
              formValues={formValues}
            />
          )
        ))}
    </div>
  );
};

export default AddObservedData;
