const API_KEY = process.env.REACT_APP_MAP_KEY;

export const MapStylesHash = {
  BASIC: `https://api.maptiler.com/maps/basic-v2/{z}/{x}/{y}.png?key=${API_KEY}`,
  BRIGHT: `https://api.maptiler.com/maps/bright/{z}/{x}/{y}.png?key=${API_KEY}`,
  SATELLITE: `https://api.maptiler.com/tiles/satellite-v2/{z}/{x}/{y}.jpg?key=${API_KEY}`,
  STREETS: `https://api.maptiler.com/maps/streets/{z}/{x}/{y}.png?key=${API_KEY}`,
  // TOPOGRAPHIC: `https://api.maptiler.com/maps/topographique/style.json?key=${API_KEY}`,
};

export type MapStylesType = typeof MapStylesHash;
