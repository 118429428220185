import { useMutation } from 'react-query';

import { axiosInstance } from '../axiosInstance';

export const useApplyLayer = () => {
  return useMutation(
    async ({ id, bounds }: { id: string; bounds: [number, number, number, number] }) => {
      const { data } = await axiosInstance.post(`layer-template/${id}/apply`, {
        leftLongitude: bounds[0],
        rightLongitude: bounds[1],
        upperLatitude: bounds[2],
        lowerLatitude: bounds[3],
      });
      return data;
    },
  );
};
