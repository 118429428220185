import { Button, Label, Modal, TextInput } from 'flowbite-react';
import opentype from 'opentype.js';
import React from 'react';
import { toast } from 'react-toastify';

import LoadingIndicator from '../../../atoms/loadingIndicator/LoadingIndicator';

interface AddFontModalProps {
  name?: string;
  description?: string;
  fontName?: any;
  setFontName?: any;
  fontDescription?: any;
  setFontDescription?: any;
  setUploading?: any;
  open: boolean;
  onClose: () => void;
  onCreateFont: () => void;
  onChange: (value: string, name: string) => void;
  loading?: boolean;
}

export const AddFontModal = ({
  open,
  onClose,
  fontName,
  setFontName,
  fontDescription,
  setFontDescription,
  onCreateFont,
  setUploading,
  loading,
}: AddFontModalProps) => {
  const onAddFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const list = e.target.files;
    const files = [];
    if (list)
      for (let i = 0; i < list.length; i++) {
        const file = list.item(i);
        file && files.push(file);
      }
    if (e.target.files) {
      const file = e.target.files[0];
      if (file) {
        try {
          const reader = new FileReader();
          reader.onload = async (event) => {
            if (event.target) {
              const fontArrayBuffer = event.target.result;
              const font = await opentype.parse(fontArrayBuffer);
              setFontName(font.names.fontFamily.en);
            }
          };
          reader.readAsArrayBuffer(file);
        } catch (error) {
          toast.error('Error loading font');
        }
      }
    }
    setUploading(files);
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFontName(e.target.value);
  };

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFontDescription(e.target.value);
  };

  return (
    <Modal show={open} onClose={onClose}>
      <Modal.Header>Upload</Modal.Header>
      <Modal.Body>
        <div className="mb-2 block mt-2">
          <Label value="Name" />
          <TextInput
            disabled={loading}
            value={fontName || ''}
            type="text"
            sizing="md"
            onChange={handleNameChange}
          />
        </div>
        <div className="mb-2 block mt-2">
          <Label value="Description" />
          <TextInput
            disabled={loading}
            value={fontDescription || ''}
            type="text"
            sizing="md"
            onChange={handleDescriptionChange}
          />
        </div>
        <div className="mb-2 block mt-5">
          <div>
            <input type={'file'} onChange={onAddFile} multiple={true} accept=".ttf" />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onCreateFont} disabled={loading || !fontName}>
          {loading && <LoadingIndicator />}Create
        </Button>
        <Button color={'gray'} onClick={onClose} disabled={loading}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
