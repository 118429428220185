import './Layout.scss';

import { Layout, LayoutProps } from 'antd';

const { Header, Footer, Content } = Layout;

const AntLayout = (props: LayoutProps) => {
  return <Layout>{props.children}</Layout>;
};

const AntHeader = (props: LayoutProps) => {
  return <Header className="header">{props.children}</Header>;
};

const AntContent = (props: LayoutProps) => {
  return (
    <Content className="w-full mx-auto max-w-[85%] md:mt-12 md:mb-32">{props.children}</Content>
  );
};

const AntFooter = (props: LayoutProps) => {
  return <Footer className="footer">{props.children}</Footer>;
};

export { AntContent, AntFooter, AntHeader, AntLayout };
