import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { queryClient } from '../../..';
import { axiosInstance } from '../../../core/api/axiosInstance';
import { RolesEnum } from '../../../model/enums/RolesEnum';
import { key } from './useGetUserRoles';

export const useDeleteRoles = () => {
  return useMutation(
    async ({ id, roles }: { id: string; roles: RolesEnum[] }) => {
      const response = await axiosInstance.post(`enterprise-users/remove-roles/${id}`, roles);
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(key);
      },
      onError: () => {
        toast.error('Error while removing roles');
      },
    },
  );
};
