import './style.scss';

import ClickAwayListener from 'react-click-away-listener';
import ReactDOM from 'react-dom/client';

import Button from '../../../atoms/button/Button';

export const useConfirmation = () => {
  const modal = document.getElementById('portal');
  const confirm = ({
    message,
    warning,
    onConfirm,
    onCancel,
  }: {
    message: string;
    warning?: string;
    onConfirm: () => void;
    onCancel?: () => void;
  }) => {
    const root = ReactDOM.createRoot(modal as HTMLElement);
    const destroy = () => modal && root.unmount();
    root.render(
      <ConfirmModal
        onAway={destroy}
        message={message}
        warning={warning}
        onCancel={onCancel ? onCancel : destroy}
        onConfirm={onConfirm}
      />,
    );
  };

  return { confirm };
};
interface ConfirmModalProps {
  onAway: () => void;
  message: string;
  warning?: string;
  onCancel?: () => void;
  onConfirm: () => void;
}
const ConfirmModal = ({ onAway, message, warning, onConfirm, onCancel }: ConfirmModalProps) => {
  return (
    <ClickAwayListener onClickAway={onAway}>
      <div className={'cf-modal confirmation-modal dark'}>
        <div className="text-center">
          <svg
            stroke="currentColor"
            fill="none"
            strokeWidth="0"
            viewBox="0 0 24 24"
            className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            ></path>
          </svg>
          <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">{message}</h3>
          {warning && (
            <p className="mt-4 text-lg font-formal text-red-500 dark-text-red-400">{warning}</p>
          )}
        </div>
        <div className={'cf-buttons flex justify-left modal-footer'}>
          <Button
            label="Confirm"
            buttonType="primary"
            className={'cf-confirm'}
            onClick={() => {
              onConfirm();
              onAway();
            }}
          />
          <Button
            label="Cancel"
            buttonType="secondary"
            className={'cf-cancel'}
            onClick={onCancel}
          />
        </div>
      </div>
    </ClickAwayListener>
  );
};
