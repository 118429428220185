import { Spin } from 'antd';
import React, { memo, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PlaybackEnum } from '../../../core/ui/enums/PlaybackEnum';
import { AnimationPanelDefTemplate } from '../../../model/definitions/AnimationPanelDefTemplate';
import { IconTemplateDef } from '../../../model/definitions/IconTemplateDef';
import { PositionControlDef } from '../../../model/definitions/PositionControlDef';
import { TimeControlDef } from '../../../model/definitions/TimeControlDef';
import { useGetSequenceZip } from '../../../pages/dashboard/queries-NEW/useGetSequenceZip';
import PlayerContext from '../../../pages/playground/playerContext/PlayerContext';
import { ActiveDef } from '../../../store/slices/active-slice';
import { updateAnimationTemplateSize, updatePosition } from '../../../store/slices/project-slice';
import { RootState } from '../../../store/store';

interface SequenceProps {
  id: string;
  item: AnimationPanelDefTemplate | IconTemplateDef;
  time?: TimeControlDef[];
  showcase?: boolean;
  parentStart?: number;
  position?: PositionControlDef;
  repeat?: boolean;
}

export const Sequence: React.FC<SequenceProps> = memo(
  ({ item, id, position, time = [new TimeControlDef()], parentStart, repeat = true }) => {
    const dispatch = useDispatch();
    const { activeFramerate, activeScene, activeAspectRatio } = useSelector<RootState>(
      (state) => state.active,
    ) as ActiveDef;
    const { versionId } = item;
    const { time: contextTime, isPlaying } = useContext(PlayerContext);
    const { data, isLoading } = useGetSequenceZip(versionId);
    const [currentFrame, setCurrentFrame] = useState(0);
    const [section, setSection] = useState<'INTRO' | 'MAIN' | 'OUTRO'>('INTRO');
    const [introImages, setIntroImages] = useState<HTMLImageElement[]>([]);
    const [mainImages, setMainImages] = useState<HTMLImageElement[]>([]);
    const [outroImages, setOutroImages] = useState<HTMLImageElement[]>([]);
    //const [isExtracted, setExtracted] = useState(false);
    const ref = useRef<HTMLCanvasElement>(null);

    const [hasLooped, setHasLooped] = useState(false);
    const introFrames = (1000 / activeFramerate) * introImages.length;
    const mainFrames = (1000 / activeFramerate) * mainImages.length;
    const outroFrames = (1000 / activeFramerate) * outroImages.length;
    const mainTime = time[0];
    const { startMS, endMS } = mainTime;
    const refreshRate = 1000 / activeFramerate;
    useEffect(() => {
      const worker = new Worker(new URL('./zipWorker.js', import.meta.url));
      worker.addEventListener('message', (event) => {
        const result = event.data;
        const intro = result.introImageSrcs.map((src: string) => {
          const img = new Image();
          img.src = src;
          return img;
        });
        setIntroImages(intro);
        const main = result.mainImageSrcs.map((src: string) => {
          const img = new Image();
          img.src = src;
          return img;
        });
        setMainImages(main);
        const outro = result.mainImageSrcs.map((src: string) => {
          const img = new Image();
          img.src = src;
          return img;
        });
        setOutroImages(outro);
      });
      data && worker.postMessage(data);
      return () => {
        worker.terminate();
      };
    }, [data]);
    const getSection = useCallback(
      (section: 'INTRO' | 'MAIN' | 'OUTRO', currentFrame: number) => {
        if (section === 'INTRO') {
          return introImages[currentFrame];
        }
        if (section === 'MAIN') {
          return mainImages[currentFrame];
        }
        if (section === 'OUTRO') {
          return outroImages[currentFrame];
        }
        return new Image();
      },
      [introImages, mainImages, outroImages],
    );
    const mainLoop = useCallback(
      (
        startMS: number,
        introFrames: number,
        refreshRate: number,
        mainImages: Array<HTMLImageElement>,
      ) => {
        const timeSinceStart = contextTime - (startMS + (parentStart ?? 0) + introFrames);
        const roundedTime = timeSinceStart / refreshRate;
        if (roundedTime / mainImages.length > 1) {
          setHasLooped(true);
        }
        return Math.round(roundedTime % mainImages.length) != 0
          ? Math.round(roundedTime % (mainImages.length - 1))
          : Math.round(roundedTime % mainImages.length);
      },
      [contextTime, parentStart],
    );
    useEffect(() => {
      if (contextTime > startMS + (parentStart ?? 0) + introFrames && section === 'MAIN') {
        const currentFrame = mainLoop(startMS, introFrames, refreshRate, mainImages);
        setCurrentFrame(currentFrame);
      }
    }, [
      contextTime,
      introFrames,
      mainImages,
      mainLoop,
      refreshRate,
      section,
      startMS,
      parentStart,
    ]);
    useEffect(() => {
      if ((!item.w || !item.h) && (item.w || item.h) && position && mainImages[0]) {
        const sequenceRatio = mainImages[0]?.width / mainImages[0]?.height;
        dispatch(
          updateAnimationTemplateSize({
            activeScene,
            elementId: id,
            w: mainImages[0].width,
            h: mainImages[0].height,
          }),
        );
        dispatch(
          updatePosition({
            position: {
              ...position,
              w: 30,
              h: (30 * (activeAspectRatio[0] / activeAspectRatio[1])) / sequenceRatio,
            },
            activeScene,
            elementId: id,
            elementType: 'animationPanels',
          }),
        );
      }
    }, [activeAspectRatio, activeScene, item.w, item.h, position, mainImages, dispatch, id]);

    useEffect(() => {
      if (contextTime < startMS + (parentStart ?? 0) + introFrames && section !== 'INTRO') {
        setSection('INTRO');
        setCurrentFrame(Math.round((contextTime - startMS + (parentStart ?? 0)) / refreshRate) - 1);
        setHasLooped(false);
      }
      if (
        contextTime < startMS + (parentStart ?? 0) + introFrames &&
        Math.round((contextTime - startMS + (parentStart ?? 0)) / refreshRate) <=
          introImages.length - 1
      )
        setCurrentFrame(Math.round((contextTime - startMS + (parentStart ?? 0)) / refreshRate));
      if (contextTime > startMS + (parentStart ?? 0) + introFrames && section === 'INTRO') {
        setCurrentFrame(0);
      }
      if (contextTime >= startMS + (parentStart ?? 0) + introFrames && section !== 'MAIN') {
        if (section !== 'OUTRO') setHasLooped(false);
        setSection('MAIN');
        setCurrentFrame(mainLoop(startMS, introFrames, refreshRate, mainImages));
      }
      if (repeat && contextTime > endMS - outroFrames && hasLooped) {
        setSection('OUTRO');
        if (
          Math.round(outroImages.length - Math.abs(contextTime - endMS) / refreshRate) <=
          outroImages.length - 1
        )
          setCurrentFrame(
            Math.round(outroImages.length - Math.abs(contextTime - endMS) / refreshRate),
          );
      }
      if (!repeat && contextTime > startMS + (parentStart ?? 0) + introFrames + mainFrames) {
        setSection('OUTRO');
        setCurrentFrame(
          Math.round((contextTime - (startMS + mainFrames) + (parentStart ?? 0)) / refreshRate) - 1,
        );
      }
    }, [
      mainFrames,
      repeat,
      contextTime,
      endMS,
      hasLooped,
      introFrames,
      introImages.length,
      mainImages,
      mainLoop,
      outroFrames,
      outroImages.length,
      refreshRate,
      section,
      startMS,
      parentStart,
    ]);
    useEffect(() => {
      if (
        contextTime <
          startMS +
            (parentStart ?? 0) +
            introFrames +
            (1000 / activeFramerate) * introImages.length &&
        isPlaying !== PlaybackEnum.PLAYING
      )
        setHasLooped(false);
    }, [
      activeFramerate,
      contextTime,
      introFrames,
      introImages.length,
      isPlaying,
      startMS,
      parentStart,
    ]);
    useEffect(() => {
      if (contextTime > endMS)
        return () => {
          mainImages.forEach((value) => URL.revokeObjectURL(value.src));
          introImages.forEach((value) => URL.revokeObjectURL(value.src));
          outroImages.forEach((value) => URL.revokeObjectURL(value.src));
        };
    }, [mainImages, introImages, outroImages, contextTime, endMS]);
    useEffect(() => {
      const canvas = ref.current;
      const ctx = canvas?.getContext('2d');
      const img = getSection(section, currentFrame);
      if (img && ctx && canvas) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      }
    }, [currentFrame, getSection, section]);

    return (
      <div className={'relative h-full'}>
        {isLoading ? (
          <div className={'h-full flex justify-center absolute right-0 left-0 items-center'}>
            <Spin className={'self-center'} tip={isLoading ? 'Fetching...' : 'Extracting...'} />
          </div>
        ) : (
          <canvas ref={ref} className={'w-full h-full min-h-full'} />
        )}
      </div>
    );
  },
  (prevProps, nextProps) => prevProps.item === nextProps.item,
);

Sequence.displayName = 'Sequence';
