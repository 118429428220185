import './style.scss';

import { useKeycloak } from '@react-keycloak/web';
import { ConfigProvider } from 'antd';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { RolesEnum } from '../../../model/enums/RolesEnum';
import Theme from '../antd/Theme';
import { AntLayout } from '../layout/Layout';
import { MarketplaceProvider } from '../marketplaceContext';
import Footer from '../molecules/Footer/Footer';
import Header from '../molecules/Header/Header';
import Sidebar from '../molecules/SidebarCart/Sidebar';
import ScrollToTop from './ScrollToTop';

const Main = () => {
  const [searchString, setSearchString] = useState<string>('');
  const [type, setType] = useState<CheckboxValueType[]>([]);
  const { keycloak } = useKeycloak();
  const [showCartSidebar, setShowCartSidebar] = useState<boolean>(false);
  return (
    <ConfigProvider theme={Theme}>
      <MarketplaceProvider
        value={{
          searchString: searchString,
          type: type,
          showCartSidebar: showCartSidebar,
          tokenEmail: keycloak.tokenParsed?.email,
          authenticated: keycloak.authenticated ? true : false,
          admin:
            keycloak.tokenParsed?.realm_access?.roles.includes(RolesEnum.ROLE_MARKETPLACE_ADMIN) ??
            false,
          setSearchString: (e) => setSearchString(e),
          setType: (e) => setType(e),
          setShowCartSidebar: (e) => setShowCartSidebar(e),
        }}
      >
        <AntLayout>
          <ToastContainer />
          <Header />
          <Outlet />
          <Footer />
        </AntLayout>
        <Sidebar />
        <ScrollToTop />
      </MarketplaceProvider>
    </ConfigProvider>
  );
};

export default Main;
