import React, { ReactNode, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface SortContextProps {
  sortAscDesc: string;
  setSortAscDesc: (e: string) => void;
  sortByField: string;
  setSortByField: (e: string) => void;
  sort: boolean;
  setSort: (e: boolean) => void;
  handleSort: (e: string) => void;
}

interface SortProviderProps {
  children: ReactNode;
}

const SortContext = React.createContext<SortContextProps>({
  sortAscDesc: '',
  setSortAscDesc: () => {},
  sortByField: '',
  setSortByField: () => {},
  sort: true,
  setSort: () => {},
  handleSort: () => {},
});

export const SortProvider: React.FC<SortProviderProps> = ({ children }) => {
  const location = useLocation();
  const [sortAscDesc, setSortAscDesc] = useState<string>('');
  const [sortByField, setSortByField] = useState<string>('');
  const [sort, setSort] = useState<boolean>(true);

  function handleSort(sortBy: string) {
    if (sortByField === '') {
      setSortAscDesc('ASC');
      setSort(true);
    }
    if (sortBy !== sortByField) {
      setSortByField(sortBy);
      return;
    }
    if (sortAscDesc === 'ASC' && sort) {
      setSortAscDesc('DESC');
    } else {
      setSortAscDesc('');
      setSortByField('');
      setSort(false);
    }
  }

  useEffect(() => {
    setSortAscDesc('');
    setSortByField('');
    setSort(false);
  }, [location.pathname, setSortAscDesc, setSortByField, setSort]);

  return (
    <SortContext.Provider
      value={{
        sortAscDesc,
        setSortAscDesc,
        sortByField,
        setSortByField,
        sort,
        setSort,
        handleSort,
      }}
    >
      {children}
    </SortContext.Provider>
  );
};

export default SortContext;
