import { useKeycloak } from '@react-keycloak/web';
import { useEffect } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';

import { RolesEnum } from '../../model/enums/RolesEnum';

interface DataProviderRoute {
  roles?: RolesEnum[];
  children?: any;
}

const PrivateRoute = ({ roles, children }: DataProviderRoute) => {
  const { keycloak, initialized } = useKeycloak();
  const navigate = useNavigate();

  const isAuth = (roles?: RolesEnum[]) => {
    if (roles) {
      return roles.some((r: RolesEnum) => {
        return keycloak.hasRealmRole(r);
      });
    }
    return false;
  };

  useEffect(() => {
    if (initialized) {
      if (!keycloak.authenticated) {
        keycloak.login({
          redirectUri: window.location.href,
        });
      } else {
        if (keycloak.hasRealmRole(RolesEnum.ROLE_DATA_PROVIDER)) {
          navigate('/workspace/dataprovider');
        } else if (keycloak.hasRealmRole(RolesEnum.ROLE_ADMIN)) {
          navigate('/workspace/account-management/enterprise');
        }
      }
    }
  }, [initialized, keycloak]);

  return roles ? (
    isAuth(roles) ? (
      children
    ) : (
      <Navigate to="/workspace" />
    )
  ) : initialized && keycloak.authenticated ? (
    <Outlet />
  ) : null;
};

export default PrivateRoute;
