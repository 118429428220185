import './mapTemplateModal.scss';

import { differenceInDays } from 'date-fns';

import { DataFrameDef } from '../../../../../model/definitions/DataFrameDef';
import { GribMapLayer } from '../../../../../model/definitions/GribMapLayer';
import { FramesRange } from '../../../modals/FramesRange';
import { useDefaultFrames } from '../../../sidebar-new/repoUtil/useDefaultFrames';
import { useGetModelLayerData } from './mapTemplateApi/useGetModelLayerData';

interface GribRangeProps {
  source: GribMapLayer;
  bounds: [number, number, number, number];
  setFrames: (id: string, frames: DataFrameDef[]) => void;
  layerNew?: GribMapLayer;
}
const wrapper = 'flex flex-col justify-center items-center range-wrapper';
export const GribRange = ({ source, bounds, setFrames, layerNew }: GribRangeProps) => {
  const {
    id,
    name,
    dataFrames,
    gribSource: { gribSource, parameterType, creationTime, gribModel },
  } = source;
  const { data, isLoading, isError } = useGetModelLayerData(
    parameterType.id,
    gribSource.id,
    gribModel.id,
    bounds,
  );
  const handleFrames = (layerFrames: Array<DataFrameDef>) => setFrames(id, layerFrames);
  const { selectedFrames } = useDefaultFrames(data, dataFrames, creationTime);
  return (
    <div className={'map-template-layer-wrapper'}>
      <h3>{name}</h3>
      <span>
        Default layer offset in days:
        {differenceInDays(dataFrames[0]?.timestamp * 1000, creationTime)}
      </span>
      <div className={wrapper}>
        <FramesRange
          selectedFrames={layerNew ? [...layerNew?.dataFrames] : []}
          layerType={'grib'}
          defaultValue={selectedFrames}
          isLoading={isLoading}
          isError={isError}
          frames={data}
          onRangeChange={handleFrames}
        />
      </div>
    </div>
  );
};
