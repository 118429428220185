import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { queryClient } from '../../..';
import { axiosInstance } from '../../../core/api/axiosInstance';
import { key } from './useGetAccounts';

export const useEditAccount = () => {
  return useMutation(
    async ({ api, id, formData }: { api: string; id: string; formData: FormData }) => {
      const response = await axiosInstance.put(api + '/' + id, formData);
      return response.data;
    },
    {
      onSuccess: () => {
        toast.success('Account edited successfully!');
        queryClient.invalidateQueries(key);
      },
      onError: () => {
        toast.error('Error editing account!');
      },
    },
  );
};
