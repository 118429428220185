import React from 'react';

import { PlaybackEnum } from '../../../core/ui/enums/PlaybackEnum';

export interface SkipInterface {
  val: number;
  ctrl: boolean;
}
export interface PlayContext {
  isRecording: boolean;
  setIsRecording: (e: boolean) => void;
  time: number;
  setTime: (e: number) => void;
  isPlaying?: PlaybackEnum;
  setIsPlaying: (e: PlaybackEnum) => void;
  interval: ReturnType<typeof setInterval>;
  clear: (e: ReturnType<typeof setInterval>) => void;
  set: (e: NodeJS.Timer) => void;
  width: number;
  setWidth: (e: number) => void;
  skip: SkipInterface;
  setSkip: (e: SkipInterface) => void;
  boardSkip: number;
  setBoardSkip: (e: number) => void;
}
const PlayerContext = React.createContext<PlayContext>({
  isRecording: false,
  setIsRecording: (e) => {},
  time: 0,
  interval: null as unknown as ReturnType<typeof setInterval>,
  isPlaying: undefined,
  skip: { val: 0, ctrl: true },
  setSkip: (e: SkipInterface) => {},
  setTime: (e: number) => {},
  setIsPlaying: (e?: PlaybackEnum) => {},
  clear: (e) => {},
  set: (e: NodeJS.Timer) => {},
  width: 0,
  setWidth: (e) => {},
  boardSkip: 0,
  setBoardSkip: (e) => e++,
});
export const PlayerProvider = PlayerContext.Provider;
export default PlayerContext;
