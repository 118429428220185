import { CheckboxValueType } from 'antd/es/checkbox/Group';
import QueryString from 'qs';
import { QueryKey, useQuery } from 'react-query';

import { axiosInstancePublic } from '../../../core/api/axiosInstancePublic';

export const key: QueryKey = ['FILTER_PUBLIC_APPROVED_ITEMS'];

export const useGetPublicApprovedItemsFilter = (
  check: boolean,
  page: number,
  size: number,
  publishedBy: string,
  searchKey: string,
  templateTypes?: CheckboxValueType[],
  sortByField?: string,
  sortAscDesc?: string,
  priceFrom?: number | null,
  priceTo?: number | null,
  tags?: string[],
) => {
  return useQuery(
    [
      ...key,
      page,
      size,
      publishedBy,
      searchKey,
      templateTypes,
      sortByField,
      sortAscDesc,
      priceFrom,
      priceTo,
      tags,
    ],
    async () => {
      const res = await axiosInstancePublic.get('marketplace/approved-marketplace-items-filter', {
        params: {
          page,
          size,
          publishedBy,
          searchKey,
          templateTypes,
          sortByField,
          sortAscDesc,
          priceFrom,
          priceTo,
          tags,
        },
        paramsSerializer: (params) => {
          return QueryString.stringify(params, { arrayFormat: 'repeat' });
        },
      });
      return res.data;
    },
    {
      enabled: check,
    },
  );
};
