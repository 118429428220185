import { ValueTypeEnum } from '../enums/ValueTypeEnum';
import { Station } from '../other/ObservedData';
import { BoxDef } from './BoxDef';
import { GeoLocationDef } from './GeoLoactionDef';
import { LogicalGroupParent } from './LogicalGroupParent';
import { ObservedWDSourceDef } from './ObservedWDSourceDef';
import { PointWDElementDef } from './PointWDElementDef';
import { PositionControlDef } from './PositionControlDef';
import { TextAnimation } from './TextAnimation';
import { TimeControlDef } from './TimeControlDef';

export class ObservedWDElementDef extends PointWDElementDef {
  observedWDSource: ObservedWDSourceDef;
  displayUnitOfMeasurement: boolean;
  timeControls: TimeControlDef[];
  positionControl = new PositionControlDef(100, 100);
  geoLocation: GeoLocationDef;
  fontColor: 'rgba(255, 255, 255, 255)';
  fontSize = 5;
  fontFamily: string;
  fontType: string;
  fontVariantId: string;
  textTransform:
    | 'none'
    | 'capitalize'
    | 'uppercase'
    | 'lowercase'
    | 'full-width'
    | 'full-size-kana';
  fontAlignment: 'left';
  strokeWidth = 0;
  strokeColor = 'rgba(255, 255, 255, 255)';
  textAnimation = new TextAnimation();
  boxDef = new BoxDef();
  parentGroups = [new LogicalGroupParent()];
  enabled: boolean;
  constructor(
    start?: number,
    end?: number,
    time?: number,
    param?: string,
    value?: Record<string, unknown> | number,
    unit?: string,
    valueType?: ValueTypeEnum,
    stat?: Station,
    location?: GeoLocationDef,
  ) {
    super();
    this.timeControls = [new TimeControlDef(start, end)];
    this.observedWDSource = new ObservedWDSourceDef(time, param, unit, valueType, stat, value);
    if (location) this.geoLocation = location;
    this.displayUnitOfMeasurement = true;
  }
}
