import { useMutation } from 'react-query';

import { queryClient } from '../../../index';
import { ColorPaletteDef } from '../../../model/definitions/ColorPaletteDef';
import { CustomPaletteDTO } from '../../../model/DTO/CustomPaletteDTO';
import { key } from '../../api/palette/useGetPalettes';
import { axiosInstance } from '../axiosInstance';

export const useCreateEnterprisePalette = () =>
  useMutation(
    async (palette: CustomPaletteDTO): Promise<ColorPaletteDef> => {
      const { data } = await axiosInstance.post('enterprise-color-palette', palette);
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(key);
      },
    },
  );
