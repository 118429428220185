import Button from '../../../../atoms/button/Button';

interface ConfirmDeleteModalProps {
  show?: boolean;
  onConfirm?: () => void;
  onClose?: () => void;
}

function ConfirmDeleteModal({ show, onConfirm, onClose }: ConfirmDeleteModalProps) {
  return (
    <>
      {show && (
        <div
          aria-hidden="false"
          className="fixed top-0 right-0 left-0 z-[1] h-modal overflow-y-auto overflow-x-hidden md:inset-0 md:h-full items-center justify-center flex bg-gray-900 bg-opacity-50 dark:bg-opacity-80"
          data-testid="modal"
          role="dialog"
        >
          <div className="relative h-full w-full p-4 md:h-auto max-w-md">
            <div className="confirmation-modal dark relative rounded-lg bg-white shadow dark:bg-gray-700">
              <div className="flex items-start justify-between rounded-t dark:border-gray-600 border-b p-5 !p-2 !border-b-0">
                <Button
                  buttonType="link"
                  onClick={onClose}
                  style={{ marginLeft: 'auto', height: ' auto' }}
                  icon={
                    <svg
                      stroke="currentColor"
                      fill="none"
                      strokeWidth="0"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      className="h-5 w-5"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      ></path>
                    </svg>
                  }
                ></Button>
              </div>
              <div className="p-6 pt-0">
                <div className="text-center">
                  <svg
                    stroke="currentColor"
                    fill="none"
                    strokeWidth="0"
                    viewBox="0 0 24 24"
                    className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    ></path>
                  </svg>
                  <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                    Are you sure you want to delete?
                  </h3>
                  <div className="flex justify-center modal-footer">
                    <Button buttonType="danger" onClick={onConfirm} label="Yes, I'm sure" />
                    <Button buttonType="secondary" onClick={onClose} label="No, cancel" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ConfirmDeleteModal;
