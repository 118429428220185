import './style.scss';

import { cloneDeep, isEqual } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import Button from '../../../atoms/button/Button';
import { postRadarLayer } from '../../../core/api/mapLayers/LayersAPI';
import { fromGeoBoxToBoundsWDLayers } from '../../../helpers/boundsManage';
import {
  DEFAULT_FONT_FAMILY,
  DEFAULT_FONT_TYPE,
  DEFAULT_FONT_VARIANT_ID,
  MAX_FULLSCREEN_HEIGHT,
} from '../../../model/constants/constants';
import { C9ProjectDef } from '../../../model/definitions/C9ProjectDef';
import { ColorPaletteDef } from '../../../model/definitions/ColorPaletteDef';
import { DataFrameDef } from '../../../model/definitions/DataFrameDef';
import { MapPanelDef } from '../../../model/definitions/MapPanelDef';
import { PickerDef } from '../../../model/definitions/PickerDef';
import { PositionControlDef } from '../../../model/definitions/PositionControlDef';
import { RadarMapLayer } from '../../../model/definitions/RadarMapLayer';
import { FilteringTypeEnum, PostProcessingTypeEnum } from '../../../model/enums/FilteringTypeEnum';
import { InterpolationEnum } from '../../../model/enums/InterpolationEnum';
import { OrientationTypeEnum } from '../../../model/enums/OrientationTypeEnum';
import { transformAbsoluteToPercent } from '../../../molecules/canvasElements/utils';
import { getZindexOfMapLayer } from '../../../molecules/mapElement/helpers';
import Modal from '../../../molecules/modal/Modal';
import { generateSetup } from '../../../molecules/palette/PaletteRefactor';
import { ActiveDef, setDisplayedFlyOvers } from '../../../store/slices/active-slice';
import { updateMapLayer } from '../../../store/slices/project-slice';
import { RootState } from '../../../store/store';
import MapRadarLayers from '../properties/mapLayersProperties/MapRadarLayers';
import { getFirstLayerRange } from './frameRangeHelpers';

interface Props {
  opened: boolean;
  onClose: () => void;
  mapLayer: MapPanelDef;
  radarLayerEdit?: RadarMapLayer;
  applyLayer?: boolean;
}
const MapRadarLayersModal: FC<Props> = ({
  mapLayer,
  opened,
  onClose,
  radarLayerEdit,
  applyLayer,
}) => {
  const { activeScene, activeElement, activeAspectRatio } = useSelector<RootState, ActiveDef>(
    (state) => state.active,
  );
  const project = useSelector<RootState, C9ProjectDef>((state) => state.project.present.project);
  const enterpriseAccountId = project.enterpriseAccountId;
  const dispatch = useDispatch();
  const [source, setSource] = useState<PickerDef | undefined>(
    radarLayerEdit ? radarLayerEdit.radarSource.radarSource : undefined,
  );
  const [dataProductId, setDataProductId] = useState<string | null>(
    radarLayerEdit ? radarLayerEdit.radarSource.dataProductId : null,
  );
  const [name, setName] = useState<string | undefined>(
    radarLayerEdit ? radarLayerEdit.name : undefined,
  );
  const firstLayerRange = getFirstLayerRange(mapLayer);
  const selectedFrames: DataFrameDef[] = [];
  if (radarLayerEdit && radarLayerEdit.dataFrames) {
    selectedFrames.push(...radarLayerEdit.dataFrames);
  }
  const [selectedDataFrames, setSelectedDataFrames] = useState<Array<DataFrameDef>>(selectedFrames);
  const [frameLoading, setFrameLoading] = useState(false);
  const [z, setZ] = useState(
    radarLayerEdit ? radarLayerEdit.zindex : getZindexOfMapLayer(mapLayer),
  );
  const [framesDensity, setFramesDensity] = useState<number>(
    radarLayerEdit ? radarLayerEdit.dataFramesDensity : 1,
  );
  const transformedBounds = fromGeoBoxToBoundsWDLayers(mapLayer.baseMapSetup.boundingBox);

  const [palette, setPalette] = useState<ColorPaletteDef | null>(
    radarLayerEdit ? radarLayerEdit.layerSetup.colorPaletteDef : null,
  );
  const [interpolation, setInterpolation] = useState<InterpolationEnum>(
    radarLayerEdit ? radarLayerEdit.layerSetup.interpolation : InterpolationEnum.NEAREST,
  );
  const [isContouring, setIsContouring] = useState<boolean>(radarLayerEdit?.isContouring ?? true);

  const [filteringType, setFilteringType] = useState<FilteringTypeEnum | null>(
    radarLayerEdit ? radarLayerEdit.layerSetup.preprocessing : FilteringTypeEnum.LOW_BANDPASS,
  );
  const [postProcessing, setPostProcessing] = useState<PostProcessingTypeEnum | null>(
    radarLayerEdit?.layerSetup?.postprocessing ? radarLayerEdit.layerSetup.postprocessing : null,
  );
  const [embossEffect, setEmbossEffect] = useState(
    radarLayerEdit ? radarLayerEdit.layerSetup.embossEffect : 7,
  );
  const [numberOfIterations, setNumberOfIterations] = useState(
    radarLayerEdit ? radarLayerEdit.layerSetup.numberOfIterations : 4,
  );
  useEffect(() => {
    if (selectedDataFrames.length === 1) {
      setSelectedDataFrames([...selectedFrames]);
    }
  }, [source]);
  function onMapLayerChange(propertyPath: Paths<MapPanelDef>, newValue: RadarMapLayer[]) {
    dispatch(
      updateMapLayer({
        activeScene,
        newValue,
        elementId: activeElement,
        propertyPath,
      }),
    );
  }

  function isRelevantDataChanged() {
    if (!radarLayerEdit || applyLayer) return true;
    return (
      !isEqual(radarLayerEdit.radarSource.radarSource, source) ||
      !isEqual(radarLayerEdit.layerSetup.preprocessing, filteringType) ||
      !isEqual(radarLayerEdit.dataFramesDensity, framesDensity) ||
      !isEqual(radarLayerEdit.layerSetup.numberOfIterations, numberOfIterations)
    );
  }

  function isIrelevantDataChanged() {
    if (!radarLayerEdit) return true;
    return (
      !isEqual(radarLayerEdit.dataFrames, selectedDataFrames) ||
      !isEqual(radarLayerEdit.name, name) ||
      !isEqual(radarLayerEdit.zindex, z) ||
      !isEqual(radarLayerEdit.isContouring, isContouring) ||
      !isEqual(radarLayerEdit.layerSetup.colorPaletteDef, palette) ||
      !isEqual(radarLayerEdit.layerSetup.postprocessing, postProcessing) ||
      !isEqual(radarLayerEdit.layerSetup.embossEffect, embossEffect) ||
      !isEqual(radarLayerEdit.layerSetup.interpolation, interpolation)
    );
  }

  function onIrelevantDataChanged() {
    const cloned = cloneDeep(mapLayer.wdSpace[0].radarMapLayers);
    const found = cloned.find((l) => l.id === radarLayerEdit?.id);
    if (found) {
      found.name = name!;
      found.zindex = Number(z);
      found.isContouring = isContouring;
      found.layerSetup.interpolation = interpolation;
      if (palette) {
        found.layerSetup.colorPaletteDef = palette;
        found.layerSetup.paletteLegendScaling = palette.paletteLegendScaling;
      }
      found.layerSetup.postprocessing = postProcessing;
      found.layerSetup.embossEffect = embossEffect;
      found.dataFrames = selectedDataFrames;
      // @ts-ignore
      onMapLayerChange('wdSpace[0].radarMapLayers', cloned);
    }
  }

  const addLayer = async () => {
    if (source && name && transformedBounds) {
      if (!isRelevantDataChanged()) {
        if (isIrelevantDataChanged()) {
          onIrelevantDataChanged();
        }
        return;
      }
      const layerToAdd = new RadarMapLayer(
        dataProductId,
        name,
        source,
        {
          leftLongitude: transformedBounds[0],
          rightLongitude: transformedBounds[1],
          upperLatitude: transformedBounds[2],
          lowerLatitude: transformedBounds[3],
        },
        radarLayerEdit ? radarLayerEdit.timeControls[0] : mapLayer.timeControls[0],
        selectedDataFrames,
        z,
      );
      layerToAdd.layerType = radarLayerEdit ? radarLayerEdit.layerType : 'RADAR';
      layerToAdd.dataFramesDensity = framesDensity;
      layerToAdd.isContouring = isContouring;
      layerToAdd.layerSetup.interpolation = interpolation;
      layerToAdd.layerSetup.preprocessing = filteringType;
      layerToAdd.layerSetup.postprocessing = postProcessing;
      layerToAdd.layerSetup.baseAppUrl = process.env.REACT_APP_API_BASE_URL as string;
      layerToAdd.layerSetup.embossEffect = embossEffect;
      layerToAdd.layerSetup.numberOfIterations = numberOfIterations;
      if (palette) {
        layerToAdd.layerSetup.colorPaletteDef = palette;
        layerToAdd.layerSetup.paletteLegendScaling = palette.paletteLegendScaling;
      }
      layerToAdd.layerSetup.colorPaletteDef = palette;
      if (layerToAdd.layerSetup.colorPaletteDef && !layerToAdd.layerSetup.colorPaletteDef.setup)
        layerToAdd.layerSetup.colorPaletteDef = {
          ...layerToAdd.layerSetup.colorPaletteDef,
          setup: generateSetup(palette?.colorStops),
        };
      layerToAdd.layerSetup.paletteLegendOrientation =
        radarLayerEdit?.layerSetup.paletteLegendOrientation ?? OrientationTypeEnum.HORIZONTAL;
      layerToAdd.layerSetup.displayPaletteLegend =
        !!radarLayerEdit?.layerSetup.displayPaletteLegend;
      layerToAdd.layerSetup.paletteLegendPositionControl =
        radarLayerEdit?.layerSetup.paletteLegendPositionControl ??
        new PositionControlDef(
          transformAbsoluteToPercent(500, activeAspectRatio, 'width', MAX_FULLSCREEN_HEIGHT),
          transformAbsoluteToPercent(60, activeAspectRatio, 'height', MAX_FULLSCREEN_HEIGHT),
          transformAbsoluteToPercent(30, activeAspectRatio, 'width', MAX_FULLSCREEN_HEIGHT),
          transformAbsoluteToPercent(
            MAX_FULLSCREEN_HEIGHT - 80,
            activeAspectRatio,
            'height',
            MAX_FULLSCREEN_HEIGHT,
          ),
        );
      dispatch(setDisplayedFlyOvers({ displayedFlyOver: activeElement! }));

      setFrameLoading(true);
      try {
        const addedLayer = await postRadarLayer(layerToAdd, mapLayer.baseMapSetup.id);
        addedLayer.layerSetup.paletteLegendOrientation =
          radarLayerEdit?.layerSetup.paletteLegendOrientation ?? OrientationTypeEnum.HORIZONTAL;
        addedLayer.layerSetup.displayPaletteLegend =
          !!radarLayerEdit?.layerSetup.displayPaletteLegend;
        addedLayer.layerSetup.displayLegendValues =
          radarLayerEdit?.layerSetup.displayLegendValues ?? true;
        layerToAdd.layerSetup.legendFontSize = radarLayerEdit?.layerSetup.legendFontSize ?? 3;
        layerToAdd.layerSetup.fontFamily =
          radarLayerEdit?.layerSetup.fontFamily ?? DEFAULT_FONT_FAMILY;
        layerToAdd.layerSetup.fontType = radarLayerEdit?.layerSetup.fontType ?? DEFAULT_FONT_TYPE;
        layerToAdd.layerSetup.fontVariantId =
          radarLayerEdit?.layerSetup.fontVariantId ?? DEFAULT_FONT_VARIANT_ID;
        addedLayer.opacity = radarLayerEdit?.opacity ?? 1;
        addedLayer.layerSetup.paletteLegendPositionControl =
          radarLayerEdit?.layerSetup.paletteLegendPositionControl ??
          new PositionControlDef(
            transformAbsoluteToPercent(500, activeAspectRatio, 'width', MAX_FULLSCREEN_HEIGHT),
            transformAbsoluteToPercent(60, activeAspectRatio, 'height', MAX_FULLSCREEN_HEIGHT),
            transformAbsoluteToPercent(30, activeAspectRatio, 'width', MAX_FULLSCREEN_HEIGHT),
            transformAbsoluteToPercent(
              MAX_FULLSCREEN_HEIGHT - 80,
              activeAspectRatio,
              'height',
              MAX_FULLSCREEN_HEIGHT,
            ),
          );

        const existingLayers = radarLayerEdit
          ? mapLayer.wdSpace[0].radarMapLayers.filter((l) => l.id !== radarLayerEdit.id)
          : mapLayer.wdSpace[0].radarMapLayers;
        // @ts-ignore
        onMapLayerChange('wdSpace[0].radarMapLayers', [...existingLayers, addedLayer]);
      } catch (e) {
        setFrameLoading(false);
        toast.error('Error adding layer!');
        console.error(e);
      }
    }
  };

  async function handleAddLayer() {
    await addLayer();
    onClose();
  }

  return (
    <Modal
      isOpen={opened}
      onClose={onClose}
      className="add-layers-wrapper"
      header={'Set layers'}
      footer={
        <div className={'modal-footer'}>
          <Button
            disabled={!(name && source && palette && selectedDataFrames.length)}
            label={radarLayerEdit && !applyLayer ? 'Apply changes' : 'Add layer'}
            buttonType="primary"
            onClick={handleAddLayer}
            loading={frameLoading}
          />
        </div>
      }
    >
      <div className="add-layers-body">
        {transformedBounds && (
          <MapRadarLayers
            oldLayer={radarLayerEdit && !dataProductId ? true : false}
            dataProductId={dataProductId}
            setDataProductId={setDataProductId}
            enterpriseAccountId={enterpriseAccountId}
            embossEffect={embossEffect}
            setEmbossEffect={setEmbossEffect}
            numberOfIterations={numberOfIterations}
            setNumberOfIterations={setNumberOfIterations}
            setPostProcessingType={setPostProcessing}
            postProcessing={postProcessing}
            name={name}
            setName={setName}
            z={z}
            setZ={setZ}
            source={source}
            setSource={setSource}
            selectedDataFrames={selectedDataFrames}
            setSelectedDataFrames={setSelectedDataFrames}
            bounds={transformedBounds}
            mapLayer={mapLayer}
            onMapLayerChange={onMapLayerChange}
            interpolation={interpolation}
            setInterpolation={setInterpolation}
            filteringType={filteringType}
            setFilteringType={setFilteringType}
            framesDensity={framesDensity}
            setFramesDensity={setFramesDensity}
            isContouring={isContouring}
            setIsContouring={setIsContouring}
            palette={palette}
            setPalette={(pal) => {
              if (pal?.colorStops?.interval != null) {
                setPalette(pal);
              } else {
                if (pal) {
                  const val = {
                    ...pal,
                    colorStops: {
                      ...pal.colorStops,
                      interval:
                        palette?.colorStops?.interval != null ? palette.colorStops.interval : 2,
                    },
                  };
                  setPalette(val);
                }
              }
            }}
            radarLayerEdit={radarLayerEdit}
            rangeFromFirstLayer={firstLayerRange}
          />
        )}
      </div>
    </Modal>
  );
};

export default MapRadarLayersModal;
