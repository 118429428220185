import './index.css';
import './index.scss';

import { ReactKeycloakProvider } from '@react-keycloak/web';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import { PortalDiv } from './atoms/portal/Portal';
import { _kc } from './core/auth/KeycloakService';
import reportWebVitals from './reportWebVitals';
import { setupSentry } from './setupSentry';
import { store } from './store/store';

setupSentry();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

root.render(
  <ReactKeycloakProvider authClient={_kc} autoRefreshToken={true}>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <App />
          <PortalDiv />
        </BrowserRouter>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Provider>
  </ReactKeycloakProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
