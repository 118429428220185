import { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { AiOutlinePlus } from 'react-icons/ai';
import { v4 } from 'uuid';

import Button from '../../../atoms/button/Button';
import { WeatherPosterDef } from '../../../model/definitions/WeatherPosterDef';
import { ForecastGeoPoster } from './components/ForecastGeoPoster';
import { ExampleCustomInput } from './ObservedGeoPosterImport';

interface ForecastGeoPosterProps {
  template: WeatherPosterDef[];
  setData: (data: Array<WeatherPosterDef>) => void;
}
export const ForecastGeoPosterImport = ({ template, setData }: ForecastGeoPosterProps) => {
  const [selected, setSelected] = useState<Date>(new Date());
  const [availableDates, setAvailableDates] = useState<Array<Date>>([]);
  const [defs, setDefs] = useState<Array<WeatherPosterDef>>(template);
  const updatePoster = (poster: WeatherPosterDef) => {
    const index = defs.findIndex((item) => item.id === poster.id);
    if (index > -1) {
      defs[index].forecastWDElements = poster.forecastWDElements;
    }
    setDefs(defs);
  };
  useEffect(() => {
    setData(defs);
  }, [defs]);
  const renderDefs = () => {
    return defs.map((poster, index) => (
      <ForecastGeoPoster
        poster={poster}
        key={poster.id}
        template={poster}
        updateAvailableDates={(dates) =>
          dates && setAvailableDates((prevState) => [...prevState, ...dates])
        }
        time={selected}
        updatePoster={updatePoster}
      />
    ));
  };
  return (
    <div className="station-wrapper">
      <DatePicker
        selected={selected}
        placeholderText={'Select observed moment'}
        dateFormat={'dd.MM.yyyy'}
        dateFormatCalendar={'MMMM yyyy'}
        showPopperArrow={false}
        shouldCloseOnSelect={true}
        timeIntervals={60}
        withPortal={true}
        openToDate={availableDates[0]}
        includeDates={availableDates}
        onChange={(e) => {
          e && setSelected(new Date(new Date(new Date(e.setHours(0)).setMinutes(0)).setSeconds(0)));
        }}
        minDate={new Date()}
        customInput={
          <ExampleCustomInput value={selected.toLocaleDateString()} onClick={() => {}} />
        }
      />
      <div className="stations">{renderDefs()}</div>
      <Button
        style={{ margin: '1rem 0 0 0' }}
        onClick={() => setDefs((prev) => [...prev, { ...cloneDeep(template[0]), id: v4() }])}
        label={'Add Location'}
        icon={<AiOutlinePlus />}
      />
    </div>
  );
};
