import { useKeycloak } from '@react-keycloak/web';
import { Button, Col, Input, Row } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useEffect, useState } from 'react';

import { AccountDTO } from '../../../model/DTO/AccountDTO';
import Loader from '../../dashboard/components/Loader';
import { useEditAccount } from '../../dashboard/queries-NEW/useEditAccount';
import { useGetProvider } from '../hooks/useGetProvider';

function Profile() {
  const { keycloak } = useKeycloak();
  const {
    data: provider,
    isLoading,
    refetch,
  } = useGetProvider('provider', keycloak?.tokenParsed?.email);
  const { mutate: editAccount, isLoading: editAccountLoading } = useEditAccount();
  const [user, setUser] = useState<AccountDTO>({
    firstName: '',
    lastName: '',
    description: '',
    isEnabled: true,
  });
  const [file, setFile] = useState<File>();
  const [edit, setEdit] = useState<boolean>(false);

  const updateUser = (property: keyof AccountDTO, value: string | boolean | File) => {
    setUser((prevUser) => ({ ...prevUser, [property]: value }));
  };

  const handleFile = (value: File) => {
    if (value) {
      const logoUrl = URL.createObjectURL(value);
      setUser((prevUser) => ({ ...prevUser, logoUrl }));
    }
    setFile(value);
  };

  useEffect(() => {
    if (provider) {
      setUser(provider);
    }
  }, [provider]);

  const save = () => {
    const formData = new FormData();
    formData.append('providerAccount', JSON.stringify(user));
    if (file) {
      formData.append('logo', file);
    }
    if (user.id) {
      editAccount(
        { api: 'provider-accounts', id: user.id, formData },
        {
          onSuccess() {
            refetch();
          },
        },
      );
    }
  };

  return (
    <>
      <h1 className="mb-8">Profile info</h1>
      {isLoading ? (
        <Loader />
      ) : (
        <Col xs={24}>
          <div className="flex">
            <div>
              Logo
              <img src={user.logoUrl} className="max-w-32 max-h-32 object-cover mb-2" />
              <Input
                type="file"
                accept="image/*"
                className="mb-2"
                onChange={(e) => e.target.files && handleFile(e.target.files[0])}
                disabled={!edit}
              />
            </div>
            <div className="w-full">
              <Row gutter={[16, 12]} align="middle">
                <Col xs={12}>
                  <span style={{ color: 'red' }}>*</span> Name
                  <Input
                    onChange={(e) => {
                      updateUser('firstName', e.target.value);
                      updateUser('lastName', e.target.value);
                      updateUser('name', e.target.value);
                    }}
                    value={user.name}
                    placeholder="Type name..."
                    disabled={!edit}
                  />
                </Col>
                <Col xs={12}>
                  <span style={{ color: 'red' }}>*</span> E-mail
                  <Input
                    onChange={(e) => updateUser('ownerEmail', e.target.value)}
                    value={user.ownerEmail}
                    placeholder="Type e-mail..."
                    disabled
                  />
                </Col>
                <Col xs={24}>
                  Description
                  <TextArea
                    onChange={(e) => updateUser('description', e.target.value)}
                    value={user.description}
                    placeholder="Type description..."
                    disabled={!edit}
                    className="border border-gray-500"
                  />
                </Col>
              </Row>
            </div>
          </div>

          <Row gutter={[16, 12]} align="middle">
            <Col xs={8}>
              Official name
              <Input
                onChange={(e) => updateUser('officialName', e.target.value)}
                value={user.officialName}
                placeholder="Type official name..."
                disabled={!edit}
              />
            </Col>
            <Col xs={8}>
              Organization type
              <Input
                onChange={(e) => updateUser('organizationType', e.target.value)}
                value={user.organizationType}
                placeholder="Type organization type..."
                disabled={!edit}
              />
            </Col>
            <Col xs={8}>
              Organization location
              <Input
                onChange={(e) => updateUser('organizationLocation', e.target.value)}
                value={user.organizationLocation}
                placeholder="Type organization location..."
                disabled={!edit}
              />
            </Col>
            <Col xs={8}>
              Ownership info
              <Input
                onChange={(e) => updateUser('ownershipInfo', e.target.value)}
                value={user.ownershipInfo}
                placeholder="Type ownership info..."
                disabled={!edit}
              />
            </Col>
            <Col xs={8}>
              Main contact
              <Input
                onChange={(e) => updateUser('mainContact', e.target.value)}
                value={user.mainContact}
                placeholder="Type main contact..."
                disabled={!edit}
              />
            </Col>
            <Col xs={8}>
              Office address
              <Input
                onChange={(e) => updateUser('officeAddress', e.target.value)}
                value={user.officeAddress}
                placeholder="Type office address..."
                disabled={!edit}
              />
            </Col>
            <Col xs={8}>
              Contact address
              <Input
                onChange={(e) => updateUser('contactAddress', e.target.value)}
                value={user.contactAddress}
                placeholder="Type contact address..."
                disabled={!edit}
              />
            </Col>
            <Col xs={8}>
              Marketing contact
              <Input
                onChange={(e) => updateUser('marketingContact', e.target.value)}
                value={user.marketingContact}
                placeholder="Type marketing contact..."
                disabled={!edit}
              />
            </Col>
            <Col xs={8}>
              Webpage link
              <Input
                onChange={(e) => updateUser('webpageLink', e.target.value)}
                value={user.webpageLink}
                placeholder="Type webpage link..."
                disabled={!edit}
              />
            </Col>
            <Col xs={8}>
              Support email
              <Input
                onChange={(e) => updateUser('supportEmail', e.target.value)}
                value={user.supportEmail}
                placeholder="Type support email..."
                disabled={!edit}
              />
            </Col>
            <Col xs={8}>
              Support phone
              <Input
                onChange={(e) => updateUser('supportPhone', e.target.value)}
                value={user.supportPhone}
                placeholder="Type support phone..."
                disabled={!edit}
              />
            </Col>
            <Col xs={8}>
              Support chat
              <Input
                onChange={(e) => updateUser('supportChat', e.target.value)}
                value={user.supportChat}
                placeholder="Type support chat..."
                disabled={!edit}
              />
            </Col>
            <Col xs={8}>
              Hours of operation
              <Input
                onChange={(e) => updateUser('hoursOfOperation', e.target.value)}
                value={user.hoursOfOperation}
                placeholder="Type hours of operation..."
                disabled={!edit}
              />
            </Col>
            <Col xs={8}>
              Billing frequency
              <Input
                onChange={(e) => updateUser('billingFrequency', e.target.value)}
                value={user.billingFrequency}
                placeholder="Type billing frequency..."
                disabled={!edit}
              />
            </Col>
            <Col xs={8}>
              Payment policy
              <Input
                onChange={(e) => updateUser('paymentPolicy', e.target.value)}
                value={user.paymentPolicy}
                placeholder="Type payment policy..."
                disabled={!edit}
              />
            </Col>
            <Col xs={8}>
              URL
              <Input
                onChange={(e) => updateUser('url', e.target.value)}
                value={user.url}
                placeholder="Type url..."
                disabled={!edit}
              />
            </Col>
            <Col xs={8}>
              Phone
              <Input
                onChange={(e) => updateUser('phone', e.target.value)}
                value={user.phone}
                placeholder="Type phone..."
                disabled={!edit}
              />
            </Col>
            <Col xs={8}>
              Actions
              <div className="flex gap-1">
                {edit ? (
                  <Button className="w-1/2" onClick={() => setEdit(false)}>
                    Cancel
                  </Button>
                ) : (
                  <Button className="w-1/2" onClick={() => setEdit(true)}>
                    Edit
                  </Button>
                )}
                <Button
                  className="w-1/2"
                  type="primary"
                  onClick={save}
                  disabled={!edit}
                  loading={editAccountLoading}
                >
                  Save
                </Button>
              </div>
            </Col>
          </Row>
        </Col>
      )}
    </>
  );
}

export default Profile;
