/**Specific for Full screen use case TO BE DONE Reconsider this - probably there is some css simple solution */

import { useSelector } from 'react-redux';

import { MAX_FULLSCREEN_HEIGHT } from '../model/constants/constants';
import { ActiveDef } from '../store/slices/active-slice';
import { RootState } from '../store/store';
import { useScaleFactor } from './useScaleFactor';
import useWindowDimensions from './useWindowDimensions';

export function useLeftMargin(isFullScreen: boolean) {
  const { activeAspectRatio } = useSelector<RootState, ActiveDef>((state) => state.active);
  const { width } = useWindowDimensions();
  const scaleFactor = useScaleFactor(isFullScreen);
  if (!isFullScreen) return 0;
  const unscaledWidth = MAX_FULLSCREEN_HEIGHT * (activeAspectRatio[0] / activeAspectRatio[1]);
  const scaledWidth = unscaledWidth * scaleFactor;
  return width > scaledWidth ? (width - scaledWidth) / 2 : 0;
}
