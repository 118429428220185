import { Tooltip } from 'flowbite-react';

import { useIsPlaying } from '../../../../hooks/useIsPlaying';
import useRoleAccess from '../../../../hooks/useRoleAccess';
import { RolesEnum } from '../../../../model/enums/RolesEnum';
import { SidebarItemsEnum } from '../../../../model/enums/SidebarItemsEnum';

interface SidebarItemProps {
  title: string;
  icon: JSX.Element;
  tab: SidebarItemsEnum;
  roles: RolesEnum[];
  setSidebarOpen: (tab: SidebarItemsEnum, e?: any) => void;
  sidebarItem: string;
  isSharedEntity: boolean;
  inEditMode: boolean;
}

const SidebarItem = ({
  title,
  icon,
  tab,
  roles,
  sidebarItem,
  isSharedEntity,
  inEditMode,
  setSidebarOpen,
}: SidebarItemProps) => {
  const isPlaying = useIsPlaying();
  const roleAccess = useRoleAccess(roles, isSharedEntity, inEditMode);

  return (
    <div className="item-wrapper">
      <Tooltip content={title} placement="right">
        <button
          disabled={isPlaying}
          className={`item ${sidebarItem !== tab ? '' : 'opened'} ${
            roleAccess ? '' : 'pointer-events-none opacity-30'
          }`}
          onClick={(e) => setSidebarOpen(tab, e)}
        >
          {icon}
        </button>
      </Tooltip>
    </div>
  );
};

export default SidebarItem;
