import React, { ChangeEvent, useState } from 'react';
import { IFileWithMeta } from 'react-dropzone-uploader';

import Input from '../../atoms/input/Input';
import TextArea from '../../atoms/input/Textarea';
import LoadingIndicator from '../../atoms/loadingIndicator/LoadingIndicator';
import FileInput from '../../molecules/FileUpload/FileUpload';

interface Props {
  editInput: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  formValues: any;
  onFile: (e: ChangeEvent) => void;
  type: 'IMAGE' | 'VIDEO' | 'AUDIO';
  displayStatus: string;
  setDisplayStatus: React.Dispatch<React.SetStateAction<string>>;
  setFile: React.Dispatch<React.SetStateAction<IFileWithMeta | undefined>>;
  file: IFileWithMeta | undefined;
}

const AddMultimediaElement: React.FC<Props> = ({
  editInput,
  formValues,
  onFile,
  type,
  displayStatus,
  setDisplayStatus,
  setFile,
  file,
}) => {
  const [successfullyAdded, setSuccessfullyAdded] = useState<boolean>(false);
  return (
    <div className={'form-body'}>
      <Input
        name={'name'}
        label={'Name'}
        value={formValues.name}
        onChange={(e) => editInput(e)}
        required
        type={'text'}
        disabled={successfullyAdded}
      />
      <TextArea
        name={'description'}
        label={'Description'}
        value={formValues.description}
        onChange={(e) => editInput(e)}
        cols={2}
        disabled={successfullyAdded}
      />
      <FileInput
        fileType={type}
        formValues={formValues}
        setSuccessfullyAdded={setSuccessfullyAdded}
        setFile={setFile}
        file={file}
        onChange={(e) => onFile(e)}
        displayStatus={displayStatus}
        setDisplayStatus={setDisplayStatus}
      />
      {successfullyAdded && (
        <div
          style={{
            marginTop: '0.5em',
            color: '#009a69',
            fontWeight: '500',
            display: 'flex',
            alignItems: 'center',
            gap: '0 5px',
            fontSize: '24px',
          }}
        >
          <LoadingIndicator /> Processing {type} element
        </div>
      )}
    </div>
  );
};

export default AddMultimediaElement;
