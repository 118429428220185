import { DraggableData } from 'react-rnd';

import CUT from '../animations/CUT';
import FADE_IN from '../animations/FADE_IN';
import FADE_OUT from '../animations/FADE_OUT';
import { SceneSimple } from '../components/timeline/Timeline';
import { C9ProjectDef } from '../model/definitions/C9ProjectDef';
import { SceneDef } from '../model/definitions/SceneDef';
import { TimeControlDef } from '../model/definitions/TimeControlDef';
import { AnimationsEnum } from '../model/enums/AnimationsEnum';
import { PanelDefs } from '../model/UI/PanelDef';

export const findMinInArray = (array: Array<any>, property?: string | number) => {
  const min =
    array.length > 0 &&
    array.reduce((prev: any, current: any) => {
      if (property) return prev[property] < current[property] ? prev[property] : current[property];
      else return prev < current ? prev : current;
    });
  if (property) return min[property];
  else return min;
};
export const findMaxInArray = (array: Array<any>, property?: string | number) => {
  return (
    array.length > 0 &&
    array.reduce((prev: any, current: any) => {
      if (property) return prev[property] > current[property] ? prev[property] : current[property];
      else return prev > current ? prev : current;
    })
  );
};
const getSceneStartEnd = (scene: SceneDef) => {
  const sceneElements = [
    ...scene.imagePanels,
    ...scene.videoPanels,
    ...scene.audioElements,
    ...scene.mapPanels,
    ...scene.weatherPosters,
    ...scene.textPanels,
  ].filter(Boolean);
  const allMin: Array<number> = [];
  const allMax: Array<number> = [];
  sceneElements.forEach((element) => {
    allMin.push(findMinInArray(element.timeControls, 'startMS'));
    allMax.push(findMinInArray(element.timeControls, 'endMS'));
  });
  return { startMS: findMinInArray(allMin), endMS: findMaxInArray(allMax) };
};
const totalSkippedTime = (project: C9ProjectDef) => {
  return project.skippedTime.reduce((sum, obj) => sum + (obj.endMS - obj.startMS), 0);
};
const getProjectDuration = (project: C9ProjectDef) => {
  // Helper function to get the maximum endMS for a specific panel type in a scene
  const getMaxEndMS = (scene: any, panelType: string) => {
    return (
      scene[panelType]?.reduce((max: number, panel: PanelDefs) => {
        const panelMax = panel.timeControls.reduce(
          (controlMax: number, control: TimeControlDef) =>
            Math.max(controlMax, Math.min(control.endMS, scene.durationInMS)),
          0,
        );
        return Math.max(max, panelMax);
      }, 0) || 0
    );
  };

  // Calculate the maximum duration for each scene in the project
  const projectDuration = project.sceneDefs.reduce((totalDuration, scene) => {
    const panelTypes = [
      'mapPanels',
      'textPanels',
      'timestampPanels',
      'imagePanels',
      'videoPanels',
      'audioElements',
      'animationPanels',
      'weatherPosters',
      'observedWDElements',
      'forecastWDElements',
    ];

    // Get the maximum end time across all panel types in the scene
    const sceneMaxEndMS = panelTypes.reduce(
      (sceneMax, type) => Math.max(sceneMax, getMaxEndMS(scene, type)),
      0,
    );

    // Add the maximum duration of the scene to the total duration
    return totalDuration + sceneMaxEndMS;
  }, 0);

  // Calculate the total transition duration between scenes
  const transitionSum = project.sceneDefs
    .slice(1)
    .reduce((sum, scene) => sum + scene.timeControl.inAnimationDuration, 0);

  return projectDuration - transitionSum;
};
const getScenePlaybackLength = (scene: SceneDef, activeOnly?: boolean): number => {
  const getMaxEndMS = (panels: any[]): number => {
    return panels.reduce((max, panel) => {
      const panelMax = panel.timeControls?.reduce(
        (controlMax: number, control: TimeControlDef) => Math.max(controlMax, control.endMS),
        0,
      );
      return Math.max(max, panelMax || 0);
    }, 0);
  };
  const panelTypes = [
    'mapPanels',
    'imagePanels',
    'animationPanels',
    'textPanels',
    'timestampPanels',
    'videoPanels',
    'weatherPosters',
    'audioElements',
    'observedWDElements',
    'forecastWDElements',
  ];
  const maxEndMS = panelTypes.reduce((max, type) => {
    //@ts-ignore
    const panels = activeOnly ? scene[type]?.filter((item) => item.enabled) : scene[type];
    return Math.max(max, getMaxEndMS(panels || []));
  }, 0);
  return scene.durationInMS >= maxEndMS ? (maxEndMS < 0 ? 0 : maxEndMS) : scene.durationInMS;
};
const getAnimationType = (type?: AnimationsEnum) => {
  switch (type) {
    case AnimationsEnum.FADE_IN:
      return FADE_IN;
    case AnimationsEnum.FADE_OUT:
      return FADE_OUT;
    default:
      return CUT;
  }
};
const getPrevShift = (project: C9ProjectDef, index: number) => {
  const prevScenes = project.sceneDefs.slice(1, index);
  return prevScenes.reduce((accumulator, currentValue) => {
    return accumulator + currentValue.timeControl.inAnimationDuration;
  }, 0);
};
const parseScenes = (project: C9ProjectDef): SceneSimple[] => {
  const simpleScenesArray: Array<SceneSimple> = [];

  project?.sceneDefs?.forEach((scene, index) => {
    const start =
      index === 0
        ? 0
        : project?.sceneDefs.slice(0, index).reduce((a, b) => +a + +getScenePlaybackLength(b), 0);
    simpleScenesArray.push({
      id: scene.id,
      name: scene.name,
      startMS:
        index === 0
          ? start
          : start -
            scene.timeControl.inAnimationDuration -
            (index > 1 ? getPrevShift(project, index) : 0),
      endMS:
        (index === 0 ? start : start - scene.timeControl.inAnimationDuration) +
        getScenePlaybackLength(scene) -
        getPrevShift(project, index),
      inAnimationDuration: scene.timeControl?.inAnimationDuration,
      outAnimationDuration: scene.timeControl?.outAnimationDuration,
      inAnimationDef: scene.timeControl?.inAnimationDef,
      outAnimationDef: scene.timeControl?.outAnimationDef,
      durationInMS: scene.durationInMS,
      thumbnailUrls: scene.thumbnailUrls,
    });
  });
  return simpleScenesArray;
};
const getMaxZindex = (currentScene: string, project: C9ProjectDef) => {
  const activeScene = project.sceneDefs.find((scene) => scene.id === currentScene);
  const allElements = [
    activeScene?.textPanels,
    activeScene?.imagePanels,
    activeScene?.videoPanels,
    activeScene?.mapPanels,
    activeScene?.observedWDElements,
    activeScene?.forecastWDElements,
  ].flat();
  const zIndexes: Array<number> = allElements
    .map((item) => (item ? item.positionControl.zindex : 0))
    .filter(Boolean);
  if (zIndexes.length === 0) return 1;
  else return Math.max(...zIndexes) + 1;
};
export const getNumber = (percent: number, width?: number): number => {
  if (width) return (percent * width) / 100;
  return 0;
};
export const absoluteToPercent = (
  segments: TimeControlDef[],
  duration: number,
  offsetStart = 0,
  offsetEnd = 0,
) => {
  const values: TimeControlDef[] = [];
  segments &&
    segments?.forEach((item) => {
      values.push({
        ...item,
        startMS: ((item?.startMS + offsetStart) * 100) / duration,
        endMS: (item?.endMS * 100) / duration,
      });
    });
  return values;
};
export const partialAbsoluteToPercent = (
  segments: Array<Pick<TimeControlDef, 'startMS' | 'endMS'> & Partial<TimeControlDef>>,
  duration: number,
  offsetStart = 0,
  offsetEnd = 0,
) => {
  const values: Array<Pick<TimeControlDef, 'startMS' | 'endMS'> & Partial<TimeControlDef>> = [];
  segments &&
    segments?.forEach((item) => {
      values.push({
        ...item,
        startMS: ((item?.startMS + offsetStart) * 100) / duration,
        endMS: ((item?.endMS > duration ? duration : item?.endMS) * 100) / duration,
      });
    });
  return values;
};
export const getPercentFromData = (data: DraggableData, width?: number): number => {
  if (width) return (data.x * 100) / width;
  return 0;
};
export {
  getAnimationType,
  getMaxZindex,
  getProjectDuration,
  getScenePlaybackLength,
  getSceneStartEnd,
  parseScenes,
  totalSkippedTime,
};
