import { Button, InputNumber, Typography } from 'antd';
import { useState } from 'react';
import { IoLinkOutline, IoUnlinkOutline } from 'react-icons/io5';

import SymbolImageModal from '../../../modals/SymbolImageModal';
import GridItem from '../../shared/GridItem';
import GridWrapper from '../../shared/GridWrapper';

const { Title } = Typography;
interface Feture {
  drawingType: string;
  zIndex: number;
  image: string;
  name: string;
  imageWidth: number;
  imageHeight: number;
  lockAspectRatio: boolean;
}
interface ImageSetupProps {
  feature: Feture;
  onChange: (e: string | boolean | number, prop: string, name?: string) => void;
}
export const ImageSetup = ({ feature, onChange }: ImageSetupProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <GridWrapper className={'py-5 pr-5'}>
      <GridItem
        label={'Drawing type'}
        noBorderBg
        item={
          <Title level={5} className="!mb-0">
            {feature.drawingType}
          </Title>
        }
      />
      <GridItem
        label={'Layer level'}
        item={
          <InputNumber
            key={feature.zIndex}
            className={'border-0 bg-transparent w-full rounded-0'}
            value={feature.zIndex}
            onChange={(e) => onChange((e ?? 0).toString(), 'zIndex')}
          />
        }
      />
      {feature.image ? (
        <GridItem
          itemStyle={{ height: 'auto' }}
          label={'Image:'}
          noBorderBg
          item={
            <>
              <img src={feature.image} className="aspect-square max-h-14 object-cover mr-3" />
              <Button className="!h-auto" onClick={() => setIsOpen(true)}>
                Change image
              </Button>
            </>
          }
        />
      ) : (
        <GridItem
          itemStyle={{ height: 'auto' }}
          label={'Image:'}
          noBorderBg
          item={
            <Button className="!h-auto" onClick={() => setIsOpen(true)}>
              Choose file
            </Button>
          }
        />
      )}
      <GridItem itemStyle={{ height: 'auto' }} label={''} noBorderBg item={<></>} />
      <GridItem
        label={`Image width:`}
        item={
          <InputNumber
            max={100}
            className={'border-0 bg-transparent w-full rounded-0'}
            onChange={(e) => e != null && onChange(e, 'imageWidth')}
            value={feature.imageWidth}
          />
        }
      />
      <GridItem
        label={
          <div
            className={'flex items-center justify-between cursor-pointer'}
            onClick={() => onChange(!feature.lockAspectRatio, 'lockAspectRatio')}
          >
            {
              <span>
                {feature.lockAspectRatio ? (
                  <IoLinkOutline style={{ color: 'green' }} />
                ) : (
                  <IoUnlinkOutline />
                )}
              </span>
            }
            Image height:
          </div>
        }
        item={
          <InputNumber
            max={100}
            className={'border-0 bg-transparent w-full rounded-0'}
            onChange={(e) => e != null && onChange(e, 'imageHeight')}
            value={feature.imageHeight}
          />
        }
      />
      <SymbolImageModal
        isOpen={isOpen}
        currentImage={feature.image}
        currentName={feature.name}
        onClose={() => setIsOpen(false)}
        onSave={(selectedImage, name) => {
          setIsOpen(false);
          onChange(selectedImage, 'image', name);
        }}
      />
    </GridWrapper>
  );
};
