import { ScreenshotData } from '../../helpers/screenshotElement';
import { ImagePanelDefTemplate } from '../../model/definitions/ImagePanelDefTemplate';
import { axiosInstance } from './axiosInstance';

const JsonLottieResponse: Record<string, string> = {};

const getMultimediaByVersionId = async (id: string, isJson?: boolean) => {
  if (isJson && JsonLottieResponse[id]) return JsonLottieResponse[id];
  const res = await axiosInstance.get(`multimedia/download/${id}`, {
    responseType: isJson ? 'json' : 'arraybuffer',
  });
  if (isJson) {
    JsonLottieResponse[id] = res.data;
  }
  return res.data;
};

const setMultimediaThumbnail = async (data: {
  versionId: string;
  thumbnail: ScreenshotData;
  multimediaType: 'IMAGE' | 'VIDEO' | 'AUDIO';
}) => {
  const {
    versionId,
    thumbnail: { blob },
    multimediaType,
  } = data;
  const fData = new FormData();
  fData.append('versionId', versionId);
  fData.append('files', blob!, 'file1');
  const res = await axiosInstance.post(`multimedia/${multimediaType}/setThumbnail`, fData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return res.data;
};

const uploadImageFile = async (data: {
  name: string;
  description: string;
  media: Blob;
}): Promise<ImagePanelDefTemplate> => {
  const { name, description, media } = data;
  const fData = new FormData();
  fData.append('file', media);
  fData.append('type', 'IMAGE');
  fData.append('description', description ? description : '');
  fData.append('name', name ? name : '');
  const res = await axiosInstance.post<ImagePanelDefTemplate>('multimedia/uploadFile', fData, {
    headers: { 'content-type': 'multipart/form-data' },
  });
  return res.data;
};

const imageToBlob = async (url?: string) => {
  if (!url) return;
  const id = url.substring(url.lastIndexOf('/') + 1);
  const { data } = await axiosInstance.get(`multimedia/download/cdn/${id}`, {
    responseType: 'arraybuffer',
  });
  return URL.createObjectURL(new Blob([data], { type: 'octet/stream' }));
};

export { getMultimediaByVersionId, imageToBlob, setMultimediaThumbnail, uploadImageFile };
