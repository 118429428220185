import React from 'react';

interface Props {
  list: any;
  title: string;
}
const FooterList: React.FC<Props> = ({ list, title }) => {
  function showList() {
    return list?.map((el: any, index: number) => {
      return (
        <li key={index}>
          <a href={el.link}>{el.name}</a>
        </li>
      );
    });
  }
  return (
    <div>
      <span className="title">{title}</span>
      <ul>{showList()}</ul>
    </div>
  );
};

export default FooterList;
