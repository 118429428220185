export enum ColorPaletteParamTypeEnum {
  TEMPERATURE = 'TEMPERATURE',
  PRECIPITATION = 'PRECIPITATION',
  CLOUD = 'CLOUD',
  RADAR = 'RADAR',
  SATELLITE_INFRARED_IMAGERY = 'SATELLITE_INFRARED_IMAGERY',
  SATELLITE_WATER_VAPOR_IMAGERY = 'SATELLITE_WATER_VAPOR_IMAGERY',
  SATELLITE_VISIBLE_IMAGERY = 'SATELLITE_VISIBLE_IMAGERY',
  PRESSURE = 'PRESSURE',
  WIND = 'WIND',
  HEIGHT = 'HEIGHT',
}
