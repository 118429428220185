export class PositionControlDef {
  x = 0;
  y = 0;
  w: number;
  h: number;
  zindex = 1;
  rotation = 0;
  constructor(w = 0, h = 0, x = 0, y = 0) {
    this.w = w;
    this.h = h;
    this.x = x;
    this.y = y;
  }
}
