import axios from 'axios';

import { BaseMapDef } from '../../model/definitions/BaseMapDef';
import { MapConfigurationObject } from '../../model/definitions/MapConfigurationObject';
import { MapPanelDef } from '../../model/definitions/MapPanelDef';
import { SceneDef } from '../../model/definitions/SceneDef';
import { TextAnimation } from '../../model/definitions/TextAnimation';
import { TextPanelDef } from '../../model/definitions/TextPanelDef';
import { axiosInstance } from './axiosInstance';

export interface FormValues extends Record<any, any> {
  name?: string;
  description?: string;
  width?: number;
  height?: number;
}

const addTextElement = async (data: FormValues): Promise<TextPanelDef> => {
  const res = await axiosInstance.post<TextPanelDef>(`multimedia/text-panel-element`, { ...data });
  res.data.textAnimation = new TextAnimation();
  return res.data;
};

const addMapElement = async (data: MapPanelDef): Promise<MapPanelDef> => {
  const res = await axiosInstance.post<MapPanelDef>(`map/map-panel-def`, {
    ...data,
  });
  if (!res.data.flyOver) res.data.flyOver = { keyFrames: [] };
  return res.data;
};

const getMapConfig = async (data: BaseMapDef): Promise<MapConfigurationObject> => {
  const res = await axiosInstance.post(`map/tiles/new-map`, data);
  return res.data;
};

const getMapConfigDirect = async (baseMapId: string): Promise<MapConfigurationObject> => {
  const res = await axios.get(`https://tiles-dev.c9weather.com/api/getMapConfig/${baseMapId}`);
  return res.data;
};

const addSceneElement = async (data: {
  name: string;
  description: string;
  duration: number;
  startDate?: number | null;
  endDate?: number | null;
}): Promise<SceneDef> => {
  const { name, description, duration, startDate, endDate } = data;
  const res = await axiosInstance.post<SceneDef>(`scene/scene-def`, null, {
    params: { name, description, duration, startDate, endDate },
  });
  res.data.pointDates = [];
  return res.data;
};

export { addMapElement, addSceneElement, addTextElement, getMapConfig, getMapConfigDirect };
