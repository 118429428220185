import { cloneDeep } from 'lodash';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { queryClient } from '../..';
import { C9ProjectDef } from '../../model/definitions/C9ProjectDef';
import { key } from '../../pages/dashboard/queries-NEW/useGetProjects';
import { addNewProject, setSavedProject } from '../../store/slices/project-slice';
import { axiosInstance } from './axiosInstance';

export const useUnlockProject = () => {
  const dispatch = useDispatch();
  return useMutation(
    async (id: string): Promise<C9ProjectDef> => {
      const response = await axiosInstance.put<C9ProjectDef>(`project/unlock-shared-project/${id}`);
      return response.data;
    },
    {
      onSuccess: (data) => {
        const project = cloneDeep(data);
        dispatch(addNewProject({ project }));
        dispatch(setSavedProject({ savedProject: project }));
        toast.success('Successfully unlocked project');
        queryClient.invalidateQueries(key);
      },
      onError: () => {
        toast.error('There was an error while trying to unlock project');
      },
    },
  );
};
