import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';

import { findMaxInArray } from '../../../helpers/timelineUtil';
import {
  DEFAULT_FONT_FAMILY,
  DEFAULT_FONT_TYPE,
  DEFAULT_FONT_VARIANT_ID,
} from '../../../model/constants/constants';
import { BorderDef } from '../../../model/definitions/BorderDef';
import { LogicalGroupParent } from '../../../model/definitions/LogicalGroupParent';
import { PointDateDef } from '../../../model/definitions/PointDateDef';
import { PointLocationDef } from '../../../model/definitions/PointLocationDef';
import { TextAnimation } from '../../../model/definitions/TextAnimation';
import { TimeControlDef } from '../../../model/definitions/TimeControlDef';
import { PointLabel } from '../../../model/enums/PointLabel';
import { transformAbsoluteToPercent } from '../../../molecules/canvasElements/utils';
import { setDuration } from '../../../store/slices/active-slice';
import { addPointDateLayer, addPointLocationLayer } from '../../../store/slices/project-slice';
import { axiosInstance } from '../axiosInstance';
import { FormValues } from '../ElementsAPI';

export const useAddPointDateElement = () => {
  const dispatch = useDispatch();
  return useMutation(
    async ({
      data,
      aspectRatio,
      previewSize,
      duration,
      zIndex,
      groupId,
      activeScene,
      pointLabel,
    }: {
      data: FormValues;
      aspectRatio: [number, number];
      previewSize: number;
      duration: number;
      zIndex: number;
      groupId: string;
      activeScene: string;
      pointLabel: PointLabel;
    }): Promise<PointDateDef> => {
      const res = await axiosInstance.post<PointDateDef>(`multimedia/text-panel-element`, {
        ...data,
      });
      res.data.textAnimation = new TextAnimation();
      return res.data;
    },
    {
      onSuccess: (data: PointDateDef, args) => {
        const { aspectRatio, previewSize, duration, zIndex, groupId, activeScene } = args;
        const res: PointDateDef | PointLocationDef = JSON.parse(JSON.stringify(data));
        const { x, y, w, h, zindex, rotation } = res.positionControl;
        res.positionControl = {
          x: transformAbsoluteToPercent(x, aspectRatio, 'width', previewSize),
          w: transformAbsoluteToPercent(w, aspectRatio, 'width', previewSize),
          y: transformAbsoluteToPercent(y, aspectRatio, 'height', previewSize),
          h: transformAbsoluteToPercent(h, aspectRatio, 'height', previewSize),
          zindex,
          rotation,
        };
        const time = [new TimeControlDef()];
        time[0].endMS = duration;
        res.timeControls = time;
        res.fontSize = 4;
        res.boxDef.background.color = 'rgba(255, 255, 255, 0)';
        res.boxDef.borderBottom = new BorderDef();
        res.boxDef.borderRight = new BorderDef();
        res.boxDef.borderTop = new BorderDef();
        res.boxDef.borderLeft = new BorderDef();
        res.fontColor = 'rgba(255, 255, 255, 255)';
        res.strokeColor = 'rgba(255, 255, 255, 255)';
        res.strokeWidth = 0;
        res.positionControl.zindex = zIndex;
        res.parentGroups = [new LogicalGroupParent()];
        res.enabled = true;
        res.dateFormat = 'DD.MM.YYYY HH:mm';
        res.pointWDGroupId = groupId;
        res.fontFamily = DEFAULT_FONT_FAMILY;
        res.fontType = DEFAULT_FONT_TYPE;
        res.fontVariantId = DEFAULT_FONT_VARIANT_ID;
        res.relativeFormat = false;

        args.pointLabel === PointLabel.DATE &&
          dispatch(
            addPointDateLayer({
              dateLayer: res,
              activeScene: activeScene,
            }),
          );
        args.pointLabel === PointLabel.LOCATION &&
          dispatch(
            addPointLocationLayer({
              locationLayer: {
                ...res,
                maskValue: res.value,
                useMaskValue: false,
              } as PointLocationDef,
              activeScene: activeScene,
            }),
          );
        const end = findMaxInArray(data.timeControls, 'endMS');
        end.endMS > duration &&
          dispatch(
            setDuration({
              duration: end.endMS,
            }),
          );
      },
    },
  );
};
