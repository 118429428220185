import { Tooltip } from 'flowbite-react';
import { isEqualWith } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { IoSaveOutline } from 'react-icons/io5';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { ActionCreators } from 'redux-undo';

import Redo from '../../assets/icons/Redo';
import Undo from '../../assets/icons/Undo';
import Button from '../../atoms/button/Button';
import { saveProject } from '../../core/api/ProjectsAPI';
import { useIsPlaying } from '../../hooks/useIsPlaying';
import useRoleAccess from '../../hooks/useRoleAccess';
import { C9ProjectDef } from '../../model/definitions/C9ProjectDef';
import { RolesEnum } from '../../model/enums/RolesEnum';
import { addNewProject, ProjectState, setSavedProject } from '../../store/slices/project-slice';
import { RootState, store } from '../../store/store';
// import UnsavedChanges from '../unsavedChanges/UnsavedChanges';
import style from './style.module.scss';

function removeIndicators(project: C9ProjectDef) {
  const proj = { ...project };
  proj.sceneDefs = project.sceneDefs.map((s) => ({
    ...s,
    mapPanels: s.mapPanels.map((m) => ({
      ...m,
      wdSpace: m.wdSpace.map((wd) => ({ ...wd, indicator: [] })),
    })),
  }));
  return proj;
}
const SaveUndoRedo: React.FC = () => {
  const dispatch = useDispatch();
  const { project, currentSavedProject } = useSelector<RootState, ProjectState>(
    (state) => state.project.present,
  );
  const roleAccess = useRoleAccess(
    [RolesEnum.ROLE_CREATOR, RolesEnum.ROLE_MAINTAINER, RolesEnum.ROLE_FORECASTER],
    project.isSharedEntity,
    project.inEditMode,
  );
  const mapIsLoading = useSelector<RootState, boolean>((state) => state.mapLoading.mapIsLoading);
  const isPlaying = useIsPlaying();

  const hasChanges = useMemo(() => {
    return !isEqualWith(project, currentSavedProject, (proj, savedProj) => {
      return JSON.stringify(removeIndicators(proj)) === JSON.stringify(removeIndicators(savedProj));
    });
  }, [currentSavedProject, project]);

  const saveMutation = useMutation(saveProject, {
    onSuccess: (data) => {
      if (data) {
        dispatch(addNewProject({ project: data }));
        dispatch(setSavedProject({ savedProject: data }));
      }
    },
  });

  const onSave = () => {
    saveMutation.mutate(project);
  };

  useEffect(() => {
    function onKeyDown(e: KeyboardEvent) {
      if ((e.ctrlKey || e.metaKey) && e.key === 's') {
        e.preventDefault();
        saveAction();
      }
      if ((e.ctrlKey || e.metaKey) && e.key === 'z') {
        e.preventDefault();
        undoAction();
      }
      if ((e.ctrlKey || e.metaKey) && e.key === 'y') {
        e.preventDefault();
        redoAction();
      }
    }
    window.addEventListener('keydown', onKeyDown);
    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [project, saveMutation]);
  const undoAction = () => {
    if (store.getState().project.past.length < 3) return;
    dispatch(ActionCreators.undo());
  };
  const redoAction = () => {
    if (store.getState().project.future.length < 1) return;
    dispatch(ActionCreators.redo());
  };
  const saveAction = () => {
    if (!hasChanges) return;
    if (!saveMutation.isLoading) {
      saveMutation.mutate(project);
    }
  };
  return (
    <>
      <div>
        <div className={`${style.buttonControls} flex`}>
          <Tooltip content="Save project (CTRL + S)" placement="bottom-start" style={`dark`}>
            <Button
              size={'small'}
              icon={<IoSaveOutline />}
              onClick={onSave}
              disabled={
                saveMutation.isLoading || !hasChanges || isPlaying || mapIsLoading || !roleAccess
              }
              className={`${style.hoverState} relative ${style.saveBtn}`}
              label={hasChanges && <span className={`absolute ${style.redNotification}`}></span>}
              buttonType={'border'}
            />
          </Tooltip>
          <Tooltip content="Undo (CTRL + Z)" placement="bottom-start" style={`dark`}>
            <Button
              size={'small'}
              icon={<Undo />}
              onClick={() => dispatch(ActionCreators.undo())}
              disabled={store.getState().project.past.length < 2 || isPlaying || !roleAccess}
              className={style.hoverState}
              buttonType={'border'}
            />
          </Tooltip>
          <Tooltip content="Redo (CTRL + Y)" placement="bottom-start" style={`dark`}>
            <Button
              size={'small'}
              icon={<Redo />}
              onClick={() => dispatch(ActionCreators.redo())}
              disabled={store.getState().project.future.length < 1 || isPlaying || !roleAccess}
              className={style.hoverState}
              buttonType={'border'}
            />
          </Tooltip>
        </div>
      </div>
      {/* <UnsavedChanges onSave={onSave} /> */}
    </>
  );
};

export default SaveUndoRedo;
