type NestedObject = { [key: string]: NestedObject | any };

export const findProperties = (obj: NestedObject, targetProperty: string): any[] => {
  const result: any[] = [];

  function searchProperties(innerObj: NestedObject) {
    for (const key in innerObj) {
      // eslint-disable-next-line no-prototype-builtins
      if (innerObj.hasOwnProperty(key)) {
        if (key === targetProperty) {
          result.push(innerObj[key]);
        } else if (typeof innerObj[key] === 'object') {
          searchProperties(innerObj[key] as NestedObject);
        }
      }
    }
  }

  searchProperties(obj);
  return result;
};
