import { useMutation } from 'react-query';

import { axiosInstance } from '../../../core/api/axiosInstance';
import { queryClient } from '../../../index';
import { key } from './useGetTemplates';

const useDeleteTemplate = () => {
  return useMutation(
    async ({ id, type }: { id: string; type: string }) => {
      const { data } = await axiosInstance.delete(
        `${
          type === 'poster' ? 'weather-poster' : type === 'geoposter' ? 'weather-geo-poster' : type
        }-template/${type === 'icon-set' ? 'current/' + id : id}`,
      );
      return data;
    },
    {
      onSuccess: () => queryClient.invalidateQueries(key),
    },
  );
};

export default useDeleteTemplate;
