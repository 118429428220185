import { WeatherPosterTypeEnum } from '../enums/WeatherPosterTypeEnum';
import { AnimationPanelDef } from './AnimationPanelDef';
import { AudioElement } from './AudioElement';
import { BackgroundDef } from './BackgroundDef';
import { C9Object } from './C9Object';
import { ForecastWDElementDef } from './ForecastWDElementDef';
import { ImagePanelDef } from './ImagePanelDef';
import { LogicalGroupParent } from './LogicalGroupParent';
import { ObservedWDElementDef } from './ObservedWDElementDef';
import { PointDateDef } from './PointDateDef';
import { PointLocationDef } from './PointLocationDef';
import { PositionControlDef } from './PositionControlDef';
import { TextPanelDef } from './TextPanelDef';
import { TimeControlDef } from './TimeControlDef';
import { VideoPanelDef } from './VideoPanelDef';

export class WeatherPosterDef extends C9Object {
  timeControls: TimeControlDef[];
  positionControl: PositionControlDef;
  background: BackgroundDef;
  title: TextPanelDef;
  videoPanels: Array<VideoPanelDef>;
  imagePanels: Array<ImagePanelDef>;
  animationPanels: Array<AnimationPanelDef>;
  textPanels: Array<TextPanelDef> = [];
  audioElements: Array<AudioElement>;
  observedWDElements: Array<ObservedWDElementDef>;
  forecastWDElements: Array<ForecastWDElementDef>;
  pointDates: PointDateDef[] = [];
  pointLocation: PointLocationDef[] = [];
  parentGroups = [new LogicalGroupParent()];
  posterType: WeatherPosterTypeEnum;
  useAsGeoPoster: boolean;
  enabled: boolean;
  constructor(
    timeStart: number,
    timeEnd: number,
    position: PositionControlDef,
    name: string,
    isGeo?: boolean,
  ) {
    super();
    this.timeControls = [new TimeControlDef(timeStart, timeEnd)];
    this.positionControl = position;
    this.name = name;
    isGeo ? (this.useAsGeoPoster = true) : (this.useAsGeoPoster = false);
  }
}
