import { Checkbox, Typography } from 'antd';
import { Button, Select } from 'flowbite-react';
import { cloneDeep, set } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { AiOutlineCaretDown, AiOutlineCaretUp, AiOutlineDrag } from 'react-icons/ai';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import ColorPickerRefactor from '../../../atoms/color-picker-refactor/ColorPickerRefactor';
import Input from '../../../atoms/input/Input';
import LoadingIndicator from '../../../atoms/loadingIndicator/LoadingIndicator';
import { useFontLoader } from '../../../core/api/useLoadFont';
import { queryCitiesByViewport } from '../../../core/api/WeatherAPI';
import { useDebounce } from '../../../hooks/useDebounce';
import { usePropertyGridActive } from '../../../hooks/usePropertyGridActive';
import { CityGeoPosterDef } from '../../../model/definitions/CityGeoPosterDef';
import { MapPanelDef } from '../../../model/definitions/MapPanelDef';
import { CitiesQueryDTO } from '../../../organisms/addElementModal/CitiesQueryDTO';
import { ActiveDef, setPropertyGridActiveHash } from '../../../store/slices/active-slice';
import { updateMapLayer } from '../../../store/slices/project-slice';
import {
  selectActiveCityGeoposter,
  selectActiveMapLayerByActiveMap,
} from '../../../store/slices/selectors';
import { RootState } from '../../../store/store';
import { CitiesImageInput } from '../modals/components/CitiesImageInput';
import transformText from './components/slatejs/transformText';
import { FontInterface, FontVariantInterface } from './panels/FontProperties';
import styles from './Properties.module.scss';
import GridActions from './shared/GridActions';
import GridItem from './shared/GridItem';
import GridWrapper from './shared/GridWrapper';

const anchorProps = [
  'anchor.shape',
  'anchor.size',
  'anchor.fillColor',
  'anchor.borderColor',
  'anchor.borderWidth',
];

const labelProps = [
  'textPanels[0].value',
  'textPanels[0].fontSize',
  'textPanels[0].fontFamily',
  'textPanels[0].fontType',
  'textPanels[0].fontVariantId',
  'textPanels[0].fontAlignment',
  'textPanels[0].fontColor',
  'textPanels[0].strokeWidth',
  'textPanels[0].strokeColor',
];
const { Text } = Typography;

const CityGeoPosterProperties: React.FC<{}> = () => {
  const activeGeoPoster = useSelector<RootState, CityGeoPosterDef>((state) =>
    selectActiveCityGeoposter(state),
  );

  const activeMapDef = useSelector<RootState, MapPanelDef>((state) =>
    selectActiveMapLayerByActiveMap(state),
  );
  const { activeScene, activeElement } = useSelector<RootState, ActiveDef>((state) => state.active);
  const dispatch = useDispatch();
  const { lastFocused } = usePropertyGridActive([]);
  const cityTextEl = activeGeoPoster?.textPanels[0];
  const [anchorOpen, setAnchorOpen] = useState(anchorProps.includes(lastFocused));
  const [labelOpen, setLabelOpen] = useState(labelProps.includes(lastFocused));
  const { fonts, fontVariants, fetchFontVariants } = useFontLoader(cityTextEl?.fontFamily);

  const showResetBtn =
    activeGeoPoster &&
    activeGeoPoster.properties?.defaultLon &&
    activeGeoPoster.properties?.defaultLat &&
    Number(activeGeoPoster.properties?.defaultLon) !== activeGeoPoster.anchor.longitude &&
    Number(activeGeoPoster.properties?.defaultLat) !== activeGeoPoster.anchor.latitude;
  function onChangeVal(prop: Paths<CityGeoPosterDef>, newValue: any) {
    onFocus(prop);
    const currCityGeoposters = cloneDeep(activeMapDef.cityPosters);
    const foundGP = currCityGeoposters.find((p) => p.id === activeGeoPoster.id)!;
    set(foundGP, prop, newValue);

    dispatch(
      updateMapLayer({
        activeScene,
        elementId: activeMapDef.id,
        propertyPath: 'cityPosters',
        newValue: currCityGeoposters,
      }),
    );
  }
  function onFocus(path: Paths<CityGeoPosterDef>) {
    dispatch(setPropertyGridActiveHash({ activeElement, focusedEl: path }));
  }
  function onChangeFont(
    prop: Paths<CityGeoPosterDef>,
    fontFamily: string,
    fontType: string,
    fontVariantId: string,
  ) {
    onFocus(prop);
    const currCityGeoposters = cloneDeep(activeMapDef.cityPosters);
    const foundGP = currCityGeoposters.find((p) => p.id === activeGeoPoster.id)!;
    set(foundGP, prop, fontFamily);
    foundGP.textPanels[0].fontVariantId = fontVariantId;
    foundGP.textPanels[0].fontType = fontType;

    dispatch(
      updateMapLayer({
        activeScene,
        elementId: activeMapDef.id,
        propertyPath: 'cityPosters',
        newValue: currCityGeoposters,
      }),
    );
  }

  function onResetPosition() {
    const currCityGeoposters = cloneDeep(activeMapDef.cityPosters);
    const foundGP = currCityGeoposters.find((p) => p.id === activeGeoPoster.id)!;
    if (foundGP) {
      foundGP.anchor.longitude = Number(activeGeoPoster.properties?.defaultLon);
      foundGP.anchor.latitude = Number(activeGeoPoster.properties?.defaultLat);
      dispatch(
        updateMapLayer({
          activeScene,
          elementId: activeMapDef.id,
          propertyPath: 'cityPosters',
          newValue: currCityGeoposters,
        }),
      );
    }
  }

  useEffect(() => {
    if (!fetchFontVariants.isLoading && fontVariants && !cityTextEl?.fontType) {
      const defaultFontVariant = fontVariants?.find(
        (fontVariant: FontVariantInterface) => fontVariant.defaultType,
      );

      if (defaultFontVariant) {
        onChangeFont(
          // @ts-ignore
          'textPanels[0].fontType',
          cityTextEl?.fontFamily,
          defaultFontVariant.type,
          defaultFontVariant.id,
        );
      }
    }
  }, [fontVariants]);
  const [cityQuery, setCityQuery] = useState('');
  const [dropdownOpened, setDropdownOpened] = useState(false);
  const [nameOnly, setNameOnly] = useState(false);
  function onChange(ev: React.ChangeEvent<HTMLInputElement>): void {
    setDropdownOpened(true);
    setCityQuery(ev.target.value);
  }
  const debouncedQuery = useDebounce(cityQuery, 300);
  const { data: citiesList, isLoading } = useQuery(
    ['query-cities-viewport', debouncedQuery, activeMapDef.id],
    () =>
      queryCitiesByViewport(debouncedQuery, activeMapDef.baseMapSetup.baseMapConfigurationBounds),
    { cacheTime: Infinity, staleTime: Infinity },
  );
  function onCitySelect(city: CitiesQueryDTO) {
    setCityQuery('');
    const currCityGeoposters = cloneDeep(activeMapDef.cityPosters);
    const foundGP = currCityGeoposters.find((p) => p.id === activeGeoPoster.id)!;
    if (!nameOnly) {
      foundGP.anchor.latitude = city.lat;
      foundGP.anchor.longitude = city.lon;
    }
    foundGP.name = city.name;
    foundGP.textPanels[0].value = city.name;
    foundGP.textPanels[0].name = city.name;
    foundGP.countryName = city.country;
    setDropdownOpened(false);
    dispatch(
      updateMapLayer({
        activeScene,
        elementId: activeMapDef.id,
        propertyPath: 'cityPosters',
        newValue: currCityGeoposters,
      }),
    );
  }
  const searchRef = useRef<HTMLInputElement>(null);

  return (
    <div className={styles.wrapper}>
      <div className={'p-5 flex flex-col gap-2'}>
        <div className={'flex items-center gap-2'}>
          <AiOutlineDrag /> Drag to move
        </div>
        <div>
          <Text keyboard>Shift</Text> + click to copy
        </div>
      </div>
      <div
        className={`mb-2 subheader layer-header ${anchorOpen ? 'layer-header-active' : ''}`}
        onClick={() => setAnchorOpen((o) => !o)}
      >
        {anchorOpen ? <AiOutlineCaretUp /> : <AiOutlineCaretDown />}
        Anchor properties
      </div>
      {anchorOpen && (
        <div className="prop-wrapper">
          <GridWrapper>
            <GridItem
              label="Type:"
              item={
                <Select
                  className={styles.select}
                  onChange={(e) => onChangeVal('anchor.type', e.target.value)}
                  value={activeGeoPoster.anchor.type}
                >
                  {['shape', 'image'].map((k) => (
                    <option key={k} value={k}>
                      {k}
                    </option>
                  ))}
                </Select>
              }
            />
            {activeGeoPoster.anchor.type == 'image' ? (
              <>
                <GridItem
                  itemStyle={{ height: 'auto' }}
                  label={'Image:'}
                  noBorderBg
                  item={
                    <CitiesImageInput
                      value={activeGeoPoster.anchor.imageURL}
                      onChange={(e) => onChangeVal('anchor.imageURL', e)}
                    />
                  }
                />

                {activeGeoPoster.anchor.imageURL && (
                  <>
                    <GridItem
                      label={'Width:'}
                      item={
                        <Input
                          style={{ padding: '0' }}
                          type={'number'}
                          onChange={(e) => onChangeVal('anchor.width', e.target.value)}
                          value={activeGeoPoster.anchor.width}
                          className={styles.inputWrap}
                          onFocus={() => onFocus('anchor.width')}
                          autoFocus={lastFocused === 'anchor.width'}
                        />
                      }
                    />
                    <GridItem
                      label={'Height:'}
                      item={
                        <Input
                          style={{ padding: '0' }}
                          type={'number'}
                          onChange={(e) => onChangeVal('anchor.height', e.target.value)}
                          value={activeGeoPoster.anchor.height}
                          className={styles.inputWrap}
                          onFocus={() => onFocus('anchor.height')}
                          autoFocus={lastFocused === 'anchor.height'}
                        />
                      }
                    />
                  </>
                )}
              </>
            ) : null}
            {activeGeoPoster.anchor.type == 'shape' ? (
              <>
                <GridItem
                  label="Shape:"
                  item={
                    <Select
                      className={styles.select}
                      onChange={(e) => onChangeVal('anchor.shape', e.target.value)}
                      value={activeGeoPoster.anchor.shape}
                    >
                      {['circle', 'square', 'cross'].map((k) => (
                        <option key={k} value={k}>
                          {k}
                        </option>
                      ))}
                    </Select>
                  }
                />
                <GridItem
                  label={'Size:'}
                  item={
                    <Input
                      style={{ padding: '0' }}
                      type={'number'}
                      onChange={(e) => onChangeVal('anchor.size', e.target.value)}
                      value={activeGeoPoster.anchor.size}
                      className={styles.inputWrap}
                      onFocus={() => onFocus('anchor.size')}
                      autoFocus={lastFocused === 'anchor.size'}
                    />
                  }
                />

                <GridItem
                  noBorderBg
                  label={'Fill color:'}
                  item={
                    <ColorPickerRefactor
                      value={activeGeoPoster.anchor.fillColor}
                      onChange={(e) => onChangeVal('anchor.fillColor', e)}
                    />
                  }
                />

                <GridItem
                  noBorderBg
                  label={'Border color:'}
                  item={
                    <ColorPickerRefactor
                      value={activeGeoPoster.anchor.borderColor}
                      onChange={(e) => onChangeVal('anchor.borderColor', e)}
                    />
                  }
                />
                <GridItem
                  label={'Border width:'}
                  item={
                    <Input
                      style={{ padding: '0' }}
                      type={'number'}
                      value={activeGeoPoster.anchor.borderWidth}
                      className={styles.inputWrap}
                      onChange={(e) => onChangeVal('anchor.borderWidth', e.target.value)}
                      onFocus={() => onFocus('anchor.borderWidth')}
                      autoFocus={lastFocused === 'anchor.borderWidth'}
                    />
                  }
                />
              </>
            ) : null}
          </GridWrapper>
          {showResetBtn && (
            <GridActions>
              <Button color="purple" onClick={onResetPosition}>
                Reset position
              </Button>
            </GridActions>
          )}
          <GridWrapper>
            <GridItem
              label="Change anchor:"
              noBorderBg
              colSpan={2}
              item={
                <div className={'relative flex'}>
                  <input
                    ref={searchRef}
                    autoFocus={true}
                    style={{ width: '100%', maxWidth: '100%', background: '#060b12' }}
                    placeholder={'Type here...'}
                    autoComplete="off"
                    id="search-center"
                    type="text"
                    value={cityQuery}
                    onChange={onChange}
                    className={'rounded-md grid-item'}
                    required
                  />
                  <Checkbox
                    checked={!activeGeoPoster.anchor.enable}
                    onChange={(e) => {
                      onChangeVal('anchor.enable', !e.target.checked);
                      setNameOnly(e.target.checked);
                    }}
                  >
                    Use name only
                  </Checkbox>
                  {Boolean(cityQuery) && dropdownOpened && (
                    <div
                      className={'rounded-b-lg fixed z-10 bg-black p-8 ro'}
                      style={{
                        top: (searchRef.current?.getBoundingClientRect().top ?? 0) + 25,
                        left: (searchRef.current?.getBoundingClientRect().left ?? 0) - 8,
                        maxWidth:
                          window.innerWidth -
                          (searchRef.current?.getBoundingClientRect().left ?? 0) -
                          30,
                      }}
                    >
                      {isLoading ? (
                        <div className={'flex w-1/2 loading'}>
                          <div className={'flex items-center mr-3'}>
                            <LoadingIndicator />
                          </div>
                          Searching
                        </div>
                      ) : citiesList && citiesList.length ? (
                        citiesList.map((c) => (
                          <div
                            key={c.id}
                            onClick={() => onCitySelect(c)}
                            className={'hover:bg-white hover:bg-opacity-20 cursor-pointer'}
                          >
                            {c.display_name}
                          </div>
                        ))
                      ) : (
                        <div className={'.getBoundingClientRect()'}>no results</div>
                      )}
                    </div>
                  )}
                </div>
              }
            />
          </GridWrapper>
        </div>
      )}
      <div
        className={`mb-2 subheader layer-header ${labelOpen ? 'layer-header-active' : ''}`}
        onClick={() => setLabelOpen((o) => !o)}
      >
        {labelOpen ? <AiOutlineCaretUp /> : <AiOutlineCaretDown />}
        Label properties
      </div>
      {labelOpen && (
        <div className="prop-wrapper">
          <GridWrapper>
            <GridItem
              label={'Value:'}
              item={
                <Input
                  style={{ padding: '0' }}
                  type={'text'}
                  value={cityTextEl?.value}
                  className={styles.inputWrap}
                  // @ts-ignore
                  onChange={(e) => onChangeVal('textPanels[0].value', e.target.value)}
                  // @ts-ignore
                  onFocus={() => onFocus('textPanels[0].value')}
                  autoFocus={lastFocused === 'textPanels[0].value'}
                />
              }
            />
            <GridItem
              label={'Font size:'}
              item={
                <Input
                  style={{ padding: '0' }}
                  type={'number'}
                  value={cityTextEl?.fontSize}
                  // @ts-ignore
                  onChange={(e) => onChangeVal('textPanels[0].fontSize', e.target.value)}
                  className={styles.inputWrap}
                  // @ts-ignore
                  onFocus={() => onFocus('textPanels[0].fontSize')}
                  autoFocus={lastFocused === 'textPanels[0].fontSize'}
                />
              }
            />

            <GridItem
              label="Font family:"
              item={
                <Select
                  className={styles.select}
                  value={cityTextEl?.fontFamily}
                  onChange={(e) => {
                    // @ts-ignore
                    onChangeFont('textPanels[0].fontFamily', e.target.value, '', '');
                  }}
                  // @ts-ignore
                  onFocus={() => onFocus('textPanels[0].fontFamily')}
                  autoFocus={lastFocused === 'textPanels[0].fontFamily'}
                >
                  {fonts?.map((font: FontInterface) => (
                    <option
                      key={font.id}
                      value={font.name.split('(')[0]}
                      style={{ fontFamily: font.name.split('(')[0] }}
                    >
                      {font.name}
                    </option>
                  ))}
                </Select>
              }
            />

            <GridItem
              label="Font type:"
              item={
                <select
                  className={styles.select}
                  value={cityTextEl?.fontType}
                  // @ts-ignore
                  onChange={(e) => {
                    const findType = fontVariants.find(
                      (fontVariant: FontVariantInterface) => fontVariant.type === e.target.value,
                    );
                    onChangeFont(
                      // @ts-ignore
                      'textPanels[0].fontType',
                      cityTextEl?.fontFamily,
                      e.target.value,
                      findType?.id,
                    );
                  }}
                  // @ts-ignore
                  onFocus={() => onFocus('textPanels[0].fontType')}
                  autoFocus={lastFocused === 'textPanels[0].fontType'}
                >
                  {fontVariants?.map((fontVariant: FontVariantInterface) => (
                    <option key={fontVariant.id} value={fontVariant.type}>
                      {fontVariant.type}
                    </option>
                  ))}
                </select>
              }
            />

            <GridItem
              noBorderBg
              label={'Font color:'}
              item={
                <ColorPickerRefactor
                  value={cityTextEl?.fontColor}
                  // @ts-ignore
                  onChange={(e) => onChangeVal('textPanels[0].fontColor', e)}
                  // @ts-ignore
                  onFocus={() => onFocus('textPanels[0].fontColor')}
                  autoFocus={lastFocused === 'textPanels[0].fontColor'}
                />
              }
            />

            <GridItem
              label="Label align:"
              item={
                <Select
                  className={styles.select}
                  // @ts-ignore
                  onChange={(e) => onChangeVal('textPanels[0].fontAlignment', e.target.value)}
                  value={cityTextEl?.fontAlignment}
                >
                  {['left', 'right', 'center'].map((k) => (
                    <option key={k} value={k}>
                      {k}
                    </option>
                  ))}
                </Select>
              }
            />
            <GridItem
              label={'Stroke width:'}
              item={
                <Input
                  style={{ padding: '0' }}
                  type={'number'}
                  value={cityTextEl?.strokeWidth ?? 0}
                  onChange={(e) =>
                    onChangeVal(
                      // @ts-ignore
                      'textPanels[0].strokeWidth',
                      Number(e.target.value) >= 0 ? e.target.value : 0,
                    )
                  }
                  className={styles.inputWrap}
                  // @ts-ignore
                  onFocus={() => onFocus('textPanels[0].strokeWidth')}
                  autoFocus={lastFocused === 'textPanels[0].strokeWidth'}
                />
              }
            />
            <GridItem
              noBorderBg
              label={'Stroke color:'}
              item={
                <ColorPickerRefactor
                  value={cityTextEl?.strokeColor ?? 'rgba(255, 255, 255, 255)'}
                  // @ts-ignore
                  onChange={(e) => onChangeVal('textPanels[0].strokeColor', e)}
                  // @ts-ignore
                  onFocus={() => onFocus('textPanels[0].strokeColor')}
                  autoFocus={lastFocused === 'textPanels[0].strokeColor'}
                />
              }
            />
            <GridItem
              label="Text transform:"
              item={
                <Select
                  className={styles.select}
                  value={cityTextEl?.textTransform}
                  // @ts-ignore
                  onChange={(e) => onChangeVal('textPanels[0].textTransform', e.target.value)}
                  // @ts-ignore
                  onFocus={() => onFocus('textPanels[0].textTransform')}
                  autoFocus={lastFocused === 'textPanels[0].textTransform'}
                >
                  {transformText.map((item) => (
                    <option key={item.format} value={item.format}>
                      {item.name}
                    </option>
                  ))}
                </Select>
              }
            />
          </GridWrapper>
        </div>
      )}
    </div>
  );
};

export default CityGeoPosterProperties;
