import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { axiosInstance } from '../../../core/api/axiosInstance';
import { queryClient } from '../../../index';
import { allFontsKey, enterpriseKey, fontKey, key } from './fontKeys';

const useDeleteFont = () => {
  return useMutation(
    async ({ id, type }: { id: string; type: string }) => {
      const { data } = await axiosInstance.delete(`${type}/${id}`);
      return data;
    },
    {
      onSuccess: () => {
        toast.success('Font deleted successfully!');
        const keys = [allFontsKey, enterpriseKey, fontKey, key];
        keys.forEach((k) => queryClient.invalidateQueries(k));
      },
      onError: (e: AxiosError) => {
        if (e?.response?.status === 403) {
          toast.error('You are not allowed to delete item');
        } else {
          toast.error('Error deleting item');
        }
      },
    },
  );
};

export default useDeleteFont;
