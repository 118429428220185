import { AxiosError } from 'axios';
import { QueryKey, useQuery, UseQueryResult } from 'react-query';

import { axiosInstance } from '../../../core/api/axiosInstance';
import { C9ProjectPreviewDTO } from '../../../model/DTO/C9ProjectPreviewDTO';
import { QueryInfoMetadata } from '../../../model/other/QueryInfoMetadata';

export const key: QueryKey = ['GET_PURCHASED_TEMPLATES'];

const useGetPurchasedTemplates = ({
  page,
  type,
  size = 20,
  searchKey,
  sortByField,
  sortAscDesc,
}: {
  page: number;
  type: string;
  size?: number;
  searchKey?: string;
  sortByField?: string;
  sortAscDesc?: string;
}): UseQueryResult<QueryInfoMetadata<C9ProjectPreviewDTO>, AxiosError> => {
  const isEnabled =
    Boolean(type) &&
    (type === 'project' ||
      type === 'scene' ||
      type === 'map' ||
      type === 'poster' ||
      type === 'geoposter' ||
      type === 'icon-set');
  return useQuery(
    [...key, type, page, searchKey, sortByField, sortAscDesc],
    async () => {
      const { data } = await axiosInstance.get(
        `dashboard/my-purchased-${
          type === 'poster' ? 'weather-poster' : type === 'geoposter' ? 'weather-geo-poster' : type
        }-templates`,
        {
          params: { page, size, searchKey, sortByField, sortAscDesc },
        },
      );
      return data;
    },
    { enabled: isEnabled },
  );
};

export default useGetPurchasedTemplates;
