import { useMutation } from 'react-query';

import { ObservedWDElementDef } from '../../../../model/definitions/ObservedWDElementDef';
import { axiosInstance } from '../../axiosInstance';

export const useApplyBiasGroupOnObserved = () =>
  useMutation(
    async ({
      biasGroupId,
      body,
    }: {
      biasGroupId: string;
      body: ObservedWDElementDef;
    }): Promise<ObservedWDElementDef> => {
      const { data } = await axiosInstance.put(
        `biasGroup/apply/${biasGroupId}/observedWDElement`,
        body,
      );
      return data;
    },
  );
