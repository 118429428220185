import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { queryClient } from '../../..';
import { axiosInstance } from '../../../core/api/axiosInstance';
import { key } from './useGetEtl';

export const useScraping = () => {
  return useMutation(
    async ({ id, etl_active }: { id: string; etl_active: boolean }) => {
      const { data } = await axiosInstance.post(`data-products/etl-active/${id}`, {
        etl_active,
      });

      return data;
    },
    {
      onSuccess: (success) => {
        toast.success(success.detail);
        queryClient.invalidateQueries(key);
      },
      onError: () => {
        toast.error('There was an error. Please try again.');
      },
    },
  );
};
