import { groupBy } from 'lodash';
import { forwardRef, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { AiOutlineInfoCircle } from 'react-icons/ai';

import Button from '../../../../../atoms/button/Button';
import { WeatherPosterDef } from '../../../../../model/definitions/WeatherPosterDef';
import { ObservedSection } from './ObservedSection';

interface ObservedPageProps {
  //definition: WeatherPosterDef;
  offset: number;
  offsetMode: 'HOURS' | 'DAYS';
  step: 'observed' | 'forecast' | 'add';
  //setOffset: (e: number) => void;
  setStep: (step: 'observed' | 'forecast' | 'add') => void;
  bias: boolean;
  allPosters: Array<WeatherPosterDef>;
}
export const ObservedPage = ({
  //definition,
  offset,
  offsetMode,
  step,
  //setOffset,
  setStep,
  bias,
  allPosters,
}: ObservedPageProps) => {
  const [selected, setSelected] = useState<Date>(new Date(Date.now()));
  useEffect(() => {
    if (allPosters.length === 0 && step === 'observed') setStep('forecast');
  }, [setStep, step]);
  const renderObserved = (definition: WeatherPosterDef) => {
    const grouped = groupBy(definition.observedWDElements, 'observedWDSource.station.value');
    const all = [];
    for (const point in grouped) {
      all.push(
        <ObservedSection
          definition={definition}
          bias={bias}
          setSelected={(e) => setSelected(new Date(e * 1000))}
          referentDate={definition.creationTime}
          step={step}
          offset={offset * (offsetMode === 'HOURS' ? 3600 : 3600 * 24)}
          tempDate={selected.getTime()}
          key={point}
          defaultStation={point}
          stationName={grouped[point][0].observedWDSource.station.fullName}
          observedTemplateData={grouped[point]}
        />,
      );
    }
    return all;
  };
  const ExampleCustomInput = forwardRef(
    ({ value, onClick }: { value: string; onClick: () => void }, ref) => (
      <Button className="example-custom-input" onClick={onClick} label={value} />
    ),
  );
  ExampleCustomInput.displayName = 'ExampleCustomInput';
  return (
    <div>
      <div className={'mb-[7px]'}>
        <div className={'items-center'}>
          <div className={'flex items-center'}>
            <AiOutlineInfoCircle className={'mr-3'} /> Select date and time of the latest element in
            template.
          </div>
          <div className={'text-red-800 font-bold bg-amber-50 px-2'}>
            If there is no available data, values from nearest date and time will be used!
          </div>
        </div>
        <DatePicker
          selected={selected}
          placeholderText={'Select observed moment'}
          dateFormat={'dd.MM.yyyy HH:mm'}
          dateFormatCalendar={'MMMM yyyy'}
          showPopperArrow={false}
          shouldCloseOnSelect={true}
          timeIntervals={60}
          withPortal={true}
          showTimeSelect
          timeFormat={'HH:mm'}
          onChange={(e) => {
            e && setSelected(e);
            //e && setOffset(e.getTime());
          }}
          maxDate={new Date()}
          customInput={
            <ExampleCustomInput
              value={selected.toLocaleDateString()}
              onClick={() => console.log(selected)}
            />
          }
        />
      </div>
      <div>
        {allPosters.length ? (
          allPosters.map((poster) => renderObserved(poster))
        ) : (
          <h3>No posters with observed data. Please proceed...</h3>
        )}
      </div>
    </div>
  );
};
