import React from 'react';
import { useSelector } from 'react-redux';

import { ElementsEnum } from '../../../../core/ui/enums/ElementsEnum';
import { usePropertyGridActive } from '../../../../hooks/usePropertyGridActive';
import { TimeControlDef } from '../../../../model/definitions/TimeControlDef';
import { ActiveDef } from '../../../../store/slices/active-slice';
import { RootState } from '../../../../store/store';
import { PropertySection } from '../components/PropertySection';
import TimeControls from './panelElements/TimeControls';

interface TimeControlsProps {
  timeControls: Array<TimeControlDef>;
  layer: ElementsEnum;
  mapId?: string;
  drawMapProps?: { mapId: string; drawElementId: string }; // if is drawingElement of mapPanel
  showAnimationPicker?: boolean;
  layerType?: 'observedDataLayers' | 'forecastDataLayers' | 'drawingElements';
  isComposite?: boolean;
}
export const TimeControlsPanel = ({
  timeControls,
  layer,
  mapId,
  drawMapProps,
  showAnimationPicker = true,
  layerType,
  isComposite = false,
}: TimeControlsProps) => {
  const active = useSelector<RootState, ActiveDef>((state) => state.active);
  const deps = timeControls
    .map((_, i) => [
      `[${i}].startMS`,
      `[${i}].endMS`,
      `[${i}].inAnimationDef`,
      `[${i}].outAnimationDef`,
      `[${i}].seekToTimeMS`,
    ])
    .flat(10);
  const { isOpened } = usePropertyGridActive(deps);
  return (
    <PropertySection label={'Time'} isOpened={isOpened}>
      <div className="prop-wrapper">
        {timeControls?.length
          ? timeControls.map((tc: TimeControlDef, i: number) => (
              <React.Fragment key={i}>
                <TimeControls
                  mapId={mapId}
                  timeControl={tc}
                  active={active}
                  index={i}
                  type={layer}
                  drawMapProps={drawMapProps}
                  elementTimes={timeControls}
                  showAnimationPicker={showAnimationPicker}
                  layerType={layerType}
                  isComposite={isComposite}
                />
              </React.Fragment>
            ))
          : null}
      </div>
    </PropertySection>
  );
};
