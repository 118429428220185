export enum RolesEnum {
  ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN',
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_PLATFORM_ADMIN = 'ROLE_PLATFORM_ADMIN',
  ROLE_DATA_PROVIDER = 'ROLE_DATA_PROVIDER',
  ROLE_ACCOUNT_OWNER = 'ROLE_ACCOUNT_OWNER',
  ROLE_ENTERPRISE_USER = 'ROLE_ENTERPRISE_USER',
  ROLE_INTERNAL_ENTERPRISE_PUBLISHER = 'ROLE_INTERNAL_ENTERPRISE_PUBLISHER',
  ROLE_EXTERNAL_PUBLISHER = 'ROLE_EXTERNAL_PUBLISHER',
  ROLE_CREATOR = 'ROLE_CREATOR',
  ROLE_MARKETPLACE_ASSET_PURCHASER = 'ROLE_MARKETPACE_ASSET_PURCHASER',
  ROLE_SCHEDULER = 'ROLE_SCHEDULER',
  ROLE_PRODUCER = 'ROLE_PRODUCER',
  ROLE_PRESENTER = 'ROLE_PRESENTER',
  ROLE_CONTENT_RENDERER = 'ROLE_CONTENT_RENDERER',
  ROLE_FORECASTER = 'ROLE_FORECASTER',
  ROLE_MAINTAINER = 'ROLE_MAINTAINER',
  ROLE_DATA_MANAGER = 'ROLE_DATA_MANAGER',
  ROLE_MARKETPLACE_ADMIN = 'ROLE_MARKETPLACE_ADMIN',
  ROLE_USER = 'ROLE_USER',
}
