// @ts-nocheck
/* eslint-disable */
import ReactApexChart from 'react-apexcharts';

import { ProfilerStatsProject } from './VRProfilerTypes';
import { useEffect, useState } from 'react';
const options = {
  chart: {
    type: 'rangeBar',
    animations: {
      enabled: false,
    },
  },
  plotOptions: {
    bar: {
      horizontal: true,
      barHeight: '80%',
    },
  },
  xaxis: {
    type: 'datetime',
  },
  yaxis: {
    labels: {
      style: {
        colors: ['#FFF'],
      },
    },
  },
  stroke: {
    width: 1,
  },
  fill: {
    type: 'solid',
    opacity: 0.6,
  },
  legend: {
    position: 'top',
    horizontalAlign: 'left',
    labels: {
      colors: ['#FFF'],
    },
    width: 1000,
  },
  noData: {
    text: 'Loading...',
    align: 'center',
    verticalAlign: 'middle',
    offsetX: 0,
    offsetY: 0,
    style: {
      color: '#ffffff',
      fontSize: '40px',
      fontFamily: 'Helvetica',
    },
  },
  tooltip: {
    custom: function ({ series, seriesIndex, dataPointIndex, w }) {
      var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
      return (
        '<ul style="padding: 10px;">' +
        '<li><b>Name</b>: ' +
        data.name +
        '</li>' +
        '<li><b>Duration</b>: ' +
        (data.durationMS * 0.001).toFixed(3) +
        's' +
        '</li>' +
        '<li><b>Type</b>: ' +
        data.type +
        '</li>' +
        '</ul>'
      );
    },
  },
};

export const RenderStatsTimeline = ({ data }: { data: ProfilerStatsProject }) => {
  const [dataSeries, setDataSeries] = useState([]);
  useEffect(() => {
    if (data) {
      const dataSeries = [];
      dataSeries.push({
        name: 'VR Duration',
        data: [
          {
            x: 'VR Duration',
            y: [data.startMS, data.endMS],
            name: data.name,
            durationMS: data.durationMS,
            type: data.type,
          },
        ],
      });

      data.scenesAssemblyStat.forEach((item) => {
        dataSeries.push({
          name: 'Scenes assembly',
          data: item.elements.map((e) => {
            return {
              x: 'Scene assembly',
              y: [e.startMS, e.endMS],
              name: e.name,
              durationMS: e.durationMS,
              type: e.type,
            };
          }),
        });
      });

      data.scenesResourceLoadingStat.forEach((item) => {
        dataSeries.push({
          name: 'Resource loading',
          data: [
            {
              x: 'Resource loading',
              y: [item.startMS, item.endMS],
              name: item.name,
              durationMS: item.durationMS,
              type: item.type,
            },
          ],
        });
      });

      data.scenesDrawElementStat.forEach((item) => {
        dataSeries.push({
          name: 'Draw elements',
          data: item.elements.map((e) => {
            return {
              x: 'Draw elements',
              y: [e.startMS, e.endMS],
              name: e.name,
              durationMS: e.durationMS,
              type: e.type,
            };
          }),
        });
      });

      data.scenesCaptureStat.forEach((item) => {
        dataSeries.push({
          name: 'Video capture',
          data: item.elements.map((e) => {
            return {
              x: 'Video capture',
              y: [e.startMS, e.endMS],
              name: e.name,
              durationMS: e.durationMS,
              type: e.type,
            };
          }),
        });
      });
      setDataSeries(dataSeries);
    }
  }, [data]);

  return <ReactApexChart options={options} series={dataSeries} type="rangeBar" height={'90%'} />;
};
