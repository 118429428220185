const getAccept = (
  fileType: 'image' | 'video' | 'audio' | 'custom_map' | 'animation' | 'symbol' | 'custom_vector',
) => {
  switch (fileType) {
    case 'image':
    case 'symbol':
      return { 'image/jpg': ['.jpg'], 'image/png': ['.png'], 'image/jpeg': ['.jpeg'] };
    case 'video':
      return {
        'video/*': [],
      };
    case 'audio':
      return { 'audio/flac': ['.flac'], 'audio/mp3': ['.mp3'] };
    case 'custom_map':
      return {
        'image/tiff': ['.tif'],
        'image/jpg': ['.jpg'],
        'image/png': ['.png'],
        'image/jpeg': ['.jpeg'],
      };
    case 'animation':
      return { 'application/zip': ['.zip'], 'application/json': ['.json'] };
    case 'custom_vector':
      return { 'application/zip': ['.zip'] };
  }
};

export { getAccept };
