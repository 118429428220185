import { DrawingDef } from './DrawingDef';

export interface CopyDrawingInput {
  layer: DrawingDef;
  projection: string;
  projectionString: string;
  mapId: string;
  layerId: string;
  drawingIdentify: boolean;
}

export function isCopyDrawingInput(input: any): input is CopyDrawingInput {
  return input?.layer && input?.drawingIdentify && input?.mapId && input?.layerId;
}
