import { useMutation } from 'react-query';

import { axiosInstance } from '../../../core/api/axiosInstance';
import { queryClient } from '../../../index';
import { key } from './useGetSqsConfigs';

const useAddSqsConfig = () => {
  return useMutation(
    async (sqs: { accessKey: string; secretKey: string; region: string; queqeName: string }) => {
      const { data } = await axiosInstance.post(`sqs-configs`, sqs);
      return data;
    },
    {
      onSuccess: () => queryClient.invalidateQueries(key),
    },
  );
};

export default useAddSqsConfig;
