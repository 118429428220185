import Fraction from 'fraction.js';

const roundToDecimals = (number: number, n = 0) => {
  const d = Math.pow(10, n);
  return Math.round((number + Number.EPSILON) * d) / d;
};
const pointDataValue = (value: number, property: string, unit?: string) => {
  switch (true) {
    case property === 'rainPrecipitation':
      return roundToDecimals(value, 2);
    case property === 'Pressure' && unit === 'MercuryInch':
      return Number(value)?.toFixed(2);
    case property === 'Pressure':
      return Number(value)?.toFixed(0);
    case property === 'PrevailingVisibility' && unit === 'Meter' && value > 9998:
      return 'unlimited ';
    case property === 'PrevailingVisibility' && unit === 'StatuteMile' && value > 6.1:
      return 'unlimited ';
    case property === 'PrevailingVisibility' && unit === 'Feet' && value > 32800:
      return 'unlimited ';
    case 'PrevailingVisibility' && unit === 'StatuteMile': {
      const fr = new Fraction(Math.round(10 * new Fraction(value).valueOf()), 10);
      return fr.toFraction(true);
    }
    default:
      return Math.round(value);
  }
};
export { pointDataValue, roundToDecimals };
