import './style.scss';

import { ConfigProvider } from 'antd';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import Theme from '../marketplace-new/antd/Theme';
import WorkspaceHeader from './header-NEW/WorkspaceHeader';
import Layout from './layout-NEW/Layout';
import DashboardSidebar from './sidebar-NEW/DashboardSidebar';
import { SortProvider } from './templates-NEW/sortContext';

const DashboardRefactor = () => {
  useEffect(() => {
    // Show Zendesk Web Widget
    if (process.env.NODE_ENV !== 'development') {
      // @ts-ignore
      if (window.zE) {
        // @ts-ignore
        window.zE('webWidget', 'show');
      } else {
        const script = document.createElement('script');
        script.id = 'ze-snippet';
        script.src =
          'https://static.zdassets.com/ekr/snippet.js?key=03778b1d-fa3e-4d50-9061-86bad414fdcc';
        script.onload = () => {
          //@ts-ignore
          window.zE('webWidget', 'show');
        };
        document.head.appendChild(script);
      }
    }
  }, []);

  return (
    <ConfigProvider theme={Theme}>
      <SortProvider>
        <div id={'Dashboard'}>
          <ToastContainer />
          <DashboardSidebar />
          <div className={'w-full h-full'}>
            <WorkspaceHeader />
            <Layout>
              <Outlet />
            </Layout>
          </div>
        </div>
      </SortProvider>
    </ConfigProvider>
  );
};
export default DashboardRefactor;
