interface Props {
  tabs: any;
  filterTab: string;
  setFilterTab: (tab: string) => void;
}
const FilterTabs = ({ tabs, filterTab, setFilterTab }: Props) => {
  const renderTabs = () =>
    Object.keys(tabs).map((tab: any, index: number) => (
      <div
        className={`tab ${filterTab !== tab ? '' : 'tab-active'}`}
        onClick={() => setFilterTab(tab)}
        key={index}
      >
        {tab !== 'GEOPOSTER' ? tab : 'COMPOSITE'}
      </div>
    ));
  return <div className="filter-tabs">{renderTabs()}</div>;
};

export default FilterTabs;
