import { Input, notification, Typography } from 'antd';
import { Button, ToggleSwitch } from 'flowbite-react';
import { chunk, cloneDeep } from 'lodash';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { v4 } from 'uuid';

import { ElementsEnum } from '../../../core/ui/enums/ElementsEnum';
import { updateProportional } from '../../../helpers/resizableHelpers';
import { CURRENT_TIME_DATE_FORMAT } from '../../../model/constants/constants';
import { DrawingDef, DrawingKeyframeDef } from '../../../model/definitions/DrawingDef';
import { MapPanelDef } from '../../../model/definitions/MapPanelDef';
import { DrawingTypeExternalEnum } from '../../../model/enums/DrawingTypeEnum';
import { GLOBAL_FRONT_PROPERTIES } from '../../../molecules/mapElement/drawHeplers';
import { findClosestPoints } from '../../../molecules/mapElement/helpers';
import {
  animateCoordinates,
  calculateDrawingKeyframeRefactor,
  calculateTimeFromTimestamp,
} from '../../../molecules/mapElement/useDrawingTools';
import { ActiveDef, DrawType, setActiveDraw } from '../../../store/slices/active-slice';
import { updateDrawingElementGeneral } from '../../../store/slices/project-slice';
import { selectActiveMapLayerByActiveMap } from '../../../store/slices/selectors';
import { RootState } from '../../../store/store';
import { GlobalPlayerControl } from '../GlobalPlayerControl';
import PlayerContext from '../playerContext/PlayerContext';
import { Panel } from './components/Panel';
import { PropertySection } from './components/PropertySection';
import { FeaturesSetup } from './panels/drawings/FeaturesSetup';
import { FrontSetup } from './panels/drawings/FrontSetup';
import { ImageSetup } from './panels/drawings/ImageSetup';
import { TimeControlsPanel } from './panels/TimeControlsPanel';
import GridItem from './shared/GridItem';
import GridWrapper from './shared/GridWrapper';

const { Text } = Typography;
export const DrawingProperties = () => {
  const dispatch = useDispatch();
  const [copied, setCopied] = useState(false);
  const mapLayer = useSelector<RootState, MapPanelDef | null | undefined>((state) =>
    selectActiveMapLayerByActiveMap(state),
  );
  const playerCtx = useContext(PlayerContext);

  const { activeElement, activeMap, activeScene } = useSelector<RootState>(
    (state) => state.active,
  ) as ActiveDef;
  const drawings = mapLayer?.drawingElements;
  const layer = drawings?.find(
    (draw) => JSON.parse(draw.drawingGeoJson).features[0].properties.featureId === activeElement,
  );

  useEffect(() => {
    async function handleClipboardData() {
      try {
        const data = await navigator.clipboard.readText();
        if (data) {
          const parsedData = JSON.parse(data);
          if (parsedData?.layerId && parsedData.layerId === layer?.id) {
            setCopied(true);
          }
        }
      } catch (e) {
        /**NOOP */
      }
    }
    handleClipboardData();
  }, []);

  const { firstFrameIndex, currentGeojson } =
    layer && mapLayer
      ? calculateDrawingKeyframeRefactor(
          layer,
          mapLayer.wdSpace[0].indicator,
          mapLayer.wdSpace[0].timeframeIndicatorStep,
        )
      : { firstFrameIndex: 0, currentGeojson: '' };

  const properties = currentGeojson ? JSON.parse(currentGeojson) : {};
  const features = properties.features && properties.features[0]?.properties;

  const shouldShowCopy =
    features &&
    features.drawingType !== DrawingTypeExternalEnum.POLYGON &&
    features.drawingType !== DrawingTypeExternalEnum.RECTANGLE &&
    features.drawingType !== DrawingTypeExternalEnum.CIRCLE &&
    features.drawingType !== DrawingTypeExternalEnum.CLOSED_CURVED_LINE;

  useHotkeys(
    'ctrl+c, command+c',
    (ev) => {
      ev.preventDefault();
      copyDraw();
    },
    { enabled: shouldShowCopy },
  );

  const copyDraw = async () => {
    if (!shouldShowCopy) return;
    try {
      const projection = mapLayer?.baseMapSetup.projection.name;
      const projectionString = mapLayer?.baseMapSetup.baseMapConfigurationProj4;
      const layerCopy = cloneDeep(layer);
      layerCopy!.keyframes = [];
      const currentTime = GlobalPlayerControl.getTime();
      const { currentGeojson, nextGeojson, firstFrameTime, nextFrameTime } =
        calculateDrawingKeyframeRefactor(
          layer!,
          mapLayer!.wdSpace[0].indicator,
          mapLayer!.wdSpace[0].timeframeIndicatorStep,
        );

      const currentJson = JSON.parse(currentGeojson);
      const nextJson = JSON.parse(nextGeojson);

      const currentJsonProps = currentJson?.features?.[0]?.properties;
      if (!currentJsonProps) return;

      currentJson.features[0].properties.featureId = v4();
      const drawingType = currentJsonProps.drawingType;

      if (
        drawingType === DrawingTypeExternalEnum.LINE_STRING ||
        drawingType === DrawingTypeExternalEnum.FRONTS ||
        drawingType === DrawingTypeExternalEnum.ARROW
      ) {
        if (!currentJson.features[0].properties.arrayOfTurningPoints) return;

        /**Animating all coordinates and respective turning points will cover both cases, morphed shape and unmorphed */
        let nextCoordsArrOfTurningPoints = nextJson.features[0].properties.arrayOfTurningPoints;
        nextCoordsArrOfTurningPoints = findClosestPoints(
          nextCoordsArrOfTurningPoints,
          nextJson.features[0].geometry.coordinates,
        ).flat();
        const prevCoords = currentJson.features[0].geometry.coordinates.flat();
        const nextCoords = nextJson.features[0].geometry.coordinates.flat();

        const calculatedArrOfTurningPoints: number[] = [];
        const calculatedCoordinates: number[] = [];
        nextCoords.forEach((c: number, i: number) => {
          const animatedCoordinate = animateCoordinates(
            prevCoords[i],
            c,
            firstFrameTime,
            nextFrameTime,
            currentTime,
          );
          calculatedCoordinates.push(animatedCoordinate);
          if (nextCoordsArrOfTurningPoints.includes(c)) {
            calculatedArrOfTurningPoints.push(animatedCoordinate);
          }
        });
        const coordsToSet = chunk(calculatedCoordinates, 2);
        const arrayOfTurningPointsToSet = chunk(calculatedArrOfTurningPoints, 2);

        currentJson.features[0].geometry.coordinates = coordsToSet;
        currentJson.features[0].properties.arrayOfTurningPoints = arrayOfTurningPointsToSet;
        currentJson.features[0].properties.featureId = v4();
        layerCopy!.drawingGeoJson = JSON.stringify(currentJson);
      }

      if (drawingType === DrawingTypeExternalEnum.IMAGE) {
        const animatedProps = {
          imageHeight: animateCoordinates(
            currentJson.features[0].properties.imageHeight,
            nextJson.features[0].properties.imageHeight,
            firstFrameTime,
            nextFrameTime,
            currentTime,
          ),
          imageWidth: animateCoordinates(
            currentJson.features[0].properties.imageWidth,
            nextJson.features[0].properties.imageWidth,
            firstFrameTime,
            nextFrameTime,
            currentTime,
          ),
        };
        currentJson.features[0].properties = {
          ...currentJson.features[0].properties,
          ...animatedProps,
          featureId: v4(),
        };

        layerCopy!.drawingGeoJson = JSON.stringify(currentJson);
      }

      const dataToClipboard = {
        layer: layerCopy,
        projection,
        projectionString,
        mapId: activeMap,
        layerId: layerCopy?.id,
        drawingIdentify: true,
      };
      await navigator.clipboard.writeText(JSON.stringify(dataToClipboard));
      setCopied(true);
      toast.success('Draw element copied to clipboard, click point on map and CTRL+V to paste it');
    } catch (error) {
      toast.error('There was an error while trying to copy draw element');
    }
  };

  const onDeleteKeyframe = (layer: DrawingDef, index: number) => {
    if (layer.keyframes.length > 0) {
      const newLayer = { ...layer };
      newLayer.keyframes = layer.keyframes.filter((x, i) => i != index);
      dispatch(
        updateDrawingElementGeneral({
          activeScene,
          activeMap,
          layerId: layer.id,
          layer: newLayer,
        }),
      );
    }
  };

  const onChangeGeneral = (value: string | boolean, property: keyof DrawingDef) => {
    const newLayer = cloneDeep(layer);
    //@ts-ignore
    newLayer[property] = value;
    layer &&
      newLayer &&
      dispatch(
        updateDrawingElementGeneral({
          activeScene,
          activeMap,
          layerId: layer.id,
          layer: newLayer,
        }),
      );
  };
  const onChangeProperties = (e: string | boolean | number, prop: string, name?: string) => {
    const newLayer = cloneDeep(layer);

    const isGlobalPropChanged = GLOBAL_FRONT_PROPERTIES.includes(prop);

    if (newLayer) {
      if (properties.features[0].properties.lockAspectRatio) {
        if (prop === 'imageWidth') {
          const recalculate = updateProportional(
            properties.features[0].properties.imageWidth,
            properties.features[0].properties.imageHeight,
          );
          properties.features[0].properties.imageHeight = recalculate(Number(e));
        } else if (prop === 'imageHeight') {
          const recalculate = updateProportional(
            properties.features[0].properties.imageHeight,
            properties.features[0].properties.imageWidth,
          );
          properties.features[0].properties.imageWidth = recalculate(Number(e));
        }
      }
      newLayer.drawingGeoJson = JSON.stringify({
        ...properties,
        features: [
          {
            ...properties.features[0],
            properties: { ...properties.features[0].properties, [prop]: e },
          },
        ],
      });
      if (name) {
        newLayer.name = 'Image - ' + name;
      }
      if (newLayer.keyframes?.length) {
        newLayer.keyframes[firstFrameIndex].geoJson = JSON.stringify({
          ...properties,
          features: [
            {
              ...properties.features[0],
              properties: { ...properties.features[0].properties, [prop]: e },
            },
          ],
        });
        if (isGlobalPropChanged) {
          const editFrontType = properties?.features?.[0]?.properties?.frontType;
          /**If front color or global prop is changed change all keyframes */
          newLayer.keyframes.forEach((fr, idx) => {
            if (idx !== firstFrameIndex) {
              try {
                const frProps = JSON.parse(fr.geoJson);
                fr.geoJson = JSON.stringify({
                  ...frProps,
                  features: [
                    {
                      ...frProps.features[0],
                      properties: { ...frProps.features[0].properties, [prop]: e },
                    },
                  ],
                });
              } catch (e) {
                console.error('Error parsing fr geoJson: ', e);
              }
            }
          });
          /**Propagate changes to all fronts of same type  */
          let sameTypeFronts = mapLayer?.drawingElements?.filter((draw) => {
            const gJson = JSON.parse(draw.drawingGeoJson);
            const frontType = gJson?.features?.[0]?.properties?.frontType;

            return (
              frontType && editFrontType && frontType === editFrontType && draw.id !== layer?.id
            );
          });
          sameTypeFronts = cloneDeep(sameTypeFronts);
          sameTypeFronts?.forEach((draw) => {
            const gJson = JSON.parse(draw.drawingGeoJson);
            const frontType = gJson?.features?.[0]?.properties?.frontType;
            if (
              frontType &&
              editFrontType &&
              frontType === editFrontType &&
              draw.id !== layer?.id
            ) {
              draw.drawingGeoJson = JSON.stringify({
                ...gJson,
                features: [
                  {
                    ...gJson.features[0],
                    properties: { ...gJson.features[0].properties, [prop]: e },
                  },
                ],
              });
              draw.keyframes.forEach((fr) => {
                const frProps = JSON.parse(fr.geoJson);
                fr.geoJson = JSON.stringify({
                  ...frProps,
                  features: [
                    {
                      ...frProps.features[0],
                      properties: { ...frProps.features[0].properties, [prop]: e },
                    },
                  ],
                });
              });
            }
          });

          sameTypeFronts?.forEach((draw) => {
            dispatch(
              updateDrawingElementGeneral({
                activeScene,
                activeMap,
                layerId: draw.id,
                layer: draw,
              }),
            );
          });
        }
      }
    }
    const feat = properties.features[0].properties;
    properties.features[0].properties = { ...feat, [prop]: e };
    layer &&
      newLayer &&
      dispatch(
        updateDrawingElementGeneral({
          activeScene,
          activeMap,
          layerId: layer.id,
          layer: newLayer,
        }),
      );
    dispatch(setActiveDraw({ newValue: e, path: prop as Paths<DrawType> }));
  };

  const jumpToKeyframe = (keyFrame: DrawingKeyframeDef) => {
    const hasIndicator = Boolean(mapLayer?.wdSpace[0]?.indicator?.length);
    const time = hasIndicator
      ? calculateTimeFromTimestamp(mapLayer?.wdSpace[0].indicator, keyFrame.timestamp)
      : keyFrame.startTime;

    if (time !== undefined) {
      playerCtx.setTime(time);
    } else {
      notification.warning({ message: 'Keyframe is out of frames' });
    }
  };

  return (
    <Panel>
      <PropertySection isOpened={true} label={'General'}>
        {features && (
          <GridWrapper className={'py-5 pr-5'}>
            <GridItem
              label={`Name:`}
              item={
                <Input
                  value={layer?.name}
                  onChange={(e) => onChangeGeneral(e.target.value, 'name')}
                />
              }
            />
            <GridItem
              label={`Description:`}
              item={
                <Input
                  value={layer?.description}
                  onChange={(e) => onChangeGeneral(e.target.value, 'description')}
                />
              }
            />
            <GridItem
              label={`Enabled:`}
              noBorderBg
              item={
                <ToggleSwitch
                  label={''}
                  checked={!!layer?.enabled}
                  onChange={(e) => onChangeGeneral(e, 'enabled')}
                />
              }
            />
            {shouldShowCopy && (
              <GridItem
                label={''}
                noBorderBg
                item={
                  <div>
                    {copied
                      ? 'CLICK on map and (CTRL/COMMAND)+V to PASTE'
                      : 'Press (CTRL/COMMAND)+C to COPY'}
                  </div>
                }
              />
            )}
          </GridWrapper>
        )}
      </PropertySection>
      <PropertySection isOpened={false} label={'Setup'}>
        {features && features?.drawingType !== 'Front' && features?.drawingType !== 'Image' && (
          <FeaturesSetup
            key={features.drawingType}
            feature={features}
            onChange={onChangeProperties}
            drawingType={features.drawingType}
          />
        )}
        {features && features?.drawingType === 'Front' && (
          <FrontSetup feature={features} onChange={onChangeProperties} />
        )}
        {features && features?.drawingType === 'Image' && (
          <ImageSetup feature={features} onChange={onChangeProperties} />
        )}
      </PropertySection>
      {layer && (
        <TimeControlsPanel
          layer={ElementsEnum.DRAWING}
          timeControls={layer.timeControls}
          layerType={'drawingElements'}
          mapId={activeMap}
        />
      )}
      <PropertySection isOpened={false} label="Keyframes">
        {layer &&
          layer.keyframes &&
          layer.keyframes.map((k, i) => (
            <div key={k.startTime}>
              <div className="px-8 pt-2 text-white">Keyframe #{i + 1}</div>
              <GridWrapper>
                {k.timestamp ? (
                  <GridItem
                    label={`Start date:`}
                    item={
                      <Input
                        readOnly={true}
                        value={moment(k.timestamp).format(CURRENT_TIME_DATE_FORMAT)}
                      />
                    }
                  />
                ) : (
                  <GridItem
                    label={`Start time:`}
                    item={<Input readOnly={true} value={(k.startTime / 1000).toFixed(2) + 's'} />}
                  />
                )}

                <div className="flex gap-x-2 items-center min-w-max">
                  <Button
                    color="warning"
                    onClick={() => {
                      onDeleteKeyframe(layer, i);
                    }}
                  >
                    Delete
                  </Button>
                  <Button onClick={() => jumpToKeyframe(k)} color="success">
                    Go To
                  </Button>
                </div>
              </GridWrapper>
            </div>
          ))}
      </PropertySection>
      <div>
        To remove control point in drawing use <Text code>Shift + click</Text>
      </div>
    </Panel>
  );
};
