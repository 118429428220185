import './style.scss';

import { format } from 'date-fns';
import { ResizeDirection } from 're-resizable';
import { useEffect, useState } from 'react';
import { MdOutlineDragHandle } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useResizeDetector } from 'react-resize-detector';
import { Position, ResizableDelta, Rnd } from 'react-rnd';

import { ElementsEnum } from '../../../core/ui/enums/ElementsEnum';
import { ModeEnum } from '../../../core/ui/enums/ModeEnum';
import { absoluteToPercent, getNumber, getPercentFromData } from '../../../helpers/timelineUtil';
import { DataFrameDef } from '../../../model/definitions/DataFrameDef';
import { TimeControlDef } from '../../../model/definitions/TimeControlDef';
import { WeatherDataSpaceDef } from '../../../model/definitions/WeatherDataSpaceDef';
import { ActiveDef, setElement } from '../../../store/slices/active-slice';
import { updateMapGRSLayer, updateSpaceTime } from '../../../store/slices/project-slice';
import { RootState } from '../../../store/store';
import { spaceSpan } from '../helpers';

interface WDSpaceLaneProps {
  time: TimeControlDef;
  frames?: Array<DataFrameDef>;
  type: ElementsEnum;
  elementId: string;
  duration: number;
  parentTime: TimeControlDef;
  enabled?: boolean;
  parentId: string;
  space: WeatherDataSpaceDef;
  sceneStart?: number | null;
  sceneEnd?: number | null;
}
const WDSpaceLane = ({
  time = new TimeControlDef(),
  elementId,
  type,
  duration,
  parentTime,
  parentId,
  space,
  sceneStart,
  sceneEnd,
  ...props
}: WDSpaceLaneProps) => {
  const {
    activeScene,
    sceneTranslation,
    mode,
    activeZoom,
    translation,
    syncSpace,
    // activeFramerate,
  } = useSelector<RootState>((state) => state.active) as ActiveDef;

  const isSynced = syncSpace.includes(elementId);
  const [timeCtrl, setTimeCtrl] = useState<TimeControlDef>(time);
  const { width, ref } = useResizeDetector();
  const dispatch = useDispatch();
  // const [span, setSpanRange] = useState<{ max: number; min: number }>({ min: 0, max: 0 });
  const {
    enableTimeframeIndicator,
    timeControls,
    timeframeIndicatorStep,
    mapTimeframeTextIndicator,
    timeframeIndicatorFormat,
  } = space;

  const openSpace = (mapId: string) => {
    dispatch(
      setElement({
        activeElement: mapId,
        activeProp: 'mapPanels',
        spaceId: space.id,
      }),
    );
  };
  /*   const noOfSteps =
    (((sceneEnd ? sceneEnd / 1000 : span?.max) ?? 0) -
      ((sceneStart ? sceneStart / 1000 : span?.min) ?? 0)) /
    enumToS(space.timeframeIndicatorStep as TimeStepEnum); */
  /*  const syncIndicator = useMemo(() => {
    return splitRange(
      space.timeControls[0].startMS,
      space.timeControls[0].endMS,
      sceneStart ? sceneStart / 1000 : span?.min,
      sceneEnd ? sceneEnd / 1000 : span?.max,
      noOfSteps,
      timeframeIndicatorFormat,
      CURRENT_TIME_DATE_FORMAT,
      space.mapTimeframeTextIndicator,
      space.roundTimeframeIndicator,
      space.timeframeIndicatorStep,
      activeFramerate,
      space,
      sceneStart,
      sceneEnd,
    );
  }, [
    activeFramerate,
    noOfSteps,
    space.satelliteMapLayers,
    space.eventLayers,
    space.gribMapLayers,
    space.radarMapLayers,
    space.symbolLayers,
    space.forecastDataLayers,
    span?.max,
    span?.min,
    timeframeIndicatorFormat,
    sceneStart,
    sceneEnd,
  ]);
  const spanOfSpace = useMemo(() => spaceSpan(space), [space]); */
  useEffect(() => {
    /*  if (isSynced) {
      if (sceneStart && sceneEnd) {
        setSpanRange({ min: sceneStart / 1000, max: sceneEnd / 1000 });
      } else {
        setSpanRange(spanOfSpace);
      }
      dispatch(
        updateIndicatorTime({
          activeScene,
          mapId: parentId,
          timeControls,
        }),
      );

      dispatch(
        createIndicator({
          activeScene,
          mapId: parentId,
          indicator: syncIndicator,
        }),
      );
    } else {
      const indicator = createUnSynchronizedIndicator(
        space,
        timeframeIndicatorFormat,
        space.mapTimeframeTextIndicator,
        activeFramerate,
        sceneStart,
        sceneEnd,
      );
      dispatch(
        updateIndicatorTime({
          activeScene,
          mapId: parentId,
          timeControls,
        }),
      );

      dispatch(
        createIndicator({
          activeScene,
          mapId: parentId,
          indicator: indicator.sort(
            (el1, el2) => dayjs(el1.dateValue).unix() - dayjs(el2.dateValue).unix(),
          ),
        }),
      );
    } */
  }, [
    isSynced,
    translation,
    enableTimeframeIndicator,
    timeControls,
    space.gribMapLayers,
    space.radarMapLayers,
    space.satelliteMapLayers,
    space.observedDataLayers,
    space.forecastDataLayers,
    space.roundTimeframeIndicator,
    timeframeIndicatorStep,
    mapTimeframeTextIndicator,
    timeframeIndicatorFormat,
    sceneTranslation[activeScene],
  ]);
  useEffect(() => {
    const start = time.startMS < parentTime.startMS ? parentTime.startMS : time.startMS;
    const end = time.endMS > parentTime.endMS ? parentTime.endMS : time.endMS;
    mode === ModeEnum.SEQUENCE &&
      setTimeCtrl(absoluteToPercent([{ ...time, startMS: start, endMS: end }], duration)[0]);
    if (time.startMS < parentTime.startMS || time.endMS > parentTime.endMS) {
      dispatch(
        updateSpaceTime({
          time: {
            ...time,
            startMS: start,
            endMS: end,
          },
          activeScene,
          mapId: parentId,
          spaceId: elementId,
        }),
      );
    }
  }, [time, activeZoom, width, mode, duration, parentTime, open]);

  const getPercentFromRef = (position: Position, ref: HTMLElement): number => {
    return (position.x * 100) / ref.offsetParent!.clientWidth;
  };
  const getPercentFromDelta = (data: ResizableDelta, ref: HTMLElement) => {
    return (data.width * 100) / ref.offsetParent!.clientWidth;
  };
  const percentToTime = (percent: number) => {
    return Math.round((duration * percent) / 100);
  };
  const dragEnded = (percent: number) => {
    let payload: TimeControlDef;
    if (absoluteToPercent([parentTime], duration)[0].startMS > percent) {
      setTimeCtrl({
        ...timeCtrl,
        startMS: absoluteToPercent([parentTime], duration)[0].startMS,
        endMS:
          absoluteToPercent([parentTime], duration)[0].startMS +
          (timeCtrl.endMS - timeCtrl.startMS),
      });
      payload = {
        ...timeCtrl,
        startMS: percentToTime(absoluteToPercent([parentTime], duration)[0].startMS),
        endMS: percentToTime(
          absoluteToPercent([parentTime], duration)[0].startMS +
            (timeCtrl.endMS - timeCtrl.startMS),
        ),
      };
    } else if (
      absoluteToPercent([parentTime], duration)[0].endMS <
      percent + (timeCtrl.endMS - timeCtrl.startMS)
    ) {
      setTimeCtrl({
        ...timeCtrl,
        startMS:
          absoluteToPercent([parentTime], duration)[0].endMS - (timeCtrl.endMS - timeCtrl.startMS),
        endMS: absoluteToPercent([parentTime], duration)[0].endMS,
      });
      payload = {
        ...timeCtrl,
        startMS: percentToTime(
          absoluteToPercent([parentTime], duration)[0].endMS - (timeCtrl.endMS - timeCtrl.startMS),
        ),
        endMS: percentToTime(absoluteToPercent([parentTime], duration)[0].endMS),
      };
    } else {
      setTimeCtrl({
        ...timeCtrl,
        startMS: percent,
        endMS: percent + (timeCtrl.endMS - timeCtrl.startMS),
      });
      payload = {
        ...timeCtrl,
        startMS: percentToTime(percent),
        endMS: percentToTime(percent + (timeCtrl.endMS - timeCtrl.startMS)),
      };
    }
    const diff = time.startMS - percentToTime(percent);
    dispatch(
      updateSpaceTime({
        time: payload,
        activeScene,
        mapId: parentId,
        spaceId: elementId,
      }),
    );
    space.gribMapLayers.forEach((grib, index) =>
      dispatch(
        updateMapGRSLayer({
          time: {
            ...grib.timeControls[0],
            startMS: grib.timeControls[0].startMS - diff,
            endMS: grib.timeControls[0].endMS - diff,
          },
          activeScene,
          mapId: parentId,
          layerType: 'gribMapLayers',
          index,
        }),
      ),
    );
    space.radarMapLayers.forEach((grib, index) =>
      dispatch(
        updateMapGRSLayer({
          time: {
            ...grib.timeControls[0],
            startMS: grib.timeControls[0].startMS - diff,
            endMS: grib.timeControls[0].endMS - diff,
          },
          activeScene,
          mapId: parentId,
          layerType: 'radarMapLayers',
          index,
        }),
      ),
    );
    space.satelliteMapLayers.forEach((grib, index) =>
      dispatch(
        updateMapGRSLayer({
          time: {
            ...grib.timeControls[0],
            startMS: grib.timeControls[0].startMS - diff,
            endMS: grib.timeControls[0].endMS - diff,
          },
          activeScene,
          mapId: parentId,
          layerType: 'satelliteMapLayers',
          index,
        }),
      ),
    );
    space.symbolLayers.forEach((grib, index) =>
      dispatch(
        updateMapGRSLayer({
          time: {
            ...grib.timeControls[0],
            startMS: grib.timeControls[0].startMS - diff,
            endMS: grib.timeControls[0].endMS - diff,
          },
          activeScene,
          mapId: parentId,
          layerType: 'symbolLayers',
          index,
        }),
      ),
    );
  };
  const isTimeOutside = (layerTime: number, parentTime: TimeControlDef) => {
    return layerTime < parentTime.startMS || layerTime > parentTime.endMS;
  };
  const resizeStopped = (
    position: Position,
    delta: ResizableDelta,
    ref: HTMLElement,
    direction: ResizeDirection,
  ): void => {
    let payload: TimeControlDef = { ...timeCtrl };
    if (direction === 'left') {
      setTimeCtrl({ ...timeCtrl, startMS: getPercentFromRef(position, ref) });
      payload = { ...timeCtrl, startMS: getPercentFromRef(position, ref) };
      payload = {
        ...timeCtrl,
        startMS:
          parentTime.startMS > percentToTime(getPercentFromRef(position, ref))
            ? parentTime.startMS
            : percentToTime(getPercentFromRef(position, ref)),
        endMS: percentToTime(timeCtrl.endMS),
      };
    }
    if (direction === 'right') {
      setTimeCtrl({ ...timeCtrl, endMS: timeCtrl.endMS + getPercentFromDelta(delta, ref) });
      payload = { ...timeCtrl, endMS: timeCtrl.endMS + getPercentFromDelta(delta, ref) };
      payload = {
        ...timeCtrl,
        startMS: percentToTime(timeCtrl.startMS),
        endMS:
          parentTime.endMS < percentToTime(timeCtrl.endMS + getPercentFromDelta(delta, ref))
            ? parentTime.endMS
            : percentToTime(timeCtrl.endMS + getPercentFromDelta(delta, ref)),
      };
    }
    space.gribMapLayers.forEach((grib, index) =>
      dispatch(
        updateMapGRSLayer({
          time: {
            ...grib.timeControls[0],
            startMS: isTimeOutside(grib.timeControls[0].startMS, payload)
              ? payload.startMS
              : grib.timeControls[0].startMS,
            endMS: isTimeOutside(grib.timeControls[0].endMS, payload)
              ? payload.endMS
              : grib.timeControls[0].endMS,
          },
          activeScene,
          mapId: parentId,
          layerType: 'gribMapLayers',
          index,
        }),
      ),
    );
    space.radarMapLayers.forEach((grib, index) =>
      dispatch(
        updateMapGRSLayer({
          time: {
            ...grib.timeControls[0],
            startMS: isTimeOutside(grib.timeControls[0].startMS, payload)
              ? payload.startMS
              : grib.timeControls[0].startMS,
            endMS: isTimeOutside(grib.timeControls[0].endMS, payload)
              ? payload.endMS
              : grib.timeControls[0].endMS,
          },
          activeScene,
          mapId: parentId,
          layerType: 'radarMapLayers',
          index,
        }),
      ),
    );
    space.satelliteMapLayers.forEach((grib, index) =>
      dispatch(
        updateMapGRSLayer({
          time: {
            ...grib.timeControls[0],
            startMS: isTimeOutside(grib.timeControls[0].startMS, payload)
              ? payload.startMS
              : grib.timeControls[0].startMS,
            endMS: isTimeOutside(grib.timeControls[0].endMS, payload)
              ? payload.endMS
              : grib.timeControls[0].endMS,
          },
          activeScene,
          mapId: parentId,
          layerType: 'satelliteMapLayers',
          index,
        }),
      ),
    );
    dispatch(
      updateSpaceTime({
        time: payload,
        activeScene,
        mapId: parentId,
        spaceId: elementId,
      }),
    );
  };

  const lane = (
    <Rnd
      key={`${elementId}`}
      enableResizing={{
        left: mode === ModeEnum.SEQUENCE,
        right: mode === ModeEnum.SEQUENCE,
      }}
      minHeight={parentTime ? 12 : 24}
      dragAxis={'x'}
      resizeHandleComponent={{
        right: (
          <MdOutlineDragHandle
            size={24}
            color={'rgb(96, 136, 113)'}
            style={{
              transform: 'rotate(90deg) translateY(12px)',
              height: '100%',
            }}
          />
        ),
        left: (
          <MdOutlineDragHandle
            size={24}
            color={'rgb(96, 136, 113)'}
            style={{
              transform: 'rotate(90deg) translateY(2px)',
              height: '100%',
            }}
          />
        ),
      }}
      title={`${percentToTime(timeCtrl.startMS) / 1000}s - ${
        percentToTime(timeCtrl.endMS) / 1000
      }s`}
      className={`segment`}
      onClick={() => openSpace(parentId)}
      style={{
        background: 'yellow',
        height: 24,
        minHeight: 24,
        top: 12,
      }}
      maxHeight={24}
      bounds={'parent'}
      position={{
        x: getNumber(timeCtrl.startMS, width),
        y: 0,
      }}
      size={{
        width: parentTime
          ? timeCtrl.endMS > absoluteToPercent([parentTime], duration)[0].endMS
            ? absoluteToPercent([parentTime], duration)[0].endMS - timeCtrl.startMS + '%'
            : timeCtrl.endMS - timeCtrl.startMS + '%'
          : timeCtrl.endMS - timeCtrl.startMS + '%',
        height: 24,
      }}
      onDragStop={(e, data) => {
        e && dragEnded(getPercentFromData(data, width));
      }}
      onResizeStop={(e, direction, ref, delta, position) =>
        resizeStopped(position, delta, ref, direction)
      }
    >
      <div className={'layer-hover'}>
        {spaceSpan(space) &&
          `${
            sceneStart
              ? format(sceneStart, 'dd.MMM, HH:mm')
              : format(spaceSpan(space).min * 1000, 'dd.MMM, HH:mm')
          }-
          ${
            sceneEnd
              ? format(sceneEnd, 'dd.MMM, HH:mm')
              : format(spaceSpan(space).max * 1000, 'dd.MMM, HH:mm')
          }`}
      </div>
    </Rnd>
  );
  return (
    <div>
      <div {...props} id={'Lane'} ref={ref}>
        <div className={'rail'} style={{ height: 1 }} />
        {lane}
      </div>
    </div>
  );
};

export default WDSpaceLane;
