import { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DraggableData, Position, ResizableDelta, Rnd } from 'react-rnd';

import { animate } from '../../animations/animate';
import { ModeEnum } from '../../core/ui/enums/ModeEnum';
import { getAnimationType } from '../../helpers/timelineUtil';
import { useOverlap } from '../../hooks/useOverlap';
import { useScaleFactor } from '../../hooks/useScaleFactor';
import { MAX_FULLSCREEN_HEIGHT } from '../../model/constants/constants';
import PlayerContext from '../../pages/playground/playerContext/PlayerContext';
import { ActiveDef } from '../../store/slices/active-slice';
import { updateIndicatorPosition } from '../../store/slices/project-slice';
import { RootState } from '../../store/store';
import { ElementContainerInterface } from './ElementContainer';
import style from './style.module.scss';
import { transformAbsoluteToPercent, transformPercentToAbsolute } from './utils';

export const TimeIndicatorContainer = ({
  canvas,
  panelProps,
  disabled,
  type,
  lock = true,
  children,
  parentTime,
  inPoster,
  posterId,
  parentSize,
  isMapOverlay = false,
  parentMapId,
  geoPosterId,
  mainIndicator,
  ...rest
}: ElementContainerInterface) => {
  const dispatch = useDispatch();
  const contextValue = useContext(PlayerContext);
  const { bringToFront } = useOverlap(panelProps.positionControl, panelProps.id);
  const { time } = contextValue;
  // @ts-ignore
  const isLegend = type === 'layerLegend';
  // @ts-ignore
  const isMapOverlayType = type === 'mapOverlay';
  const resizeHandleStyles: React.CSSProperties = { zIndex: isMapOverlayType ? 9999999 : '' };
  const scaleFactor = useScaleFactor(disabled);
  const { mode, activeElement, activeScene, activeAspectRatio } = useSelector<RootState>(
    (state) => state.active,
  ) as ActiveDef;
  const { positionControl, id, timeControls } = panelProps;
  const elementTime = timeControls;
  const run = () => {
    if (!parentTime)
      return elementTime.find((segment) => segment.startMS <= time && segment.endMS >= time);
    if (isMapOverlay)
      return parentTime.find((segment) => segment.startMS <= time && segment.endMS >= time);

    return elementTime.find(
      (segment) => segment.startMS + parentTime[0].startMS <= time && segment.endMS >= time,
    );
  };
  const [gridPosition, setPosition] = useState<Position & ResizableDelta>({
    x: positionControl.x,
    y: positionControl.y,
    width: positionControl.w,
    height: positionControl.y,
  });

  const [lines, setLines] = useState<boolean>(false);
  const isVisible = () => (!run() ? 'hidden' : 'visible');

  const draggingStopped = (data: DraggableData) => {
    dispatch(
      updateIndicatorPosition({
        activeScene: activeScene as string,
        position: {
          ...panelProps.positionControl,
          x: transformAbsoluteToPercent(data.x, activeAspectRatio, 'width', MAX_FULLSCREEN_HEIGHT, {
            w: inPoster ? parentSize?.w : undefined,
            h: inPoster ? parentSize?.h : undefined,
          }),
          y: transformAbsoluteToPercent(
            data.y,
            activeAspectRatio,
            'height',
            MAX_FULLSCREEN_HEIGHT,
            {
              w: inPoster ? parentSize?.w : undefined,
              h: inPoster ? parentSize?.h : undefined,
            },
          ),
        },
        mapId: parentMapId,
      }),
    );
  };
  const dragging = (data: DraggableData) => {
    const { x, y } = data;
    setPosition({
      ...gridPosition,
      x: transformAbsoluteToPercent(x, activeAspectRatio, 'width', MAX_FULLSCREEN_HEIGHT, {
        w: inPoster ? parentSize?.w : undefined,
        h: inPoster ? parentSize?.h : undefined,
      }),
      y: transformAbsoluteToPercent(y, activeAspectRatio, 'height', MAX_FULLSCREEN_HEIGHT, {
        w: inPoster ? parentSize?.w : undefined,
        h: inPoster ? parentSize?.h : undefined,
      }),
    });
  };
  const resizingStopped = (ref: HTMLElement, position: Position) => {
    dispatch(
      updateIndicatorPosition({
        activeScene: activeScene as string,
        position: {
          ...positionControl,
          w: transformAbsoluteToPercent(
            ref.offsetWidth,
            activeAspectRatio,
            'width',
            MAX_FULLSCREEN_HEIGHT,
            {
              w: inPoster ? parentSize?.w : undefined,
              h: inPoster ? parentSize?.h : undefined,
            },
          ),
          h: transformAbsoluteToPercent(
            ref.offsetHeight,
            activeAspectRatio,
            'height',
            MAX_FULLSCREEN_HEIGHT,
            {
              w: inPoster ? parentSize?.w : undefined,
              h: inPoster ? parentSize?.h : undefined,
            },
          ),
          x: transformAbsoluteToPercent(
            position.x,
            activeAspectRatio,
            'width',
            MAX_FULLSCREEN_HEIGHT,
            {
              w: inPoster ? parentSize?.w : undefined,
              h: inPoster ? parentSize?.h : undefined,
            },
          ),
          y: transformAbsoluteToPercent(
            position.y,
            activeAspectRatio,
            'height',
            MAX_FULLSCREEN_HEIGHT,
            {
              w: inPoster ? parentSize?.w : undefined,
              h: inPoster ? parentSize?.h : undefined,
            },
          ),
        },
        mapId: parentMapId,
      }),
    );
  };
  return (
    <>
      <div
        style={{
          visibility: lines && !posterId ? 'visible' : 'hidden',
          position: 'absolute',
          top: transformPercentToAbsolute(
            gridPosition.y,
            activeAspectRatio,
            'height',
            MAX_FULLSCREEN_HEIGHT,
            {
              w: inPoster ? parentSize?.w : undefined,
              h: inPoster ? parentSize?.h : undefined,
            },
          ),
          left: 0,
          right: 0,
          height: 1,
          borderBottom: '1px dashed white',
        }}
      />
      <div
        style={{
          visibility: lines && !posterId ? 'visible' : 'hidden',
          position: 'absolute',
          top: 0,
          left: transformPercentToAbsolute(
            gridPosition.x,
            activeAspectRatio,
            'width',
            MAX_FULLSCREEN_HEIGHT,
            {
              w: inPoster ? parentSize?.w : undefined,
              h: inPoster ? parentSize?.h : undefined,
            },
          ),
          bottom: 0,
          width: 1,
          borderRight: '1px dashed white',
        }}
      />
      <div
        style={{
          visibility: lines && !posterId ? 'visible' : 'hidden',
          position: 'absolute',
          top:
            transformPercentToAbsolute(
              gridPosition.y,
              activeAspectRatio,
              'height',
              MAX_FULLSCREEN_HEIGHT,
              {
                w: inPoster ? parentSize?.w : undefined,
                h: inPoster ? parentSize?.h : undefined,
              },
            ) +
            transformPercentToAbsolute(
              positionControl.h,
              activeAspectRatio,
              'height',
              MAX_FULLSCREEN_HEIGHT,
              {
                w: inPoster ? parentSize?.w : undefined,
                h: inPoster ? parentSize?.h : undefined,
              },
            ),
          left: 0,
          right: 0,
          height: 1,
          borderTop: '1px dashed white',
        }}
      />
      <div
        style={{
          visibility: lines && !posterId ? 'visible' : 'hidden',
          position: 'absolute',
          left:
            transformPercentToAbsolute(
              gridPosition.x,
              activeAspectRatio,
              'width',
              MAX_FULLSCREEN_HEIGHT,
              {
                w: inPoster ? parentSize?.w : undefined,
                h: inPoster ? parentSize?.h : undefined,
              },
            ) +
            transformPercentToAbsolute(
              positionControl.w,
              activeAspectRatio,
              'width',
              MAX_FULLSCREEN_HEIGHT,
              {
                w: inPoster ? parentSize?.w : undefined,
                h: inPoster ? parentSize?.h : undefined,
              },
            ),
          top: 0,
          bottom: 0,
          width: 1,
          borderLeft: '1px dashed white',
        }}
      />
      <Rnd
        id={type === 'videoPanels' ? '' : id}
        data-parent={posterId}
        data-parenttype={posterId && 'weatherPosters'}
        data-type={type}
        scale={scaleFactor}
        disableDragging={mode === ModeEnum.PROJECT || disabled || !!posterId || isMapOverlay}
        enableResizing={mode === ModeEnum.SEQUENCE && !disabled && !posterId}
        lockAspectRatio={lock || isMapOverlay}
        resizeHandleStyles={{
          right: resizeHandleStyles,
          bottom: resizeHandleStyles,
          left: resizeHandleStyles,
          top: resizeHandleStyles,
          bottomLeft: resizeHandleStyles,
          bottomRight: resizeHandleStyles,
          topLeft: resizeHandleStyles,
          topRight: resizeHandleStyles,
        }}
        style={{
          visibility: isVisible(),
          zIndex:
            activeElement === id && bringToFront
              ? 20
              : isLegend || isMapOverlayType
              ? 100
              : positionControl.zindex,
          textAlign: 'center',
          boxSizing: 'border-box',
          ...rest.style,
        }}
        className={`${style.canvasElement} ${
          mode === ModeEnum.SEQUENCE && !disabled ? style.elementHover : null
        } ${
          activeElement === id && mode === ModeEnum.SEQUENCE && !disabled ? style.active : null
        } ${posterId}`}
        bounds={inPoster ? 'parent' : undefined}
        onDragStop={(e, data) => {
          setLines(false);
          draggingStopped(data);
        }}
        onDrag={(e, data) => dragging(data)}
        onResizeStop={(e, direction, ref, delta, position) => resizingStopped(ref, position)}
        size={{
          width: transformPercentToAbsolute(
            positionControl.w,
            activeAspectRatio,
            'width',
            MAX_FULLSCREEN_HEIGHT,
            {
              w: inPoster ? parentSize?.w : undefined,
              h: inPoster ? parentSize?.h : undefined,
            },
          ),
          height: transformPercentToAbsolute(
            positionControl.h,
            activeAspectRatio,
            'height',
            MAX_FULLSCREEN_HEIGHT,
            {
              w: inPoster ? parentSize?.w : undefined,
              h: inPoster ? parentSize?.h : undefined,
            },
          ),
        }}
        position={{
          x: transformPercentToAbsolute(
            positionControl.x,
            activeAspectRatio,
            'width',
            MAX_FULLSCREEN_HEIGHT,
            {
              w: inPoster ? parentSize?.w : undefined,
              h: inPoster ? parentSize?.h : undefined,
            },
          ),
          y: transformPercentToAbsolute(
            positionControl.y,
            activeAspectRatio,
            'height',
            MAX_FULLSCREEN_HEIGHT,
            {
              w: inPoster ? parentSize?.w : undefined,
              h: inPoster ? parentSize?.h : undefined,
            },
          ),
        }}
      >
        {animate(
          children,
          getAnimationType(run()?.inAnimationDef),
          {
            duration: run()?.inAnimationDuration,
            enterTime: run()?.startMS,
            currentTime: contextValue.time,
          },
          getAnimationType(run()?.outAnimationDef),
          {
            duration: run()?.outAnimationDuration,
            exitTime: run()?.endMS,
            currentTime: contextValue.time,
          },
        )}
      </Rnd>
    </>
  );
};
