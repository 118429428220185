const Button = (props: any) => {
  const { children, format, active, ...rest } = props;
  return (
    <button
      className={`mx-1 my-1 rounded-xl ${active ? 'bg-white' : 'bg-[#060b12]'}`}
      title={format}
      {...rest}
    >
      {children}
    </button>
  );
};

export default Button;
