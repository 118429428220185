import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { queryClient } from '../../..';
import { axiosInstance } from '../../../core/api/axiosInstance';
import { AnimationRetryRequest } from '../../../model/other/AnimationRetryRequest';
import { key } from './useGetTemplate';

export const useRetryPngSequence = () => {
  return useMutation(
    async (data: AnimationRetryRequest) => {
      const response = await axiosInstance.post('multimedia/retry-png-seq-create', data);
      return response.data;
    },
    {
      onSuccess: () => {
        toast.success('Request for png sequence creation is sent');
        queryClient.invalidateQueries(key);
      },
      onError: () => {
        toast.error('Error while trying to create png sequence');
      },
    },
  );
};
