import { useMutation } from 'react-query';

import { BiasFilterGroupDef } from '../../../../model/definitions/BiasFilterGroupDef';
import { ForecastWDSourceDef } from '../../../../model/definitions/ForecastWDSourceDef';
import { axiosInstance } from '../../axiosInstance';

export interface ForecastWDSourceBiasRequest extends ForecastWDSourceDef {
  changedValue: number;
}

export interface ForecastBiasGroup {
  groupName: string;
  groupDescription?: string;
  forecastWDBiasSources: Array<ForecastWDSourceBiasRequest>;
}
export const useCreateForecastBiasGroup = () =>
  useMutation(async ({ payload }: { payload: ForecastBiasGroup }): Promise<BiasFilterGroupDef> => {
    const { data } = await axiosInstance.post('biasGroup/forecastBiasGroup', { ...payload });
    return data;
  });
