import { InputNumber, Typography } from 'antd';
import Color from 'colorjs.io';

import { PaletteColorPicker } from '../../mapLayersProperties/PalettecolorPicker';
import GridItem from '../../shared/GridItem';
import GridWrapper from '../../shared/GridWrapper';

const { Title } = Typography;
interface Feture {
  drawingType: string;
  fillColor: string;
  lineColor: string;
  lineThickness: number;
  zIndex: number;
}
interface FeaturesSetupProps {
  feature: Feture;
  onChange: (e: string, prop: string) => void;
  drawingType: 'Circle' | 'Polygon' | 'LineString' | 'Arrow';
}
export const FeaturesSetup = ({ feature, onChange, drawingType }: FeaturesSetupProps) => {
  function convertPercentageToRGBA(rgbaString: string) {
    // Match the numbers between rgba() and split by comma
    const matches = rgbaString.match(/\d+\.\d+%?/g);

    if (matches && matches.length >= 3 && matches.length <= 4) {
      // Convert percentage values to integers in range 0-255
      const red = Math.round((parseFloat(matches[0]) * 255) / 100);
      const green = Math.round((parseFloat(matches[1]) * 255) / 100);
      const blue = Math.round((parseFloat(matches[2]) * 255) / 100);

      let alpha = 255; // Default alpha value

      if (matches.length === 4) {
        alpha = Math.round(parseFloat(matches[3]) * 255);
      }

      // Return RGBA string
      return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
    } else {
      // Return default values or handle error
      return 'rgba(0, 0, 0, 255)';
    }
  }
  return (
    <GridWrapper className={'py-5 pr-5'}>
      <GridItem
        label={'Drawing type'}
        noBorderBg
        item={<Title level={5}>{feature.drawingType}</Title>}
      />
      <GridItem
        label={'Layer level'}
        item={
          <InputNumber
            key={feature.zIndex}
            className={'border-0 bg-transparent w-full rounded-0'}
            value={feature.zIndex}
            onChange={(e) => onChange((e ?? 0).toString(), 'zIndex')}
          />
        }
      />
      <GridItem
        noBorderBg
        label={'Line color'}
        item={
          <PaletteColorPicker
            browserRGBA
            value={convertPercentageToRGBA(
              new Color(feature.lineColor).toString({ format: 'rgba' }),
            )}
            onChange={(e) => onChange(e, 'lineColor')}
          />
        }
      />
      <GridItem
        label={'Line width'}
        item={
          <InputNumber
            className={'border-0 bg-transparent w-full rounded-0'}
            value={feature.lineThickness}
            onChange={(e) => onChange((e ?? 0).toString(), 'lineThickness')}
          />
        }
      />
      {!['LineString', 'Arrow'].includes(drawingType) && (
        <GridItem
          noBorderBg
          label={'Fill'}
          item={
            <PaletteColorPicker
              browserRGBA
              value={convertPercentageToRGBA(
                new Color(feature.fillColor).toString({ format: 'rgba' }),
              )}
              onChange={(e) => onChange(e, 'fillColor')}
            />
          }
        />
      )}
    </GridWrapper>
  );
};
