import './style.scss';

import { cloneDeep } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { IoSaveOutline } from 'react-icons/io5';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import Button from '../../../atoms/button/Button';
import { saveMapAsTemplate, updateMapAsTemplate } from '../../../core/api/TemplatesAPI';
import { ElementsEnum } from '../../../core/ui/enums/ElementsEnum';
import { ScreenshotData } from '../../../helpers/screenshotElement';
import { useImplicitSave } from '../../../hooks/useImplicitSave';
import { C9ProjectDef } from '../../../model/definitions/C9ProjectDef';
import { KeyFramesDef } from '../../../model/definitions/KeyFramesDef';
import { MapPanelDef } from '../../../model/definitions/MapPanelDef';
import { MapPositionControlDef } from '../../../model/definitions/MapPositionControlDef';
import {
  ActiveDef,
  removeDisplayedFlyOvers,
  setDisplayedFlyOvers,
} from '../../../store/slices/active-slice';
import { addKeyFrame as addFlyOver, updateMapLayer } from '../../../store/slices/project-slice';
import { selectActiveMapLayer } from '../../../store/slices/selectors';
import { RootState } from '../../../store/store';
import BaseMapLayers from './BaseMapLayers';
import ColorFiltersProperties from './ColorFiltersProperties';
import DrawMapProperties from './DrawMapProperties';
import FlyOvers from './FlyOvers';
import GraticuleProperties from './GraticuleProperties';
import { CustomVectorLayers } from './mapLayersProperties/CustomVectorLayers';
import { Layers } from './mapLayersProperties/Layers';
import MapPosition from './MapPosition';
import { GeoPoster } from './panels/GeoPoster';
import { PositionControls } from './panels/PositionControls';
import { TimeControlsPanel } from './panels/TimeControlsPanel';
import styles from './Properties.module.scss';
import SaveAsTemplateModal from './SaveAsTemplateModal';

const MapProperties: React.FC = () => {
  const { activeScene, activeElement, selectedLayer } = useSelector<RootState, ActiveDef>(
    (state) => state.active,
  );
  const [opened, setOpened] = useState(false);
  const implicitSave = useImplicitSave();
  useHotkeys('ctrl+f, command+f', (ev) => {
    ev.preventDefault();
    enableFlyover(true);
  });

  const project = useSelector<RootState, C9ProjectDef>((state) => state.project.present.project);

  const mapLayer = useSelector<RootState, MapPanelDef | null | undefined>((state) =>
    selectActiveMapLayer(state),
  );

  const currentMapState = useSelector<RootState, MapPositionControlDef>(
    (state) => state.mapState.mapState,
  );

  const existsAsTemplate = Boolean(mapLayer?.templateId && mapLayer.templateVersionId);

  const dispatch = useDispatch();
  function onMapLayerChange(propertyPath: Paths<MapPanelDef> | keyof MapPanelDef, newValue: any) {
    if (mapLayer?.mapPositionControl.updateView) {
      dispatch(
        updateMapLayer({
          activeScene,
          newValue: false,
          elementId: activeElement,
          propertyPath: 'mapPositionControl.updateView',
        }),
      );
    }
    dispatch(
      updateMapLayer({
        activeScene,
        newValue,
        elementId: activeElement,
        propertyPath,
      }),
    );
  }

  const saveMapTemplate = useMutation(saveMapAsTemplate, {
    onSuccess: (data) => {
      const proj = cloneDeep(project);
      const scene = proj.sceneDefs.find((sc) => sc.id === activeScene);
      const map = scene?.mapPanels.find((mp) => mp.id === mapLayer?.id);
      if (!map) return;
      map.templateId = data.id;
      map.templateVersionId = data.versionId;
      implicitSave(proj);
      toast.success('Successfully added map template!');
      setOpened(false);
    },
  });
  const updateMapTemplate = useMutation(updateMapAsTemplate, {
    onSuccess: (data) => {
      const proj = cloneDeep(project);
      const scene = proj.sceneDefs.find((sc) => sc.id === activeScene);
      const map = scene?.mapPanels.find((mp) => mp.id === mapLayer?.id);
      if (!map) return;
      map.templateVersionId = data.versionId;
      implicitSave(proj);
      toast.success('Successfully updated map template!');
      setOpened(false);
    },
  });

  const setInitialKeyFrame = () => {
    const initKeyframe: KeyFramesDef = {
      timeInMS: mapLayer?.timeControls[0].startMS ? mapLayer?.timeControls[0].startMS : 0,
      mapPositionControl: {
        longitude: currentMapState.longitude!,
        latitude: currentMapState.latitude,
        zoom: currentMapState.zoom!,
        bearing: currentMapState.bearing,
        pitch: currentMapState.pitch,
        defaultZoom: currentMapState.defaultZoom,
      },
      transitionType: 'LINEAR',
      transitionTilt: 0,
    };
    dispatch(
      addFlyOver({
        activeScene: activeScene as string,
        elementId: activeElement,
        keyFrame: initKeyframe,
      }),
    );
  };

  useEffect(() => {}, [activeElement]);

  function enableFlyover(e: boolean) {
    e &&
      mapLayer?.flyOver?.keyFrames &&
      mapLayer?.flyOver?.keyFrames[0]?.timeInMS !== 0 &&
      setInitialKeyFrame();
    // !e && onMapLayerChange('flyOver.keyFrames', []);
    onMapLayerChange('flyOverEnabled', e);
    e
      ? dispatch(setDisplayedFlyOvers({ displayedFlyOver: activeElement as string }))
      : dispatch(
          removeDisplayedFlyOvers({
            displayedFlyOvers: activeElement as string,
          }),
        );
  }

  function onTemplateSave() {
    setOpened(true);
  }

  function onConfirm(formData: {
    name: string;
    description: string;
    thumbnailURLs: ScreenshotData[];
  }) {
    const { name, description, thumbnailURLs } = formData;
    const mapLayerClone = cloneDeep(mapLayer);
    if (mapLayerClone) {
      mapLayerClone.wdSpace.forEach((wd) => {
        wd.gribMapLayers = [];
        wd.radarMapLayers = [];
        wd.satelliteMapLayers = [];
      });
      mapLayerClone.drawingElements = [];

      if (!existsAsTemplate) {
        saveMapTemplate.mutate({
          name,
          description,
          thumbnailURLs,
          mapPanelDef: mapLayerClone!,
        });
      } else {
        updateMapTemplate.mutate({
          mapDef: mapLayerClone!,
          templateId: mapLayer!.templateId,
          thumbnailURLs,
          name,
          description,
        });
      }
    }
  }

  /** drag to scroll **/
  const scrollableContainer = useRef<any>();

  return (
    <>
      <div className={`${styles.wrapper} map-properties-wrapper`} ref={scrollableContainer}>
        <div className="absolute property-grey-actions-wrapper">
          <Button
            buttonType="border"
            label={existsAsTemplate ? 'Save new template version' : 'Save as template'}
            icon={<IoSaveOutline style={{ width: 12, height: 12 }} />}
            onClick={onTemplateSave}
            className="flex items-center gap-1 property-grey-buttons"
          />
        </div>

        {mapLayer && <MapPosition mapLayer={mapLayer} />}
        {/* <BiasFilters /> */}
        {mapLayer && <GeoPoster mapLayer={mapLayer} />}
        {mapLayer?.positionControl && (
          <PositionControls position={mapLayer.positionControl} layer={'mapPanels'} />
        )}

        {mapLayer?.timeControls.length && (
          <TimeControlsPanel timeControls={mapLayer?.timeControls} layer={ElementsEnum.MAP} />
        )}

        {mapLayer && <DrawMapProperties mapLayer={mapLayer} />}

        {mapLayer && <BaseMapLayers mapLayer={mapLayer} />}
        {mapLayer && <CustomVectorLayers mapLayer={mapLayer} />}
        {mapLayer && <GraticuleProperties mapLayer={mapLayer} />}
        {mapLayer && <FlyOvers mapLayer={mapLayer} />}
        {mapLayer && <ColorFiltersProperties mapLayer={mapLayer} />}

        {mapLayer && (
          <Layers
            mapLayer={mapLayer}
            onMapLayerChange={onMapLayerChange}
            layerType={selectedLayer[1]}
            layerId={selectedLayer[2]}
          />
        )}
      </div>
      {mapLayer && opened && (
        <SaveAsTemplateModal
          entityName={mapLayer!.name}
          onClose={() => setOpened(false)}
          onConfirm={onConfirm}
          opened={opened}
          loading={saveMapTemplate.isLoading}
          templateId={mapLayer.templateId}
          type={'map'}
        />
      )}
    </>
  );
};

export default MapProperties;
