import { AudioElementTemplate } from '../../model/definitions/AudioElementTemplate';
import { ImagePanelDefTemplate } from '../../model/definitions/ImagePanelDefTemplate';
import { LayerDefTemplate } from '../../model/definitions/LayerDefTemplate';
import { MapDefTemplate } from '../../model/definitions/MapDefTemplate ';
import { SceneDefTemplate } from '../../model/definitions/SceneDefTemplate';
import { VideoPanelDefTemplate } from '../../model/definitions/VideoPanelDefTemplate';
import { WeatherPosterDefTemplate } from '../../model/definitions/WeatherPosterDefTemplate';
import { axiosInstance } from './axiosInstance';

const getRepoImage = async (id: string) => {
  const res = await axiosInstance.get<ImagePanelDefTemplate>(
    `multimedia/IMAGE/current-template/${id}`,
  );
  return res.data;
};
const getRepoVideo = async (id: string) => {
  const res = await axiosInstance.get<VideoPanelDefTemplate>(
    `multimedia/VIDEO/current-template/${id}`,
  );
  return res.data;
};

const getRepoAudio = async (id: string) => {
  const res = await axiosInstance.get<AudioElementTemplate>(
    `/multimedia/AUDIO/current-template/${id}`,
  );
  return res.data;
};

const getRepoMap = async (id: string) => {
  const res = await axiosInstance.get<MapDefTemplate>(`map-template/current/${id}`);
  return res.data;
};

const getRepoGraph = async (id: string) => {
  const res = await axiosInstance.get<WeatherPosterDefTemplate>(
    `weather-poster-template/current/${id}`,
  );
  return res.data;
};

const getComposite = async (id: string) => {
  const res = await axiosInstance.get<WeatherPosterDefTemplate>(
    `weather-geo-poster-template/current/${id}`,
  );
  return res.data;
};

const getRepoScene = async (id: string) => {
  const res = await axiosInstance.get<SceneDefTemplate>(`scene-template/current/${id}`);
  return res.data;
};

const getRepoLayer = async (id: string) => {
  const res = await axiosInstance.get<LayerDefTemplate>(`layer-template/current/${id}`);
  return res.data;
};

export {
  getComposite,
  getRepoAudio,
  getRepoGraph,
  getRepoImage,
  getRepoLayer,
  getRepoMap,
  getRepoScene,
  getRepoVideo,
};
