import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '../../atoms/Button/AntButton';
import { useApproveItem } from '../../hooks/useAproveItem';
import { useBuyItem } from '../../hooks/useBuyItem';
import { useRemoveApproval } from '../../hooks/useRemoveApproval';
import { useUnpublishMarketplaceItem } from '../../hooks/useUnpublishMarketplaceItem';
import MarketplaceContext from '../../marketplaceContext';

interface AdminAreaProps {
  id: string;
  approved: boolean;
  item: any;
  onHover?: boolean;
  edit?: () => void;
  save?: () => void;
  isEditing?: boolean;
}
const AdminArea = ({ approved, id, item, onHover, edit, save, isEditing }: AdminAreaProps) => {
  const { mutate: approve } = useApproveItem();
  const { mutate: disapprove } = useRemoveApproval();
  const { mutate: unpublish } = useUnpublishMarketplaceItem();
  const { mutate: mutateBuyItem, isSuccess } = useBuyItem();
  const { tokenEmail } = useContext(MarketplaceContext);
  const navigate = useNavigate();
  function unPublishItemFunction() {
    unpublish({
      templateType: item.templateType,
      templateId: item.templateId,
      templateVersionId: item.templateVersionId,
    });
  }
  const handlePurchase = () => {
    mutateBuyItem(id);
  };

  if (isSuccess) {
    navigate('/marketplace/confirm');
  }

  return (
    <>
      {onHover ? (
        <>
          {approved ? (
            <Button onClick={() => disapprove(id)}>Disapprove</Button>
          ) : (
            <Button onClick={() => approve(id)}>Approve</Button>
          )}
          <Button onClick={() => unPublishItemFunction()}>Unpublish</Button>
        </>
      ) : (
        <div className="w-full">
          <div className="flex mt-4 gap-4">
            {approved ? (
              <Button
                type="primary"
                size="large"
                className="w-full rounded-[4px]"
                onClick={() => disapprove(id)}
              >
                Disapprove
              </Button>
            ) : (
              <Button
                type="primary"
                size="large"
                className="w-full rounded-[4px]"
                onClick={() => approve(id)}
              >
                Approve
              </Button>
            )}
            <Button
              type="primary"
              size="large"
              className="w-full rounded-[4px]"
              onClick={() => unPublishItemFunction()}
            >
              Unpublish
            </Button>
          </div>
          <div className="flex mt-4 gap-4">
            {isEditing ? (
              <Button size="large" className="w-full rounded-[4px]" onClick={() => save && save()}>
                Save
              </Button>
            ) : (
              <Button size="large" className="w-full rounded-[4px]" onClick={() => edit && edit()}>
                Edit
              </Button>
            )}
            {tokenEmail !== item.createdBy && (
              <Button
                size="large"
                className="w-full rounded-[4px]"
                type="primary"
                onClick={handlePurchase}
              >
                Purchase item (admin)
              </Button>
            )}
          </div>
        </div>
      )}
    </>
  );
};
export default AdminArea;
