import { QueryKey, useQuery } from 'react-query';

import { axiosInstance } from '../../../core/api/axiosInstance';

export const key: QueryKey = ['CURRENCY_OPTIONS'];

export const useGetCurrencyOptions = () => {
  return useQuery([...key], async () => {
    const res = await axiosInstance.get(`/payment/exchange/today`);
    return res.data;
  });
};
