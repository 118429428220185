import { TimeStepEnum } from '../enums/TimeStepEnum';
import { BoxDef } from './BoxDef';
import { C9Object } from './C9Object';
import { LogicalGroupParent } from './LogicalGroupParent';
import { PositionControlDef } from './PositionControlDef';
import { TimeControlDef } from './TimeControlDef';

export class TimestampElementDef extends C9Object {
  timeControls: TimeControlDef[] = [new TimeControlDef(0, 10000)];
  positionControl = new PositionControlDef(30, 5, 5, 5);
  boxDef = new BoxDef();
  fontColor = 'rgba(255, 255, 255, 255)';
  fontSize = 4;
  fontFamily: string;
  fontType?: string;
  fontVariantId?: string;
  textTransform:
    | 'none'
    | 'capitalize'
    | 'uppercase'
    | 'lowercase'
    | 'full-width'
    | 'full-size-kana';
  fontAlignment: string;
  justifyContent: JustifyContent = JustifyContent.Start;
  strokeWidth = 0;
  strokeColor = 'rgba(255, 255, 255, 255)';
  parentGroups = [new LogicalGroupParent()];
  dateStart: number | null;
  dateEnd: number | null;
  displayText: boolean;
  enabled: boolean;
  format: string;
  step: TimeStepEnum;
}

export enum JustifyContent {
  FlexStart = 'flex-start',
  FlexEnd = 'flex-end',
  Center = 'center',
  SpaceBetween = 'space-between',
  SpaceAround = 'space-around',
  SpaceEvenly = 'space-evenly',
  Start = 'start',
  End = 'end',
  Left = 'left',
  Right = 'right',
}
