import { InputNumber, Select } from 'antd';
import { Button, ToggleSwitch } from 'flowbite-react';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ColorPickerRefactor from '../../../../atoms/color-picker-refactor/ColorPickerRefactor';
import { useGetCustomVectorLayer } from '../../../../core/api/mapLayers/customVectorLayers/useGetCustomVectorLayer';
import { CustomVectorLayer } from '../../../../model/definitions/CustomVectorLayer';
import { MapPanelDef } from '../../../../model/definitions/MapPanelDef';
import { VectorMapLayer } from '../../../../model/definitions/VecorMapLayer';
import { ActiveDef } from '../../../../store/slices/active-slice';
import {
  addCustomVectorLayer,
  removeCustomVectorLayer,
  updateMapLayer,
} from '../../../../store/slices/project-slice';
import { RootState } from '../../../../store/store';
import useGetMultimedia from '../../../dashboard/queries-NEW/useGetMultimedia';
import { PropertySection } from '../components/PropertySection';
import GridItem from '../shared/GridItem';
import GridWrapper from '../shared/GridWrapper';

interface Props {
  mapLayer: MapPanelDef;
}
export const CustomVectorLayers = ({ mapLayer }: Props) => {
  const { activeScene, activeElement } = useSelector<RootState, ActiveDef>((state) => state.active);
  const dispatch = useDispatch();
  const { data } = useGetMultimedia({
    page: 0,
    type: 'custom_vector',
    size: 200,
  });
  const { mutate } = useGetCustomVectorLayer();

  const handleAddLayer = (id: string) =>
    mutate(
      { id },
      {
        onSuccess: (data) => {
          dispatch(
            addCustomVectorLayer({
              vector: new CustomVectorLayer(data),
              mapId: mapLayer.id,
              activeScene,
            }),
          );
        },
      },
    );

  const layersInMap = mapLayer.wdSpace[0].vectorMapLayers.filter(isCustom);

  const handleRemoveLayer = useCallback(
    (id: string) =>
      dispatch(
        removeCustomVectorLayer({
          vector: id,
          mapId: mapLayer.id,
          activeScene,
        }),
      ),
    [activeScene, dispatch, mapLayer.id],
  );
  function isCustom(obj: unknown): obj is CustomVectorLayer {
    return typeof obj === 'object' && obj !== null && 'customVectorDef' in obj;
  }
  const handleEnabledChange = (layer: CustomVectorLayer, enabled: boolean) => {
    const allLayers = structuredClone(mapLayer.wdSpace[0].vectorMapLayers);
    const editedLayer = allLayers?.find(
      (l: CustomVectorLayer | VectorMapLayer) => l.id === layer.id,
    );
    if (editedLayer) editedLayer.enabled = enabled;
    dispatch(
      updateMapLayer({
        activeScene,
        newValue: allLayers,
        elementId: activeElement,
        //@ts-ignore
        propertyPath: 'wdSpace[0].vectorMapLayers',
      }),
    );
  };
  const handlePropsChange = (layer: CustomVectorLayer, prop: string, value: string | number) => {
    const style = JSON.parse(layer.style);
    style[prop] = value;
    const allLayers = structuredClone(mapLayer.wdSpace[0].vectorMapLayers);
    const editedLayer = allLayers?.find(
      (l: CustomVectorLayer | VectorMapLayer) => l.id === layer.id,
    );
    if (editedLayer) editedLayer.style = JSON.stringify(style);
    dispatch(
      updateMapLayer({
        activeScene,
        newValue: allLayers,
        elementId: activeElement,
        //@ts-ignore
        propertyPath: 'wdSpace[0].vectorMapLayers',
      }),
    );
  };
  const activeLayers = (mapLayer: MapPanelDef) => {
    const layers = mapLayer.wdSpace[0].vectorMapLayers.filter(isCustom);
    return layers.map((item) => (
      <PropertySection isOpened={false} label={item.name} key={`${item.id}`} className={'ml-4'}>
        <GridWrapper className={'py-2'}>
          <GridItem
            noBorderBg
            label="Stroke color:"
            item={
              <ColorPickerRefactor
                value={JSON.parse(item.style).strokeColor}
                onChange={(e) => handlePropsChange(item, 'strokeColor', e)}
              />
            }
          />
          <GridItem
            noBorderBg
            label="Stroke width:"
            item={
              <InputNumber
                className={'w-full'}
                value={JSON.parse(item.style).strokeWidth}
                onChange={(e) => handlePropsChange(item, 'strokeWidth', e)}
              />
            }
          />
          <GridItem
            noBorderBg
            label="Level:"
            item={
              <InputNumber
                className={'w-full'}
                value={JSON.parse(item.style).zindex}
                onChange={(e) => handlePropsChange(item, 'zindex', e)}
              />
            }
          />
          <GridItem
            noBorderBg
            label={'Enabled:'}
            item={
              <ToggleSwitch
                checked={item.enabled}
                label={'enabled'}
                onChange={(e) => handleEnabledChange(item, e)}
              />
            }
          />
          <GridItem
            noBorderBg
            label={''}
            item={
              <div className={'flex justify-end w-full'}>
                <Button color={'warning'} onClick={() => handleRemoveLayer(item.id)}>
                  remove
                </Button>
              </div>
            }
          />
        </GridWrapper>
      </PropertySection>
    ));
  };
  const vectorOptions = data?.content
    .filter((item) => !layersInMap.map((layer) => layer.customVectorDef?.id).includes(item.id))
    .map((item) => {
      return { value: item.versionId, label: item.name };
    });
  return (
    <PropertySection isOpened={false} label={'Custom Vector Layers'}>
      <GridWrapper className={'py-2'}>
        <GridItem
          noBorderBg
          label={'Vector'}
          item={<Select options={vectorOptions} onSelect={(value) => handleAddLayer(value)} />}
        />
        <GridItem noBorderBg label={''} item={null} />
      </GridWrapper>
      {activeLayers(mapLayer)}
    </PropertySection>
  );
};
