import '../../storyBoard.scss';

import { ResizeDirection } from 're-resizable';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useResizeDetector } from 'react-resize-detector';
import { Position, ResizableDelta, Rnd } from 'react-rnd';

import {
  getNumber,
  getPercentFromData,
  partialAbsoluteToPercent,
} from '../../../../../helpers/timelineUtil';
import { TimeControlDef } from '../../../../../model/definitions/TimeControlDef';
import { deleteSkip, updateProjectSkipTimes } from '../../../../../store/slices/project-slice';

declare global {
  type SkipTimeDef = Pick<TimeControlDef, 'startMS' | 'endMS'> & Partial<TimeControlDef>;
}
interface MarkerProps {
  value: Array<SkipTimeDef>;
  duration: number;
}
const style = {
  backgroundColor: 'rgba(252,0,0,0.1)',
  borderBottom: '5px solid red',
};

const LeftHandle = () => {
  return <div className={'left-handle marker-handle'} />;
};
const RightHandle = () => {
  return <div className={'right-handle marker-handle'} />;
};
export const Marker: FC<MarkerProps> = ({ value, duration, ...props }) => {
  const dispatch = useDispatch();
  const { width, ref } = useResizeDetector();
  const [pieces, setPieces] = useState<Array<SkipTimeDef>>(value);
  useEffect(() => {
    setPieces(partialAbsoluteToPercent(value, duration, 0, duration));
  }, [width, duration, value]);
  const getPercentFromRef = (position: Position, ref: HTMLElement): number => {
    return (position.x * 100) / ref.offsetParent!.clientWidth;
  };
  const getPercentFromDelta = (data: ResizableDelta, ref: HTMLElement) => {
    return (data.width * 100) / ref.offsetParent!.clientWidth;
  };
  const percentToTime = (percent: number) => {
    return Math.round((duration * percent) / 100);
  };
  const dragEnded = (percent: number, index: number) => {
    const newPieces = [...pieces];
    const width = newPieces[index].endMS - newPieces[index].startMS;
    if (newPieces[index - 1] && newPieces[index - 1].endMS > percent) {
      newPieces[index].startMS = newPieces[index - 1].endMS;
      newPieces[index].endMS = newPieces[index - 1].endMS + width;
    } else if (newPieces[index + 1] && newPieces[index + 1].startMS < percent + width) {
      newPieces[index].startMS = newPieces[index + 1].startMS - width;
      newPieces[index].endMS = newPieces[index + 1].startMS;
    } else {
      newPieces[index].startMS = percent;
      newPieces[index].endMS = percent + width;
    }
    const percentToTime = (percent: number) => {
      return Math.round((duration * percent) / 100);
    };
    setPieces(newPieces);
    const newTimes: SkipTimeDef[] = [];
    newPieces.forEach((item) => {
      newTimes.push({
        ...item,
        endMS: percentToTime(item.endMS),
        startMS: percentToTime(item.startMS),
      });
    });
    dispatch(updateProjectSkipTimes({ timeToSkip: newTimes }));
  };
  const resizeStopped = (
    position: Position,
    delta: ResizableDelta,
    ref: HTMLElement,
    direction: ResizeDirection,
    index: number,
  ): void => {
    const newPieces = [...pieces];
    if (direction === 'left') {
      if (newPieces[index - 1] && newPieces[index - 1].endMS > getPercentFromRef(position, ref)) {
        newPieces[index].startMS = newPieces[index - 1].endMS;
      } else {
        newPieces[index].startMS = getPercentFromRef(position, ref);
      }
    }
    if (direction === 'right') {
      if (
        newPieces[index + 1] &&
        newPieces[index + 1].startMS < newPieces[index].endMS + getPercentFromDelta(delta, ref)
      ) {
        newPieces[index].endMS = newPieces[index + 1].startMS;
      } else {
        newPieces[index].endMS += getPercentFromDelta(delta, ref);
      }
    }
    setPieces(newPieces);
    const newTimes: SkipTimeDef[] = [];
    newPieces.forEach((item) => {
      newTimes.push({
        ...item,
        endMS: percentToTime(item.endMS),
        startMS: percentToTime(item.startMS),
      });
    });
    dispatch(updateProjectSkipTimes({ timeToSkip: newTimes }));
  };
  const all = () =>
    pieces?.map((item, index) => (
      <Rnd
        resizeHandleComponent={{
          left: <LeftHandle />,
          right: <RightHandle />,
        }}
        key={`Skip_${index}`}
        onDoubleClick={(e: MouseEvent) => dispatch(deleteSkip({ index })) /*onDelete(e, index)*/}
        enableResizing={{ left: true, right: true }}
        minHeight={275}
        dragAxis={'x'}
        maxHeight={275}
        bounds={'parent'}
        style={style}
        position={{
          x: getNumber(item.startMS, width),
          y: -260,
        }}
        size={{
          width: item.endMS - item.startMS + '%',
          height: 275,
        }}
        onDragStop={(e, data) => {
          e && dragEnded(getPercentFromData(data, width), index);
        }}
        onResizeStop={(e, direction, ref, delta, position) =>
          resizeStopped(position, delta, ref, direction, index)
        }
      />
    ));
  return (
    <div {...props} id={'Lane'} ref={ref}>
      <div className={'rail'} style={{ height: 1 }} />
      {all()}
    </div>
  );
};
