import { useMutation } from 'react-query';

import { AudioElementTemplate } from '../../../model/definitions/AudioElementTemplate';
import { ElementsEnum } from '../../ui/enums/ElementsEnum';
import { axiosInstance } from '../axiosInstance';

export const useAddAudioElement = () =>
  useMutation(
    async ({ file, name }: { file: File; name: string }): Promise<AudioElementTemplate> => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('type', ElementsEnum.AUDIO);
      formData.append('name', `Voiceover_for_${name}`);
      formData.append('description', '');
      const { data } = await axiosInstance.post('multimedia/uploadFile', formData, {
        headers: { 'content-type': 'multipart/form-data' },
      });
      return data;
    },
  );
