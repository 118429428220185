import { ToggleSwitch } from 'flowbite-react';

import { IconTemplateDef } from '../../../model/definitions/IconTemplateDef';
import { ObservedWDElementRequestDTO } from '../../../model/DTO/ObservedWDElementRequestDTO';
import { ObservedData, ObservedProperties } from '../../../model/other/ObservedData';
import { IconPicker } from './IconPicker';
import { ObservedCard } from './ObservedCard';

interface SecondStepProps {
  observedData: ObservedData;
  selectData: (property: ObservedProperties, unit: string) => void;
  selectIcon: (icon: IconTemplateDef, remove?: boolean) => void;
  formValues: ObservedWDElementRequestDTO;
  bias: boolean;
  setBias: (bias: boolean) => void;
  setFormValues: (form: ObservedWDElementRequestDTO) => void;
}

export const ObservedSecondStep = ({
  observedData,
  selectData,
  selectIcon,
  formValues,
  bias,
  setBias,
  setFormValues,
}: SecondStepProps) => {
  const isIconSelected = (parameterType: string) =>
    !!formValues.elements?.find((element) => element.parameterType === parameterType);
  const renderProperties = () => {
    const iconPicker = (
      <IconPicker
        active={isIconSelected(observedData.weatherType)}
        weatherType={observedData.weatherType}
        onSelect={selectIcon}
        onClick={(icon, active) => selectIcon(icon, active)}
      />
    );
    const restData = observedData?.properties.map((property, index) => (
      <ObservedCard
        biased={!!property.biasFilterId}
        property={property}
        key={index}
        onClick={(unit) => selectData(property, unit)}
        active={!!formValues?.elements?.find((element) => element.parameterType === property.name)}
      />
    ));
    return [iconPicker, ...restData];
  };

  return (
    <div>
      <div className={'flex forecast-properties'}>
        <div>
          <label>
            Station:
            <p>{observedData.station?.fullName}</p>
          </label>
        </div>
        <div>
          <label>
            Position:
            <p>
              {observedData.station?.latitude.toFixed(5)},
              {observedData.station?.longitude.toFixed(5)}
            </p>
          </label>
        </div>
        <div>
          <label>
            Code:
            <p>{observedData.station?.value}</p>
          </label>
        </div>
        <div>
          <label>
            Weather Type:
            <p>{observedData.weatherType}</p>
          </label>
        </div>
        <div>
          <label>
            UTC time:
            <p>{observedData.utcTime}</p>
          </label>
        </div>
        <div>
          <label>
            Local time:
            <p>{observedData.localTime ?? 'N/A'}</p>
          </label>
        </div>
      </div>
      <div className={'grid grid-cols-3 gap-5'}>
        <div className={'flex'}>
          <label className={'flex pl-2'}>
            <span className={'mr-2'}>Apply bias filter</span>{' '}
            <ToggleSwitch checked={bias} onChange={setBias} label={''} />
          </label>
        </div>
        <div className={'flex'}>
          <label className={'flex pl-2'}>
            <span className={'mr-2'}>Use Group Date</span>{' '}
            <ToggleSwitch
              checked={formValues?.groupDate}
              onChange={(e) => setFormValues({ ...formValues, groupDate: e })}
              label={''}
            />
          </label>
        </div>
        <div className={'flex'}>
          <label className={'flex pl-2'}>
            <span className={'mr-2'}>Use Group Location</span>{' '}
            <ToggleSwitch
              checked={formValues?.groupLocation}
              onChange={(e) => setFormValues({ ...formValues, groupLocation: e })}
              label={''}
            />
          </label>
        </div>
      </div>
      <div className={'grid grid-cols-4 gap-4 mt-6 mb-6'}>{renderProperties()}</div>
    </div>
  );
};
