import { useMutation } from 'react-query';

import { SceneDef } from '../../../../model/definitions/SceneDef';
import { axiosInstance } from '../../axiosInstance';

export const useApplyBiasGroupOnScene = () =>
  useMutation(
    async ({
      type,
      biasGroupId,
      body,
    }: {
      type: 'OBSERVED' | 'FORECAST';
      biasGroupId: string;
      body: SceneDef;
    }): Promise<SceneDef> => {
      const { data } = await axiosInstance.put(
        `biasGroup/apply/${type}/${biasGroupId}/scene`,
        body,
      );
      return data;
    },
  );
