import './style.scss';

import { Checkbox } from 'antd';
import { Tooltip } from 'flowbite-react';
import { useHotkeys } from 'react-hotkeys-hook';
import { BiCut, BiImageAlt, BiStopwatch, BiText, BiVideo } from 'react-icons/bi';
import { BsClock } from 'react-icons/bs';
import { FiMic } from 'react-icons/fi';
import { VscTrash } from 'react-icons/vsc';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../../atoms/button/Button';
import { ElementsEnum } from '../../../core/ui/enums/ElementsEnum';
import { enumToPath } from '../../../helpers/enumToPath';
import { queryClient } from '../../../index';
import { AudioElement } from '../../../model/definitions/AudioElement';
import { ForecastWDElementDef } from '../../../model/definitions/ForecastWDElementDef';
import { ImagePanelDef } from '../../../model/definitions/ImagePanelDef';
import { MapPanelDef } from '../../../model/definitions/MapPanelDef';
import { ObservedWDElementDef } from '../../../model/definitions/ObservedWDElementDef';
import { PointDateDef } from '../../../model/definitions/PointDateDef';
import { PointLocationDef } from '../../../model/definitions/PointLocationDef';
import { TextPanelDef } from '../../../model/definitions/TextPanelDef';
import { TimestampElementDef } from '../../../model/definitions/TimestampElementDef';
import { VideoPanelDef } from '../../../model/definitions/VideoPanelDef';
import { WeatherPosterDef } from '../../../model/definitions/WeatherPosterDef';
import { GroupingEnum } from '../../../model/UI/enums/GroupingEnum';
import {
  ActiveDef,
  addToMultiselect,
  setActiveMap,
  setElement,
} from '../../../store/slices/active-slice';
import { RootState } from '../../../store/store';
import { AllElementsDefs } from '../../../types/elements';
import { elementEnumToKey } from '../helpers';

interface RegularListElementProps {
  id: string | number;
  elementType: ElementsEnum;
  name: string;
  enabled: boolean;
  cut?: () => void;
  deleteElement?: () => void;
  paddingLeft?: number;
  inPoster?: boolean;
  parentId?: string;
  mapId?: string;
  onClick?: (e: any, id?: string) => void;
  onDragEnd?: () => void;
  onDragStart?: () => void;
  enable?: (e: boolean, id: string | number, elementType: string) => void;
  versionId?: string;
  elem:
    | TextPanelDef
    | TimestampElementDef
    | AudioElement
    | VideoPanelDef
    | ImagePanelDef
    | WeatherPosterDef
    | ObservedWDElementDef
    | ForecastWDElementDef
    | PointDateDef
    | PointLocationDef
    | MapPanelDef;
  item:
    | TextPanelDef
    | TimestampElementDef
    | AudioElement
    | VideoPanelDef
    | ImagePanelDef
    | WeatherPosterDef
    | ObservedWDElementDef
    | ForecastWDElementDef
    | PointDateDef
    | PointLocationDef
    | MapPanelDef;
}
const RegularListElement = ({
  id,
  cut,
  deleteElement,
  elementType,
  name,
  enabled,
  paddingLeft,
  inPoster,
  parentId,
  mapId,
  onClick,
  onDragEnd,
  onDragStart,
  enable,
  versionId,
  item,
  elem,
}: RegularListElementProps) => {
  const dispatch = useDispatch();
  const { activeElement, grouping, multiselect } = useSelector<RootState>(
    (state) => state.active,
  ) as ActiveDef;
  //@ts-ignore
  const elemSection = multiselect.find((section) => section.type === enumToPath(elementType));
  const selected = elemSection?.elements.find((listItem) => listItem.element.id === id);
  useHotkeys(
    'shift+ctrl+delete, shift+command+delete',
    (ev) => {
      ev.preventDefault();
      if (activeElement === id && deleteElement) deleteElement();
    },
    [deleteElement, activeElement, id],
  );
  useHotkeys(
    'shift+ctrl+x, shift+command+x',
    (ev) => {
      ev.preventDefault();
      if (activeElement === id && cut) cut();
    },
    [cut, activeElement, id],
  );

  function panelIcon(elementType: ElementsEnum) {
    if (elementType === ElementsEnum.IMAGE) {
      return <BiImageAlt />;
    } else if (elementType === ElementsEnum.AUDIO) {
      return <FiMic />;
    } else if (elementType === ElementsEnum.TEXT) {
      return <BiText />;
    } else if (elementType === ElementsEnum.TIMESTAMP) {
      return <BiStopwatch />;
    } else if (elementType === ElementsEnum.VIDEO) {
      return <BsClock />;
    } else {
      return <BiVideo />;
    }
  }
  const padLeft = paddingLeft ?? 0;
  return (
    <div
      style={{
        paddingLeft: `${10 + padLeft}px`,
        height: inPoster ? 'auto!important' : undefined,
      }}
      draggable={grouping === GroupingEnum.LOGICAL}
      key={id}
      className={'sequence-title ' + (activeElement === id || selected ? 'active' : '')} // + (activeElement === id ? 'active' : '')}
      onClick={(e) => {
        if (e.metaKey || e.ctrlKey) {
          dispatch(
            addToMultiselect({
              element: {
                type: enumToPath(elementType),
                element: elem as AllElementsDefs,
                parentId,
                mapId,
              },
            }),
          );
        } else {
          dispatch(
            setElement({
              activeElement: id as string,
              activeProp: elementEnumToKey(elementType),
              parentId: parentId === mapId ? '' : parentId,
              mapId,
            }),
          );
          mapId && dispatch(setActiveMap({ mapId }));
        }
        onClick && onClick(e, id as string);
      }}
      onDragEnd={onDragEnd}
      onDragStart={onDragStart}
    >
      <div className="icon-title">
        {enable && (
          <Checkbox
            checked={enabled}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onChange={(checked) => {
              enable(checked.target.checked, item.id, item.elementType);
            }}
            style={{ marginRight: '0.3rem' }}
          />
        )}
        {panelIcon(elementType)}
        <span title={name ?? 'new element'} style={{ marginLeft: '5px', width: 140 - padLeft }}>
          {name ?? 'new element'}
        </span>
      </div>
      {activeElement === id && (
        <div className="flex items-center">
          {cut && (
            <Tooltip content="Cut" style={`dark`}>
              <Button
                title={'Cut'}
                size={'small'}
                className={'hoverState'}
                icon={
                  <BiCut
                    style={{
                      color: 'white',
                      fontSize: 14,
                    }}
                  />
                }
                onClick={() => cut && cut()}
                disabled={!activeElement}
              />
            </Tooltip>
          )}
          {deleteElement && (
            <Tooltip content="Delete" style={`dark`}>
              <Button
                size={'small'}
                icon={
                  <VscTrash
                    style={{
                      color: 'white',
                      fontSize: 14,
                    }}
                  />
                }
                className={'hoverState text-red-500'}
                onClick={() => {
                  versionId && queryClient.cancelQueries(['VIDEO_MULTIMEDIA', versionId]);
                  deleteElement && deleteElement();
                }}
                disabled={!activeElement}
              />
            </Tooltip>
          )}
        </div>
      )}
    </div>
  );
};

export default RegularListElement;
