import { ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { MAX_FULLSCREEN_HEIGHT } from '../../model/constants/constants';
import { PositionControlDef } from '../../model/definitions/PositionControlDef';
import { ActiveDef } from '../../store/slices/active-slice';
import { RootState } from '../../store/store';
import { transformPercentToAbsolute } from './utils';

interface VideoOverWrapperProps {
  positionControl: PositionControlDef;
  id: string;
  children: ReactNode;
}
export const VideoOverWrapper = ({ positionControl, id, children }: VideoOverWrapperProps) => {
  const { activeAspectRatio } = useSelector<RootState, ActiveDef>((state) => state.active);
  /* const translateAbsolute = {
    x: transformPercentToAbsolute(
      positionControl.x,
      activeAspectRatio,
      'width',
      MAX_FULLSCREEN_HEIGHT,
      {
        w: undefined,
        h: undefined,
      },
    ),
    y: transformPercentToAbsolute(
      positionControl.y,
      activeAspectRatio,
      'height',
      MAX_FULLSCREEN_HEIGHT,
      {
        w: undefined,
        h: undefined,
      },
    ),
  }; */
  return (
    <div
      style={{
        zIndex: positionControl?.zindex,
        left: 0,
        top: 0,
        width: transformPercentToAbsolute(
          positionControl?.w,
          activeAspectRatio,
          'width',
          MAX_FULLSCREEN_HEIGHT,
          {
            w: undefined,
            h: undefined,
          },
        ),
        height: transformPercentToAbsolute(
          positionControl?.h,
          activeAspectRatio,
          'height',
          MAX_FULLSCREEN_HEIGHT,
          {
            w: undefined,
            h: undefined,
          },
        ),
        position: 'absolute',
        /* transform: `translate(${translateAbsolute.x}px, ${translateAbsolute.y}px) rotate(${(
          positionControl.rotation || 0
        ).toFixed(4)}deg)`,*/
      }}
    >
      {children}
    </div>
  );
};
