import { FC, useState } from 'react';

import {
  RenderCompressionEnum,
  RenderCompressionNamesEnum,
} from '../../../../../model/enums/RenderCompressionEnum';
import { PropertySection } from '../../components/PropertySection';
import GridItem from '../../shared/GridItem';
import GridWrapper from '../../shared/GridWrapper';

interface OutputVideoCompressionPropertiesProps {
  outputVideoCompression: string;
  onUpdateOutputVideoCompression: (value: string) => void;
}
export const OutputVideoCompressionProperties: FC<OutputVideoCompressionPropertiesProps> = ({
  outputVideoCompression,
  onUpdateOutputVideoCompression,
}) => {
  const [open] = useState<boolean>(false);
  const outputVideoCompressionRenders = () => {
    return (
      <GridWrapper>
        <GridItem
          label={'Video Compression'}
          item={
            <select
              value={outputVideoCompression}
              onChange={(e) => onUpdateOutputVideoCompression(e.target.value)}
              style={{ width: '100%' }}
            >
              {Object.values(RenderCompressionEnum).map((option, index) => (
                <option key={index} value={option}>
                  {RenderCompressionNamesEnum[RenderCompressionEnum[option]]}
                </option>
              ))}
            </select>
          }
        />
      </GridWrapper>
    );
  };
  return (
    <>
      <PropertySection label={'Video Compression'} isOpened={open}>
        <div>{outputVideoCompressionRenders()}</div>
      </PropertySection>
    </>
  );
};
