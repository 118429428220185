import { useMutation } from 'react-query';

import { queryClient } from '../../../index';
import { key as enterprisePaletteQueryKey } from '../../api/palette/useGetPalettes';
import { axiosInstance } from '../axiosInstance';
import { key as paletteQueryKey } from '../mapLayers/useGetPalette';
import { key as isolineQueryKey } from '../mapLayers/useGetVisualisationPalette';

export const useDeletePalette = () =>
  useMutation(
    async (id: string) => {
      await axiosInstance.delete(`color-palette/${id}`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(paletteQueryKey);
        queryClient.invalidateQueries(isolineQueryKey);
        queryClient.invalidateQueries(enterprisePaletteQueryKey);
      },
    },
  );
