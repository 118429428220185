import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { axiosInstance } from '../../../core/api/axiosInstance';

export const useUnpublishFromMarketplace = () => {
  return useMutation(
    async ({
      templateType,
      templateId,
      templateVersionId,
    }: {
      templateType: string;
      templateId: string;
      templateVersionId: string;
    }) => {
      const response = await axiosInstance.post('marketplace/unPublish', {
        templateType,
        templateId,
        templateVersionId,
      });
      return response.data;
    },
    {
      onSuccess: () => {
        toast.success('Unpublished');
      },
      onError: () => {
        toast.error('Error unpublishing');
      },
    },
  );
};
