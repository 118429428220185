import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { axiosInstance } from '../../../core/api/axiosInstance';
import { queryClient } from '../../../index';
import { key as getAccountsKey } from './useGetAccounts';

export const useDeleteAccount = () => {
  return useMutation(
    async ({ api, id }: { api: string; id: string }) => {
      return await axiosInstance.delete(api + '/' + id);
    },
    {
      onSuccess: () => {
        toast.success('Account deleted!');
        queryClient.invalidateQueries(getAccountsKey);
      },
      onError: () => {
        toast.error('Error deleting account!');
      },
    },
  );
};
