import { useMutation } from 'react-query';

import { queryClient } from '../../../index';
import { ColorPaletteDef } from '../../../model/definitions/ColorPaletteDef';
import { axiosInstance } from '../axiosInstance';
import { key as paletteQueryKey } from '../mapLayers/useGetPalette';
import { key as isolineQueryKey } from '../mapLayers/useGetVisualisationPalette';

export const useUpdatePalette = () =>
  useMutation(
    async (palette: ColorPaletteDef): Promise<ColorPaletteDef> => {
      const { data } = await axiosInstance.put('color-palette', palette);
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(paletteQueryKey);
        queryClient.invalidateQueries(isolineQueryKey);
      },
    },
  );
