import { useQuery } from 'react-query';

import { CustomMapDef } from '../../../model/definitions/CustomMapDef';
import { axiosInstance } from '../axiosInstance';

const key = ['GET_CUSTOM_MAPS'];
export const useGetCustomMaps = () =>
  useQuery([...key], async (): Promise<Array<CustomMapDef>> => {
    const { data } = await axiosInstance.get('multimedia/my-repo-preview', {
      params: { type: 'CUSTOM_MAP', page: 0, size: 100, searchKey: '' },
    });
    return data.content;
  });
