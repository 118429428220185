import { QueryKey, useQuery } from 'react-query';

import { axiosInstancePublic } from '../../../core/api/axiosInstancePublic';

export const key: QueryKey = ['MARKETPLACE_PUBLIC_SIMILAR_ITEMS'];

export const usePublicSimilarItems = (id: string, auth: boolean) => {
  return useQuery(
    [...key, id],
    async () => {
      const res = await axiosInstancePublic.get(`marketplace/similar-items/${id}`);
      return res.data;
    },
    {
      enabled: !auth,
    },
  );
};
