import { useQuery } from 'react-query';

import { axiosInstance } from '../axiosInstance';

interface Translation {
  description: string | null;
  dialect: string | null;
  id: string;
  key: string;
  name: string;
  translationFile: any;
}
const key = ['TRANSLATIONS'];
export const useGetAvailableTranslations = () =>
  useQuery([...key], async (): Promise<Array<Translation>> => {
    const { data } = await axiosInstance.get('translations/by-organization');
    return data;
  });
