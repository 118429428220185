import { cloneDeep } from 'lodash';
import { forwardRef, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { AiOutlineInfoCircle, AiOutlinePlus } from 'react-icons/ai';
import { v4 } from 'uuid';

import Button from '../../../atoms/button/Button';
import { WeatherPosterDef } from '../../../model/definitions/WeatherPosterDef';
import { ObservedGeoPoster } from './components/ObservedGeoPoster';

export const ExampleCustomInput = forwardRef(
  ({ value, onClick }: { value: string; onClick: () => void }, ref) => (
    <Button className="example-custom-input" onClick={onClick} label={value} />
  ),
);
ExampleCustomInput.displayName = 'ExampleCustomInput';

interface ObservedGeoPosterProps {
  template: WeatherPosterDef[];
  setData: (data: Array<WeatherPosterDef>) => void;
}
export const ObservedGeoPosterImport = ({ template, setData }: ObservedGeoPosterProps) => {
  const [selected, setSelected] = useState<Date>(new Date(Date.now()));
  const [defs, setDefs] = useState<Array<WeatherPosterDef>>(template);
  const renderDefs = () => {
    return defs.map((poster, index) => (
      <ObservedGeoPoster
        key={poster.id}
        template={poster}
        time={selected.getTime()}
        updatePoster={updatePoster}
      />
    ));
  };

  const updatePoster = (poster: WeatherPosterDef) => {
    const index = defs.findIndex((item) => item.id === poster.id);
    if (index > -1) {
      defs[index].observedWDElements = poster.observedWDElements;
      defs[index].pointLocation = poster.pointLocation;
    }
    setDefs(defs);
  };
  useEffect(() => {
    setData(defs);
  }, [defs]);
  return (
    <>
      <div className={'items-center'}>
        <div className={'flex items-center'}>
          <AiOutlineInfoCircle className={'mr-3'} /> Select date and time of the latest element in
          template.
        </div>
        <div className={'text-red-800 font-bold bg-amber-50 px-2'}>
          If there is no available data, values from nearest date and time will be used!
        </div>
      </div>
      <DatePicker
        selected={selected}
        placeholderText={'Select observed moment'}
        dateFormat={'dd.MM.yyyy HH:mm'}
        dateFormatCalendar={'MMMM yyyy'}
        showPopperArrow={false}
        shouldCloseOnSelect={true}
        timeIntervals={60}
        withPortal={true}
        showTimeSelect
        timeFormat={'HH:mm'}
        onChange={(e) => {
          e && setSelected(e);
        }}
        maxDate={new Date()}
        customInput={
          <ExampleCustomInput
            value={selected.toLocaleDateString()}
            onClick={() => console.log(selected)}
          />
        }
      />
      <div className="stations">{renderDefs()}</div>
      <Button
        style={{ margin: '1rem 0 0 0' }}
        onClick={() => setDefs((prev) => [...prev, { ...cloneDeep(template[0]), id: v4() }])}
        label={'Add Location'}
        icon={<AiOutlinePlus />}
      />
    </>
  );
};
