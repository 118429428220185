const Redo = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.00924 0.497074L8.40134 1.20537L10.3543 3.48093H3.86902C1.74103 3.48093 0 5.53213 0 8.00857C0 10.485 1.74624 12.4971 3.86902 12.4971H10.3174V11.4953H3.86902C2.21047 11.4953 0.859782 9.93802 0.859782 8.00857C0.859782 6.07912 2.21569 4.48273 3.86902 4.48273H10.3543L8.40134 6.75828L9.00924 7.46658L12 3.98183L9.00924 0.497074Z"
        fill="#BCBFCA"
      />
    </svg>
  );
};

export default Redo;
