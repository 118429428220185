import { DatePicker, Form, InputNumber } from 'antd';
import dayjs, { Dayjs } from 'dayjs';

import { ScrapingEngineTypeEnum } from '../../../model/enums/ScrapingEngineTypeEnum';
import Cron from '../atoms/Cron';
import { FormErrors } from '../organisms/Steps';
import { DataProductInterface } from '../pages/Products';

export interface DataFormInterface {
  form: DataProductInterface;
  setForm: React.Dispatch<React.SetStateAction<DataProductInterface>>;
  errors: FormErrors;
}

function DataFilterSetup({ form, setForm, errors }: DataFormInterface) {
  const handleStartDateChange = (date: dayjs.Dayjs | null, dateString: string) => {
    setForm({
      ...form,
      dataFilterSetup: { ...form.dataFilterSetup, dataStartDate: date?.valueOf() },
    });
  };

  const handleEndDateChange = (date: dayjs.Dayjs | null, dateString: string) => {
    setForm({
      ...form,
      dataFilterSetup: { ...form.dataFilterSetup, dataEndDate: date?.valueOf() },
    });
  };

  const handleRetentionTimeChange = (e: number | null) => {
    if (e) {
      setForm({
        ...form,
        dataFilterSetup: { ...form.dataFilterSetup, retentionTime: e },
      });
    }
  };
  const disabledDate = (current: Dayjs | null) => {
    if (!current) return false;
    return current.isBefore(dayjs().startOf('day'));
  };

  return (
    <>
      {form.scrapingEngineType !== ScrapingEngineTypeEnum.WITHOUT_SCRAPING && (
        <>
          <Cron form={form} setForm={setForm} errors={errors} />
          <Form.Item label="Start Date">
            <DatePicker
              style={{ width: '100%' }}
              disabledDate={disabledDate}
              value={dayjs(form.dataFilterSetup.dataStartDate)}
              onChange={(date, dateString) => handleStartDateChange(date, dateString)}
            />
          </Form.Item>
          <Form.Item label="End Date">
            <DatePicker
              style={{ width: '100%' }}
              value={
                form.dataFilterSetup.dataEndDate ? dayjs(form.dataFilterSetup.dataEndDate) : null
              }
              disabledDate={disabledDate}
              onChange={(date, dateString) => handleEndDateChange(date, dateString)}
            />
          </Form.Item>
        </>
      )}
      <Form.Item
        label="Retention time"
        required
        validateStatus={!form.dataFilterSetup.retentionTime && errors.retentionTime ? 'error' : ''}
        help={!form.dataFilterSetup.retentionTime && errors.retentionTime}
      >
        <InputNumber
          name="retentionTime"
          value={form.dataFilterSetup.retentionTime}
          min={0}
          placeholder="Retention in days"
          onChange={(e) => handleRetentionTimeChange(e)}
          className="w-full"
        />
      </Form.Item>
      {/* TODO Now sampling and future limit date not defined - check this again after Milija finish  that on wd console*/}
      {/*<Form.Item label="Time">*/}
      {/*  <div className="flex gap-5">*/}
      {/*    <Form.Item name="samplingTime" className="w-full">*/}
      {/*      <Input*/}
      {/*        name="samplingTime"*/}
      {/*        type="number"*/}
      {/*        placeholder="Sampling time"*/}
      {/*        onChange={(e) => handleTimeChange(e)}*/}
      {/*      />*/}
      {/*    </Form.Item>*/}
      {/*    <Form.Item name="futureLimitTime" className="w-full">*/}
      {/*      <Input*/}
      {/*        name="futureLimitTime"*/}
      {/*        type="number"*/}
      {/*        placeholder="Future limit time"*/}
      {/*        onChange={(e) => handleTimeChange(e)}*/}
      {/*      />*/}
      {/*    </Form.Item>*/}
      {/*  </div>*/}
      {/*</Form.Item>*/}
    </>
  );
}

export default DataFilterSetup;
