import { useQuery, useQueryClient } from 'react-query';

import { ColorPaletteDef } from '../../../model/definitions/ColorPaletteDef';
import { axiosInstance } from '../axiosInstance';

const key = 'PALETTE_ID';
export const usePaletteById = (id?: string) =>
  useQuery(
    [key, id],
    async (): Promise<ColorPaletteDef> => {
      const { data } = await axiosInstance.get(`color-palette/${id}`);
      return data;
    },
    {
      enabled: !!id,
    },
  );

export const useClearPaletteCache = () => {
  const queryClient = useQueryClient();
  return () => {
    queryClient.invalidateQueries(key);
  };
};
