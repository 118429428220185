import { AxiosError } from 'axios';
import { useQuery, UseQueryResult } from 'react-query';

import { axiosInstance } from '../../../core/api/axiosInstance';
import { C9ProjectPreviewDTO } from '../../../model/DTO/C9ProjectPreviewDTO';
import { QueryInfoMetadata } from '../../../model/other/QueryInfoMetadata';

const useGetGeoPosterTemplates = ({
  page,
  size = 19,
  searchKey,
}: {
  page: number;
  size?: number;
  searchKey?: string;
}): UseQueryResult<QueryInfoMetadata<C9ProjectPreviewDTO>, AxiosError> => {
  return useQuery([searchKey], async () => {
    const { data } = await axiosInstance.get(`dashboard/my-weather-geo-poster-templates`, {
      params: { page, size, searchKey },
    });
    return data;
  });
};

export default useGetGeoPosterTemplates;
