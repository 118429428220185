import { useSelector } from 'react-redux';

import { ElementsEnum } from '../../../../core/ui/enums/ElementsEnum';
import { ObservedWDElementDef } from '../../../../model/definitions/ObservedWDElementDef';
import { ActiveDef } from '../../../../store/slices/active-slice';
import { selectActiveObservedLayer } from '../../../../store/slices/selectors';
import { RootState } from '../../../../store/store';
import { Panel } from '../components/Panel';
import { BoxStyle } from '../panels/BoxStyle';
import { FontProperties } from '../panels/FontProperties';
import { PositionControls } from '../panels/PositionControls';
import { TimeControlsPanel } from '../panels/TimeControlsPanel';
import { ObservedSpecificProperties } from './ObservedSpecificProperties';

export const ObservedProperties = () => {
  const { activePoster, activeMap } = useSelector<RootState, ActiveDef>((state) => state.active);
  const observedLayer = useSelector<RootState, ObservedWDElementDef | undefined>((state) =>
    selectActiveObservedLayer(state),
  );
  const {
    timeControls,
    positionControl,
    observedWDSource,
    boxDef,
    name,
    description,
    displayUnitOfMeasurement,
  } = observedLayer ? observedLayer : new ObservedWDElementDef();

  return (
    <Panel>
      {observedLayer && (
        <>
          <ObservedSpecificProperties
            layer={observedLayer}
            source={observedWDSource}
            name={name}
            description={description}
            displayUnitOfMeasurement={displayUnitOfMeasurement}
            mapId={activeMap}
          />
          {!activePoster && (
            <>
              <PositionControls
                position={positionControl}
                layer={'observedWDElements'}
                mapId={activeMap}
              />
              <TimeControlsPanel
                timeControls={timeControls}
                layer={ElementsEnum.OBSERVED_WD}
                layerType={'observedDataLayers'}
                mapId={activeMap}
              />
            </>
          )}
          <FontProperties
            layer={observedLayer}
            layerType={'observedWDElements'}
            poster={activePoster}
            mapId={activeMap}
          />
          <BoxStyle box={boxDef} elementType={ElementsEnum.OBSERVED_WD} mapId={activeMap} />
        </>
      )}
    </Panel>
  );
};
