import { Badge } from 'flowbite-react';
import { AiOutlineEdit } from 'react-icons/ai';
import { IoDuplicateOutline } from 'react-icons/io5';
import { RiDeleteBin5Line } from 'react-icons/ri';

import { useDeletePalette } from '../../core/api/palette/useDeletePalette';
import { ColorPaletteDef } from '../../model/definitions/ColorPaletteDef';
import { getRgba } from './utils';

interface IsolinePalettePreviewProps {
  palette: ColorPaletteDef;
  selected: ColorPaletteDef | null;
  setPalette: (palette: ColorPaletteDef) => void;
  editPalette?: (e: string) => void;
  showMinMax: boolean;
  clonePalette: (palette: ColorPaletteDef) => void;
}
export const IsolinePalettePreview = ({
  palette,
  selected,
  setPalette,
  editPalette,
  showMinMax,
  clonePalette,
}: IsolinePalettePreviewProps) => {
  const { mutate: deletePalette, isLoading: isLoadingDelete } = useDeletePalette();
  const uniqueValues = [...new Set(Object.values(palette.colorStops.pallet))];
  const min = Math.min(...Object.keys(palette.colorStops?.pallet).map((key) => Number(key)));
  const max = Math.max(...Object.keys(palette.colorStops?.pallet).map((key) => Number(key)));
  return (
    <div
      className={` ${
        palette?.id === selected?.id ? 'PaletteIsoline__item-selected' : ''
      } cursor-pointer mb-6 p-6`}
      onClick={() => setPalette(palette)}
    >
      <div className={`flex items-center mb-2 justify-between`}>
        <div className="flex mr-4">
          <p className="mr-4">{palette.name}</p>
          {showMinMax && (
            <div className="flex gap-1">
              <Badge>{min}</Badge> - <Badge>{max}</Badge>
            </div>
          )}
        </div>
        <div className="flex">
          {palette?.editableByUser && (
            <>
              <div
                onClick={() => !isLoadingDelete && deletePalette(palette.id)}
                className={'mr-2 cursor-pointer'}
              >
                <RiDeleteBin5Line color={'red'} />
              </div>
              <div onClick={() => clonePalette(palette)} className={'mr-2 cursor-pointer'}>
                <IoDuplicateOutline color={'green'} />
              </div>
            </>
          )}

          <div onClick={() => editPalette && editPalette(palette.id)} className={'cursor-pointer'}>
            <AiOutlineEdit color={'yellow'} />
          </div>
        </div>
      </div>

      <div className={'flex w-full'}>
        {uniqueValues.map((stk) => (
          <div
            key={stk}
            className="flex w-full"
            style={{
              height: 5,
              margin: 'auto',
              minHeight: '100%',
            }}
          >
            <div
              style={{
                margin: 'auto',
                minHeight: '100%',
                backgroundColor: getRgba(stk),
              }}
              className="w-full h-[100%]"
            />
          </div>
        ))}
      </div>
    </div>
  );
};
