import moment from 'moment-timezone';

import { ForecastValueDef } from '../../../model/definitions/ForecastPropertiesDef';

interface DayCardProps {
  day: ForecastValueDef;
  onClick: (e: string) => void;
}
export const DayCard = ({ day, onClick }: DayCardProps) => {
  const date = new Date(day.utcDate);
  return (
    <div className={'property-card'} onClick={() => onClick(day.utcDate)}>
      <div>{new Intl.DateTimeFormat('en-US', { month: 'long' }).format(date)}</div>
      <div className={'text-sm! font-light'} style={{ fontSize: '0.7rem' }}>
        local: <br />
        {moment(day.localDate).format('DD.MMM.yyyy HH:mm')}
      </div>
      <div className={'prop-observed-val'}>{date.getDate()}</div>
      <div>{date.getUTCFullYear()}</div>
    </div>
  );
};
