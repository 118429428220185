const timezoneOffsetMap = {
  'GMT+0:00': 'UTC',
  'GMT+0:30': 'UTC+00:30',
  'GMT+1:00': 'CET',
  'GMT+1:30': 'UTC+01:30',
  'GMT+2:00': 'CEST',
  'GMT+2:30': 'UTC+02:30',
  'GMT+3:00': 'MSK',
  'GMT+3:30': 'IRST',
  'GMT+4:00': 'GST',
  'GMT+4:30': 'AFT',
  'GMT+5:00': 'PKT',
  'GMT+5:30': 'IST',
  'GMT+5:45': 'NPT',
  'GMT+6:00': 'BST',
  'GMT+6:30': 'MMT',
  'GMT+7:00': 'ICT',
  'GMT+7:30': 'UTC+07:30',
  'GMT+8:00': 'CST',
  'GMT+8:30': 'UTC+08:30',
  'GMT+8:45': 'ACWST',
  'GMT+9:00': 'JST',
  'GMT+9:30': 'ACST',
  'GMT+10:00': 'AEST',
  'GMT+10:30': 'LHST',
  'GMT+11:00': 'SBT',
  'GMT+11:30': 'UTC+11:30',
  'GMT+12:00': 'NZST',
  'GMT+12:45': 'CHAST',
  'GMT+13:00': 'UTC+13:00',
  'GMT+13:45': 'UTC+13:45',
  'GMT+14:00': 'UTC+14:00',
  'GMT-0:30': 'UTC-00:30',
  'GMT-1:00': 'AZOT',
  'GMT-1:30': 'UTC-01:30',
  'GMT-2:00': 'GST',
  'GMT-2:30': 'UTC-02:30',
  'GMT-3:00': 'BRT',
  'GMT-3:30': 'NST',
  'GMT-4:00': 'AST',
  'GMT-4:30': 'VET',
  'GMT-5:00': 'EST',
  'GMT-5:30': 'UTC-05:30',
  'GMT-6:00': 'CST',
  'GMT-6:30': 'UTC-06:30',
  'GMT-7:00': 'MST',
  'GMT-7:30': 'UTC-07:30',
  'GMT-8:00': 'PST',
  'GMT-8:30': 'UTC-08:30',
  'GMT-9:00': 'AKST',
  'GMT-9:30': 'MART',
  'GMT-10:00': 'HST',
  'GMT-10:30': 'UTC-10:30',
  'GMT-11:00': 'NUT',
  'GMT-11:30': 'UTC-11:30',
  'GMT-12:00': 'AoE',
};
const TimezoneOffsets: { [key: string]: string } = {
  '+00:00': 'UTC',
  '+00:30': 'UTC+00:30',
  '+01:00': 'CET',
  '+01:30': 'UTC+01:30',
  '+02:00': 'CEST',
  '+02:30': 'UTC+02:30',
  '+03:00': 'MSK',
  '+03:30': 'IRST',
  '+04:00': 'GST',
  '+04:30': 'AFT',
  '+05:00': 'PKT',
  '+05:30': 'IST',
  '+05:45': 'NPT',
  '+06:00': 'BST',
  '+06:30': 'MMT',
  '+07:00': 'ICT',
  '+07:30': 'UTC+07:30',
  '+08:00': 'CST',
  '+08:30': 'UTC+08:30',
  '+08:45': 'ACWST',
  '+09:00': 'JST',
  '+09:30': 'ACST',
  '+10:00': 'AEST',
  '+10:30': 'LHST',
  '+11:00': 'SBT',
  '+11:30': 'UTC+11:30',
  '+12:00': 'NZST',
  '+12:45': 'CHAST',
  '+13:00': 'UTC+13:00',
  '+13:45': 'UTC+13:45',
  '+14:00': 'UTC+14:00',
  '-00:30': 'UTC-00:30',
  '-01:00': 'AZOT',
  '-01:30': 'UTC-01:30',
  '-02:00': 'GST',
  '-02:30': 'UTC-02:30',
  '-03:00': 'BRT',
  '-03:30': 'NST',
  '-04:00': 'AST',
  '-04:30': 'VET',
  '-05:00': 'EST',
  '-05:30': 'UTC-05:30',
  '-06:00': 'CST',
  '-06:30': 'UTC-06:30',
  '-07:00': 'MST',
  '-07:30': 'UTC-07:30',
  '-08:00': 'PST',
  '-08:30': 'UTC-08:30',
  '-09:00': 'AKST',
  '-09:30': 'MART',
  '-10:00': 'HST',
  '-10:30': 'UTC-10:30',
  '-11:00': 'NUT',
  '-11:30': 'UTC-11:30',
  '-12:00': 'AoE',
};

export { timezoneOffsetMap, TimezoneOffsets };
