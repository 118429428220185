import { Select, ToggleSwitch } from 'flowbite-react';
import { cloneDeep, set } from 'lodash';
import React, { useEffect, useState } from 'react';
import { AiOutlineCaretDown, AiOutlineCaretUp } from 'react-icons/ai';
import { GrRevert } from 'react-icons/gr';
import { MdDeleteOutline } from 'react-icons/md';

import Button from '../../../atoms/button/Button';
// import ColorPickerRefactor from '../../../atoms/color-picker-refactor/ColorPickerRefactor';
import Input from '../../../atoms/input/Input';
import { useFontLoader, useFontSetter } from '../../../core/api/useLoadFont';
import {
  DEFAULT_FONT_FAMILY,
  DEFAULT_FONT_TYPE,
  DEFAULT_FONT_VARIANT_ID,
  MAX_ZINDEX_VALUE,
} from '../../../model/constants/constants';
import { CityGeoPosterDef } from '../../../model/definitions/CityGeoPosterDef';
import { MapPanelDef } from '../../../model/definitions/MapPanelDef';
import Modal from '../../../molecules/modal/Modal';
import InputNumber from '../../marketplace-new/atoms/FormatNumber/FormatNumber';
import transformText from '../properties/components/slatejs/transformText';
import { PaletteColorPicker } from '../properties/mapLayersProperties/PalettecolorPicker';
import { FontInterface, FontVariantInterface } from '../properties/panels/FontProperties';
import GridItem from '../properties/shared/GridItem';
import GridWrapper from '../properties/shared/GridWrapper';
import styles from './AddCitiesModal.module.scss';
import { CitiesImageInput } from './components/CitiesImageInput';
import FontSelector from './components/FontSelector';

interface Props {
  opened: boolean;
  onClose: () => void;
  onCitiesSelect: (cities: CityGeoPosterDef[]) => void;
  mapDef: MapPanelDef;
  fonts: FontInterface[];
}

function getInitialOpened(posters: CityGeoPosterDef[]) {
  const initOpen: Record<string, boolean> = {};
  posters.forEach((p) => {
    initOpen[p.id] = false;
  });
  return initOpen;
}

const mostFrequentVal = (arr: any[], nestedProperty: string) => {
  const valueCounts: Record<string, number> = {};

  const processNestedProperty = (obj: any, properties: string[]) => {
    let value = obj;
    for (const prop of properties) {
      value = value[prop];
    }
    return value;
  };

  arr.forEach((obj) => {
    const properties = nestedProperty.split('.');
    let value = obj;

    for (let i = 0; i < properties.length; i++) {
      if (Array.isArray(value)) {
        const tempArray: any[] = [];
        value.forEach((item) => {
          const nestedValue = processNestedProperty(item, properties.slice(i));
          if (nestedValue !== undefined) {
            if (Array.isArray(nestedValue)) {
              tempArray.push(...nestedValue);
            } else {
              tempArray.push(nestedValue);
            }
          }
        });
        value = tempArray;
        break;
      } else {
        value = processNestedProperty(value, [properties[i]]);
      }
    }

    if (valueCounts[value]) {
      valueCounts[value]++;
    } else {
      valueCounts[value] = 1;
    }
  });

  let mostFrequentValue = null;
  let maxCount = 0;

  for (const value in valueCounts) {
    if (valueCounts[value] > maxCount) {
      maxCount = valueCounts[value];
      mostFrequentValue = value;
    }
  }

  return mostFrequentValue;
};

const defaultCityStyle = {
  fontSize: 3.5,
  fontFamily: DEFAULT_FONT_FAMILY,
  fontType: DEFAULT_FONT_TYPE,
  fontVariantId: DEFAULT_FONT_VARIANT_ID,
  fontColor: '#fff',
  fontAlignment: 'left',
  shape: 'circle',
  size: 1.2, //radius/height
  fillColor: 'rgba(80, 10, 10, 255)',
  borderColor: 'rgba(255, 255, 255, 255)',
  borderWidth: 3,
  zindex: 20,
  enable: true,
  strokeWidth: 0,
  strokeColor: 'rgba(50, 10, 10, 255)',
  textTransform: 'none' as
    | 'none'
    | 'capitalize'
    | 'uppercase'
    | 'lowercase'
    | 'full-width'
    | 'full-size-kana',
};

const EditCitiesModal: React.FC<Props> = ({ opened, onClose, onCitiesSelect, mapDef, fonts }) => {
  const [defaultStyle, setDefaultStyle] = useState<typeof defaultCityStyle>(
    mapDef.cityPosters &&
      mapDef.cityPosters.length &&
      mapDef.cityPosters[0].properties?.defaultStyle
      ? JSON.parse(mapDef.cityPosters[0].properties?.defaultStyle)
      : defaultCityStyle,
  );
  const [defaultShape, setDefaultShape] = useState(
    mostFrequentVal(mapDef.cityPosters, 'anchor.shape'),
  );
  const [defaultSize] = useState(mostFrequentVal(mapDef.cityPosters, 'anchor.size'));
  const [defaultFill] = useState(mostFrequentVal(mapDef.cityPosters, 'anchor.fillColor'));
  const [defaultBorder] = useState(mostFrequentVal(mapDef.cityPosters, 'anchor.borderColor'));
  const [defaultBorderWidth] = useState(mostFrequentVal(mapDef.cityPosters, 'anchor.borderWidth'));
  const [defaultLevel] = useState(mostFrequentVal(mapDef.cityPosters, 'positionControl.zindex'));
  const [defaultFontSize] = useState(mostFrequentVal(mapDef.cityPosters, 'textPanels.fontSize'));
  const [defaultFontFamily] = useState(
    mostFrequentVal(mapDef.cityPosters, 'textPanels.fontFamily'),
  );
  const [defaultFontType] = useState(mostFrequentVal(mapDef.cityPosters, 'textPanels.fontType'));
  const [defaultFontColor] = useState(mostFrequentVal(mapDef.cityPosters, 'textPanels.fontColor'));
  const [defaultTextTransform] = useState(
    mostFrequentVal(mapDef.cityPosters, 'textPanels.textTransform'),
  );
  const [defaultAlign] = useState(mostFrequentVal(mapDef.cityPosters, 'textPanels.fontAlignment'));
  const [defaultStrokeWidth] = useState(
    mostFrequentVal(mapDef.cityPosters, 'textPanels.strokeWidth'),
  );
  const [defaultStrokeColor] = useState(
    mostFrequentVal(mapDef.cityPosters, 'textPanels.strokeColor'),
  );

  const { fontVariants, fetchFontVariants, fontId } = useFontLoader(defaultStyle.fontFamily);

  useFontSetter(
    fontVariants,
    defaultStyle.fontType,
    fontTypeSetter,
    fontVariantIdSetter,
    fetchFontVariants.isLoading,
  );

  function fontTypeSetter(value: string) {
    //@ts-ignore
    onDefaultChange('fontType', 'textPanels[0].fontType', value);
  }

  function fontVariantIdSetter(value: string) {
    //@ts-ignore
    onDefaultChange('fontVariantId', 'textPanels[0].fontVariantId', value);
  }

  const [selectedCities, setSelectedCities] = useState<CityGeoPosterDef[]>(
    mapDef.cityPosters || [],
  );
  const [openedC, setOpenedC] = useState<Record<string, boolean>>(
    getInitialOpened(mapDef.cityPosters),
  );
  const [defaultStylesOpen, setDefaultStylesOpen] = useState(false);
  const [bulkToggle, setBulkToggle] = useState(getBulkToggleState());
  function getBulkToggleState() {
    let enabled = true;
    selectedCities.forEach((c) => {
      if (!c.anchor.enable) {
        enabled = false;
      }
    });
    return enabled;
  }
  useEffect(() => {
    setBulkToggle(getBulkToggleState());
  }, [selectedCities]);

  function onCityChange(cityId: number | string, prop: Paths<CityGeoPosterDef>, newValue: any) {
    setSelectedCities((cities) => {
      const copied = cloneDeep(cities);
      copied.forEach((c) => {
        if (c.id === cityId) set(c, prop, newValue);
      });
      return copied;
    });
  }

  function onCityNameChange(cityId: number | string, newValue: string) {
    setSelectedCities((cities) => {
      const copied = cloneDeep(cities);
      copied.forEach((c, i) => {
        if (c.id === cityId) {
          copied[i].textPanels[0].value = newValue;
        }
      });
      return copied;
    });
  }

  function onDelete(city: CityGeoPosterDef) {
    const cities = selectedCities.filter((c) => c.id !== city.id);
    setSelectedCities(cities);
  }

  function onDefaultChange(
    prop: keyof typeof defaultStyle,
    cityProp: Paths<CityGeoPosterDef>,
    newValue: any,
  ) {
    setDefaultStyle((s) => ({ ...s, [prop]: newValue }));
    if (prop === 'shape') setDefaultShape(newValue);
    setSelectedCities((cities) => {
      const copied = cloneDeep(cities);
      copied.forEach((c) => {
        set(c, cityProp, newValue);
        const currentDefault = JSON.parse(c.properties.defaultStyle);
        set(currentDefault, prop, newValue);
        c.properties.defaultStyle = JSON.stringify(currentDefault);
      });
      return copied;
    });
  }

  function onResetPosition(c: CityGeoPosterDef) {
    if (c.anchor?.anchorOffsetX || c.anchor.anchorOffsetY) {
      const cities: CityGeoPosterDef[] = selectedCities.map((sc) => {
        if (sc.id === c.id) {
          return {
            ...sc,
            anchor: {
              ...sc.anchor,
              longitude: sc.anchor.anchorOffsetX,
              latitude: sc.anchor.anchorOffsetY,
            },
          };
        }
        return { ...sc };
      });
      setSelectedCities(cities);
    }
  }

  function isRevertDisabled(c: CityGeoPosterDef) {
    if (!c.anchor?.anchorOffsetX && !c.anchor.anchorOffsetY) return true;
    return (
      c.anchor.longitude == c.anchor.anchorOffsetX && c.anchor.latitude == c.anchor.anchorOffsetY
    );
  }

  function onOk() {
    onCitiesSelect(selectedCities);
  }

  return (
    <Modal
      isOpen={opened}
      onClose={onClose}
      bodyClassName={'SendAssemblyModal__modal-body add-city-modal'}
      className={'AddCitiesModal__modal-wrap'}
      header={'Edit cities labels'}
      footer={
        <div className={'modal-footer flex'}>
          <Button label={'Save'} buttonType="primary" onClick={onOk} />
          <Button label={'Cancel'} buttonType="secondary" onClick={onClose} />
        </div>
      }
    >
      <div className="min-w-[890px] properties__tabs_wrapper add-cities-modal-wrapper">
        <div className="max-h-[400px] overflow-y-auto">
          <div className="mt-6">
            {selectedCities.map((c) => (
              <div key={c.id} className={styles.wrapper}>
                <div
                  className={`mb-2 subheader `}
                  onClick={() => setOpenedC((o) => ({ ...o, [c.id]: !o[c.id] }))}
                  style={{
                    fontWeight: 500,
                    fontSize: '15px',
                  }}
                >
                  {openedC[c.id] ? <AiOutlineCaretUp /> : <AiOutlineCaretDown />}
                  <div
                    style={{
                      width: '150px',
                      textAlign: 'left',
                    }}
                  >
                    <div className={'text-left'}>Label:</div>{' '}
                    <b style={{ color: '#4289fb' }}>{c.textPanels[0].value}</b>
                  </div>
                  <div
                    style={{
                      width: '150px',
                      minWidth: '150px',
                      maxWidth: '250px',
                      textAlign: 'left',
                    }}
                  >
                    <div className={'text-left'}>City:</div>
                    <b style={{ color: '#4289fb' }}>{c.name.split('-')[0]}</b>
                  </div>
                  <div className="ml-8 text-left">
                    <div className={'text-left'}>Country:</div>{' '}
                    <b style={{ color: '#4289fb' }}>{c.countryName}</b>
                  </div>
                  <Button
                    buttonType="link"
                    onClick={() => onDelete(c)}
                    style={{ marginLeft: 'auto' }}
                    icon={<MdDeleteOutline style={{ color: '#fa5656', fontSize: '15px' }} />}
                  />
                </div>
                {openedC[c.id] && (
                  <div>
                    <div
                      className={`mb-2 subheader  `}
                      style={{
                        paddingLeft: '30px',
                        fontWeight: 400,
                      }}
                    >
                      Information
                    </div>
                    <div className="prop-wrapper">
                      <GridWrapper>
                        <GridItem
                          noBorderBg
                          label="Name:"
                          item={
                            <Input
                              style={{ padding: '0' }}
                              type={'text'}
                              value={c.textPanels[0].value}
                              className={styles.inputWrap}
                              onChange={(e) => onCityNameChange(c.id, e.target.value)}
                            />
                          }
                        />
                      </GridWrapper>
                    </div>
                    <div
                      className={`mb-2 subheader  `}
                      style={{
                        paddingLeft: '30px',
                        fontWeight: 400,
                      }}
                    >
                      Anchor styles
                    </div>
                    <div className="mt-4">
                      <div className="prop-wrapper">
                        <GridWrapper>
                          <GridItem
                            noBorderBg
                            label="Show Anchor:"
                            item={
                              <ToggleSwitch
                                checked={c.anchor.enable}
                                label={''}
                                onChange={(val) => onCityChange(c.id, 'anchor.enable', val)}
                              />
                            }
                          />
                          <GridItem
                            noBorderBg
                            label="Reset position:"
                            item={
                              <button
                                disabled={isRevertDisabled(c)}
                                className={`${styles['revert-btn']} flex items-center`}
                                onClick={() => onResetPosition(c)}
                              >
                                <GrRevert />
                              </button>
                            }
                          />
                          <GridItem
                            label="Anchor type:"
                            noBorderBg
                            item={
                              <Select
                                className={styles.select}
                                onChange={(e) => onCityChange(c.id, 'anchor.type', e.target.value)}
                                value={c.anchor.type}
                              >
                                {['shape', 'image'].map((k) => (
                                  <option key={k} value={k}>
                                    {k}
                                  </option>
                                ))}
                              </Select>
                            }
                          />
                          {c.anchor.type == 'shape' ? (
                            <>
                              <GridItem
                                noBorderBg
                                label="Shape:"
                                item={
                                  <Select
                                    className={styles.select}
                                    onChange={(e) =>
                                      onCityChange(c.id, 'anchor.shape', e.target.value)
                                    }
                                    value={c.anchor.shape}
                                  >
                                    {['circle', 'square', 'cross'].map((k) => (
                                      <option key={k} value={k}>
                                        {k}
                                      </option>
                                    ))}
                                  </Select>
                                }
                              />
                              <GridItem
                                noBorderBg
                                label={'Size:'}
                                item={
                                  <Input
                                    style={{ padding: '0' }}
                                    type={'number'}
                                    onChange={(e) =>
                                      onCityChange(c.id, 'anchor.size', e.target.value)
                                    }
                                    value={c.anchor.size}
                                    className={styles.inputWrap}
                                  />
                                }
                              />

                              <GridItem
                                noBorderBg
                                label={'Fill color:'}
                                item={
                                  <PaletteColorPicker
                                    value={c.anchor.fillColor}
                                    onChange={(e) => onCityChange(c.id, 'anchor.fillColor', e)}
                                  />
                                }
                              />

                              <GridItem
                                noBorderBg
                                label={'Border color:'}
                                item={
                                  <PaletteColorPicker
                                    value={c.anchor.borderColor}
                                    onChange={(e) => onCityChange(c.id, 'anchor.borderColor', e)}
                                  />
                                }
                              />
                              <GridItem
                                noBorderBg
                                label={'Border width:'}
                                item={
                                  <Input
                                    style={{ padding: '0' }}
                                    type={'number'}
                                    value={c.anchor.borderWidth}
                                    className={styles.inputWrap}
                                    onChange={(e) =>
                                      onCityChange(c.id, 'anchor.borderWidth', e.target.value)
                                    }
                                  />
                                }
                              />
                            </>
                          ) : null}

                          {c.anchor.type == 'image' ? (
                            <>
                              <GridItem
                                itemStyle={{ height: 'auto' }}
                                label={'Image:'}
                                noBorderBg
                                item={
                                  <CitiesImageInput
                                    value={c.anchor.imageURL}
                                    onChange={(e) => onCityChange(c.id, 'anchor.imageURL', e)}
                                  />
                                }
                              />
                              {c.anchor.imageURL && (
                                <>
                                  <GridItem
                                    label={'Width:'}
                                    noBorderBg
                                    item={
                                      <Input
                                        style={{ padding: '0' }}
                                        type={'number'}
                                        onChange={(e) =>
                                          onCityChange(c.id, 'anchor.width', e.target.value)
                                        }
                                        value={c.anchor.width}
                                        className={styles.inputWrap}
                                      />
                                    }
                                  />
                                  <GridItem
                                    label={'Height:'}
                                    noBorderBg
                                    item={
                                      <Input
                                        style={{ padding: '0' }}
                                        type={'number'}
                                        onChange={(e) =>
                                          onCityChange(c.id, 'anchor.height', e.target.value)
                                        }
                                        value={c.anchor.height}
                                        className={styles.inputWrap}
                                      />
                                    }
                                  />
                                </>
                              )}
                            </>
                          ) : null}
                        </GridWrapper>
                      </div>
                    </div>
                    <div
                      className={`mb-2 subheader  `}
                      style={{
                        paddingLeft: '30px',
                        fontWeight: 400,
                      }}
                    >
                      Label styles
                    </div>
                    <div className="mt-4">
                      <div className="prop-wrapper">
                        <GridWrapper>
                          <GridItem
                            noBorderBg
                            label={'Font size:'}
                            item={
                              <Input
                                style={{ padding: '0' }}
                                type={'number'}
                                value={c.textPanels[0].fontSize}
                                onChange={(e) =>
                                  // @ts-ignore
                                  onCityChange(c.id, 'textPanels[0].fontSize', e.target.value)
                                }
                                className={styles.inputWrap}
                              />
                            }
                          />
                          <FontSelector
                            onCityChange={onCityChange}
                            fonts={fonts}
                            textPanel={c.textPanels[0]}
                            id={c.id}
                            defaultFontId={fontId}
                          />

                          <GridItem
                            noBorderBg
                            label={'Font color:'}
                            item={
                              <PaletteColorPicker
                                value={c.textPanels[0].fontColor}
                                // @ts-ignore
                                onChange={(e) => onCityChange(c.id, 'textPanels[0].fontColor', e)}
                              />
                            }
                          />

                          <GridItem
                            noBorderBg
                            label="Label align:"
                            item={
                              <Select
                                className={styles.select}
                                value={c.textPanels[0].fontAlignment}
                                onChange={(e) =>
                                  // @ts-ignore
                                  onCityChange(c.id, 'textPanels[0].fontAlignment', e.target.value)
                                }
                              >
                                {['left', 'right', 'center'].map((k) => (
                                  <option key={k} value={k}>
                                    {k}
                                  </option>
                                ))}
                              </Select>
                            }
                          />

                          <GridItem
                            noBorderBg
                            label={'Stroke width:'}
                            item={
                              <Input
                                style={{ padding: '0' }}
                                type={'number'}
                                value={c.textPanels[0].strokeWidth ?? 0}
                                onChange={(e) =>
                                  onCityChange(
                                    c.id,
                                    // @ts-ignore
                                    'textPanels[0].strokeWidth',
                                    Number(e.target.value) >= 0 ? e.target.value : 0,
                                  )
                                }
                                className={styles.inputWrap}
                              />
                            }
                          />

                          <GridItem
                            noBorderBg
                            label={'Stroke color:'}
                            item={
                              <PaletteColorPicker
                                value={c.textPanels[0].strokeColor ?? 'rgba(255, 255, 255, 255)'}
                                onChange={(e) =>
                                  // @ts-ignore
                                  onCityChange(c.id, 'textPanels[0].strokeColor', e)
                                }
                              />
                            }
                          />
                        </GridWrapper>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className={styles.wrapper}>
            <div
              className={`mb-2 subheader`}
              onClick={() => setDefaultStylesOpen((o) => !o)}
              style={{
                fontWeight: 500,
                fontSize: '15px',
              }}
            >
              {defaultStylesOpen ? <AiOutlineCaretUp /> : <AiOutlineCaretDown />}
              Bulk edit
            </div>
            {defaultStylesOpen && (
              <div>
                <div
                  className={`mb-2 subheader  `}
                  style={{
                    paddingLeft: '30px',
                    fontWeight: 400,
                  }}
                >
                  Anchor styles
                </div>
                <div className="mt-4">
                  <div className="prop-wrapper">
                    <GridWrapper>
                      <GridItem
                        noBorderBg
                        label="Show Anchor:"
                        item={
                          <ToggleSwitch
                            checked={bulkToggle}
                            label={''}
                            onChange={() => {
                              onDefaultChange('enable', 'anchor.enable', !bulkToggle);
                              setBulkToggle(!bulkToggle);
                            }}
                          />
                        }
                      />
                      <GridItem noBorderBg label="" item={<></>} />
                      <GridItem
                        noBorderBg
                        label="Shape:"
                        item={
                          <Select
                            className={styles.select}
                            onChange={(e) =>
                              onDefaultChange('shape', 'anchor.shape', e.target.value)
                            }
                            value={defaultShape ?? defaultStyle.shape}
                          >
                            {['circle', 'square', 'cross'].map((k) => (
                              <option key={k} value={k}>
                                {k}
                              </option>
                            ))}
                          </Select>
                        }
                      />
                      <GridItem
                        noBorderBg
                        label={'Size:'}
                        item={
                          <Input
                            style={{ padding: '0' }}
                            type={'number'}
                            onChange={(e) => onDefaultChange('size', 'anchor.size', e.target.value)}
                            defaultValue={defaultSize ?? defaultStyle.size}
                            className={styles.inputWrap}
                          />
                        }
                      />

                      <GridItem
                        noBorderBg
                        label={'Fill color:'}
                        item={
                          <PaletteColorPicker
                            value={defaultFill ?? defaultStyle.fillColor}
                            onChange={(e) => onDefaultChange('fillColor', 'anchor.fillColor', e)}
                          />
                        }
                      />

                      <GridItem
                        noBorderBg
                        label={'Border color:'}
                        item={
                          <PaletteColorPicker
                            value={defaultBorder ?? defaultStyle.borderColor}
                            onChange={(e) =>
                              onDefaultChange('borderColor', 'anchor.borderColor', e)
                            }
                          />
                        }
                      />
                      <GridItem
                        noBorderBg
                        label={'Border width:'}
                        item={
                          <Input
                            style={{ padding: '0' }}
                            type={'number'}
                            defaultValue={defaultBorderWidth ?? defaultStyle.borderWidth}
                            className={styles.inputWrap}
                            onChange={(e) =>
                              onDefaultChange('borderWidth', 'anchor.borderWidth', e.target.value)
                            }
                          />
                        }
                      />
                      <GridItem
                        noBorderBg
                        label={'Layer level:'}
                        item={
                          <InputNumber
                            style={{ padding: '0' }}
                            step={1}
                            min={0}
                            max={MAX_ZINDEX_VALUE}
                            defaultValue={defaultLevel ?? defaultStyle.zindex}
                            className={styles.inputWrap}
                            onInputChange={(e) =>
                              onDefaultChange('zindex', 'positionControl.zindex', e)
                            }
                          />
                        }
                      />
                    </GridWrapper>
                  </div>
                </div>
                <div
                  className={`mb-2 subheader  `}
                  style={{
                    paddingLeft: '30px',
                    fontWeight: 400,
                  }}
                >
                  Label styles
                </div>
                <div className="mt-4">
                  <div className="prop-wrapper">
                    <GridWrapper>
                      <GridItem
                        noBorderBg
                        label={'Font size:'}
                        item={
                          <Input
                            style={{ padding: '0' }}
                            type={'number'}
                            defaultValue={defaultFontSize ?? defaultStyle.fontSize}
                            onChange={(e) =>
                              // @ts-ignore
                              onDefaultChange('fontSize', 'textPanels[0].fontSize', e.target.value)
                            }
                            className={styles.inputWrap}
                          />
                        }
                      />
                      <GridItem
                        noBorderBg
                        label="Font family:"
                        item={
                          <Select
                            className={styles.select}
                            defaultValue={defaultFontFamily ?? defaultStyle.fontFamily}
                            onChange={(e) => {
                              onDefaultChange(
                                'fontFamily',
                                // @ts-ignore
                                'textPanels[0].fontFamily',
                                e.target.value,
                              );
                              onDefaultChange(
                                'fontType',
                                // @ts-ignore
                                'textPanels[0].fontType',
                                '',
                              );
                              onDefaultChange(
                                'fontVariantId',
                                // @ts-ignore
                                'textPanels[0].fontVariantId',
                                '',
                              );
                            }}
                          >
                            {fonts?.map((font: FontInterface) => (
                              <option
                                key={font.id}
                                value={font.name.split('(')[0]}
                                style={{ fontFamily: font.name.split('(')[0] }}
                              >
                                {font.name}
                              </option>
                            ))}
                          </Select>
                        }
                      />

                      <GridItem
                        noBorderBg
                        label="Font type:"
                        item={
                          <Select
                            className={styles.select}
                            defaultValue={defaultFontType ?? defaultStyle.fontType}
                            onChange={(e) => {
                              const findType = fontVariants.find(
                                (fontVariant: FontVariantInterface) =>
                                  fontVariant.type === e.target.value,
                              );
                              // @ts-ignore
                              onDefaultChange(
                                'fontType',
                                // @ts-ignore
                                'textPanels[0].fontType',
                                e.target.value,
                              );
                              // @ts-ignore
                              onDefaultChange(
                                'fontVariantId',
                                // @ts-ignore
                                'textPanels[0].fontVariantId',
                                findType?.id,
                              );
                            }}
                          >
                            {fontVariants?.map((fontVariant: FontVariantInterface) => (
                              <option key={fontVariant.id} value={fontVariant.type}>
                                {fontVariant.type}
                              </option>
                            ))}
                          </Select>
                        }
                      />

                      <GridItem
                        noBorderBg
                        label={'Font color:'}
                        item={
                          <PaletteColorPicker
                            value={defaultFontColor ?? defaultStyle.fontColor}
                            onChange={(e) =>
                              // @ts-ignore
                              onDefaultChange('fontColor', 'textPanels[0].fontColor', e)
                            }
                          />
                        }
                      />

                      <GridItem
                        noBorderBg
                        label="Label align:"
                        item={
                          <Select
                            className={styles.select}
                            defaultValue={defaultAlign ?? defaultStyle.fontAlignment}
                            onChange={(e) =>
                              onDefaultChange(
                                'fontAlignment',
                                // @ts-ignore
                                'textPanels[0].fontAlignment',
                                e.target.value,
                              )
                            }
                          >
                            {['left', 'right', 'center'].map((k) => (
                              <option key={k} value={k}>
                                {k}
                              </option>
                            ))}
                          </Select>
                        }
                      />

                      <GridItem
                        noBorderBg
                        label={'Stroke width:'}
                        item={
                          <Input
                            style={{ padding: '0' }}
                            type={'number'}
                            defaultValue={defaultStrokeWidth ?? defaultStyle.strokeWidth ?? 0}
                            onChange={(e) =>
                              onDefaultChange(
                                'strokeWidth',
                                // @ts-ignore
                                'textPanels[0].strokeWidth',
                                Number(e.target.value) >= 0 ? e.target.value : 0,
                              )
                            }
                            className={styles.inputWrap}
                          />
                        }
                      />

                      <GridItem
                        noBorderBg
                        label={'Stroke color:'}
                        item={
                          <PaletteColorPicker
                            value={
                              defaultStrokeColor ??
                              defaultStyle.strokeColor ??
                              'rgba(255, 255, 255, 255)'
                            }
                            onChange={(e) =>
                              // @ts-ignore
                              onDefaultChange('strokeColor', 'textPanels[0].strokeColor', e)
                            }
                          />
                        }
                      />

                      <GridItem
                        label="Text transform:"
                        noBorderBg
                        item={
                          <Select
                            className={styles.select}
                            defaultValue={defaultTextTransform ?? defaultStyle.textTransform}
                            onChange={(e) =>
                              onDefaultChange(
                                'textTransform',
                                // @ts-ignore
                                'textPanels[0].textTransform',
                                e.target.value,
                              )
                            }
                          >
                            {transformText.map((item) => (
                              <option key={item.format} value={item.format}>
                                {item.name}
                              </option>
                            ))}
                          </Select>
                        }
                      />
                    </GridWrapper>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditCitiesModal;
