const Undo = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.99076 0.497074L0 3.98183L2.99076 7.46658L3.59866 6.75828L1.64568 4.48273H8.13098C9.78444 4.48273 11.1402 6.07799 11.1402 8.00857C11.1402 9.93915 9.78966 11.4953 8.13098 11.4953H1.68262V12.4971H8.13098C10.2536 12.4971 12 10.4859 12 8.00857C12 5.53126 10.2588 3.48093 8.13098 3.48093H1.64568L3.59866 1.20537L2.99076 0.497074Z"
        fill="#BCBFCA"
      />
    </svg>
  );
};

export default Undo;
