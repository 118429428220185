import { useQuery } from 'react-query';

import { axiosInstance } from '../../../core/api/axiosInstance';

export const key = ['ASSIGNED_DATA_PRODUCTS'];
export const useGetAssignedDataProducts = (id: string) => {
  return useQuery(
    [...key, id],
    async () => {
      const res = await axiosInstance.get('enterprise-accounts/assigned-products', {
        params: { enterpriseAccountID: id },
      });
      return res.data;
    },
    {
      enabled: !!id,
    },
  );
};
