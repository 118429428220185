import { useQuery } from 'react-query';

import { axiosInstance } from '../../../core/api/axiosInstance';

export const key = ['DATA_PRODUCT_FILES'];

export const useGetDataProductFiles = (refetch: boolean, id?: string) =>
  useQuery(
    [...key, id],
    async () => {
      const { data } = await axiosInstance.get(`/data-product-files/by-product/${id}`);
      return data;
    },
    {
      enabled: !!id && refetch,
      refetchInterval: 2000,
    },
  );
