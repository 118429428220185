import { BorderDef, BorderStyleEnum } from '../../../../../model/definitions/BorderDef';
import { BoxDef } from '../../../../../model/definitions/BoxDef';
import { Border } from './Border';

interface BorderPickerProps {
  box: BoxDef;
  onChange: (value: BorderDef, name: Leaves<BoxDef>) => void;
}
export const BorderPicker = ({ box, onChange }: BorderPickerProps) => {
  const { borderLeft, borderRight, borderTop, borderBottom } = box;
  const onBorderChange = (
    pos: string,
    prop: keyof BorderDef,
    value: number | BorderStyleEnum | string,
  ) => {
    const leave = `border${pos}` as keyof BoxDef;
    const border = box[leave] as BorderDef;
    const val = { ...border, [prop]: value };
    onChange(val, leave as Leaves<BoxDef>);
  };
  return (
    <>
      <h4>Borders</h4>
      <div className={'grid prop-grid-4'}>
        <Border border={borderLeft} position={'Left'} onChange={onBorderChange} />
        <Border border={borderRight} position={'Right'} onChange={onBorderChange} />
        <Border border={borderTop} position={'Top'} onChange={onBorderChange} />
        <Border border={borderBottom} position={'Bottom'} onChange={onBorderChange} />
      </div>
    </>
  );
};
