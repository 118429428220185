import { useQuery } from 'react-query';

import { ObservedData } from '../../../model/other/ObservedData';
import { axiosInstance } from '../axiosInstance';

const key = ['OBSERVED_DATA'];
export const useGetValue = (
  time?: number,
  station?: string,
  applyBias = false,
  step?: number,
  source?: string,
) =>
  useQuery(
    [...key, station, time, applyBias, source],
    async (): Promise<ObservedData> => {
      const { data } = await axiosInstance.get('observed-data/observed-data', {
        params: {
          station,
          time,
          applyBias,
          source,
        },
      });
      return data;
    },
    {
      enabled: !!station && !!time && (step === undefined ? true : step === 1),
    },
  );
