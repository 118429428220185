import './RelatedContent.scss';
import 'swiper/swiper.scss';

import React, { useState } from 'react';
import { MdArrowBack, MdArrowForward } from 'react-icons/md';
import SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import Button from '../../atoms/Button/AntButton';
import { useGetCurrency } from '../../hooks/useGetCurrency';
import { usePublicSimilarItems } from '../../hooks/usePublicSimilarItems';
import { useSimilarItems } from '../../hooks/useSimilarItems';
import { MarketplaceItem } from '../../model/interfaces/MarketplaceItem';
import AntItemCard from '../AntItemCard/AntItemCard';

interface Props {
  id: string;
  title: string;
  email: string;
  authenticated: boolean;
}

const RelatedContent: React.FC<Props> = ({ id, title, email, authenticated }) => {
  const [swiper, setSwiper] = useState<SwiperCore>();
  const [sliderProgress, setSliderProgress] = useState<number>(0);

  const { data: similarItems } = useSimilarItems(id, authenticated);
  const { data: publicSimilarItems } = usePublicSimilarItems(id, authenticated);

  const { data: currency } = useGetCurrency();

  const data = authenticated ? similarItems : publicSimilarItems;

  const renderItems = () => {
    return data
      ?.filter((item: MarketplaceItem) => item.id !== id)
      .map((item: MarketplaceItem) => (
        <SwiperSlide key={item.id}>
          <AntItemCard item={item} email={email} currency={currency} />
        </SwiperSlide>
      ));
  };

  const forwardSlide = () => {
    if (swiper) {
      swiper.slideTo(swiper.activeIndex + 1);
      setSliderProgress(swiper.progress);
    }
  };

  const backSlide = () => {
    if (swiper) {
      swiper.slideTo(swiper.activeIndex - 1);
      setSliderProgress(swiper.progress);
    }
  };

  return (
    <>
      {data?.length > 1 && (
        <div className="similar-content mt-10 md:mt-20 mx-auto max-w-[90%]">
          <p className="title text-center !font-light text-with-line">{title}</p>
          {data?.length > 4 && (
            <div className="text-right mb-5">
              <Button
                className="px-2 rounded-none mr-4 border-2 disabled:bg-white disabled:border-gray-300 disabled:text-gray-300 text-black border-black"
                disabled={swiper && sliderProgress === 0}
                onClick={() => backSlide()}
              >
                <MdArrowBack />
              </Button>
              <Button
                className="px-2 rounded-none border-2 disabled:bg-white disabled:border-gray-300 disabled:text-gray-300 text-black border-black"
                disabled={swiper && sliderProgress === 1}
                onClick={() => forwardSlide()}
              >
                <MdArrowForward />
              </Button>
            </div>
          )}

          <Swiper
            breakpoints={{
              300: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
              1280: {
                slidesPerView: 4,
              },
            }}
            spaceBetween={50}
            slidesPerView={1}
            onSwiper={(e) => setSwiper(e)}
            onSlideChange={(e) => setSliderProgress(e.progress)}
          >
            {renderItems()}
          </Swiper>
        </div>
      )}
    </>
  );
};

export default RelatedContent;
