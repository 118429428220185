import { Typography } from 'antd';
import React from 'react';
import { BiEnvelope, BiPhone } from 'react-icons/bi';
import { MdError } from 'react-icons/md';

import video from '../../assets/images/storm.mp4';

const { Text } = Typography;

const Fallback: React.FC<any> = ({ error }) => {
  return (
    <>
      <video autoPlay loop className={'absolute left-0 right-0 top-0 bottom-0 z-0 w-full'}>
        <source src={video} />
      </video>
      <div
        className={
          'flex w-full h-full bg-neutral-900 bg-opacity-90 text-amber-50 flex-col justify-center items-center z-10 relative'
        }
      >
        <MdError className={'text-red-400 text-9xl'} />
        <div>
          <h2 className={'text-amber-50 mb-6'}>Something went wrong!</h2>
        </div>
        <div className={'flex justify-center items-center flex-col'}>
          <div className={'mb-6'}>Please try again later or contact support</div>
          <div className={'flex justify-center items-center mb-6'}>
            <BiEnvelope className={'text-amber-50'} />
            <a href={`mailto:support@geo.stream`}>support@geo.stream</a>
          </div>
          <div className={'flex justify-center items-center mb-6'}>
            <BiPhone className={'text-amber-50'} />
            +1 877 463-6991
          </div>
          <div className={'flex justify-center items-center mb-6'}>
            <BiPhone className={'text-amber-50'} />
            +1 201-677-2354
          </div>
        </div>
        <div>
          <h2 className={'text-amber-50'}>Error:</h2>
        </div>
        <div>
          <Text code copyable className={'text-red-400'}>
            {error?.message}
          </Text>
        </div>
      </div>
    </>
  );
};

export default Fallback;
