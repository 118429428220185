import { AxiosError } from 'axios';
import { QueryKey, useQuery, UseQueryResult } from 'react-query';

import { axiosInstance } from '../../../core/api/axiosInstance';
import { C9ProjectPreviewDTO } from '../../../model/DTO/C9ProjectPreviewDTO';
import { QueryInfoMetadata } from '../../../model/other/QueryInfoMetadata';

export const key: QueryKey = ['GET_PROJECTS'];

const useGetProjects = ({
  page,
  size = 20,
  searchKey,
  sort,
  activeTab,
}: {
  page: number;
  size?: number;
  searchKey?: string;
  sort?: string;
  activeTab: string;
}): UseQueryResult<QueryInfoMetadata<C9ProjectPreviewDTO>, AxiosError> => {
  return useQuery(
    [...key, page, searchKey, sort],
    async () => {
      const { data } = await axiosInstance.get(
        `dashboard/${activeTab === 'USER' ? 'user' : 'shared'}-projects`,
        {
          params: { page, size, searchKey, sort },
        },
      );
      return data;
    },
    {
      staleTime: 1000 * 60, // 1min
    },
  );
};

export default useGetProjects;
