import { useEffect, useState } from 'react';

import LoadingIndicator from '../../../../atoms/loadingIndicator/LoadingIndicator';
import { useGetValue } from '../../../../core/api/observedData/useGetValue';
import { ObservedWDElementDef } from '../../../../model/definitions/ObservedWDElementDef';
import { ObservedWDStationDef } from '../../../../model/definitions/ObservedWDStationDef';
import { WeatherPosterDef } from '../../../../model/definitions/WeatherPosterDef';
import { ValueTypeEnum } from '../../../../model/enums/ValueTypeEnum';
import { IconPicker } from '../../../../organisms/addElementModal/PointDataComponents/IconPicker';
import { closest } from '../../sidebar-new/repoUtil/nearestDataFrame';

interface ObservedGeoPosterTimeProps {
  time: number;
  tempOffset: number;
  data: Array<ObservedWDElementDef>;
  station: { val: string; loc?: string };
  observedTime: number[];
  template: WeatherPosterDef;
  updatePoster: (poster: ObservedWDElementDef) => void;
}
export const ObservedGeoPosterTime = ({
  time,
  tempOffset,
  data,
  station,
  observedTime,
  updatePoster,
}: ObservedGeoPosterTimeProps) => {
  const [icon, setIcon] = useState<any>(undefined);
  const { data: observedData, isLoading: dataLoading } = useGetValue(
    closest(observedTime, (time - tempOffset) / 1000),
    station.val,
  );
  useEffect(() => {
    data.forEach((item) => {
      const source = {
        ...item,
        name: observedData?.station.fullName + ' ' + item.observedWDSource.parameterType,
        observedWDSource: {
          ...item.observedWDSource,
          station: observedData?.station ?? new ObservedWDStationDef(),
          datetime: closest(observedTime, (time - tempOffset) / 1000),
          utcDate: observedData?.utcTime ?? '',
          localDate: observedData?.localTime ?? '',
          name: observedData?.station.fullName + ' ' + item.observedWDSource.parameterType,
          value:
            item.observedWDSource.valueType === ValueTypeEnum.NUMERICAL
              ? observedData?.properties.find(
                  (param) => param.name === item.observedWDSource.parameterType,
                )?.values //?.value //.find((val) => val.unit === item.observedWDSource.unitOfMeasurement)
              : icon,
        },
      };
      updatePoster(source);
    });
  }, [time, icon, observedData]);
  return (
    <>
      <div>
        <div className="selected-date-and-time">
          {new Date(closest(observedTime, (time - tempOffset) / 1000) * 1000).toLocaleDateString(
            'en-GB',
            {
              hour: '2-digit',
              minute: '2-digit',
            },
          )}
        </div>
        <div className={'grid grid-cols-5 gap-2'}>
          {data.map((item) => {
            return item.observedWDSource.valueType === ValueTypeEnum.NUMERICAL ? (
              <div key={item.observedWDSource.parameterType} className={`mb-2 property-card`}>
                <div>
                  {item.observedWDSource.parameterType.split(/(?=[A-Z])/).join(' ') ?? 'NA'}
                </div>
                <div>
                  {dataLoading ? (
                    <LoadingIndicator />
                  ) : (
                    (Array.isArray(item.observedWDSource.value) &&
                      item.observedWDSource.value.find(
                        (val: any) => val.unit === item.observedWDSource.unitOfMeasurement,
                      ).value) ??
                    'NA'
                  )}
                </div>
                <div>
                  {item.observedWDSource.unitOfMeasurement.split(/(?=[A-Z])/).join(' ') ?? 'NA'}
                </div>
              </div>
            ) : (
              <div key={item.id}>
                <IconPicker
                  isTemp
                  weatherType={observedData?.weatherType ?? 'N/A'}
                  onSelect={(e) => {
                    setIcon(e);
                  }}
                  onClick={(e) => console.log(item.id, e)}
                  active={false}
                />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
