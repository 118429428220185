import { useDispatch, useSelector } from 'react-redux';

import VideoRecorder from '../../components/recorder/VideoRecorder';
import { useAddVideoOverElement } from '../../core/api/multimedia/useAddVideoElement';
import { useImplicitSave } from '../../hooks/useImplicitSave';
import { C9ProjectDef } from '../../model/definitions/C9ProjectDef';
import { TimeControlDef } from '../../model/definitions/TimeControlDef';
import { VideoPanelDef } from '../../model/definitions/VideoPanelDef';
import { addProjectVideOver, addVideoRecorder } from '../../store/slices/project-slice';
import { RootState } from '../../store/store';
import { RecorderElement } from './RecorderElement';

interface VideoOverElementProps {
  canvas: { cnvWidth: number; cnvHeight: number };
  play: () => void;
  videoOver?: VideoPanelDef | null;
  disabled: boolean;
}
export const VideoOverRecorder = ({ play, videoOver, disabled }: VideoOverElementProps) => {
  const project = useSelector<RootState, C9ProjectDef>((state) => state.project.present.project);
  const save = useImplicitSave();
  const dispatch = useDispatch();
  const { mutate: addRecording } = useAddVideoOverElement();
  const handleDownload = (blob: Blob, time: TimeControlDef) => {
    const videoPanel = { ...videoOver } as VideoPanelDef;
    videoPanel.timeControls = [time];
    addRecording(blob, {
      onSuccess: (data) => {
        videoPanel.videoPanelDefTemplate = data.data;
        dispatch(addProjectVideOver({ video: videoPanel }));
        dispatch(addVideoRecorder({ recorder: null }));
        save({ ...project, videoOver: videoPanel });
      },
    });
  };
  return videoOver ? (
    <RecorderElement videoOver={videoOver} disabled={disabled}>
      <VideoRecorder
        onRecorded={(recorded, time) => {
          handleDownload(recorded, time);
        }}
        onStartRecording={() => {
          play();
        }}
      />
    </RecorderElement>
  ) : null;
};
