import { useMutation } from 'react-query';

import { PosterTemplateSaveResponse } from '../../../model/DTO/PosterTemplateSaveResponse';
import { axiosInstance } from '../axiosInstance';

export const useGetPosterById = () =>
  useMutation(async (id: string): Promise<PosterTemplateSaveResponse> => {
    const { data } = await axiosInstance.get(`weather-poster-template/current/${id}`);
    return data;
  });
