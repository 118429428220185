import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';

import { axiosInstance } from '../../../../core/api/axiosInstance';
import { fitToCanvasHelper } from '../../../../helpers/fitToCanvas';
import { getMaxZindex } from '../../../../helpers/timelineUtil';
import { AnimationPanelDef } from '../../../../model/definitions/AnimationPanelDef';
import { AnimationPanelDefTemplate } from '../../../../model/definitions/AnimationPanelDefTemplate';
import { BackgroundDef } from '../../../../model/definitions/BackgroundDef';
import { C9ProjectDef } from '../../../../model/definitions/C9ProjectDef';
import { PositionControlDef } from '../../../../model/definitions/PositionControlDef';
import { TimeControlDef } from '../../../../model/definitions/TimeControlDef';
import { setElement } from '../../../../store/slices/active-slice';
import { addAnimationLayer } from '../../../../store/slices/project-slice';

export const useGetAnimationFromRepo = () => {
  const dispatch = useDispatch();
  return useMutation(
    async ({
      id,
      ratio,
      activeScene,
      project,
      duration,
      time,
      previewSize,
    }: {
      id: string;
      ratio: [number, number];
      activeScene: string;
      project: C9ProjectDef;
      duration: number;
      time: number;
      previewSize: number;
    }): Promise<AnimationPanelDefTemplate> => {
      const { data } = await axiosInstance.get(`multimedia/ANIMATION/current-template/${id}`);
      const animationLayer = new AnimationPanelDef();
      const lottieRatio = data.w / data.h;
      animationLayer.name = data.name;
      animationLayer.description = data.description;
      const { x, y } = fitToCanvasHelper(data, ratio, previewSize);
      animationLayer.positionControl = new PositionControlDef(
        30,
        (30 * (ratio[0] / ratio[1])) / lottieRatio,
      );
      animationLayer.positionControl.x = x;
      animationLayer.positionControl.y = y;
      animationLayer.positionControl.zindex = getMaxZindex(activeScene as string, project);
      animationLayer.templateId = data.id;
      animationLayer.templateVersionId = data.versionId;
      animationLayer.timeControls = [new TimeControlDef(time, duration)];
      animationLayer.enabled = true;
      animationLayer.background = new BackgroundDef();
      animationLayer.animationPanelDefTemplate = data;
      dispatch(addAnimationLayer({ animationLayer, activeScene }));
      dispatch(
        setElement({
          activeElement: animationLayer.id,
          activeProp: 'animationPanels',
        }),
      );
      return data;
    },
  );
};
