import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import { DataFrameDef } from '../../../model/definitions/DataFrameDef';
import { PickerDef } from '../../../model/definitions/PickerDef';
import { axiosInstance } from '../axiosInstance';

export const useGetSatellite = (oldLayer: boolean) => {
  const [satelliteType, setSatelliteType] = useState<PickerDef[]>([]);
  const [sourceData, setSourceData] = useState<PickerDef[]>([]);
  const [values, setValues] = useState<DataFrameDef[]>([]);
  const [loadingType, setLoadingType] = useState(false);
  const [loadingSource, setLoadingSource] = useState(false);
  const [loadingValues, setLoadingValues] = useState(false);

  const apiUrl = `satellite-data${oldLayer ? '' : '/v2'}`;
  const satelliteKey = [`SATELLITE_VALUE${oldLayer ? '' : '_V2'}`];
  const typeKey = ['SATELLITE_TYPE'];

  const useGetSatelliteType = (bounds: [number, number, number, number]) => {
    return useQuery(
      [typeKey, bounds],
      async () => {
        setLoadingType(true);
        try {
          const { data } = await axiosInstance.post(`${apiUrl}/satellite-type`, {
            leftLongitude: bounds[0],
            rightLongitude: bounds[1],
            upperLatitude: bounds[2],
            lowerLatitude: bounds[3],
          });
          setSatelliteType(data);
        } catch (error) {
          // Handle error
        } finally {
          setLoadingType(false);
        }
      },
      { enabled: Boolean(bounds.length) && oldLayer },
    );
  };

  const getTypes = useMutation(
    async ({
      dataProductId,
      bounds,
    }: {
      dataProductId: string;
      bounds: [number, number, number, number];
    }) => {
      setLoadingType(true);
      try {
        const { data } = await axiosInstance.post(`${apiUrl}/satellite-type`, {
          location: {
            leftLongitude: bounds[0],
            rightLongitude: bounds[1],
            upperLatitude: bounds[2],
            lowerLatitude: bounds[3],
          },
          dataProductId,
        });
        setSatelliteType(data);
      } catch (error) {
        // Handle error
      } finally {
        setLoadingType(false);
      }
    },
  );

  const getSource = useMutation(
    async ({ bounds, typeId }: { bounds: [number, number, number, number]; typeId?: number }) => {
      setLoadingSource(true);
      try {
        const requestData: any = {
          leftLongitude: bounds[0],
          rightLongitude: bounds[1],
          upperLatitude: bounds[2],
          lowerLatitude: bounds[3],
        };

        if (oldLayer) {
          requestData.typeId = typeId;
        }
        const { data } = await axiosInstance.post(`${apiUrl}/satellite-source`, requestData);
        setSourceData(data);
      } catch (error) {
        // Handle error
      } finally {
        setLoadingSource(false);
      }
    },
  );

  const getValues = useMutation(
    satelliteKey,
    async ({
      typeId,
      bounds,
      dataProductId,
      sourceId,
    }: {
      typeId: number;
      bounds: [number, number, number, number];
      dataProductId?: string;
      sourceId?: number;
    }) => {
      setLoadingValues(true);
      try {
        const requestData: any = {
          typeId,
          location: {
            leftLongitude: bounds[0],
            rightLongitude: bounds[1],
            upperLatitude: bounds[2],
            lowerLatitude: bounds[3],
          },
        };

        if (oldLayer) {
          requestData.sourceId = sourceId;
        } else {
          requestData.dataProductId = dataProductId;
        }
        const { data } = await axiosInstance.post(`${apiUrl}/satellite-value`, requestData);
        setValues(data);
      } catch (error) {
        // Handle error
      } finally {
        setLoadingValues(false);
      }
    },
  );
  return {
    useGetSatelliteType,
    getTypes: getTypes.mutate,
    getSource: getSource.mutate,
    getValues: getValues.mutate,
    satelliteType,
    sourceData,
    values,
    loadingType,
    loadingSource,
    loadingValues,
  };
};
