import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { axiosInstance } from '../../../core/api/axiosInstance';
import { queryClient } from '../../../index';
import { key } from './useGetBookmarks';

export const useBookmark = () => {
  return useMutation(
    async ({ marketplaceItemId, name }: { marketplaceItemId: string; name: string }) => {
      return await axiosInstance.post('bookmark/marketplace/', undefined, {
        params: { marketplaceItemId, name },
      });
    },
    {
      onSuccess: () => {
        toast.success('Item added to bookmark!');
        queryClient.invalidateQueries(key);
      },
      onError: () => {
        toast.error('Error while adding item to bookmark!');
      },
    },
  );
};
