import './style.scss';

import { Select, SelectProps } from 'antd';

interface AntSelectProps extends SelectProps {
  title?: string;
}

const AntSelect = (props: AntSelectProps) => {
  return (
    <>
      {props.title && <div className="ant-select-title">{props.title}</div>}
      <Select {...props} />
    </>
  );
};

export default AntSelect;
