import { Result } from 'antd';
import { useContext, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import Button from '../../atoms/Button/AntButton';
import { useConfirmTransaction } from '../../hooks/payment/useConfirmTransaction';
import MarketplaceContext from '../../marketplaceContext';

function Confirm() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const payment_intent = params.get('payment_intent');
  const payment_intent_client_secret = params.get('payment_intent_client_secret');
  const redirect_status = params.get('redirect_status');
  const { mutate: confirmTransaction } = useConfirmTransaction();
  const { authenticated } = useContext(MarketplaceContext);
  useEffect(() => {
    if (payment_intent && payment_intent_client_secret && redirect_status && authenticated) {
      confirmTransaction({
        paymentIntentId: payment_intent,
        paymentIntentSecret: payment_intent_client_secret,
        redirectStatus: redirect_status,
      });
    }
  }, [payment_intent, payment_intent_client_secret, redirect_status, authenticated]);
  return (
    <div className="h-1/2 relative">
      <Result
        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
        status="success"
        title="Transaction successfully finished"
        extra={[
          <Link to="/marketplace" key="link">
            <Button size="large" key="back">
              Go Back
            </Button>
          </Link>,
        ]}
      />
    </div>
  );
}

export default Confirm;
