import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { queryClient } from '../../..';
import { axiosInstance } from '../../../core/api/axiosInstance';
import { allFontsKey, enterpriseKey } from './fontKeys';

const useCreateEnterpriseFont = () => {
  return useMutation(
    async (data: FormData) => {
      console.log(data);
      return await axiosInstance.post(`font/enterprise`, data);
    },
    {
      onSuccess: () => {
        toast.success('Font created successfully!');
        const keys = [allFontsKey, enterpriseKey];
        keys.forEach((k) => queryClient.invalidateQueries(k));
      },
      onError: () => {
        toast.error('Error creating font!');
      },
    },
  );
};

export default useCreateEnterpriseFont;
//
