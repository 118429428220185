import { FC, ReactNode } from 'react';

import styles from '../Properties.module.scss';

interface PanelProps {
  children: ReactNode;
  actions?: false | JSX.Element | JSX.Element[] | null | undefined;
}
export const Panel: FC<PanelProps> = ({ children, actions }) => {
  return (
    <div className={styles.wrapper}>
      <div className={'flex justify-end'}>{actions}</div>
      <>{children}</>
    </div>
  );
};
