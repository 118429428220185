import './style.scss';

import { ShoppingCartOutlined } from '@ant-design/icons';
import { Badge, Button, Card } from 'antd';
import { format } from 'date-fns';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { DefaultThumb } from '../../../../assets/images';
import { MarketplaceTemplateEnum } from '../../../../model/enums/MarketplaceTemplateEnum';
import { ICartItem } from '../../../payment/types/ICart';
import Tag from '../../atoms/Tags/AntTag';
import { useAddToCart } from '../../hooks/payment/useAddToCart';
import { useGetShoppingCartItems } from '../../hooks/payment/useGetShoppingCartItems';
import { useBuyItem } from '../../hooks/useBuyItem';
import MarketplaceContext from '../../marketplaceContext';
import { MarketplaceItem } from '../../model/interfaces/MarketplaceItem';
import AdminArea from '../../pages/DetailsPage/AdminArea';
import Price from '../Price/Price';

interface AntItemCardInterface {
  item: MarketplaceItem;
  imageCard?: boolean;
  isAdmin?: boolean;
  email?: string;
  author?: string;
  currency?: Currency;
}

interface Currency {
  name: string;
  symbol: string;
  code: string;
  label: string;
  exchangeRateUsd: number;
}
const AntItemCard = ({
  item,
  imageCard,
  isAdmin,
  email,
  author,
  currency,
}: AntItemCardInterface) => {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname.indexOf('/my-store/');
  const { mutate: addToCart, isSuccess } = useAddToCart();
  const [redirect, setRedirect] = useState<boolean>();
  const { authenticated } = useContext(MarketplaceContext);
  const { data: cart } = useGetShoppingCartItems(authenticated);
  const { mutate: mutateBuyItem, isSuccess: isSuccessBuy } = useBuyItem();

  function redirectToDetailsPage(id: string) {
    path === -1
      ? navigate(`/marketplace/items/details-page/${id}`)
      : navigate(`/marketplace/my-store/items/details-page/${id}`);
  }

  function redirectToStorePage(e: React.MouseEvent<HTMLParagraphElement, MouseEvent>) {
    e.stopPropagation();
    navigate(`/marketplace/store/${author || item.creator}`);
  }

  const convertEnumToString = (type: MarketplaceTemplateEnum) => {
    switch (type) {
      case MarketplaceTemplateEnum.MAP_TEMPLATE:
        return 'Map';
      case MarketplaceTemplateEnum.CUSTOM_MAP_TEMPLATE:
        return 'Custom Map';
      case MarketplaceTemplateEnum.CUSTOM_VECTOR_TEMPLATE:
        return 'Custom Vector';
      case MarketplaceTemplateEnum.IMAGE_TEMPLATE:
        return 'Image';
      case MarketplaceTemplateEnum.VIDEO_TEMPLATE:
        return 'Video';
      case MarketplaceTemplateEnum.AUDIO_TEMPLATE:
        return 'Audio';
      case MarketplaceTemplateEnum.ANIMATION_TEMPLATE:
        return 'Animation';
      case MarketplaceTemplateEnum.WEATHER_POSTER_TEMPLATE:
        return 'Poster';
      case MarketplaceTemplateEnum.WEATHER_GEO_POSTER_TEMPLATE:
        return 'Composite';
      case MarketplaceTemplateEnum.ICON_SET_TEMPLATE:
        return 'Icon Set';
      case MarketplaceTemplateEnum.PROJECT_TEMPLATE:
        return 'Project';
      case MarketplaceTemplateEnum.SCENE_TEMPLATE:
        return 'Scene';
    }
  };

  const handleAddToCart = (e: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    e.stopPropagation();
    addToCart(item.id);
  };

  const handleBuyNow = (e: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    e.stopPropagation();
    if (item.price > 0) {
      const inCart = cart.items.filter((i: ICartItem) => i.storeItemId === item.id).length > 0;
      if (inCart) {
        navigate('/marketplace/checkout');
      } else {
        addToCart(item.id);
        setRedirect(true);
      }
    } else {
      mutateBuyItem(item.id);
    }
  };

  useEffect(() => {
    if (cart && isSuccess && redirect) {
      navigate('/marketplace/checkout');
    }
  }, [cart]);

  useEffect(() => {
    if (isSuccessBuy) {
      navigate('/marketplace/confirm');
    }
  }, [isSuccessBuy]);

  const hoverActions = () => {
    if (isAdmin) {
      return (
        <>
          <AdminArea approved={item.approved} id={item.id} item={item} onHover />
        </>
      );
    }
    return (
      <>
        {authenticated && email !== item.creator && (
          <>
            {item.price > 0 ? (
              <>
                <Button icon={<ShoppingCartOutlined />} onClick={(e) => handleAddToCart(e)}>
                  ADD TO CART
                </Button>
                <Button onClick={(e) => handleBuyNow(e)}>BUY NOW</Button>
              </>
            ) : (
              <Button onClick={(e) => handleBuyNow(e)}>BUY NOW</Button>
            )}
          </>
        )}
      </>
    );
  };

  return (
    <>
      <Card
        className="ant-card-item"
        onClick={() => redirectToDetailsPage(item.id)}
        cover={<img alt={item.name} src={item.thumbnailUrls[0] ?? DefaultThumb} />}
      >
        {!imageCard && (
          <>
            <p className="ant-card-body-title">
              <span className="name">{item.name}&nbsp;</span>-
              <span className="type">&nbsp;{convertEnumToString(item.templateType)}</span>
            </p>
            <p className="ant-card-body-description">
              Created date: {format(item.creationDate, 'dd.MM.yyyy')}
            </p>
            <p className="cursor-pointer mt-1 text-sm " onClick={(e) => redirectToStorePage(e)}>
              {author || item.creator}
            </p>
            {isAdmin && (
              <Badge
                count={item.approved ? 'Approved' : 'Not approved'}
                style={{ backgroundColor: item.approved ? '#52c41a' : 'red' }}
              />
            )}
            <p className="ant-card-body-price">
              {item.price > 0 ? (
                currency && <Price currency={currency} price={item.price} />
              ) : (
                <Tag color="success">FREE</Tag>
              )}
            </p>
          </>
        )}
      </Card>
      {!imageCard && (
        <span className="hover-actions">
          <span className="overlay" onClick={() => redirectToDetailsPage(item.id)}></span>
          {hoverActions()}
        </span>
      )}
      {imageCard && (
        <span className="image-card cursor-pointer" onClick={() => redirectToDetailsPage(item.id)}>
          <span className="overlay"></span>
          <span className="image-card-actions">
            <p className="image-card-actions-title">
              <span className="name">{item.name}&nbsp;</span>-
              <span className="type">&nbsp;{convertEnumToString(item.templateType)}</span>
            </p>
            <p className="image-card-actions-description">
              Created date: {format(item.creationDate, 'dd.MM.yyyy')}
            </p>
            <p className="image-card-actions-price">
              {item.price > 0
                ? currency && <Price currency={currency} price={item.price} />
                : 'Free'}
              {authenticated &&
                email !== item.creator &&
                (item.price > 0 ? (
                  <Button icon={<ShoppingCartOutlined />} onClick={(e) => handleAddToCart(e)}>
                    ADD TO CART
                  </Button>
                ) : (
                  <Button icon={<ShoppingCartOutlined />} onClick={(e) => handleBuyNow(e)}>
                    BUY NOW
                  </Button>
                ))}
            </p>
          </span>
        </span>
      )}
    </>
  );
};

export default AntItemCard;
