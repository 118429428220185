import 'react-toastify/dist/ReactToastify.css';

import { Tooltip } from 'antd';
import { format } from 'date-fns';
import { debounce } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';

import noThumbnail from '../../../assets/images/thumbnail.jpg';
import formatFileSize from '../../../helpers/formatFileSize';
import { RepositoryDTO } from '../../../model/DTO/RepositoryDTO';
import { DeleteTemplateProps } from '../../../model/other/DeleteTemplateProps';
import { ActiveDef } from '../../../store/slices/active-slice';
import { RootState } from '../../../store/store';
import styles from './RepositoryItem.module.scss';

interface Props {
  item: RepositoryDTO;
  setSidebarOpen: (open: boolean) => void;
  type: string;
  deleteModal?: boolean;
  handleDeleteModal?: (props: DeleteTemplateProps) => void;
  showMore?: boolean;
  onShowMore?: () => void;
  onAddItem: (id: string, type?: string) => void;
  isGeo?: boolean;
}

const RepositoryItem: React.FC<Props> = ({
  item: { creationDate, description, thumbnailURLs, name, id, sizeInMB, disabled, disableReason },
  onAddItem,
  type,
  isGeo,
}) => {
  const { activeScene } = useSelector<RootState, ActiveDef>((state) => state.active);
  // const add = () => onAddItem(id, type);
  function handleButtonClick() {
    onAddItem(id, type);
  }
  const handleClickDebounced = debounce(handleButtonClick, 300);

  return (
    <Tooltip
      title={disableReason}
      placement="bottom"
      getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
    >
      <div
        className={`${styles['repository-item']} ${disabled && styles['disabled-repository']}`}
        onClick={!disabled ? handleClickDebounced : undefined} //{addLayerFromRepo}
        style={{
          pointerEvents: `${!activeScene ? 'none' : 'unset'}`,
          opacity: `${!activeScene ? '0.7' : '1'}`,
          width: '48%',
          // width: isGeo ? '100%' : undefined,
        }}
      >
        <div
          className={`${styles['img-wrap']}`}
          // style={{ height: isGeo ? '100%' : undefined }}
        >
          <img
            className={`${styles['img']}`}
            src={thumbnailURLs?.[0] ? thumbnailURLs[0] : noThumbnail}
            alt="Repository preview"
          />
        </div>
        <div>
          <div className={`${styles['text-title']}`}>
            <h3>{name}</h3>
            <p className={`${styles['description']}`}>{description}</p>
            {sizeInMB && (
              <p>
                <small className={`${styles['date']}`}>
                  {formatFileSize(sizeInMB * 1000 * 1000)}
                </small>
              </p>
            )}
            <small className={`${styles['date']}`}>{format(creationDate, 'dd/MM/yyyy')} </small>
          </div>
        </div>
      </div>
    </Tooltip>
  );
};

export default RepositoryItem;
