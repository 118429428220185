import { useMutation } from 'react-query';

import { axiosInstance } from '../../../core/api/axiosInstance';
import { queryClient } from '../../../index';
import { key } from './useGetMultimedia';

const apiURL = 'multimedia/delete';
const useDeleteMultimedia = () => {
  return useMutation(
    async ({ id, type }: { id: string; type: string }) => {
      const { data } = await axiosInstance.delete(`${apiURL}/${id}`, {
        params: { type: type.toUpperCase() },
      });
      return data;
    },
    {
      onSuccess: (data, { type }) => queryClient.invalidateQueries(key),
    },
  );
};

export default useDeleteMultimedia;
