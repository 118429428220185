import { useMutation } from 'react-query';

import { ObservedWDElementDef } from '../../../model/definitions/ObservedWDElementDef';
import { ObservedWDElementRequestDTO } from '../../../model/DTO/ObservedWDElementRequestDTO';
import { axiosInstance } from '../axiosInstance';

export const useGetObservedElement = () => {
  return useMutation(
    async (requestData: ObservedWDElementRequestDTO): Promise<Array<ObservedWDElementDef>> => {
      const { data } = await axiosInstance.post(
        'observed-data/add-observed-wd-element',
        requestData,
      );
      return data;
    },
  );
};
