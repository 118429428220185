import './style.scss';

import { DetailedHTMLProps, HTMLAttributes } from 'react';

export const Layout = ({
  ...props
}: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
  return (
    <div className={'ws-layout'}>
      <div className={'ws-layout-inner'}>{props.children}</div>
    </div>
  );
};

export default Layout;
