/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Select, Tooltip } from 'flowbite-react';
import { nanoid } from 'nanoid';
import React, { ChangeEvent, FormEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { AiOutlineSave } from 'react-icons/ai';
import {
  BsDownload,
  BsFillRecordFill,
  BsFillStopFill,
  BsMic,
  BsMicMute,
  BsXCircleFill,
} from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import Webcam from 'react-webcam';
import { v4 } from 'uuid';

import Input from '../../../atoms/input/Input';
import TextArea from '../../../atoms/input/Textarea';
import LoadingIndicator from '../../../atoms/loadingIndicator/LoadingIndicator';
import { axiosInstance } from '../../../core/api/axiosInstance';
import { fitToCanvasHelper } from '../../../helpers/fitToCanvas';
import { getMaxZindex } from '../../../helpers/timelineUtil';
import { useTimer } from '../../../hooks/useTimer';
import { MAX_FULLSCREEN_HEIGHT } from '../../../model/constants/constants';
import { C9ProjectDef } from '../../../model/definitions/C9ProjectDef';
import { VideoPanelDef } from '../../../model/definitions/VideoPanelDef';
import { VideoPanelDefTemplate } from '../../../model/definitions/VideoPanelDefTemplate';
import { ActiveDef } from '../../../store/slices/active-slice';
import { addVideoLayer } from '../../../store/slices/project-slice';
import { RootState, store } from '../../../store/store';

const resolutions = [
  { name: 'SD', width: 640, height: 480 },
  { name: 'HD', width: 1280, height: 720 },
  { name: 'FHD', width: 1920, height: 1080 },
];
const baseUrl = process.env.REACT_APP_API_BASE_URL;

interface VideoRecorderProps {
  save: boolean;
  loading: boolean;
  recordedChunks: any;
  onClose: () => void;
  setRecordedChunks: (e: any) => void;
  formValues: { name: string; description: string };
  handleAdd: () => void;
  changeUrl: (e: string) => void;
  editInput: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
  ) => void;
}
const VideoRecorder = ({
  save,
  handleAdd,
  changeUrl,
  recordedChunks,
  setRecordedChunks,
  formValues,
  editInput,
  onClose,
}: VideoRecorderProps) => {
  const videoConstraints = {
    facingMode: 'user',
  };
  const dispatch = useDispatch();
  const webcamRef = useRef<Webcam>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const { activeAspectRatio, activeScene } = useSelector<RootState, ActiveDef>(
    (state) => state.active,
  );
  const project = useSelector<RootState, C9ProjectDef>((state) => state.project.present.project);
  const [capturing, setCapturing] = useState(false);
  const [url, setUrl] = useState<string>('');
  const [videoUrl, setVideoUrl] = useState<string>();
  const [audio, setAudio] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [resolution, setResolution] = useState<{
    width: number;
    height: number;
  }>({
    width: 640,
    height: 480,
  });
  useHotkeys('ctrl+r, command+r', (ev) => {
    ev.preventDefault();
    handleStartCaptureClick();
  });
  const { startTimer, stopTimer, result } = useTimer();
  const returnState = (state?: string | null) => {
    if (state === 'recording')
      return (
        <div className={'flex'}>
          <BsFillRecordFill size={24} className={'blink'} /> {state}
        </div>
      );
    else
      return (
        <div className={'flex'}>
          <BsFillStopFill size={24} /> {state}
        </div>
      );
  };
  const handleDataAvailable = React.useCallback(
    ({ data }: { data: Blob }) => {
      if (data.size > 0) {
        setRecordedChunks((prev: any) => prev.concat(data));
      }
    },
    [setRecordedChunks],
  );
  useEffect(() => {
    const blob = new Blob(recordedChunks, {
      type: 'video/webm',
    });
    setUrl(URL.createObjectURL(blob));
    changeUrl(URL.createObjectURL(blob));
  }, [recordedChunks]);
  const handleStartCaptureClick = React.useCallback(() => {
    startTimer();
    setCapturing(true);
    if (mediaRecorderRef && webcamRef) {
      if (webcamRef.current?.stream) {
        mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
          mimeType: 'video/webm;codecs=h264',
        });
      }
      if (mediaRecorderRef?.current) {
        mediaRecorderRef?.current.addEventListener('dataavailable', handleDataAvailable);
      }
      if (mediaRecorderRef?.current) mediaRecorderRef?.current.start();
    }
  }, [webcamRef, setCapturing, mediaRecorderRef]);
  const handleStopCaptureClick = useCallback(() => {
    if (mediaRecorderRef.current) mediaRecorderRef.current.stop();
    stopTimer();
    setCapturing(false);
    setVideoUrl(url);
    setRecordedChunks([]);
  }, [mediaRecorderRef, webcamRef, setCapturing, capturing, recordedChunks, setVideoUrl]);
  const handleDownload = useCallback(() => {
    if (recordedChunks.length) {
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = url;
      a.download = `Video recording-${nanoid(10)}.webm`;
      a.click();
    }
  }, [recordedChunks]);
  const switchAudio = () => {
    setAudio(!audio);
  };
  const changeResolution = (e: ChangeEvent<HTMLSelectElement>) => {
    const resolution = resolutions.find((item) => item.name === e.target.value);
    if (resolution) {
      const res = { width: resolution.width, height: resolution.height };
      setResolution(res);
    }
  };
  function handleRetake() {
    videoUrl && window.URL.revokeObjectURL(videoUrl);
    setVideoUrl(undefined);
    setRecordedChunks([]);
  }
  const addVideoPanel = (media: VideoPanelDefTemplate) => {
    const { width, height, x, y } = fitToCanvasHelper(
      media,
      activeAspectRatio,
      MAX_FULLSCREEN_HEIGHT,
    );
    const layer = new VideoPanelDef();
    layer.videoPanelDefTemplate = media;
    layer.id = v4();
    layer.name = media.name;
    layer.timeControls[0].endMS = media.durationInMS;
    layer.positionControl.h = height;
    layer.positionControl.w = width;
    layer.positionControl.x = x;
    layer.positionControl.y = y;
    layer.positionControl.zindex = getMaxZindex(activeScene as string, project);

    dispatch(
      addVideoLayer({
        videoLayer: layer,
        activeScene: store.getState().active.activeScene,
      }),
    );
    onClose();
  };
  const handleUpload = () => {
    setLoading(true);
    const data = new FormData();
    const file = new Blob(recordedChunks, {
      type: 'video/mp4',
    });
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);
    fileReader.onload = async function () {
      data.append('file', file);
      data.append('type', 'VIDEO');
      data.append('description', formValues.description ? formValues.description : '');
      data.append('name', formValues.name ? formValues.name : '');
      try {
        const addedFile = await axiosInstance.post(
          `${baseUrl}/api/store/multimedia/uploadFile`,
          data,
          {
            headers: { 'content-type': 'multipart/form-data' },
          },
        );
        const file = addedFile.data as unknown as VideoPanelDefTemplate;
        addVideoPanel({
          ...file,
          durationInMS: (videoRef.current?.duration ?? 0) * 1000,
        });
        setLoading(false);
        onClose();
      } catch (e) {
        return 'error';
      } finally {
        setLoading(false);
      }
    };
  };
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    handleUpload();
  };
  useEffect(() => {
    const video = videoRef.current;
    if (video)
      video.addEventListener('loadedmetadata', () => {
        if (video.duration === Infinity) {
          video.currentTime = 1e101;
          video.addEventListener('timeupdate', () => console.log(video.duration));
        }
      });
  });
  const videoRef = useRef<HTMLVideoElement>(null);
  return (
    <div
      style={{
        minWidth: 640,
        minHeight: 380,
      }}
      className={
        'flex justify-center flex-col flex-auto items-center justify-between relative record-video'
      }
    >
      {videoUrl ? (
        <div className="video">
          <video src={url} controls muted={false} height={380} ref={videoRef} />
        </div>
      ) : (
        <Webcam
          audio={audio}
          ref={webcamRef}
          width={640}
          videoConstraints={{ ...videoConstraints, ...resolution }}
          muted
        />
      )}
      <div className={'flex justify-between w-full items-center'}>
        <div className={'flex w-[8rem] justify-center gap-2'}>
          {recordedChunks.length > 0 && (
            <>
              <Tooltip style="dark" content="Download video">
                <BsDownload size={24} onClick={handleDownload} className={'icon-button'} />
              </Tooltip>
              <Tooltip style="dark" content="Save video">
                <AiOutlineSave size={24} onClick={handleAdd} className={'icon-button'} />
              </Tooltip>
            </>
          )}
          <div onClick={() => switchAudio()}>
            {audio ? (
              <Tooltip style="dark" content="Mute">
                <BsMic size={24} className={'icon-button'} />
              </Tooltip>
            ) : (
              <Tooltip style="dark" content="Unmute">
                <BsMicMute size={24} className={'icon-button'} />
              </Tooltip>
            )}
          </div>
        </div>
        <div className={'flex flex-col flex-1 justify-center w-[30%] items-center'}>
          <div className={'indicator'}>
            {returnState(mediaRecorderRef.current?.state)}
            {result()}
          </div>
          {capturing ? (
            <div className={'recorder-button'} title="Stop">
              <BsFillStopFill size={48} onClick={handleStopCaptureClick} />
            </div>
          ) : videoUrl ? (
            <div className={'recorder-button'} title="Retake">
              <BsXCircleFill size={48} onClick={handleRetake} color={'red'} />
            </div>
          ) : (
            <div className={'recorder-button'} title="Start">
              <BsFillRecordFill size={48} onClick={handleStartCaptureClick} color={'red'} />
            </div>
          )}
        </div>
        <div className={'flex w-[8rem] justify-center'}>
          <Select className={'select'} onChange={changeResolution} defaultValue={'FHD'}>
            <option value={'FHD'}>FHD</option>
            <option value={'SD'}>SD</option>
            <option value={'HD'}>HD</option>
          </Select>
        </div>
      </div>
      {save && (
        <form onSubmit={handleSubmit} className={'form-body'}>
          <Input
            name={'name'}
            label={'Name'}
            required
            value={formValues.name}
            onChange={(e) => editInput(e)}
            type={'text'}
          />
          <TextArea
            name={'description'}
            label={'Description'}
            value={formValues.description}
            onChange={(e) => editInput(e)}
            cols={2}
          />
          <Button type={'submit'} style={{ borderRadius: '0.5rem' }} disabled={loading}>
            {loading && <LoadingIndicator />}Add recording
          </Button>
        </form>
      )}
    </div>
  );
};

export default VideoRecorder;
