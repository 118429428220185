import { Button, Form, Input, Popconfirm, Table } from 'antd';
import { useEffect, useState } from 'react';

import useAddSqsConfig from '../queries-NEW/useAddSqsConfig';
import { useDeleteSqsConfig } from '../queries-NEW/useDeleteSqsConfig';
import { useEditSqsConfig } from '../queries-NEW/useEditSqsConfig';
import { useGetSqsConfigs } from '../queries-NEW/useGetSqsConfigs';

export interface SqsInterface {
  id: number;
  accessKey: string;
  secretKey: string;
  region: string;
  queueName: string;
}
interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: keyof SqsInterface;
  title: string;
  inputType: 'text' | 'number';
  record: SqsInterface;
  index: number;
}
const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

function ThinClientDetails({ enterpriseAccountId }: { enterpriseAccountId: string }) {
  const { data, isLoading } = useGetSqsConfigs(enterpriseAccountId);
  const { mutate: addSqs } = useAddSqsConfig();
  const { mutate: editSqs } = useEditSqsConfig();
  const { mutate: deleteSqs } = useDeleteSqsConfig();
  const [dataSource, setDataSource] = useState<SqsInterface[]>([]);
  const [editingKey, setEditingKey] = useState<number | undefined>();
  const [form] = Form.useForm();

  useEffect(() => {
    if (data) {
      setDataSource(data);
    }
  }, [data]);

  const isEditing = (record: SqsInterface) => record.id === editingKey;

  const edit = (record: SqsInterface) => {
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey(undefined);
  };

  const save = async (id: number) => {
    try {
      const row = await form.validateFields();
      const findItem = data.find((item: SqsInterface) => id === item.id);
      if (findItem) {
        row.id = findItem.id;
        row.enterpriseAccountId = findItem.enterpriseAccountId;
        editSqs(row);
        setEditingKey(undefined);
      } else {
        addSqs(row);
        setEditingKey(undefined);
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const handleDelete = (id: number) => {
    deleteSqs(id);
    setEditingKey(undefined);
  };

  const columns = [
    {
      title: 'Access key',
      dataIndex: 'accessKey',
      key: 'accessKey',
      editable: true,
    },
    {
      title: 'Secret key',
      dataIndex: 'secretKey',
      key: 'secretKey',
      editable: true,
    },
    {
      title: 'Region',
      dataIndex: 'region',
      key: 'region',
      editable: true,
    },
    {
      title: 'Queue name',
      dataIndex: 'queueName',
      key: 'queueName',
      editable: true,
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (_: any, record: SqsInterface) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Button onClick={() => save(record.id)} style={{ marginRight: 8 }}>
              Save
            </Button>
            {editingKey !== record.id && (
              <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                <Button>Cancel</Button>
              </Popconfirm>
            )}
          </span>
        ) : (
          <div className="flex gap-2">
            <Button disabled={!!editingKey} onClick={() => edit(record)}>
              Edit
            </Button>
            <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.id)}>
              <Button style={{ marginLeft: 8 }}>Delete</Button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: SqsInterface) => ({
        record,
        inputType: 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  const handleAdd = () => {
    const newData: SqsInterface = {
      id: dataSource.length + 1,
      accessKey: '',
      secretKey: '',
      region: '',
      queueName: '',
    };
    setDataSource([newData, ...dataSource]);
    setEditingKey(data.length);
    edit(newData);
  };

  return (
    <Form form={form} component={false}>
      {!editingKey && (
        <Button onClick={handleAdd} type="primary" style={{ marginBottom: 16 }}>
          Add SQS config
        </Button>
      )}
      <Table
        className="mt-2"
        rowKey="id"
        columns={mergedColumns}
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        rowClassName="editable-row"
        dataSource={dataSource}
        loading={isLoading}
        pagination={false}
      />
    </Form>
  );
}

export default ThinClientDetails;
