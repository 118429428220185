import { useDispatch } from 'react-redux';
import { v4 } from 'uuid';

import { TimestampElementDef } from '../../../model/definitions/TimestampElementDef';
import { ITimeStampData } from '../../../organisms/addElementModal/addTimestampModal';
import { addTimestampLayer } from '../../../store/slices/project-slice';

export const useAddTimeStampElement = () => {
  const dispatch = useDispatch();
  const addTimeStamp = (scene: string, data: ITimeStampData) => {
    const timestamp = new TimestampElementDef();
    const updated = {
      ...timestamp,
      ...data,
      enabled: true,
      id: v4(),
    };
    dispatch(
      addTimestampLayer({
        activeScene: scene,
        timestampLayer: updated,
      }),
    );
  };
  return { addTimeStamp };
};
