import { useQuery, UseQueryResult } from 'react-query';

import { IconSetTemplateItemDef } from '../../model/definitions/IconSetTemplateItemDef';
import { axiosInstance } from './axiosInstance';

const key = ['GET_ICON_SETS'];

export const useGetIconSets = (
  iconSets: string[],
): UseQueryResult<{ id: string; icons: IconSetTemplateItemDef[] }[]> => {
  return useQuery(
    [...key, iconSets],
    async () => {
      const promises = iconSets.map((iconSetId) =>
        axiosInstance
          .get(`icon-set-template/current/${iconSetId}`)
          .then((response) => {
            return {
              icons: response.data.iconItems,
              id: response.data.id,
            };
          })
          .catch((error) => {
            console.error('Error fetching icon set:', error);
          }),
      );

      return await Promise.all(promises);
    },
    { enabled: !!iconSets.length },
  );
};
