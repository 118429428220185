import { useDispatch, useSelector } from 'react-redux';

import Input from '../../../atoms/input/Input';
import { ElementsEnum } from '../../../core/ui/enums/ElementsEnum';
import { usePropertyGridActive } from '../../../hooks/usePropertyGridActive';
import { PointLocationDef } from '../../../model/definitions/PointLocationDef';
import { SceneDef } from '../../../model/definitions/SceneDef';
import { ActiveDef, setPropertyGridActiveHash } from '../../../store/slices/active-slice';
import { updateLocationLayer } from '../../../store/slices/project-slice';
import { selectActiveScene } from '../../../store/slices/selectors';
import { RootState } from '../../../store/store';
import { Panel } from './components/Panel';
import { PropertySection } from './components/PropertySection';
import { BoxStyle } from './panels/BoxStyle';
import { FontProperties } from './panels/FontProperties';
import { PositionControls } from './panels/PositionControls';
import { TimeControlsPanel } from './panels/TimeControlsPanel';
import styles from './Properties.module.scss';
import GridItem from './shared/GridItem';
import GridWrapper from './shared/GridWrapper';

export const PointLocationProperties = () => {
  const dispatch = useDispatch();
  const { activePoster, activeElement, activeScene, activeMap } = useSelector<RootState, ActiveDef>(
    (state) => state.active,
  );
  const activeSceneDef = useSelector<RootState, SceneDef | null>((state) =>
    selectActiveScene(state),
  );
  const locationLayer = activeSceneDef?.pointLocation?.find((point) => point.id === activeElement);
  const { isOpened, lastFocused } = usePropertyGridActive([
    'textAnimation.active',
    'textAnimation.speed',
    'value',
  ]);
  const posterLocationLayer = activeSceneDef?.weatherPosters
    .find((poster) => poster.id === activePoster)
    ?.pointLocation?.find((pointDt) => pointDt.id === activeElement);
  const posterMapLocationLayer = activeSceneDef?.mapPanels
    ?.find((map) => map.id === activeMap)
    ?.geoPosters.find((poster) => poster.id === activePoster)
    ?.pointLocation?.find((pointDt) => pointDt.id === activeElement);
  function onFocus(path: Leaves<PointLocationDef>) {
    dispatch(setPropertyGridActiveHash({ activeElement, focusedEl: path }));
  }
  function onDateLayerChange(propertyPath: Leaves<PointLocationDef>, e: boolean | string | number) {
    onFocus(propertyPath);
    dispatch(
      updateLocationLayer({
        newValue: e,
        activeScene,
        elementId: activeElement,
        propertyPath: propertyPath,
        parentId: activePoster,
        mapId: activeMap,
      }),
    );
  }
  const layer = locationLayer || posterLocationLayer || posterMapLocationLayer;
  return (
    <Panel>
      <PropertySection label={'Location properties'} isOpened={isOpened}>
        <div className="prop-wrapper">
          <GridWrapper>
            <GridItem
              label={'Name:'}
              item={
                <Input
                  style={{ padding: '0' }}
                  type={'text'}
                  value={layer?.name}
                  autoFocus={lastFocused === 'name'}
                  onChange={(e) => {
                    onDateLayerChange('name', e.target.value);
                  }}
                  className={styles.inputWrap}
                  onFocus={() => onFocus('name')}
                />
              }
            />
            <GridItem
              label={'Description:'}
              item={
                <Input
                  style={{ padding: '0' }}
                  type={'text'}
                  value={layer?.description}
                  autoFocus={lastFocused === 'description'}
                  onChange={(e) => {
                    onDateLayerChange('description', e.target.value);
                  }}
                  className={styles.inputWrap}
                  onFocus={() => onFocus('description')}
                />
              }
            />
            <GridItem
              label={'Value:'}
              item={
                <Input
                  style={{ padding: '0' }}
                  type={'text'}
                  value={layer?.maskValue}
                  className={styles.inputWrap}
                  onChange={(e) => onDateLayerChange('maskValue', e.target.value)}
                />
              }
            />
            <GridItem
              label={'Station:'}
              noBorderBg
              item={
                <Input
                  style={{ padding: '0' }}
                  type={'text'}
                  value={layer?.value}
                  disabled
                  className={styles.inputWrap}
                />
              }
            />
          </GridWrapper>
        </div>
      </PropertySection>
      <>
        {layer && !activeMap && (
          <FontProperties layer={layer} layerType={'pointLocation'} poster={activePoster} />
        )}
        {locationLayer?.positionControl && !activePoster && !activeMap && (
          <PositionControls
            position={locationLayer?.positionControl}
            layer={'pointLocation'}
            poster={activePoster}
          />
        )}
        {locationLayer?.timeControls && !activePoster && !activeMap && (
          <TimeControlsPanel
            timeControls={locationLayer?.timeControls}
            layer={ElementsEnum.POINT_LOCATION}
          />
        )}
        {layer && !activeMap && (
          <BoxStyle box={layer?.boxDef} elementType={ElementsEnum.POINT_LOCATION} />
        )}
      </>
    </Panel>
  );
};
