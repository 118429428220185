import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';

export interface FADE_OUTProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  duration?: number;
  exitTime?: number;
  currentTime?: number;
  opacity?: number;
}
const FADE_OUT: FC<FADE_OUTProps> = ({
  duration = 0,
  exitTime,
  currentTime = 0,
  opacity = 1,
  ...props
}: FADE_OUTProps) => {
  let remaining = (exitTime ?? 0) - currentTime;
  remaining = (remaining * 100) / duration / 100;
  const opacityLevel = Math.min(Math.max(remaining, 0), 1) * opacity;
  return <div style={{ opacity: opacityLevel, height: '100%' }}>{props.children}</div>;
};
export default FADE_OUT;
