import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Rnd } from 'react-rnd';

import { useScaleFactor } from '../../hooks/useScaleFactor';
import { MAX_FULLSCREEN_HEIGHT } from '../../model/constants/constants';
import { PositionControlDef } from '../../model/definitions/PositionControlDef';
import { ActiveDef } from '../../store/slices/active-slice';
import { RootState } from '../../store/store';
import { transformPercentToAbsolute } from './utils';

interface PosterMarkerProps {
  position: PositionControlDef;
}
export const PosterMarker: FC<PosterMarkerProps> = ({ position }) => {
  const { activeAspectRatio } = useSelector<RootState>((state) => state.active) as ActiveDef;
  const { w, h, x, y } = position;
  const scaleFactor = useScaleFactor(false);

  return (
    <Rnd
      scale={scaleFactor}
      style={{ border: '1px solid pink' }}
      size={{
        width: transformPercentToAbsolute(w, activeAspectRatio, 'width', MAX_FULLSCREEN_HEIGHT, {
          w: undefined,
          h: undefined,
        }),
        height: transformPercentToAbsolute(h, activeAspectRatio, 'height', MAX_FULLSCREEN_HEIGHT, {
          w: undefined,
          h: undefined,
        }),
      }}
      position={{
        x: transformPercentToAbsolute(x, activeAspectRatio, 'width', MAX_FULLSCREEN_HEIGHT, {
          w: undefined,
          h: undefined,
        }),
        y: transformPercentToAbsolute(y, activeAspectRatio, 'height', MAX_FULLSCREEN_HEIGHT, {
          w: undefined,
          h: undefined,
        }),
      }}
    />
  );
};
