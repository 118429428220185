import './style.scss';

import { Modal } from 'flowbite-react';
import React, { useRef, useState } from 'react';
import { AiOutlineCamera, AiOutlineClose, AiOutlineUpload } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import { v4 } from 'uuid';

import Button from '../../../atoms/button/Button';
import { screenshotCanvasElement, ScreenshotData } from '../../../helpers/screenshotElement';
import { C9ProjectDef } from '../../../model/definitions/C9ProjectDef';
import { ActiveDef } from '../../../store/slices/active-slice';
import { RootState } from '../../../store/store';
import styles from './SetThumbnailModal.module.scss';

interface Thumbs {
  id: string;
  url: string;
  blob: Blob | null;
}

interface Props {
  opened: boolean;
  onClose: () => void;
  loading: boolean;
  onConfirm: (data: Thumbs[]) => void;
  project: C9ProjectDef;
  type: 'project';
}

const SetThumbnailModal: React.FC<Props> = ({
  opened,
  onClose,
  loading,
  onConfirm,
  project,
  type,
}) => {
  const { activeAspectRatio } = useSelector<RootState>((state) => state.active) as ActiveDef;
  const aspectRatio = activeAspectRatio[0].toString() + ' / ' + activeAspectRatio[1].toString();
  const [thumbnails, setThumbnails] = useState<Thumbs[]>(
    project.thumbnailUrls.map((t) => ({ id: v4(), url: t, blob: null })),
  );

  const [activeThumb, setActiveThumb] = useState(thumbnails[0]?.id);
  const current = thumbnails.find((t) => t.id === activeThumb);
  const [, setThumbnail] = useState<ScreenshotData | null>(null);
  const hiddenFileInput = useRef<HTMLInputElement | null>(null);
  const noThumbnail = current?.url?.includes('no-thumbnail');
  const takeScreenshot = async () => {
    if (thumbnails.length >= 5) return;
    // setLoading(true);
    const data = await screenshotCanvasElement(type);
    setThumbnail(data);
    if (thumbnails.length < 5) {
      const newId = v4();
      setThumbnails((t) => [{ id: newId, url: data.base64, blob: data.blob }, ...t]);
      setActiveThumb(newId);
    }
  };

  function previewClck(id: string) {
    setActiveThumb(id);
  }

  const handleClick = () => {
    hiddenFileInput?.current?.click();
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileUploaded = event.target?.files?.[0];
    if (!fileUploaded) return;
    const reader = new FileReader();
    reader.readAsDataURL(fileUploaded!);
    reader.onload = function () {
      setThumbnail({ base64: reader.result as string, blob: fileUploaded! });
      if (thumbnails.length < 5) {
        const newId = v4();
        setThumbnails((t) => [
          { id: newId, url: reader.result as string, blob: fileUploaded },
          ...t,
        ]);
        setActiveThumb(newId);
      }
    };

    reader.onerror = function (error) {};
  };

  function onDelete(e: React.MouseEvent<HTMLButtonElement>, id: string) {
    e.stopPropagation();
    if (thumbnails.length === 1) return;
    const newThumbs = thumbnails.filter((t) => t.id !== id);
    setThumbnails(newThumbs);
    if (activeThumb === id) setActiveThumb(newThumbs[0]?.id);
  }
  return (
    <Modal show={opened} onClose={onClose}>
      <div className="save-as-template-wrapper">
        <div className="modal-header">
          <Modal.Header>Set project thumbnail?</Modal.Header>
        </div>
        <Modal.Body>
          <div className="flex items-center gap-x-6 mb-3 thumb-wrap">
            {thumbnails.map((t) => (
              <div
                key={t.id}
                className={`cursor-pointer relative col-item rounded-2xl overflow-hidden ${
                  t.url?.includes('no-thumbnail') ? 'thumb' : 'w-fit h-[105px]'
                }`}
                onClick={() => previewClck(t.id)}
                style={{
                  outline: activeThumb === t.id ? '2px solid green' : '',
                  aspectRatio: aspectRatio,
                  // transform: activeThumb === t.id ? "scale(1.3)" : "",
                }}
              >
                <img
                  alt="preview"
                  className={`${t.url?.includes('no-thumbnail') && styles['small-thumb']}`}
                  src={t.url}
                />
                {thumbnails.length > 1 && (
                  <button
                    onClick={(e) => onDelete(e, t.id)}
                    className={`${styles.delete}`}
                    disabled={thumbnails.length === 1}
                  >
                    <AiOutlineClose style={{ color: 'red' }} />
                  </button>
                )}
              </div>
            ))}
            <div className="take-sc-upload-img">
              <p>Upload image or make screenshot</p>
              <div className={`flex items-center mt-2 justify-center`}>
                <Button
                  size="small"
                  buttonType="secondary"
                  onClick={handleClick}
                  icon={<AiOutlineUpload />}
                  disabled={thumbnails.length > 4}
                />
                <input
                  type="file"
                  accept="image/png,image/jpeg"
                  multiple={false}
                  ref={hiddenFileInput}
                  onChange={handleChange}
                  style={{ display: 'none' }}
                />

                <Button
                  size="small"
                  buttonType="secondary"
                  onClick={takeScreenshot}
                  icon={<AiOutlineCamera />}
                  disabled={thumbnails.length > 4}
                />
              </div>
            </div>
          </div>
          <div className="relative">
            <div
              className={`overflow-hidden mx-auto max-h-[292px] ${
                noThumbnail ? 'w-full' : 'max-w-[520px]'
              }`}
              style={{
                aspectRatio: aspectRatio,
              }}
            >
              {thumbnails && thumbnails.length ? (
                <img
                  className="w-full object-cover m-auto"
                  alt="thumbnail"
                  src={current?.url}
                ></img>
              ) : null}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={loading}
            onClick={() => onConfirm(thumbnails)}
            loading={loading}
            label="Save"
          />

          <Button label="Cancel" style={{ backgroundColor: '#808080' }} onClick={onClose} />
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default SetThumbnailModal;
