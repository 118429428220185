import { AnimationsEnum } from '../enums/AnimationsEnum';

export class TimeControlDef {
  startMS = 0;
  endMS: number;
  inAnimationDef: AnimationsEnum = AnimationsEnum.CUT;
  outAnimationDef: AnimationsEnum = AnimationsEnum.CUT;
  inAnimationDuration = 0;
  outAnimationDuration = 0;
  seekToTimeMS = 0;
  constructor(startMs = 0, endMs = 0) {
    this.endMS = endMs;
    this.startMS = startMs;
    this.inAnimationDuration = 0;
    this.outAnimationDuration = 0;
  }
}
