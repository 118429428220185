import { useQuery } from 'react-query';

import { axiosInstance } from '../../../core/api/axiosInstance';

export const key = ['AUDIO'];
export const useGetAudio = (elementKey: Array<string>, id: string) => {
  return useQuery([...key, ...elementKey], async () => {
    const response = await axiosInstance.get(`multimedia/download/${id}`, {
      responseType: 'arraybuffer',
    });
    const blob = new Blob([response.data], { type: response.data.type });
    return URL.createObjectURL(blob);
  });
};
