import './style.scss';

import { Tooltip } from 'flowbite-react';
// import { useHotkeys } from 'react-hotkeys-hook';
import { CgArrowsBreakeH } from 'react-icons/cg';
import { VscZoomIn, VscZoomOut } from 'react-icons/vsc';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../atoms/button/Button';
import { ModeEnum } from '../../core/ui/enums/ModeEnum';
import { ZoomEnum } from '../../core/ui/enums/ZoomEnum';
import { getScenePlaybackLength } from '../../helpers/timelineUtil';
import useRoleAccess from '../../hooks/useRoleAccess';
import { C9ProjectDef } from '../../model/definitions/C9ProjectDef';
import { SceneDef } from '../../model/definitions/SceneDef';
import { RolesEnum } from '../../model/enums/RolesEnum';
import { ActiveDef, setZoom } from '../../store/slices/active-slice';
import { extendSceneDuration } from '../../store/slices/project-slice';
import { selectActiveScene } from '../../store/slices/selectors';
import { RootState } from '../../store/store';

const TimelineActions = () => {
  const dispatch = useDispatch();
  const active = useSelector<RootState>((state) => state.active) as ActiveDef;
  const activeSceneDef = useSelector<RootState, SceneDef | null>((state) =>
    selectActiveScene(state),
  );
  const project = useSelector<RootState>((state) => state.project.present.project) as C9ProjectDef;
  const roleCreator = useRoleAccess(
    [RolesEnum.ROLE_CREATOR],
    project.isSharedEntity,
    project.inEditMode,
  );
  // TODO: Find other shortcuts because these are not supported by useHotkeys
  // useHotkeys('+', (ev) => {
  //   ev.preventDefault();
  //   dispatch(setZoom({ type: ZoomEnum.IN }));
  // });
  // useHotkeys(
  //   '-',
  //   (ev) => {
  //     ev.preventDefault();
  //     if (active.activeZoom === 0) return;
  //     dispatch(setZoom({ type: ZoomEnum.OUT }));
  //   },
  //   [active],
  // );
  // useHotkeys(
  //   '*',
  //   (ev) => {
  //     // ev.preventDefault();
  //     if (active.activeZoom === 0) return;
  //     dispatch(setZoom({ type: ZoomEnum.FIT }));
  //   },
  //   [active],
  // );
  const extendScene = (value: number) => {
    if (activeSceneDef) {
      const totalDuration = getScenePlaybackLength(activeSceneDef, true);
      const sceneExtendDuration: number = activeSceneDef.durationInMS + value * 1000;
      if (totalDuration <= sceneExtendDuration) {
        if (sceneExtendDuration > 0) {
          dispatch(
            extendSceneDuration({
              activeScene: active.activeScene,
              duration: value,
            }),
          );
        }
      } else {
        const val = totalDuration - activeSceneDef.durationInMS;
        dispatch(
          extendSceneDuration({
            activeScene: active.activeScene,
            duration: val / 1000,
          }),
        );
      }
    }
  };
  return (
    <div
      className={`flex items-start timeline-actions ${
        roleCreator ? '' : 'pointer-events-none opacity-30'
      }`}
    >
      {active.mode === ModeEnum.SEQUENCE && (
        <div className={'flex '}>
          <Button
            size={'small'}
            className={'hoverState'}
            label={'-5s'}
            style={{ fontSize: 12 }}
            onClick={() => extendScene(-5)}
          />
          <Button
            size={'small'}
            className={'hoverState'}
            label={'-1s'}
            style={{ fontSize: 12 }}
            onClick={() => extendScene(-1)}
          />
          <Button
            size={'small'}
            className={'hoverState'}
            label={'+1s'}
            style={{ fontSize: 12 }}
            onClick={() => extendScene(1)}
          />
          <Button
            size={'small'}
            className={'hoverState'}
            label={'+5s'}
            style={{ fontSize: 12 }}
            onClick={() => extendScene(5)}
          />
        </div>
      )}
      <Tooltip content="Zoom in" style={`dark`}>
        <Button
          size={'small'}
          className={'hoverState'}
          icon={<VscZoomIn className="text-xl" />}
          style={{ height: 22, width: 44, padding: 0 }}
          onClick={() => {
            dispatch(setZoom({ type: ZoomEnum.IN }));
          }}
        />
      </Tooltip>

      <Tooltip content="Zoom out" style={`dark`}>
        <Button
          disabled={active.activeZoom === 0}
          size={'small'}
          className={'hoverState'}
          icon={<VscZoomOut className="text-xl" />}
          style={{ height: 22, width: 44, padding: 0 }}
          onClick={() => {
            dispatch(setZoom({ type: ZoomEnum.OUT }));
          }}
        />
      </Tooltip>
      <Tooltip content="Fit" style={`dark`}>
        <Button
          size={'small'}
          className={'hoverState'}
          style={{ height: 22, width: 44, padding: 0 }}
          disabled={active.activeZoom === 0}
          icon={<CgArrowsBreakeH className="text-xl" />}
          onClick={() => {
            dispatch(
              setZoom({
                type: ZoomEnum.FIT,
              }),
            );
          }}
        />
      </Tooltip>
    </div>
  );
};

export default TimelineActions;
