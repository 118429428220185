import 'react-datepicker/dist/react-datepicker.css';

import { Input, Select } from 'antd';
import { Label, Textarea, TextInput } from 'flowbite-react';
import React, { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import { AiOutlineClose } from 'react-icons/ai';
import { BsCalendar3 } from 'react-icons/bs';
import { useNavigate, useParams } from 'react-router-dom';

import Button from '../../../atoms/button/Button';
import { MarketplaceItem } from '../../../model/definitions/MarketplaceItem';
import { ExchangeRateResponseDTO } from '../../../model/DTO/ExchangeRateResponseDTO';
import { MarketplaceTemplateEnum } from '../../../model/enums/MarketplaceTemplateEnum';
import CreateCard from '../../marketplace-new/atoms/CreateCard/CreateCard';
import { useGetCurrency } from '../../marketplace-new/hooks/useGetCurrency';
import { useGetCurrencyOptions } from '../../marketplace-new/hooks/useGetCurrencyOptions';
import Heading from '../../marketplace-new/molecules/heading/Heading';
import { useGetTemplate } from '../queries-NEW/useGetTemplate';
import { usePublishToMarketplace } from '../queries-NEW/usePublishToMarketplace';
import Sorter from './components/Sorter';
import ThumbSliderDetails from './components/ThumbSliderDetails';

interface DetailsPageWsInterface {
  preview?: boolean;
}

export const getTemplateType = (type: string) => {
  switch (type) {
    case 'project':
      return MarketplaceTemplateEnum.PROJECT_TEMPLATE;
    case 'scene':
      return MarketplaceTemplateEnum.SCENE_TEMPLATE;
    case 'map':
      return MarketplaceTemplateEnum.MAP_TEMPLATE;
    case 'custom_map':
      return MarketplaceTemplateEnum.CUSTOM_MAP_TEMPLATE;
    case 'custom_vector':
      return MarketplaceTemplateEnum.CUSTOM_VECTOR_TEMPLATE;
    case 'poster':
      return MarketplaceTemplateEnum.WEATHER_POSTER_TEMPLATE;
    case 'geoposter':
      return MarketplaceTemplateEnum.WEATHER_GEO_POSTER_TEMPLATE;
    case 'image':
      return MarketplaceTemplateEnum.IMAGE_TEMPLATE;
    case 'video':
      return MarketplaceTemplateEnum.VIDEO_TEMPLATE;
    case 'audio':
      return MarketplaceTemplateEnum.AUDIO_TEMPLATE;
    case 'animation':
      return MarketplaceTemplateEnum.ANIMATION_TEMPLATE;
    case 'icon-set':
      return MarketplaceTemplateEnum.ICON_SET_TEMPLATE;
    default:
      return MarketplaceTemplateEnum.PROJECT_TEMPLATE;
  }
};

const DetailsPageWS = ({ preview }: DetailsPageWsInterface) => {
  const { type, id } = useParams() as unknown as { type: string; id: string };
  const navigate = useNavigate();
  const getLink = (): string => {
    switch (type) {
      case 'project':
        return 'project-template/current';
      case 'map':
        return 'map-template/current';
      case 'scene':
        return 'scene-template/current';
      case 'poster':
        return 'weather-poster-template/current';
      case 'geoposter':
        return 'weather-geo-poster-template/current';
      case 'image':
        return 'multimedia/IMAGE/current-template';
      case 'video':
        return 'multimedia/VIDEO/current-template';
      case 'audio':
        return 'multimedia/AUDIO/current-template';
      case 'custom_map':
        return 'multimedia/CUSTOM_MAP/current-template';
      case 'custom_vector':
        return 'multimedia/CUSTOM_VECTOR/current-template';
      case 'layer':
        return 'layer-template/current';
      case 'animation':
        return 'multimedia/ANIMATION/current-template';
      case 'symbol':
        return 'multimedia/SYMBOL/current-template';
      case 'icon-set':
        return 'icon-set-template/current';
      default:
        return 'project-template';
    }
  };

  const { data } = useGetTemplate(getLink(), id);
  const { mutate } = usePublishToMarketplace();
  const tagInputRef = useRef<HTMLInputElement>(null);
  const [temp, setTemp] = useState<MarketplaceItem>(
    new MarketplaceItem({
      name: data?.name,
      description: data?.description,
      enterpriseAccountId: data?.enterpriseAccountId,
      isSharedEntity: data?.isSharedEntity,
      creator: data?.createdBy,
      templateType: getTemplateType(type),
    }),
  );
  const [price, setPrice] = useState<string>(data?.price || '0.00');
  const [uploading, setUploading] = useState<Array<File>>([]);
  const { data: currencyOptions } = useGetCurrencyOptions();
  const { data: currency } = useGetCurrency();
  const [itemCurrency, setItemCurrency] = useState(currency);

  useEffect(() => {
    setItemCurrency(currency);
  }, [currency]);

  const handleCurrencyChange = (value: string) => {
    const newCurrency = currencyOptions?.find((item: ExchangeRateResponseDTO) => {
      return item.code === value;
    });
    setItemCurrency(newCurrency);
  };

  useEffect(
    () =>
      setTemp(
        new MarketplaceItem({
          name: data?.name,
          description: data?.description,
          enterpriseAccountId: data?.enterpriseAccountId,
          isSharedEntity: data?.isSharedEntity,
          creator: data?.createdBy,
          templateType: getTemplateType(type),
        }),
      ),
    [data],
  );

  const removeTag = (item: string) => {
    const newArray = temp.tags.filter((value) => value != item);
    setTemp({ ...temp, tags: newArray });
  };
  const renderTags = () =>
    temp.tags?.map((item) => (
      <div className={'ws-tag'} key={item}>
        {item}
        <AiOutlineClose onClick={() => removeTag(item)} className={'ws-tag-remove'} />
      </div>
    ));
  const addTag = (e: string) => {
    if (e.endsWith(' ')) {
      const added = [...temp.tags, e.substring(0, e.length - 1)];
      e.length > 1 && setTemp({ ...temp, tags: added });
      if (tagInputRef.current) tagInputRef.current.value = '';
    }
  };
  const onEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && tagInputRef.current?.value) {
      const added = [...temp.tags, tagInputRef.current?.value];
      setTemp({ ...temp, tags: added });
      if (tagInputRef.current) tagInputRef.current.value = '';
    }
  };
  const onAddFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const list = e.target.files;
    const files = [...uploading];
    if (list)
      for (let i = 0; i < list.length; i++) {
        const file = list.item(i);
        file && files.push(file);
      }
    setUploading(files);
  };
  const publish = async () => {
    const form = new FormData();
    let item;
    switch (type) {
      case 'project':
        item = {
          ...temp,
          projectDefTemplate: data,
          templateType: MarketplaceTemplateEnum.PROJECT_TEMPLATE,
        };
        break;
      case 'scene':
        item = {
          ...temp,
          sceneDefTemplate: data,
          templateType: MarketplaceTemplateEnum.SCENE_TEMPLATE,
        };
        break;
      case 'map':
        item = {
          ...temp,
          mapDefTemplate: data,
          templateType: MarketplaceTemplateEnum.MAP_TEMPLATE,
        };
        break;
      case 'custom_map':
        item = {
          ...temp,
          customMapDefTemplate: data,
          templateType: MarketplaceTemplateEnum.CUSTOM_MAP_TEMPLATE,
        };
        break;
      case 'custom_vector':
        item = {
          ...temp,
          customVectorDefTemplate: data,
          templateType: MarketplaceTemplateEnum.CUSTOM_VECTOR_TEMPLATE,
        };
        break;
      case 'poster':
        item = {
          ...temp,
          wPosterDefTemplate: data,
          templateType: MarketplaceTemplateEnum.WEATHER_POSTER_TEMPLATE,
        };
        break;
      case 'geoposter':
        item = {
          ...temp,
          wGeoPosterDefTemplate: data,
          templateType: MarketplaceTemplateEnum.WEATHER_GEO_POSTER_TEMPLATE,
        };
        break;
      case 'icon-set':
        item = {
          ...temp,
          iconSetTemplateDef: data,
          templateType: MarketplaceTemplateEnum.ICON_SET_TEMPLATE,
        };
        break;
      case 'image':
        item = {
          ...temp,
          imagePanelDefTemplate: data,
          templateType: MarketplaceTemplateEnum.IMAGE_TEMPLATE,
        };
        break;
      case 'video':
        item = {
          ...temp,
          videoPanelDefTemplate: data,
          templateType: MarketplaceTemplateEnum.VIDEO_TEMPLATE,
        };
        break;
      case 'audio':
        item = {
          ...temp,
          audioElementTemplate: data,
          templateType: MarketplaceTemplateEnum.AUDIO_TEMPLATE,
        };
        break;
      case 'animation':
        item = {
          ...temp,
          animationPanelDefTemplate: data,
          templateType: MarketplaceTemplateEnum.ANIMATION_TEMPLATE,
        };
        break;
    }
    form.append(
      'request',
      JSON.stringify({
        ...item,
        price: price ? (parseFloat(price) / itemCurrency.exchangeRateUsd).toFixed(2) : 0,
        templateId: '',
        templateVersionId: '',
      }),
    );
    uploading.forEach((file) => {
      file && form.append('thumbnailFiles', file);
    });
    mutate({ data: form });
  };

  const onPurchase = () => {
    navigate(`/workspace/templates/project/project-template/${id}`);
  };

  const handleNumber = (e: React.FormEvent<HTMLInputElement>) => {
    const input = e.currentTarget.value;
    if (input.match(/^([0-9]{1,})?(\.)?([0-9]{1,2})?$/)) setPrice(input);
  };

  const handleFloat = () => {
    setPrice(price ? parseFloat(price).toFixed(2) + '' : '0.00');
  };

  return (
    <div className={'ws-details'}>
      <Heading title={temp?.name} editable onChange={(e) => setTemp({ ...temp, name: e })} />
      <div className="thumb-wrapper flex">
        <div className="thumb-slider">
          <ThumbSliderDetails thumbs={data?.thumbnailUrls} />
        </div>
        <div className="aside">
          {!preview && (
            <>
              <div className="confirm-thumb">
                <h2>Edit thumbnails</h2>
              </div>
              <br />
              <div className="flex gap-x-4 choose-file">
                <input type={'file'} onChange={onAddFile} multiple={true} />
              </div>
              <Sorter files={uploading} setFiles={(e) => setUploading(e)} />
            </>
          )}

          <div className={'ws-central'}>
            <Textarea
              rows={8}
              value={temp?.description}
              className={`ws-details-textarea ${
                preview && data?.description?.length === 0 && 'hidden'
              }`}
              onChange={(e) => setTemp({ ...temp, description: e.target.value })}
              disabled={preview}
            />
            <div className={'ws-tag-area'}>
              {!preview && (
                <TextInput
                  ref={tagInputRef}
                  className={'my-3 bg-gray-500'}
                  placeholder={'Add tags here'}
                  onChange={(e) => addTag(e.target.value)}
                  onKeyDown={(e) => onEnter(e)}
                />
              )}
              <div className={'ws-tags-container'}>{renderTags()}</div>
              {type === 'project' && <CreateCard onClick={onPurchase} />}
            </div>
          </div>
          {!preview && (
            <>
              <h2>Additional data</h2>
              <div className={'flex w-full justify-between gap-4'}>
                <div style={{ width: '50%' }}>
                  <Label>Available from</Label>
                  <div className={'ws-calendar-input'}>
                    <DatePicker
                      showTimeInput
                      minDate={new Date()}
                      minTime={new Date()}
                      dateFormatCalendar={'MMM yyyy'}
                      dateFormat="dd.MM.yyyy HH:ss"
                      selected={temp.availableFrom ? new Date(temp.availableFrom) : new Date()}
                      onChange={(date: Date) => {
                        setTemp({
                          ...temp,
                          availableFrom: new Date(date).getTime(),
                        });
                        if (
                          (temp.availableTo && temp.availableFrom > temp.availableTo) ||
                          !temp.availableTo
                        )
                          setTemp({
                            ...temp,
                            availableFrom: new Date(date).getTime(),
                          });
                      }}
                      customInput={<TextInput className={'w-full'} type={'text'} />}
                    />
                    <BsCalendar3 className={'calendar-icon'} size={24} />
                  </div>
                </div>
                <div style={{ width: '50%' }}>
                  <Label>Available to</Label>
                  <div className={'ws-calendar-input'}>
                    <DatePicker
                      showTimeInput
                      minDate={temp.availableFrom ? new Date(temp.availableFrom) : new Date()}
                      minTime={new Date()}
                      dateFormatCalendar={'MMM yyyy'}
                      dateFormat="dd.MM.yyyy HH:ss"
                      selected={temp.availableTo ? new Date(temp.availableTo) : null}
                      onChange={(date: Date) =>
                        setTemp({ ...temp, availableTo: new Date(date).getTime() })
                      }
                      customInput={<TextInput className={'w-full'} />}
                    />
                    <BsCalendar3 className={'calendar-icon'} size={24} />
                  </div>
                </div>
              </div>
              <div className={'flex w-full justify-between gap-4'}>
                <div style={{ width: '50%' }}>
                  <Label>Price</Label>
                  <Input value={price} onChange={handleNumber} onBlur={handleFloat} />
                </div>
                <div style={{ width: '50%' }}>
                  <Label>Currency</Label>
                  <Select
                    defaultValue={currency?.code ?? 'USD'}
                    showSearch={true}
                    onChange={(value) => handleCurrencyChange(value)}
                    options={currencyOptions}
                    fieldNames={{
                      value: 'code',
                      label: 'code',
                    }}
                    optionLabelProp="label"
                  />
                </div>
              </div>
            </>
          )}
          {!preview && (
            <div className={'flex justify-end my-8'}>
              <Button buttonType="primary" label="Publish" onClick={() => publish()} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DetailsPageWS;
