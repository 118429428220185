import { useMutation } from 'react-query';

import { WeatherPosterDef } from '../../../../model/definitions/WeatherPosterDef';
import { axiosInstance } from '../../axiosInstance';

export const useApplyBiasGroupOnPoster = () =>
  useMutation(
    async ({
      type,
      biasGroupId,
      body,
      posterType,
    }: {
      type: 'OBSERVED' | 'FORECAST';
      biasGroupId: string;
      body: WeatherPosterDef;
      posterType: 'weatherPoster' | 'weatherGeoPoster';
    }): Promise<WeatherPosterDef> => {
      const { data } = await axiosInstance.put(
        `biasGroup/apply/${type}/${biasGroupId}/${posterType}`,
        body,
      );
      return data;
    },
  );
