import { ExclamationCircleFilled, ScheduleOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Empty, Input, InputNumber, Modal, Row, Select } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
// import SchedulersTable from './SchedulersTable';
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { v4 } from 'uuid';

import { getProjectDetails } from '../../../../core/api/ProjectsAPI';
import { C9ProjectDef } from '../../../../model/definitions/C9ProjectDef';
import { SchedulerDTO } from '../../../../model/DTO/SchedulerDTO';
import { RootState } from '../../../../store/store';
import { useGetEnterprise } from '../../../dataprovider/hooks/useGetEnterprise';
import { SqsInterface } from '../../enterprise-settings/ThinClientDetails';
import { useCreateScheduler } from '../../queries-NEW/useCreateScheduler';
import { useDeleteScheduler } from '../../queries-NEW/useDeleteScheduler';
import { useEditScheduler } from '../../queries-NEW/useEditScheduler';
import { useGetCrons } from '../../queries-NEW/useGetCrons';
import { useGetSqsConfigs } from '../../queries-NEW/useGetSqsConfigs';
import Cron from '../../templates-NEW/react-cron-generator/cron';
import { formatSqs } from '../../templates-NEW/RenderDetails';

const { confirm } = Modal;

const CronDetails = () => {
  const { data: provider } = useGetEnterprise();
  const enterpriseAccountId = provider?.id;
  const { data: sqsList, isLoading } = useGetSqsConfigs(enterpriseAccountId);
  const projectId = useSelector<RootState, string>((state) => state.project.present.project.id);
  const [projectData, setProjectData] = useState<any>();
  const [value, setValue] = useState('');
  const [schName, setSchName] = useState('');
  const [schDesc, setSchDesc] = useState('');
  const [schMap, setSchMap] = useState(100);
  const [schRad, setSchRad] = useState(100);
  const [schSat, setSchSat] = useState(100);
  const [selectedCron, setSelectedCron] = useState<SchedulerDTO>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sendToThinClient, setSendToThinClient] = useState<boolean>(false);
  const [sqsConfigs, setSqsConfigs] = useState<string[]>([]);
  const { mutate: createScheduler } = useCreateScheduler();
  const { mutate: editScheduler } = useEditScheduler();
  const mutation = useMutation(getProjectDetails, {
    onSuccess: (data: C9ProjectDef) => {
      setProjectData(data);
    },
  });

  useEffect(() => {
    if (projectId) {
      mutation.mutate(projectId);
    }
  }, [projectId]);

  const { data: crons, isLoading: isLoadingSchedulers } = useGetCrons(projectId);

  const handleCreateScheduler = () => {
    // setCronVisible(true);
    const schedulerData = {
      name: schName,
      description: schDesc,
      cronExpression: value,
      projectId: projectId,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      offsets: {
        mapLayer: schMap,
        radarLayer: schRad,
        satelliteLayer: schSat,
      },
      sendToThinClient,
      sqsConfigs: formatSqs(sqsConfigs),
    };
    createScheduler(schedulerData);
  };

  const handleEditScheduler = () => {
    const schedulerData = {
      id: selectedCron?.id ?? '',
      name: schName,
      description: schDesc,
      cronExpression: value,
      projectId: projectId,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      offsets: {
        mapLayer: schMap,
        radarLayer: schRad,
        satelliteLayer: schSat,
      },
      sendToThinClient,
      sqsConfigs: formatSqs(sqsConfigs),
    };
    editScheduler(schedulerData);
    setIsModalOpen(false);
    setSelectedCron(undefined);
    resetModalData();
  };

  const showModal = () => {
    setSchName(projectData.name + ` - scheduler ${v4().slice(0, 5)}`);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    if (!schName) return;
    if (selectedCron) {
      handleEditScheduler();
    } else {
      handleCreateScheduler();
    }
    setIsModalOpen(false);
    resetModalData();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    resetModalData();
  };

  const resetModalData = () => {
    setSchName('');
    setSchDesc('');
    setSchMap(100);
    setSchRad(100);
    setSchSat(100);
    setValue('');
    setSendToThinClient(false);
    setSqsConfigs([]);
    setSelectedCron(undefined);
  };
  const { mutate: deleteCron } = useDeleteScheduler();

  const handleEditModalOpen = (cron: any) => {
    setSelectedCron(cron);
    setSchName(cron.name ?? '');
    setSchDesc(cron.description ?? '');
    setSchMap(cron.offsets?.mapLayer ?? 100);
    setSchRad(cron.offsets?.radarLayer ?? 100);
    setSchSat(cron.offsets?.satelliteLayer ?? 100);
    setValue(cron.cronExpression);
    setSendToThinClient(cron.sendToThinClient);
    const sqs = cron.sqsConfigs.map((s: SqsInterface) => {
      return s.id;
    });
    setSqsConfigs(sqs);
    setIsModalOpen(true);
  };
  const handleDelete = (id: string) => {
    confirm({
      title: 'Delete schedule',
      icon: <ExclamationCircleFilled />,
      content: 'Are you sure you want to delete this item?',
      centered: true,
      okText: 'Delete',
      okType: 'danger',
      className: 'dark-modal',
      onOk() {
        deleteCron(id);
      },
      onCancel() {},
    });
  };

  return (
    <>
      <Modal
        className="studio-cron-modal cron-modal"
        title="ADD SCHEDULE"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose
        width="750px"
      >
        <div className="cron-wrapper">
          <Row gutter={[16, 12]}>
            <Col xs={24} sm={12}>
              <span style={{ color: 'red' }}>*</span> Name
              <Input
                onChange={(e) => setSchName(e.target.value)}
                value={schName}
                placeholder="Type schedule name..."
              />
            </Col>
            <Col xs={24} sm={12}>
              Description
              <Input
                onChange={(e) => setSchDesc(e.target.value)}
                value={schDesc}
                placeholder="Type description..."
              />
            </Col>
            <Col xs={24} sm={4}>
              <span style={{ color: 'red' }}>*</span> Map Layer
              <InputNumber addonAfter="%" value={schMap} onChange={(e) => setSchMap(e || 0)} />
            </Col>
            <Col xs={24} sm={4}>
              <span style={{ color: 'red' }}>*</span> Radar Layer
              <InputNumber addonAfter="%" value={schRad} onChange={(e) => setSchRad(e || 0)} />
            </Col>
            <Col xs={24} sm={4}>
              <span style={{ color: 'red' }}>*</span> Satellite Layer
              <InputNumber addonAfter="%" value={schSat} onChange={(e) => setSchSat(e || 0)} />
            </Col>
            <Col xs={24}>
              <span>Send to thin client</span>
              <Checkbox
                className="ml-2"
                checked={sendToThinClient}
                onChange={(e) => {
                  setSendToThinClient(e.target.checked);
                }}
              />
            </Col>
            <Col xs={24} className={`${sendToThinClient ? 'block' : 'hidden'}`}>
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="Please select"
                defaultValue={sqsConfigs}
                loading={isLoading}
                onChange={(e) => {
                  setSqsConfigs(e);
                }}
                options={sqsList?.map((sqs: { queueName: string; id: string }) => {
                  return {
                    label: sqs.queueName,
                    value: sqs.id,
                  };
                })}
              />
            </Col>
          </Row>
          <Cron
            onChange={(value: any) => {
              setValue(value);
            }}
            value={value}
            showResultText={true}
            showResultCron={true}
          />
          <span className="footnote">
            *If the project has observed data, it is necessary to select the job execution time
            after the time specified for observed data
          </span>
        </div>
      </Modal>
      <div className="sch-list">
        <div className="add-cron-button">
          <Button
            type="primary"
            icon={
              <span style={{ marginRight: '1rem' }}>
                <ScheduleOutlined />
              </span>
            }
            onClick={showModal}
            size="large"
            loading={!projectData}
          >
            ADD SCHEDULE
          </Button>
        </div>
        <div style={{ marginTop: '1rem' }}>
          {!isLoadingSchedulers && crons && crons.length > 0 ? (
            crons.map((cron: any, i: number) => (
              <div className="schedule-wrapper" key={i}>
                <div className="schedule-card">
                  <h3>{cron.name}</h3>
                  <p>{cron.description}</p>
                  <br />
                  <div className="schedule-and-actions">
                    <div className="schedule">
                      {cron.nextFireTime && (
                        <span className="cr-date">
                          <b>Next time of execution:</b>
                          <em>{dayjs(cron?.nextFireTime).format('DD/MM/YYYY HH:mm:ss')}</em>
                        </span>
                      )}
                      <span className="md-date">
                        <b>Scheduler starting time / Occurrence and frequency:</b>{' '}
                        <em>{cron.cronExpression}</em>
                      </span>
                    </div>
                    <div className="schedule-actions">
                      <Button
                        type="text"
                        icon={
                          <span>
                            <AiOutlineEdit />
                          </span>
                        }
                        onClick={() => handleEditModalOpen(cron)}
                      >
                        Edit
                      </Button>
                      <Button
                        type="text"
                        style={{ color: '#fa5656' }}
                        icon={
                          <span>
                            <AiOutlineDelete style={{ color: '#fa5656' }} />
                          </span>
                        }
                        onClick={() => handleDelete(cron.id)}
                      >
                        Delete
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </div>
      </div>
    </>
  );
};

export default CronDetails;
