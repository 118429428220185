import { Checkbox } from 'antd';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import Range from 'rc-slider';
import { HandleProps } from 'rc-slider/lib/Handles/Handle';
import { MarkObj } from 'rc-slider/lib/Marks';
import { ReactElement, ReactNode, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { PointDataFrameDef } from '../../../model/definitions/PointDataFrameDef';
import { SceneDef } from '../../../model/definitions/SceneDef';
import { selectActiveScene } from '../../../store/slices/selectors';
import { RootState } from '../../../store/store';

interface Props {
  timestamps: PointDataFrameDef[];
  defaultValue: PointDataFrameDef[] | undefined;
  isObserved: boolean;
  onValueChange: (val: PointDataFrameDef[]) => void;
}

const dotStyle = {
  width: 0.1,
  borderRadius: 0,
  border: 0,
};

export const PointTimestampsRange = ({
  timestamps,
  defaultValue,
  isObserved,
  onValueChange,
}: Props) => {
  const activeSceneDef = useSelector<RootState, SceneDef | null>((state) =>
    selectActiveScene(state),
  );
  const [isDateDriven, setIsDateDriven] = useState(
    !!(activeSceneDef?.startDate && activeSceneDef?.endDate),
  );
  const [range, setRange] = useState<number[]>([]);

  dayjs.extend(utc);
  dayjs.extend(timezone);
  useEffect(() => {
    const startDate = activeSceneDef?.startDate;
    const endDate = activeSceneDef?.endDate;
    if (timestamps && startDate && endDate && isDateDriven) {
      const framesInRange = timestamps.filter(
        (frame) => frame.startDate >= startDate / 1000 && frame.endDate <= endDate / 1000,
      );
      const firstFrame = framesInRange[0];
      const lastFrame = framesInRange[framesInRange.length - 1];
      const indexOne = timestamps.findIndex((x) => x?.startDate === firstFrame?.startDate);
      const indexLast = timestamps.findIndex((x) => x?.endDate === lastFrame?.endDate);
      if (isDateDriven) {
        console.log('set', framesInRange?.slice(indexOne, indexLast + 1));
        setRange([indexOne, indexLast]);
        onValueChange(framesInRange?.slice(indexOne, indexLast + 1));
      }
    } else {
      if (defaultValue && defaultValue.length > 1) {
        const result: number[] = [];
        timestamps.forEach((timestamp, index) => {
          if (defaultValue[0].dateString === timestamp.dateString) {
            result.push(index);
          }
          if (defaultValue[defaultValue.length - 1].dateString === timestamp.dateString) {
            result.push(index);
          }
        });
        setRange(result);
      } else {
        const lastIndex = timestamps.length - 1;
        const value = isObserved ? [lastIndex - 1, lastIndex] : [0, 1];
        setRange(value);
      }
    }
  }, [timestamps, activeSceneDef, isDateDriven, onValueChange]);

  const sliderMarks = useMemo(() => {
    const marks: Record<number, MarkObj> = {};
    timestamps
      .sort((a, b) => {
        const dateA = new Date(a.startDate);
        const dateB = new Date(b.startDate);
        return dateA.getTime() - dateB.getTime();
      })
      .forEach((t, i) => {
        marks[i] = {
          label: dayjs.unix(t.startDate).utc().tz('UTC').format('dddd DD. MMM HH:mm z'),
          style: {
            top: -40,
            display: i === 0 || i === timestamps.length - 1 ? 'block' : 'none',
            padding: '2px 0',
            borderRadius: 0,
          },
        };
      });
    return marks;
  }, [timestamps]);

  const sliderHandle = (e: ReactElement<HandleProps>, key: number, value: ReactNode) => {
    return {
      ...e,
      props: {
        ...e.props,
        style: {
          ...e.props.style,
          top: 20,
          boxShadow: 'none',
          width: 20,
          height: 20,
          borderRadius: 0,
          backgroundColor: '#0054ff',
          transform: 'translateX(-50%) rotate(-45deg)',
        },
        children: [
          <div
            key={key}
            style={{
              top: -5,
              transform: 'translateX(-50%) rotate(45deg)',
              position: 'relative',
              width: 'fit-content',
              backgroundColor: '#0054ff',
              color: '#ffffff',
              padding: 2,
              borderRadius: 3,
            }}
          >
            {value}
          </div>,
        ],
      },
    };
  };

  return (
    <div id="FramesRange">
      <div className={'flex gap-2 items-center'}>
        <Checkbox
          disabled={!(activeSceneDef?.startDate && activeSceneDef?.endDate)}
          checked={isDateDriven}
          onChange={(e) => setIsDateDriven(e.target.checked)}
          className={'!min-w-[0px]'}
        />
        <span
          className={`${
            !(activeSceneDef?.startDate && activeSceneDef?.endDate) && 'text-gray-600'
          }`}
        >
          Use scene timeframe
        </span>
      </div>
      {!isDateDriven && (
        <Range
          value={range}
          draggableTrack
          range={true}
          className="frames-range"
          min={0}
          max={timestamps.length - 1}
          marks={sliderMarks}
          dotStyle={dotStyle}
          handleRender={(props, origin) =>
            sliderHandle(props, origin.index, sliderMarks[origin.value]?.label)
          }
          onChange={(e: number | number[]) => {
            if (Array.isArray(e)) {
              // console.log(e);
              onValueChange(
                e[1] - e[0] >= 1
                  ? timestamps.slice(e[0], e[1] + 1)
                  : timestamps.slice(e[0], e[0] + 1),
              );
              setRange(e);
            }
          }}
        />
      )}
    </div>
  );
};
