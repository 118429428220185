import { useEffect, useRef } from 'react';

export const useDidUpdateEffect: typeof useEffect = (effect, deps) => {
  const isFirstMount = useRef(true);

  useEffect(() => {
    if (!isFirstMount.current) {
      return effect();
    }
    isFirstMount.current = false;
  }, deps);
};
