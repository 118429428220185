import './style.scss';

import { Col, Collapse, Input, Modal, Row, Select, Switch } from 'antd';
import { useEffect, useState } from 'react';

import { AccountDTO } from '../../model/DTO/AccountDTO';
import { AccountTypeEnum } from '../../model/enums/AccountTypeEnum';
import { RolesEnum } from '../../model/enums/RolesEnum';
import { useAddRoles } from '../../pages/dashboard/queries-NEW/useAddRoles';
import { useCreateAccount } from '../../pages/dashboard/queries-NEW/useCreateAccount';
import { useDeleteRoles } from '../../pages/dashboard/queries-NEW/useDeleteRoles';
import { useEditAccount } from '../../pages/dashboard/queries-NEW/useEditAccount';
import { useGetUserRoles } from '../../pages/dashboard/queries-NEW/useGetUserRoles';

const { Panel } = Collapse;

type UserActionsModalProps = {
  opened: boolean;
  action: string;
  type: AccountTypeEnum;
  onClose: () => void;
  existingUser?: AccountDTO | null;
};

const excludedRoles = [
  RolesEnum.ROLE_SUPER_ADMIN,
  RolesEnum.ROLE_ADMIN,
  RolesEnum.ROLE_PLATFORM_ADMIN,
  RolesEnum.ROLE_ACCOUNT_OWNER,
  RolesEnum.ROLE_ENTERPRISE_USER,
  RolesEnum.ROLE_USER,
];

export const UserActionsModal = ({
  opened,
  action,
  type,
  onClose,
  existingUser,
}: UserActionsModalProps) => {
  const [user, setUser] = useState<AccountDTO>({
    firstName: '',
    lastName: '',
    description: '',
    isEnabled: true,
  });
  const [file, setFile] = useState<File>();
  const [roles, setRoles] = useState<RolesEnum[]>([]);
  const { mutate: createAccount, isLoading: createAccountLoading } = useCreateAccount();
  const { mutate: editAccount, isLoading: editAccountLoading } = useEditAccount();
  const { mutate: addRoles } = useAddRoles();
  const { mutate: deleteRoles } = useDeleteRoles();
  const { data: userRoles } = useGetUserRoles(type, existingUser?.id);
  const apiMap = new Map([
    [AccountTypeEnum.ENTERPRISE, 'enterprise-accounts'],
    [AccountTypeEnum.DATA_PROVIDER, 'provider-accounts'],
    [AccountTypeEnum.ENTERPRISE_USER, 'enterprise-users'],
  ]);

  const updateUser = (property: keyof AccountDTO, value: string | boolean | File) => {
    setUser((prevUser) => ({ ...prevUser, [property]: value }));
  };

  const handleFile = (value: File) => {
    setFile(value);
  };

  const handleSelect = (value: RolesEnum[]) => {
    const uniqueRoles = Array.from(new Set([...roles, ...value]));
    setRoles(uniqueRoles);
  };

  const handleDeselect = (value: RolesEnum) => {
    const updatedRoles = roles.filter((role) => !value.includes(role));
    setRoles(updatedRoles);
  };

  const handleCancel = () => {
    onClose();
    resetModalData();
  };

  const resetModalData = () => {
    setUser({
      firstName: '',
      lastName: '',
      description: '',
      isEnabled: true,
    });
    setRoles([]);
  };

  const handleOk = () => {
    const formData = new FormData();
    formData.append(
      type === AccountTypeEnum.DATA_PROVIDER
        ? 'providerAccount'
        : type === AccountTypeEnum.ENTERPRISE
        ? 'enterpriseAccount'
        : 'enterpriseUser',
      JSON.stringify(user),
    );
    if (file) {
      formData.append('logo', file);
    }
    if (user.id) {
      editAccount(
        { api: apiMap.get(type) ?? '', id: user.id, formData },
        {
          onSuccess: (data) => {
            if (type === AccountTypeEnum.ENTERPRISE_USER) {
              const newRoles = roles.filter((r: RolesEnum) => !userRoles.includes(r));
              const removeDefaultRole = userRoles.filter(
                (role: string) => role !== 'default-roles-c9poc',
              );
              const deletedRoles = removeDefaultRole.filter((r: RolesEnum) => !roles.includes(r));
              if (newRoles.length > 0) {
                addRoles(
                  { id: data.id, roles: newRoles },
                  {
                    onSuccess: () => {
                      onClose();
                      resetModalData();
                    },
                  },
                );
              }
              if (deletedRoles.length > 0) {
                deleteRoles(
                  { id: data.id, roles: deletedRoles },
                  {
                    onSuccess: () => {
                      onClose();
                      resetModalData();
                    },
                  },
                );
              }
            } else {
              onClose();
              resetModalData();
            }
          },
        },
      );
    } else {
      createAccount(
        { api: apiMap.get(type) ?? '', formData },
        {
          onSuccess: (data) => {
            if (type === AccountTypeEnum.ENTERPRISE_USER && roles.length > 0) {
              addRoles(
                { id: data.id, roles },
                {
                  onSuccess: () => {
                    onClose();
                    resetModalData();
                  },
                },
              );
            } else {
              onClose();
              resetModalData();
            }
          },
        },
      );
    }
  };

  const okButtonDisabled = () => {
    if (type === AccountTypeEnum.ENTERPRISE_USER) {
      return !!(!user.firstName || !user.lastName || !user.email);
    } else {
      return !!(!user.firstName || !user.lastName || !user.ownerEmail);
    }
  };

  useEffect(() => {
    if (existingUser) {
      setUser(existingUser);
    }
  }, [existingUser]);

  useEffect(() => {
    if (userRoles) {
      const roles = userRoles.filter((role: string) => role !== 'default-roles-c9poc');
      setRoles(roles);
    } else {
      setRoles([]);
    }
  }, [userRoles]);

  return (
    <>
      <Modal
        title={action.toUpperCase() + ' ' + type}
        open={opened}
        onOk={handleOk}
        okButtonProps={{ disabled: okButtonDisabled() }}
        confirmLoading={createAccountLoading || editAccountLoading}
        onCancel={handleCancel}
        destroyOnClose
        width="750px"
        style={{ top: 20 }}
      >
        <div className="user-actions-modal">
          <Row gutter={[16, 12]}>
            {type === AccountTypeEnum.ENTERPRISE_USER ? (
              <>
                <Col xs={24} sm={12}>
                  <span style={{ color: 'red' }}>*</span> First Name
                  <Input
                    onChange={(e) => {
                      updateUser('firstName', e.target.value);
                    }}
                    value={user.firstName}
                    placeholder="Type first name..."
                  />
                </Col>
                <Col xs={24} sm={12}>
                  <span style={{ color: 'red' }}>*</span> Last Name
                  <Input
                    onChange={(e) => {
                      updateUser('lastName', e.target.value);
                    }}
                    value={user.lastName}
                    placeholder="Type last name..."
                  />
                </Col>
              </>
            ) : (
              <Col xs={24} sm={12}>
                <span style={{ color: 'red' }}>*</span> Name
                <Input
                  onChange={(e) => {
                    updateUser('firstName', e.target.value);
                    updateUser('lastName', e.target.value);
                    updateUser('name', e.target.value);
                  }}
                  value={user.name}
                  placeholder="Type name..."
                />
              </Col>
            )}
            <Col xs={24} sm={type === AccountTypeEnum.ENTERPRISE_USER ? 24 : 12}>
              Description
              <Input
                onChange={(e) => updateUser('description', e.target.value)}
                value={user.description}
                placeholder="Type description..."
              />
            </Col>
            {type === AccountTypeEnum.ENTERPRISE_USER ? (
              <Col xs={21}>
                <span style={{ color: 'red' }}>*</span> E-mail
                <Input
                  onChange={(e) => updateUser('email', e.target.value)}
                  value={user.email}
                  placeholder="Type e-mail..."
                  disabled={!!existingUser}
                />
              </Col>
            ) : (
              <Col xs={21}>
                <span style={{ color: 'red' }}>*</span> E-mail
                <Input
                  onChange={(e) => updateUser('ownerEmail', e.target.value)}
                  value={user.ownerEmail}
                  placeholder="Type e-mail..."
                  disabled={!!existingUser}
                />
              </Col>
            )}
            <Col xs={3}>
              Enabled
              <Switch
                checked={user.isEnabled}
                onChange={() => updateUser('isEnabled', !user.isEnabled)}
              />
            </Col>
            {type === AccountTypeEnum.ENTERPRISE_USER && (
              <Col xs={24}>
                <span style={{ color: 'red' }}>*</span> Roles
                <Select
                  value={roles.filter((role) => !excludedRoles.includes(role))}
                  mode="multiple"
                  allowClear
                  onChange={(e) => handleSelect(e)}
                  onDeselect={handleDeselect}
                  options={Object.entries(RolesEnum)
                    .filter(([key, value]) => !excludedRoles.includes(value))
                    .map((value) => {
                      return {
                        value: value[0],
                        label: value[1].substring(5).replaceAll('_', ' '),
                      };
                    })}
                />
              </Col>
            )}
            {type === AccountTypeEnum.DATA_PROVIDER && (
              <Col xs={24}>
                <Collapse size="large">
                  <Panel header="OPTIONAL" key="1">
                    <Row gutter={[16, 12]} className="p-4">
                      <Col xs={12}>
                        Logo url
                        <Input
                          type="file"
                          onChange={(e) => e.target.files && handleFile(e.target.files[0])}
                        />
                      </Col>
                      <Col xs={12}>
                        Official name
                        <Input
                          onChange={(e) => updateUser('officialName', e.target.value)}
                          value={user.officialName}
                          placeholder="Type official name..."
                        />
                      </Col>
                      <Col xs={12}>
                        Organization type
                        <Input
                          onChange={(e) => updateUser('organizationType', e.target.value)}
                          value={user.organizationType}
                          placeholder="Type organization type..."
                        />
                      </Col>
                      <Col xs={12}>
                        Organization location
                        <Input
                          onChange={(e) => updateUser('organizationLocation', e.target.value)}
                          value={user.organizationLocation}
                          placeholder="Type organization location..."
                        />
                      </Col>
                      <Col xs={12}>
                        Ownership info
                        <Input
                          onChange={(e) => updateUser('ownershipInfo', e.target.value)}
                          value={user.ownershipInfo}
                          placeholder="Type ownership info..."
                        />
                      </Col>
                      <Col xs={12}>
                        Main contact
                        <Input
                          onChange={(e) => updateUser('mainContact', e.target.value)}
                          value={user.mainContact}
                          placeholder="Type main contact..."
                        />
                      </Col>
                      <Col xs={12}>
                        Office address
                        <Input
                          onChange={(e) => updateUser('officeAddress', e.target.value)}
                          value={user.officeAddress}
                          placeholder="Type office address..."
                        />
                      </Col>
                      <Col xs={12}>
                        Contact address
                        <Input
                          onChange={(e) => updateUser('contactAddress', e.target.value)}
                          value={user.contactAddress}
                          placeholder="Type contact address..."
                        />
                      </Col>
                      <Col xs={12}>
                        Marketing contact
                        <Input
                          onChange={(e) => updateUser('marketingContact', e.target.value)}
                          value={user.marketingContact}
                          placeholder="Type marketing contact..."
                        />
                      </Col>
                      <Col xs={12}>
                        Webpage link
                        <Input
                          onChange={(e) => updateUser('webpageLink', e.target.value)}
                          value={user.webpageLink}
                          placeholder="Type webpage link..."
                        />
                      </Col>
                      <Col xs={12}>
                        Support email
                        <Input
                          onChange={(e) => updateUser('supportEmail', e.target.value)}
                          value={user.supportEmail}
                          placeholder="Type support email..."
                        />
                      </Col>
                      <Col xs={12}>
                        Support phone
                        <Input
                          onChange={(e) => updateUser('supportPhone', e.target.value)}
                          value={user.supportPhone}
                          placeholder="Type support phone..."
                        />
                      </Col>
                      <Col xs={12}>
                        Support chat
                        <Input
                          onChange={(e) => updateUser('supportChat', e.target.value)}
                          value={user.supportChat}
                          placeholder="Type support chat..."
                        />
                      </Col>
                      <Col xs={12}>
                        Hours of operation
                        <Input
                          onChange={(e) => updateUser('hoursOfOperation', e.target.value)}
                          value={user.hoursOfOperation}
                          placeholder="Type hours of operation..."
                        />
                      </Col>
                      <Col xs={12}>
                        Billing frequency
                        <Input
                          onChange={(e) => updateUser('billingFrequency', e.target.value)}
                          value={user.billingFrequency}
                          placeholder="Type billing frequency..."
                        />
                      </Col>
                      <Col xs={12}>
                        Payment policy
                        <Input
                          onChange={(e) => updateUser('paymentPolicy', e.target.value)}
                          value={user.paymentPolicy}
                          placeholder="Type payment policy..."
                        />
                      </Col>
                      <Col xs={12}>
                        URL
                        <Input
                          onChange={(e) => updateUser('url', e.target.value)}
                          value={user.url}
                          placeholder="Type url..."
                        />
                      </Col>
                      <Col xs={12}>
                        Phone
                        <Input
                          onChange={(e) => updateUser('phone', e.target.value)}
                          value={user.phone}
                          placeholder="Type phone..."
                        />
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
              </Col>
            )}
          </Row>
        </div>
      </Modal>
    </>
  );
};
