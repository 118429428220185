import { useQuery } from 'react-query';

import { axiosInstance } from '../../../core/api/axiosInstance';

export const key = ['ACCOUNT'];
export const useGetAccount = (api: string, id: string) => {
  return useQuery([...key, api, id], async () => {
    const res = await axiosInstance.get(`${api}/${id}`);
    return res.data;
  });
};
