import { cloneDeep, groupBy } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import Input from '../../../../../atoms/input/Input';
import LoadingIndicator from '../../../../../atoms/loadingIndicator/LoadingIndicator';
import { useGetForecastData } from '../../../../../core/api/forecastData/useGetForecastData';
import { useGetLocations } from '../../../../../core/api/forecastData/useGetLocations';
import { useDebounce } from '../../../../../hooks/useDebounce';
import { ForecastWDElementDef } from '../../../../../model/definitions/ForecastWDElementDef';
import { ForecastDate } from './ForecastDate';

interface ForecastSectionProps {
  point: string;
  forecast: Array<ForecastWDElementDef>;
  refDate: number;
  offset: number;
  step: 'observed' | 'forecast' | 'add';
  setDates: (e: any) => void;
  selectedDate?: number;
  zeroDate: number;
  bias: boolean;
  returnedValues: (e: Array<ForecastWDElementDef>) => void;
  id: string;
}

export const ForecastSection = ({
  point,
  forecast,
  refDate,
  offset,
  step,
  setDates,
  selectedDate,
  zeroDate,
  bias,
  returnedValues,
  id,
}: ForecastSectionProps) => {
  const [searchKey, setSearchKey] = useState<string | undefined>(point);
  const debounceSearch = useDebounce(searchKey, 0);
  const { data: locations, isLoading } = useGetLocations(debounceSearch);

  const getBorders = () => {
    if (searchKey && location?.toUpperCase() !== searchKey.toUpperCase())
      return { borderBottomLeftRadius: 0, borderBottomRightRadius: 0 };
  };
  const handleStation = (val: string) => {
    setSearchKey(val);
    setLocation(val);
  };
  const [location, setLocation] = useState<string>(point);
  const { data } = useGetForecastData(location, bias);
  useEffect(() => setDates(data?.daily.map((item) => item.utcDate)), [data]);
  const [current] = useState<Array<ForecastWDElementDef>>(forecast);
  const groupedByTime = useMemo(() => groupBy(forecast, 'forecastWDSource.utcDate'), [data]);
  const updateValue = (e: ForecastWDElementDef) => {
    const newState = cloneDeep(current);
    const index = newState.findIndex((entry) => entry.id === e.id);
    newState[index] = e;
  };
  const renderSections = () => {
    const arr = [];
    for (const daily in groupedByTime) {
      arr.push(
        <ForecastDate
          id={id}
          returnedValues={returnedValues}
          location={location}
          selectedDate={selectedDate}
          zeroDate={zeroDate}
          step={step}
          setCurrent={updateValue}
          key={JSON.stringify(groupedByTime[daily])}
          date={daily}
          templateData={groupedByTime[daily]}
          freshData={data}
          offset={offset}
          refDate={refDate}
        />,
      );
    }
    return arr;
  };
  return (
    <div>
      <div className="relative location-wrapper">
        <Input
          value={searchKey}
          onChange={(e) => setSearchKey(e.target.value)}
          style={getBorders()}
          required
          label={'Location'}
        />
        {searchKey && location?.trim().toUpperCase() !== searchKey.trim().toUpperCase() && (
          <div className={'search-result items-center absolute'}>
            {isLoading ? (
              <div className={'flex w-1/2 loading'}>
                <div className={'flex items-center mr-3'}>
                  <LoadingIndicator />
                </div>
                Searching
              </div>
            ) : locations?.length &&
              location?.trim().toUpperCase() !== searchKey.trim().toUpperCase() ? (
              locations.map((loc) => (
                <div
                  key={loc.locationId}
                  onClick={() => handleStation(loc.locationId)}
                  className={'result'}
                >
                  {loc.name} {loc.locationId}
                </div>
              ))
            ) : (
              <div className={'flex justify-center items-center'}>no results</div>
            )}
          </div>
        )}
      </div>
      <div>{!isLoading && renderSections()}</div>
    </div>
  );
};
