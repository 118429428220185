import { useKeycloak } from '@react-keycloak/web';
import { KeyboardEventHandler, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { createPlayer } from '../../components/timeline/helpers';
import TimelineContainer from '../../components/timeline-container/TimelineContainer';
import { useGetTranslation } from '../../core/api/translation/useGetTranslation';
import { ModeEnum } from '../../core/ui/enums/ModeEnum';
import { PlaybackEnum } from '../../core/ui/enums/PlaybackEnum';
import { getAspectRatioFromExportFormat } from '../../helpers/getAspectRatioFromExportFormat';
import { getFpsFromExportFormat } from '../../helpers/getFpsFromExportFormat';
import { getProjectDuration, getScenePlaybackLength } from '../../helpers/timelineUtil';
import useRoleAccess from '../../hooks/useRoleAccess';
import { C9ProjectDef } from '../../model/definitions/C9ProjectDef';
import { SceneDef } from '../../model/definitions/SceneDef';
import { RolesEnum } from '../../model/enums/RolesEnum';
import { precacheDrawings } from '../../molecules/mapElement/helpers';
import AddElementModalDropdown from '../../organisms/addElementModal/AddElementModalDropdown';
import SceneCanvas from '../../organisms/SceneCanvas';
import SecondaryControls from '../../organisms/secondaryControls/SecondaryControls';
import {
  ActiveDef,
  resetActiveState,
  setActiveAspectRatio,
  setActiveFrameRate,
  setElement,
  setProjectToPlay,
  setSceneTranslation,
  setTime,
  setTranslation,
} from '../../store/slices/active-slice';
import { addNewProject, setSavedProject } from '../../store/slices/project-slice';
import { selectActiveScene } from '../../store/slices/selectors';
import { RootState } from '../../store/store';
import TopBar from '../dashboard/components/top-bar/TopBar';
import { BiasFilter } from './bias-filter/BiasFilter';
import { loadFrames } from './loadFrames';
import NewProjectFromTemplateModal from './modals/NewProjectFromTemplateModal';
import PlayerContext from './playerContext/PlayerContext';
import styles from './Playground.module.scss';
import Properties from './properties/Properties';
import Sidebar from './sidebar-new/Sidebar';
import StudioSkeleton from './StudioSkeleton';
import { useLoadProject } from './useLoadProject';

const Playground = () => {
  const [addElementModal, setAddElementModal] = useState<boolean>(false);
  const [newProjectFromTemplateModal, setNewProjectFromTemplateModal] = useState(false);
  const active = useSelector<RootState>((state) => state.active) as ActiveDef;
  const { activeScene, mode, activeFramerate, biasMode } = active;
  const [params] = useSearchParams();
  const dispatch = useDispatch();

  const { isLoading } = useLoadProject(params.get('projectId'));
  const {
    keycloak: { idTokenParsed },
  } = useKeycloak();

  useEffect(() => {
    // Hide Zendesk Web Widget
    // @ts-ignore
    if (window.zE && process.env.NODE_ENV !== 'development') {
      // @ts-ignore
      window.zE('webWidget', 'hide');
    }
  }, []);

  useEffect(() => {
    if (params.get('templateId')) {
      setNewProjectFromTemplateModal(true);
    }
  }, [params]);
  /**On leave reset active state */
  useEffect(() => {
    return () => {
      dispatch(resetActiveState());
    };
  }, [dispatch]);
  const project = useSelector<RootState, C9ProjectDef>((state) => state.project.present.project);
  const roleAccess = useRoleAccess(
    [RolesEnum.ROLE_CREATOR, RolesEnum.ROLE_MAINTAINER, RolesEnum.ROLE_FORECASTER],
    project.isSharedEntity,
    project.inEditMode,
  );
  const activeSceneDef = useSelector<RootState, SceneDef | null>((state) =>
    selectActiveScene(state),
  );
  const { data: language } = useGetTranslation(project.translationId ?? '');
  const { data: sceneLanguage } = useGetTranslation(activeSceneDef?.translationId ?? '');
  useEffect(() => {
    dispatch(setTranslation({ translation: language }));
  }, [dispatch, language, project.translationId]);
  useEffect(() => {
    activeSceneDef &&
      dispatch(setSceneTranslation({ translation: sceneLanguage, scene: activeSceneDef?.id }));
  }, [dispatch, sceneLanguage, activeSceneDef?.translationId, activeSceneDef?.id]);
  const durationScene = project.sceneDefs?.find((scene) => scene.id === activeScene)
    ? project.sceneDefs.find((scene) => scene.id === activeScene)?.durationInMS
    : 0;
  const {
    setIsPlaying,
    time,
    clear,
    interval,
    set,
    isPlaying,
    setTime: setContextTime,
    setSkip,
    isRecording,
  } = useContext(PlayerContext);

  const [skippedTime, setSkippedTime] = useState<{
    val: number;
    ctrl: boolean;
  }>({ val: 0, ctrl: false });
  function isBetween(startMs: number, endMs: number, num: number) {
    return num >= startMs && num <= endMs;
  }

  useEffect(() => {
    mode === ModeEnum.PROJECT && !isRecording && isInRange(project.skippedTime, time);
  }, [mode, project.skippedTime, time]);
  const changeTime = async (e: number, getTo: number) => {
    clear(interval);
    await setContextTime(time + e);
    isPlaying === PlaybackEnum.PLAYING && skip && skip(time + e);
    isPlaying === PlaybackEnum.PLAYING && (await dispatch(setTime({ activeTime: time + e })));
    // isPlaying !== PlaybackEnum.PLAYING && (await dispatch(setTime({ activeTime: time })));
  };
  const isInRange = (arr: SkipTimeDef[], num: number) => {
    for (let i = 0; i < arr.length; i++) {
      if (isBetween(arr[i].startMS, arr[i].endMS, num)) {
        if (arr[i].endMS === getProjectDuration(project)) {
          clear(interval);
          setIsPlaying(PlaybackEnum.STOP);
          setContextTime(arr[i].startMS);
          dispatch(
            setTime({
              activeTime: arr[i].startMS,
            }),
          );
          setSkippedTime({ val: 0, ctrl: !skippedTime.ctrl });
          setSkip({ val: 0, ctrl: !skippedTime.ctrl });
          setIsPlaying(PlaybackEnum.STOP);
        } else {
          changeTime(arr[i].endMS - arr[i].startMS, arr[i].endMS);
        }
      }
    }
    return 0;
  };
  const start = async () => {
    const scene = project.sceneDefs.find((scene) => scene.id === (activeScene as string));

    const scenes = mode === ModeEnum.SEQUENCE && scene ? [scene] : project.sceneDefs;
    await loadFrames(scenes, dispatch, idTokenParsed!);
    await precacheDrawings(scenes);
    dispatch(
      setProjectToPlay({
        projectToPlay: createPlayer(project, active.syncSpace, active.activeFramerate),
      }),
    );
    dispatch(setElement({ activeElement: '', activeProp: '' }));
    setIsPlaying(PlaybackEnum.PLAYING);
    const timelineTime = time;
    const startTime = new Date().getTime();
    const startPlay = () => {
      const currentTime = new Date().getTime();
      setContextTime(currentTime - startTime + timelineTime + skippedTime.val);
      if (
        currentTime - startTime + timelineTime + skippedTime.val >=
        (mode === ModeEnum.SEQUENCE
          ? scene
            ? getScenePlaybackLength(scene)
            : 90000
          : getProjectDuration(project))
      ) {
        clearInterval(intv);
        clear(interval);
        setIsPlaying(PlaybackEnum.STOP);
        setContextTime(
          mode === ModeEnum.SEQUENCE && scene
            ? getScenePlaybackLength(scene)
            : getProjectDuration(project),
        );
        dispatch(
          setTime({
            activeTime:
              mode === ModeEnum.SEQUENCE && scene
                ? getScenePlaybackLength(scene)
                : getProjectDuration(project),
          }),
        );
        setSkippedTime({ val: 0, ctrl: !skippedTime.ctrl });
        setSkip({ val: 0, ctrl: !skippedTime.ctrl });
        setIsPlaying(PlaybackEnum.STOP);
      }
    };
    const intv = setInterval(startPlay, 16);
    set(intv);
  };
  const stop = () => {
    setIsPlaying(PlaybackEnum.STOP);
    clear && clear(interval);
    setContextTime(0);
    setSkippedTime({ val: 0, ctrl: !skippedTime.ctrl });
    setSkip({ val: 0, ctrl: !skippedTime.ctrl });
    dispatch(setTime({ activeTime: 0 }));
  };
  const skip = (e?: number) => {
    if (isPlaying === PlaybackEnum.PLAYING && e) {
      clear(interval);
      setContextTime(e);
      setSkippedTime({ val: 0, ctrl: !skippedTime.ctrl });
      setSkip({ val: 0, ctrl: !skippedTime.ctrl });
    }
  };
  const pause = () => {
    clear(interval);
    setIsPlaying(PlaybackEnum.PAUSE);
    clear && clear(interval);
    dispatch(setTime({ activeTime: time }));
    setSkippedTime({ val: 0, ctrl: !skippedTime.ctrl });
    setSkip({ val: 0, ctrl: !skippedTime.ctrl });
  };
  const backFive = () => {
    if (isPlaying === PlaybackEnum.PLAYING) {
      clear(interval);
      time - 5000 > 0
        ? setSkippedTime({ val: -5000, ctrl: !skippedTime.ctrl })
        : setSkippedTime({ val: -time, ctrl: !skippedTime.ctrl });
      time - 5000 > 0
        ? setSkip({ val: -5000, ctrl: !skippedTime.ctrl })
        : setSkip({
            val: -time,
            ctrl: !skippedTime.ctrl,
          });
    } else {
      time - 5000 > 0 ? setContextTime(time - 5000) : setContextTime(0);
      setSkippedTime({ val: 0, ctrl: !skippedTime.ctrl });
      setSkip({ val: 0, ctrl: !skippedTime.ctrl });
    }
  };
  const backFrame = () => {
    if (isPlaying === PlaybackEnum.PLAYING) {
      clear(interval);
      time - 1000 / activeFramerate > 0
        ? setSkippedTime({
            val: -(1000 / activeFramerate),
            ctrl: !skippedTime.ctrl,
          })
        : setSkippedTime({ val: -time, ctrl: !skippedTime.ctrl });
      time - 1000 / activeFramerate > 0
        ? setSkip({
            val: -(1000 / activeFramerate),
            ctrl: !skippedTime.ctrl,
          })
        : setSkip({
            val: -time,
            ctrl: !skippedTime.ctrl,
          });
    } else {
      time - 1000 / activeFramerate > 0
        ? setContextTime(time - 1000 / activeFramerate)
        : setContextTime(0);
      setSkippedTime({ val: 0, ctrl: !skippedTime.ctrl });
      setSkip({ val: 0, ctrl: !skippedTime.ctrl });
    }
  };
  const backScene = (timeToSkip: number) => {
    if (isPlaying === PlaybackEnum.PLAYING) {
      clear(interval);
      setSkippedTime({ val: -timeToSkip, ctrl: !skippedTime.ctrl });
    } else {
      setContextTime(timeToSkip);
    }
  };
  const skipFive = () => {
    if (isPlaying === PlaybackEnum.PLAYING) {
      clear(interval);
      time + 5000 < durationScene!
        ? setSkippedTime({ val: 5000, ctrl: !skippedTime.ctrl })
        : setSkippedTime({
            val: durationScene! - time,
            ctrl: !skippedTime.ctrl,
          });
      time + 5000 < durationScene!
        ? setSkip({ val: 5000, ctrl: !skippedTime.ctrl })
        : setSkip({
            val: durationScene! - time,
            ctrl: !skippedTime.ctrl,
          });
    } else {
      time + 5000 < durationScene! ? setContextTime(time + 5000) : setContextTime(durationScene!);
      setSkippedTime({ val: 0, ctrl: !skippedTime.ctrl });
      setSkip({ val: 0, ctrl: !skippedTime.ctrl });
    }
  };
  const skipScene = (timeToSkip: number, isProjectSkip?: boolean) => {
    if (isPlaying === PlaybackEnum.PLAYING) {
      clear(interval);
      setSkippedTime({ val: timeToSkip, ctrl: !skippedTime.ctrl });
    } else {
      setContextTime(timeToSkip);
    }
  };
  const skipF = () => {
    setContextTime(time + 1000 / activeFramerate);
    dispatch(setTime({ activeTime: time + 1000 / activeFramerate }));
  };
  const skipFrame = () => {
    if (isPlaying === PlaybackEnum.PLAYING) {
      clear(interval);
      time + 1000 / activeFramerate < durationScene!
        ? setSkippedTime({
            val: 1000 / activeFramerate,
            ctrl: !skippedTime.ctrl,
          })
        : setSkippedTime({
            val: durationScene! - time,
            ctrl: !skippedTime.ctrl,
          });
      time + 1000 / activeFramerate < durationScene!
        ? setSkip({
            val: 1000 / activeFramerate,
            ctrl: !skippedTime.ctrl,
          })
        : setSkip({
            val: durationScene! - time,
            ctrl: !skippedTime.ctrl,
          });
    } else {
      time + 1000 / activeFramerate < durationScene! ? skipF() : setContextTime(durationScene!);
      setSkippedTime({ val: 0, ctrl: !skippedTime.ctrl });
      setSkip({ val: 0, ctrl: !skippedTime.ctrl });
    }
  };
  const toStart = () => {
    clear(interval);
    setIsPlaying(PlaybackEnum.STOP);
    setContextTime(0);
  };
  const toEnd = () => {
    clear(interval);
    setIsPlaying(PlaybackEnum.STOP);
    setContextTime(durationScene!);
  };
  useEffect(() => {
    const el = document.getElementById('canvas-container');
    function handleListener(e: KeyboardEvent) {
      if (e.key === ' ') {
        if (isPlaying === PlaybackEnum.PLAYING) {
          pause();
        } else {
          start();
        }
      }
      if (e.key === 'Enter') {
        stop();
      }
      if (e.key === 'End') {
        toEnd();
      }
      if (e.key === 'Home') {
        toStart();
      }
      if ((e.ctrlKey || e.metaKey) && e.shiftKey && e.key === 'ArrowRight') {
        skipFive();
      }
      if ((e.ctrlKey || e.metaKey) && e.shiftKey && e.key === 'ArrowLeft') {
        backFive();
      }
    }
    el?.addEventListener('keydown', handleListener);
    return () => {
      el?.removeEventListener('keydown', handleListener);
    };
  });
  useEffect(() => {
    isPlaying === PlaybackEnum.PLAYING && start();
  }, [skippedTime]);

  function onNewProjectFromTemplate(newProject: C9ProjectDef) {
    setNewProjectFromTemplateModal(false);
    const fps = getFpsFromExportFormat(newProject.projectExportFormat);
    const aspectRatio = getAspectRatioFromExportFormat(newProject.projectExportFormat);
    dispatch(addNewProject({ project: newProject }));
    dispatch(setSavedProject({ savedProject: newProject }));
    dispatch(setActiveAspectRatio({ aspectRatio }));
    dispatch(setActiveFrameRate({ fps }));
  }
  useEffect(() => {
    function handleKeyDown(e: KeyboardEvent) {
      if (e.key === 'e' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setAddElementModal(true);
      }
    }

    document.addEventListener('keydown', handleKeyDown);
    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
  const handleKeyDown: KeyboardEventHandler = (e) => {
    if (e.key === 'ArrowRight') {
      e.preventDefault();
      skipFrame();
    }
    if (e.key === 'ArrowLeft') {
      e.preventDefault();
      backFrame();
    }
  };

  return (
    <div className="flex h-full overflow-y-hidden overflow-x-hidden">
      <div className={`relative flex flex-col xl:w-full ${styles.dark}`}>
        {isLoading && <StudioSkeleton />}
        <TopBar addElement={() => setAddElementModal(!addElementModal)} stop={stop} />
        {addElementModal && (
          <AddElementModalDropdown
            isOpen={addElementModal}
            setAddElementModal={setAddElementModal}
            onClose={() => setAddElementModal(false)}
            duration={project.sceneDefs?.find((scene) => scene.id === activeScene)?.durationInMS}
          />
        )}
        <div
          className={`flex pr-[10px] h-full dark-background mt-10 w-full ${styles['below-top']}`}
        >
          <Sidebar />
          <div>
            <div className={`${styles.studio} flex justify-center`}>
              <SceneCanvas
                project={project}
                skip={skip}
                play={start}
                pause={pause}
                backFive={backFive}
                skipFive={skipFive}
                stop={stop}
                toEnd={toEnd}
                toStart={toStart}
                addElement={() => setAddElementModal(true)}
                nextScene={skipScene}
                prevScene={backScene}
                active={active}
              />
            </div>
          </div>
          <div
            className={`w-[100%] ml-[auto] h-full max-h-full ${
              roleAccess ? '' : 'pointer-events-none opacity-30'
            }`}
          >
            {biasMode ? <BiasFilter /> : <Properties />}
          </div>
        </div>
        <div
          onKeyDown={handleKeyDown}
          className="flex pl-[63px] pr-[15px] h-full dark-background w-full"
        >
          <SecondaryControls
            prevScene={backScene}
            nextScene={skipScene}
            backFive={backFive}
            pause={pause}
            skipFive={skipFive}
            start={start}
            stop={stop}
            toEnd={toEnd}
            toStart={toStart}
          />
        </div>
        <div
          className="flex h-full dark-background w-full pl-[5px] pr-[10px] scrollbar"
          style={{ height: '-webkit-fill-available', overflowY: 'auto' }}
        >
          <TimelineContainer stop={stop} skip={skip} addElement={() => setAddElementModal(true)} />
        </div>
        <NewProjectFromTemplateModal
          opened={newProjectFromTemplateModal}
          onClose={() => setNewProjectFromTemplateModal(false)}
          onNewProjectFromTemplate={onNewProjectFromTemplate}
        />
      </div>
    </div>
  );
};

export default Playground;
