type GridItemProps = {
  label: string | JSX.Element;
  item: JSX.Element | string | number | null | undefined;
  noBorderBg?: boolean;
  title?: string;
  itemStyle?: React.CSSProperties;
  colSpan?: number;
};

function GridItem({ label, item, noBorderBg, title, itemStyle = {}, colSpan = 1 }: GridItemProps) {
  return (
    <>
      <div className="grid-item-label">
        <span>{label}</span>
      </div>
      <div
        style={{ border: !item ? 'none' : '', ...itemStyle }}
        className={`grid-item col-span-${colSpan} ${noBorderBg ? 'no-border-bg' : ''}`}
        title={title}
      >
        {item}
      </div>
    </>
  );
}

export default GridItem;
