import StepsAntd from '../organisms/Steps';

function ProductRegistration() {
  return (
    <>
      <h1 className="mb-8">New Data Product</h1>
      <StepsAntd />
    </>
  );
}

export default ProductRegistration;
