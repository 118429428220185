const units = {
  TEMPERATURE: ['C'],
  PRECIPITATION: ['MMperHOUR'],
  CLOUD: ['0-1'],
  RADAR: ['dBZ'],
  SATELLITE_INFRARED_IMAGERY: [''],
  SATELLITE_WATER_VAPOR_IMAGERY: [''],
  SATELLITE_VISIBLE_IMAGERY: [''],
  PRESSURE: ['Pa'],
  WIND: ['WDirWSpeed'],
  HEIGHT: [],
};

export { units };
