export function getFpsFromExportFormat(exportFormat: string): 25 | 30 | 50 | 60 {
  let fps: 25 | 30 | 50 | 60 = 60;
  const splittedExportFormat = exportFormat.split('_') || [];
  const format = splittedExportFormat[splittedExportFormat.length - 1];
  if (!format) fps = 60;
  if (format === '50i') fps = 25;
  if (format === '60i') fps = 30;
  if (format === '60p') fps = 60;
  if (format === '50p') fps = 50;
  if (format === '30p') fps = 30;
  if (format === '25p') fps = 25;
  return fps;
}

export function getFrameForMS(ms: number, fps: 25 | 30 | 50 | 60) {
  const frameEachMS = 1000 / fps;
  return Math.floor(ms / frameEachMS);
}
