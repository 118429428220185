import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';

import { axiosInstance } from '../../../../core/api/axiosInstance';
import { AudioElement } from '../../../../model/definitions/AudioElement';
import { AudioElementTemplate } from '../../../../model/definitions/AudioElementTemplate';
import { TimeControlDef } from '../../../../model/definitions/TimeControlDef';
import { setElement } from '../../../../store/slices/active-slice';
import { addAudioLayer } from '../../../../store/slices/project-slice';

export const useGetAudioFromRepo = () => {
  const dispatch = useDispatch();
  return useMutation(
    async ({
      id,
      activeScene,
      time,
    }: {
      id: string;
      activeScene: string;
      time: number;
    }): Promise<AudioElementTemplate> => {
      const { data } = await axiosInstance.get(`/multimedia/AUDIO/current-template/${id}`);
      const audioLayer = new AudioElement();
      audioLayer.audioElementTemplate = data;
      audioLayer.name = data.name;
      audioLayer.timeControls = [new TimeControlDef(time, time + data.durationInMS)];
      audioLayer.templateId = data.id;
      audioLayer.templateVersionId = data.versionId;
      audioLayer.enabled = true;
      dispatch(
        addAudioLayer({
          audioLayer,
          activeScene,
        }),
      );
      dispatch(
        setElement({
          activeElement: data.id,
          activeProp: 'audioElements',
        }),
      );
      return data;
    },
  );
};
