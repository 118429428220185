import { useKeycloak } from '@react-keycloak/web';
import { Tabs } from 'antd';
import { GiSettingsKnobs } from 'react-icons/gi';
import { IoColorPaletteOutline, IoLanguageSharp } from 'react-icons/io5';
import { RiComputerLine } from 'react-icons/ri';
import { TbLanguageKatakana } from 'react-icons/tb';
import { useLocation } from 'react-router-dom';

import { useGetProvider } from '../../dataprovider/hooks/useGetProvider';
import Dictionary from '../templates-NEW/Dictionary';
import DefaultColorPalettes from './DefaultColorPalettes';
import Fonts from './Fonts';
import StudioProperties from './StudioProperties';
import ThinClientDetails from './ThinClientDetails';

function EnterpriseProperties() {
  const { keycloak } = useKeycloak();
  const { data: provider } = useGetProvider('enterprise', keycloak?.tokenParsed?.email);
  const enterpriseAccountId = provider?.id;
  const location = useLocation();
  //@ts-ignore
  const tabKey = location.state?.tabKey;

  const items = [
    {
      key: '1',
      label: (
        <div className="flex items-center gap-1 text-lg">
          <TbLanguageKatakana />
          <span>Dictionary</span>
        </div>
      ),
      children: <Dictionary />,
    },
    {
      key: '2',
      label: (
        <div className="flex items-center gap-1 text-lg">
          <IoLanguageSharp />
          <span>Fonts</span>
        </div>
      ),
      children: <Fonts />,
    },
    {
      key: '3',
      label: (
        <div className="flex items-center gap-1 text-lg">
          <GiSettingsKnobs />
          <span>Studio properties</span>
        </div>
      ),
      children: <StudioProperties enterpriseAccountId={enterpriseAccountId} />,
    },
    {
      key: '4',
      label: (
        <div className="flex items-center gap-1 text-lg">
          <RiComputerLine />
          <span>Thin client details</span>
        </div>
      ),
      children: <ThinClientDetails enterpriseAccountId={enterpriseAccountId} />,
    },
    {
      key: '5',
      label: (
        <div className="flex items-center gap-1 text-lg">
          <IoColorPaletteOutline />
          <span>Default color palettes</span>
        </div>
      ),
      children: <DefaultColorPalettes enterpriseAccountId={enterpriseAccountId} />,
    },
  ];

  return (
    <>
      <div className="ws-temp-title">
        <h1>Enterprise properties</h1>
      </div>
      <Tabs defaultActiveKey={tabKey ?? '1'} items={items} />
    </>
  );
}

export default EnterpriseProperties;
