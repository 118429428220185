import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { queryClient } from '../../..';
import { axiosInstance } from '../../../core/api/axiosInstance';
import { key } from './useGetTranslations';

export const useEditTranslation = () => {
  return useMutation(
    async (data: FormData) => {
      return await axiosInstance.put(`translations`, data);
    },
    {
      onSuccess: () => {
        toast.success('Translation edited successfully!');
        queryClient.invalidateQueries(key);
      },
      onError: () => {
        toast.error('Error while editing translation!');
      },
    },
  );
};
