import { TimeStepEnum } from '../enums/TimeStepEnum';
import { CustomVectorLayer } from './CustomVectorLayer';
import { EventMapLayer } from './EventMapLayer';
import { ForecastWDElementDef } from './ForecastWDElementDef';
import { GribMapLayer } from './GribMapLayer';
import { ObservedWDElementDef } from './ObservedWDElementDef';
import { RadarMapLayer } from './RadarMapLayer';
import { SatelliteMapLayer } from './SatelliteMapLayer';
import { SymbolLayerDef } from './SymbolLayerDef';
import { TextPanelDef } from './TextPanelDef';
import { TimeControlDef } from './TimeControlDef';
import { VectorMapLayer } from './VecorMapLayer';

export class WeatherDataSpaceDef {
  id: string;
  timeControls: TimeControlDef[]; //by default same duration as map
  gribMapLayers: GribMapLayer[] = [];
  radarMapLayers: RadarMapLayer[] = [];
  satelliteMapLayers: SatelliteMapLayer[] = [];
  vectorMapLayers: Array<VectorMapLayer | CustomVectorLayer> = [];
  observedDataLayers: ObservedWDElementDef[] = [];
  forecastDataLayers: ForecastWDElementDef[] = [];
  symbolLayers: SymbolLayerDef[] = [];
  eventLayers: EventMapLayer[];
  mapTimeframeTextIndicator = new TextPanelDef();
  timeframeIndicatorStep = TimeStepEnum.HOURLY; //quoter hourly, hourly, daily, weekly
  roundTimeframeIndicator = false;
  relativeTime = false;
  layerSync = false;
  timeframeIndicatorFormat = 'DD-MM-yyyy HH:mm';
  enabledLayers: string[] = [];
  /*** added properties***/
  enableTimeframeIndicator: boolean;
  /*** UI properties ***/
  indicator: Array<{ timeControls: TimeControlDef; value: string; dateValue: string }>;
}
