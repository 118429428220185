import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';

import {
  FontInterface,
  FontVariantInterface,
} from '../../pages/playground/properties/panels/FontProperties';
import { axiosInstancePublic } from './axiosInstancePublic';
import { useGetFonts } from './useGetFonts';
import { useGetVariants } from './useGetVariants';

const useFontsLoader = (fontFamilies: string[]) => {
  const uniqueFontFamilies = [...new Set(fontFamilies)];
  const { data: fonts } = useGetFonts();
  const [fontNamesIds, setFontNamesIds] = useState<FontInterface[]>([]);
  useFontsIdNameSetter(fonts, uniqueFontFamilies, setFontNamesIds);
  const { data: fontVariants } = useGetVariants(fontNamesIds);
  useQuery(
    ['fontVariants'],
    () => {
      if (fontVariants) {
        loadFonts(fontVariants);
      }
    },
    {
      enabled: !!fontVariants,
      staleTime: Infinity,
    },
  );
};

const useFontsIdNameSetter = (
  fonts: FontInterface[],
  fontFamilies: string[],
  setFontIds: React.Dispatch<FontInterface[]>,
) => {
  useEffect(() => {
    const arrayOfFontsNamesIds: FontInterface[] = [];
    if (fonts && fontFamilies.length > 0) {
      fontFamilies.forEach((fontFamily: string) => {
        const findFont = fonts.find((font: FontInterface) => font.name === fontFamily);
        if (findFont) {
          arrayOfFontsNamesIds.push(findFont);
        }
      });
    }
    setFontIds([...arrayOfFontsNamesIds]);
  }, [fonts]);
};

async function fetchFontVariant(variant: FontVariantInterface) {
  const response = await axiosInstancePublic.get(`font-variant/ttf/${variant.id}`, {
    responseType: 'blob',
  });
  return response.data;
}

async function loadFonts(fontVariants: any[]) {
  const existingFonts = new Set(Array.from(document.fonts).map((font) => font.family));
  if (!Array.isArray(fontVariants)) {
    return;
  }
  for (const fV of fontVariants) {
    const fontExists = existingFonts.has(fV.name);
    if (!fontExists) {
      if (!Array.isArray(fV.fontVariants)) {
        return;
      }
      try {
        const fontPromises = fV.fontVariants.map(async (fontVariant: FontVariantInterface) => {
          const fontBlob = await fetchFontVariant(fontVariant);
          const fontUrl = URL.createObjectURL(fontBlob);
          const fontFace = new FontFace(fV.name + ' ' + fontVariant.type, `url(${fontUrl})`);
          document.fonts.add(fontFace);
          await fontFace.load();
          URL.revokeObjectURL(fontUrl);
        });
        await Promise.all(fontPromises);
      } catch (error) {
        toast.error(`Error loading font: ${fV.name}`);
      }
    }
  }
}

export default useFontsLoader;
