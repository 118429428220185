const Prev5sec = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.00008 0C6.70969 0 4.6197 0.86653 3.02937 2.2793L1.50007 0.75V5.25H6.00008L4.09138 3.34131C5.40721 2.19827 7.11609 1.5 9.00008 1.5C13.151 1.5 16.5001 4.8491 16.5001 9C16.5001 13.1509 13.151 16.5 9.00008 16.5C4.84917 16.5 1.50007 13.1509 1.50007 9C1.50148 8.90062 1.48312 8.80194 1.44606 8.70972C1.409 8.61749 1.35398 8.53355 1.2842 8.46277C1.21441 8.392 1.13126 8.33579 1.03956 8.29743C0.947872 8.25907 0.849469 8.23932 0.750075 8.23932C0.650681 8.23932 0.552279 8.25907 0.460586 8.29743C0.368893 8.33579 0.285738 8.392 0.215955 8.46277C0.146172 8.53355 0.0911511 8.61749 0.0540908 8.70972C0.0170304 8.80194 -0.00133053 8.90062 7.50055e-05 9C7.50055e-05 13.9616 4.03848 18 9.00008 18C13.9617 18 18.0001 13.9616 18.0001 9C18.0001 4.0384 13.9617 0 9.00008 0ZM7.32283 6.40283L7.07381 9.49805H8.2574C8.4254 9.23105 8.6881 9.06152 9.0616 9.06152C9.5521 9.06152 9.90388 9.41464 9.90388 9.94189C9.90388 10.4586 9.55649 10.7944 9.06599 10.7944C8.62274 10.7944 8.27131 10.5384 8.20906 10.1499H6.98884C7.00759 11.1092 7.82874 11.8316 9.05574 11.8301C10.33 11.8301 11.1724 11.0505 11.1724 9.9082C11.1724 8.8792 10.4547 8.13574 9.45125 8.13574C8.909 8.13574 8.53539 8.34848 8.33064 8.67773H8.26033L8.35994 7.4502H10.8385V6.40283H7.32283Z"
        fill="#678299"
      />
    </svg>
  );
};

export default Prev5sec;
