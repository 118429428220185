import { Button, Modal } from 'antd';

interface FormatterModalProps {
  onClose: () => void;
  isOpen: boolean;
}
export const FormattingHelpPage = ({ onClose, isOpen }: FormatterModalProps) => {
  return (
    <Modal
      title={'Time formating symols'}
      className="template-scene-modal"
      width={500}
      open={isOpen}
      onCancel={onClose}
      footer={[
        <Button key={'close_help_button'} onClick={onClose}>
          Close
        </Button>,
      ]}
    >
      <div className={'flex justify-center'}>
        <table>
          <thead>
            <tr>
              <th>Symbol/Pattern</th>
              <th>Description</th>
              <th>Example</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>YYYY</td>
              <td>4-digit year</td>
              <td>2023</td>
            </tr>
            <tr>
              <td>YY</td>
              <td>2-digit year</td>
              <td>23</td>
            </tr>
            <tr>
              <td>MMMM</td>
              <td>Full month name</td>
              <td>April</td>
            </tr>
            <tr>
              <td>MMM</td>
              <td>Short month name</td>
              <td>Apr</td>
            </tr>
            <tr>
              <td>MM</td>
              <td>2-digit month</td>
              <td>04</td>
            </tr>
            <tr>
              <td>M</td>
              <td>Month without leading zeros</td>
              <td>4</td>
            </tr>
            <tr>
              <td>DD</td>
              <td>2-digit day of the month</td>
              <td>11</td>
            </tr>
            <tr>
              <td>D</td>
              <td>Day of the month without leading zeros</td>
              <td>11</td>
            </tr>
            <tr>
              <td>dddd</td>
              <td>Full day of the week</td>
              <td>Wednesday</td>
            </tr>
            <tr>
              <td>ddd</td>
              <td>Short day of the week</td>
              <td>Wed</td>
            </tr>
            <tr>
              <td>HH</td>
              <td>2-digit hour (24-hour format)</td>
              <td>14</td>
            </tr>
            <tr>
              <td>H</td>
              <td>Hour (24-hour format) without leading zeros</td>
              <td>14</td>
            </tr>
            <tr>
              <td>hh</td>
              <td>2-digit hour (12-hour format)</td>
              <td>02</td>
            </tr>
            <tr>
              <td>h</td>
              <td>Hour (12-hour format) without leading zeros</td>
              <td>2</td>
            </tr>
            <tr>
              <td>mm</td>
              <td>2-digit minute</td>
              <td>30</td>
            </tr>
            <tr>
              <td>m</td>
              <td>Minute without leading zeros</td>
              <td>30</td>
            </tr>
            <tr>
              <td>ss</td>
              <td>2-digit second</td>
              <td>45</td>
            </tr>
            <tr>
              <td>s</td>
              <td>Second without leading zeros</td>
              <td>45</td>
            </tr>
            <tr>
              <td>A</td>
              <td>Uppercase AM/PM indicator</td>
              <td>PM</td>
            </tr>
            <tr>
              <td>a</td>
              <td>Lowercase AM/PM</td>
              <td>pm</td>
            </tr>
            <tr>
              <td>z</td>
              <td>Timezone code</td>
              <td>CEST</td>
            </tr>
            <tr>
              <td>pp</td>
              <td>Part of a day</td>
              <td>Evening</td>
            </tr>
            <tr>
              <td>PP</td>
              <td>Part of a day uppercase</td>
              <td>MORNING</td>
            </tr>
            <tr>
              <td>{'[]'}</td>
              <td>Text inside brackets is displayed as is</td>
              <td>{'"[any text]"'} displays "any text"</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Modal>
  );
};
