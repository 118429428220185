import { ColorPaletteDef } from '../../model/definitions/ColorPaletteDef';
import { InterpolationEnum } from '../../model/enums/InterpolationEnum';

export class ColorPaletteTexture {
  paletteData: ColorPaletteDef | null;
  scaleMin: number;
  scaleMax: number;
  colorMap: Map<number, number[]>;
  interpolation: InterpolationEnum;

  static IMAGE_WIDTH = 512;

  static DEFAULT_TEMPERATURE_COLORS: Map<number, number[]> = new Map([
    [-130.0, [40, 28, 120, 255]],
    [-20.0, [40, 28, 92, 255]],
    [-15.0, [61, 47, 142, 255]],
    [-10.0, [46, 63, 153, 255]],
    [-5.0, [71, 139, 200, 255]],
    [0.0, [25, 187, 189, 255]],
    [5.0, [101, 193, 134, 255]],
    [10.0, [160, 203, 59, 255]],
    [15.0, [242, 235, 23, 255]],
    [20.0, [246, 167, 26, 255]],
    [25.0, [241, 100, 34, 255]],
    [30.0, [240, 73, 37, 255]],
    [35.0, [236, 31, 36, 255]],
    [40.0, [130, 21, 24, 255]],
    [45.0, [89, 10, 13, 255]],
    [180, [89, 10, 13, 255]],
  ]);

  constructor(
    paletteData: ColorPaletteDef | null,
    scaleMin: number,
    scaleMax: number,
    interpolation: InterpolationEnum,
  ) {
    this.interpolation = interpolation;
    if (paletteData) {
      this.paletteData = paletteData;
      this.scaleMin = scaleMin;
      this.scaleMax = scaleMax;
      this.colorMap = ColorPaletteTexture.getColorMapFromPalette(paletteData);
    } else {
      this.scaleMin = -70;
      this.scaleMax = 70;
      this.colorMap = ColorPaletteTexture.DEFAULT_TEMPERATURE_COLORS;
    }
  }
  async generateTexture(): Promise<HTMLImageElement> {
    const result = new Image();
    result.width = ColorPaletteTexture.IMAGE_WIDTH;
    result.height = 1;

    const canvas = document.createElement('canvas');
    canvas.width = ColorPaletteTexture.IMAGE_WIDTH;
    canvas.height = 1;
    const ctx = canvas.getContext('2d');

    if (!ctx) return result;

    for (let x = 0; x < ColorPaletteTexture.IMAGE_WIDTH; x++) {
      const color = this.getColorForImagePixel(
        this.colorMap,
        x,
        this.scaleMin,
        this.scaleMax,
        this.interpolation == InterpolationEnum.LINEAR,
      )!;
      ctx.fillStyle = `rgba(${color[0]}, ${color[1]}, ${color[2]}, ${
        color[3] > 0 ? color[3] / 255 : 0
      })`;
      ctx.fillRect(x, 0, 1, 1);
    }

    const dataUrl = await canvas.toDataURL();
    result.src = dataUrl;
    await result.decode();

    return result;
  }

  getColorForImagePixel(
    floatColorMap: Map<number, number[]>,
    i: number,
    minValue: number,
    maxValue: number,
    interpolate: boolean,
  ) {
    const rangeSize = Math.abs(minValue) + Math.abs(maxValue); // The range between the minimum (-20.0f) and maximum (45.0f) temperature values
    const step = rangeSize / (ColorPaletteTexture.IMAGE_WIDTH - 1); // Temperature step per pixel
    const temperature = minValue + step * i; // Calculate the temperature value for the current pixel

    // Find the two closest temperature values in the map
    let lowerTemperature: number | null = null;
    let upperTemperature: number | null = null;

    for (const temp of floatColorMap.keys()) {
      if (temp <= temperature && (lowerTemperature == null || temp > lowerTemperature)) {
        lowerTemperature = temp;
      }
      if (temp >= temperature && (upperTemperature == null || temp < upperTemperature)) {
        upperTemperature = temp;
      }
    }

    // If there's only one temperature value found, return its corresponding color
    if (lowerTemperature == null) {
      return floatColorMap.get(upperTemperature!);
    }
    if (upperTemperature == null) {
      return floatColorMap.get(lowerTemperature);
    }

    // If the temperature value matches an entry in the map, return its corresponding color
    if (lowerTemperature === temperature) {
      return floatColorMap.get(lowerTemperature);
    }

    // If the temperature value matches an entry in the map, return its corresponding color
    if (upperTemperature === temperature) {
      return floatColorMap.get(upperTemperature);
    }

    // Linearly interpolate between the two closest colors
    const lowerColor = floatColorMap.get(lowerTemperature)!;
    const upperColor = floatColorMap.get(upperTemperature)!;

    const t = (temperature - lowerTemperature) / (upperTemperature - lowerTemperature);
    if (interpolate) {
      const r = lowerColor[0] + t * (upperColor[0] - lowerColor[0]);
      const g = lowerColor[1] + t * (upperColor[1] - lowerColor[1]);
      const b = lowerColor[2] + t * (upperColor[2] - lowerColor[2]);
      const a = lowerColor[3] + t * (upperColor[3] - lowerColor[3]);

      return [r, g, b, a];
    } else {
      return t < 0.5 ? lowerColor : upperColor;
    }
  }

  static getColorMapFromPalette(palette: ColorPaletteDef) {
    // let gammaCorrection = false;
    const result = new Map<number, number[]>();

    for (const [key, value] of Object.entries(palette.colorStops.pallet)) {
      const colorComponents = value.split(',');

      const red = parseInt(colorComponents[0].trim());
      const green = parseInt(colorComponents[1].trim());
      const blue = parseInt(colorComponents[2].trim());
      const alpha = parseInt(colorComponents[3].trim());

      // if (gammaCorrection) {
      //   const sRGBR = Math.pow(red / 255, 1.0 / 2.2);
      //   const sRGBG = Math.pow(green / 255, 1.0 / 2.2);
      //   const sRGBB = Math.pow(blue / 255, 1.0 / 2.2);
      //   red = sRGBR * 255;
      //   green = sRGBG * 255;
      //   blue = sRGBB * 255;
      // }

      const color = [red, green, blue, alpha];
      const resultKey = palette.colorStops.unit.toLowerCase().includes('k')
        ? ColorPaletteTexture.kelvinToCelsius(parseFloat(key))
        : parseFloat(key);

      result.set(resultKey, color);
    }

    return result;
  }

  static kelvinToCelsius(k: number): number {
    return k - 273.15;
  }
}
