import { cloneDeep } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';

import Button from '../../../../atoms/button/Button';
import Input from '../../../../atoms/input/Input';
import TextArea from '../../../../atoms/input/Textarea';
import { useCreatePalette } from '../../../../core/api/palette/useCreatePalette';
import { useClearPaletteCache, usePaletteById } from '../../../../core/api/palette/usePalettebyId';
import { useUpdatePalette } from '../../../../core/api/palette/useUpdatePalette';
import { ColorPaletteDef } from '../../../../model/definitions/ColorPaletteDef';
import { PickerDef } from '../../../../model/definitions/PickerDef';
import { CustomPaletteDTO } from '../../../../model/DTO/CustomPaletteDTO';
import { ColorPaletteParamTypeEnum } from '../../../../model/enums/ColorPaletteParamTypeEnum';
import {
  VisualisationTypeEnum,
  VisualisationTypeName,
} from '../../../../model/enums/VisualisationTypeEnum';
import Modal from '../../../../molecules/modal/Modal';
import { CustomPaletteV2 } from '../components/custom-palette/CustomPaletteV2';
import styles from '../Properties.module.scss';
import GridItem from '../shared/GridItem';
import GridWrapper from '../shared/GridWrapper';
import { PaletteColor } from './PaletteColor';
import { PaletteColorPicker } from './PalettecolorPicker';
import { units } from './units';

interface CustomPaletteProp {
  onOk: (e: ColorPaletteDef) => void;
  paletteParamType: ColorPaletteParamTypeEnum;
  visualisation?: VisualisationTypeEnum;
  paletteToEdit: string | undefined;
  setPaletteToEdit: (e: string | undefined) => void;
  source: PickerDef;
}
export const CustomPalette = ({
  onOk,
  paletteParamType,
  visualisation = VisualisationTypeEnum.HEATMAP,
  paletteToEdit,
  setPaletteToEdit,
  source,
}: CustomPaletteProp) => {
  const { mutate: create } = useCreatePalette();
  const { mutate: update } = useUpdatePalette();
  const { data: editing, isSuccess: gotPalette } = usePaletteById(paletteToEdit);
  const clearPaletteCache = useClearPaletteCache();
  const [isModal, setIsModal] = useState<boolean>(false);
  const [min, setMin] = useState<number>(0);
  const [max, setMax] = useState<number>(0);
  const [noOfSteps, setNoOfSteps] = useState<number>(0);
  const [palette, setPalette] = useState<CustomPaletteDTO | ColorPaletteDef>(
    editing ?? new CustomPaletteDTO(),
  );
  const isEditingHeatmap =
    Boolean(paletteToEdit) && visualisation !== VisualisationTypeEnum.ISOLINE;
  const [isolineColor, setIsolineColor] = useState<string>('255,255,255,255');

  const isShaded =
    visualisation === VisualisationTypeEnum.HEATMAP ||
    visualisation === VisualisationTypeEnum.PARTICLE;

  const unit =
    visualisation === VisualisationTypeEnum.PARTICLE ? 'UV Components' : units[paletteParamType][0];

  useEffect(() => {
    function findPropertyValue(obj: any) {
      for (const key in obj) {
        return obj[key];
      }
    }
    const color = findPropertyValue(palette.colorStops.pallet);
    setIsolineColor(color);
  }, [palette]);
  useEffect(() => {
    if (paletteToEdit) {
      setIsModal(true);
    }
  }, [paletteToEdit]);
  useEffect(() => {
    if (palette.type === 'SYSTEM' || palette.type === 'ENTERPRISE')
      setPalette({
        ...palette,
        type: 'CUSTOM',
        name: '',
        description: '',
      });
  }, [palette]);
  const setPaletteScale = useCallback(
    (newKey: number, oldKey: number | string) => {
      const o = cloneDeep(palette.colorStops.pallet);
      //@ts-ignore
      const keepColor = cloneDeep(o[oldKey]);
      o[newKey] = keepColor;
      //@ts-ignore
      delete o[oldKey];
      const palletNew = { ...o, [newKey]: keepColor };
      setPalette({
        ...palette,
        colorStops: {
          ...palette.colorStops,
          pallet: palletNew,
        },
      });
    },
    [palette],
  );
  useEffect(() => {
    setNoOfSteps(Object.keys(palette.colorStops.pallet).length);
  }, [paletteParamType]);
  useEffect(() => {
    if (paletteToEdit && editing) {
      setMin(Math.min(...Object.keys(editing.colorStops?.pallet).map((key) => Number(key))));
      setMax(Math.max(...Object.keys(editing.colorStops?.pallet).map((key) => Number(key))));
      setNoOfSteps(Object.keys(editing.colorStops?.pallet).length);
      setPalette(editing);
    }
  }, [editing, gotPalette, paletteToEdit]);
  useEffect(() => {
    if (!isModal) {
      setMin(0);
      setMax(0);
      setNoOfSteps(0);
      setPalette(new CustomPaletteDTO());
      setPaletteToEdit(undefined);
    }
  }, [isModal]);
  const changeColorsNumber = (e: number) => {
    const newPalette = cloneDeep(palette);
    const palletNew: Record<number, string> = {};
    let color = '0, 0, 0, 255';
    if (Object.keys(newPalette.colorStops.pallet).length > 0) {
      color = newPalette.colorStops.pallet[max];
    }
    const step = Math.ceil(Number(((max - min) / (e - 1)).toFixed(2)) * 100) / 100;
    for (let i = 0; i < e - 1; i++) {
      const key =
        visualisation === VisualisationTypeEnum.HEATMAP
          ? Math.ceil((min + i * step) * 100) / 100
          : Math.round(min + i * step);
      palletNew[Number(key)] = color;
    }
    palletNew[Number(max)] = color;
    const ordered = Object.keys(palletNew)
      .sort((a, b) => Number(a) - Number(b))
      .reduce((obj: Record<number, string>, key: string) => {
        obj[Number(key)] = palletNew[Number(key)];
        return obj;
      }, {});
    setNoOfSteps(e);
    setPalette({
      ...newPalette,
      colorStops: {
        ...newPalette.colorStops,
        pallet: ordered,
        interval: visualisation === VisualisationTypeEnum.HEATMAP ? 10 : null,
      },
    });
  };
  const setPaletteColor = (color: string, value: string) => {
    setPalette({
      ...palette,
      colorStops: {
        ...palette.colorStops,
        pallet: { ...palette.colorStops.pallet, [color]: value },
      },
    });
  };
  const editIsolineColor = (e: string) => {
    const pallet = palette.colorStops.pallet;
    for (const step in pallet) {
      pallet[step] = e;
    }
    setPalette(palette);
  };
  const onAddPalletValue = (value: string, colorValue: string) => {
    const numberValue = Number(value);
    if (!palette?.colorStops?.pallet) return;
    const sortedValues = Object.keys(palette?.colorStops?.pallet)
      .map(Number)
      .sort((a, b) => a - b);
    const currentIndex = sortedValues.findIndex((v) => v === Number(value));
    if (currentIndex === -1) return;
    let newValue;
    if (sortedValues[currentIndex + 1]) {
      newValue = (sortedValues[currentIndex] + sortedValues[currentIndex + 1]) / 2;
    } else if (sortedValues[currentIndex - 1]) {
      newValue = numberValue + (sortedValues[currentIndex] - sortedValues[currentIndex - 1]);
    } else {
      newValue = numberValue + 1;
    }
    let interpolatedColor = colorValue;
    const sortedValuesString = Object.keys(palette?.colorStops?.pallet)
      .map(String)
      .sort((a, b) => Number(a) - Number(b));
    //@ts-ignore
    const color1 = palette.colorStops.pallet[sortedValuesString[currentIndex]];
    //@ts-ignore
    const color2 = palette.colorStops.pallet[sortedValuesString[currentIndex + 1]];

    if (color1 && color2) {
      interpolatedColor = interpolateColor(color1, color2);
    }
    setPaletteColor(`${newValue}`, interpolatedColor);
  };

  const interpolateColor = (color1: string, color2: string) => {
    const rgba1 = color1.split(',').map(Number);
    const rgba2 = color2.split(',').map(Number);
    const interpolatedR = Math.round((rgba1[0] + rgba2[0]) / 2);
    const interpolatedG = Math.round((rgba1[1] + rgba2[1]) / 2);
    const interpolatedB = Math.round((rgba1[2] + rgba2[2]) / 2);
    const interpolatedA = (rgba1[3] + rgba2[3]) / 2;
    return `rgba(${interpolatedR},${interpolatedG},${interpolatedB},${interpolatedA})`;
  };
  const onDeletePalletValue = (color: any) => {
    const o = cloneDeep(palette.colorStops.pallet);
    delete o[color];
    setPalette({ ...palette, colorStops: { ...palette.colorStops, pallet: o } });
  };
  const renderUnits = () => {
    return <label>{unit}</label>;
  };
  const renderPickers = () => {
    const palColors = palette.colorStops.pallet;
    const elements = [];
    const sortedValues = Object.keys(palColors).sort((a, b) => Number(a) - Number(b));
    for (const color in palColors) {
      const currentIndex = sortedValues.findIndex((v) => Number(v) == Number(color));
      const hideAdd =
        Number(sortedValues[currentIndex]) + 0.01 < Number(sortedValues[currentIndex + 1]);
      elements.push(
        <PaletteColor
          key={Number(color)}
          color={color}
          colorValue={`rgba(${palColors[color]})`}
          setPaletteColor={setPaletteColor}
          setPaletteScale={setPaletteScale}
          onAddPalletValue={isEditingHeatmap ? onAddPalletValue : undefined}
          onDeletePalletValue={isEditingHeatmap ? onDeletePalletValue : undefined}
          paletteLength={Object.keys(palColors).length}
          hideAdd={hideAdd}
        />,
      );
    }
    return elements.sort((a, b) => Number(a.key) - Number(b.key));
  };
  const isolineRanges = () => {
    const palColors = palette.colorStops.pallet;
    const elements = [];
    for (const color in palColors) {
      elements.push(
        <Input
          key={Number(color)}
          value={color}
          onChange={(e) => setPaletteScale(Number(e.target.value), Number(color))}
        />,
      );
    }
    return elements.sort((a, b) => Number(a.key) - Number(b.key));
  };
  const editPalette = (key: keyof ColorPaletteDef, val: string) => {
    setPalette({ ...palette, [key]: val });
  };
  const createPalette = () => {
    paletteToEdit !== undefined && editing?.type !== 'SYSTEM' && editing?.type !== 'ENTERPRISE'
      ? update(palette as ColorPaletteDef, {
          onSuccess: (data) => {
            onOk(data);
            setPaletteToEdit(undefined);
            setIsModal(false);
            clearPaletteCache();
          },
        })
      : create(
          {
            ...palette,
            paletteParamType: paletteParamType,
            visualisationType: visualisation,
            type: 'CUSTOM',
            colorStops: { ...palette.colorStops, unit: unit ?? '' },
            source: source.name,
            enterpriseAccountId: undefined,
          },
          {
            onSuccess: (data) => {
              onOk(data);
              setPaletteToEdit(undefined);
              setIsModal(false);
              clearPaletteCache();
            },
          },
        );
  };
  const createHeatmapPalette = (palette: ColorPaletteDef) => {
    paletteToEdit !== undefined && editing?.type !== 'SYSTEM' && editing?.type !== 'ENTERPRISE'
      ? update(palette, {
          onSuccess: (data) => {
            onOk(data);
            setPaletteToEdit(undefined);
            setIsModal(false);
            clearPaletteCache();
          },
        })
      : create(
          {
            ...palette,
            paletteParamType: paletteParamType,
            visualisationType: visualisation,
            type: 'CUSTOM',
            colorStops: { ...palette.colorStops, unit: unit ?? '' },
            source: source.name,
            enterpriseAccountId: undefined,
          },
          {
            onSuccess: (data) => {
              onOk(data);
              setPaletteToEdit(undefined);
              setIsModal(false);
              clearPaletteCache();
            },
          },
        );
  };
  return (
    <>
      <Button
        label={'Add custom palette'}
        icon={<AiOutlinePlus />}
        onClick={() => setIsModal(true)}
        style={{ marginBottom: '2rem', gap: '7px', width: '25%' }}
      />
      {isShaded && isModal && (
        <CustomPaletteV2
          key={JSON.stringify(palette)}
          isEdit={isEditingHeatmap}
          parameter={paletteParamType}
          visualisation={visualisation}
          unit={unit}
          palette={palette}
          createPalette={createHeatmapPalette}
          isModal={isModal}
          onClose={() => {
            setPaletteToEdit(undefined);
            setIsModal(false);
          }}
        />
      )}
      {isModal && (
        <Modal
          isOpen={isModal && !isShaded}
          onClose={() => {
            setPaletteToEdit(undefined);
            setIsModal(false);
          }}
          header={'Palette editor'}
          className={styles.paletteModal}
          style={{ content: { width: 700, overflow: 'hidden' } }}
          footer={
            <div className="modal-footer">
              <div className={'footnote'} />
              <div className={'button-holder'}>
                <Button
                  onClick={() => {
                    setPaletteToEdit(undefined);
                    setIsModal(false);
                  }}
                  buttonType="secondary"
                  label="Cancel"
                />
                <Button
                  label={paletteToEdit || editing?.type !== 'SYSTEM' ? 'Update' : 'Create'}
                  onClick={createPalette}
                  disabled={noOfSteps === 0 || min === max || !palette.name}
                />
              </div>
            </div>
          }
        >
          <div
            style={{ maxHeight: 400 }}
            className={'form-body basic-properties CustomPalette__grid-wrapper'}
          >
            <div className={`mb-2 subheader layer-header`}>Basic properties</div>
            <Input
              name={'name'}
              label={'Name'}
              value={palette.name}
              onChange={(e) => editPalette('name', e.target.value)}
              required
              type={'text'}
            />
            <TextArea
              name={'description'}
              label={'Description'}
              value={palette.description ?? ''}
              onChange={(e) => editPalette('description', e.target.value)}
              cols={2}
            />
            {!isEditingHeatmap ? (
              <div className={`mb-2 subheader layer-header`}>Palette properties</div>
            ) : null}

            {!isEditingHeatmap && (
              <GridWrapper>
                <GridItem
                  label={'Parameter:'}
                  item={<label style={{ overflow: 'hidden' }}>{paletteParamType}</label>}
                />
                <GridItem
                  label={'Visualisation:'}
                  item={<label>{VisualisationTypeName[visualisation]}</label>}
                />
                <GridItem
                  label={'Min value:'}
                  item={
                    <>
                      <input
                        className={styles.inputWrap}
                        value={min}
                        onChange={(e) => setMin(Number(e.target.value))}
                        type="number"
                      />
                    </>
                  }
                />
                <GridItem
                  label={'Max value:'}
                  item={
                    <input
                      className={styles.inputWrap}
                      value={max}
                      onChange={(e) => setMax(Number(e.target.value))}
                      type="number"
                    />
                  }
                />
                <GridItem
                  label={'No of steps:'}
                  item={
                    <input
                      className={styles.inputWrap}
                      value={noOfSteps}
                      disabled={min === max}
                      onChange={(e) => changeColorsNumber(Number(e.target.value))}
                      type="number"
                      min={0}
                    />
                  }
                />
                <GridItem label={'Unit:'} item={renderUnits()} />
              </GridWrapper>
            )}
            <div className={`mb-2 subheader layer-header`} style={{ marginBottom: '1rem' }}>
              Colors
            </div>
            <div>
              {visualisation === VisualisationTypeEnum.PARTICLE ? (
                renderPickers()
              ) : (
                <>
                  <PaletteColorPicker
                    value={`rgba(${isolineColor})`}
                    key={isolineColor}
                    isPalette
                    onChange={editIsolineColor}
                  />

                  <div className={'grid grid-cols-10 gap-2 mt-3'}>{isolineRanges()}</div>
                </>
              )}
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};
