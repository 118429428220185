import QueryString from 'qs';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { queryClient } from '../../..';
import { axiosInstance } from '../../../core/api/axiosInstance';
import { key } from './useGetAssignedDataProducts';

export const useAddAssignedDataProducts = () => {
  return useMutation(
    async ({
      enterpriseAccountID,
      dataProductIds,
    }: {
      enterpriseAccountID: string;
      dataProductIds: string[];
    }) => {
      const response = await axiosInstance.post('enterprise-accounts/assigned-products', null, {
        params: {
          enterpriseAccountID,
          dataProductIds,
        },
        paramsSerializer: (params: any) => {
          return QueryString.stringify(params, { arrayFormat: 'repeat' });
        },
      });
      return response.data;
    },
    {
      onSuccess: () => {
        toast.success('Successfully added!');
        queryClient.invalidateQueries(key);
      },
      onError: () => {
        toast.error('Error while adding data products!');
      },
    },
  );
};
