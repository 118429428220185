import { useQuery } from 'react-query';

import { axiosInstance } from '../../../core/api/axiosInstance';
import { IconSetTemplateDef } from '../../../model/definitions/IconSetTemplateDef';

export const key = ['ICON_PACK'];
export const useGetPackByTemplateId = (id: string) =>
  useQuery(
    [...key, id],
    async (): Promise<IconSetTemplateDef> => {
      const { data } = await axiosInstance.get(`icon-set-template/current/${id}`);
      return data;
    },
    {
      staleTime: Infinity,
    },
  );
