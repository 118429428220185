import { CityGeoPosterDef } from '../../../model/definitions/CityGeoPosterDef';
import { ColorPaletteDef } from '../../../model/definitions/ColorPaletteDef';
import { GribMapLayer } from '../../../model/definitions/GribMapLayer';
import { RadarMapLayer } from '../../../model/definitions/RadarMapLayer';
import { SatelliteMapLayer } from '../../../model/definitions/SatelliteMapLayer';
import { CityGeoPosterDTO } from '../../../model/DTO/CityGeoPosterDTO';
import { ColorPaletteParamTypeEnum } from '../../../model/enums/ColorPaletteParamTypeEnum';
import { VisualisationTypeEnum } from '../../../model/enums/VisualisationTypeEnum';
import { GribMapLayerRequest } from '../../../model/other/GribMapLayerRequest';
import { GribMapLayerResponse } from '../../../model/other/GribMapLayerResponse';
import { axiosInstance } from '../axiosInstance';

interface InitiateFramesReq {
  layoutSetupId: string;
  mapConfigId: string;
  username: string;
  layerType: 'heatmap' | 'radar' | 'satellite';
  framesToInitialize: string[];
}

const postGribLayer = async (
  gribLayer: GribMapLayer,
  baseMapSetupId: string,
): Promise<GribMapLayer> => {
  const res = await axiosInstance.post(`map/${baseMapSetupId}/grib-map-layer`, gribLayer);
  return res.data;
};

const postRadarLayer = async (
  radarLayer: RadarMapLayer,
  baseMapSetupId: string,
): Promise<RadarMapLayer> => {
  const res = await axiosInstance.post(`map/${baseMapSetupId}/radar-map-layer`, radarLayer);
  return res.data;
};

const postSatelliteLayer = async (
  satelliteLayer: SatelliteMapLayer,
  baseMapSetupId: string,
): Promise<SatelliteMapLayer> => {
  const res = await axiosInstance.post(`map/${baseMapSetupId}/satellite-map-layer`, satelliteLayer);
  return res.data;
};

const initiateFrames = async (
  options: InitiateFramesReq,
  url = `https://weather-tiles-bozo.test.c9weather.com/api/initiateFrames`,
) => {
  const res = await axiosInstance.post(url, options);
  return res.data;
};

const getWindFrames = async (url: string, frameIds: string[]) => {
  const res = await axiosInstance.get(url.replace('{frameId}', frameIds.join(',')), {
    responseType: 'blob',
  });

  // const downloadLink = document.createElement('a');
  // // @ts-ignore
  // downloadLink.href = window.URL.createObjectURL(res.data);
  // downloadLink.setAttribute('download', 'frames.zip');
  // downloadLink.click();

  return res.data;
};

const getDefaultPalettes = async (type: ColorPaletteParamTypeEnum): Promise<ColorPaletteDef[]> => {
  const res = await axiosInstance.get(`color-palette/get-default/${type}`);
  return res.data;
};

const getDefaultPalettesWithVisualisation = async (
  visualisation: VisualisationTypeEnum,
  type: ColorPaletteParamTypeEnum,
): Promise<ColorPaletteDef[]> => {
  const res = await axiosInstance.get(`color-palette/get-default/${visualisation}/${type}`);
  return res.data;
};

const postGribLayerWithVisualisation = async (
  gribReq: GribMapLayerRequest,
): Promise<GribMapLayerResponse> => {
  const res = await axiosInstance.post(`map/grib-map-layer`, gribReq);
  return res.data;
};

const postCity = async (cityDef: CityGeoPosterDTO[]): Promise<CityGeoPosterDef[]> => {
  const res = await axiosInstance.post(`cityGeoPoster/city-geo-posters`, cityDef);
  return res.data;
};

export {
  getDefaultPalettes,
  getDefaultPalettesWithVisualisation,
  getWindFrames,
  initiateFrames,
  postCity,
  postGribLayer,
  postGribLayerWithVisualisation,
  postRadarLayer,
  postSatelliteLayer,
};
