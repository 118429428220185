import { ToggleSwitch } from 'flowbite-react';
import React, { useState } from 'react';
import { AiOutlineCaretDown, AiOutlineCaretUp } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';

import { MAX_ZINDEX_VALUE } from '../../../model/constants/constants';
import { MapPanelDef } from '../../../model/definitions/MapPanelDef';
import { ActiveDef } from '../../../store/slices/active-slice';
import { updateMapLayer } from '../../../store/slices/project-slice';
import { RootState } from '../../../store/store';
import InputNumber from '../../marketplace-new/atoms/FormatNumber/FormatNumber';
import { PaletteColorPicker } from './mapLayersProperties/PalettecolorPicker';
import styles from './Properties.module.scss';
import GridItem from './shared/GridItem';
import GridWrapper from './shared/GridWrapper';

const Graticule: React.FC<{ mapLayer: MapPanelDef }> = ({ mapLayer }) => {
  const [open, setOpen] = useState(false);
  const { activeScene, activeElement } = useSelector<RootState, ActiveDef>((state) => state.active);
  const dispatch = useDispatch();

  function onMapLayerChange(propertyPath: Leaves<MapPanelDef> | keyof MapPanelDef, newValue: any) {
    dispatch(
      updateMapLayer({
        activeScene,
        newValue,
        elementId: activeElement,
        propertyPath,
      }),
    );
  }
  return (
    <>
      <div
        className={`mb-2 subheader layer-header ${open ? 'layer-header-active' : ''}`}
        onClick={() => setOpen(!open)}
      >
        {open ? <AiOutlineCaretUp /> : <AiOutlineCaretDown />}
        Map Grid
      </div>
      {open && (
        <div className="prop-wrapper">
          <GridWrapper>
            <GridItem
              noBorderBg
              label={'Color:'}
              item={
                <PaletteColorPicker
                  value={mapLayer.graticule.strokeColor}
                  onChange={(e) => onMapLayerChange('graticule.strokeColor', e)}
                />
              }
            />
            <GridItem
              label={'Width:'}
              item={
                <InputNumber
                  className={styles.inputWrap}
                  min={0}
                  value={mapLayer.graticule.strokeWidth}
                  onInputChange={(e) => {
                    onMapLayerChange('graticule.strokeWidth', e ? Number(e) : 0);
                  }}
                />
              }
            />
            <GridItem
              label={'Longitude interval:'}
              item={
                <InputNumber
                  className={styles.inputWrap}
                  min={0}
                  step={0.1}
                  value={mapLayer.graticule.longitudeInterval}
                  onInputChange={(e) =>
                    onMapLayerChange('graticule.longitudeInterval', e ? Number(e) : 0.1)
                  }
                />
              }
            />
            <GridItem
              label={'Latitude interval:'}
              item={
                <InputNumber
                  className={styles.inputWrap}
                  min={0}
                  step={0.1}
                  value={mapLayer.graticule.latitudeInterval}
                  onInputChange={(e) =>
                    onMapLayerChange('graticule.latitudeInterval', e ? Number(e) : 0.1)
                  }
                />
              }
            />
            <GridItem
              noBorderBg
              label={'Enabled:'}
              item={
                <ToggleSwitch
                  label={''}
                  checked={mapLayer.graticule?.enabled}
                  onChange={(checked) => onMapLayerChange('graticule.enabled', checked)}
                />
              }
            />
            <GridItem
              label={`Layer level:`}
              item={
                <InputNumber
                  className={styles.inputWrap}
                  value={mapLayer.graticule.zindex}
                  onInputChange={(e) => onMapLayerChange('graticule.zindex', e)}
                  min={0}
                  max={MAX_ZINDEX_VALUE}
                  step={1}
                />
              }
            />
            <GridItem
              noBorderBg
              label={'Labels:'}
              item={
                <ToggleSwitch
                  label={''}
                  checked={mapLayer.graticule?.enableLabels}
                  onChange={(checked) => onMapLayerChange('graticule.enableLabels', checked)}
                />
              }
            />
          </GridWrapper>
        </div>
      )}
    </>
  );
};

export default Graticule;
