import { useCallback, useEffect, useState } from 'react';
import { AiOutlineEdit } from 'react-icons/ai';
import { PortalWithState } from 'react-portal';

import { LottieIcon } from '../../../atoms/LottieIcon';
import { useGetIcons } from '../../../core/api/observedData/useGetIcons';
import { IconTemplateDef } from '../../../model/definitions/IconTemplateDef';
import PNGSequence from '../../../molecules/canvasElements/sequence/PNGSequence';

interface IconPickerProps {
  weatherType: string;
  onSelect: (icon: IconTemplateDef) => void;
  onClick: (icon: IconTemplateDef, active: boolean) => void;
  active: boolean;
  isTemp?: boolean;
}

export const IconPicker = ({ weatherType, onSelect, active, onClick, isTemp }: IconPickerProps) => {
  const { data } = useGetIcons(0, undefined, weatherType);
  const [top, setTop] = useState<{
    top: number;
    left?: number;
    right?: number;
  }>();
  const [selectedIcon, setSelectedIcon] = useState<IconTemplateDef | undefined>(undefined);
  const handleSelectIcon = useCallback(
    (icon: IconTemplateDef) => {
      setSelectedIcon(icon);
      onSelect(icon);
    },
    [data],
  );
  useEffect(() => {
    selectedIcon && handleSelectIcon(selectedIcon);
  }, [handleSelectIcon, selectedIcon]);
  useEffect(() => {
    data && selectedIcon && handleSelectIcon(selectedIcon);
  }, [data, handleSelectIcon, selectedIcon]);
  useEffect(() => {
    data && !selectedIcon && handleSelectIcon(data[0]);
  }, [data, selectedIcon]);
  const height = window.innerHeight;
  const renderAllIcons = (onClick: () => void) => {
    if (data)
      return data.map((icon) => (
        <div
          className={'icon-list'}
          key={icon.id}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            handleSelectIcon(icon);
            onClick();
          }}
        >
          {icon.jsonAnimation ? (
            <div className={'picker-lottie-wrap'}>
              <LottieIcon versionId={icon.versionId} autoPlay={false} />
            </div>
          ) : icon.originalZipSequence ? (
            <PNGSequence item={icon} showcase />
          ) : (
            <object
              data={icon?.thumbnailUrls[0] ?? 'https://www.computerhope.com/jargon/e/error.png'}
              type="image/png"
            >
              <img src="https://www.computerhope.com/jargon/e/error.png" alt={'icon'} />
            </object>
          )}
          <div>{icon.name}</div>
        </div>
      ));
    else return <div className={'icon-list'}>no icons available</div>;
  };
  return (
    <div
      className={`mb-2 property-card ${active ? 'active-card' : ''}`}
      onClick={() => selectedIcon && onClick(selectedIcon, active)}
      style={{ animation: isTemp ? 'none' : 'inherit' }}
    >
      <PortalWithState closeOnOutsideClick closeOnEsc>
        {({ openPortal, closePortal, isOpen, portal }) => (
          <>
            <>
              <div className={'icon-header'}>
                {weatherType}
                <div
                  className={'icon-arrows'}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    let left;
                    let right;
                    if (window.screen.width - 235 < e.clientX)
                      right = window.screen.width - e.clientX;
                    else left = e.clientX;
                    const clickPosition = {
                      top: e.clientY,
                      left: left,
                      right: right,
                    };
                    clickPosition && setTop(clickPosition);
                    isOpen && closePortal();
                    openPortal();
                  }}
                >
                  <AiOutlineEdit />
                </div>
              </div>
              <div className={'prop-icon-val'}>
                {selectedIcon?.jsonAnimation ? (
                  <LottieIcon versionId={selectedIcon.versionId} />
                ) : selectedIcon?.originalZipSequence ? (
                  <PNGSequence item={selectedIcon} showcase />
                ) : (
                  <object data={selectedIcon?.thumbnailUrls[0]} type="image/png">
                    <img src="https://www.computerhope.com/jargon/e/error.png" alt={'icon'} />
                  </object>
                )}
              </div>
              <div>{selectedIcon?.name}</div>
            </>
            {portal(
              <div
                className={'icon-picker-list'}
                style={{
                  position: 'fixed',
                  ...top,
                  maxHeight: height - (top ? top.top : 0),
                }}
              >
                {renderAllIcons(closePortal)}
              </div>,
            )}
          </>
        )}
      </PortalWithState>
    </div>
  );
};
