import { BoxDef } from './BoxDef';
import { ForecastWDSourceDef } from './ForecastWDSourceDef';
import { GeoLocationDef } from './GeoLoactionDef';
import { PointWDElementDef } from './PointWDElementDef';
import { PositionControlDef } from './PositionControlDef';
import { RichTextDef } from './RichTextDef';
import { TextAnimation } from './TextAnimation';
import { TimeControlDef } from './TimeControlDef';

export class ForecastWDElementDef extends PointWDElementDef {
  forecastWDSource: ForecastWDSourceDef;
  timeControls: TimeControlDef[];
  positionControl = new PositionControlDef(100, 100);
  geoLocation: GeoLocationDef;
  fontColor = 'rgba(255, 255, 255, 255)';
  fontSize = 5;
  fontFamily: string;
  fontType: string;
  fontVariantId: string;
  textTransform:
    | 'none'
    | 'capitalize'
    | 'uppercase'
    | 'lowercase'
    | 'full-width'
    | 'full-size-kana';
  fontAlignment: 'left';
  strokeWidth = 0;
  strokeColor = 'rgba(255, 255, 255, 255)';
  richText: RichTextDef;
  textAnimation = new TextAnimation();
  boxDef = new BoxDef();
  displayUnitOfMeasurement: boolean;
  enabled: boolean;
  constructor(start?: number, end?: number) {
    super();
    this.timeControls = [new TimeControlDef(start, end)];
    this.displayUnitOfMeasurement = true;
  }
}
