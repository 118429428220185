import './style.scss';

// import { useKeycloak } from '@react-keycloak/web';
import { cloneDeep, isEqual } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import Button from '../../../atoms/button/Button';
import {
  getDefaultPalettes,
  postGribLayer,
  postGribLayerWithVisualisation,
} from '../../../core/api/mapLayers/LayersAPI';
import { useGetParameterMappings } from '../../../core/api/mapLayers/useGetParameterMappings';
import { shouldHaveInterpolateOption } from '../../../core/weather-data/helpers/interpolationPropertyHelper';
import { WeatherDataLoader } from '../../../core/weather-data/WeatherDataLoader';
import { fromGeoBoxToBoundsWDLayers } from '../../../helpers/boundsManage';
import {
  DEFAULT_FONT_FAMILY,
  DEFAULT_FONT_TYPE,
  DEFAULT_FONT_VARIANT_ID,
  MAX_FULLSCREEN_HEIGHT,
} from '../../../model/constants/constants';
import { C9ProjectDef } from '../../../model/definitions/C9ProjectDef';
import { ColorPalette } from '../../../model/definitions/ColorPalette';
import { ColorPaletteDef } from '../../../model/definitions/ColorPaletteDef';
import { DataFrameDef } from '../../../model/definitions/DataFrameDef';
import { GribMapLayer } from '../../../model/definitions/GribMapLayer';
import { MapPanelDef } from '../../../model/definitions/MapPanelDef';
import { PickerDef } from '../../../model/definitions/PickerDef';
import { PositionControlDef } from '../../../model/definitions/PositionControlDef';
import { ColorPaletteParamTypeEnum } from '../../../model/enums/ColorPaletteParamTypeEnum';
import { FilteringTypeEnum, PostProcessingTypeEnum } from '../../../model/enums/FilteringTypeEnum';
import { InterpolationEnum } from '../../../model/enums/InterpolationEnum';
import { OrientationTypeEnum } from '../../../model/enums/OrientationTypeEnum';
import {
  isTileVisualisationType,
  VisualisationTypeEnum,
} from '../../../model/enums/VisualisationTypeEnum';
import { transformAbsoluteToPercent } from '../../../molecules/canvasElements/utils';
import { getZindexOfMapLayer } from '../../../molecules/mapElement/helpers';
import Modal from '../../../molecules/modal/Modal';
import { generateSetup } from '../../../molecules/palette/PaletteRefactor';
import { ActiveDef, setDisplayedFlyOvers } from '../../../store/slices/active-slice';
import { updateMapLayer } from '../../../store/slices/project-slice';
import { RootState } from '../../../store/store';
import MapGribLayers from '../properties/mapLayersProperties/MapGribLayers';
import { getFirstLayerRange } from './frameRangeHelpers';

interface Props {
  opened: boolean;
  onClose: () => void;
  mapLayer: MapPanelDef;
  gribLayerEdit?: GribMapLayer;
  applyLayer?: boolean;
}

const MapGribLayersModal: FC<Props> = ({
  mapLayer,
  opened,
  onClose,
  gribLayerEdit,
  applyLayer,
}) => {
  const { activeScene, activeElement, activeAspectRatio } = useSelector<RootState, ActiveDef>(
    (state) => state.active,
  );
  // const {
  //   keycloak: { idTokenParsed },
  // } = useKeycloak();
  const project = useSelector<RootState, C9ProjectDef>((state) => state.project.present.project);
  const enterpriseAccountId = project.enterpriseAccountId;
  const dispatch = useDispatch();
  const [param, setParam] = useState<PickerDef | undefined>(
    gribLayerEdit ? gribLayerEdit.gribSource?.parameterType : undefined,
  );
  const [source, setSource] = useState<PickerDef | undefined>(
    gribLayerEdit ? gribLayerEdit.gribSource?.gribSource : undefined,
  );
  const [dataProductId, setDataProductId] = useState<string | null>(
    gribLayerEdit ? gribLayerEdit.gribSource.dataProductId : null,
  );
  const { data: parameterMappings } = useGetParameterMappings({
    dataProductId,
    parameter: param?.name,
  });
  const [gribModel, setGribModel] = useState<PickerDef | undefined>(
    gribLayerEdit ? gribLayerEdit.gribSource?.gribModel : undefined,
  );
  const [visualisationType, setVisualisationType] = useState<VisualisationTypeEnum | undefined>(
    gribLayerEdit?.layerSetup?.visualisationType
      ? gribLayerEdit?.layerSetup?.visualisationType
      : VisualisationTypeEnum.HEATMAP,
  );
  const [enableInterpolation, setEnableInterpolation] = useState(
    gribLayerEdit ? gribLayerEdit.enableInterpolation : true,
  );
  // Wind particle settings
  const [windParticleDensity, setWindParticleDensity] = useState<number>(
    gribLayerEdit?.layerSetup?.windParticles?.particleDensity ||
      gribLayerEdit?.layerSetup?.windParticles?.particleDensity === 0
      ? gribLayerEdit?.layerSetup?.windParticles?.particleDensity
      : 1,
  );

  const { data: tempPaletteData } = useQuery(
    'temperature-palette',
    () => getDefaultPalettes(ColorPaletteParamTypeEnum.TEMPERATURE),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  );
  const { data: cloudPaletteData } = useQuery(
    'cloud-palette',
    () => getDefaultPalettes(ColorPaletteParamTypeEnum.TEMPERATURE),
    {
      staleTime: Infinity,
      onSuccess: (data) => palette === null && setPalette(data[0]),
    },
  );
  const [palette, setPalette] = useState<ColorPaletteDef | null>(
    tempPaletteData
      ? gribLayerEdit
        ? gribLayerEdit.layerSetup.colorPaletteDef
        : tempPaletteData[0]
      : null,
  );
  const [colorStops, setColorStops] = useState<ColorPalette | Record<string, string>>(
    gribLayerEdit?.layerSetup?.colorPaletteDef?.colorStops
      ? gribLayerEdit.layerSetup.colorPaletteDef?.colorStops
      : {},
  );
  const [interval, setInterval] = useState(1);
  const [embossEffect, setEmbossEffect] = useState(
    gribLayerEdit ? gribLayerEdit.layerSetup.embossEffect : 7,
  );
  const [numberOfIterations, setNumberOfIterations] = useState(
    gribLayerEdit ? gribLayerEdit.layerSetup.numberOfIterations : 4,
  );
  const [isolineWidth, setIsolineWidth] = useState(
    gribLayerEdit ? gribLayerEdit.layerSetup.isolineWidth ?? 1 : 1,
  );
  const [frameLoading, setFrameLoading] = useState(false);
  const [name, setName] = useState<string | undefined>(
    gribLayerEdit ? gribLayerEdit.name : undefined,
  );
  const [z, setZ] = useState(gribLayerEdit ? gribLayerEdit.zindex : getZindexOfMapLayer(mapLayer));

  const firstLayerRange = getFirstLayerRange(mapLayer);

  const selectedFrames: DataFrameDef[] = [];
  if (gribLayerEdit && gribLayerEdit.dataFrames) {
    selectedFrames.push(...gribLayerEdit.dataFrames);
  }
  const [selectedDataFrames, setSelectedDataFrames] = useState<Array<DataFrameDef>>(selectedFrames);
  useEffect(() => {
    if (selectedDataFrames.length === 1) {
      setSelectedDataFrames([...selectedFrames]);
    }
  }, [param, source, gribModel]);
  const transformedBounds = fromGeoBoxToBoundsWDLayers(mapLayer.baseMapSetup.boundingBox);
  const [interpolation, setInterpolation] = useState<InterpolationEnum>(
    gribLayerEdit ? gribLayerEdit.layerSetup.interpolation : InterpolationEnum.LINEAR,
  );
  const [filteringType, setFilteringType] = useState<FilteringTypeEnum | null>(
    gribLayerEdit ? gribLayerEdit.layerSetup.preprocessing : FilteringTypeEnum.LOW_BANDPASS,
  );
  const [framesDensity, setFramesDensity] = useState<number>(
    gribLayerEdit ? gribLayerEdit.dataFramesDensity : 1,
  );
  const [postProcessing, setPostProcessing] = useState<PostProcessingTypeEnum | null>(
    gribLayerEdit ? gribLayerEdit.layerSetup.postprocessing : null,
  );
  function onMapLayerChange(propertyPath: Paths<MapPanelDef>, newValue: GribMapLayer[]) {
    dispatch(
      updateMapLayer({
        activeScene,
        newValue,
        elementId: activeElement,
        propertyPath,
      }),
    );
  }

  function isRelevantDataChanged() {
    if (!gribLayerEdit || applyLayer) return true;
    return (
      !isEqual(gribLayerEdit.gribSource.parameterType, param) ||
      !isEqual(gribLayerEdit.gribSource.gribSource, source) ||
      !isEqual(gribLayerEdit.gribSource.gribModel, gribModel) ||
      !isEqual(gribLayerEdit.layerSetup.preprocessing, filteringType) ||
      !isEqual(gribLayerEdit.layerSetup.numberOfIterations, numberOfIterations) ||
      !isEqual(gribLayerEdit.layerSetup.visualisationType, visualisationType) ||
      !isEqual(gribLayerEdit.dataFramesDensity, framesDensity) ||
      !isEqual(gribLayerEdit.layerSetup.windParticles.particleDensity, windParticleDensity) ||
      !isEqual(
        gribLayerEdit?.layerSetup?.colorPaletteDef?.colorStops?.pallet,
        palette?.colorStops?.pallet,
      )
    );
  }

  function isIrelevantDataChanged() {
    if (!gribLayerEdit) return true;
    return (
      !isEqual(gribLayerEdit.dataFrames, selectedDataFrames) ||
      !isEqual(gribLayerEdit.name, name) ||
      !isEqual(gribLayerEdit.zindex, z) ||
      !isEqual(gribLayerEdit.enableInterpolation, enableInterpolation) ||
      !isEqual(gribLayerEdit.layerSetup.colorPaletteDef, palette) ||
      !isEqual(gribLayerEdit.layerSetup.postprocessing, postProcessing) ||
      !isEqual(gribLayerEdit.layerSetup.embossEffect, embossEffect) ||
      !isEqual(gribLayerEdit.layerSetup.colorPaletteDef?.colorStops, palette?.colorStops) ||
      !isEqual(gribLayerEdit.layerSetup.interpolation, interpolation)
    );
  }

  function onIrelevantDataChanged() {
    const cloned = cloneDeep(mapLayer.wdSpace[0].gribMapLayers);
    const found = cloned.find((l) => l.id === gribLayerEdit?.id);
    if (found && param) {
      found.name = name!;
      found.zindex = Number(z);
      found.layerSetup.interpolation = interpolation;
      found.enableInterpolation =
        shouldHaveInterpolateOption(param.name, found.layerSetup.visualisationType) ||
        parameterMappings?.isInterpolationEnabled
          ? enableInterpolation
          : false;
      if (palette) {
        found.layerSetup.colorPaletteDef = palette;
        found.layerSetup.paletteLegendScaling = palette.paletteLegendScaling;
      }
      found.layerSetup.postprocessing = postProcessing;
      found.layerSetup.embossEffect = embossEffect;
      found.dataFrames = selectedDataFrames;
      // @ts-ignore
      onMapLayerChange('wdSpace[0].gribMapLayers', cloned);
    }
  }

  const addLayer = async () => {
    if (source && param && name && gribModel) {
      if (!isRelevantDataChanged()) {
        if (isIrelevantDataChanged()) {
          onIrelevantDataChanged();
        }
        return;
      }

      const layerToAdd = new GribMapLayer(
        dataProductId,
        name,
        source,
        param,
        gribModel,
        {
          leftLongitude: transformedBounds[0],
          rightLongitude: transformedBounds[1],
          upperLatitude: transformedBounds[2],
          lowerLatitude: transformedBounds[3],
        },
        gribLayerEdit ? gribLayerEdit.timeControls[0] : mapLayer.timeControls[0],
        selectedDataFrames,
        z,
      );
      layerToAdd.layerType = gribLayerEdit ? gribLayerEdit.layerType : 'GRIB';
      layerToAdd.layerSetup.interpolation = interpolation;
      layerToAdd.enableInterpolation = shouldHaveInterpolateOption(param.name, visualisationType)
        ? enableInterpolation
        : false;
      layerToAdd.layerSetup.preprocessing = filteringType;
      layerToAdd.layerSetup.postprocessing = postProcessing;
      layerToAdd.layerSetup.embossEffect = embossEffect;
      layerToAdd.layerSetup.numberOfIterations = numberOfIterations;
      layerToAdd.layerSetup.visualisationType = visualisationType!;
      if (layerToAdd.layerSetup.visualisationType === VisualisationTypeEnum.ISOLINE) {
        layerToAdd.layerSetup.isolineWidth = isolineWidth;
      }
      layerToAdd.dataFramesDensity = framesDensity;
      layerToAdd.layerSetup.windParticles.particleDensity = windParticleDensity;
      layerToAdd.opacity = gribLayerEdit?.opacity ?? 1;
      layerToAdd.layerSetup.baseAppUrl = process.env.REACT_APP_API_BASE_URL as string;
      if (palette) {
        layerToAdd.layerSetup.colorPaletteDef = palette;
        layerToAdd.layerSetup.paletteLegendScaling = palette.paletteLegendScaling;
      }
      if (layerToAdd.layerSetup.colorPaletteDef && !layerToAdd.layerSetup.colorPaletteDef.setup)
        layerToAdd.layerSetup.colorPaletteDef = {
          ...layerToAdd.layerSetup.colorPaletteDef,
          setup: generateSetup(palette?.colorStops),
        };
      layerToAdd.layerSetup.paletteLegendOrientation =
        gribLayerEdit?.layerSetup.paletteLegendOrientation ?? OrientationTypeEnum.HORIZONTAL;
      layerToAdd.layerSetup.displayPaletteLegend = !!gribLayerEdit?.layerSetup.displayPaletteLegend;
      layerToAdd.layerSetup.displayLegendValues =
        gribLayerEdit?.layerSetup.displayLegendValues ?? true;
      layerToAdd.layerSetup.legendFontSize = gribLayerEdit?.layerSetup.legendFontSize ?? 3;
      layerToAdd.layerSetup.fontFamily =
        gribLayerEdit?.layerSetup.fontFamily ?? DEFAULT_FONT_FAMILY;
      layerToAdd.layerSetup.fontType = gribLayerEdit?.layerSetup.fontType ?? DEFAULT_FONT_TYPE;
      layerToAdd.layerSetup.fontVariantId =
        gribLayerEdit?.layerSetup.fontVariantId ?? DEFAULT_FONT_VARIANT_ID;
      layerToAdd.layerSetup.paletteLegendPositionControl =
        gribLayerEdit?.layerSetup.paletteLegendPositionControl ??
        new PositionControlDef(
          transformAbsoluteToPercent(500, activeAspectRatio, 'width', MAX_FULLSCREEN_HEIGHT),
          transformAbsoluteToPercent(60, activeAspectRatio, 'height', MAX_FULLSCREEN_HEIGHT),
          transformAbsoluteToPercent(30, activeAspectRatio, 'width', MAX_FULLSCREEN_HEIGHT),
          transformAbsoluteToPercent(
            MAX_FULLSCREEN_HEIGHT - 80,
            activeAspectRatio,
            'height',
            MAX_FULLSCREEN_HEIGHT,
          ),
        );

      dispatch(setDisplayedFlyOvers({ displayedFlyOver: activeElement! }));
      setFrameLoading(true);
      try {
        if (isTileVisualisationType(visualisationType)) {
          const addedLayer = await postGribLayer(layerToAdd, mapLayer.baseMapSetup.id);
          const existingLayers = gribLayerEdit
            ? mapLayer.wdSpace[0].gribMapLayers.filter((l) => l.id !== gribLayerEdit.id)
            : mapLayer.wdSpace[0].gribMapLayers;

          // @ts-ignore
          onMapLayerChange('wdSpace[0].gribMapLayers', [...existingLayers, { ...addedLayer }]);
        }

        if (!isTileVisualisationType(visualisationType) && layerToAdd.layerSetup.colorPaletteDef) {
          if (
            gribLayerEdit &&
            !isEqual(gribLayerEdit.layerSetup.visualisationType, visualisationType)
          ) {
            WeatherDataLoader.clearSelectedFramesCache(mapLayer.id, selectedFrames);
          }
          const cloneLayer = cloneDeep(layerToAdd);
          if (cloneLayer.layerSetup.colorPaletteDef) {
            cloneLayer.layerSetup.colorPaletteDef.colorStops.interval = null;
          }
          const addedLayer = await postGribLayerWithVisualisation({
            gribMapLayer: cloneLayer,
            baseMapDef: mapLayer.baseMapSetup,
          });
          const existingLayers = gribLayerEdit
            ? mapLayer.wdSpace[0]?.gribMapLayers.filter((l) => l.id !== gribLayerEdit.id)
            : mapLayer.wdSpace[0]?.gribMapLayers;
          const projCloned = cloneDeep(project);
          const mapFound = projCloned.sceneDefs
            .find((sc) => sc.id === activeScene)
            ?.mapPanels.find((m) => m.id === mapLayer.id);

          if (mapFound) {
            mapFound.wdSpace[0].gribMapLayers = [...existingLayers, { ...addedLayer.gribMapLayer }];

            // @ts-ignore
            onMapLayerChange('wdSpace[0].gribMapLayers', [
              ...existingLayers,
              { ...addedLayer.gribMapLayer },
            ]);
          }
        }
      } catch (e) {
        setFrameLoading(false);
        toast.error('Error adding layer!');
        console.error(e);
      }
    }
  };

  async function handleAddLayer() {
    await addLayer();
    onClose();
  }
  return (
    <Modal
      isOpen={opened}
      onClose={onClose}
      className="add-layers-wrapper"
      header={'Set layers'}
      footer={
        <div className={'modal-footer'}>
          <Button
            disabled={
              !(name && source && param && palette && selectedDataFrames.length && gribModel)
            }
            label={gribLayerEdit && !applyLayer ? 'Apply changes' : 'Add layer'}
            buttonType="primary"
            onClick={handleAddLayer}
            loading={frameLoading}
          />
        </div>
      }
    >
      <div className="add-layers-body">
        <MapGribLayers
          oldLayer={gribLayerEdit && !dataProductId ? true : false}
          dataProductId={dataProductId}
          setDataProductId={setDataProductId}
          enterpriseAccountId={enterpriseAccountId}
          framesDensity={framesDensity}
          setFramesDensity={(e) => {
            setFramesDensity(e);
          }}
          embossEffect={embossEffect}
          setEmbossEffect={setEmbossEffect}
          numberOfIterations={numberOfIterations}
          setNumberOfIterations={setNumberOfIterations}
          isolineWidth={isolineWidth}
          setIsolineWidth={setIsolineWidth}
          param={param}
          source={source}
          name={name}
          setName={setName}
          setParam={setParam}
          z={z}
          setZ={setZ}
          setSource={setSource}
          selectedDataFrames={selectedDataFrames}
          setSelectedDataFrames={setSelectedDataFrames}
          bounds={transformedBounds}
          mapLayer={mapLayer}
          onMapLayerChange={onMapLayerChange}
          interpolation={interpolation}
          setInterpolation={setInterpolation}
          enableInterpolation={enableInterpolation}
          setEnableInterpolation={setEnableInterpolation}
          filteringType={filteringType}
          setFilteringType={setFilteringType}
          postProcessing={postProcessing}
          setPostProcessingType={setPostProcessing}
          palette={palette}
          setPalette={(pal) => {
            if (pal?.colorStops?.interval != null) {
              setPalette(pal);
            } else {
              if (pal) {
                const val = {
                  ...pal,
                  colorStops: {
                    ...pal.colorStops,
                    interval:
                      palette?.colorStops?.interval != null ? palette.colorStops.interval : 2,
                  },
                };
                setPalette(val);
              }
            }
          }}
          cloudPalettes={cloudPaletteData}
          tempPalettes={tempPaletteData}
          colorStops={colorStops}
          setColorStops={setColorStops}
          interval={interval}
          setInterval={setInterval}
          gribLayerEdit={gribLayerEdit}
          rangeFromFirstLayer={firstLayerRange}
          gribModel={gribModel}
          setGribModel={setGribModel}
          visualisationType={visualisationType}
          setVisualisationType={setVisualisationType}
          windParticleDensity={windParticleDensity}
          setWindParticleDensity={setWindParticleDensity}
          parameterMappings={parameterMappings}
        />
      </div>
    </Modal>
  );
};

export default MapGribLayersModal;
