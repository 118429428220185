import {
  DragLayerMonitorProps,
  DropOptions,
  getBackendOptions,
  isAncestor,
  MultiBackend,
  NodeModel,
  RenderParams,
  Tree,
} from '@minoru/react-dnd-treeview';
import { set } from 'lodash';
import { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { useDispatch, useSelector } from 'react-redux';

import { ElementsEnum } from '../../core/ui/enums/ElementsEnum';
import { enumToPath } from '../../helpers/enumToPath';
import { LogicalGroupElement } from '../../model/definitions/LogicalGroupElement';
import { MapPanelDef } from '../../model/definitions/MapPanelDef';
import { SceneDef } from '../../model/definitions/SceneDef';
import { WeatherPosterDef } from '../../model/definitions/WeatherPosterDef';
import { PanelDefs } from '../../model/UI/PanelDef';
import { ActiveDef, addToMultiselect, setOpenGroup } from '../../store/slices/active-slice';
import { updateGroupPos, updateLayerGroupPos } from '../../store/slices/project-slice';
import { RootState } from '../../store/store';
import { AllElementsDefs } from '../../types/elements';
import { CustomNode } from './elementList/CustomNode';
import { DragPreview } from './elementList/DragPreview';
import styles from './elementList/LogicalList.module.scss';
import MapListElement from './elementList/MapListElement';
import { MultipleDragPreview } from './elementList/MultipleDragPreview';
import { PosterElementList } from './elementList/PosterElementList';
import RegularListElement from './elementList/RegularListElement';
import { GroupListElement } from './timelineComponents/GroupListElement';

interface LogicalGroupsListProps {
  elements: SceneDef;
  cut: () => void;
  deleteElement: () => void;
  select?: boolean;
  setSelect?: (e: boolean) => void;
  enable?: (e: boolean, id: string | number, elementType: string) => void;
}
export const LogicalGroupsList = ({
  elements,
  cut,
  deleteElement,
  select,
  setSelect,
  enable,
}: LogicalGroupsListProps) => {
  const dispatch = useDispatch();
  const { activeScene, openGroups } = useSelector<RootState>((state) => state.active) as ActiveDef;
  const [treeElements, setTreeElement] = useState<
    NodeModel<PanelDefs & LogicalGroupElement & { type: ElementsEnum }>[]
  >([]);
  const [selectedNodes, setSelectedNodes] = useState<
    NodeModel<PanelDefs & LogicalGroupElement & { type: ElementsEnum }>[]
  >([]);
  const [isCtrlPressing, setIsCtrlPressing] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  const {
    textPanels,
    weatherPosters,
    videoPanels,
    mapPanels,
    imagePanels,
    audioElements,
    logicalGroups,
    observedWDElements,
    forecastWDElements,
    pointDates,
    pointLocation,
  } = elements;
  const groups = (): Array<NodeModel<LogicalGroupElement & PanelDefs & { type: ElementsEnum }>> => {
    //@ts-ignore
    return elements.logicalGroups.map((group) => {
      return {
        id: group.id,
        parent: group.parentGroupId,
        text: group.name,
        droppable: true,
        data: { ...group, type: ElementsEnum.GROUP },
      };
    });
  };
  const handleMultiSelect = (
    clickedNode: NodeModel<PanelDefs & LogicalGroupElement & { type: ElementsEnum }>,
  ) => {
    const selectedIds = selectedNodes.map((n) => n.id);
    if (selectedIds.includes(clickedNode.id)) {
      return;
    }
    if (selectedIds.some((selectedId) => isAncestor(treeElements, selectedId, clickedNode.id))) {
      return;
    }

    let updateNodes = [...selectedNodes];
    updateNodes = updateNodes.filter((selectedNode) => {
      return !isAncestor(treeElements, clickedNode.id, selectedNode.id);
    });

    updateNodes = [...updateNodes, clickedNode];
    setSelectedNodes(updateNodes);
  };
  const handleDragEnd = () => {
    setIsDragging(false);
    setIsCtrlPressing(false);
    setSelectedNodes([]);
  };
  const handleDragStart = (
    node: NodeModel<PanelDefs & LogicalGroupElement & { type: ElementsEnum }>,
  ) => {
    const isSelectedNode = selectedNodes.some((n) => n.id === node.id);
    setIsDragging(true);

    if (!isCtrlPressing && isSelectedNode) {
      return;
    }

    if (!isCtrlPressing) {
      setSelectedNodes([node]);
      return;
    }

    if (!selectedNodes.some((n) => n.id === node.id)) {
      setSelectedNodes([...selectedNodes, node]);
    }
  };
  const handleSingleSelect = (
    node: NodeModel<PanelDefs & LogicalGroupElement & { type: ElementsEnum }>,
  ) => {
    setSelectedNodes([node]);
  };
  const handleClick = (
    e: any,
    node: NodeModel<PanelDefs & LogicalGroupElement & { type: ElementsEnum }>,
  ) => {
    if (e.ctrlKey || e.metaKey) {
      handleMultiSelect(node);
    } else {
      handleSingleSelect(node);
    }
  };
  const parseData = (
    panels: PanelDefs[],
    elementType: ElementsEnum,
  ): Array<NodeModel<PanelDefs & LogicalGroupElement & { type: ElementsEnum }>> => {
    if (panels.length > 0) {
      const nodes = panels.map((item) => {
        const parsed: NodeModel<PanelDefs & { type: ElementsEnum }> = {
          id: item.id,
          parent: item.parentGroups[0]?.groupId,
          text: item.name,
          droppable: false,
          data: { ...item, type: elementType },
        };
        return parsed;
      });
      //@ts-ignore
      return nodes
        .filter(Boolean)
        .sort(
          (a, b) =>
            a.data!.parentGroups[0].orderNumInGroup - b.data!.parentGroups[0].orderNumInGroup,
        );
    } else return [];
  };
  const updateElementByType = (
    layer: NodeModel<PanelDefs & LogicalGroupElement & { type: ElementsEnum }>,
    parent: string,
    order: number,
  ) => {
    dispatch(
      updateLayerGroupPos({
        activeScene,
        type: layer.data!.type,
        parent,
        id: layer.id as string,
        order,
      }),
    );
  };
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key.toLowerCase() === 'escape') {
        setSelectedNodes([]);
      } else if (e.ctrlKey || e.metaKey) {
        setIsCtrlPressing(true);
      }
    };

    const handleKeyUp = (e: KeyboardEvent) => {
      if (e.key.toLowerCase() === 'control' || e.key.toLowerCase() === 'meta') {
        setIsCtrlPressing(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);
  useEffect(() => {
    const tree = [
      parseData(textPanels, ElementsEnum.TEXT),
      parseData(weatherPosters, ElementsEnum.WEATHER_GRAPH),
      parseData(videoPanels, ElementsEnum.VIDEO),
      parseData(mapPanels, ElementsEnum.MAP),
      parseData(imagePanels, ElementsEnum.IMAGE),
      parseData(audioElements, ElementsEnum.AUDIO),
      parseData(observedWDElements, ElementsEnum.OBSERVED_WD),
      parseData(forecastWDElements, ElementsEnum.FORECAST_WD),
      parseData(pointDates, ElementsEnum.POINT_DATE),
      parseData(pointLocation, ElementsEnum.POINT_LOCATION),
      groups(),
    ]
      .flat()
      .sort(
        (a, b) =>
          Number(
            a.data?.parentGroups
              ? a.data?.parentGroups[0]?.orderNumInGroup
              : a.data?.orderNumInParentGroup,
          ) -
          Number(
            b.data?.parentGroups
              ? b.data?.parentGroups[0]?.orderNumInGroup
              : b.data?.orderNumInParentGroup,
          ),
      );
    setTreeElement(tree as NodeModel<PanelDefs & LogicalGroupElement & { type: ElementsEnum }>[]);
  }, [
    audioElements,
    elements,
    imagePanels,
    mapPanels,
    textPanels,
    videoPanels,
    weatherPosters,
    forecastWDElements,
    observedWDElements,
    logicalGroups,
  ]);

  const toggleGroup = (groupId: string, callback: () => void) => {
    dispatch(setOpenGroup({ groupId }));
    callback();
  };
  const handleDrop = (
    newTree: NodeModel<PanelDefs & LogicalGroupElement & { type: ElementsEnum }>[],
    options: DropOptions<PanelDefs & LogicalGroupElement & { type: ElementsEnum }>,
  ) => {
    const { dropTargetId } = options;
    const treeItem = newTree.map((node) => {
      if (selectedNodes.some((selectedNode) => selectedNode.id === node.id)) {
        return {
          ...node,
          parent: dropTargetId,
        };
      }

      return node;
    });
    setTreeElement(treeItem);
    const data = [...treeItem];
    setTreeElement(treeItem);
    data
      .filter((item) => item.parent === dropTargetId)
      .forEach((node, index) => {
        const item = { ...node };
        if (item.data && item.data.parentGroups) {
          set(item.data.parentGroups[0], 'orderNumInGroup', index);
          set(item.data.parentGroups[0], 'groupId', dropTargetId);
          updateElementByType(item, dropTargetId as string, index);
        }
        if (item.data && item.data.parentGroupId) {
          set(item.data, 'orderNumInParentGroup', index);
          set(item.data, 'parentGroupId', index);
          dispatch(
            updateGroupPos({
              activeScene,
              parent: dropTargetId as string,
              id: item.data.id as string,
              order: index,
            }),
          );
        }
      });
    setSelectedNodes([]);
  };
  const handleGroupClick = (e: React.MouseEvent, node: NodeModel, options: RenderParams) => {
    if (e.metaKey || e.ctrlKey) {
      const elementsInGroup = treeElements.filter((elem) => elem.parent === node.id);
      elementsInGroup.forEach((item) => {
        if (item.data) {
          const type = enumToPath(item.data.type);
          dispatch(addToMultiselect({ element: { element: item.data as AllElementsDefs, type } }));
        }
      });
    } else toggleGroup(node.id as string, options.onToggle);
  };
  const defPadding = 5;
  return (
    <DndProvider backend={MultiBackend} options={getBackendOptions()}>
      <Tree
        initialOpen={openGroups}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        classes={{
          root: styles.mainList,
          draggingSource: 'dragging-source',
          dropTarget: 'drop-target',
        }}
        tree={treeElements.sort(
          (a, b) =>
            (Number(a.data!.orderNumInParentGroup) ?? a.data!.parentGroups[0].orderNumInGroup) -
            (Number(b.data!.orderNumInParentGroup) ?? b.data!.parentGroups[0].orderNumInGroup),
        )}
        rootId={'00000000-0000-0000-0000-000000000000'}
        render={(node, options) => {
          const selected = selectedNodes.some((selectedNode) => selectedNode.id === node.id);
          if (node.data?.type === ElementsEnum.GROUP) {
            return (
              <GroupListElement
                key={node.id}
                activeScene={activeScene}
                node={node}
                isOpen={options.isOpen}
                depth={options.depth}
                onToggle={options.onToggle}
                openGroups={openGroups}
                onClick={(e) => handleGroupClick(e, node, options)}
                paddingLeft={defPadding * options.depth}
              />
            );
          } else if (node.data?.type === ElementsEnum.MAP) {
            return (
              <CustomNode
                node={node}
                {...options}
                isSelected={selected}
                isDragging={selected && isDragging}
                onClick={handleClick}
              >
                <MapListElement
                  enable={enable}
                  enabled={node.data.enabled}
                  item={node.data as MapPanelDef}
                  deleteElement={deleteElement}
                  cut={cut}
                  select={select}
                  setSelect={setSelect}
                  paddingLeft={defPadding * options.depth + 7}
                  onClick={(e) => handleClick(e, node)}
                />
              </CustomNode>
            );
          } else if (node.data?.type === ElementsEnum.WEATHER_GRAPH) {
            return (
              <CustomNode
                node={node}
                {...options}
                isSelected={selected}
                isDragging={selected && isDragging}
                onClick={handleClick}
              >
                <PosterElementList
                  enable={enable}
                  item={node.data as WeatherPosterDef}
                  deleteElement={deleteElement}
                  select={select}
                  setSelect={setSelect}
                  paddingLeft={defPadding * options.depth + 7}
                  onClick={(e) => handleClick(e, node)}
                />
              </CustomNode>
            );
          } else {
            return (
              <CustomNode
                node={node}
                {...options}
                isSelected={selected}
                isDragging={selected && isDragging}
                onClick={handleClick}
              >
                <RegularListElement
                  //@ts-ignore
                  item={node.data}
                  enable={enable}
                  enabled={node.data!.enabled}
                  id={node.data!.id}
                  elementType={node.data!.type}
                  name={node.text}
                  cut={cut}
                  deleteElement={deleteElement}
                  paddingLeft={defPadding * options.depth + 7}
                  onClick={(e) => handleClick(e, node)}
                />
              </CustomNode>
            );
          }
        }}
        dragPreviewRender={(
          monitorProps: DragLayerMonitorProps<
            PanelDefs & LogicalGroupElement & { type: ElementsEnum }
          >,
        ) => {
          if (selectedNodes.length > 1) {
            return <MultipleDragPreview dragSources={selectedNodes} />;
          }

          return <DragPreview monitorProps={monitorProps} />;
        }}
        onDrop={handleDrop}
        sort={false}
        insertDroppableFirst={false}
        canDrop={(tree, { dragSource, dropTargetId }) => {
          if (dragSource?.parent === dropTargetId) {
            return true;
          }
        }}
        dropTargetOffset={0}
      />
    </DndProvider>
  );
};
