import './style.scss';

import { Input, Modal, ModalProps, notification, Typography } from 'antd';
import { AxiosError } from 'axios';
import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useCreateForecastBiasGroup } from '../../../core/api/bias/bias-group/useCreateForecastBiasGroup';
import { useCreateObservedBiasGroup } from '../../../core/api/bias/bias-group/useCreateObservedBiasGroup';
import { BiasFilterGroupDef } from '../../../model/definitions/BiasFilterGroupDef';
import { ForecastWDElementDef } from '../../../model/definitions/ForecastWDElementDef';
import { MapPanelDef } from '../../../model/definitions/MapPanelDef';
import { ObservedWDElementDef } from '../../../model/definitions/ObservedWDElementDef';
import { ValueTypeEnum } from '../../../model/enums/ValueTypeEnum';
import { removeDuplicateIds } from '../../../molecules/canvasElements/utils';
import { ActiveDef, toggleBiasMode } from '../../../store/slices/active-slice';
import { updateMapLayer } from '../../../store/slices/project-slice';
import { RootState } from '../../../store/store';

const { Title } = Typography;

interface SaveModalProps extends ModalProps {
  onClose: () => void;
}
export const SaveModal: FC<SaveModalProps> = ({ open, onCancel, onClose, ...rest }) => {
  const dispatch = useDispatch();
  const { mutate: createObserved, isLoading: creatingObserved } = useCreateObservedBiasGroup();
  const { mutate: createForecast, isLoading: creatingForecast } = useCreateForecastBiasGroup();
  const [groupName, setName] = useState<string>();
  const [groupDescription, setDesc] = useState<string>();
  const { activeScene, biasedValues, addedBiasedValues, biasPanel } = useSelector<RootState>(
    (state) => state.active,
  ) as ActiveDef;

  const updateObservedOfMap = (panel: MapPanelDef) => {
    const replacementArray = [...biasedValues, ...addedBiasedValues];
    const updatedObj = {
      ...panel,
      geoPosters: panel.geoPosters.map((geoPoster) => {
        return {
          ...geoPoster,
          observedWDElements: geoPoster.observedWDElements.map((observedElement) => {
            const matchingElement = replacementArray.find(
              (replacementElement) => replacementElement.id === observedElement.id,
            );
            if (matchingElement && isObserved(matchingElement)) {
              return {
                ...matchingElement,
                observedWDSource: {
                  ...matchingElement.observedWDSource,
                  biasFilterAppliedOnValue: true,
                },
              };
            }
            return observedElement;
          }),
          forecastWDElements: geoPoster.forecastWDElements.map((observedElement) => {
            const matchingElement = replacementArray.find(
              (replacementElement) => replacementElement.id === observedElement.id,
            );

            if (matchingElement && isForecast(matchingElement)) {
              return {
                ...matchingElement,
                forecastWDSource: {
                  ...matchingElement.forecastWDSource,
                  biasFilterAppliedOnValue: true,
                },
              };
            }

            return observedElement;
          }),
        };
      }),
    };
    return updatedObj.geoPosters;
  };
  function isMap(obj: any): obj is MapPanelDef {
    if (obj) return 'wdSpace' in obj;
    return false;
  }
  const applyBias = (data: BiasFilterGroupDef) => {
    if (isMap(biasPanel)) {
      dispatch(
        updateMapLayer({
          activeScene,
          newValue: updateObservedOfMap(biasPanel),
          elementId: biasPanel.id,
          propertyPath: 'geoPosters',
        }),
      );
    }
    dispatch(toggleBiasMode());
  };
  const confirmCreateObserved = () => {
    const vals = removeDuplicateIds([
      ...addedBiasedValues,
      ...biasedValues,
    ]) as Array<ObservedWDElementDef>;
    const newVals = vals.map((item) => {
      return {
        ...item.observedWDSource,
        changedValue:
          item.observedWDSource.valueType === ValueTypeEnum.NUMERICAL
            ? item.observedWDSource.value[0].value
            : item.observedWDSource.value,
      };
    });
    groupName &&
      createObserved(
        {
          payload: {
            groupName: groupName,
            groupDescription,
            observedWDBiasSources: newVals,
          },
        },
        {
          onSuccess: (data) => {
            notification.success({ message: 'Filter created!' });
            applyBias(data);
            onClose();
          },
          onError: (error) => {
            const err = error as AxiosError;
            notification.error({ message: err.message });
            onClose();
          },
        },
      );
  };
  const confirmCreateForecast = () => {
    const vals = removeDuplicateIds([
      ...addedBiasedValues,
      ...biasedValues,
    ]) as Array<ForecastWDElementDef>;
    const newVals = vals.map((item) => {
      return {
        ...item.forecastWDSource,
        changedValue:
          item.forecastWDSource.valueType === ValueTypeEnum.NUMERICAL
            ? item.forecastWDSource.value[0].value
            : item.forecastWDSource.value,
      };
    });
    groupName &&
      createForecast(
        {
          payload: {
            groupName: groupName,
            groupDescription,
            forecastWDBiasSources: newVals,
          },
        },
        {
          onSuccess: (data) => {
            notification.success({ message: 'Filter created!' });
            applyBias(data);
            onClose();
          },
          onError: (error) => {
            const err = error as AxiosError;
            notification.error({ message: err.message });
            onClose();
          },
        },
      );
  };
  function isObserved(obj: any): obj is ObservedWDElementDef {
    if (obj) return 'observedWDSource' in obj;
    return false;
  }
  function isForecast(obj: any): obj is ForecastWDElementDef {
    if (obj) return 'forecastWDSource' in obj;
    return false;
  }
  const createFilter = () => {
    const forecastFilter =
      [...addedBiasedValues, ...biasedValues].length > 0 &&
      [...addedBiasedValues, ...biasedValues].every((item) => isForecast(item));
    const observedFilter =
      [...addedBiasedValues, ...biasedValues].length > 0 &&
      [...addedBiasedValues, ...biasedValues].every((item) => isObserved(item));
    if (forecastFilter) confirmCreateForecast();
    if (observedFilter) confirmCreateObserved();
  };
  return (
    <Modal
      onCancel={onCancel}
      open={open}
      {...rest}
      confirmLoading={creatingObserved || creatingForecast}
      onOk={createFilter}
      destroyOnClose
    >
      <Title level={3}>BIAS FILTER</Title>
      <div>
        <label className={'text-white'}>
          Name
          <Input
            value={groupName}
            className={'bias-input'}
            onChange={(e) => setName(e.target.value)}
          />
        </label>
        <label className={'text-white'}>
          Description
          <Input
            value={groupDescription}
            className={'bias-input'}
            onChange={(e) => setDesc(e.target.value)}
          />
        </label>
      </div>
    </Modal>
  );
};
