import './style.scss';

import { Checkbox } from 'antd';
import { CheckboxGroupProps } from 'antd/es/checkbox/Group';

const CheckboxGroup = Checkbox.Group;

interface AntCheckboxGroupProps extends CheckboxGroupProps {
  title: string;
}

const AntCheckboxGroup = (props: AntCheckboxGroupProps) => {
  return (
    <>
      <div className="ant-checkbox-group-title">{props.title}</div>
      <CheckboxGroup {...props} />
    </>
  );
};

export default AntCheckboxGroup;
