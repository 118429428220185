import { useMutation } from 'react-query';

import { ObservedWDSourceDef } from '../../../model/definitions/ObservedWDSourceDef';
import { axiosInstance } from '../axiosInstance';

export const useCreateObservedBias = () =>
  useMutation(
    async ({
      filterName,
      filterDescription,
      observedWDSource,
    }: {
      filterName: string;
      filterDescription: string;
      observedWDSource: ObservedWDSourceDef;
    }) => {
      await axiosInstance.post('biasFilter/observedFilter', {
        filterName,
        filterDescription,
        observedWDSource,
      });
    },
  );
