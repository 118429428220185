import { C9Object } from './C9Object';
import { GeoBoundingBoxDef } from './GeoBoundingBoxDef';
import { MapStyleDef } from './MapStyleDef';
import { SupportedProjection } from './SupportedProjection';

export class BaseMapDef extends C9Object {
  mapStyleId: string; // uuid
  baseMapUrl?: string;
  mapStyle: MapStyleDef;
  projection: SupportedProjection;
  projectionParams: string | null;
  boundingBox: GeoBoundingBoxDef;
  maxZoom?: number;
  minZoom?: number;
  osmId: string;
  baseMapConfigurationProj4: string;
  baseMapConfigurationBounds: [number, number, number, number];
  hidden?: boolean;
  inverted: boolean;
  zindex: number;
  oceanLayerColor: string | null;
}
