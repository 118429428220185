import './style.scss';

import { Tooltip } from 'antd';
import { Badge, Checkbox } from 'flowbite-react';
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';
import { IoReload } from 'react-icons/io5';

import Button from '../../../../atoms/button/Button';
import { LottieIcon } from '../../../../atoms/LottieIcon';
import { IconTemplateDef } from '../../../../model/definitions/IconTemplateDef';
import { MarketplaceTemplateEnum } from '../../../../model/enums/MarketplaceTemplateEnum';
import PNGSequence from '../../../../molecules/canvasElements/sequence/PNGSequence';
import { useRetryPngSequence } from '../../queries-NEW/useRetryPngSequence';

interface ImagePreviewCardProps {
  id: string;
  name: string;
  imgSrc: string;
  checked: boolean;
  weatherTypeCodes: string[];
  versionId: string;
  jsonAnimation: boolean;
  preview?: boolean;
  onCheck?: () => void;
  onDelete?: (id: string) => void;
  onEdit?: () => void;
  isSequence?: boolean;
  item: IconTemplateDef;
  admin: boolean;
}

const ImagePreviewCard = ({
  id,
  name,
  imgSrc,
  checked,
  weatherTypeCodes,
  versionId,
  jsonAnimation,
  preview,
  onCheck,
  onEdit,
  onDelete,
  isSequence,
  item,
  admin,
}: ImagePreviewCardProps) => {
  const { mutate } = useRetryPngSequence();

  const handleEdit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (onEdit) onEdit();
  };
  const handleDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (onDelete) onDelete(id);
  };

  const handleRetryPngSequence = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const data = {
      iconTemplateId: item.id,
      lottieJsonStorageId: item.file.id,
      pngSequenceStorageId: item.pngSequenceStorageId as string,
      templateType: MarketplaceTemplateEnum.ICON_SET_TEMPLATE,
    };
    mutate(data);
  };

  const pngSequenceNotCreated = item.jsonAnimation && !item.pngSequenceCreated;

  return (
    <div
      title={weatherTypeCodes.length === 0 ? 'Missing tags' : 'Icon set'}
      className={`image-preview-card group cursor-pointer ${
        weatherTypeCodes.length > 0 ? 'card-default' : 'card-danger'
      } ${checked && 'checked'}`}
    >
      {checked && <Checkbox checked={checked} onChange={onCheck} readOnly />}
      {pngSequenceNotCreated && (
        <Badge
          style={{
            position: 'absolute',
            zIndex: 1,
            top: 6,
            left: 6,
            padding: '2px 5px',
            width: 'fit-content',
            fontSize: '9px',
          }}
          color={'failure'}
          size="sm"
        >
          PNG Sequence Not Created
        </Badge>
      )}
      {jsonAnimation ? (
        <LottieIcon versionId={versionId} />
      ) : isSequence ? (
        <PNGSequence item={item} showcase />
      ) : (
        <img className="aspect-square" src={imgSrc} alt="img" />
      )}
      <div className={`image-preview-card__name ${preview && 'group-hover:!block'}`}>{name}</div>
      {!preview && (
        <div className="image-preview-card__buttons">
          <Button buttonType="link" icon={<AiOutlineEdit />} size="small" onClick={handleEdit} />
          <Button
            buttonType="danger"
            icon={<AiOutlineDelete />}
            size="small"
            onClick={handleDelete}
          />
          <Tooltip title="Retry PNG Sequence Creation">
            <div>
              {pngSequenceNotCreated && admin && (
                <Button
                  buttonType="primary"
                  icon={<IoReload />}
                  size="small"
                  onClick={handleRetryPngSequence}
                />
              )}
            </div>
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default ImagePreviewCard;
