import { SymbolLayerDef } from '../../model/definitions/SymbolLayerDef';
import SymbolLayerFont from './SymbolLayerFont';

const SymbolLayerContainer = ({ symbolLayers }: { symbolLayers: SymbolLayerDef[] }) => {
  return (
    <>
      {symbolLayers.map(
        (layer: SymbolLayerDef) =>
          layer.symbolSource?.defaultStyle?.fontFamily && (
            <SymbolLayerFont
              key={layer.id}
              fontFamily={layer.symbolSource.defaultStyle.fontFamily}
            />
          ),
      )}
    </>
  );
};

export default SymbolLayerContainer;
