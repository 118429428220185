import './style.scss';

import { ColorPicker } from 'antd';
import { Select } from 'flowbite-react';
import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// import { toast } from 'react-toastify';
import Button from '../../../atoms/button/Button';
import Input from '../../../atoms/input/Input';
import {
  useGetEventSources,
  useGetEventTimestamps,
} from '../../../core/api/point/useGetEventParameters';
import { fromGeoBoxToBoundsWDLayers } from '../../../helpers/boundsManage';
import { DataFrameDef } from '../../../model/definitions/DataFrameDef';
import { EventMapLayer } from '../../../model/definitions/EventMapLayer';
import { EventSourceDef, SourceDef } from '../../../model/definitions/EventSourceDef';
import { MapPanelDef } from '../../../model/definitions/MapPanelDef';
import { getZindexOfMapLayer } from '../../../molecules/mapElement/helpers';
import Modal from '../../../molecules/modal/Modal';
import { ActiveDef } from '../../../store/slices/active-slice';
import { updateMapLayer } from '../../../store/slices/project-slice';
import { RootState } from '../../../store/store';
// import { PaletteColorPicker } from '../properties/mapLayersProperties/PalettecolorPicker';
import { EventTimestampsRange } from './EventTimestampRange';
// import { FramesRange } from './FramesRange';

interface Props {
  opened: boolean;
  onClose: () => void;
  mapLayer: MapPanelDef;
  gribLayerEdit?: EventMapLayer;
  applyLayer?: boolean;
}

const MapEventLayersModal: FC<Props> = ({
  mapLayer,
  opened,
  onClose,
  gribLayerEdit,
  applyLayer,
}) => {
  const { activeScene, activeElement } = useSelector<RootState, ActiveDef>((state) => state.active);
  const dispatch = useDispatch();
  const transformedBounds = fromGeoBoxToBoundsWDLayers(mapLayer.baseMapSetup.boundingBox);
  const [threshold, setThreshold] = useState(
    gribLayerEdit?.eventSource?.minimumPower ? gribLayerEdit?.eventSource?.minimumPower : '0',
  );
  const [width, setWidth] = useState(gribLayerEdit?.radius ? gribLayerEdit.radius : '10');
  const [color, setColor] = useState(
    gribLayerEdit?.color ? gribLayerEdit.color : 'rgba(255, 0, 0, 1)',
  );

  const [dataProductId, setDataProductId] = useState<string | null>(
    gribLayerEdit?.eventSource?.eventSource?.dataProductId
      ? gribLayerEdit.eventSource.eventSource?.dataProductId
      : null,
  );
  const { data: sourceQuery, isLoading: isSourceLoading } = useGetEventSources();
  const { data: timestampsQuery } = useGetEventTimestamps(
    dataProductId,
    transformedBounds,
    Number(threshold),
  );
  const [z /*setZ*/] = useState(
    gribLayerEdit ? gribLayerEdit.zindex : getZindexOfMapLayer(mapLayer),
  );
  const [eventSource, setEventSource] = useState<SourceDef | undefined>(
    gribLayerEdit ? gribLayerEdit.eventSource.eventSource : undefined,
  );

  const [selectedEvents, setSelectedEvents] = useState(
    gribLayerEdit?.dataFrames ? gribLayerEdit.dataFrames : [],
  );

  function onRangeChange(values: number[]) {
    const start = values[0];
    const end = values[values.length - 1];
    const frames: DataFrameDef[] = [];
    timestampsQuery?.forEach((ts) => {
      if (ts.timestamp >= start && ts.timestamp <= end) {
        frames.push({ ...ts });
      }
    });

    setSelectedEvents(frames);
  }

  function onMapLayerChange(propertyPath: Paths<MapPanelDef>, newValue: EventMapLayer[]) {
    dispatch(
      updateMapLayer({
        activeScene,
        newValue,
        elementId: activeElement,
        propertyPath,
      }),
    );
  }

  function addLayer() {
    const layerToAdd = new EventMapLayer();
    layerToAdd.dataFrameDensity = 'hourly';
    layerToAdd.dataFrames = selectedEvents;
    layerToAdd.enabled = true;
    layerToAdd.color = color || 'rgba(255, 0, 0, 1)';
    layerToAdd.radius = !isNaN(Number(width)) ? Number(width) : 10;
    layerToAdd.timeControls = gribLayerEdit
      ? gribLayerEdit.timeControls
      : [mapLayer.timeControls[0]];
    layerToAdd.zindex = z;
    const sourceDef = new EventSourceDef();
    sourceDef.eventSource = new SourceDef();
    sourceDef.minimumPower = !isNaN(Number(threshold)) ? Number(threshold) : 0;
    sourceDef.dataProductId = dataProductId || '';
    sourceDef.eventSource.dataProductId = dataProductId || '';
    layerToAdd.eventSource = sourceDef;
    let existingLayers = gribLayerEdit
      ? mapLayer.wdSpace[0].eventLayers.filter((l) => l.id !== gribLayerEdit.id)
      : mapLayer.wdSpace[0].eventLayers;
    if (!existingLayers) existingLayers = [];
    // @ts-ignore
    onMapLayerChange('wdSpace[0].eventLayers', [...existingLayers, { ...layerToAdd }]);
    onClose();
  }

  return (
    <Modal
      isOpen={opened}
      onClose={onClose}
      className="add-layers-wrapper"
      header={'Set layers'}
      footer={
        <div className={'modal-footer'}>
          <Button
            onClick={addLayer}
            label={gribLayerEdit && !applyLayer ? 'Apply changes' : 'Add layer'}
          />
        </div>
      }
    >
      <div className="add-layers-body">
        <div className="layer-item">
          <label>
            <span className="text-[#fa5656]">*</span> Select Source
          </label>
          {isSourceLoading ? (
            <div className={'loading'}>Loading sources</div>
          ) : (
            <Select
              style={{ width: '100%' }}
              onChange={(e) => {
                setDataProductId(e.target.value);
                const selectedSource = sourceQuery?.dataProducts?.find(
                  (source) => source.dataProductId === e.target.value,
                );
                setEventSource(selectedSource);
              }}
              value={eventSource?.dataProductId || ''}
              required
            >
              <option value={''}>
                {sourceQuery?.dataProducts?.length === 0 ? 'No sources available' : 'Select source'}
              </option>
              {sourceQuery?.dataProducts
                ?.sort((a, b) => a.sourceName.localeCompare(b.sourceName))
                ?.map((k: { sourceName: string; dataProductId: string }) => (
                  <option key={k.dataProductId} value={k.dataProductId}>
                    {k.sourceName}
                  </option>
                ))}
            </Select>
          )}
        </div>
        <div className="MapEventLayersModal__row">
          <div className="MapEventLayersModal__item">
            <label>
              <span className="text-[#fa5656]">*</span> Threshold
            </label>
            <Input
              type="number"
              min={0}
              value={threshold}
              onChange={(e) => setThreshold(e.target.value)}
            />
          </div>
          <div className="MapEventLayersModal__item">
            <label>
              <span className="text-[#fa5656]">*</span> Radius
            </label>
            <Input
              type="number"
              min={0}
              step={1}
              value={width}
              onChange={(e) => setWidth(e.target.value)}
            />
          </div>
        </div>
        <div className="MapEventLayersModal__palette">
          <label>
            <span className="text-[#fa5656]">*</span> Color:
          </label>
          {/* <DrawingColorPicker /> */}
          <ColorPicker value={color} onChange={(_, hex) => setColor(hex)} />
          {/* <PaletteColorPicker browserRGBA value={color} onChange={(e) => setColor(e)} /> */}
        </div>
        {Boolean(dataProductId) && (
          <div className="MapEventLayersModal__frames">
            {timestampsQuery && timestampsQuery.length > 0 ? (
              <EventTimestampsRange
                timestamps={timestampsQuery}
                defaultValue={selectedEvents}
                onValueChange={onRangeChange}
              />
            ) : (
              'No data...'
            )}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default MapEventLayersModal;
