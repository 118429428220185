import { merge } from 'lodash';

import { AudioElement } from './AudioElement';
import { C9Object } from './C9Object';
import { C9ProjectDefProperties } from './C9ProjectDefProperties';
import { SceneDef } from './SceneDef';
import { VideoPanelDef } from './VideoPanelDef';
import { VideoRenderQualityDef } from './VideoRenderQualityDef';

interface ExportDef {
  name?: string;
  type?: string;
  sftpUrl?: string;
  sftpUsername?: string;
  sftpPassword?: string;
}

export class C9ProjectDef extends C9Object {
  skippedTime: Array<SkipTimeDef> = [];
  sceneDefs: SceneDef[] = [];
  c9ProjectDefProperties: C9ProjectDefProperties[];
  /**thumbnail, lastRenderTime added by me */
  thumbnailUrls: string[];
  projectExportFormat = 'HD_1920x1080_60p';
  projectFormat: string;
  lastRenderTime: number;
  voiceOver: AudioElement;
  videoOver: VideoPanelDef;
  exportDefs: ExportDef[];
  outputVideoCompression: string;
  /*** temp def ***/
  videoOverRecorder: VideoPanelDef | null;
  translationId: string | null;
  automaticallyAddToRepoVAOutput = false;
  renderQualityDef: VideoRenderQualityDef;
  isSharedEntity: boolean;
  sharedDate: number;
  sharedBy: string;
  inEditMode: boolean;
  isEditVersion: boolean;
  editor: string;
  editingStartDate: number;
  editEntityId: string;
  enterpriseAccountId: string;
  isSuccessfulTimeReference: boolean;
  sceneDefaultDuration: number;

  constructor(projectDef?: DeepPartial<C9ProjectDef>) {
    super();
    if (projectDef) merge(this, projectDef);
  }
}
