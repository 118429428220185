import { IconTemplateDef } from '../definitions/IconTemplateDef';
import { ValueTypeEnum } from '../enums/ValueTypeEnum';

export class ForecastIconElementRequestDTO {
  name: string;
  description: string;
  datetime: number | null = null;
  utcDate: string;
  localDate: string;
  parameterType: string;
  value: IconTemplateDef;
  valueType: ValueTypeEnum;
  intraTime: boolean;
  daily: boolean;
  constructor(
    name: string,
    parameterType: string,
    utcDate: string,
    valueType: ValueTypeEnum,
    value: IconTemplateDef,
    isDaily: boolean,
  ) {
    if (name && parameterType)
      this.name =
        name +
        ' ' +
        parameterType +
        ' ' +
        (isDaily
          ? 'daily'
          : new Date(utcDate).toLocaleTimeString(undefined, {
              hour: '2-digit',
              minute: '2-digit',
              timeZone: 'UTC',
            }));
    this.valueType = valueType;
    this.parameterType = parameterType;
    this.value = value;
    this.utcDate = utcDate;
    if (isDaily) {
      this.daily = true;
      this.intraTime = false;
    } else {
      this.intraTime = true;
      this.daily = false;
    }
  }
}
