import QueryString from 'qs';
import { useQuery } from 'react-query';

import { ColorPaletteDef } from '../../../model/definitions/ColorPaletteDef';
import { ColorPaletteParamTypeEnum } from '../../../model/enums/ColorPaletteParamTypeEnum';
import { axiosInstance } from '../axiosInstance';

export const key = ['PALETTE'];

export const useGetPalette = (
  paramType: ColorPaletteParamTypeEnum,
  dataProductId: string | null,
  sourceId?: string,
  visualisationType?: string,
  enterpriseAccountId?: string,
) => {
  return useQuery(
    [...key, paramType, sourceId, visualisationType],
    async (): Promise<Array<ColorPaletteDef>> => {
      const res = await axiosInstance.get(
        `color-palette-new/get-default/${paramType}/${sourceId}`,
        {
          params: {
            visualisationType: visualisationType,
            dataProductId,
            enterpriseAccountId,
          },
          paramsSerializer: (params) => {
            return QueryString.stringify(params, { arrayFormat: 'repeat' });
          },
        },
      );
      return res.data;
    },
    {
      enabled: !!paramType || !!sourceId,
      staleTime: Infinity,
    },
  );
};
