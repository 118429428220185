import { HTMLProps, useEffect, useRef } from 'react';

interface CloseOnClickOutsideProps extends HTMLProps<HTMLDivElement> {
  onClose: () => void;
  children: JSX.Element;
}
export const CloseOnClickOutside = ({ children, onClose, ...rest }: CloseOnClickOutsideProps) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        onClose();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, onClose]);

  return (
    <div ref={ref} {...rest}>
      {children}
    </div>
  );
};
