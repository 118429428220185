import '../style.scss';

import { useKeycloak } from '@react-keycloak/web';
import { FC, useEffect, useState } from 'react';
import { IconBaseProps } from 'react-icons';
import { BsChevronCompactDown } from 'react-icons/bs';
import { useMatch, useNavigate } from 'react-router-dom';

import { RolesEnum } from '../../../../model/enums/RolesEnum';

interface SidebarItemProps {
  title: string;
  subtitle?: string;
  icon?: FC<IconBaseProps>;
  sub?: Array<SidebarItemProps>;
  route?: string;
  active?: boolean;
  roles?: RolesEnum[];
  setOpen?: (e: boolean) => void;
}

const SidebarItem = ({ title, subtitle, icon, sub, route, setOpen }: SidebarItemProps) => {
  const navigate = useNavigate();
  const { keycloak } = useKeycloak();

  const match = useMatch(`/workspace/${route === './' ? '' : route}`);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  useEffect(() => {
    !!match && setOpen && setOpen(true);
  }, []);
  const Icon = icon ? icon : 'div';

  const renderItems = sub?.map((item) => {
    if (!item.roles || item.roles.some((role) => keycloak.hasRealmRole(role))) {
      return (
        <SidebarItem
          setOpen={(e) => setIsOpen(e)}
          key={item.title}
          title={item.title}
          icon={item.icon}
          route={item.route}
          sub={item?.sub}
        />
      );
    }
  });

  return (
    <li>
      <div
        className={`sidebar-item ${match ? 'active-item' : ''}`}
        onClick={() => {
          sub ? setIsOpen(!isOpen) : route && navigate(route);
        }}
      >
        <div className={'sidebar-item-content'}>
          {icon && <Icon size={24} />}{' '}
          <span>
            {title} {subtitle && <small>{subtitle}</small>}
          </span>
        </div>
        {sub && <BsChevronCompactDown size={12} />}
      </div>
      <div className={`sidebar-subItems ${isOpen ? 'open' : ''}`}>
        <ul>{renderItems}</ul>
      </div>
    </li>
  );
};
export default SidebarItem;
