import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { queryClient } from '../../..';
import { axiosInstance } from '../../../core/api/axiosInstance';
import { key } from './useGetDataProducts';

export const useDeleteDataProduct = () => {
  return useMutation(
    async (id: string) => {
      const { data } = await axiosInstance.delete(`data-products/${id}`);
      return data;
    },
    {
      onSuccess: () => {
        toast.success('Successfully deleted product!');
        queryClient.invalidateQueries(key);
      },
      onError: (e: any) => {
        toast.error('There was an error. Please try again.');
      },
    },
  );
};
