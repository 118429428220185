import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { FC } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { useDispatch, useSelector } from 'react-redux';

import { usePropertyGridActive } from '../../../../hooks/usePropertyGridActive';
import { TimestampElementDef } from '../../../../model/definitions/TimestampElementDef';
import { ActiveDef, setPropertyGridActiveHash } from '../../../../store/slices/active-slice';
import { updateTimestampLayer } from '../../../../store/slices/project-slice';
import { RootState } from '../../../../store/store';
import { PropertySection } from '../components/PropertySection';
import GridItem from '../shared/GridItem';
import GridWrapper from '../shared/GridWrapper';

export const BasicProperties: FC<Partial<TimestampElementDef>> = ({
  name,
  description,
  dateStart,
  dateEnd,
}) => {
  const dispatch = useDispatch();
  const { activeElement, activeScene } = useSelector<RootState, ActiveDef>((state) => state.active);
  function onFocus(path: Leaves<TimestampElementDef>) {
    dispatch(setPropertyGridActiveHash({ activeElement, focusedEl: path }));
  }
  const { isOpened, lastFocused } = usePropertyGridActive(['name', 'description']);

  const onChange = (value: string | number, propertyPath: Leaves<TimestampElementDef>) =>
    dispatch(updateTimestampLayer({ activeScene, elementId: activeElement, propertyPath, value }));
  return (
    <PropertySection isOpened={isOpened} label={'basic properties'}>
      <div className="prop-wrapper">
        <GridWrapper>
          <GridItem
            label={'Name'}
            item={
              <DebounceInput
                debounceTimeout={400}
                onFocus={() => onFocus('name')}
                autoFocus={lastFocused === 'name'}
                value={name}
                onChange={(e) => onChange(e.target.value, 'name')}
              />
            }
          />
          <GridItem
            label={'Description'}
            item={
              <DebounceInput
                debounceTimeout={400}
                value={description}
                onFocus={() => onFocus('description')}
                autoFocus={lastFocused === 'description'}
                onChange={(e) => onChange(e.target.value, 'description')}
              />
            }
          />
          <GridItem
            noBorderBg
            label={`Start date`}
            item={
              <DatePicker
                format={'DD MMM YYYY HH:mm:ss'}
                showTime
                value={dayjs(dateStart)}
                onChange={(e) => onChange(dayjs(e).unix() * 1000, 'dateStart')}
              />
            }
          />
          <GridItem
            noBorderBg
            label={`End date`}
            item={
              <DatePicker
                format={'DD MMM YYYY HH:mm:ss'}
                showTime
                value={dayjs(dateEnd)}
                onChange={(e) => onChange(dayjs(e).unix() * 1000, 'dateEnd')}
              />
            }
          />
        </GridWrapper>
      </div>
    </PropertySection>
  );
};
