import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { axiosInstance } from '../../../core/api/axiosInstance';
import { queryClient } from '../../../index';
import { key as getSchedulersKey } from './useGetSchedulers';

export const useDeleteScheduler = () => {
  return useMutation(
    async (id: string) => {
      return await axiosInstance.delete(`scheduler/${id}`);
    },
    {
      onSuccess: () => {
        toast.success('Scheduler deleted!');
        queryClient.invalidateQueries(getSchedulersKey);
      },
      onError: () => {
        toast.error('Error deleting scheduler!');
      },
    },
  );
};
