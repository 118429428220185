import { LottieIcon } from '../../atoms/LottieIcon';
import { pointDataValue } from '../../helpers/numberHelpers';
import { AnimationPanelDefTemplate } from '../../model/definitions/AnimationPanelDefTemplate';
import { IconTemplateDef } from '../../model/definitions/IconTemplateDef';
import { TimeControlDef } from '../../model/definitions/TimeControlDef';
import { ValueTypeEnum } from '../../model/enums/ValueTypeEnum';
import { Sequence } from './sequence/Sequence';

export const displayPointValue = (
  valueType: ValueTypeEnum,
  elemValue: Array<{ unit: string; value: number }> | string | undefined,
  parameterType: string,
  jsonAnimation?: boolean,
  url?: string,
  unit?: string,
  showOriginal?: boolean,
  isSequence?: boolean,
  icon?: IconTemplateDef | AnimationPanelDefTemplate,
  showcase?: boolean,
  time?: TimeControlDef[],
): JSX.Element | undefined => {
  if (valueType === ValueTypeEnum.IMAGE) {
    return jsonAnimation && typeof elemValue === 'string' ? (
      <LottieIcon versionId={elemValue} inCanvas={!showcase} time={time} repeat={true} />
    ) : isSequence && icon ? (
      <Sequence id={icon.id} item={icon} time={time} showcase={showcase} repeat={true} />
    ) : (
      <img
        style={{ pointerEvents: 'none', height: '100%', width: '100%' }}
        src={url}
        loading={'eager'}
        alt={parameterType}
      />
    );
  }
  if (valueType === ValueTypeEnum.NUMERICAL) {
    const vals = elemValue as Array<{
      unit: string | undefined;
      value: number;
      originalValue: number;
    }>;
    const val = Array.isArray(vals) ? vals.find((val) => val.unit === unit)?.value ?? NaN : NaN;
    const valOrig = Array.isArray(vals)
      ? vals.find((val) => val.unit === unit)?.originalValue ?? NaN
      : NaN;
    return (
      <span style={{ pointerEvents: 'none' }}>
        {pointDataValue(showOriginal ? valOrig : val, parameterType, unit)}
      </span>
    );
  }
};
