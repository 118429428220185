export const WIND_PREPROCESSING_MASK_FRAG = `
#ifdef GL_ES
precision mediump float;
precision mediump int;
#endif


void main() {

    gl_FragColor = vec4( 1.0,1.0,1.0, 1.0);

}
`;
