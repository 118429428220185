import './styles.scss';

import { Tag as AntTag, TagProps } from 'antd';

interface TagInterface extends TagProps {
  large?: boolean;
}

const Tag = (props: TagInterface) => {
  return (
    <AntTag
      className={`${!props.color && 'border-none bg-[#F4F4F4] text-[#444444] py-1 px-3'} ${
        props.color === 'success' && props.large
          ? 'text-2xl px-5 py-[5px]'
          : 'text-md px-3 py-[3px]'
      }`}
      {...props}
    ></AntTag>
  );
};

export default Tag;
