import './styles.scss';

import Button from '../Button/AntButton';

interface CreateCardProps {
  onClick?: () => void;
}
const CreateCard = ({ onClick }: CreateCardProps) => {
  return (
    <div className="create-card">
      <span className="title">Start creating</span>

      <span className="description">
        Use this template in GeoStream platform and create a professional weather videos in minutes
      </span>
      <Button size="large" onClick={() => onClick && onClick()}>
        Use this template
      </Button>
    </div>
  );
};

export default CreateCard;
