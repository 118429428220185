import { useSelector } from 'react-redux';

import { MAX_LAYERS_PER_MAP } from '../model/constants/constants';
import { MapPanelDef } from '../model/definitions/MapPanelDef';
import { selectActiveMapLayer } from '../store/slices/selectors';
import { RootState } from '../store/store';

export function useAddLayersDisabled() {
  const map = useSelector<RootState, MapPanelDef | null | undefined>((state) =>
    selectActiveMapLayer(state),
  );
  if (!map) return true;
  const wdSpace = map.wdSpace[0];
  if (!wdSpace) return true;
  const layersNum =
    (wdSpace.gribMapLayers?.length || 0) +
    (wdSpace.radarMapLayers?.length || 0) +
    (wdSpace.satelliteMapLayers?.length || 0);
  return layersNum >= MAX_LAYERS_PER_MAP;
}
