import { RcFile } from 'antd/es/upload';
import { useEffect, useState } from 'react';

import { usePalettesById } from '../../../core/api/palette/usePalettesById';
import { ColorPaletteDef } from '../../../model/definitions/ColorPaletteDef';
import { ColorPaletteParamTypeEnum } from '../../../model/enums/ColorPaletteParamTypeEnum';
import { useAddDataProduct } from '../hooks/useAddDataProduct';
import { useEditDataProduct } from '../hooks/useEditDataProduct';
import { DataProductInterface, ParameterMappingInterface } from '../pages/Products';
import { CustomPalette } from './Palette/CustomPalette';
import { Palette } from './Palette/Palette';

function ColorPalette({
  form,
  setForm,
  thumbnailFiles,
}: {
  form: DataProductInterface;
  setForm: React.Dispatch<React.SetStateAction<DataProductInterface>>;
  thumbnailFiles: RcFile[];
}) {
  const { data: dataPalettes } = usePalettesById(form.defaultColorPaletteIds);
  const [palettes, setPalettes] = useState<ColorPaletteDef[]>([]);
  const [paletteToEdit, setPaletteToEdit] = useState<string>();
  const [paletteTypes, setPaletteTypes] = useState<ColorPaletteParamTypeEnum[]>([]);
  const { mutate: addProductMutation } = useAddDataProduct();
  const { mutate: editProductMutation } = useEditDataProduct();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!dataPalettes) {
      if (form.parameterMappings) {
        const data = form.parameterMappings?.map((parameterMapping: ParameterMappingInterface) => {
          if (parameterMapping.colorPaletteParameterGroup) {
            return parameterMapping.colorPaletteParameterGroup.value;
          }
        });
        if (data) {
          const uniqueValues = Array.from(new Set(data));
          if (uniqueValues) setPaletteTypes(uniqueValues as ColorPaletteParamTypeEnum[]);
        }
      }
    } else {
      const data = form.parameterMappings?.map((parameterMapping: ParameterMappingInterface) => {
        if (parameterMapping.colorPaletteParameterGroup) {
          return parameterMapping.colorPaletteParameterGroup.value;
        }
      });
      if (data) {
        const filteredTypes: ColorPaletteParamTypeEnum[] = (data?.filter((type) => {
          return !dataPalettes?.some((dataPalette) => dataPalette.paletteParamType === type);
        }) || []) as ColorPaletteParamTypeEnum[];
        setPaletteTypes(filteredTypes);
      }
    }
  }, [dataPalettes]);

  useEffect(() => {
    if (dataPalettes) {
      setPalettes(dataPalettes);
    }
  }, [dataPalettes]);

  const updateProduct = (defaultColorPaletteIds: string[]) => {
    const formData = new FormData();
    if (form.parameterMappings) {
      form.parameterMappings.map((parameter) => {
        delete parameter.id;
        delete parameter.default;
      });
    }
    formData.append('dataProduct', JSON.stringify({ ...form, defaultColorPaletteIds }));
    thumbnailFiles.forEach((file) => {
      file && formData.append('thumbnailFiles', file);
    });
    if (form.id) {
      editProductMutation(formData, {
        onSuccess: (data) => {
          setForm(data);
        },
      });
    } else {
      addProductMutation(formData, {
        onSuccess: (data) => {
          setForm(data);
        },
      });
    }
  };

  return (
    <>
      <div className="flex flex-col items-center">
        {!isOpen &&
          palettes.map((palette) => {
            return (
              <Palette
                key={palette.id}
                value={palette}
                palettes={palettes}
                setPalettes={setPalettes}
                editPalette={setPaletteToEdit}
                form={form}
                setForm={setForm}
                updateProduct={updateProduct}
              />
            );
          })}
      </div>

      {palettes.length === 0 && paletteTypes?.length === 0 ? (
        'Add parameter types to validation files in order to be able to add color palette'
      ) : (
        <CustomPalette
          onOk={(palette: ColorPaletteDef) => {
            if (paletteToEdit) {
              const updatedDefaultColorPaletteIds = form.defaultColorPaletteIds.includes(palette.id)
                ? form.defaultColorPaletteIds
                : [...form.defaultColorPaletteIds, palette.id];
              const updatedPalettes = palettes.map((p) => (p.id === palette.id ? palette : p));
              updateProduct(updatedDefaultColorPaletteIds);
              setPalettes(updatedPalettes);
            } else {
              updateProduct([...form.defaultColorPaletteIds, palette.id]);
              setPalettes([...palettes, palette]);
            }
          }}
          paletteTypes={
            paletteTypes?.length
              ? paletteTypes
              : !palettes.length
              ? [ColorPaletteParamTypeEnum.RADAR]
              : []
          }
          source={form.baseName}
          paletteToEdit={paletteToEdit}
          setPaletteToEdit={(e) => {
            setPaletteToEdit(e);
          }}
          type={form.productType}
          parameterMappings={form.parameterMappings}
          dataProductId={form.id}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      )}
    </>
  );
}

export default ColorPalette;
