import { C9Object } from './C9Object';

export class SourceDef {
  dataProductId: string;
  sourceName: string;
}

export class EventSourceDef extends C9Object {
  eventSource: SourceDef;
  minimumPower: number;
  datetime: number;
  timeFrameFrom: number;
  timeframeTo: number;
  dataProductId: string;
}
