import { pickBy } from 'lodash';

import { C9ProjectPreviewDTO } from '../../model/DTO/C9ProjectPreviewDTO';
import { C9ProjectTemplatePreviewDTO } from '../../model/DTO/C9ProjectTemplatePreviewDTO';
import { QueryInfo } from '../../model/other/QueryInfo';
import { BuildQueryInfo, QueryInfoMetadata } from '../../model/other/QueryInfoMetadata';
import { axiosInstance } from './axiosInstance';

function predicatePickBy(val: number | string | null | undefined) {
  if (typeof val === 'number') return true;
  return Boolean(val);
}

const buildQueryInfo: BuildQueryInfo<C9ProjectPreviewDTO | C9ProjectTemplatePreviewDTO> = async (
  query: QueryInfo,
  path: string,
) => {
  const params = pickBy(query, predicatePickBy);
  const res = await axiosInstance.get<QueryInfoMetadata<any>>(path, { params });
  return res.data;
};

export { buildQueryInfo };
