import { Button, Label, Modal, TextInput } from 'flowbite-react';

import LoadingIndicator from '../../../atoms/loadingIndicator/LoadingIndicator';

interface AddModalProps {
  name?: string;
  description?: string;
  open: boolean;
  onClose: () => void;
  onCreate: () => void;
  onChange: (value: string, name: string) => void;
  loading?: boolean;
}

export const AddModal = ({
  open,
  name,
  onClose,
  description,
  onChange,
  onCreate,
  loading,
}: AddModalProps) => {
  return (
    <Modal show={open} onClose={onClose}>
      <Modal.Header>Upload</Modal.Header>
      <Modal.Body>
        <div className="mb-2 block mt-2">
          <Label value="Name" />
          <TextInput
            disabled={loading}
            value={name || ''}
            type="text"
            sizing="md"
            onChange={(e) => onChange(e.target.value, 'name')}
          />
        </div>
        <div className="mb-2 block mt-2">
          <Label value="Description" />
          <TextInput
            disabled={loading}
            value={description || ''}
            type="text"
            sizing="md"
            onChange={(e) => onChange(e.target.value, 'description')}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onCreate} disabled={loading || !name || !description}>
          {loading && <LoadingIndicator />}Create
        </Button>
        <Button color={'gray'} onClick={onClose} disabled={loading}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
