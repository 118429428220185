import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';

import { axiosInstance } from '../../../../core/api/axiosInstance';
import { getNormalisedFrames } from '../../../../helpers/math';
import { DataFrameDef } from '../../../../model/definitions/DataFrameDef';
import { filterWithSelection } from '../../../../molecules/mapElement/helpers';
import { updateRadarFrames } from '../../../../store/slices/project-slice';

interface Coordinates {
  leftLongitude: number;
  rightLongitude: number;
  upperLatitude: number;
  lowerLatitude: number;
}

const filterFramesByRange = (
  frames: Array<DataFrameDef>,
  range: [number | null, number | null],
  framesDensity: number | null,
) => {
  console.log(frames);
  if (frames?.length && range) {
    const data = getNormalisedFrames(frames).filter(
      (frame) =>
        frame.timestamp >= Number(range[0]) / 1000 && frame.timestamp <= Number(range[1]) / 1000,
    );
    return filterWithSelection(data || [], framesDensity ?? 0, data[0]);
  }
  return [];
};
export const useGetRadarValue = () => {
  const dispatch = useDispatch();
  return useMutation(
    [],
    async ({
      dataProductId,
      location,
      range,
      density,
      sceneId,
      mapId,
      spaceId,
      layerId,
    }: {
      dataProductId?: string | null;
      location: Coordinates;
      range: [number | null, number | null];
      density: number | null;
      sceneId: string;
      mapId: string;
      spaceId: string;
      layerId: string;
    }): Promise<Array<DataFrameDef>> => {
      const { data } = await axiosInstance.post('radar-data/v2/radar-value', {
        dataProductId,
        location,
      });
      return data;
    },
    {
      onSuccess: (data, variables) => {
        dispatch(
          updateRadarFrames({
            sceneId: variables.sceneId,
            mapId: variables.mapId,
            spaceId: variables.spaceId,
            layerId: variables.layerId,
            frames: filterFramesByRange(data, variables.range, variables.density),
          }),
        );
      },
    },
  );
};
