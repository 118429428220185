import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { CURRENT_TIME_DATE_FORMAT } from '../../model/constants/constants';
import { SceneDef } from '../../model/definitions/SceneDef';
import { TimeControlDef } from '../../model/definitions/TimeControlDef';
import { PlayContext } from '../../pages/playground/playerContext/PlayerContext';
import { ActiveDef } from '../../store/slices/active-slice';
import { RootState } from '../../store/store';

function useTimeUpdater(contextValue: PlayContext, scene?: SceneDef) {
  const { activeElement } = useSelector<RootState>((state) => state.active) as ActiveDef;
  const [localTime, setLocalTime] = useState<string>('');
  const [utcTime, setUtcTime] = useState<string>('');
  const [timeZone, setTimeZone] = useState<string>('');
  const { time } = contextValue;

  useEffect(() => {
    const { mapPanels, pointDates } = scene || {};
    let mapPanel;
    if (activeElement) {
      mapPanel = mapPanels?.find(
        (mapPanel) => mapPanel.id === activeElement && mapPanel.wdSpace[0]?.indicator?.length > 0,
      );
    }
    if (!mapPanel) {
      mapPanel = mapPanels?.find((mapPanel) => {
        const { wdSpace } = mapPanel || {};
        if (wdSpace[0]?.indicator?.length > 0) {
          return mapPanel;
        }
      });
    }
    const { wdSpace } = mapPanel || {};
    const { indicator } = wdSpace?.[0] || {};

    function findValueByTime(
      timeControls: { timeControls: TimeControlDef; value: string; dateValue: string }[],
      time: number,
    ) {
      return timeControls.find(
        (val) => val.timeControls.startMS <= time && val.timeControls.endMS >= time,
      )?.dateValue;
    }

    if (indicator?.length) {
      const timeControlledValue = findValueByTime(indicator, time);
      const date = moment(timeControlledValue, CURRENT_TIME_DATE_FORMAT).toDate();
      if (timeControlledValue) {
        setLocalTime(timeControlledValue);
        setTimeZone('local');
        const currentUtcTime = moment(date).utc().format(CURRENT_TIME_DATE_FORMAT);
        setUtcTime(currentUtcTime);
      }
    } else if (pointDates?.length) {
      const formattedDate = moment(pointDates[0].value).format(CURRENT_TIME_DATE_FORMAT);
      setLocalTime(formattedDate);
      setTimeZone('local');
      const currentUtcTime = moment(pointDates[0].value).utc().format(CURRENT_TIME_DATE_FORMAT);
      setUtcTime(currentUtcTime);
    } else {
      const interval = setInterval(() => {
        const currentLocalTime = moment().format(CURRENT_TIME_DATE_FORMAT);
        const currentUtcTime = moment().utc().format(CURRENT_TIME_DATE_FORMAT);
        setLocalTime(currentLocalTime);
        setUtcTime(currentUtcTime);
      }, 1000);
      setTimeZone('no data');
      return () => {
        clearInterval(interval);
      };
    }
  }, [scene, contextValue, activeElement]);

  return { localTime, utcTime, timeZone };
}

export default useTimeUpdater;
