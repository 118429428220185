import JSZip from 'jszip';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { PlaybackEnum } from '../../../core/ui/enums/PlaybackEnum';
import { IconTemplateDef } from '../../../model/definitions/IconTemplateDef';
import { useGetSequenceZip } from '../../../pages/dashboard/queries-NEW/useGetSequenceZip';
import PlayerContext from '../../../pages/playground/playerContext/PlayerContext';
import { ActiveDef } from '../../../store/slices/active-slice';
import { RootState } from '../../../store/store';

interface PNGSequenceProps {
  showcase?: boolean;
  item: IconTemplateDef;
}

const PNGSequence: React.FC<PNGSequenceProps> = ({ item, showcase }) => {
  const { activeFramerate } = useSelector<RootState>((state) => state.active) as ActiveDef;
  const refreshRate = 1000 / activeFramerate;
  const { versionId } = item;
  const { data } = useGetSequenceZip(versionId);
  const [extracted, setExtracted] = useState(false);
  const [currentFrame, setCurrentFrame] = useState(0);
  const [section, setSection] = useState<'INTRO' | 'MAIN' | 'OUTRO'>('INTRO');
  const [introImages, setIntroImages] = useState<string[]>([]);
  const [mainImages, setMainImages] = useState<string[]>([]);
  const [outroImages, setOutroImages] = useState<string[]>([]);
  const contextValue = useContext(PlayerContext);
  const { isPlaying } = contextValue;
  useEffect(() => {
    const handleZipData = async () => {
      try {
        const zip = new JSZip();
        await zip.loadAsync(data);
        const fileArray: [string, JSZip.JSZipObject][] = Object.entries(zip.files);
        fileArray.sort((a, b) => {
          const filenameA = a[0].split('/').pop() || '';
          const filenameB = b[0].split('/').pop() || '';
          return filenameA.localeCompare(filenameB, undefined, { numeric: true });
        });
        const mainImageSrcs: string[] = [];
        const introImageSrcs: string[] = [];
        const outroImageSrcs: string[] = [];
        for (const [filename, entry] of fileArray) {
          const ext = filename.split('.').pop()?.toLowerCase();
          if (
            !entry.dir &&
            entry.name.includes('3_outro') &&
            ['png', 'jpg', 'jpeg', 'gif', 'bmp'].includes(ext || '') &&
            filename.indexOf('__MACOSX') === -1
          ) {
            const rawBlob = await entry.async('blob');
            const blob = new Blob([rawBlob], { type: 'image/png' });
            const dataUrl = URL.createObjectURL(blob);
            outroImageSrcs.push(dataUrl);
          }
          if (
            !entry.dir &&
            entry.name.includes('1_intro') &&
            ['png', 'jpg', 'jpeg', 'gif', 'bmp'].includes(ext || '') &&
            filename.indexOf('__MACOSX') === -1
          ) {
            const rawBlob = await entry.async('blob');
            const blob = new Blob([rawBlob], { type: 'image/png' });
            const dataUrl = URL.createObjectURL(blob);
            introImageSrcs.push(dataUrl);
          }
          if (
            !entry.dir &&
            entry.name.includes('2_main') &&
            ['png', 'jpg', 'jpeg', 'gif', 'bmp'].includes(ext || '') &&
            filename.indexOf('__MACOSX') === -1
          ) {
            const rawBlob = await entry.async('blob');
            const blob = new Blob([rawBlob], { type: 'image/png' });
            const dataUrl = URL.createObjectURL(blob);
            mainImageSrcs.push(dataUrl);
          }
        }

        setMainImages(mainImageSrcs);
        setIntroImages(introImageSrcs);
        setOutroImages(outroImageSrcs);
      } catch (error) {
        console.error('Error processing the zip file:', error);
      }
    };

    if (data && (mainImages.length < 1 || introImages.length < 1)) {
      handleZipData().then(() => setExtracted(true));
    }
  }, [data, mainImages, introImages]);
  useEffect(() => {
    const handleInterval = () => {
      if (section === 'INTRO' && currentFrame >= introImages.length - 1) {
        setSection('MAIN');
        setCurrentFrame(0);
      }
      if (section === 'INTRO') {
        setCurrentFrame((prevCount) => prevCount + 1);
      }
      if (
        section === 'MAIN' &&
        currentFrame >= refreshRate * activeFramerate * 5 &&
        outroImages.length > 0
      ) {
        setSection('OUTRO');
        setCurrentFrame(0);
      }
      if (section === 'MAIN' && outroImages.length < 1) {
        setCurrentFrame((prevCount) => (prevCount < mainImages.length - 1 ? prevCount + 1 : 0));
      }
      if (section === 'MAIN' && outroImages.length > 0) {
        setCurrentFrame((prevCount) => prevCount + 1);
      }
      if (section === 'MAIN' && outroImages.length > 0 && currentFrame > mainImages.length - 1) {
        setSection('OUTRO');
        setCurrentFrame(0);
      }
      if (section === 'OUTRO') {
        setCurrentFrame((prevCount) => prevCount + 1);
      }
      if (section === 'OUTRO' && currentFrame >= outroImages.length - 1) {
        setSection('INTRO');
        setCurrentFrame(0);
      }
    };
    if (showcase && extracted) {
      const intervalId = setInterval(handleInterval, refreshRate);
      return () => clearInterval(intervalId);
    } else {
      let intervalId: NodeJS.Timer;

      if (isPlaying === PlaybackEnum.PLAYING) {
        intervalId = setInterval(handleInterval, refreshRate);
      }
      return () => clearInterval(intervalId);
    }
  }, [
    section,
    mainImages,
    introImages.length,
    refreshRate,
    showcase,
    isPlaying,
    currentFrame,
    extracted,
    activeFramerate,
    outroImages.length,
  ]);
  const getSection = (section: 'INTRO' | 'MAIN' | 'OUTRO') => {
    if (section === 'INTRO') {
      return introImages;
    }
    if (section === 'MAIN') {
      return mainImages;
    }
    if (section === 'OUTRO') {
      return outroImages;
    }
    return [];
  };
  return (
    <>
      <img src={getSection(section)[currentFrame]} alt="Animation" />
    </>
  );
};

export default PNGSequence;
