import { ElementsEnum } from '../core/ui/enums/ElementsEnum';
import { ElementType } from '../types/elements';

export const enumToPath = (element: ElementsEnum): ElementType => {
  switch (element) {
    case ElementsEnum.VIDEO:
      return 'videoPanels';
    case ElementsEnum.IMAGE:
      return 'imagePanels';
    case ElementsEnum.ANIMATION:
      return 'animationPanels';
    case ElementsEnum.TEXT:
      return 'textPanels';
    case ElementsEnum.TIMESTAMP:
      return 'timestampPanels';
    case ElementsEnum.MAP:
      return 'mapPanels';
    case ElementsEnum.WEATHER_GRAPH:
      return 'weatherPosters';
    case ElementsEnum.POSTER:
      return 'weatherPosters';
    case ElementsEnum.OBSERVED_WD:
      return 'observedWDElements';
    case ElementsEnum.FORECAST_WD:
      return 'forecastWDElements';
    case ElementsEnum.POINT_LOCATION:
      return 'pointLocation';
    case ElementsEnum.POINT_DATE:
      return 'pointDates';
    default:
      return 'textPanels';
  }
};
export const pathToEnum = (path: ElementType): ElementsEnum => {
  switch (path) {
    case 'videoPanels':
      return ElementsEnum.VIDEO;
    case 'imagePanels':
      return ElementsEnum.IMAGE;
    case 'textPanels':
      return ElementsEnum.TEXT;
    case 'mapPanels':
      return ElementsEnum.MAP;
    case 'weatherPosters':
      return ElementsEnum.WEATHER_GRAPH; // You might need to handle POSTER separately if needed
    case 'observedWDElements':
      return ElementsEnum.OBSERVED_WD;
    case 'forecastWDElements':
      return ElementsEnum.FORECAST_WD;
    default:
      return ElementsEnum.TEXT; // Default value if the path doesn't match any known values
  }
};
