import { Radio, Space } from 'antd';
import React, { useState } from 'react';

import Button from '../../../atoms/button/Button';
import useShareProject from '../../../core/api/useShareProject';
import Modal from '../../../molecules/modal/Modal';

interface ShareProjectModalProps {
  opened: boolean;
  onClose: () => void;
  isEditVersion: boolean;
  projectId: string;
}

const ShareProjectModal: React.FC<ShareProjectModalProps> = ({
  opened,
  onClose,
  isEditVersion,
  projectId: id,
}) => {
  const [selection, setSelection] = useState('new');
  const shareProject = useShareProject();
  const replaceExisting = !!isEditVersion && selection === 'overwrite';
  const type = 'PROJECT';

  const handleShare = () => {
    shareProject.mutate({ id, type, replaceExisting });
    onClose();
  };

  return (
    <Modal
      isOpen={opened}
      onClose={onClose}
      bodyClassName={'SendAssemblyModal__modal-body'}
      className={'SendAssemblyModal__modal-wrap'}
      header={'Share Project'}
      footer={
        <div className={'modal-footer flex'}>
          <Button key="back" label={'Cancel'} buttonType="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button key="submit" label={'Submit'} buttonType="primary" onClick={handleShare}>
            Share
          </Button>
        </div>
      }
    >
      <div className="text-left">
        {isEditVersion ? (
          <Radio.Group onChange={(e) => setSelection(e.target.value)} value={selection}>
            <Space direction="vertical">
              <Radio value="new">Create a New Shared Project</Radio>
              <Radio value="overwrite">Replace Existing Shared Project</Radio>
            </Space>
          </Radio.Group>
        ) : (
          <p>Are you sure you want to share project across organization?</p>
        )}
      </div>
    </Modal>
  );
};

export default ShareProjectModal;
