import './style.scss';

import { Label, Tabs, TextInput } from 'flowbite-react';
import { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Button from '../../../../atoms/button/Button';
import { C9ProjectDef } from '../../../../model/definitions/C9ProjectDef';
import { DataFrameDef } from '../../../../model/definitions/DataFrameDef';
import { MapPanelDef } from '../../../../model/definitions/MapPanelDef';
import { useGetProjectFromTemplate } from '../../queries-NEW/useGetProjectFromTemplate';
import { useOpenTemplateInStudio } from '../../queries-NEW/useOpenTemplateInStudio';
import { MapTemplating } from './MapTemplating';

export const TemplatingPage = () => {
  const { mutate, data, isSuccess } = useGetProjectFromTemplate();
  const { mutate: open } = useOpenTemplateInStudio();
  const [name, setName] = useState<string>('');
  const [project, setProject] = useState<C9ProjectDef>();
  const { id } = useParams();
  useEffect(() => {
    setProject(data);
  }, [data, isSuccess]);
  const renderScene = () =>
    project?.sceneDefs.map((item) => (
      <Tabs.Item key={item.id} title={item.name}>
        {renderMaps(item.id, item.mapPanels)}
      </Tabs.Item>
    ));
  const updateMapLayer = (
    sceneId: string,
    mapId: string,
    layerType: 'grib' | 'radar' | 'satellite',
    layerId: string,
    frames: DataFrameDef[],
  ) => {
    const proj = cloneDeep(project);
    const scene = proj?.sceneDefs.find((scene) => scene.id === sceneId);
    if (scene) {
      const map = scene.mapPanels.find((map) => map.id === mapId);
      if (map && layerType === 'grib') {
        const layer = map.wdSpace[0].gribMapLayers.find((layer) => layer.id === layerId);
        if (layer) layer.dataFrames = frames;
      }
    }
    if (scene) {
      const map = scene.mapPanels.find((map) => map.id === mapId);
      if (map && layerType === 'radar') {
        const layer = map.wdSpace[0].radarMapLayers.find((layer) => layer.id === layerId);
        if (layer) layer.dataFrames = frames;
      }
    }
    if (scene) {
      const map = scene.mapPanels.find((map) => map.id === mapId);
      if (map && layerType === 'satellite') {
        const layer = map.wdSpace[0].satelliteMapLayers.find((layer) => layer.id === layerId);
        if (layer) layer.dataFrames = frames;
      }
    }
    setProject(proj);
  };
  const renderMaps = (sceneId: string, maps: MapPanelDef[]) => {
    return maps.map((item) => (
      <div key={item.id}>
        <MapTemplating
          map={item}
          scene={sceneId}
          onLayerFramesChange={(mapId, scene, layerType, layerId, frames) =>
            updateMapLayer(scene, mapId, layerType, layerId, frames)
          }
        />
      </div>
    ));
  };

  return (
    <>
      <div className={'ws-temp-title flex justify-between items-center'}>
        <h1>
          {project?.name}{' '}
          <small>
            <em>(Project setup)</em>
          </small>
        </h1>{' '}
        {project && (
          <Button
            style={{ whiteSpace: 'nowrap' }}
            label="Open in studio"
            buttonType="secondary"
            disabled={!project}
            onClick={() => project && open(project)}
          />
        )}
      </div>
      <div className={'flex items-end min-w-[400px]'}>
        {project ? null : (
          <>
            <Label>
              Enter project name:
              <TextInput
                className={'flex-1 min-w-[400px]'}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Label>
            <div className={'ml-6'}>
              <Button
                style={{ margin: 0 }}
                label="Create"
                disabled={name.length === 0}
                onClick={() => id && mutate({ id, name })}
              />
            </div>
          </>
        )}
      </div>
      {project && (
        <div className={'template-tabs project-setup-content'}>
          <Tabs.Group aria-label="Tabs with icons" style="underline">
            {renderScene()}
          </Tabs.Group>
        </div>
      )}
    </>
  );
};
