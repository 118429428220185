const capitalize = (word: string) => {
  const words = word?.split(' ');
  const newString = words?.map((item) => {
    const rest = item.slice(1, word.length - 1).toLowerCase();
    return item[0].toUpperCase() + rest;
  });
  return newString?.join(' ');
};

export { capitalize };
