import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { queryClient } from '../..';
import { axiosInstance } from './axiosInstance';

export const useDeleteAssemblyStatusBulk = () => {
  return useMutation(
    async (idArray: string[]) => {
      return await axiosInstance.delete(`/video-assembly-task`, { data: idArray });
    },
    {
      onSuccess: (response) => {
        toast.success(response.data);
        queryClient.invalidateQueries('render-status-by-project');
      },
      onError: () => {
        toast.error('Error deleting item!');
      },
    },
  );
};
