import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { queryClient } from '../../..';
import { axiosInstance } from '../../../core/api/axiosInstance';
import { key } from './useGetDataProductFiles';

export const useDeleteDataProductFile = () => {
  return useMutation(
    async (id: number) => {
      const { data } = await axiosInstance.delete(`data-product-files/${id}`);
      return data;
    },
    {
      onSuccess: () => {
        toast.success('Successfully deleted data product file!');
        queryClient.invalidateQueries(key);
      },
      onError: () => {
        toast.error('There was an error. Please try again.');
      },
    },
  );
};
