import './styles.scss';

import * as Sentry from '@sentry/react';
import { Tooltip } from 'flowbite-react';
import { cloneDeep } from 'lodash';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import { BsArrowDownUp } from 'react-icons/bs';
import { GiTargetPoster } from 'react-icons/gi';
import { MdClose } from 'react-icons/md';
import { RiAddCircleLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { v4 } from 'uuid';

import Button from '../../../atoms/button/Button';
import { useApplyLayer } from '../../../core/api/mapLayers/useApplyLayer';
import { useCreatePosterScene } from '../../../core/api/poster/useCreatePosterScene';
import { useGetGeoPosterById } from '../../../core/api/poster/useGetGeoPosterById';
import { useGetPosterById } from '../../../core/api/poster/useGetPosterById';
import { getLayerTemplateDetails } from '../../../core/api/TemplatesAPI';
import { ElementsEnum } from '../../../core/ui/enums/ElementsEnum';
import { PlaybackEnum } from '../../../core/ui/enums/PlaybackEnum';
import { fromGeoBoxToBoundsWDLayers } from '../../../helpers/boundsManage';
import {
  findHeight,
  findWidth,
  findXPosition,
  findYPosition,
} from '../../../helpers/posterHelpers';
import useRoleAccess from '../../../hooks/useRoleAccess';
import { MAX_FULLSCREEN_HEIGHT } from '../../../model/constants/constants';
import { AnimationPanelDef } from '../../../model/definitions/AnimationPanelDef';
import { C9ProjectDef } from '../../../model/definitions/C9ProjectDef';
import { ForecastWDElementDef } from '../../../model/definitions/ForecastWDElementDef';
import { ImagePanelDef } from '../../../model/definitions/ImagePanelDef';
import { MapPanelDef } from '../../../model/definitions/MapPanelDef';
import { ObservedWDElementDef } from '../../../model/definitions/ObservedWDElementDef';
import { PointDateDef } from '../../../model/definitions/PointDateDef';
import { PointLocationDef } from '../../../model/definitions/PointLocationDef';
import { PositionControlDef } from '../../../model/definitions/PositionControlDef';
import { SymbolLayerDef } from '../../../model/definitions/SymbolLayerDef';
import { TextPanelDef } from '../../../model/definitions/TextPanelDef';
import { VideoPanelDef } from '../../../model/definitions/VideoPanelDef';
import { WeatherPosterDef } from '../../../model/definitions/WeatherPosterDef';
import { RolesEnum } from '../../../model/enums/RolesEnum';
import { SidebarItemsEnum } from '../../../model/enums/SidebarItemsEnum';
import { transformPercentToAbsolute } from '../../../molecules/canvasElements/utils';
import {
  ActiveDef,
  setApplyLayer,
  setElement,
  setPosterMode,
  toggleBiasMode,
} from '../../../store/slices/active-slice';
import { updatePanel, updatePosition } from '../../../store/slices/project-slice';
import { selectActiveElementAny } from '../../../store/slices/selectors';
import { RootState } from '../../../store/store';
import useGetRepoMultimedia from '../../dashboard/queries-NEW/useGetRepoMultimedia';
import useGetRepoTemplates from '../../dashboard/queries-NEW/useGetRepoTemplates';
import { CreatePosterModal } from '../modals/CreatePosterModal';
import RecordAVModal from '../modals/RecordModal';
import PlayerContext from '../playerContext/PlayerContext';
import { MapTemplateModal } from '../repository-item/templateModals/MapTemplate/MapTemplateModal';
import { PosterTemplateModal } from '../repository-item/templateModals/PosterTemplate/PosterTemplateModal';
import {
  useGetAnimationFromRepo,
  useGetAudioFromRepo,
  useGetImageFromRepo,
  useGetMapFromRepo,
  useGetSceneFromRepo,
  useGetVideoFromRepo,
} from './ApiHooks';
import SidebarContent from './sidebar-content/SidebarContent';
import SidebarItems from './sidebar-items/SidebarItems';

const Sidebar = () => {
  const project = useSelector<RootState, C9ProjectDef>((state) => state.project?.present?.project);
  const {
    biasMode,
    activeScene,
    activeProp,
    activeAspectRatio: ratio,
    posterMode,
    posterContent,
    activePoster,
  } = useSelector<RootState, ActiveDef>((state) => state.active);
  const activeElementAny = useSelector<RootState, any>((state) => selectActiveElementAny(state));
  const roleAccess = useRoleAccess(
    [RolesEnum.ROLE_CREATOR, RolesEnum.ROLE_MAINTAINER, RolesEnum.ROLE_FORECASTER],
    project.isSharedEntity,
    project.inEditMode,
  );

  const dispatch = useDispatch();
  const contextValue = useContext(PlayerContext);
  const { time } = contextValue;
  const isPlaying = contextValue.isPlaying === PlaybackEnum.PLAYING;
  const sceneDuration = project.sceneDefs?.find((scene) => scene.id === activeScene)?.durationInMS;
  const [sidebarItem, setSidebarItem] = useState<SidebarItemsEnum>(SidebarItemsEnum.DEFAULT);
  const [filterTab, setFilterTab] = useState<string>('');
  const [isRecord, setRecord] = useState(false);
  const [mapInScene, setMapInScene] = useState(0);
  const [flow, setFlow] = useState<'scene' | 'single'>('single');
  const [isMapTemplateModal, setIsMapTemplateModal] = useState<boolean>(false);
  const [createPosterModal, setCreatePosterModal] = useState<boolean>(false);
  const [isPosterTemplateModal, setIsPosterTemplateModal] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const { mutate: getMap, data: mapDefinition } = useGetMapFromRepo();
  const { mutate: getVideo } = useGetVideoFromRepo();
  const { mutate: getAudio } = useGetAudioFromRepo();
  const { mutate: getImage } = useGetImageFromRepo();
  const { mutate: getAnimation } = useGetAnimationFromRepo();
  const { mutate: getScene, data: sceneDefinition } = useGetSceneFromRepo();
  const { mutate: getPoster, data: posterDefinition } = useGetPosterById();
  const { mutate: getGeoPoster, data: geoPosterDefinition } = useGetGeoPosterById();
  const { mutate: createPosterScene } = useCreatePosterScene();
  const { mutate: applyLayer } = useApplyLayer();
  const {
    data: templates,
    isError,
    error,
    refetch,
  } = useGetRepoTemplates({
    page: 0,
    type: filterTab.toLowerCase() as
      | 'project'
      | 'scene'
      | 'weather-poster'
      | 'weather-geo-poster'
      | 'font'
      | 'map'
      | 'layer',
    searchKey: search,
    size: 1000,
  });
  const templateUsed = (mapDef: MapPanelDef) => {
    const panels = project.sceneDefs.find((scene) => scene.id === activeScene)?.mapPanels;
    const map = panels?.find((map) => map.id === mapDef.id);
    return !map;
  };
  const {
    data: multimedia,
    error: multimediaError,
    isError: isMultimediaError,
    refetch: refetchMultimedia,
  } = useGetRepoMultimedia({
    page: 0,
    type: sidebarItem.toLowerCase() as 'video' | 'audio' | 'image',
    searchKey: search,
    size: 1000,
  });
  const addVideoTemplate = (id: string) => {
    sceneDuration &&
      getVideo(
        {
          id,
          ratio,
          activeScene,
          project,
          time,
          previewSize: MAX_FULLSCREEN_HEIGHT,
        },
        {
          onSuccess: () => {
            setSidebarItem(SidebarItemsEnum.DEFAULT);
          },
        },
      );
  };
  const addImageTemplate = (id: string) => {
    sceneDuration &&
      getImage(
        {
          id,
          ratio,
          activeScene,
          project,
          duration: sceneDuration,
          time,
          previewSize: MAX_FULLSCREEN_HEIGHT,
        },
        {
          onSuccess: () => {
            setSidebarItem(SidebarItemsEnum.DEFAULT);
          },
        },
      );
  };
  const addAnimationTemplate = (id: string) => {
    sceneDuration &&
      getAnimation(
        {
          id,
          ratio,
          activeScene,
          project,
          duration: sceneDuration,
          time,
          previewSize: MAX_FULLSCREEN_HEIGHT,
        },
        {
          onSuccess: () => {
            setSidebarItem(SidebarItemsEnum.DEFAULT);
          },
        },
      );
  };
  const addAudioTemplate = (id: string) => {
    sceneDuration &&
      getAudio(
        {
          id,
          activeScene,
          time,
        },
        {
          onSuccess: () => {
            setSidebarItem(SidebarItemsEnum.DEFAULT);
          },
        },
      );
  };
  const addPosterTemplate = (id: string) => {
    sceneDuration &&
      getPoster(id, {
        onSuccess: () => {
          setSidebarItem(SidebarItemsEnum.DEFAULT);
          setIsPosterTemplateModal(true);
        },
      });
  };
  const addGeoPosterTemplate = (id: string) => {
    sceneDuration &&
      getGeoPoster(id, {
        onSuccess: () => {
          setSidebarItem(SidebarItemsEnum.DEFAULT);
          setIsPosterTemplateModal(true);
        },
      });
  };
  const addLayerTemplate = async (id: string) => {
    const layer: any = templates?.content.find((template) => template.id === id);
    if (!layer) return;
    const mapPanels = project.sceneDefs.find((scene) => scene.id === activeScene)?.mapPanels;
    if (!activeElementAny && mapPanels?.length === 0) {
      toast.error('Select map to apply layer');
      return;
    }
    if (!activeElementAny && mapPanels)
      dispatch(
        setElement({
          activeElement: mapPanels[0].id,
          activeProp: 'mapPanels',
        }),
      );
    const bounds = fromGeoBoxToBoundsWDLayers(
      activeElementAny
        ? activeElementAny.baseMapSetup?.boundingBox
        : //@ts-ignore
          mapPanels[0]?.baseMapSetup?.boundingBox,
    );
    if (!sceneDuration) return;
    if (layer.layerType == 'SYMBOL') {
      try {
        const response = await getLayerTemplateDetails(layer.id);
        // TODO: handle case when the location is out of bounds
        const symbolLayer = response.layerPanelDef as SymbolLayerDef;
        symbolLayer.symbolSource.gribSource.location = {
          leftLongitude: bounds[0],
          rightLongitude: bounds[1],
          upperLatitude: bounds[2],
          lowerLatitude: bounds[3],
        };
        symbolLayer.symbolSource.id = v4();
        symbolLayer.symbolSource.templateVersionId = v4();
        setSidebarItem(SidebarItemsEnum.DEFAULT);
        dispatch(
          setApplyLayer({
            layer: symbolLayer,
          }),
        );
      } catch (e) {
        Sentry.captureException(e);
        toast.error('Unable to add symbol layer template.');
      }
    } else {
      sceneDuration &&
        applyLayer(
          { id: layer.id, bounds },
          {
            onSuccess: (data) => {
              setSidebarItem(SidebarItemsEnum.DEFAULT);
              dispatch(
                setApplyLayer({
                  layer: data.layerPanelDef,
                }),
              );
            },
            onError: (error: any) => {
              toast.error(error.response.data.message);
            },
          },
        );
    }
  };
  const addMapTemplate = (id: string) => {
    sceneDuration &&
      getMap(
        {
          id,
          ratio,
          activeScene,
          project,
          sceneDuration,
          time,
          previewSize: MAX_FULLSCREEN_HEIGHT,
        },
        {
          onSuccess: (data) => {
            if (templateUsed(data)) {
              setSidebarItem(SidebarItemsEnum.DEFAULT);
              setIsMapTemplateModal(true);
            } else {
              toast.error('Map template already used in scene!');
            }
          },
        },
      );
  };
  const addSceneTemplate = (id: string) => {
    setFlow('scene');
    sceneDuration &&
      getScene(
        {
          id,
        },
        {
          onSuccess: (data) => {
            setSidebarItem(SidebarItemsEnum.DEFAULT);
            data.mapPanels.length
              ? setIsMapTemplateModal(true)
              : data.weatherPosters.length
              ? setIsPosterTemplateModal(true)
              : null;
          },
        },
      );
  };

  useEffect(() => {
    (!sidebarItem || isRecord) && setSidebarItem(SidebarItemsEnum.DEFAULT);
  }, [sidebarItem, isRecord]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);
  const handleSidebar = (itemToSet: SidebarItemsEnum) => {
    if (sidebarItem == itemToSet) {
      setSidebarItem(SidebarItemsEnum.DEFAULT);
      return;
    }
    setSearch('');
    itemToSet !== 'RECORD' ? setSidebarItem(itemToSet) : setRecord(true);
    itemToSet !== 'RECORD' && itemToSet === 'TEMPLATE' && setFilterTab('MAP');
  };

  useEffect(() => {
    sidebarItem === SidebarItemsEnum.TEMPLATE && refetch();
    [SidebarItemsEnum.AUDIO, SidebarItemsEnum.IMAGE, SidebarItemsEnum.VIDEO].includes(
      sidebarItem,
    ) && refetchMultimedia();
  }, [sidebarItem, filterTab, refetch, search, refetchMultimedia]);
  const handleAddItem = (id: string, type: ElementsEnum) => {
    switch (type) {
      case ElementsEnum.VIDEO:
        return addVideoTemplate(id);
      case ElementsEnum.MAP:
        return addMapTemplate(id);
      case ElementsEnum.AUDIO:
        return addAudioTemplate(id);
      case ElementsEnum.IMAGE:
        return addImageTemplate(id);
      case ElementsEnum.POSTER:
        return addPosterTemplate(id);
      case ElementsEnum.SCENE:
        return addSceneTemplate(id);
      case ElementsEnum.ANIMATION:
        return addAnimationTemplate(id);
      case ElementsEnum.GEOPOSTER:
        return addGeoPosterTemplate(id);
      case ElementsEnum.LAYER:
        return addLayerTemplate(id);
    }
  };
  const handleModal = () => {
    //setMapInScene(0);
    setIsMapTemplateModal(false);
    //setFlow('single');
  };
  const handlePosterModal = () => {
    setMapInScene(0);
    setIsPosterTemplateModal(false);
    if (flow === 'scene') {
      const ids = sceneDefinition?.weatherPosters.map((poster) => poster.id);
      ids?.forEach((id) =>
        dispatch(updatePanel({ activeScene, activeElement: id, activeProp: 'weatherPosters' })),
      );
    }
    setFlow('single');
  };
  const addToPoster = () => {
    const usedPoster = project.sceneDefs
      .find((scene) => scene.id === activeScene)
      ?.weatherPosters.find((graph) => graph.id === activePoster);
    if (usedPoster) {
      const position = new PositionControlDef(
        findWidth([...posterContent, usedPoster]),
        findHeight([...posterContent, usedPoster]),
        findXPosition([...posterContent, usedPoster]),
        findYPosition([...posterContent, usedPoster]),
      );
      dispatch(
        updatePosition({
          position,
          activeScene,
          elementType: 'weatherPosters',
          elementId: activePoster,
        }),
      );
      usedPoster.imagePanels.map((image) => {
        console.log(image.positionControl.x, usedPoster.positionControl.x, position.x);
        dispatch(
          updatePosition({
            position: {
              ...image.positionControl,
              h: image.positionControl.h * (usedPoster.positionControl.h / position.h),
              w: image.positionControl.w * (usedPoster.positionControl.w / position.w),
              x:
                image.positionControl.x +
                image.positionControl.x *
                  (position.x === 0 ? 0 : usedPoster.positionControl.x / position.x),
              y:
                image.positionControl.y +
                image.positionControl.y *
                  (position.y === 0 ? 0 : usedPoster.positionControl.y / position.y),
            },
            activeScene,
            elementType: 'imagePanels',
            elementId: image.id,
            isPoster: true,
            posterId: activePoster,
          }),
        );
      });
      usedPoster.videoPanels.map((image) => {
        dispatch(
          updatePosition({
            position: {
              ...image.positionControl,
              h: image.positionControl.h * (usedPoster.positionControl.h / position.h),
              w: image.positionControl.w * (usedPoster.positionControl.w / position.w),
              x: image.positionControl.x * (usedPoster.positionControl.w / position.w),
              y: image.positionControl.y * (usedPoster.positionControl.h / position.h),
            },
            activeScene,
            elementType: 'videoPanels',
            elementId: image.id,
            isPoster: true,
            posterId: activePoster,
          }),
        );
      });
      usedPoster.textPanels.map((image) => {
        dispatch(
          updatePosition({
            position: {
              ...image.positionControl,
              h: image.positionControl.h * (usedPoster.positionControl.h / position.h),
              w: image.positionControl.w * (usedPoster.positionControl.w / position.w),
              x: image.positionControl.x * (usedPoster.positionControl.w / position.w),
              y: image.positionControl.y * (usedPoster.positionControl.h / position.h),
            },
            activeScene,
            elementType: 'textPanels',
            elementId: image.id,
            isPoster: true,
            posterId: activePoster,
          }),
        );
      });
      usedPoster.pointLocation.map((image) => {
        dispatch(
          updatePosition({
            position: {
              ...image.positionControl,
              h: image.positionControl.h * (usedPoster.positionControl.h / position.h),
              w: image.positionControl.w * (usedPoster.positionControl.w / position.w),
              x: image.positionControl.x * (usedPoster.positionControl.w / position.w),
              y: image.positionControl.y * (usedPoster.positionControl.h / position.h),
            },
            activeScene,
            elementType: 'textPanels',
            elementId: image.id,
            isPoster: true,
            posterId: activePoster,
          }),
        );
      });
      usedPoster.observedWDElements.map((image) => {
        dispatch(
          updatePosition({
            position: {
              ...image.positionControl,
              h: image.positionControl.h * (usedPoster.positionControl.h / position.h),
              w: image.positionControl.w * (usedPoster.positionControl.w / position.w),
              x: image.positionControl.x * (usedPoster.positionControl.w / position.w),
              y: image.positionControl.y * (usedPoster.positionControl.h / position.h),
            },
            activeScene,
            elementType: 'observedWDElements',
            elementId: image.id,
            isPoster: true,
            posterId: activePoster,
          }),
        );
      });
      usedPoster.forecastWDElements.map((image) => {
        dispatch(
          updatePosition({
            position: {
              ...image.positionControl,
              h: image.positionControl.h * (usedPoster.positionControl.h / position.h),
              w: image.positionControl.w * (usedPoster.positionControl.w / position.w),
              x: image.positionControl.x * (usedPoster.positionControl.w / position.w),
              y: image.positionControl.y * (usedPoster.positionControl.h / position.h),
            },
            activeScene,
            elementType: 'forecastWDElements',
            elementId: image.id,
            isPoster: true,
            posterId: activePoster,
          }),
        );
      });
    }
  };
  const createPoster = (name: string, description: string, isGeo?: boolean) => {
    const position = new PositionControlDef(
      findWidth(posterContent),
      findHeight(posterContent),
      findXPosition(posterContent),
      findYPosition(posterContent),
    );
    const heightRatio =
      MAX_FULLSCREEN_HEIGHT /
      transformPercentToAbsolute(findHeight(posterContent), ratio, 'height', MAX_FULLSCREEN_HEIGHT);
    const widthRatio =
      (MAX_FULLSCREEN_HEIGHT * ratio[0]) /
      ratio[1] /
      transformPercentToAbsolute(findWidth(posterContent), ratio, 'width', MAX_FULLSCREEN_HEIGHT);
    const newData = posterContent.map((element) => {
      return {
        ...element,
        positionControl: {
          ...element.positionControl,
          h: element.positionControl.h * heightRatio,
          w: element.positionControl.w * widthRatio,
          x: (element.positionControl.x - position.x) * widthRatio,
          y: (element.positionControl.y - position.y) * heightRatio,
        },
      };
    });

    const textDefs = cloneDeep(newData.filter((element) => element.type === 'textPanels'));
    const videoDefs = cloneDeep(newData.filter((element) => element.type === 'videoPanels'));
    const imageDefs = cloneDeep(newData.filter((element) => element.type === 'imagePanels'));
    const animationDefs = cloneDeep(
      newData.filter((element) => element.type === 'animationPanels'),
    );
    const observed = cloneDeep(newData.filter((element) => element.type === 'observedWDElements'));
    const forecast = cloneDeep(newData.filter((element) => element.type === 'forecastWDElements'));
    const pointDate = cloneDeep(newData.filter((element) => element.type === 'pointDates'));
    const pointLocation = cloneDeep(newData.filter((element) => element.type === 'pointLocation'));
    const poster = new WeatherPosterDef(time, sceneDuration ?? 0, position, name, isGeo);
    poster.imagePanels = imageDefs as ImagePanelDef[];
    poster.animationPanels = animationDefs as AnimationPanelDef[];
    poster.textPanels = textDefs as TextPanelDef[];
    poster.textPanels = poster.textPanels.map((text) => ({
      ...text,
      fontSize: (text.fontSize * 100) / poster.positionControl.h,
    }));
    poster.videoPanels = videoDefs as VideoPanelDef[];
    poster.observedWDElements = observed as ObservedWDElementDef[];
    poster.observedWDElements = poster.observedWDElements.map((text) => ({
      ...text,
      fontSize: (text.fontSize * 100) / poster.positionControl.h,
    }));
    poster.forecastWDElements = forecast as ForecastWDElementDef[];
    poster.forecastWDElements = poster.forecastWDElements.map((text) => ({
      ...text,
      fontSize: (text.fontSize * 100) / poster.positionControl.h,
    }));
    poster.pointDates = pointDate as unknown as PointDateDef[];
    poster.pointDates = poster.pointDates.map((text) => ({
      ...text,
      fontSize: (text.fontSize * 100) / poster.positionControl.h,
    }));
    poster.pointLocation = pointLocation as unknown as PointLocationDef[];
    poster.pointLocation = poster.pointLocation.map((text) => ({
      ...text,
      fontSize: (text.fontSize * 100) / poster.positionControl.h,
    }));
    poster.description = description;
    poster.enterpriseAccountId = project.enterpriseAccountId;
    createPosterScene({
      name,
      description: `Scene of ${name} poster`,
      duration: sceneDuration ?? 0,
      poster,
    });
    isPosterMode();
    setCreatePosterModal(false);
  };
  const isPosterMode = () => dispatch(setPosterMode());
  return (
    <>
      <ClickAwayListener onClickAway={() => setSidebarItem(SidebarItemsEnum.DEFAULT)}>
        <div className={`sidebar ${roleAccess ? '' : 'pointer-events-none opacity-30'}`}>
          {/* <ToastContainer /> */}
          <SidebarItems setSidebarOpen={handleSidebar} sidebarItem={sidebarItem} />
          <div
            className={'poster-mode flex flex-wrap max-w-min item-wrapper bg-gray-50 px-0.5'}
            onClick={() => (isPlaying ? null : isPosterMode())}
          >
            {!posterMode ? (
              <Tooltip content={'Poster mode'} placement="right">
                <Button
                  disabled={isPlaying}
                  buttonType="link"
                  icon={<GiTargetPoster color="white" />}
                />
              </Tooltip>
            ) : (
              <Tooltip content={'Cancel poster mode'} placement="right">
                <Button disabled={isPlaying} buttonType="link" icon={<MdClose color="white" />} />
              </Tooltip>
            )}
          </div>
          {(activeProp === 'mapPanels' || activeProp === 'weatherPosters' || biasMode) && (
            <div
              className={`poster-mode flex flex-wrap max-w-min item-wrapper ${
                biasMode ? 'bg-red-800' : 'bg-gray-50'
              } px-0.5`}
              onClick={() => dispatch(toggleBiasMode({ biasPanel: activeElementAny }))}
            >
              <Tooltip
                content={`Click to ${biasMode ? 'exit' : 'enter'} bias filter creation mode`}
                placement="right"
              >
                <Button
                  disabled={isPlaying}
                  buttonType="link"
                  icon={<BsArrowDownUp style={{ color: biasMode ? 'red' : 'white' }} />}
                />
              </Tooltip>
            </div>
          )}
          {posterMode && posterContent.length > 0 && (
            <div className={'poster-mode flex flex-wrap max-w-min item-wrapper bg-gray-50 px-0.5'}>
              <Tooltip content={'Create poster'} placement="right">
                <Button
                  disabled={posterContent.length === 0 || isPlaying}
                  className={'flex flex-wrap max-w-min item-wrapper bg-gray-50 px-0.5'}
                  onClick={() => (!activePoster ? setCreatePosterModal(true) : addToPoster())}
                  buttonType="link"
                  icon={<RiAddCircleLine color="white" />}
                />
              </Tooltip>
            </div>
          )}
          {sidebarItem && !isRecord && (
            <SidebarContent
              onAddItem={(id, type) => handleAddItem(id, type as ElementsEnum)}
              sidebarItem={sidebarItem}
              onClose={() => setSidebarItem(SidebarItemsEnum.DEFAULT)}
              search={search}
              onChange={onChange}
              filterTab={sidebarItem !== 'TEMPLATE' ? '' : filterTab}
              setFilterTab={setFilterTab}
              content={sidebarItem !== 'TEMPLATE' ? multimedia?.content : templates?.content}
              isError={sidebarItem !== 'TEMPLATE' ? isMultimediaError : isError}
              error={sidebarItem !== 'TEMPLATE' ? multimediaError : error}
              isRecord={isRecord}
              setRecord={setRecord}
            />
          )}
        </div>
      </ClickAwayListener>
      {isRecord && <RecordAVModal opened={isRecord} onClose={() => setRecord(false)} />}
      {isMapTemplateModal && mapDefinition && flow === 'single' && templateUsed(mapDefinition) && (
        <MapTemplateModal
          flow={'single'}
          scene={activeScene}
          definition={mapDefinition}
          isOpen={isMapTemplateModal}
          onClose={() => handleModal()}
        />
      )}
      {isPosterTemplateModal && posterDefinition && flow === 'single' && filterTab === 'POSTER' && (
        <PosterTemplateModal
          flow={'single'}
          scene={activeScene}
          allPosters={[posterDefinition.posterDef]}
          tab={filterTab}
          isOpen={isPosterTemplateModal}
          onClose={() => handlePosterModal()}
        />
      )}
      {isPosterTemplateModal &&
        geoPosterDefinition &&
        flow === 'single' &&
        filterTab === 'GEOPOSTER' && (
          <PosterTemplateModal
            flow={'single'}
            scene={activeScene}
            allPosters={[geoPosterDefinition.geoPosterDef]}
            tab={filterTab}
            isOpen={isPosterTemplateModal}
            onClose={() => handlePosterModal()}
          />
        )}
      {createPosterModal && (
        <CreatePosterModal
          selectedContent={posterContent}
          opened={createPosterModal}
          onClose={() => setCreatePosterModal(false)}
          onOk={(name, description, isGeo) => createPoster(name, description, isGeo)}
          noOfForecast={posterContent.filter((item) => item.type === 'forecastWDElements').length}
          noOfObserved={posterContent.filter((item) => item.type === 'observedWDElements').length}
        />
      )}
      {isMapTemplateModal &&
        sceneDefinition?.mapPanels &&
        sceneDefinition.mapPanels[mapInScene] &&
        flow === 'scene' && (
          <MapTemplateModal
            key={sceneDefinition.mapPanels[mapInScene].id}
            flow={'scene'}
            scene={activeScene}
            definition={sceneDefinition.mapPanels[mapInScene]}
            isOpen={isMapTemplateModal}
            onClose={() => {
              setMapInScene((mapInScene) => ++mapInScene);
              mapInScene + 1 === sceneDefinition.mapPanels.length && handleModal();
              if (mapInScene + 1 === sceneDefinition.mapPanels.length)
                setIsPosterTemplateModal(true);
            }}
          />
        )}
      {flow === 'scene' && (
        <PosterTemplateModal
          allPosters={sceneDefinition?.weatherPosters ?? []}
          flow={'scene'}
          scene={activeScene}
          tab={filterTab}
          isOpen={isPosterTemplateModal}
          onClose={() => handlePosterModal()}
        />
      )}
    </>
  );
};

export default Sidebar;
