import { BackgroundDef } from './BackgroundDef';
import { BorderDef } from './BorderDef';

export class BoxDef {
  background = new BackgroundDef();
  paddingTop = 0;
  paddingBottom = 0;
  paddingLeft = 0;
  paddingRight = 0;
  borderTop = new BorderDef();
  borderRight = new BorderDef();
  borderBottom = new BorderDef();
  borderLeft = new BorderDef();
  opacity = 1;
}
