import { useEffect, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { Rnd } from 'react-rnd';

import { getNumber } from '../../../helpers/timelineUtil';
import { TimeControlDef } from '../../../model/definitions/TimeControlDef';

interface GroupLaneProps {
  times: Array<TimeControlDef>;
  isOpen?: boolean;
  groupName?: string;
}
export const GroupLane = ({ times, isOpen = true, groupName }: GroupLaneProps) => {
  const { width, ref } = useResizeDetector();
  const [calculatedWidth, setCalculatedWidth] = useState<number | undefined>(width);
  useEffect(() => {
    width !== 0 && setCalculatedWidth(width);
  }, [width, ref]);
  const all = () =>
    times.map((item) => (
      <Rnd
        key={`${groupName}_${item.startMS}_${item.endMS}`}
        enableResizing={false}
        minHeight={3}
        disableDragging={true}
        className={`segment`}
        style={{
          background: 'grey',
        }}
        maxHeight={3}
        bounds={'parent'}
        position={{
          x: getNumber(item.startMS, calculatedWidth),
          y: 12,
        }}
        size={{
          width: item.endMS - item.startMS + '%',
          height: 3,
        }}
      />
    ));
  return (
    <>
      {isOpen ? (
        <div id={'Lane'} ref={ref}>
          {all()}
        </div>
      ) : (
        <div className={'text-center uppercase italic h-[33px]'}>{groupName}</div>
      )}
    </>
  );
};
