import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { axiosInstance } from '../../../../core/api/axiosInstance';
import { queryClient } from '../../../../index';
import { key } from './useGetTransactions';

export const useCancelTransaction = () => {
  return useMutation(
    async (id: string | undefined) => {
      return await axiosInstance.put(`payment/transaction/cancel/${id}`);
    },
    {
      onSuccess: () => {
        toast.success('Transaction successfully canceled!');
        queryClient.invalidateQueries([...key]);
      },
      onError: () => {
        toast.error('Error while canceling transaction!');
      },
    },
  );
};
