import { Label, Modal, ModalProps, TextInput } from 'flowbite-react';
import { useState } from 'react';

import Button from '../../../atoms/button/Button';
import { LogicalGroupElement } from '../../../model/definitions/LogicalGroupElement';

interface AddGroupModalProps extends ModalProps {
  addGroup: (e: LogicalGroupElement) => void;
}
export const AddGroupModal = ({ show, onClose, addGroup }: AddGroupModalProps) => {
  const [name, setName] = useState<string>();
  const handleAddGroup = (e: string) => {
    const group = new LogicalGroupElement(e, 0);
    addGroup(group);
    onClose && onClose();
  };
  return (
    <Modal show={show} popup={true} size="md" onClose={onClose} style={{ zIndex: 9999 }}>
      <div className="duration-modal z-[999]">
        <div className="duration-modal-header">
          <Modal.Header>{'Add Group'}</Modal.Header>
        </div>
        <Modal.Body style={{ paddingTop: 10, paddingBottom: 0 }}>
          <div className="mb-2 block mt-2">
            <Label value="Group name" />
          </div>
          <TextInput
            placeholder="Type Group name..."
            aria-label=""
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            label="Confirm"
            buttonType="primary"
            onClick={() => name && handleAddGroup(name)}
          />
          <Button label="Cancel" buttonType="secondary" onClick={onClose} />
        </Modal.Footer>
      </div>
    </Modal>
  );
};
