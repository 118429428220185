import { QueryKey, useQuery } from 'react-query';

import { axiosInstance } from '../../../core/api/axiosInstance';

export const key: QueryKey = ['MARKETPLACE_TAGS'];

export const useGetMarketplaceTags = (user: boolean) => {
  return useQuery(
    [...key],
    async () => {
      const res = await axiosInstance.get('tag');
      return res.data;
    },
    {
      enabled: user,
    },
  );
};
