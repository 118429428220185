import { ErrorBoundary } from 'react-error-boundary';
import { Navigate, RouteObject } from 'react-router-dom';

import Menu from '../components/menu/Menu';
import MenuMarketplace from '../components/menu-marketplace/MenuMarketplace';
import { RolesEnum } from '../model/enums/RolesEnum';
import { DataProviders } from '../pages/dashboard/components/account-management/DataProviders';
import { Enterprise } from '../pages/dashboard/components/account-management/Enterprise';
import { EnterpriseDetails } from '../pages/dashboard/components/account-management/EnterpriseDetails';
import EnterpriseUsers from '../pages/dashboard/components/account-management/EnterpriseUsers';
import { IconMaker } from '../pages/dashboard/components/IconMaker';
import InDevelopment from '../pages/dashboard/components/in-development/InDevelopment';
import DashboardRefactor from '../pages/dashboard/DashboardRefactor';
import EnterprisePalette from '../pages/dashboard/enterprise-settings/EnterprisePalette';
import EnterpriseProperties from '../pages/dashboard/enterprise-settings/EnterpriseProperties';
import ProfileSettings from '../pages/dashboard/profile-settings/ProfileSettings';
import { TemplatingPage } from '../pages/dashboard/templates-NEW/components/TemplatingPage';
import DetailsPageWS from '../pages/dashboard/templates-NEW/DetailsPageWS';
import Multimedia from '../pages/dashboard/templates-NEW/Multimedia';
import Projects from '../pages/dashboard/templates-NEW/Projects';
import PurchasedMultimedia from '../pages/dashboard/templates-NEW/PurchasedMultimedia';
import PurchasedTemplates from '../pages/dashboard/templates-NEW/PurchasedTemplates';
import RenderDetails from '../pages/dashboard/templates-NEW/RenderDetails';
import SchedulersTable from '../pages/dashboard/templates-NEW/SchedulersTable';
import Templates from '../pages/dashboard/templates-NEW/Templates';
import Translation from '../pages/dashboard/templates-NEW/Translation';
import Wallet from '../pages/dashboard/templates-NEW/Wallet';
import DataProvider from '../pages/dataprovider/pages/DataProvider';
import Monitoring from '../pages/dataprovider/pages/Monitoring';
import ProductRegistration from '../pages/dataprovider/pages/ProductRegistration';
import Products from '../pages/dataprovider/pages/Products';
import Profile from '../pages/dataprovider/pages/Profile';
import Fallback from '../pages/error-boundary/Fallback';
import Checkout from '../pages/marketplace-new/pages/Checkout/Checkout';
import Confirm from '../pages/marketplace-new/pages/Checkout/Confirm';
import DetailsPage from '../pages/marketplace-new/pages/DetailsPage/DetailsPage';
import LandingPage from '../pages/marketplace-new/pages/LandingPage/LandingPage';
import Main from '../pages/marketplace-new/pages/Main';
import Market from '../pages/marketplace-new/pages/market/Market';
import MyProfile from '../pages/marketplace-new/pages/MyProfile/MyProfile';
import Studio from '../pages/playground/Studio';
import { VRProfiler } from '../pages/vr-profiler/VRProfiler';
import PrivateRoute from './auth/PrivateRoute';

export const routes: RouteObject[] = [
  {
    path: '/',
    element: (
      <ErrorBoundary FallbackComponent={Fallback}>
        <PrivateRoute />
      </ErrorBoundary>
    ),
    children: [
      {
        path: '/',
        index: true,
        element: <Navigate to="/workspace" replace />,
      },
      {
        path: '/studio',
        element: <Studio />,
      },
      {
        path: '/vr-profiler',
        element: <VRProfiler />,
      },
      {
        path: '/workspace',
        element: <DashboardRefactor />,
        children: [
          { index: true, element: <Projects /> },
          { path: 'projects/:id', element: <RenderDetails /> },
          {
            path: 'dataprovider',
            children: [
              {
                index: true,
                element: (
                  <PrivateRoute roles={[RolesEnum.ROLE_DATA_PROVIDER]}>
                    <DataProvider />
                  </PrivateRoute>
                ),
              },
              {
                path: 'products',
                element: (
                  <PrivateRoute roles={[RolesEnum.ROLE_DATA_PROVIDER]}>
                    <Products />
                  </PrivateRoute>
                ),
              },
              {
                path: 'products/monitoring/:id',
                element: (
                  <PrivateRoute roles={[RolesEnum.ROLE_DATA_PROVIDER]}>
                    <Monitoring />
                  </PrivateRoute>
                ),
              },
              {
                path: 'product-registration',
                element: (
                  <PrivateRoute roles={[RolesEnum.ROLE_DATA_PROVIDER]}>
                    <ProductRegistration />
                  </PrivateRoute>
                ),
              },
              {
                path: 'profile',
                element: (
                  <PrivateRoute roles={[RolesEnum.ROLE_DATA_PROVIDER]}>
                    <Profile />
                  </PrivateRoute>
                ),
              },
            ],
          },
          {
            path: 'scheduler',
            element: <SchedulersTable />,
          },
          {
            path: 'profile-settings',
            element: <ProfileSettings />,
          },

          {
            path: 'multimedia/:type',
            element: <Multimedia />,
          },
          {
            path: 'purchased/multimedia/:type',
            element: <PurchasedMultimedia />,
          },
          { path: 'purchased/multimedia/:type/preview/:id', element: <DetailsPageWS preview /> },
          {
            path: 'purchased/templates/:type',
            element: <PurchasedTemplates />,
          },
          { path: 'purchased/templates/:type/preview/:id', element: <DetailsPageWS preview /> },
          { path: 'multimedia/:type/:id', element: <DetailsPageWS /> },
          { path: 'multimedia/:type/preview/:id', element: <DetailsPageWS preview /> },
          {
            path: 'templates/:type',
            element: <Templates />,
          },
          {
            path: 'templates/icon-set/edit/:id',
            element: <IconMaker />,
          },
          {
            path: 'templates/icon-set/preview/:id',
            element: <IconMaker preview />,
          },
          { path: 'templates/:type/:id', element: <DetailsPageWS /> },
          { path: 'templates/:type/preview/:id', element: <DetailsPageWS preview /> },
          { path: 'subscription', element: <InDevelopment /> },
          { path: 'wallet', element: <Wallet /> },
          {
            path: 'enterprise-properties',
            children: [
              {
                index: true,
                element: (
                  <PrivateRoute roles={[RolesEnum.ROLE_ACCOUNT_OWNER]}>
                    <EnterpriseProperties />
                  </PrivateRoute>
                ),
              },
              {
                path: 'add-translation',
                element: (
                  <PrivateRoute roles={[RolesEnum.ROLE_ACCOUNT_OWNER]}>
                    <Translation />
                  </PrivateRoute>
                ),
              },
              {
                path: 'edit-translation/:id',
                element: (
                  <PrivateRoute roles={[RolesEnum.ROLE_ACCOUNT_OWNER]}>
                    <Translation />
                  </PrivateRoute>
                ),
              },
              {
                path: 'add-palette',
                element: (
                  <PrivateRoute roles={[RolesEnum.ROLE_ACCOUNT_OWNER]}>
                    <EnterprisePalette />
                  </PrivateRoute>
                ),
              },
              {
                path: 'edit-palette/:id',
                element: (
                  <PrivateRoute roles={[RolesEnum.ROLE_ACCOUNT_OWNER]}>
                    <EnterprisePalette />
                  </PrivateRoute>
                ),
              },
            ],
          },
          { path: 'administration', element: <InDevelopment /> },
          {
            path: 'account-management',
            children: [
              {
                path: 'enterprise',
                element: (
                  <PrivateRoute roles={[RolesEnum.ROLE_ADMIN]}>
                    <Enterprise />
                  </PrivateRoute>
                ),
              },
              {
                path: 'enterprise/:id',
                element: (
                  <PrivateRoute roles={[RolesEnum.ROLE_ADMIN]}>
                    <EnterpriseDetails />
                  </PrivateRoute>
                ),
              },
              {
                path: 'enterprise-users',
                element: (
                  <PrivateRoute roles={[RolesEnum.ROLE_ACCOUNT_OWNER]}>
                    <EnterpriseUsers />
                  </PrivateRoute>
                ),
              },
              {
                path: 'data-providers',
                element: (
                  <PrivateRoute roles={[RolesEnum.ROLE_ADMIN]}>
                    <DataProviders />
                  </PrivateRoute>
                ),
              },
            ],
          },
          { path: 'reports', element: <InDevelopment /> },
          { path: 'templates/:type/project-template/:id', element: <TemplatingPage /> },
          { path: 'purchased/templates/:type/project-template/:id', element: <TemplatingPage /> },
        ],
      },
    ],
  },
  {
    path: '/marketplace',
    element: <Main />,
    children: [
      {
        index: true,
        element: <LandingPage />,
      },
      {
        path: 'items/',
        element: <Market />,
      },
      {
        path: 'items/details-page/:id',
        element: <DetailsPage />,
      },
      {
        path: 'my-profile',
        element: <MyProfile />,
      },
      {
        path: 'my-store/items/',
        element: <Market />,
      },
      {
        path: 'store/:email',
        element: <Market />,
      },
      {
        path: 'my-store/items/details-page/:id',
        element: <DetailsPage />,
      },
      {
        path: 'checkout',
        element: <Checkout />,
      },
      {
        path: 'confirm',
        element: <Confirm />,
      },
    ],
  },
  {
    path: '*',
    element: (
      <Menu>
        <InDevelopment />
      </Menu>
    ),
  },
  {
    path: '/marketplace/*',
    element: (
      <MenuMarketplace>
        <InDevelopment />
      </MenuMarketplace>
    ),
  },
  {
    path: '*',
    element: <Navigate to="/" />,
  },
];
