import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { axiosInstance } from '../../../core/api/axiosInstance';
import { queryClient } from '../../../index';
import { enterpriseKey, fontKey, key } from './fontKeys';

const useDeleteFontVariant = () => {
  return useMutation(
    async (id: string) => {
      const { data } = await axiosInstance.delete(`font-variant/${id}`);
      return data;
    },
    {
      onSuccess: () => {
        toast.success('Font variant deleted successfully!');
        const keys = [key, enterpriseKey, fontKey];
        keys.forEach((k) => queryClient.invalidateQueries(k));
      },
      onError: (e: AxiosError) => {
        toast.error('Error while deleting item');
      },
    },
  );
};

export default useDeleteFontVariant;
