import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { axiosInstance } from '../../../core/api/axiosInstance';

export const useBuyItem = () => {
  return useMutation(
    async (id: string) => {
      return await axiosInstance.post(`marketplace/purchase-item?marketplaceItemId=${id}`);
    },
    {
      onSuccess: () => {
        toast.success('Item successfully purchased!');
      },
      onError: (error: AxiosError) => {
        if (error.request.status !== 403) toast.error('There was an error. Please try again!');
      },
    },
  );
};
