import './App.css';

import { useKeycloak } from '@react-keycloak/web';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import { toast } from 'react-toastify';

import { routes } from './core/Routes';
import { WeatherDataHttpClient } from './core/weather-data/WeatherDataHttpClient';
import useWindowDimensions from './hooks/useWindowDimensions';
import { RolesEnum } from './model/enums/RolesEnum';
import { useGetRoles } from './pages/dashboard/queries-NEW/useGetRoles';
import { setViewportSize } from './store/slices/active-slice';
import { setRoles } from './store/slices/user-slice';

const App = () => {
  const dispatch = useDispatch();
  const { keycloak, initialized } = useKeycloak();
  const { width, height } = useWindowDimensions();
  const { data: roles } = useGetRoles();
  useEffect(() => {
    dispatch(setViewportSize({ width, height }));
  }, [width, height]);

  function handleLogoutWithError() {
    toast.error("This account doesn't belong to any organization");
    setTimeout(() => {
      keycloak.logout({
        redirectUri: `${process.env.REACT_APP_BASE_URL}`,
      });
    }, 4000);
  }

  function handleAuthenticatedActions() {
    if (keycloak.authenticated) {
      Sentry.setUser({ email: keycloak.idTokenParsed!.email });
      keycloak.token && WeatherDataHttpClient.setAccessToken(keycloak.token);
      keycloak.idTokenParsed!.email &&
        WeatherDataHttpClient.setUserEmail(keycloak.idTokenParsed?.email);
    } else {
      Sentry.setUser(null);
    }
  }

  useEffect(() => {
    if (initialized && roles) {
      const enterpriseUser = roles.some(
        (role: RolesEnum) =>
          role === RolesEnum.ROLE_ENTERPRISE_USER ||
          role === RolesEnum.ROLE_DATA_PROVIDER ||
          role === RolesEnum.ROLE_ACCOUNT_OWNER,
      );
      if (!enterpriseUser) {
        handleLogoutWithError();
      } else {
        handleAuthenticatedActions();
        dispatch(setRoles(roles));
      }
    }
  }, [initialized, keycloak.authenticated, keycloak.idTokenParsed, roles]);
  return useRoutes(routes);
};

export default App;
