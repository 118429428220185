import Color from 'colorjs.io';

import { ColorPaletteDef } from '../../model/definitions/ColorPaletteDef';

/* function findIndexClosestBiggerOrEqual(arr: Array<number>, target: number) {
  let closestBigger = Infinity;
  let closestBiggerIndex = -1;

  for (let i = 0; i < arr.length; i++) {
    if (arr[i] >= target && arr[i] < closestBigger) {
      closestBigger = arr[i];
      closestBiggerIndex = i;
    }
  }

  return closestBiggerIndex;
} */
export function extractNumbersWithApproxDistance(
  arr: Array<number>,
  targetDistance: number | null,
  isCloud?: boolean,
) {
  targetDistance = targetDistance ?? 0;
  const numbers = arr.map(Number);
  const result = [];
  let currentIndex = 0;

  while (currentIndex < numbers.length) {
    result.push(numbers[currentIndex]);

    const nextValue = numbers[currentIndex] + targetDistance;
    const nextIndex = numbers.findIndex((num) => num >= nextValue);
    const nextSmallerIndex = nextIndex - 1;
    if (
      Math.abs(numbers[currentIndex] + targetDistance - numbers[nextIndex]) <
        Math.abs(numbers[currentIndex] + targetDistance - numbers[nextSmallerIndex]) ||
      numbers[nextSmallerIndex] === numbers[currentIndex]
    ) {
      if (nextIndex === -1) {
        break;
      }
      currentIndex = nextIndex;
    } else {
      if (nextSmallerIndex === -1) {
        break;
      }
      currentIndex = nextSmallerIndex;
    }
    if (nextIndex === -1) {
      break;
    }
  }
  if (isCloud && result.indexOf(numbers[numbers.length - 1]) < 0)
    result.push(numbers[numbers.length - 1]);
  return result;
}
function extractElements(array: Array<number>, param: number) {
  if (param < 0 || param > 1) {
    // Ensure the parameter is within the valid range [0, 1]
    throw new Error('Parameter must be in the range [0, 1]');
  }
  const extractedElements = [];
  const elements = array.map(Number);
  for (let i = 0; i <= 1; i += param) {
    const x = Number(parseFloat(i.toString()).toPrecision(2));
    if (elements.indexOf(x) > -1 && x !== 0) extractedElements.push(x);
  }

  return extractedElements;
}
function changeKeysInRecord(inputRecord: any, keyMapper: any) {
  const modifiedRecord = {};

  for (const [key, value] of Object.entries(inputRecord)) {
    const newKey = keyMapper(key);
    // @ts-ignore
    modifiedRecord[newKey] = value;
  }

  return modifiedRecord;
}
function fillAndInterpolateColorStops(originalColorStops: Record<number, string>, step: number) {
  const filledAndInterpolatedColorStops: Record<number, string> = {};
  const keys = Object.keys(originalColorStops)
    .map(parseFloat)
    .sort((a, b) => a - b);

  for (let i = 0; i < keys.length - 1; i++) {
    const currentKey = keys[i];
    const nextKey = keys[i + 1];

    // Copy the color from the original object
    filledAndInterpolatedColorStops[Number(currentKey.toFixed(2))] = originalColorStops[currentKey];

    // Interpolate colors between the current and next keys
    let interpolatedKey = currentKey + step;
    while (interpolatedKey < nextKey) {
      const alpha = (interpolatedKey - currentKey) / (nextKey - currentKey);
      const currentColor = originalColorStops[currentKey].split(',').map(parseFloat);
      const nextColor = originalColorStops[nextKey].split(',').map(parseFloat);
      const interpolatedColor = currentColor.map((channel, index) =>
        Math.round(channel + alpha * (nextColor[index] - channel)),
      );
      filledAndInterpolatedColorStops[Number(interpolatedKey.toFixed(2))] =
        interpolatedColor.join(',');
      interpolatedKey += step;
    }
  }

  // Copy the color for the last key
  filledAndInterpolatedColorStops[Number(keys[keys.length - 1].toFixed(2))] =
    originalColorStops[keys[keys.length - 1]];

  return filledAndInterpolatedColorStops;
}
export const generateCloudGradient = (
  palette: ColorPaletteDef,
  scale: Array<number>,
  intervalSize: number | null,
  min: number,
  max: number,
) => {
  if (!palette) return [];
  if (intervalSize === 0) return [];
  const filledPallete = fillAndInterpolateColorStops(palette.colorStops.pallet, 0.01);
  const points = Object.keys(filledPallete)
    .map((item) => parseFloat(item).toFixed(item.split('.')[1]?.length) as unknown as number)
    .sort((a, b) => Number(a) - Number(b))
    .filter((key) => Number(key) >= Number(min) && Number(key) <= Number(max));
  const gradient: Record<number, Color[]> = {};
  const intervalPoints = extractElements(points, (intervalSize ?? 1) / 10);
  /*   const pallet: any = changeKeysInRecord(palette.colorStops.pallet, (oldKey: any) =>
    Number(oldKey),
  ); */
  for (let i = 1; i < intervalPoints.length; i++) {
    getRgba(filledPallete[intervalPoints[i]]);
    gradient[intervalPoints[i - 1]] = [];
    const color = new Color(getRgba(filledPallete[intervalPoints[i - 1]]) || 'white');
    const step = Math.round(
      ((Math.round((intervalPoints[i] - intervalPoints[i - 1]) * 10) / 10) * 10) /
        (intervalSize ?? 1),
    );
    // @ts-ignore
    gradient[intervalPoints[i - 1]] = color.steps(
      getRgba(filledPallete[intervalPoints[i]]) || 'rgba(0, 0, 0, 0)',
      //@ts-ignore
      {
        space: 'srgb',
        outputSpace: 'srgb',
        steps: step,
      },
    );
  }
  const lastColor = new Color(
    getRgba(filledPallete[intervalPoints[intervalPoints.length - 1]]) || 'white',
  );
  //@ts-ignore
  gradient[intervalPoints[intervalPoints.length - 1]] = lastColor.steps(
    getRgba(filledPallete[intervalPoints[intervalPoints.length - 1]]) || 'rgba(255, 0, 0, 255)',
    //@ts-ignore
    {
      space: 'srgb',
      outputSpace: 'srgb',
      steps: 1,
    },
  );
  /*   if (intervalSize === 10) {
    return {
      [intervalPoints[intervalPoints.length - 1]]:
        gradient[intervalPoints[intervalPoints.length - 1]],
    };
  } */
  if (intervalSize === 10) {
    return {
      [intervalPoints[intervalPoints.length - 1]]:
        gradient[intervalPoints[intervalPoints.length - 1]],
    };
  } else {
    return gradient;
  }
};
export const generateGradient = (
  palette: ColorPaletteDef,
  intervalSize: number | null,
  min: number,
  max: number,
) => {
  if (!palette) return [];
  const points = Object.keys(palette.colorStops.pallet)
    .map((item) => parseFloat(item).toFixed(item.split('.')[1]?.length) as unknown as number)
    .sort((a, b) => Number(a) - Number(b))
    .filter((key) => Number(key) >= Number(min) && Number(key) <= Number(max));
  const gradient: Record<number, Color[]> = {};
  const intervalPoints = extractNumbersWithApproxDistance(points, 0.01 /* intervalSize ?? 1 */);
  const pallet: any = changeKeysInRecord(palette.colorStops.pallet, (oldKey: any) =>
    Number(oldKey),
  );
  for (let i = 1; i < intervalPoints.length; i++) {
    getRgba(pallet[intervalPoints[i]]);
    gradient[intervalPoints[i - 1]] = [];
    const color = new Color(getRgba(pallet[intervalPoints[i - 1]]) || 'white');
    //const steps = intervalSize ? Math.round(10 / intervalSize) : 1;
    // @ts-ignore
    gradient[intervalPoints[i - 1]] = color.steps(
      getRgba(pallet[intervalPoints[i]]) || 'rgba(0, 0, 0, 0)',
      //@ts-ignore
      {
        space: 'srgb',
        outputSpace: 'srgb',
        steps: Math.ceil(
          (intervalPoints[i] - intervalPoints[i - 1]) / (intervalSize ?? 1),
        ) /*  intervalSize
          ? Math.round((intervalPoints[i] - intervalPoints[i - 1]) / intervalSize)
          : 1, */,
      },
    );
  }
  const lastColor = new Color(
    getRgba(pallet[intervalPoints[intervalPoints.length - 1]]) || 'white',
  );
  //@ts-ignore
  gradient[intervalPoints[intervalPoints.length - 1]] = lastColor.steps(
    getRgba(pallet[intervalPoints.length - 1]) || 'rgba(0, 0, 0, 0)',
    //@ts-ignore
    {
      space: 'srgb',
      outputSpace: 'srgb',
      steps: 1,
    },
  );
  return gradient;
};

export const getRgba = (input: string) => {
  if (!input) return '';
  const [r, g, b, a] = input.split(',');
  const opacity = a == '255' ? 1 : (parseFloat(a) / 255).toFixed(2);
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};
export const getMinMax = (pal: ColorPaletteDef) => {
  const points = Object.keys(pal.colorStops?.pallet)
    .map((item) => parseFloat(item).toFixed(item.split('.')[1]?.length) as unknown as number)
    .sort((a, b) => a - b);
  const min = points[0];
  const max = points[points.length - 1];
  return { min, max };
};
export const getGradient = (pal: ColorPaletteDef) => {
  const points = Object.keys(pal.colorStops?.pallet)
    .map((item) => parseFloat(item).toFixed(item.split('.')[1]?.length) as unknown as number)
    .sort((a, b) => a - b);
  const min = points[0];
  const max = points[points.length - 1];
  return points.map(
    (p) => `${getRgba(pal.colorStops.pallet[p])} ${(((p - min) / (max - min)) * 100).toFixed(2)}%`,
  );
};
export const createBuiRgba = (input: string) => {
  const cleaned = input.slice(5).slice(0, -1);
  const splitted = cleaned
    .split(',')
    .map((s) => s.trim().replace('%', ''))
    .map((num) => parseFloat(num));
  const recalculated = splitted.map((n) => {
    return Math.round((n / 100) * 255);
  });
  recalculated.push(255); // for now opacity always 1
  return recalculated.join(',');
};

//color interpolation intervals in celsius degrees (0 means any value with two decimal resolution)
export const Intervals = [0, 1, 2, 5, 10];
//min-max scale for legend and also generated output
export const TempRange = { max: 70, min: -70 };
//how often does degree label in legend appear
export const LabelResolution = 20;
