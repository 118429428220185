import { AxiosError } from 'axios';
import { QueryKey, useQuery, UseQueryResult } from 'react-query';

import { axiosInstance } from '../../../core/api/axiosInstance';
import { C9ProjectPreviewDTO } from '../../../model/DTO/C9ProjectPreviewDTO';
import { QueryInfoMetadata } from '../../../model/other/QueryInfoMetadata';

export const key: QueryKey = ['GET_ALL_ICON_SETS'];

const useGetAllIconSets = ({
  parameter,
  page,
  size,
}: {
  parameter?: string;
  page: number;
  size: number;
}): UseQueryResult<QueryInfoMetadata<C9ProjectPreviewDTO>, AxiosError> => {
  return useQuery(
    [...key],
    async () => {
      const { data } = await axiosInstance.get(`icon-set-template`, {
        params: { page, size },
      });
      return data;
    },
    {
      enabled: parameter === 'WeatherType',
    },
  );
};

export default useGetAllIconSets;
