import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { axiosInstance } from '../../../core/api/axiosInstance';
import { queryClient } from '../../../index';
import { key } from './useGetEnterpriseProperties';

const useEditEnterpriseProperties = () => {
  return useMutation(
    async (properties: {
      id: string;
      gammaCorrection: number;
      saturation: number;
      contrast: number;
      enterpriseAccountId: string;
    }) => {
      const { data } = await axiosInstance.put(
        `enterprise-properties/${properties.id}`,
        properties,
      );
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(key);
        toast.success('Successfully updated enterprise properties');
      },
    },
  );
};

export default useEditEnterpriseProperties;
