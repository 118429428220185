import dayjs from 'dayjs';
import { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { gradientOpacity, singleColorOpacity } from '../../helpers/convertOpacity';
import { MAX_FULLSCREEN_HEIGHT } from '../../model/constants/constants';
import { BorderDef } from '../../model/definitions/BorderDef';
import { C9ProjectDef } from '../../model/definitions/C9ProjectDef';
import { TimestampElementDef } from '../../model/definitions/TimestampElementDef';
import { TimeStepEnum } from '../../model/enums/TimeStepEnum';
import PlayerContext from '../../pages/playground/playerContext/PlayerContext';
import { RootState } from '../../store/store';
import ElementContainer from './ElementContainer';
import { displayTimestampsEvenly, generateTimestamps, roundToClosestInterval } from './utils';

interface TimestampElementProps {
  panelProps: TimestampElementDef;
  canvas: { cnvWidth?: number; cnvHeight?: number };
  disabled: boolean;
}

export const TimestampElement = ({ panelProps, canvas, disabled }: TimestampElementProps) => {
  const project = useSelector<RootState, C9ProjectDef>((state) => state.project.present.project);
  const {
    boxDef,
    fontSize,
    fontFamily,
    fontType,
    fontColor,
    fontAlignment,
    strokeWidth,
    strokeColor,
    dateStart,
    dateEnd,
    timeControls,
    step,
    format,
    justifyContent,
    textTransform,
  } = panelProps;
  const {
    paddingBottom,
    paddingTop,
    paddingLeft,
    paddingRight,
    background,
    borderRight,
    borderTop,
    borderLeft,
    borderBottom,
  } = boxDef;
  const { cnvHeight } = canvas;
  const fontUnit = (cnvHeight ?? MAX_FULLSCREEN_HEIGHT) / 100;
  const { color } = background;
  const colorBgOpacity = color?.includes('linear-gradient')
    ? gradientOpacity(color)
    : singleColorOpacity(color);
  const backgroundClip = fontColor?.includes('linear') ? 'text' : 'initial';
  const bgColor = fontColor?.includes('linear-gradient')
    ? gradientOpacity(fontColor)
    : colorBgOpacity;
  const borderString = (val: BorderDef) =>
    val && `${fontUnit * val.width}px ${val.style} ${singleColorOpacity(val.color)}`;
  const timestamps = useMemo(
    () => generateTimestamps(dateStart, dateEnd, TimeStepEnum.MIN_1),
    [dateStart, dateEnd],
  );
  const contextValue = useContext(PlayerContext);
  const { time } = contextValue;

  const currentTimestamp = useMemo(
    () =>
      displayTimestampsEvenly(
        timestamps,
        { min: timeControls[0].startMS, max: timeControls[0].endMS },
        time,
      ),
    [timestamps, time, timeControls],
  );

  return (
    <ElementContainer
      visibility={panelProps.enabled}
      canvas={canvas}
      panelProps={panelProps}
      disabled={disabled}
      type={'timestampPanels'}
      lock={false}
      style={{
        color: fontColor?.includes('linear') ? 'transparent' : singleColorOpacity(fontColor),
        textAlign: fontAlignment as FontAlignment,
        fontSize: fontUnit * fontSize,
      }}
    >
      <div
        className="text-panel-container"
        key={fontColor + 'container'}
        style={{
          height: '100%',
          overflow: 'hidden',
          background: bgColor,
          paddingTop: fontUnit * paddingTop,
          paddingLeft: fontUnit * paddingLeft,
          paddingRight: fontUnit * paddingRight,
          paddingBottom: fontUnit * paddingBottom,
          borderLeft: borderString(borderLeft),
          borderRight: borderString(borderRight),
          borderTop: borderString(borderTop),
          borderBottom: borderString(borderBottom),
          WebkitBackgroundClip: backgroundClip,
        }}
      >
        <div style={{ overflow: 'hidden', width: '100%' }}>
          <div
            style={{
              height: '100%',
              minWidth: 'auto',
              width: 'auto',
              whiteSpace: 'break-spaces',
              lineHeight: 1.15,
              WebkitBackgroundClip: backgroundClip,
              WebkitTextStroke: strokeWidth
                ? `${strokeWidth}px ${singleColorOpacity(strokeColor)}`
                : '0px',
              fontVariantNumeric: 'tabular-nums',
              display: 'flex',
              justifyContent: justifyContent,
              fontFamily: `${fontFamily} ${fontType}`,
              textTransform: textTransform,
            }}
          >
            {dayjs(roundToClosestInterval(currentTimestamp, step))
              .tz(project.properties.timezone)
              .format(format)
              .split(' ')
              .map((i) => (
                <div key={i}> {i}</div>
              ))}
          </div>
        </div>
      </div>
    </ElementContainer>
  );
};
