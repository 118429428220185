import { IconType } from 'react-icons';

const Users: IconType = (props) => {
  const { size, color } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 27 20"
      stroke="currentColor"
      strokeWidth="0"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.6 7.8001C4.92375 7.8001 6 6.72385 6 5.4001C6 4.07635 4.92375 3.0001 3.6 3.0001C2.27625 3.0001 1.2 4.07635 1.2 5.4001C1.2 6.72385 2.27625 7.8001 3.6 7.8001ZM20.4 7.8001C21.7238 7.8001 22.8 6.72385 22.8 5.4001C22.8 4.07635 21.7238 3.0001 20.4 3.0001C19.0763 3.0001 18 4.07635 18 5.4001C18 6.72385 19.0763 7.8001 20.4 7.8001ZM21.6 9.0001H19.2C18.54 9.0001 17.9438 9.26635 17.5088 9.6976C19.02 10.5263 20.0925 12.0226 20.325 13.8001H22.8C23.4638 13.8001 24 13.2638 24 12.6001V11.4001C24 10.0763 22.9238 9.0001 21.6 9.0001ZM12 9.0001C14.3213 9.0001 16.2 7.12135 16.2 4.8001C16.2 2.47885 14.3213 0.600098 12 0.600098C9.67875 0.600098 7.8 2.47885 7.8 4.8001C7.8 7.12135 9.67875 9.0001 12 9.0001ZM14.88 10.2001H14.5688C13.7888 10.5751 12.9225 10.8001 12 10.8001C11.0775 10.8001 10.215 10.5751 9.43125 10.2001H9.12C6.735 10.2001 4.8 12.1351 4.8 14.5201V15.6001C4.8 16.5938 5.60625 17.4001 6.6 17.4001H17.4C18.3938 17.4001 19.2 16.5938 19.2 15.6001V14.5201C19.2 12.1351 17.265 10.2001 14.88 10.2001ZM6.49125 9.6976C6.05625 9.26635 5.46 9.0001 4.8 9.0001H2.4C1.07625 9.0001 0 10.0763 0 11.4001V12.6001C0 13.2638 0.53625 13.8001 1.2 13.8001H3.67125C3.9075 12.0226 4.98 10.5263 6.49125 9.6976Z"
        fill={color}
      />
    </svg>
  );
};

export default Users;
