import { NodeModel } from '@minoru/react-dnd-treeview';
import { Tooltip } from 'flowbite-react';
import { ChangeEvent, useState } from 'react';
import { AiOutlineCaretDown, AiOutlineCaretUp } from 'react-icons/ai';
import { VscTrash } from 'react-icons/vsc';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../../atoms/button/Button';
import Input from '../../../atoms/input/Input';
import { C9ProjectDef } from '../../../model/definitions/C9ProjectDef';
import { deleteLogicalGroup, updateGroupName } from '../../../store/slices/project-slice';
import { RootState } from '../../../store/store';

interface GroupListElementProps {
  node: NodeModel;
  isOpen: boolean;
  depth: number;
  onToggle: () => void;
  openGroups: string[];
  onClick: (e: React.MouseEvent) => void;
  activeScene: string;
  paddingLeft?: any;
}
const inputStyle = {
  height: 'auto',
  background: 'transparent',
};
export const GroupListElement = ({
  node,
  isOpen,
  depth,
  openGroups,
  onClick,
  activeScene,
  paddingLeft,
}: GroupListElementProps) => {
  const project = useSelector<RootState, C9ProjectDef>((state) => state.project.present.project);
  const scene = project.sceneDefs.find((scene) => scene.id === activeScene);
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [name, setName] = useState<string>(node.text);
  const changeName = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
    dispatch(
      updateGroupName({
        activeScene,
        id: node.id as string,
        name: e.target.value,
      }),
    );
  };
  const hasElements = () => {
    if (scene) {
      const {
        textPanels,
        videoPanels,
        mapPanels,
        imagePanels,
        audioElements,
        observedWDElements,
        weatherPosters,
        forecastWDElements,
        logicalGroups,
      } = scene;
      const hasText = textPanels.filter(
        (panel) => panel.parentGroups[0].groupId === node.id,
      ).length;
      const hasVideo = videoPanels.filter(
        (panel) => panel.parentGroups[0].groupId === node.id,
      ).length;
      const hasMap = mapPanels.filter((panel) => panel.parentGroups[0].groupId === node.id).length;
      const hasImage = imagePanels.filter(
        (panel) => panel.parentGroups[0].groupId === node.id,
      ).length;
      const hasAudio = audioElements.filter(
        (panel) => panel.parentGroups[0].groupId === node.id,
      ).length;
      const hasObserved = observedWDElements.filter(
        (panel) => panel.parentGroups[0].groupId === node.id,
      ).length;
      const hasGraphs = weatherPosters.filter(
        (panel) => panel.parentGroups[0].groupId === node.id,
      ).length;
      const hasForecast = forecastWDElements.filter(
        (panel) => panel.parentGroups[0].groupId === node.id,
      ).length;
      const hasGroups = logicalGroups.filter((panel) => panel.parentGroupId === node.id).length;
      const result =
        hasText +
        hasVideo +
        hasMap +
        hasImage +
        hasAudio +
        hasObserved +
        hasGraphs +
        hasForecast +
        hasGroups;
      return result > 0;
    }
  };
  const editFinished = () => {
    setIsEdit(false);
    dispatch(
      updateGroupName({
        activeScene,
        id: node.id as string,
        name: name,
      }),
    );
  };
  const deleteGroup = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(deleteLogicalGroup({ activeScene, id: node.id as string }));
  };
  return (
    <div
      className={`element-parent ${isOpen ? 'element-parent-active' : ''}`}
      style={{ paddingLeft: `${5 + paddingLeft}px` }}
      onClick={onClick}
    >
      <div className={'flex items-center'}>
        {node.droppable && (
          <>
            {isOpen || openGroups.indexOf(node.id as string) > -1 ? (
              <AiOutlineCaretUp />
            ) : (
              <AiOutlineCaretDown />
            )}
          </>
        )}
        <div
          onDoubleClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setIsEdit(true);
          }}
        >
          {!isEdit ? (
            name
          ) : (
            <Input
              onBlur={() => editFinished()}
              style={inputStyle}
              value={name}
              onChange={changeName}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            />
          )}
        </div>
      </div>
      <Tooltip
        content={`${hasElements() ? "Can't delete group containing elements" : 'Delete'}`}
        style={`dark`}
      >
        <Button
          size={'small'}
          buttonType="link"
          icon={<VscTrash style={{ color: 'white', fontSize: 14 }} />}
          className={'hoverState text-red-500'}
          onClick={(e) => deleteGroup(e)}
          disabled={hasElements()}
        />
      </Tooltip>
    </div>
  );
};
