import { Select } from 'antd';
import { ToggleSwitch } from 'flowbite-react';
import Slider from 'rc-slider';
import { FC, useEffect, useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { useDispatch, useSelector } from 'react-redux';

import { useFontLoader, useFontSetter } from '../../../../core/api/useLoadFont';
import { usePropertyGridActive } from '../../../../hooks/usePropertyGridActive';
import {
  JustifyContent,
  TimestampElementDef,
} from '../../../../model/definitions/TimestampElementDef';
import { FontAlignmentPicker } from '../../../../molecules/FontAlignmentPicker';
import { ActiveDef, setPropertyGridActiveHash } from '../../../../store/slices/active-slice';
import { updateTimestampLayer } from '../../../../store/slices/project-slice';
import { RootState } from '../../../../store/store';
import InputNumber from '../../../marketplace-new/atoms/FormatNumber/FormatNumber';
import { PropertySection } from '../components/PropertySection';
import transformText from '../components/slatejs/transformText';
import { PaletteColorPicker } from '../mapLayersProperties/PalettecolorPicker';
import { FontInterface, FontVariantInterface } from '../panels/CoreFontProperties';
import styles from '../Properties.module.scss';
import GridItem from '../shared/GridItem';
import GridWrapper from '../shared/GridWrapper';

function transformJustifyContent(): { value: string; label: string }[] {
  return Object.values(JustifyContent).map((value) => ({
    value,
    label: value.replace(/-/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase()),
  }));
}

export const TextStyle: FC<TimestampElementDef> = ({
  fontColor,
  fontSize,
  fontFamily,
  fontType,
  fontAlignment,
  justifyContent,
  strokeColor,
  strokeWidth,
  textTransform,
}) => {
  const { fonts, fontVariants, fetchFontVariants } = useFontLoader(fontFamily ?? '');
  function fontTypeSetter(value: string) {
    onChange(value, 'fontType');
  }

  function fontVariantIdSetter(value: string) {
    onChange(value, 'fontVariantId');
  }
  useFontSetter(
    fontVariants,
    fontType,
    fontTypeSetter,
    fontVariantIdSetter,
    fetchFontVariants.isLoading,
  );
  const dispatch = useDispatch();
  const { activeElement, activeScene } = useSelector<RootState, ActiveDef>((state) => state.active);
  function onFocus(path: Leaves<TimestampElementDef>) {
    dispatch(setPropertyGridActiveHash({ activeElement, focusedEl: path }));
  }
  const onChange = (value: string | number, propertyPath: Leaves<TimestampElementDef>) =>
    dispatch(updateTimestampLayer({ activeScene, elementId: activeElement, propertyPath, value }));
  const { isOpened, lastFocused } = usePropertyGridActive(['fontSize']);
  const [isGradient, setIsGradient] = useState(false);
  const [savedGradient, setSavedGradient] = useState<string>(
    'linear-gradient(45deg, rgba(255, 255, 255, 255), rgba(255, 255, 255, 0))',
  );
  useEffect(() => {
    fontColor?.includes('linear') ? setIsGradient(true) : setIsGradient(false);
  }, [fontColor]);
  const [savedColor, setSavedColor] = useState<string>('rgba(255, 255, 255, 255)');
  const parseFontLinearGradient = (index: number) => {
    const colors = fontColor?.match(/\d+/g);
    let value: string[] = [];
    if (colors) {
      value = [
        colors[0],
        `rgba(${colors[1]}, ${colors[2]},${colors[3]},${colors[4]})`,
        `rgba(${colors[5]}, ${colors[6]},${colors[7]},${colors[8]})`,
      ];
    }
    return value[index];
  };
  const getFontGradientArray = () => {
    if (fontColor?.includes('linear')) {
      const colors = fontColor.match(/[\d.]+/g); // Extract all numeric values including decimals
      if (colors && colors.length >= 9) {
        return [
          colors[0], // degree
          `rgba(${colors[1]}, ${colors[2]}, ${colors[3]}, ${colors[4]})`, // from color
          `rgba(${colors[5]}, ${colors[6]}, ${colors[7]}, ${colors[8]})`, // to color
        ];
      }
    }
    return ['0', 'rgba(255, 255, 255, 255)', 'rgba(255, 255, 255, 0)']; // Default values
  };

  const onChangeFontGradient = (color: string, index: number) => {
    const newGradient = getFontGradientArray();
    if (newGradient) {
      newGradient[index] = color; // Update the degree or color based on the index
      onChange(
        `linear-gradient(${newGradient[0]}deg, ${newGradient[1]}, ${newGradient[2]})`,
        'fontColor',
      );
    }
  };

  return (
    <PropertySection isOpened={isOpened} label={'Text style'}>
      <div className="prop-wrapper">
        <GridWrapper>
          {!isGradient && (
            <GridItem
              noBorderBg
              label={'Color'}
              item={
                <PaletteColorPicker
                  value={fontColor ?? 'rgba(255, 255, 255, 255)'}
                  onChange={(e) => onChange(e, 'fontColor')}
                />
              }
            />
          )}
          <GridItem
            label={'Font size'}
            item={
              <DebounceInput
                debounceTimeout={400}
                value={fontSize}
                onFocus={() => onFocus('fontSize')}
                autoFocus={lastFocused === 'fontSize'}
                onChange={(e) => onChange(e.target.value, 'fontSize')}
              />
            }
          />
          <GridItem
            noBorderBg
            label="Align:"
            item={
              <FontAlignmentPicker
                onClick={(e) => onChange(e, 'fontAlignment')}
                value={fontAlignment}
              />
            }
          />
          <GridItem
            noBorderBg
            label="Justify:"
            item={
              <Select
                options={transformJustifyContent()}
                value={justifyContent}
                onChange={(e) => {
                  onChange(e, 'justifyContent');
                }}
              />
            }
          />
          <GridItem
            label="Font family:"
            noBorderBg
            item={
              <Select
                onChange={(e) => {
                  onChange(e, 'fontFamily');
                  onChange('', 'fontType');
                  onChange('', 'fontVariantId');
                }}
                value={fontFamily}
              >
                {fonts?.map((font: FontInterface) => (
                  <option
                    key={`${font.name}`}
                    value={font.name.split('(')[0]}
                    style={{ fontFamily: font.name.split('(')[0] }}
                  >
                    {font.name}
                  </option>
                ))}
              </Select>
            }
          />
          <GridItem
            label="Font type:"
            item={
              <select
                className={styles.select}
                value={fontType}
                onChange={(e) => {
                  const findType = fontVariants.find(
                    (fontVariant: FontVariantInterface) => fontVariant.type === e.target.value,
                  );
                  onChange(e.target.value, 'fontType');
                  onChange(findType.id, 'fontVariantId');
                }}
              >
                {Array.isArray(fontVariants) &&
                  fontVariants?.map((fontVariant: FontVariantInterface) => (
                    <option key={`${fontVariant.id}`} value={fontVariant.type}>
                      {fontVariant.type}
                    </option>
                  ))}
              </select>
            }
          />
          <GridItem
            label="Stroke width:"
            item={
              <input
                className={styles.inputWrap}
                min={0}
                value={strokeWidth ?? 0}
                type="number"
                onChange={(e) =>
                  onChange(Number(e.target.value) >= 0 ? Number(e.target.value) : 0, 'strokeWidth')
                }
                step={1}
                onFocus={() => onFocus('strokeWidth')}
                autoFocus={lastFocused === 'strokeWidth'}
              />
            }
          />
          <GridItem
            noBorderBg
            label="Stroke Color:"
            item={
              <PaletteColorPicker
                value={strokeColor ?? 'rgba(255, 255, 255, 255)'}
                onChange={(e) => onChange(e, 'strokeColor')}
              />
            }
          />
          <GridItem
            noBorderBg
            label="Font gradient:"
            item={
              <ToggleSwitch
                style={{ width: 'auto' }}
                label={''}
                checked={isGradient}
                onChange={(e) => {
                  e ? setSavedColor(fontColor) : setSavedGradient(fontColor);
                  onChange(e ? savedGradient : savedColor, 'fontColor');
                }}
              />
            }
          />
          {!isGradient && (
            <GridItem
              noBorderBg
              label="Font color:"
              item={
                <PaletteColorPicker value={fontColor} onChange={(e) => onChange(e, 'fontColor')} />
              }
            />
          )}
        </GridWrapper>

        {isGradient && (
          <>
            <h4>Gradient</h4>
            <GridWrapper>
              <GridItem
                noBorderBg
                label="From:"
                item={
                  <PaletteColorPicker
                    value={parseFontLinearGradient(1)}
                    onChange={(e) => onChangeFontGradient(e, 1)}
                    disabled={!fontColor.includes('linear-gradient')}
                  />
                }
              />

              <GridItem
                noBorderBg
                label="To:"
                item={
                  <PaletteColorPicker
                    value={parseFontLinearGradient(2)}
                    onChange={(e) => onChangeFontGradient(e, 2)}
                    disabled={!fontColor.includes('linear-gradient')}
                  />
                }
              />

              <GridItem
                noBorderBg
                label="Degree:"
                item={
                  <>
                    <Slider
                      min={0}
                      max={360}
                      value={Number(parseFontLinearGradient(0))}
                      disabled={!fontColor.includes('linear-gradient')}
                      onChange={(e) => {
                        e && typeof e === 'number' && onChangeFontGradient(e.toString(), 0);
                      }}
                    />
                    <InputNumber
                      className={styles.inputWrap}
                      max={360}
                      min={0}
                      precision={2}
                      disabled={!fontColor.includes('linear-gradient')}
                      value={parseFontLinearGradient(0)}
                      onInputChange={(e) => {
                        e >= 0 && e <= 360 && onChangeFontGradient(e.toString(), 0);
                      }}
                      onFocus={() => onFocus('fontColor')}
                      autoFocus={lastFocused === 'fontColor'}
                      type="number"
                    />
                  </>
                }
              />
            </GridWrapper>
          </>
        )}
        <GridWrapper className="mt-2">
          <GridItem
            noBorderBg
            label="Text transform:"
            item={
              <Select value={textTransform} onChange={(e) => onChange(e, 'textTransform')}>
                {transformText.map((item) => (
                  <option key={item.format} value={item.format}>
                    {item.name}
                  </option>
                ))}
              </Select>
            }
          />
        </GridWrapper>
      </div>
    </PropertySection>
  );
};
