import { useRef, useState } from 'react';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { IoSaveOutline } from 'react-icons/io5';
import { MdDeleteOutline } from 'react-icons/md';

import styles from '../Properties.module.scss';
import { PaletteColorPicker } from './PalettecolorPicker';

interface PaletteColorProps {
  color: string;
  setPaletteScale: (newValue: number, prevValue: number | string) => void;
  setPaletteColor: (color: string, value: string) => void;
  colorValue: string;
  onAddPalletValue?: (color: string, colorValue: string) => void;
  onDeletePalletValue?: (color: string) => void;
  paletteLength: number;
  hideAdd: boolean;
}
export const PaletteColor = ({
  color,
  setPaletteScale,
  setPaletteColor,
  colorValue,
  onAddPalletValue,
  onDeletePalletValue,
  paletteLength,
  hideAdd,
}: PaletteColorProps) => {
  const [val, setVal] = useState(Number(color));
  const isAddAction = Boolean(onAddPalletValue);
  const isDeleteAction = Boolean(onDeletePalletValue);
  const ref = useRef<string>(color);
  return (
    <div key={Number(color)} className={`${styles.paletteGrid}`}>
      <input
        style={{ marginRight: '1rem', textAlign: 'right' }}
        value={val}
        type={'number'}
        onChange={(e) => setVal(Number(e.target.value))}
        onBlur={(e) => setPaletteScale(Number(e.target.value), ref.current)}
      />
      <div className="flex items-center">{Number(ref.current) !== val && <IoSaveOutline />}</div>
      <PaletteColorPicker
        isPalette
        onChange={(e) => setPaletteColor(color, e)}
        value={colorValue}
      />
      <div className="flex">
        {isAddAction && hideAdd && (
          <button className="outline-0" onClick={() => onAddPalletValue!(color, colorValue)}>
            <AiOutlinePlusCircle size={20} />
          </button>
        )}
        {isDeleteAction && paletteLength > 1 && (
          <button onClick={() => onDeletePalletValue!(color)}>
            <MdDeleteOutline color="red" size={22} />
          </button>
        )}
      </div>
    </div>
  );
};
