import './style.scss';

import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '../../atoms/Button/AntButton';
import Drawer from '../../atoms/Drawer/Drawer';
import { useGetShoppingCartItems } from '../../hooks/payment/useGetShoppingCartItems';
import { useRemoveFromCart } from '../../hooks/payment/useRemoveFromCart';
import MarketplaceContext from '../../marketplaceContext';
import Cart from './Cart';

export interface DataType {
  id: string;
  name: string;
  description: string;
  image: string;
  priceCurrency: string;
  price: number;
  count: number;
}

function Sidebar() {
  const { showCartSidebar, setShowCartSidebar, authenticated } = useContext(MarketplaceContext);
  const { data: cart, isLoading: isLoadingItems } = useGetShoppingCartItems(authenticated);
  const navigate = useNavigate();
  const { mutate: removeFromCart } = useRemoveFromCart();

  const onClose = () => {
    setShowCartSidebar(false);
  };

  const handleDelete = (item: DataType) => {
    removeFromCart(item.id);
  };

  const handleCheckout = () => {
    setShowCartSidebar(false);
    navigate('/marketplace/checkout');
  };

  return (
    <>
      {!isLoadingItems && (
        <Drawer
          title={
            <div>
              <div className="text-xl">
                Your cart{' '}
                <span className="text-gray-500 font-normal">
                  ({cart?.items?.length ?? '0'} items)
                </span>
              </div>
            </div>
          }
          closable={false}
          placement="right"
          width={550}
          onClose={onClose}
          open={showCartSidebar}
          footer={
            cart?.items?.length < 1 ? (
              <Button disabled block size="large">
                CHECKOUT
              </Button>
            ) : (
              <Button block type="primary" size="large" onClick={() => handleCheckout()}>
                CHECKOUT
              </Button>
            )
          }
        >
          <Cart data={cart?.items} handleDelete={handleDelete} />
        </Drawer>
      )}
    </>
  );
}

export default Sidebar;
