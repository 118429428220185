import { UnitDisplayEnum } from '../enums/UnitDisplayEnum';
import { C9Object } from './C9Object';
import { GribSourceDef } from './GribSourceDef';
import { PointDataFrameDef } from './PointDataFrameDef';
import { WeatherDataMapLayer } from './WeatherDataMapLayer';

export enum SymbolSourceType {
  PointData = 'POINT_DATA',
  ModelData = 'MODEL_DATA',
}

export enum SymbolPointType {
  Forecast = 'FORECAST',
  Observed = 'OBSERVED',
}

export interface PointSourceDef {
  dataProductId: string;
  sourceName: string;
}

export class SymbolLayerDef extends WeatherDataMapLayer {
  symbolSource: SymbolLayerSourceDef = new SymbolLayerSourceDef();
}

export class SymbolLayerSourceDef extends C9Object {
  gribSource = new GribSourceDef();
  points: SymbolLayerPointDef[] = [];
  sourceType: SymbolSourceType;
  pointType: SymbolPointType;
  defaultStyle: SymbolStyleDef = new SymbolStyleDef();
  unit: string;
  iconPackId: string;
  format: string;
  pointSource: PointSourceDef | undefined;
  pointParameter: string | undefined;
  pointDataFrames: PointDataFrameDef[] | undefined;
}

export class SymbolStyleDef {
  fontColor = 'rgba(255, 255, 255, 255)';
  fontSize = 5;
  iconSize = 80;
  fontFamily: string;
  fontType: string;
  fontVariantId: string;
  fontAlignment: 'left';
  strokeWidth = 0;
  strokeColor = 'rgba(255, 255, 255, 0)';
  fillColor = 'rgba(255, 0, 0, 255)';
  displayUnitOfMeasurement: boolean;
  precision = 0;
  unitDisplay: UnitDisplayEnum;
  showWindArrow = true;
  showWindValue = false;
  windArrowImage: string;
  windArrowWidth = 6;
  windArrowHeight = 6;
  windArrowScale = 1;
  scaleByWindSpeed = false;
  showUnit = true;
  dualColor = false;
  fontStrokeWidth = 0;
  fontStrokeColor = 'rgba(0, 0, 0, 0)';
}

export interface SymbolLayerPointDef {
  lat: number;
  lon: number;
  locationId?: string;
}

// export enum GriddedFieldValueTypeEnum {
//   Numerical,
//   Wind,
//   WeatherCode,
// }
