import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ScenesDurations {
  [key: string]: number;
}
const initialState: ScenesDurations = {
  asd: 1,
};
const durationSlice = createSlice({
  name: 'scenesDuration',
  initialState,
  reducers: {
    setSceneDuration(
      state,
      action: PayloadAction<{
        scene: string;
        duration: number;
      }>,
    ) {
      const {
        payload: { scene, duration },
      } = action;
      state[scene] = duration;
    },
  },
});

export const { setSceneDuration } = durationSlice.actions;
export default durationSlice.reducer;
