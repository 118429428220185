import { QueryKey, useQuery } from 'react-query';

import { C9ProjectDef } from '../../model/definitions/C9ProjectDef';
import { VideoAssemblyTask } from '../../model/definitions/VideoAssemblyTask';
import { VideoAssemblyTaskDTO } from '../../model/definitions/VideoAssemblyTaskDTO';
import { TaskStatusEnum } from '../../model/enums/TaskStatusEnum';
import { QueryInfoMetadata } from '../../model/other/QueryInfoMetadata';
import { axiosInstance } from './axiosInstance';

const sendAssemblyTask = async (project: C9ProjectDef): Promise<VideoAssemblyTask> => {
  const res = await axiosInstance.post('video-assembly-task', project);
  return res.data;
};

const getAssemblyTasksPerProject = async (
  projectId: string,
  page: number,
  size: number,
): Promise<QueryInfoMetadata<VideoAssemblyTaskDTO> | null> => {
  if (projectId === 'mock') {
    return null;
  }
  const res = await axiosInstance.get(
    `video-assembly-task/by-project/${projectId}?page=${page}&size=${size}`,
  );
  return res.data;
};

export const key: QueryKey = ['GET_ASSEMBLY_TASK_BY_ID'];
const useGetAssemblyTaskById = (taskId: string) =>
  useQuery(
    [...key, taskId],
    async () => {
      const res = await axiosInstance.get(`video-assembly-task/${taskId}`);
      return res.data;
    },
    {
      enabled: !!taskId,
    },
  );

const getAssemblyTasksPerUser = async (
  page: number,
  size: number,
  searchKey = '',
  status: TaskStatusEnum,
): Promise<QueryInfoMetadata<VideoAssemblyTaskDTO>> => {
  const res = await axiosInstance.get(
    `dashboard/my-video-assembly-tasks?page=${page}&size=${size}&searchKey=${searchKey}${
      status ? '&status=' + status : ''
    }`,
  );
  return res.data;
};

export {
  getAssemblyTasksPerProject,
  getAssemblyTasksPerUser,
  sendAssemblyTask,
  useGetAssemblyTaskById,
};
