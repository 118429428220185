import './styles.scss';

import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import React from 'react';

interface Props {
  sorting: Record<string, string>;
  handleSort: (item: string) => void;
  sortByField: string;
  sortAscDesc: string;
}

const Sorting: React.FC<Props> = ({ sorting, handleSort, sortByField, sortAscDesc }) => {
  const menu = (
    <Menu>
      {Object.entries(sorting).map(([key, value]) => (
        <Menu.Item key={key} onClick={() => handleSort(key)}>
          {value}
          {key === sortByField && sortAscDesc === 'DESC' ? (
            <CaretUpOutlined />
          ) : (
            <CaretDownOutlined />
          )}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <Button className="sorting-dropdown-button">
        Sort By {sortAscDesc && `${sorting[sortByField]} (${sortAscDesc})`} <CaretDownOutlined />
      </Button>
    </Dropdown>
  );
};

export default Sorting;
