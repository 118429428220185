const ButtonStyles = {
  colorText: '#5a5a5a',
  fontSize: 15,
  fontSizeLG: 15,
  borderRadius: 8,
  paddingContentHorizontal: 30,
  colorPrimary: '#2176ff !important',
  colorPrimaryHover: 'none !important',
  colorBorder: '#888888',
};

export default ButtonStyles;
