import './PaletteIsoline.scss';

import { cloneDeep } from 'lodash';
import React, { useEffect } from 'react';

import LoadingIndicator from '../../atoms/loadingIndicator/LoadingIndicator';
import { useGetPalette } from '../../core/api/mapLayers/useGetPalette';
import { useCreatePalette } from '../../core/api/palette/useCreatePalette';
import { ColorPaletteDef } from '../../model/definitions/ColorPaletteDef';
import { GribMapLayer } from '../../model/definitions/GribMapLayer';
import { PickerDef } from '../../model/definitions/PickerDef';
import { RadarMapLayer } from '../../model/definitions/RadarMapLayer';
import { SatelliteMapLayer } from '../../model/definitions/SatelliteMapLayer';
import { CustomPaletteDTO } from '../../model/DTO/CustomPaletteDTO';
import { ColorPaletteParamTypeEnum } from '../../model/enums/ColorPaletteParamTypeEnum';
import { VisualisationTypeEnum } from '../../model/enums/VisualisationTypeEnum';
import { isGribLayerType } from '../../pages/playground/properties/mapLayersProperties/MapGribLayers';
import { IsolinePalettePreview } from './IsolinePalettePreview';

interface Props {
  parameter: ColorPaletteParamTypeEnum;
  palette: ColorPaletteDef | null;
  setPalette: (palette: ColorPaletteDef | null) => void;
  editPalette?: (e: string) => void;
  layerEdit?: GribMapLayer | RadarMapLayer | SatelliteMapLayer;
  source?: PickerDef;
  visualisationType?: VisualisationTypeEnum;
  dataProductId: string | null;
  enterpriseAccountId?: string;
}

const PaletteIsoline: React.FC<Props> = ({
  parameter,
  palette,
  setPalette,
  editPalette,
  layerEdit,
  source,
  visualisationType,
  dataProductId,
  enterpriseAccountId,
}) => {
  const { data, isLoading, isSuccess } = useGetPalette(
    parameter,
    dataProductId,
    source?.name,
    visualisationType,
    enterpriseAccountId,
  );
  const { mutate: createPalette } = useCreatePalette();
  useEffect(() => {
    if (!palette && data) {
      setPalette(data[0]);
    }
  }, [data, parameter]);
  useEffect(() => {
    if (data) {
      if (!layerEdit) setPalette(data[0]);
      else if (layerEdit) {
        if (
          isGribLayerType(layerEdit) &&
          !layerEdit.gribSource.parameterType.name.toLowerCase().includes(parameter?.toLowerCase())
        ) {
          setPalette(data[0]);
        } else if (
          visualisationType &&
          layerEdit.layerSetup?.visualisationType !== visualisationType
        ) {
          setPalette(data[0]);
        } else {
          // Removed to attempt to fix NIMA-1634
          // layerEdit.layerSetup?.colorPaletteDef && setPalette(layerEdit.layerSetup.colorPaletteDef);
        }
      }
    }
  }, [isSuccess, data]);
  if (isLoading) return <LoadingIndicator />;
  if (visualisationType == VisualisationTypeEnum.PARTICLE) return <></>;
  const clonePalette = (pal: ColorPaletteDef) => {
    const clonePalette = cloneDeep(pal);
    const { id, versionId, ...paletteWithoutIds } = clonePalette;
    if (id && versionId) {
      createPalette(paletteWithoutIds as CustomPaletteDTO);
    }
  };
  return (
    <>
      <div className="w-fit">
        {data?.map((p) => (
          <IsolinePalettePreview
            key={p.id}
            palette={p}
            selected={palette}
            setPalette={setPalette}
            editPalette={editPalette}
            showMinMax={visualisationType == VisualisationTypeEnum.ISOLINE}
            clonePalette={clonePalette}
          />
        ))}
      </div>
    </>
  );
};

export default PaletteIsoline;
