import { Input as AntInput, notification, Select } from 'antd';
import { Button, ToggleSwitch } from 'flowbite-react';
import { cloneDeep } from 'lodash';
import moment from 'moment/moment';
import { useState } from 'react';
import { AiOutlineCaretDown, AiOutlineCaretUp } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { v4 } from 'uuid';

import Input from '../../../../atoms/input/Input';
import { spaceSpan } from '../../../../components/timeline/helpers';
import { useApplyBiasGroupOnForecast } from '../../../../core/api/bias/bias-group/useApplyBiasGroupOnForecast';
import { useGetBiasGroups } from '../../../../core/api/bias/bias-group/useGetBiasGroups';
import { useGetLatestVersionIcon } from '../../../../core/api/bias/bias-group/useGetLatestVersionIcon';
import { useCreateForecastBias } from '../../../../core/api/bias/useCreateForecastBias';
import { useGetForecastData } from '../../../../core/api/forecastData/useGetForecastData';
import { useGetMultipleIcons } from '../../../../core/api/observedData/useGetIcons';
import { stringToUnit } from '../../../../helpers/stringToUnit';
import { usePropertyGridActive } from '../../../../hooks/usePropertyGridActive';
import { ForecastWDElementDef } from '../../../../model/definitions/ForecastWDElementDef';
import { ForecastWDSourceDef } from '../../../../model/definitions/ForecastWDSourceDef';
import { SceneDef } from '../../../../model/definitions/SceneDef';
import { ValueTypeEnum } from '../../../../model/enums/ValueTypeEnum';
import {
  ActiveDef,
  setPropertyGridActiveHash,
  setSyncSpace,
} from '../../../../store/slices/active-slice';
import {
  addMapForecastLayer,
  fillSpaceWithForecast,
  updateForecastLayer,
  updatePanel,
} from '../../../../store/slices/project-slice';
import { selectActiveScene } from '../../../../store/slices/selectors';
import { RootState } from '../../../../store/store';
import { DDIcon } from '../../bias-filter/Icon';
import { BiasFilterModal } from '../../modals/BiasFilterModal';
import { PropertySection } from '../components/PropertySection';
import styles from '../Properties.module.scss';
import GridActions from '../shared/GridActions';
import GridItem from '../shared/GridItem';
import GridWrapper from '../shared/GridWrapper';

const { Option } = Select;
interface ForecastSpecificsProps {
  layer?: ForecastWDElementDef;
  name: string;
  description: string;
  source: ForecastWDSourceDef;
  displayUnitOfMeasurement: boolean;
  mapId?: string;
}
export const ForecastSpecifics = ({
  layer,
  source,
  name,
  description,
  displayUnitOfMeasurement,
  mapId,
}: ForecastSpecificsProps) => {
  const { data: icons } = useGetLatestVersionIcon(
    source.originalValue?.iconSetId || source.value?.iconSetId,
  );
  const activeSceneDef = useSelector<RootState, SceneDef | null>((state) =>
    selectActiveScene(state),
  );
  const { activeScene, activeElement, activePoster, activeMap } = useSelector<RootState, ActiveDef>(
    (state) => state.active,
  );
  const maps = activeSceneDef?.mapPanels.map((layer) => {
    return { label: layer.name, value: layer.id };
  });
  const dispatch = useDispatch();

  const { data: biasFilters } = useGetBiasGroups('FORECAST');
  const scene = useSelector<RootState, SceneDef | null>((state) => selectActiveScene(state));
  const space = scene?.mapPanels.find((panel) => panel.id === mapId)?.wdSpace[0];
  const span = space && spaceSpan(space);
  const spaceWidth = {
    start: moment((span?.min ?? 0) * 1000),
    end: moment((span?.max ?? 0) * 1000),
  };
  const hasBias = layer?.forecastWDSource.biasFilterAppliedOnValue;
  const { mutate: applyBiasGroup } = useApplyBiasGroupOnForecast();
  const { data } = useGetForecastData(source.location.locationId);
  const restData = source.daily ? data?.daily : data?.intraDay;
  const days = restData?.filter((point) =>
    moment(point.utcDate).isBetween(spaceWidth.start.startOf('day'), spaceWidth.end, 'd', '[]'),
  );
  const possibleIcons = days?.map((day) => day.weatherType);
  const { data: iconsToSet } = useGetMultipleIcons(possibleIcons);
  const pointData = days?.map((day) => {
    const template = cloneDeep(layer!);
    if (template) {
      template.id = v4();
      if (template.forecastWDSource.valueType === ValueTypeEnum.NUMERICAL) {
        template.forecastWDSource.value = day.properties.find(
          (prop) => prop.name === source.parameterType,
        )?.values;
      }
      if (template.forecastWDSource.valueType === ValueTypeEnum.IMAGE) {
        template.forecastWDSource.parameterType = day.weatherType;
        template.forecastWDSource.value = iconsToSet?.find(
          (icon) => icon.param === day.weatherType,
        )?.value;
      }
      template.forecastWDSource.utcDate = day.utcDate;
      return template;
    }
  });
  const applyBiasGroupOnElement = (id: string) => {
    layer &&
      applyBiasGroup(
        {
          biasGroupId: id,
          body: layer,
        },
        {
          onSuccess: (data) => {
            dispatch(
              updateForecastLayer({
                newValue: data.forecastWDSource,
                //@ts-ignore
                propertyPath: 'forecastWDSource',
                activeScene,
                elementId: activeElement,
                parentId: activePoster,
                mapId: activeMap,
              }),
            );
            notification.success({ message: 'Group Filter Applied!' });
          },
        },
      );
  };

  const filterSelector = () => {
    const options = biasFilters?.map((filter) => (
      <Option value={filter.id} key={filter.id}>
        {filter.name}
      </Option>
    ));
    return (
      <Select
        defaultValue={layer?.forecastWDSource.valueHistory.slice(-1)[0]?.appliedBiasFilterId}
        onChange={applyBiasGroupOnElement}
      >
        {options}
      </Select>
    );
  };
  const fill = () => {
    const payloadData = pointData?.filter((item) => item !== undefined);
    payloadData &&
      mapId &&
      dispatch(
        fillSpaceWithForecast({
          forecastLayers: payloadData as unknown as ForecastWDElementDef[],
          activeScene,
          mapId,
        }),
      );
    //space && dispatch(setSyncSpace({ spaceId: space.id }));
  };

  const { isOpened, lastFocused } = usePropertyGridActive([
    'forecastWDSource.value',
    'name',
    'description',
    'displayUnitOfMeasurement',
  ]);
  const handleChange = (index: number) => {
    if (icons?.length - 1 >= index && index >= 0) {
      const newIcon = icons.find((icon: any) => icon.iconIndexInSet === index);
      updateValue(newIcon, 'forecastWDSource.value');
    }
  };
  const { location, unitOfMeasurement, value, valueType, parameterType, utcDate, valueHistory } =
    source;
  const { latitude, longitude, name: locationName, state } = location;
  const [open, setOpen] = useState<boolean>(isOpened);
  const { mutate: createBias } = useCreateForecastBias();
  const [isModal, setIsModal] = useState(false);
  const [selectedMap, setSelectedMap] = useState(maps ? maps[0]?.value : undefined);
  const addForecastDataToMap = () => {
    if (selectedMap && layer) {
      dispatch(
        addMapForecastLayer({
          forecastLayer: { ...layer, positionControl: { ...layer.positionControl, x: 0, y: 0 } },
          activeScene,
          mapId: selectedMap,
        }),
      );
      dispatch(
        updatePanel({
          activeScene: activeScene,
          activeProp: 'forecastWDElements' as keyof SceneDef,
          activeElement: activeElement,
        }),
      );
      space && dispatch(setSyncSpace({ spaceId: space.id }));
    }
  };

  const updateValue = (
    newValue: string | number | boolean | unknown,
    propertyPath: Leaves<ForecastWDElementDef>,
  ) => {
    onFocus(propertyPath);
    dispatch(
      updateForecastLayer({
        newValue,
        propertyPath,
        activeScene,
        elementId: activeElement,
        parentId: activePoster,
        mapId: activeMap,
      }),
    );
  };
  function onFocus(path: Leaves<ForecastWDElementDef>) {
    dispatch(setPropertyGridActiveHash({ activeElement, focusedEl: path }));
  }
  const unitsSelection = () => (
    <Select
      onChange={(e) => updateValue(e, 'forecastWDSource.unitOfMeasurement')}
      value={!value?.length ? undefined : unitOfMeasurement}
      options={
        Array.isArray(value)
          ? value.map((val: { unit: string; value: number }) => {
              return { label: stringToUnit(val.unit), value: val.unit };
            })
          : []
      }
    />
  );
  const removeBiasFilter = () => {
    const values =
      layer?.forecastWDSource.valueType === ValueTypeEnum.NUMERICAL
        ? layer.forecastWDSource.value.map((val: any) => {
            return { ...val, value: val.originalValue };
          })
        : layer?.forecastWDSource.originalValue;
    const newValue = {
      ...layer?.forecastWDSource,
      biasFilterAppliedOnValue: false,
      value: values,
    };
    dispatch(
      updateForecastLayer({
        newValue,
        //@ts-ignore
        propertyPath: 'forecastWDSource',
        activeScene,
        elementId: activeElement,
        parentId: activePoster,
        mapId: activeMap,
      }),
    );
  };
  return (
    <>
      <div
        className={`mb-2 subheader ${open ? 'layer-header-active' : ''}`}
        onClick={() => setOpen(!open)}
      >
        {open ? <AiOutlineCaretUp /> : <AiOutlineCaretDown />}
        Forecast Data Properties
      </div>
      {open && (
        <div className="prop-wrapper">
          <GridWrapper>
            <GridItem
              label={'Name:'}
              item={
                <Input
                  value={name}
                  onFocus={() => onFocus('name')}
                  autoFocus={lastFocused === 'name'}
                  onChange={(e) => updateValue(e.target.value, 'name')}
                />
              }
            />
            <GridItem
              label={'Description:'}
              item={
                <Input
                  value={description}
                  onFocus={() => onFocus('description')}
                  autoFocus={lastFocused === 'description'}
                  onChange={(e) => updateValue(e.target.value, 'description')}
                />
              }
            />
            <GridItem noBorderBg label={'Station name:'} item={locationName} />

            <GridItem
              label={'Location:'}
              item={
                <>
                  {latitude ? longitude.toFixed(4) : 'N/A'},{latitude ? latitude.toFixed(4) : 'N/A'}
                </>
              }
            />

            <GridItem label={'Station state:'} item={state} />

            <GridItem label={'Forecast Time:'} item={new Date(utcDate).toLocaleString('sr-RS')} />

            {valueType === ValueTypeEnum.NUMERICAL && (
              <GridItem noBorderBg label={'Parameter:'} item={parameterType} />
            )}
            <GridItem label={'Apply Group Bias:'} noBorderBg item={filterSelector()} />
            {valueType === ValueTypeEnum.IMAGE && (
              <GridItem noBorderBg label={'Parameter:'} item={<div>{parameterType}</div>} />
            )}
            {valueType === ValueTypeEnum.NUMERICAL && (
              <>
                <GridItem noBorderBg label={'Unit of measurement:'} item={unitsSelection()} />
                <GridItem
                  label={'Value:'}
                  item={
                    <div className={'flex'}>
                      <AntInput
                        disabled={Boolean(!value?.length)}
                        type={'number'}
                        value={
                          Array.isArray(value)
                            ? (value.find(
                                (val: { unit: string; value: number }) =>
                                  val.unit === unitOfMeasurement,
                              ).value as number)
                            : NaN
                        }
                        onFocus={() => onFocus('forecastWDSource.value')}
                        autoFocus={lastFocused === 'forecastWDSource.value'}
                        onChange={(e) => {
                          const newVal = cloneDeep(value);
                          newVal.find(
                            (val: { unit: string; value: number }) =>
                              val.unit === unitOfMeasurement,
                          ).value = e.target.value;
                          updateValue(newVal, 'forecastWDSource.value');
                        }}
                        className={styles.inputWrap}
                      />
                    </div>
                  }
                />

                <GridItem
                  noBorderBg
                  label={'Display unit:'}
                  item={
                    <ToggleSwitch
                      disabled={!!activePoster}
                      style={{ width: 'auto' }}
                      label={''}
                      checked={displayUnitOfMeasurement}
                      onChange={(e) => {
                        updateValue(e, 'displayUnitOfMeasurement');
                      }}
                    />
                  }
                />
                <GridItem
                  noBorderBg
                  label={`${!mapId ? 'Select' : ''} Parent Map`}
                  item={
                    <Select
                      value={selectedMap}
                      onChange={(e) => setSelectedMap(e)}
                      options={maps}
                      disabled={!!mapId}
                    >
                      Add to selected map
                    </Select>
                  }
                />
              </>
            )}
            {valueType === ValueTypeEnum.IMAGE && (
              <GridItem
                label={'Value:'}
                noBorderBg
                item={
                  source.value?.thumbnailUrls?.length > 0 ? (
                    <img
                      className="h-2/3"
                      src={source.value?.thumbnailUrls[0]}
                      alt={source.value?.name}
                    />
                  ) : (
                    <DDIcon
                      versionId={source.value?.versionId}
                      isJson={source.value?.jsonAnimation}
                      full
                    />
                  )
                }
              />
            )}
            {
              <GridItem
                noBorderBg
                label={''}
                item={
                  !mapId ? (
                    <Button disabled={!selectedMap} onClick={addForecastDataToMap}>
                      Add to map
                    </Button>
                  ) : (
                    <Button disabled={!selectedMap} onClick={fill}>
                      Fill space
                    </Button>
                  )
                }
              />
            }
          </GridWrapper>
          {valueType === ValueTypeEnum.IMAGE && (
            <PropertySection isOpened={true} label={'Available icons'}>
              <div className={'grid grid-cols-6 gap-2'}>
                {icons?.map((icon: any) => (
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleChange(icon.iconIndexInSet);
                    }}
                    title={icon.name}
                    key={icon.versionId}
                    className={`${
                      icon?.versionId === source.value?.versionId
                        ? 'border-green-500'
                        : 'border-slate-200'
                    } flex flex-col items-center justify-center border border-solid border-slate-200 h-[90px] cursor-pointer`}
                  >
                    {icon.thumbnailUrls?.length > 0 ? (
                      <img className="h-2/3" src={icon.thumbnailUrls[0]} alt={icon.name} />
                    ) : (
                      <DDIcon versionId={icon.versionId} isJson={icon.jsonAnimation} />
                    )}
                    <div className={'text-center'}>{icon.name}</div>
                  </div>
                ))}
              </div>
            </PropertySection>
          )}
          <GridActions>
            <Button
              color="purple"
              onClick={() => setIsModal(true)}
              disabled={valueHistory[valueHistory?.length - 1]?.newValue === source.value}
            >
              Save bias filter
            </Button>
            {hasBias && (
              <Button
                color="purple"
                onClick={() => removeBiasFilter()}
                disabled={valueHistory[valueHistory?.length - 1]?.newValue === source.value}
              >
                Remove bias filter
              </Button>
            )}
          </GridActions>
        </div>
      )}
      <BiasFilterModal
        sourceVal={source.value}
        type={valueType}
        isOpen={isModal}
        onClose={() => setIsModal(false)}
        value={source.value}
        originalValue={source.originalValue}
        unit={source.unitOfMeasurement}
        parameter={source.parameterType}
        onCreate={(form) => {
          const biased =
            valueType === ValueTypeEnum.NUMERICAL
              ? Number(
                  value.find(
                    (val: { unit: string; value: number }) => val.unit === unitOfMeasurement,
                  ).value,
                )
              : source.value;
          createBias({
            filterName: `${form.name}`,
            filterDescription: `${form.description}`,
            forecastWDSource: {
              ...source,
              changedValue: biased,
            },
          });
          setIsModal(false);
        }}
      />
    </>
  );
};
