import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { axiosInstance } from '../../../core/api/axiosInstance';
import { queryClient } from '../../../index';
import { key as getAssignedDataProducts } from './useGetAssignedDataProducts';

export const useDeleteAssignedDataProduct = () => {
  return useMutation(
    async ({
      enterpriseAccountID,
      dataProductId,
    }: {
      enterpriseAccountID: string;
      dataProductId: string;
    }) => {
      return await axiosInstance.delete(`enterprise-accounts/assigned-products`, {
        params: { enterpriseAccountID, dataProductId },
      });
    },
    {
      onSuccess: () => {
        toast.success('Data product deleted!');
        queryClient.invalidateQueries(getAssignedDataProducts);
      },
      onError: () => {
        toast.error('Error while deleting data product!');
      },
    },
  );
};
