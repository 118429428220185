import { IconTemplateDef } from '../definitions/IconTemplateDef';
import { PointDataSourceHistoryDef } from '../definitions/PointDataSourceHistoryDef';
import { ValueTypeEnum } from '../enums/ValueTypeEnum';
import { ObservedData, Station } from '../other/ObservedData';

export class ObservedElement {
  name: string;
  description?: string | null;
  datetime: number;
  utcDate: string;
  localDate?: string | null;
  dayTime: boolean;
  value: IconTemplateDef | Array<{ unit: string; value: number }>;
  originalValue: number | IconTemplateDef;
  parameterType: string;
  unitOfMeasurement?: string;
  valueType: ValueTypeEnum;
  daily?: boolean;
  intraDay?: boolean;
  valueHistory: Array<PointDataSourceHistoryDef> = [];

  constructor(
    data?: ObservedData,
    name?: string,
    description?: string,
    valueType?: ValueTypeEnum,
    value?: IconTemplateDef | Array<{ unit: string; value: number }>,
    originalValue?: number | IconTemplateDef,
    parameterType?: string,
    unitOfMeasurement?: string,
    daily?: boolean,
  ) {
    if (data) {
      this.datetime = data.utcTimeStamp;
      this.utcDate = data.utcTime;
      this.localDate = data.localTime;
      this.dayTime = data.dayTime;
    }
    if (name && parameterType) this.name = name + ' ' + parameterType.split(/(?=[A-Z])/).join(' ');
    if (description) this.description = description;
    if (valueType) this.valueType = valueType;
    if (value) this.value = value;
    if (originalValue) this.originalValue = originalValue;
    if (parameterType) this.parameterType = parameterType;
    if (unitOfMeasurement) this.unitOfMeasurement = unitOfMeasurement;
    if (daily === true) this.daily = daily;
    if (daily === false) this.intraDay = daily;
  }
}
export interface ObservedWDElementRequestDTO {
  station: Station;
  elements: ObservedElement[];
  groupDate: boolean;
  groupLocation: boolean;
}
