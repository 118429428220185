const Previous = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17 18C17.2652 18 17.5196 17.8946 17.7071 17.7071C17.8946 17.5196 18 17.2652 18 17C18 16.9987 18 16.9974 18 16.9961L18 9L18 1.00391C18 1.0026 18 1.0013 18 1C18 0.734783 17.8946 0.480429 17.7071 0.292892C17.5196 0.105356 17.2652 -6.4237e-08 17 -8.74229e-08C16.787 0.000406159 16.5796 0.0688342 16.4082 0.195312L16.4062 0.195312C16.397 0.202314 16.3879 0.209476 16.3789 0.216797L5.45898 8.1582C5.31809 8.24884 5.20222 8.37342 5.12203 8.52051C5.04183 8.66759 4.99987 8.83247 5 9C5.00011 9.16412 5.04061 9.32569 5.11793 9.47045C5.19525 9.61521 5.30702 9.73872 5.44336 9.83008L16.4062 17.8047C16.5782 17.9316 16.7863 18 17 18ZM1.5 18C2.328 18 3 17.328 3 16.5L3 1.5C3 0.672 2.328 -1.37009e-06 1.5 -1.44248e-06C0.672001 -1.51486e-06 -3.92487e-07 0.671999 -4.64873e-07 1.5L-1.77621e-06 16.5C-1.8486e-06 17.328 0.671999 18 1.5 18Z"
        fill="#678299"
      />
    </svg>
  );
};

export default Previous;
