import './mapTemplateModal.scss';

import { differenceInDays } from 'date-fns';

import { DataFrameDef } from '../../../../../model/definitions/DataFrameDef';
import { RadarMapLayer } from '../../../../../model/definitions/RadarMapLayer';
import { FramesRange } from '../../../modals/FramesRange';
import { useDefaultFrames } from '../../../sidebar-new/repoUtil/useDefaultFrames';
import { useGetRadarLayerData } from './mapTemplateApi/useGetRadarLayerData';

interface RadarRangeProps {
  source: RadarMapLayer;
  bounds: [number, number, number, number];
  setFrames: (id: string, frames: DataFrameDef[]) => void;
  layerNew?: RadarMapLayer;
}
const wrapper = 'flex flex-col justify-center items-center range-wrapper';
export const RadarRange = ({ source, bounds, setFrames, layerNew }: RadarRangeProps) => {
  const {
    id,
    name,
    dataFrames,
    radarSource: { radarSource, creationTime },
  } = source;
  const { data, isError, isLoading } = useGetRadarLayerData(radarSource.id, bounds);
  //const sortedFrames = [...dataFrames].sort((a, b) => a.timestamp - b.timestamp);
  const { selectedFrames } = useDefaultFrames(data, dataFrames, creationTime);
  const handleFrames = (layerFrames: Array<DataFrameDef>) => setFrames(id, layerFrames);
  return (
    <div className={'map-template-layer-wrapper'}>
      <h3>{name}</h3>
      <span>
        Default layer offset in days:
        {dataFrames && differenceInDays(dataFrames[0]?.timestamp * 1000, creationTime)}
      </span>
      <div className={wrapper}>
        <FramesRange
          selectedFrames={layerNew ? [...layerNew?.dataFrames] : []}
          layerType={'radar'}
          defaultValue={selectedFrames}
          isError={isError}
          isLoading={isLoading}
          frames={data}
          onRangeChange={handleFrames}
        />
      </div>
    </div>
  );
};
