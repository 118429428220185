import '../../../molecules/palette/PaletteMenu.scss';

import { ExclamationCircleFilled } from '@ant-design/icons';
import { Button as AntdButton, Modal, Space, Table } from 'antd';
import { AiOutlinePlus } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import Button from '../../../atoms/button/Button';
import { useDeletePalette } from '../../../core/api/palette/useDeletePalette';
import { useGetPalettes } from '../../../core/api/palette/useGetPalettes';
import { ColorPaletteDef } from '../../../model/definitions/ColorPaletteDef';
import { getGradient } from '../../../molecules/palette/utils';

const { confirm } = Modal;

interface PaletteInterface {
  id: string;
  name: string;
  description: string;
  source: string;
  paletteParamType: string;
  visualisationType: string;
}

function DefaultColorPalettes({ enterpriseAccountId }: { enterpriseAccountId: string }) {
  const { data: palettes, isLoading } = useGetPalettes(enterpriseAccountId);
  const { mutate: deletePalette } = useDeletePalette();
  const handleDelete = (id: string) => {
    confirm({
      title: 'Delete palette',
      icon: <ExclamationCircleFilled />,
      content: 'Are you sure you want to delete this palette?',
      centered: true,
      okText: 'Delete',
      okType: 'danger',
      onOk() {
        deletePalette(id, {
          onSuccess: () => {
            toast.success('Palette successfully deleted');
          },
        });
      },
    });
  };

  const columns: {
    title: string;
    dataIndex?: string;
    key?: string;
    render?: (_: any, product: PaletteInterface) => JSX.Element;
  }[] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Source',
      dataIndex: 'source',
      key: 'source',
    },
    {
      title: 'Parameter',
      dataIndex: 'paletteParamType',
      key: 'paletteParamType',
      render: (_: any, palette: PaletteInterface) => (
        <div>{palette.paletteParamType.replaceAll('_', ' ')}</div>
      ),
    },
    {
      title: 'Visualisation',
      dataIndex: 'visualisationType',
      key: 'visualisationType',
    },
    {
      title: 'Palette',
      render: (_: any, palette: PaletteInterface) => (
        <div className="palette-menu mb-1 !bg-[#f9f9f9] !w-fit !p-0 -ml-1">
          {generatePalettePreview(palette as ColorPaletteDef)}
        </div>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (_: any, palette: PaletteInterface) => {
        return (
          <Space size="middle">
            <Link to={`./edit-palette/${palette.id}`}>
              <AntdButton type="primary" shape="round">
                Edit
              </AntdButton>
            </Link>
            <AntdButton danger shape="round" onClick={() => palette.id && handleDelete(palette.id)}>
              Delete
            </AntdButton>
          </Space>
        );
      },
    },
  ];

  const generatePalettePreview = (pal: ColorPaletteDef) => {
    const gradient = getGradient(pal);
    return (
      <div key={pal.id} className={`!bg-white palette-item !cursor-default`}>
        <div
          className="preview"
          style={{
            background: `linear-gradient(to right, ${gradient.join(',')})`,
          }}
        />
      </div>
    );
  };

  return (
    <>
      <div className={'flex ws-fixed-header'}>
        <div className={'ws-temp-title'}>
          <h1>Palettes</h1>
        </div>
        <Link to="./add-palette">
          <Button className={`ws-button`} label={`Add palette`} icon={<AiOutlinePlus />} />
        </Link>
      </div>
      <Table rowKey="id" dataSource={palettes} columns={columns} loading={isLoading} />
    </>
  );
}

export default DefaultColorPalettes;
