import './style.scss';

import { Input, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { BsSearch } from 'react-icons/bs';

import { useDebounce } from '../../../../hooks/useDebounce';

const { Title } = Typography;

interface MarketPlaceBgInterface {
  setSearchString: (e: string) => void;
}

const MarketBG = ({ setSearchString }: MarketPlaceBgInterface) => {
  const [inputText, setInputText] = useState<string>('');

  const handleChange = (e: string) => {
    setInputText(e);
  };

  const debounceSearch = useDebounce(inputText);

  useEffect(() => {
    setSearchString(debounceSearch);
  }, [debounceSearch]);

  return (
    <>
      <div className="mp-bg-wrapper">
        <video loop autoPlay muted>
          <source src="/mapEarth.mp4" type="video/mp4" />
        </video>
        <div className="overlay"></div>

        <div className={'mp-bg'}>
          <Title>
            SEARCH OUT <span>MARKETPLACE</span>
          </Title>
          <Title level={2}>
            <b>Our extensive collection</b> of <b>dynamic data</b> offerings, captivating
            <b> rich media content</b>, <br /> and
            <b> state-of-the-art broadcasting assets </b> <br /> sets us apart from the rest.
          </Title>
          <div className="search">
            <Input
              value={inputText}
              onChange={(e) => handleChange(e.target.value)}
              placeholder="Type here..."
              suffix={<BsSearch size={16} className="mr-2" />}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MarketBG;
