import { useQuery } from 'react-query';

import { axiosInstance } from '../../../core/api/axiosInstance';

export const key = ['DATA_PRODUCTS'];

export const useGetDataProducts = ({ page, searchKey }: { page: number; searchKey: string }) =>
  useQuery(
    [...key, page, searchKey],
    async () => {
      const { data } = await axiosInstance.get('data-products', {
        params: { page, searchKey },
      });
      return data;
    },
    {
      staleTime: Infinity,
    },
  );
