import styles from './styles.module.scss';

interface ControlButtonProps {
  icon: JSX.Element | string;
  onClick?: () => void;
  disabled?: boolean;
  bordered?: boolean;
  replay?: boolean;
}
const ControlButton = ({ icon, onClick, disabled, bordered, replay }: ControlButtonProps) => {
  return (
    <button
      className={`${styles.controlButton} ${bordered ? styles.bordered : ''} ${
        replay ? styles.replay : ''
      } ${disabled ? styles.disabled : ''}`}
      onClick={() => onClick && onClick()}
    >
      {icon}
    </button>
  );
};
export default ControlButton;
