import { RefObject } from 'react';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import ReactPaginate from 'react-paginate';

interface PaginationProps {
  pages: number;
  changePage: (e: number) => void;
  gridRef?: RefObject<HTMLDivElement>;
  currentPage?: number;
}
const Pagination = ({ pages, changePage, gridRef, currentPage }: PaginationProps) => {
  return (
    <ReactPaginate
      className={'pagination'}
      pageLinkClassName={'pagination-item'}
      activeLinkClassName={'pagination-active'}
      previousLinkClassName={'pagination-prev-next'}
      nextLinkClassName={'pagination-prev-next'}
      renderOnZeroPageCount={() => null}
      breakLabel="..."
      nextLabel={<BsChevronRight />}
      pageRangeDisplayed={5}
      pageCount={pages ?? 0}
      previousLabel={<BsChevronLeft />}
      forcePage={currentPage}
      onPageChange={(e) => {
        changePage && changePage(e.selected);
        gridRef && gridRef.current?.parentElement?.scrollTo(0, 0);
      }}
    />
  );
};

export default Pagination;
