import './style.scss';

import { ShoppingCartOutlined } from '@ant-design/icons';
import { useKeycloak } from '@react-keycloak/web';
import { Badge, Select } from 'antd';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { queryClient } from '../../..';
import { ExchangeRateResponseDTO } from '../../../model/DTO/ExchangeRateResponseDTO';
import UserActions from '../../dashboard/userActions-NEW/UserActions';
import Button from '../atoms/Button/AntButton';
import { useGetShoppingCartItems } from '../hooks/payment/useGetShoppingCartItems';
import { key as currencyKey, useGetCurrency } from '../hooks/useGetCurrency';
import { useGetCurrencyOptions } from '../hooks/useGetCurrencyOptions';
import MarketplaceContext from '../marketplaceContext';

const HeaderButtons = () => {
  const navigate = useNavigate();
  const { keycloak, initialized } = useKeycloak();
  const { showCartSidebar, setShowCartSidebar, authenticated } = useContext(MarketplaceContext);
  const { data: shoppingCartItems, isLoading } = useGetShoppingCartItems(authenticated);
  const { data: currencyOptions } = useGetCurrencyOptions();
  const { data: currency } = useGetCurrency();
  const openCartSidebar = () => {
    setShowCartSidebar(!showCartSidebar);
  };

  const handleCurrencyChange = (value: string) => {
    const newCurrency = currencyOptions?.find((item: ExchangeRateResponseDTO) => {
      return item.code === value;
    });
    localStorage.setItem('selectedCurrency', JSON.stringify(newCurrency));
    queryClient.invalidateQueries(currencyKey);
  };

  return (
    <div className="flex items-center right-menu">
      {keycloak.authenticated && initialized ? (
        <div className="flex items-center">
          <Button className="hidden md:block" type="link" onClick={() => navigate('/workspace')}>
            NAVIGATE TO WORKSPACE
          </Button>
          <Button
            className="block md:hidden px-2 text-base"
            type="link"
            onClick={() => navigate('/workspace')}
          >
            WORKSPACE
          </Button>
          <Select
            defaultValue={currency?.code ?? 'USD'}
            showSearch={true}
            onChange={(value) => handleCurrencyChange(value)}
            options={currencyOptions}
            fieldNames={{
              value: 'code',
              label: 'code',
            }}
            optionLabelProp="label"
            notFoundContent={<div>No data</div>}
          />
          <Button
            disabled={isLoading}
            className="hidden md:block"
            type="link"
            onClick={() => openCartSidebar()}
          >
            <Badge
              showZero={false}
              size="small"
              count={shoppingCartItems ? shoppingCartItems.items.length : 0}
            >
              <ShoppingCartOutlined style={{ fontSize: 18 }} />
            </Badge>
            &nbsp; CART
          </Button>
          <Button
            disabled={isLoading}
            className="block md:hidden px-2 text-base"
            type="text"
            onClick={() => openCartSidebar()}
          >
            <Badge
              showZero={false}
              size="small"
              count={shoppingCartItems ? shoppingCartItems.items.length : 0}
            >
              <ShoppingCartOutlined style={{ fontSize: 18 }} />
            </Badge>
          </Button>
          <UserActions />
        </div>
      ) : (
        <Button size="large" onClick={() => keycloak.login()}>
          Log in
        </Button>
      )}
    </div>
  );
};

export default HeaderButtons;
