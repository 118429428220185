import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { axiosInstance } from '../../../core/api/axiosInstance';
import { queryClient } from '../../../index';
import { key as getSchedulersKey } from './useGetSchedulers';

export const useDeleteVideoAssemblyTask = () => {
  return useMutation(
    async (id: string) => {
      return await axiosInstance.delete(`/scheduler/scheduler_task/${id}`);
    },
    {
      onSuccess: () => {
        toast.success('Item deleted!');
        queryClient.invalidateQueries(getSchedulersKey);
      },
      onError: () => {
        toast.error('Error deleting item!');
      },
    },
  );
};
