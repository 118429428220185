import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { saveProject } from '../core/api/ProjectsAPI';
import { C9ProjectDef } from '../model/definitions/C9ProjectDef';
import { addNewProject, setSavedProject } from '../store/slices/project-slice';
import { RootState } from '../store/store';

export function useImplicitSave() {
  const dispatch = useDispatch();
  const project = useSelector<RootState, C9ProjectDef>((state) => state.project.present.project);
  const projectMut = useMutation(saveProject, {
    onSuccess: (data) => {
      dispatch(addNewProject({ project: data }));
      dispatch(setSavedProject({ savedProject: data }));
    },
  });
  return (projectToSave?: C9ProjectDef) => {
    projectMut.mutate(projectToSave ? projectToSave : project);
  };
}
