import { Button, Select, ToggleSwitch } from 'flowbite-react';
import { useEffect, useState } from 'react';
import { VscArrowLeft } from 'react-icons/vsc';

import { ForecastDataDef } from '../../../model/definitions/ForecastDataDef';
import { ForecastValueDef } from '../../../model/definitions/ForecastPropertiesDef';
import { IconTemplateDef } from '../../../model/definitions/IconTemplateDef';
import { ForecastElementRequestDTO } from '../../../model/DTO/ForecastElementRequestDTO';
import { ValueTypeEnum } from '../../../model/enums/ValueTypeEnum';
import { ObservedProperties } from '../../../model/other/ObservedData';
import { ForecastElementForm } from '../../../model/UI/ForecastElementForm';
import { ForecastIconElementRequestDTO } from '../../../model/UI/ForecastIconElementRequestDTO';
import { DayCard } from './DayCard';
import { IconPicker } from './IconPicker';
import { ObservedCard } from './ObservedCard';

interface ForecastSecondStepProps {
  basic: {
    name: string;
    description?: string;
  };
  data: ForecastDataDef;
  onElementClick: (
    element: ForecastElementRequestDTO | ForecastIconElementRequestDTO,
    remove?: boolean,
  ) => void;
  formData: ForecastElementForm;
  bias: boolean;
  setBias: (bias: boolean) => void;
  setFormData: (e: ForecastElementForm) => void;
}
export const ForecastSecondStep = ({
  data,
  onElementClick,
  formData,
  basic,
  bias,
  setBias,
  setFormData,
}: ForecastSecondStepProps) => {
  const { elements } = formData;
  const [selectedDate, setSelectedDate] = useState<string | undefined>();
  const [selectedHour, setSelectedHour] = useState<string | undefined>();
  const [selection, setSelection] = useState<'day' | 'hour'>();
  const [dayData, setDayData] = useState<{
    daily: ForecastValueDef;
    intraDay: Array<ForecastValueDef>;
  }>();
  const { location, daily } = data;
  const isSelected = (data: ObservedProperties, date: string, daily: boolean) =>
    !!elements.find(
      (element) =>
        element.parameterType === data.name &&
        element.localDate === date &&
        element.daily === daily,
    );
  const isIconSelected = (data: string, date: string, daily: boolean) =>
    !!elements.find(
      (element) =>
        element.parameterType === data && element.utcDate === date && element.daily === daily,
    );

  const createElementReq = (
    day: ForecastValueDef,
    property: ObservedProperties,
    unit: string,
    daily: boolean,
  ) => {
    const element = new ForecastElementRequestDTO(
      basic.name,
      basic.description ?? '',
      property.name,
      day,
      unit,
      ValueTypeEnum.NUMERICAL,
      daily,
    );
    onElementClick(element);
  };
  const createIconElementReq = (
    icon: IconTemplateDef,
    date: string,
    param: string,
    daily: boolean,
    remove?: boolean,
  ) => {
    const element = new ForecastIconElementRequestDTO(
      basic.name,
      param,
      date,
      ValueTypeEnum.IMAGE,
      icon,
      daily,
    );
    onElementClick(element, remove);
  };
  const handleOnIconClick = (
    icon: IconTemplateDef,
    active: boolean,
    utc: string,
    weatherType: string,
    daily: boolean,
  ) => {
    if (!active) createIconElementReq(icon, utc, weatherType, daily);
    else {
      createIconElementReq(icon, utc, weatherType, daily, true);
    }
  };

  const availableDays = daily?.map((day) => day);

  const handleBackToDates = () => {
    setSelectedDate(undefined);
    setDayData(undefined);
    setSelection(undefined);
  };
  const handleSelectDate = (date: string) => {
    setSelectedDate(date);
    const dateObject = new Date(date);
    const daily = data.daily.find((day) => day.utcDate === date);
    const intraDay = data.intraDay.filter(
      (day) =>
        new Date(day.utcDate).getFullYear() === dateObject.getFullYear() &&
        new Date(day.utcDate).getMonth() === dateObject.getMonth() &&
        new Date(day.utcDate).getDate() === dateObject.getDate(),
    );
    daily && setDayData({ daily, intraDay });
  };
  useEffect(() => {
    if (selectedDate) {
      const dateObject = new Date(selectedDate);
      const daily = data.daily.find((day) => day.utcDate === selectedDate);
      const intraDay = data.intraDay.filter(
        (day) =>
          new Date(day.utcDate).getFullYear() === dateObject.getFullYear() &&
          new Date(day.utcDate).getMonth() === dateObject.getMonth() &&
          new Date(day.utcDate).getDate() === dateObject.getDate(),
      );
      daily &&
        setDayData({
          daily,
          intraDay,
        });
    }
  }, [bias]);
  const renderDays = availableDays?.map((day) => (
    <DayCard key={day.utcDate} day={day} onClick={handleSelectDate} />
  ));
  const renderDailyForecast = (property: ForecastValueDef, daily: boolean) => {
    const properties = property.properties.map((day) => {
      return (
        <ObservedCard
          biased={!!day.biasFilterId}
          active={isSelected(day, property.localDate, daily)}
          key={day.name + day.biasFilterId}
          property={day}
          onClick={(unit) => createElementReq(property, day, unit, daily)}
        />
      );
    });
    const iconCard = (
      <IconPicker
        active={isIconSelected(property.weatherType, property.utcDate, daily)}
        weatherType={property.weatherType}
        onSelect={(icon) =>
          createIconElementReq(icon, property.utcDate, property.weatherType, daily)
        }
        onClick={(icon, active) =>
          handleOnIconClick(icon, active, property.utcDate, property.weatherType, daily)
        }
      />
    );
    return [iconCard, ...properties];
  };
  const renderIntraDayForecast = () =>
    dayData?.intraDay
      .filter((intra) => intra.utcDate === selectedHour)
      .sort((a, b) => a.utcDate.localeCompare(b.utcDate))
      .map((hour) => renderDailyForecast(hour, false));
  const availableHours = dayData?.intraDay.filter(
    (day) => new Date(day.utcDate).getDate() === new Date(selectedDate ?? '').getDate(),
  );
  const buttonActive = (act: 'day' | 'hour') => {
    if (act !== selection)
      return { background: 'transparent', border: '1px solid rgba(255, 255, 255, 0.3)' };
  };
  useEffect(() => {
    selection === 'hour' &&
      availableHours &&
      availableHours[0] &&
      setSelectedHour(availableHours[0].utcDate);
  }, [selection]);
  return (
    <>
      <div className={'flex forecast-properties'}>
        {location && (
          <>
            <div>
              <label>
                Name:
                <p>{location.name}</p>
              </label>
            </div>
            <div>
              <label>
                Country:
                <p>{location.country}</p>
              </label>
            </div>
            {location.state && (
              <div>
                <label>
                  State:
                  <p>{location.state}</p>
                </label>
              </div>
            )}
            <div>
              <label>
                Timezone:
                <p>{location.timezoneId}</p>
              </label>
            </div>
            <div>
              <label>
                Position(lat, lon):
                <p>
                  {location.latitude?.toFixed(4) ?? 'N/A'},{' '}
                  {location.longitude?.toFixed(4) ?? 'N/A'}
                </p>
              </label>
            </div>
          </>
        )}
      </div>
      <div className={'flex justify-between'}>
        <div className={'flex'}>
          <label className={'flex pl-2'}>
            <span className={'mr-2'}>Apply bias filter</span>{' '}
            <ToggleSwitch checked={bias} onChange={setBias} label={''} />
          </label>
        </div>
        <div className={'flex'}>
          <label className={'flex pl-2'}>
            <span className={'mr-2'}>Use group date</span>{' '}
            <ToggleSwitch
              label={''}
              checked={formData.groupDate}
              onChange={(e) => setFormData({ ...formData, groupDate: e })}
            />
          </label>
        </div>
        <div className={'flex'}>
          <label className={'flex pl-2'}>
            <span className={'mr-2'}>Use group location</span>{' '}
            <ToggleSwitch
              label={''}
              checked={formData.groupLocation}
              onChange={(e) => setFormData({ ...formData, groupLocation: e })}
            />
          </label>
        </div>
      </div>
      {selectedDate && (
        <div className={'title'} style={{ marginTop: '2rem' }}>
          <button
            onClick={() => handleBackToDates()}
            style={{
              display: 'flex',
              alignItems: 'center',
              border: '1px solid #ffffff5e',
              padding: '5px 10px',
              borderRadius: '7px',
            }}
          >
            <VscArrowLeft style={{ marginRight: '7px' }} /> Back to date selection
          </button>
        </div>
      )}
      {selectedDate && (
        <div className={'date'} style={{ marginBottom: '1rem', marginTop: '2rem' }}>
          {new Date(selectedDate).toLocaleDateString(undefined, {
            year: 'numeric',
            month: 'long',
            day: '2-digit',
            weekday: 'long',
          })}
          {selectedHour &&
            '  ' +
              new Date(selectedHour).toLocaleString(undefined, {
                hour: '2-digit',
              }) +
              'h UTC time'}
        </div>
      )}
      {selectedDate && (
        <div className={'grid grid-cols-3'}>
          <Button
            style={{ width: '90%', margin: 3, ...buttonActive('day') }}
            onClick={() => {
              setSelection('day');
              setSelectedHour(undefined);
            }}
          >
            <span>Daily</span>
          </Button>
          <Button
            style={{ width: '90%', margin: 3, ...buttonActive('hour') }}
            onClick={() => {
              setSelection('hour');
            }}
          >
            <span>Hourly</span>
          </Button>
          {selection === 'hour' && (
            <div className={'flex'} style={{ width: '90%', margin: 3 }}>
              <Select
                onChange={(e) => setSelectedHour(e.target.value)}
                value={selectedHour}
                className={'observed-select ml-2 w-full'}
              >
                {availableHours?.map((hour) => (
                  <option key={hour.utcDate} value={hour.utcDate}>
                    {new Date(hour.utcDate).toLocaleString(undefined, { hour: '2-digit' })}
                  </option>
                ))}
              </Select>
            </div>
          )}
        </div>
      )}
      {!selectedDate && <div className={'grid grid-cols-5 gap-4 mt-6 mb-6'}>{renderDays}</div>}

      {dayData && selectedDate && (
        <div className={'grid grid-cols-4 gap-2 mt-6'} key={location.name + bias.toString()}>
          {selection === 'day' && renderDailyForecast(dayData.daily, true)}
          {selection === 'hour' && renderIntraDayForecast()}
        </div>
      )}
    </>
  );
};
