import './styles.scss';

import { cloneDeep } from 'lodash';
import { useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import {
  AiOutlineAudio,
  AiOutlineFileImage,
  AiOutlineUndo,
  AiOutlineVideoCameraAdd,
} from 'react-icons/ai';
import { HiOutlineTemplate } from 'react-icons/hi';
import { MdAlignHorizontalLeft, MdAlignVerticalTop, MdOutlineAnimation } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';

import { C9ProjectDef } from '../../../../model/definitions/C9ProjectDef';
import { RolesEnum } from '../../../../model/enums/RolesEnum';
import { SidebarItemsEnum } from '../../../../model/enums/SidebarItemsEnum';
import { ActiveDef, alignPoster, PosterContent } from '../../../../store/slices/active-slice';
import { RootState } from '../../../../store/store';
import SidebarItem from './SidebarItem';

interface SidebarItemProp {
  title: string;
  icon: JSX.Element;
  tab: SidebarItemsEnum;
  roles: RolesEnum[];
}

interface Props {
  setSidebarOpen: (tab: SidebarItemsEnum, e?: any) => void;
  sidebarItem: string;
}
const SidebarItems = ({ setSidebarOpen, sidebarItem }: Props) => {
  const dispatch = useDispatch();
  const { posterMode, posterContent } = useSelector<RootState, ActiveDef>((state) => state.active);
  const project = useSelector<RootState, C9ProjectDef>((state) => state.project.present.project);
  const [prevContent, setPrevContent] = useState<PosterContent[][]>([]);
  useHotkeys('alt+shift+ctrl+i, option+shift+command+i', (ev) => {
    ev.preventDefault();
    setSidebarOpen(SidebarItemsEnum.IMAGE);
  });
  useHotkeys('alt+shift+ctrl+v, option+shift+command+v', (ev) => {
    ev.preventDefault();
    setSidebarOpen(SidebarItemsEnum.VIDEO);
  });
  useHotkeys('alt+shift+ctrl+a, option+shift+command+a', (ev) => {
    ev.preventDefault();
    setSidebarOpen(SidebarItemsEnum.AUDIO);
  });
  useHotkeys('alt+shift+ctrl+t, option+shift+command+t', (ev) => {
    ev.preventDefault();
    setSidebarOpen(SidebarItemsEnum.TEMPLATE);
  });

  function alignToTop(content: Array<PosterContent>) {
    const array = cloneDeep(content);
    const lowestY = Math.min(...array.map((elem) => elem.positionControl.y));
    array.sort((a, b) => a.positionControl.x - b.positionControl.x);
    for (let i = 0; i < array.length; i++) {
      const elem = array[i];
      let newY = lowestY;
      for (let j = 0; j < i; j++) {
        const otherElem = array[j];
        if (
          elem.positionControl.x + elem.positionControl.w > otherElem.positionControl.x &&
          elem.positionControl.x < otherElem.positionControl.x + otherElem.positionControl.w &&
          newY + elem.positionControl.h > otherElem.positionControl.y
        ) {
          newY = otherElem.positionControl.y + otherElem.positionControl.h;
        }
      }
      elem.positionControl.y = newY;
    }
    if (content.length) setPrevContent((c) => [...c, content]);
    dispatch(alignPoster({ elements: array }));
  }
  function alignToLeft(content: Array<PosterContent>) {
    const array = cloneDeep(content);
    const lowestX = Math.min(...array.map((elem) => elem.positionControl.x));
    array.sort((a, b) => a.positionControl.y - b.positionControl.y);
    for (let i = 0; i < array.length; i++) {
      const elem = array[i];
      let newX = lowestX;
      for (let j = 0; j < i; j++) {
        const otherElem = array[j];
        if (
          elem.positionControl.y + elem.positionControl.h > otherElem.positionControl.y &&
          elem.positionControl.y < otherElem.positionControl.y + otherElem.positionControl.h &&
          newX + elem.positionControl.x > otherElem.positionControl.x
        ) {
          newX = otherElem.positionControl.x + otherElem.positionControl.w;
        }
      }
      elem.positionControl.x = newX;
    }
    if (content.length) setPrevContent((c) => [...c, content]);
    dispatch(alignPoster({ elements: array }));
  }
  function onRedo() {
    const cloned = cloneDeep(prevContent);
    const lastState = cloned.pop();
    if (lastState) {
      dispatch(alignPoster({ elements: lastState }));
    }
    setPrevContent(cloned);
  }
  const sidebarMenu = [
    {
      title: 'Image repository',
      icon: <AiOutlineFileImage />,
      tab: SidebarItemsEnum.IMAGE,
      roles: [RolesEnum.ROLE_CREATOR, RolesEnum.ROLE_MAINTAINER],
    },
    {
      title: 'Video repository',
      icon: <AiOutlineVideoCameraAdd />,
      tab: SidebarItemsEnum.VIDEO,
      roles: [RolesEnum.ROLE_CREATOR, RolesEnum.ROLE_MAINTAINER],
    },
    {
      title: 'Audio repository',
      icon: <AiOutlineAudio />,
      tab: SidebarItemsEnum.AUDIO,
      roles: [RolesEnum.ROLE_CREATOR, RolesEnum.ROLE_MAINTAINER],
    },
    {
      title: 'Animation repository',
      icon: <MdOutlineAnimation />,
      tab: SidebarItemsEnum.ANIMATION,
      roles: [RolesEnum.ROLE_CREATOR, RolesEnum.ROLE_MAINTAINER],
    },
    {
      title: 'Template repository',
      icon: <HiOutlineTemplate />,
      tab: SidebarItemsEnum.TEMPLATE,
      roles: [RolesEnum.ROLE_CREATOR, RolesEnum.ROLE_FORECASTER],
    },
  ];
  const renderItems = () =>
    sidebarMenu.map((item: SidebarItemProp, index: number) => (
      <SidebarItem
        key={item.tab}
        title={item.title}
        icon={item.icon}
        tab={item.tab}
        roles={item.roles}
        isSharedEntity={project.isSharedEntity}
        inEditMode={project.inEditMode}
        sidebarItem={sidebarItem}
        setSidebarOpen={setSidebarOpen}
      />
    ));
  const renderPosterControl = () => {
    return (
      <div className={'alignment-buttons'}>
        <button onClick={() => alignToTop(posterContent)}>
          <MdAlignVerticalTop />
        </button>
        <button onClick={() => alignToLeft(posterContent)}>
          <MdAlignHorizontalLeft />
        </button>
        <button disabled={!prevContent.length} onClick={onRedo}>
          <AiOutlineUndo />
        </button>
      </div>
    );
  };

  return <div className="items">{posterMode ? renderPosterControl() : renderItems()}</div>;
};

export default SidebarItems;
