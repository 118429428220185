import './style.scss';

import { useKeycloak } from '@react-keycloak/web';
import { FC } from 'react';
import GitInfo from 'react-git-info/macro';
import { IconBaseProps } from 'react-icons';
import { FiSettings } from 'react-icons/fi';

import version from '../../../../package.json';
import { Logo } from '../../../assets/images';
import { RolesEnum } from '../../../model/enums/RolesEnum';
import { menu } from '../config-NEW';
import { adminMenu, dataProviderMenu } from '../config-NEW/menu';
import SidebarItem from './components/SidebarItem';

interface MenuItem {
  title: string;
  icon?: FC<IconBaseProps>;
  sub?: Array<MenuItem>;
  route?: string;
  roles?: RolesEnum[];
}
const DashboardSidebar = () => {
  const { keycloak } = useKeycloak();
  const renderItems = () => {
    return keycloak.hasRealmRole(RolesEnum.ROLE_ADMIN)
      ? adminMenu.map((menuItem: MenuItem) => {
          return (
            <SidebarItem
              title={menuItem.title}
              icon={menuItem.icon}
              key={menuItem.title}
              sub={menuItem.sub}
              route={menuItem.route}
            />
          );
        })
      : keycloak.hasRealmRole(RolesEnum.ROLE_DATA_PROVIDER)
      ? dataProviderMenu.map((menuItem: MenuItem) => (
          <SidebarItem
            title={menuItem.title}
            icon={menuItem.icon}
            key={menuItem.title}
            route={menuItem.route}
          />
        ))
      : menu.map((menuItem: MenuItem) => {
          if (!menuItem.roles || menuItem.roles.some((role) => keycloak.hasRealmRole(role))) {
            return (
              <SidebarItem
                title={menuItem.title}
                icon={menuItem.icon}
                key={menuItem.title}
                sub={menuItem.sub}
                route={menuItem.route}
              />
            );
          }
        });
  };
  const gitInfo = GitInfo();
  return (
    <div className={'sidebar-wrapper'}>
      <div className={'sidebar-logo'}>
        <img src={Logo} width={200} alt={'GeoStream'} />
        <h2 className={'ws-header-title'}>Workspace</h2>
      </div>
      <div className={'sidebar-content'}>
        <ul className={'sidebar-main'}>{renderItems()}</ul>
        <div className={'sidebar-bottom'}>
          <ul className={'sidebar-help'}>
            <SidebarItem
              title={'Settings'}
              subtitle={
                version.version +
                ' - ' +
                new Date(gitInfo.commit.date).toLocaleDateString(undefined, {
                  day: '2-digit',
                  month: 'short',
                  year: 'numeric',
                })
              }
              icon={FiSettings}
            />
          </ul>
        </div>
      </div>
    </div>
  );
};

export default DashboardSidebar;
