import './style.scss';

import { Tooltip } from 'antd';
import { format } from 'date-fns';
import { Badge } from 'flowbite-react';
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { BsTrash } from 'react-icons/bs';
import { GiCombinationLock } from 'react-icons/gi';
import { HiInformationCircle } from 'react-icons/hi';

import loadFont from '../../../core/api/useLoadFont';
import formatFileSize from '../../../helpers/formatFileSize';
import { C9ProjectPreviewDTO } from '../../../model/DTO/C9ProjectPreviewDTO';
import { RolesEnum } from '../../../model/enums/RolesEnum';
import { AnimationRetryRequest } from '../../../model/other/AnimationRetryRequest';
import ThumbnailSlider from '../../marketplace/ThumbnailSlider';
import { FontVariantInterface } from '../../playground/properties/panels/FontProperties';
import SpeedDial from '../components/speed-dial/SpeedDial';
import EditInfoModal from '../modals/EditInfoModal';
import FontVariantsModal from '../modals/FontVariantsModal';
import { useConfirmation } from '../modals/useConfirmation';
import { useGetTransferInfo } from '../queries-NEW/useGetTransferInfo';
import { hasDuplicateFieldValue } from '../templates-NEW/Templates';

interface CardProps {
  item: any;
  id: string;
  search?: string;
  onDelete?: (id: string, versionId: string) => void;
  onPublish?: (id: string) => void;
  onRetry?: (data: AnimationRetryRequest) => void;
  onClone?: (id: string, versionId: string) => void;
  onShare?: (id: string, type: string, replaceExisting: boolean) => void;
  link: (id: string) => void;
  detailsLink?: (id: string, data: C9ProjectPreviewDTO) => void;
  type?: string;
  onEdit?: (data: {
    newName: string;
    newDescription: string;
    newIsDefault?: boolean;
    newSymbolType?: string;
    id: string;
    versionId: string;
  }) => void;
  onDefault?: (data: {
    newName: string;
    newDescription: string;
    newIsDefault: boolean;
    id: string;
    versionId: string;
  }) => void;
  onGetTemplate?: (id: string) => void;
  path?: boolean;
  fontWarningId?: string;
  setFontWarningId?: (id: string) => void;
  userRoles: RolesEnum[];
  onUnlock?: (id: string) => void;
}

const Card = ({
  item,
  onEdit,
  onClone,
  onShare,
  onDelete,
  onPublish,
  onRetry,
  onGetTemplate,
  onDefault,
  search,
  link,
  detailsLink,
  type,
  path,
  fontWarningId,
  setFontWarningId,
  userRoles,
  onUnlock,
}: CardProps) => {
  const {
    id,
    thumbnailURLs,
    thumbnailUrls,
    name,
    isDefault,
    description,
    symbolType,
    creationDate = 0,
    versionId,
    disabled,
    isSharedEntity,
    disableReason,
    sizeInMB,
    pngSequenceStorageId,
    sharedDate,
    sharedBy,
    enterpriseAccountId,
    inEditMode,
    editor,
  } = item;
  const { confirm } = useConfirmation();
  const ref = useRef<HTMLDivElement>(null);
  const [isTooltip, setTooltip] = useState<boolean>(false);
  const [exists, setExists] = useState<boolean>(false);
  const [editModal, showEditModal] = useState<boolean>(false);
  const [fontVariantModal, setFontVariantModal] = useState<boolean>(fontWarningId === item?.id);
  const [fontVariant, setFontVariant] = useState<string>();
  const { data: proxyProgress } = useGetTransferInfo({
    id: item.proxyVideoId,
    disabled: item.disabled,
  });
  const hasDuplicates = type === 'font' && hasDuplicateFieldValue(item?.fontVariants);
  const admin = userRoles.includes(RolesEnum.ROLE_SUPER_ADMIN);
  // Ugly fix to not have to rewrite all templates code
  const thumbUrls = thumbnailURLs || thumbnailUrls;
  const onIn = useCallback(() => setTooltip(true), []);
  const onOut = useCallback(() => setTooltip(false), []);
  const closeEditModal = useCallback(() => showEditModal(false), []);
  const closeFontVariantModal = useCallback(() => {
    setFontVariantModal(false);
    setFontWarningId && setFontWarningId('');
  }, []);
  const edit = React.useMemo(
    () =>
      (param: {
        newName: string;
        newDescription: string;
        newIsDefault?: boolean;
        newSymbolType?: string;
      }) => {
        onEdit && onEdit({ ...param, id: id, versionId: versionId });
        showEditModal(false);
      },
    [id, onEdit, versionId],
  );
  const defaultIconPack = () => {
    onDefault &&
      onDefault({
        newName: name as string,
        newDescription: description as string,
        newIsDefault: !isDefault as boolean,
        id: id as string,
        versionId: versionId as string,
      });
  };
  useLayoutEffect(() => {
    if (ref.current && ref.current?.clientWidth < ref.current?.scrollWidth) {
      setExists(true);
    }
  }, [ref]);
  const openEditModal = () => showEditModal(true);

  const onSpeedDialClick = useCallback(
    () => detailsLink && detailsLink(id, item),
    [id, item, detailsLink],
  );

  const openProject = useCallback(() => link(id), [id, link]);

  useEffect(() => {
    if (type === 'font') {
      loadFont(item?.fontVariants, item?.name);
      setFontVariant(item?.fontVariants[0]?.type);
    }
  }, []);

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedFontType = event.target.value;
    setFontVariant(selectedFontType);
  };

  const titleHTML = name;

  const descriptionHTML = description
    ? description
    : '<span class="no-data">No description available</span>';

  const renderDate = (name: string, date: number) => {
    if (!date) return;
    return (
      <div className={'ws-card-date'}>
        {name}: <span>{format(new Date(date), 'dd.MM.yyyy HH:mm')}</span>
      </div>
    );
  };
  const renderShared = () => {
    return (
      <>
        <div className={'ws-card-date'}>
          Shared by: <span>{sharedBy}</span>
        </div>
        <div className={'ws-card-date'}>
          Shared date: <span>{format(new Date(sharedDate), 'dd.MM.yyyy HH:mm')}</span>
        </div>
      </>
    );
  };
  const deleteItem = () => {
    confirm({
      message: `You are about to delete item ${name}`,
      onConfirm: () => onDelete && onDelete(id, versionId),
    });
  };

  const pngSequenceNotCreated: boolean =
    type === 'animation' && item.jsonAnimation && !item.pngSequenceCreated;

  return (
    <Tooltip placement="bottom" title={disableReason}>
      <div className={`ws-card ${disabled && 'disabled-project'}`}>
        {isTooltip && exists && (
          <div className={'ws-card-tooltip'} style={{ width: '100%' }}>
            {name}
          </div>
        )}
        {!path && (
          <SpeedDial
            disabled={disabled}
            isSharedEntity={isSharedEntity}
            disableReason={disableReason}
            title={name}
            isDefault={isDefault}
            onEdit={onEdit && openEditModal}
            id={id}
            onClick={onSpeedDialClick}
            type={type}
            versionId={versionId}
            pngSequenceStorageId={pngSequenceStorageId}
            pngSequenceNotCreated={pngSequenceNotCreated}
            admin={admin}
            onClone={onClone}
            onShare={onShare}
            onDelete={onDelete}
            onGetTemplate={onGetTemplate}
            onPublish={onPublish}
            onDefault={defaultIconPack}
            enterpriseAccountId={enterpriseAccountId}
            onRetry={onRetry}
            inEditMode={inEditMode}
            onUnlock={onUnlock}
          />
        )}
        {type === 'video' && disabled && (
          <span
            onClick={deleteItem}
            className="absolute top-[10px] right-[10px] bg-white rounded-full w-[38px] h-[38px] z-10 text-gray-400 flex items-center justify-center gap-2 cursor-pointer"
          >
            <BsTrash fill="red" size={17} className={'sd-icon'} />
          </span>
        )}
        {path && (
          <span
            onClick={deleteItem}
            className="absolute top-[10px] right-[10px] bg-white rounded-full w-[38px] h-[38px] z-10 text-gray-400 flex items-center justify-center gap-2 cursor-pointer"
          >
            <BsTrash fill="red" size={17} className={'sd-icon'} />
          </span>
        )}
        {type !== 'font' && (
          <ThumbnailSlider
            thumbnails={thumbUrls}
            isDefault={type === 'icon-set' && isDefault}
            {...(!disabled && { onClick: openProject })}
          />
        )}
        {pngSequenceNotCreated && (
          <Badge
            style={{
              position: 'absolute',
              zIndex: 1,
              top: 10,
              left: 10,
              padding: '2px 5px',
              width: 'fit-content',
              fontSize: '10px',
            }}
            color={'failure'}
            size="sm"
          >
            PNG Sequence Not Created
          </Badge>
        )}
        {inEditMode && (
          <div className="absolute top-2.5 left-2.5">
            <Badge color="failure">
              <div className="flex items-center gap-2 group">
                <GiCombinationLock className="text-lg" />{' '}
                <span className="hidden group-hover:block">{editor}</span>
              </div>
            </Badge>
          </div>
        )}

        {type === 'font' ? (
          <div className="font-card-wrapper">
            {hasDuplicates && (
              <Badge
                style={{ padding: '5px 10px', width: 'fit-content', marginBottom: 4 }}
                color="warning"
                size="sm"
                icon={HiInformationCircle}
              >
                Multiple same font types
              </Badge>
            )}
            <div className="title-wrapper">
              <h2
                title={name}
                className={`ws-card-title`}
                onMouseEnter={onIn}
                onMouseLeave={onOut}
                ref={ref}
                dangerouslySetInnerHTML={{
                  __html: titleHTML,
                }}
              />
              <p
                className={'ws-card-desc'}
                dangerouslySetInnerHTML={{
                  __html: descriptionHTML,
                }}
              />
            </div>
            <select
              id="fontSelect"
              onChange={(e) => handleChange(e)}
              defaultValue={item?.fontVariants[0]?.type}
            >
              {item?.fontVariants.map((fontVariant: FontVariantInterface, index: string) => (
                <option key={index} value={fontVariant.type}>
                  {fontVariant.type}
                </option>
              ))}
            </select>
            <button
              className={`${
                hasDuplicates && 'bg-yellow-100 text-yellow-700 border-none font-semibold'
              } block my-1 text-gray-700 border border-gray-700 rounded-xl px-3 py-[6px] text-sm`}
              onClick={() => setFontVariantModal(true)}
            >
              Edit Variants
            </button>
            <div className="font-snippet" style={{ fontFamily: item.name + ' ' + fontVariant }}>
              The quick brown fox jumps over the lazy dog 1234567890 !@#$%^&*,./? and the quick
              brown fox jumps over the lazy dog again.
            </div>
            <div className="date-wrapper">
              {renderDate('Date created', item.createdAt)}
              <span>{item?.format}</span>
            </div>
          </div>
        ) : (
          <div className={'ws-card-content'}>
            <div
              title={name}
              className="ws-card-title"
              {...(!disabled && { onClick: openProject })}
              onMouseEnter={onIn}
              onMouseLeave={onOut}
              ref={ref}
              dangerouslySetInnerHTML={{
                __html: titleHTML,
              }}
            />
            <div
              className={'ws-card-desc'}
              dangerouslySetInnerHTML={{
                __html: descriptionHTML,
              }}
            />
            <div className={'ws-card-date'}>
              {sizeInMB && (
                <>
                  Size: <span>{formatFileSize(sizeInMB * 1000 * 1000)}</span>
                </>
              )}
            </div>
            {renderDate('Date created', creationDate)}
            {renderDate('Modified date', item.lastActivityDateTime)}
            {isSharedEntity && renderShared()}

            {disabled && proxyProgress ? (
              <div className="text-red-500">Uploading: {proxyProgress.toFixed(2)}%</div>
            ) : (
              <></>
            )}
          </div>
        )}
        <EditInfoModal
          opened={editModal}
          onClose={closeEditModal}
          onEdit={edit}
          name={name}
          description={description}
          isDefault={isDefault}
          symbolType={symbolType}
          type={type}
        />
        {type === 'font' && fontVariantModal && (
          <FontVariantsModal
            fontId={item?.id}
            name={name}
            fontVariants={item?.fontVariants}
            open={fontVariantModal}
            hasDuplicates={hasDuplicates}
            fontWarningId={fontWarningId}
            onClose={closeFontVariantModal}
          />
        )}
      </div>
    </Tooltip>
  );
};

export default Card;
