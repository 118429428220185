import { useQuery } from 'react-query';

import { axiosInstance } from '../../../core/api/axiosInstance';

export const key = ['SCHEDULER_DEFINITIONS'];
export const useGetSchedulerDefinitions = (pagination: { page: number; size: number }) => {
  return useQuery([...key, pagination], async () => {
    const url = `/scheduler/jobs-by-user?page=${pagination.page - 1}&size=${pagination.size}`;
    const res = await axiosInstance.get(url);
    return res.data;
  });
};
