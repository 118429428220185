import { Tooltip } from 'flowbite-react';
import { HTMLAttributes } from 'react';
import { BsFillSquareFill } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';

import { KeyFramesDef } from '../../../model/definitions/KeyFramesDef';
import { ActiveDef } from '../../../store/slices/active-slice';
import { removeKeyFrame } from '../../../store/slices/project-slice';
import { RootState } from '../../../store/store';
import style from './style.module.scss';

interface FlyOverLaneProps extends HTMLAttributes<HTMLDivElement> {
  length: number;
  start: number;
  duration: number;
  keyFrames?: KeyFramesDef[];
}

const FlyOverLane = ({ length, keyFrames, start, duration, ...props }: FlyOverLaneProps) => {
  const dispatch = useDispatch();
  const active = useSelector<RootState>((state) => state.active) as ActiveDef;

  const absoluteToPercent = (time: number, duration: number) => {
    return (time * 100) / duration;
  };
  const removeKey = (activeScene: string, elementId: string | number, keyFrame: number) => {
    dispatch(removeKeyFrame({ activeScene, elementId, keyFrame }));
  };

  const renderKeys = () => {
    return keyFrames?.map((keyFrame, index) => (
      <Tooltip
        key={index}
        content={`Time:${keyFrame?.timeInMS}ms 
         
            Transition type:${keyFrame?.transitionType}
             
           Transition tilt:${keyFrame?.transitionTilt}
            
           Latitude:${keyFrame?.mapPositionControl?.latitude?.toFixed(4)}
            
           Longitude:${keyFrame?.mapPositionControl?.longitude?.toFixed(4)}
           
           Zoom:${keyFrame?.mapPositionControl?.zoom?.toFixed(4)}
           
           Pitch:${keyFrame?.mapPositionControl?.pitch}
           
           Bearing:${keyFrame?.mapPositionControl?.bearing}`}
      >
        <div
          className={style.keyframe}
          key={`keyframe_${index}`}
          style={{
            left: absoluteToPercent(keyFrame.timeInMS, duration) + '%',
          }}
          onClick={() =>
            keyFrame.timeInMS > 0 &&
            index > 0 &&
            removeKey(active.activeScene as string, active.activeElement, index)
          }
          data-for={index.toString()}
        >
          <BsFillSquareFill
            style={{
              transform: 'rotate(45deg)',
              color: '#1ad598',
              fontSize: 6,
            }}
          />
        </div>
      </Tooltip>
    ));
  };
  return (
    <div {...props} id={'Lane-flyover'}>
      <div
        className={style.bar}
        style={{
          left: absoluteToPercent(start, duration) + '%',
          width: absoluteToPercent(length - start, duration) + '%',
          position: 'relative',
        }}
      />
      {renderKeys()}
    </div>
  );
};

export default FlyOverLane;
