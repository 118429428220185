import { v4 } from 'uuid';

import { MapLayersEnum } from '../enums/MapLayersEnum';
import { MapCityElement } from './MapCityElement';
import { MultimediaTemplateDef } from './MultimediaTemplateDef';
import { TimeControlDef } from './TimeControlDef';

export class CustomVectorDefTemplate extends MultimediaTemplateDef {
  customVectorFileInitiated = false;
}
export class CustomVectorLayer {
  id = v4();
  name: string;
  baseMapUrl: string;
  type: MapLayersEnum;
  style = '{"strokeColor":"#2b1606","strokeWidth":1,"zindex":1}';
  minZoom = 0;
  maxZoom = 22;
  createdBy: string;
  creationTime = new Date().getTime();
  zindex: number;
  timeControls: TimeControlDef[];
  enabled = true;
  cities: MapCityElement[] = [];
  customVectorDef: CustomVectorDefTemplate;
  constructor(layer: any) {
    this.baseMapUrl = `${process.env.REACT_APP_BASE_MAP_URL}/mvtiles/v2/{z}/{x}/{y}.mvt?customVectorGuid=${layer.versionId}`;
    this.name = layer.name;
    this.customVectorDef = layer;
  }
}
/*  */
