import { InputNumber, Radio, Space } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import Button from '../../../atoms/button/Button';
import { useAssembleVideo } from '../../../core/api/assembly/useAssembleVideo';
import { ElementsEnum } from '../../../core/ui/enums/ElementsEnum';
import { ModeEnum } from '../../../core/ui/enums/ModeEnum';
import { GAMMA_CORRECTION, SATURATION } from '../../../model/constants/constants';
import { C9ProjectDef } from '../../../model/definitions/C9ProjectDef';
import { ImageTypeEnum } from '../../../model/enums/ImageTypeEnum';
import {
  RenderCompressionEnum,
  RenderCompressionNamesEnum,
} from '../../../model/enums/RenderCompressionEnum';
import Modal from '../../../molecules/modal/Modal';
import { ActiveDef } from '../../../store/slices/active-slice';
import { updateProject } from '../../../store/slices/project-slice';
import { RootState } from '../../../store/store';
import PlayerContext from '../playerContext/PlayerContext';

interface Props {
  opened: boolean;
  renderType?: ElementsEnum;
  onClose: () => void;
}

const SendAssemblyModal: React.FC<Props> = ({ opened, renderType, onClose }) => {
  const { activeScene, mode } = useSelector<RootState, ActiveDef>((state) => state.active);
  const { time } = useContext(PlayerContext);
  const [taskSent, setTaskSent] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { mutate: assemble, isLoading } = useAssembleVideo();
  const isVideo = renderType === ElementsEnum.VIDEO;
  /* const { mutate } = useMutation(sendAssemblyTask, {
    onSuccess: (data) => {
      if (data?.requestSentToQueue) {
        setTaskSent(true);
        queryClient.invalidateQueries('render-status-by-project');
      } else {
        setError(true);
      }
      setTimeout(onClose, 3000);
    },
    onError: (e) => {
      setError(true);
    },
  }); */
  const project = useSelector<RootState, C9ProjectDef>((state) => state.project.present.project);

  function onUpdateProject(newValue: any, propertyPath: Leaves<C9ProjectDef>) {
    dispatch(updateProject({ newValue, propertyPath }));
  }

  function onCommpressionChange(newValue: string) {
    dispatch(updateProject({ newValue, propertyPath: 'properties.renderCompression' }));
    dispatch(updateProject({ newValue, propertyPath: 'outputVideoCompression' }));
  }

  function onAssemble() {
    assemble(
      {
        type: mode === ModeEnum.PROJECT ? 'project' : 'scene',
        projectDef: {
          ...project,
          //@ts-ignore
          properties: {
            baseAppUrl: project.properties.baseAppUrl,
            timezone: project.properties.timezone,
            ...(!isVideo && { thumbnailToCapture: time, imageType: project.properties.imageType }),
            ...(isVideo && { renderCompression: project.properties.renderCompression }),
          },
        },
        sceneDefId: mode === ModeEnum.SEQUENCE ? activeScene : null,
        isImage: !isVideo,
      },
      {
        onSuccess: (data) => {
          if (data.requestSentToQueue) {
            setTaskSent(true);
            queryClient.invalidateQueries('render-status-by-project');
          } else {
            setError(true);
          }
          setTimeout(onClose, 3000);
        },
        onError: (error: any) => {
          if (error.request.status !== 403) {
            toast.error('Error video rendering');
          }
        },
      },
    );
  }

  useEffect(() => {
    if (project.properties.renderCompression !== project.outputVideoCompression) {
      onUpdateProject(project.outputVideoCompression, 'properties.renderCompression');
    }
  }, [isVideo, project.properties.renderCompression, time]);

  return (
    <Modal
      isOpen={opened}
      onClose={onClose}
      bodyClassName={'SendAssemblyModal__modal-body'}
      className={'SendAssemblyModal__modal-wrap'}
      header={`Create ${isVideo ? 'video' : 'image'}`}
      footer={
        <div className={'modal-footer flex'}>
          <Button
            label={'Create'}
            buttonType="primary"
            loading={isLoading}
            onClick={onAssemble}
            disabled={isLoading || taskSent}
          />
          <Button label={'Cancel'} buttonType="secondary" onClick={onClose} />
        </div>
      }
    >
      {taskSent ? (
        <div>Creation of a {isVideo ? 'video' : 'image'} initiated...</div>
      ) : error ? (
        <div style={{ color: 'red' }}>An error occurred, please try again later...</div>
      ) : (
        <div className="text-left">
          {isVideo ? (
            <>
              <p className="pb-2">Select compression:</p>
              <Radio.Group
                style={{ paddingBottom: 0 }}
                // onChange={(e) => onUpdateProject(e.target.value, 'properties.renderCompression')}
                onChange={(e) => onCommpressionChange(e.target.value)}
                value={project.properties.renderCompression}
              >
                <Space direction="vertical">
                  {Object.values(RenderCompressionEnum).map((f) => (
                    <Radio key={f} value={f}>
                      {RenderCompressionNamesEnum[RenderCompressionEnum[f]]}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
            </>
          ) : (
            <>
              <p className="pb-2">Select image type:</p>
              <Radio.Group
                style={{ paddingBottom: 0 }}
                onChange={(e) => onUpdateProject(e.target.value, 'properties.imageType')}
                value={project.properties.imageType}
              >
                <Space direction="vertical">
                  {Object.entries(ImageTypeEnum).map(([key, value]) => (
                    <Radio key={key} value={key}>
                      {value}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
            </>
          )}

          <div className="mt-2 flex gap-4">
            <div>
              <p className="pb-2">Gamma</p>
              <InputNumber
                value={
                  project?.renderQualityDef?.gammaCorrection
                    ? project.renderQualityDef?.gammaCorrection
                    : GAMMA_CORRECTION
                }
                onChange={(e) => onUpdateProject(e, 'renderQualityDef.gammaCorrection')}
                step={0.2}
                min={0}
                max={2}
              />
            </div>
            <div>
              <p className="pb-2">Saturation</p>
              <InputNumber
                value={
                  project?.renderQualityDef?.saturation
                    ? project.renderQualityDef?.saturation
                    : SATURATION
                }
                onChange={(e) => onUpdateProject(e, 'renderQualityDef.saturation')}
                step={0.2}
                min={0}
                max={2}
              />
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default SendAssemblyModal;
