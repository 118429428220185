import { QueryKey, useQuery } from 'react-query';

import { axiosInstance } from '../../../../core/api/axiosInstance';
import ITransaction from '../../../payment/types/ITransaction.type';

export const key: QueryKey = ['PAYMENT_TRANSACTIONS'];

export const useGetTransactions = () => {
  return useQuery([...key], async () => {
    const response = await axiosInstance.get<ITransaction[]>(`payment/transaction/user`);
    return response.data;
  });
};
