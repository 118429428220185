import './style.scss';

import { ExclamationCircleFilled } from '@ant-design/icons';
import { Button as AntdButton, Modal, Space, Table } from 'antd';
import { useContext, useEffect, useRef, useState } from 'react';
import { AiOutlinePlus, AiOutlineSearch } from 'react-icons/ai';
import { Link } from 'react-router-dom';

import Button from '../../../atoms/button/Button';
import { useDebounce } from '../../../hooks/useDebounce';
import { TranslationFile } from '../../../model/definitions/TranslationFileDef';
import { SortingLangageEnum } from '../../../model/enums/SortingEnum';
import Sorting from '../../marketplace-new/molecules/Sorting/Sorting';
import { useDeleteTranslation } from '../queries-NEW/useDeleteTranslation';
import { useGetTranslations } from '../queries-NEW/useGetTranslations';
import SortContext from './sortContext';

const { confirm } = Modal;

interface LanguageInterface {
  id: string;
  key: string;
  name: string;
  dialect: string;
  description: string;
  translationFile: TranslationFile;
}

function Dictionary() {
  const [page, setPage] = useState<number>(0);
  const [search, setSearch] = useState<string>('');
  const { sortAscDesc, sortByField, handleSort } = useContext(SortContext);
  const [sizeChanger, setSizeChanger] = useState<number>(20);
  const searchKey = useDebounce(search, 300);

  const {
    data: translations,
    isLoading,
    refetch,
  } = useGetTranslations(page, sizeChanger, sortByField + ',' + sortAscDesc, searchKey);
  const { mutate: deleteTranslation } = useDeleteTranslation();
  const searchRef = useRef<HTMLInputElement>(null);

  const handleDelete = (id: string) => {
    confirm({
      title: 'Delete translation',
      icon: <ExclamationCircleFilled />,
      content: 'Are you sure you want to delete this translation?',
      centered: true,
      okText: 'Delete',
      okType: 'danger',
      onOk() {
        deleteTranslation(id);
      },
    });
  };

  useEffect(() => {
    if (page !== 0) {
      const timer = setTimeout(() => {
        setPage(0);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [search]);

  useEffect(() => {
    refetch();
  }, [page, searchKey, sortAscDesc, sortByField, sizeChanger, refetch]);

  const columns: {
    title: string;
    dataIndex: string;
    key: string;
    render?: (_: any, product: LanguageInterface) => JSX.Element;
  }[] = [
    {
      title: 'Key',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Dialect',
      dataIndex: 'dialect',
      key: 'dialect',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (_: any, product: LanguageInterface) => {
        return (
          <Space size="middle">
            <Link to={`./edit-translation/${product.id}`}>
              <AntdButton type="primary" shape="round">
                Edit
              </AntdButton>
            </Link>
            <AntdButton danger shape="round" onClick={() => product.id && handleDelete(product.id)}>
              Delete
            </AntdButton>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <div className={'flex ws-fixed-header'}>
        <div className={'ws-temp-title'}>
          <h1>Dictionary</h1>
        </div>
        <Link to="./add-translation">
          <Button className={`ws-button`} label={`Add translation`} icon={<AiOutlinePlus />} />
        </Link>
        <div className={'ws-input'}>
          <input
            ref={searchRef}
            value={search}
            autoFocus
            onChange={(e) => setSearch && setSearch(e.target.value)}
            placeholder={'Type here...'}
            className="w-full 2xl:!w-[300px]"
          />
          <AiOutlineSearch color={'rgba(0,0,0,0.3)'} size={24} />
        </div>
        {translations?.content && (
          <div className="ml-auto">
            <Sorting
              sortAscDesc={sortAscDesc}
              sorting={SortingLangageEnum}
              handleSort={handleSort}
              sortByField={sortByField}
            />
          </div>
        )}
      </div>
      <Table
        rowKey="id"
        dataSource={translations?.content}
        //@ts-ignore
        columns={columns}
        loading={isLoading}
        pagination={{
          defaultPageSize: sizeChanger,
          showSizeChanger: true,
          pageSizeOptions: [10, 20, 50, 100],
          total: translations?.totalElements,
          onShowSizeChange(current: number, pageSize: number) {
            setSizeChanger(pageSize);
            setPage(0);
          },
          onChange(page) {
            setPage(page - 1);
          },
        }}
      />
    </>
  );
}

export default Dictionary;
