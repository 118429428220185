import { C9Object } from './C9Object';
import { PickerDef } from './PickerDef';
import { WDLocation } from './WDLocation';

export class RadarSourceDef extends C9Object {
  dataProductId: string | null;
  radarSource: PickerDef;
  location: WDLocation;
  datetime: number;
  timeFrameFrom: number;
  timeFrameTo: number;
}
