import './style.scss';

import { useAudioRecorder } from '@sarafhbk/react-audio-recorder';
import { Button } from 'flowbite-react';
import { nanoid } from 'nanoid';
import React, { FormEvent, useEffect, useRef, useState } from 'react';
import { BsFillPauseFill, BsFillRecordFill, BsFillStopFill } from 'react-icons/bs';
import { useDispatch } from 'react-redux';
import { v4 } from 'uuid';

import Input from '../../../atoms/input/Input';
import TextArea from '../../../atoms/input/Textarea';
import { axiosInstance } from '../../../core/api/axiosInstance';
import { AudioElement } from '../../../model/definitions/AudioElement';
import { AudioElementTemplate } from '../../../model/definitions/AudioElementTemplate';
import Modal from '../../../molecules/modal/Modal';
import { addAudioLayer } from '../../../store/slices/project-slice';
import { store } from '../../../store/store';
import VideoRecorder from './VideoRecorder';

interface RecordModalProps {
  opened: boolean;
  onClose: () => void;
}
const baseUrl = process.env.REACT_APP_API_BASE_URL;
const RecordModal = ({ opened, onClose }: RecordModalProps) => {
  const audioPlayerRef = useRef<HTMLAudioElement>(null);
  const dispatch = useDispatch();
  const {
    audioResult,
    timer,
    startRecording,
    stopRecording,
    pauseRecording,
    resumeRecording,
    status,
    errorMessage,
  } = useAudioRecorder();
  const [save, setSave] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [formValues, setFormValues] = useState({
    name: 'Video recording-' + nanoid(10),
    description: '',
  });

  const [recordedChunks, setRecordedChunks] = useState<any>([]);
  const [url, setUrl] = useState<string>();
  const [recordType, setRecordType] = useState<'AUDIO' | 'VIDEO'>('VIDEO');
  useEffect(() => {
    setFormValues({
      ...formValues,
      name:
        recordType === 'VIDEO' ? 'Video recording-' + nanoid(6) : 'Audio recording-' + nanoid(6),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordType]);
  const editInput = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
  ) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };
  const addAudioPanel = async (media: AudioElementTemplate) => {
    const layer = new AudioElement();
    layer.audioElementTemplate = media;
    layer.id = v4();
    layer.name = media.name;
    layer.timeControls[0].endMS = media.durationInMS;

    dispatch(
      addAudioLayer({
        audioLayer: layer,
        activeScene: store.getState().active.activeScene,
      }),
    );
    onClose();
  };
  useEffect(() => {
    const audio = audioPlayerRef.current;
    if (audio)
      audio.addEventListener('loadedmetadata', () => {
        if (audio.duration === Infinity) {
          audio.currentTime = 1e101;
          audio.addEventListener('timeupdate', () => console.log(audio.duration));
        }
      });
  });
  const handleAudioUpload = async () => {
    setLoading(true);
    const data = new FormData();
    const blob = await fetch(audioResult).then((r) => r.blob());
    const file = new File([blob], 'audio', {
      type: 'audio/mpeg',
      endings: 'transparent',
    });

    data.append('file', file);
    data.append('type', 'AUDIO');
    data.append('description', formValues.description ? formValues.description : '');
    data.append('name', formValues.name ? formValues.name : '');
    try {
      const addedFile = await axiosInstance.post(
        `${baseUrl}/api/store/multimedia/uploadFile`,
        data,
        {
          headers: { 'content-type': 'multipart/form-data' },
        },
      );
      const file = addedFile.data as unknown as AudioElementTemplate;
      addAudioPanel({
        ...file,
        durationInMS: (audioPlayerRef.current?.duration ?? 0) * 1000,
      });
      setLoading(false);
      onClose();
      return file;
    } catch (e) {
      console.log(e);
      throw e;
    } finally {
      setLoading(false);
    }
  };
  const handleAdd = () => setSave(true);
  const addRecording = () => {
    handleAudioUpload();
  };
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    addRecording();
  };
  return (
    <Modal
      isOpen={opened}
      onClose={() => {
        url && window.URL.revokeObjectURL(url);
        onClose();
      }}
      header={'Media recorder'}
      className="record-modal"
    >
      <div className="flex flex-col">
        <div className={'flex justify-center items-center record-video-actions'}>
          <Button
            onClick={() => {
              stopRecording();
              setRecordType('VIDEO');
            }}
            color="light"
            style={{
              borderBottomRightRadius: 0,
              borderTopRightRadius: 0,
              marginRight: 0,
              borderRight: '1px solid white',
            }}
          >
            Video
          </Button>
          <Button
            onClick={() => setRecordType('AUDIO')}
            color="light"
            style={{
              borderBottomLeftRadius: 0,
              borderTopLeftRadius: 0,
              marginLeft: 0,
            }}
          >
            Audio
          </Button>
        </div>
        {recordType === 'VIDEO' && (
          <VideoRecorder
            onClose={onClose}
            save={save}
            loading={loading}
            handleAdd={handleAdd}
            formValues={formValues}
            editInput={editInput}
            recordedChunks={recordedChunks}
            setRecordedChunks={setRecordedChunks}
            changeUrl={setUrl}
          />
        )}
        {recordType === 'AUDIO' && (
          <div
            style={{
              minWidth: 640,
              minHeight: 480,
            }}
            className={'flex justify-center flex-col items-center relative  pt-10 audio-wrapper'}
          >
            <audio controls src={audioResult} ref={audioPlayerRef} />
            <p className={'my-10'}>
              Status : <b>{status}</b>
            </p>
            {errorMessage && (
              <p>
                Error Message : <b>{errorMessage}</b>
              </p>
            )}
            <div className={'audio-btn'}>
              <p className={'text-center'}>{new Date(timer * 1000).toISOString().substr(11, 8)}</p>
              <div className={'flex'}>
                {status !== 'idle' && (
                  <div className={'recorder-button'}>
                    <BsFillStopFill size={48} onClick={stopRecording} />
                  </div>
                )}
                {status !== 'recording' && status !== 'paused' && (
                  <div className={'recorder-button'}>
                    <BsFillRecordFill size={48} onClick={startRecording} color={'red'} />
                  </div>
                )}
                {status !== 'paused' && status !== 'idle' && (
                  <div className={'recorder-button'}>
                    <BsFillPauseFill size={48} onClick={pauseRecording} />
                  </div>
                )}
                {status === 'paused' && (
                  <div className={'recorder-button'} onClick={resumeRecording}>
                    <div>Resume</div>
                  </div>
                )}
              </div>
            </div>
            {audioResult && (
              <form onSubmit={handleSubmit} className={'form-body w-full'}>
                <Input
                  name={'name'}
                  label={'Name'}
                  required
                  value={formValues.name}
                  onChange={(e) => editInput(e)}
                  type={'text'}
                />
                <TextArea
                  name={'description'}
                  label={'Description'}
                  value={formValues.description}
                  onChange={(e) => editInput(e)}
                  cols={2}
                />
                <Button type={'submit'} style={{ borderRadius: '0.5rem' }}>
                  Add recording
                </Button>
              </form>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
};
export default RecordModal;
