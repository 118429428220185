import { useQuery } from 'react-query';

import { axiosInstance } from '../../../core/api/axiosInstance';

export const useGetProvider = (type: 'enterprise' | 'provider', email: string) =>
  useQuery(
    email,
    async () => {
      const { data } = await axiosInstance.get(`${type}-accounts/by-email`, {
        params: {
          email,
        },
      });
      return data;
    },
    {
      staleTime: Infinity,
      enabled: !!email,
    },
  );
