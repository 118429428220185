import { useQuery } from 'react-query';

import { axiosInstance } from '../../../core/api/axiosInstance';

export const key = ['USER_DATA'];
const apiURL = `${process.env.REACT_APP_KEYCLOAK_URL}/realms/C9POC/account`;
export const useGetProfileInfo = () => {
  return useQuery(
    key,
    async () => {
      const { data } = await axiosInstance.get(`${apiURL}`);
      return data;
    },
    { staleTime: Infinity },
  );
};
