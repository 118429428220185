import { useQuery } from 'react-query';

import { axiosInstance } from '../../../core/api/axiosInstance';

export const key = ['DATA_PRODUCT_MONITORING_PARAMETERS_TYPE'];

export const useGetDataByParameterType = (
  dateRange: [string | null, string | null],
  typeId: string,
  id?: string,
) =>
  useQuery(
    [...key, typeId, id, ...dateRange],
    async () => {
      if (!dateRange) return;
      const { data } = await axiosInstance.get(
        `data-products/${id}/ingestion-data/types/${typeId}`,
        {
          params: {
            dateFrom: dateRange[0],
            dateTo: dateRange[1],
          },
        },
      );
      return data;
    },
    {
      enabled: !!typeId,
    },
  );
