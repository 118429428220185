import { useQuery } from 'react-query';

import { axiosInstance } from '../../../core/api/axiosInstance';
import { MarketplaceTemplateEnum } from '../../../model/enums/MarketplaceTemplateEnum';

const key = ['PRODUCT_MULTIMEDIA'];
export const useGetProductMultimedia = (
  id: string,
  templateType: MarketplaceTemplateEnum,
  auth: boolean,
) =>
  useQuery(
    [...key, id, templateType],
    async (): Promise<string> => {
      const res = await axiosInstance.get(`multimedia/download/${id}`, {
        responseType: 'arraybuffer',
      });
      return URL.createObjectURL(new Blob([res.data]));
    },
    {
      staleTime: Infinity,
      enabled:
        (templateType === MarketplaceTemplateEnum.VIDEO_TEMPLATE ||
          templateType === MarketplaceTemplateEnum.AUDIO_TEMPLATE) &&
        auth,
      retry: false,
    },
  );
