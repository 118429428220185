export const WIND_DRAW_FRAG = `
#ifdef GL_ES
precision mediump float;
precision mediump int;
#endif

varying vec4 v_position;

varying vec2 v_particle_pos;

uniform float u_particles_res;
uniform sampler2D u_wind;
uniform sampler2D maskTexture;
uniform vec2 u_wind_min;
uniform vec2 u_wind_max;
uniform sampler2D u_color_ramp;
uniform float u_scale_min;
uniform float u_scale_max;


void main() {

    vec2 velocity_uv = texture2D(u_wind, v_particle_pos).rg;
    float maskBit = texture2D(maskTexture, v_position.xy).r;
    vec2 velocity = mix(u_wind_min, u_wind_max, velocity_uv);
    //float speed_t = length(velocity) / length(u_wind_max); 

    //speed normalized 0-1 to the color palette
    float ramp_pos_x = (length(velocity) - u_scale_min) / (u_scale_max - u_scale_min);

    vec2 ramp_pos = vec2(ramp_pos_x,0.5);

    vec4 empty = vec4(0);
    vec4 color = texture2D(u_color_ramp, ramp_pos);
    vec4 finalColor = texture2D(maskTexture, v_particle_pos.xy);
    
    if (finalColor.a > 0.0) {
        gl_FragColor = color;
    } else {
        gl_FragColor = vec4(0.0);
    }
}
`;
