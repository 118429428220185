import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DraggableData, Position, Rnd } from 'react-rnd';

import { createPlayer } from '../../components/timeline/helpers';
import { useScaleFactor } from '../../hooks/useScaleFactor';
import { MAX_FULLSCREEN_HEIGHT } from '../../model/constants/constants';
import { C9ProjectDef } from '../../model/definitions/C9ProjectDef';
import { VideoPanelDef } from '../../model/definitions/VideoPanelDef';
import { ActiveDef, setElement, setProjectToPlay } from '../../store/slices/active-slice';
import { updateRecorderPosition } from '../../store/slices/project-slice';
import { RootState } from '../../store/store';
import { transformAbsoluteToPercent, transformPercentToAbsolute } from './utils';

const resizeHandleStyles: React.CSSProperties = { zIndex: '' };
interface RecorderElementProps {
  videoOver: VideoPanelDef;
  children: JSX.Element[] | JSX.Element;
  disabled: boolean;
}
export const RecorderElement = ({ children, videoOver, disabled }: RecorderElementProps) => {
  const project = useSelector<RootState, C9ProjectDef>((state) => state.project.present.project);
  const { activeAspectRatio, syncSpace, activeFramerate } = useSelector<RootState, ActiveDef>(
    (state) => state.active,
  );
  const scaleFactor = useScaleFactor(disabled);
  const dispatch = useDispatch();
  const { positionControl, id } = videoOver ?? new VideoPanelDef();
  useEffect(() => {
    videoOver &&
      dispatch(
        setProjectToPlay({ projectToPlay: createPlayer(project, syncSpace, activeFramerate) }),
      );
  }, [activeFramerate, dispatch, project.sceneDefs, syncSpace, videoOver]);
  const draggingStopped = (data: DraggableData) => {
    dispatch(
      updateRecorderPosition({
        position: {
          ...positionControl,
          x: transformAbsoluteToPercent(data.x, activeAspectRatio, 'width', MAX_FULLSCREEN_HEIGHT),
          y: transformAbsoluteToPercent(data.y, activeAspectRatio, 'height', MAX_FULLSCREEN_HEIGHT),
        },
      }),
    );
  };
  const resizingStopped = (ref: HTMLElement, position: Position) => {
    dispatch(
      updateRecorderPosition({
        position: {
          ...positionControl,
          w: transformAbsoluteToPercent(
            ref.offsetWidth,
            activeAspectRatio,
            'width',
            MAX_FULLSCREEN_HEIGHT,
          ),
          h: transformAbsoluteToPercent(
            ref.offsetHeight,
            activeAspectRatio,
            'height',
            MAX_FULLSCREEN_HEIGHT,
          ),
          x: transformAbsoluteToPercent(
            position.x,
            activeAspectRatio,
            'width',
            MAX_FULLSCREEN_HEIGHT,
          ),
          y: transformAbsoluteToPercent(
            position.y,
            activeAspectRatio,
            'height',
            MAX_FULLSCREEN_HEIGHT,
          ),
        },
      }),
    );
  };
  return (
    <Rnd
      id={'video-over-recorder'}
      className={'flex'}
      key={id}
      disableDragging={false}
      enableResizing={true}
      lockAspectRatio={false}
      scale={scaleFactor}
      resizeHandleStyles={{
        right: resizeHandleStyles,
        bottom: resizeHandleStyles,
        left: resizeHandleStyles,
        top: resizeHandleStyles,
        bottomLeft: resizeHandleStyles,
        bottomRight: resizeHandleStyles,
        topLeft: resizeHandleStyles,
        topRight: resizeHandleStyles,
      }}
      style={{
        border: '1px solid red',
        zIndex: 100,
        textAlign: 'center',
        boxSizing: 'border-box',
      }}
      onClick={() => {
        dispatch(
          setElement({
            activeElement: id,
            activeProp: 'videoOver',
          }),
        );
        dispatch(
          setProjectToPlay({ projectToPlay: createPlayer(project, syncSpace, activeFramerate) }),
        );
      }}
      bounds={undefined}
      onDragStop={(e, data) => {
        draggingStopped(data);
      }}
      onResizeStop={(e, direction, ref, delta, position) => resizingStopped(ref, position)}
      size={{
        width: transformPercentToAbsolute(
          positionControl.w,
          activeAspectRatio,
          'width',
          MAX_FULLSCREEN_HEIGHT,
        ),
        height: transformPercentToAbsolute(
          positionControl.h,
          activeAspectRatio,
          'height',
          MAX_FULLSCREEN_HEIGHT,
        ),
      }}
      position={{
        x: transformPercentToAbsolute(
          positionControl.x,
          activeAspectRatio,
          'width',
          MAX_FULLSCREEN_HEIGHT,
        ),
        y: transformPercentToAbsolute(
          positionControl.y,
          activeAspectRatio,
          'height',
          MAX_FULLSCREEN_HEIGHT,
        ),
      }}
    >
      {children}
    </Rnd>
  );
};
