import { useMutation } from 'react-query';
import { v4 } from 'uuid';

import { BaseMapDef } from '../../../model/definitions/BaseMapDef';
import { axiosInstance } from '../axiosInstance';

export const useGetOceanMask = () => {
  return useMutation(
    async ({
      baseMap,
      inverted,
    }: {
      baseMap: BaseMapDef;
      inverted: boolean;
    }): Promise<BaseMapDef> => {
      const payload: BaseMapDef = {
        ...baseMap,
        inverted,
        id: v4(),
        mapStyle: {
          ...baseMap.mapStyle,
          name: inverted ? 'po-water-inverted' : 'po-water',
        },
      };
      const { data } = await axiosInstance.post('map/tiles/new-ocean-mask', payload);
      return data;
    },
  );
};
