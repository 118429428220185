import { useMutation } from 'react-query';

import { axiosInstance } from '../../../core/api/axiosInstance';
import { queryClient } from '../../../index';
import { key as multimediaKey } from './useGetPurchasedMultimedia';
import { key as templateKey } from './useGetPurchasedTemplates';

export const useDeletePurchasedItem = () => {
  return useMutation(
    async ({ id, templateType }: { id: string; templateType: string; multimedia: boolean }) => {
      const response = await axiosInstance.delete(
        `marketplace/purchase-item/${id}?templateType=${templateType}`,
      );
      return response.data;
    },
    {
      onSuccess: (data, variables) => {
        if (variables.multimedia) {
          queryClient.invalidateQueries(multimediaKey);
        } else {
          queryClient.invalidateQueries(templateKey);
        }
      },
    },
  );
};
