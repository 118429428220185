import './style.scss';

import { DetailedHTMLProps, InputHTMLAttributes } from 'react';

interface Interface
  extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label?: string;
  textarea?: boolean;
}
const Input = ({ label, ...props }: Interface) => {
  const { required } = props;
  return (
    <div id={'Input'}>
      <label>
        {label && required && <span>* </span>}
        {label && `${label}`}
        {label && ':'}
      </label>

      <input {...props} />
    </div>
  );
};
export default Input;
