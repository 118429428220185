export enum SidebarItemsEnum {
  DEFAULT = '',
  RECORD = 'RECORD',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  AUDIO = 'AUDIO',
  ANIMATION = 'ANIMATION',
  SYMBOL = 'SYMBOL',
  TEMPLATE = 'TEMPLATE',
}
