import './cron-studio-drawer.scss';

import { Drawer } from 'antd';

import CronDetails from './CronDetails';

interface Props {
  openDrawer: boolean;
  setOpenDrawer: (value: boolean) => void;
}

function CronStudioDrawer({ openDrawer, setOpenDrawer }: Props) {
  const onClose = () => {
    setOpenDrawer(false);
  };

  return (
    <Drawer
      title="SCHEDULE LIST"
      placement="right"
      onClose={onClose}
      open={openDrawer}
      className="cron-drawer"
    >
      <CronDetails />
    </Drawer>
  );
}

export default CronStudioDrawer;
