import { cloneDeep } from 'lodash';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { queryClient } from '../..';
import { C9ProjectDef } from '../../model/definitions/C9ProjectDef';
import { key as getProjectsKey } from '../../pages/dashboard/queries-NEW/useGetProjects';
import { addNewProject, setSavedProject } from '../../store/slices/project-slice';
import { axiosInstance } from './axiosInstance';

const useShareProject = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  return useMutation(
    async ({
      id,
      type,
      replaceExisting,
    }: {
      id: string;
      type: string;
      replaceExisting: boolean;
    }): Promise<C9ProjectDef> => {
      const response = await axiosInstance.put(
        `dashboard/share-${type.toLowerCase()}/${id}?replaceExisting=${replaceExisting}`,
      );
      return response.data;
    },
    {
      onSuccess: (data) => {
        if (location.pathname.includes('studio')) {
          const project = cloneDeep(data);
          dispatch(addNewProject({ project }));
          dispatch(setSavedProject({ savedProject: project }));
        } else {
          queryClient.invalidateQueries(getProjectsKey);
        }
        toast.success('Project successfully shared');
      },
      onError: (error) => {
        toast.error(`Failed to share project`);
      },
    },
  );
};

export default useShareProject;
