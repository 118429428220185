export const WIND_UPDATE_FRAG = `
#ifdef GL_ES
precision highp float;
#endif



uniform sampler2D u_particles;
uniform sampler2D u_wind;
uniform float u_particles_res;
uniform vec2 u_wind_res;
uniform vec2 u_wind_min; // Minimum and maximum wind velocities.
uniform vec2 u_wind_max; // Minimum and maximum wind velocities.
uniform float frameNumber;

uniform float u_rand_seed;
uniform float u_speed_factor;

// Parameters to control the rate at which particles are restarted at random positions.
uniform float u_drop_rate;
uniform float u_drop_rate_bump;

uniform vec2 resolution;


varying vec2 v_tex_pos;

// pseudo-random generator
const vec3 rand_constants = vec3(12.9898, 78.233, 4375.85453);

float rand(const vec2 co) {
    float t = dot(rand_constants.xy, co);
    return fract(sin(t) * (rand_constants.z + t));
}

// wind speed lookup; use manual bilinear filtering based on 4 adjacent pixels for smooth interpolation
vec2 lookup_wind(const vec2 uv) {
   //  return texture2D(u_wind, uv).ar; // lower-res hardware filtering
    vec2 px = 1.0 / u_wind_res;
    vec2 vc = (floor(uv * u_wind_res)) * px;
    vec2 f = fract(uv * u_wind_res);
    vec2 tl = texture2D(u_wind, vc).rg;
    vec2 tr = texture2D(u_wind, vc + vec2(px.x, 0)).rg;
    vec2 bl = texture2D(u_wind, vc + vec2(0, px.y)).rg;
    vec2 br = texture2D(u_wind, vc + px).rg;
   return mix(mix(tl, tr, f.x), mix(bl, br, f.x), f.y);
}


void main() {

    vec4 color = texture2D(u_particles, v_tex_pos);

    vec2 pos = vec2(
        color.r / 255.0 + color.b,
        color.g / 255.0 + color.a); // decode particle position from pixel RGBA

    vec2 velocity = mix(u_wind_min, u_wind_max, lookup_wind(pos));
    float speed_t = length(velocity) / length(u_wind_max);

    // take EPSG:4236 distortion into account for calculating where the particle moved
    float distortion = cos(radians(pos.y * 180.0 - 90.0));
    distortion = 1.0;
    vec2 offset = vec2(velocity.x/distortion, - velocity.y) * 0.0001 * u_speed_factor;

    // update particle position, wrapping around the date line
    // The new position is updated, and it wraps around the date line to ensure particles don't move off-screen.
    //pos = pos + offset;
    pos = fract(1.0 + pos + offset);

    // a random seed to use for the particle drop
    vec2 seed = (pos + v_tex_pos) * u_rand_seed;

    // drop rate is a chance a particle will restart at random position, to avoid degeneration
    float drop_rate = u_drop_rate + speed_t * u_drop_rate_bump;
    float drop = step(1.0 - drop_rate, rand(seed)); // 0 ili 1 , ako

    vec2 random_pos = vec2(
        rand(seed + 1.3),
        rand(seed + 2.1)
        );
    pos = mix(pos, random_pos, drop);

    // encode the new particle position back into RGBA
    gl_FragColor = vec4(
        fract(pos * 255.0),
        floor(pos * 255.0) / 255.0);
}
`;
