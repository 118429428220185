import Color from 'colorjs.io';

import { getRgba } from '../../../../../molecules/palette/utils';

const toRgbaString = (color: Color) =>
  `${Math.round(color.get('r') * 255)}, ${Math.round(color.get('g') * 255)}, ${Math.round(
    color.get('b') * 255,
  )}, ${Math.round(color.alpha * 255)}`;

const legendRgba = (color: string) => {
  const rgbaStrings = color.split(',');
  const rgba = rgbaStrings.map((c) => Number(c.trim()));
  return `rgba(${rgba[0]},${rgba[1]},${rgba[2]},${rgba[3] / 255})`;
};

const findNumberRange = (arr: Array<number>, target: number) => {
  let index = -1;

  for (let i = 0; i < arr.length - 1; i++) {
    if (target >= arr[i] && target < arr[i + 1]) {
      index = i + (target - arr[i]) / (arr[i + 1] - arr[i]);
      return { range: [arr[i], arr[i + 1]], index };
    }
  }

  if (target < arr[0]) {
    index = 0;
    return { range: [0, arr[0]], index };
  }

  if (target >= arr[arr.length - 1]) {
    index = arr.length - 1;
    return { range: [arr[arr.length - 1], Number.POSITIVE_INFINITY], index };
  }
};

const interpolateInSteps = (a: number, b: number, steps: number) => {
  const stepSize = 1 / steps;
  const result = [];

  for (let i = 0; i <= steps; i++) {
    const t = i * stepSize;
    const interpolatedValue = a + t * (b - a);
    result.push(Number(interpolatedValue.toPrecision(3)));
  }
  return result;
};
const interpolateValues = (start: number, end: number, steps: number): number[] => {
  if (steps <= 0) {
    return [];
  }

  const stepSize: number = (end - start) / (steps + 1);
  const result: number[] = [];

  for (let i = 1; i <= steps; i++) {
    const interpolatedValue: number = start + i * stepSize;
    result.push(interpolatedValue);
  }
  return result;
};

const numberFormatter = (value: string | undefined, precision: number): string => {
  if (value !== null && value !== undefined && value !== '') {
    const formattedValue = parseFloat(value).toFixed(precision);
    return parseFloat(formattedValue).toString();
  }
  return '';
};

const middleColor = (color1: string, color2: string) => {
  console.log({ color1, color2 });
  const color = new Color(getRgba(color1));
  const colorNext = new Color(getRgba(color2));
  // @ts-ignore
  const interColors = color.steps(colorNext, {
    space: 'srgb',
    outputSpace: 'srgb',
    steps: 1,
  });
  return toRgbaString(interColors[0] as Color);
};

const defaultDegree = {
  degree: 0,
  color: '255, 255, 255, 255',
  active: true,
  interpolationSteps: 0,
};

export {
  defaultDegree,
  findNumberRange,
  interpolateInSteps,
  interpolateValues,
  legendRgba,
  middleColor,
  numberFormatter,
  toRgbaString,
};
