import { Alert } from 'antd';

import { useGetStorageLocation } from '../hooks/useGetStorageLocation';
import { DataProductInterface } from '../pages/Products';

function IngestionInfo({ form }: { form: DataProductInterface }) {
  const { data: storageLocation } = useGetStorageLocation(form?.id);

  return (
    <>
      {storageLocation && (
        <Alert
          className="mb-4 select-text"
          description={
            <div className="text-left">
              <p>
                To directly upload files to the studio, for the data product, please use the
                following location on S3:
              </p>
              <ul className="list-disc list-inside">
                <li>
                  studio location:{' '}
                  <span className="font-semibold">{storageLocation.full_storage_path}</span>
                </li>
              </ul>
              <p>Please contact the Administrator to provide you with key and secret.</p>
            </div>
          }
          type="info"
        />
      )}
    </>
  );
}

export default IngestionInfo;
