import { toBlob } from 'html-to-image';
import html2canvas from 'html2canvas';

export interface ScreenshotData {
  base64: string;
  blob: Blob | null;
}

export async function getScreenshotOfElement(
  element: HTMLDivElement,
  aspectRatio: [number, number],
  callback: (data: ScreenshotData) => void,
  previewSize: number,
) {
  async function onCanvas(canvas: HTMLCanvasElement) {
    const blobPromise = new Promise<Blob>((resolve) => canvas.toBlob((bl) => resolve(bl!)));
    const base64Promise = new Promise<string>((resolve) => {
      const encodedString = canvas.toDataURL();
      resolve(encodedString);
    });
    const [blobImage, base64] = await Promise.all([blobPromise, base64Promise]);
    callback({ base64, blob: blobImage! });
  }
  const canvas = await html2canvas(element, {
    width: previewSize * (aspectRatio[0] / aspectRatio[1]),
    height: previewSize,
    useCORS: true,
    // taintTest: false,
    allowTaint: true,
    // foreignObjectRendering: true,
    backgroundColor: '#050f1e',
  });
  await onCanvas(canvas);
}

function filterScreenshotElements(node: HTMLElement) {
  return node.classList?.contains('no-screenshot') ? false : true;
}

function filterMapScreenshotElements(node: HTMLElement) {
  const classListArray = Array.from(node.classList);
  return node.classList?.contains('no-screenshot') ||
    classListArray.some(
      (className: string) => className.includes('drawing-layer') || className.includes('c9layer'),
    )
    ? false
    : true;
}

export async function screenshotCanvasElement(
  type: 'project' | 'scene' | 'map' | 'weather-poster' | 'weather-geo-poster' | 'layer',
): Promise<ScreenshotData> {
  let el;
  if (type === 'map') {
    el = document.querySelector('.ol-unselectable.ol-layers');
  } else {
    el = document.getElementById('canvas-container');
  }
  if (!el) {
    throw new Error('No canvas container id');
  }
  const blob = await toBlob(el as HTMLElement, {
    filter: type === 'map' ? filterMapScreenshotElements : filterScreenshotElements,
  });
  return {
    base64: URL.createObjectURL(blob!),
    blob,
  };
}
