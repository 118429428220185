import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useState } from 'react';
import { toast } from 'react-toastify';

import Button from '../../marketplace-new/atoms/Button/AntButton';

interface SetupFormInterface {
  setIsShown: React.Dispatch<React.SetStateAction<boolean>>;
  getPaymentMethods: () => void;
}

const SetupForm = ({ setIsShown, getPaymentMethods }: SetupFormInterface) => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState<string | undefined>('');

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    await stripe
      .confirmSetup({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: process.env.REACT_APP_BASE_URL + '/workspace/wallet',
        },
        redirect: 'if_required',
      })
      .then((result) => {
        if (result.error) {
          setErrorMessage(result.error.message);
          toast.error(result.error.message);
        } else {
          toast.success('Payment method successfully added');
          setIsShown(false);
          getPaymentMethods();
        }
      });
  };

  return (
    <form onSubmit={handleSubmit} className="form-stripe">
      <PaymentElement />
      <div className="flex mt-4 justify-end">
        <Button className="mr-2" onClick={() => setIsShown(false)}>
          Cancel
        </Button>
        <Button type="primary" htmlType="submit" disabled={!stripe}>
          Submit
        </Button>
      </div>

      {/* Show error message to your customers */}
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  );
};

export default SetupForm;
