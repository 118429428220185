import { useSelector } from 'react-redux';

import { ActiveDef } from '../store/slices/active-slice';
import { RootState } from '../store/store';

export function usePropertyGridActive(usedProps: string[] = []) {
  const { activeElement, propertyGridActiveHash } = useSelector<RootState, ActiveDef>(
    (state) => state.active,
  );
  const lastFocused = propertyGridActiveHash[activeElement];
  const isOpened = usedProps.includes(lastFocused);
  return { lastFocused, isOpened };
}
