import { useQuery } from 'react-query';

import { axiosInstance } from '../../axiosInstance';

const key = ['LATEST_VERSION_ICON'];
export const useGetLatestVersionIcon = (iconSetId: string) =>
  useQuery(
    [...key, iconSetId],
    async () => {
      const iconPack = await axiosInstance.get(`icon-set-template/current/${iconSetId}`);
      const icon = await axiosInstance.get(
        `icon-set-template/ordered-icons-by-set?iconSetVersionId=${iconPack.data.versionId}`,
      );
      return icon.data;
    },
    {
      enabled: !!iconSetId,
    },
  );
