import { ValueTypeEnum } from '../enums/ValueTypeEnum';
import { C9Object } from './C9Object';
import { LogicalGroupParent } from './LogicalGroupParent';
import { PointDataSourceHistoryDef } from './PointDataSourceHistoryDef';

export class PointWDElementDef extends C9Object {
  datetime: number; //correspond to utcTimeStamp property
  utcDate: string; //correspond to utcTime property
  localDate: string | null; //correspond to localTime property
  dayTime: boolean;
  parameterType: string;
  unitOfMeasurement: string;
  valueType: ValueTypeEnum;
  value: any;
  originalValue: any;
  parentGroups = [new LogicalGroupParent()];
  biasFilterAppliedOnValue: boolean;
  valueHistory: Array<PointDataSourceHistoryDef> = [];
  pointWDGroupId?: string;
  externalId: string;
  changedValue?: any;
}
