import './style.scss';

import { DetailedHTMLProps, TextareaHTMLAttributes } from 'react';

interface Interface
  extends DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {
  label?: string;
}
const TextArea = ({ label, ...props }: Interface) => {
  return (
    <div id={'Input'}>
      <label>{label}:</label>
      <textarea {...props} />
    </div>
  );
};
export default TextArea;
