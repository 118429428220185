import './voiceover.scss';

import { FC, useContext, useEffect, useState } from 'react';
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import { useDispatch, useSelector } from 'react-redux';

import { useAddAudioElement } from '../../core/api/multimedia/useAddAudioElement';
import { PlaybackEnum } from '../../core/ui/enums/PlaybackEnum';
import { useImplicitSave } from '../../hooks/useImplicitSave';
import { AudioElement } from '../../model/definitions/AudioElement';
import { C9ProjectDef } from '../../model/definitions/C9ProjectDef';
import { TimeControlDef } from '../../model/definitions/TimeControlDef';
import PlayerContext from '../../pages/playground/playerContext/PlayerContext';
import { addProjectVoiceOver } from '../../store/slices/project-slice';
import { RootState } from '../../store/store';

interface VoiceOverProps {
  projectName: string;
  start: () => void;
  duration: number;
}
export const VoiceOver: FC<VoiceOverProps> = ({ projectName, start, duration }) => {
  const project = useSelector<RootState, C9ProjectDef>((state) => state.project.present.project);
  const dispatch = useDispatch();
  const { time, isPlaying, setIsRecording, isRecording } = useContext(PlayerContext);
  const recordControls = useAudioRecorder();
  const save = useImplicitSave();
  useEffect(() => {
    if (isPlaying === PlaybackEnum.STOP && recordControls.isRecording)
      recordControls.stopRecording();
    isPlaying === PlaybackEnum.STOP &&
      !recordControls.isRecording &&
      recordControls.recordingBlob &&
      isRecording &&
      addAudioElement(recordControls.recordingBlob);
    isPlaying === PlaybackEnum.STOP && setIsRecording(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaying]);
  const [audioTime, setAudioTime] = useState<TimeControlDef>(new TimeControlDef(0, 0));
  const { mutate, isLoading } = useAddAudioElement();
  function blobToFile(blob: Blob, fileName: string) {
    return new File([blob], fileName, {
      type: 'audio/mpeg',
      endings: 'transparent',
    });
  }
  const addAudioElement = async (blob: Blob) => {
    setIsRecording(false);
    recordControls.stopRecording();
    await setAudioTime({ ...audioTime, endMS: time });
    mutate(
      { file: blobToFile(blob, projectName), name: projectName },
      {
        onSuccess: async (data) => {
          data.durationInMS = time - audioTime.startMS;
          const audioLayer = new AudioElement();
          audioLayer.audioElementTemplate = data;
          audioLayer.timeControls = [{ ...audioTime, endMS: time }];

          await dispatch(addProjectVoiceOver({ audio: audioLayer }));
          save({ ...project, voiceOver: audioLayer });
        },
        onError: (err) => console.log(err),
      },
    );
  };
  const startToRecord = () => {
    setIsRecording(true);
    start();
    recordControls.startRecording();
    setAudioTime({ ...audioTime, startMS: time });
  };
  return (
    <>
      <AudioRecorder
        classes={{
          AudioRecorderClass: 'audio-recorder-class',
        }}
        recorderControls={{
          ...recordControls,
          startRecording: () => time < duration && startToRecord(),
        }}
        onRecordingComplete={(e) => {
          addAudioElement(e);
        }}
      />
      {isLoading && <span>Adding audio</span>}
    </>
  );
};
