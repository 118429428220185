import { AxiosError } from 'axios';
import { QueryKey, useQuery, UseQueryResult } from 'react-query';

import { axiosInstance } from '../../../core/api/axiosInstance';
import { C9ProjectPreviewDTO } from '../../../model/DTO/C9ProjectPreviewDTO';
import { QueryInfoMetadata } from '../../../model/other/QueryInfoMetadata';

export const key: QueryKey = ['GET_PURCHASED_MULTIMEDIA'];

const useGetPurchasedMultimedia = ({
  page,
  type,
  size = 20,
  searchKey,
  sortByField,
  sortAscDesc,
}: {
  page: number;
  type: string;
  size?: number;
  searchKey?: string;
  sortByField?: string;
  sortAscDesc?: string;
}): UseQueryResult<QueryInfoMetadata<C9ProjectPreviewDTO>, AxiosError> => {
  const isEnabled =
    Boolean(type) &&
    (type.toUpperCase() === 'AUDIO' ||
      type.toUpperCase() === 'VIDEO' ||
      type.toUpperCase() === 'IMAGE' ||
      type.toUpperCase() === 'CUSTOM_MAP' ||
      type.toUpperCase() === 'CUSTOM_VECTOR' ||
      type.toUpperCase() === 'ANIMATION');
  return useQuery(
    [...key, type, page, searchKey, sortByField, sortAscDesc],
    async () => {
      const { data } = await axiosInstance.get(`multimedia/my-purchased-preview`, {
        params: { type, page, size, searchKey, sortByField, sortAscDesc },
      });
      return data;
    },
    { enabled: isEnabled },
  );
};

export default useGetPurchasedMultimedia;
