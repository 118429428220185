export function getAspectRatioFromExportFormat(
  exportFormat: string, // should be BroadcastExport | SocialMediaExport
): [number, number] {
  if (!exportFormat) return [16, 9];
  const splitedExpFormat = exportFormat.split('_');
  if (splitedExpFormat.length === 3) {
    /**In this case always 16:9 */
    return [16, 9];
  }
  if (splitedExpFormat.length === 4) {
    const ratio = [splitedExpFormat[1], splitedExpFormat[2]].map((n) => Number(n));
    return ratio as [number, number];
  }
  /**Default should never happen */
  return [16, 9];
}
