import { Select } from 'antd';
import { useCallback } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useDispatch } from 'react-redux';

import { ForecastWDElementDef } from '../../../model/definitions/ForecastWDElementDef';
import { ObservedWDElementDef } from '../../../model/definitions/ObservedWDElementDef';
import { updateForecastBiasIcon, updateObservedBiasIcon } from '../../../store/slices/active-slice';
import { DDIcon } from './Icon';

interface IconsDropDownProps {
  icons: Array<any>;
  item: ObservedWDElementDef | ForecastWDElementDef;
  confirmed: boolean;
}
export const IconsDropDown = ({ icons, item, confirmed }: IconsDropDownProps) => {
  const dispatch = useDispatch();
  function isObserved(obj: any): obj is ObservedWDElementDef {
    if (obj) return 'observedWDSource' in obj;
    return false;
  }
  const val = isObserved(item)
    ? icons?.find((icon) => icon.versionId === item.observedWDSource.value.versionId)
        ?.iconIndexInSet
    : icons?.find((icon) => icon.versionId === item.forecastWDSource.value.versionId)
        ?.iconIndexInSet;
  const options = icons?.map((icon) => {
    return {
      label:
        icon.thumbnailUrls.length > 0 ? (
          <img src={icon.thumbnailUrls[0]} alt={icon.name} />
        ) : (
          <DDIcon versionId={icon.versionId} isJson={icon.jsonAnimation} icon={icon} />
        ),
      value: icon.iconIndexInSet,
    };
  });
  const handleChange = (index: number) => {
    if (icons.length - 1 >= index && index >= 0) {
      const newIcon = icons.find((icon) => icon.iconIndexInSet === index);
      isObserved(item)
        ? dispatch(
            updateObservedBiasIcon({
              id: item.id,
              value: newIcon,
            }),
          )
        : dispatch(
            updateForecastBiasIcon({
              id: item.id,
              value: newIcon,
            }),
          );
    }
  };
  useHotkeys(
    'up',
    (ev) => {
      ev.preventDefault();
      !confirmed && handleUp();
    },
    [val, item, confirmed],
  );
  useHotkeys(
    'down',
    (ev) => {
      ev.preventDefault();
      !confirmed && handleDown();
    },
    [val, item, confirmed],
  );
  const handleUp = useCallback(() => {
    !confirmed && handleChange(val + 1);
  }, [confirmed, val]);
  const handleDown = useCallback(() => {
    !confirmed && handleChange(val - 1);
  }, [confirmed, val]);
  return (
    <Select
      options={options}
      className={'h-12'}
      size={'large'}
      value={val}
      onChange={handleChange}
    />
  );
};
