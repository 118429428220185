import './style.scss';

import { useEffect, useRef, useState } from 'react';
import { AiOutlineMore, AiOutlineSync } from 'react-icons/ai';
import { BiDetail } from 'react-icons/bi';
import { BsEye, BsTrash } from 'react-icons/bs';
import { CgLockUnlock } from 'react-icons/cg';
import { FaRegClone, FaRegEdit } from 'react-icons/fa';
import { FiSettings } from 'react-icons/fi';
import { GrTemplate } from 'react-icons/gr';
import { IoReloadOutline } from 'react-icons/io5';
import { MdIosShare } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

import { DisableReasonEnum } from '../../../../model/enums/DisableReasonEnum';
import { MarketplaceTemplateEnum } from '../../../../model/enums/MarketplaceTemplateEnum';
import { AnimationRetryRequest } from '../../../../model/other/AnimationRetryRequest';
import { useConfirmation } from '../../modals/useConfirmation';
import { useReprocessMap } from '../../queries-NEW/useReprocessMap';

interface SpeedDialProps {
  id: string;
  versionId: string;
  title: string;
  isDefault?: boolean;
  onEdit?: () => void;
  onClone?: (id: string, versionId: string) => void;
  onShare?: (id: string, type: string, replaceExisting: boolean) => void;
  onDelete?: (id: string, versionId: string) => void;
  onPublish?: (id: string) => void;
  onRetry?: (data: AnimationRetryRequest) => void;
  onGetTemplate?: (id: string) => void;
  onClick?: () => void;
  onDefault?: () => void;
  onUnlock?: (id: string) => void;
  type?: string;
  disabled?: boolean;
  disableReason?: string;
  pngSequenceNotCreated?: boolean;
  pngSequenceStorageId?: string;
  isSharedEntity?: boolean;
  enterpriseAccountId?: string;
  admin?: boolean;
  inEditMode?: boolean;
}
const SpeedDial = ({
  onDelete,
  onPublish,
  onRetry,
  onClone,
  onShare,
  onEdit,
  onDefault,
  onUnlock,
  id,
  versionId,
  title,
  isDefault,
  onGetTemplate,
  onClick,
  type,
  disabled,
  isSharedEntity,
  disableReason,
  enterpriseAccountId,
  pngSequenceNotCreated,
  pngSequenceStorageId,
  admin,
  inEditMode,
}: SpeedDialProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const dialRef = useRef<HTMLDivElement>(null);
  const excludedTypes = ['PROJECT', 'font', 'layer', 'symbol'];
  useEffect(() => {
    if (dialRef.current?.parentElement)
      dialRef.current.parentElement.onmouseleave = () => {
        setOpen(false);
      };
  });
  const { confirm } = useConfirmation();
  const { mutate: reprocess, isLoading } = useReprocessMap();

  const clone = () => {
    confirm({
      message: `You are about to clone item ${title}`,
      warning: "Scheduled jobs won't be cloned with the project!",
      onConfirm: () => onClone && onClone(id, versionId),
    });
  };
  const share = () => {
    confirm({
      message: `You are about to share item ${title}`,
      onConfirm: () => onShare && onShare(id, type as string, false),
    });
  };
  const unlock = () => {
    confirm({
      message: `You are about to unlock item ${title}`,
      onConfirm: () => onUnlock && onUnlock(id),
    });
  };
  const use = () => {
    confirm({
      message: `Create new project using template ${title}?`,
      onConfirm: () => onGetTemplate && onGetTemplate(id),
    });
  };
  const deleteItem = () => {
    confirm({
      message: `You are about to delete item ${title}`,
      onConfirm: () => onDelete && onDelete(id, versionId),
    });
  };
  const defaultIconPack = () => {
    confirm({
      message: `You are about to set icon pack ${title} as default`,
      onConfirm: () => onDefault && onDefault(),
    });
  };

  return (
    <div className={'ws-card-speed-dial'} ref={dialRef}>
      <AiOutlineMore
        className={`ws-card-speed-dial-icon ${open ? 'dial-open' : ''}`}
        onClick={() => setOpen(!open)}
      />
      <div className={`ws-action-list ${open ? 'action-open' : ''}`}>
        <ul>
          {type && type === 'icon-set' && !disabled && (
            <li onClick={() => navigate(`./edit/${id}`)}>
              <span>
                Edit icon pack <FaRegEdit className={'sd-icon'} />
              </span>
            </li>
          )}
          {onEdit && !disabled && (
            <li onClick={onEdit}>
              <span>
                Edit basic info <FaRegEdit className={'sd-icon'} />
              </span>
            </li>
          )}
          {type && type === 'PROJECT' && onClick && !disabled && (
            <li onClick={onClick}>
              <span>
                Scheduler <BiDetail className={'sd-icon'} />
              </span>
            </li>
          )}
          {onClone && !disabled && (
            <li onClick={clone}>
              <span>
                Clone <FaRegClone className={'sd-icon'} />
              </span>
            </li>
          )}
          {onShare && !isSharedEntity && enterpriseAccountId && !disabled && (
            <li onClick={share}>
              <span>
                Share <MdIosShare className={'sd-icon'} />
              </span>
            </li>
          )}
          {onUnlock && admin && inEditMode && (
            <li>
              <span onClick={unlock}>
                Unlock
                <CgLockUnlock className={'sd-icon'} />
              </span>
            </li>
          )}
          {onGetTemplate && type === 'project' && !disabled && (
            <li onClick={use}>
              <span>
                Use Template <GrTemplate className={'sd-icon'} />
              </span>
            </li>
          )}
          {type === 'icon-set' && !isDefault && (
            <li onClick={defaultIconPack}>
              <span>
                Set as default <FiSettings className={'sd-icon'} />
              </span>
            </li>
          )}
          {!excludedTypes.includes(type as string) && !disabled && (
            <li onClick={() => onPublish && onPublish(id)}>
              <span>
                Publish <BsEye fill="green" className={'sd-icon'} />
              </span>
            </li>
          )}
          {type === 'custom_map' &&
            disabled &&
            disableReason === DisableReasonEnum.UNABLE_TO_GET_RESULT_FROM_TILES_SERVICE && (
              <li
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  !isLoading && reprocess(id);
                }}
                className={`${isLoading ? 'pointer-events-none' : ' pointer-events-auto'}`}
              >
                <span>
                  Reprocess <AiOutlineSync className={'sd-icon'} />
                </span>
              </li>
            )}
          {pngSequenceNotCreated && pngSequenceStorageId && admin && (
            <li
              onClick={() =>
                onRetry &&
                onRetry({
                  iconTemplateId: id,
                  lottieJsonStorageId: versionId,
                  pngSequenceStorageId: pngSequenceStorageId,
                  templateType: MarketplaceTemplateEnum.ANIMATION_TEMPLATE,
                })
              }
            >
              <span>
                Retry PNG Sequence Creation <IoReloadOutline className={'sd-icon'} />
              </span>
            </li>
          )}
          <li onClick={deleteItem}>
            <span>
              Delete <BsTrash fill="red" size={15} className={'sd-icon'} />
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SpeedDial;
