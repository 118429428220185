import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { queryClient } from '../../..';
import { axiosInstance } from '../../../core/api/axiosInstance';
import { key as getSchedulersKey } from './useGetSchedulers';

export const useCreateScheduler = () => {
  return useMutation(
    async (data: {
      name: string;
      description: string;
      cronExpression: string;
      projectId: string;
    }) => {
      return await axiosInstance.post(`scheduler`, data);
    },
    {
      onSuccess: () => {
        toast.success('Scheduler created successfully!');
        queryClient.invalidateQueries(getSchedulersKey);
      },
      onError: (error: AxiosError) => {
        if (error.request.status !== 403) {
          toast.error('Error creating scheduler!');
        }
      },
    },
  );
};
