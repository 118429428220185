import { MapStylesType } from '../../molecules/mapElement/MapStylesHash';
import { BaseMapDef } from './BaseMapDef';
import { C9Object } from './C9Object';
import { CityGeoPosterDef } from './CityGeoPosterDef';
import { ColorFilterDef } from './ColorFilterDef';
import { CustomBackgroundImageDef } from './CustomBackgroundImageDef';
import { DrawingDef } from './DrawingDef';
import { FlyOverDef } from './FlyOverDef';
import { GraticulesDef } from './GraticulesDef';
import { LogicalGroupParent } from './LogicalGroupParent';
import { MapPositionControlDef } from './MapPositionControlDef';
import { PositionControlDef } from './PositionControlDef';
import { TimeControlDef } from './TimeControlDef';
import { WeatherDataSource } from './WeatherDataSource';
import { WeatherDataSpaceDef } from './WeatherDataSpaceDef';
import { WeatherDataVisibilityControlDef } from './WeatherDataVisibilityControlDef';
import { WeatherGeoPosterDef } from './WeatherGeoPosterDef';

export class MapPanelDef extends C9Object {
  timeControls: TimeControlDef[];
  positionControl: PositionControlDef;
  weatherDataSource?: WeatherDataSource;
  mapPositionControl: MapPositionControlDef;
  flyOver?: FlyOverDef;
  weatherDataVisibilityControl?: WeatherDataVisibilityControlDef;
  flyOverEnabled = false;
  mapType: keyof MapStylesType;
  baseMapSetup: BaseMapDef;
  wdSpace: WeatherDataSpaceDef[];
  isNew?: boolean; // Added by me
  parentGroups = [new LogicalGroupParent()];
  geoPosters: WeatherGeoPosterDef[];
  cityPosters: CityGeoPosterDef[];
  drawingElements: DrawingDef[];
  enabled: boolean;
  graticule: GraticulesDef = new GraticulesDef();
  lockCitiesPosition?: boolean; // added by me
  showCities: boolean;
  oceanMask: BaseMapDef;
  colorFilters: ColorFilterDef;
  /**For NSPER projection */
  customBackgroundImage: CustomBackgroundImageDef;
  /*** UI props ***/
  extentVersion = 0;
}
