import { Popover } from 'antd';
import Color from 'colorjs.io';
import React from 'react';
import { ColorResult } from 'react-color';
import useEyeDropper from 'use-eye-dropper';

import styles from '../../pages/playground/properties/Properties.module.scss';
import Chrome from './chrome/Chrome';

interface ColorPickerProps {
  value?: string;
  disabled?: boolean;
  onChange?: (e: string) => void;
  isRgba?: boolean;
  hideInput?: boolean;
}
const ColorPickerRefactor = ({
  value,
  disabled,
  onChange,
  isRgba,
  hideInput,
}: ColorPickerProps) => {
  const { open, isSupported } = useEyeDropper();

  const addAlpha = (color: string, opacity: string) => {
    if (opacity.length === 1) opacity = '0' + opacity;
    return color + opacity;
  };
  const changeColor = (color: ColorResult, event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    event.preventDefault();
    const defaultAlpha = value!.substring(7, 9); // ?? '00';
    let val;
    if (
      color.rgb.a &&
      ((event.target.labels &&
        event.target.labels[0].innerText !== 'R' &&
        event.target.labels[0].innerText !== 'G' &&
        event.target.labels[0].innerText !== 'B') ||
        event.type === 'mousedown')
    ) {
      const decimal = color.rgb.a * 255;
      val = decimal.toString(16).split('.')[0];
    }
    const transformToHex = addAlpha(color.hex, val ?? defaultAlpha);
    const rgba = `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${Math.round(
      (color.rgb.a || 1) * 255,
    )})`;
    onChange && onChange(isRgba ? rgba : transformToHex);
  };
  const pickColor = () => {
    open().then((color) => {
      onChange && onChange(color.sRGBHex + 'ff');
    });
  };
  return (
    <div className={styles.colorPicker}>
      <div className={styles.colorPickerWrapper}>
        <Popover
          placement="right"
          content={
            <Chrome
              onChange={(e: ColorResult, event: React.ChangeEvent<HTMLInputElement>) => {
                changeColor(e, event);
              }}
              color={value}
              pickColor={pickColor}
              colorByName={(e: string) => {
                const color = new Color(e + 'ff');
                const rgba = `rgba(${Math.round(color.srgb.r * 255)},${Math.round(
                  color.srgb.g * 255,
                )},${Math.round(color.srgb.b * 255)},${Math.round((color.alpha || 1) * 255)})`;
                onChange && onChange(rgba);
              }}
              isSupported={isSupported}
            />
          }
          trigger="click"
        >
          <div
            className={styles.picker}
            style={{
              cursor: 'pointer',
              backgroundColor: value,
              minWidth: '25px',
              minHeight: '25px',
              borderRadius: '50%',
            }}
          />
        </Popover>
        {!hideInput && (
          <input
            disabled={disabled}
            type={'text'}
            value={value}
            onChange={(e) => onChange && onChange(e.target.value)}
            className={styles.inputWrap}
          />
        )}
      </div>
    </div>
  );
};

export default ColorPickerRefactor;
