export const projections = [
  {
    code: 'EPSG:3857',
    projectionString:
      '+proj=merc +a=6378137 +b=6378137 +lat_ts=0 +lon_0=0 +x_0=0 +y_0=0 +k=1 +units=m +nadgrids=@null +wktext +no_defs +type=crs',
    name: 'Mercator',
    extent: [9189, 9690, 9691, 15960],
    bbox: [85.06, -180, -85.06, 180],
    setExtent: false,
    zoom: 1,
  },
  // {
  //   code: 'EPSG:4326',
  //   projectionString: '+proj=longlat +datum=WGS84 +no_defs +type=crs',
  //   name: 'WGS 84',
  //   bbox: [90, -180, -90, 180],
  //   extent: null,
  //   setExtent: false,
  //   zoom: 1,
  // },

  {
    code: 'ESRI:53026',
    projectionString:
      '+proj=stere +lat_0=90 +lon_0=0 +lat_ts=71 +datum=WGS84 +x_0=0 +y_0=0 +units=m +no_defs +type=crs',
    name: 'Polar Stereographic',
    extent: [-19979211.93706869, -20142323.99197869, 19979211.93706869, 20142323.99197869],
    bbox: [-180.0, -90.0, 180.0, 90.0],
    setExtent: false,
    zoom: 1,
  },
  {
    code: 'EPSG:9820',
    name: 'Lambert Azimuthal Equal Area',
    projectionString:
      '+proj=laea +lat_0=52 +lon_0=10 +x_0=4321000 +y_0=3210000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
  },
  {
    code: 'ESRI:54049',
    name: 'Near-Sided Perspective',
    projectionString: '+proj=nsper +lat_0=40 +h=5900000',
  },
  {
    code: 'ESRI:54032',
    name: 'Azimuthal Equidistant',
    projectionString:
      '+proj=aeqd +lat_0=52 +lon_0=10 +x_0=4321000 +y_0=3210000 +datum=WGS84 +R=6371000 +units=m +no_defs +type=crs',
  },
  // {
  //   code: "VNSP",
  //   name: "Vertical Near-Side Perspective",
  //   projectionString:
  //     "+proj=nsper +h=3000000 +lat_0=52 +lon_0=10 +x_0=4321000 +y_0=3210000 +datum=WGS84 +units=m +no_defs +type=crs",
  // },
  {
    code: 'LLC',
    name: 'Lambert Conformal Conic',
    projectionString:
      '+proj=lcc +lat_0=52 +lon_0=10 +lat_1=33 +lat_2=45 +x_0=4321000 +y_0=3210000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
  },
  {
    code: 'UTM',
    name: 'Universal Transverse Mercator',
    projectionString: undefined,
  },
];

export function getProjectionNameByCode(code: string) {
  const found = projections.find((p) => p.code.includes(code));
  if (!found) {
    if (code.includes('EPSG:326')) {
      return 'Universal Transverse Mercator';
    }
    return '-';
  }
  if (!found) return 'UNKNOWN';
  return found.name;
}
