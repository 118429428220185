import { useQuery } from 'react-query';

import { axiosInstance } from '../../../core/api/axiosInstance';

export const key = ['TEMPLATE'];
export const useGetTemplate = (link: string, id: string) => {
  return useQuery(
    key,
    async () => {
      const res = await axiosInstance.get(`${link}/${id}`);
      return res.data;
    },
    {
      enabled: !!id && !!link,
      staleTime: 1,
    },
  );
};
