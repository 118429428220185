import '../../components/templates-list/style.scss';

import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  MouseSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  rectSwappingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import { nanoid } from 'nanoid';
import { useEffect, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';

import Button from '../../../../atoms/button/Button';
import { SortableItem } from '../../../../components/test-dir/Sortable';

interface SorterProps {
  files: Array<File>;
  setFiles: (e: Array<File>) => void;
}
const Sorter = ({ files, setFiles }: SorterProps) => {
  const [items, setItems] = useState<Array<File & { id?: string }>>([]);
  const [, setIsSorting] = useState(false);

  useEffect(() => {
    setItems(
      files?.map((file: File) =>
        Object.assign(file, {
          id: nanoid(10),
        }),
      ),
    );
  }, [files.length]);
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 100,
        tolerance: 5,
      },
    }),
    useSensor(MouseSensor, {
      activationConstraint: {
        delay: 100,
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );
  async function handleDragEnd(event: { active: any; over: any }) {
    setIsSorting(false);
    const { active, over } = event;
    if (active !== over) {
      const oldIndex = items.map((i) => i.id).indexOf(active.id);
      const newIndex = items.map((i) => i.id).indexOf(over.id);
      const newArray = [...items];
      [newArray[oldIndex], newArray[newIndex]] = [newArray[newIndex], newArray[oldIndex]];
      await setItems(newArray);
    }
  }
  const confirmSelection = () => {
    setFiles(
      items.map((item) => {
        delete item.id;
        return item;
      }),
    );
  };

  return (
    <div>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={() => setIsSorting(true)}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          //@ts-ignore
          items={items}
          strategy={rectSwappingStrategy}
        >
          <div className={'grid grid-cols-4'}>
            {items?.map((file) => (
              <SortableItem
                key={file.id}
                id={file.id}
                children={
                  <div
                    className={
                      'uploaded-image rounded-2xl h-full flex items-center justify-center m-2 bg-gray-500'
                    }
                  >
                    <img src={URL.createObjectURL(file)} alt={file.type} height={'100%'} />
                    <div
                      className={'download'}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <AiOutlineClose size={10} />
                    </div>
                  </div>
                }
              />
            ))}
          </div>
        </SortableContext>
      </DndContext>
      {files.length > 0 && (
        <Button
          buttonType="link"
          label={'Confirm thumbnails selection?'}
          onClick={() => confirmSelection()}
        />
      )}
    </div>
  );
};
export default Sorter;
