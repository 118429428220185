export const WIND_PREPROCESSING_HEATMAP_FRAG = `
#ifdef GL_ES
precision mediump float;
#endif

uniform vec2 u_wind_res;
uniform vec2 resolution;


uniform sampler2D u_wind;
//uniform sampler2D u_wind_next;

varying vec2 v_pixel_pos;



vec2 lookup_wind_pn_flipped(const vec2 fragCoord, const vec2 uv, sampler2D u_wind_pn) {
    vec2 delta = 1.0 / u_wind_res;
    vec2 vc = (floor(uv * u_wind_res)) * delta;


    vec2 tl = texture2D(u_wind_pn, vc).rg;
    vec2 tr = texture2D(u_wind_pn, vc + vec2(delta.x, 0)).rg;
    vec2 bl = texture2D(u_wind_pn, vc + vec2(0, delta.y)).rg;
    vec2 br = texture2D(u_wind_pn, vc + delta).rg;


    vec2 f = fract(uv * u_wind_res);
    //vec2 f = fract(fragCoord * u_wind_res);

    return mix(mix(tl, tr, f.x), mix(bl, br, f.x), f.y);

    //return texture2D(u_wind_pn, vc).rg;
}


void main() {

    vec2 fragCoord = gl_FragCoord.xy / resolution.xy;
    //gl_FragColor = vec4(lookup_wind_pn_flipped(fragCoord, v_pixel_pos, u_wind),0.0,1.0);
    gl_FragColor = vec4(texture2D(u_wind, v_pixel_pos).rg,0.0,1.0);
    //gl_FragColor = vec4(1.0,0.0,0.0,1.0);

}
`;
