const transformText = [
  {
    id: 1,
    name: 'None',
    format: 'none',
    type: 'mark',
  },
  {
    id: 2,
    name: 'ALLCAPS',
    format: 'uppercase',
    type: 'mark',
  },
  {
    id: 3,
    name: 'lowercase',
    format: 'lowercase',
    type: 'mark',
  },
];

export default transformText;
