import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { queryClient } from '../../..';
import { axiosInstance } from '../../../core/api/axiosInstance';
import { enterpriseKey, fontKey, key } from './fontKeys';

export const useAddFontVariant = () => {
  return useMutation(
    async (data: FormData) => {
      return await axiosInstance.post(`font-variant`, data);
    },
    {
      onSuccess: () => {
        toast.success('Font variant added successfully!');
        const keys = [key, enterpriseKey, fontKey];
        keys.forEach((k) => queryClient.invalidateQueries(k));
      },
      onError: () => {
        toast.error('Error while adding font variant!');
      },
    },
  );
};
