import { useContext, useEffect, useState } from 'react';
import { MdOutlineReplayCircleFilled } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';

import Next from '../../assets/icons/Next';
import Next5sec from '../../assets/icons/Next5sec';
import Pause from '../../assets/icons/Pause';
import Play from '../../assets/icons/Play';
import Prev5sec from '../../assets/icons/Prev5sec';
import Previous from '../../assets/icons/Previous';
import Stop from '../../assets/icons/Stop';
import ControlButton from '../../atoms/controlButton/ControlButton';
import { ModeEnum } from '../../core/ui/enums/ModeEnum';
import { PlaybackEnum } from '../../core/ui/enums/PlaybackEnum';
import {
  getProjectDuration,
  getScenePlaybackLength,
  parseScenes,
} from '../../helpers/timelineUtil';
import { C9ProjectDef } from '../../model/definitions/C9ProjectDef';
import { SceneDef } from '../../model/definitions/SceneDef';
import { TimeControlDef } from '../../model/definitions/TimeControlDef';
import PlayerContext from '../../pages/playground/playerContext/PlayerContext';
import styles from '../../pages/playground/Playground.module.scss';
import { ActiveDef } from '../../store/slices/active-slice';
import { addTimeToSkip, closeTimeSkip } from '../../store/slices/project-slice';
import { selectActiveScene } from '../../store/slices/selectors';
import { RootState } from '../../store/store';

interface PlayerProps {
  project: C9ProjectDef;
  onPlay: () => void;
  onPause: () => void;
  onStop: () => void;
  backFive: () => void;
  skipFive: () => void;
  toStart: () => void;
  toEnd: () => void;
  prevScene: (e: number) => void;
  nextScene: (e: number) => void;
}

const Player = ({
  project,
  onPlay,
  onPause,
  onStop,
  backFive,
  skipFive,
  toStart,
  toEnd,
  nextScene,
  prevScene,
}: PlayerProps) => {
  const dispatch = useDispatch();
  const { mode } = useSelector<RootState>((state) => state.active) as ActiveDef;
  const mapIsLoading = useSelector<RootState>((state) => state.mapLoading.mapIsLoading) as boolean;
  const { isPlaying, time } = useContext(PlayerContext);
  const [isReplay, setIsReplay] = useState(false);
  const activeSceneDef = useSelector<RootState, SceneDef | null>((state) =>
    selectActiveScene(state),
  );
  useEffect(() => {
    time === 0 && isReplay && startReplay();
  }, [isReplay, time]);
  const findNextSceneTime = () => {
    const currScene = parseScenes(project).find(
      (scene) => scene.startMS <= time && scene.endMS > time,
    )?.endMS;
    if (currScene && isPlaying === PlaybackEnum.PLAYING) return currScene - time;
    else return currScene ?? 0;
  };
  const findPreviousSceneTime = () => {
    const currScene = parseScenes(project).findIndex(
      (scene) => scene.startMS <= time && scene.endMS > time,
    );
    if (currScene > 0 && isPlaying === PlaybackEnum.PLAYING) {
      return time - parseScenes(project)[currScene - 1].startMS;
    } else {
      return parseScenes(project)[currScene - 1]?.startMS || 0;
    }
  };
  const maxProjectTime = getProjectDuration(project);
  const maxSceneTime = activeSceneDef ? getScenePlaybackLength(activeSceneDef) : 0;

  const addSkip = () => {
    const timeToSkip = new TimeControlDef(time, time);
    dispatch(addTimeToSkip({ timeToSkip }));
  };
  const closeSkip = () => {
    dispatch(closeTimeSkip({ time: time > maxProjectTime ? maxProjectTime : time }));
  };

  const startReplay = () => {
    onPlay();
    setIsReplay(false);
  };
  const replay = () => {
    onStop();
    setIsReplay(true);
  };
  const disableAddSkip = project.skippedTime.some((item) => item.startMS === item.endMS);
  const disableCloseSkip = !project.skippedTime.some((item) => item.startMS === item.endMS);
  return (
    <div className={styles.playControls}>
      {mode === ModeEnum.PROJECT && isPlaying !== PlaybackEnum.PLAYING && (
        <ControlButton icon={'{'} onClick={() => addSkip()} disabled={disableAddSkip} bordered />
      )}
      {mode === ModeEnum.PROJECT && isPlaying !== PlaybackEnum.PLAYING && (
        <ControlButton
          icon={'}'}
          onClick={() => closeSkip()}
          bordered
          disabled={disableCloseSkip}
        />
      )}
      {mode === ModeEnum.SEQUENCE && (
        <ControlButton disabled={maxSceneTime <= 0} icon={<Previous />} onClick={() => toStart()} />
      )}
      {mode === ModeEnum.PROJECT && (
        <ControlButton icon={<Previous />} onClick={() => prevScene(findPreviousSceneTime())} />
      )}
      {mode === ModeEnum.SEQUENCE && (
        <ControlButton
          disabled={maxSceneTime <= 0}
          icon={<Prev5sec />}
          onClick={() => backFive()}
        />
      )}
      {mode === ModeEnum.SEQUENCE &&
        (time < maxSceneTime || maxSceneTime <= 0 ? (
          <ControlButton
            disabled={isPlaying === PlaybackEnum.PLAYING || mapIsLoading || maxSceneTime <= 0}
            icon={<Play />}
            onClick={() => onPlay()}
          />
        ) : (
          <ControlButton
            replay
            disabled={isPlaying === PlaybackEnum.PLAYING || mapIsLoading || maxSceneTime <= 0}
            icon={<MdOutlineReplayCircleFilled size={28} />}
            onClick={() => replay()}
          />
        ))}
      {mode === ModeEnum.PROJECT &&
        (time < maxProjectTime ? (
          <ControlButton
            disabled={isPlaying === PlaybackEnum.PLAYING || mapIsLoading}
            icon={<Play />}
            onClick={() => onPlay()}
          />
        ) : (
          <ControlButton
            replay
            disabled={isPlaying === PlaybackEnum.PLAYING || mapIsLoading}
            icon={<MdOutlineReplayCircleFilled size={28} />}
            onClick={() => replay()}
          />
        ))}
      <ControlButton
        disabled={isPlaying !== PlaybackEnum.PLAYING}
        icon={<Pause />}
        onClick={onPause}
      />
      <ControlButton
        disabled={isPlaying !== PlaybackEnum.PLAYING}
        icon={<Stop />}
        onClick={() => onStop()}
      />

      {mode === ModeEnum.SEQUENCE && (
        <ControlButton
          disabled={maxSceneTime <= 0}
          icon={<Next5sec />}
          onClick={() => skipFive()}
        />
      )}
      {mode === ModeEnum.PROJECT && (
        <ControlButton icon={<Next />} onClick={() => nextScene(findNextSceneTime())} />
      )}
      {mode === ModeEnum.SEQUENCE && (
        <ControlButton disabled={maxSceneTime <= 0} icon={<Next />} onClick={() => toEnd()} />
      )}
    </div>
  );
};

export default Player;
