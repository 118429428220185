import { v4 } from 'uuid';

import { MapLayersEnum } from '../enums/MapLayersEnum';
import { MapCityElement } from './MapCityElement';
import { TimeControlDef } from './TimeControlDef';

export const defaultStyle = { strokeColor: '#2596be', strokeWidth: 1, zindex: 1 };

export class VectorMapLayer {
  id = v4();
  name: string;
  baseMapUrl: string;
  type: MapLayersEnum;
  style: string;
  minZoom = 0;
  maxZoom = 22;
  createdBy: string;
  creationTime = new Date().getTime();
  zindex: number;
  timeControls: TimeControlDef[];
  enabled = true;
  cities: MapCityElement[] = [];
  customVectorDefTemplate = false;
  constructor(type: MapLayersEnum, projString: string, style: string, zindex?: number) {
    this.type = type;
    this.baseMapUrl = `${
      process.env.REACT_APP_BASE_MAP_URL
    }/mvtiles/v2/{z}/{x}/{y}.mvt?features=${type}&srs=${encodeURIComponent(projString)}}`;
    this.name = type.charAt(0).toUpperCase() + type.slice(1);
    this.style = style;
    this.zindex = zindex || 1;
  }
}
