import { useMutation } from 'react-query';

import { C9ProjectDef } from '../../../../model/definitions/C9ProjectDef';
import { axiosInstance } from '../../axiosInstance';

export const useApplyBiasGroupOnProject = () =>
  useMutation(
    async ({
      type,
      biasGroupId,
      body,
    }: {
      type: 'OBSERVED' | 'FORECAST';
      biasGroupId: string;
      body: C9ProjectDef;
    }): Promise<C9ProjectDef> => {
      const { data } = await axiosInstance.put(
        `biasGroup/apply/${type}/${biasGroupId}/project`,
        body,
      );
      return data;
    },
  );
