import { ScreenshotData } from '../../helpers/screenshotElement';
import { NO_THUMBNAIL_URL } from '../../model/constants/constants';
import { C9ProjectDef } from '../../model/definitions/C9ProjectDef';
import { C9ProjectDefTemplate } from '../../model/definitions/C9ProjectDefTemplate';
import { GribMapLayer } from '../../model/definitions/GribMapLayer';
import { LayerDefTemplate } from '../../model/definitions/LayerDefTemplate';
import { MapDefTemplate } from '../../model/definitions/MapDefTemplate ';
import { MapPanelDef } from '../../model/definitions/MapPanelDef';
import { RadarMapLayer } from '../../model/definitions/RadarMapLayer';
import { SatelliteMapLayer } from '../../model/definitions/SatelliteMapLayer';
import { SceneDef } from '../../model/definitions/SceneDef';
import { SceneDefTemplate } from '../../model/definitions/SceneDefTemplate';
import { SymbolLayerDef } from '../../model/definitions/SymbolLayerDef';
import { WeatherGeoPosterDefTemplate } from '../../model/definitions/WeatherGeoPosterDefTemplate';
import { SnapshotRequestDTO } from '../../model/DTO/SnapshotRequestDTO';
import { SnapshotResponseDTO } from '../../model/DTO/SnapshotResponseDTO';
import { QueryInfo } from '../../model/other/QueryInfo';
import { axiosInstance } from './axiosInstance';
import { buildQueryInfo } from './buildQueryInfo';
import { getRepoLayer, getRepoMap, getRepoScene } from './RepositoryAPI';

const getMyProjectTemplatesQueryInfo = async (query: QueryInfo) => {
  return buildQueryInfo(query, 'dashboard/my-project-templates');
};

const getPurchasedTemplatesQueryInfo = async (query: QueryInfo) => {
  return buildQueryInfo(query, 'dashboard/purchased-project-templates');
};

const getTemplateDetails = async (
  id: string,
  type: string,
): Promise<
  SceneDefTemplate | C9ProjectDefTemplate | MapDefTemplate | WeatherGeoPosterDefTemplate
> => {
  const res = await axiosInstance.get(`${type.toLowerCase()}-template/current/${id}`);
  return res.data;
};

const getLayerTemplateDetails = async (id: string): Promise<LayerDefTemplate> => {
  const res = await axiosInstance.get(`layer-template/current/${id}`);
  return res.data;
};

const saveProjectAsTemplate = async (data: {
  name: string;
  description: string;
  thumbnailURLs: ScreenshotData[];
  projectDef: C9ProjectDef;
}) => {
  const fData = new FormData();
  if (data.thumbnailURLs && data.thumbnailURLs.length) {
    data.thumbnailURLs.forEach((th) => {
      fData.append('thumbnailFiles', th.blob!);
    });
  }

  fData.append(
    'request',
    JSON.stringify({
      ...data,
      thumbnailURLs: [],
    }),
  );

  const res = await axiosInstance.post<C9ProjectDefTemplate>(`project-template`, fData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return res.data;
};

const updateProjectAsTemplate = async (arg: {
  templateId: string;
  projectDef: C9ProjectDef;
  thumbnailURLs: ScreenshotData[];
  name: string;
  description: string;
}) => {
  const { templateId, projectDef, thumbnailURLs, name, description } = arg;
  const fd = new FormData();
  const newFiles = thumbnailURLs.filter((t) => !!t.blob);
  newFiles.forEach((t) => {
    fd.append('thumbnailFiles', t.blob!);
  });

  const tempDef: C9ProjectDefTemplate = (await getTemplateDetails(
    templateId,
    'project',
  )) as C9ProjectDefTemplate;
  tempDef.name = name;
  tempDef.description = description;
  tempDef.c9ProjectDef = projectDef;
  fd.append('projectDefTemplate', JSON.stringify(tempDef));
  const res = await axiosInstance.put<C9ProjectDefTemplate>(`project-template`, fd, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return res.data;
};

const saveSceneAsTemplate = async (data: {
  name: string;
  description: string;
  thumbnailURLs: ScreenshotData[];
  sceneDef: SceneDef;
}) => {
  const fData = new FormData();
  if (data.thumbnailURLs && data.thumbnailURLs.length) {
    data.thumbnailURLs.forEach((th) => {
      fData.append('thumbnailFiles', th.blob!);
    });
  }

  fData.append(
    'request',
    JSON.stringify({
      ...data,
      thumbnailURLs: [],
    }),
  );
  const res = await axiosInstance.post<SceneDefTemplate>(`scene-template`, fData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return res.data;
};

const updateSceneAsTemplate = async (arg: {
  templateId: string;
  sceneDef: SceneDef;
  thumbnailURLs: ScreenshotData[];
  name: string;
  description: string;
}) => {
  const { templateId, sceneDef, thumbnailURLs, name, description } = arg;
  const fd = new FormData();

  const newFiles = thumbnailURLs.filter((t) => !!t.blob);
  newFiles.forEach((t) => {
    fd.append('thumbnailFiles', t.blob!);
  });

  const tempDef = await getRepoScene(templateId);
  tempDef.name = name;
  tempDef.description = description;
  tempDef.sceneDef = sceneDef;
  fd.append('sceneDefTemplate', JSON.stringify(tempDef));
  const res = await axiosInstance.put<SceneDefTemplate>(`scene-template`, fd, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return res.data;
};

const saveMapAsTemplate = async (data: {
  name: string;
  description: string;
  thumbnailURLs: ScreenshotData[];
  mapPanelDef: MapPanelDef;
}) => {
  const fData = new FormData();
  if (data.thumbnailURLs && data.thumbnailURLs.length) {
    data.thumbnailURLs.forEach((th) => {
      fData.append('thumbnailFiles', th.blob!);
    });
  }

  fData.append(
    'request',
    JSON.stringify({
      ...data,
      thumbnailURLs: [],
    }),
  );
  const res = await axiosInstance.post<MapDefTemplate>(`map-template`, fData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return res.data;
};

const updateMapAsTemplate = async (arg: {
  templateId: string;
  mapDef: MapPanelDef;
  thumbnailURLs: ScreenshotData[];
  name: string;
  description: string;
}) => {
  const { templateId, mapDef, thumbnailURLs, name, description } = arg;

  const fd = new FormData();
  const newFiles = thumbnailURLs.filter((t) => !!t.blob);
  newFiles.forEach((t) => {
    fd.append('thumbnailFiles', t.blob!);
  });
  /**Get template for update */
  const tempDef = await getRepoMap(templateId);
  tempDef.name = name;
  tempDef.description = description;
  tempDef.mapDef = mapDef;

  fd.append('mapDefTemplate', JSON.stringify(tempDef));
  const res = await axiosInstance.put<MapDefTemplate>(`map-template`, fd, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return res.data;
};

const saveLayerAsTemplate = async (data: {
  name: string;
  description: string;
  thumbnailURLs: ScreenshotData[];
  layerPanelDef: GribMapLayer | RadarMapLayer | SatelliteMapLayer | SymbolLayerDef;
}) => {
  const fData = new FormData();
  if (data.thumbnailURLs && data.thumbnailURLs.length) {
    data.thumbnailURLs.forEach((th) => {
      fData.append('thumbnailFiles', th.blob!);
    });
  }

  fData.append(
    'request',
    JSON.stringify({
      ...data,
      thumbnailURLs: [],
    }),
  );
  const res = await axiosInstance.post(`layer-template`, fData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return res.data;
};

const updateLayerAsTemplate = async (data: {
  templateId: string;
  name: string;
  description: string;
  thumbnailURLs: ScreenshotData[];
  layerPanelDef: GribMapLayer | RadarMapLayer | SatelliteMapLayer | SymbolLayerDef;
}) => {
  const { layerPanelDef, thumbnailURLs, name, description, templateId } = data;
  const fd = new FormData();

  const newFiles = thumbnailURLs.filter((t) => !!t.blob);
  newFiles.forEach((t) => {
    fd.append('thumbnailFiles', t.blob!);
  });

  const tempDef = await getRepoLayer(templateId);
  tempDef.name = name;
  tempDef.description = description;
  tempDef.layerPanelDef = layerPanelDef;
  fd.append('layerDefTemplate', JSON.stringify(tempDef));
  const res = await axiosInstance.put(`layer-template`, fd, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return res.data;
};

const getTemplateById = async (type: string, id: string) => {
  const res = await axiosInstance.get(`${type}/${id}`);
  return res.data;
};

const getSnapshot = async (req: SnapshotRequestDTO): Promise<string> => {
  const res = await axiosInstance.post<SnapshotResponseDTO>(`snapshot`, req);
  if (res.data?.status === 'FAILED') return NO_THUMBNAIL_URL;
  return res.data.pngUrl;
};

export {
  getLayerTemplateDetails,
  getMyProjectTemplatesQueryInfo,
  getPurchasedTemplatesQueryInfo,
  getSnapshot,
  getTemplateById,
  getTemplateDetails,
  saveLayerAsTemplate,
  saveMapAsTemplate,
  saveProjectAsTemplate,
  saveSceneAsTemplate,
  updateLayerAsTemplate,
  updateMapAsTemplate,
  updateProjectAsTemplate,
  updateSceneAsTemplate,
};
