import { v4 } from 'uuid';

export class LogicalGroupElement {
  id = v4();
  name: string;
  parentGroupId = '00000000-0000-0000-0000-000000000000';
  orderNumInParentGroup: number;
  constructor(name?: string, order?: number) {
    if (name) this.name = name;
    if (order) this.orderNumInParentGroup = order;
  }
}
