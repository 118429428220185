import { Button } from 'flowbite-react';
import ButtonGroup from 'flowbite-react/lib/esm/components/Button/ButtonGroup';
import { FiAlignCenter, FiAlignJustify, FiAlignLeft, FiAlignRight } from 'react-icons/fi';

interface FontAlignmentPickerProps {
  onClick: (e: FontAlignment) => void;
  value?: FontAlignment | string;
}
export const FontAlignmentPicker = ({ onClick, value }: FontAlignmentPickerProps) => {
  const activeStyle = { background: 'white' };
  return (
    <div className="font-alignment">
      <ButtonGroup className={'flex items-center'}>
        <Button
          size={'sm'}
          style={value === 'left' || value === undefined ? activeStyle : undefined}
          onClick={() => {
            onClick('left');
          }}
        >
          <FiAlignLeft color={value === 'left' || value === undefined ? '#05152b' : undefined} />
        </Button>
        <Button
          size={'sm'}
          style={value === 'center' || value === undefined ? activeStyle : undefined}
          color="gray"
          onClick={() => {
            onClick('center');
          }}
        >
          <FiAlignCenter
            color={value === 'center' || value === undefined ? '#05152b' : undefined}
          />
        </Button>
        <Button
          size={'sm'}
          style={value === 'justify' || value === undefined ? activeStyle : undefined}
          color="gray"
          onClick={() => {
            onClick('justify');
          }}
        >
          <FiAlignJustify
            color={value === 'justify' || value === undefined ? '#05152b' : undefined}
          />
        </Button>
        <Button
          size={'sm'}
          style={value === 'right' || value === undefined ? activeStyle : undefined}
          color="gray"
          onClick={() => {
            onClick('right');
          }}
        >
          <FiAlignRight color={value === 'right' || value === undefined ? '#05152b' : undefined} />
        </Button>
      </ButtonGroup>
    </div>
  );
};
