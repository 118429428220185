import { Select } from 'antd';
import { Button, Label, Modal, TextInput } from 'flowbite-react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import UploadFiles from '../components/upload/UploadFiles';
import useUpdateInfo from '../queries-NEW/useEditBasicInfo';
import { UpdateInterface } from '../templates-NEW/Projects';

interface UploadModalProps {
  open: boolean;
  onClose: () => void;
  type: 'audio' | 'image' | 'video' | 'custom_map' | 'animation' | 'symbol' | 'custom_vector';
}

const UploadModal = ({ open, onClose, type }: UploadModalProps) => {
  const [files, setFiles] = useState<UpdateInterface[]>([]);
  const updateProject = useUpdateInfo();
  const [align, setAlign] = useState<string>('center');
  const [disabled, setDisabled] = useState<boolean>(false);

  const handleUpdate = (files: UpdateInterface[]) =>
    files.map((file) => {
      if (file.newDescription === '') {
        file.newDescription = file.newName + '[' + moment().format('YYYYMMDDhhmm') + ']';
      }

      updateProject.mutate(file, {
        onSuccess: async () => {
          toast.success("Item's basic info updated");
          setTimeout(() => {
            onClose();
          }, 500);
        },
        onError: async () => toast.error('Error updating basic info'),
      });
    });

  const handleFile = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, id: string) => {
    setFiles(
      files.map((file: UpdateInterface) =>
        file.id === id ? Object.assign({}, file, { [e.target.name]: e.target.value }) : file,
      ),
    );
  };

  const handleSymbolType = (e: string, id: string) => {
    setFiles(
      files.map((file: UpdateInterface) =>
        file.id === id ? Object.assign({}, file, { newSymbolType: e }) : file,
      ),
    );
  };

  useEffect(() => {
    if (files.length > 2) {
      setAlign('baseline');
    }
    const checkNames = files.some((element) => element.newName === '');
    setDisabled(checkNames);
  }, [files]);

  return (
    <Modal show={open} onClose={onClose} style={{ alignItems: align }}>
      <Modal.Header>Upload</Modal.Header>
      <Modal.Body>
        <div className="flex flex-wrap">
          {files.length > 0 &&
            files.map((file) => {
              return (
                <div key={file.id} className="flex w-full gap-4">
                  <div className="mb-2 block mt-2 w-full">
                    <Label value="Name" />
                    <TextInput
                      type="text"
                      name="newName"
                      sizing="md"
                      value={file.newName}
                      onChange={(e) => handleFile(e, file.id)}
                    />
                  </div>
                  <div className="mb-2 block mt-2 w-full">
                    <Label value="Description" />
                    <TextInput
                      type="text"
                      name="newDescription"
                      sizing="md"
                      value={file.newDescription}
                      onChange={(e) => handleFile(e, file.id)}
                    />
                  </div>
                  {type === 'symbol' && (
                    <div className="mb-2 block mt-2 w-full">
                      <Label value="Symbol type" />
                      <Select
                        value={file.newSymbolType}
                        onChange={(e) => handleSymbolType(e, file.id)}
                        options={[
                          { value: 'ARROW', label: 'ARROW' },
                          { value: 'WIND', label: 'WIND' },
                        ]}
                      />
                    </div>
                  )}
                </div>
              );
            })}
        </div>
        <div className="mb-2 block mt-2">
          <UploadFiles fileType={type} setFiles={setFiles} />
        </div>
        <div className="mt-8 flex justify-end">
          <Button disabled={disabled} onClick={() => handleUpdate(files)}>
            Submit
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UploadModal;
