import { v4 } from 'uuid';

import { C9Object } from './C9Object';

export class MapStyleDef extends C9Object {
  externalId: string = v4();
  thumbnailUrl: string;
  basicPreviewUrl: string;
  styleDefinition: string;
}
