export const createListConfig = [
  { name: 'Template creator', link: '' },
  { name: 'Rendering options', link: '' },
  { name: 'Benefit 3', link: '' },
  { name: 'Benefit 4', link: '' },
  { name: 'Benefit 5', link: '' },
];

export const supportListConfig = [
  { name: 'Help', link: '' },
  { name: 'Contact', link: '' },
];
