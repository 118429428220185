import React, { useState } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { Popover, PopoverPosition } from 'react-tiny-popover';

import styles from './ManualElement.module.scss';

interface Props {
  children: JSX.Element;
  content: JSX.Element;
  position?: PopoverPosition[];
  containerStyle?: Partial<CSSStyleDeclaration>;
}

export const ManualElement: React.FC<Props> = ({ children, content, position, containerStyle }) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  return (
    <div className="relative">
      <Popover
        isOpen={isPopoverOpen}
        containerStyle={
          containerStyle ?? {
            backgroundColor: '#fff',
            padding: '10px',
            borderRadius: '10px',
            zIndex: '2',
          }
        }
        positions={position ?? ['top', 'left']} // if you'd like, you can limit the positions
        padding={10} // adjust padding here!
        reposition={false} // prevents automatic readjustment of content position that keeps your popover content within its parent's bounds
        onClickOutside={() => setIsPopoverOpen(false)} // handle click events outside of the popover/target here!
        content={(
          { position, nudgedLeft, nudgedTop }, // you can also provide a render function that injects some useful stuff!
        ) => <div>{content}</div>}
      >
        <button className={`${styles.icon}`} onClick={() => setIsPopoverOpen((o) => !o)}>
          <AiOutlineInfoCircle />
        </button>
      </Popover>

      {children}
    </div>
  );
};

export default ManualElement;
