import './style.scss';

import { useState } from 'react';

function CircleInput({
  value,
  setValue,
}: {
  value: string | number;
  setValue: (value: string | number) => void;
}) {
  const [isDragging, setIsDragging] = useState<boolean>(false);

  const handleMouseDown = () => {
    setIsDragging(true);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (isDragging) {
      updateDirection(event);
    }
  };

  const updateDirection = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const rect = event.currentTarget.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    const angle = Math.atan2(y - 12.5, x - 12.5) * (180 / Math.PI) + 90;
    setValue((angle < 0 ? angle + 360 : angle).toFixed(0));
  };

  const direction = value;

  return (
    <div className="circle-input-container">
      <div
        className="circle"
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
      >
        <div
          className="marker"
          style={{ transform: `translate(-50%, 0%) rotate(${direction}deg)` }}
        ></div>
      </div>
    </div>
  );
}

export default CircleInput;
