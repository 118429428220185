import { InputNumber, notification, Select } from 'antd';
import { ToggleSwitch } from 'flowbite-react';
import { cloneDeep } from 'lodash';
import moment from 'moment-timezone';
import React, { useEffect, useRef, useState } from 'react';
import { IoCameraOutline, IoSaveOutline } from 'react-icons/io5';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import Button from '../../../atoms/button/Button';
import Checkbox from '../../../atoms/checkbox/Checkbox';
import { createPlayer } from '../../../components/timeline/helpers';
import { useApplyBiasGroupOnProject } from '../../../core/api/bias/bias-group/useApplyBiasGroupOnProject';
import { useGetBiasGroups } from '../../../core/api/bias/bias-group/useGetBiasGroups';
import { setProjectThumbnail } from '../../../core/api/ProjectsAPI';
import { saveProjectAsTemplate, updateProjectAsTemplate } from '../../../core/api/TemplatesAPI';
import { ScreenshotData } from '../../../helpers/screenshotElement';
import { useImplicitSave } from '../../../hooks/useImplicitSave';
import { GAMMA_CORRECTION, SATURATION } from '../../../model/constants/constants';
import { C9ProjectDef } from '../../../model/definitions/C9ProjectDef';
import { HistoricalTypeEnum } from '../../../model/enums/HistoricalTypeEnum';
import { ActiveDef, setProjectToPlay } from '../../../store/slices/active-slice';
import { setProject, updateProject } from '../../../store/slices/project-slice';
import { RootState } from '../../../store/store';
import { Panel } from './components/Panel';
import { ExportDefs } from './panels/projectPanels/exportDefs';
import { OutputVideoCompressionProperties } from './panels/projectPanels/OutputVideoCompressionProperties';
import { SkippedTime } from './panels/projectPanels/SkippedTime';
import { VoiceOverProperties } from './panels/projectPanels/VoiceOverProperties';
import { SceneTransitionProperties } from './panels/SceneTransitionProperties';
import { TranslationPanel } from './panels/TranslationPanel';
import styles from './Properties.module.scss';
import SaveAsTemplateModal from './SaveAsTemplateModal';
import SetThumbnailModal from './SetThumbnailModal';
import GridItem from './shared/GridItem';
import GridWrapper from './shared/GridWrapper';

const { Option } = Select;
const ProjectProperties: React.FC = () => {
  const dispatch = useDispatch();
  const [templateOpened, setTemplateOpened] = useState(false);
  const [thumbnailOpened, setThumbnailOpened] = useState(false);
  const thumbnailsTempRef = useRef<{ id: string; url: string; blob: Blob | null }[]>([]);

  const implicitSave = useImplicitSave();
  const project = useSelector<RootState, C9ProjectDef>((state) => state.project.present.project);
  const { activeFramerate, syncSpace } = useSelector<RootState>(
    (state) => state.active,
  ) as ActiveDef;
  const { data: biasFiltersObserved } = useGetBiasGroups('OBSERVED');
  const { data: biasFiltersForecast } = useGetBiasGroups('FORECAST');
  const { mutate: applyBias, isLoading: applyingBias } = useApplyBiasGroupOnProject();

  const existsAsTemplate = Boolean(project.templateId && project.templateVersionId);

  function onUpdateProject(newValue: any, propertyPath: Leaves<C9ProjectDef>) {
    dispatch(updateProject({ newValue, propertyPath }));
  }
  const changeVolume = (volume: number) => onUpdateProject(volume, 'voiceOver.volume');
  //@ts-ignore
  const removeVoiceOver = () => onUpdateProject(undefined, 'voiceOver');
  const saveProjectTemplate = useMutation(saveProjectAsTemplate, {
    onSuccess: (data) => {
      const proj = cloneDeep(project);
      proj.templateId = data.id;
      proj.templateVersionId = data.versionId;
      implicitSave(proj);
      toast.success('Successfully added project template!');
      setTemplateOpened(false);
    },
  });

  useEffect(() => {
    dispatch(
      setProjectToPlay({ projectToPlay: createPlayer(project, syncSpace, activeFramerate) }),
    );
  }, [project.sceneDefs]);
  const updateProjectTemplate = useMutation(updateProjectAsTemplate, {
    onSuccess: (data) => {
      const proj = cloneDeep(project);
      proj.templateVersionId = data.versionId;
      implicitSave(proj);
      toast.success('Successfully updated project template!');
      setTemplateOpened(false);
    },
  });
  const setThumbnailMut = useMutation(setProjectThumbnail, {
    onSuccess: (data) => {
      const proj = cloneDeep(project);
      proj.thumbnailUrls = [...data.thumbnailUrls, ...thumbnailsTempRef.current.map((c) => c.url)];
      thumbnailsTempRef.current = [];
      setThumbnailOpened(false);
      implicitSave(proj);
    },
    onError: () => {
      thumbnailsTempRef.current = [];
      setThumbnailOpened(false);
    },
  });

  const timeZones = moment.tz.names();

  function onTemplateSave() {
    setTemplateOpened(true);
  }
  function onThumbnailSave() {
    setThumbnailOpened(true);
  }

  function onAddToRepoFlagChange(ev: React.ChangeEvent<HTMLInputElement>) {
    onUpdateProject(ev.target.checked, 'automaticallyAddToRepoVAOutput');
  }

  function onConfirm(formData: {
    name: string;
    description: string;
    thumbnailURLs: ScreenshotData[];
  }) {
    const { name, description, thumbnailURLs } = formData;
    if (!existsAsTemplate) {
      saveProjectTemplate.mutate({
        name,
        description,
        thumbnailURLs,
        projectDef: project,
      });
    }
    if (existsAsTemplate) {
      updateProjectTemplate.mutate({
        templateId: project!.templateId,
        thumbnailURLs,
        projectDef: project,
        name,
        description,
      });
    }
  }

  function onThumbnail(
    data: {
      id: string;
      url: string;
      blob: Blob | null;
    }[],
  ) {
    const newFiles = data.filter((d) => !!d.blob);
    const existingUrls = data.filter((d) => d.blob === null);
    if (newFiles.length) {
      thumbnailsTempRef.current = existingUrls;
      setThumbnailOpened(false);
      setThumbnailMut.mutate({
        thumbnail: newFiles,
        versionId: project.versionId,
      });
    } else {
      const proj = cloneDeep(project);
      proj.thumbnailUrls = [...existingUrls.map((e) => e.url)];
      implicitSave(proj);
      setThumbnailOpened(false);
    }
  }

  const onUpdateExportDefs = (val: string, prop: string) => {
    const newExportDefs = [...project.exportDefs];
    newExportDefs[0] = { ...newExportDefs[0], [prop]: val };
    // adapt update project method to accept exportDefs
    onUpdateProject(newExportDefs[0], 'exportDefs.0');
  };

  const onUpdateOutputVideoCompression = (val: string) => {
    onUpdateProject(val, 'outputVideoCompression');
  };

  const onHistoricalTypeChange = (e: boolean) => {
    if (e) {
      onUpdateProject(HistoricalTypeEnum.HISTORICAL, 'historicalType');
    } else {
      onUpdateProject(HistoricalTypeEnum.RELATIVE, 'historicalType');
    }
  };
  const applyBiasGroupOnScene = (type: 'FORECAST' | 'OBSERVED', id: string) => {
    project &&
      applyBias(
        { type, biasGroupId: id, body: project },
        {
          onSuccess: (data) => {
            dispatch(setProject({ project: data }));
            notification.success({ message: 'Group Filter Applied!' });
          },
        },
      );
  };
  const filterSelectorObserved = () => {
    const options = biasFiltersObserved?.map((filter) => (
      <Option value={filter.id} key={filter.id}>
        {filter.name}
      </Option>
    ));
    return (
      <Select
        disabled={applyingBias}
        style={{ maxWidth: 200 }}
        onChange={(e) => applyBiasGroupOnScene('OBSERVED', e)}
      >
        {options}
      </Select>
    );
  };
  const filterSelectorForecast = () => {
    const options = biasFiltersForecast?.map((filter) => (
      <Option value={filter.id} key={filter.id}>
        {filter.name}
      </Option>
    ));
    return (
      <Select
        disabled={applyingBias}
        style={{ maxWidth: 200 }}
        onChange={(e) => applyBiasGroupOnScene('FORECAST', e)}
      >
        {options}
      </Select>
    );
  };

  return (
    <>
      <Panel
        actions={[
          <Button
            key={project.id + 'save_template'}
            buttonType="border"
            icon={<IoSaveOutline />}
            label={existsAsTemplate ? 'Save new template version' : 'Save as template'}
            onClick={onTemplateSave}
            className="flex items-center gap-1 property-grey-buttons"
          />,

          <Button
            key={project.id + 'save_thumbnail'}
            buttonType="border"
            label="Set thumbnail"
            icon={<IoCameraOutline />}
            onClick={onThumbnailSave}
            className="property-grey-buttons"
          />,
        ]}
      >
        <div
          className={'mb-2 subheader'}
          style={{ marginTop: '15px', justifyContent: 'space-between' }}
        >
          {project?.name ? project.name : 'Project properties'}
          <Checkbox
            label="Add Videos Rendered To Repository"
            checked={project?.automaticallyAddToRepoVAOutput}
            onChange={onAddToRepoFlagChange}
          />
        </div>
        <div className="prop-wrapper">
          <GridWrapper>
            <GridItem
              label={'Name:'}
              item={
                <input
                  className={styles.inputWrap}
                  value={project?.name ? project.name : ''}
                  onChange={(e) => onUpdateProject(e.target.value, 'name')}
                  type="text"
                />
              }
            />

            <GridItem
              label={'Description:'}
              item={
                <input
                  type={'text'}
                  value={project?.description ? project.description : ''}
                  onChange={(e) => onUpdateProject(e.target.value, 'description')}
                  className={styles.inputWrap}
                  placeholder="Type description"
                />
              }
            />
            <GridItem
              label={'Render Timezone:'}
              item={
                <select
                  value={project.properties.timezone}
                  //@ts-ignore
                  onChange={(e) => onUpdateProject(e.target.value, 'properties.timezone')}
                  style={{ width: '100%' }}
                >
                  {timeZones.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              }
            />
            <GridItem
              noBorderBg
              label={'Fixed time:'}
              item={
                <ToggleSwitch
                  checked={project.historicalType === HistoricalTypeEnum.HISTORICAL}
                  label={''}
                  onChange={onHistoricalTypeChange}
                />
              }
            />

            <GridItem
              noBorderBg
              label={'Gamma:'}
              item={
                <InputNumber
                  value={
                    project?.renderQualityDef?.gammaCorrection
                      ? project?.renderQualityDef?.gammaCorrection
                      : GAMMA_CORRECTION
                  }
                  onChange={(e) => onUpdateProject(e, 'renderQualityDef.gammaCorrection')}
                  step={0.2}
                  min={0}
                  max={2}
                />
              }
            />
            <GridItem
              noBorderBg
              label={'Saturation:'}
              item={
                <InputNumber
                  value={
                    project?.renderQualityDef?.saturation
                      ? project.renderQualityDef?.saturation
                      : SATURATION
                  }
                  onChange={(e) => onUpdateProject(e, 'renderQualityDef.saturation')}
                  step={0.2}
                  min={0}
                  max={2}
                />
              }
            />
            <GridItem
              noBorderBg
              label={`Apply filter on obsv. data:`}
              item={filterSelectorObserved()}
            />
            <GridItem
              noBorderBg
              label={`Apply filter on for. data:`}
              item={filterSelectorForecast()}
            />
          </GridWrapper>
          <SkippedTime skips={project.skippedTime} />
          {project.voiceOver && (
            <VoiceOverProperties
              voiceOver={project.voiceOver}
              changeVolume={changeVolume}
              remove={removeVoiceOver}
            />
          )}
          <SceneTransitionProperties scenes={project.sceneDefs} frameRate={activeFramerate} />
          <ExportDefs exportDefs={project.exportDefs} onUpdateExportDefs={onUpdateExportDefs} />
          <OutputVideoCompressionProperties
            outputVideoCompression={project.outputVideoCompression ?? undefined}
            onUpdateOutputVideoCompression={onUpdateOutputVideoCompression}
          />
          <TranslationPanel />
        </div>
      </Panel>
      {project && templateOpened && (
        <SaveAsTemplateModal
          entityName={project.name}
          onClose={() => setTemplateOpened(false)}
          onConfirm={onConfirm}
          opened={templateOpened}
          loading={saveProjectTemplate.isLoading}
          templateId={project.templateId}
          type={'project'}
        />
      )}
      {thumbnailOpened && (
        <SetThumbnailModal
          opened={thumbnailOpened}
          onClose={() => setThumbnailOpened(false)}
          onConfirm={onThumbnail}
          loading={setThumbnailMut.isLoading}
          project={project}
          type={'project'}
        />
      )}
    </>
  );
};

export default ProjectProperties;
