import { Button, ToggleSwitch } from 'flowbite-react';
import { cloneDeep, uniqBy } from 'lodash';
import React, { useState } from 'react';
import { AiOutlineCaretDown, AiOutlineCaretUp, AiOutlineEdit, AiOutlinePlus } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';

import Checkbox from '../../../atoms/checkbox/Checkbox';
import ColorPickerRefactor from '../../../atoms/color-picker-refactor/ColorPickerRefactor';
import { useGetFonts } from '../../../core/api/useGetFonts';
import { usePropertyGridActive } from '../../../hooks/usePropertyGridActive';
import { MAX_ZINDEX_VALUE } from '../../../model/constants/constants';
import { CityGeoPosterDef } from '../../../model/definitions/CityGeoPosterDef';
import { MapPanelDef } from '../../../model/definitions/MapPanelDef';
import { VectorMapLayer } from '../../../model/definitions/VecorMapLayer';
import { MapLayersEnum } from '../../../model/enums/MapLayersEnum';
import { getZindexOfMapLayer } from '../../../molecules/mapElement/helpers';
import { ActiveDef, setPropertyGridActiveHash } from '../../../store/slices/active-slice';
import { updateMapLayer } from '../../../store/slices/project-slice';
import { RootState } from '../../../store/store';
import InputNumber from '../../marketplace-new/atoms/FormatNumber/FormatNumber';
import AddCitiesModal from '../modals/AddCitiesModal';
import EditCitiesModal from '../modals/EditCitiesModal';
import {
  bordersPropertiesDeps,
  boundaryLinesLandPropertiesDeps,
  coastlinePropertiesDeps,
  countriesPropertiesDeps,
  disputedAreasPropertiesDeps,
  maritimeIndicatorPropertiesDeps,
  riversPropertiesDeps,
  roadsPropertiesDeps,
  statesProvincesPropertiesDeps,
  usastatesPropertiesDeps,
  vectorLayersPropertiesDeps,
} from './constants/propertiesConstants';
import GridItem from './shared/GridItem';
import GridWrapper from './shared/GridWrapper';

type Props = {
  mapLayer: MapPanelDef;
};

export const projMerc =
  '+proj=merc +a=6378137 +b=6378137 +lat_ts=0 +lon_0=0 +x_0=0 +y_0=0 +k=1 +units=m +nadgrids=@null +wktext +no_defs +type=crs';

function BaseMapLayers({ mapLayer }: Props) {
  const dispatch = useDispatch();

  const [citiesOpen, setCitiesOpen] = useState(false);
  const [citiesModal, setCitiesModal] = useState(false);
  const [citiesEditModal, setCitiesEditModal] = useState(false);
  const { data: fonts } = useGetFonts();
  // const oceanMask = useSelector<RootState, boolean>((state) => state.active.oceanMask);
  const { activeScene, activeElement } = useSelector<RootState, ActiveDef>((state) => state.active);
  const { isOpened, lastFocused } = usePropertyGridActive(vectorLayersPropertiesDeps);
  const [openLayers, setOpenLayers] = useState({
    [MapLayersEnum.BORDERS]: bordersPropertiesDeps.includes(lastFocused),
    [MapLayersEnum.USA0STATES]: usastatesPropertiesDeps.includes(lastFocused),
    [MapLayersEnum.COASTLINE]: coastlinePropertiesDeps.includes(lastFocused),
    [MapLayersEnum.ROADS]: roadsPropertiesDeps.includes(lastFocused),
    [MapLayersEnum.RIVERS]: riversPropertiesDeps.includes(lastFocused),
    [MapLayersEnum.STATES_PROVINCES]: statesProvincesPropertiesDeps.includes(lastFocused),
    [MapLayersEnum.BOUNDARY_LINES_LAND]: boundaryLinesLandPropertiesDeps.includes(lastFocused),
    [MapLayersEnum.BOUNDARY_LINES_MARITIME_INDICATOR]:
      maritimeIndicatorPropertiesDeps.includes(lastFocused),
    [MapLayersEnum.BOUNDARY_BREAKAWAY_DISPUTED_AREAS]:
      disputedAreasPropertiesDeps.includes(lastFocused),
    [MapLayersEnum.COUNTRIES]: countriesPropertiesDeps.includes(lastFocused),
  });
  const [open, setOpen] = React.useState<boolean>(isOpened);
  const mapLayersArray: { name: string; value: string }[] = Object.keys(MapLayersEnum)
    .filter((key) => isNaN(Number(key))) // Filter out numeric enum keys
    .map((key) => ({ name: key, value: (MapLayersEnum as Record<string, string>)[key] }));

  function onMapLayerChange(propertyPath: Leaves<MapPanelDef> | keyof MapPanelDef, newValue: any) {
    dispatch(
      updateMapLayer({
        activeScene,
        newValue,
        elementId: activeElement,
        propertyPath,
      }),
    );
  }

  function defaultStyle(value: MapLayersEnum) {
    let strokeColor;
    let strokeWidth;
    const zindex = 1;
    switch (value) {
      case MapLayersEnum.BORDERS:
        strokeColor = '#c8c800';
        strokeWidth = 2;
        break;
      case MapLayersEnum.COASTLINE:
        strokeColor = '#dcdcf0';
        strokeWidth = 2;
        break;
      case MapLayersEnum.RIVERS:
        strokeColor = '#b4b4f0';
        strokeWidth = 2;
        break;
      case MapLayersEnum.STATES_PROVINCES:
        strokeColor = '#1e1e1e';
        strokeWidth = 1.5;
        break;
      case MapLayersEnum.ROADS:
        strokeColor = '#000000';
        strokeWidth = 1.2;
        break;
      default:
        strokeColor = '#2596be';
        strokeWidth = 1;
    }
    return { strokeColor, strokeWidth, zindex };
  }

  function changeLayers(value: MapLayersEnum, checked: boolean, emit?: boolean) {
    if (emit) onFocus(value);
    const currLayers = cloneDeep(mapLayer?.wdSpace[0].vectorMapLayers);
    if (checked && !currLayers.map((l) => l.type).includes(value)) {
      currLayers?.push(
        new VectorMapLayer(
          value,
          mapLayer.baseMapSetup?.baseMapConfigurationProj4 || projMerc,
          JSON.stringify(defaultStyle(value)),
          getZindexOfMapLayer(mapLayer),
        ),
      );
    }
    if (checked && currLayers.map((l) => l.type).includes(value)) {
      const layerFound = currLayers.find((l) => l.type === value);
      layerFound && (layerFound.enabled = true);
    }
    if (!checked && currLayers.map((l) => l.type)?.includes(value)) {
      const layerFound = currLayers.find((l) => l.type === value);
      layerFound && (layerFound.enabled = false);
    }
    // @ts-ignore
    onMapLayerChange('wdSpace[0].vectorMapLayers', currLayers);
  }

  function isChecked(value: MapLayersEnum) {
    const layerFound = mapLayer.wdSpace[0].vectorMapLayers?.find((l) => l.type === value);
    if (layerFound) return layerFound.enabled;
    return false;
  }

  function handleOpened(layer: MapLayersEnum) {
    setOpenLayers((l) => ({ ...l, [layer]: !l[layer] }));
  }

  function getLayer(val: MapLayersEnum) {
    return mapLayer.wdSpace[0].vectorMapLayers.find((l) => l.type === val);
  }

  function getStyle(layer: MapLayersEnum) {
    const layerFound = mapLayer.wdSpace[0].vectorMapLayers.find((l) => l.type === layer);
    return JSON.parse(layerFound!.style);
  }

  function onCitiesSelect(cities: CityGeoPosterDef[]) {
    onMapLayerChange('cityPosters', uniqBy([...mapLayer.cityPosters, ...cities], 'id'));
    setCitiesModal(false);
  }

  function onCitiesEditSelect(cities: CityGeoPosterDef[]) {
    onMapLayerChange('cityPosters', uniqBy([...cities], 'id'));
    setCitiesEditModal(false);
  }

  function handlePropChange(
    layer: MapLayersEnum,
    propertyPath: keyof VectorMapLayer | 'strokeColor' | 'strokeWidth',
    newValue: string,
  ) {
    onFocus(`${layer}.${propertyPath}`);
    const currLayers = cloneDeep(mapLayer?.wdSpace[0].vectorMapLayers);
    const layerFound = currLayers.find((l) => l.type === layer);
    if (
      /**fillColor not used anymore */
      propertyPath === 'strokeColor' ||
      propertyPath === 'strokeWidth'
    ) {
      const currStyle = JSON.parse(layerFound!.style);
      if (propertyPath === 'strokeWidth') {
        currStyle[propertyPath] = Number(newValue);
        layerFound!.style = JSON.stringify(currStyle);
      } else {
        currStyle[propertyPath] = newValue;
        layerFound!.style = JSON.stringify(currStyle);
      }
    } else {
      // @ts-ignore
      layerFound![propertyPath] = Number(newValue);
    }
    // @ts-ignore
    onMapLayerChange('wdSpace[0].vectorMapLayers', currLayers);
  }

  function onFocus(path: string) {
    dispatch(setPropertyGridActiveHash({ activeElement, focusedEl: path }));
  }

  return (
    <>
      <div
        className={`mb-2 subheader layer-header ${citiesOpen ? 'layer-header-active' : ''}`}
        onClick={() => setCitiesOpen(!citiesOpen)}
      >
        <div className="layer-title" style={{ paddingLeft: '20px' }}>
          {/* {citiesOpen ? <AiOutlineCaretUp /> : <AiOutlineCaretDown />} */}
          <span>Cities</span>
        </div>
        <Button
          color="cyan"
          onClick={() => {
            setCitiesModal(true);
          }}
        >
          <AiOutlinePlus /> Add Cities
        </Button>
        <Button
          color="cyan"
          onClick={() => {
            setCitiesEditModal(true);
          }}
          disabled={!mapLayer.cityPosters.length}
        >
          <AiOutlineEdit /> Edit Cities
        </Button>
        <div className="BaseMapLayers__checkbox-wrap">
          <Checkbox
            checked={mapLayer.lockCitiesPosition || false}
            label="Lock position"
            onChange={(e) => onMapLayerChange('lockCitiesPosition', e.target.checked)}
          />
        </div>
        <div className="ml-5">
          <ToggleSwitch
            label="Show cities"
            checked={mapLayer.showCities}
            onChange={(e) => onMapLayerChange('showCities', e)}
          />
        </div>
      </div>
      <div
        className={`mb-2 subheader layer-header ${open ? 'layer-header-active' : ''}`}
        onClick={() => setOpen(!open)}
      >
        <div className="layer-title">
          {open ? <AiOutlineCaretUp /> : <AiOutlineCaretDown />}
          <span>Vector map layers</span>
        </div>
      </div>
      <div className="sub-tab-wrapper">
        {open &&
          mapLayersArray.map((val) => (
            <React.Fragment key={val.value}>
              <div
                key={val.value}
                className={`mb-2 subheader layer-header ${
                  openLayers[val.value as MapLayersEnum] ? 'layer-header-active' : ''
                }`}
                onClick={() => handleOpened(val.value as MapLayersEnum)}
              >
                <div className="layer-title">
                  {openLayers[val.value as MapLayersEnum] ? (
                    <AiOutlineCaretUp />
                  ) : (
                    <AiOutlineCaretDown />
                  )}
                </div>
                <ToggleSwitch
                  label={''}
                  checked={isChecked(val.value as MapLayersEnum)}
                  onChange={(checked) => changeLayers(val.value as MapLayersEnum, checked, true)}
                />
                {val.name.toLocaleUpperCase().replace('0', ' ').replaceAll('_', ' ')}
              </div>
              {openLayers[val.value as MapLayersEnum] &&
              getLayer(val.value as MapLayersEnum) &&
              getLayer(val.value as MapLayersEnum)?.enabled ? (
                <div className="prop-wrapper" key={val.value + 1}>
                  <GridWrapper>
                    <GridItem
                      noBorderBg
                      label="Stroke color:"
                      item={
                        <ColorPickerRefactor
                          value={getStyle(val.value as MapLayersEnum).strokeColor}
                          onChange={(e) =>
                            handlePropChange(val.value as MapLayersEnum, 'strokeColor', e)
                          }
                        />
                      }
                    />
                    <GridItem
                      label="Stroke width:"
                      item={
                        <InputNumber
                          value={getStyle(val.value as MapLayersEnum).strokeWidth}
                          onInputChange={(e) =>
                            handlePropChange(val.value as MapLayersEnum, 'strokeWidth', e)
                          }
                          onFocus={() => onFocus(`${val}.strokeWidth`)}
                          autoFocus={lastFocused === `${val}.strokeWidth`}
                        />
                      }
                    />
                    <GridItem
                      label="Layer level:"
                      item={
                        <InputNumber
                          value={getLayer(val.value as MapLayersEnum)!.zindex}
                          onInputChange={(e) =>
                            handlePropChange(val.value as MapLayersEnum, 'zindex', e)
                          }
                          onFocus={() => onFocus(`${val}.zindex`)}
                          autoFocus={lastFocused === `${val}.zindex`}
                          step={1}
                          min={0}
                          max={MAX_ZINDEX_VALUE}
                        />
                      }
                    />
                  </GridWrapper>
                </div>
              ) : null}
            </React.Fragment>
          ))}
        {citiesModal && (
          <AddCitiesModal
            opened={citiesModal}
            onClose={() => setCitiesModal(false)}
            onCitiesSelect={onCitiesSelect}
            mapDef={mapLayer}
            fonts={fonts}
          />
        )}
        {citiesEditModal && (
          <EditCitiesModal
            opened={citiesEditModal}
            onClose={() => setCitiesEditModal(false)}
            onCitiesSelect={onCitiesEditSelect}
            mapDef={mapLayer}
            fonts={fonts}
          />
        )}
      </div>
    </>
  );
}
export default BaseMapLayers;
