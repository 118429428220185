import moment from 'moment';
import { useContext, useEffect } from 'react';

import Loader from '../../../dashboard/components/loader-NEW/Loader';
import CheckoutForm from '../../../payment/components/CheckoutForm';
import { useCheckoutOrder } from '../../hooks/payment/useCheckoutOrder';
import { useGetListOfCards } from '../../hooks/payment/useGetListOfCards';
import { useGetShoppingCartItems } from '../../hooks/payment/useGetShoppingCartItems';
import { useGetTransactionById } from '../../hooks/payment/useGetTransactionById';
import { useGetCurrency } from '../../hooks/useGetCurrency';
import { AntContent } from '../../layout/Layout';
import MarketplaceContext from '../../marketplaceContext';
import Price from '../../molecules/Price/Price';
import Privacy from './Privacy';

const Checkout = () => {
  const { authenticated } = useContext(MarketplaceContext);
  const { data: checkoutData } = useGetShoppingCartItems(authenticated);
  const { data: orderId, mutate: checkoutOrder } = useCheckoutOrder();
  const { data: transaction } = useGetTransactionById(orderId?.data.id);
  const { data: savedCards } = useGetListOfCards();
  const { data: currency } = useGetCurrency();

  useEffect(() => {
    if (checkoutData.id) {
      checkoutOrder(checkoutData.id);
    }
  }, [checkoutData]);

  return (
    <AntContent>
      {transaction ? (
        <>
          <h1 className="text-black ">Checkout</h1>
          <div className="flex mt-10 mx-4 gap-10">
            <div className="w-full">
              <div className="flex items-center justify-between mb-10">
                <span className="text-3xl font-semibold">Your order details</span>
                <span className="text-xl font-light">{checkoutData?.items?.length} Items</span>
              </div>
              <div className="border-b">
                {checkoutData?.items?.map((item: any) => {
                  return (
                    <div key={item.id} className="flex mb-20">
                      <img className="object-contain max-w-[33%] rounded-md" src={item.image} />
                      <div className="flex flex-col ml-10 justify-center">
                        <h2 className="text-black uppercase break-words text-2xl">{item.name}</h2>
                        <div className="mt-4 md:mt-6 lg:mt-8">
                          <p className="text-[#444444] text-[12px]">
                            Created date: {moment(item.creationTime).format('DD.MM.YYYY')}
                          </p>
                          <p className="text-black text-[13px] font-light">
                            {item?.description ?? 'No description'}
                          </p>
                        </div>

                        <h3 className="text-[#444444] font-semibold text-xl mt-3 md:mt-5 lg:mt-7">
                          {currency && <Price currency={currency} price={item.price} />}
                        </h3>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="flex items-center justify-between mb-32 mt-10 mx-4">
                <span className="text-3xl font-semibold uppercase">TOTAL</span>
                <span className="text-3xl text-[#444444] font-semibold">
                  {currency && <Price currency={currency} price={checkoutData?.amount} />}
                </span>
              </div>
              <Privacy
                text='We take your privacy seriously and want you to know how we collect, use, and protect your
        personal information. This Privacy Policy applies to the use of our website, mobile
        applications, and any other services that we provide (collectively referred to as
        "Services").'
              />
              <Privacy
                text='We take your privacy seriously and want you to know how we collect, use, and protect your
        personal information. This Privacy Policy applies to the use of our website, mobile
        applications, and any other services that we provide (collectively referred to as
        "Services").'
              />
            </div>
            <CheckoutForm
              transactionId={orderId?.data.id}
              savedCards={savedCards}
              transaction={transaction}
            ></CheckoutForm>
          </div>
        </>
      ) : (
        <div className="flex justify-center">
          <Loader />
        </div>
      )}
    </AntContent>
  );
};

export default Checkout;
