import './styles.scss';

import { AxiosError } from 'axios';
import { AiOutlineSearch } from 'react-icons/ai';
import { BsRecordBtn } from 'react-icons/bs';

import Close from '../../../../assets/images/close.svg';
import Button from '../../../../atoms/button/Button';
import Error from '../../../dashboard/components/error/Error';
import Loader from '../../../dashboard/components/Loader';
import RecordModal from '../../modals/RecordModal';
import ContentItems from './ContentItems';
import FilterTabs from './FilterTabs';

interface ContentProps {
  sidebarItem: string;
  onClose: () => void;
  search: string;
  onChange: (e: any) => void;
  filterTab?: string;
  setFilterTab?: (tab: string) => void;
  content: any;
  isError: boolean;
  error: AxiosError | null;
  isRecord?: any;
  setRecord?: any;
  onAddItem: (id: string, type?: string) => void;
}

enum Tabs {
  MAP = 'MAP',
  SCENE = 'SCENE',
  POSTER = 'POSTER',
  GEOPOSTER = 'GEOPOSTER',
  LAYER = 'LAYER',
}
const SidebarContent = ({
  sidebarItem,
  onClose,
  search,
  onChange,
  filterTab,
  setFilterTab,
  content,
  isError,
  error,
  isRecord,
  setRecord,
  onAddItem,
}: ContentProps) => {
  return (
    <>
      {isRecord && <RecordModal opened={isRecord} onClose={() => setRecord(false)} />}
      <div className="sidebar-content-wrapper">
        <span className="title">
          {sidebarItem !== 'TEMPLATE' ? sidebarItem + ' TEMPLATES' : 'TEMPLATE REPOSITORY'}
          {sidebarItem === 'AUDIO' && (
            <Button
              size="small"
              buttonType="primary"
              onClick={() => setRecord(true)}
              className="flex items-center gap-1"
              label="Record audio"
              icon={<BsRecordBtn />}
            />
          )}
          {sidebarItem === 'VIDEO' && (
            <Button
              size="small"
              buttonType="primary"
              onClick={() => setRecord(true)}
              className="flex items-center gap-1"
              label="Record video"
              icon={<BsRecordBtn />}
            />
          )}
        </span>
        <div className={'input-wrapper'}>
          <AiOutlineSearch />
          <input placeholder="Search" value={search} onChange={onChange} />
        </div>
        {sidebarItem === 'TEMPLATE' && (
          <FilterTabs tabs={Tabs} filterTab={filterTab!} setFilterTab={setFilterTab!} />
        )}
        {content ? (
          <ContentItems
            onAddItem={onAddItem}
            data={content}
            filterTab={filterTab!}
            type={sidebarItem}
            onClose={onClose}
          />
        ) : (
          !isError && <Loader />
        )}
        {isError && <Error error={error} />}
        <div className={'close'} onClick={onClose}>
          <img src={Close} alt="" />
        </div>
      </div>
    </>
  );
};

export default SidebarContent;
