import { CheckboxValueType } from 'antd/es/checkbox/Group';
import React from 'react';

interface MarketplaceContext {
  searchString: string;
  type: CheckboxValueType[];
  showCartSidebar: boolean;
  tokenEmail: string;
  authenticated: boolean;
  admin: boolean;
  setSearchString: (e: string) => void;
  setType: (e: CheckboxValueType[]) => void;
  setShowCartSidebar: (e: boolean) => void;
}
const MarketplaceContext = React.createContext<MarketplaceContext>({
  searchString: '',
  type: [],
  showCartSidebar: false,
  tokenEmail: '',
  authenticated: false,
  admin: false,
  setSearchString: () => {},
  setType: () => {},
  setShowCartSidebar: () => {},
});
export const MarketplaceProvider = MarketplaceContext.Provider;
export default MarketplaceContext;
