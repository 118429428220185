import { DatePicker, Modal } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { EventValue } from 'rc-picker/lib/interface';
import { useEffect, useState } from 'react';
import { AiFillWarning } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../../../atoms/button/Button';
import { SceneDef } from '../../../../model/definitions/SceneDef';
import { updateScene } from '../../../../store/slices/project-slice';
import { selectActiveScene } from '../../../../store/slices/selectors';
import { RootState } from '../../../../store/store';
import { PropertySection } from '../components/PropertySection';
import GridItem from '../shared/GridItem';
import GridWrapper from '../shared/GridWrapper';
import { useUpdateGrib } from './hooks/useUpdateGrib';
import { useUpdateRadar } from './hooks/useUpdateRadar';
import { useUpdateSatellite } from './hooks/useUpdateSatellite';

const { RangePicker } = DatePicker;

export const SceneTimeFrame = () => {
  const dispatch = useDispatch();
  const activeSceneDef = useSelector<RootState, SceneDef | null>(selectActiveScene);
  const { updateGrib, isLoadingGrib } = useUpdateGrib();
  const { updateRadar, isLoadingRadar } = useUpdateRadar();
  const { updateSatellite, isLoadingSatellite } = useUpdateSatellite();
  const isLoading = isLoadingGrib || isLoadingRadar || isLoadingSatellite;
  const [isWarningModalOpen, setWarningModalOpen] = useState(false);
  const [dates, setDates] = useState<Array<number | null | undefined>>([
    activeSceneDef?.startDate || null,
    activeSceneDef?.endDate || null,
  ]);
  const handleDateChange = (newValue: number, propertyPath: Leaves<SceneDef>) => {
    if (activeSceneDef) {
      dispatch(updateScene({ newValue, propertyPath, activeScene: activeSceneDef.id }));
    }
  };
  useEffect(() => {
    setDates([activeSceneDef?.startDate || null, activeSceneDef?.endDate || null]);
  }, [activeSceneDef]);

  const handleUpdateSceneData = async () => {
    try {
      if (dates[0]) handleDateChange(dates[0], 'startDate');
      if (dates[1]) handleDateChange(dates[1], 'endDate');
      updateGrib(activeSceneDef, dates);
      updateRadar(activeSceneDef, dates);
      updateSatellite(activeSceneDef, dates);
    } catch (error) {
      console.error('Error updating scene data:', error);
    } finally {
      setWarningModalOpen(false);
    }
  };
  const handleDatePickerChange = (dateRange: [EventValue<Dayjs>, EventValue<Dayjs>] | null) => {
    if (dateRange) {
      const [startDate, endDate] = dateRange;
      startDate &&
        endDate &&
        setDates([startDate?.unix() * 1000 || null, endDate?.unix() * 1000 || null]);
    }
  };
  const renderModalContent = () => (
    <div>
      {isLoading
        ? 'Updating, please wait...'
        : 'This action will update ALL WEATHER DATA within the selected time frame. Are you sure you want to proceed?'}
    </div>
  );

  return (
    <PropertySection isOpened={true} label="Data">
      <div className="py-3">
        <GridWrapper>
          <GridItem
            noBorderBg
            label="Range:"
            item={
              <RangePicker
                className="my-4"
                value={[dates[0] ? dayjs(dates[0]) : null, dates[1] ? dayjs(dates[1]) : null]}
                showTime
                onChange={handleDatePickerChange}
              />
            }
          />
          <GridItem
            noBorderBg
            label=""
            item={<Button onClick={() => setWarningModalOpen(true)}>Update scene data</Button>}
          />
        </GridWrapper>
      </div>

      <Modal
        open={isWarningModalOpen}
        onCancel={() => setWarningModalOpen(false)}
        title={
          <div className="flex items-center justify-center gap-4">
            <AiFillWarning style={{ color: 'yellow' }} /> Update scene data
          </div>
        }
        footer={
          <div className="flex items-center justify-end gap-4">
            <Button disabled={isLoading} onClick={() => setWarningModalOpen(false)}>
              Cancel
            </Button>
            <Button
              disabled={isLoading || !dates[0] || !dates[1]}
              onClick={handleUpdateSceneData}
              loading={isLoading}
              buttonType="danger"
            >
              Confirm update
            </Button>
          </div>
        }
      >
        {renderModalContent()}
      </Modal>
    </PropertySection>
  );
};
