export enum RenderCompressionEnum {
  '10Mbps' = '10Mbps',
  '20Mbps' = '20Mbps',
  '30Mbps' = '30Mbps',
  '70Mbps' = '70Mbps',
}

export enum RenderCompressionNamesEnum {
  '10Mbps' = 'Proxy H.264 10 Mbps',
  '20Mbps' = 'H.264 20 Mbps',
  '30Mbps' = 'H.265 30 Mbps',
  '70Mbps' = 'Apple ProRes 422 LT 70 Mbps',
}
