import { Button, ToggleSwitch } from 'flowbite-react';
import React from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { AiOutlineCaretDown, AiOutlineCaretUp, AiOutlineDelete } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';

import { usePropertyGridActive } from '../../../hooks/usePropertyGridActive';
import { KeyFramesDef } from '../../../model/definitions/KeyFramesDef';
import { MapPanelDef } from '../../../model/definitions/MapPanelDef';
import { MapPositionControlDef } from '../../../model/definitions/MapPositionControlDef';
import ManualElement from '../../../molecules/manualElement/ManualElement';
import { ActiveDef, setPropertyGridActiveHash } from '../../../store/slices/active-slice';
import {
  addKeyFrame as addFlyOver,
  removeKeyFrame,
  updateKeyframePositionControlByIndex,
  updateMapLayer,
} from '../../../store/slices/project-slice';
import { RootState } from '../../../store/store';
import InputNumber from '../../marketplace-new/atoms/FormatNumber/FormatNumber';
import styles from './Properties.module.scss';
import GridActions from './shared/GridActions';
import GridItem from './shared/GridItem';
import GridWrapper from './shared/GridWrapper';

type Props = {
  mapLayer: MapPanelDef;
};

function FlyOvers({ mapLayer }: Props) {
  const dispatch = useDispatch();

  const { activeScene, activeElement } = useSelector<RootState, ActiveDef>((state) => state.active);
  const currentMapState = useSelector<RootState, MapPositionControlDef>(
    (state) => state.mapState.mapState,
  );

  const manual = (
    <div>
      <div>1) Enable flyovers</div>
      <div>2) Select keyframes - Use current to use current map position</div>
      <div>Note*</div>
      <div>
        -If first keyframe is at 0 time it will be used as default position and flyover start point
      </div>
      <div>
        -If first keyframe is not at 0 time default posiiton will be used as flyover start point
      </div>
      <div>
        -If two keyframes in sequence have same position in that period of time map will not move
      </div>
      <div>-Flyover time will be determined by diference in time between two keyframes</div>
    </div>
  );

  function onFocus(path: string) {
    dispatch(setPropertyGridActiveHash({ activeElement, focusedEl: path }));
  }

  const flyoverDeps = mapLayer.flyOver?.keyFrames
    ?.map((_, i) => [
      `flyOver.keyFrames[${i}].mapPositionControl.longitude`,
      `flyOver.keyFrames[${i}].mapPositionControl.latitude`,
      `flyOver.keyFrames[${i}].mapPositionControl.pitch`,
      `flyOver.keyFrames[${i}].mapPositionControl.zoom`,
      `flyOver.keyFrames[${i}].timeInMS`,
      `flyOver.keyFrames[${i}].transitionTilt`,
      `flyOver.keyFrames[${i}].transitionType`,
    ])
    .flat(10)
    .concat('enabled');

  const { isOpened, lastFocused } = usePropertyGridActive(flyoverDeps);

  const [open, setOpen] = React.useState<boolean>(isOpened);

  useHotkeys('ctrl+f, command+f', (ev) => {
    ev.preventDefault();
    enableFlyover(true);
  });

  function onMapLayerChange(
    propertyPath: Leaves<MapPanelDef> | keyof MapPanelDef,
    newValue: any,
    i: number,
  ) {
    if (i && i >= 0) onFocus(propertyPath);
    dispatch(
      updateMapLayer({
        activeScene,
        newValue,
        elementId: activeElement,
        propertyPath,
      }),
    );
  }

  function changeKeyframe(index: number) {
    dispatch(
      updateKeyframePositionControlByIndex({
        activeScene,
        defIndex: index,
        elementId: activeElement,
        positionControl: currentMapState,
      }),
    );
  }

  const removeKey = (
    e: React.MouseEvent,
    activeScene: string,
    elementId: string | number,
    keyFrame: number,
  ) => {
    e.stopPropagation();
    dispatch(removeKeyFrame({ activeScene, elementId, keyFrame }));
  };

  const setInitialKeyFrame = () => {
    const initKeyframe: KeyFramesDef = {
      timeInMS: mapLayer?.timeControls[0].startMS ? mapLayer?.timeControls[0].startMS : 0,
      mapPositionControl: {
        longitude: currentMapState.longitude!,
        latitude: currentMapState.latitude,
        zoom: currentMapState.zoom!,
        bearing: currentMapState.bearing,
        pitch: currentMapState.pitch,
        defaultZoom: currentMapState.defaultZoom,
      },
      transitionType: 'LINEAR',
      transitionTilt: 0,
    };
    dispatch(
      addFlyOver({
        activeScene: activeScene as string,
        elementId: activeElement,
        keyFrame: initKeyframe,
      }),
    );
  };

  function enableFlyover(e: boolean) {
    onFocus('enabled');
    e &&
      mapLayer?.flyOver?.keyFrames &&
      mapLayer?.flyOver?.keyFrames[0]?.timeInMS !== 0 &&
      setInitialKeyFrame();
    // !e && onMapLayerChange('flyOver.keyFrames', [], -1);
    if (!mapLayer?.mapPositionControl.updateView) {
      dispatch(
        updateMapLayer({
          activeScene,
          newValue: true,
          elementId: activeElement,
          propertyPath: 'mapPositionControl.updateView',
        }),
      );
    }
    dispatch(
      updateMapLayer({
        activeScene,
        newValue: e,
        elementId: activeElement,
        propertyPath: 'flyOverEnabled',
      }),
    );
  }

  const addKeyFrame = () => {
    const initKeyframe: KeyFramesDef = {
      timeInMS: 0,
      mapPositionControl: {
        longitude: currentMapState.longitude!,
        latitude: currentMapState.latitude,
        zoom: currentMapState.zoom!,
        bearing: currentMapState.bearing,
        pitch: currentMapState.pitch,
        defaultZoom: currentMapState.defaultZoom,
      },
      transitionType: 'LINEAR',
      transitionTilt: 0,
    };
    onMapLayerChange('flyOver.keyFrames', [...mapLayer?.flyOver?.keyFrames!, initKeyframe], -1);
  };

  return (
    <>
      <div
        className={`mb-2 subheader layer-header ${open ? 'layer-header-active' : ''}`}
        onClick={() => setOpen(!open)}
      >
        {open ? <AiOutlineCaretUp /> : <AiOutlineCaretDown />}
        Fly overs
      </div>
      {open && (
        <div className="prop-wrapper">
          <GridWrapper>
            <GridItem
              noBorderBg
              label={`Fly-over controls:`}
              item={
                <ManualElement content={manual}>
                  <ToggleSwitch
                    checked={mapLayer.flyOverEnabled}
                    label={''}
                    onChange={(e) => enableFlyover(e)}
                  />
                </ManualElement>
              }
            />
          </GridWrapper>
          <div>
            {!!mapLayer?.flyOver?.keyFrames?.length &&
              mapLayer?.flyOver?.keyFrames &&
              mapLayer?.flyOver?.keyFrames?.map((e, i) => (
                <>
                  <h4>
                    Keyframe #{i + 1}
                    {i > 0 && (
                      <AiOutlineDelete
                        color={'red'}
                        onClick={(s) => removeKey(s, activeScene as string, activeElement, i)}
                      />
                    )}
                  </h4>
                  <GridWrapper>
                    <GridItem
                      label="Longitude:"
                      item={
                        <InputNumber
                          className={styles.inputWrap}
                          value={e?.mapPositionControl?.longitude}
                          onInputChange={(e) =>
                            onMapLayerChange(
                              //@ts-ignore
                              `flyOver.keyFrames[${i}].mapPositionControl.longitude`,
                              e,
                              i,
                            )
                          }
                          onFocus={() =>
                            onFocus(`flyOver.keyFrames[${i}].mapPositionControl.longitude`)
                          }
                          autoFocus={
                            lastFocused === `flyOver.keyFrames[${i}].mapPositionControl.longitude`
                          }
                          precision={2}
                          type="number"
                          min={0}
                        />
                      }
                    />

                    <GridItem
                      label="Latitude:"
                      item={
                        <InputNumber
                          type={'number'}
                          value={e?.mapPositionControl?.latitude}
                          onInputChange={(e) =>
                            onMapLayerChange(
                              //@ts-ignore
                              `flyOver.keyFrames[${i}].mapPositionControl.latitude`,
                              e,
                              i,
                            )
                          }
                          onFocus={() =>
                            onFocus(`flyOver.keyFrames[${i}].mapPositionControl.latitude`)
                          }
                          autoFocus={
                            lastFocused === `flyOver.keyFrames[${i}].mapPositionControl.latitude`
                          }
                          className={styles.inputWrap}
                          min={0}
                          precision={2}
                        />
                      }
                    />

                    <GridItem
                      label="Pitch:"
                      item={
                        <InputNumber
                          type={'number'}
                          value={e?.mapPositionControl?.pitch}
                          onInputChange={(e) =>
                            onMapLayerChange(
                              //@ts-ignore
                              `flyOver.keyFrames[${i}].mapPositionControl.pitch`,
                              e,
                              i,
                            )
                          }
                          precision={2}
                          onFocus={() =>
                            onFocus(`flyOver.keyFrames[${i}].mapPositionControl.pitch`)
                          }
                          autoFocus={
                            lastFocused === `flyOver.keyFrames[${i}].mapPositionControl.pitch`
                          }
                          className={styles.inputWrap}
                          min={0}
                        />
                      }
                    />

                    <GridItem
                      label="Zoom:"
                      item={
                        <InputNumber
                          type={'number'}
                          value={e?.mapPositionControl?.zoom}
                          onInputChange={(e) =>
                            onMapLayerChange(
                              //@ts-ignore
                              `flyOver.keyFrames[${i}].mapPositionControl.zoom`,
                              e,
                              i,
                            )
                          }
                          onFocus={() => onFocus(`flyOver.keyFrames[${i}].mapPositionControl.zoom`)}
                          autoFocus={
                            lastFocused === `flyOver.keyFrames[${i}].mapPositionControl.zoom`
                          }
                          className={styles.inputWrap}
                          min={0}
                          precision={2}
                        />
                      }
                    />

                    <GridItem
                      label="Time in MS:"
                      item={
                        <InputNumber
                          type={'number'}
                          value={e?.timeInMS}
                          onInputChange={(e) =>
                            onMapLayerChange(
                              //@ts-ignore
                              `flyOver.keyFrames[${i}].timeInMS`,
                              e.target.value,
                              i,
                            )
                          }
                          onFocus={() => onFocus(`flyOver.keyFrames[${i}].timeInMS`)}
                          autoFocus={lastFocused === `flyOver.keyFrames[${i}].timeInMS`}
                          className={styles.inputWrap}
                          precision={2}
                        />
                      }
                    />

                    <GridItem
                      label="Transition tilt:"
                      item={
                        <InputNumber
                          type={'number'}
                          value={e?.transitionTilt}
                          onInputChange={(e) =>
                            onMapLayerChange(
                              //@ts-ignore
                              `flyOver.keyFrames[${i}].transitionTilt`,
                              e,
                              i,
                            )
                          }
                          precision={2}
                          onFocus={() => onFocus(`flyOver.keyFrames[${i}].transitionTilt`)}
                          autoFocus={lastFocused === `flyOver.keyFrames[${i}].transitionTilt`}
                          className={styles.inputWrap}
                        />
                      }
                    />
                    <GridItem
                      label="Transition type:"
                      item={
                        <input
                          type={'text'}
                          value={e?.transitionType ? e?.transitionType : ''}
                          onChange={(e) =>
                            onMapLayerChange(
                              //@ts-ignore
                              `flyOver.keyFrames[${i}].transitionType`,
                              e.target.value,
                              i,
                            )
                          }
                          onFocus={() => onFocus(`flyOver.keyFrames[${i}].transitionType`)}
                          autoFocus={lastFocused === `flyOver.keyFrames[${i}].transitionType`}
                          className={styles.inputWrap}
                        ></input>
                      }
                    />
                  </GridWrapper>
                  <GridActions>
                    <Button color="purple" size={'xl'} onClick={() => changeKeyframe(i)}>
                      Use current
                    </Button>
                    {mapLayer.flyOverEnabled &&
                      i ===
                        (mapLayer?.flyOver?.keyFrames?.length &&
                          mapLayer?.flyOver?.keyFrames?.length - 1) && (
                        <Button color="purple" onClick={addKeyFrame}>
                          Add key frame
                        </Button>
                      )}
                  </GridActions>
                </>
              ))}
          </div>
        </div>
      )}
    </>
  );
}
export default FlyOvers;
