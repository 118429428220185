import { InputNumber } from 'antd';
import { Select, ToggleSwitch } from 'flowbite-react';
import { useDispatch, useSelector } from 'react-redux';

import { FrontTypeExternalEnum } from '../../../../../model/enums/FrontTypeEnum';
import { ActiveDef, setActiveDraw } from '../../../../../store/slices/active-slice';
import { RootState } from '../../../../../store/store';
import { PaletteColorPicker } from '../../mapLayersProperties/PalettecolorPicker';
import GridItem from '../../shared/GridItem';
import GridWrapper from '../../shared/GridWrapper';

interface FronInterface {
  coldFront: string;
  drawingType: string;
  featureId: string;
  frontDensity: number;
  frontType: string;
  hornOrientation: boolean;
  lineGap: number;
  lineThickness: number;
  occludedFront: string;
  occludedFrontV2: string;
  oneDashLength: number;
  originalPartSize: number;
  outflowFront: string;
  partSize: number;
  resolution: number;
  selected: boolean;
  stationaryCold: string;
  stationaryWarm: string;
  warmFront: string;
  zIndex: number;
}

interface FrontSetupProps {
  feature: FronInterface;
  onChange: (e: string | boolean, prop: string) => void;
}

export const FrontSetup = ({ feature, onChange }: FrontSetupProps) => {
  const dispatch = useDispatch();
  const { activeDraw } = useSelector<RootState, ActiveDef>((state) => state.active);

  function onModifyChange(checked: boolean) {
    dispatch(setActiveDraw({ newValue: checked, path: 'modify' }));
    dispatch(setActiveDraw({ newValue: false, path: 'enabled' }));
  }
  return (
    <>
      <GridWrapper className={'py-5 pr-5'}>
        <GridItem
          label={`Front type:`}
          item={
            <Select
              value={feature.frontType}
              onChange={(e) => onChange(e.target.value, 'frontType')}
            >
              {Object.entries(FrontTypeExternalEnum).map(([key, v]) => (
                <option key={v} value={v}>
                  {key}
                </option>
              ))}
            </Select>
          }
        />
        <GridItem
          noBorderBg
          label={`Barb direction:`}
          item={
            <ToggleSwitch
              checked={feature.hornOrientation}
              label={''}
              onChange={(e) => onChange(e, 'hornOrientation')}
            />
          }
        />
        <GridItem
          label={'Line width'}
          item={
            <InputNumber
              className={'border-0 bg-transparent w-full rounded-0'}
              value={feature.lineThickness}
              onChange={(e) => onChange((e ?? 0).toString(), 'lineThickness')}
            />
          }
        />
        <GridItem
          label={'Layer level'}
          item={
            <InputNumber
              className={'border-0 bg-transparent w-full rounded-0'}
              value={feature.zIndex}
              onChange={(e) => onChange((e ?? 0).toString(), 'zIndex')}
            />
          }
        />
        {feature.frontType === FrontTypeExternalEnum.WARM_FRONT && (
          <GridItem
            noBorderBg
            label={'Front color'}
            item={
              <PaletteColorPicker
                browserRGBA
                value={feature.warmFront}
                onChange={(e) => onChange(e, 'warmFront')}
              />
            }
          />
        )}
        {feature.frontType === FrontTypeExternalEnum.COLD_FRONT && (
          <GridItem
            noBorderBg
            label={'Front color'}
            item={
              <PaletteColorPicker
                browserRGBA
                value={feature.coldFront}
                onChange={(e) => onChange(e, 'coldFront')}
              />
            }
          />
        )}
        <GridItem
          noBorderBg
          label={'Modify'}
          item={<ToggleSwitch checked={activeDraw.modify} label={''} onChange={onModifyChange} />}
        />
        {feature.frontType === FrontTypeExternalEnum.STATIONARY_FRONT && (
          <>
            <GridItem
              noBorderBg
              label={'Front color warm'}
              item={
                <PaletteColorPicker
                  browserRGBA
                  value={feature.stationaryWarm}
                  onChange={(e) => onChange(e, 'stationaryWarm')}
                />
              }
            />
            <GridItem
              noBorderBg
              label={'Front color cold'}
              item={
                <PaletteColorPicker
                  browserRGBA
                  value={feature.stationaryCold}
                  onChange={(e) => onChange(e, 'stationaryCold')}
                />
              }
            />
          </>
        )}
        {feature.frontType === FrontTypeExternalEnum.OCCLUDED_FRONT && (
          <GridItem
            noBorderBg
            label={'Front color'}
            item={
              <PaletteColorPicker
                browserRGBA
                value={feature.occludedFront}
                onChange={(e) => onChange(e, 'occludedFront')}
              />
            }
          />
        )}
        {feature.frontType === FrontTypeExternalEnum.OCCLUDED_FRONT_V2 && (
          <GridItem
            noBorderBg
            label={'Front color'}
            item={
              <PaletteColorPicker
                browserRGBA
                value={feature.occludedFrontV2}
                onChange={(e) => onChange(e, 'occludedFrontV2')}
              />
            }
          />
        )}
        {feature.frontType === FrontTypeExternalEnum.THROUGH_OR_OUTFLOW_BOUNDARY && (
          <GridItem
            noBorderBg
            label={'Front color'}
            item={
              <PaletteColorPicker
                browserRGBA
                value={feature.outflowFront}
                onChange={(e) => onChange(e, 'outflowFront')}
              />
            }
          />
        )}
      </GridWrapper>
      <GridWrapper className={'pr-5'}>
        <GridItem
          label={'Density'}
          item={
            <InputNumber
              className={'border-0 bg-transparent w-full rounded-0'}
              value={feature.frontDensity}
              onChange={(e) => onChange((e ?? 0).toString(), 'frontDensity')}
            />
          }
        />
        <GridItem
          label={'Part size'}
          item={
            <InputNumber
              className={'border-0 bg-transparent w-full rounded-0'}
              value={feature.partSize}
              onChange={(e) => onChange((e ?? 0).toString(), 'partSize')}
            />
          }
        />
      </GridWrapper>
    </>
  );
};
