import './SocialMedia.scss';

import { useNavigate } from 'react-router-dom';

import { SocialMediaIcons } from './config';

const SocialMedia = () => {
  const navigate = useNavigate();
  function displayIcons() {
    return SocialMediaIcons?.map((el: any, index) => {
      return <img key={index} src={el.image} alt="" onClick={() => navigate(el.link)} />;
    });
  }
  return <div className="icons">{displayIcons()}</div>;
};

export default SocialMedia;
