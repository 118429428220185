import { Select } from 'flowbite-react';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';

import Checkbox from '../../../../atoms/checkbox/Checkbox';
import Input from '../../../../atoms/input/Input';
import { useGetParameterMappings } from '../../../../core/api/mapLayers/useGetParameterMappings';
import { useGetSatellite } from '../../../../core/api/mapLayers/useGetSatellite';
import { MAX_ZINDEX_VALUE } from '../../../../model/constants/constants';
import { ColorPaletteDef } from '../../../../model/definitions/ColorPaletteDef';
import { DataFrameDef } from '../../../../model/definitions/DataFrameDef';
import { MapPanelDef } from '../../../../model/definitions/MapPanelDef';
import { PickerDef } from '../../../../model/definitions/PickerDef';
import { SatelliteMapLayer } from '../../../../model/definitions/SatelliteMapLayer';
import { ColorPaletteParamTypeEnum } from '../../../../model/enums/ColorPaletteParamTypeEnum';
import {
  FilteringTypeEnum,
  PostProcessingTypeEnum,
} from '../../../../model/enums/FilteringTypeEnum';
import { InterpolationEnum } from '../../../../model/enums/InterpolationEnum';
import { filterFramesByTimeWindow } from '../../../../molecules/mapElement/helpers';
import { PaletteRefactor } from '../../../../molecules/palette/PaletteRefactor';
import InputNumber from '../../../marketplace-new/atoms/FormatNumber/FormatNumber';
import { FirstLayerRange } from '../../modals/frameRangeHelpers';
import { FramesRange } from '../../modals/FramesRange';
import styles from '../Properties.module.scss';
import { CustomPalette } from './CustomPalette';

interface MapLayersProps {
  oldLayer: boolean;
  dataProductId: string | null;
  setDataProductId: (e: string) => void;
  bounds: [number, number, number, number];
  name?: string;
  setName: (e: string) => void;
  mapLayer: MapPanelDef;
  onMapLayerChange: (propertyPath: keyof MapPanelDef, newValue: SatelliteMapLayer[]) => void;
  source: PickerDef | undefined;
  setSource: (e?: PickerDef) => void;
  selectedDataFrames: Array<DataFrameDef>;
  setSelectedDataFrames: (e: Array<DataFrameDef>) => void;
  z: number;
  setZ: (e: number) => void;
  type: PickerDef | undefined;
  setType: (e?: PickerDef) => void;
  interpolation: InterpolationEnum;
  filteringType: FilteringTypeEnum | null;
  setInterpolation: React.Dispatch<React.SetStateAction<InterpolationEnum>>;
  setFilteringType: React.Dispatch<React.SetStateAction<FilteringTypeEnum | null>>;
  palette: ColorPaletteDef | null;
  setPalette: (palette: ColorPaletteDef) => void;
  satelliteLayerEdit?: SatelliteMapLayer;
  rangeFromFirstLayer?: FirstLayerRange | null;
  embossEffect: number;
  setEmbossEffect: (e: number) => void;
  numberOfIterations: number;
  setNumberOfIterations: (e: number) => void;
  postProcessing: PostProcessingTypeEnum | null;
  setPostProcessingType: React.Dispatch<React.SetStateAction<PostProcessingTypeEnum | null>>;
  setFramesDensity: React.Dispatch<React.SetStateAction<number>>;
  framesDensity: number;
  enableInterpolation: boolean | undefined;
  setEnableInterpolation: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  enterpriseAccountId: string;
  isContouring: boolean;
  setIsContouring: React.Dispatch<React.SetStateAction<boolean>>;
}
const MapSatelliteLayer = ({
  oldLayer,
  dataProductId,
  setDataProductId,
  bounds,
  source,
  name,
  setName,
  setSource,
  selectedDataFrames,
  setSelectedDataFrames,
  z,
  setZ,
  type,
  setType,
  filteringType,
  interpolation,
  setFilteringType,
  setInterpolation,
  setPalette,
  palette,
  satelliteLayerEdit,
  rangeFromFirstLayer,
  embossEffect,
  setEmbossEffect,
  numberOfIterations,
  setNumberOfIterations,
  postProcessing,
  setPostProcessingType,
  framesDensity,
  setFramesDensity,
  enableInterpolation,
  setEnableInterpolation,
  enterpriseAccountId,
  isContouring,
  setIsContouring,
}: MapLayersProps) => {
  const {
    useGetSatelliteType,
    getTypes,
    getSource,
    getValues,
    satelliteType,
    sourceData,
    values,
    loadingType,
    loadingSource,
    loadingValues,
  } = useGetSatellite(oldLayer);
  useGetSatelliteType(bounds);
  const { data: parameterMappings } = useGetParameterMappings({
    dataProductId,
    parameter: type?.name,
  });
  const [editPalette, setEditPalette] = useState<string | undefined>(undefined);

  const framesWithDensity = useMemo(() => {
    if (!framesDensity) return values ?? [];
    else return filterFramesByTimeWindow(values || [], framesDensity, selectedDataFrames[0]);
  }, [framesDensity, values]);

  const selectedRangeValue = useMemo(() => {
    if (!satelliteLayerEdit || !values?.length || !satelliteLayerEdit?.dataFrames) return [0, 0];
    const [firstFrame] = satelliteLayerEdit.dataFrames;
    if (!firstFrame) return [0, 0];
    const indexOfFirstFrame = values!
      .sort((a, b) => a.timestamp - b.timestamp)
      .findIndex((fr) => fr.frameId === firstFrame.frameId);
    return [indexOfFirstFrame, indexOfFirstFrame + satelliteLayerEdit.dataFrames.length];
  }, [satelliteLayerEdit, values]);

  useEffect(() => {
    if (bounds.length) {
      if (oldLayer) {
        getValues({ typeId: type!.id, bounds, sourceId: source!.id });
        getSource({ bounds, typeId: type!.id });
      } else {
        getSource({ bounds });
      }
      if (satelliteLayerEdit && dataProductId) {
        getValues({
          typeId: type!.id,
          bounds,
          dataProductId: dataProductId,
        });
        getTypes({
          dataProductId: dataProductId,
          bounds: bounds,
        });
      }
    }
  }, [bounds.length]);

  useEffect(() => {
    if (!satelliteLayerEdit && parameterMappings) {
      setEnableInterpolation(parameterMappings.isInterpolationEnabled);
    }
  }, [parameterMappings]);
  const onInterpolationChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setInterpolation(e.target.value as InterpolationEnum);
  };
  const onFilteringTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setFilteringType(e.target.value !== 'null' ? (e.target.value as FilteringTypeEnum) : null);
  };
  const onEnableInterpolationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEnableInterpolation(e.target.checked);
  };
  const onEnableContouringChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsContouring(e.target.checked);
  };
  const onTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = Number(e.target.value);
    const selectedParam = satelliteType?.find((param) => param.id === value);
    setType(selectedParam);
    if (value >= 0) {
      dataProductId && !oldLayer
        ? value &&
          getValues({
            typeId: value,
            dataProductId: dataProductId,
            bounds,
          })
        : getSource({
            typeId: value,
            bounds,
          });
      if (oldLayer) {
        setSource(undefined);
      }
    }
    if (selectedParam) {
      setName(`Satellite ${selectedParam.name}`);
    }
  };

  const onSourceChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = Number(e.target.value);
    const selectedSource = sourceData?.find((source) => source.id === value);
    if (selectedSource?.dataProductId) {
      getTypes({
        dataProductId: selectedSource.dataProductId,
        bounds: bounds,
      });
      setDataProductId(selectedSource.dataProductId);
      setType(undefined);
    } else {
      if (value >= 0) {
        type &&
          value &&
          getValues({
            typeId: type.id,
            sourceId: value,
            bounds,
          });
      }
    }
    setSource(selectedSource);
  };
  function onHourlyChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.checked) {
      setFramesDensity(1);
    } else {
      setFramesDensity(0);
    }
  }
  const useHourlyValues = (
    <div className="relative">
      <Checkbox
        checked={framesDensity > 0}
        label="Time step granularity"
        onChange={onHourlyChange}
      />
      {!framesDensity && (
        <small className="absolute left-[0px] top-5 text-red-500">
          *A high number of data time steps may lower rendering performance
        </small>
      )}
    </div>
  );
  const satelliteTypeSelect = () => (
    <Select
      className={'w-full'}
      style={{ width: '100%' }}
      onChange={onTypeChange}
      value={type?.id}
      required
    >
      <option value={0}>
        {satelliteType?.length === 0 ? 'No sources available' : 'select parameter'}
      </option>
      {satelliteType?.map((k: PickerDef) => (
        <option key={k.id} value={k.id}>
          {k.name}
        </option>
      ))}
    </Select>
  );
  const satelliteSourceSelect = () =>
    sourceData ? (
      <Select
        className={'w-full'}
        style={{ width: '100%' }}
        onChange={onSourceChange}
        value={source?.id}
        required
      >
        <option value={undefined}>
          {sourceData.length === 0 ? 'No sources available' : 'select source'}
        </option>
        {sourceData
          .sort((a, b) => (a.id > b.id ? 1 : -1))
          .map((k) => (
            <option key={k.id} value={k.id}>
              {k.name}
            </option>
          ))}
      </Select>
    ) : null;
  const interpolationSelect = (
    <Select
      className={'w-full'}
      style={{ width: '100%' }}
      onChange={onInterpolationChange}
      value={interpolation}
      required
    >
      {Object.entries(InterpolationEnum).map(([k, v]) => (
        <option key={k} value={v}>
          {k}
        </option>
      ))}
    </Select>
  );
  const enableInterpolationJSX = (
    <Checkbox
      checked={Boolean(enableInterpolation)}
      label="Enable interpolation"
      onChange={onEnableInterpolationChange}
    />
  );
  const onPostProcessingTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setPostProcessingType(
      e.target.value !== 'null' ? (e.target.value as PostProcessingTypeEnum) : null,
    );
  };
  const postProcessingSelect = (
    <Select
      className={'w-full'}
      style={{ width: '100%' }}
      onChange={onPostProcessingTypeChange}
      value={postProcessing ?? 'null'}
      required
    >
      <option value={''}>No post processing</option>
      {Object.entries(PostProcessingTypeEnum).map(([k, v]) => {
        return (
          <option key={k} value={v}>
            {k}
          </option>
        );
      })}
    </Select>
  );
  const filteringTypeSelect = (
    <Select
      className={'w-full'}
      style={{ width: '100%' }}
      onChange={onFilteringTypeChange}
      value={filteringType ?? 'null'}
      required
    >
      <option key={'no-val'} value={'null'}>
        No filtering
      </option>
      {Object.entries(FilteringTypeEnum).map(([k, v]) => (
        <option key={k} value={v}>
          {k}
        </option>
      ))}
    </Select>
  );
  const getPaletteType = (satelliteType: string) => {
    switch (satelliteType) {
      case 'INFRARED IMAGERY':
        return ColorPaletteParamTypeEnum.SATELLITE_INFRARED_IMAGERY;
      case 'VISIBLE IMAGERY':
        return ColorPaletteParamTypeEnum.SATELLITE_VISIBLE_IMAGERY;
      case 'WATER VAPOR IMAGERY':
        return ColorPaletteParamTypeEnum.SATELLITE_WATER_VAPOR_IMAGERY;
      default:
        return ColorPaletteParamTypeEnum.SATELLITE_INFRARED_IMAGERY;
    }
  };
  return (
    <>
      <h3>Satellite Layer</h3>
      <div className="layers-wrap">
        <div className="layer-item-flex-wrapper">
          <div className="layer-item">
            {oldLayer ? (
              <>
                <label>
                  <span>*</span> Satellite type
                </label>
                {loadingType ? (
                  <div className={'loading'}>Loading types</div>
                ) : (
                  satelliteTypeSelect()
                )}
              </>
            ) : (
              <>
                <label>
                  <span>*</span> Satellite source
                </label>
                {loadingSource ? (
                  <div className={'loading'}>Loading sources</div>
                ) : (
                  satelliteSourceSelect()
                )}
              </>
            )}
          </div>
          <div className="layer-item">
            {type && (
              <>
                <label>
                  <span>*</span> Layer name
                </label>
                <Input
                  placeholder="Type satellite layer name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </>
            )}
          </div>
        </div>
        <div className="layer-item-flex-wrapper">
          <div className="layer-item">
            {oldLayer
              ? type && (
                  <>
                    <label>
                      <span>*</span> Satellite source
                    </label>
                    {loadingSource ? (
                      <div className={'loading'}>Loading sources</div>
                    ) : (
                      satelliteSourceSelect()
                    )}
                  </>
                )
              : source && (
                  <>
                    <label>
                      <span>*</span> Satellite type
                    </label>
                    {loadingType ? (
                      <div className={'loading'}>Loading types</div>
                    ) : (
                      satelliteTypeSelect()
                    )}
                  </>
                )}
          </div>
          <div className="layer-item">
            {type && (
              <>
                <label>
                  <span>*</span> Layer level
                </label>

                <div className="grid-item">
                  <InputNumber
                    value={z}
                    onInputChange={(e) => setZ(e)}
                    className={styles.inputWrap}
                    required
                    min={0}
                    max={MAX_ZINDEX_VALUE}
                    step={1}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        {type && (
          <div className="layer-item-flex-wrapper">
            <div className="layer-item">{useHourlyValues}</div>
            <div className="layer-item mt-[20px]">
              <label>
                Frames granularity
                <Select
                  value={framesDensity ?? 0}
                  onChange={(e) => setFramesDensity(Number(e.target.value))}
                >
                  <option value={0}>original</option>
                  <option value={1}>1h</option>
                  <option value={2}>2h</option>
                  <option value={6}>6h</option>
                  <option value={12}>12h</option>
                  <option value={24}>24h</option>
                  <option value={168}>7 days</option>
                </Select>
              </label>
            </div>
            <div className="layer-item flex flex-col gap-4">
              {enableInterpolationJSX}
              <Checkbox
                checked={isContouring}
                onChange={onEnableContouringChange}
                label="Enable contouring"
              />
            </div>
          </div>
        )}

        {source && type && (
          <>
            {loadingValues ? (
              <div className={'loading my-[70px] h-[14px] text-center'}>
                Loading available frames
              </div>
            ) : (
              values.length > 0 && (
                <FramesRange
                  maxDays={7}
                  layerType={'satellite'}
                  frames={framesWithDensity}
                  onRangeChange={setSelectedDataFrames}
                  selectedFrames={selectedDataFrames}
                  layerEdit={satelliteLayerEdit}
                  defaultValue={satelliteLayerEdit ? selectedRangeValue : undefined}
                  show={!!rangeFromFirstLayer}
                  rangeFromFirstLayer={rangeFromFirstLayer}
                  framesDensity={framesDensity}
                />
              )
            )}
            <div className="layer-item-flex-wrapper modal-palette">
              {type?.name && (
                <PaletteRefactor
                  parameter={
                    `SATELLITE_${type?.name?.split(' ').join('_')}` as ColorPaletteParamTypeEnum
                  }
                  value={palette}
                  setPalette={setPalette}
                  layerEdit={satelliteLayerEdit}
                  editPalette={setEditPalette}
                  source={source}
                  dataProductId={dataProductId}
                  enterpriseAccountId={enterpriseAccountId}
                  width={1000}
                />
              )}
            </div>
            {type?.name && (
              <CustomPalette
                onOk={setPalette}
                paletteParamType={getPaletteType(type.name)}
                paletteToEdit={editPalette}
                setPaletteToEdit={(e) => {
                  setEditPalette(e);
                }}
                source={source}
              />
            )}
            <div className="layer-item-flex-wrapper">
              <div className="layer-item">
                <label>Color scale interpolation</label>
                {interpolationSelect}
              </div>
              <div className="layer-item"></div>
            </div>
            <div className="layer-item-flex-wrapper">
              <div className="layer-item">
                <label>Filtering type</label>
                {filteringTypeSelect}
              </div>
              {filteringType === FilteringTypeEnum.LOW_BANDPASS ? (
                <div className="layer-item">
                  <label>Number of Iterations</label>
                  <InputNumber
                    value={numberOfIterations}
                    max={100}
                    min={0}
                    onInputChange={(e) => {
                      const val = e >= 0 && e <= 100 ? e : numberOfIterations;
                      setNumberOfIterations(val);
                    }}
                  />
                </div>
              ) : (
                <div className="layer-item"></div>
              )}
            </div>
            <div className="layer-item-flex-wrapper">
              <div className="layer-item">
                <label>Post-processing</label>
                {postProcessingSelect}
              </div>
              {postProcessing === PostProcessingTypeEnum.EMBOSS ? (
                <div className="layer-item">
                  <label>Emboss effect</label>
                  <InputNumber
                    value={embossEffect}
                    max={100}
                    min={0}
                    onInputChange={(e) => {
                      const val = e >= 0 && e <= 100 ? e : embossEffect;
                      setEmbossEffect(val);
                    }}
                  />
                </div>
              ) : (
                <div className="layer-item"></div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default MapSatelliteLayer;
