import * as Sentry from '@sentry/react';

export const setupSentry = () => {
  Sentry.init({
    dsn: 'https://536076899bd844ed837b1928b438d036@o4505130163109888.ingest.sentry.io/4505130164617216',
    normalizeDepth: 20,
    environment: process.env.REACT_APP_ENV,
    release: process.env.REACT_APP_SENTRY_RELEASE,
    // disable Sentry for local development
    enabled: process.env.NODE_ENV != 'development',
  });
};
