import { ColorPaletteParamTypeEnum } from '../enums/ColorPaletteParamTypeEnum';
import { PaletteLegendScalingEnum } from '../enums/PaletteLegendScalingEnum';
import { C9Object } from './C9Object';
import { ColorPalette } from './ColorPalette';

export interface PaletteSetupDegree {
  degree: number;
  color: string;
  interpolationSteps: number;
  active: boolean;
}
export interface PaletteSetup {
  defaultStep: number;
  scale: Array<PaletteSetupDegree>;
}
export class ColorPaletteDef extends C9Object {
  type: string; // system | user_defined
  paletteParamType: ColorPaletteParamTypeEnum;
  setup?: PaletteSetup | null;
  colorStops: ColorPalette = new ColorPalette();
  editableByUser: boolean;
  visualisationType: string;
  source: string;
  paletteLegendScaling: PaletteLegendScalingEnum;
  enterpriseAccountId: string;
}
