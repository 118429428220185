import { Button, Modal, Table } from 'antd';
import { v4 } from 'uuid';

import { useFileResult } from '../hooks/useFileResult';

function FileValidationModal({
  isOpen,
  fileId,
  type,
  setIsOpen,
}: {
  isOpen: boolean;
  id?: string;
  fileId: number;
  type: string;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { data, isLoading } = useFileResult(fileId);

  const columns = [
    {
      title: 'Base name',
      dataIndex: 'source_name',
      key: 'source_name',
    },
    {
      title: 'Product name',
      dataIndex: 'model_name',
      key: 'model_name',
      hidden: type !== 'MODEL',
    },
    {
      title: 'Left lon',
      dataIndex: 'left_lon',
      key: 'left_lon',
    },
    {
      title: 'Right lon',
      dataIndex: 'right_lon',
      key: 'right_lon',
    },
    {
      title: 'Lower lat',
      dataIndex: 'lower_lat',
      key: 'lower_lat',
    },
    {
      title: 'Upper lat',
      dataIndex: 'upper_lat',
      key: 'upper_lat',
    },
    {
      title: 'Projection',
      dataIndex: 'projection',
      key: 'projection',
    },
    {
      title: 'Ortho',
      dataIndex: 'ortho',
      key: 'ortho',
    },
    {
      title: 'Parameter',
      dataIndex: 'parameter',
      key: 'parameter',
      hidden: type === 'RADAR',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      hidden: type !== 'SATELLITE',
    },
    {
      title: 'X res',
      dataIndex: 'x_res',
      key: 'x_res',
      hidden: type === 'MODEL',
    },
    {
      title: 'Y res',
      dataIndex: 'y_res',
      key: 'y_res',
      hidden: type === 'MODEL',
    },
  ].filter((item) => !item.hidden);
  return (
    <Modal
      title="File Validation Information"
      width="90%"
      open={isOpen}
      onCancel={() => setIsOpen(!isOpen)}
      destroyOnClose
      bodyStyle={{ overflowX: 'scroll' }}
      footer={<Button onClick={() => setIsOpen(!isOpen)}>Close</Button>}
    >
      <Table
        dataSource={data}
        columns={columns}
        rowKey={() => v4()}
        loading={isLoading}
        pagination={{ defaultPageSize: 10, hideOnSinglePage: true }}
      />
    </Modal>
  );
}

export default FileValidationModal;
