import { DataFrameDef } from './DataFrameDef';
import { PickerDef } from './PickerDef';
import { SatelliteSourceDef } from './SatelliteSourceDef';
import { TimeControlDef } from './TimeControlDef';
import { WDLocation } from './WDLocation';
import { WeatherDataMapLayer } from './WeatherDataMapLayer';

export class SatelliteMapLayer extends WeatherDataMapLayer {
  satelliteSource = new SatelliteSourceDef();
  constructor(
    name: string,
    dataProductId: string | null,
    source: PickerDef,
    parameterType: PickerDef,
    location: WDLocation,
    timeControls: TimeControlDef,
    dataFrames: Array<DataFrameDef>,
    zIndex: number,
  ) {
    super();
    this.name = name;
    this.satelliteSource.dataProductId = dataProductId;
    this.satelliteSource.satelliteSource = source;
    this.satelliteSource.satelliteType = parameterType;
    this.satelliteSource.location = location;
    this.timeControls = [timeControls];
    this.dataFrames = dataFrames;
    this.zindex = zIndex;
  }
}
