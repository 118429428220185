import { useMutation } from 'react-query';

import { ForecastWDElementDef } from '../../../../model/definitions/ForecastWDElementDef';
import { axiosInstance } from '../../axiosInstance';

export const useApplyBiasGroupOnForecast = () =>
  useMutation(
    async ({
      biasGroupId,
      body,
    }: {
      biasGroupId: string;
      body: ForecastWDElementDef;
    }): Promise<ForecastWDElementDef> => {
      const { data } = await axiosInstance.put(
        `biasGroup/apply/${biasGroupId}/forecastWDElement`,
        body,
      );
      return data;
    },
  );
