import { PositionControlDef } from '../model/definitions/PositionControlDef';
import { SceneDef } from '../model/definitions/SceneDef';

export interface DefinedPosition {
  id: string;
  x: number;
  y: number;
  w: number;
  h: number;
  zindex: number;
}
const getListOfHigherElements = (
  scene: SceneDef,
  zIndex: number,
  id: string,
): Array<DefinedPosition> => {
  const images = scene.imagePanels.map((element) => {
    return { id: element.id, ...element.positionControl };
  });
  const videos = scene.videoPanels.map((element) => {
    return { id: element.id, ...element.positionControl };
  });
  const text = scene.textPanels.map((element) => {
    return { id: element.id, ...element.positionControl };
  });
  const maps = scene.mapPanels.map((element) => {
    return { id: element.id, ...element.positionControl };
  });
  const observed = scene.observedWDElements.map((element) => {
    return { id: element.id, ...element.positionControl };
  });
  const forecast = scene.forecastWDElements.map((element) => {
    return { id: element.id, ...element.positionControl };
  });
  const posters = scene.weatherPosters.map((element) => {
    return { id: element.id, ...element.positionControl };
  });
  return [...images, ...videos, ...text, ...maps, ...observed, ...forecast, ...posters].filter(
    (element) => element.zindex >= zIndex && element.id !== id,
  );
};

function comparedOverlap(container: PositionControlDef, allElements: Array<DefinedPosition>) {
  const getX = (pos: number) => {
    if (pos < container.x) {
      return container.x;
    } else {
      return pos;
    }
  };
  const getY = (pos: number) => {
    if (pos < container.y) return container.y;
    else return pos;
  };
  // Create a variable to store the total area
  let totalArea = 0;
  const elements = allElements.filter(
    (elem) =>
      !(
        container.y > elem.y + elem.h ||
        container.x + container.w < elem.x ||
        container.y + container.h < elem.y ||
        container.x > elem.x + elem.w
      ),
  );
  const getW = (posx: number, posw: number) => {
    if (posx + posw < container.x + container.w) return posw;
    else {
      return container.x + container.w - posx;
    }
  };
  const getH = (posy: number, posh: number) => {
    if (posy + posh < container.y + container.h) return posh;
    else {
      return container.y + container.h - posy;
    }
  };
  // Iterate through the elements
  for (let i = 0; i < elements.length; i++) {
    // Get the bounding box of the current element
    const bbox1 = elements[i];
    const area1 =
      (getW(bbox1.x, bbox1.w) - (getX(bbox1.x) - bbox1.x)) *
      (getH(bbox1.y, bbox1.h) - (getY(bbox1.y) - bbox1.y));
    totalArea += area1;

    for (let j = i + 1; j < elements.length; j++) {
      // Get the bounding box of the other element
      const bbox2 = elements[j];

      // Calculate the intersection of the two bounding boxes
      const left = Math.max(getX(bbox1.x), getX(bbox2.x));
      const top = Math.max(getY(bbox1.y), getY(bbox2.y));
      const right = Math.min(
        getX(bbox1.x) + (getW(bbox1.x, bbox1.w) - (getX(bbox1.x) - bbox1.x)),
        getX(bbox2.x) + (getW(bbox2.x, bbox2.w) - (getX(bbox2.x) - bbox2.x)),
      );
      const bottom = Math.min(
        getY(bbox1.y) + (bbox1.h - (getY(bbox1.y) - bbox1.y)),
        getY(bbox2.y) + (bbox2.h - (getY(bbox2.y) - bbox2.y)),
      );
      // Check if there is an intersection
      if (left < right && top < bottom) {
        // Calculate the area of the intersection
        const area = (right - left) * (bottom - top);
        totalArea -= area;
      }
    }
  }

  return totalArea;
}
function calculateOverlap(viewed: PositionControlDef, elements: Array<DefinedPosition>) {
  // calculate the area of each element
  const element1Area = viewed?.w * viewed?.h;

  const uncoveredArea = element1Area - comparedOverlap(viewed, elements);
  return Math.round((uncoveredArea * 100) / element1Area);
}

export { calculateOverlap, getListOfHigherElements };
