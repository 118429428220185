import { AxiosError } from 'axios';
import { useQuery, UseQueryResult } from 'react-query';

import { axiosInstance } from '../../../core/api/axiosInstance';

const key = ['WEATHER_TYPE_CODES'];
const useGetWeatherTypeCodes = (): UseQueryResult<Array<string>, AxiosError> =>
  useQuery(
    key,
    async () => {
      const { data } = await axiosInstance.get(`all-weather-type-codes`);
      return data;
    },
    {
      staleTime: Infinity,
    },
  );

export default useGetWeatherTypeCodes;
