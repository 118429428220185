import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { queryClient } from '../../..';
import { axiosInstance } from '../../../core/api/axiosInstance';
import { key } from './useGetFonts';

const useCreateFont = () => {
  return useMutation(
    async (data: FormData) => {
      return await axiosInstance.post(`font`, data);
    },
    {
      onSuccess: () => {
        toast.success('Font created successfully!');
        queryClient.invalidateQueries(key);
      },
      onError: () => {
        toast.error('Error creating font!');
      },
    },
  );
};

export default useCreateFont;
