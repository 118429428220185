import './style.scss';

import { AiOutlineClose } from 'react-icons/ai';
import { default as ReactModal, Styles } from 'react-modal';

interface Interface {
  isOpen: boolean;
  onClose: () => void;
  header?: string;
  children: JSX.Element;
  noHeader?: boolean;
  id?: string;
  className?: string;
  footer?: JSX.Element;
  onClick?: any;
  bodyClassName?: string;
  style?: Styles;
}
const Modal = ({
  isOpen,
  onClose,
  header,
  noHeader,
  children,
  className,
  footer,
  bodyClassName,
  style = { content: {}, overlay: {} },
}: Interface) => {
  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isOpen}
      shouldCloseOnOverlayClick={false}
      id={`Modal`}
      onRequestClose={onClose}
      className={className}
      style={style}
    >
      {!noHeader && (
        <div className={'header-bar'}>
          <div>{header}</div>
          <div className={'close'} onClick={onClose}>
            <AiOutlineClose />
          </div>
        </div>
      )}
      <div className={`modal-body ${bodyClassName ? bodyClassName : ''}`}>{children}</div>
      {footer && footer}
    </ReactModal>
  );
};

export default Modal;
