export class ObservedWDStationDef {
  fullName: string;
  value: string;
  longitude: number;
  latitude: number;
  constructor(name?: string, value?: string, long?: number, lat?: number) {
    if (name) this.fullName = name;
    if (value) this.value = value;
    if (long) this.longitude = long;
    if (lat) this.latitude = lat;
  }
}
