import '../../assets/css/animations.scss';

import { HTMLAttributes, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { useSelector } from 'react-redux';

import { useGetImageMultimedia } from '../../core/api/useGetMultimedia';
import { ModeEnum } from '../../core/ui/enums/ModeEnum';
import { PlaybackEnum } from '../../core/ui/enums/PlaybackEnum';
import { PositionControlDef } from '../../model/definitions/PositionControlDef';
import { TimeControlDef } from '../../model/definitions/TimeControlDef';
import { VideoPanelDef } from '../../model/definitions/VideoPanelDef';
import PlayerContext from '../../pages/playground/playerContext/PlayerContext';
import { ActiveDef } from '../../store/slices/active-slice';
import { RootState } from '../../store/store';
import ElementContainer from './ElementContainer';

interface VideoElementProps extends HTMLAttributes<HTMLDivElement> {
  panelProps: VideoPanelDef;
  canvas: { cnvWidth?: number; cnvHeight?: number };
  disabled?: boolean;
  parentTime?: TimeControlDef[];
  inPoster?: boolean;
  posterId?: string;
  parentSize?: PositionControlDef;
  isMapOverlay?: boolean;
  mapId?: string;
  geoPosterId?: string;
  isVideoOver?: boolean;
  useProxy?: boolean;
}
export const VideoElement = ({
  canvas,
  panelProps,
  disabled,
  parentTime,
  posterId,
  inPoster,
  parentSize,
  isMapOverlay = false,
  mapId,
  geoPosterId,
  isVideoOver,
  useProxy = true,
}: VideoElementProps) => {
  const { mode } = useSelector<RootState, ActiveDef>((state) => state.active);
  const { data, refetch, downloadProgress } = useGetImageMultimedia(
    useProxy
      ? panelProps?.videoPanelDefTemplate?.proxyVideoFile?.id ||
          panelProps?.videoPanelDefTemplate?.file?.id
      : panelProps.videoPanelDefTemplate.versionId,
    false,
  );
  const ref = useRef<HTMLVideoElement>(null);
  const contextValue = useContext(PlayerContext);
  const { time, isPlaying, skip, boardSkip } = contextValue;
  const { timeControls } = panelProps;
  const [seekVal, setSeekVal] = useState<number>();
  const [projSkip, setProjSkip] = useState<{ boardSkip: number; time: number }>({
    boardSkip: boardSkip,
    time: time,
  });
  useEffect(() => {
    const video = ref.current;
    const handleError = (event: Event) => {
      refetch();
    };
    if (video && data) {
      video?.addEventListener('error', handleError);
      video.src = data;
    }
    return () => {
      video?.removeEventListener('error', handleError);
    };
  }, []);
  useEffect(() => {
    isVideoOver && refetch();
  }, [panelProps, isVideoOver]);
  useEffect(() => {
    setProjSkip({ ...projSkip, time });
  }, [time]);
  useEffect(() => {
    setProjSkip({ ...projSkip, boardSkip });
  }, [boardSkip]);
  /*** removed cleanup by request, reconsider this!!! ***/
  /*  useEffect(() => {
    return () => {
      if (data) URL.revokeObjectURL(data);
    };
  }, [data]); */
  const segment = timeControls?.find((segment) => segment.startMS <= time && segment.endMS >= time);
  const run = useCallback(() => {
    return segment;
  }, [segment]);
  const [ready, setReady] = useState<boolean>();

  useEffect(() => {
    const element = ref.current;
    if (element && run()) isPlaying === PlaybackEnum.PLAYING ? element.play() : element.pause();
  }, [isPlaying, boardSkip, time]);
  useEffect(() => {
    if (ref.current && panelProps.audioVolume) ref.current.volume = panelProps.audioVolume;
  }, [panelProps.audioVolume]);
  useEffect(() => {
    if (ref.current && panelProps.audioVolume) ref.current.volume = panelProps.audioVolume;
  }, [panelProps.audioVolume, isPlaying]);
  useEffect(() => {
    const element = ref.current;
    const seek = run()?.seekToTimeMS ?? 0;
    const start = run()?.startMS ?? 0;
    if (element) {
      element.currentTime = (time - start + seek) / 1000;
    }
    seek !== seekVal && setSeekVal(seek);
  }, [run, seekVal]);
  useEffect(() => {
    const start = run()?.startMS ?? 0;
    const seek = run()?.seekToTimeMS ?? 0;
    const element = ref.current;
    if (element && isPlaying !== PlaybackEnum.PLAYING) {
      element.currentTime = (time - start + seek) / 1000;
    }
    if (element && isPlaying === PlaybackEnum.PLAYING) {
      element.currentTime = (time - start + seek) / 1000;
    }
  }, [isPlaying, skip, run, seekVal, seekVal]);
  useEffect(() => {
    const seek = run()?.seekToTimeMS ?? 0;
    const start = run()?.startMS ?? 0;
    const element = ref.current;
    if (element && skip) {
      element.currentTime = (time - start + seek) / 1000;
    }
  }, [isPlaying, skip, seekVal, run]);
  useEffect(() => {
    const seek = run()?.seekToTimeMS ?? 0;
    const start = run()?.startMS ?? 0;
    const element = ref.current;
    if (element && mode === ModeEnum.PROJECT) {
      element.currentTime = (projSkip.time - start + seek) / 1000;
    }
  }, [projSkip.boardSkip, run]);
  useEffect(() => {
    const start = run()?.startMS ?? 0;
    const seek = run()?.seekToTimeMS ?? 0;
    const element = ref.current;
    if (element && isPlaying !== PlaybackEnum.PLAYING) {
      element.currentTime = (time - start + seek) / 1000;
    }
  }, [time]);
  return (
    /* !isVideoOver ? ( */
    <ElementContainer
      visibility={panelProps.enabled}
      canvas={canvas}
      disabled={!!disabled}
      type={'videoPanels'}
      panelProps={panelProps}
      parentTime={parentTime}
      inPoster={inPoster}
      posterId={posterId}
      parentSize={parentSize}
      key={panelProps.id}
      isMapOverlay={isMapOverlay}
      parentMapId={mapId}
      geoPosterId={geoPosterId}
      opacity={panelProps.opacity}
    >
      <video
        data-html2canvas-ignore="true"
        autoPlay={false}
        width={'100%'}
        height={'100%'}
        id={panelProps.id}
        ref={ref}
        src={data}
        muted={!panelProps.enableAudio}
        onLoadStart={(e) => {
          ref.current && (ref.current.volume = panelProps.audioVolume);
        }}
        onLoadedData={() => {
          setReady(true);
          ref.current ? (ref.current.currentTime = 0.001) : null;
        }}
        /* poster={
          !ready
            ? 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='
            : undefined
        } */
      />
      {!ready && (
        <div
          className="absolute w-full h-full flex items-center justify-center inset-0 bg-gray-400/50"
          style={{ flexDirection: 'column' }}
        >
          <CircularProgressbarWithChildren
            value={downloadProgress}
            maxValue={100}
            strokeWidth={3}
            styles={buildStyles({
              pathColor: `${ready ? '#7ac142' : '#2176ff'}`,
              trailColor: '#ffffff8f',
            })}
          >
            {ready ? (
              <div className="wrapper-check">
                <svg
                  className="checkmark-check"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 52 52"
                >
                  <circle className="checkmark__circle-check" cx="26" cy="26" r="25" fill="none" />{' '}
                  <path
                    className="checkmark__check-check"
                    fill="none"
                    d="M14.1 27.2l7.1 7.2 16.7-16.8"
                  />
                </svg>
              </div>
            ) : (
              <div style={{ fontSize: ' 35px', marginTop: '-5px', color: '#ffffff8f' }}>
                <strong>{`${downloadProgress}%`}</strong>
              </div>
            )}
          </CircularProgressbarWithChildren>
        </div>
      )}
    </ElementContainer>
  );
  /*  ) : (
    <>
      <video
        data-html2canvas-ignore="true"
        autoPlay={false}
        width={'100%'}
        height={'100%'}
        id={panelProps.id}
        ref={ref}
        src={data}
        onLoadStart={(e) => {
          ref.current && (ref.current.volume = panelProps.audioVolume);
        }}
        muted={!panelProps.enableAudio}
        onLoadedData={() => {
          setReady(true);
          ref.current ? (ref.current.currentTime = 0.001) : null;
        }}
        poster={
          !ready
            ? 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='
            : undefined
        }
      />
      {!ready && (
        <div
          className="absolute w-full h-full flex items-center justify-center inset-0 bg-gray-400/50"
          style={{ flexDirection: 'column' }}
        >
          <CircularProgressbarWithChildren
            value={downloadProgress}
            maxValue={100}
            strokeWidth={3}
            styles={buildStyles({
              pathColor: `${ready ? '#7ac142' : '#2176ff'}`,
              trailColor: '#ffffff8f',
            })}
          >
            {ready ? (
              <div className="wrapper-check">
                <svg
                  className="checkmark-check"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 52 52"
                >
                  <circle className="checkmark__circle-check" cx="26" cy="26" r="25" fill="none" />{' '}
                  <path
                    className="checkmark__check-check"
                    fill="none"
                    d="M14.1 27.2l7.1 7.2 16.7-16.8"
                  />
                </svg>
              </div>
            ) : (
              <div style={{ fontSize: ' 35px', marginTop: '-5px', color: '#ffffff8f' }}>
                <strong>{`${downloadProgress}%`}</strong>
              </div>
            )}
          </CircularProgressbarWithChildren>
        </div>
      )}
    </>
  ); */
};
