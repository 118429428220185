import './style.scss';

import { useState } from 'react';

interface ThumbDetailsProps {
  thumbs: Array<string>;
}
const ThumbSliderDetails = ({ thumbs }: ThumbDetailsProps) => {
  const [active, setActive] = useState<number>(0);
  const renderThumbs = () =>
    thumbs.map((thumb: string, index) => (
      <img
        key={index}
        src={thumb}
        className={'ws-list-thumb'}
        alt={thumb}
        onClick={() => setActive(index)}
      />
    ));
  return (
    thumbs && (
      <div>
        <div>
          <div
            className={'ws-active-thumb'}
            style={{
              background: `url(${thumbs[active]}) center center / contain no-repeat rgb(0, 0, 0)`,
            }}
          />
          <div className={'ws-thumb-list'}>{renderThumbs()}</div>
        </div>
      </div>
    )
  );
};
export default ThumbSliderDetails;
