import { useState } from 'react';

import Input from '../../../atoms/input/Input';
import TextArea from '../../../atoms/input/Textarea';
import LoadingIndicator from '../../../atoms/loadingIndicator/LoadingIndicator';
import { useGetLocations } from '../../../core/api/forecastData/useGetLocations';
import { useDebounce } from '../../../hooks/useDebounce';

interface ForecastFirstStepProps {
  basic: {
    name: string;
    description?: string;
    groupDate: boolean;
  };
  setBasic: (e: { name: string; description?: string; groupDate: boolean }) => void;
  location?: string;
  setLocation: (e: string) => void;
}
export const ForecastFirstStep = ({
  basic,
  setBasic,
  location,
  setLocation,
}: ForecastFirstStepProps) => {
  const [searchKey, setSearchKey] = useState<string | undefined>(location);
  const debounceSearch = useDebounce(searchKey);
  const { data: locations, isLoading } = useGetLocations(debounceSearch);

  const getBorders = () => {
    if (searchKey && location?.toUpperCase() !== searchKey.toUpperCase())
      return { borderBottomLeftRadius: 0, borderBottomRightRadius: 0 };
  };
  const handleStation = (val: string, name: string) => {
    setSearchKey(val);
    setBasic({ ...basic, name: name });
    setLocation(val);
  };
  return (
    <>
      <div className="relative location-wrapper">
        <Input
          value={locations?.find((item) => item.locationId === location)?.name}
          onChange={(e) => setSearchKey(e.target.value)}
          style={getBorders()}
          required
          label={'Location'}
        />
        {searchKey && location?.toUpperCase() !== searchKey.toUpperCase() && (
          <div className={'search-result items-center absolute'}>
            {isLoading ? (
              <div className={'flex w-1/2 loading'}>
                <div className={'flex items-center mr-3'}>
                  <LoadingIndicator />
                </div>
                Searching
              </div>
            ) : locations?.length && location?.toUpperCase() !== searchKey.toUpperCase() ? (
              locations.map((loc, index) => (
                <div
                  key={loc.name}
                  onClick={() => handleStation(loc.locationId, loc.name)}
                  className={'result'}
                >
                  {loc.name} {loc.locationId}
                </div>
              ))
            ) : (
              <div className={'flex justify-center items-center'}>no results</div>
            )}
          </div>
        )}
      </div>
      <Input
        onChange={(e) => setBasic({ ...basic, name: e.target.value })}
        value={basic.name}
        required
        label={'Name'}
      />
      <TextArea
        label={'Description'}
        value={basic.description}
        onChange={(e) => setBasic({ ...basic, description: e.target.value })}
      />
    </>
  );
};
