import { SceneDef } from '../../../../../model/definitions/SceneDef';
import { useGetRadarValue } from '../useGetRadarValue';

export const useUpdateRadar = () => {
  const { mutate: updateRadarValues, isLoading: isLoadingRadar } = useGetRadarValue();

  const updateRadar = (scene: SceneDef | null, dateRange: Array<number | null | undefined>) =>
    scene?.mapPanels.map((map) =>
      map.wdSpace.map((space) =>
        space.radarMapLayers.map(
          (radar) =>
            dateRange[0] &&
            dateRange[1] &&
            updateRadarValues({
              dataProductId: radar.radarSource.dataProductId,
              location: radar.radarSource.location,
              range: [dateRange[0], dateRange[1]],
              density: radar.dataFramesDensity,
              sceneId: scene.id,
              mapId: map.id,
              spaceId: space.id,
              layerId: radar.id,
            }),
        ),
      ),
    );

  return { updateRadar, isLoadingRadar };
};
