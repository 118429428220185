import { Checkbox, Modal, Table, Tag } from 'antd';
import { useEffect, useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';

import { useDebounce } from '../../../../hooks/useDebounce';
import { useGetDataProducts } from '../../../dataprovider/hooks/useGetDataProducts';
import { DataProductInterface } from '../../../dataprovider/pages/Products';
import { useAddAssignedDataProducts } from '../../queries-NEW/useAddAssignedDataProducts';
import { useDeleteAssignedDataProduct } from '../../queries-NEW/useDeleteAssignedDataProduct';

interface AssignDataProductsInterface {
  data: DataProductInterface[];
  isLoading: boolean;
  enterpriseId: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AssignDataProductsModal = ({
  data,
  isLoading,
  enterpriseId,
  open,
  setOpen,
}: AssignDataProductsInterface) => {
  const [page, setPage] = useState<number>(0);
  const [search, setSearch] = useState<string>('');
  const searchKey = useDebounce(search, 300);
  const [assignedDataProducts, setAssignedDataProducts] = useState<string[]>([]);
  const [unassignedDataProducts, setUnassignedDataProducts] = useState<string[]>([]);
  const [filteredData, setFilteredData] = useState<DataProductInterface[]>([]);
  const [checkData, setCheckData] = useState<any[]>([]);
  const { mutate: addDataProduct } = useAddAssignedDataProducts();
  const { mutate: deleteDataProduct } = useDeleteAssignedDataProduct();
  const { data: dataProducts } = useGetDataProducts({ page, searchKey });

  const columns = [
    {
      dataIndex: 'checked',
      key: 'checked',
      render: (checked: boolean, product: DataProductInterface) => (
        <Checkbox checked={checked} onChange={(e) => handleAdd(product, e.target.checked)} />
      ),
    },
    {
      title: 'Image',
      dataIndex: 'thumbnailUrls',
      key: 'thumbnailUrls',
      render: (thumbnailUrls: string) => (
        <img
          //remove later
          src={
            thumbnailUrls[0]?.indexOf('no%20audio') === -1
              ? thumbnailUrls[0]
              : 'https://media.istockphoto.com/vectors/no-thumbnail-image-vector-graphic-vector-id1147544806?k=20&m=1147544806&s=170667a&w=0&h=5rN3TBN7bwbhW_0WyTZ1wU_oW5Xhan2CNd-jlVVnwD0='
          }
          className="h-[45px]"
        />
      ),
      width: 100,
    },
    {
      title: 'Base Name',
      dataIndex: 'baseName',
      key: 'baseName',
    },
    {
      title: 'Product Type',
      dataIndex: 'productType',
      key: 'productType',
      render: (productType: string) => (
        <Tag
          color={
            productType === 'SATELLITE' ? 'purple' : productType === 'MODEL' ? 'orange' : 'cyan'
          }
        >
          {productType}
        </Tag>
      ),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
  ];

  const handleAdd = (product: DataProductInterface, checked: boolean) => {
    if (!product.id) {
      return;
    }

    const updatedArray = filteredData?.map((dp: DataProductInterface) => {
      if (product.id === dp.id) {
        return { ...dp, checked };
      } else {
        return dp;
      }
    });

    const findProduct = checkData?.find((dp) => product.id === dp.id && dp.checked);

    if (checked) {
      if (findProduct) {
        setUnassignedDataProducts((prevUnassigned) =>
          prevUnassigned?.filter((udp) => udp !== product.id),
        );
      } else {
        setAssignedDataProducts([...assignedDataProducts, product.id]);
      }
    } else {
      setAssignedDataProducts((prevAssigned) => prevAssigned?.filter((adp) => adp !== product.id));
      if (findProduct) {
        setUnassignedDataProducts([...unassignedDataProducts, product.id]);
      }
    }

    setFilteredData(updatedArray);
  };

  const save = () => {
    if (assignedDataProducts.length > 0) {
      addDataProduct(
        { enterpriseAccountID: enterpriseId, dataProductIds: assignedDataProducts },
        {
          onSuccess() {
            setAssignedDataProducts([]);
            setSearch('');
            setOpen(false);
          },
        },
      );
    }
    if (unassignedDataProducts.length > 0) {
      unassignedDataProducts.forEach((udp) => {
        deleteDataProduct({ enterpriseAccountID: enterpriseId, dataProductId: udp });
      });
    }
  };

  useEffect(() => {
    const array: any[] = [];
    dataProducts?.content?.forEach((dp: DataProductInterface) => {
      if (data?.some((d: DataProductInterface) => d.id === dp.id)) {
        array.push({ ...dp, checked: true });
      } else {
        array.push({ ...dp, checked: false });
      }
    });
    setFilteredData(array);
    setCheckData(array);
    if (page !== 0) {
      setPage(0);
    }
  }, [data, dataProducts]);

  return (
    <Modal
      title={
        <>
          <div className={'flex items-center justify-between'}>
            <span>Assign Data Products</span>
            <div className={'ws-input'} style={{ height: '32px' }}>
              <input
                autoFocus
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                placeholder={'Type here...'}
                className="w-full 2xl:!w-[300px]"
              />
              <AiOutlineSearch color={'rgba(0,0,0,0.3)'} size={24} />
            </div>
          </div>
        </>
      }
      open={open}
      width="50%"
      centered
      onCancel={() => {
        setAssignedDataProducts([]);
        setSearch('');
        setOpen(!open);
      }}
      onOk={() => save()}
      closable={false}
      okButtonProps={{ disabled: !assignedDataProducts.length && !unassignedDataProducts.length }}
      destroyOnClose
    >
      <Table
        dataSource={filteredData}
        columns={columns}
        rowKey="id"
        loading={isLoading}
        pagination={{
          showSizeChanger: false,
          current: dataProducts?.page,
          pageSize: dataProducts?.size,
          total: dataProducts?.totalElements,
          onChange(page) {
            setPage(page - 1);
          },
        }}
      />
    </Modal>
  );
};
