import { ExclamationCircleFilled } from '@ant-design/icons';
import { Checkbox, Modal, Popover } from 'antd';
import { format } from 'date-fns';
import { Badge, Progress, Tooltip } from 'flowbite-react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  AiOutlineCloudDownload,
  AiOutlineDelete,
  AiOutlineInfoCircle,
  AiOutlineLineChart,
} from 'react-icons/ai';
import { BiRefresh } from 'react-icons/bi';
import { useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';

import Button from '../../../atoms/button/Button';
import { useDeleteAssemblyStatusBulk } from '../../../core/api/useDeleteAssemblyStatusBulk';
import { useGetAssemblyTaskById } from '../../../core/api/VideoAssemblyAPI';
import formatFileSize from '../../../helpers/formatFileSize';
import { VideoAssemblyTaskDTO } from '../../../model/definitions/VideoAssemblyTaskDTO';
import { SftpStatusEnum } from '../../../model/enums/SftpStatus';
import { TaskStatusEnum } from '../../../model/enums/TaskStatusEnum';
import Loader from '../../dashboard/components/Loader';

const { confirm } = Modal;

interface Props {
  opened?: boolean;
  onClose?: () => void;
  statuses?: VideoAssemblyTaskDTO[];
}

const AssemblyStatusModal: React.FC<Props> = ({ opened, onClose, statuses }) => {
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [showNumber, setShowNumber] = useState<number>(7);
  const queryClient = useQueryClient();
  const [id, setId] = useState<string>('');
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);
  const { data, isLoading: loadStatistics } = useGetAssemblyTaskById(id);
  const { mutate: deleteSelectedStatuses } = useDeleteAssemblyStatusBulk();

  const statistics = data
    ? Object.keys(data.statistics).map((key) => [key, data.statistics[key]])
    : [];

  statistics.sort(function (a, b) {
    const numA = parseInt(a[0].slice(0, 2));
    const numB = parseInt(b[0].slice(0, 2));
    const charA = a[0].charAt(2);
    const charB = b[0].charAt(2);
    if (numA !== numB) {
      return numA - numB;
    }
    if (charA !== ' ' && charB !== ' ') {
      if (charA < charB) {
        return -1;
      }

      if (charA > charB) {
        return 1;
      }
    }
    return 0;
  });

  statistics.pop();
  function refresh() {
    queryClient.invalidateQueries('render-status-by-project');
    setRefreshLoading(true);
    const timer = setTimeout(() => {
      setRefreshLoading(false);
    }, 500);
    return () => clearTimeout(timer);
  }

  function handleShowNumber() {
    if (statuses) {
      showNumber + 7 < statuses?.length
        ? setShowNumber(showNumber + 7)
        : setShowNumber(statuses?.length);
    }
  }

  function checkStatus(status: string) {
    return status === TaskStatusEnum.FINISHED
      ? 'success'
      : status === TaskStatusEnum.FAILED
      ? 'failure'
      : status === TaskStatusEnum.IN_PROGRESS
      ? 'gray'
      : 'info';
  }

  const selectAllStatuses = () => {
    if (!statuses) return;
    if (selectedStatuses.length === statuses.length) {
      setSelectedStatuses([]);
    } else {
      setSelectedStatuses(statuses?.map((obj) => obj.id) || []);
    }
  };

  const onStatusSelect = (status: string) => {
    if (selectedStatuses.indexOf(status) != -1) {
      setSelectedStatuses(selectedStatuses.filter((s) => s != status));
    } else {
      setSelectedStatuses([...selectedStatuses, status]);
    }
  };

  const handleDelete = () => {
    confirm({
      title: 'Delete assembly ',
      icon: <ExclamationCircleFilled />,
      content: 'Are you sure you want to delete this?',
      centered: true,
      okText: 'Delete',
      okType: 'danger',
      className: 'dark-modal',
      onOk() {
        deleteSelectedStatuses(selectedStatuses);
      },
      onCancel() {},
    });
  };

  useEffect(() => {
    setSelectedStatuses([]);
  }, [statuses]);

  const statisticsContent = (
    <div
      className={`max-h-[95vh] overflow-auto ${loadStatistics && 'max-w-[424px] max-h-[482px]'}`}
    >
      {loadStatistics ? (
        <Loader />
      ) : (
        <table className="max-w-[424px] overflow-auto bg-[#06152b]">
          <tbody>
            {statistics.length > 0 ? (
              statistics.map((statistic) => {
                return (
                  <tr key={statistic[0]}>
                    <td className="border border-slate-300 p-[6px] text-slate-300">
                      {statistic[0]}
                    </td>
                    <td className="border border-slate-300 p-[6px] text-slate-300">
                      {statistic[1]}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td className="border border-slate-300 p-2 text-slate-300">No data</td>
              </tr>
            )}
          </tbody>
        </table>
      )}
    </div>
  );
  return (
    <div className="assembly-status">
      <h1>Render status</h1>
      <div className="overflow-y-auto max-h-[72vh] scrollbar">
        <div className="flex flex-col assembly-status-head">
          <div className="flex">
            <div className="assembly-col assembly-col-no-min-w">
              <p>
                <Checkbox
                  checked={selectedStatuses.length === statuses?.length}
                  onChange={selectAllStatuses}
                />
              </p>
            </div>
            <div className="assembly-col">
              <p>Name</p>
            </div>
            <div className="assembly-col !min-w-[120px]">
              <p>Started</p>
            </div>
            <div className="assembly-col !min-w-[100px]">
              <p>Status</p>
            </div>
            <div className="assembly-col">
              <p>Percent</p>
            </div>
            <div className="assembly-col">
              <p>Job Type</p>
            </div>
            <div className="assembly-col">
              <p>Sftp Name</p>
            </div>
            <div className="assembly-col !min-w-[120px]">
              <p>Sftp Status</p>
            </div>
            <div className="assembly-col !min-w-[80px]">
              <p>Size</p>
            </div>
            <div className="assembly-col !min-w-fit">
              <p>Actions</p>
            </div>
          </div>
        </div>
        {statuses?.slice(0, showNumber).map((s) => {
          const outputFormat = s.outputVideo?.properties.outputFileFormat
            ? s.outputVideo?.properties.outputFileFormat
            : '.mp4';
          return (
            <div key={s.id} className="flex flex-col assembly-status-body">
              <div className="flex">
                <div className="assembly-col assembly-col-no-min-w">
                  <p>
                    <Checkbox
                      checked={selectedStatuses.includes(s.id)}
                      onChange={() => onStatusSelect(s.id)}
                    />
                  </p>
                </div>
                <div className="assembly-col break-all max-w-[150px]">
                  <p className="txt">{s.name}</p>
                </div>
                <div className="assembly-col !min-w-[120px] max-w-[120px]">
                  <p className="txt">
                    {format(new Date(Number(s.creationDate)), 'EEEE dd.MM.yyyy HH:mm:ss')}
                  </p>
                </div>
                <div className="assembly-col !min-w-[100px]">
                  {s.status !== TaskStatusEnum.FINISHED &&
                  moment().diff(moment(s.latestResponseDate), 'h') > 2 ? (
                    <Badge color="failure" size="sm">
                      <Tooltip content="VA not responsive">FAILED</Tooltip>
                    </Badge>
                  ) : (
                    <Badge color={checkStatus(s.status)} size="sm">
                      <Tooltip content={s.errorMessage ? s.errorMessage : null}>{s.status}</Tooltip>
                    </Badge>
                  )}
                </div>
                <div className="assembly-col">
                  <div className="flex-center">
                    <Progress
                      color="purple"
                      size="lg"
                      style={{ width: '100px', fontWeight: 100, fontSize: '10px' }}
                      progress={s.percentageFinished}
                      label={``}
                      labelPosition="inside"
                    />
                    <p>{`${s.percentageFinished.toFixed(0)} %`}</p>
                  </div>
                </div>
                <div className="assembly-col">
                  <p className="txt">
                    {s.thinClientTask
                      ? 'Thin client job'
                      : `Render ${
                          s.isImage ? 'image' : s.outputVideo?.properties.outputFileType ?? 'video'
                        } file`}
                  </p>
                </div>
                <div className="assembly-col">
                  <p className="txt break-all max-w-[155px]">{s.sftpName}</p>
                </div>
                <div className="assembly-col !min-w-[120px]">
                  {s.sftpStatus && (
                    <Badge
                      color={s.sftpStatus === SftpStatusEnum.SUCCESS ? 'success' : 'failure'}
                      size="sm"
                    >
                      {s.sftpStatus}
                    </Badge>
                  )}
                </div>
                <div className="assembly-col !min-w-[80px]">
                  {s.sizeInMB ? formatFileSize(s.sizeInMB * 1000 * 1000) : ''}
                </div>
                <div className="assembly-col !min-w-fit">
                  <Tooltip content="Download" style={`dark`}>
                    <a
                      href={
                        process.env.REACT_APP_API_BASE_URL +
                        '/api/public/store/multimedia/download/video/' +
                        s.id +
                        '?fileName=' +
                        encodeURIComponent(s.name) +
                        ' - ' +
                        moment(s.creationDate).format('DD.MM.yyyy HH:mm:ss') +
                        outputFormat
                      }
                      target="_blank"
                      download
                      rel="noreferrer"
                      className={`${!s.outputVideo && 'pointer-events-none'}`}
                    >
                      <Button
                        buttonType="link"
                        disabled={!s.outputVideo}
                        icon={<AiOutlineCloudDownload color="white" />}
                        className="!m-0"
                      />
                    </a>
                  </Tooltip>

                  <div className="ml-2">
                    <Tooltip content="Statistics" style={`dark`}>
                      <Popover
                        placement="left"
                        content={statisticsContent}
                        trigger="click"
                        style={{ width: 300 }}
                      >
                        <Button
                          onClick={() => setId(s.id)}
                          buttonType="link"
                          disabled={!s.outputVideo}
                          icon={<AiOutlineInfoCircle color="white" />}
                        />
                      </Popover>
                    </Tooltip>
                  </div>
                  <Tooltip content="Charts" style={`dark`}>
                    <Link
                      to={`/vr-profiler?taskId=${s.id}`}
                      target="_blank"
                      className={`${!s.outputVideo && 'pointer-events-none'}`}
                    >
                      <Button
                        buttonType="link"
                        disabled={!s.outputVideo}
                        icon={<AiOutlineLineChart color="white" />}
                      />
                    </Link>
                  </Tooltip>
                </div>
              </div>
            </div>
          );
        })}
        {statuses && statuses.length > showNumber && (
          <div className="flex justify-center mt-2">
            <Button label={'Show more'} buttonType="secondary" onClick={handleShowNumber} />
          </div>
        )}
      </div>
      <div className="actions">
        {selectedStatuses.length > 0 && (
          <Button
            buttonType="danger"
            onClick={handleDelete}
            label="Delete"
            icon={<AiOutlineDelete />}
            loading={refreshLoading}
          />
        )}
        <Button
          buttonType="secondary"
          onClick={refresh}
          label="Refresh"
          icon={<BiRefresh />}
          loading={refreshLoading}
        />
        <Button label={'OK and Close'} buttonType="primary" onClick={onClose} />
      </div>
    </div>
  );
};

export default AssemblyStatusModal;
