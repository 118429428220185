import { useMutation } from 'react-query';

import { C9ProjectDef } from '../../../model/definitions/C9ProjectDef';
import { axiosInstance } from '../axiosInstance';

export const useAssembleVideo = () =>
  useMutation(
    async ({
      type,
      projectDef,
      sceneDefId,
      sendToThinClient,
      isImage,
    }: {
      type: 'project' | 'scene';
      projectDef: C9ProjectDef;
      sceneDefId?: string | null;
      sendToThinClient?: boolean;
      isImage?: boolean;
    }) => {
      const { data } = await axiosInstance.post('video-assembly-task', {
        type,
        projectDef,
        sceneDefId,
        sendToThinClient,
        isImage,
      });
      return data;
    },
  );
