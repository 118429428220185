import '../../../playground/properties/components/custom-palette/style.scss';

import { Checkbox, Form, InputNumber } from 'antd';
import { ToggleSwitch } from 'flowbite-react';
import { useCallback, useEffect } from 'react';
import { AiFillPlusCircle, AiOutlineDelete } from 'react-icons/ai';

import { ColorPaletteDef, PaletteSetupDegree } from '../../../../model/definitions/ColorPaletteDef';
import { CustomPaletteDTO } from '../../../../model/DTO/CustomPaletteDTO';
import { PaletteLegendScalingEnum } from '../../../../model/enums/PaletteLegendScalingEnum';
import { CustomLegend } from '../../../playground/properties/components/custom-palette/CustomLegend';
import {
  defaultDegree,
  interpolateInSteps,
  middleColor,
  numberFormatter,
} from '../../../playground/properties/components/custom-palette/utils';
import { PaletteColorPicker } from '../../../playground/properties/mapLayersProperties/PalettecolorPicker';

interface CustomPaletteV2Props {
  palette: ColorPaletteDef | CustomPaletteDTO;
  isEdit: boolean;
  min: number;
  max: number;
  setPalette: (value: React.SetStateAction<ColorPaletteDef | CustomPaletteDTO>) => void;
  setMin: (value: React.SetStateAction<number>) => void;
  setMax: (value: React.SetStateAction<number>) => void;
}
export const CustomPaletteV2 = ({
  palette,
  isEdit,
  min,
  max,
  setPalette,
  setMin,
  setMax,
}: CustomPaletteV2Props) => {
  useEffect(() => {
    if (palette && !palette.setup?.scale) {
      const degrees: Array<PaletteSetupDegree> = Object.keys(palette.colorStops.pallet).map(
        (key) => {
          return {
            degree: Number(key),
            color: palette.colorStops.pallet[key as unknown as number],
            active: true,
            interpolationSteps: 0,
          };
        },
      );
      setPalette({
        ...palette,
        setup: { defaultStep: 0, scale: degrees.sort((a, b) => a.degree - b.degree) },
      });
    }
  }, [palette.colorStops.pallet, palette]);
  useEffect(() => {
    if (isEdit && palette.setup) {
      const low = Math.min(...palette.setup.scale.map((item) => item.degree));
      setMin(low);
      const high = Math.max(...palette.setup.scale.map((item) => item.degree));
      setMax(high);
    }
  }, [isEdit, palette.setup]);

  const updateScale = (e: number, maxVal?: number | null, minVal?: number | null) => {
    const newScale = [];
    const degrees = interpolateInSteps(minVal ?? min, maxVal ?? max, e);
    if (palette.setup) {
      for (let i = 0; i < degrees.length; i++) {
        newScale.push({
          ...defaultDegree,
          degree: degrees[i],
        });
      }
      const setup = {
        ...palette.setup,
        scale: newScale,
      };
      setPalette({
        ...palette,
        setup,
      });
    }
  };
  const cleanPalette = useCallback(() => {
    if (palette.setup) {
      const redefinedPalette = Object.fromEntries(
        palette.setup?.scale.map((item) => [item.degree, item.color]),
      );
      setPalette({
        ...palette,
        colorStops: {
          ...palette.colorStops,
          pallet: redefinedPalette,
        },
      });
    }
  }, [palette.setup]);

  useEffect(() => {
    cleanPalette();
  }, [cleanPalette, palette.setup]);

  const changeInterpolationStep = (e: number, degree: number) => {
    const steps = palette.setup?.scale;
    if (steps) {
      const pointInScale = steps.find((val) => val.degree === degree);
      if (pointInScale && palette.setup) {
        pointInScale.interpolationSteps = e;
        const setup = {
          ...palette.setup,
          scale: steps,
        };
        setPalette({
          ...palette,
          setup,
        });
      }
    }
  };
  const changeColor = (e: string, degree: number) => {
    const steps = palette.setup?.scale;
    if (steps) {
      const pointInScale = steps.find((val) => val.degree === degree);
      if (pointInScale && palette.setup) {
        pointInScale.color = e;
        const setup = {
          ...palette.setup,
          scale: steps,
        };
        setPalette({
          ...palette,
          setup,
        });
      }
    }
  };
  const changeDegree = (e: number, degree: number) => {
    const steps = palette.setup?.scale;
    if (steps) {
      const pointInScale = steps.find((val) => val.degree === degree);
      if (pointInScale && palette.setup) {
        pointInScale.degree = e;
        const setup = {
          ...palette.setup,
          scale: steps,
        };
        setPalette({
          ...palette,
          setup,
        });
      }
    }
  };
  const changeDefaultStep = (e: number) => {
    const steps = palette.setup?.scale.map((point) => {
      return { ...point, interpolationSteps: e };
    });
    if (steps && palette.setup) {
      const setup = {
        ...palette.setup,
        defaultStep: e,
        scale: steps,
      };
      setPalette({
        ...palette,
        setup,
      });
    }
  };
  const setDegreeEnabled = (e: boolean, degree: number) => {
    const steps = palette.setup?.scale;
    if (steps) {
      const pointInScale = steps.find((val) => Number(val.degree) === Number(degree));
      if (pointInScale && palette.setup) {
        pointInScale.active = e;
        const setup = {
          ...palette.setup,
          scale: steps,
        };
        setPalette({
          ...palette,
          setup,
        });
      }
    }
  };
  const removeColor = (degree: number) => {
    if (palette.setup) {
      const newScale = palette.setup?.scale.filter((obj) => obj.degree !== degree);
      const setup = {
        ...palette.setup,
        scale: newScale,
      };
      setPalette({
        ...palette,
        setup,
      });
    }
  };
  const addColor = (degreeToAdd: number) => {
    if (palette.setup) {
      const sortedData = palette.setup.scale.slice().sort((a, b) => a.degree - b.degree);
      const index = sortedData?.findIndex((obj) => obj.degree > degreeToAdd);

      if (index !== -1) {
        const nextHighestElement = sortedData[index];
        const newElement = {
          ...nextHighestElement,
          color: middleColor(sortedData[index - 1].color, sortedData[index].color),
          degree: (degreeToAdd + nextHighestElement.degree) / 2,
        };
        middleColor(sortedData[index - 1].color, sortedData[index].color);
        const newScale = [...palette.setup.scale, newElement];
        const setup = {
          ...palette.setup,
          scale: newScale,
        };
        setPalette({
          ...palette,
          setup,
        });
      }
    }
  };
  const renderPickers = useCallback(() => {
    const stops = palette.setup?.scale.sort((a, b) => a.degree - b.degree);
    const grid = stops?.map((stop, index) => (
      <div key={stop.degree} className={'grid grid-cols-10 gap-3 items-center justify-center'}>
        <InputNumber
          defaultValue={stop.degree.toString()}
          formatter={(e) => numberFormatter(e, 5)}
          className={'col-span-2'}
          onBlur={(e) => changeDegree(Number(e.target.value), stop.degree)}
          onKeyDown={(e: any) => {
            if (e && e?.code === 'Enter' && !isNaN(Number(e.target.value))) {
              changeDegree(Number(e.target.value), stop.degree);
              blur();
            }
          }}
          onChange={(e: any) => {
            if (e && e.code === 'Enter' && !isNaN(Number(e.target.value))) {
              changeDegree(Number(e.target.value), stop.degree);
              blur();
            }
          }}
        />
        <div className={'col-span-4'}>
          <PaletteColorPicker
            isPalette
            value={stop.color}
            onChange={(e) => changeColor(e, stop.degree)}
            outline
            pickerStyle={{ left: 25 }}
          />
        </div>
        {index !== (stops?.length ?? 0) - 1 ? (
          <InputNumber
            key={`${stop.interpolationSteps}_${stop.degree}`}
            value={stop.interpolationSteps ?? palette.setup?.defaultStep}
            className={`justify-self-end col-span-2 w-1/2 text-blue-700 input:text-blue-700 ${
              palette.setup?.defaultStep !== stop.interpolationSteps && 'step-input-diff'
            }`}
            min={0}
            max={900}
            precision={0}
            onChange={(e) => e && changeInterpolationStep(e, stop.degree)}
          />
        ) : (
          <div className={'col-span-2'} />
        )}
        <Checkbox
          className={'justify-center'}
          checked={stop.active}
          onChange={(e) => setDegreeEnabled(e.target.checked, stop.degree)}
        />
        <div className={'flex justify-between gap-3'}>
          <AiOutlineDelete
            className={'text-red-700 cursor-pointer'}
            size={18}
            onClick={() => removeColor(stop.degree)}
          />
          {index < stops?.length - 1 && (
            <AiFillPlusCircle
              className={'text-green-700 cursor-pointer'}
              size={18}
              onClick={() => addColor(stop.degree)}
            />
          )}
        </div>
      </div>
    ));
    return <>{grid}</>;
  }, [palette.setup?.defaultStep, palette.setup?.scale]);

  return (
    <>
      <div className={'flex gap-5 justify-center'}>
        <Form.Item label="Min value" style={{ width: '50%' }} required>
          <InputNumber
            value={min}
            className={'w-full'}
            disabled={isEdit}
            max={max}
            onChange={(e) => {
              e !== null && setMin(e);
              palette.setup &&
                e !== null &&
                updateScale(palette.setup.scale.length - 1, undefined, e);
            }}
          />
        </Form.Item>

        <Form.Item label="Max value" style={{ width: '50%' }} required>
          <InputNumber
            value={max}
            className={'w-full'}
            disabled={isEdit}
            onChange={(e) => {
              e !== null && setMax(e);
              palette.setup && updateScale(palette.setup.scale.length - 1, e);
            }}
          />
        </Form.Item>
      </div>
      <div className={'flex gap-5'}>
        <Form.Item label={'No of colors:'} style={{ width: '50%', paddingRight: 10 }} required>
          <InputNumber
            value={palette.setup?.scale.length}
            className={'w-full'}
            disabled={isEdit || min === max}
            min={1}
            onChange={(e) => e !== null && updateScale(e - 1)}
          />
        </Form.Item>
      </div>

      <div className="w-[86%] mx-auto">
        <div className={`my-5`}>
          <div className={'uppercase text-left text-xl text-black'}>Legend</div>
          <hr />
        </div>
        <div className={'flex gap-10 items-center'}>
          <div>Preview</div>
          <div className={'outline outline-1 outline-[#d9d9d9]'}>
            {palette.setup && (
              <CustomLegend
                setup={palette.setup}
                key={JSON.stringify(palette.setup)}
                proportional={palette.paletteLegendScaling}
                w={1000}
              />
            )}
          </div>
          <div className={'flex gap-2 justify-self-end justify-between relative'}>
            <span className={'absolute top-5 text-sm text-red-500'}>preview purposes only!</span>
            <div>homogenous</div>
            <ToggleSwitch
              label={''}
              checked={palette.paletteLegendScaling === PaletteLegendScalingEnum.PROPORTIONAL}
              onChange={(e) =>
                setPalette({
                  ...palette,
                  paletteLegendScaling: e
                    ? PaletteLegendScalingEnum.PROPORTIONAL
                    : PaletteLegendScalingEnum.HOMOGENOUS,
                })
              }
            />{' '}
            <div>proportional</div>
          </div>
        </div>
        <div
          className={`my-5 grid grid-cols-10 gap-3 font-light border-b border-white/[.07] layer-header items-center justify-between`}
        >
          <div className={'uppercase col-span-6'}>Colors</div>
          <div className={'flex items-center gap-3 col-span-2 justify-end'}>
            Step
            <InputNumber
              min={0}
              max={900}
              value={palette.setup?.defaultStep}
              className={'justify-self-end w-1/2'}
              onChange={(e) => typeof e === 'number' && changeDefaultStep(e)}
            />
          </div>
          <div className={'text-center'}>Legend</div>
        </div>
      </div>
      <div className={`w-[86%] mx-auto`}>{renderPickers()}</div>
    </>
  );
};
