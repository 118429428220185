import { cloneDeep } from 'lodash';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { C9ProjectDef } from '../../model/definitions/C9ProjectDef';
import { addNewProject, setSavedProject } from '../../store/slices/project-slice';
import { axiosInstance } from './axiosInstance';

export const useEditSharedProject = () => {
  const dispatch = useDispatch();

  return useMutation(
    async (versionId: string): Promise<C9ProjectDef> => {
      const response = await axiosInstance.post<C9ProjectDef>(`project/edit-shared/${versionId}`);
      return response.data;
    },
    {
      onSuccess: (data) => {
        const project = cloneDeep(data);
        dispatch(addNewProject({ project }));
        dispatch(setSavedProject({ savedProject: project }));
        toast.success('Successfully created working version of shared project');
      },
    },
  );
};
