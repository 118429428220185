import { PaymentMethod } from '@stripe/stripe-js';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { axiosInstance } from '../../../../core/api/axiosInstance';
import { queryClient } from '../../../../index';
import { key } from './useGetListOfCards';

export const useRemoveCard = () => {
  return useMutation(
    async (id?: string) => {
      return await axiosInstance.delete<PaymentMethod>(`payment/user/remove-payment-method/${id}`);
    },
    {
      onSuccess: () => {
        toast.success('Payment method successfully removed!');
        queryClient.invalidateQueries([...key]);
      },
      onError: () => {
        toast.error('Error while removing payment method!');
      },
    },
  );
};
