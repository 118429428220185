import { Checkbox, Col, Row } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { Select } from 'flowbite-react';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';

import Input from '../../../../atoms/input/Input';
import { useGetGrib, valuesKey } from '../../../../core/api/mapLayers/useGetGrib';
import { useGetGribModelType } from '../../../../core/api/mapLayers/useGetGribModelType';
import { shouldHaveInterpolateOption } from '../../../../core/weather-data/helpers/interpolationPropertyHelper';
import { queryClient } from '../../../../index';
import { MAX_ZINDEX_VALUE } from '../../../../model/constants/constants';
import { ColorPalette } from '../../../../model/definitions/ColorPalette';
import { ColorPaletteDef } from '../../../../model/definitions/ColorPaletteDef';
import { DataFrameDef } from '../../../../model/definitions/DataFrameDef';
import { GribMapLayer } from '../../../../model/definitions/GribMapLayer';
import { MapPanelDef } from '../../../../model/definitions/MapPanelDef';
import { PickerDef } from '../../../../model/definitions/PickerDef';
import { RadarMapLayer } from '../../../../model/definitions/RadarMapLayer';
import { SatelliteMapLayer } from '../../../../model/definitions/SatelliteMapLayer';
import { ColorPaletteParamTypeEnum } from '../../../../model/enums/ColorPaletteParamTypeEnum';
import {
  FilteringTypeEnum,
  PostProcessingTypeEnum,
} from '../../../../model/enums/FilteringTypeEnum';
import { InterpolationEnum } from '../../../../model/enums/InterpolationEnum';
import {
  isTileVisualisationType,
  VisualisationTypeEnum,
  VisualisationTypeName,
} from '../../../../model/enums/VisualisationTypeEnum';
import { filterWithSelection } from '../../../../molecules/mapElement/helpers';
import PaletteIsoline from '../../../../molecules/palette/PaletteIsoline';
import { PaletteRefactor } from '../../../../molecules/palette/PaletteRefactor';
import {
  ParameterMappingInterface,
  VisualizationTypeInterface,
} from '../../../dataprovider/pages/Products';
import InputNumber from '../../../marketplace-new/atoms/FormatNumber/FormatNumber';
import { FirstLayerRange } from '../../modals/frameRangeHelpers';
import { FramesRange } from '../../modals/FramesRange';
import { PropertySection } from '../components/PropertySection';
import styles from '../Properties.module.scss';
import { CustomPalette } from './CustomPalette';

export const isGribLayerType = (
  l: GribMapLayer | RadarMapLayer | SatelliteMapLayer,
): l is GribMapLayer => {
  return (l as GribMapLayer).gribSource !== undefined;
};

interface MapLayersProps {
  oldLayer: boolean;
  dataProductId: string | null;
  setDataProductId: (e: string) => void;
  bounds: [number, number, number, number];
  name?: string;
  setName: (e: string) => void;
  mapLayer: MapPanelDef;
  onMapLayerChange: (propertyPath: keyof MapPanelDef, newValue: GribMapLayer[]) => void;
  source: PickerDef | undefined;
  setSource: (e?: PickerDef) => void;
  param: PickerDef | undefined;
  setParam: (e?: PickerDef) => void;
  selectedDataFrames: Array<DataFrameDef>;
  z: number;
  setZ: React.Dispatch<React.SetStateAction<number>>;
  setSelectedDataFrames: (e: Array<DataFrameDef>) => void;
  interpolation: InterpolationEnum;
  filteringType: FilteringTypeEnum | null;
  postProcessing: PostProcessingTypeEnum | null;
  embossEffect: number;
  setEmbossEffect: (e: number) => void;
  numberOfIterations: number;
  setNumberOfIterations: (e: number) => void;
  isolineWidth: number;
  setIsolineWidth: (e: number) => void;
  setInterpolation: React.Dispatch<React.SetStateAction<InterpolationEnum>>;
  setFilteringType: React.Dispatch<React.SetStateAction<FilteringTypeEnum | null>>;
  setPostProcessingType: React.Dispatch<React.SetStateAction<PostProcessingTypeEnum | null>>;
  palette: ColorPaletteDef | null;
  setPalette: (palettes: ColorPaletteDef | null) => void;
  tempPalettes: ColorPaletteDef[] | undefined;
  cloudPalettes: ColorPaletteDef[] | undefined;
  colorStops: ColorPalette | Record<string, string>;
  setColorStops: (cs: Record<string, string>) => void;
  interval: number;
  setInterval: (int: number) => void;
  gribLayerEdit?: GribMapLayer;
  rangeFromFirstLayer: FirstLayerRange | null;
  gribModel: PickerDef | undefined;
  setGribModel: React.Dispatch<React.SetStateAction<PickerDef | undefined>>;
  visualisationType: VisualisationTypeEnum | undefined;
  setVisualisationType: React.Dispatch<React.SetStateAction<VisualisationTypeEnum | undefined>>;
  enableInterpolation: boolean | undefined;
  setEnableInterpolation: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  windParticleDensity: number;
  setWindParticleDensity: (int: number) => void;
  setFramesDensity: React.Dispatch<React.SetStateAction<number>>;
  framesDensity: number;
  enterpriseAccountId: string;
  parameterMappings: ParameterMappingInterface;
}
const MapGribLayers = ({
  oldLayer,
  dataProductId,
  setDataProductId,
  bounds,
  name,
  setName,
  setParam,
  setSource,
  param,
  source,
  selectedDataFrames,
  setSelectedDataFrames,
  z,
  setZ,
  filteringType,
  interpolation,
  setFilteringType,
  setInterpolation,
  palette,
  setPalette,
  tempPalettes,
  gribLayerEdit,
  rangeFromFirstLayer,
  gribModel,
  setGribModel,
  visualisationType,
  setVisualisationType,
  postProcessing,
  setPostProcessingType,
  embossEffect,
  setEmbossEffect,
  numberOfIterations,
  setNumberOfIterations,
  isolineWidth,
  setIsolineWidth,
  enableInterpolation,
  setEnableInterpolation,
  windParticleDensity,
  setWindParticleDensity,
  framesDensity,
  setFramesDensity,
  enterpriseAccountId,
  parameterMappings,
}: MapLayersProps) => {
  const {
    useGetGribParamType,
    getSource,
    getTypes,
    getGribValue,
    paramData,
    sourceData,
    gribValue,
    paramLoading,
    sourceLoading,
    framesLoading,
  } = useGetGrib(oldLayer);
  useGetGribParamType(bounds ?? [0, 0, 0, 0]);
  const { mutate: getGribModel, data: modelData, isLoading: modelLoading } = useGetGribModelType();
  const [editPalette, setEditPalette] = useState<string | undefined>(undefined);
  const selectedRangeValue = useMemo(() => {
    if (!gribLayerEdit || !gribValue?.length || !gribLayerEdit?.dataFrames) return [0, 0];

    if (
      isGribLayerType(gribLayerEdit) &&
      param &&
      gribLayerEdit.gribSource.parameterType.name !== param.name
    )
      return undefined;
    const [firstFrame] = gribLayerEdit.dataFrames;
    if (!firstFrame) return [0, 0];
    const indexOfFirstFrame = gribValue!
      .sort((a, b) => a.timestamp - b.timestamp)
      .findIndex((fr) => fr.frameId === firstFrame.frameId);
    return [indexOfFirstFrame, indexOfFirstFrame + gribLayerEdit.dataFrames.length];
  }, [gribLayerEdit, gribValue, param]);

  useEffect(() => {
    if (bounds.length) {
      if (oldLayer) {
        getSource({ parameterTypeId: param!.id, bounds });
        getGribModel({ parameterTypeId: param!.id, bounds, sourceId: source!.id });
        getGribValue({
          bounds,
          paramTypeId: param!.id,
          sourceId: source!.id,
          modelId: gribModel!.id,
        });
      } else {
        getSource({ bounds });
      }
      if (gribLayerEdit && dataProductId) {
        getGribValue({
          paramTypeId: param!.id,
          bounds,
          dataProductId: dataProductId,
        });

        getTypes({
          dataProductId: dataProductId,
          bounds: bounds,
        });
      }
    }
  }, [bounds.length]);

  useEffect(() => {
    if (!gribLayerEdit && parameterMappings) {
      setEnableInterpolation(parameterMappings.isInterpolationEnabled);
      const defaultVisualisationType = parameterMappings.visualizationTypes?.find(
        (visualisationType: VisualizationTypeInterface) => visualisationType.isDefault,
      );
      if (defaultVisualisationType) {
        setVisualisationType(defaultVisualisationType.visualizationType);
      }
    }
  }, [parameterMappings]);

  const onParamChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = Number(e.target.value);
    if (value >= 0) {
      dataProductId && !oldLayer
        ? value &&
          getGribValue({
            paramTypeId: value,
            dataProductId: dataProductId,
            bounds,
          })
        : getSource({
            parameterTypeId: value,
            bounds,
          });
      if (oldLayer) {
        setSource(undefined);
        setGribModel(undefined);
      }
    }
    queryClient.invalidateQueries(valuesKey);
    const selectedParam = paramData?.find((param) => param.id === value);
    setParam(selectedParam);
    if (selectedParam) {
      setName(selectedParam.name);
    }
    if (selectedParam?.visualisation?.supportedTypes.length) {
      const selectedType =
        selectedParam.visualisation.defaultType &&
        selectedParam.visualisation.supportedTypes.includes(selectedParam.visualisation.defaultType)
          ? selectedParam.visualisation.defaultType
          : selectedParam.visualisation.supportedTypes?.length > 0
          ? selectedParam.visualisation.supportedTypes[0]
          : VisualisationTypeEnum.HEATMAP;
      setVisualisationType(selectedType);
    } else {
      setVisualisationType(undefined);
    }
  };

  const onSourceChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = Number(e.target.value);
    const selectedSource = sourceData?.find((source) => source.id === value);
    if (selectedSource?.dataProductId) {
      getTypes({
        dataProductId: selectedSource.dataProductId,
        bounds: bounds,
      });
      setDataProductId(selectedSource.dataProductId);
      setParam(undefined);
      if (selectedSource.modelId && selectedSource.modelName) {
        setGribModel({ id: selectedSource.modelId, name: selectedSource.modelName });
      }
    } else {
      if (value >= 0) {
        param &&
          value &&
          getGribModel({
            parameterTypeId: param.id,
            sourceId: value,
            bounds,
          });
        param &&
          value &&
          gribModel &&
          getGribValue({
            paramTypeId: param.id,
            sourceId: value,
            modelId: gribModel.id,
            bounds,
          });
      }
      setGribModel(undefined);
    }
    setSource(selectedSource);
  };
  const onModelChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = Number(e.target.value);
    if (value >= 0) {
      param &&
        source &&
        value &&
        getGribValue({
          paramTypeId: param.id,
          sourceId: source.id,
          modelId: value,
          bounds,
        });
    }
    const selectedModel = modelData?.find((model) => model.id === value);
    setGribModel(selectedModel);
  };
  const onVisualisationChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setVisualisationType(e.target.value as VisualisationTypeEnum);
    setPostProcessingType(null);
  };
  const onInterpolationChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setInterpolation(e.target.value as InterpolationEnum);
  };
  const onFilteringTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setFilteringType(e.target.value !== 'null' ? (e.target.value as FilteringTypeEnum) : null);
  };
  const onPostProcessingTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setPostProcessingType(
      e.target.value !== 'null' ? (e.target.value as PostProcessingTypeEnum) : null,
    );
  };
  const onEnableInterpolationChange = (e: CheckboxChangeEvent) => {
    setEnableInterpolation(e.target.checked);
  };
  const getPaletteParam = (paramName: string | undefined) => {
    if (paramName?.toUpperCase().includes(ColorPaletteParamTypeEnum.TEMPERATURE))
      return ColorPaletteParamTypeEnum.TEMPERATURE;
    if (paramName?.toUpperCase().includes(ColorPaletteParamTypeEnum.PRECIPITATION))
      return ColorPaletteParamTypeEnum.PRECIPITATION;
    if (paramName?.toUpperCase().includes(ColorPaletteParamTypeEnum.CLOUD))
      return ColorPaletteParamTypeEnum.CLOUD;
    if (paramName?.toUpperCase().includes(ColorPaletteParamTypeEnum.PRESSURE))
      return ColorPaletteParamTypeEnum.PRESSURE;
    if (paramName?.toUpperCase().includes(ColorPaletteParamTypeEnum.WIND))
      return ColorPaletteParamTypeEnum.WIND;
    if (paramName?.toUpperCase().includes(ColorPaletteParamTypeEnum.HEIGHT))
      return ColorPaletteParamTypeEnum.HEIGHT;
    return ColorPaletteParamTypeEnum.TEMPERATURE;
  };
  const gribParamSelect = () => (
    <Select
      className={'w-full'}
      style={{ width: '100%' }}
      onChange={onParamChange}
      value={param?.id}
      required
    >
      <option value={0}>
        {paramData?.length === 0 ? 'No parameters available' : 'Select parameter'}
      </option>
      {paramData
        ?.sort((a, b) => (a.id > b.id ? 1 : -1))
        .filter((p) => p.name !== 'WeatherType')
        .map((k: PickerDef) => (
          <option key={k.id} value={k.id}>
            {k.name}
          </option>
        ))}
    </Select>
  );
  const framesWithDensity = useMemo(() => {
    //if (!framesDensity) return gribValue;
    return filterWithSelection(gribValue || [], framesDensity, selectedDataFrames[0]);
  }, [framesDensity, gribValue]);
  const gribSourceSelect = () =>
    sourceData ? (
      <Select
        className={'w-full'}
        style={{ width: '100%' }}
        onChange={onSourceChange}
        value={source?.id}
        required
      >
        <option value={undefined}>
          {sourceData?.length === 0 ? 'No sources available' : 'Select source'}
        </option>
        {sourceData
          ?.sort((a, b) => a.name.localeCompare(b.name))
          ?.map((k: PickerDef) => (
            <option key={k.id} value={k.id}>
              {k.name}
            </option>
          ))}
      </Select>
    ) : null;
  const gribModelSelect = () =>
    oldLayer ? (
      param?.id && source?.id ? (
        <Select className={'w-full'} onChange={onModelChange} value={gribModel?.id} required>
          <option value={undefined}>
            {modelData?.length === 0 ? 'No models available' : 'Select model'}
          </option>
          {modelData?.map((k: PickerDef) => (
            <option key={k.id} value={k.id}>
              {k.name}
            </option>
          ))}
        </Select>
      ) : null
    ) : (
      <Input value={gribModel?.name} disabled />
    );

  const visualisationSelect = () => (
    <Select
      className={'w-full'}
      style={{ width: '100%' }}
      onChange={onVisualisationChange}
      value={visualisationType}
      required
    >
      {parameterMappings
        ? parameterMappings.visualizationTypes?.map((k: VisualizationTypeInterface) => (
            <option key={k.visualizationType} value={k.visualizationType}>
              {VisualisationTypeName[k.visualizationType]}
            </option>
          ))
        : param?.visualisation?.supportedTypes.map((k: VisualisationTypeEnum) => (
            <option key={k} value={k}>
              {VisualisationTypeName[k]}
            </option>
          ))}
    </Select>
  );
  const postProcessingSelect = (
    <Select
      className={'w-full'}
      style={{ width: '100%' }}
      onChange={onPostProcessingTypeChange}
      value={postProcessing ?? 'null'}
      required
    >
      <option value={''}>No post processing</option>
      {Object.entries(PostProcessingTypeEnum).map(([k, v]) => {
        if (isTileVisualisationType(visualisationType) || v !== PostProcessingTypeEnum.EMBOSS)
          return (
            <option key={k} value={v}>
              {k}
            </option>
          );
      })}
    </Select>
  );
  const interpolationSelect = (
    <Select
      className={'w-full'}
      style={{ width: '100%' }}
      onChange={onInterpolationChange}
      value={interpolation}
      required
    >
      {Object.entries(InterpolationEnum).map(([k, v]) => (
        <option key={k} value={v}>
          {k}
        </option>
      ))}
    </Select>
  );

  const filteringTypeSelect = (
    <Select
      className={'w-full'}
      style={{ width: '100%' }}
      onChange={onFilteringTypeChange}
      value={filteringType ?? 'null'}
      required
    >
      <option key={'no-val'} value={'null'}>
        No filtering
      </option>
      {Object.entries(FilteringTypeEnum).map(([k, v]) => (
        <option key={k} value={v}>
          {k}
        </option>
      ))}
    </Select>
  );
  function onHourlyChange(e: CheckboxChangeEvent) {
    if (e.target.checked) {
      setFramesDensity(1);
    } else {
      setFramesDensity(0);
    }
  }
  const useHourlyValues = (
    <div className="relative flex flex-col">
      <Checkbox checked={!!framesDensity} onChange={onHourlyChange}>
        <span className={'!text-white'}>Time step granularity</span>
      </Checkbox>
      {!framesDensity && (
        <small className=" absolute top-5 left-[0px] text-red-500">
          *A high number of data time steps may lower rendering performance
        </small>
      )}
    </div>
  );
  const enableInterpolationJSX = (
    <Checkbox checked={Boolean(enableInterpolation)} onChange={onEnableInterpolationChange}>
      <span className="!text-white">Enable interpolation</span>
    </Checkbox>
  );
  return (
    <>
      <h3>Model Layer</h3>
      <div className="layers-wrap model-layer">
        <div className="layer-item-flex-wrapper">
          <div className="layer-item">
            {oldLayer ? (
              <>
                <label>
                  <span>*</span> Parameter type
                </label>
                {paramLoading ? (
                  <div className={'loading'}>Loading parameters</div>
                ) : (
                  gribParamSelect()
                )}
              </>
            ) : (
              <>
                <label>
                  <span>*</span> Source
                </label>
                {sourceLoading ? (
                  <div className={'loading'}>Loading sources</div>
                ) : (
                  gribSourceSelect()
                )}
              </>
            )}
          </div>
          <div className="layer-item">
            {param && (
              <>
                <label>
                  <span>*</span> Layer name
                </label>
                <Input
                  placeholder="Type model layer name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </>
            )}
          </div>
        </div>
        <div className="layer-item-flex-wrapper">
          <div className="layer-item">
            {oldLayer
              ? param && (
                  <>
                    <label>
                      <span>*</span> Source
                    </label>
                    {sourceLoading ? (
                      <div className={'loading'}>Loading sources</div>
                    ) : (
                      gribSourceSelect()
                    )}
                  </>
                )
              : source && (
                  <>
                    <label>
                      <span>*</span> Parameter type
                    </label>
                    {paramLoading ? (
                      <div className={'loading'}>Loading parameters</div>
                    ) : (
                      gribParamSelect()
                    )}
                  </>
                )}
          </div>
          <div className="layer-item">
            {param && (
              <>
                <label>
                  <span>*</span> Layer level
                </label>
                <InputNumber
                  placeholder="0"
                  value={z}
                  onInputChange={(e) => setZ(e)}
                  className={styles.inputWrap}
                  required
                  min={0}
                  max={MAX_ZINDEX_VALUE}
                  step={1}
                />
              </>
            )}
          </div>
        </div>
        <div className="layer-item-flex-wrapper">
          <div className="layer-item">
            {source && (
              <>
                <label>
                  <span>*</span> Model
                </label>
                {modelLoading ? <div className={'loading'}>Loading models</div> : gribModelSelect()}
              </>
            )}
          </div>
          <div className="layer-item">
            {param && Boolean(param.visualisation?.supportedTypes?.length) && (
              <>
                <label>
                  <span>*</span> Visualisation Type
                </label>
                {visualisationSelect()}
              </>
            )}
          </div>
        </div>
        {param && gribValue && (
          <div className={'grid grid-cols-3 gap-2'}>
            <div className="layer-item mt-[20px]">{useHourlyValues}</div>
            <div className="layer-item">
              <label className={'top-5'}>
                Frames granularity
                <Select
                  value={framesDensity ?? 0}
                  onChange={(e) => setFramesDensity(Number(e.target.value))}
                >
                  <option value={0}>original</option>
                  <option value={1}>1h</option>
                  <option value={2}>2h</option>
                  <option value={6}>6h</option>
                  <option value={12}>12h</option>
                  <option value={24}>24h</option>
                  <option value={168}>7 days</option>
                </Select>
              </label>
            </div>
            <div className="layer-item flex justify-end items-baseline mt-[20px]">
              {((param && shouldHaveInterpolateOption(param.name, visualisationType)) ||
                parameterMappings?.isInterpolationEnabled) &&
                enableInterpolationJSX}
            </div>
          </div>
        )}

        {param && source && gribModel && (
          <>
            {framesLoading ? (
              <div className={'loading my-[68px] text-center'}>Loading available frames</div>
            ) : (
              gribValue.length > 0 && (
                <FramesRange
                  maxDays={14}
                  layerType={'grib'}
                  frames={framesWithDensity}
                  framesDensity={framesDensity}
                  onRangeChange={setSelectedDataFrames}
                  selectedFrames={selectedDataFrames}
                  layerEdit={gribLayerEdit}
                  defaultValue={gribLayerEdit ? selectedRangeValue : undefined}
                  show={!!rangeFromFirstLayer}
                  rangeFromFirstLayer={rangeFromFirstLayer}
                />
              )
            )}
            {palette && tempPalettes && visualisationType != VisualisationTypeEnum.ISOLINE ? (
              <div className="layer-item-flex-wrapper modal-palette">
                {/*  <ColorStripPalette colorObject={undefined} steps={[0]} /> */}
                <PaletteRefactor
                  source={source}
                  parameter={getPaletteParam(param?.name)}
                  value={palette}
                  setPalette={setPalette}
                  layerEdit={gribLayerEdit}
                  editPalette={setEditPalette}
                  visualisationType={visualisationType}
                  dataProductId={dataProductId}
                  enterpriseAccountId={enterpriseAccountId}
                  width={1000}
                />
              </div>
            ) : (
              <PaletteIsoline
                source={source}
                parameter={getPaletteParam(param?.name)}
                palette={palette}
                setPalette={setPalette}
                editPalette={setEditPalette}
                layerEdit={gribLayerEdit}
                visualisationType={visualisationType}
                dataProductId={dataProductId}
                enterpriseAccountId={enterpriseAccountId}
              />
            )}
            <br />
            {visualisationType && (
              <CustomPalette
                onOk={(pal) => {
                  const val = {
                    ...pal,
                    colorStops: {
                      ...pal.colorStops,
                      interval:
                        palette?.colorStops?.interval != null
                          ? palette?.colorStops?.interval
                          : null,
                    },
                  };
                  setPalette(val);
                }}
                paletteParamType={getPaletteParam(param?.name)}
                visualisation={visualisationType}
                paletteToEdit={editPalette}
                setPaletteToEdit={(e) => {
                  setEditPalette(e);
                }}
                source={source}
              />
            )}
            <div className="layer-item-flex-wrapper">
              <div className="layer-item">
                <label>Color scale interpolation</label>
                {interpolationSelect}
              </div>
              {visualisationType === VisualisationTypeEnum.ISOLINE ? (
                <div className="layer-item">
                  <label>Isoline width</label>
                  <InputNumber
                    value={isolineWidth}
                    max={100}
                    min={0.1}
                    onInputChange={(e) => {
                      const val = e >= 0.1 && e <= 100 ? e : isolineWidth;
                      setIsolineWidth(val);
                    }}
                  />
                </div>
              ) : (
                <div className="layer-item"></div>
              )}
            </div>
            <div className="layer-item-flex-wrapper">
              <div className="layer-item">
                <label>Filtering type</label>
                {filteringTypeSelect}
              </div>
              {filteringType === FilteringTypeEnum.LOW_BANDPASS ? (
                <div className="layer-item">
                  <label>Number of Iterations</label>
                  <InputNumber
                    value={numberOfIterations}
                    max={100}
                    min={0}
                    onInputChange={(e) => {
                      const val = e >= 0 && e <= 100 ? e : numberOfIterations;
                      setNumberOfIterations(val);
                    }}
                  />
                </div>
              ) : (
                <div className="layer-item"></div>
              )}
            </div>
            <div className="layer-item-flex-wrapper">
              <div className="layer-item">
                <label>Post-processing</label>
                {postProcessingSelect}
              </div>
              {postProcessing === PostProcessingTypeEnum.EMBOSS ? (
                <div className="layer-item">
                  <label>Emboss effect</label>
                  <InputNumber
                    value={embossEffect}
                    max={100}
                    min={0}
                    onInputChange={(e) => {
                      const val = e >= 0 && e <= 100 ? e : embossEffect;
                      setEmbossEffect(val);
                    }}
                  />
                </div>
              ) : (
                <div className="layer-item"></div>
              )}
            </div>
            {visualisationType === VisualisationTypeEnum.PARTICLE ? (
              <>
                <PropertySection label="Particle options" isOpened={true}>
                  <Row style={{ padding: '7px' }}>
                    <Col xs={24} sm={8} style={{ padding: '7px' }}>
                      <label>Particle density</label>
                      <Select
                        className={'w-full'}
                        style={{ width: '100%' }}
                        onChange={(e) => {
                          setWindParticleDensity(Number(e.target.value));
                        }}
                        value={windParticleDensity}
                        required
                      >
                        <option value={0}>Low</option>
                        <option value={1}>Medium</option>
                        <option value={2}>High</option>
                      </Select>
                    </Col>
                  </Row>
                </PropertySection>
              </>
            ) : null}
          </>
        )}
      </div>
    </>
  );
};
export default MapGribLayers;
