import { Alert, Button, Checkbox, FileInput, Modal, Table } from 'flowbite-react';
import { useState } from 'react';
import { HiInformationCircle } from 'react-icons/hi';

import loadFont from '../../../core/api/useLoadFont';
import { FontVariantsEnum } from '../../../model/enums/FontVariantsEnum';
import { FontVariantInterface } from '../../playground/properties/panels/FontProperties';
import { useConfirmation } from '../modals/useConfirmation';
import { useAddFontVariant } from '../queries-NEW/useAddFontVariant';
import useDeleteFontVariant from '../queries-NEW/useDeleteFontVariant';
import { useEditFontVariant } from '../queries-NEW/useEditFontVariant';

interface FontVariantsModalProps {
  fontId: string;
  name: string;
  fontVariants: FontVariantInterface[];
  open: boolean;
  hasDuplicates: boolean;
  fontWarningId?: string;
  onClose: () => void;
}

function FontVariantsModal({
  fontId,
  name,
  fontVariants,
  open,
  hasDuplicates,
  fontWarningId,
  onClose,
}: FontVariantsModalProps) {
  const { confirm } = useConfirmation();
  const { mutate: deleteMutate } = useDeleteFontVariant();
  const { mutate: editMutate } = useEditFontVariant();
  const { mutate: addMutate } = useAddFontVariant();
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [file, setFile] = useState<File>();
  const [type, setType] = useState<string>('');

  const handleEdit = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>,
    fontVariant: FontVariantInterface,
  ) => {
    editMutate({
      fontVariant: {
        ...fontVariant,
        [e.target.name]: e.target instanceof HTMLSelectElement ? e.target.value : e.target.checked,
      },
      fontId,
    });
    if (e.target instanceof HTMLSelectElement) {
      const fontName = `${name} ${fontVariant.type}`;
      const existingFonts = Array.from(document.fonts);
      const deletedFontFace = existingFonts.find((font) => font.family === fontName);
      if (deletedFontFace) {
        document.fonts.delete(deletedFontFace);
      }
      loadFont([{ ...fontVariant, type: e.target.value }], name);
    }
  };

  const handleDelete = (id: string, type: string) => {
    confirm({
      message: `You are about to delete font variant ${type}`,
      onConfirm: () => deleteMutate(id),
    });
  };

  function getMatchingFontVariant(name: string): FontVariantsEnum | undefined {
    const fontVariantsArray = Object.values(FontVariantsEnum);
    const nameWithoutExtension = name.split('.')[0].toLowerCase();
    return fontVariantsArray.find((variant) =>
      nameWithoutExtension.includes(variant.toLowerCase()),
    );
  }

  const handleAdd = (
    e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>,
  ) => {
    if (e.target instanceof HTMLInputElement) {
      if (e.target.name === 'file' && e.target.files) {
        setFile(e.target.files[0]);
        const matchingVariant = getMatchingFontVariant(e.target.files[0].name);
        if (matchingVariant) {
          setType(matchingVariant as FontVariantsEnum);
        }
      }
    } else {
      setType(e.target.value);
    }
  };

  const add = () => {
    if (isAdding) {
      const formData = new FormData();
      type && formData.append('fontVariantString', JSON.stringify({ type, defaultType: false }));
      formData.append('fontId', fontId);
      file && formData.append('fontFile', file);
      addMutate(formData, {
        onSuccess: (response) => {
          loadFont([{ ...response.data }], name);
          setFile(undefined);
          setType('');
          setIsAdding(false);
        },
      });
    } else {
      setIsAdding(true);
    }
  };

  return (
    <Modal
      show={open}
      onClose={() => {
        onClose();
      }}
      size="4xl"
    >
      <Modal.Header>{name} Variants</Modal.Header>
      <Modal.Body>
        <div className="flex mb-4 justify-between items-center">
          {isAdding && (
            <div className="flex gap-3">
              <div>
                <label>Font file</label>
                <FileInput accept=".ttf" name="file" onChange={(e) => handleAdd(e)} />
              </div>
              <div>
                <label>Font type</label>
                <select className="block" name="type" onChange={(e) => handleAdd(e)} value={type}>
                  {Object.values(FontVariantsEnum)
                    .filter(
                      (fv: string) =>
                        !fontVariants.some((f: FontVariantInterface) => f.type === fv),
                    )
                    .map((filteredFv: string) => (
                      <option key={filteredFv} value={filteredFv}>
                        {filteredFv}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          )}
          <Button disabled={!type && !file && isAdding} onClick={() => add()}>
            {isAdding ? 'Save' : 'Add Variant'}
          </Button>
        </div>
        {fontWarningId === fontId && hasDuplicates ? (
          <Alert color="failure" icon={HiInformationCircle}>
            <span>
              <p>Metadata cannot be read from files. Please manually add font types.</p>
            </span>
          </Alert>
        ) : (
          hasDuplicates && (
            <Alert color="warning" icon={HiInformationCircle}>
              <span>
                <p>This font has multiple same types!</p>
              </span>
            </Alert>
          )
        )}
        <Table>
          <Table.Head>
            <Table.HeadCell>Style</Table.HeadCell>
            <Table.HeadCell>Type</Table.HeadCell>
            <Table.HeadCell>Default</Table.HeadCell>
            <Table.HeadCell></Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            {fontVariants?.map((fontVariant: FontVariantInterface) => (
              <Table.Row
                key={fontVariant.id}
                className="bg-white dark:border-gray-700 dark:bg-gray-800"
              >
                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                  <div
                    className="text-2xl mb-2"
                    style={{ fontFamily: name + ' ' + fontVariant.type }}
                  >
                    The quick brown fox
                  </div>
                </Table.Cell>
                <Table.Cell>
                  <select
                    id="fontSelect"
                    name="type"
                    value={fontVariant.type}
                    onChange={(e) => handleEdit(e, fontVariant)}
                  >
                    {Object.values(FontVariantsEnum).map((fv: string) => (
                      <option key={fv} value={fv}>
                        {fv}
                      </option>
                    ))}
                  </select>
                </Table.Cell>
                <Table.Cell>
                  <Checkbox
                    name="defaultType"
                    checked={fontVariant.defaultType}
                    disabled={fontVariant.defaultType}
                    style={fontVariant.defaultType ? { cursor: 'not-allowed' } : {}}
                    onChange={(e) => handleEdit(e, fontVariant)}
                  />
                </Table.Cell>
                <Table.Cell>
                  <Button
                    color="failure"
                    disabled={fontVariant.defaultType}
                    onClick={() => handleDelete(fontVariant.id, fontVariant.type)}
                  >
                    Delete
                  </Button>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button color={'gray'} onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default FontVariantsModal;
