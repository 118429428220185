import React from 'react';

import Dashboard from '../../assets/images/dashboard.svg';
import Repository from '../../assets/images/Repository.svg';
import Settings from '../../assets/images/settings.svg';
import Studio from '../../assets/images/Studio.svg';
import Subscription from '../../assets/images/Subscription.svg';
import User from '../../assets/images/User.svg';
import Wallet from '../../assets/images/Wallet.svg';
import MenuItem from '../menu-item/MenuItem';
import styles from './Menu.module.scss';

const Menu: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div className={`h-full flex justify-between`}>
      <div className={`h-full flex flex-col   ${styles['menu-wrraper']}`}>
        <p className={`self-center ${styles.logo}`}>nimbus</p>
        <p className={`self-center ${styles['sub-logo']} mb-4`}>media</p>
        <div className="flex flex-col justify-between h-full">
          <div className="h-full">
            <MenuItem to={'/dashboard'} label={'Dashboard'} iconSrc={Dashboard} />
            <MenuItem to={'/multimedia-repository'} label={'My multimedia'} iconSrc={Repository} />
            <MenuItem to={'/templates-repository'} label={'My templates'} iconSrc={Repository} />

            <MenuItem to={'/subscription'} label={'Subscription'} iconSrc={Subscription} />
            <MenuItem to={'/wallet'} label={'Wallet'} iconSrc={Wallet} />
            <MenuItem to={'/studio-properties'} label={'Studio properties'} iconSrc={Studio} />
            <MenuItem to={'/user-administration'} label={'User administration'} iconSrc={User} />
            <MenuItem to={'/reports'} label={'Reports'} iconSrc={Repository} />
          </div>
        </div>
        <div className={styles.settings}>
          <MenuItem to={'/settings'} label={'Settings'} iconSrc={Settings} />
        </div>
      </div>
      <div
        className="w-full"
        style={{
          backgroundColor: '#f6f6f6',
          height: '100vh',
          overflow: 'auto',
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default Menu;
