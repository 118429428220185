import { AxiosError } from 'axios';
import { QueryKey, useQuery, UseQueryResult } from 'react-query';

import { axiosInstance } from '../../../core/api/axiosInstance';
import { C9ProjectPreviewDTO } from '../../../model/DTO/C9ProjectPreviewDTO';
import { QueryInfoMetadata } from '../../../model/other/QueryInfoMetadata';

export const key: QueryKey = ['GET_TEMPLATES'];

const useGetTemplates = ({
  page,
  type,
  size = 19,
  searchKey,
  sort,
  activeTab,
}: {
  page: number;
  type:
    | 'project'
    | 'scene'
    | 'weather-poster'
    | 'weather-geo-poster'
    | 'font'
    | 'map'
    | 'icon-set'
    | 'geoposter'
    | 'poster'
    | 'layer';
  size?: number;
  searchKey?: string;
  sort?: string;
  activeTab: string;
}): UseQueryResult<QueryInfoMetadata<C9ProjectPreviewDTO>, AxiosError> => {
  return useQuery(
    [...key, type, page, searchKey, sort],
    async () => {
      if (type === 'font') {
        return null; // Return null when type is 'font'
      }
      const { data } = await axiosInstance.get(
        `dashboard/${
          type === 'poster' ? 'weather-poster' : type === 'geoposter' ? 'weather-geo-poster' : type
        }-templates`,
        {
          params: {
            page,
            size,
            searchKey,
            sort,
            isShared: activeTab === 'ORGANIZATION',
          },
        },
      );
      return data;
    },
    {
      //retry: 3, // Will retry failed requests 1 times before displaying an error
      enabled: !!type,
    },
  );
};

export default useGetTemplates;
