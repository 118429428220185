import { useQuery } from 'react-query';

import { axiosInstance } from '../axiosInstance';

export interface SourceDef {
  dataProductId: string;
  sourceName: string;
}
export const useGetObservedDivep = () =>
  useQuery(['OBSERVED_DIVEP'], async (): Promise<{ dataProducts: Array<SourceDef> }> => {
    const { data } = await axiosInstance.get(
      `https://point-data.dev.c9weather.com/api/observed/divep/sources`,
    );
    return data;
  });
