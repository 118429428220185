import { useQuery } from 'react-query';

import { ColorPaletteDef } from '../../../model/definitions/ColorPaletteDef';
import { ColorPaletteParamTypeEnum } from '../../../model/enums/ColorPaletteParamTypeEnum';
import { VisualisationTypeEnum } from '../../../model/enums/VisualisationTypeEnum';
import { axiosInstance } from '../axiosInstance';

export const key = ['VISUALISATION_PALETTE'];
export const useGetVisualisationPalette = (
  param: ColorPaletteParamTypeEnum,
  visalisationType: VisualisationTypeEnum | undefined,
) =>
  useQuery([...key, param, visalisationType], async (): Promise<Array<ColorPaletteDef>> => {
    const { data } = await axiosInstance.get(
      `color-palette/get-default/${visalisationType || 'isoline'}/${param}`,
    );
    return data;
  });
