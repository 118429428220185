import LineString from 'ol/geom/LineString';

import { resolutionToZoom, zoomToResolution } from '../molecules/mapElement/helpers';

export function distanceBetweenPoints(latlng1: [number, number], latlng2: [number, number]) {
  const line = new LineString([latlng1, latlng2]);
  return line.getLength();
}

export function getMinusZoom(distance: number, zoom: number, coef = 1000) {
  const add = distance / coef + zoomToResolution(zoom);
  return resolutionToZoom(add);
}
