import { FC, useState } from 'react';
import { AiOutlineCaretDown, AiOutlineCaretUp, AiOutlineEyeInvisible } from 'react-icons/ai';
import { MdOutlineVisibility } from 'react-icons/md';

import styles from '../../Properties.module.scss';
import GridItem from '../../shared/GridItem';
import GridWrapper from '../../shared/GridWrapper';

interface ExportDef {
  name?: string;
  type?: string;
  sftpUrl?: string;
  sftpUsername?: string;
  sftpPassword?: string;
  folderPath?: string;
}

interface ExportDefsProps {
  exportDefs: Array<ExportDef>;
  onUpdateExportDefs: (value: string, prop: string) => void;
}
export const ExportDefs: FC<ExportDefsProps> = ({ exportDefs, onUpdateExportDefs }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const exportDefsRenders = () =>
    exportDefs.map((item, index) => (
      <GridWrapper key={index}>
        <GridItem
          label={'SFTP Name:'}
          item={
            <input
              value={item.name}
              onChange={(e) => onUpdateExportDefs(e.target.value, 'name')}
              type="text"
              className={styles.inputWrap}
            />
          }
        />

        <GridItem
          label={'SFTP URL:'}
          item={
            <input
              value={item.sftpUrl}
              onChange={(e) => onUpdateExportDefs(e.target.value, 'sftpUrl')}
              type="text"
              className={styles.inputWrap}
            />
          }
        />
        <GridItem
          label={'SFTP Username:'}
          item={
            <input
              value={item.sftpUsername}
              onChange={(e) => onUpdateExportDefs(e.target.value, 'sftpUsername')}
              type="text"
              className={styles.inputWrap}
            />
          }
        />
        <GridItem
          label={'SFTP Password:'}
          item={
            <div className="flex items-center">
              <input
                value={item.sftpPassword}
                onChange={(e) => onUpdateExportDefs(e.target.value, 'sftpPassword')}
                type={showPassword ? 'text' : 'password'}
                className={styles.inputWrap}
              />
              <div
                className="absolute right-6 cursor-pointer text-white text-lg"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <AiOutlineEyeInvisible /> : <MdOutlineVisibility />}
              </div>
            </div>
          }
        />
        <GridItem
          label={'Folder path:'}
          item={
            <input
              value={item.folderPath}
              onChange={(e) => onUpdateExportDefs(e.target.value, 'folderPath')}
              type="text"
              className={styles.inputWrap}
            />
          }
        />
      </GridWrapper>
    ));
  return (
    <>
      <div
        className={`mb-2 subheader layer-header ${open ? 'layer-header-active' : ''}`}
        onClick={() => setOpen(!open)}
      >
        {open ? <AiOutlineCaretUp /> : <AiOutlineCaretDown />}
        SFTP Export
      </div>
      {open && <div>{exportDefsRenders()}</div>}
    </>
  );
};
