import Slider from 'rc-slider';
import { useState } from 'react';
import { AiOutlineCaretDown, AiOutlineCaretUp } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';

import { MapPanelDef } from '../../../model/definitions/MapPanelDef';
import { ActiveDef } from '../../../store/slices/active-slice';
import { updateMapLayer } from '../../../store/slices/project-slice';
import { RootState } from '../../../store/store';
import GridItem from './shared/GridItem';
import GridWrapper from './shared/GridWrapper';

function ColorFiltersProperties({ mapLayer }: { mapLayer: MapPanelDef }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { activeScene, activeElement } = useSelector<RootState, ActiveDef>((state) => state.active);
  async function onMapLayerChange(
    propertyPath: Leaves<MapPanelDef> | keyof MapPanelDef,
    newValue: number,
  ) {
    dispatch(
      updateMapLayer({
        activeScene,
        newValue,
        elementId: activeElement,
        propertyPath,
      }),
    );
  }
  return (
    <>
      <div
        className={`mb-2 subheader layer-header ${open ? 'layer-header-active' : ''}`}
        onClick={() => setOpen(!open)}
      >
        {open ? <AiOutlineCaretUp /> : <AiOutlineCaretDown />}
        Color filters
      </div>
      {open && (
        <div className="prop-wrapper">
          <GridWrapper>
            <GridItem
              noBorderBg
              label="Brightness"
              item={
                <Slider
                  style={{ width: '100%' }}
                  min={0}
                  max={200}
                  step={1}
                  value={mapLayer.colorFilters?.brightness || 100}
                  onChange={(e) => {
                    if (typeof e === 'number') {
                      onMapLayerChange('colorFilters.brightness', e);
                    }
                  }}
                />
              }
            />
            <GridItem
              noBorderBg
              label="Contrast"
              item={
                <Slider
                  style={{ width: '100%' }}
                  min={0}
                  max={200}
                  step={1}
                  value={mapLayer.colorFilters?.contrast || 100}
                  onChange={(e) => {
                    if (typeof e === 'number') {
                      onMapLayerChange('colorFilters.contrast', e);
                    }
                  }}
                />
              }
            />
            <GridItem
              noBorderBg
              label="Saturation"
              item={
                <Slider
                  style={{ width: '100%' }}
                  min={0}
                  max={100}
                  step={1}
                  value={mapLayer.colorFilters?.saturate || 100}
                  onChange={(e) => {
                    if (typeof e === 'number') {
                      onMapLayerChange('colorFilters.saturate', e);
                    }
                  }}
                />
              }
            />
            <GridItem
              noBorderBg
              label="Hue"
              item={
                <Slider
                  style={{ width: '100%' }}
                  min={0}
                  max={360}
                  step={1}
                  value={mapLayer.colorFilters?.hueRotate || 0}
                  onChange={(e) => {
                    if (typeof e === 'number') {
                      onMapLayerChange('colorFilters.hueRotate', e);
                    }
                  }}
                />
              }
            />
            <GridItem
              noBorderBg
              label="Grayscale"
              item={
                <Slider
                  style={{ width: '100%' }}
                  min={0}
                  max={100}
                  step={1}
                  value={mapLayer.colorFilters?.grayscale || 0}
                  onChange={(e) => {
                    if (typeof e === 'number') {
                      onMapLayerChange('colorFilters.grayscale', e);
                    }
                  }}
                />
              }
            />
            <GridItem
              noBorderBg
              label="Sepia"
              item={
                <Slider
                  style={{ width: '100%' }}
                  min={0}
                  max={100}
                  step={1}
                  value={mapLayer.colorFilters?.sepia || 0}
                  onChange={(e) => {
                    if (typeof e === 'number') {
                      onMapLayerChange('colorFilters.sepia', e);
                    }
                  }}
                />
              }
            />
            <GridItem
              noBorderBg
              label="Invert"
              item={
                <Slider
                  style={{ width: '100%' }}
                  min={0}
                  max={100}
                  step={1}
                  value={mapLayer.colorFilters?.invert || 0}
                  onChange={(e) => {
                    if (typeof e === 'number') {
                      onMapLayerChange('colorFilters.invert', e);
                    }
                  }}
                />
              }
            />
            <GridItem
              noBorderBg
              label="Blur"
              item={
                <Slider
                  style={{ width: '100%' }}
                  min={0}
                  max={20}
                  step={1}
                  value={mapLayer.colorFilters?.blur || 0}
                  onChange={(e) => {
                    if (typeof e === 'number') {
                      onMapLayerChange('colorFilters.blur', e);
                    }
                  }}
                />
              }
            />
          </GridWrapper>
        </div>
      )}
    </>
  );
}

export default ColorFiltersProperties;
