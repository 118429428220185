import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { editPreviewSize } from '../store/slices/active-slice';

export const usePreviewSize = () => {
  const dispatch = useDispatch();
  const initial = () => {
    const hasInitial = localStorage.getItem('preview');
    if (hasInitial) return Number(hasInitial);
    else return 350;
  };
  const [previewSize, setPreviewSize] = useState<number>(initial());
  const setPreview = (newSize?: number) => {
    if (newSize) {
      setPreviewSize(newSize);
      localStorage.setItem('preview', newSize.toString());
      dispatch(editPreviewSize({ size: newSize }));
    }
  };
  const resize = (difference: number) => {
    const newSize = previewSize - difference;
    setPreviewSize(newSize);
    localStorage.setItem('preview', newSize.toString());
    dispatch(editPreviewSize({ size: newSize }));
  };
  useEffect(() => {
    const size = localStorage.getItem('preview');
    setPreview(Number(size));
  }, []);
  return {
    previewSize,
    setPreview,
    resize,
  };
};
