import { Coordinate } from 'ol/coordinate';

const cache: {
  [mapId: string]: {
    [layerId: string]: Coordinate;
  };
} = {};

const mapClickCache: {
  [mapId: string]: Coordinate;
} = {};

export function cacheMapClick({ coord, mapId }: { coord: Coordinate; mapId: string }) {
  mapClickCache[mapId] = coord;
}

export function getMapClickCoordinate({ mapId }: { mapId: string }) {
  return mapClickCache[mapId];
}

export function cacheCoordinate({
  coord,
  mapId,
  layerId,
}: {
  coord: Coordinate;
  mapId: string;
  layerId: string;
}) {
  if (!cache[mapId]) {
    cache[mapId] = {};
  }
  cache[mapId][layerId] = coord;
}

export function getCachedCoordinate({ mapId, layerId }: { mapId: string; layerId: string }) {
  return cache?.[mapId]?.[layerId];
}

export function clearCoordinateFromCache({ mapId, layerId }: { mapId: string; layerId: string }) {
  if (cache[mapId]?.[layerId]) {
    delete cache[mapId][layerId];
  }
}

export function clearMapIdEntryFromCache({ mapId }: { mapId: string }) {
  if (cache[mapId]) {
    delete cache[mapId];
  }
}
