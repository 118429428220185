import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { queryClient } from '../../../..';
import { axiosInstance } from '../../../../core/api/axiosInstance';
import { key } from './useGetShoppingCartItems';

export const useRemoveFromCart = () => {
  return useMutation(
    async (itemId: string) => {
      return await axiosInstance.delete(`/order-item/${itemId}`);
    },
    {
      onSuccess: () => {
        toast.success('Item removed from cart!');
        queryClient.invalidateQueries([...key]);
      },
      onError: () => {
        toast.error('Error while removing item from cart!');
      },
    },
  );
};
