import { QueryKey, useQuery } from 'react-query';

import { axiosInstance } from '../../../core/api/axiosInstance';

export const key: QueryKey = ['GET_TRANSLATION'];

export const useGetTranslation = (id?: string) =>
  useQuery(
    [...key, id],
    async () => {
      const { data } = await axiosInstance.get(`translations/${id}`);
      return data;
    },
    {
      enabled: !!id,
    },
  );
