/* eslint-disable */
import { configureStore } from '@reduxjs/toolkit';
import undoable, { combineFilters, excludeAction } from 'redux-undo';
import * as Sentry from '@sentry/react';

import activeSceneReducer from './slices/active-slice';
import durationSlice from './slices/duration-slice';
import mapStateSlice from './slices/map-state.slice';
import projectReducer from './slices/project-slice';
import mapLoadingSlice from './slices/map-loading.slice';
import userSlice from './slices/user-slice';

const sentryReduxEnhancer = Sentry.createReduxEnhancer();
function doesStringIncludeArray(string: string, stringArray: string[]) {
  return !stringArray.some((item) => string.includes(item));
}
const excludedActions = [
  'project/createIndicator',
  'project/setProjectToPlay',
  'project/updateIndicatorTime',
  'active',
  'user',
  'mapState',
];

export const store = configureStore({
  reducer: {
    // @ts-ignore
    project: undoable(projectReducer, {
      filter: combineFilters(excludeAction(excludedActions), (action) =>
        doesStringIncludeArray(action.type, excludedActions),
      ),
    }),
    active: activeSceneReducer,
    mapState: mapStateSlice,
    durations: durationSlice,
    mapLoading: mapLoadingSlice,
    user: userSlice,
  },
  devTools: {
    maxAge: 15, // Limit to the last 50 actions
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    });
  },
  enhancers: [sentryReduxEnhancer],
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
