const Next5sec = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.99996 0C4.03838 0 0 4.03838 0 8.99996C0 13.9615 4.03838 17.9999 8.99996 17.9999C13.9615 17.9999 17.9999 13.9615 17.9999 8.99996C18.0013 8.90058 17.983 8.80191 17.9459 8.70968C17.9088 8.61746 17.8538 8.53352 17.784 8.46274C17.7143 8.39196 17.6311 8.33576 17.5394 8.2974C17.4477 8.25904 17.3493 8.23928 17.2499 8.23928C17.1505 8.23928 17.0521 8.25904 16.9604 8.2974C16.8687 8.33576 16.7856 8.39196 16.7158 8.46274C16.646 8.53352 16.591 8.61746 16.5539 8.70968C16.5169 8.80191 16.4985 8.90058 16.4999 8.99996C16.4999 13.1508 13.1508 16.4999 8.99996 16.4999C4.84908 16.4999 1.49999 13.1508 1.49999 8.99996C1.49999 4.84908 4.84908 1.49999 8.99996 1.49999C10.8839 1.49999 12.5928 2.19826 13.9086 3.34129L12 5.24998H16.4999V0.749997L14.9706 2.27929C13.3803 0.866526 11.2903 0 8.99996 0ZM7.32272 6.40281L7.0737 9.49801H8.25729C8.42529 9.23101 8.68799 9.06149 9.06149 9.06149C9.55198 9.06149 9.90377 9.41461 9.90377 9.94185C9.90377 10.4586 9.55638 10.7944 9.06588 10.7944C8.62263 10.7944 8.2712 10.5384 8.20895 10.1499H6.98874C7.00749 11.1091 7.82863 11.8315 9.05563 11.83C10.3299 11.83 11.1723 11.0504 11.1723 9.90816C11.1723 8.87917 10.4546 8.13571 9.45113 8.13571C8.90889 8.13571 8.53528 8.34845 8.33053 8.6777H8.26022L8.35983 7.45016H10.8383V6.40281H7.32272Z"
        fill="#678299"
      />
    </svg>
  );
};

export default Next5sec;
