import './style.scss';

import { EditOutlined } from '@ant-design/icons';
import { useKeycloak } from '@react-keycloak/web';
import { ToggleSwitch } from 'flowbite-react';
import { cloneDeep, isEqual } from 'lodash';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Button from '../../../atoms/button/Button';
import { usePrompt } from '../../../hooks/useBlockNavigation';
import { queryClient } from '../../../index';
import { IconSetTemplateDef } from '../../../model/definitions/IconSetTemplateDef';
import { IconSetTemplateItemDef } from '../../../model/definitions/IconSetTemplateItemDef';
import { IconTemplateDef } from '../../../model/definitions/IconTemplateDef';
import { ThumbnailModal } from '../modals/ThumbnailModal';
import { useAddViaUpload } from '../queries-NEW/useAddViaUpload';
import {
  key as getTemplateKey,
  useGetPackByTemplateId,
} from '../queries-NEW/useGetPackByTemplateId';
import { useUpdateIconSet } from '../queries-NEW/useUpdateIconSet';
import ImagePreviewCardContainer from './image-preview-card-container/ImagePreviewCardContainer';
import Loader from './Loader';
import { SequenceModal } from './SequenceModal';

interface IconMakerInterface {
  preview?: boolean;
}

export const IconMaker = ({ preview }: IconMakerInterface) => {
  const { id } = useParams();
  const inputRef = useRef<HTMLInputElement>(null);
  const { data: template } = useGetPackByTemplateId(id ?? '');
  const { keycloak } = useKeycloak();
  const isPreview = preview || template?.createdBy !== keycloak?.tokenParsed?.email;
  const { mutate: createIcons, isLoading } = useAddViaUpload();
  const { mutate: update } = useUpdateIconSet();
  const [modal, setModal] = useState<boolean>(false);
  const [icons, setIcons] = useState<IconSetTemplateItemDef[]>([]);
  const [selectedIcon, setSelectedIcon] = useState<IconSetTemplateItemDef | null>(null);
  const [showAllImages, setShowAllImages] = useState<boolean>(true);
  const [disableSaveBtn, setDisableSaveBtn] = useState<boolean>(true);
  const [showSequenceModal, setShowSequenceModal] = useState<boolean>(false);

  usePrompt('You have unsaved changes. Leave?', !isEqual(template?.iconItems, icons));
  const clearForm = () => {
    if (inputRef.current) inputRef.current.value = '';
  };

  const updateSet = (temp: IconSetTemplateDef, file?: File | null) => {
    const newSet = { ...temp, iconItems: icons };
    const data = new FormData();
    data.append('iconSetTemplateDef', JSON.stringify(newSet));
    file && data.append('thumbnailFiles', file);
    update(data, {
      onSuccess: () => {
        queryClient
          .invalidateQueries([...getTemplateKey, id])
          .then(() => toast.success('Icon pack saved!'));
      },
    });
  };

  const handleIconInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      e.preventDefault();
      const imageFileArr = [...e.target.files];
      const formData = new FormData();
      template && formData.append('iconSetTemplateId', template?.id);
      template && formData.append('iconSetTemplateVersionId', template?.versionId);
      template && formData.append('weatherTypeCodes', '');
      imageFileArr.forEach((item) => {
        template && formData.append('files', item);
      });
      createIcons(formData, {
        onSuccess: (data) => {
          clearForm();
          toast.success('Icons added');
          const imagePreviewCardArr: IconSetTemplateItemDef[] = [...icons];
          data.data.map((item: IconSetTemplateItemDef) => {
            imagePreviewCardArr.push(item);
          });
          setIcons(imagePreviewCardArr);
        },
      });
    }
  };

  const deleteAll = () => {
    setIcons([]);
    setSelectedIcon(null);
    toast.success('All icons deleted!');
  };
  const addIconToTemp = (e: IconTemplateDef) => {
    const newIcon = { ...e, weatherTypeCodes: [] } as unknown as IconSetTemplateItemDef;
    setIcons([...icons, newIcon]);
  };
  useEffect(() => {
    if (!template) return;
    const newIcons: IconSetTemplateItemDef[] = cloneDeep(template.iconItems);
    setIcons(newIcons);
    setSelectedIcon(null);
  }, [template]);

  return (
    <>
      <div className={'flex justify-between items-center'}>
        <div className={'ws-temp-title'}>
          <h1>{template?.name}</h1>
          <div className={'flex pl-12'}>
            {!isPreview && (
              <Button
                buttonType="primary"
                label="+ ADD ICON TO SET"
                onClick={() => inputRef.current?.click()}
              />
            )}
            {!isPreview && (
              <Button
                buttonType="primary"
                label="+ ADD SEQUENCE TO SET"
                onClick={() => setShowSequenceModal(true)}
              />
            )}
            {icons.length > 0 && !isPreview && (
              <>
                {!isPreview && (
                  <Button buttonType="danger" label="DELETE ALL" onClick={() => deleteAll()} />
                )}
                <div className="toggle-wrapper flex items-center">
                  <span className="flowbite-toggleswitch-label">SHOW UNFINISHED</span>
                  <ToggleSwitch
                    label={'SHOW ALL'}
                    checked={showAllImages}
                    onChange={() => setShowAllImages(!showAllImages)}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        {!isPreview && (
          <Button
            buttonType="link"
            label="Basic Edit"
            icon={<EditOutlined style={{ marginRight: '0.5rem' }} />}
            onClick={() => setModal(true)}
          />
        )}
      </div>
      <div>
        {template?.description && (
          <h3
            style={{
              fontSize: '20px',
              marginBottom: '20px',
              // textAlign: 'center',
              marginTop: '1rem',
            }}
          >
            {template?.description}
          </h3>
        )}
      </div>
      {icons.length > 0 && (
        <ImagePreviewCardContainer
          icons={icons}
          selectedIcon={selectedIcon}
          showAllImages={showAllImages}
          preview={isPreview}
          setIcons={(icons) => setIcons(icons)}
          setSelectedIcon={(icon) => setSelectedIcon(icon)}
          setDisableSaveBtn={(disable) => setDisableSaveBtn(disable)}
        />
      )}
      <form className={'hidden'} onSubmit={(e) => e.preventDefault()}>
        <input
          type={'file'}
          accept="image/jpg, image/jpeg, image/png, application/JSON"
          ref={inputRef}
          onChange={handleIconInput}
          multiple
        />
      </form>
      {!isEqual(template?.iconItems, icons) && (
        <div className="pt-12">
          <Button
            buttonType="primary"
            label="SAVE"
            onClick={() => template && updateSet(template)}
            className="pa-0"
            disabled={disableSaveBtn}
            style={{ margin: 0 }}
          />
        </div>
      )}
      {modal && template && (
        <ThumbnailModal
          open={modal}
          onClose={() => setModal(false)}
          onUpload={(template, file) => updateSet(template, file)}
          formValues={template}
        />
      )}
      {isLoading && <Loader />}
      {template && (
        <SequenceModal
          addIcon={addIconToTemp}
          open={showSequenceModal}
          template={template}
          closeModal={() => setShowSequenceModal(false)}
        />
      )}
    </>
  );
};
