import { Tooltip } from 'flowbite-react';
import React, { useEffect, useRef, useState } from 'react';
import { AiOutlineCamera, AiOutlineClose, AiOutlineUpload } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import { v4 } from 'uuid';

import Button from '../../atoms/button/Button';
import { screenshotCanvasElement, ScreenshotData } from '../../helpers/screenshotElement';
import { STUDIO_PANEL_HEIGHT } from '../../model/constants/constants';
import Loader from '../../pages/dashboard/components/Loader';
import { ActiveDef } from '../../store/slices/active-slice';
import { RootState } from '../../store/store';
import styles from './ThumbnailUpload.module.scss';

interface Props {
  thumbnailURLs: (ScreenshotData & { id: string })[];
  setFormData: React.Dispatch<
    React.SetStateAction<{
      name: string;
      description: string;
      thumbnailURLs: (ScreenshotData & { id: string })[];
    }>
  >;
  opened: boolean;
  templateId: string;
  activeThumb: null | string;
  setActiveThumb: React.Dispatch<React.SetStateAction<null | string>>;
  type: 'project' | 'scene' | 'map' | 'weather-poster' | 'weather-geo-poster' | 'layer';
}

const ThumbnailUpload: React.FC<Props> = ({
  thumbnailURLs,
  setFormData,
  opened,
  templateId,
  activeThumb,
  setActiveThumb,
  type,
}) => {
  const hiddenFileInput = useRef<HTMLInputElement | null>(null);
  const [loading, setLoading] = useState(false);
  const { activeAspectRatio } = useSelector<RootState>((state) => state.active) as ActiveDef;
  const aspectRatio = activeAspectRatio[0].toString() + ' / ' + activeAspectRatio[1].toString();
  const noThumbnail = getBgImage() === undefined;
  useEffect(() => {
    if (opened) takeScreenshot();
  }, [opened]);

  const handleClick = () => {
    hiddenFileInput?.current?.click();
  };

  const takeScreenshot = async () => {
    setLoading(true);
    const { blob, base64 } = await screenshotCanvasElement(type);
    setFormData((fd) => ({
      ...fd,
      thumbnailURLs: [...fd.thumbnailURLs, { blob, base64, id: v4() }],
    }));
    setLoading(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileUploaded = event.target?.files?.[0];
    if (!fileUploaded) return;
    const reader = new FileReader();
    reader.readAsDataURL(fileUploaded!);
    reader.onload = function () {
      setFormData((fd) => ({
        ...fd,
        thumbnailURLs: [
          ...fd.thumbnailURLs,
          { base64: URL.createObjectURL(fileUploaded!), blob: fileUploaded!, id: v4() },
        ],
      }));
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  };

  function previewClck(id: string) {
    setActiveThumb(id);
  }
  function onDelete(e: React.MouseEvent<HTMLButtonElement>, id: string) {
    e.stopPropagation();
    if (thumbnailURLs.length <= 1) return;
    const newThumbs = thumbnailURLs.filter((t) => t.id !== id);
    setFormData((fd) => ({ ...fd, thumbnailURLs: newThumbs }));
    if (activeThumb === id) setActiveThumb(newThumbs[0] ? newThumbs[0].id : null);
  }

  function getBgImage() {
    if (!thumbnailURLs?.length) return;
    if (activeThumb) return thumbnailURLs.find((t) => t.id === activeThumb)?.base64;
    return thumbnailURLs[0]?.base64;
  }
  return (
    <div className="relative">
      <div className="flex items-center gap-x-6 mb-3 thumb-wrap">
        {Boolean(thumbnailURLs.length) &&
          thumbnailURLs.map((t) => (
            <div
              key={t.id}
              className="cursor-pointer relative col-item rounded-2xl overflow-hidden max-w-[165px] max-h-[105px]"
              onClick={() => previewClck(t.id)}
              style={{
                outline: activeThumb === t.id ? '2px solid green' : '',
                aspectRatio: aspectRatio,
                // transform: activeThumb === t.id ? "scale(1.3)" : "",
              }}
            >
              <img alt="preview" src={t.base64} />
              {thumbnailURLs.length > 1 && (
                <button
                  onClick={(e) => onDelete(e, t.id)}
                  className={`${styles.delete}`}
                  disabled={thumbnailURLs.length === 1}
                >
                  <AiOutlineClose style={{ color: 'red' }} />
                </button>
              )}
            </div>
          ))}
        {Boolean(!thumbnailURLs?.length || loading) && (
          <div
            className="relative col-item rounded-2xl overflow-hidden bg-gray-200 w-[165px] max-h-[105px]"
            style={{ aspectRatio: aspectRatio }}
          >
            <Loader />
          </div>
        )}
      </div>
      <div
        className={`overflow-hidden ${styles.thumbnailImgWrapper}`}
        style={
          noThumbnail
            ? {
                height: STUDIO_PANEL_HEIGHT,
              }
            : {
                maxWidth: 520,
                maxHeight: 292,
                aspectRatio: aspectRatio,
              }
        }
      >
        {getBgImage() ? <img src={getBgImage()} /> : <Loader />}
      </div>

      <div className={`flex items-center gap-x-3 mt-10 justify-center ${styles.thumbnailActions}`}>
        <input
          type="file"
          accept="image/png,image/jpeg"
          multiple={false}
          ref={hiddenFileInput}
          onChange={handleChange}
          style={{ display: 'none' }}
        />
        <Tooltip content="Make thumbnail" placement="top" style={`dark`}>
          <Button
            size="small"
            disabled={thumbnailURLs.length > 4}
            onClick={takeScreenshot}
            icon={<AiOutlineCamera />}
          />
        </Tooltip>
        <Tooltip content="Upload a file" placement="top" style={`dark`}>
          <Button
            size="small"
            disabled={thumbnailURLs.length > 4}
            onClick={handleClick}
            icon={<AiOutlineUpload />}
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default ThumbnailUpload;
