import { ToggleSwitch } from 'flowbite-react';
import { Feature, Map, View } from 'ol';
import { Zoom } from 'ol/control';
import { shiftKeyOnly } from 'ol/events/condition';
import { Extent } from 'ol/extent';
import MVT from 'ol/format/MVT';
import { Geometry } from 'ol/geom';
import Circle from 'ol/geom/Circle';
import { Draw } from 'ol/interaction';
import Layer from 'ol/layer/Layer';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import VectorTileLayer from 'ol/layer/VectorTile';
import { fromLonLat, Projection, toLonLat, transformExtent } from 'ol/proj';
import { register } from 'ol/proj/proj4';
import { clear as clearTransforms } from 'ol/proj/transforms';
import VectorSource from 'ol/source/Vector';
import VectorTileSource from 'ol/source/VectorTile';
import XYZ from 'ol/source/XYZ';
import { Stroke, Style } from 'ol/style';
import Fill from 'ol/style/Fill';
import proj4 from 'proj4';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { distanceBetweenPoints } from '../../helpers/distanceBetweenPoints';
import {
  EARTH_RADIUS,
  OCEAN_NSPER_COORDINATE,
  SOUTH_POLE_NSPER_COORDINATE,
  STUDIO_PANEL_HEIGHT,
} from '../../model/constants/constants';
import { calculateProjection, getProjections } from '../../molecules/mapElement/getProjections';
import {
  createBaseMapTilesURL,
  distance,
  drawCircleGradient,
  getLayerByClassName,
  getProjectionStringForUtm,
  getResolutionForMapConstraint,
  haversineDistance,
  registerAllUtmProjections,
  // resolutionToZoom,
  utmZoneFromLonLat,
} from '../../molecules/mapElement/helpers';
import nsper from '../../molecules/mapElement/nsper';
import Loader from '../../pages/dashboard/components/Loader';
import { ActiveDef } from '../../store/slices/active-slice';
import { RootState } from '../../store/store';
import { createBoxWithRatio } from './createBoxWithRatio';

interface Props {
  projection: string;
  mapType: string;
  center: [number, number];
  setFormValues: React.Dispatch<React.SetStateAction<any>>;
  formValues: any;
  mapRef: React.MutableRefObject<Map | null>;
}

function isUtmOverEquator(center: [number, number], extent: Extent, isUtm: boolean) {
  if (!isUtm) return false;
  const isNorthHemisphere = center[1] >= 0;
  if (isNorthHemisphere) return extent[1] < 0;
  return extent[3] > 0;
}

const GVNSP_MASK_ZINDEX = 1000;

const MapElementMapBaseMerc: React.FC<Props> = ({
  projection,
  mapType,
  center,
  setFormValues,
  formValues,
  mapRef,
}) => {
  const [loading, setLoading] = useState(true);
  const initialCenterRef = useRef<[number, number]>(formValues.center);
  const drawRef = useRef<Draw | null>(null);
  const drawSourceRef = useRef<VectorSource<Geometry> | null>(null);
  const [isInvalid, setIsInvalid] = useState(false);
  const active = useSelector<RootState, ActiveDef>((state) => state.active);
  const projectionCalc = calculateProjection(projection, center);
  const isGVNSP = projectionCalc.includes('ESRI:54049');
  const [border, setBorder] = useState(false);
  const initTopCoord = useRef<number | null>(null);

  const projections = getProjections({ center, north: formValues.north });
  const projectionSettings = projections.find((p) => p.code == projectionCalc);
  let projString = projections.find((p) => p.code == projectionCalc)?.projectionString;
  const isUtm = projectionCalc.includes('EPSG:326');

  if (!projString && projectionCalc?.includes('EPSG:326')) {
    // UTM Projection
    const zone = utmZoneFromLonLat(formValues.center);
    projString = getProjectionStringForUtm(zone);
  }
  const url = `${
    process.env.REACT_APP_BASE_MAP_URL
  }/mvtiles/v2/{z}/{x}/{y}.mvt?features=borders,test&srs=${encodeURIComponent(
    projString ? projString : '',
  )}`;
  const projectionLike = new Projection({
    code: projectionCalc,
    units: 'm',
    worldExtent: projectionSettings?.worldExtent,
  });
  if (projectionSettings) {
    projectionLike.setExtent(projectionSettings.extent);
  }

  useEffect(() => {
    if (mapRef.current) {
      return;
    }

    // @ts-ignore
    proj4.Proj.projections.add(nsper);

    projections.forEach(function (p) {
      if (p.projectionString !== undefined) proj4.defs(p.code, p.projectionString);
    });
    // clear transforms cache of OL to re-register projections
    // this is added to allow projections with 'north' param to change on the fly
    clearTransforms();
    register(proj4);
    registerAllUtmProjections(register, proj4);

    const mapURL = createBaseMapTilesURL(mapType);
    const isMercator = Boolean(projectionCalc === 'EPSG:3857');
    const viewCenter = fromLonLat(center, projectionCalc);
    // Haversine for projections
    const distanceToNorthH = haversineDistance(center, [center[0], 85]);
    const distanceToSouthH = haversineDistance(center, [center[0], -89]);
    const distanceToWestH = haversineDistance(center, [-179.9, center[1]]);
    const distanceToEastH = haversineDistance(center, [179.9, center[1]]);
    // Standard for wgs and mercator
    const distanceToWest = distanceBetweenPoints(center, [-180, center[1]]) * 111000;
    const distanceToEast = distanceBetweenPoints(center, [180, center[1]]) * 111000;

    const resolEast = getResolutionForMapConstraint(
      distanceToEast,
      'E',
      active.activeAspectRatio,
      STUDIO_PANEL_HEIGHT,
    );
    const resolWest = getResolutionForMapConstraint(
      distanceToWest,
      'W',
      active.activeAspectRatio,
      STUDIO_PANEL_HEIGHT,
    );
    const maxResolutionW = getResolutionForMapConstraint(
      distanceToWestH,
      'W',
      active.activeAspectRatio,
      STUDIO_PANEL_HEIGHT,
    );
    const maxResolutionS = getResolutionForMapConstraint(
      distanceToSouthH,
      'S',
      active.activeAspectRatio,
      STUDIO_PANEL_HEIGHT,
    );
    const maxResolutionE = getResolutionForMapConstraint(
      distanceToEastH,
      'E',
      active.activeAspectRatio,
      STUDIO_PANEL_HEIGHT,
    );
    const maxResolutionN = getResolutionForMapConstraint(
      distanceToNorthH,
      'N',
      active.activeAspectRatio,
      STUDIO_PANEL_HEIGHT,
    );

    const baseLayer = new TileLayer({
      className: 'base-tile-layer',
      source: new XYZ({
        url: mapURL,
        crossOrigin: 'anonymous',
        minZoom: isMercator ? 3 : 4, // set min zoom to 3 to improve performance of map server
      }),
    });
    const layers: Layer[] = [baseLayer];
    if (isGVNSP) {
      layers.push(
        new VectorLayer({
          zIndex: GVNSP_MASK_ZINDEX,
          source: new VectorSource({
            features: [new Feature(new Circle([0, 0], EARTH_RADIUS))],
          }),
          style: new Style({
            renderer: (coordinates, state) => {
              drawCircleGradient({
                coordinates,
                state,
                fromColor: formValues?.fromColor ?? 'black',
                toColor: formValues?.toColor ?? 'black',
                width: formValues?.ringWidth,
              });
            },
          }),
        }),
      );
    }

    mapRef.current = new Map({
      target: 'add-map-element',
      controls: [new Zoom()],
      layers,
      view: new View({
        center: viewCenter,
        zoom: 6,
        rotation: 0,
        projection: projectionLike,
        extent: viewCenter.concat(viewCenter),
        constrainOnlyCenter: true,
        smoothExtentConstraint: false,
        maxResolution: isGVNSP
          ? undefined
          : isMercator
          ? Math.min(resolEast, resolWest)
          : Math.min(maxResolutionW, maxResolutionE, maxResolutionN, maxResolutionS), // TO BE DOEN
      }),
    });

    mapRef.current.on('rendercomplete', () => {
      setLoading(false);
    });

    mapRef.current!.on('rendercomplete', () => {
      if (isGVNSP) {
        const hasCoverUp = Boolean(getLayerByClassName('gvnsp-coverup', mapRef.current!));
        if (hasCoverUp) return;
        const [projectionCenterLat, projectionCenterLon] = center;
        const oceanPointDist = distance(
          Number(projectionCenterLat),
          Number(projectionCenterLon),
          85,
          0,
        );
        const southPolePointDist = distance(
          Number(projectionCenterLon),
          Number(projectionCenterLat),
          -85,
          0,
        );

        let point = OCEAN_NSPER_COORDINATE;
        if (oceanPointDist > southPolePointDist) {
          point = SOUTH_POLE_NSPER_COORDINATE;
        }
        const pixel = mapRef.current?.getPixelFromCoordinate(point)!;

        const baseLayerFound = getLayerByClassName('base-tile-layer', mapRef.current!);

        const imgData = baseLayerFound!.getData(pixel) as Uint8ClampedArray;
        if (!imgData) return;
        const r = imgData?.[0];
        const g = imgData?.[1];
        const b = imgData?.[2];
        const a = (imgData?.[3] / 255) * 100;

        mapRef.current?.addLayer(
          new VectorLayer({
            zIndex: -1,
            className: 'gvnsp-coverup',
            source: new VectorSource({
              features: [new Feature(new Circle([0, 0], EARTH_RADIUS))],
            }),
            style: new Style({
              fill: new Fill({
                color: `rgba(${r},${g},${b},${a})`,
              }),
            }),
          }),
        );
      }
    });

    drawSourceRef.current = new VectorSource({ wrapX: false });

    const vectorForDraw = new VectorLayer({
      source: drawSourceRef.current,
      zIndex: 999,
    });
    mapRef.current.addLayer(vectorForDraw);
    drawRef.current = new Draw({
      source: drawSourceRef.current!,
      type: 'Circle',
      geometryFunction: createBoxWithRatio(active.activeAspectRatio, initTopCoord),
      condition: shiftKeyOnly,
      wrapX: false,
    });

    mapRef.current?.addInteraction(drawRef.current);
    const viewExt = mapRef.current.getView().calculateExtent();
    const transformedRawExt = transformExtent(
      mapRef.current!.getView().calculateExtent(mapRef.current?.getSize()),
      projectionCalc,
      'EPSG:4326',
    );

    setIsInvalid(isUtmOverEquator(center, transformedRawExt, isUtm));

    setFormValues((fv: any) => ({
      ...fv,
      bounds: viewExt,
      zoom: 6,
      resolution: mapRef.current?.getView()?.getResolution(),
      transformedRawExt,
    }));

    drawRef.current.on('drawstart', (ev) => {
      drawSourceRef.current?.clear();
    });

    drawRef.current.on('drawend', (evt) => {
      // @ts-ignore
      const coordinates = evt?.feature?.getGeometry().getCoordinates()[0];

      const newExt = [coordinates[0][0], coordinates[0][1], coordinates[1][0], coordinates[2][1]];
      const transformedRawExt = transformExtent(newExt, projectionCalc, 'EPSG:4326');

      const centerX = (transformedRawExt[0] + transformedRawExt[2]) / 2;
      const centerY = (transformedRawExt[1] + transformedRawExt[3]) / 2;
      let drawCenter = [centerX, centerY];
      if (drawCenter.some((c) => isNaN(c))) {
        const centerXraw = (newExt[0] + newExt[2]) / 2;
        const centerYraw = (newExt[1] + newExt[3]) / 2;
        drawCenter = toLonLat([centerXraw, centerYraw], projectionCalc);
      }

      setIsInvalid(isUtmOverEquator(center, transformedRawExt, isUtm));
      setFormValues((fv: any) => ({
        ...fv,
        bounds: newExt,
        center: drawCenter,
        zoom: mapRef.current?.getView()?.getZoom(),
        resolution: mapRef.current?.getView()?.getResolution(),
        transformedRawExt,
      }));
    });
    mapRef.current.on('dblclick', () => {
      drawSourceRef.current?.clear();
      const transformedRawExt = transformExtent(
        mapRef.current!.getView().calculateExtent(mapRef.current?.getSize()),
        projectionCalc,
        'EPSG:4326',
      );

      const viewExt = mapRef.current!.getView().calculateExtent();

      setIsInvalid(isUtmOverEquator(center, transformedRawExt, isUtm));

      setFormValues((fv: any) => ({
        ...fv,
        bounds: viewExt,
        center: initialCenterRef.current,
        zoom: mapRef.current?.getView().getZoom(),
        resolution: mapRef.current?.getView()?.getResolution(),
        transformedRawExt,
      }));
    });

    mapRef.current.on('moveend', () => {
      // const extUntransformed = mapRef.current!.getView().calculateExtent(mapRef.current?.getSize());
      drawSourceRef.current?.clear();
      const transformedRawExt = transformExtent(
        mapRef.current!.getView().calculateExtent(mapRef.current?.getSize()),
        projectionCalc,
        'EPSG:4326',
      );

      const viewExt = mapRef.current!.getView().calculateExtent();

      setIsInvalid(isUtmOverEquator(center, transformedRawExt, isUtm));

      setFormValues((fv: any) => ({
        ...fv,
        bounds: viewExt,
        zoom: mapRef.current?.getView().getZoom(),
        resolution: mapRef.current?.getView()?.getResolution(),
        transformedRawExt,
      }));
    });
  }, []);

  useEffect(
    () => () => {
      mapRef.current = null;
    },
    [],
  );

  useEffect(() => {
    if (mapRef.current) {
      if (!border) {
        const layers = mapRef.current.getLayers().getArray();
        const borders = layers.find((layer) => layer.get('name') && layer.get('name') == 'borders');
        if (borders) {
          mapRef.current.removeLayer(borders);
          borders.dispose();
        }
      } else {
        const vectorLayer = new VectorTileLayer({
          source: new VectorTileSource({
            url,
            projection: projectionLike,
            format: new MVT(),
          }),
          zIndex: 1,
          style: () => {
            return new Style({
              stroke: new Stroke({
                color: 'red',
                width: 1,
              }),
            });
          },
        });
        vectorLayer.set('name', 'borders');
        mapRef.current.addLayer(vectorLayer);
      }
    }
  }, [border]);

  return (
    <>
      <div>
        {isInvalid ? (
          <p className="text-center text-red-600">Map is out of bounds of selected projection!!!</p>
        ) : null}
        <p className="text-center">
          * Use{' '}
          <span>
            <code>Shift</code> + Drag
          </span>{' '}
          on the corners or edges of the extent to resize it. <span>Double Click</span> off the
          bounds to remove it. <br />
          *If extent is not selected current viewport will be used as extent.
        </p>
        <div className="flex my-4">
          <div className="flex">
            <span className="mr-2">Borders: </span>
            <ToggleSwitch
              checked={border}
              onChange={() => {
                setBorder(!border);
              }}
              label=""
            />
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center relative">
        <div
          id="add-map-element"
          className={isGVNSP ? 'gvnsp-background' : ''}
          style={{
            height: 350,
            aspectRatio: `${active.activeAspectRatio[0]}/${active.activeAspectRatio[1]}`,
            border: isInvalid ? '1px solid red' : '',
          }}
        />
        {loading && (
          <div
            data-html2canvas-ignore="true"
            className={`absolute w-full h-full z-[9999] flex items-center justify-center bg-[#1f2a40]/50`}
          >
            <Loader />
          </div>
        )}
      </div>
    </>
  );
};

export default MapElementMapBaseMerc;
