import { usePropertyGridActive } from '../../../../hooks/usePropertyGridActive';
import { ForecastWDElementDef } from '../../../../model/definitions/ForecastWDElementDef';
import { ObservedWDElementDef } from '../../../../model/definitions/ObservedWDElementDef';
import { SceneDef } from '../../../../model/definitions/SceneDef';
import { TextPanelDef } from '../../../../model/definitions/TextPanelDef';
import { PropertySection } from '../components/PropertySection';
import { fontPropertiesDeps } from '../constants/propertiesConstants';
import { CoreFontProperties } from './CoreFontProperties';

interface FontPropertiesProps {
  layer: ObservedWDElementDef | TextPanelDef | ForecastWDElementDef;
  layerType:
    | SceneKeys<SceneDef>
    | 'mapTimeframeTextIndicator'
    | 'observedDataLayers'
    | 'forecastDataLayers';
  poster?: string;
  mapId?: string;
}

export interface FontInterface {
  createdAt: number;
  creator: string;
  description: string;
  format: string;
  id: string;
  name: string;
  updatedAt?: number;
}

export interface FontVariantInterface {
  createdAt: number;
  creator?: string;
  defaultType: boolean;
  id: string;
  type: string;
  updatedAt?: number;
  url: string;
}

export const FontProperties = ({ layer, layerType, poster, mapId }: FontPropertiesProps) => {
  const { isOpened } = usePropertyGridActive(fontPropertiesDeps);
  return (
    <PropertySection label={'Font'} isOpened={isOpened}>
      <div>
        If the user's machine doesn't have the selected font installed, the default system font will
        be displayed in studio.
      </div>
      <CoreFontProperties layer={layer} layerType={layerType} poster={poster} mapId={mapId} />
    </PropertySection>
  );
};
