import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { axiosInstance } from '../../../core/api/axiosInstance';
import { queryClient } from '../../../index';
import { key } from './useGetTranslations';

export const useDeleteTranslation = () => {
  return useMutation(
    async (id: string) => {
      return await axiosInstance.delete(`translations/${id}`);
    },
    {
      onSuccess: () => {
        toast.success('Translation deleted!');
        queryClient.invalidateQueries(key);
      },
      onError: () => {
        toast.error('Error deleting translation!');
      },
    },
  );
};
