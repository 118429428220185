import { groupBy } from 'lodash';
import { useEffect, useState } from 'react';

import Input from '../../../../../atoms/input/Input';
import LoadingIndicator from '../../../../../atoms/loadingIndicator/LoadingIndicator';
import { useGetDateTime } from '../../../../../core/api/observedData/useGetDateTime';
import { useGetStations } from '../../../../../core/api/observedData/useGetStations';
import { useDebounce } from '../../../../../hooks/useDebounce';
import { ObservedWDElementDef } from '../../../../../model/definitions/ObservedWDElementDef';
import { WeatherPosterDef } from '../../../../../model/definitions/WeatherPosterDef';
import { findClosest } from '../../../sidebar-new/repoUtil/nearestDataFrame';
import { ObservedTimes } from './ObservedTimes';

interface ObservedSectionProps {
  tempDate: number;
  defaultStation: string;
  stationName: string;
  offset: number;
  observedTemplateData: Array<ObservedWDElementDef>;
  step: 'observed' | 'forecast' | 'add';
  referentDate: number;
  setSelected: (e: number) => void;
  bias: boolean;
  definition: WeatherPosterDef;
}

export const ObservedSection = ({
  tempDate,
  defaultStation,
  stationName,
  observedTemplateData,
  step,
  setSelected,
  bias,
  definition,
}: ObservedSectionProps) => {
  const [searchKey, setSearchKey] = useState<string | undefined>(stationName);
  const debounceSearch = useDebounce(searchKey);
  const { data: stations, isLoading } = useGetStations(debounceSearch?.toUpperCase());
  const handleStation = (val: string, loc: string) => {
    setSearchKey(val);
    setStation({ val, loc });
  };
  useEffect(() => {
    console.log(definition.pointLocation);
  }, [definition]);
  const [station, setStation] = useState<{ val: string; loc?: string }>({
    val: defaultStation,
    loc: stations?.find((stat) => stat.value === defaultStation)?.fullName,
  });

  const getBorders = () => {
    if (
      searchKey &&
      (station.val !== searchKey.toUpperCase() || station.loc !== searchKey.toUpperCase())
    )
      return { borderBottomLeftRadius: 0, borderBottomRightRadius: 0 };
  };
  const { data: observedTime, isSuccess } = useGetDateTime(station.val);
  useEffect(() => {
    isSuccess && setSelected(Math.max(...observedTime));
  }, [observedTime]);
  const dateGroup = groupBy(observedTemplateData, 'observedWDSource.datetime');
  const lowestDate = definition.observedWDElements.reduce((acc, obj) => {
    const objDate = new Date(Date.parse(obj.observedWDSource.utcDate));
    return objDate < acc ? objDate : acc;
  }, new Date('9999-12-31'));

  const renderDataSegmentation = () => {
    const dates = [];
    for (const date in dateGroup) {
      const tempOffset = Number(date) - lowestDate.getTime() / 1000;
      dates.push(
        <ObservedTimes
          definition={definition}
          bias={bias}
          step={step}
          key={`${date}_${definition.id}_${tempOffset}`}
          date={findClosest(observedTime, Math.round(tempDate / 1000) - Math.round(tempOffset))}
          station={station.val}
          stationName={station.loc}
          observedGroup={dateGroup[date]}
        />,
      );
    }
    return dates;
  };
  return (
    <>
      <div className="relative location-wrapper">
        <Input
          label={'Select Station'}
          required
          value={searchKey}
          onChange={(e) => setSearchKey(e.target.value)}
          style={getBorders()}
        />
        {searchKey &&
          station.val !== searchKey.toUpperCase() &&
          station.loc !== searchKey.toUpperCase() && (
            <div className={'search-result items-center'}>
              {isLoading ? (
                <div className={'flex w-1/2 loading'}>
                  <div className={'flex items-center mr-3'}>
                    <LoadingIndicator />
                  </div>
                  Searching
                </div>
              ) : searchKey &&
                station.val !== searchKey.toUpperCase() &&
                station.loc !== searchKey.toUpperCase() ? (
                stations?.map((stat) => (
                  <div
                    key={stat.value}
                    onClick={() => handleStation(stat.value, stat.fullName)}
                    className={'result'}
                  >
                    {stat.fullName} {stat.value}
                  </div>
                ))
              ) : (
                <div className={'flex justify-center items-center'}>no results</div>
              )}
            </div>
          )}
      </div>
      {renderDataSegmentation()}
    </>
  );
};
