import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { axiosInstance } from '../../../core/api/axiosInstance';
import { queryClient } from '../../../index';
import { key as getItemDataKey } from '../../dashboard/queries-NEW/useGetPublishedItem';
import { key as useGetPublishedItemsKey } from '../hooks/filterUseGetPublishedItems';

export const useApproveItem = () => {
  return useMutation(
    async (marketplaceItemId: string) => {
      return await axiosInstance.post(`marketplace/approve?marketplaceItemId=${marketplaceItemId}`);
    },
    {
      onSuccess: () => {
        toast.success('Item approved!');
        queryClient.invalidateQueries(getItemDataKey);
        queryClient.invalidateQueries(useGetPublishedItemsKey);
      },
      onError: () => {
        toast.error('Error approving item!');
      },
    },
  );
};
