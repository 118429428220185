export enum ParticleDensityEnum {
  LOW = 0,
  MEDIUM = 1,
  HIGH = 2,
}

export const ParticleDensityText = {
  [ParticleDensityEnum.LOW]: 'Low',
  [ParticleDensityEnum.MEDIUM]: 'Medium',
  [ParticleDensityEnum.HIGH]: 'High',
};
