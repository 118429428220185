import { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { axiosInstance } from './axiosInstance';

const key = ['IMAGE'];

export const useGetImageMultimedia = (id: string, isJson: boolean) => {
  const [downloadProgress, setDownloadProgress] = useState(0);
  const queryClient = useQueryClient();

  const query = useQuery(
    [...key, id],
    async () => {
      const { data } = await axiosInstance.get(`multimedia/download/${id}`, {
        responseType: isJson ? 'json' : 'arraybuffer',
        onDownloadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setDownloadProgress(percentCompleted);
        },
      });
      queryClient.setQueryData(
        [...key, id],
        URL.createObjectURL(new Blob([data], { type: 'octet/stream' })),
      );

      return URL.createObjectURL(new Blob([data], { type: 'octet/stream' }));
    },
    {
      enabled: !!id && !Array.isArray(id) && id !== 'undefined',
      cacheTime: 10 * 60 * 1000,
      staleTime: Infinity,
      keepPreviousData: true,
    },
  );

  return { ...query, downloadProgress };
};
