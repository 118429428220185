import { AnimationPanelDef } from './AnimationPanelDef';
import { AudioElement } from './AudioElement';
import { C9Object } from './C9Object';
import { C9SceneDefProperties } from './C9SceneDefProperties';
import { ForecastWDElementDef } from './ForecastWDElementDef';
import { ImagePanelDef } from './ImagePanelDef';
import { LogicalGroupElement } from './LogicalGroupElement';
import { MapPanelDef } from './MapPanelDef';
import { ObservedWDElementDef } from './ObservedWDElementDef';
import { PointDateDef } from './PointDateDef';
import { PointLocationDef } from './PointLocationDef';
import { TextPanelDef } from './TextPanelDef';
import { TimeControlDef } from './TimeControlDef';
import { TimestampElementDef } from './TimestampElementDef';
import { VideoPanelDef } from './VideoPanelDef';
import { WeatherPosterDef } from './WeatherPosterDef';

export class SceneDef extends C9Object {
  videoPanels: VideoPanelDef[];
  imagePanels: ImagePanelDef[];
  mapPanels: MapPanelDef[];
  textPanels: TextPanelDef[];
  timestampPanels: TimestampElementDef[];
  mapTextPanels: TextPanelDef[];
  weatherPosters: WeatherPosterDef[];
  audioElements: AudioElement[];
  animationPanels: AnimationPanelDef[];
  observedWDElements: ObservedWDElementDef[];
  forecastWDElements: ForecastWDElementDef[];
  pointDates: PointDateDef[];
  pointLocation: PointLocationDef[];
  logicalGroups: LogicalGroupElement[];
  durationInMS: number;
  c9SceneDefProperties: C9SceneDefProperties[];
  thumbnailUrls: Array<string>;
  timeControl: TimeControlDef;
  translationId: string | null;
  startDate: number | null;
  endDate: number | null;
}
