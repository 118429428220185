import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';

import { SceneDef } from '../../../model/definitions/SceneDef';
import { TimeControlDef } from '../../../model/definitions/TimeControlDef';
import { WeatherPosterDef } from '../../../model/definitions/WeatherPosterDef';
import { setScene } from '../../../store/slices/active-slice';
import { addScene } from '../../../store/slices/project-slice';
import { axiosInstance } from '../axiosInstance';

export const useCreatePosterScene = () => {
  const dispatch = useDispatch();
  return useMutation(
    async (payload: {
      name: string;
      description: string;
      duration: number;
      poster: WeatherPosterDef;
    }): Promise<SceneDef> => {
      const { name, description, duration } = payload;
      const { data } = await axiosInstance.post<SceneDef>(`scene/scene-def`, null, {
        params: {
          name,
          description,
          duration,
        },
      });
      return data;
    },
    {
      onSuccess: (data, variables) => {
        dispatch(
          addScene({
            scene: {
              ...data,
              weatherPosters: [{ ...variables.poster, enabled: true }],
              timeControl: new TimeControlDef(),
            },
          }),
        );
        dispatch(setScene({ activeScene: data.id }));
      },
    },
  );
};
