import { useEffect, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';

import { ExampleCustomInput } from '../../../pages/playground/modals/ObservedGeoPosterImport';

interface ObservedTimePickerProps {
  availableTimes: Array<number>;
  onDateSelect: (e: number) => void;
  currentSelection?: number;
}
export const ObservedTimePicker = ({
  availableTimes = [],
  onDateSelect,
  currentSelection,
}: ObservedTimePickerProps) => {
  const includedDates = useMemo(
    () =>
      availableTimes
        ?.sort((a, b) => b - a)
        ?.map((time) => {
          const date = new Date(time * 1000);
          return new Date(date);
        }),
    [availableTimes],
  );
  const [selected, setSelected] = useState<Date>(
    /* currentSelection ? new Date(currentSelection * 1000) :  */ includedDates[0],
  );
  const [timesForSelect, setTimesForSelect] = useState<Date[]>();

  useEffect(() => {
    const timeForDay = includedDates.filter(
      (date) =>
        date.getMonth() === selected.getMonth() &&
        date.getDate() === selected.getDate() &&
        date.getFullYear() === selected.getFullYear(),
    );
    setTimesForSelect(timeForDay);
  }, [includedDates, selected]);

  useEffect(() => {
    currentSelection && setSelected(new Date(currentSelection * 1000));
    if (!currentSelection) {
      const date = includedDates[0];
      const setD = date?.getTime() / 1000;
      onDateSelect(setD);
    }
  }, [currentSelection, includedDates, onDateSelect]);
  const timeClass = () => {
    return 'time-style';
  };

  return (
    <div className={'mb-[7px]'}>
      <DatePicker
        placeholderText={'Select observed moment'}
        selected={selected}
        value={selected.toLocaleDateString('en-GB', { hour: '2-digit', minute: '2-digit' })}
        includeDates={includedDates.length > 0 ? includedDates : []}
        includeTimes={timesForSelect}
        dateFormat={'yyyy.mm.d UTC'}
        dateFormatCalendar={'MMMM yyyy'}
        showPopperArrow={false}
        shouldCloseOnSelect={true}
        onChange={(e) => {
          e && setSelected(new Date(e.getTime()));
          e && onDateSelect(e?.getTime() / 1000 ?? 0);
        }}
        timeIntervals={1}
        withPortal={true}
        showTimeSelect
        timeClassName={timeClass}
        timeFormat={'HH:mm'}
        customInput={
          <ExampleCustomInput
            value={selected.toLocaleDateString('en-GB', { hour: '2-digit', minute: '2-digit' })}
            onClick={() => console.log(selected)}
          />
        }
      />
    </div>
  );
};
