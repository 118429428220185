import { useSelector } from 'react-redux';

import { RolesEnum } from '../model/enums/RolesEnum';
import { RootState } from '../store/store';

function useRoleAccess(roles: RolesEnum[], isSharedEntity: boolean, inEditMode: boolean) {
  const { userRoles } = useSelector<RootState>((state) => state.user) as {
    userRoles: RolesEnum[];
  };
  if (userRoles?.includes(RolesEnum.ROLE_SUPER_ADMIN)) return true;
  // inEditMode is null if it is working version
  if (isSharedEntity && inEditMode !== null) {
    return false;
  }
  if (!isSharedEntity) {
    return roles.some((role: RolesEnum) => userRoles?.includes(role));
  }
}

export default useRoleAccess;
