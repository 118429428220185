import { AiOutlineAudio, AiOutlineFileImage, AiOutlineVideoCameraAdd } from 'react-icons/ai';
import { HiOutlineTemplate } from 'react-icons/hi';
import { MdOutlineAnimation } from 'react-icons/md';

import { SidebarItemsEnum } from '../../../model/enums/SidebarItemsEnum';

export const sidebarMenu = [
  {
    title: 'Image repository',
    icon: <AiOutlineFileImage />,
    tab: SidebarItemsEnum.IMAGE,
  },
  {
    title: 'Video repository',
    icon: <AiOutlineVideoCameraAdd />,
    tab: SidebarItemsEnum.VIDEO,
  },
  {
    title: 'Audio repository',
    icon: <AiOutlineAudio />,
    tab: SidebarItemsEnum.AUDIO,
  },
  {
    title: 'Animation repository',
    icon: <MdOutlineAnimation />,
    tab: SidebarItemsEnum.ANIMATION,
  },
  {
    title: 'Template repository',
    icon: <HiOutlineTemplate />,
    tab: SidebarItemsEnum.TEMPLATE,
  },
];
