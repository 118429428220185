import React from 'react';

import Repository from '../../assets/images/Repository.svg';
import styles from '../menu/Menu.module.scss';
import MenuItem from '../menu-item/MenuItem';

const MenuMarketplace: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div className={`h-full flex justify-between`}>
      <div className={`h-full flex flex-col   ${styles['menu-wrraper']}`}>
        <p className={`self-center ${styles.logo}`}>nimbus</p>
        <p className={`self-center ${styles['sub-logo']} mb-4`}>media</p>
        <div className="flex flex-col justify-between h-full">
          <div className="h-full">
            <MenuItem
              to={'/marketplace/projects'}
              label={'Projects'}
              iconSrc={Repository}
              // type="audio"
            />
            <MenuItem
              to={'/marketplace/scenes'}
              label={'Scenes'}
              iconSrc={Repository}
              type="audio"
            />
            <MenuItem to={'/marketplace/maps'} label={'Maps'} iconSrc={Repository} type="audio" />
            <MenuItem to={'/marketplace/images'} label={'Images'} iconSrc={''} type={'image'} />
            <MenuItem
              to={'/marketplace/videos'}
              label={'Videos'}
              iconSrc={Repository}
              type="video"
            />
            <MenuItem
              to={'/marketplace/audios'}
              label={'Audios'}
              iconSrc={Repository}
              type="audio"
            />
          </div>
        </div>
      </div>
      <div
        className="w-full"
        style={{
          backgroundColor: '#f6f6f6',
          height: '100vh',
          overflow: 'auto',
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default MenuMarketplace;
