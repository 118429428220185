import { useQuery } from 'react-query';

import { axiosInstance } from '../../../core/api/axiosInstance';

export const key = ['DATA_PRODUCTS_PALETTE_TYPES'];

export const useGetDataProductsPaletteTypes = (layerType?: string) =>
  useQuery(
    key,
    async () => {
      const { data } = await axiosInstance.get('color-palette-parameter-group/by-layer-type', {
        params: { layerType: layerType === 'MODEL' ? 'GRIB' : layerType },
      });
      return data;
    },
    {
      staleTime: Infinity,
    },
  );
