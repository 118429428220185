import './style.scss';

import { AiOutlineShop } from 'react-icons/ai';
import { Link } from 'react-router-dom';

import UserActions from '../userActions-NEW/UserActions';

const WorkspaceHeader = () => {
  return (
    <div className={'w-full ws-header flex justify-end items-center'}>
      <div className={'flex justify-between items-center'}>
        <Link to={'/marketplace'} className={'ws-mp-link'}>
          <AiOutlineShop size={18} /> <span>Marketplace</span>
        </Link>
        <UserActions align />
      </div>
    </div>
  );
};

export default WorkspaceHeader;
