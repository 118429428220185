import { useQuery } from 'react-query';

import { axiosInstance } from '../../../core/api/axiosInstance';
import { AccountTypeEnum } from '../../../model/enums/AccountTypeEnum';

export const key = ['USER_ROLES'];
export const useGetUserRoles = (type: string, id?: string) => {
  return useQuery(
    [...key, id],
    async () => {
      const { data } = await axiosInstance.get(`enterprise-users/assigned-roles/${id}`);
      return data;
    },
    { enabled: !!id && type === AccountTypeEnum.ENTERPRISE_USER, staleTime: Infinity },
  );
};
