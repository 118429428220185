export class GlobalPlayerControl {
  private static time = 0;

  static getTime() {
    return this.time;
  }

  static setTime(val: number) {
    this.time = val;
  }
}
