import { useState } from 'react';
import { AiOutlineDoubleRight } from 'react-icons/ai';

import Button from '../../../atoms/button/Button';
import Input from '../../../atoms/input/Input';
import TextArea from '../../../atoms/input/Textarea';
import { useGetImageMultimedia } from '../../../core/api/useGetMultimedia';
import { stringToUnit } from '../../../helpers/stringToUnit';
import { ValueTypeEnum } from '../../../model/enums/ValueTypeEnum';
import { displayPointValue } from '../../../molecules/canvasElements/showPointDataValue';
import Modal from '../../../molecules/modal/Modal';
import { splitStringOnCapital } from '../bias-filter/BiasFilter';

interface BiasFilterModalProps {
  sourceVal: Array<{ unit: string; value: number; originalValue: number }>;
  type: ValueTypeEnum;
  isOpen: boolean;
  onClose: () => void;
  value: any;
  originalValue: any;
  unit: string;
  parameter: string;
  onCreate: (form: { name: string; description: string }) => void;
}

export const BiasFilterModal = ({
  type,
  isOpen,
  onClose,
  value,
  originalValue,
  unit,
  parameter,
  onCreate,
  sourceVal,
}: BiasFilterModalProps) => {
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const { data: urlOrig } = useGetImageMultimedia(originalValue?.versionId, false);
  const { data: urlNew } = useGetImageMultimedia(value?.versionId, false);
  return (
    <Modal
      header={'Create Bias Filter'}
      className="template-scene-modal"
      isOpen={isOpen}
      onClose={onClose}
      footer={
        <div className="modal-footer">
          <div className={'footnote'}></div>
          <div className={'button-holder'}>
            <div className="w-full flex items-center mt-5 next-back-btn">
              <>
                <Button onClick={onClose} buttonType="secondary" label="Cancel" />
                <Button
                  disabled={name.length < 1 && description.length < 1}
                  buttonType="primary"
                  onClick={() => {
                    onCreate({ name, description });
                  }}
                  label="Create"
                />
              </>
            </div>
          </div>
        </div>
      }
    >
      <>
        <Input
          name={'name'}
          label={'Name'}
          placeholder="Type name..."
          required
          value={name}
          onChange={(e) => setName(e.target.value)}
          type={'text'}
        />
        <TextArea
          placeholder="Type description..."
          name={'description'}
          label={'Description'}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          cols={2}
        />
        <div className="bias-filer-results">
          <h3 className={'text-white mt-6'}>
            {type === ValueTypeEnum.NUMERICAL ? splitStringOnCapital(parameter) : 'Icon'}
          </h3>
          <div className={'flex'}>
            <div className={'flex w-[50%] items-center'}>
              <div
                className={
                  'mb-2 property-card w-[40%] h-[150px] flex items-center justify-center text-3xl'
                }
              >
                {displayPointValue(
                  type,
                  type === ValueTypeEnum.NUMERICAL ? sourceVal : originalValue,
                  parameter,
                  originalValue?.jsonAnimation,
                  urlOrig,
                  unit,
                  true,
                )}
                {stringToUnit(unit)}
              </div>
              <div>
                <AiOutlineDoubleRight style={{ fontSize: 50 }} />
              </div>
              <div
                className={
                  'mb-2 property-card w-[40%] h-[150px] flex items-center justify-center text-3xl'
                }
              >
                {displayPointValue(type, value, parameter, value?.jsonAnimation, urlNew, unit)}
                {stringToUnit(unit)}
              </div>
            </div>
            {!isNaN(value - originalValue) && (
              <div className="flex items-center">
                <h2 className={'text-white mr-3'}>Bias value:</h2>
                <h2 className={'text-white'} style={{ color: '#2176ff' }}>
                  {value - originalValue}
                  {stringToUnit(unit)}
                </h2>
              </div>
            )}
          </div>
        </div>
      </>
    </Modal>
  );
};
