import { Empty } from 'antd';
import { Button } from 'flowbite-react/lib/esm/components/Button';
import proj4 from 'proj4';

import { CustomMapDef } from '../../model/definitions/CustomMapDef';
import { MapPanelDef } from '../../model/definitions/MapPanelDef';
import { MapStylesType } from '../../molecules/mapElement/MapStylesHash';

interface CustomMapProps {
  data?: Array<CustomMapDef>;
  currentMapType?: string;
  center: [number, number];
  changeType: (
    path: Leaves<MapPanelDef>,
    type: keyof MapStylesType | string,
    mapStyleId: string,
  ) => void;
}
export const CustomMaps = ({ data = [], changeType, currentMapType, center }: CustomMapProps) => {
  //const projectionCalc = calculateProjection(data[0].geoTiffDef.originalMapProjection, center);
  const coordinates = (projString: string): [number, number] => proj4('WGS84', projString, center);
  const maps = data
    ?.filter(
      (map) =>
        map.geoTiffDef &&
        map.geoTiffDef.originalBoundingBox.latMax >=
          coordinates(map.geoTiffDef.originalMapProjection)[1] &&
        map.geoTiffDef.originalBoundingBox.latMin <=
          coordinates(map.geoTiffDef.originalMapProjection)[1] &&
        map.geoTiffDef.originalBoundingBox.lonMax >=
          coordinates(map.geoTiffDef.originalMapProjection)[0] &&
        map.geoTiffDef.originalBoundingBox.lonMin <=
          coordinates(map.geoTiffDef.originalMapProjection)[0] &&
        !map.disabled,
    )
    ?.map((map) => (
      <div
        key={map.id}
        onClick={() => changeType('mapType', `customMap-${map.versionId}`, map.id)}
        className={`map-btn ${currentMapType === map.name ? 'active' : ''}`}
      >
        <Button>
          {<img alt={map.name} width={'100%'} height={'100%'} src={map.thumbnailURLs[0]} />}
          <div>
            <p>{map.name}</p>
          </div>
        </Button>
      </div>
    ));
  return (
    <div className="map-buttons-wrapper">
      {maps.length > 0 ? (
        maps
      ) : (
        <Empty
          className={'flex justify-center flex-col'}
          image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
          imageStyle={{ height: 60, margin: 'auto' }}
          description={<span>No maps containing the chosen center coordinates</span>}
        ></Empty>
      )}
    </div>
  );
};
