import { Select } from 'flowbite-react';
import { useEffect, useRef, useState } from 'react';

import { pointDataValue } from '../../../helpers/numberHelpers';
import { stringToUnit } from '../../../helpers/stringToUnit';
import { ObservedProperties } from '../../../model/other/ObservedData';

interface ObservedCardProps {
  property: ObservedProperties;
  onClick: (unit: string) => void;
  active?: boolean;
  biased: boolean;
}
export const ObservedCard = ({ property, onClick, active, biased }: ObservedCardProps) => {
  const cardRef = useRef<HTMLDivElement>(null);
  const valRef = useRef<HTMLDivElement>(null);
  const [shrink, setShrink] = useState(1);
  const [unit, setUnit] = useState<string>(property.values[0]?.unit);
  useEffect(() => {
    const ratio = (cardRef.current?.offsetWidth ?? 1) / (valRef.current?.offsetWidth ?? 1);
    setShrink(ratio > 1 ? 1 : ratio);
  }, [cardRef, valRef, unit, biased]);
  const selectUnt = () =>
    property.values.map((value) => (
      <option key={`${value?.value}_${value?.unit}`} value={value?.unit}>
        {stringToUnit(value.unit)}
      </option>
    ));
  const selectedValue = () => {
    const val = Number(property.values.find((value) => value?.unit === unit)?.value);
    return val ?? NaN;
  };
  return (
    <div
      key={property.name}
      className={`mb-2 property-card ${active ? 'active-card' : ''} ${
        biased ? 'ring-1 ring-red-500' : undefined
      }`}
      ref={cardRef}
      onClick={() => onClick(unit)}
    >
      <div>{property.name?.split(/(?=[A-Z])/).join(' ')}</div>
      <div
        className={'l'}
        ref={valRef}
        style={{
          transform: `scale(${shrink})`,
        }}
      >
        {pointDataValue(selectedValue(), property.name, unit)}
      </div>
      {property.values.length > 1 ? (
        <Select
          className={'observed-select'}
          sizing={'sm'}
          value={unit}
          onChange={(e) => {
            onClick(e.target.value);
            setUnit(e.target.value);
          }}
        >
          {selectUnt()}
        </Select>
      ) : (
        stringToUnit(property.values[0]?.unit)
      )}
    </div>
  );
};
