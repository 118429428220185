import { Select } from 'flowbite-react';
import { useDispatch, useSelector } from 'react-redux';

import { usePropertyGridActive } from '../../../../../hooks/usePropertyGridActive';
import { BorderDef, BorderStyleEnum } from '../../../../../model/definitions/BorderDef';
import { ActiveDef, setPropertyGridActiveHash } from '../../../../../store/slices/active-slice';
import { RootState } from '../../../../../store/store';
import InputNumber from '../../../../marketplace-new/atoms/FormatNumber/FormatNumber';
import { PaletteColorPicker } from '../../mapLayersProperties/PalettecolorPicker';

interface BorderProps {
  border: BorderDef;
  position: 'Left' | 'Right' | 'Top' | 'Bottom';
  onChange: (
    pos: 'Left' | 'Right' | 'Top' | 'Bottom',
    prop: keyof BorderDef,
    value: number | BorderStyleEnum | string,
  ) => void;
}
export const Border = ({ border, position, onChange }: BorderProps) => {
  const { color, width, style } = border ? border : new BorderDef();
  const { activeElement } = useSelector<RootState, ActiveDef>((state) => state.active);
  const dispatch = useDispatch();
  const { lastFocused } = usePropertyGridActive([]);
  function onFocus(path: string) {
    dispatch(setPropertyGridActiveHash({ activeElement, focusedEl: path }));
  }
  const borderOptions = Object.keys(BorderStyleEnum).map((style) => (
    <option key={style} value={style}>
      {style}
    </option>
  ));
  return (
    <>
      <div className={'grid-item-label'}>
        <label>{position}:</label>
      </div>
      <div className={'grid-item border-input'}>
        <InputNumber
          onFocus={() => onFocus(`border${position}`)}
          autoFocus={lastFocused === `border${position}`}
          value={width}
          onInputChange={(e) => onChange(position, 'width', e)}
        />
      </div>
      <div className={'grid-item '}>
        <Select value={style} onChange={(e) => onChange(position, 'style', e.target.value)}>
          {borderOptions}
        </Select>
      </div>
      <div className={'grid-item no-border-bg'}>
        <PaletteColorPicker value={color} onChange={(e) => onChange(position, 'color', e)} />
      </div>
    </>
  );
};
