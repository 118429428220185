import { differenceInDays } from 'date-fns';

import { DataFrameDef } from '../../../../../model/definitions/DataFrameDef';
import { SatelliteMapLayer } from '../../../../../model/definitions/SatelliteMapLayer';
import { FramesRange } from '../../../modals/FramesRange';
import { useGetSatelliteLayerData } from './mapTemplateApi/useGetSatelliteLayerData';

interface SatelliteRangeProps {
  source: SatelliteMapLayer;
  bounds: [number, number, number, number];
  setFrames: (id: string, frames: DataFrameDef[]) => void;
  layerNew?: SatelliteMapLayer;
}
const wrapper = 'flex flex-col justify-center items-center range-wrapper';
export const SatelliteRange = ({ source, setFrames, bounds, layerNew }: SatelliteRangeProps) => {
  const {
    id,
    name,
    dataFrames,
    satelliteSource: { satelliteSource, satelliteType, creationTime },
  } = source;
  dataFrames.sort((a, b) => a.timestamp - b.timestamp);
  const { data, isLoading, isError } = useGetSatelliteLayerData(
    satelliteType.id,
    satelliteSource.id,
    bounds,
  );
  const handleFrames = (layerFrames: Array<DataFrameDef>) => setFrames(id, layerFrames);
  return (
    <div className={'map-template-layer-wrapper'}>
      <h3>{name}</h3>
      <span>
        Default layer offset in days:
        {differenceInDays(dataFrames[0].timestamp * 1000, creationTime)}
      </span>
      <div className={wrapper}>
        <FramesRange
          selectedFrames={layerNew ? [...layerNew?.dataFrames] : []}
          layerType={'satellite'}
          isError={isError}
          isLoading={isLoading}
          frames={data}
          onRangeChange={handleFrames}
        />
      </div>
    </div>
  );
};
