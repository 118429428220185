import { NodeModel } from '@minoru/react-dnd-treeview';
import React from 'react';

import { ElementsEnum } from '../../../core/ui/enums/ElementsEnum';
import { LogicalGroupElement } from '../../../model/definitions/LogicalGroupElement';
import { PanelDefs } from '../../../model/UI/PanelDef';
import styles from './MultipleDragPreview.module.scss';

type Props = {
  dragSources: NodeModel<PanelDefs & LogicalGroupElement & { type: ElementsEnum }>[];
};

export const MultipleDragPreview: React.FC<Props> = (props) => {
  return (
    <div className={styles.badge} color="error">
      <div className={styles.root} data-testid="custom-drag-preview">
        {props.dragSources.map((node) => (
          <div className={styles.item} key={node.id}>
            <div className={styles.icon}></div>
            <div className={styles.label}>{node.text}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
