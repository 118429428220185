import { C9ProjectPreviewDTO } from '../DTO/C9ProjectPreviewDTO';
import { MarketplaceTemplateEnum } from '../enums/MarketplaceTemplateEnum';

export class MarketplaceItem {
  templateType: MarketplaceTemplateEnum = MarketplaceTemplateEnum.PROJECT_TEMPLATE;
  templateId = '';
  templateVersionId = '';
  name: string;
  description: string;
  availableFrom: number = new Date().getTime();
  availableTo: number | null = null;
  price?: number;
  tags: Array<string> = [];
  currency = 'eur';
  enterpriseAccountId: string;
  isSharedEntity: boolean;
  creator: string;
  constructor(
    data?: Partial<C9ProjectPreviewDTO> & {
      templateType: MarketplaceTemplateEnum;
    },
  ) {
    if (data?.templateType) {
      this.templateType = data.templateType;
    }
    if (data?.name) this.name = data.name;
    if (data?.description) this.description = data.description;
    if (data?.enterpriseAccountId) this.enterpriseAccountId = data.enterpriseAccountId;
    if (data?.isSharedEntity !== undefined) this.isSharedEntity = data.isSharedEntity;
    if (data?.creator) this.creator = data.creator;
  }
}
