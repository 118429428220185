import { DeleteFilled, FileZipOutlined } from '@ant-design/icons';
import { Button, Form, Modal, ModalProps, notification, Row } from 'antd';
import JSZip from 'jszip';
import { FC, useRef, useState } from 'react';

import { IconSetTemplateDef } from '../../../model/definitions/IconSetTemplateDef';
import { IconTemplateDef } from '../../../model/definitions/IconTemplateDef';
import { useAddSingleViaUpload } from '../queries-NEW/useAddSingleViaUpload';

export const handleZipData = async (data: any) => {
  try {
    const zip = new JSZip();
    await zip.loadAsync(data[0] as any);
    const fileArray: [string, JSZip.JSZipObject][] = Object.entries(zip.files);
    for (const [, entry] of fileArray) {
      if (entry.dir && entry.name.includes('2_main/')) {
        return true;
      }
    }
    return false;
  } catch (error) {
    console.error('Error processing the zip file:', error);
  }
};

interface SequenceModalProps extends ModalProps {
  template: IconSetTemplateDef;
  closeModal: () => void;
  addIcon: (e: IconTemplateDef) => void;
}
export const SequenceModal: FC<SequenceModalProps> = ({ open, template, addIcon, closeModal }) => {
  const { mutate: createIcons, isLoading } = useAddSingleViaUpload();
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<FileList | null>(null);
  const onFinish = async () => {
    const formData = new FormData();
    template && formData.append('iconSetTemplateId', template?.id);
    template && formData.append('iconSetTemplateVersionId', template?.versionId);
    template && formData.append('weatherTypeCodes', '');
    template && fileList && formData.append('itemName', fileList[0].name);
    if (fileList && fileList?.length > 0) {
      template && formData.append('file', fileList[0]);
    }
    createIcons(formData, {
      onSuccess: (icon) => {
        addIcon(icon);
        if (inputRef.current) inputRef.current.value = '';
        closeModal();
      },
    });
  };

  const onFinishFailed = () => notification.error({ message: 'failed uploading sequence' });
  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <Modal
      open={open}
      onOk={() => form.submit()}
      okButtonProps={{ loading: isLoading }}
      onCancel={() => {
        if (inputRef.current) inputRef.current.value = '';
        closeModal();
      }}
      destroyOnClose
    >
      <Form
        layout={'vertical'}
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
      >
        <Row>Select sequence (*.zip)</Row>
        <Row>
          <input
            className={'!hidden'}
            type={'file'}
            hidden
            ref={inputRef}
            onChange={(e) => {
              handleZipData(e.target.files).then((res) =>
                res ? setFileList(e.target.files) : alert("Folder '2_main' not found!"),
              );
            }}
          />
          {!fileList?.length ? (
            <Button
              icon={<FileZipOutlined style={{ fontSize: 60 }} type={'dashed'} />}
              style={{
                height: 100,
                width: 100,
              }}
              onClick={() => inputRef.current?.click()}
            />
          ) : (
            <div className={'flex justify-between w-full h-20 items-center'}>
              <div>{fileList[0].name}</div>
              <DeleteFilled
                className={'text-red-500 cursor-pointer'}
                onClick={() => setFileList(null)}
              />
            </div>
          )}
        </Row>
      </Form>
    </Modal>
  );
};
