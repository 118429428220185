import { useSelector } from 'react-redux';

import { getProjectDuration, parseScenes } from '../../../helpers/timelineUtil';
import { AudioElement } from '../../../model/definitions/AudioElement';
import { C9ProjectDef } from '../../../model/definitions/C9ProjectDef';
import { selectSkips, selectVoiceOver } from '../../../store/slices/selectors';
import { RootState } from '../../../store/store';
import { Board } from './components/board/Board';
import { Marker } from './components/marker/Marker';
import { VoiceOverLane } from './components/voice-over/VoiceOverLane';

export const StoryBoard = () => {
  const project = useSelector<RootState>((state) => state.project.present.project) as C9ProjectDef;
  const skips = useSelector<RootState, SkipTimeDef[]>((state) => selectSkips(state));
  const voiceOver = useSelector<RootState, AudioElement>((state) => selectVoiceOver(state));
  const duration = getProjectDuration(project);
  const isStoryboardHoverActive = useSelector<RootState>(
    (state) => state.project.present.isStoryboardHoverActive,
  );
  const storyboardScenes = useSelector<RootState>(
    (state) => state.project.present.storyboardSceneDefs,
  );
  const storyboardProject = { ...project, sceneDefs: storyboardScenes } as C9ProjectDef;
  const storyboardProjectScenes = parseScenes(storyboardProject);
  const scenes = parseScenes(project);
  return (
    <>
      <Board
        duration={duration}
        scenes={isStoryboardHoverActive ? storyboardProjectScenes : scenes}
        originalScenesOrder={scenes.map((x) => x.id)}
      />
      <VoiceOverLane
        value={voiceOver?.timeControls}
        duration={duration}
        key={voiceOver?.id}
        videoOver={false}
      />
      <VoiceOverLane
        videoOver={true}
        value={project.videoOver?.timeControls}
        duration={duration}
        key={project.videoOver?.id}
      />
      <Marker value={skips} duration={duration} />
    </>
  );
};
