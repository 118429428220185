import { ArrowLeftOutlined, ExclamationCircleFilled, PlusOutlined } from '@ant-design/icons';
import { Button, Modal, Table, Tag } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { DataProductInterface } from '../../../dataprovider/pages/Products';
import { useDeleteAssignedDataProduct } from '../../queries-NEW/useDeleteAssignedDataProduct';
import { useGetAccount } from '../../queries-NEW/useGetAccount';
import { useGetAssignedDataProducts } from '../../queries-NEW/useGetAssignedDataProducts';
import { AssignDataProductsModal } from './AssignDataProductsModal';

const { confirm } = Modal;

export const EnterpriseDetails = () => {
  const { id } = useParams();
  const { data: user } = useGetAccount('enterprise-accounts', id ?? '');
  const { data, isLoading } = useGetAssignedDataProducts(id ?? '');
  const [open, setOpen] = useState<boolean>(false);
  const { mutate: deleteDataProduct } = useDeleteAssignedDataProduct();
  const columns = [
    {
      title: 'Image',
      dataIndex: 'thumbnailUrls',
      key: 'thumbnailUrls',
      render: (thumbnailUrls: string) => (
        <img
          src={
            thumbnailUrls[0]?.indexOf('no%20audio') === -1
              ? thumbnailUrls[0]
              : 'https://media.istockphoto.com/vectors/no-thumbnail-image-vector-graphic-vector-id1147544806?k=20&m=1147544806&s=170667a&w=0&h=5rN3TBN7bwbhW_0WyTZ1wU_oW5Xhan2CNd-jlVVnwD0='
          }
        />
      ),
      width: 100,
    },
    {
      title: 'Base Name',
      dataIndex: 'baseName',
      key: 'baseName',
    },
    {
      title: 'Product Type',
      dataIndex: 'productType',
      key: 'productType',
      render: (productType: string) => (
        <Tag
          color={
            productType === 'SATELLITE' ? 'purple' : productType === 'MODEL' ? 'orange' : 'cyan'
          }
        >
          {productType}
        </Tag>
      ),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (_: string, product: DataProductInterface) => (
        <Button
          danger
          shape="round"
          onClick={() => {
            if (product.id) {
              handleDelete(product.id);
            }
          }}
        >
          Delete
        </Button>
      ),
    },
  ];

  const handleDelete = (dataProductId: string) => {
    confirm({
      title: 'Delete product',
      icon: <ExclamationCircleFilled />,
      content: 'Are you sure you want to delete product from this enterprise?',
      centered: true,
      okText: 'Delete',
      okType: 'danger',
      onOk() {
        deleteDataProduct({ enterpriseAccountID: id ? id : '', dataProductId });
      },
    });
  };

  return (
    <>
      <Link to="/workspace/account-management/enterprise/">
        <ArrowLeftOutlined /> Go Back
      </Link>
      <div className={'ws-temp-title'}>
        <h1>Enterprise account info</h1>
      </div>
      <div className="flex flex-col gap-2 text-lg bg-gray-100 border rounded-lg p-4 w-fit">
        <p>
          Name: <b>{user?.name}</b>
        </p>
        <p>
          Description: <b>{user?.description}</b>
        </p>
        <p>
          Owner email: <b>{user?.ownerEmail}</b>
        </p>
        <p>
          Created at: <b>{moment(user?.createdAt).format('DD-MM-YYYY')}</b>
        </p>
      </div>
      <div className={'flex ws-fixed-header mt-2'}>
        <div className={'ws-temp-title'}>
          <h4 className="text-2xl">Data products</h4>
        </div>
        <Button
          type="primary"
          icon={
            <span style={{ marginRight: '0.5rem' }}>
              <PlusOutlined />
            </span>
          }
          onClick={() => setOpen(true)}
          className="px-4"
        >
          Add Product
        </Button>
      </div>
      <Table dataSource={data} columns={columns} rowKey="id" loading={isLoading} />
      <AssignDataProductsModal
        data={data}
        isLoading={isLoading}
        enterpriseId={id ?? ''}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
};
