import { useQuery } from 'react-query';

import { axiosInstance } from '../../../core/api/axiosInstance';

export const key = ['VALIDATE_FILE'];

export const useValidateFile = (id?: number) =>
  useQuery(
    [...key, id],
    async () => {
      const { data } = await axiosInstance.get(`data-product-files/retry/${id}`);
      return data;
    },
    {
      enabled: !!id,
    },
  );
