import { Button } from 'flowbite-react/lib/esm/components/Button';
import React from 'react';

import noMapImg from '../../../assets/images/no-map.jpg';
import { MapPanelDef } from '../../../model/definitions/MapPanelDef';
import { MapStylesType } from '../../../molecules/mapElement/MapStylesHash';

type FilteredMaps = {
  id: string;
  name: string;
  imgSrc: string;
  imgSrcPreview: string;
}[];
interface Props {
  currentMapType?: string;
  changeType: (
    path: Leaves<MapPanelDef>,
    type: keyof MapStylesType | string,
    mapStyleId: string,
  ) => void;
  filteredMaps: FilteredMaps;
}

const MapTypes: React.FC<Props> = ({ changeType, currentMapType, filteredMaps }) => {
  return (
    <div className="map-buttons-wrapper">
      {filteredMaps &&
        filteredMaps.map((map) => {
          if (map.name !== 'SATELLITE MT')
            return (
              <div
                key={map.id}
                onClick={() => changeType('mapType', map.name, map.id)}
                className={`map-btn ${currentMapType === map.name ? 'active' : ''}`}
              >
                <Button>
                  {
                    <img
                      alt={map.name}
                      width={'100%'}
                      height={'100%'}
                      src={
                        map.imgSrcPreview === null || map.imgSrcPreview === ''
                          ? noMapImg
                          : map.imgSrcPreview
                      }
                    />
                  }
                  <div>
                    <p>{map.name}</p>
                  </div>
                </Button>
              </div>
            );
        })}
    </div>
  );
};

export default MapTypes;
