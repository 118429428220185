import React from 'react';

import { AnimationsEnum } from '../../model/enums/AnimationsEnum';

interface Props {
  children: JSX.Element;
  inType: AnimationsEnum;
  outType: AnimationsEnum;
  inOptions: any;
  outOptions: any;
}

const Transition: React.FC<Props> = ({ children, inType, inOptions, outType, outOptions }) => {
  function getInOpacity() {
    switch (inType) {
      case AnimationsEnum.CUT:
        return 1;
      case AnimationsEnum.FADE_IN: {
        const finalTime = inOptions.enterTime + inOptions.duration;
        const remaining = finalTime - inOptions.currentTime;
        return Math.min(Math.max((100 - (remaining * 100) / inOptions.duration) / 100, 0), 1);
      }
    }
  }
  function getOutOpacity() {
    switch (outType) {
      case AnimationsEnum.CUT:
        return 1;
      case AnimationsEnum.FADE_OUT: {
        let remaining = outOptions.exitTime - outOptions.currentTime;
        remaining = (remaining * 100) / outOptions.duration / 100;
        return Math.min(Math.max(remaining, 0), 1);
      }
    }
  }
  return (
    <div
      style={{
        height: '100%',
        opacity: getOutOpacity(),
      }}
    >
      <div
        style={{
          height: '100%',
          opacity: getInOpacity(),
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default Transition;
