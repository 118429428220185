import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MapPositionControlDef } from '../../model/definitions/MapPositionControlDef';

export interface CurrentMapState {
  mapState: MapPositionControlDef;
}
const initialState: CurrentMapState = {
  mapState: {
    latitude: 0,
    longitude: 0,
    zoom: 0,
    bearing: 0,
    pitch: 0,
    defaultZoom: 0,
  },
};
const mapStateSlice = createSlice({
  name: 'mapState',
  initialState,
  reducers: {
    updateMapState(state, action: PayloadAction<{ mapState: MapPositionControlDef }>) {
      const {
        payload: { mapState },
      } = action;
      state.mapState = mapState;
    },
  },
});

export const { updateMapState } = mapStateSlice.actions;
export default mapStateSlice.reducer;
