import { useQuery } from 'react-query';

import { axiosInstance } from './axiosInstance';

export const key = ['GET_FONT'];

export const useGetFont = (id: string) => {
  return useQuery(
    [...key, id],
    async () => {
      const res = await axiosInstance.get(`font-variant/font/${id}`);
      return res.data;
    },
    { enabled: !!id, staleTime: Infinity },
  );
};
