import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { queryClient } from '../../..';
import { axiosInstance } from '../../../core/api/axiosInstance';
import { key } from './useGetSqsConfigs';

export const useEditSqsConfig = () => {
  return useMutation(
    async (data: {
      id: string;
      accessKey: string;
      secretKey: string;
      region: string;
      queueName: string;
    }) => {
      return await axiosInstance.put(`sqs-configs`, data);
    },
    {
      onSuccess: () => {
        toast.success('SQS config edited successfully!');
        queryClient.invalidateQueries(key);
      },
      onError: () => {
        toast.error('Error editing SQS config!');
      },
    },
  );
};
