import { useMutation } from 'react-query';

import { axiosInstance } from '../../../core/api/axiosInstance';
import { IconTemplateDef } from '../../../model/definitions/IconTemplateDef';

export const useAddSingleViaUpload = () =>
  useMutation(async (form: FormData): Promise<IconTemplateDef> => {
    const { data } = await axiosInstance.post('multimedia/add-icon-set-item', form, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return data;
  });
