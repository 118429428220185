import { groupBy } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { v4 } from 'uuid';

import Input from '../../../../atoms/input/Input';
import LoadingIndicator from '../../../../atoms/loadingIndicator/LoadingIndicator';
import { useGetForecastData } from '../../../../core/api/forecastData/useGetForecastData';
import { useGetLocations } from '../../../../core/api/forecastData/useGetLocations';
import { useDebounce } from '../../../../hooks/useDebounce';
import { ForecastWDElementDef } from '../../../../model/definitions/ForecastWDElementDef';
import { ForecastWDLocationDef } from '../../../../model/definitions/ForecastWDLocationDef';
import { WeatherPosterDef } from '../../../../model/definitions/WeatherPosterDef';
import { ForecastDateGroup } from './ForecastDateGroup';

interface ForecastGeoPosterProps {
  template: WeatherPosterDef;
  updateAvailableDates: (dates: Array<Date> | undefined) => void;
  time: Date;
  updatePoster: (poster: WeatherPosterDef) => void;
  poster: WeatherPosterDef;
}

export const ForecastGeoPoster = ({
  template,
  updateAvailableDates,
  time,
  updatePoster,
  poster,
}: ForecastGeoPosterProps) => {
  const location = template.forecastWDElements.map((item) => item.forecastWDSource.location.name);
  const pos = [...new Set(location)][0];
  const [searchKey, setSearchKey] = useState(pos);
  //const [icon] = useState<any>(undefined);
  const debounceSearch = useDebounce(searchKey);
  const [selected, setSelected] = useState<ForecastWDLocationDef>();
  const [station, setStation] = useState<string>(pos);
  const { data: stations, isLoading } = useGetLocations(debounceSearch);
  const getBorders = () => {
    if (searchKey && (station !== searchKey.toUpperCase() || station !== searchKey.toUpperCase()))
      return { borderBottomLeftRadius: 0, borderBottomRightRadius: 0 };
  };
  const { data } = useGetForecastData(station);
  const availableData = () => {
    const daily = data?.daily.map((item) => new Date(item.utcDate));
    const intra = data?.intraDay.map((item) => new Date(item.utcDate));
    if (daily && intra) return [...daily, ...intra];
  };
  useEffect(() => {
    updateAvailableDates(availableData());
  }, [data]);
  const handleStation = (val: ForecastWDLocationDef) => {
    setSearchKey(val.locationId);
    setStation(val.locationId);
    setSelected(val);
  };
  const zeroDate = Math.min(
    ...template.forecastWDElements.map((forecast) =>
      new Date(forecast.forecastWDSource.utcDate).getTime(),
    ),
  );
  const updateThisPoster = (item: ForecastWDElementDef) => {
    const index = template.forecastWDElements.findIndex((point) => point.id === item.id);
    template.forecastWDElements[index] = item;
    template.name = item.forecastWDSource.location.name;
    template.pointLocation = template.pointLocation.map((tempLoc) => {
      return {
        ...tempLoc,
        id: v4(),
        name: selected?.name ?? tempLoc.name,
        maskValue: selected?.name ?? tempLoc.name,
        value: `${selected?.name} ${selected?.locationId}` ?? tempLoc.value,
        description:
          `${selected?.name} ${selected?.locationId} ${selected?.country}` ?? tempLoc.description,
      };
    });
    updatePoster(template);
  };
  const inputRef = useRef<HTMLInputElement>(null);
  const dt = groupBy(template.forecastWDElements, 'forecastWDSource.utcDate');
  const renderData = () => {
    const topGroups = [];
    for (const date in dt) {
      topGroups.push(
        <ForecastDateGroup
          station={station}
          poster={poster}
          key={date}
          template={template}
          time={time}
          data={dt[date]}
          values={data}
          date={date}
          offset={zeroDate}
          updatePoster={updateThisPoster}
        />,
      );
    }
    return topGroups;
  };
  return (
    <div className="station">
      <div className="relative location-wrapper">
        <Input
          ref={inputRef}
          label={'Select Station'}
          required
          value={pos}
          onChange={(e) => {
            setSearchKey(e.target.value);
          }}
          style={getBorders()}
        />
        {searchKey && station?.trim().toUpperCase() !== searchKey?.trim().toUpperCase() && (
          <div className={'search-result items-center'}>
            {isLoading ? (
              <div className={'flex w-1/2 loading'}>
                <div className={'flex items-center mr-3'}>
                  <LoadingIndicator />
                </div>
                Searching
              </div>
            ) : searchKey && station.trim().toUpperCase() !== searchKey.trim().toUpperCase() ? (
              stations?.map((stat) => (
                <div
                  key={stat.locationId}
                  onClick={() => {
                    handleStation(stat);
                  }}
                  className={'result'}
                >
                  {stat.name} {stat.locationId}
                </div>
              ))
            ) : (
              <div className={'flex justify-center items-center'}>no results</div>
            )}
          </div>
        )}
      </div>
      <div>{renderData()}</div>
    </div>
  );
};
