import { AxiosError } from 'axios';
import { QueryKey, useQuery, UseQueryResult } from 'react-query';

import { axiosInstance } from '../../../core/api/axiosInstance';
import { C9ProjectPreviewDTO } from '../../../model/DTO/C9ProjectPreviewDTO';
import { QueryInfoMetadata } from '../../../model/other/QueryInfoMetadata';

export const key: QueryKey = ['GET_MULTIMEDIA'];

const useGetMultimedia = ({
  page,
  type,
  size = 20,
  searchKey,
  sort,
  activeTab,
}: {
  page: number;
  type: 'video' | 'audio' | 'image' | 'custom_map' | 'animation' | 'symbol' | 'custom_vector';
  size?: number;
  searchKey?: string;
  sort?: string;
  activeTab?: string;
}): UseQueryResult<QueryInfoMetadata<C9ProjectPreviewDTO>, AxiosError> => {
  const isEnabled =
    Boolean(type) &&
    (type.toUpperCase() === 'AUDIO' ||
      type.toUpperCase() === 'VIDEO' ||
      type.toUpperCase() === 'IMAGE' ||
      type.toUpperCase() === 'CUSTOM_MAP' ||
      type.toUpperCase() === 'CUSTOM_VECTOR' ||
      type.toUpperCase() === 'ANIMATION' ||
      type.toUpperCase() === 'SYMBOL');
  return useQuery(
    [...key, page, searchKey, type, sort],
    async () => {
      const { data } = await axiosInstance.get('multimedia/preview', {
        params: {
          page,
          size,
          searchKey,
          type: type.toUpperCase(),
          sort,
          isShared: activeTab === 'ORGANIZATION',
        },
      });
      return data;
    },
    { enabled: isEnabled },
  );
};

export default useGetMultimedia;
