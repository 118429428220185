import { forwardRef, Ref } from 'react';
import { BsArrowRightShort } from 'react-icons/bs';
import { Link } from 'react-router-dom';

interface CardInterface {
  title: string;
  description: string;
  image: string;
  link: string;
}

const Card = forwardRef(({ title, description, image, link }: CardInterface, ref: Ref<any>) => {
  return (
    <div
      ref={ref}
      className="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700"
    >
      <img className="rounded-t-lg" src={image} alt={image} />
      <div className="p-5">
        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
          {title}
        </h5>
        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">{description}</p>
        <Link
          to={link}
          className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Go to page
          <BsArrowRightShort className="ml-2 -mr-1" size={22} />
        </Link>
      </div>
    </div>
  );
});
Card.displayName = 'MyComponent';

export default Card;
