import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { Tooltip } from 'flowbite-react';
import { cloneDeep } from 'lodash';
import { Fragment, useEffect, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import { BiCut, BiMapAlt } from 'react-icons/bi';
import { BsFillSquareFill } from 'react-icons/bs';
import { VscTrash } from 'react-icons/vsc';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../../atoms/button/Button';
import { ElementsEnum } from '../../../core/ui/enums/ElementsEnum';
import { findMaxInArray } from '../../../helpers/timelineUtil';
import { C9ProjectDef } from '../../../model/definitions/C9ProjectDef';
import { ForecastWDElementDef } from '../../../model/definitions/ForecastWDElementDef';
import { KeyFramesDef } from '../../../model/definitions/KeyFramesDef';
import { MapPanelDef } from '../../../model/definitions/MapPanelDef';
import { clearCoordinateFromCache } from '../../../molecules/mapElement/DrawingSelectedPointsCache';
import {
  ActiveDef,
  addToMultiselect,
  removeDisplayedFlyOvers,
  setActiveDrawing,
  setActiveMap,
  setDisplayedFlyOvers,
  setElement,
  setLayerType,
  setSymbolEditingLayerId,
  setSyncSpace,
} from '../../../store/slices/active-slice';
import { CurrentMapState } from '../../../store/slices/map-state.slice';
import {
  addKeyFrame,
  deleteGeoPoster,
  setSpaceSync,
  toggleGeoPoster,
  toggleGeoPosterElement,
  updateMapForecastLayer,
  updateMapLayer,
} from '../../../store/slices/project-slice';
import { RootState } from '../../../store/store';
import { AllElementsDefs, ElementType } from '../../../types/elements';
import { keyToElementsEnum } from '../helpers';
import { pointForLane, splitArrayByNestedProperty } from '../laneRendering';
import { LoadLayerFramesButton } from './LoadLayerFramesButton';
import { PosterElementList } from './PosterElementList';
import RegularListElement from './RegularListElement';

interface MapListElementProps {
  item: MapPanelDef;
  deleteElement: () => void;
  cut: () => void;
  select?: boolean;
  setSelect?: (e: boolean) => void;
  paddingLeft?: any;
  onClick?: (e: any, id?: string) => void;
  onDragEnd?: () => void;
  onDragStart?: () => void;
  enabled: boolean;
  enable?: (e: boolean, id: string | number, elementType: string) => void;
}
const MapListElement = ({
  item,
  deleteElement,
  cut,
  select,
  setSelect,
  paddingLeft,
  onClick,
  onDragEnd,
  onDragStart,
  enabled,
  enable,
}: MapListElementProps) => {
  const project = useSelector<RootState, C9ProjectDef>((state) => state.project?.present?.project);
  const dispatch = useDispatch();
  const { activeElement, activeTime, activeScene, syncSpace, activeProp, multiselect } =
    useSelector<RootState>((state) => state.active) as ActiveDef;
  const elemSection = multiselect.find((section) => section.type === 'mapPanels');
  const drawingSelection = multiselect.find((section) => section.type === 'drawingElements');
  const selected = elemSection?.elements.find((listItem) => listItem.element.id === item.id);
  const selectedDrawing = (id: string) =>
    drawingSelection?.elements.find((listItem) => listItem.element.id === id)
      ? 'active'
      : 'inactive';
  const scene = project.sceneDefs.find((scene) => scene.id === activeScene);
  //@ts-ignore
  const elem = scene![activeProp as ElementType]?.find((elmnt) => elmnt.id === activeElement);
  const [open, setOpen] = useState<boolean>(item.flyOverEnabled);
  function onMapLayerChange(propertyPath: Paths<MapPanelDef>, newValue: any) {
    dispatch(
      updateMapLayer({
        activeScene,
        newValue,
        elementId: item.id,
        propertyPath,
      }),
    );
  }

  const enableGribLayer = (e: any, id: string) => {
    const checked = e.target.checked;
    const layersToEdit = cloneDeep(item.wdSpace[0].gribMapLayers);
    const layerToEnable = layersToEdit.find((layer) => layer.gribSource.id === id);
    if (layerToEnable) layerToEnable.enabled = checked;
    // @ts-ignore
    onMapLayerChange('wdSpace[0].gribMapLayers', layersToEdit);
  };
  function onIdentify(featureId: string, layerId: string) {
    clearCoordinateFromCache({ mapId: item.id, layerId: layerId });
    dispatch(setActiveDrawing({ featureId, drawingId: layerId }));
  }
  const enableRadarLayer = (e: any, id: string) => {
    const checked = e.target.checked;
    const layersToEdit = cloneDeep(item.wdSpace[0].radarMapLayers);
    const layerToEnable = layersToEdit.find((layer) => layer.radarSource.id === id);
    if (layerToEnable) layerToEnable.enabled = checked;
    // @ts-ignore
    onMapLayerChange('wdSpace[0].radarMapLayers', layersToEdit);
  };
  const enableSatelliteLayer = (e: any, id: string) => {
    const checked = e.target.checked;
    const layersToEdit = cloneDeep(item.wdSpace[0].satelliteMapLayers);
    const layerToEnable = layersToEdit.find((layer) => layer.satelliteSource.id === id);
    if (layerToEnable) layerToEnable.enabled = checked;
    // @ts-ignore
    onMapLayerChange('wdSpace[0].satelliteMapLayers', layersToEdit);
  };
  const enableSymbolLayer = (e: any, id: string) => {
    const checked = e.target.checked;
    const layersToEdit = cloneDeep(item.wdSpace[0].symbolLayers);
    const layerToEnable = layersToEdit.find((layer) => layer.id === id);
    if (layerToEnable) {
      layerToEnable.enabled = checked;
      !layerToEnable.enabled && dispatch(setSymbolEditingLayerId(''));
    }
    // @ts-ignore
    onMapLayerChange('wdSpace[0].symbolLayers', layersToEdit);
  };
  const removeGribLayer = (id: string) => {
    const remainingLayers = item.wdSpace[0].gribMapLayers.filter(
      (layer) => layer.gribSource.id !== id,
    );
    // @ts-ignore
    onMapLayerChange('wdSpace[0].gribMapLayers', remainingLayers);
  };
  const removeSymbolLayer = (id: string) => {
    const remainingLayers = item.wdSpace[0].symbolLayers.filter((layer) => layer.id !== id);
    // @ts-ignore
    onMapLayerChange('wdSpace[0].symbolLayers', remainingLayers);
  };
  const removeObservedLayers = (id: string) => {
    const remainingLayers = item.wdSpace[0].observedDataLayers.filter((layer) => layer.id !== id);
    // @ts-ignore
    onMapLayerChange('wdSpace[0].observedDataLayers', remainingLayers);
  };
  const removeForecastLayers = (ids: string[]) => {
    function removeObjects(
      originalArray: ForecastWDElementDef[],
      idsToRemove: string[],
    ): ForecastWDElementDef[] {
      return originalArray.filter((obj) => !idsToRemove.includes(obj.id));
    }
    const remainingLayers = removeObjects(item.wdSpace[0].forecastDataLayers, ids);
    // @ts-ignore
    onMapLayerChange('wdSpace[0].forecastDataLayers', remainingLayers);
  };
  const removeDrawing = (id: string) => {
    const remainingLayers = item.drawingElements.filter((layer) => layer.id !== id);
    onMapLayerChange('drawingElements', remainingLayers);
  };
  const removeRadarLayers = (id: string) => {
    const remainingLayers = item.wdSpace[0].radarMapLayers.filter(
      (layer) => layer.radarSource.id !== id,
    );
    // @ts-ignore
    onMapLayerChange('wdSpace[0].radarMapLayers', remainingLayers);
  };
  const removeSatelliteLayers = (id: string) => {
    const remainingLayers = item.wdSpace[0].satelliteMapLayers.filter(
      (layer) => layer.satelliteSource.id !== id,
    );
    // @ts-ignore
    onMapLayerChange('wdSpace[0].satelliteMapLayers', remainingLayers);
  };
  useHotkeys(
    'shift+ctrl+delete, shift+command+delete',
    (ev) => {
      ev.preventDefault();
      if (activeElement === item.id) deleteElement();
    },
    [deleteElement, activeElement, item],
  );
  useHotkeys(
    'shift+ctrl+x, shift+command+x',
    (ev) => {
      ev.preventDefault();
      if (activeElement === item.id) cut();
    },
    [cut, activeElement, item],
  );
  const closeLane = (val: boolean) => {
    val
      ? dispatch(setDisplayedFlyOvers({ displayedFlyOver: item.id! }))
      : dispatch(removeDisplayedFlyOvers({ displayedFlyOvers: item.id! }));
    setOpen(val);
  };
  useEffect(() => {
    setOpen(
      item.flyOverEnabled ||
        item?.wdSpace[0].gribMapLayers.length > 0 ||
        item?.wdSpace[0].radarMapLayers.length > 0 ||
        item?.wdSpace[0].satelliteMapLayers.length > 0 ||
        item?.wdSpace[0].symbolLayers?.length > 0 ||
        item?.drawingElements.length > 0,
    );
  }, [
    item.flyOverEnabled,
    item?.wdSpace[0].gribMapLayers.length,
    item?.wdSpace[0].radarMapLayers.length,
    item?.wdSpace[0].satelliteMapLayers.length,
    item?.wdSpace[0].symbolLayers?.length,
    item?.drawingElements.length,
  ]);
  useEffect(() => {
    open && dispatch(setDisplayedFlyOvers({ displayedFlyOver: item.id! }));
  }, [open]);

  const map = useSelector<RootState>((state) => state.mapState) as CurrentMapState;
  function createFlyOverKeyFrame(transition?: string) {
    let keyFrame = new KeyFramesDef();
    keyFrame = {
      ...keyFrame,
      mapPositionControl: { ...map.mapState },
      timeInMS: activeTime,
      transitionTilt: 0,
      transitionType: transition ?? 'LINEAR',
    };
    dispatch(
      addKeyFrame({
        activeScene: activeScene as string,
        elementId: activeElement,
        keyFrame: keyFrame,
      }),
    );
    setSelect && setSelect(false);
  }

  const deletePoster = () =>
    dispatch(deleteGeoPoster({ activeScene, mapId: item.id, elementId: activeElement }));

  function onToggleDrawing(event: CheckboxChangeEvent, drawingId: string) {
    const newDraws = item.drawingElements.map((d) => {
      if (d.id === drawingId) return { ...d, enabled: event.target.checked };
      return { ...d };
    });

    dispatch(
      updateMapLayer({
        activeScene,
        elementId: item.id,
        newValue: newDraws,
        propertyPath: 'drawingElements',
      }),
    );
  }

  const openLayerProp = (
    mapId: string,
    layerType: 'grib' | 'radar' | 'satellite' | 'symbol',
    layerId: string,
  ) => {
    dispatch(
      setElement({
        activeElement: mapId,
        activeProp: 'mapPanels',
      }),
    );
    dispatch(
      setLayerType({
        mapId,
        layerType,
        layerId,
      }),
    );
    if (layerType === 'symbol') {
      const layersToEdit = cloneDeep(item.wdSpace[0].symbolLayers);
      const layerToEnable = layersToEdit.find((layer) => layer.id === layerId);
      layerToEnable?.enabled && dispatch(setSymbolEditingLayerId(layerId));
    }
  };
  const toggleGeo = (elemEnabled: boolean, elementId: string) => {
    dispatch(toggleGeoPoster({ activeScene, elementId, mapId: item.id, enabled: elemEnabled }));
  };
  const toggleForecastLayer = (
    elements: ForecastWDElementDef[],
    enabled: boolean,
    mapId: string,
  ) => {
    const newElements = elements.map((point) => {
      return {
        ...point,
        enabled,
      };
    });
    dispatch(updateMapForecastLayer({ mapId, elements: newElements, activeScene }));
  };
  const toggleElement = (
    enabled: boolean,
    elementId: string,
    elementType: ElementsEnum,
    posterId: string,
  ) => {
    dispatch(
      toggleGeoPosterElement({
        activeScene,
        elementId,
        mapId: item.id,
        elementType,
        posterId,
        enabled,
      }),
    );
  };

  useEffect(() => {
    syncSpace.includes(item.wdSpace[0].id) &&
      dispatch(setSyncSpace({ spaceId: item.wdSpace[0].id }));
  }, [item.wdSpace[0].forecastDataLayers.length]);

  return (
    <div
      style={{ paddingLeft: `${10 + paddingLeft}px` }}
      key={item.id}
      onClick={(e) => onClick && onClick(e, item.id)}
      className={
        'timeline-map-wrapper w-full justify-end flex flex-wrap border-b-amber-50' +
        (activeElement === item.id || selected ? 'active' : '')
      }
      onDragEnd={onDragEnd}
      onDragStart={onDragStart}
      draggable={true}
    >
      <div
        className={'sequence-title map'}
        onClick={(e) => {
          if (e.metaKey || e.ctrlKey) {
            if (elem) {
              dispatch(
                addToMultiselect({
                  element: { type: activeProp as ElementType, element: elem as AllElementsDefs },
                }),
              );
            }
            dispatch(
              addToMultiselect({
                element: { type: 'mapPanels', element: item as AllElementsDefs },
              }),
            );
          } else
            dispatch(
              setElement({
                activeElement: item.id,
                activeProp: 'mapPanels',
              }),
            );
        }}
      >
        {enable && (
          <Checkbox
            checked={enabled}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onChange={(checked) => enable(checked.target.checked, item.id, item.elementType)}
            style={{ marginRight: '0.3rem' }}
          />
        )}
        <div className="icon-title">
          <BiMapAlt />
          <span style={{ marginLeft: '5px' }}>{item.name ?? 'new element'}</span>
        </div>
        {activeElement === item.id && (
          <div className="flex items-center gap-1">
            <Tooltip content="Cut" style={`dark`}>
              <Button
                size={'small'}
                className={'hoverState'}
                icon={<BiCut style={{ color: 'white', fontSize: 14 }} />}
                onClick={() => cut()}
                disabled={!activeElement}
              />
            </Tooltip>
            <Tooltip content="Delete" style={`dark`}>
              <Button
                size={'small'}
                icon={<VscTrash style={{ color: 'white', fontSize: 14 }} />}
                className={'hoverState text-red-500'}
                onClick={() => deleteElement()}
                disabled={!activeElement}
              />
            </Tooltip>
          </div>
        )}
        {item.flyOverEnabled ||
        item.wdSpace[0].gribMapLayers.length ||
        item.wdSpace[0].radarMapLayers.length ||
        item.wdSpace[0].satelliteMapLayers.length ||
        item.wdSpace[0].symbolLayers?.length ||
        item.wdSpace[0].observedDataLayers.length ||
        item.wdSpace[0].forecastDataLayers.length ||
        item.drawingElements.length ||
        item.geoPosters.length ? (
          <div onClick={() => closeLane(!open)}>
            {open ? <AiOutlineMinus /> : !open ? <AiOutlinePlus /> : null}
          </div>
        ) : null}
      </div>
      <div className={'sub-wrapper w-100'}>
        {open && item.flyOverEnabled && item.id === activeElement && (
          <div className={`sub-item open`} key={`${item.id}-flyover`}>
            <span className="map-sub-element">Flyover keyframes</span>
            <Button
              disabled={findMaxInArray(item.timeControls!).endMS < activeTime}
              size={'small'}
              className={'timeline-button'}
              onClick={() => setSelect && setSelect(true)}
              title="Click to select Fly over transition type"
              icon={
                <BsFillSquareFill
                  title="Click to select Fly over transition type"
                  style={{
                    transform: 'rotate(45deg)',
                    color: '#1ad598',
                    fontSize: 6,
                  }}
                />
              }
            />
          </div>
        )}
        {open &&
          item.drawingElements.map((drawing) => (
            <div
              key={drawing.id}
              className={`sequence-title  ${selectedDrawing(drawing.id)} ${
                JSON.parse(drawing.drawingGeoJson).features[0].properties.featureId ===
                activeElement
                  ? 'active'
                  : 'inactive'
              }`}
              onClick={(e) => {
                if (e.metaKey || e.ctrlKey) {
                  dispatch(
                    addToMultiselect({
                      element: {
                        type: 'drawingElements',
                        element: drawing as AllElementsDefs,
                        mapId: item.id,
                      },
                    }),
                  );
                } else {
                  onIdentify(
                    JSON.parse(drawing.drawingGeoJson).features[0].properties.featureId,
                    drawing.id,
                  );
                  dispatch(
                    setElement({
                      activeElement: JSON.parse(drawing.drawingGeoJson).features[0].properties
                        .featureId,
                      activeProp: 'drawLayer',
                    }),
                  );
                  dispatch(
                    setActiveMap({
                      mapId: item.id,
                    }),
                  );
                }
              }}
            >
              <span
                className="map-sub-element"
                title={`${drawing.name ? drawing.name : 'Drawing'}`}
              >
                <Checkbox
                  checked={drawing.enabled}
                  onChange={(event) => onToggleDrawing(event, drawing.id)}
                  style={{ marginRight: '0.3rem' }}
                />
                {drawing.name}
              </span>
              <button
                className={'hoverState text-red-500'}
                onClick={() => removeDrawing(drawing.id)}
              >
                <VscTrash />
              </button>
            </div>
          ))}
        {item?.wdSpace.map((space) =>
          open &&
          (space.satelliteMapLayers.length ||
            space.gribMapLayers.length ||
            space.radarMapLayers.length ||
            space.symbolLayers?.length ||
            space.observedDataLayers.length ||
            space.forecastDataLayers.length ||
            space.vectorMapLayers.length) ? (
            <Fragment key={space.id}>
              <div className={`sequence-title map sub-item open layer`}>
                <span title="Timer" style={{ maxWidth: 250, display: 'flex' }}>
                  Layer sync mode
                  <Button
                    onClick={() => {
                      dispatch(setActiveMap({ mapId: item.id }));
                      dispatch(setSyncSpace({ spaceId: space.id }));
                      dispatch(
                        setSpaceSync({
                          spaceId: space.id,
                          sync: !space.layerSync,
                          activeScene,
                          mapId: item.id,
                        }),
                      );
                    }}
                    label={space.layerSync ? 'Auto-sync' : 'Free set'}
                    className={`sync-button ${space.layerSync ? 'synced' : 'free-set'}`}
                  />
                </span>
              </div>
              {space.observedDataLayers.map(
                (pointData) =>
                  open && (
                    <RegularListElement
                      item={item}
                      elem={pointData}
                      enabled={pointData.enabled}
                      key={pointData.id}
                      deleteElement={() => removeObservedLayers(pointData.id)}
                      inPoster
                      parentId={item.id}
                      id={pointData.id}
                      elementType={ElementsEnum.OBSERVED_WD}
                      name={pointData.name}
                      mapId={item.id}
                      enable={(enabled) =>
                        toggleElement &&
                        item.id &&
                        toggleElement(
                          enabled,
                          elem.id,
                          keyToElementsEnum(elem.elementType),
                          item.id,
                        )
                      }
                    />
                  ),
              )}
              {pointForLane(splitArrayByNestedProperty(space.forecastDataLayers, 'name')).map(
                (pointData) =>
                  open && (
                    <RegularListElement
                      item={item}
                      elem={pointData[0]}
                      enabled={pointData[0].enabled}
                      key={pointData[0].id}
                      deleteElement={() => removeForecastLayers(pointData.map((point) => point.id))}
                      inPoster
                      parentId={item.id}
                      id={pointData[0].id}
                      elementType={ElementsEnum.FORECAST_WD}
                      name={pointData[0].name}
                      mapId={item.id}
                      enable={(enabled) => toggleForecastLayer(pointData, enabled, item.id)}
                    />
                  ),
              )}
              {space.gribMapLayers.map(
                (layer) =>
                  open && (
                    <div
                      key={layer.gribSource.id}
                      className={`sequence-title map sub-item open layer`}
                    >
                      <span className="map-sub-element" title={item.name}>
                        <Checkbox
                          checked={layer.enabled}
                          onChange={(checked) => enableGribLayer(checked, layer.gribSource.id)}
                          style={{ marginRight: '0.3rem' }}
                        />
                      </span>
                      <span
                        onClick={() => openLayerProp(item.id, 'grib', layer.id)}
                        className={'cursor-pointer'}
                      >
                        {layer.name}
                      </span>
                      <div className={'flex'}>
                        <LoadLayerFramesButton layer={layer} />
                        <button
                          className={'hoverState text-red-500'}
                          onClick={() => removeGribLayer(layer.gribSource.id)}
                        >
                          <VscTrash />
                        </button>
                      </div>
                    </div>
                  ),
              )}
              {space.radarMapLayers.map(
                (layer) =>
                  open && (
                    <div
                      key={layer.radarSource.id}
                      className={`sequence-title map sub-item open layer`}
                    >
                      <span className="map-sub-element">
                        <Checkbox
                          checked={layer.enabled}
                          onChange={(checked) => enableRadarLayer(checked, layer.radarSource.id)}
                          style={{ marginRight: '0.3rem' }}
                        />
                      </span>
                      <span
                        onClick={() => openLayerProp(item.id, 'radar', layer.id)}
                        className={'cursor-pointer'}
                      >
                        {layer.name}
                      </span>
                      <div className={'flex'}>
                        <LoadLayerFramesButton layer={layer} />
                        <button
                          className={'hoverState text-red-500'}
                          onClick={() => removeRadarLayers(layer.radarSource.id)}
                        >
                          <VscTrash />
                        </button>
                      </div>
                    </div>
                  ),
              )}
              {space.satelliteMapLayers.map(
                (layer) =>
                  open && (
                    <div
                      key={layer.satelliteSource.id}
                      className={`sequence-title map sub-item open layer`}
                    >
                      <span className="map-sub-element">
                        <Checkbox
                          checked={layer.enabled}
                          onChange={(checked) =>
                            enableSatelliteLayer(checked, layer.satelliteSource.id)
                          }
                          style={{ marginRight: '0.3rem' }}
                        />
                      </span>
                      <span
                        onClick={() => openLayerProp(item.id, 'satellite', layer.id)}
                        className={'cursor-pointer'}
                      >
                        {layer.name}
                      </span>
                      <div className={'flex'}>
                        <LoadLayerFramesButton layer={layer} />
                        <button
                          className={'hoverState text-red-500'}
                          onClick={() => removeSatelliteLayers(layer.satelliteSource.id)}
                        >
                          <VscTrash />
                        </button>
                      </div>
                    </div>
                  ),
              )}
              {space.symbolLayers?.map(
                (layer) =>
                  open && (
                    <div key={layer.id} className={`sequence-title map sub-item open layer`}>
                      <span className="map-sub-element" title={item.name}>
                        <Checkbox
                          checked={layer.enabled}
                          onChange={(checked) => enableSymbolLayer(checked, layer.id)}
                          style={{ marginRight: '0.3rem' }}
                        />
                      </span>
                      <span
                        onClick={() => openLayerProp(item.id, 'symbol', layer.id)}
                        className={'cursor-pointer'}
                      >
                        {layer.name}
                      </span>
                      <div className={'flex'}>
                        <LoadLayerFramesButton layer={layer} />
                        <button
                          className={'hoverState text-red-500'}
                          onClick={() => removeSymbolLayer(layer.id)}
                        >
                          <VscTrash />
                        </button>
                      </div>
                    </div>
                  ),
              )}
            </Fragment>
          ) : null,
        )}
        {item?.geoPosters.map(
          (pos) =>
            open && (
              <PosterElementList
                item={pos}
                key={pos.id}
                mapId={item.id}
                deleteElement={deletePoster}
                toggleElement={toggleElement}
                enable={(e) => toggleGeo(e, pos.id)}
              />
            ),
        )}

        {select && item.id === activeElement && (
          <div key={`${item.id}-flyover-choice`} className={'choice-container'}>
            <div className={'header'}>Select fly over transition type</div>
            <div className="choice-wrapper">
              <p className={'choice'} onClick={() => createFlyOverKeyFrame('LINEAR')}>
                Linear
              </p>
              <p className={'choice'} onClick={() => createFlyOverKeyFrame('PARABOLIC')}>
                Parabolic
              </p>
              <p className={'choice cancel'} onClick={() => setSelect && setSelect(false)}>
                Cancel
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MapListElement;
