import { useDispatch, useSelector } from 'react-redux';

import { ElementsEnum } from '../../../../core/ui/enums/ElementsEnum';
import { usePropertyGridActive } from '../../../../hooks/usePropertyGridActive';
import { BorderDef } from '../../../../model/definitions/BorderDef';
import { BoxDef } from '../../../../model/definitions/BoxDef';
import { ActiveDef, setPropertyGridActiveHash } from '../../../../store/slices/active-slice';
import {
  updateBoxDef,
  updateForecastMapLayerBox,
  updateIndicatorBox,
  updateObservedMapLayerBox,
} from '../../../../store/slices/project-slice';
import { RootState } from '../../../../store/store';
import { PropertySection } from '../components/PropertySection';
import { boxStylePropertiesDeps } from '../constants/propertiesConstants';
import { BackgroundProperties } from './panelElements/BackgroundProperties';
import { BorderPicker } from './panelElements/BorderPicker';
import { PaddingControl } from './panelElements/PaddingControl';

interface BoxStyleProps {
  box: BoxDef;
  elementType: ElementsEnum;
  parent?: string;
  mapId?: string;
}
export const BoxStyle = ({ box, elementType, parent, mapId }: BoxStyleProps) => {
  const dispatch = useDispatch();
  const { activeScene, activeElement, activePoster } = useSelector<RootState, ActiveDef>(
    (state) => state.active,
  );

  const { isOpened } = usePropertyGridActive(boxStylePropertiesDeps);

  const updateBox = (value: string | number | BorderDef, propertyPath: Leaves<BoxDef>) => {
    onFocus(propertyPath);
    elementType === ElementsEnum.INDICATOR
      ? dispatch(updateIndicatorBox({ activeScene, propertyPath, value, parentId: parent }))
      : elementType === ElementsEnum.OBSERVED_WD && mapId && !parent
      ? dispatch(
          updateObservedMapLayerBox({
            newValue: value,
            activeScene,
            propertyPath,
            parentId: mapId,
            id: activeElement,
          }),
        )
      : elementType === ElementsEnum.FORECAST_WD && mapId && !parent
      ? dispatch(
          updateForecastMapLayerBox({
            newValue: value,
            activeScene,
            propertyPath,
            parentId: mapId,
            id: activeElement,
          }),
        )
      : dispatch(
          updateBoxDef({
            activeScene,
            elementType,
            value,
            propertyPath,
            activeElement,
            parentId: activePoster,
          }),
        );
  };
  const { paddingLeft, paddingBottom, paddingTop, paddingRight, background } = box;
  function onFocus(path: Leaves<BoxDef>) {
    dispatch(setPropertyGridActiveHash({ activeElement, focusedEl: path }));
  }
  return (
    <PropertySection label={'Box style'} isOpened={isOpened}>
      <div className="prop-wrapper">
        <BackgroundProperties
          background={background}
          elementType={elementType}
          parent={parent}
          mapId={mapId}
        />
        <PaddingControl
          top={paddingTop}
          bottom={paddingBottom}
          left={paddingLeft}
          right={paddingRight}
          onChange={(value, name) => updateBox(value, name)}
        />
        <BorderPicker box={box} onChange={(value, name) => updateBox(value, name)} />
      </div>
    </PropertySection>
  );
};
