import { useMutation } from 'react-query';

import { axiosInstance } from '../axiosInstance';

export const useAddVideoOverElement = () => {
  return useMutation(
    async (data: Blob) => {
      const formData = new FormData();
      const file = new File([data], 'file.webm', { type: data.type });
      formData.append('file', file, `${file.name}`);
      formData.append('type', 'VIDEO');
      formData.append('description', 'project video over');
      formData.append('name', 'VideoOver');
      return await axiosInstance.post(`multimedia/uploadFile`, formData, {
        headers: { 'content-type': 'multipart/form-data' },
      });
    },
    // { onSuccess: (data) => console.log(data) },
  );
};
