import './style.scss';

import { useContext, useEffect, useRef, useState } from 'react';
import { AiOutlinePlus, AiOutlineSearch } from 'react-icons/ai';
import { MdRefresh } from 'react-icons/md';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Button from '../../../atoms/button/Button';
import { useDebounce } from '../../../hooks/useDebounce';
import { SortingWorkspaceEnum } from '../../../model/enums/SortingEnum';
import { AnimationRetryRequest } from '../../../model/other/AnimationRetryRequest';
import Sorting from '../../marketplace-new/molecules/Sorting/Sorting';
import GridLayout from '../layout-NEW/GridLayout';
import UploadModal from '../modals/UploadModal';
import useDeleteMultimedia from '../queries-NEW/useDeleteMultimedia';
import useUpdateInfo from '../queries-NEW/useEditBasicInfo';
import useGetMultimedia from '../queries-NEW/useGetMultimedia';
import { key } from '../queries-NEW/useGetTransferInfo';
import { useRetryPngSequence } from '../queries-NEW/useRetryPngSequence';
import useShareMultimedia from '../queries-NEW/useShareMultimedia';
import FilterItems from './components/FilterItems';
import { UpdateInterface } from './Projects';
import SortContext from './sortContext';

const Multimedia = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(0);
  const [search, setSearch] = useState<string>('');
  const [uploadModal, setUploadModal] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const { type } = useParams() as unknown as {
    type: 'audio' | 'video' | 'image' | 'custom_map' | 'animation' | 'symbol' | 'custom_vector';
  };
  const { sortAscDesc, sortByField, handleSort } = useContext(SortContext);
  const searchKey = useDebounce(search, 300);
  const [activeTab, setActiveTab] = useState<{ value: string; label: string }>({
    value: 'USER',
    label: `My ${type}s`,
  });
  const {
    data: multimedia,
    isLoading,
    isError,
    error,
    refetch,
  } = useGetMultimedia({
    page,
    type,
    searchKey,
    sort: sortByField + ',' + sortAscDesc,
    activeTab: activeTab.value,
  });
  const deleteMultimedia = useDeleteMultimedia();
  const shareMultimedia = useShareMultimedia();
  const searchRef = useRef<HTMLInputElement>(null);
  const updateProject = useUpdateInfo();
  const { mutate: mutatePngSequence } = useRetryPngSequence();

  const onUpdate = (data: UpdateInterface) =>
    updateProject.mutate(data, {
      onSuccess: async () => toast.success("Item's basic info updated"),
      onError: async () => toast.error('Error updating basic info'),
    });

  const onDelete = (id: string, versionId: string) =>
    deleteMultimedia.mutate(
      { id: versionId, type },
      {
        onSuccess: async () => {
          if (multimedia?.content.length === 1 && page > 0) {
            setPage(page - 1);
          }
          toast.success('Item deleted');
        },
        onError: async (error: any) => {
          if (error?.response?.status === 403) {
            toast.error('You are not allowed to delete item');
          } else {
            toast.error('Error deleting item');
          }
        },
      },
    );

  const onRetry = (data: AnimationRetryRequest) => {
    mutatePngSequence(data);
  };

  const onShare = (id: string, type: string) =>
    shareMultimedia.mutate(
      { id, type },
      {
        onSuccess: async () => {
          toast.success(`${type} shared`);
        },
      },
    );

  useEffect(() => {
    setSearch('');
    setPage(0);
    setActiveTab({
      value: 'USER',
      label: `My ${type}s`,
    });
  }, [type]);

  useEffect(() => {
    if (page !== 0) {
      const timer = setTimeout(() => {
        setPage(0);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [search]);

  useEffect(() => {
    refetch();
  }, [page, searchKey, sortAscDesc, sortByField, activeTab, refetch]);

  return (
    <>
      <div className={'flex ws-fixed-header'}>
        <div className={'ws-temp-title'}>
          <h1>{type.charAt(0).toUpperCase() + type.substring(1).replace('_', ' ') + 's'}</h1>
        </div>
        {(type === 'video' || type === 'custom_map') && (
          <Button
            className="ws-button !mr-4"
            label="Refresh data"
            onClick={() => (type === 'custom_map' ? refetch() : queryClient.invalidateQueries(key))}
            icon={<MdRefresh />}
          />
        )}
        <Button
          className={`ws-button`}
          onClick={() => setUploadModal(true)}
          disabled={activeTab.value !== 'USER'}
          label={`Add ${type.charAt(0).toUpperCase() + type.substring(1).replace('_', ' ')}`}
          icon={<AiOutlinePlus />}
        />
        <FilterItems activeTab={activeTab} type={type} setActiveTab={setActiveTab} />
        <div className={'ws-input !ml-auto lg:!ml-[10px]'} style={{ height: '32px' }}>
          <input
            ref={searchRef}
            value={search}
            autoFocus
            onChange={(e) => setSearch && setSearch(e.target.value)}
            placeholder={'Type here...'}
            className="w-full 2xl:!w-[300px]"
          />
          <AiOutlineSearch color={'rgba(0,0,0,0.3)'} size={24} />
        </div>
        {multimedia?.content && multimedia?.content.length > 1 && (
          <div className="ml-auto mt-[10px] lg:mt-0">
            <Sorting
              sortAscDesc={sortAscDesc}
              sorting={SortingWorkspaceEnum}
              handleSort={handleSort}
              sortByField={sortByField}
            />
          </div>
        )}
      </div>
      <GridLayout
        searchString={searchKey}
        link={(id: string) => navigate(`preview/${id}`)}
        error={error}
        onShare={(id, type) => onShare(id, type)}
        onDelete={(id, versionId) => onDelete(id, versionId)}
        type={type}
        onPublish={(id: string) => {
          navigate(id);
        }}
        onRetry={(data: AnimationRetryRequest) => {
          onRetry(data);
        }}
        loading={isLoading}
        isError={isError}
        pages={multimedia?.totalPages}
        currentPage={page}
        changePage={(e) => setPage(e)}
        onSearch={setSearch}
        contents={multimedia?.content}
        onEdit={(
          data: Pick<
            UpdateInterface,
            'newName' | 'newDescription' | 'newSymbolType' | 'id' | 'versionId'
          >,
        ) =>
          onUpdate({
            ...data,
            type: `${type.toUpperCase()}_TEMPLATE`,
            key: type,
          })
        }
      />
      {uploadModal && (
        <UploadModal open={uploadModal} onClose={() => setUploadModal(false)} type={type} />
      )}
    </>
  );
};

export default Multimedia;
