export enum VisualisationTypeEnum {
  HEATMAP = 'heatmap',
  ISOLINE = 'isoline',
  PARTICLE = 'particle',
}

export const VisualisationTypeName = {
  [VisualisationTypeEnum.HEATMAP]: 'Shaded',
  [VisualisationTypeEnum.ISOLINE]: 'Isoline',
  [VisualisationTypeEnum.PARTICLE]: 'Particle',
};

export const isTileVisualisationType = (type: VisualisationTypeEnum | undefined): boolean => {
  return !type || type === VisualisationTypeEnum.HEATMAP;
};
