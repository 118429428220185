import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FrameLoadingStatus } from '../../core/weather-data/WeatherDataLoaderTypes';

export interface MapLoadingState {
  mapIsLoading: boolean;
  frames: Record<string, FrameLoadingStatus>;
  loadingState: Record<FrameLoadingStatus, number>;
}

const initialState: MapLoadingState = {
  mapIsLoading: false,
  frames: {},
  loadingState: {
    [FrameLoadingStatus.Loading]: 0,
    [FrameLoadingStatus.Error]: 0,
    [FrameLoadingStatus.Success]: 0,
  },
};
const mapLoadingSlice = createSlice({
  name: 'mapLoadingState',
  initialState,
  reducers: {
    setMapLoading(state, action: PayloadAction<boolean>) {
      state.mapIsLoading = action.payload;
    },
    resetMapLoading() {
      // return { ...initialState };
    },
    addFrames(state, action: PayloadAction<Record<string, FrameLoadingStatus>>) {
      state.frames = { ...action.payload };
    },
    setLoadingState(state, action: PayloadAction<Record<FrameLoadingStatus, number>>) {
      state.loadingState = action.payload;
    },
  },
});

export const { resetMapLoading, setMapLoading, addFrames, setLoadingState } =
  mapLoadingSlice.actions;
export default mapLoadingSlice.reducer;
