import { Button } from 'flowbite-react';
import ButtonGroup from 'flowbite-react/lib/esm/components/Button/ButtonGroup';
import { useEffect, useState } from 'react';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';

interface CrawlDirectionPickerProps {
  onClick: (e: string) => void;
  value?: string;
  disabled?: boolean;
}

interface Style {
  backgroundColor: string;
  borderColor: string;
  color: string;
}

export const CrawlDirectionPicker = ({ onClick, value, disabled }: CrawlDirectionPickerProps) => {
  const [styleLeft, setLeftStyle] = useState<Style>({
    backgroundColor: '#fff',
    borderColor: '#2b3441',
    color: '#05152b',
  });
  const [styleRight, setRightStyle] = useState<Style>({
    backgroundColor: '#060b12',
    borderColor: '#2b3441',
    color: '#fff',
  });

  useEffect(() => {
    if (value === 'left' || undefined) {
      setLeftStyle({ backgroundColor: '#fff', borderColor: '#2b3441', color: '#05152b' });
      setRightStyle({ backgroundColor: '#060b12', borderColor: '#2b3441', color: '#fff' });
    } else {
      setLeftStyle({ backgroundColor: '#060b12', borderColor: '#2b3441', color: '#fff' });
      setRightStyle({ backgroundColor: '#fff', borderColor: '#2b3441', color: '#05152b' });
    }
  }, [value]);

  return (
    <ButtonGroup>
      <Button
        disabled={disabled}
        size={'sm'}
        style={styleLeft}
        onClick={() => {
          onClick('left');
        }}
      >
        <AiOutlineArrowLeft color={styleLeft.color} />
      </Button>
      <Button
        disabled={disabled}
        size={'sm'}
        style={styleRight}
        onClick={() => {
          onClick('right');
        }}
      >
        <AiOutlineArrowRight color={styleRight.color} />
      </Button>
    </ButtonGroup>
  );
};
