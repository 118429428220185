import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { axiosInstance } from '../../../core/api/axiosInstance';
import { C9ProjectDef } from '../../../model/definitions/C9ProjectDef';

export const useOpenTemplateInStudio = () => {
  const navigate = useNavigate();
  return useMutation(
    async (project: C9ProjectDef): Promise<C9ProjectDef> => {
      const { data } = await axiosInstance.put('project', project);
      return data;
    },
    {
      onSuccess: (data) => navigate(`../../studio?projectId=${data.id}`),
    },
  );
};
