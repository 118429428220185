import { ChangeEvent, FunctionComponent, ReactElement } from 'react';

interface MinutesSelectProp {
  disabled?: boolean;
  onChange(event: ChangeEvent<HTMLSelectElement>): void;
  value: string;
}
const MinutesSelect: FunctionComponent<MinutesSelectProp> = (props) => {
  const buildOptions = (): ReactElement[] => {
    const options = [];
    for (let i = 0; i < 60; i++) {
      options.push(
        <option key={i} id={i.toString()}>
          {(i < 10 ? '0' : '') + i}
        </option>,
      );
    }
    return options;
  };
  return (
    <select
      disabled={props.disabled === true ? true : false}
      className="minutes"
      onChange={props.onChange}
      value={props.value}
    >
      {buildOptions()}
    </select>
  );
};
export default MinutesSelect;
