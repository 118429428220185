export enum SortingMarketplaceEnum {
  NAME = 'NAME',
  POPULAR = 'POPULAR',
  CREATION_DATE = 'CREATION DATE',
}

export enum SortingOldWorkspaceEnum {
  NAME = 'NAME',
  CREATION_DATE = 'CREATION DATE',
}

export enum SortingWorkspaceEnum {
  name = 'NAME',
  creationDate = 'CREATION DATE',
  creator = 'CREATOR',
}

export enum SortingProjectEnum {
  name = 'NAME',
  creationDate = 'CREATION DATE',
  lastActivityDateTime = 'LAST ACTIVITY DATE TIME',
  creator = 'CREATOR',
}

export enum SortingEnum {
  name = 'NAME',
  createdAt = 'CREATED AT',
}

export enum SortingLangageEnum {
  name = 'NAME',
  key = 'KEY',
}
