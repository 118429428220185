import { useState } from 'react';

import RepositoryItem from '../../repository-item/RepositoryItem';

interface Props {
  data: any;
  filterTab: string;
  type: string;
  onClose: () => void;
  onAddItem: (id: string, type?: string) => void;
}
const ContentItems = ({ data, filterTab, type, onClose, onAddItem }: Props) => {
  const [contentRef, setContentRef] = useState<HTMLDivElement | null>(null);
  const renderItems = () =>
    data.map((item: any) => (
      <RepositoryItem
        onAddItem={onAddItem}
        key={item.id}
        setSidebarOpen={onClose}
        item={item}
        type={filterTab !== '' ? filterTab : type}
      />
    ));
  function getContentMaxHeight(): number {
    if (contentRef) {
      const rect = contentRef.getBoundingClientRect();
      return window.innerHeight - rect.top;
    }
    return 0;
  }
  return (
    <div
      ref={(el) => setContentRef(el)}
      style={{
        maxHeight: getContentMaxHeight() ? getContentMaxHeight() : '',
      }}
      className="content-repo"
    >
      {renderItems()}
    </div>
  );
};

export default ContentItems;
