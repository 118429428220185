import Slider from 'rc-slider';
import { MarkObj } from 'rc-slider/lib/Marks';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { WeatherDataLoader } from '../../../core/weather-data/WeatherDataLoader';
import { FrameLoadingStatus } from '../../../core/weather-data/WeatherDataLoaderTypes';
import { DataFrameDef } from '../../../model/definitions/DataFrameDef';
import { MapLoadingState } from '../../../store/slices/map-loading.slice';
import { RootState } from '../../../store/store';

export const LayerLaneSlider = ({
  frames,
  layerId,
}: {
  frames: DataFrameDef[];
  layerId: string;
}) => {
  const { frames: loadingFrames } = useSelector<RootState, MapLoadingState>(
    (state) => state.mapLoading,
  );

  const sliderMarks = useMemo(() => {
    const marks: Record<number, MarkObj> = {};
    frames.forEach((frame, index) => {
      // set mark color based on loading status
      const status = loadingFrames[WeatherDataLoader.getCacheKey(frame.frameId, layerId)];
      const colors = {
        [FrameLoadingStatus.Loading]: 'rgba(100, 100, 100, 1)',
        [FrameLoadingStatus.Error]: 'rgba(255, 0, 0, 1)',
        [FrameLoadingStatus.Success]: 'rgba(0, 255, 0, 1)',
      };
      marks[index] = {
        label: '|',
        style: {
          color:
            index !== frames.length - 1
              ? colors[status ?? FrameLoadingStatus.Success]
              : 'transparent',
          position: 'absolute',
        },
      };
    });
    return marks;
  }, [frames, loadingFrames]);

  return useMemo(
    () => (
      <Slider
        disabled
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: -20,
          background: 'transparent',
          pointerEvents: 'none',
        }}
        className={'dataTime-slider'}
        marks={sliderMarks}
        min={0}
        handleRender={() => <div />}
        step={1}
        max={frames.length - 1}
      />
    ),
    [frames, sliderMarks],
  );
};
