import { useCallback, useMemo, useRef, useState } from 'react';
import { BiFullscreen } from 'react-icons/bi';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useResizeDetector } from 'react-resize-detector';

import { createPlayer } from '../components/timeline/helpers';
import { ModeEnum } from '../core/ui/enums/ModeEnum';
import { parseScenes } from '../helpers/timelineUtil';
import { useContextMenu } from '../hooks/useContextMenu';
import { useHideMapOnLastSkip } from '../hooks/useHideMapOnLastSkip';
import { useLeftMargin } from '../hooks/useLeftMargin';
import { useScaleFactor } from '../hooks/useScaleFactor';
import { MAX_FULLSCREEN_HEIGHT /*setFullScreenHeight */ } from '../model/constants/constants';
import { C9ProjectDef } from '../model/definitions/C9ProjectDef';
import { MapPanelDef } from '../model/definitions/MapPanelDef';
import { VideoPanelDef } from '../model/definitions/VideoPanelDef';
import { CanvasAudioElement, VideoElement } from '../molecules/canvasElements';
import { VideoOverRecorder } from '../molecules/canvasElements/VideoOverRecorder';
import { VideoOverWrapper } from '../molecules/canvasElements/VideoOverWrapper';
import { MapLoader } from '../molecules/mapElement/MapLoader';
import Loader from '../pages/dashboard/components/Loader';
import styles from '../pages/playground/Playground.module.scss';
import { ActiveDef } from '../store/slices/active-slice';
import { updateMapLayer } from '../store/slices/project-slice';
import { RootState } from '../store/store';
import { SceneElement } from './SceneElement';
import SecondaryControls from './secondaryControls/SecondaryControls';

interface SceneCanvasProps {
  project: C9ProjectDef;
  addElement?: () => void;
  play: () => void;
  pause: () => void;
  toStart: () => void;
  backFive: () => void;
  stop: () => void;
  skipFive: () => void;
  toEnd: () => void;
  skip: () => void;
  prevScene: (e: number) => void;
  nextScene: (e: number) => void;
  active: ActiveDef;
}
const SceneCanvas = ({
  project,
  play,
  pause,
  stop,
  skipFive,
  toEnd,
  backFive,
  toStart,
  skip,
  addElement,
  prevScene,
  nextScene,
  active,
}: SceneCanvasProps) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { ref, height } = useResizeDetector();
  const recorder = useSelector<RootState, VideoPanelDef | null>(
    (state) => state.project.present.project?.videoOverRecorder,
  );
  const isMapDefFetching = queryClient.isMutating({ mutationKey: 'addMapElement' }) > 0;
  const [isFullScreen, setFullScreen] = useState<boolean>(false);
  const { showMenu, menu } = useContextMenu(isFullScreen, addElement);
  const scaleFactor = useScaleFactor(isFullScreen);

  const aspectRatio = `${active.activeAspectRatio[0]}/${active.activeAspectRatio[1]}`;
  const fullScreenRef = useRef<HTMLDivElement>(null);
  const leftMargin = useLeftMargin(isFullScreen);

  function exitFullScreen() {
    setFullScreen(false);
  }
  const isProjectMode = active.mode === ModeEnum.PROJECT;

  const hideMapLoader = useHideMapOnLastSkip();
  function goFullScreen() {
    setFullScreen(true);
  }
  const updateSceneMap = useCallback(
    (mapDef: MapPanelDef) => {
      if (isProjectMode) return;
      dispatch(
        updateMapLayer({
          activeScene: active.activeScene,
          elementId: mapDef.id,
          newValue: mapDef.positionControl,
          propertyPath: 'positionControl',
        }),
      );
    },
    [active.activeScene, isProjectMode],
  );

  const renderScene = () => {
    const scene = project.sceneDefs?.find((scene) => scene.id === active.activeScene);
    return (
      <SceneElement
        scene={scene}
        key={scene?.id}
        isFullScreen={isFullScreen}
        aspectRatio={active.activeAspectRatio}
        cnvHeight={height!}
        updateMap={updateSceneMap}
      />
    );
  };
  const renderProject = useMemo(() => {
    const stbProject = createPlayer(project, active.syncSpace, active.activeFramerate);
    const simpleScenes = parseScenes(stbProject);

    const scenes = active.projectToPlay?.sceneDefs
      ? stbProject?.sceneDefs.map((scene, index) => (
          <SceneElement
            scene={scene}
            key={scene?.id}
            isFullScreen={isFullScreen}
            aspectRatio={active.activeAspectRatio}
            cnvHeight={height!}
            updateMap={updateSceneMap}
            index={index}
            simpleScenes={simpleScenes}
          />
        ))
      : [];
    const voiceover = project.voiceOver ? (
      <CanvasAudioElement
        panelProps={project.voiceOver}
        key={'Project_voiceover'}
        isVoiceOver={true}
      />
    ) : null;
    const videoOverElement = project.videoOver ? (
      <VideoOverWrapper
        positionControl={project.videoOver.positionControl}
        id={project.videoOver.id}
      >
        <VideoElement
          useProxy={false}
          key={project.videoOver.id}
          canvas={{
            cnvWidth: (active.activeAspectRatio[0] / active.activeAspectRatio[1]) * height!,
            cnvHeight: height!,
          }}
          panelProps={project.videoOver}
        />
      </VideoOverWrapper>
    ) : null;
    return [...scenes, voiceover, videoOverElement];
  }, [
    active.activeAspectRatio,
    active.projectToPlay?.sceneDefs,
    active.syncSpace,
    height,
    isFullScreen,
    project.sceneDefs,
    updateSceneMap,
  ]);

  return (
    <div
      style={{
        position: 'relative',
        height: isFullScreen ? MAX_FULLSCREEN_HEIGHT : active.previewSize,
        aspectRatio,
        width: 'auto',
      }}
    >
      {isFullScreen && <div className="fullScreen_backdrop"></div>}
      <div
        ref={fullScreenRef}
        className={`${isFullScreen ? 'fullScreen_container' : 'noFullScreen_container'}`}
      >
        <div
          className={`${styles.canvasContainer}`}
          id={'Canvas'}
          style={{
            height: MAX_FULLSCREEN_HEIGHT,
            width: 'auto',
            aspectRatio,
            transform: `scale(${scaleFactor})`,
            transformOrigin: '0 0',
            marginLeft: leftMargin,
          }}
        >
          <div
            tabIndex={0}
            id="canvas-container"
            className={styles.canvas}
            ref={ref}
            style={{
              width: 'auto',
              height: MAX_FULLSCREEN_HEIGHT,
              aspectRatio,
            }}
          >
            {isMapDefFetching && (
              <div
                key={'canvas-container-map-def-fetching'}
                data-html2canvas-ignore="true"
                className={`absolute w-full h-full z-[9999] flex items-center justify-center bg-neutral-400/50`}
              >
                <Loader />
              </div>
            )}
            {active.mode === ModeEnum.SEQUENCE && renderScene()}
            {isProjectMode && recorder !== null && (
              <VideoOverRecorder
                videoOver={project.videoOverRecorder}
                disabled={isFullScreen}
                play={play}
                key={project.videoOverRecorder?.id}
                canvas={{
                  cnvWidth: (active.activeAspectRatio[0] / active.activeAspectRatio[1]) * height!,
                  cnvHeight: height!,
                }}
              />
            )}
            {isProjectMode && project.videoOver ? (
              <VideoOverWrapper
                positionControl={project.videoOver.positionControl}
                id={project.videoOver.id}
              >
                <VideoElement
                  canvas={{
                    cnvWidth: (active.activeAspectRatio[0] / active.activeAspectRatio[1]) * height!,
                    cnvHeight: height!,
                  }}
                  isVideoOver
                  useProxy={false}
                  panelProps={project.videoOver}
                />
              </VideoOverWrapper>
            ) : null}
            {isProjectMode && renderProject}
            {!isFullScreen && (
              <div
                style={{ transform: `scale(${1 / scaleFactor})`, transformOrigin: 'bottom right' }}
                className={styles.controlBar}
              >
                <div
                  id="full_screen"
                  className={styles.fullscreenIcon}
                  onClick={() => goFullScreen()}
                >
                  <BiFullscreen color={'white'} size={36} />
                </div>
              </div>
            )}
            {showMenu && !isFullScreen && menu}
            {isFullScreen && (
              <SecondaryControls
                prevScene={prevScene}
                nextScene={nextScene}
                passRef={fullScreenRef}
                canvas
                skip={skip}
                fullScreen={isFullScreen}
                backFive={backFive}
                pause={pause}
                skipFive={skipFive}
                start={play}
                stop={stop}
                toEnd={toEnd}
                toStart={toStart}
                onClose={exitFullScreen}
              />
            )}
            {!hideMapLoader && <MapLoader />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SceneCanvas;
