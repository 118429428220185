import { useEffect, useState } from 'react';
import { IoArrowBack, IoCreateOutline } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { v4 } from 'uuid';

import Button from '../../../atoms/button/Button';
import Input from '../../../atoms/input/Input';
import { useGetGeoPosterById } from '../../../core/api/poster/useGetGeoPosterById';
import useGetGeoPosterTemplates from '../../../core/api/poster/useGetGeoPosterTemplates';
import { TimeControlDef } from '../../../model/definitions/TimeControlDef';
import { WeatherGeoPosterDef } from '../../../model/definitions/WeatherGeoPosterDef';
import { WeatherPosterDef } from '../../../model/definitions/WeatherPosterDef';
import { mapWeatherPosterToGeo } from '../../../molecules/mapElement/helpers';
import Modal from '../../../molecules/modal/Modal';
import { ActiveDef } from '../../../store/slices/active-slice';
import { addGeoPoster } from '../../../store/slices/project-slice';
import { RootState } from '../../../store/store';
import RepositoryItem from '../repository-item/RepositoryItem';
import { ForecastGeoPosterImport } from './ForecastGeoPosterImport';
import { ObservedGeoPosterImport } from './ObservedGeoPosterImport';

interface CreatePosterModalProps {
  opened: boolean;
  onClose: () => void;
  onOk: (e?: Array<WeatherPosterDef>) => void;
  timeControls: Array<TimeControlDef>;
  initialData?: Array<WeatherPosterDef>;
  initialPosterTemp?: string;
  addPostersToMap?: (e?: Array<WeatherGeoPosterDef>) => void;
}

export const AddGeoModal = ({
  opened,
  onClose,
  onOk,
  timeControls,
  initialData = [],
  initialPosterTemp = '',
  addPostersToMap,
}: CreatePosterModalProps) => {
  const dispatch = useDispatch();
  const { activeElement, activeScene } = useSelector<RootState>(
    (state) => state.active,
  ) as ActiveDef;
  const [search, setSearch] = useState<string>('');
  const [poster, setPoster] = useState<string>(initialPosterTemp);
  const [postersData, setPostersData] = useState<Array<WeatherPosterDef>>(initialData);
  const { mutate: getPoster, data: posterDefinition } = useGetGeoPosterById();
  useEffect(() => {
    poster && getPoster(poster);
  }, [poster]);
  const { data } = useGetGeoPosterTemplates({
    page: 0,
    searchKey: search,
    size: 1000,
  });
  const addPosters = () => {
    postersData[0].id = v4();
    postersData.forEach((pd) => {
      pd.positionControl.x = 0;
      pd.positionControl.y = 0;
    });
    const geoPosterData = postersData.map((data) =>
      mapWeatherPosterToGeo({ ...data, timeControls: timeControls }),
    );
    !initialPosterTemp
      ? dispatch(
          addGeoPoster({
            newValue: geoPosterData,
            activeScene,
            elementId: activeElement,
          }),
        )
      : addPostersToMap && addPostersToMap(geoPosterData);
    onOk();
  };
  const renderItems = () =>
    data?.content.map((item: any) => (
      <RepositoryItem
        isGeo
        onAddItem={(id) => setPoster(id)}
        key={item.id}
        setSidebarOpen={onClose}
        item={item}
        type={'weather-poster'}
      />
    ));
  return (
    <Modal
      isOpen={opened}
      onClose={() => {
        onClose();
      }}
      header={'Add Composite'}
      className="template-scene-modal"
      footer={
        <div className="modal-footer">
          <div className={'button-holder'}>
            <Button onClick={onClose} buttonType="secondary" label="Cancel" />
            <Button
              disabled={!poster}
              icon={<IoArrowBack />}
              onClick={() => setPoster('')}
              buttonType="secondary"
              label="Previous"
            />
            <Button
              disabled={!poster}
              icon={<IoCreateOutline />}
              label="Create"
              onClick={() => addPosters()}
            />
          </div>
        </div>
      }
    >
      <>
        {!poster && (
          <div className={'mb-3 border-b-amber-100'}>
            <div className={'mb-4 border-b-amber-100'}>
              <Input
                label={'Search'}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Type here..."
                style={{ width: `calc(100% - 17px)` }}
              />
            </div>
            <div className={'geo-posters-list'}>{renderItems()}</div>
          </div>
        )}
        {poster &&
          posterDefinition &&
          posterDefinition?.geoPosterDef?.observedWDElements?.length > 0 && (
            <ObservedGeoPosterImport
              template={[posterDefinition?.geoPosterDef]}
              setData={(e) => {
                setPostersData(e);
              }}
            />
          )}
        {poster &&
          posterDefinition &&
          posterDefinition?.geoPosterDef?.forecastWDElements?.length > 0 && (
            <ForecastGeoPosterImport
              template={[posterDefinition?.geoPosterDef]}
              setData={(e) => setPostersData(e)}
            />
          )}
      </>
    </Modal>
  );
};
