import { _kc } from '../../core/auth/KeycloakService';
import { EventResponseDTO } from '../../model/DTO/EventResponseDTO';
// import { dataEventsMocked } from './events-mock';

export class EventsResponseCache {
  private static instance: EventsResponseCache | null = null;
  private cache: Record<string, EventResponseDTO[]>;
  private url = process.env.REACT_APP_POINT_API_BASE_URL || 'https://point-data-dev.c9weather.com';

  private constructor() {
    this.cache = {};
  }

  public static getInstance(): EventsResponseCache {
    if (!EventsResponseCache.instance) {
      EventsResponseCache.instance = new EventsResponseCache();
    }
    return EventsResponseCache.instance;
  }

  private generateKey(array: number[] | string[]): string {
    /**Timestamps must be sorted!!! */
    return array.join('-');
  }

  public getCachedResponse(array: number[] | string[]): EventResponseDTO[] | undefined {
    const key = this.generateKey(array);
    return this.cache[key];
  }

  public cacheResponse(array: number[] | string[], response: EventResponseDTO[]): void {
    const key = this.generateKey(array);
    this.cache[key] = response;
  }

  public async getEventsData(array: number[] | string[]): Promise<EventResponseDTO[]> {
    const cachedResponse = this.getCachedResponse(array);
    if (cachedResponse) {
      // console.log('Events found in cache ', cachedResponse);
      return cachedResponse;
    }

    const response = await fetch(this.url + '/api/event/data', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${_kc.token}`,
      },
      body: JSON.stringify({ eventIds: array }),
    });

    if (response.ok) {
      const data = await response.json();
      // console.log('response data ', data);
      this.cacheResponse(array, data.data);
      return data.data;
    } else {
      throw new Error('Failed to fetch Event data');
    }
  }
}
