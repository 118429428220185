import { History } from 'history';
import { useCallback, useContext, useEffect } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

import { useConfirmation } from '../pages/dashboard/modals/useConfirmation';

export function useBlocker(blocker: any, when = true) {
  const navigator = useContext(NavigationContext).navigator as History;

  useEffect(() => {
    if (!when) return;

    const unblock = navigator.block((tx) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          // Automatically unblock the transition so it can play all the way
          // through before retrying it. TODO: Figure out how to re-enable
          // this block if the transition is cancelled for some reason.
          unblock();
          tx.retry();
        },
      };

      blocker(autoUnblockingTx);
    });

    return unblock;
  }, [navigator, blocker, when]);
}
export function usePrompt(message: string, when = true) {
  const { confirm } = useConfirmation();
  const blocker = useCallback(
    (tx: any) => {
      confirm({ message: message, onConfirm: () => tx.retry() });
    },
    [confirm, message],
  );

  useBlocker(blocker, when);
}
