import { cloneDeep, groupBy } from 'lodash';
import { useState } from 'react';
import { v4 } from 'uuid';

import Input from '../../../../atoms/input/Input';
import LoadingIndicator from '../../../../atoms/loadingIndicator/LoadingIndicator';
import { useGetDateTime } from '../../../../core/api/observedData/useGetDateTime';
import { useGetStations } from '../../../../core/api/observedData/useGetStations';
import { useDebounce } from '../../../../hooks/useDebounce';
import { ObservedWDElementDef } from '../../../../model/definitions/ObservedWDElementDef';
import { WeatherPosterDef } from '../../../../model/definitions/WeatherPosterDef';
import { ObservedGeoPosterTime } from './ObservedGeoPosterTime';

interface ObservedGeoPosterProps {
  template: WeatherPosterDef;
  time: number;
  updatePoster: (poster: WeatherPosterDef) => void;
}

export const ObservedGeoPoster = ({ template, time, updatePoster }: ObservedGeoPosterProps) => {
  const location = template.observedWDElements.map((item) => item.observedWDSource.station.value);
  const pos = [...new Set(location)][0];
  const [searchKey, setSearchKey] = useState(pos);
  const debounceSearch = useDebounce(searchKey);
  const { data: stations, isLoading } = useGetStations(debounceSearch?.toUpperCase());
  const [station, setStation] = useState<{ val: string; loc?: string }>({
    val: template.observedWDElements.map((item) => item.observedWDSource.station.value)[0],
    loc: stations?.find(
      (stat) =>
        stat.value ===
        template.observedWDElements.map((item) => item.observedWDSource.station.value)[0],
    )?.fullName,
  });
  const { data: observedTime } = useGetDateTime(station.val);

  const updateElement = (elem: ObservedWDElementDef) => {
    const data = cloneDeep(template.observedWDElements);
    const index = data.findIndex((item) => item.id === elem.id);
    if (index > -1) data[index] = elem;
    updatePoster({
      ...template,
      observedWDElements: data,
      pointLocation: template.pointLocation.map((tempLoc) => {
        return {
          ...tempLoc,
          id: v4(),
          name: station.loc ?? tempLoc.name,
          maskValue: station.loc ?? tempLoc.name,
          value: `${station.loc} ${station.val}` ?? tempLoc.value,
          description: station.val ?? tempLoc.description,
        };
      }),
    });
  };
  const dt = groupBy(template.observedWDElements, 'observedWDSource.utcDate');
  const handleStation = (val: string, loc: string) => {
    setSearchKey(loc);
    setStation({ val, loc });
  };
  const smallestDate = Math.min(
    ...template.observedWDElements.map((item) => new Date(item.observedWDSource.utcDate).getTime()),
  );
  const renderData = () => {
    const vals = [];
    const ordered = Object.keys(dt)
      .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
      .reduce((obj, key) => {
        // @ts-ignore
        obj[key] = dt[key];
        return obj;
      }, {});
    for (const date in ordered) {
      const tempOffset = new Date(date).getTime() - smallestDate;
      vals.push(
        <>
          <ObservedGeoPosterTime
            key={date}
            time={time}
            tempOffset={tempOffset}
            //@ts-ignore
            data={ordered[date] as Array<ObservedWDElementDef>}
            station={station}
            observedTime={observedTime}
            template={template}
            updatePoster={updateElement}
          />
        </>,
      );
    }
    return vals;
  };
  return (
    <div className="station">
      <div className="relative location-wrapper">
        <Input
          label={'Select Station'}
          required
          value={searchKey}
          onChange={(e) => setSearchKey(e.target.value)}
          // style={getBorders()}
        />
        {searchKey &&
          station.val !== searchKey.toUpperCase() &&
          station.loc !== searchKey.toUpperCase() && (
            <div className={'search-result items-center'}>
              {isLoading ? (
                <div className={'flex w-1/2 loading'}>
                  <div className={'flex items-center mr-3'}>
                    <LoadingIndicator />
                  </div>
                  Searching
                </div>
              ) : searchKey &&
                station.val !== searchKey.toUpperCase() &&
                station.loc !== searchKey.toUpperCase() ? (
                stations?.map((stat) => (
                  <div
                    key={stat.value}
                    onClick={() => handleStation(stat.value, stat.fullName)}
                    className={'result'}
                  >
                    {stat.fullName} {stat.value}
                  </div>
                ))
              ) : (
                <div className={'flex justify-center items-center'}>no results</div>
              )}
            </div>
          )}
      </div>
      <div>{renderData()}</div>
    </div>
  );
};
