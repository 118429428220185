const toolbarGroups = [
  [
    {
      id: 1,
      format: 'underline',
      type: 'mark',
    },
    {
      id: 2,
      format: 'superscript',
      type: 'mark',
    },
    {
      id: 3,
      format: 'subscript',
      type: 'mark',
    },
  ],
];

export default toolbarGroups;
