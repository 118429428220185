import { Select } from 'flowbite-react';
import moment from 'moment';
import { useEffect, useState } from 'react';

import { stringToUnit } from '../../../../helpers/stringToUnit';
import { ForecastDataDef } from '../../../../model/definitions/ForecastDataDef';
import { ForecastWDElementDef } from '../../../../model/definitions/ForecastWDElementDef';
import { ForecastWDLocationDef } from '../../../../model/definitions/ForecastWDLocationDef';
import { WeatherPosterDef } from '../../../../model/definitions/WeatherPosterDef';
import { ValueTypeEnum } from '../../../../model/enums/ValueTypeEnum';
import { IconPicker } from '../../../../organisms/addElementModal/PointDataComponents/IconPicker';

interface ForecastDateGroupProps {
  time: Date;
  data: ForecastWDElementDef[];
  values: ForecastDataDef | undefined;
  date: string;
  offset: number;
  template: WeatherPosterDef;
  updatePoster: (data: ForecastWDElementDef) => void;
  poster: WeatherPosterDef;
  station: string;
}
export const ForecastDateGroup = ({
  time,
  data,
  date,
  offset,
  values,
  template,
  poster,
  updatePoster,
  station,
}: ForecastDateGroupProps) => {
  const isDaily = data.every((item) => item.forecastWDSource.daily);
  const [icon, setIcon] = useState<any>(undefined);
  const askedForTime = new Date(new Date(time).getTime() + (new Date(date).getTime() - offset));
  const usableData = isDaily ? values?.daily : values?.intraDay;
  const thisValue = usableData?.find((item) => {
    const format = isDaily ? 'DD.MM.yyyy' : 'DD.MM.yyyy HH:mm';
    return (
      moment(new Date(item.utcDate)).format(format) ===
      moment(askedForTime.toUTCString()).format(format)
    );
  });
  useEffect(() => {
    const elements = data.map((item) => {
      return {
        ...item,
        name: values?.location.name + ' ' + item.forecastWDSource.parameterType,
        forecastWDSource: {
          ...item.forecastWDSource,
          location: {
            ...((values?.location as unknown as ForecastWDLocationDef) ??
              new ForecastWDLocationDef()),
            locationId: station,
          },
          datetime: time.getTime(),
          name: values?.location.name + ' ' + item.forecastWDSource.parameterType,
          value:
            item.forecastWDSource.valueType === ValueTypeEnum.NUMERICAL
              ? thisValue?.properties?.find(
                  (param) => param.name === item.forecastWDSource.parameterType,
                )?.values
              : icon,
        },
      } as ForecastWDElementDef;
    });
    elements.forEach((elem) => updatePoster(elem));
  }, [poster, date, data, values?.location, time, thisValue?.properties, icon, updatePoster]);
  const selectUnt = (property: Array<{ value: number; unit: string }>) =>
    property?.map((value) => (
      <option key={`${value?.value}_${value?.unit}`} value={value?.unit}>
        {stringToUnit(value.unit)}
      </option>
    ));
  return (
    <div>
      <div className="selected-date-and-time">
        <i>{isDaily ? 'DAILY' : 'HOURLY'}</i>
        {new Date(
          new Date(time).getTime() + (new Date(date).getTime() - offset),
        ).toLocaleDateString('en-GB', { hour: '2-digit', minute: '2-digit' })}
      </div>
      <div className={'grid grid-cols-5 gap-2'}>
        {poster.forecastWDElements.map((item, index) =>
          item.forecastWDSource.valueType === ValueTypeEnum.NUMERICAL ? (
            <div
              key={item.forecastWDSource.parameterType + item.forecastWDSource.value}
              className={`mb-2 property-card`}
            >
              <div>{item.forecastWDSource.parameterType.split(/(?=[A-Z])/).join(' ')}</div>
              <div>
                {JSON.stringify(
                  item.forecastWDSource.value?.find(
                    (val: { unit: string; value: number }) =>
                      val.unit === item.forecastWDSource.unitOfMeasurement,
                  ).value,
                ) ?? 'N/A'}
              </div>
              <Select
                className={'observed-select'}
                sizing={'sm'}
                value={item.forecastWDSource.unitOfMeasurement}
                onChange={(e) => {
                  updatePoster({
                    ...item,
                    forecastWDSource: {
                      ...item.forecastWDSource,
                      unitOfMeasurement: e.target.value,
                      location: { ...item.forecastWDSource.location, locationId: station },
                    },
                  });
                }}
              >
                {selectUnt(item.forecastWDSource.value)}
              </Select>
            </div>
          ) : (
            <div key={item.id}>
              <IconPicker
                isTemp
                weatherType={thisValue?.weatherType ?? 'N/A'}
                onSelect={(e) => {
                  setIcon(e);
                }}
                onClick={(e) => console.log(item.id, e)}
                active={false}
              />
            </div>
          ),
        )}
      </div>
    </div>
  );
};
