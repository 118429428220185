import { AiOutlineAppstore, AiOutlineFileAdd } from 'react-icons/ai';
import { CgProfile } from 'react-icons/cg';
import { FaSitemap } from 'react-icons/fa';
import { MdStars } from 'react-icons/md';

import { Multimedia, Settings, Users, Wallet } from '../../../assets/icons';
import { RolesEnum } from '../../../model/enums/RolesEnum';

export const menu = [
  {
    title: 'Dashboard',
    icon: AiOutlineAppstore,
    sub: [
      {
        title: 'Projects',
        route: './',
      },
      { title: 'Scheduled Jobs', route: 'scheduler' },
    ],
  },
  {
    title: 'Multimedia',
    icon: Multimedia,
    sub: [
      { title: 'Images', route: 'multimedia/image' },
      { title: 'Video', route: 'multimedia/video' },
      { title: 'Audio', route: 'multimedia/audio' },
      { title: 'Animation', route: 'multimedia/animation' },
      { title: 'Symbol', route: 'multimedia/symbol' },
    ],
  },
  {
    title: 'Purchased items',
    icon: Wallet,
    sub: [
      {
        title: 'Multimedia',
        route: 'purchased/multimedia',
        sub: [
          { title: 'Images', route: 'purchased/multimedia/image' },
          { title: 'Video', route: 'purchased/multimedia/video' },
          { title: 'Audio', route: 'purchased/multimedia/audio' },
          { title: 'Animation', route: 'purchased/multimedia/animation' },
        ],
      },
      {
        title: 'Templates',
        route: 'purchased/templates',
        sub: [
          { title: 'Project', route: 'purchased/templates/project' },
          { title: 'Scene', route: 'purchased/templates/scene' },
          { title: 'Map', route: 'purchased/templates/map' },
          { title: 'Custom Map', route: 'purchased/multimedia/custom_map' },
          { title: 'Custom Vector', route: 'purchased/multimedia/custom_vector' },
          { title: 'Poster', route: 'purchased/templates/poster' },
          { title: 'Composite', route: 'purchased/templates/geoposter' },
          { title: 'Icon Pack', route: 'purchased/templates/icon-set' },
        ],
      },
    ],
  },
  {
    title: 'Templates',
    icon: Multimedia,
    sub: [
      { title: 'Project', route: 'templates/project' },
      { title: 'Scene', route: 'templates/scene' },
      { title: 'Map', route: 'templates/map' },
      { title: 'Custom Map', route: 'multimedia/custom_map' },
      { title: 'Custom Vector', route: 'multimedia/custom_vector' },
      { title: 'Layer', route: 'templates/layer' },
      { title: 'Poster', route: 'templates/poster' },
      { title: 'Composite', route: 'templates/geoposter' },
      { title: 'Icon Pack', route: 'templates/icon-set' },
      { title: 'Fonts', route: 'templates/font' },
    ],
  },
  {
    title: 'Enterprise properties',
    icon: Settings,
    route: 'enterprise-properties',
    roles: [RolesEnum.ROLE_ACCOUNT_OWNER],
  },
  {
    title: 'User administration',
    icon: Users,
    roles: [RolesEnum.ROLE_ACCOUNT_OWNER],
    sub: [
      {
        title: 'Enterprise users',
        route: 'account-management/enterprise-users',
        roles: [RolesEnum.ROLE_ACCOUNT_OWNER],
      },
    ],
  },
  { title: 'Subscription', icon: MdStars, route: 'subscription' },
  { title: 'Wallet', icon: Wallet, route: 'wallet' },
  { title: 'Reports', icon: Multimedia, route: 'reports' },
];

export const dataProviderMenu = [
  { title: 'Dashboard', icon: AiOutlineAppstore, route: 'dataprovider' },
  { title: 'Products preview', icon: FaSitemap, route: 'dataprovider/products' },
  { title: 'New Data Product', icon: AiOutlineFileAdd, route: 'dataprovider/product-registration' },
  { title: 'Profile', icon: CgProfile, route: 'dataprovider/profile' },
];

export const adminMenu = [
  {
    title: 'User administration',
    icon: Users,
    roles: [RolesEnum.ROLE_ADMIN],
    sub: [
      {
        title: 'Enterprise',
        route: 'account-management/enterprise',
        roles: [RolesEnum.ROLE_ADMIN],
      },
      {
        title: 'Data providers',
        route: 'account-management/data-providers',
        roles: [RolesEnum.ROLE_ADMIN],
      },
    ],
  },
];
