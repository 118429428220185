import { useQuery, useQueryClient } from 'react-query';

import { axiosInstance } from '../axiosInstance';

const key = 'PALETTES_ID';
export const usePalettesById = (ids: string[]) =>
  useQuery(
    [key, ...ids],
    async () => {
      const colorPalettePromises = ids.map((id) => axiosInstance.get(`color-palette/${id}`));
      const colorPaletteResponses = await Promise.all(colorPalettePromises);

      return colorPaletteResponses.map((response) => {
        return { ...response.data };
      });
    },
    {
      enabled: !!ids.length,
      staleTime: Infinity,
    },
  );

export const useClearPaletteCache = () => {
  const queryClient = useQueryClient();
  return () => {
    queryClient.invalidateQueries(key);
  };
};
