import '../../../../molecules/palette/PaletteLegend.scss';
import '../../../../molecules/palette/PaletteMenu.scss';

import { AiOutlineEdit } from 'react-icons/ai';
import { RiDeleteBin5Line } from 'react-icons/ri';
import { toast } from 'react-toastify';

import { useDeletePalette } from '../../../../core/api/palette/useDeletePalette';
import { ColorPaletteDef } from '../../../../model/definitions/ColorPaletteDef';
import { getGradient } from '../../../../molecules/palette/utils';
import { DataProductInterface } from '../../pages/Products';

interface PaletteProps {
  value: ColorPaletteDef;
  palettes: ColorPaletteDef[];
  setPalettes: (palette: ColorPaletteDef[]) => void;
  editPalette: (e: string | undefined) => void;
  form: DataProductInterface;
  setForm: React.Dispatch<React.SetStateAction<DataProductInterface>>;
  updateProduct: (defaultColorPaletteIds: string[]) => void;
}

export const Palette = ({
  value,
  palettes,
  setPalettes,
  editPalette,
  form,
  setForm,
  updateProduct,
}: PaletteProps) => {
  const { mutate: deletePalette, isLoading: isLoadingDelete } = useDeletePalette();

  const handleDelete = (id: string) => {
    deletePalette(id, {
      onSuccess: () => {
        const filterDefaultColorPaletteIds = form.defaultColorPaletteIds.filter((i) => i !== id);
        const filterPalettes = palettes.filter((palette) => palette.id !== id);
        updateProduct(filterDefaultColorPaletteIds);
        setPalettes(filterPalettes);
        editPalette(undefined);
        toast.success('Palette successfully deleted');
      },
    });
  };

  const generatePalettePreview = (pal: ColorPaletteDef) => {
    const gradient = getGradient(pal);
    return (
      <div key={pal.id} className={`!bg-[#183885] palette-item`}>
        <span>{pal.paletteParamType.replaceAll('_', ' ')}</span>
        <span>{pal.name}</span>
        <div className={'flex items-center mx-3'}>
          <RiDeleteBin5Line
            color={'red'}
            onClick={() => !isLoadingDelete && handleDelete(pal.id)}
          />
          <AiOutlineEdit color={'yellow'} onClick={() => editPalette && editPalette(pal.id)} />
        </div>
        <div
          className="preview"
          style={{
            background: `linear-gradient(to right, ${gradient.join(',')})`,
          }}
        />
      </div>
    );
  };

  const generatePalettes = () => {
    if (value) {
      return generatePalettePreview(value);
    }
  };

  return (
    <>
      {value && <div className="palette-menu mb-1 !bg-[#f9f9f9] !w-fit">{generatePalettes()}</div>}
    </>
  );
};
