import { useQuery } from 'react-query';

import { axiosInstance } from '../../../core/api/axiosInstance';

export const key = ['SCHEDULERS'];
export const useGetCrons = (projectId: string) => {
  return useQuery([...key, projectId], async () => {
    const url = `/scheduler/jobs-by-user/${projectId}`;
    const res = await axiosInstance.get(url);
    return res.data;
  });
};
