import { useQuery } from 'react-query';

import { axiosInstance } from '../../../core/api/axiosInstance';

export const key = ['TRANSFER_INFO'];

export const useGetTransferInfo = ({ id, disabled }: { id: string; disabled: boolean }) => {
  return useQuery(
    [...key, id],
    async () => {
      const { data } = await axiosInstance('multimedia/transferInfo', {
        params: { fileName: id },
      });
      return data;
    },
    {
      enabled: !!id && disabled,
    },
  );
};
