import { useMutation } from 'react-query';

import { axiosInstance } from '../../../core/api/axiosInstance';
import { queryClient } from '../../../index';
import { key as getProjectsKey } from './useGetProjects';

const apiURL = 'project';
const useDeleteProject = () => {
  return useMutation(
    async (id: string) => {
      const { data } = await axiosInstance.delete(`${apiURL}/${id}`);
      return data;
    },
    {
      onSuccess: () => queryClient.invalidateQueries(getProjectsKey),
    },
  );
};

export default useDeleteProject;
