import { intervalToDuration } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';

export const useTimer = () => {
  const [timerInterval, setTimerInterval] = useState<Interval>({
    start: 0,
    end: 0,
  });
  const [intvId, setIntvId] = useState<NodeJS.Timer>();
  const startTimer = () => {
    const start = Date.now();
    setTimerInterval({ start: 0, end: 0 });
    const timerInt = setInterval(() => setTimerInterval({ start: start, end: Date.now() }), 100);
    setIntvId(timerInt);
  };
  const stopTimer = () => {
    setTimerInterval({ start: 0, end: 0 });
    clearInterval(intvId);
  };
  useEffect(() => {
    setTimerInterval({ start: 0, end: 0 });
  }, []);
  const result = useCallback(() => {
    const value = intervalToDuration(timerInterval);
    return `${String(value.minutes).padStart(2, '0')}:${String(value.seconds).padStart(2, '0')}`;
  }, [timerInterval]);
  return { startTimer, stopTimer, result };
};
