import { useQuery } from 'react-query';

import { ForecastWDLocationDef } from '../../../model/definitions/ForecastWDLocationDef';
import { axiosInstance } from '../axiosInstance';

const key = ['GET_FORECAST_LOCATIONS'];
export const useGetLocations = (searchKey?: string) =>
  useQuery(
    [...key, searchKey],
    async (): Promise<Array<ForecastWDLocationDef>> => {
      const { data } = await axiosInstance.get('forecast/locations', {
        params: {
          searchKey,
        },
      });
      return data;
    },
    {
      enabled: !!searchKey,
    },
  );
