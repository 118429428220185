export enum DrawingTypeEnum {
  FRONTS = 'Fronts',
  LINE_STRING = 'Line String',
  POLYGON = 'Polygon',
  CIRCLE = 'Circle',
  ARROW = 'Arrow',
  RECTANGLE = 'Rectangle',
  CLOSED_CURVED_LINE = 'Closed Curved Line',
  IMAGE = 'Image',
}

export enum DrawingTypeExternalEnum {
  FRONTS = 'Front',
  LINE_STRING = 'LineString',
  POLYGON = 'Polygon',
  CIRCLE = 'Circle',
  ARROW = 'Arrow',
  RECTANGLE = 'Rectangle',
  CLOSED_CURVED_LINE = 'closedCurvedLine',
  IMAGE = 'Image',
}
