import { QueryKey, useQuery } from 'react-query';

export const key: QueryKey = ['CURRENCY'];

export const useGetCurrency = () => {
  return useQuery([...key], async () => {
    const defaultValue = {
      name: 'US Dollar',
      symbol: '$',
      code: 'USD',
      label: '$ (USD)',
      exchangeRateUsd: 1,
    };
    const currency = JSON.parse(localStorage.getItem('selectedCurrency') ?? 'null');
    return currency ?? defaultValue;
  });
};
