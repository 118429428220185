import { useMutation } from 'react-query';

import { PosterTemplateSaveResponse } from '../../../model/DTO/PosterTemplateSaveResponse';
import { WeatherGraphTemplate } from '../../../model/DTO/WeatherGraphTemplate';
import { axiosInstance } from '../axiosInstance';

export const useCreatePosterTemplate = () =>
  useMutation(async (payload: WeatherGraphTemplate): Promise<PosterTemplateSaveResponse> => {
    const form = new FormData();
    form.append('request', JSON.stringify({ ...payload, thumbnailURLs: [] }));
    if (payload.thumbnailURLs && payload.thumbnailURLs.length) {
      payload.thumbnailURLs.forEach((th) => {
        form.append('thumbnailFiles', th.blob!);
      });
    }

    const { data } = await axiosInstance.post('weather-poster-template', form, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return data;
  });
