import { useQuery } from 'react-query';

import { axiosInstance } from '../../../core/api/axiosInstance';

export const key = ['MARKETPLACE_ITEM'];
export const useGetPublishedItem = (id: string, auth: boolean) => {
  return useQuery(
    key,
    async () => {
      const res = await axiosInstance.get(`marketplace-item/${id}`);
      return res.data;
    },
    {
      enabled: auth,
    },
  );
};
