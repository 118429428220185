import { useMutation } from 'react-query';

import { GeoPosterTemplateSaveResponse } from '../../../model/DTO/PosterTemplateSaveResponse';
import { axiosInstance } from '../axiosInstance';

export const useGetGeoPosterById = () =>
  useMutation(async (id: string): Promise<GeoPosterTemplateSaveResponse> => {
    const { data } = await axiosInstance.get(`weather-geo-poster-template/current/${id}`);
    return data;
  });
