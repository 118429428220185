import './style.scss';

import { BsBookmarkPlus } from 'react-icons/bs';
import { FaArrowLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

import Button from '../../atoms/Button/Button';

interface HeadingProps {
  title: string;
  isBookmarked?: boolean;
  editable?: boolean;
  bookmark?: () => void;
  onChange?: (e: string) => void;
  onPurchase?: () => void;
}
const Heading = ({
  title,
  isBookmarked,
  bookmark,
  editable,
  onChange,
  onPurchase,
}: HeadingProps) => {
  const navigate = useNavigate();
  return (
    <div className={'heading'}>
      {editable ? (
        <input
          value={title || ''}
          style={{ fontSize: '2.3rem', lineHeight: '3rem', fontWeight: 700 }}
          onChange={(e) => onChange && onChange(e.target.value)}
        />
      ) : (
        <h2>{title}</h2>
      )}

      <Button buttonType="secondary" onClick={() => navigate(-1)} style={{ whiteSpace: 'nowrap' }}>
        <FaArrowLeft fill="#2176ff" />
        Go back
      </Button>

      {bookmark && (
        <Button
          shape={'rounded'}
          onClick={() => bookmark()}
          buttonType={isBookmarked ? 'primary' : 'secondary'}
        >
          <BsBookmarkPlus />
        </Button>
      )}
    </div>
  );
};

export default Heading;
