export const WIND_PREPROCESSING_HEATMAP_VERT = `
attribute vec4 a_texCoord;

uniform vec2 dataResolution;
uniform vec2 resolution;

varying vec2 v_pixel_pos;

void main() {

    vec2 clipSpace = a_texCoord.xy * 2.0 - 1.0;
    //clipSpace.y = -clipSpace.y;

    v_pixel_pos = a_texCoord.zw/dataResolution;
    gl_Position = vec4(clipSpace, 0, 1);
}
`;
