import './style.scss';

import { message } from 'antd';
import { useContext, useEffect, useRef, useState } from 'react';
import { AiOutlinePlus, AiOutlineSearch } from 'react-icons/ai';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Button from '../../../atoms/button/Button';
import { useDebounce } from '../../../hooks/useDebounce';
import {
  SortingEnum,
  SortingProjectEnum,
  SortingWorkspaceEnum,
} from '../../../model/enums/SortingEnum';
import Sorting from '../../marketplace-new/molecules/Sorting/Sorting';
import GridLayout from '../layout-NEW/GridLayout';
import { AddFontModal } from '../modals/AddFontModal';
import { AddModal } from '../modals/AddModal';
import useCreateFont from '../queries-NEW/useCreateFont';
import { useCreateIconPack } from '../queries-NEW/useCreateIconPack';
import useDeleteFont from '../queries-NEW/useDeleteFont';
import useDeleteTemplate from '../queries-NEW/useDeleteTemplate';
import useUpdateInfo from '../queries-NEW/useEditBasicInfo';
import { useEditFont } from '../queries-NEW/useEditFont';
import useGetFonts from '../queries-NEW/useGetFonts';
import useGetTemplates from '../queries-NEW/useGetTemplates';
import useShareTemplates from '../queries-NEW/useShareTemplates';
import FilterItems from './components/FilterItems';
import { UpdateInterface } from './Projects';
import SortContext from './sortContext';

interface CustomFormData {
  [k: string]: string;
}

export const hasDuplicateFieldValue = (dataArray: any) => {
  for (let i = 0; i < dataArray.length - 1; i++) {
    for (let j = i + 1; j < dataArray.length; j++) {
      if (dataArray[i].type === dataArray[j].type) {
        return true;
      }
    }
  }
  return false;
};

const Templates = () => {
  const searchRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(0);
  const [search, setSearch] = useState<string>('');
  const [add, setAdd] = useState<boolean>(false);
  const [addFont, setAddFont] = useState<boolean>(false);
  const [fontWarningId, setFontWarningId] = useState<string>();
  const [addForm, setAddForm] = useState<CustomFormData>();
  const { mutate, isLoading: loadCreate } = useCreateIconPack();
  const { type } = useParams() as unknown as {
    type:
      | 'project'
      | 'scene'
      | 'weather-poster'
      | 'weather-geo-poster'
      | 'font'
      | 'map'
      | 'icon-set'
      | 'geoposter'
      | 'layer';
  };
  const { sortAscDesc, sortByField, handleSort } = useContext(SortContext);
  const searchKey = useDebounce(search, 300);
  const [activeTab, setActiveTab] = useState<{ value: string; label: string }>({
    value: 'USER',
    label: `My ${type}s`,
  });
  const {
    data: templates,
    isLoading,
    isError,
    error,
    refetch,
  } = useGetTemplates({
    page,
    type,
    searchKey,
    sort: sortByField + ',' + sortAscDesc,
    activeTab: activeTab.value,
  });

  const { data: fonts, refetch: refetchFonts } = useGetFonts({
    page,
    type,
    searchKey,
    sort: sortByField + ',' + sortAscDesc,
    activeTab: activeTab.value,
  });
  const { mutate: createFont, isLoading: loadCreateFont } = useCreateFont();
  const shareTemplates = useShareTemplates();

  const [fontName, setFontName] = useState('');
  const [fontDescription, setFontDescription] = useState('');
  const [uploading, setUploading] = useState<Array<File>>([]);

  const resetAddFontModal = () => {
    setFontName('');
    setFontDescription('');
    setUploading([]);
  };

  const handleUpload = () => {
    if (uploading.length === 0) {
      message.error('Please select at least one file.');
      return;
    }

    if (!fontName) {
      message.error('Please enter a name.');
      return;
    }

    const formData = new FormData();
    formData.append('font', JSON.stringify({ name: fontName, description: fontDescription }));
    uploading.forEach((file) => {
      file && formData.append('fontFiles', file);
    });

    createFont(formData, {
      onSuccess: (response) => {
        const hasDuplicates = hasDuplicateFieldValue(response.data.fontVariants);
        if (hasDuplicates) {
          setFontWarningId(response.data.id);
          toast.error('Metadata cannot be read from files. Please manually add font types.');
        }
        setAddFont(false);
        resetAddFontModal();
      },
    });
  };

  const deleteTemplate = useDeleteTemplate();
  const deleteFont = useDeleteFont();
  const updateProject = useUpdateInfo();
  const updateFont = useEditFont();
  const onDelete = (id: string) =>
    type !== 'font'
      ? deleteTemplate.mutate(
          { id: id, type },
          {
            onSuccess: async () => {
              if (templates?.content.length === 1 && page > 0) {
                setPage(page - 1);
              }
              toast.success('Item deleted');
            },
            onError: async (error: any) => {
              if (error?.response?.status === 403) {
                toast.error('You are not allowed to delete item');
              } else {
                toast.error('Error deleting item');
              }
            },
          },
        )
      : deleteFont.mutate(
          { id: id, type },
          {
            onSuccess: async () => {
              if (templates?.content.length === 1 && page > 0) {
                setPage(page - 1);
              }
            },
          },
        );

  const onUpdate = (data: UpdateInterface) => {
    const fontData = {
      id: data.id,
      name: data.newName,
      description: data.newDescription,
    };
    if (type === 'icon-set') {
      //@ts-ignore
      fontData.isDefault = data.newIsDefault;
    }
    type !== 'font' &&
      updateProject.mutate(data, {
        onSuccess: async () => toast.success("Item's basic info updated"),
        onError: async () => toast.error('Error updating basic info'),
      });
    type === 'font' && updateFont.mutate(fontData);
  };

  const onCreate = () => {
    addForm?.name &&
      addForm?.description &&
      mutate(
        { name: addForm.name, description: addForm?.description },
        {
          onSuccess: (data) => navigate('edit/' + data.id),
        },
      );
  };

  const onShare = (id: string, type: string) =>
    shareTemplates.mutate(
      { id, type },
      {
        onSuccess: async () => {
          toast.success(`${type} shared`);
        },
      },
    );

  const onChange = (value: string, name: string) => {
    setAddForm({ ...addForm, [name]: value });
  };

  useEffect(() => {
    setSearch('');
    setPage(0);
    setActiveTab({
      value: 'USER',
      label: `My ${type}s`,
    });
  }, [type]);

  useEffect(() => {
    if (page !== 0) {
      const timer = setTimeout(() => {
        setPage(0);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [search]);

  useEffect(() => {
    type !== 'font' ? refetch() : refetchFonts();
  }, [page, searchKey, sortAscDesc, sortByField, activeTab, refetch, refetchFonts]);

  return (
    <>
      {/* <StyledText>Sample Text with Google Sans font</StyledText> */}
      <div className={'flex ws-fixed-header'}>
        <div className={'ws-temp-title'}>
          <h1>
            {type !== 'geoposter'
              ? type.charAt(0).toUpperCase() + type.substring(1).replace('-', ' ') + 's'
              : 'Composites'}
          </h1>
        </div>
        {type === 'icon-set' && (
          <Button
            className={`ws-button`}
            onClick={() => setAdd(true)}
            label="Add Set"
            icon={<AiOutlinePlus />}
          />
        )}
        {type === 'font' && (
          <Button
            className={`ws-button`}
            onClick={() => setAddFont(true)}
            label="Add Font"
            icon={<AiOutlinePlus />}
          />
        )}
        <FilterItems activeTab={activeTab} type={type} setActiveTab={setActiveTab} />
        <div className={'ws-input'} style={{ height: '32px' }}>
          <input
            ref={searchRef}
            value={search}
            autoFocus
            onChange={(e) => setSearch && setSearch(e.target.value)}
            placeholder={'Type here...'}
            className="w-full 2xl:!w-[300px]"
          />
          <AiOutlineSearch color={'rgba(0,0,0,0.3)'} size={24} />
        </div>
        {((templates?.content && templates?.content.length > 1) ||
          (fonts?.content && fonts?.content.length > 1)) && (
          <div className="ml-auto">
            <Sorting
              sortAscDesc={sortAscDesc}
              sorting={
                templates?.content
                  ? type === 'project'
                    ? SortingProjectEnum
                    : SortingWorkspaceEnum
                  : SortingEnum
              }
              handleSort={handleSort}
              sortByField={sortByField}
            />
          </div>
        )}
      </div>
      {add && (
        <AddModal
          open={add}
          onClose={() => setAdd(false)}
          onCreate={onCreate}
          onChange={(value, name) => onChange(value, name)}
          name={addForm?.name}
          description={addForm?.description}
          loading={loadCreate}
        />
      )}
      {addFont && (
        <AddFontModal
          open={addFont}
          fontName={fontName}
          setFontName={setFontName}
          fontDescription={fontDescription}
          setFontDescription={setFontDescription}
          setUploading={setUploading}
          onClose={() => {
            setAddFont(false);
            resetAddFontModal();
          }}
          onCreateFont={handleUpload}
          onChange={(value, name) => onChange(value, name)}
          loading={loadCreateFont}
        />
      )}
      <GridLayout
        searchString={searchKey}
        link={(id: string) => navigate(`preview/${id}`)}
        onPublish={(id: string) => navigate(id)}
        onShare={(id, type) => onShare(id, type)}
        error={error}
        onDelete={(id) => onDelete(id)}
        onDefault={(
          data: Pick<
            UpdateInterface,
            'newName' | 'newDescription' | 'id' | 'versionId' | 'newIsDefault'
          >,
        ) =>
          updateProject.mutate(
            { ...data, type: `${type.toUpperCase().replace(/-/g, '_')}_TEMPLATE`, key: type },
            {
              onSuccess: async () =>
                toast.success(`Successfully set icon pack ${data.newName} as default`),
              onError: async () =>
                toast.error(`Error while setting icon pack ${data.newName} as default`),
            },
          )
        }
        onEdit={(
          data: Pick<
            UpdateInterface,
            'newName' | 'newDescription' | 'id' | 'versionId' | 'newIsDefault'
          >,
        ) =>
          onUpdate({
            ...data,
            type: `${type.toUpperCase().replace(/-/g, '_')}_TEMPLATE`,
            key: type,
          })
        }
        onGetTemplate={(id) => type === 'project' && navigate(`project-template/${id}`)}
        loading={isLoading}
        isError={isError}
        pages={templates?.totalPages || fonts?.totalPages}
        currentPage={page}
        changePage={(e) => setPage(e)}
        onSearch={setSearch}
        contents={templates?.content || fonts?.content}
        type={type}
        fontWarningId={fontWarningId}
        setFontWarningId={setFontWarningId}
      />
    </>
  );
};

export default Templates;
