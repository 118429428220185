import { Select } from 'flowbite-react';
import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../../../atoms/button/Button';
import Input from '../../../../atoms/input/Input';
import { usePropertyGridActive } from '../../../../hooks/usePropertyGridActive';
import { AnimationPanelDef } from '../../../../model/definitions/AnimationPanelDef';
import { TimestampElementDef } from '../../../../model/definitions/TimestampElementDef';
import { TimeStepEnum } from '../../../../model/enums/TimeStepEnum';
import { convertTimeStepEnumToString } from '../../../../organisms/addElementModal/addTimestampModal';
import { ActiveDef, setPropertyGridActiveHash } from '../../../../store/slices/active-slice';
import { updateTimestampLayer } from '../../../../store/slices/project-slice';
import { RootState } from '../../../../store/store';
import { PropertySection } from '../components/PropertySection';
import { FormattingHelpPage } from '../panels/timeFrameIndicator/FormattingHelpPage';
import { formats } from '../panels/timeFrameIndicator/util';
import GridItem from '../shared/GridItem';
import GridWrapper from '../shared/GridWrapper';

function hasValue(obj: Record<string, string>, value: string | undefined): boolean {
  if (value) return !Object.values(obj).includes(value);
  return false;
}
export const FormatProperties: FC<Partial<TimestampElementDef>> = ({ step, format }) => {
  const dispatch = useDispatch();
  const { activeElement, activeScene } = useSelector<RootState, ActiveDef>((state) => state.active);
  function onFocus(path: Leaves<AnimationPanelDef>) {
    dispatch(setPropertyGridActiveHash({ activeElement, focusedEl: path }));
  }
  const { isOpened, lastFocused } = usePropertyGridActive(['step', 'format']);
  const [isCustom, setIsCustom] = useState(hasValue(formats, format));
  const [isHelp, setIsHelp] = useState<boolean>(false);
  const formatOptions = () => {
    const optionsArray = [];
    for (const key in formats) {
      optionsArray.push(
        // @ts-ignore
        <option key={key} value={formats[key as keyof formats]}>
          {key}
        </option>,
      );
    }
    return optionsArray;
  };
  const stepOptions = Object.keys(TimeStepEnum).map((step) => (
    <option key={step} value={step}>
      {convertTimeStepEnumToString(step as TimeStepEnum)}
    </option>
  ));
  const onChange = (value: string, propertyPath: Leaves<TimestampElementDef>) =>
    dispatch(updateTimestampLayer({ activeScene, elementId: activeElement, propertyPath, value }));
  return (
    <PropertySection isOpened={isOpened} label={'format'}>
      <div className="prop-wrapper">
        <GridWrapper>
          <GridItem
            label={'Step'}
            item={
              <Select
                onChange={(e) => onChange(e.target.value, 'step')}
                value={step}
                onFocus={() => onFocus('name')}
                autoFocus={lastFocused === 'name'}
              >
                {stepOptions}
              </Select>
            }
          />
          <GridItem
            label={'Format'}
            item={
              <Select
                onChange={(e) => {
                  if (e.target.value === 'CUSTOM') {
                    setIsCustom(true);
                  } else {
                    setIsCustom(false);
                    onChange(e.target.value, 'format');
                  }
                }}
                value={isCustom ? 'CUSTOM' : format}
              >
                {formatOptions()}
              </Select>
            }
          />
          {isCustom ? (
            <>
              <GridItem
                label={'Custom format'}
                item={
                  <Input
                    onChange={(e) => {
                      onChange(e.target.value, 'format');
                    }}
                    style={{ width: '100%' }}
                    value={format}
                  />
                }
              />
              <GridItem
                noBorderBg
                label={'Help'}
                item={
                  <Button
                    label={'?'}
                    shape={'round'}
                    onClick={() => setIsHelp(true)}
                    style={{ margin: 0 }}
                    className={'self-start mt-2'}
                  />
                }
              />
            </>
          ) : null}
        </GridWrapper>
      </div>
      {isHelp && <FormattingHelpPage isOpen={isHelp} onClose={() => setIsHelp(false)} />}
    </PropertySection>
  );
};
