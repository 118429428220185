export enum FrontTypeEnum {
  WARM_FRONT = 'Warm Front',
  COLD_FRONT = 'Cold Front',
  STATIONARY_FRONT = 'Stationary Front',
  OCCLUDED_FRONT = 'Occluded Front',
  OCCLUDED_FRONT_V2 = 'Occluded Front v2',
  THROUGH_OR_OUTFLOW_BOUNDARY = 'Through Or Outflow Boundary',
}

export enum FrontTypeExternalEnum {
  WARM_FRONT = 'warmFront',
  COLD_FRONT = 'coldFront',
  STATIONARY_FRONT = 'stationaryFront',
  OCCLUDED_FRONT = 'occludedFront',
  OCCLUDED_FRONT_V2 = 'occludedFrontV2',
  THROUGH_OR_OUTFLOW_BOUNDARY = 'troughOrOutflowBoundary',
}
