import { boundingExtent, getBottomLeft, getBottomRight, getTopLeft, getTopRight } from 'ol/extent';
import Polygon from 'ol/geom/Polygon';
import { fromUserCoordinate, getUserProjection } from 'ol/proj';

export function createBoxWithRatio(ratio = [16, 9], topCoordRef) {
  return function (coordinates, geometry, projection) {
    const extent = boundingExtent(
      /** @type {LineCoordType} */ [coordinates[0], coordinates[coordinates.length - 1]].map(
        function (coordinate) {
          return fromUserCoordinate(coordinate, projection);
        },
      ),
    );
    const boxCoordinates = [
      [
        getBottomLeft(extent),
        getBottomRight(extent),
        getTopRight(extent),
        getTopLeft(extent),
        getBottomLeft(extent),
      ],
    ];
    const isDrawStart = getBottomLeft(extent)[1] === getTopRight(extent)[1];
    if (isDrawStart) {
      topCoordRef.current = getBottomLeft(extent)[1];
    }

    const isSouth = topCoordRef.current > getBottomLeft(extent)[1];

    transformBoxCoordinatesBasedOnRatio(boxCoordinates, ratio, isSouth);

    if (geometry) {
      geometry.setCoordinates(boxCoordinates);
    } else {
      geometry = new Polygon(boxCoordinates);
    }
    const userProjection = getUserProjection();

    if (userProjection) {
      geometry.transform(projection, userProjection);
    }
    return geometry;
  };
}

export function transformBoxCoordinatesBasedOnRatio(coordinates, ratio, isSouth) {
  const [x, y] = ratio;

  const width = Math.abs(coordinates[0][1][0] - coordinates[0][0][0]);
  const expectedHeight = width * (y / x);
  if (isSouth) {
    coordinates[0][0][1] = coordinates[0][2][1] - expectedHeight;
    coordinates[0][1][1] = coordinates[0][2][1] - expectedHeight;
    coordinates[0][4][1] = coordinates[0][2][1] - expectedHeight;
  } else {
    coordinates[0][2][1] = coordinates[0][0][1] + expectedHeight;
    coordinates[0][3][1] = coordinates[0][0][1] + expectedHeight;
  }
}
