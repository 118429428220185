import { useQuery } from 'react-query';

import { ParameterUnitDTO } from '../../../model/DTO/ParameterUnitDTO';
import { axiosInstance } from '../axiosInstance';

export const useGetModelUnits = (parameterName: string | undefined) => {
  return useQuery(['useGetModelUnits', parameterName], async () => {
    if (parameterName) {
      const result = await axiosInstance.get(`units/wd?parameter=${parameterName}`);
      return result.data as ParameterUnitDTO[];
    } else {
      return [];
    }
  });
};

export const useGetPointUnits = (parameterName: string | undefined) => {
  return useQuery(['useGetPointUnits', parameterName], async () => {
    if (parameterName) {
      const result = await axiosInstance.get(`units/point?parameter=${parameterName}`);
      return result.data as ParameterUnitDTO[];
    } else {
      return [];
    }
  });
};
