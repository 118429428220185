import axios from 'axios';
import { toast } from 'react-toastify';

import { _kc } from '../auth/KeycloakService';

const BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/api/store/`;

export const axiosInstance = axios.create({ baseURL: BASE_URL });

axiosInstance.interceptors.request.use(async (config) => {
  if (_kc.authenticated && config?.headers) {
    config.headers['Authorization'] = `Bearer ${_kc.token}`;
    return config;
  }
  await _kc.updateToken(5);
  if (!!_kc.token && config?.headers) config.headers['Authorization'] = `Bearer ${_kc.token}`;
  return config;
});
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log(error);
    if (error?.response.status === 403) {
      toast.error(error?.response.data.message);
    }
    return Promise.reject(error);
  },
);
