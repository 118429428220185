import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { queryClient } from '../../..';
import { axiosInstance } from '../../../core/api/axiosInstance';
import { key } from './useGetTemplate';

export const usePublishToMarketplace = () => {
  return useMutation(
    async (data: { data: FormData }) => {
      const response = await axiosInstance.post('marketplace/publish', data.data, {
        headers: { 'content-type': 'multipart/form-data' },
      });
      return response.data;
    },
    {
      onSuccess: () => {
        toast.success('Published');
        queryClient.invalidateQueries(key);
      },
      onError: (error: AxiosError) => {
        if (error.request.status !== 403) {
          const errorMessage = (error.response?.data as { message?: string })?.message;
          if (errorMessage) {
            toast.error(errorMessage);
          } else {
            toast.error('An error occurred.');
          }
        }
      },
    },
  );
};
