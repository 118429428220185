import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 } from 'uuid';

import LoadingIndicator from '../../../../../atoms/loadingIndicator/LoadingIndicator';
import { WeatherPosterDef } from '../../../../../model/definitions/WeatherPosterDef';
import {
  ActiveDef,
  resetForecastTemp,
  resetObservedTemp,
  tempDone,
} from '../../../../../store/slices/active-slice';
import { addGraphLayer } from '../../../../../store/slices/project-slice';
import { RootState } from '../../../../../store/store';

interface AddTemplateProps {
  onClose: () => void;
  step: 'observed' | 'forecast' | 'add';
}
export const AddTemplate = ({ onClose, step }: AddTemplateProps) => {
  const dispatch = useDispatch();
  const { activeScene, observedTemp, forecastTemp, dispatchedTemp } = useSelector<
    RootState,
    ActiveDef
  >((state) => state.active);
  const addLayer = async () => {
    const updatePointLocationsObserved = (temp: WeatherPosterDef) => {
      const observed = temp.observedWDElements;
      return temp.pointLocation.map((location) => {
        const loc =
          observed.find((item) => item.pointWDGroupId === location.pointWDGroupId)?.observedWDSource
            .station.fullName ?? '';
        const labelMatch = loc?.match(/\((\d+)\)\s(.+)/);
        const locName = labelMatch ? labelMatch[2] : loc;
        return {
          ...location,
          name: locName,
          description: locName,
          value: locName,
          maskValue: locName,
        };
      });
    };
    const updatePointLocationsForecast = (temp: WeatherPosterDef) => {
      const forecast = temp.forecastWDElements;
      return temp.pointLocation.map((location) => {
        const loc =
          forecast.find((item) => item.pointWDGroupId === location.pointWDGroupId)?.forecastWDSource
            .location.name ?? '';
        const labelMatch = loc?.match(/\((\d+)\)\s(.+)/);
        const locName = labelMatch ? labelMatch[2] : loc;
        return {
          ...location,
          name: locName,
          description: locName,
          value: locName,
          maskValue: locName,
        };
      });
    };
    forecastTemp.forEach((temp) => {
      const data = temp.forecastWDElements.map((elem) => {
        return {
          ...elem,
          id: v4(),
        };
      });
      const dates = temp.pointDates.map((elem) => {
        return {
          ...elem,
          id: v4(),
        };
      });
      dispatch(
        addGraphLayer({
          graphLayer: {
            ...temp,
            forecastWDElements: data,
            pointDates: dates,
            id: v4(),
            pointLocation: updatePointLocationsForecast(temp),
          },
          activeScene,
        }),
      );
    });
    observedTemp.forEach((temp) =>
      dispatch(
        addGraphLayer({
          graphLayer: { ...temp, id: v4(), pointLocation: updatePointLocationsObserved(temp) },
          activeScene,
        }),
      ),
    );
    dispatch(tempDone());
    dispatch(resetObservedTemp());
    dispatch(resetForecastTemp());
  };
  useEffect(() => {
    step === 'add' && addLayer();
  }, []);
  useEffect(() => {
    if (!dispatchedTemp && step === 'add') onClose();
  }, [dispatchedTemp]);
  return <LoadingIndicator />;
};
