export enum MarketplaceTemplateEnum {
  PROJECT_TEMPLATE = 'PROJECT_TEMPLATE',
  SCENE_TEMPLATE = 'SCENE_TEMPLATE',
  MAP_TEMPLATE = 'MAP_TEMPLATE',
  CUSTOM_MAP_TEMPLATE = 'CUSTOM_MAP_TEMPLATE',
  CUSTOM_VECTOR_TEMPLATE = 'CUSTOM_VECTOR_TEMPLATE',
  WEATHER_POSTER_TEMPLATE = 'WEATHER_POSTER_TEMPLATE',
  WEATHER_GEO_POSTER_TEMPLATE = 'WEATHER_GEO_POSTER_TEMPLATE',
  ICON_SET_TEMPLATE = 'ICON_SET_TEMPLATE',
  IMAGE_TEMPLATE = 'IMAGE_TEMPLATE',
  VIDEO_TEMPLATE = 'VIDEO_TEMPLATE',
  AUDIO_TEMPLATE = 'AUDIO_TEMPLATE',
  ANIMATION_TEMPLATE = 'ANIMATION_TEMPLATE',
}
