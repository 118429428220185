import { useEffect, useState } from 'react';

function useDebounce<T>(value: T, delay = 500) {
  const [debouncedVal, setDebouncedVal] = useState(value);

  useEffect(() => {
    const tOut = setTimeout(
      () => {
        setDebouncedVal(value);
      },
      value ? delay : 0, // return immeditely if search is ""
    );
    return () => {
      clearTimeout(tOut);
    };
  }, [value, delay]);

  return debouncedVal;
}

export { useDebounce };
