import './access-file-info.scss';

import { Alert, Form, Input, Select, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useRef } from 'react';
import { AiOutlineCopy } from 'react-icons/ai';
import { toast } from 'react-toastify';

import { DataProductTypeEnum } from '../../../model/enums/DataProductTypeEnum';
import { ProtocolTypeEnum } from '../../../model/enums/ProtocolTypeEnum';
import { ScrapingEngineTypeEnum } from '../../../model/enums/ScrapingEngineTypeEnum';
import { DataFormInterface } from './DataFilterSetup';

const { TextArea } = Input;

const AccessFileInfo = ({ form, setForm, errors }: DataFormInterface) => {
  const pathRef = useRef<HTMLElement>(null);
  const perlRef = useRef<HTMLElement>(null);

  const handleCopyClick = (ref: React.RefObject<HTMLElement>) => {
    if (ref.current) {
      const textToCopy = ref.current.innerText;

      navigator.clipboard.writeText(textToCopy).then(() => {
        toast.success('Text copied');
      });
    }
  };
  const handleAccessInformation = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name !== 'ports') {
      setForm({
        ...form,
        accessInformation: { ...form.accessInformation, [e.target.name]: e.target.value },
      });
    } else {
      const array = e.target.value.split(',');
      setForm({
        ...form,
        accessInformation: { ...form.accessInformation, [e.target.name]: array },
      });
    }
  };

  const options = Object.entries(ProtocolTypeEnum).map((o) => {
    return {
      value: o[0],
      label: o[1],
    };
  });

  const handleDropdownChange = (value: ProtocolTypeEnum) => {
    setForm((form) => ({
      ...form,
      accessInformation: {
        ...form.accessInformation,
        protocol: value,
        ports: getDefaultPort(value),
      },
    }));
  };
  const handleFormatDropdownChange = (value: string) => {
    setForm((form) => ({
      ...form,
      fileInformation: {
        ...form.fileInformation,
        fileFormat: value,
      },
    }));
  };

  const getDefaultPort = (protocol: ProtocolTypeEnum) => {
    switch (protocol) {
      case ProtocolTypeEnum.FTP:
        return ['21'];
      case ProtocolTypeEnum.HTTP:
        return ['80'];
      case ProtocolTypeEnum.HTTPS:
        return ['443'];
      case ProtocolTypeEnum.SFTP:
        return ['22'];
      default:
        return ['21'];
    }
  };

  const handleFileInformation = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      fileInformation: { ...form.fileInformation, [e.target.name]: e.target.value },
    });
  };

  const getFileFormatOptions = () => {
    return form.productType === 'MODEL'
      ? ['grb', 'grb2'].map((format) => ({ value: format, label: format }))
      : ['.pbm', '.pgm', '.ppm', '.pxm', '.pnm', '.png', '.tiff', '.tif'].map((format) => ({
          value: format,
          label: format,
        }));
  };

  const handleScrapingEngineType = (e: string) => {
    setForm({
      ...form,
      scrapingEngineType: e,
    });
  };

  const handleShFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          if (e.target && e.target.result) {
            //@ts-expect-error
            const base64ShFile = e.target.result.split(',')[1];
            setForm({ ...form, base64ShFile });
          }
        };
        reader.readAsDataURL(file);
      }
    }
  };

  useEffect(() => {
    if (form.scrapingEngineType !== ScrapingEngineTypeEnum.WITHOUT_SCRAPING) {
      form.dataFilterSetup.updateFrequency = '* 1 * * *';
      form.dataFilterSetup.dataStartDate = dayjs().valueOf();
    } else {
      form.dataFilterSetup.updateFrequency = undefined;
      form.dataFilterSetup.dataStartDate = undefined;
    }
  }, [form.scrapingEngineType]);

  return (
    <>
      <Alert
        className="mb-4 text-left"
        description={
          <p>
            Supported types:{' '}
            {form.productType === DataProductTypeEnum.MODEL
              ? 'grb, grb2'
              : 'grb, grb2, pbm, pgm, ppm, pxm ,pnm, png, tiff, tif'}
          </p>
        }
        type="info"
        closable
      />
      <Form.Item label="Scraping Engine Type" className="mb-0">
        <Select
          className="mb-7"
          value={form.scrapingEngineType}
          onChange={(e) => handleScrapingEngineType(e)}
          options={Object.values(ScrapingEngineTypeEnum).map((item) => ({
            label:
              item === ScrapingEngineTypeEnum.WITHOUT_SCRAPING ? (
                <Tooltip title="Direct upload to our system">{item}</Tooltip>
              ) : (
                item
              ),
            value: item,
            disabled: item === ScrapingEngineTypeEnum.GEO_STREAM_DSL,
          }))}
        />
      </Form.Item>
      {form.scrapingEngineType === ScrapingEngineTypeEnum.GEO_STREAM_DSL ? (
        <>
          <Form.Item label="Username">
            <Input
              name="username"
              value={form.accessInformation?.username}
              onChange={(e) => handleAccessInformation(e)}
            />
          </Form.Item>
          <Form.Item label="Password">
            <Input
              name="password"
              value={form.accessInformation?.password}
              onChange={(e) => handleAccessInformation(e)}
            />
          </Form.Item>
          <Form.Item
            label="URL"
            required
            validateStatus={!form.accessInformation?.url && errors.url ? 'error' : ''}
            help={!form.accessInformation?.url && errors.url}
          >
            <Input
              name="url"
              value={form.accessInformation?.url}
              onChange={(e) => handleAccessInformation(e)}
            />
          </Form.Item>
          <Form.Item
            label="Protocol"
            required
            validateStatus={!form.accessInformation?.protocol && errors.protocol ? 'error' : ''}
            help={!form.accessInformation?.protocol && errors.protocol}
          >
            <Select value={form.accessInformation?.protocol} onChange={handleDropdownChange}>
              {options.map((o) => (
                <Select.Option key={o.value} value={o.value}>
                  {o.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Ports">
            <Input
              name="ports"
              value={form.accessInformation?.ports}
              onChange={(e) => handleAccessInformation(e)}
            />
          </Form.Item>
          <Form.Item label="Root folder">
            <Input
              name="folderName"
              value={form.fileInformation?.folderName}
              onChange={(e) => handleFileInformation(e)}
            />
          </Form.Item>
          <Form.Item
            label="Regex"
            required
            validateStatus={!form.fileInformation?.regexName && errors.regexName ? 'error' : ''}
            help={!form.fileInformation?.regexName && errors.regexName}
          >
            <Input
              value={form.fileInformation?.regexName}
              name="regexName"
              onChange={(e) => handleFileInformation(e)}
            />
          </Form.Item>
          <Form.Item label="File format" className="w-full">
            <Select value={form.fileInformation?.fileFormat} onChange={handleFormatDropdownChange}>
              {getFileFormatOptions().map((format) => (
                <Select.Option key={format.value} value={format.value}>
                  {format.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </>
      ) : (
        form.scrapingEngineType === ScrapingEngineTypeEnum.PROVIDER_SCRIPTING && (
          <>
            <Form.Item
              label="SH File"
              required
              validateStatus={!form.base64ShFile && errors.base64ShFile ? 'error' : ''}
              help={!form.base64ShFile && errors.base64ShFile}
            >
              <Input value={''} type="file" accept=".sh" onChange={(e) => handleShFile(e)} />
            </Form.Item>
            {form.base64ShFile && (
              <Form.Item label="SH File Text">
                <TextArea
                  value={atob(form.base64ShFile)}
                  autoSize={{ minRows: 1, maxRows: 10 }}
                  disabled
                />
              </Form.Item>
            )}
            <div className="w-2/3 mx-auto text-left">
              <h2 className="mb-2 text-2xl">Guideline for Writing the sh Script:</h2>
              <ul className="list-disc list-inside">
                <li>
                  Use
                  <a href="https://www.gnu.org/software/wget/" target="_blank" rel="noreferrer">
                    <b className="text-[#2176ff]"> wget </b>
                  </a>
                  for downloading.
                </li>
                <li>Download data to the current directory.</li>
                <li>
                  In the name of the output file, please put the full path to the file separated by
                  dots.
                </li>
              </ul>
              <div className="relative">
                <pre className="bg-gray-200 px-4 py-8 rounded-lg w-[100%] mx-auto my-4 overflow-x-scroll">
                  <code ref={pathRef} className="text-gray-800 text-sm">
                    {`host=https://nomads.ncep.noaa.gov\nstatic_url_part=pub/data/nccf/com/gens/prod\n\nsampling_hour=00\ntimestep_short=`}
                    `printf "%.3d" $i` <br /> <br />
                    current_date=`perl -e '($mday, $mon, $year) = (gmtime)[3..5];
                    printf("%4d_%02d_%02d",$year+1900, $mon+1, $mday+0);'` <br /> <br />
                    {`link_file_name=geavg.t\${sampling_hour}z.pgrb2s.0p25.f\${timestep_short}\n\n`}
                    <b>{`dynamic_url_part=gefs.\${current_date}/\${sampling_hour}/atmos/pgrb2sp25\nresult_file_name=gefs.\${current_date}.\${sampling_hour}.atmos.pgrb2sp25.\${link_file_name}\n\nwget --continue --output-document=\${result_file_name} \${host}/\${static_url_part}/\${dynamic_url_part}/\${link_file_name}`}</b>
                  </code>
                  <AiOutlineCopy
                    className="inline absolute top-2 right-2 cursor-pointer"
                    size={18}
                    onClick={() => handleCopyClick(pathRef)}
                  />
                </pre>
              </div>
              <ul className="list-disc list-inside">
                <li>
                  You can use
                  <a href="https://www.perl.org/" target="_blank" rel="noreferrer">
                    <b className="text-[#2176ff]"> Perl </b>
                  </a>
                  if you need some processing.
                </li>
              </ul>
              <div className="relative">
                <pre className="bg-gray-200 px-4 py-8 rounded-lg w-[100%] mx-auto my-4 overflow-x-scroll">
                  <code ref={perlRef} className="text-gray-800 text-sm">
                    current_date=`perl -e '($mday, $mon, $year) = (gmtime)[3..5];
                    printf("%4d_%02d_%02d",$year+1900, $mon+1, $mday+0);'` <br />
                    current_hour=`perl -e '($hour) = (gmtime)[2]; printf("%02d",$hour+0);'`
                  </code>
                  <AiOutlineCopy
                    className="inline absolute top-2 right-2 cursor-pointer"
                    size={18}
                    onClick={() => handleCopyClick(perlRef)}
                  />
                </pre>
              </div>
            </div>
          </>
        )
      )}
    </>
  );
};

export default AccessFileInfo;
