import { QueryKey, useQuery } from 'react-query';

import { axiosInstance } from '../../../core/api/axiosInstance';

export const key: QueryKey = ['GET_TRANSLATIONS'];

export const useGetTranslations = (page: number, size: number, sort: string, searchKey: string) =>
  useQuery(key, async () => {
    const { data } = await axiosInstance.get('translations', {
      params: { page, size, sort, searchKey },
    });
    return data;
  });
