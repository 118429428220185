import { FC, useEffect, useState } from 'react';

import { readableTime } from '../components/timeline/helpers';
import { convertMsToFrames } from '../helpers/convertMsToFrames';

interface CountDownProps {
  time: number;
  duration: number;
  skippedTime: number;
  framerate: 25 | 30 | 50 | 60;
  skips: Array<SkipTimeDef>;
}
export const CountDown: FC<CountDownProps> = ({
  time,
  duration,
  framerate,
  skippedTime,
  skips,
}) => {
  const [prevSkips, setPrevSkips] = useState<number>(0);
  const finalDuration = duration - skippedTime;
  useEffect(() => {
    const passedSkips = skips.filter((skip) => skip.endMS < time);
    const passedSkipTime = passedSkips.reduce((sum, obj) => sum + (obj.endMS - obj.startMS), 0);
    if (prevSkips !== passedSkipTime) setPrevSkips(passedSkipTime);
  }, [prevSkips, skips, time]);
  const frames = (convertMsToFrames(framerate, finalDuration - (time - prevSkips ?? 0)) % framerate)
    .toString()
    .padStart(2, '0');
  const seconds = readableTime(finalDuration - (time - prevSkips)) ?? 0;
  return (
    <div>
      Remaining: {seconds};{frames}
    </div>
  );
};
