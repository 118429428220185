export const fontPropertiesDeps = [
  'fontSize',
  'fontAlignment',
  'fontColor',
  'fontFamily',
  'fontColor',
  'strokeWidth',
];

export const positionControlPropertiesDeps = ['x', 'y', 'h', 'w', 'zindex', 'rotation'];

export const boxStylePropertiesDeps = [
  'background.color',
  'background.gradient',
  'background.aspectFit',
  'paddingTop',
  'paddingLeft',
  'paddingBottom',
  'paddingRight',
  'borderLeft.color',
  'borderLeft.width',
  'borderLeft.style',
  'borderTop.color',
  'borderTop.width',
  'borderTop.style',
  'borderBottom.color',
  'borderBottom.width',
  'borderBottom.style',
  'borderRight.color',
  'borderRight.width',
  'borderRight.style',
];

export const timeIndicatorPropertiesDeps = [
  ...fontPropertiesDeps,
  ...positionControlPropertiesDeps,
  ...boxStylePropertiesDeps,
  'enableTimeframeIndicator',
  'timeframeIndicatorStep',
  'mapTimeframeTextIndicator',
  'timeframeIndicatorFormat',
];

export const gribPropertiesDeps = ['gribMapLayers', 'gribMapLayers.zindex'];
export const radarPropertiesDeps = ['radarMapLayers', 'radarMapLayers.zindex'];
export const satellitePropertiesDeps = ['satelliteMapLayers', 'satelliteMapLayers.zindex'];
export const symbolPropertiesDeps = ['symbolLayers', 'symbolLayers.zindex'];
export const riversPropertiesDeps = [
  'rivers',
  'rivers.strokeColor',
  'rivers.strokeWidth',
  'rivers.zindex',
];
export const bordersPropertiesDeps = [
  'borders',
  'borders.strokeColor',
  'borders.strokeWidth',
  'borders.zindex',
];
export const usastatesPropertiesDeps = [
  'usastates',
  'usastates.strokeColor',
  'usastates.zindex',
  'usastates.strokeWidth',
];
export const coastlinePropertiesDeps = [
  'coastline',
  'coastline.strokeColor',
  'coastline.strokeWidth',
  'coastline.zindex',
];
export const roadsPropertiesDeps = [
  'roads',
  'roads.strokeColor',
  'roads.strokeWidth',
  'roads.zindex',
];
export const disputedAreasPropertiesDeps = [
  'boundary_breakaway_disputed_areas',
  'boundary_breakaway_disputed_areas.strokeColor',
  'boundary_breakaway_disputed_areas.strokeWidth',
  'boundary_breakaway_disputed_areas.zindex',
];
export const statesProvincesPropertiesDeps = [
  'states_provinces',
  'states_provinces.strokeColor',
  'states_provinces.zindex',
  'states_provinces.strokeWidth',
];
export const countriesPropertiesDeps = [
  'admin_countries',
  'admin_countries.strokeColor',
  'admin_countries.strokeWidth',
  'admin_countries.zindex',
];
export const maritimeIndicatorPropertiesDeps = [
  'boundary_lines_maritime_indicator',
  'boundary_lines_maritime_indicator.strokeColor',
  'boundary_lines_maritime_indicator.strokeWidth',
  'boundary_lines_maritime_indicator.zindex',
];
export const boundaryLinesLandPropertiesDeps = [
  'boundary_lines_land',
  'boundary_lines_land.strokeColor',
  'boundary_lines_land.strokeWidth',
  'boundary_lines_land.zindex',
];
export const vectorLayersPropertiesDeps = [
  ...riversPropertiesDeps,
  ...bordersPropertiesDeps,
  ...usastatesPropertiesDeps,
  ...coastlinePropertiesDeps,
  ...roadsPropertiesDeps,
];
