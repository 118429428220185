export enum FontVariantsEnum {
  BLACK = 'Black',
  BLACK_ITALIC = 'Black Italic',
  BOLD = 'Bold',
  BOLD_ITALIC = 'Bold Italic',
  BOOK = 'Book',
  BOOK_ITALIC = 'Book Italic',
  EXTRA_BOLD = 'Extra Bold',
  EXTRA_BOLD_ITALIC = 'Extra Bold Italic',
  EXTRA_LIGHT = 'Extra Light',
  EXTRA_LIGHT_ITALIC = 'Extra Light Italic',
  ITALIC = 'Italic',
  LIGHT = 'Light',
  LIGHT_ITALIC = 'Light Italic',
  MEDIUM = 'Medium',
  MEDIUM_ITALIC = 'Medium Italic',
  REGULAR = 'Regular',
  SEMI_BOLD = 'Semi-Bold',
  SEMI_BOLD_ITALIC = 'Semi-Bold Italic',
  SEMI_LIGHT = 'Semi-Light',
  SEMI_LIGHT_ITALIC = 'Semi-Light Italic',
  THIN = 'Thin',
  THIN_ITALIC = 'Thin Italic',
  ULTRA = 'Ultra',
  ULTRA_ITALIC = 'Ultra Italic',
}
