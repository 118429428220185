import UnfoldMoreHorizontalIcon from '@icons/material/UnfoldMoreHorizontalIcon';
import { Select } from 'flowbite-react';
import isUndefined from 'lodash/isUndefined';
import { useEffect, useState } from 'react';
import { EditableInput } from 'react-color/lib/components/common';
import * as color from 'react-color/lib/helpers/color';
import reactCSS from 'reactcss';

import colors from '../color-names.json';

export function ChromeFields(props) {
  const [view, setView] = useState(props.view);
  useEffect(() => {
    if (props.hsl.a !== 1 && view === 'hex') {
      setView('hex8');
    }
  }, [props.hsl.a, view]);

  const toggleViews = () => {
    if (view === 'hex') {
      setView('hex8');
    } else if (view === 'hex8') {
      setView('name');
    } else if (view === 'name') {
      setView('rgb');
    } else if (view === 'rgb') {
      setView('hsl');
    } else if (view === 'hsl') {
      if (props.hsl.a === 1) {
        setView('hex');
      } else {
        setView('hex8');
      }
    }
  };
  const handleChange = (data, e) => {
    if (data.hex8) {
      if (data.hex8.length > 9 || data.hex8.length === 8) {
        return;
      }
      props.onChange(
        {
          hex: data.hex8,
          source: 'hex',
        },
        e,
      );
      if (data.hex8.length < 8) {
        setView('hex');
      }
    }
    if (data.hex) {
      if (data.hex.length > 8) {
        props.onChange(
          {
            hex: data.hex,
            source: 'hex',
          },
          e,
        );
        setView('hex8');
      } else {
        color.isValidHex(data.hex) &&
          props.onChange(
            {
              hex: data.hex,
              source: 'hex',
            },
            e,
          );
      }
    } else if (data.r || data.g || data.b) {
      props.onChange(
        {
          r: data.r || props.rgb.r,
          g: data.g || props.rgb.g,
          b: data.b || props.rgb.b,
          source: 'rgb',
        },
        e,
      );
    } else if (data.a) {
      if (data.a < 0) {
        data.a = 0;
      } else if (data.a > 1) {
        data.a = 1;
      }

      props.onChange(
        {
          h: props.hsl.h,
          s: props.hsl.s,
          l: props.hsl.l,
          a: Math.round(data.a * 100) / 100,
          source: 'rgb',
        },
        e,
      );
    } else if (data.h || data.s || data.l) {
      // Remove any occurances of '%'.
      if (typeof data.s === 'string' && data.s.includes('%')) {
        data.s = data.s.replace('%', '');
      }
      if (typeof data.l === 'string' && data.l.includes('%')) {
        data.l = data.l.replace('%', '');
      }

      // We store HSL as a unit interval so we need to override the 1 input to 0.01
      if (data.s == 1) {
        data.s = 0.01;
      } else if (data.l == 1) {
        data.l = 0.01;
      }

      props.onChange(
        {
          h: data.h || props.hsl.h,
          s: Number(!isUndefined(data.s) ? data.s : props.hsl.s),
          l: Number(!isUndefined(data.l) ? data.l : props.hsl.l),
          source: 'hsl',
        },
        e,
      );
    }
  };

  const showHighlight = (e) => {
    e.currentTarget.style.background = '#eee';
  };

  const hideHighlight = (e) => {
    e.currentTarget.style.background = 'transparent';
  };

  const alphaNumberToHex8 = (alphaNumber) => {
    alphaNumber = Math.min(1, Math.max(0, alphaNumber));
    return Math.round(alphaNumber * 255)
      .toString(16)
      .toUpperCase();
  };
  const styles = reactCSS(
    {
      default: {
        wrap: {
          paddingTop: '16px',
          display: 'flex',
        },
        fields: {
          flex: '1',
          display: 'flex',
          marginLeft: '-6px',
        },
        field: {
          paddingLeft: '6px',
          width: '100%',
        },
        alpha: {
          paddingLeft: '6px',
          width: '100%',
        },
        toggle: {
          width: '32px',
          textAlign: 'right',
          position: 'relative',
        },
        icon: {
          marginRight: '-4px',
          marginTop: '12px',
          cursor: 'pointer',
          position: 'relative',
        },
        iconHighlight: {
          position: 'absolute',
          width: '24px',
          height: '28px',
          background: '#eee',
          borderRadius: '4px',
          top: '10px',
          left: '12px',
          display: 'none',
        },
        input: {
          fontSize: '11px',
          color: '#333',
          width: '100%',
          borderRadius: '2px',
          border: 'none',
          boxShadow: 'inset 0 0 0 1px #dadada',
          height: '21px',
          textAlign: 'center',
          padding: '0',
        },
        label: {
          textTransform: 'uppercase',
          fontSize: '11px',
          lineHeight: '11px',
          color: '#969696',
          textAlign: 'center',
          display: 'block',
          marginTop: '12px',
        },
        svg: {
          fill: '#333',
          width: '24px',
          height: '24px',
          border: '1px transparent solid',
          borderRadius: '5px',
        },
      },
      disableAlpha: {
        alpha: {
          display: 'none',
        },
      },
    },
    props,
    view,
  );
  const renderFields = () => {
    let fields;
    if (view === 'hex') {
      fields = (
        <div style={styles.fields}>
          <div style={styles.field}>
            <EditableInput
              style={{ input: styles.input, label: styles.label }}
              label="hex"
              value={props.hex}
              onChange={handleChange}
            />
          </div>
        </div>
      );
    } else if (view === 'hex8') {
      fields = (
        <div style={styles.fields}>
          <div style={styles.field}>
            <EditableInput
              style={{ input: styles.input, label: styles.label }}
              label="hex8"
              value={props.hex + alphaNumberToHex8(props.rgb.a)}
              onChange={handleChange}
            />
          </div>
        </div>
      );
    } else if (view === 'name') {
      fields = (
        <div style={styles.fields}>
          <div style={styles.field}>
            <Select
              style={styles.input}
              value={props.hex}
              onChange={(e) => {
                props.colorByName(e.target.value);
              }}
            >
              <option value={''}>None</option>
              {Object.keys(colors).map((color) => (
                <option key={color} value={colors[color]}>
                  {color}
                </option>
              ))}
            </Select>
            <label style={styles.label}>COLOR NAME</label>
          </div>
        </div>
      );
    } else if (view === 'rgb') {
      fields = (
        <div style={styles.fields}>
          <div style={styles.field}>
            <EditableInput
              style={{ input: styles.input, label: styles.label }}
              label="r"
              value={props.rgb.r}
              onChange={handleChange}
            />
          </div>
          <div style={styles.field}>
            <EditableInput
              style={{ input: styles.input, label: styles.label }}
              label="g"
              value={props.rgb.g}
              onChange={handleChange}
            />
          </div>
          <div style={styles.field}>
            <EditableInput
              style={{ input: styles.input, label: styles.label }}
              label="b"
              value={props.rgb.b}
              onChange={handleChange}
            />
          </div>
          <div style={styles.alpha}>
            <EditableInput
              style={{ input: styles.input, label: styles.label }}
              label="a"
              value={props.rgb.a}
              arrowOffset={0.01}
              onChange={handleChange}
            />
          </div>
        </div>
      );
    } else if (view === 'hsl') {
      fields = (
        <div style={styles.fields}>
          <div style={styles.field}>
            <EditableInput
              style={{ input: styles.input, label: styles.label }}
              label="h"
              value={Math.round(props.hsl.h)}
              onChange={handleChange}
            />
          </div>
          <div style={styles.field}>
            <EditableInput
              style={{ input: styles.input, label: styles.label }}
              label="s"
              value={`${Math.round(props.hsl.s * 100)}%`}
              onChange={handleChange}
            />
          </div>
          <div style={styles.field}>
            <EditableInput
              style={{ input: styles.input, label: styles.label }}
              label="l"
              value={`${Math.round(props.hsl.l * 100)}%`}
              onChange={handleChange}
            />
          </div>
          <div style={styles.alpha}>
            <EditableInput
              style={{ input: styles.input, label: styles.label }}
              label="a"
              value={props.hsl.a}
              arrowOffset={0.01}
              onChange={handleChange}
            />
          </div>
        </div>
      );
    }
    return fields;
  };
  return (
    <div style={styles.wrap}>
      {renderFields()}
      <div style={styles.toggle}>
        <div style={styles.icon} onClick={toggleViews} ref={(icon) => icon}>
          <UnfoldMoreHorizontalIcon
            style={styles.svg}
            onMouseOver={showHighlight}
            onMouseEnter={showHighlight}
            onMouseOut={hideHighlight}
          />
        </div>
      </div>
    </div>
  );
}

ChromeFields.defaultProps = {
  view: 'hex',
};

export default ChromeFields;
