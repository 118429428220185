import { uniq } from 'lodash';
import moment from 'moment';
import { Coordinate } from 'ol/coordinate';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { WebGLPoints } from 'ol/layer';
import Map from 'ol/Map';
import { fromLonLat } from 'ol/proj';
import { Vector as VectorSource } from 'ol/source';
import { useEffect } from 'react';

import { PlaybackEnum } from '../../core/ui/enums/PlaybackEnum';
import { CURRENT_TIME_DATE_FORMAT } from '../../model/constants/constants';
import { EventMapLayer } from '../../model/definitions/EventMapLayer';
import { MapPanelDef } from '../../model/definitions/MapPanelDef';
import { TimeControlDef } from '../../model/definitions/TimeControlDef';
import { EventsResponseCache } from '../../pages/playground/EventsResponseCache';
import { GlobalPlayerControl } from '../../pages/playground/GlobalPlayerControl';
import { PlayContext } from '../../pages/playground/playerContext/PlayerContext';

const EVENT_TIME_WINDOW = 30 * 60; // seconds 1/2 hours

let LAST_PLAYED_TIME_CACHE: Record<string, number> = {};

export function resetEventsLayerPlayTime() {
  LAST_PLAYED_TIME_CACHE = {};
}

export const useEventLayers = (mapPanel: MapPanelDef, map: Map, playContext: PlayContext) => {
  useEffect(() => {
    rerenderEventLayers();
  }, [mapPanel.wdSpace?.[0]?.eventLayers, playContext.time, playContext.isPlaying]);
  async function rerenderEventLayers() {
    if (!map) return;

    deleteRemovedlayers(map, mapPanel.wdSpace?.[0]?.eventLayers);
    const indicatorClock = mapPanel.wdSpace?.[0]?.indicator;

    /**Events are tied to other layers if no other layers return */
    if (!indicatorClock?.length) {
      removeMapLayerIfExists(
        map,
        mapPanel.wdSpace?.[0]?.eventLayers.map((e) => getLayerId(e.id)),
      );
      return;
    }
    const isPlaying = playContext.isPlaying === PlaybackEnum.PLAYING;
    const disabledLayers = mapPanel.wdSpace?.[0]?.eventLayers?.filter((l) => !l.enabled);
    removeMapLayerIfExists(
      map,
      disabledLayers.map((e) => getLayerId(e.id)),
    );
    const enabledEventLayers = mapPanel.wdSpace?.[0]?.eventLayers?.filter((l) => l.enabled);

    if (!enabledEventLayers?.length) return;
    const playTime = GlobalPlayerControl.getTime();
    const currentIndex = indicatorClock.findIndex(
      (i) => i.timeControls.startMS <= playTime && i.timeControls.endMS >= playTime,
    );
    if (currentIndex < 0) return;
    const currentIndicator = indicatorClock[currentIndex];
    const nextIndicator = indicatorClock[currentIndex + 1];

    if (!currentIndicator || !nextIndicator) return;

    // eslint-disable-next-line prefer-const
    let { start, end } = getRenderTimeFrameFromIndicator(currentIndicator, nextIndicator, playTime);

    const instance = EventsResponseCache.getInstance();
    const promises = enabledEventLayers.map((l) =>
      instance.getEventsData(uniq(l.dataFrames.map((fr) => Number(fr.frameId)))),
    );
    try {
      const res = await Promise.all(promises);
      enabledEventLayers.forEach((evLayer, i) => {
        const data = res[i];
        if (!data) return;
        const lastPlayedTime = LAST_PLAYED_TIME_CACHE[evLayer.id];
        if (
          isPlaying &&
          lastPlayedTime !== undefined &&
          typeof lastPlayedTime === 'number' &&
          lastPlayedTime < start
        ) {
          start = lastPlayedTime;
        }
        LAST_PLAYED_TIME_CACHE[evLayer.id] = end;
        const points: Coordinate[] = [];
        const size = !isNaN(Number(evLayer.radius)) ? Number(evLayer.radius) : 10;
        const color = evLayer.color || 'rgba(255, 0, 0, 1)';
        data.forEach((d) => {
          if (d.utcDate >= start && d.utcDate <= end) points.push([d.longitude, d.latitude]);
        });

        const features = points.map((point) => {
          return new Feature({
            geometry: new Point(fromLonLat(point, map.getView().getProjection())),
          });
        });
        const existingLayer = map
          .getAllLayers()
          .find((l) => l.get('id') === getLayerId(evLayer.id)) as unknown as
          | WebGLPoints<VectorSource<Point>>
          | undefined;
        if (existingLayer) {
          const prevColor = existingLayer.get('color');
          const prevRadius = existingLayer.get('size');
          const isSame = prevColor === color && prevRadius === size;

          if (isPlaying || isSame) {
            /**If playing styles is the same  */
            const source = existingLayer.getSource() as VectorSource<Point>;
            source.clear();
            source.addFeatures(features);
          } else {
            map.removeLayer(existingLayer);
            existingLayer.dispose();
            const vectorSource = new VectorSource({
              features: features,
            });

            const webGLLayer = new WebGLPoints({
              source: vectorSource,
              zIndex: evLayer.zindex || 100,
              style: {
                symbol: {
                  symbolType: 'circle',

                  size: !isNaN(Number(evLayer.radius)) ? Number(evLayer.radius) : 10,
                  color: evLayer.color || 'rgba(255, 0, 0, 1)',

                  rotateWithView: false,
                  offset: [0, 0],
                  opacity: 1.0,
                },
              },
              properties: {
                id: getLayerId(evLayer.id),
                size,
                color,
              },
            });

            map.addLayer(webGLLayer);
          }
        } else {
          const vectorSource = new VectorSource({
            features: features,
          });

          const webGLLayer = new WebGLPoints({
            source: vectorSource,
            zIndex: evLayer.zindex || 100,
            style: {
              symbol: {
                symbolType: 'circle',
                size,
                color,
                rotateWithView: false,
                offset: [0, 0],
                opacity: 1.0,
              },
            },
            properties: {
              id: getLayerId(evLayer.id),
              size,
              color,
            },
          });

          map.addLayer(webGLLayer);
        }
      });
    } catch (e) {
      console.error(e);
    }
  }
};

function getRenderTimeFrameFromIndicator(
  curr: {
    timeControls: TimeControlDef;
    value: string;
    dateValue: string;
  },
  next: {
    timeControls: TimeControlDef;
    value: string;
    dateValue: string;
  },
  playTime: number,
) {
  const indicatorStartTimestamp = moment(curr.dateValue, CURRENT_TIME_DATE_FORMAT).unix();
  const nextIndicatorTimestamp = moment(next.dateValue, CURRENT_TIME_DATE_FORMAT).unix();
  const diff = playTime - curr.timeControls.startMS;

  const progress = diff / (curr.timeControls.endMS - curr.timeControls.startMS);
  const indRealTimeDiff = nextIndicatorTimestamp - indicatorStartTimestamp;
  const toAdd = progress * indRealTimeDiff;
  const currentRealTimeStamp = indicatorStartTimestamp + toAdd;

  return { start: currentRealTimeStamp, end: currentRealTimeStamp + EVENT_TIME_WINDOW };
}

function removeMapLayerIfExists(map: Map, ids: string[]) {
  if (!map) return;
  if (!ids?.length) return;
  ids.forEach((id) => {
    const layer = map.getAllLayers().find((l) => l.get('id') === id);

    if (layer) {
      map.removeLayer(layer);
      layer.dispose();
    }
  });
}

function getLayerId(eventId: string) {
  return `eventlayer-${eventId}`;
}

function deleteRemovedlayers(map: Map, eventLayers: EventMapLayer[]) {
  if (!map) return;
  const mapEventLayersAll = map
    .getAllLayers()
    .filter((l) => l.get('id')?.startsWith('eventlayer-'));
  const allLayerIdsFromData = eventLayers.map((e) => getLayerId(e.id));

  mapEventLayersAll.forEach((l) => {
    const id = l.get('id');
    if (!allLayerIdsFromData.includes(id)) {
      map.removeLayer(l);
      l.dispose();
    }
  });
}
