export const WIND_PREPROCESSING_MASK_VERT = `
attribute vec4 a_texCoord;


void main() {
    vec2 clipSpace = a_texCoord.xy * 2.0 - 1.0;
    //clipSpace.y = -clipSpace.y;
    gl_Position = vec4(clipSpace, 0, 1);
}
`;
