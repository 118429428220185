import { Checkbox } from 'antd';
import { Tooltip } from 'flowbite-react';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import { BiCut } from 'react-icons/bi';
import { VscTrash } from 'react-icons/vsc';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../../atoms/button/Button';
import { ElementsEnum } from '../../../core/ui/enums/ElementsEnum';
import { AnimationPanelDef } from '../../../model/definitions/AnimationPanelDef';
import { AudioElement } from '../../../model/definitions/AudioElement';
import { ForecastWDElementDef } from '../../../model/definitions/ForecastWDElementDef';
import { ImagePanelDef } from '../../../model/definitions/ImagePanelDef';
import { MapPanelDef } from '../../../model/definitions/MapPanelDef';
import { ObservedWDElementDef } from '../../../model/definitions/ObservedWDElementDef';
import { PointDateDef } from '../../../model/definitions/PointDateDef';
import { PointLocationDef } from '../../../model/definitions/PointLocationDef';
import { SceneDef } from '../../../model/definitions/SceneDef';
import { TextPanelDef } from '../../../model/definitions/TextPanelDef';
import { VideoPanelDef } from '../../../model/definitions/VideoPanelDef';
import { WeatherPosterDef } from '../../../model/definitions/WeatherPosterDef';
import {
  ActiveDef,
  addToMultiselect,
  setElement,
  setOpenPosters,
} from '../../../store/slices/active-slice';
import { togglePosterElement } from '../../../store/slices/project-slice';
import { RootState } from '../../../store/store';
import { AllElementsDefs } from '../../../types/elements';
import { elementEnumToKey, keyToElementsEnum } from '../helpers';
import RegularListElement from './RegularListElement';

interface PosterElementListProps {
  item: WeatherPosterDef;
  deleteElement?: () => void;
  cut?: () => void;
  select?: boolean;
  setSelect?: (e: boolean) => void;
  paddingLeft?: any;
  mapId?: string;
  onClick?: (e: any, id?: string) => void;
  onDragEnd?: () => void;
  onDragStart?: () => void;
  enable?: (e: boolean, id: string | number, elementType: string) => void;
  toggleElement?: (
    enabled: boolean,
    elementId: string,
    elementType: ElementsEnum,
    posterId: string,
  ) => void;
}

export const PosterElementList = ({
  paddingLeft,
  item,
  cut,
  deleteElement,
  mapId,
  onClick,
  onDragEnd,
  onDragStart,
  enable,
  toggleElement,
}: PosterElementListProps) => {
  const dispatch = useDispatch();
  const { activeElement, posterOpen, activeScene, multiselect } = useSelector<RootState>(
    (state) => state.active,
  ) as ActiveDef;
  const isOpen = posterOpen.findIndex((value) => value === item.id) > -1;
  const elemSection = multiselect.find(
    (section) => section.type === (mapId ? 'geoPosters' : 'weatherPosters'),
  );
  const selected = elemSection?.elements.find((listItem) => listItem.element.id === item.id);
  //@ts-ignore

  const openLanes = () => {
    dispatch(setOpenPosters({ poster: item.id! }));
  };
  const addTypeToPanel = (
    panels:
      | TextPanelDef[]
      | AudioElement[]
      | VideoPanelDef[]
      | ImagePanelDef[]
      | AnimationPanelDef[]
      | WeatherPosterDef[]
      | MapPanelDef[]
      | ObservedWDElementDef[]
      | ForecastWDElementDef[]
      | PointDateDef[]
      | PointLocationDef[]
      | undefined,
    type: keyof SceneDef,
  ) => {
    const reworkedPanels:
      | TextPanelDef[]
      | AudioElement[]
      | VideoPanelDef[]
      | ImagePanelDef[]
      | AnimationPanelDef[]
      | WeatherPosterDef[]
      | ObservedWDElementDef[]
      | ForecastWDElementDef[]
      | PointDateDef[]
      | PointLocationDef[]
      | MapPanelDef[] = [];
    panels?.forEach((element) => {
      //@ts-ignore
      reworkedPanels.push({ ...element, elementType: type });
    });
    return reworkedPanels;
  };
  const panels = [
    addTypeToPanel(item?.textPanels, 'textPanels'),
    addTypeToPanel(item?.imagePanels, 'imagePanels'),
    addTypeToPanel(item?.animationPanels, 'animationPanels'),
    addTypeToPanel(item?.videoPanels, 'videoPanels'),
    addTypeToPanel(item?.observedWDElements, 'observedWDElements'),
    addTypeToPanel(item?.forecastWDElements, 'forecastWDElements'),
    addTypeToPanel(item?.pointDates, 'pointDates'),
    addTypeToPanel(item?.pointLocation, 'pointLocation'),
  ]
    .flat()
    .filter(Boolean);
  const toggleElementPoster = (elemId: string, elementType: ElementsEnum, enabled: boolean) => {
    dispatch(
      togglePosterElement({
        activeScene,
        elementId: elemId,
        elementType,
        posterId: item.id,
        enabled,
      }),
    );
  };
  const findInForecast = (panel: PointLocationDef) => {
    return item.forecastWDElements.find(
      (element) => element.pointWDGroupId === panel.pointWDGroupId,
    )?.forecastWDSource.location.name;
  };
  const findInObserved = (panel: PointLocationDef) => {
    return [...item.observedWDElements].find(
      (element) => element.pointWDGroupId === panel.pointWDGroupId,
    )?.observedWDSource.station.fullName;
  };
  const getName = (item: any) => {
    if (item.elementType === 'pointLocation') {
      const location = findInForecast(item) || findInObserved(item);
      return `Label ${location}`;
    }
    return item.name;
  };
  const renderElementList = () => {
    const elementList: JSX.Element[] = [];
    panels.forEach((elem) => {
      if (
        elem.elementType !== elementEnumToKey(ElementsEnum.MAP) &&
        elem.elementType !== 'weatherPosters'
      ) {
        elementList.push(
          <RegularListElement
            item={item}
            elem={elem}
            enabled={elem.enabled}
            key={elem.id}
            inPoster
            parentId={item.id}
            id={elem.id}
            elementType={keyToElementsEnum(elem.elementType)}
            name={getName(elem)}
            mapId={mapId}
            enable={(enabled) =>
              toggleElement && mapId
                ? toggleElement(enabled, elem.id, keyToElementsEnum(elem.elementType), item.id)
                : toggleElementPoster(elem.id, keyToElementsEnum(elem.elementType), enabled)
            }
          />,
        );
      }
    });
    return elementList;
  };
  return (
    <div
      style={{ paddingLeft: `${10 + paddingLeft}px` }}
      key={item.id}
      className={
        'timeline-map-wrapper w-full justify-end flex flex-wrap border-b-amber-50 ' +
        (activeElement === item.id || selected ? 'active' : '')
      }
      onClick={(e) => onClick && onClick(e, item.id)}
      onDragEnd={onDragEnd}
      onDragStart={onDragStart}
      draggable={true}
    >
      <div
        className={'sequence-title map'}
        onClick={(e) => {
          if (e.metaKey || e.ctrlKey) {
            dispatch(
              addToMultiselect({
                element: {
                  type: !mapId ? 'weatherPosters' : 'geoPosters',
                  element: item as AllElementsDefs,
                  mapId,
                },
              }),
            );
          } else
            dispatch(
              setElement({
                activeElement: item.id,
                activeProp: 'weatherPosters',
                mapId,
              }),
            );
        }}
      >
        <div className="icon-title">
          {enable && (
            <Checkbox
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              checked={item.enabled}
              onChange={(checked) => {
                enable(checked.target.checked, item.id, item.elementType);
              }}
              style={{ marginRight: '0.3rem' }}
            />
          )}
          <span style={{ marginLeft: '5px' }}>{item.name ?? 'new element'}</span>
        </div>
        {activeElement === item.id && (
          <div className="flex items-center gap-1">
            {cut && (
              <Tooltip content="Cut" style={`dark`}>
                <Button
                  size={'small'}
                  className={'hoverState'}
                  icon={
                    <BiCut
                      style={{
                        color: 'white',
                        fontSize: 14,
                      }}
                    />
                  }
                  onClick={() => cut && cut()}
                  disabled={!activeElement}
                />
              </Tooltip>
            )}
            <Tooltip content="Delete" style={`dark`}>
              <Button
                size={'small'}
                icon={<VscTrash style={{ color: 'white', fontSize: 14 }} />}
                className={'hoverState text-red-500'}
                onClick={() => {
                  deleteElement && deleteElement();
                }}
                disabled={!activeElement}
              />
            </Tooltip>
            <div onClick={() => openLanes()}>
              {isOpen ? <AiOutlineMinus /> : !isOpen ? <AiOutlinePlus /> : null}
            </div>
          </div>
        )}
      </div>
      {isOpen && <div className={'sub-wrapper w-100'}>{renderElementList()}</div>}
    </div>
  );
};
