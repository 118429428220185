import { FC, useState } from 'react';
import { AiOutlineCaretDown, AiOutlineCaretUp } from 'react-icons/ai';

import { SkipTimeItem } from './components/SkipTimeItem';

interface SkippedTimeProps {
  skips: Array<SkipTimeDef>;
}
export const SkippedTime: FC<SkippedTimeProps> = ({ skips }) => {
  const [open, setOpen] = useState<boolean>(false);
  const skipItemRenders = () =>
    skips.map((skip, index) => <SkipTimeItem key={`skip_${index}`} index={index} step={skip} />);
  return (
    <>
      <div
        className={`mb-2 subheader layer-header ${open ? 'layer-header-active' : ''}`}
        onClick={() => setOpen(!open)}
      >
        {open ? <AiOutlineCaretUp /> : <AiOutlineCaretDown />}
        Skipped time
      </div>
      {open && <div>{skipItemRenders()}</div>}
    </>
  );
};
