import { DragLayerMonitorProps } from '@minoru/react-dnd-treeview';
import { FC } from 'react';

import { ElementsEnum } from '../../../core/ui/enums/ElementsEnum';
import { LogicalGroupElement } from '../../../model/definitions/LogicalGroupElement';
import { PanelDefs } from '../../../model/UI/PanelDef';
import styles from './DragPreview.module.scss';

type Props = {
  monitorProps: DragLayerMonitorProps<PanelDefs & LogicalGroupElement & { type: ElementsEnum }>;
};

export const DragPreview: FC<Props> = (props) => {
  const item = props.monitorProps.item;

  return (
    <div className={styles.root}>
      <div className={styles.icon}>Elements</div>
      <div className={styles.label}>{item.text}</div>
    </div>
  );
};
