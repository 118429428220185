import './style.scss';

import { Checkbox } from 'antd';
import dayjs from 'dayjs';
import { Button, Label } from 'flowbite-react';
import moment from 'moment';
import Range, { SliderProps } from 'rc-slider';
import { MarkObj } from 'rc-slider/lib/Marks';
import { useEffect, useMemo, useState } from 'react';
import { VscError } from 'react-icons/vsc';
import { useSelector } from 'react-redux';

import LoadingIndicator from '../../../atoms/loadingIndicator/LoadingIndicator';
import { getNormalisedFrames } from '../../../helpers/math';
import { useDidUpdateEffect } from '../../../hooks/useDidUpdateEffect';
import { MAX_FRAMES_PER_LAYER } from '../../../model/constants/constants';
import { DataFrameDef } from '../../../model/definitions/DataFrameDef';
import { GribMapLayer } from '../../../model/definitions/GribMapLayer';
import { RadarMapLayer } from '../../../model/definitions/RadarMapLayer';
import { SatelliteMapLayer } from '../../../model/definitions/SatelliteMapLayer';
import { SceneDef } from '../../../model/definitions/SceneDef';
import { SymbolLayerDef } from '../../../model/definitions/SymbolLayerDef';
import { findIndexesAfterDensityFramesChange } from '../../../molecules/mapElement/helpers';
import { selectActiveScene } from '../../../store/slices/selectors';
import { RootState } from '../../../store/store';
import { findClosest } from '../sidebar-new/repoUtil/nearestDataFrame';
import { FirstLayerRange, getDefaultRange } from './frameRangeHelpers';
import { sliderHandle } from './util';

const dotStyle = {
  width: 0.1,
  borderRadius: 0,
  border: 0,
};

interface FramesRangeProps extends SliderProps<number[]> {
  frames?: Array<DataFrameDef>;
  onRangeChange: (e: Array<DataFrameDef>) => void;
  selectedFrames?: Array<DataFrameDef>;
  isLoading?: boolean;
  isError?: boolean;
  layerEdit?: GribMapLayer | RadarMapLayer | SatelliteMapLayer | SymbolLayerDef;
  layerType?: 'grib' | 'radar' | 'satellite';
  maxDays?: number;
  show?: boolean;
  rangeFromFirstLayer?: FirstLayerRange | null;
  framesDensity?: null | number;
}
const wrapper = 'flex flex-col justify-center items-center range-wrapper';
export const FramesRange = ({
  frames,
  onRangeChange,
  isLoading,
  isError,
  selectedFrames,
  layerType,
  maxDays,
  show,
  framesDensity,
  rangeFromFirstLayer,
  ...props
}: FramesRangeProps) => {
  const { defaultValue } = props;
  const activeSceneDef = useSelector<RootState, SceneDef | null>((state) =>
    selectActiveScene(state),
  );
  let now = new Date();
  const offset = now.getTimezoneOffset() * 60 * 1000;
  now = new Date(now.getTime() + offset);
  now.setMinutes(0, 0, 0);
  now = new Date(now.getTime() - offset);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const currentDate = useMemo(() => now.getTime() / 1000, []);
  let defaultIsHistory =
    Boolean(props.layerEdit) || !!(activeSceneDef?.startDate && activeSceneDef?.endDate);
  if (
    (frames &&
      defaultValue &&
      (defaultValue[0] > 0 || defaultValue[1] > 0) &&
      frames[defaultValue[0]] &&
      frames[defaultValue[0]].timestamp < currentDate) ||
    show
  ) {
    defaultIsHistory = true;
  }
  const [isHistory, setIsHistory] = useState(defaultIsHistory);
  const [isDateDriven, setIsDateDriven] = useState(
    !!(activeSceneDef?.startDate && activeSceneDef?.endDate),
  );

  const usable = useMemo<DataFrameDef[]>(() => {
    if (isHistory && frames) {
      return getNormalisedFrames(frames);
      //return frames;
    }
    const usableFrames =
      layerType === 'grib'
        ? frames?.filter(
            (frame) =>
              frame.timestamp > currentDate &&
              frame.timestamp < currentDate + (maxDays ?? 14) * 86400,
          )
        : frames?.filter(
            (frame) =>
              frame.timestamp <= currentDate &&
              frame.timestamp > currentDate - (maxDays ?? 14) * 86400 /*14 days in seconds*/,
          );
    if (usableFrames) {
      return getNormalisedFrames(usableFrames);
    }
    return [];
  }, [currentDate, frames, isHistory, layerType, maxDays]);

  useDidUpdateEffect(() => {
    const newRange = findIndexesAfterDensityFramesChange(selectedFrames || [], usable || []);
    onSliderChange(newRange);
  }, [framesDensity, usable]);

  const sliderMarks = useMemo(() => {
    const marks: Record<number, MarkObj> = {};
    usable.some((item) => item !== undefined) &&
      usable.forEach((frame, index) => {
        marks[index] = {
          label: dayjs(new Date(frame?.timestamp * 1000)).format('dddd DD. MMMM HH:mm'),
          style: {
            top: -40,
            display: index === 0 || index === usable.length - 1 ? 'block' : 'none',
            padding: '2px 0',
            borderRadius: 0,
          },
        };
      });
    return usable.some((item) => item !== undefined) ? marks : [];
  }, [usable]);
  const onSliderChange = (e: number | number[]) => {
    if (Array.isArray(e)) {
      {
        if (e[1] - e[0] <= MAX_FRAMES_PER_LAYER) {
          const selected = usable?.slice(e[0], e[1] + 1);
          selected && onRangeChange(selected);
          setRangeValue([e[0], e[1]]);
        }
      }
    }
  };
  let defaultRange: Array<number> | undefined;
  if (
    frames &&
    defaultValue &&
    (defaultValue[0] || defaultValue[1]) &&
    frames[defaultValue[0]] &&
    frames[defaultValue[1]]
  ) {
    const start = frames[defaultValue[0]].timestamp;
    const end = frames[defaultValue[1]].timestamp;
    defaultRange = [
      usable.findIndex((x) => x.timestamp === start),
      usable.findIndex((x) => x.timestamp === end),
    ];
  }
  const [rangeValue, setRangeValue] = useState<Array<number> | undefined>(defaultRange);
  const getNext = (usable: Array<DataFrameDef>) => {
    let i = 0;
    const date = Math.round(Date.now() / 1000);
    while (i < usable.length - 1) {
      i++;
      if (usable[i].timestamp > date) {
        return i;
      }
    }
    return usable.length - 1;
  };

  useEffect(() => {
    let valueToSet: number[] | undefined;
    // if (!rangeFromFirstLayer) {
    if (selectedFrames && selectedFrames.length > 0) {
      const start = selectedFrames[0]?.timestamp;
      const end = selectedFrames[selectedFrames.length - 1]?.timestamp;
      const startIndex = usable.findIndex((x) => x?.timestamp === start);
      const endIndex = usable.findIndex((x) => x?.timestamp === end);
      if (startIndex > -1 && endIndex > -1) {
        valueToSet = [startIndex, endIndex];
      } else {
        valueToSet = findIndexesAfterDensityFramesChange(selectedFrames, usable || []);
      }
    }
    if (!valueToSet && rangeFromFirstLayer && !props.layerEdit) {
      const firstRange = getDefaultRange(rangeFromFirstLayer, usable);
      if (firstRange && !rangeValue) {
        valueToSet = firstRange;
      }
    }
    if (usable && usable.length > 0 && !rangeValue && !valueToSet) {
      const next = getNext(usable);
      const start = next === usable.length ? next - 1 : next;
      const end = next === usable.length ? next : next + 1;
      valueToSet = [start, end];
    }
    if (valueToSet) {
      onSliderChange(valueToSet);
    }
  }, [usable, props.layerEdit]);
  useEffect(() => {
    const startDate = activeSceneDef?.startDate;
    const endDate = activeSceneDef?.endDate;
    if (frames && startDate && endDate && isDateDriven) {
      const framesInRange = getNormalisedFrames(usable).filter(
        (frame) => frame.timestamp >= startDate / 1000 && frame.timestamp <= endDate / 1000,
      );
      const firstFrame = framesInRange[0];
      const lastFrame = framesInRange[framesInRange.length - 1];
      const indexOne = usable.findIndex((x) => x?.timestamp === firstFrame?.timestamp);
      const indexLast = usable.findIndex((x) => x?.timestamp === lastFrame?.timestamp);
      onRangeChange(framesInRange);
      setRangeValue([indexOne, indexLast]);
    }
  }, [usable, activeSceneDef, frames, isDateDriven]);

  const getFramesFromClick = (e: number) => {
    const currentTime = Math.round(Date.now() / 1000);
    const hoursToTake = e * (layerType === 'grib' ? 3600 : -3600);
    const timestamps = usable.map((item) => item.timestamp);
    const startFrameTime = findClosest(timestamps, currentTime);
    const endFrameTime = findClosest(timestamps, startFrameTime + hoursToTake);
    const startFrame = usable.find((item) => item.timestamp === startFrameTime);
    const endFrame = usable.find((item) => item.timestamp === endFrameTime);
    const startIndex = startFrame && usable.indexOf(startFrame);
    const endIndex = endFrame && usable.indexOf(endFrame);
    const observedVal = [endIndex ?? 0, startIndex ?? 1];
    const forecastVal = [startIndex ?? 0, endIndex ?? 1];
    onSliderChange(layerType === 'grib' ? forecastVal : observedVal);
  };
  return (
    <>
      <div className={'flex justify-between mb-4'}>
        <div className={'flex gap-2 items-center'}>
          <Checkbox
            disabled={!(activeSceneDef?.startDate && activeSceneDef?.endDate)}
            checked={isDateDriven}
            onChange={(e) => setIsDateDriven(e.target.checked)}
            className={'!min-w-[0px]'}
          />
          <span
            className={`${
              !(activeSceneDef?.startDate && activeSceneDef?.endDate) && 'text-gray-600'
            }`}
          >
            Use scene timeframe
          </span>
        </div>
        <label
          className={'text-2xl'}
          style={{
            color: `rgb( ${255 * ((selectedFrames?.length ?? 0) / MAX_FRAMES_PER_LAYER)},${
              255 * ((MAX_FRAMES_PER_LAYER - (selectedFrames?.length ?? 0)) / MAX_FRAMES_PER_LAYER)
            }, 0)`,
          }}
        >
          Selected frames:{' '}
          {selectedFrames ? (selectedFrames.length - 1 === 0 ? 1 : selectedFrames.length - 1) : 1}
        </label>
        {(selectedFrames?.length ?? 0) >= MAX_FRAMES_PER_LAYER + 1 ? (
          <label>Max frames added!</label>
        ) : null}
      </div>
      {!props.layerEdit && !isDateDriven && (
        <Button onClick={() => setIsHistory((prevState) => !prevState)}>
          {isHistory ? 'Hide' : 'Show'} historical data
        </Button>
      )}
      {!isDateDriven && (
        <>
          <div className={'grid grid-cols-4 mt-4 gap-8 items-end'}>
            <Label>
              {' '}
              From:
              <p>
                {selectedFrames &&
                  selectedFrames[0] &&
                  moment(selectedFrames[0]?.timestamp * 1000).format('dddd DD.MM HH:mm')}
              </p>
            </Label>
            <Label>
              To:
              <p>
                {selectedFrames &&
                  selectedFrames[selectedFrames.length - 1] &&
                  moment(selectedFrames[selectedFrames.length - 1]?.timestamp * 1000).format(
                    'dddd DD.MM HH:mm',
                  )}
              </p>
            </Label>
          </div>
          <div className={'flex items-center mt-8'}>
            <div>
              <span>Click on slider handle and use arrow keys for fine tuning</span>
            </div>
            <div className={' flex ml-8 items-center'}>
              <span className={'mr-3'}>select {layerType === 'grib' ? 'next' : 'last'}:</span>
              <div className={'flex gap-2'}>
                {layerType !== 'grib' && (
                  <>
                    <Button onClick={() => getFramesFromClick(4)}>4h</Button>
                    <Button onClick={() => getFramesFromClick(12)}>12h</Button>
                  </>
                )}
                <Button onClick={() => getFramesFromClick(24)}>24h</Button>
                {layerType === 'grib' && (
                  <>
                    <Button onClick={() => getFramesFromClick(36)}>36h</Button>
                    <Button onClick={() => getFramesFromClick(48)}>48h</Button>
                  </>
                )}
              </div>
            </div>
          </div>

          {usable.some((item) => item !== undefined) && usable.length > 0 ? (
            <div id={'FramesRange'}>
              {frames?.length ? (
                <Range
                  key={frames.length}
                  value={rangeValue}
                  defaultValue={[0, 1]}
                  range={true}
                  className={'frames-range'}
                  marks={sliderMarks}
                  onChange={onSliderChange}
                  min={0}
                  max={usable.length - 1}
                  activeDotStyle={{ backgroundColor: '#37ff00' }}
                  draggableTrack
                  dotStyle={dotStyle}
                  handleRender={(props, origin) =>
                    sliderHandle(props, origin.index, sliderMarks[origin.value]?.label)
                  }
                />
              ) : (
                frames?.length === 0 && (
                  <div className={'flex justify-center text-red-500'}>
                    No values available for set parameters
                  </div>
                )
              )}
              {isLoading && (
                <div className={wrapper}>
                  <LoadingIndicator size={48} />
                </div>
              )}
              {isError && (
                <div className={wrapper}>
                  <VscError size={48} color={'red'} />
                  <div>Error getting values</div>
                </div>
              )}
            </div>
          ) : (
            <div className={'flex w-full text-center justify-center mt-6 mb-6'}>
              no data for last 15 days or longer
            </div>
          )}
        </>
      )}
    </>
  );
};
