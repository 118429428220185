import { FC } from 'react';
import { VscTrash } from 'react-icons/vsc';
import { useDispatch, useSelector } from 'react-redux';

import { getProjectDuration } from '../../../../../../helpers/timelineUtil';
import { C9ProjectDef } from '../../../../../../model/definitions/C9ProjectDef';
import { TimeInput } from '../../../../../../molecules/timeInput/TimeInput';
import { ActiveDef } from '../../../../../../store/slices/active-slice';
import { deleteSkip, editOneSkip } from '../../../../../../store/slices/project-slice';
import { RootState } from '../../../../../../store/store';
import GridItem from '../../../shared/GridItem';

interface SkipTimeItemProps {
  step: SkipTimeDef;
  index: number;
}
export const SkipTimeItem: FC<SkipTimeItemProps> = ({ step, index }) => {
  const project = useSelector<RootState>((state) => state.project.present.project) as C9ProjectDef;
  const dispatch = useDispatch();
  const { activeFramerate } = useSelector<RootState>((state) => state.active) as ActiveDef;
  const { startMS, endMS } = step;
  const deleteSkipTime = () => {
    dispatch(deleteSkip({ index }));
  };
  const maxProjectTime = getProjectDuration(project);

  const editValue = (prop: Leaves<SkipTimeDef>, value: number) => {
    const val = { ...step, [prop]: value };
    dispatch(editOneSkip({ index, value: val }));
  };
  return (
    <div className={'grid grid-cols-6 my-3'}>
      <GridItem
        label="Start:"
        item={
          <TimeInput
            time={startMS}
            relative={endMS}
            relation={'lower'}
            framerate={activeFramerate}
            onChange={(e) => editValue('startMS', e)}
            index={index}
            startEnd="start"
          />
        }
      />
      <GridItem
        label="End:"
        item={
          <TimeInput
            time={endMS}
            relative={startMS}
            relation={'higher'}
            framerate={activeFramerate}
            onChange={(e) => editValue('endMS', e > maxProjectTime ? maxProjectTime : e)}
            index={index}
            startEnd="end"
          />
        }
      />

      <button
        title="Delete skip time"
        onClick={deleteSkipTime}
        className="text-red-500 flex items-center delete-scene mx-3"
      >
        <VscTrash />
      </button>
    </div>
  );
};
