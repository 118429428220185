import './StudioSkeleton.scss';

import Loader from '../dashboard/components/loader-NEW/Loader';

function StudioSkeleton() {
  return (
    <div className="skeleton-wrapper">
      <div className="playground-skeleton">
        <div className="map-skeleton">
          <span className="actions-sk"></span>
          <div className="playground-sk-wrapper">
            <span className="left-actions-sk"></span>
            <span className="playground-sk"></span>
          </div>
        </div>
        <div className="grid-wrapper-skeleton">
          <span className="grid-actions-sk"></span>
          <div className="grid-sk">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
      <div className={'absolute w-full z-10 top-0 h-screen flex items-center'}>
        <Loader />
      </div>
      <div className="timeline-wrapper-skeleton">
        <div className="items-skeleton">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div className="timeline-skeleton">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  );
}

export default StudioSkeleton;
