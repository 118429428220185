import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useUnpublishFromMarketplace } from '../../../../dashboard/queries-NEW/useUnpublishFromMarketplace';
import { ICartItem } from '../../../../payment/types/ICart';
import Button from '../../../atoms/Button/AntButton';
import Tag from '../../../atoms/Tags/AntTag';
import { useAddToCart } from '../../../hooks/payment/useAddToCart';
import { useGetShoppingCartItems } from '../../../hooks/payment/useGetShoppingCartItems';
import { useBuyItem } from '../../../hooks/useBuyItem';
import useEditBasicInfo from '../../../hooks/useEditBasicInfo';
import { useGetCurrency } from '../../../hooks/useGetCurrency';
import MarketplaceContext from '../../../marketplaceContext';
import Price from '../../../molecules/Price/Price';
import AdminArea from '../AdminArea';

interface Props {
  item: any;
  isAdmin?: boolean;
  path: boolean;
  email: string;
  authenticated: boolean;
}

export interface NewMarketplaceItem {
  id: string;
  newName: string;
  newDescription: string;
  newPriceForPublishedItem: number;
  newPriceCurrencyForPublishedItem: string;
  newAvailableFrom: number;
  newAvailableTo: number;
}

const DetailsPageInformation: React.FC<Props> = ({ item, isAdmin, path, email, authenticated }) => {
  const { mutate: addToCart, isSuccess } = useAddToCart();
  const { data: currency } = useGetCurrency();
  const [redirect, setRedirect] = useState<boolean>();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const navigate = useNavigate();
  const { data: cart } = useGetShoppingCartItems(authenticated);
  const { setType } = useContext(MarketplaceContext);
  const { mutate: unpublishMutate } = useUnpublishFromMarketplace();
  const { mutate: editMutate } = useEditBasicInfo();
  const { mutate: mutateBuyItem, isSuccess: isSuccessBuy } = useBuyItem();

  const [newItem, setNewItem] = useState<NewMarketplaceItem>({
    id: item.id,
    newName: item.name,
    newDescription: item.description,
    newPriceForPublishedItem: item.price,
    newPriceCurrencyForPublishedItem: item.priceCurrency,
    newAvailableFrom: item.availableFrom,
    newAvailableTo: item.availableTo,
  });

  useEffect(() => {
    setNewItem({
      id: item.id,
      newName: item.name,
      newDescription: item.description,
      newPriceForPublishedItem: item.price,
      newPriceCurrencyForPublishedItem: item.priceCurrency,
      newAvailableFrom: item.availableFrom,
      newAvailableTo: item.availableTo,
    });
  }, [item]);

  const handleAddToCart = () => {
    addToCart(item.id);
  };

  const handleBuyNow = () => {
    if (item.price > 0) {
      const inCart = cart.items.filter((i: ICartItem) => i.storeItemId === item.id).length > 0;
      if (inCart) {
        navigate('/marketplace/checkout');
      } else {
        addToCart(item.id);
        setRedirect(true);
      }
    } else {
      mutateBuyItem(item.id);
    }
  };
  useEffect(() => {
    if (isSuccess && redirect) {
      navigate('/marketplace/checkout');
    }
  }, [redirect, isSuccess]);

  if (isSuccessBuy) {
    navigate('/marketplace/confirm');
  }

  const getTemplateType = (type: string) => {
    switch (type) {
      case 'PROJECT_TEMPLATE':
        return 'PROJECT';
      case 'SCENE_TEMPLATE':
        return 'SCENE';
      case 'MAP_TEMPLATE':
        return 'MAP';
      case 'CUSTOM_MAP_TEMPLATE':
        return 'CUSTOM MAP';
      case 'CUSTOM_VECTOR_TEMPLATE':
        return 'CUSTOM VECTOR';
      case 'WEATHER_POSTER_TEMPLATE':
        return 'POSTER';
      case 'WEATHER_GEO_POSTER_TEMPLATE':
        return 'COMPOSITE';
      case 'ICON_SET_TEMPLATE':
        return 'ICON SET';
      case 'AUDIO_TEMPLATE':
        return 'AUDIO';
      case 'IMAGE_TEMPLATE':
        return 'IMAGE';
      case 'VIDEO_TEMPLATE':
        return 'VIDEO';
      case 'ANIMATION_TEMPLATE':
        return 'ANIMATION';
    }
  };
  const edit = () => {
    setIsEditing(true);
  };

  const save = () => {
    editMutate(newItem);
    setIsEditing(false);
  };

  const unpublish = async () => {
    unpublishMutate({
      templateType: item.templateType,
      templateId: item.templateId,
      templateVersionId: item.templateVersionId,
    });
  };

  function redirectToStorePage(e: React.MouseEvent<HTMLParagraphElement, MouseEvent>) {
    e.stopPropagation();
    navigate(`/marketplace/store/${item?.createdBy || item?.creator}`);
  }

  return (
    <div className="flex flex-col">
      <div>
        <h1 className="uppercase text-[#2176FF] break-words text-xl md:text-2xl lg:text-[42px] lg:!leading-[46px]">
          {(isAdmin || (path && item.createdBy === email)) && isEditing ? (
            <input
              className="uppercase w-full border p-1 border-[#6B7280]"
              value={newItem.newName ?? ''}
              onChange={(e) => {
                setNewItem({ ...newItem, newName: e.target.value });
              }}
            />
          ) : (
            item.name
          )}
        </h1>
        <Button
          className="bg-[#D9D9D9] text-black border-none rounded-[4px] mt-2 text-sm lg:text-lg"
          onClick={() => {
            setType([item.templateType]);
            navigate('/marketplace/items');
          }}
        >
          {getTemplateType(item.templateType)}
        </Button>
      </div>
      <div className="mt-4 md:mt-6 lg:mt-8">
        <p className="text-[#444444] text-[12px]">
          Created date: {moment(item.creationTime).format('DD.MM.YYYY')}
        </p>
        <p
          className="text-[#444444] text-[12px] mt-2 cursor-pointer"
          onClick={(e) => redirectToStorePage(e)}
        >
          Author: {item?.createdBy || item?.creator}
        </p>
        <h3 className="text-black text-xl lg:text-3xl font-light mt-2">
          {item.description && (isAdmin || (path && item.createdBy === email)) && isEditing ? (
            <textarea
              className="w-full text-black text-xl lg:text-3xl font-light mt-2 p-1"
              rows={4}
              value={newItem.newDescription ?? ''}
              onChange={(e) => setNewItem({ ...newItem, newDescription: e.target.value })}
            />
          ) : (
            item.description
          )}
        </h3>
      </div>
      <h2 className="text-[#444444] text-2xl lg:text-5xl mt-3 md:mt-5 lg:mt-7">
        {item.price > 0 ? (
          currency && <Price currency={currency} price={item.price} />
        ) : (
          <Tag color="success" large={true}>
            FREE
          </Tag>
        )}
      </h2>
      <div className="flex gap-5 w-3/4 mt-4 md:mt-7 lg:mt-10">
        {isAdmin ? (
          <AdminArea
            approved={item.approved}
            id={item.id}
            item={item}
            edit={edit}
            save={save}
            isEditing={isEditing}
          />
        ) : path && item.createdBy === email ? (
          <>
            {isEditing ? (
              <Button
                size="large"
                type="primary"
                className="w-full rounded-[4px]"
                onClick={() => save()}
              >
                Save
              </Button>
            ) : (
              <Button size="large" className="w-full rounded-[4px]" onClick={() => edit()}>
                Edit
              </Button>
            )}
            <Button
              size="large"
              type="primary"
              className="w-full rounded-[4px]"
              onClick={unpublish}
            >
              Unpublish
            </Button>
          </>
        ) : (
          email !== item.createdBy && (
            <>
              {item.price > 0 ? (
                <>
                  <Button size="large" className="w-full rounded-[4px]" onClick={handleAddToCart}>
                    ADD TO CART
                  </Button>
                  <Button
                    size="large"
                    className="w-full rounded-[4px]"
                    type="primary"
                    onClick={handleBuyNow}
                  >
                    BUY NOW
                  </Button>
                </>
              ) : (
                <Button
                  size="large"
                  className="w-1/2 rounded-[4px]"
                  type="primary"
                  onClick={handleBuyNow}
                >
                  BUY NOW
                </Button>
              )}
            </>
          )
        )}
      </div>
      <div>
        {item.tags?.length > 0 && (
          <>
            <p className="mb-2 text-[#444444] mt-6 md:mt-10">TAGS:</p>
            {item.tags?.map((item: string) => {
              return <Tag key={item}>{item}</Tag>;
            })}
          </>
        )}
      </div>
    </div>
  );
};

export default DetailsPageInformation;
