import { useMutation } from 'react-query';

import { axiosInstance } from '../../../core/api/axiosInstance';
import { queryClient } from '../../../index';

const useShareMultimedia = () => {
  return useMutation(
    async ({ id, type }: { id: string; type: string }) => {
      const { data } = await axiosInstance.post(
        `multimedia/internal-share/${id}?type=${type.toUpperCase()}`,
      );
      return data;
    },
    {
      onSuccess: () => queryClient.invalidateQueries('GET_MULTIMEDIA'),
    },
  );
};

export default useShareMultimedia;
