import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { axiosInstance } from '../../../core/api/axiosInstance';
import { queryClient } from '../../../index';
import { key } from '../../dashboard/queries-NEW/useGetPublishedItem';

const useEditBasicInfo = () => {
  return useMutation(
    async ({
      id,
      newName,
      newDescription,
      newPriceForPublishedItem,
      newPriceCurrencyForPublishedItem,
      newAvailableFrom,
      newAvailableTo,
    }: {
      id: string;
      newName: string;
      newDescription: string;
      newPriceForPublishedItem: number;
      newPriceCurrencyForPublishedItem: string;
      newAvailableFrom: number;
      newAvailableTo: number;
    }) => {
      const { data } = await axiosInstance.put('marketplace/basic-update', {
        id,
        newName,
        newDescription,
        newPriceForPublishedItem,
        newPriceCurrencyForPublishedItem,
        newAvailableFrom,
        newAvailableTo,
      });
      return data;
    },
    {
      onSuccess: () => {
        toast.success('Successfully edited item!');
        queryClient.invalidateQueries(key);
      },
      onError: () => {
        toast.error('There was an error. Please try again!');
      },
    },
  );
};

export default useEditBasicInfo;
