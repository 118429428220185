import { useQuery } from 'react-query';

import { axiosInstance } from '../../../core/api/axiosInstance';
import { EtlStatus } from '../pages/Products';

export const key = ['ETL_ACTIVE'];

export const useGetEtl = (ids: string[]) =>
  useQuery(
    [...key, ...ids],
    async () => {
      const fileResultPromises = ids.map((id) =>
        axiosInstance.get(`data-products/etl-active/${id}`),
      );
      const fileResultResponses = await Promise.all(fileResultPromises);

      const flattenedArray: EtlStatus[] = [];
      fileResultResponses.forEach((response, index) => {
        const id = ids[index];
        flattenedArray.push({ etl_active: response.data.etl_active, id });
      });
      return flattenedArray;
    },
    {
      enabled: !!ids.length,
    },
  );
