import { Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { useGetAvailableTranslations } from '../../../../core/api/translation/useGetAvailableTranslations';
import { C9ProjectDef } from '../../../../model/definitions/C9ProjectDef';
import { setDictionary } from '../../../../store/slices/active-slice';
import { updateProject } from '../../../../store/slices/project-slice';
import { RootState } from '../../../../store/store';
import { PropertySection } from '../components/PropertySection';
import GridItem from '../shared/GridItem';
import GridWrapper from '../shared/GridWrapper';

export const TranslationPanel = () => {
  const dispatch = useDispatch();
  const project = useSelector<RootState, C9ProjectDef>((state) => state.project.present.project);
  const { data } = useGetAvailableTranslations();

  const changeLanguage = (value: string) => {
    dispatch(
      updateProject({
        newValue: value,
        // @ts-ignore
        propertyPath: 'translationId',
      }),
    );
    dispatch(setDictionary({ dictionary: value }));
  };
  const options = data?.map((lang) => {
    return { label: lang.name, value: lang.id };
  });
  return (
    <PropertySection label={'Project language'} isOpened={false}>
      <GridWrapper className={'py-5'}>
        <GridItem
          noBorderBg
          label={'Language:'}
          item={
            <Select
              value={project.translationId ?? ''}
              options={[{ label: 'Default', value: '' }, ...(options ?? [])]}
              onChange={changeLanguage}
            />
          }
        />
      </GridWrapper>
    </PropertySection>
  );
};
