import { Select } from 'flowbite-react';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useGetValue } from '../../../../../core/api/observedData/useGetValue';
import { stringToUnit } from '../../../../../helpers/stringToUnit';
import { ObservedWDElementDef } from '../../../../../model/definitions/ObservedWDElementDef';
import { WeatherPosterDef } from '../../../../../model/definitions/WeatherPosterDef';
import { ValueTypeEnum } from '../../../../../model/enums/ValueTypeEnum';
import { ObservedData } from '../../../../../model/other/ObservedData';
import { IconPicker } from '../../../../../organisms/addElementModal/PointDataComponents/IconPicker';
import { ActiveDef, updateObservedTemp } from '../../../../../store/slices/active-slice';
import { RootState } from '../../../../../store/store';

interface ObservedTimesProps {
  station: string;
  observedGroup: Array<ObservedWDElementDef>;
  date: number;
  step: 'observed' | 'forecast' | 'add';
  stationName?: string;
  bias: boolean;
  definition: WeatherPosterDef;
}
export const ObservedTimes = ({
  station,
  observedGroup,
  date,
  step,
  stationName,
  bias,
  definition,
}: ObservedTimesProps) => {
  const { observedTemp } = useSelector<RootState, ActiveDef>((state) => state.active);
  const currentDef = observedTemp.find((def) => def.id === definition.id);
  const { data: observedData } = useGetValue(date, station, bias);
  const [data, setData] = useState<Array<ObservedWDElementDef>>([]);
  const dispatch = useDispatch();
  useEffect(
    () => observedData && renderNewData(observedGroup, observedData),
    [observedData, observedGroup],
  );
  useEffect(() => {
    step === 'forecast' &&
      currentDef &&
      dispatch(
        updateObservedTemp({
          id: definition.id,
          data,
        }),
      );
  }, [step]);
  const renderNewData = (
    observedGroup: Array<ObservedWDElementDef>,
    observedData: ObservedData,
  ) => {
    const changed = observedGroup.map((item) => {
      return {
        ...item,
        name: `${stationName} ${
          item.observedWDSource.valueType === ValueTypeEnum.IMAGE
            ? observedData.weatherType
            : item.observedWDSource.parameterType
        }`,
        observedWDSource: {
          ...item.observedWDSource,
          name: `${stationName} ${item.observedWDSource.parameterType}`,
          utcDate: observedData?.utcTime,
          localDate: observedData?.localTime,
          datetime: observedData?.utcTimeStamp ?? 0,
          station: observedData.station,
          parameterType:
            item.observedWDSource.valueType === ValueTypeEnum.IMAGE
              ? observedData.weatherType
              : item.observedWDSource.parameterType, //item.observedWDSource.parameterType,
          value:
            item.observedWDSource.valueType === ValueTypeEnum.NUMERICAL
              ? observedData?.properties.find(
                  (oData) => oData.name === item.observedWDSource.parameterType,
                )?.values
              : item.observedWDSource.value,
        },
      };
    });
    setData(changed);
  };
  const updateIcon = (id: string, icon: any) => {
    const changed = cloneDeep(data);
    const itemToChange = changed.find((item) => item.id === id);
    if (itemToChange) itemToChange.observedWDSource.value = icon;
    setData(changed);
  };
  const selectUnt = (values: { unit: string; value: number }[]) =>
    values?.map((value) => (
      <option key={`${value?.value}_${value?.unit}`} value={value?.unit}>
        {stringToUnit(value.unit)}
      </option>
    ));
  const isBiased = () => {
    return observedData?.properties.some((prop) =>
      prop.values.some((value) => value.value !== value.originalValue),
    );
  };
  return (
    <>
      <div className="selected-date-and-time">
        {moment(new Date(date * 1000)).format('DD.MM.YYYY HH:ss a z')}
      </div>
      <div className={'grid grid-cols-5 gap-2'}>
        {data.map((item) => {
          return item.observedWDSource.valueType === ValueTypeEnum.NUMERICAL ? (
            <div
              key={item.id}
              className={`mb-2 property-card ${isBiased() ? 'ring-1 ring-red-500' : undefined}`}
            >
              <div>{item.observedWDSource.parameterType.split(/(?=[A-Z])/).join(' ')}</div>
              <div>
                {Array.isArray(item.observedWDSource.value) &&
                  item.observedWDSource.value.find(
                    (val: { unit: string; value: number }) =>
                      val.unit === item.observedWDSource.unitOfMeasurement,
                  ).value}
              </div>
              <Select
                className={'observed-select'}
                sizing={'sm'}
                value={item.observedWDSource.unitOfMeasurement}
                onChange={(e) => {
                  const changed = [...data];
                  const pointData = data.find((point) => point.id === item.id);
                  if (pointData) pointData.observedWDSource.unitOfMeasurement = e.target.value;
                  setData(changed);
                }}
              >
                {selectUnt(item.observedWDSource.value)}
              </Select>
            </div>
          ) : (
            <div key={item.id}>
              <IconPicker
                isTemp
                weatherType={observedData?.weatherType ?? 'N/A'}
                onSelect={(e) => {
                  console.log({ e });
                  updateIcon(item.id, e);
                }}
                onClick={(e) => updateIcon(item.id, e)}
                active={false}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};
