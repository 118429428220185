export interface IntersectElements {
  leftIntersect: boolean;
  topIntersect: boolean;
  bottomIntersect: boolean;
  rightIntersect: boolean;
  isIntersecting: boolean;
}

export interface AspectRatio {
  xRatio: number;
  yRatio: number;
}

export function getRectFromAspectRatio(
  ratio: AspectRatio,
  changedVal: number,
  axis: 'width' | 'height',
) {
  const { xRatio, yRatio } = ratio;
  switch (axis) {
    case 'width': {
      return {
        width: changedVal,
        height: Math.floor((yRatio / xRatio) * changedVal),
      };
    }
    case 'height': {
      return {
        width: Math.floor((xRatio / yRatio) * changedVal),
        height: changedVal,
      };
    }
  }
}

export function isIntersectingRects(
  parentEl: HTMLDivElement,
  moveEl: HTMLDivElement,
): IntersectElements {
  const parentRect = parentEl.getBoundingClientRect();
  const moveRect = moveEl.getBoundingClientRect();
  const leftIntersect = parentRect.left >= moveRect.left;
  const topIntersect = parentRect.top >= moveRect.top;
  const bottomIntersect = parentRect.bottom <= moveRect.bottom;
  const rightIntersect = parentRect.right <= moveRect.right;
  const isIntersecting = leftIntersect || topIntersect || bottomIntersect || rightIntersect;
  return {
    leftIntersect,
    topIntersect,
    bottomIntersect,
    rightIntersect,
    isIntersecting,
  };
}

export function getSafeResizeMeasureRatio(
  parentEl: HTMLDivElement,
  moveEl: HTMLDivElement,
  ratio: AspectRatio,
  newWidth: number,
  newHeight: number,
  axis: 'left' | 'right',
) {
  const moveStyles = window.getComputedStyle(moveEl);
  const { xRatio, yRatio } = ratio;
  const initialWidth = parseFloat(moveStyles.width);
  const initialHeight = parseFloat(moveStyles.height);
  const widthDiff = newWidth - initialWidth;
  const heightDiff = newHeight - initialHeight;
  if (newWidth <= initialWidth && newHeight <= initialHeight)
    return { width: newWidth, height: newHeight };

  const parentRect = parentEl.getBoundingClientRect();
  const moveRect = moveEl.getBoundingClientRect();

  switch (axis) {
    /**Axis right resize goes right/bottom */
    case 'right': {
      if (
        moveRect.left + moveRect.width + widthDiff <= parentRect.right &&
        moveRect.top + moveRect.height + heightDiff <= parentRect.bottom
      )
        /**No intersection */
        return { width: newWidth, height: newHeight };
      /**Has intersection  */
      const toIntersectionX = parentRect.right - moveRect.width - moveRect.left;
      const toIntersectionY = parentRect.bottom - moveRect.height - moveRect.top;
      if ((initialWidth + toIntersectionX) / (initialHeight + toIntersectionY) <= xRatio / yRatio)
        return getRectFromAspectRatio(ratio, initialWidth + toIntersectionX, 'width');

      return getRectFromAspectRatio(ratio, initialHeight + toIntersectionY, 'height');
    }
    case 'left': {
      if (
        moveRect.left - widthDiff >= parentRect.left &&
        moveRect.top - heightDiff >= parentRect.top
      )
        /**No intersection */
        return { width: newWidth, height: newHeight };
      /**Has intersection  */
      const toIntersectionX = moveRect.left - parentRect.left;
      const toIntersectionY = moveRect.top - parentRect.top;
      if ((initialWidth + toIntersectionX) / (initialHeight + toIntersectionY) <= xRatio / yRatio)
        return getRectFromAspectRatio(ratio, initialWidth + toIntersectionX, 'width');
      return getRectFromAspectRatio(ratio, initialHeight + toIntersectionY, 'height');
    }
  }
}

export function getSaveMoveMeasure(
  parentEl: HTMLDivElement,
  moveEl: HTMLDivElement,
  newLeft: number,
  newTop: number,
) {
  const parentRect = parentEl.getBoundingClientRect();
  const moveRect = moveEl.getBoundingClientRect();
  let left = newLeft;
  let top = newTop;
  if (newLeft + moveRect.width + parentRect.left >= parentRect.right)
    left = parentRect.right - moveRect.width - parentRect.left;
  if (newLeft <= 0) left = 0;
  if (newTop + moveRect.height + parentRect.top >= parentRect.bottom)
    top = parentRect.bottom - moveRect.height - parentRect.top;
  if (newTop <= 0) top = 0;
  return { left, top };
}

export function getSafeResizeMeasure(
  parentEl: HTMLDivElement,
  moveEl: HTMLDivElement,
  newMeasure: number,
  axis: 'left' | 'right' | 'bottom' | 'top',
) {
  const parentRect = parentEl.getBoundingClientRect();
  const moveRect = moveEl.getBoundingClientRect();
  switch (axis) {
    case 'left': {
      if (moveRect.right - newMeasure <= parentRect.left) {
        return { measure: moveRect.right - parentRect.left };
      }
      return { measure: newMeasure };
    }
    case 'right': {
      if (moveRect.left + newMeasure >= parentRect.right) {
        return { measure: parentRect.right - moveRect.left };
      }
      return { measure: newMeasure };
    }
    case 'bottom': {
      if (moveRect.top + newMeasure >= parentRect.bottom) {
        return { measure: parentRect.bottom - moveRect.top };
      }
      return { measure: newMeasure };
    }
    case 'top': {
      if (moveRect.bottom - newMeasure <= parentRect.top) {
        return { measure: moveRect.bottom - parentRect.top };
      }
      return { measure: newMeasure };
    }
  }
}

export const updateProportional = (num1: number, num2: number, precision?: number) => {
  precision = precision || 2;
  const ratio: number = Number(num2 / num1) || 1;
  return function (newNum1: number) {
    const newNum2 = newNum1 * ratio;
    return parseFloat(newNum2.toFixed(precision));
  };
};
