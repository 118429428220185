import { ToggleSwitch } from 'flowbite-react';
import { useEffect, useState } from 'react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { useDispatch } from 'react-redux';

import Button from '../../../../../atoms/button/Button';
import { WeatherPosterDef } from '../../../../../model/definitions/WeatherPosterDef';
import Modal from '../../../../../molecules/modal/Modal';
import { setForecastTemp, setObservedTemp } from '../../../../../store/slices/active-slice';
import { AddTemplate } from './AddTemplate';
import { ForecastPage } from './ForecastPage';
import { ObservedPage } from './ObservedPage';

interface TemplateModalProps {
  flow?: 'single' | 'scene';
  scene: string;
  isOpen: boolean;
  onClose: () => void;
  tab: string;
  allPosters: Array<WeatherPosterDef>;
}

export const PosterTemplateModal = ({ isOpen, onClose, tab, allPosters }: TemplateModalProps) => {
  const dispatch = useDispatch();
  const [step, setStep] = useState<'observed' | 'forecast' | 'add'>('observed');
  const [offset /* setOffset */] = useState<number>(0);
  const [bias, setBias] = useState<boolean>(false);
  const [offsetMode, setOffsetMode] = useState<'HOURS' | 'DAYS'>('HOURS');
  useEffect(() => {
    step === 'forecast' && setOffsetMode('DAYS');
  }, [step]);
  useEffect(() => {
    allPosters
      ?.filter((poster) => !!poster.observedWDElements.length)
      .forEach((poster) => dispatch(setObservedTemp({ observed: poster })));
    allPosters
      ?.filter((poster) => !!poster.forecastWDElements.length)
      .forEach((poster) => dispatch(setForecastTemp({ forecast: poster })));
  }, [allPosters.length]);
  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        className="template-scene-modal"
        header={`Weather ${tab === 'GEOPOSTER' ? 'Composites' : 'Poster'}  template setup`}
        footer={
          <div className="modal-footer">
            <div className={'footnote'}></div>
            <div className={'button-holder'}>
              <div className="w-full flex items-center mt-5 next-back-btn">
                <>
                  <Button onClick={onClose} buttonType="secondary" label="Cancel" />
                  {step === 'forecast' ? (
                    <Button
                      onClick={() => {
                        setStep('observed');
                      }}
                      label="Add"
                    />
                  ) : (
                    <Button
                      onClick={() => {
                        setStep('forecast');
                      }}
                      label="Add"
                    />
                  )}
                  {step === 'forecast' && (
                    <Button
                      buttonType="primary"
                      onClick={() => setStep('add')}
                      label="Add"
                      icon={<AiOutlineArrowRight />}
                    />
                  )}
                </>
              </div>
            </div>
          </div>
        }
      >
        <>
          <label>
            <span className={'mr-2'}>Apply bias filter</span>{' '}
            <ToggleSwitch checked={bias} onChange={(e) => setBias(e)} label={''} />
          </label>
          <div style={{ display: step === 'observed' ? 'block' : 'none' }}>
            <div className={'subheader'}>Observed data</div>
            <ObservedPage
              bias={bias}
              setStep={setStep}
              step={step}
              offset={offset}
              offsetMode={offsetMode}
              allPosters={allPosters?.filter((poster) => !!poster.observedWDElements.length) ?? []}
              /* setOffset={(e) => {
                e && setOffset(differenceInHours(definition.creationTime, e));
              }} */
            />
          </div>
          <div style={{ display: step === 'forecast' ? 'block' : 'none' }}>
            <div className={'subheader'}>Forecast data</div>
            <ForecastPage
              bias={bias}
              setStep={setStep}
              step={step}
              offset={offset}
              offsetMode={offsetMode}
              allPosters={allPosters?.filter((poster) => !!poster.forecastWDElements.length) ?? []}
            />
          </div>
          {step === 'add' && <AddTemplate onClose={onClose} step={step} />}
        </>
      </Modal>
    </>
  );
};
