import { useSelector } from 'react-redux';

import { MAX_FULLSCREEN_HEIGHT } from '../model/constants/constants';
import { ActiveDef } from '../store/slices/active-slice';
import { RootState } from '../store/store';

export function useScaleFactor(isFullScreen: boolean) {
  const { previewSize, fullCanvasSize } = useSelector<RootState, ActiveDef>(
    (state) => state.active,
  );
  return isFullScreen
    ? fullCanvasSize[0] / MAX_FULLSCREEN_HEIGHT
    : previewSize / MAX_FULLSCREEN_HEIGHT;
}
