export enum TimeStepEnum {
  MIN_1 = 'MIN_1',
  MIN_5 = 'MIN_5',
  MIN_10 = 'MIN_10',
  MIN_15 = 'MIN_15',
  MIN_30 = 'MIN_30',
  HOURLY = 'HOURLY',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
}
