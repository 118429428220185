import { Tooltip } from 'antd';
import { Map } from 'ol';
import { HTMLAttributes, RefObject } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useGetImageMultimedia } from '../../core/api/useGetMultimedia';
import { useFontLoader } from '../../core/api/useLoadFont';
import { gradientOpacity, singleColorOpacity } from '../../helpers/convertOpacity';
import { stringToUnit } from '../../helpers/stringToUnit';
import { MAX_FULLSCREEN_HEIGHT } from '../../model/constants/constants';
import { BorderDef } from '../../model/definitions/BorderDef';
import { ObservedWDElementDef } from '../../model/definitions/ObservedWDElementDef';
import { PositionControlDef } from '../../model/definitions/PositionControlDef';
import { TimeControlDef } from '../../model/definitions/TimeControlDef';
import { ValueTypeEnum } from '../../model/enums/ValueTypeEnum';
import { ActiveDef, addPointForBiasObserved } from '../../store/slices/active-slice';
import { RootState } from '../../store/store';
import ElementContainer from './ElementContainer';
import { displayPointValue } from './showPointDataValue';
import { removeDuplicateIds, transformPercentToAbsolute } from './utils';

interface ObservedElementProps extends HTMLAttributes<HTMLDivElement> {
  panelProps: ObservedWDElementDef;
  canvas: { cnvWidth?: number; cnvHeight?: number };
  disabled?: boolean;
  parentTime?: TimeControlDef[];
  inPoster?: boolean;
  posterId?: string;
  parentSize?: PositionControlDef;
  isMapOverlay?: boolean;
  mapId?: string;
  geoPosterId?: string;
  projectionCalc?: string;
  mapRef?: RefObject<Map | null>;
}
export const ObservedElement = ({
  canvas,
  disabled,
  panelProps,
  parentTime,
  posterId,
  inPoster,
  parentSize,
  isMapOverlay = false,
  mapId,
  geoPosterId,
}: ObservedElementProps) => {
  const dispatch = useDispatch();
  const { activeAspectRatio, biasMode, biasedValues, addedBiasedValues, biasParam } =
    useSelector<RootState>((state) => state.active) as ActiveDef;
  const {
    fontFamily,
    fontColor,
    fontAlignment,
    fontSize,
    fontType,
    observedWDSource,
    boxDef,
    displayUnitOfMeasurement,
    timeControls,
    strokeWidth,
    strokeColor,
    textTransform,
  } = panelProps;
  const {
    valueType,
    value: elemValue,
    parameterType,
    unitOfMeasurement,
    biasFilterAppliedOnValue,
    originalValue,
  } = observedWDSource;
  const {
    paddingBottom,
    paddingTop,
    paddingLeft,
    paddingRight,
    background,
    borderRight,
    borderTop,
    borderLeft,
    borderBottom,
  } = boxDef;
  function isObserved(obj: any): obj is ObservedWDElementDef {
    if (obj) return 'observedWDSource' in obj;
    return false;
  }
  const activeValue =
    Array.isArray(elemValue) && elemValue?.find((val) => val.unit === unitOfMeasurement);
  const indexOfBias = biasedValues.findIndex((item) => item.id === panelProps.id);
  const indexOfConfirmed = removeDuplicateIds(addedBiasedValues).findIndex(
    (item) => item.id === panelProps.id,
  );
  const getBiasValue = () => {
    const element =
      removeDuplicateIds(addedBiasedValues)[indexOfConfirmed] || biasedValues[indexOfBias];
    if (isObserved(element))
      return element.observedWDSource.valueType === ValueTypeEnum.NUMERICAL
        ? element.observedWDSource.value
        : element.observedWDSource.value?.versionId;
  };
  const borderString = (val: BorderDef) => val && `${val.width}px ${val.style} ${val.color}`;
  const { data: url } = useGetImageMultimedia(getBiasValue() || elemValue?.versionId, false);
  const { data: urlOrig } = useGetImageMultimedia(originalValue?.versionId, false);
  const { cnvHeight } = canvas;
  let fontUnit;
  if (inPoster && cnvHeight) {
    const cH = transformPercentToAbsolute(
      cnvHeight,
      activeAspectRatio,
      'height',
      MAX_FULLSCREEN_HEIGHT,
    );
    fontUnit = cH / 100;
  } else {
    fontUnit = (cnvHeight ?? MAX_FULLSCREEN_HEIGHT) / 100;
  }
  const { color } = background;
  const backgroundClip = fontColor?.includes('linear') ? 'text' : 'initial';
  const colorBgOpacity = color?.includes('linear-gradient')
    ? gradientOpacity(color)
    : singleColorOpacity(color);
  const bgColor = fontColor?.includes('linear-gradient')
    ? gradientOpacity(fontColor)
    : colorBgOpacity;
  useFontLoader(fontFamily);
  const disabledForBias =
    [...addedBiasedValues, ...biasedValues].length > 0 &&
    [...addedBiasedValues, ...biasedValues].every((item) => !isObserved(item));
  const tooltipContent = (
    <div>
      <b>Original value:</b>{' '}
      <em>
        {displayPointValue(
          valueType,
          !biasMode && valueType === ValueTypeEnum.IMAGE
            ? originalValue?.versionId
            : getBiasValue() || elemValue,
          parameterType,
          originalValue?.jsonAnimation,
          urlOrig,
          unitOfMeasurement,
          true,
        )}
      </em>
    </div>
  );
  return (
    <ElementContainer
      visibility={panelProps.enabled}
      canvas={canvas}
      lock={valueType === ValueTypeEnum.IMAGE}
      disabled={!!disabled}
      type={'observedWDElements'}
      panelProps={panelProps}
      style={{
        fontFamily: fontFamily + ' ' + fontType,
        textTransform,
        color: fontColor?.includes('linear') ? 'transparent' : singleColorOpacity(fontColor),
        textAlign: fontAlignment as FontAlignment,
        fontSize: fontUnit * fontSize,
        border: indexOfBias > -1 ? '4px dashed red' : 'inherit',
        opacity:
          (biasMode &&
            biasParam !== panelProps.observedWDSource.parameterType &&
            biasParam &&
            panelProps.observedWDSource.valueType === ValueTypeEnum.NUMERICAL &&
            biasParam) ||
          (biasParam === 'image' &&
            panelProps.observedWDSource.valueType !== ValueTypeEnum.IMAGE &&
            biasParam) ||
          disabledForBias
            ? 0.3
            : 'inherit',
      }}
      parentTime={parentTime}
      inPoster={inPoster}
      posterId={posterId}
      parentSize={parentSize}
      isMapOverlay={isMapOverlay}
      parentMapId={mapId}
      geoPosterId={geoPosterId}
      onClick={() =>
        biasMode && !disabledForBias && dispatch(addPointForBiasObserved({ point: panelProps }))
      }
    >
      <div
        key={fontColor + 'container'}
        style={{
          height: '100%',
          overflow: 'hidden',
          background: indexOfConfirmed >= 0 && biasMode ? 'green' : bgColor,
          paddingTop: fontUnit * paddingTop,
          paddingLeft: fontUnit * paddingLeft,
          paddingRight: fontUnit * paddingRight,
          paddingBottom: fontUnit * paddingBottom,
          textAlign: 'left',
          borderLeft: borderString(borderLeft),
          borderRight: borderString(borderRight),
          borderTop: borderString(borderTop),
          borderBottom: borderString(borderBottom),
          // @ts-ignore
          WebkitBackgroundClip: backgroundClip,
        }}
      >
        <div style={{ overflow: 'hidden', width: '100%', height: '100%' }}>
          {(biasFilterAppliedOnValue ||
            (activeValue && activeValue.value !== activeValue.originalValue)) && (
            <Tooltip title={tooltipContent}>
              <div
                className={'absolute top-0 right-0 !bg-red-800 w-5 h-5 z-40 !border-0'}
                style={{
                  overflow: 'hidden',
                  transform: 'skew(45deg)',
                  transformOrigin: 'top right',
                }}
              />
            </Tooltip>
          )}
          <div
            style={{
              height: '100%',
              //
              // marginTop: valueType !== ValueTypeEnum.IMAGE ? fontSize * 1.15 : 0,
              lineHeight: 1.15,
              // @ts-ignore
              WebkitBackgroundClip: backgroundClip,
              textAlign: fontAlignment as FontAlignment,
              WebkitTextStroke: strokeWidth
                ? `${strokeWidth}px ${singleColorOpacity(strokeColor)}`
                : '0px',
            }}
          >
            {displayPointValue(
              valueType,
              !biasMode && valueType === ValueTypeEnum.IMAGE
                ? elemValue?.versionId
                : getBiasValue() || elemValue,
              parameterType,
              elemValue?.jsonAnimation,
              url,
              unitOfMeasurement,
              false,
              elemValue?.originalZipSequence,
              elemValue,
              undefined,
              timeControls,
            )}
            {displayUnitOfMeasurement && <span>{stringToUnit(unitOfMeasurement)}</span>}
          </div>
        </div>
      </div>
    </ElementContainer>
  );
};
