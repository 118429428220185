import './LPBackground.scss';

import { animated, to, useTrail } from '@react-spring/web';
import { useContext, useEffect, useState } from 'react';
import CountUp from 'react-countup';
import { AiOutlineBorderInner, AiOutlinePlayCircle } from 'react-icons/ai';
import ReactPlayer from 'react-player/lazy';
import { useNavigate } from 'react-router-dom';

import MarketplaceContext from '../../marketplaceContext';
// import Button from '../Button/AntButton';
import Modal from '../Modal/Modal';

// interface MyComponentProps {
//   open: boolean;
//   children?: React.ReactNode;
// }

const LPBackground = (props: any) => {
  const { name, description, type, backgroundImages, backgroundIcon } = props;
  const navigate = useNavigate();
  const { setType } = useContext(MarketplaceContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [scroll, setScroll] = useState(0);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    setScroll(scrollTop);
  };
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  // const Trail: React.FC<MyComponentProps> = ({ open, children }) => {
  //   const items = React.Children.toArray(children);
  //   const trail = useTrail(items.length, {
  //     config: { mass: 5, tension: 2000, friction: 200 },
  //     opacity: 1,
  //     x: 0,
  //     height: 110,
  //     from: { opacity: 0, x: 20, height: 0 },
  //   });
  //   return (
  //     <div>
  //       {trail.map(({ height, ...style }, index) => (
  //         <a.div key={index} style={style} className="trailsText">
  //           <a.div style={{ height }}>{items[index]}</a.div>
  //         </a.div>
  //       ))}
  //     </div>
  //   );
  // };

  const [trail] = useTrail(1, () => ({
    // trail: 1000,
    opacity: 1,
    sc: 0.9,
    x: '0%',
    y: '0px',
    skewX: 0,
    from: {
      opacity: 0,
      sc: 0.8,
      x: '100%',
      y: '100px',
      skewX: 20,
    },
  }));

  const [trail2] = useTrail(1, () => ({
    // trail: 1000,
    opacity: 1,
    x: '0%',
    y: '0px',
    // skewX: 0,
    from: {
      opacity: 0,
      sc: 0.8,
      x: '20px',
      y: '0',
      // skewX: 20,
    },
  }));

  return (
    <div className="lpbackground flex relative bg-gradient-to-r from-[#D5EAE9] to-[#F8F9F9] even:flex-row-reverse odd:bg-gradient-to-r odd:from-[#F9F8F8] odd:to-[#EBE0EB] items-center content-center justify-center rounded-[36px] px-10 lg:pr-28 lg:pl-20 mb-[30px] gap-20 2xl:gap-[15%]">
      <div className="w-full xl:w-2/3 z-[2]">
        <div className="containerTrail">
          {/* <Trail open={open}>
            <span>Search out </span>
            <span className="text-[#2176FF]">{name}</span>
            <span>marketplace</span>
          </Trail> */}
          {trail2.map(({ x, y, ...props }, i) => (
            <animated.div
              key={i}
              style={{
                ...props,
                transform: to([x, y], (x, y) => `translate(${x}, ${y})`),
              }}
            >
              <h1>Search out </h1>
              <h1 className="text-[#2176FF]">{name}</h1>
              <h1>marketplace</h1>
            </animated.div>
          ))}
        </div>
        <p
          className="mt-12 text-[17px] text-[#3A3A3A]"
          dangerouslySetInnerHTML={{ __html: description }}
        ></p>

        <div className="wrapperExplore">
          <div
            className="cta"
            onClick={() => {
              setType([type]);
              navigate('items');
            }}
          >
            {/* <Trail open={open}>
              <span>EXPLORE MORE</span>
            </Trail> */}

            {trail2.map(({ sc, x, y, ...props }, i) => (
              <animated.div
                key={i}
                style={{
                  ...props,
                  transform: to([sc, x, y], (sc, x, y) => `scale(${sc}) translate(${x}, ${y})`),
                }}
              >
                <span>EXPLORE MORE</span>
              </animated.div>
            ))}
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="66" height="43" viewBox="0 0 66 43">
                <g fill="#FFF" fillRule="evenodd" stroke="none" strokeWidth="1">
                  <path
                    className="one"
                    d="M40.154 3.895L43.976.139a.5.5 0 01.701 0l21.015 20.646a1 1 0 010 1.426l-21.015 20.65a.5.5 0 01-.7 0l-3.822-3.754a.5.5 0 010-.714l16.839-16.536a.5.5 0 000-.714L40.154 4.608a.5.5 0 010-.713z"
                  ></path>
                  <path
                    className="two"
                    d="M20.154 3.895L23.976.139a.5.5 0 01.701 0l21.015 20.646a1 1 0 010 1.426l-21.015 20.65a.5.5 0 01-.7 0l-3.822-3.754a.5.5 0 010-.714l16.839-16.536a.5.5 0 000-.714L20.154 4.608a.5.5 0 010-.713z"
                  ></path>
                  <path
                    className="three"
                    d="M.154 3.895L3.976.139a.5.5 0 01.701 0l21.015 20.646a1 1 0 010 1.426L4.677 42.861a.5.5 0 01-.7 0L.154 39.107a.5.5 0 010-.714l16.839-16.536a.5.5 0 000-.714L.154 4.608a.5.5 0 010-.713z"
                  ></path>
                </g>
              </svg>
            </span>
          </div>
        </div>
        {/* <Button
          size="large"
          type="primary"
          className="uppercase flex items-center p-4 !rounded mt-16"
          onClick={() => {
            setType([type]);
            navigate('items');
          }}
        >
          Explore more <AiOutlineArrowRight size={20} className="ml-4" />
        </Button> */}
        <div className="flex my-10 md:mt-16">
          <div className="border-r pr-4 md:pr-10 text-center">
            <h3 className="counts md:text-3xl">
              <CountUp end={300} />
              <span className="plus">+</span>
            </h3>
            <p className="uppercase text-gray-400 text-lg">projects</p>
          </div>
          <div className="md:pl-10 pl-4 pr-4 md:pr-10 border-r text-center">
            <h3 className="counts md:text-3xl">
              <CountUp end={250} />
              <span className="plus">+</span>
            </h3>
            <p className="uppercase text-gray-400 text-lg">images</p>
          </div>
          <div className="md:pl-10 pl-4 text-center">
            <h3 className="counts md:text-3xl">
              <CountUp end={450} />
              <span className="plus">+</span>
            </h3>
            <p className="uppercase text-gray-400 text-lg">videos</p>
          </div>
        </div>
      </div>
      {/* <Parallax pages={1} style={{ top: '0', left: '0' }}>
        <ParallaxLayer offset={0} speed={2.5}> */}
      <div className="images hidden md:flex w-full flex-row items-center h-[600px] overflow-hidden z-[2]">
        {name === 'Videos' || name === 'Audios' ? (
          <>
            <div className="relative">
              <img
                className="w-[280px] h-[350px] lg:w-[320px] lg:h-[390px] xl:w-[280px] xl:h-[350px] mx-10 rounded-[10px] drop-shadow-lg aspect-auto"
                src={backgroundImages[0]}
                style={{ transform: `translateY(${-scroll / 15}px)` }}
              />

              <AiOutlinePlayCircle
                onClick={toggleModal}
                size={65}
                color="white"
                className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 hover:fill-[#2176FF] hover:cursor-pointer"
              />
              <Modal
                className="w-[100%] lg:!w-[50%] !p-0"
                centered
                closable={false}
                open={isModalOpen}
                onCancel={toggleModal}
                footer={null}
                destroyOnClose={true}
              >
                {name === 'Videos' ? (
                  <ReactPlayer
                    controls
                    width="100%"
                    height="100%"
                    playing={isModalOpen}
                    url={'/mapEarth.mp4'}
                    config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                  />
                ) : (
                  <ReactPlayer
                    controls
                    width="100%"
                    height="50px"
                    playing={isModalOpen}
                    url={
                      'https://storage.googleapis.com/media-session/elephants-dream/the-wires.mp3'
                    }
                    config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                  />
                )}
              </Modal>
            </div>
          </>
        ) : (
          trail.map(({ sc, x, y, skewX, ...props }, i) => (
            <animated.div
              key={i}
              style={{
                ...props,
                transform: to(
                  [sc, x, y, skewX],
                  (sc, x, y, skewX) => `scale(${sc}) translate(${x}, ${y}) skewX(${skewX}deg)`,
                ),
              }}
            >
              <img
                className="w-[280px]  lg:w-[320px] lg:h-[390px] xl:w-[280px] mx-10 rounded-[10px] drop-shadow-lg aspect-auto"
                src={backgroundImages[0]}
                style={{ transform: `translateY(${-scroll / 30}px)` }}
              />
            </animated.div>
          ))
        )}
        <div>
          {trail.map(({ sc, x, y, skewX, ...props }, i) => (
            <animated.div
              key={i}
              style={{
                ...props,
                transform: to(
                  [sc, x, y, skewX],
                  (sc, x, y, skewX) => `scale(${sc}) translate(${x}, ${y}) skewX(${skewX}deg)`,
                ),
              }}
            >
              <img
                className="hidden xl:block w-[280px]  mt-[250px] mb-10 rounded-[10px] drop-shadow-lg"
                src={backgroundImages[1]}
                style={{ transform: `translateY(${-scroll / 20}px)` }}
              />
            </animated.div>
          ))}
          {trail.map(({ sc, x, y, skewX, ...props }, i) => (
            <animated.div
              key={i}
              style={{
                ...props,
                transform: to(
                  [sc, x, y, skewX],
                  (sc, x, y, skewX) => `scale(${sc}) translate(${x}, ${y}) skewX(${skewX}deg)`,
                ),
              }}
            >
              <img
                className="hidden xl:block w-[280px]  rounded-[10px] drop-shadow-lg"
                src={backgroundImages[2]}
                style={{ transform: `translateY(${-scroll / 20}px)` }}
              />
            </animated.div>
          ))}
        </div>
      </div>
      {/* </ParallaxLayer>
      </Parallax> */}
      <AiOutlineBorderInner
        size={350}
        color="white"
        className="odd hidden xl:block absolute bottom-[-32%] right-[-2%] z-0"
      />
      <AiOutlineBorderInner
        size={280}
        color="white"
        className="odd hidden xl:block absolute top-[4%] right-[25%] z-0 floating"
      />
      {backgroundIcon}
      <AiOutlineBorderInner
        size={150}
        color="white"
        className="even hidden xl:block absolute top-[11%] left-[35%] z-0 rotating"
      />
      <AiOutlineBorderInner
        size={230}
        color="white"
        className="even hidden xl:block absolute bottom-[5%] left-[27%] z-0"
      />
    </div>
  );
};

export default LPBackground;
