import { useQuery } from 'react-query';

import { axiosInstance } from '../../axiosInstance';

export interface BiasGroupFilter {
  id: string;
  name: string;
  type: string;
}
const key = ['BIAS_GROUP'];
export const useGetBiasGroups = (type?: string) =>
  useQuery(
    [...key, type],
    async (): Promise<Array<BiasGroupFilter>> => {
      const { data } = await axiosInstance.get(`biasGroupPreview/${type}`);
      return data;
    },
    {
      enabled: !!type,
    },
  );
