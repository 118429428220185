import { sortedUniqBy } from 'lodash';

import { DataFrameDef } from '../model/definitions/DataFrameDef';

function findMostFrequent(array: number[]): number {
  if (array.length === 0) {
    return 0; // Return null for empty array
  }

  const frequencyMap: { [key: number]: number } = {};
  let maxFrequency = 0;
  let mostFrequentElement = array[0]; // Initialize with the first element

  array.forEach(function (element) {
    // Count the frequency of each element
    frequencyMap[element] = (frequencyMap[element] || 0) + 1;

    // Update maxFrequency and mostFrequentElement if necessary
    if (frequencyMap[element] > maxFrequency) {
      maxFrequency = frequencyMap[element];
      mostFrequentElement = element;
    }
  });

  return mostFrequentElement;
}
// Function to find the greatest common divisor (GCD)
function gcd(a: number, b: number): number {
  if (!b) {
    return a;
  }
  return gcd(b, a % b);
}

// Function to find the GCD for an array of time differences
function findGCD(timeDiffs: number[]): number | null {
  // If the array is empty, return null
  if (timeDiffs.length === 0) {
    return null;
  }
  return timeDiffs.reduce((acc, timeDiff) => gcd(acc, timeDiff), timeDiffs[0]);
}

// Function to generate new frames based on the GCD
function getNormalisedFrames(data: DataFrameDef[]): DataFrameDef[] {
  // Handle case of an empty data array
  if (data.length === 0) {
    return []; // Return an empty array if no frames exist
  }

  // Step 1: Sort data by timestamp (if not already sorted)
  const frames = data
    .map((d) => ({
      ...d,
      timestamp: Math.round(d.timestamp / 60) * 60,
    }))
    .sort((a, b) => a.timestamp - b.timestamp);

  const uniqueFrames = sortedUniqBy(frames, 'timestamp');
  // Step 2: Calculate time differences between consecutive timestamps
  const timeDiffs: number[] = [];
  for (let i = 1; i < uniqueFrames.length; i++) {
    timeDiffs.push(uniqueFrames[i].timestamp - uniqueFrames[i - 1].timestamp);
  }

  // Step 3: Find the GCD of the time differences
  const gcdValue = findGCD(timeDiffs);

  // If the GCD is null, return the original data (no new frames can be generated)
  if (!gcdValue) {
    return uniqueFrames;
  }

  // Step 4: Generate new frame IDs and timestamps
  const result: DataFrameDef[] = [];
  for (let i = 0; i < uniqueFrames.length - 1; i++) {
    const currentFrame = uniqueFrames[i];
    const nextFrame = uniqueFrames[i + 1];

    // Add the original frame
    result.push(currentFrame);

    // Generate new frames based on GCD
    let newTimestamp = currentFrame.timestamp + gcdValue;
    while (newTimestamp < nextFrame.timestamp) {
      result.push({
        frameId: currentFrame.frameId, // Indicate this is a generated frame
        timestamp: newTimestamp,
      });
      newTimestamp += gcdValue;
    }
  }

  // Add the last original frame
  result.push(uniqueFrames[uniqueFrames.length - 1]);
  return result;
}

const getMedianDifferenceFromTimestamp = (frames: DataFrameDef[]) => {
  const timestamps: number[] = frames.map((item) => item.timestamp * 1000); // Convert to milliseconds

  // Calculate differences between consecutive timestamps in seconds
  const differencesInSeconds: number[] = timestamps
    .slice(1)
    .map((timestamp, index) => (timestamp - timestamps[index]) / 1000);

  // Sort the differences
  const sortedDifferences = differencesInSeconds.sort((a, b) => a - b);

  // Find the median of the sorted differences
  /*   const medianIndex = Math.floor(sortedDifferences.length / 2);
  return sortedDifferences.length % 2 === 0
    ? (sortedDifferences[medianIndex - 1] + sortedDifferences[medianIndex]) / 2
    : sortedDifferences[medianIndex]; */
  return findMostFrequent(sortedDifferences);
};

/* const getNormalisedFrames = (data: DataFrameDef[]) => {
  if (!data || data.length === 0) return [];

  // Round timestamps to the nearest minute and sort by timestamp
  const frames = data
    .map((d) => ({
      ...d,
      timestamp: Math.round(d.timestamp / 60) * 60,
    }))
    .sort((a, b) => a.timestamp - b.timestamp);

  // Remove duplicates based on timestamp
  const uniqueFrames = sortedUniqBy(frames, 'timestamp');

  const updatedFrames = [];
  const medianDifference = Math.round(getMedianDifferenceFromTimestamp(uniqueFrames));

  for (let i = 0; i < uniqueFrames.length - 1; i++) {
    const currentFrame = uniqueFrames[i];
    const nextFrame = uniqueFrames[i + 1];

    updatedFrames.push(currentFrame);

    const timestampDifference = nextFrame.timestamp - currentFrame.timestamp;

    // If there's a gap, fill it with the previous frameId, ensuring equal intervals
    if (timestampDifference > medianDifference) {
      let currentTimestamp = currentFrame.timestamp;

      while (currentTimestamp + medianDifference < nextFrame.timestamp) {
        currentTimestamp += medianDifference;
        updatedFrames.push({
          frameId: currentFrame.frameId,
          timestamp: currentTimestamp,
        });
      }
    }
  }

  // Add the last frame
  updatedFrames.push(uniqueFrames[uniqueFrames.length - 1]);

  return updatedFrames;
}; */
export { getMedianDifferenceFromTimestamp, getNormalisedFrames };
