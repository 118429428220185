import '../style/cards.scss';
import '../style/payment.scss';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Loader from '../../dashboard/components/loader-NEW/Loader';
import { useChargeCard } from '../../marketplace-new/hooks/payment/useChargeCard';
import AntSelect from '../../marketplace-new/molecules/AntSelect/AntSelect';
import IChargeCardRequest from '../types/IChargeCardRequest.type';
import OneTimePayment from './OneTimePayment';

let stripePromise: Stripe | PromiseLike<Stripe | null> | null;
if (process.env.REACT_APP_STRIPE_PUBLIC_API_KEY) {
  stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_API_KEY);
}

export default function CheckoutForm(props: any) {
  const [id] = useState(props.transactionId);
  const { data: confirmedTransaction, mutate: chargeCard, isLoading } = useChargeCard();
  const navigate = useNavigate();

  useEffect(() => {
    if (confirmedTransaction && location.pathname.includes('checkout')) {
      navigate('/marketplace/confirm');
    }
  }, [confirmedTransaction]);

  const appearance = {
    theme: 'stripe',
  };
  const [options] = useState<any>({
    clientSecret: props.transaction.clientSecret,
    appearance,
  });

  const makePayment = (fingerprint: string, id: string) => {
    const chargeCardReq: IChargeCardRequest = {
      transactionId: id,
      fingerprint: fingerprint,
    };

    chargeCard(chargeCardReq!);
  };

  return (
    <div className="w-4/5 h-full px-6 py-8 bg-[#F5F9FC]">
      {isLoading ? (
        <div className="flex justify-center">
          <Loader />
        </div>
      ) : (
        <>
          <div className="text-2xl font-semibold">Pay with</div>
          <div className="lin-ep"></div>
          <div>
            {props?.savedCards?.length > 0 && (
              <AntSelect
                size="large"
                className="mt-4 mb-12"
                placeholder="Select payment card"
                options={props?.savedCards?.map((item: any) => {
                  return {
                    key: item.id,
                    label: '**** **** **** ' + item?.card?.last4,
                    value: item?.card?.fingerprint,
                  };
                })}
                onChange={(value) => {
                  makePayment(value, id);
                }}
              />
            )}
          </div>
          <div>
            <p className="text-2xl font-semibold mb-4">One time payment</p>
            <Elements options={options} stripe={stripePromise}>
              <OneTimePayment
                clientSecret={options.clientSecret}
                amount={props.transaction.amount}
              />
            </Elements>
          </div>
        </>
      )}
    </div>
  );
}
