import { useQuery } from 'react-query';

import { axiosInstance } from '../../../core/api/axiosInstance';

const key = ['ENTERPISE_ACCOUNT'];
export const useGetEnterprise = () =>
  useQuery(
    [key],
    async () => {
      const { data } = await axiosInstance.get(`enterprise-accounts/info/by-user`);
      return data;
    },
    {
      staleTime: Infinity,
    },
  );
