import { C9Object } from './C9Object';
import { LogicalGroupParent } from './LogicalGroupParent';
import { PositionControlDef } from './PositionControlDef';
import { TimeControlDef } from './TimeControlDef';
import { VideoPanelDefTemplate } from './VideoPanelDefTemplate';

export class VideoPanelDef extends C9Object {
  timeControls = [new TimeControlDef()];
  positionControl = new PositionControlDef(0, 0);
  playSpeed = 1;
  useAudioAsAudioElement = true;
  loopVideo: boolean;
  parentGroups = [new LogicalGroupParent()];
  videoPanelDefTemplate: VideoPanelDefTemplate;
  enabled: boolean;
  audioVolume = 1;
  enableAudio = true;
  opacity = 1;
}
