export const RADAR_FRAG = `precision mediump float;

uniform float frame_count;
uniform float interval;

uniform vec2 u_wind_res;
uniform float u_wind_min;
uniform float u_wind_max;
uniform float u_wind_min_next;
uniform float u_wind_max_next;

uniform vec2 resolution;

uniform float scale_min;
uniform float scale_max;

uniform sampler2D u_wind;
uniform sampler2D u_wind_next;

uniform int u_interpolate;

uniform sampler2D u_color_ramp; // color ramp is encoded in a 16x16 texture

varying vec2 v_pixel_pos;


float lerpTemp(float temp, float temp2) {
    float t = frame_count / interval;
    if(u_interpolate==1) {
        return mix(temp, temp2, t);
    }
    return temp;
}


float testTemp(const vec2 uv, sampler2D u_wind_pn) {
    return texture2D(u_wind_pn, uv).r;
}

float getInterpolatedColorLinear(const vec2 uv, sampler2D u_wind_pn) {
    vec2 delta = 1.0 / u_wind_res;
    vec2 vc = (floor(uv * u_wind_res)) * delta;

    float tl = texture2D(u_wind_pn, vc).r;
    float tr = texture2D(u_wind_pn, vc + vec2(delta.x, 0)).r;
    float bl = texture2D(u_wind_pn, vc + vec2(0, delta.y)).r;
    float br = texture2D(u_wind_pn, vc + delta).r;

    vec2 f = fract(uv * u_wind_res);
    return mix(mix(tl, tr, f.x), mix(bl, br, f.x), f.y);
}


float get_image_pixel_from_temp(float temp){
    float step = (abs(scale_min)+abs(scale_max))/(512.0 - 1.0); // Temperature step per pixel
    return (temp - scale_min)/step;
}

void main() {

    float temp = mix(u_wind_min, u_wind_max, testTemp(v_pixel_pos, u_wind));
    float temp2 = mix(u_wind_min_next, u_wind_max_next, getInterpolatedColorLinear(v_pixel_pos, u_wind_next));

    float normalized_0_1 = get_image_pixel_from_temp(temp)/512.0;
    float normalized_0_2 = get_image_pixel_from_temp(temp2)/512.0;

    // color ramp is encoded in a 512x1 texture
    vec2 ramp_pos = vec2(lerpTemp(normalized_0_1,normalized_0_2), 0.5);
    vec4 clr = texture2D(u_color_ramp, ramp_pos);
    gl_FragColor = clr;

}
`;
