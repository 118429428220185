import { useMutation } from 'react-query';

import { axiosInstance } from '../../../core/api/axiosInstance';
import { queryClient } from '../../../index';
import { key as getUserDataKey } from './useGetProfileInfo';

const apiURL = `${process.env.REACT_APP_KEYCLOAK_URL}/realms/C9POC/account`;
export const useUpdateProfileInfo = () => {
  return useMutation(
    async ({
      firstName,
      lastName,
      username,
      email,
    }: {
      firstName: string;
      lastName: string;
      username: string;
      email: string;
    }) => {
      const { data } = await axiosInstance.post(`${apiURL}`, {
        firstName,
        lastName,
        username,
        email,
      });
      return data;
    },

    { onSuccess: () => queryClient.invalidateQueries(getUserDataKey) },
  );
};
