import { Select } from 'flowbite-react';
import { uniqBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { AiOutlineCaretDown, AiOutlineCaretUp } from 'react-icons/ai';
import { MdDeleteOutline } from 'react-icons/md';
import { useMutation, useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../../atoms/button/Button';
import Input from '../../../atoms/input/Input';
import LoadingIndicator from '../../../atoms/loadingIndicator/LoadingIndicator';
import Tabs, { TabItemProp } from '../../../atoms/Tabs/Tabs';
import { postCity } from '../../../core/api/mapLayers/LayersAPI';
import { useFontLoader, useFontSetter } from '../../../core/api/useLoadFont';
import { queryCities } from '../../../core/api/WeatherAPI';
import { useDebounce } from '../../../hooks/useDebounce';
import {
  DEFAULT_FONT_FAMILY,
  DEFAULT_FONT_TYPE,
  DEFAULT_FONT_VARIANT_ID,
  MAX_ZINDEX_VALUE,
} from '../../../model/constants/constants';
import { AnchorDef } from '../../../model/definitions/AnchorDef';
import { CityGeoPosterDef } from '../../../model/definitions/CityGeoPosterDef';
import { MapPanelDef } from '../../../model/definitions/MapPanelDef';
import { PositionControlDef } from '../../../model/definitions/PositionControlDef';
import { TextPanelDef } from '../../../model/definitions/TextPanelDef';
import Modal from '../../../molecules/modal/Modal';
import { CitiesQueryDTO } from '../../../organisms/addElementModal/CitiesQueryDTO';
import { ActiveDef } from '../../../store/slices/active-slice';
import { updateMapLayer } from '../../../store/slices/project-slice';
import { RootState } from '../../../store/store';
import InputNumber from '../../marketplace-new/atoms/FormatNumber/FormatNumber';
import transformText from '../properties/components/slatejs/transformText';
import { PaletteColorPicker } from '../properties/mapLayersProperties/PalettecolorPicker';
import { FontInterface, FontVariantInterface } from '../properties/panels/FontProperties';
import GridItem from '../properties/shared/GridItem';
import GridWrapper from '../properties/shared/GridWrapper';
import styles from './AddCitiesModal.module.scss';
import { CitiesImageInput } from './components/CitiesImageInput';
import SearchByViewport from './SearchByViewport';

interface Props {
  opened: boolean;
  onClose: () => void;
  onCitiesSelect: (cities: CityGeoPosterDef[]) => void;
  mapDef: MapPanelDef;
  fonts: FontInterface[];
}

export const CITY_STYLE_DEFAULT = {
  fontSize: 3.5,
  fontFamily: DEFAULT_FONT_FAMILY,
  fontType: DEFAULT_FONT_TYPE,
  fontVariantId: DEFAULT_FONT_VARIANT_ID,
  fontColor: 'rgba(255, 255, 255, 255)',
  fontAlignment: 'left',
  type: 'shape',
  imageURL: null,
  shape: 'circle',
  size: 1.2, //radius/height
  width: 20,
  height: 20,
  fillColor: 'rgba(80, 10, 10, 255)',
  borderColor: 'rgba(255, 255, 255, 255)',
  borderWidth: 3,
  zindex: 20,
  strokeWidth: 0,
  strokeColor: 'rgba(50, 10, 10, 255)',
  textTransform: 'none' as
    | 'none'
    | 'capitalize'
    | 'uppercase'
    | 'lowercase'
    | 'full-width'
    | 'full-size-kana',
};

const AddCitiesModal: React.FC<Props> = ({ opened, onClose, onCitiesSelect, mapDef, fonts }) => {
  const { activeScene } = useSelector<RootState>((state) => state.active) as ActiveDef;
  const dispatch = useDispatch();
  const [cityQuery, setCityQuery] = useState('');
  const [dropdownOpened, setDropdownOpened] = useState(false);
  const [tab, setTab] = useState<string | number>('by-viewport');
  const debouncedQuery = useDebounce(cityQuery, 300);
  const [defaultStyle, setDefaultStyle] = useState<typeof CITY_STYLE_DEFAULT>(
    mapDef.properties.defaultCityStyle
      ? JSON.parse(mapDef.properties.defaultCityStyle)
      : CITY_STYLE_DEFAULT,
  );
  useEffect(() => {
    !mapDef.properties.defaultCityStyle &&
      dispatch(
        updateMapLayer({
          newValue: JSON.stringify(CITY_STYLE_DEFAULT),
          propertyPath: 'properties.defaultCityStyle',
          elementId: mapDef.id,
          activeScene,
        }),
      );
  }, [mapDef.id]);
  const { fontVariants, fetchFontVariants, setFontId } = useFontLoader(defaultStyle.fontFamily);

  useFontSetter(
    fontVariants,
    defaultStyle.fontType,
    fontTypeSetter,
    fontVariantIdSetter,
    fetchFontVariants.isLoading,
  );

  function fontTypeSetter(value: string) {
    onDefaultChange('fontType', value);
  }

  function fontVariantIdSetter(value: string) {
    onDefaultChange('fontVariantId', value);
  }

  const [selectedCities, setSelectedCities] = useState<
    (CitiesQueryDTO & typeof CITY_STYLE_DEFAULT & { overrideName: string })[]
  >([]);
  const [defaultStylesOpen, setDefaultStylesOpen] = useState(false);

  const cityMutation = useMutation(postCity, {
    onSuccess: (data) => {
      data.forEach((d) => {
        d.properties.defaultStyle = JSON.stringify(defaultStyle);
        d.properties.defaultLon = `${d.anchor.longitude}`;
        d.properties.defaultLat = `${d.anchor.latitude}`;
        d.properties.originalName = `${d.textPanels[0].value}`;
        d.positionControl = new PositionControlDef();
        d.positionControl.zindex = defaultStyle.zindex;
      });
      onCitiesSelect(data);
    },
  });

  const { data: citiesList, isLoading } = useQuery(
    ['query-cities', debouncedQuery],
    () => queryCities(debouncedQuery),
    { cacheTime: Infinity, staleTime: Infinity },
  );
  function onClickTab(e: TabItemProp) {
    if (e.itemKey) {
      setSelectedCities([]);
      setTab(e.itemKey);
    }
  }
  function onChange(ev: React.ChangeEvent<HTMLInputElement>): void {
    setDropdownOpened(true);
    setCityQuery(ev.target.value);
  }
  function onCitySelect(city: CitiesQueryDTO) {
    setCityQuery('');

    setSelectedCities((cities) =>
      uniqBy([...cities, { ...city, ...defaultStyle, overrideName: city.name }], 'id'),
    );
    setDropdownOpened(false);
  }

  function onCityChange(
    cityId: number | string,
    prop: keyof (CitiesQueryDTO & typeof CITY_STYLE_DEFAULT & { overrideName: string }),
    newValue: any,
  ) {
    setSelectedCities((cities) =>
      cities.map((c) => {
        if (c.id !== cityId) return { ...c };
        return { ...c, [prop]: newValue };
      }),
    );
  }

  function onDefaultChange(prop: keyof typeof defaultStyle, newValue: any) {
    setDefaultStyle((s) => ({ ...s, [prop]: newValue }));
    dispatch(
      updateMapLayer({
        newValue: JSON.stringify({
          ...JSON.parse(mapDef.properties.defaultCityStyle),
          [prop]: newValue,
        }),
        propertyPath: 'properties.defaultCityStyle',
        elementId: mapDef.id,
        activeScene,
      }),
    );
    setSelectedCities((cities) => cities.map((c) => ({ ...c, [prop]: newValue })));
  }

  function onDelete(city: CitiesQueryDTO & typeof CITY_STYLE_DEFAULT & { overrideName: string }) {
    const cities = selectedCities.filter((c) => c.id !== city.id);
    setSelectedCities(cities);
  }

  function onOk() {
    const citiesToSave = selectedCities.map((city) => {
      const cityTextPanel = new TextPanelDef();
      cityTextPanel.value = city.overrideName;
      cityTextPanel.fontSize = city.fontSize;
      cityTextPanel.fontFamily = city.fontFamily;
      cityTextPanel.fontType = city.fontType;
      cityTextPanel.fontVariantId = city.fontVariantId;
      cityTextPanel.fontColor = city.fontColor;
      cityTextPanel.textTransform = city.textTransform;
      cityTextPanel.fontAlignment = city.fontAlignment;
      cityTextPanel.strokeWidth = city.strokeWidth;
      cityTextPanel.strokeColor = city.strokeColor;
      const cityAnchor = new AnchorDef(city.lon, city.lat);
      cityAnchor.type = city.type as 'shape' | 'image';
      cityAnchor.imageURL = city.imageURL;
      cityAnchor.shape = city.shape as 'circle' | 'square' | 'cross';
      cityAnchor.size = city.size;
      cityAnchor.width = city.width;
      cityAnchor.height = city.height;
      cityAnchor.borderColor = city.borderColor;
      cityAnchor.borderWidth = city.borderWidth;
      cityAnchor.fillColor = city.fillColor;
      return {
        cityName: city.name,
        cityState: city.country,
        overrideName: city.overrideName,
        lat: city.lat,
        lon: city.lon,
        cityAnchor,
        cityTextPanelDef: cityTextPanel,
      };
    });
    cityMutation.mutate(citiesToSave);
  }

  return (
    <Modal
      isOpen={opened}
      onClose={onClose}
      bodyClassName={'SendAssemblyModal__modal-body add-city-modal'}
      className={'AddCitiesModal__modal-wrap'}
      header={'Add cities labels'}
      footer={
        <div className={'modal-footer flex'}>
          <Button label={'Save'} buttonType="primary" onClick={onOk} />
          <Button label={'Cancel'} buttonType="secondary" onClick={onClose} />
        </div>
      }
    >
      <div className="min-w-[890px]">
        <Tabs
          active={tab}
          key={'cities-tab'}
          onClick={onClickTab}
          style={{ backgroundColor: '#05152b', height: 'fill-available' }}
        >
          <Tabs.Item
            title="Search by name"
            itemKey={'by-name'}
            key={'by-name'}
            style={{ backgroundColor: '#05152b', height: 'fill-available' }}
          >
            <div className="max-h-[500px] overflow-y-auto properties__tabs_wrapper add-cities-modal-wrapper scrollbar">
              <div id="Input" className="relative">
                <label className="text-sm relative" htmlFor="search-center">
                  <span>*</span>Select cities that will be visible on map:{' '}
                </label>
                <div className="relative">
                  <button
                    className="AddCitiesModal__caret"
                    onClick={() => setDropdownOpened((o) => !o)}
                  >
                    {dropdownOpened ? <AiOutlineCaretUp /> : <AiOutlineCaretDown />}
                  </button>
                  <input
                    autoFocus={true}
                    style={{ width: '100%' }}
                    placeholder={'Type here...'}
                    autoComplete="off"
                    id="search-center"
                    type="text"
                    value={cityQuery}
                    onChange={onChange}
                    className="rounded-md"
                    required
                  />
                </div>
                {Boolean(cityQuery) && dropdownOpened && (
                  <div className={'search-result'}>
                    {isLoading ? (
                      <div className={'flex w-1/2 loading'}>
                        <div className={'flex items-center mr-3'}>
                          <LoadingIndicator />
                        </div>
                        Searching
                      </div>
                    ) : citiesList && citiesList.length ? (
                      citiesList.map((c) => (
                        <div key={c.id} onClick={() => onCitySelect(c)} className={'result'}>
                          {c.display_name}
                        </div>
                      ))
                    ) : (
                      <div className={'flex justify-center items-center'}>no results</div>
                    )}
                  </div>
                )}
              </div>
              <div className="mt-6">
                {selectedCities.map((c) => (
                  <div key={c.id} className="flex items-center justify-between p-4 gap-2">
                    <div>City: {c.name}</div>
                    <div>Country: {c.country}</div>
                    <div>
                      Label name:{' '}
                      <input
                        onChange={(e) => onCityChange(c.id, 'overrideName', e.target.value)}
                        value={c.overrideName}
                      ></input>
                    </div>
                    <Button
                      buttonType="link"
                      onClick={() => onDelete(c)}
                      style={{ marginLeft: 'auto' }}
                      icon={<MdDeleteOutline style={{ color: '#fa5656', fontSize: '15px' }} />}
                    />
                  </div>
                ))}
              </div>
              <div className={styles.wrapper}>
                <div
                  className={`mb-2 subheader`}
                  style={{ marginTop: '6px', fontWeight: '500', fontSize: '15px' }}
                  onClick={() => setDefaultStylesOpen((o) => !o)}
                >
                  {defaultStylesOpen ? <AiOutlineCaretUp /> : <AiOutlineCaretDown />}
                  Default styles
                </div>
                {defaultStylesOpen && (
                  <div>
                    <div
                      className={`mb-2 subheader`}
                      style={{ paddingLeft: '30px', fontWeight: '400', marginTop: '6px' }}
                    >
                      Anchor styles
                    </div>
                    <div className="mt-4">
                      <div className="prop-wrapper">
                        <GridWrapper>
                          <GridItem
                            label="Anchor type:"
                            noBorderBg
                            item={
                              <Select
                                className={styles.select}
                                onChange={(e) => onDefaultChange('type', e.target.value)}
                                value={defaultStyle.type}
                              >
                                {['shape', 'image'].map((k) => (
                                  <option key={k} value={k}>
                                    {k}
                                  </option>
                                ))}
                              </Select>
                            }
                          />
                          {defaultStyle.type == 'shape' ? (
                            <>
                              <GridItem
                                label="Shape:"
                                noBorderBg
                                item={
                                  <Select
                                    className={styles.select}
                                    onChange={(e) => onDefaultChange('shape', e.target.value)}
                                    value={defaultStyle.shape}
                                  >
                                    {['circle', 'square', 'cross'].map((k) => (
                                      <option key={k} value={k}>
                                        {k}
                                      </option>
                                    ))}
                                  </Select>
                                }
                              />
                              <GridItem
                                label={'Size:'}
                                noBorderBg
                                item={
                                  <Input
                                    style={{ padding: '0' }}
                                    type={'number'}
                                    onChange={(e) => onDefaultChange('size', e.target.value)}
                                    value={defaultStyle.size}
                                    className={styles.inputWrap}
                                  />
                                }
                              />

                              <GridItem
                                noBorderBg
                                label={'Fill color:'}
                                item={
                                  <PaletteColorPicker
                                    value={defaultStyle.fillColor}
                                    onChange={(e) => onDefaultChange('fillColor', e)}
                                  />
                                }
                              />

                              <GridItem
                                noBorderBg
                                label={'Border color:'}
                                item={
                                  <PaletteColorPicker
                                    value={defaultStyle.borderColor}
                                    onChange={(e) => onDefaultChange('borderColor', e)}
                                  />
                                }
                              />
                              <GridItem
                                label={'Border width:'}
                                noBorderBg
                                item={
                                  <Input
                                    style={{ padding: '0' }}
                                    type={'number'}
                                    value={defaultStyle.borderWidth}
                                    className={styles.inputWrap}
                                    onChange={(e) => onDefaultChange('borderWidth', e.target.value)}
                                  />
                                }
                              />
                            </>
                          ) : null}
                          <GridItem
                            label={'Layer level:'}
                            noBorderBg
                            item={
                              <InputNumber
                                style={{ padding: '0' }}
                                min={0}
                                max={MAX_ZINDEX_VALUE}
                                step={1}
                                value={defaultStyle.zindex}
                                onInputChange={(e) => onDefaultChange('zindex', e)}
                              />
                            }
                          />
                          {defaultStyle.type == 'image' ? (
                            <>
                              <GridItem
                                itemStyle={{ height: 'auto' }}
                                label={'Image:'}
                                noBorderBg
                                item={
                                  <CitiesImageInput
                                    value={defaultStyle.imageURL}
                                    onChange={(e) => onDefaultChange('imageURL', e)}
                                  />
                                }
                              />
                              {defaultStyle.imageURL && (
                                <>
                                  <GridItem
                                    label={'Width:'}
                                    noBorderBg
                                    item={
                                      <Input
                                        style={{ padding: '0' }}
                                        type={'number'}
                                        onChange={(e) => onDefaultChange('width', e.target.value)}
                                        value={defaultStyle.width}
                                        className={styles.inputWrap}
                                      />
                                    }
                                  />
                                  <GridItem
                                    label={'Height:'}
                                    noBorderBg
                                    item={
                                      <Input
                                        style={{ padding: '0' }}
                                        type={'number'}
                                        onChange={(e) => onDefaultChange('height', e.target.value)}
                                        value={defaultStyle.height}
                                        className={styles.inputWrap}
                                      />
                                    }
                                  />
                                </>
                              )}
                            </>
                          ) : null}
                        </GridWrapper>
                      </div>
                    </div>
                    <div
                      className={`mb-2 subheader `}
                      style={{ paddingLeft: '30px', fontWeight: '400', marginTop: '6px' }}
                    >
                      Label styles
                    </div>
                    <div className="mt-4">
                      <div className="prop-wrapper">
                        <GridWrapper>
                          <GridItem
                            label={'Font size:'}
                            noBorderBg
                            item={
                              <Input
                                style={{ padding: '0' }}
                                type={'number'}
                                value={defaultStyle.fontSize}
                                onChange={(e) => onDefaultChange('fontSize', e.target.value)}
                                className={styles.inputWrap}
                              />
                            }
                          />

                          <GridItem
                            label="Font family:"
                            noBorderBg
                            item={
                              <Select
                                className={styles.select}
                                value={defaultStyle.fontFamily}
                                onChange={(e) => {
                                  onDefaultChange('fontFamily', e.target.value);
                                  onDefaultChange('fontType', '');
                                  onDefaultChange('fontVariantId', '');
                                  dispatch(
                                    updateMapLayer({
                                      newValue: JSON.stringify({
                                        ...JSON.parse(mapDef.properties.defaultCityStyle),
                                        fontFamily: e.target.value,
                                        fontType: '',
                                        fontVariantId: '',
                                      }),
                                      propertyPath: 'properties.defaultCityStyle',
                                      elementId: mapDef.id,
                                      activeScene,
                                    }),
                                  );
                                }}
                              >
                                {fonts?.map((font: FontInterface) => (
                                  <option
                                    key={font.id}
                                    value={font.name.split('(')[0]}
                                    style={{ fontFamily: font.name.split('(')[0] }}
                                  >
                                    {font.name}
                                  </option>
                                ))}
                              </Select>
                            }
                          />

                          <GridItem
                            label="Font type:"
                            noBorderBg
                            item={
                              <Select
                                className={styles.select}
                                value={defaultStyle.fontType}
                                onChange={(e) => {
                                  const findType = fontVariants.find(
                                    (fontVariant: FontVariantInterface) =>
                                      fontVariant.type === e.target.value,
                                  );
                                  onDefaultChange('fontType', e.target.value);
                                  onDefaultChange('fontVariantId', findType?.id);
                                  dispatch(
                                    updateMapLayer({
                                      newValue: JSON.stringify({
                                        ...JSON.parse(mapDef.properties.defaultCityStyle),
                                        fontType: e.target.value,
                                        fontVariantId: findType?.id,
                                      }),
                                      propertyPath: 'properties.defaultCityStyle',
                                      elementId: mapDef.id,
                                      activeScene,
                                    }),
                                  );
                                }}
                              >
                                {fontVariants?.map((fontVariant: FontVariantInterface) => (
                                  <option key={fontVariant.id} value={fontVariant.type}>
                                    {fontVariant.type}
                                  </option>
                                ))}
                              </Select>
                            }
                          />

                          <GridItem
                            noBorderBg
                            label={'Font color:'}
                            item={
                              <PaletteColorPicker
                                value={defaultStyle.fontColor}
                                onChange={(e) => onDefaultChange('fontColor', e)}
                              />
                            }
                          />

                          <GridItem
                            label="Label align:"
                            noBorderBg
                            item={
                              <Select
                                className={styles.select}
                                value={defaultStyle.fontAlignment}
                                onChange={(e) => onDefaultChange('fontAlignment', e.target.value)}
                              >
                                {['left', 'right', 'center'].map((k) => (
                                  <option key={k} value={k}>
                                    {k}
                                  </option>
                                ))}
                              </Select>
                            }
                          />

                          <GridItem
                            label={'Stroke width:'}
                            noBorderBg
                            item={
                              <Input
                                style={{ padding: '0' }}
                                type={'number'}
                                onChange={(e) =>
                                  onDefaultChange(
                                    'strokeWidth',
                                    Number(e.target.value) >= 0 ? e.target.value : 0,
                                  )
                                }
                                value={defaultStyle.strokeWidth ?? 0}
                                className={styles.inputWrap}
                              />
                            }
                          />

                          <GridItem
                            noBorderBg
                            label={'Stroke color:'}
                            item={
                              <PaletteColorPicker
                                value={defaultStyle.strokeColor ?? 'rgba(255, 255, 255, 255)'}
                                onChange={(e) => onDefaultChange('strokeColor', e)}
                              />
                            }
                          />

                          <GridItem
                            label="Text transform:"
                            noBorderBg
                            item={
                              <Select
                                className={styles.select}
                                value={defaultStyle.textTransform}
                                onChange={(e) => onDefaultChange('textTransform', e.target.value)}
                              >
                                {transformText.map((item) => (
                                  <option key={item.format} value={item.format}>
                                    {item.name}
                                  </option>
                                ))}
                              </Select>
                            }
                          />
                        </GridWrapper>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Tabs.Item>
          <Tabs.Item
            title="Load cities for viewport"
            itemKey={'by-viewport'}
            key={'by-viewport'}
            style={{ backgroundColor: '#05152b', height: 'fill-available' }}
          >
            {/* <div className="h-[250px] flex justify-center items-center">Under construction</div> */}
            <SearchByViewport
              mapDef={mapDef}
              selectedCities={selectedCities}
              setSelectedCities={setSelectedCities}
              fonts={fonts}
              fontVariants={fontVariants}
              setFontId={setFontId}
              defaultStyle={defaultStyle}
              setDefaultStyle={setDefaultStyle}
            />
          </Tabs.Item>
          <Tabs.Item
            title="All cities in country"
            itemKey={'by-country'}
            key={'by-country'}
            style={{ backgroundColor: '#05152b', height: 'fill-available' }}
          >
            <div className="h-[250px] flex justify-center items-center">Under construction</div>
          </Tabs.Item>
        </Tabs>
      </div>
    </Modal>
  );
};

export default AddCitiesModal;
