import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { usePropertyGridActive } from '../../hooks/usePropertyGridActive';
import styles from '../../pages/playground/properties/Properties.module.scss';
import { ActiveDef, setPropertyGridActiveHash } from '../../store/slices/active-slice';
import { RootState } from '../../store/store';

interface TimeInputProps {
  time: number;
  framerate: number;
  onChange: (e: number) => void;
  relative?: number;
  relation?: 'higher' | 'lower';
  index: number;
  startEnd: 'start' | 'end';
}
export const TimeInput = ({
  time,
  framerate,
  onChange,
  relative,
  relation,
  index,
  startEnd,
}: TimeInputProps) => {
  const { activeElement } = useSelector<RootState, ActiveDef>((state) => state.active);
  const dispatch = useDispatch();
  const { lastFocused } = usePropertyGridActive([]);
  function onFocus(path: string) {
    dispatch(setPropertyGridActiveHash({ activeElement, focusedEl: path }));
  }
  const [seconds, setSeconds] = useState<number>(Math.floor(time / 1000));
  const [frames, setFrames] = useState<number>(
    (time / 1000) * framerate - Math.floor(time / 1000) * framerate,
  );
  useEffect(() => {
    setSeconds(Math.floor(time / 1000));
    setFrames(Math.round((time / 1000) * framerate) - Math.floor(time / 1000) * framerate);
  }, [framerate, time, seconds, frames]);
  const changeTime = (secs?: number, frs?: number) => {
    const secondsConverted = (secs ?? seconds) * 1000;
    const framesConverted = ((frs ?? frames) / framerate) * 1000;
    const milliseconds = Math.round(secondsConverted + framesConverted);
    if (secs) setSeconds(Number(secs));
    if (frs) setFrames(Number(frs));
    onChange(milliseconds);
    if (relation === 'higher' && relative && relative < milliseconds) {
      setSeconds(Math.floor(time / 1000));
      setFrames(Math.round((time / 1000) * framerate) - Math.floor(time / 1000) * framerate);
    }
    if (relation === 'lower' && relative && relative > milliseconds) {
      setSeconds(Math.floor(time / 1000));
      setFrames(Math.round((time / 1000) * framerate) - Math.floor(time / 1000) * framerate);
    }
  };
  const onBlurSeconds = (e: ChangeEvent<HTMLInputElement>) => {
    changeTime(Number(e.target.value));
    setSeconds(Number(e.target.value));
  };
  const onBlurFrames = (e: ChangeEvent<HTMLInputElement>) => {
    changeTime(undefined, Number(e.target.value));
    setFrames(Number(e.target.value));
  };

  return (
    <div className={'flex'}>
      <input
        className={styles.inputWrap}
        onBlur={onBlurSeconds}
        value={seconds}
        onChange={(e) => {
          changeTime(Number(e.target.value));
        }}
        onFocus={() => onFocus(`[${index}].${startEnd}MS`)}
        autoFocus={lastFocused === `[${index}].${startEnd}MS`}
      />
      s
      <input
        className={styles.inputWrap}
        onBlur={onBlurFrames}
        value={frames}
        onChange={(e) => {
          changeTime(undefined, Number(e.target.value));
        }}
        onFocus={() => onFocus(`[${index}].${startEnd}MS`)}
        autoFocus={lastFocused === `[${index}].${startEnd}MS`}
      />
      fr
    </div>
  );
};
