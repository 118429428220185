import { DatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import moment from 'moment/moment';
import { useDispatch, useSelector } from 'react-redux';

import { spaceSpan } from '../../../../components/timeline/helpers';
import { MapPanelDef } from '../../../../model/definitions/MapPanelDef';
import { WeatherDataSpaceDef } from '../../../../model/definitions/WeatherDataSpaceDef';
import { TimeInput } from '../../../../molecules/timeInput/TimeInput';
import { ActiveDef } from '../../../../store/slices/active-slice';
import { updateSpaceClipLayers, updateSpaceTime } from '../../../../store/slices/project-slice';
import { selectActiveMapLayer } from '../../../../store/slices/selectors';
import { RootState } from '../../../../store/store';
import { Panel } from '../components/Panel';
import { PropertySection } from '../components/PropertySection';
import GridItem from '../shared/GridItem';
import GridWrapper from '../shared/GridWrapper';

export const SpaceProperties = () => {
  const dispatch = useDispatch();
  const mapLayer = useSelector<RootState, MapPanelDef | null | undefined>((state) =>
    selectActiveMapLayer(state),
  );
  const { activeFramerate, activeScene } = useSelector<RootState, ActiveDef>(
    (state) => state.active,
  );
  const space = mapLayer?.wdSpace[0];
  const timeControls = space!.timeControls;
  const { startMS, endMS } = timeControls[0];
  const onChange = (e: number, position: string) => {
    const time = { ...timeControls[0], [position]: e };
    mapLayer?.id &&
      dispatch(
        updateSpaceTime({
          time,
          activeScene,
          mapId: mapLayer.id,
          spaceId: space!.id,
        }),
      );
  };
  const span = space ? spaceSpan(space) : { min: 0, max: 0 };
  const format = 'DD-MM-YYYY HH:mm';
  const timeStart = moment(span.min * 1000).format(format);
  const timeEnd = moment(span.max * 1000).format(format);
  const start = dayjs(timeStart, format);
  const end = dayjs(timeEnd, format);
  const gribLayers = space?.gribMapLayers;
  const radarLayers = space?.radarMapLayers;
  const satelliteLayers = space?.satelliteMapLayers;
  const symbolLayers = space?.symbolLayers;
  const onChangeStart = (e: number) => {
    if (space) {
      const filteredGrib = gribLayers?.map((grib) => {
        return {
          ...grib,
          dataFrames: grib.dataFrames.filter((frame) => frame.timestamp >= e),
        };
      });
      const filteredRadar = radarLayers?.map((grib) => {
        return {
          ...grib,
          dataFrames: grib.dataFrames.filter((frame) => frame.timestamp >= e),
        };
      });
      const filteredSatellite = satelliteLayers?.map((grib) => {
        return {
          ...grib,
          dataFrames: grib.dataFrames.filter((frame) => frame.timestamp >= e),
        };
      });
      const filteredSymbol = symbolLayers?.map((grib) => {
        return {
          ...grib,
          dataFrames: grib.dataFrames.filter((frame) => frame.timestamp >= e),
        };
      });
      const payload: WeatherDataSpaceDef = {
        ...space,
        gribMapLayers: filteredGrib ?? [],
        radarMapLayers: filteredRadar ?? [],
        satelliteMapLayers: filteredSatellite ?? [],
        symbolLayers: filteredSymbol ?? [],
      };
      mapLayer &&
        space &&
        dispatch(
          updateSpaceClipLayers({
            payload,
            activeScene,
            mapId: mapLayer.id,
            spaceId: space.id,
          }),
        );
    }
  };
  const onChangeEnd = (e: number) => {
    if (space) {
      const filteredGrib = gribLayers?.map((grib) => {
        return {
          ...grib,
          dataFrames: grib.dataFrames.filter((frame) => frame.timestamp <= e),
        };
      });
      const filteredRadar = radarLayers?.map((grib) => {
        return {
          ...grib,
          dataFrames: grib.dataFrames.filter((frame) => frame.timestamp <= e),
        };
      });
      const filteredSatellite = satelliteLayers?.map((grib) => {
        return {
          ...grib,
          dataFrames: grib.dataFrames.filter((frame) => frame.timestamp <= e),
        };
      });
      const filteredSymbol = symbolLayers?.map((grib) => {
        return {
          ...grib,
          dataFrames: grib.dataFrames.filter((frame) => frame.timestamp <= e),
        };
      });
      const payload: WeatherDataSpaceDef = {
        ...space,
        gribMapLayers: filteredGrib ?? [],
        radarMapLayers: filteredRadar ?? [],
        satelliteMapLayers: filteredSatellite ?? [],
        symbolLayers: filteredSymbol ?? [],
      };
      mapLayer &&
        space &&
        dispatch(
          updateSpaceClipLayers({
            payload,
            activeScene,
            mapId: mapLayer.id,
            spaceId: space.id,
          }),
        );
    }
  };

  const disabledTime = (current: Dayjs | null) => {
    if (!current) {
      return {};
    }

    const currentIsSameStartDate = current.isSame(start, 'day');
    const currentIsSameEndDate = current.isSame(end, 'day');

    if (currentIsSameStartDate) {
      return {
        disabledHours: () => Array.from({ length: start.hour() }, (_, i) => i), // Disable hours before the start hour
        disabledMinutes: (hour: number) =>
          hour === start.hour() ? Array.from({ length: start.minute() }, (_, i) => i) : [],
        disabledSeconds: (hour: number, minute: number) =>
          hour === start.hour() && minute === start.minute()
            ? Array.from({ length: start.second() }, (_, i) => i)
            : [],
      };
    }

    if (currentIsSameEndDate) {
      return {
        disabledHours: () =>
          Array.from({ length: 24 - end.hour() - 1 }, (_, i) => i + end.hour() + 1), // Disable hours after the end hour
        disabledMinutes: (hour: number) =>
          hour === end.hour()
            ? Array.from({ length: 60 - end.minute() - 1 }, (_, i) => i + end.minute() + 1)
            : [],
        disabledSeconds: (hour: number, minute: number) =>
          hour === end.hour() && minute === end.minute()
            ? Array.from({ length: 60 - end.second() - 1 }, (_, i) => i + end.second() + 1)
            : [],
      };
    }

    return {};
  };
  return (
    <Panel>
      <PropertySection isOpened={true} label={'space time'}>
        <div className="prop-wrapper">
          <GridWrapper>
            <GridItem
              label="Start:"
              item={
                <TimeInput
                  time={startMS}
                  relative={endMS}
                  relation={'lower'}
                  framerate={activeFramerate}
                  onChange={(e) => onChange(e, 'startMS')}
                  index={0}
                  startEnd="start"
                />
              }
            />
            <GridItem
              label="End:"
              item={
                <TimeInput
                  time={endMS}
                  relative={startMS}
                  relation={'higher'}
                  framerate={activeFramerate}
                  onChange={(e) => onChange(e, 'endMS')}
                  index={0}
                  startEnd="end"
                />
              }
            />
          </GridWrapper>
        </div>
      </PropertySection>
      <PropertySection isOpened={true} label={'space range'}>
        <div className="prop-wrapper">
          <GridWrapper>
            <GridItem
              noBorderBg
              label="Start:"
              item={
                <DatePicker
                  defaultValue={start}
                  disabledDate={(e) => e < start.startOf('day') || e > end.endOf('day')}
                  disabledTime={(e) => disabledTime(e)}
                  format={format}
                  showTime
                  allowClear={false}
                  onChange={(e) => {
                    e && onChangeStart(e.unix());
                  }}
                />
              }
            />
            <GridItem noBorderBg label="" item={null} />
            <GridItem
              noBorderBg
              label="End:"
              item={
                <DatePicker
                  defaultValue={end}
                  disabledDate={(e) => e < start.startOf('day') || e > end.endOf('day')}
                  format={format}
                  disabledTime={(e) => disabledTime(e)}
                  showTime
                  allowClear={false}
                  onChange={(e) => e && onChangeEnd(e.unix())}
                />
              }
            />
          </GridWrapper>
        </div>
      </PropertySection>
    </Panel>
  );
};
