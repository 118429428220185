import './style.scss';

import { AxiosError } from 'axios';
import { DetailedHTMLProps, HTMLAttributes } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { C9ProjectPreviewDTO } from '../../../model/DTO/C9ProjectPreviewDTO';
import { RolesEnum } from '../../../model/enums/RolesEnum';
import { AnimationRetryRequest } from '../../../model/other/AnimationRetryRequest';
import { RootState } from '../../../store/store';
import Card from '../card-new/Card';
import Error from '../components/error/Error';
import Loader from '../components/Loader';
import NoDataFound from '../components/no-data/NoDataFound';
import Pagination from '../Pagination/Pagination';

interface GridProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  loading?: boolean;
  isError?: boolean;
  pages?: number;
  currentPage?: number;
  changePage?: (e: number) => void;
  onSearch?: (e: string) => void;
  contents: Array<C9ProjectPreviewDTO> | undefined;
  onClone?: (id: string, versionId: string) => void;
  onShare?: (id: string, type: string, replaceExisting: boolean) => void;
  onDelete?: (id: string, versionId: string) => void;
  onPublish?: (id: string) => void;
  onRetry?: (data: AnimationRetryRequest) => void;
  link?: (id: string) => void;
  detailsLink?: (id: string, data: C9ProjectPreviewDTO) => void;
  type?: string;
  error: AxiosError | null;
  activeTab?: string;
  setOpen?: (e: boolean) => void;
  searchString: string;
  onGetTemplate?: (id: string) => void;
  onEdit?: (data: {
    newName: string;
    newDescription: string;
    newIsDefault?: boolean;
    id: string;
    versionId: string;
  }) => void;
  onDefault?: (data: {
    newName: string;
    newDescription: string;
    newIsDefault: boolean;
    id: string;
    versionId: string;
  }) => void;
  fontWarningId?: string;
  setFontWarningId?: (id: string) => void;
  onUnlock?: (id: string) => void;
}
const GridLayout = ({
  loading = true,
  isError,
  pages,
  currentPage,
  changePage,
  onEdit,
  contents,
  onClone,
  onShare,
  onDelete,
  onPublish,
  onRetry,
  onDefault,
  error,
  link,
  detailsLink,
  type,
  searchString,
  onGetTemplate,
  fontWarningId,
  setFontWarningId,
  onUnlock,
}: GridProps) => {
  const location = useLocation();
  const path = location.pathname.includes('purchased');
  const { userRoles } = useSelector<RootState>((state) => state.user) as {
    userRoles: RolesEnum[];
  };
  const cards = () =>
    contents?.map((item) => (
      <Card
        link={(id) => link && link(id)}
        detailsLink={(id, data) => detailsLink && detailsLink(id, data)}
        type={type}
        key={item.id}
        item={item}
        id={item.id}
        search={searchString}
        onClone={onClone}
        onShare={onShare}
        onDelete={onDelete}
        onEdit={onEdit}
        onDefault={onDefault}
        onRetry={onRetry}
        onGetTemplate={onGetTemplate}
        path={path}
        onPublish={onPublish}
        fontWarningId={fontWarningId}
        setFontWarningId={setFontWarningId}
        userRoles={userRoles}
        onUnlock={onUnlock}
      />
    ));
  // console.log('contentFonts', content);
  return (
    <>
      {loading && <Loader />}
      {isError && <Error error={error} />}
      {contents && (
        <div className={'ws-grid-wrapper'}>
          <div className={'ws-grid-layout'}>
            {contents && contents?.length > 0 ? (
              <>{cards()}</>
            ) : (
              <div className={'ws-no-data'}>
                <NoDataFound />
              </div>
            )}
          </div>
          <Pagination
            pages={pages ?? 0}
            changePage={(e) => changePage && changePage(e)}
            currentPage={currentPage}
          />
        </div>
      )}
    </>
  );
};

export default GridLayout;
